import { AMAURY_TDF, IIHF_MS, IIHF_ZOH, UEFA_ME } from '../../config';

/**
 * Vrati base URl pre event na zaklade id sutaze a rocnika.
 * @param competitionId
 * @param season
 * @returns
 */
const getEventBaseUrl = (competitionId: string) => {
  const msHokej = Object.entries(IIHF_MS).find(
    ([, val]) => val === competitionId,
  );
  if (msHokej) {
    const [year] = msHokej;
    return year ? `/ms-v-hokeji/${year}` : `/ms-v-hokeji`;
  }
  const iihfZoh = Object.entries(IIHF_ZOH).find(
    ([, val]) => val === competitionId,
  );
  if (iihfZoh) {
    const [year] = iihfZoh;
    return year
      ? `/olympijske-hry/zimna-olympiada/hokej/${year}`
      : `/olympijske-hry/zimna-olympiada/hokej`;
  }
  const uefaMe = Object.entries(UEFA_ME).find(
    ([, val]) => val === competitionId,
  );
  if (uefaMe) {
    const [year] = uefaMe;
    return year ? `/me-vo-futbale/${year}` : `/me-vo-futbale`;
  }
  const amauryTdf = Object.entries(AMAURY_TDF).find(
    ([, val]) => val === competitionId,
  );
  if (amauryTdf) {
    const [year] = amauryTdf;
    return year ? `/tour-de-france/${year}` : `/tour-de-france`; 
  }
  return '';
};

export default getEventBaseUrl;
