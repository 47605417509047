import { OptimizedPublicSmartTagResponse } from '../../library/SmartTags';

const getSmarttagPageUrl = (
  baseUri: string,
  smartTag: OptimizedPublicSmartTagResponse,
) => {
  const { identifier, url } = smartTag;
  if (url) {
    return url;
  }
  return `${baseUri}/t/${identifier}/`;
};

export default getSmarttagPageUrl;
