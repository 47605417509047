import { ThirdPartyResultsOdd } from '../../library/thirdPartyResults';
import __ from '../__';

export enum NikeOfferType {
  TEAM_ONE = '1',
  DRAW = 'X',
  TEAM_TWO = '2',
}

const getTooltipContent = (type: ThirdPartyResultsOdd['type']) => {
  if (type === NikeOfferType.TEAM_ONE) {
    return __('výhra domácich');
  }

  if (type === NikeOfferType.TEAM_TWO) {
    return __('výhra hostí');
  }

  if (type === NikeOfferType.DRAW) {
    return __('remíza');
  }

  return '-';
};

export default getTooltipContent;
