import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import useQuery, { PageParam } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { Article_Author } from '../../../../api/CmsApi';
import FutbalnetCMSAdManager from '../../../../components/Ads/AdManager/FutbalnetCMSAdManager';
import BottomPaddedBigAd from '../../../../components/Ads/BottomPaddedBigAd';
import BottomPaddedListBodyRespAd from '../../../../components/Ads/BottomPaddedListBodyRespAd';
import MegaboardAd from '../../../../components/Ads/MegaboardAd';
import SideAd from '../../../../components/Ads/SideAd';
import TopAd from '../../../../components/Ads/TopAd';
import ArticleEnding from '../../../../components/Articles/ArticleEnding';
import ArticleMetaInformation from '../../../../components/Articles/ArticleMetaInformation';
import ArticlePicture from '../../../../components/Articles/ArticlePicture';
import ArticleSection from '../../../../components/Articles/ArticleSection';
import ArticleSeparatorLine from '../../../../components/Articles/ArticleSeparatorLine';
import {
  ContextMarginBigTopStyles,
  ContextMarginTopStyles,
} from '../../../../components/Layout';
import SidebarLayout, {
  BottomPaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../../components/Layout/SidebarLayout';
import Perex from '../../../../components/Perex';
import SocialShare from '../../../../components/Share/SocialShare';
import NativeShare from '../../../../components/Share/Types/NativeShare';
import Title from '../../../../components/Title';
import Widgets from '../../../../components/Widgets';
import config from '../../../../config';
import { RootState } from '../../../../configureStore';
import ArticleListPreview from '../../../../containers/ArticleListPreview';
import Authors from '../../../../containers/Authors';
import FutbalnetSectionsBreadcrumbs from '../../../../containers/FutbalnetBreadcrumbs/FutbalnetSectionsBreadcrumbs';
import PremiumContentMessage from '../../../../containers/PremiumContentMessage';
import SimilarArticles from '../../../../containers/SimilarArticles';
import TopArticlesSidebar from '../../../../containers/TopArticlesSidebar';
import UrlContext from '../../../../contexts/UrlContext';
import useTopArticlesSidebar from '../../../../hooks/useTopArticlesSidebar';
import useWidgetDoxxbetInjector from '../../../../hooks/useWidgetDoxxbetInjector';
import { IArticle, ISection, RouteProps } from '../../../../library/App';
import {
  initializeOrSetListParams,
  loadPPOUsers,
} from '../../../../pages/App/actions';
import styled from '../../../../theme/styled-components';
// import useScrollProgress from '../../../../utilities/useScrollProgress';
import { setScrollProgress } from '../../../App/actions';
import {
  loadSimilarArticlesList,
  putArticleStats,
} from '../../../Article/actions';
import { currentOrganizationProfileSelector } from '../../../Competition/selectors';
import { appSettingsSelector } from '../../../DomainResolver/selectors';
import { currentSectionSelector } from '../../../Section/selectors';

const HeaderSidebarLayout = styled(SidebarLayout)`
  margin-bottom: ${rem(32)};
  ${mb('s')} {
    margin-bottom: ${rem(40)};
  }
  ${ContextMarginTopStyles}
`;

const ContentSidebarLayout = styled.div`
  display: flex;
`;

const LeftSidebarLayoutSidebar = styled.aside`
  flex: 0 0 ${rem(40)};
  margin: 0 ${rem(32)} 0 0;

  display: none;
  flex-direction: column;
  ${mb('l')} {
    display: flex;
  }
  & > *:not(:last-child) {
    margin-bottom: ${rem(12)};
  }
`;

const StickyLeftSidebarContainer = styled.div`
  position: sticky;
  top: ${rem(89)};
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: ${rem(12)};
  }
`;

const InlineShareContainer = styled.div`
  & > *:not(:last-child) {
    margin-right: ${rem(8)};
  }
  display: flex;
  align-items: center;
  ${mb('l')} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  ${mb('l')} {
    max-width: ${rem(680)};
    margin: 0 auto;
  }
`;

const WiderContentContainer = styled.div`
  ${mb('l')} {
    max-width: ${rem(728)};
    margin-right: auto;
  }
`;

const ArticleDescriptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    padding: ${rem(12)};
  }
  margin: ${rem(-12)} ${rem(-12)} ${rem(48 - 12)} ${rem(-12)};
  ${mb('s')} {
    margin: ${rem(-12)} ${rem(-12)} ${rem(56 - 12)} ${rem(-12)};
  }
`;

const ResponsiveTopArticlesSidebar = styled(TopArticlesSidebar)`
  ${ContextMarginBigTopStyles}
  ${mb('l')} {
    display: none;
  }
`;

// const SpacedSmarttags = styled(Smarttags)`
//   ${ContextMarginBottomStyles}
// `;

type IOwnProps = {
  article: IArticle;
  isPreview?: boolean;
  appSpace: string;
};

const mapStateToProps = (state: RootState) => {
  return {
    section: currentSectionSelector(state),
    appSettings: appSettingsSelector(state),
    organizationProfile: currentOrganizationProfileSelector(state),
  };
};

const mapDispatchToProps = {
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadSimilarArticlesList,
  putArticleStats,
  setScrollProgress,
  loadPPOUsers,
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  RouteComponentProps<{ zvaz: string }, {}>;

const QUERY_HOC_CONFIG = {
  parameters: {
    page: PageParam(),
  },
};

const RegularArticle: React.FC<IProps> = ({
  location: { search, pathname },
  router,
  initializeOrSetListParams,
  article,
  section,
  loadSimilarArticlesList,
  loadPPOUsers,
  putArticleStats,
  setScrollProgress,
  isPreview,
  appSpace,
  appSettings,
  params: { zvaz },
}) => {
  const { getSectionUrl } = React.useContext(UrlContext);
  const authorsHash = JSON.stringify(article.authors || []);

  const articleId = article._id!;

  const { query } = useQuery(
    search,
    (serializedQuery) => router.push(`${pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG,
  );

  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  const authors = React.useMemo(() => {
    return JSON.parse(authorsHash) as Article_Author[];
  }, [authorsHash]);

  // ziskame profily autorov, ktori maju `sportnetId`
  useAsyncData(async () => {
    if (!appSpace) {
      return;
    }
    const ids = authors.filter((i) => i.sportnetId).map((i) => i.sportnetId!);
    if (ids.length) {
      await loadPPOUsers({
        appSpaceId: appSpace,
        params: {
          ids,
          limit: authors.length,
        },
      });
    }
  }, [loadPPOUsers, authors, appSpace]);

  useAsyncData(async () => {
    if (!articleId || articleId === config.PREVIEW_ARTICLE_ID) {
      return;
    }
    await initializeOrSetListParams({
      listName: config.LIST_SIMILAR_ARTICLES,
      params: {
        ...query,
        limit: config.LIST_SIMILAR_ARTICLES_LIMIT,
        articleId,
        appSpace,
      },
    });
    await loadSimilarArticlesList(config.LIST_SIMILAR_ARTICLES);
  }, [
    initializeOrSetListParams,
    loadSimilarArticlesList,
    query,
    articleId,
    appSpace,
  ]);

  React.useEffect(() => {
    if (!isPreview) {
      return;
    }
    const id = articleId;
    const timeoutID = window.setTimeout(() => {
      putArticleStats({ id, appSpace });
    }, config.ARTICLE_STATS_DELAY);
    return () => {
      window.clearTimeout(timeoutID);
    };
  }, [putArticleStats, articleId, isPreview, appSpace]);

  // const articleStartElRef = React.useRef<HTMLDivElement | null>(null);
  // const articleEndElRef = React.useRef<HTMLDivElement | null>(null);
  // useScrollProgress(
  //   articleStartElRef,
  //   articleEndElRef,
  //   setScrollProgress,
  //   true,
  // );

  const content = article.widgets || [];
  const url = `${appSettings.baseUri}${pathname}`;
  const title = article.name || '';
  const expandedDoctype = article.doctypeExpanded;
  const socialConfig = expandedDoctype?.social || {};

  useWidgetDoxxbetInjector(content);

  return (
    <>
      <FutbalnetCMSAdManager
        site="article"
        siteId={String(articleId)}
        pagetype="article"
      />

      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <HeaderSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {/* <div ref={articleStartElRef} /> */}
          {section && (
            <ArticleSection to={getSectionUrl(section as ISection)}>
              {section.name}
            </ArticleSection>
          )}
          <Title>{title}</Title>
        </SidebarLayoutContent>
        <Sidebar />
      </HeaderSidebarLayout>

      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <ContentSidebarLayout>
            <LeftSidebarLayoutSidebar>
              <StickyLeftSidebarContainer>
                <SocialShare
                  config={socialConfig}
                  shape="circle"
                  title={title}
                  url={url}
                />
                <NativeShare shape="circle" url={url} />
              </StickyLeftSidebarContainer>
            </LeftSidebarLayoutSidebar>

            <SidebarLayoutContent>
              <WiderContentContainer>
                <ContentContainer>
                  {article.picture && (
                    <ArticlePicture picture={article.picture} />
                  )}
                  <ArticleDescriptionContainer>
                    <ArticleMetaInformation
                      createAuthorsLink={[
                        'pages:sportnet.sme.sk:default',
                        'pages:beta.sportnet.sme.sk:default',
                      ].includes(article.contentspace ?? '')}
                      createdDate={
                        article.valid_from ? new Date(article.valid_from) : null
                      }
                      modifiedDate={
                        article.modified ? new Date(article.modified) : null
                      }
                      authors={article.authors || []}
                      isPrivate={!!article.is_private}
                    />
                    <InlineShareContainer>
                      <SocialShare
                        config={socialConfig}
                        shape="rectangle"
                        title={title}
                        url={url}
                      />
                    </InlineShareContainer>
                  </ArticleDescriptionContainer>
                  <div className="nl-subscribe" />
                  <BottomPaddedListBodyRespAd name="list_body_1" />
                  <Perex text={article.perex} />
                  {content.length > 0 && (
                    <Widgets
                      items={content}
                      context={{ currentArticleId: articleId }}
                    />
                  )}
                  {(article.state === 'UNATHORIZED' ||
                    article.state === 'FORBIDDEN') && (
                    <PremiumContentMessage
                      showShadow={content.length > 1}
                      article={article}
                    />
                  )}
                  {article.state === 'FULL' && <ArticleEnding />}
                  {/* <SpacedSmarttags smarttags={article.smarttags || []} /> */}
                  <Authors appSpaceId={appSpace || ''} authors={authors} />
                  {/* <div ref={articleEndElRef} /> */}
                  <ArticleSeparatorLine />
                  {isPreview && <ArticleListPreview article={article} />}
                  <SimilarArticles page={query.page} />
                </ContentContainer>
              </WiderContentContainer>
            </SidebarLayoutContent>
          </ContentSidebarLayout>
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>

        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>

      <MegaboardAd />

      <FutbalnetSectionsBreadcrumbs section={section} zvaz={zvaz} />
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RegularArticle) as any as React.FC<IOwnProps>;
