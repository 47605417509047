import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M19.4,15.6c-0.1,0.1-0.2,0.2-0.4,0.3V20c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1V6c0-0.6,0.4-1,1-1h1
    c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2h1c0.6,0,1,0.4,1,1v1.5l2-2C20.7,4.1,19.5,3,18,3h-1c0-1.1-0.9-2-2-2H9C7.9,1,7,1.9,7,3H6
    C4.3,3,3,4.3,3,6v14c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-6L19.4,15.6z M9,3h6v2H9l0-1c0,0,0,0,0,0s0,0,0,0L9,3L9,3z M15.2,16.5
    c-0.3,0-0.5-0.1-0.7-0.3l-2.7-2.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2,2l5.3-5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-6,6
    C15.8,16.4,15.5,16.5,15.2,16.5z"
    />
  </svg>
);
