import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled.p`
  font-family: pt-serif-pro, serif;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(17)};
  line-height: ${rem(25.5)};
  font-weight: 700;
  ${mb('s')} {
    font-size: ${rem(19)};
    line-height: ${rem(28.5)};
  }
  margin: 0 0 ${rem(32)} 0;
  white-space: pre-wrap;
`;

interface OwnProps {
  text?: string | null;
}

type Props = OwnProps;

const Perex: React.FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return <Wrapper>{text}</Wrapper>;
};

export default Perex;
