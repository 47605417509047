import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import Link from '../../components/Link';
import { css } from '../../theme/styled-components';
import useIsResponsiveLayout from '../../hooks/useIsResponsiveLayout';

const Wrapper = styled.div`
  background-color: #f9f9f9;
  ${mb('s')} {
    background-color: #fff;
  }
  ${mb('l')} {
    padding-bottom: 0;
  }
`;

const InnerWRapperStyles = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${rem(5)} 0;
  font-size: ${rem(14)};
  font-weight: 600;
  min-height: ${rem(30)};
  flex-direction: column;
  ${mb('s')} {
    flex-direction: row;
  }
`;

const TargetLink = styled(Link)<{ backgroundColor: string; color: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${InnerWRapperStyles};
`;

const NoLinkWrapper = styled.div<{ backgroundColor: string; color: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  ${InnerWRapperStyles};
`;

const LogoImage = styled.img<{ logoMarginRight?: number }>`
  flex: 0 0 auto;
  height: ${rem(20)};
  flex-shrink: 0;
  margin: auto ${({ logoMarginRight = 4 }) => rem(logoMarginRight)} auto 0;
  vertical-align: bottom;
`;

const Text = styled.div`
  font-weight: 700;
  text-align: center;
  ${mb('s')} {
    margin-bottom: -2px;
  }
`;

type IOwnProps = {
  backgroundColor?: string;
  color?: string;
  text: string;
  textMobile?: string;
  logoSrc?: string;
  url?: string;
  logoMarginRight?: number;
};

const PromoStrip: React.FC<IOwnProps> = ({
  backgroundColor = '#CF2528',
  color = '#fff',
  text,
  textMobile,
  logoSrc,
  url,
  logoMarginRight = 4,
}) => {
  const [isHydrated, setIsHydrated] = React.useState(false);
  const isMobile = useIsResponsiveLayout(768);

  React.useEffect(() => {
    setIsHydrated(true);
  }, []);

  const renderStripContent = () => (
    <>
      {logoSrc && (
        <LogoImage
          src={logoSrc}
          alt="Logo Image"
          logoMarginRight={logoMarginRight}
        />
      )}
      <Text>{isMobile ? textMobile || text : text}</Text>
    </>
  );

  return isHydrated ? (
    <Wrapper>
      {url ? (
        <TargetLink
          backgroundColor={backgroundColor}
          color={color}
          to={url}
          rel="nofollow noreferrer noopener"
          target="_blank"
        >
          {renderStripContent()}
          {/* <PromoStripTrackingImage /> */}
        </TargetLink>
      ) : (
        <NoLinkWrapper backgroundColor={backgroundColor} color={color}>
          {renderStripContent()}
        </NoLinkWrapper>
      )}
    </Wrapper>
  ) : null;
};

export default PromoStrip;
