import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { ITeam } from '../../library/Competitions';

export default function getMatchHomeTeam(
  match: IWidgetMatchesListMatchItem,
): ITeam | undefined {
  const teams = match.teams || [];

  return teams.find((t) => t.additionalProperties?.homeaway === 'home');
}
