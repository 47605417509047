import React from 'react';
export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M13.3,8.4c0,0.1-0.1,0.1-0.1,0.2l-4.7,4.7c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l3.4-3.4H3.3
	C2.9,8.8,2.6,8.4,2.6,8s0.3-0.8,0.8-0.8h7.5L7.5,3.9c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.7,4.7c0,0,0.1,0.1,0.1,0.2
	c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3S13.4,8.2,13.3,8.4C13.3,8.3,13.3,8.3,13.3,8.4z"
    />
  </svg>
);
