import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM9.665,12.4q-.618.244-.985.371a2.6,2.6,0,0,1-.855.128,1.7,1.7,0,0,1-1.163-.365,1.178,1.178,0,0,1-.414-.926,3.363,3.363,0,0,1,.03-.446c.021-.152.054-.322.1-.514l.515-1.82c.045-.175.085-.341.116-.5A2.2,2.2,0,0,0,7.056,7.9a.66.66,0,0,0-.144-.486.816.816,0,0,0-.551-.136,1.445,1.445,0,0,0-.41.061c-.139.043-.259.081-.358.119L5.73,6.9q.506-.206.968-.353A2.861,2.861,0,0,1,7.572,6.4a1.672,1.672,0,0,1,1.146.359,1.193,1.193,0,0,1,.4.932q0,.119-.028.418a2.8,2.8,0,0,1-.1.549l-.513,1.815c-.042.146-.079.312-.113.5a2.636,2.636,0,0,0-.049.424.609.609,0,0,0,.162.493.906.906,0,0,0,.56.131,1.625,1.625,0,0,0,.424-.066,2.41,2.41,0,0,0,.343-.115ZM9.574,5.031a1.224,1.224,0,0,1-.863.333,1.236,1.236,0,0,1-.867-.333,1.064,1.064,0,0,1-.361-.808,1.074,1.074,0,0,1,.361-.81,1.227,1.227,0,0,1,.867-.337,1.214,1.214,0,0,1,.863.337,1.091,1.091,0,0,1,0,1.618Z"
    />
  </svg>
);
