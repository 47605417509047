import React from 'react';
import AdManager from '../../components/Ads/AdManager';
import { RootState } from '../../configureStore';
import { RouteComponentProps } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { getCanonicalUrl } from '../Transfers/utilities';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';
import RempIntegration from '../../containers/RempIntegration';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import TopAd from '../../components/Ads/TopAd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ListBodyRespAd from '../../components/Ads/ListBodyRespAd';
import { authUserIsNoAdvertsSelector } from '../App/selectors';
import styled, { useTheme } from 'styled-components';
import { rem } from 'polished';
import MaxWidthBox from '../../components/MaxWidthBox';
import BigAd from '../../components/Ads/BigAd';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import SectionName from '../../components/Sections/SectionBlockHeader/SectionName';
import FutbalnetLogo from '../../components/FutbalnetHeader/FutbalnetLogo';
import {
  BottomPaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import SideAd from '../../components/Ads/SideAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import { prefetchInfiniteQ, prefetchQ, useInfiniteQ, useQ } from '../../query';
import getFeedItemsWithBannersQ, {
  FeedItemWithCustomSmarttags,
  getNumberOfFeedItems,
} from './features/getFeedItemsWithBanners';
import GossipsListLoader from './GossipsListLoader';
import GossipsList from './GossipsList';
import NoResults from '../../components/v2/NoResults';
import SectionBodyAd from '../../components/Sections/Ads/SectionBodyAd';
import { useQueryClient } from '@tanstack/react-query';
import { useAsyncData } from 'ssr-service';
import isBrowser from '../../utilities/isBrowser';
import config from '../../config';
import { getPublicFeed } from './features/getPublicFeed';
import InfiniteListingArticleList from '../../containers/InfiniteListingArticleList';
import useInfiniteListParams from '../../hooks/useInfiniteListParams';
import { getGossipsBaseUrl } from './utils';
import Breadcrumbs from '../../components/Breadcrumbs';
import { ROOT_BREADCRUMB } from '../../containers/SectionsBreadcrumbs';
import { StyledIcon, StyledSectionBlockHeaderContainer } from './detail';
import SectionNameSmall from '../../components/Sections/SectionBlockHeader/SectionNameSmall';
import { ITheme } from '../../theme/theme';
import SDGossipsLiveBlogPosting from '../../containers/StructuredData/SDGossipsLiveBlogPosting';
import isAfter from 'date-fns/isAfter';
import TransfersWidget from '../Transfers/TransfersWidget';

const ResponsiveTransfersWidget = styled(TransfersWidget)`
  display: block;
  margin-top: ${rem(32)};
  ${mb('l')} {
    display: none;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  margin: 0;
  ${mb('s')} {
    margin-bottom: ${rem(24)};
  }

  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

const SpacedMaxWidthBox = styled(MaxWidthBox)`
  margin-top: ${rem(24)};
`;

const SpacedBigAd = styled(BigAd)`
  && {
    min-height: ${rem(120)};
    margin-bottom: ${rem(24)};
    ${mb('l')} {
      margin-bottom: ${rem(32)};
    }
  }
`;

export const FutbalnetLogoWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: block;
    margin-left: auto;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  ${mb('m')} {
    gap: ${rem(16)};
  }
`;

export const Subtitle = styled.p`
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.textColor};
  margin: 0;
  opacity: 0.56;
  ${mb('m')} {
    font-size: ${rem(14)};
  }
`;

const mapStateToProps = (
  state: RootState,
  { location: { hash } }: RouterProps
) => {
  return {
    authUserIsNoAdverts: authUserIsNoAdvertsSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type RouterProps = RouteComponentProps<{}, void>;

type Props = ReturnType<typeof mapStateToProps> & RouterProps;

const Gossips: React.FC<Props> = ({
  location: { pathname, search, hash },
  appSettings,
  authUserIsNoAdverts,
}) => {
  const title = `${__('Futbalové pikošky')}`;
  const metaTitle = `${__(
    'Futbalové pikošky - Prestupy, klebety, špekulácie vo futbale'
  )}`;

  // Najnovšie prestupové špekulácie a zaujímavosti vo futbale z našich zdrojov. Futbalové prestupy, klebety a hosťovania.
  const metaDescription = `${__(
    'Najnovšie prestupové špekulácie a zaujímavosti vo futbale z našich zdrojov. Futbalové prestupy, klebety a hosťovania.'
  )}`;
  const canonicalUrl = getCanonicalUrl(pathname, search);
  const siteId = `${pathname}/${search}/${hash}`;

  const [page, setPage] = React.useState(1);
  const { initialLimit, initialOffset, nextOffset } =
    useInfiniteListParams(page);

  const queryClient = useQueryClient();

  const { data: publicFeedData } = useQ(getPublicFeed, {
    feedId: config.FEEDS_GOSSIP_ID,
  });

  const sorter = !!publicFeedData ? publicFeedData.sorter ?? 'auto' : undefined;
  const fix = !!publicFeedData ? publicFeedData.fix ?? 1 : undefined;

  const theme = useTheme() as ITheme;

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    isRefetching,
  } = useInfiniteQ(
    getFeedItemsWithBannersQ,
    {
      feedId: config.FEEDS_GOSSIP_ID,
      limit: config.FEEDS_GOSSIP_LIST_DEFAULT_LIMIT,
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset,
    }
  );

  const allGossips = React.useMemo(() => {
    return (data?.pages || []).reduce((acc, page) => {
      acc.push(...page.feeditems);
      return acc;
    }, [] as FeedItemWithCustomSmarttags[]);
  }, [data]);

  const newItemsFilter = React.useMemo(() => {
    if (allGossips && allGossips.length > 0 && sorter) {
      if (sorter === 'auto') {
        return {
          dateFrom: allGossips[0]?.date,
        };
      } else if (sorter === 'manual' && fix !== undefined) {
        if (allGossips.length > fix - 1) {
          return {
            feedItemId: allGossips[fix - 1]._id,
          };
        } else {
          return {
            feedItemId: allGossips[allGossips.length - 1]._id,
          };
        }
      } else {
        return null;
      }
    }
    return null;
  }, [allGossips, sorter, fix]);

  // newest feed item
  const newestFeedItemDate =
    data && data.pages.length > 0
      ? data.pages[0].feeditems.reduce<Date | null>((acc, feedItem) => {
          const feedItemDate = new Date(feedItem.date);
          if (!acc) {
            return feedItemDate;
          }
          if (isAfter(new Date(feedItem.date), acc)) {
            acc = feedItemDate;
          }
          return acc;
        }, null)
      : null;

  const { data: newFeedsData } = useQ(getNumberOfFeedItems, newItemsFilter, {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 65,
    refetchIntervalInBackground: false,
    enabled: !!newItemsFilter,
    cacheTime: 1000 * 10,
    staleTime: 1000 * 10,
  });

  // ssr
  useAsyncData(async () => {
    if (!isBrowser()) {
      await prefetchInfiniteQ(queryClient, getFeedItemsWithBannersQ, {
        feedId: config.FEEDS_GOSSIP_ID,
        limit: config.FEEDS_GOSSIP_LIST_DEFAULT_LIMIT,
      });

      await prefetchQ(queryClient, getPublicFeed, {
        feedId: config.FEEDS_GOSSIP_ID,
      });
    }
  }, [queryClient, isBrowser]);

  const hasData =
    data?.pages && data.pages.length > 0 && data.pages[0].total > 0;

  const handleLoadMoreArticles = () => {
    setPage((prev) => ++prev);
  };

  return (
    <>
      <AdManager
        site="rubric"
        siteId={siteId}
        origin="futbalnet"
        pagetype="other"
        rubric="futbalnet-pikosky"
      />
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://mediamanager.ws/media/feeds/b/beta.sportnet.sme.sk/2024/08/futbalove-pikosky-zoznam-og-image.png"
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {hasData && (
        <SDGossipsLiveBlogPosting
          gossips={data.pages[0].feeditems}
          newestGossipDate={newestFeedItemDate}
        />
      )}
      <RempIntegration destroy />
      <FutbalnetHomeHeader />
      <TopAd />
      <ListBodyRespAd name="list_body_1" />
      {!authUserIsNoAdverts && <SpacedBigAd name="big_1" />}
      <SpacedMaxWidthBox className="sptn-main-content">
        <SectionTitleWrapper
          style={{
            paddingTop: 0,
            paddingBottom: rem(16),
          }}
        >
          <TitleWrapper>
            <SectionName as="h1">{title}</SectionName>
            <Subtitle>
              {__(
                'Najnovšie prestupové špekulácie a zaujímavosti vo futbale z našich zdrojov. Futbalové prestupy, klebety a hosťovania.'
              )}
            </Subtitle>
          </TitleWrapper>
          <FutbalnetLogoWrapper>
            <FutbalnetLogo />
          </FutbalnetLogoWrapper>
        </SectionTitleWrapper>
      </SpacedMaxWidthBox>
      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {isLoading || isRefetching ? (
            <GossipsListLoader />
          ) : hasData ? (
            <GossipsList
              gossips={allGossips}
              isFetchingNextPage={isFetchingNextPage}
              onLoadMoreClick={hasNextPage ? fetchNextPage : undefined}
              newMessagesCount={newFeedsData?.numberOfFeedItems || 0}
              onNewMessagesButtonClick={refetch}
              fixedCount={fix}
            />
          ) : (
            <NoResults
              text={__('Nenašli sa žiadne výsledky')}
              resetText="Znova načítať"
              onResetFilterClick={refetch}
            />
          )}
          <SectionBodyAd />
          <ResponsiveTransfersWidget />
          <StyledSectionBlockHeaderContainer
            left={
              <>
                <StyledIcon name="news" size={24} color={theme.color.primary} />
                <SectionNameSmall>
                  {__('Najnovšie články z futbalnetu')}
                </SectionNameSmall>
              </>
            }
          />
          <InfiniteListingArticleList
            initialLimit={initialLimit}
            nextOffset={nextOffset}
            initialOffset={initialOffset}
            listingId={config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID}
            onLoadMore={handleLoadMoreArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TransfersWidget />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>
      <Breadcrumbs
        crumbs={[
          ROOT_BREADCRUMB,
          {
            label: 'Pikošky',
            url: getGossipsBaseUrl({ trailingSlash: true }),
          },
        ]}
      />
      <MegaboardAd />
    </>
  );
};

export default compose(connect(mapStateToProps), React.memo)(Gossips);
