import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M21,22.1h-9c-0.6,0-1-0.4-1-1s0.4-1,1-1h9c0.6,0,1,0.4,1,1S21.6,22.1,21,22.1z M3.2,22.1l4-1
    c0.2,0,0.3-0.1,0.5-0.3L20.2,8.3c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0L3.3,16.4c-0.1,0.1-0.2,0.3-0.3,0.5l-1,4
    c-0.1,0.3,0,0.7,0.3,0.9C2.5,22,2.7,22.1,3,22.1C3.1,22.1,3.2,22.1,3.2,22.1z M17.2,5.3L17.2,5.3c0.4-0.4,1.2-0.4,1.6,0
    c0.4,0.4,0.4,1.1,0,1.6L6.5,19.2l-2.1,0.5l0.5-2.1L17.2,5.3z M16.5,4.6L16.5,4.6L16.5,4.6z"
    />
  </svg>
);
