import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import styled, { css } from '../../theme/styled-components';
import { LinkStyles } from '../Link';
import SectionListTabsStyleOverrides from './Overrides/Sectionlist/SectionListTabsStyleOverrides';

const hOverrides = css`
  font-family: pt-serif-pro, serif;
  font-weight: 600;
  color: #000;
`;

const h2OverridesNoSpacing = css`
  ${hOverrides}
  font-size: ${rem(27)};
  line-height: ${rem(32.4)};
`;

const h2Overrides = css`
  ${h2OverridesNoSpacing}
  margin-bottom: ${rem(32)};
  ${mb('s')} {
    margin-bottom: ${rem(40)};
  }
`;

export default styled.div`
  .content-widgets {
    .content-widget {
      margin: ${rem(48)} 0;
      ${mb('s')} {
        margin: ${rem(56)} 0;
      }
    }
    .js-advm-provider-dfp.admngr-overflow-hidden {
      margin-bottom: ${em(-27)};
    }
    .content-widget-header {
      ${h2Overrides}
      h2 {
        ${h2OverridesNoSpacing}
      }
    }
    .content-text {
      h2 {
        ${h2Overrides}
      }
      h3,
      h4,
      h5,
      h6 {
        ${hOverrides}
        margin-bottom: ${em(19)};
        ${mb('s')} {
          margin-bottom: ${em(23.7)};
        }
      }

      hr {
        height: 1px;
        border: 0;
        background: #dddddd;
      }

      a {
        ${LinkStyles}
      }

      a.button {
        max-width: max-content;
        font-size: ${rem(13)};
        font-family: ${({ theme }) => theme.primaryFont.family};
        color: rgb(51, 51, 51);
        font-weight: 600;
        text-decoration: none;
        outline: none;
        background: none white;
        word-break: break-word;
        line-height: ${rem(16)};
        padding: ${rem(4)} ${rem(8)};
        min-height: ${rem(2)};
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        vertical-align: top;
        box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px;
        border: 1px solid rgb(221, 221, 221);
        border-radius: ${rem(4)};
        cursor: pointer;
      }

      strong {
        color: #000;
        font-weight: bold;
      }

      a strong {
        color: inherit;
      }

      p {
        margin-top: ${em(27)};
        margin-bottom: ${em(27)};
      }
    }
    .content-widget-textBlock {
      .content-text > *:first-child {
        margin-top: 0;
      }
      .content-text > *:last-child {
        margin-bottom: 0;
      }
    }

    .widget-multiblock-wrapper {
      margin: ${rem(-16)};
      .widget-multiblock-cellContent {
        margin: ${rem(16)};
      }
    }

    .content-widget-matchOverview-teamLogo {
      border-radius: 50%;
    }

    .content-widget-matchDetail-teamLogo {
      border-radius: 50%;
    }

    .content-widget-matchesList {
      font-family: ${({ theme }) => theme.primaryFont.family};
    }

    .content-widget-matchDetail {
      font-family: ${({ theme }) => theme.primaryFont.family};
      h3,
      h4 {
        font-size: ${rem(16)};
        font-weight: 600;
        padding: 0;
      }
      .event-description {
        color: #666;
        font-size: ${rem(12)};
        font-weight: 500;
      }
    }

    .content-widget-matchOverview {
      font-family: ${({ theme }) => theme.primaryFont.family};
    }

    .content-widget-table {
      a {
        ${LinkStyles}
      }
    }

    ${SectionListTabsStyleOverrides}
  }
`;
