class InternalServerError extends Error {
  identifier: string;
  error: any;
  constructor(e: any) {
    super('INTERNAL_SERVER_ERROR');
    this.error = e;
    this.identifier = 'INTERNAL_SERVER_ERROR';
  }
}

export default InternalServerError;
