import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import { MediaManagerPhoto } from '../../../library/Sportnet';
import styled from '../../../theme/styled-components';
import AuthorSocialLinks from './socialLinks';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkedImage = styled(Link)`
  flex-shrink: 0;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  &,
  & img {
    border-radius: 50%;
    height: ${rem(48)};
    margin-right: ${rem(16)};
    ${mb('s')} {
      height: ${rem(80)};
      margin-right: ${rem(24)};
    }
    flex-shrink: 0;
  }
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  word-break: break-word;
`;

const Name = styled.span`
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`;
const Perex = styled.span`
  display: none;
  ${mb('s')} {
    display: inline;
  }
`;
const Position = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-size: 90%;
  margin-top: -${rem(8)};
`;

const SocialLinksWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: flex;
  }
  flex-wrap: wrap;
  margin: ${rem(8)} ${rem(-12)} ${rem(-8)} ${rem(-12)};
`;

type IOwnProps = {
  displayName: string;
  facebook: string;
  instagram: string;
  perex: string;
  twitter: string;
  photo: MediaManagerPhoto | null;
  className?: string;
  authorUrl?: string;
  position?: string;
};

type IProps = IOwnProps;

const Author: React.FC<IProps> = ({
  displayName,
  facebook,
  instagram,
  perex,
  twitter,
  photo,
  className,
  authorUrl,
  position,
}) => {
  return (
    <Wrapper className={className}>
      {photo &&
        (authorUrl ? (
          <LinkedImage to={authorUrl} title={displayName}>
            <StyledMediaManagerImage
              picture={photo}
              naturalHeight={80 * 2}
              naturalWidth={80 * 2}
              script="media"
              webp={true}
              alt={displayName}
              loading="lazy"
            />
          </LinkedImage>
        ) : (
          <StyledMediaManagerImage
            picture={photo}
            naturalHeight={80 * 2}
            naturalWidth={80 * 2}
            script="media"
            webp={true}
            alt={displayName}
            loading="lazy"
          />
        ))}
      <Description>
        {position && <Position>{position}</Position>}
        {authorUrl ? (
          <Link to={authorUrl}>
            <Name>{displayName}</Name>
          </Link>
        ) : (
          <Name>{displayName}</Name>
        )}

        <Perex>
          {` `}
          {perex}
        </Perex>

        <AuthorSocialLinks
          wrapperComponent={SocialLinksWrapper}
          {...{ facebook, twitter, instagram, displayName }}
        />
      </Description>
    </Wrapper>
  );
};

export default Author;
