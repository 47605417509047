import React from 'react';

export default (size: string, color: string) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 20 19.9">
      <path
        fill={color}
        d="M20,10c0-5.5-4.5-10-10-10S0,4.5,0,10c0,4.9,3.6,9.1,8.4,9.9v-7H5.9V10h2.5V7.8c-0.2-1.9,1.2-3.7,3.1-3.9c0.2,0,0.4,0,0.6,0
	c0.8,0,1.5,0.1,2.2,0.2v2.5h-1.3c-0.8-0.1-1.5,0.5-1.6,1.2c0,0.1,0,0.2,0,0.3V10h2.8l-0.4,2.9h-2.3v7C16.4,19.1,20,14.9,20,10z"
      />
    </svg>
  );
};
