import React from 'react';
import styled from 'styled-components';

const LogoImg = styled.img<{ $height: number }>`
  height: ${({ $height }) => `${$height}cm`};
`;

interface OwnProps {
  height: number;
  src: string;
  alt: string;
}

const PrintLogoImage: React.FC<OwnProps> = ({ height, src, alt }) => {
  return <LogoImg $height={height} src={src} alt={alt} />;
};

export default PrintLogoImage;
