import React from 'react';
import { IAdParams, IAdverts } from '../../components/Ads/AdProvider';
import emptyFn from '../../utilities/emptyFn';

export interface IAdContext {
  adverts: IAdverts;
  params: IAdParams | null;
  setParams: (params: IAdParams) => void;
  resetAdverts: () => void;
}

const AdContext = React.createContext<IAdContext>({
  params: null,
  setParams: emptyFn,
  adverts: {},
  resetAdverts: emptyFn,
});

export default AdContext;
