export const SPORT_SECTOR_SETTINGS = [
  {
    _id: "futbal",
    items: [
      {
        _id: "competitionPartSettings",
        value: {
          displaySeconds: false,
          overlapTime: true,
          reverseTime: false,
          contumation: { score: 3 },
          players: { field: { min: 7, max: 11 }, substitutes: { max: 12 } },
        },
      },
    ],
  },
  {
    _id: "beachfutbal",
    items: [
      {
        _id: "competitionPartSettings",
        value: {
          displaySeconds: false,
          overlapTime: true,
          reverseTime: false,
          contumation: { score: 3 },
          players: {
            field: { min: 5, max: 5 },
            substitutes: { min: 3, max: 10 },
          },
        },
      },
    ],
  },
];
