import { mb } from '@sportnet/ui/lib/Themes/utilities';
import * as React from 'react';
import { connect } from 'react-redux';
import { useAsyncData } from 'ssr-service';
import styled from 'styled-components';
import BodyAd from '../../components/Ads/BodyAd';
import ListBodyRespAd from '../../components/Ads/ListBodyRespAd';
import SideAd from '../../components/Ads/SideAd';
import FutbalnetHomeTopArticlesGrid from '../../components/Articles/FutbalnetHomeTopArticlesGrid';
import {
  ContextMarginBigTopStyles,
  ContextMarginBottomStyles,
} from '../../components/Layout';
import {
  BottomPaddedSidebarLayout,
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import config from '../../config';
import { RootState } from '../../configureStore';
import FavoriteCompetitionsBlock from '../../containers/FavoriteCompetitionsBlock';
import ListingNewsFeedArticles from '../../containers/NewsFeedArticles/ListingNewsFeedArticles';
import TopArticlesSidebar from '../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../hooks/useTopArticlesSidebar';
import { IArticle } from '../../library/App';
import { SectionSettings } from '../../library/SectionSettings';
import {
  cumulatedListResultsSelector,
  getSportnetMainMenuSectionByUiniqId,
  staticContentSelector,
} from '../App/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import {
  sectionsEntitiesSelector,
} from '../Section/selectors';
import CustomBanner from './CustomBanner';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { initializeOrSetListParams, loadStaticContent } from '../App/actions';
import StaticInfoSidebar from '../Section/pages/MainSection/StaticInfoSidebar';

const ResponsiveTopArticlesSidebar = styled(TopArticlesSidebar)`
  ${ContextMarginBigTopStyles}
  ${mb('l')} {
    display: none;
  }
`;

interface IOwnProps {
  onLoadMoreArticlesWithOffset: (offset: number) => void;
}

const PositionedFutbalnetHomeTopArticlesGrid = styled(
  FutbalnetHomeTopArticlesGrid,
)`
  ${ContextMarginBottomStyles}
`;

const StyledBodyAd = styled(BodyAd)`
  && {
    ${ContextMarginBottomStyles};
  }
`;

const mapStateToProps = (state: RootState) => {
  const section = getSportnetMainMenuSectionByUiniqId(state, { sectionIdOrUniqId: 'Futbalnet' });
  const appSettings = appSettingsSelector(state);
  const appSpace = appSettings.CSMAppSpace || '';
  const { settings = {} } = (section as any) ?? {};
  const { SIDEBAR_STATIC_CONTENT_ID } = settings;
  return {
    appSpace,
    section,
    sectionsById: sectionsEntitiesSelector(state),
    mainArticles: (cumulatedListResultsSelector(
      state,
      config.LIST_FUTBALNET_HOMEPAGE_ARTICLES,
    ) || []) as IArticle[],
    staticInfoContent:
      staticContentSelector(state, { appSpace, cid: SIDEBAR_STATIC_CONTENT_ID })
        ?.content || [],
  };
};

const mapDispatchToProps = {
  loadStaticContent: thunkToAction(loadStaticContent.action),
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
};

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const FutbalnetHomeDetail: React.FC<Props> = ({
  appSpace,
  section,
  mainArticles,
  sectionsById,
  staticInfoContent,
  loadStaticContent,

  onLoadMoreArticlesWithOffset,
}) => {
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  const { settings = {} } = (section ?? {}) as { settings?: SectionSettings };
  const { SIDEBAR_STATIC_CONTENT_ID } = settings ?? {};

  useAsyncData(async () => {
    if (!SIDEBAR_STATIC_CONTENT_ID) {
      return;
    }
    try {
      await loadStaticContent({ appSpace, cid: SIDEBAR_STATIC_CONTENT_ID });
    } catch (e: any) {
      //
    }
  }, [loadStaticContent, SIDEBAR_STATIC_CONTENT_ID, appSpace]);

  return (
    <>
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <CustomBanner
            validFrom={new Date('2021-04-01T00:00:00+0200')}
            validTo={new Date('2021-06-01')}
            linkTo={'https://mcdonaldscup.sk/'}
            src={'/mcdcup_Banner_1736x240_.jpg'}
            srcset={`/mcdcup_Banner_640x100-2.jpg 640w, /mcdcup_Banner_1736x240_.jpg 1736w`}
            sizes={'(max-width: 640px) 320px, 868px'}
            alt={"Jednoduché tréningové videá pre deti - McDonald's Cup"}
          />
          <PositionedFutbalnetHomeTopArticlesGrid
            articles={mainArticles}
            sectionsById={sectionsById}
          >
            <ListBodyRespAd name="list_body_1" />
          </PositionedFutbalnetHomeTopArticlesGrid>
          <FavoriteCompetitionsBlock />
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <TopArticlesSidebar
              activeTab={activeTabTopArticles}
              setActiveTab={setActiveTabTopArticles}
            />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      {/* <FeaturedVideoArticles /> */}

      <StyledBodyAd name="body_1" />

      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <ListingNewsFeedArticles
            listName={config.LIST_FUTBALNET_HOMEPAGE_ARTICLES}
            onLoadMoreWithOffset={onLoadMoreArticlesWithOffset}
            widthAd
            forceListOffset={5}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <StaticInfoSidebar content={staticInfoContent} />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(FutbalnetHomeDetail));
