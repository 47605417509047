import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../configureStore';
import isBrowser from '../../utilities/isBrowser';
import scribe from '../../utilities/scribe';
import { useQuery } from '@tanstack/react-query';

type IOwnProps = {
  discussionType?: string;
  discussionId?: number;
  onlyTopNewest?: boolean;
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const Comments: React.FC<IProps> = ({
  discussionType,
  discussionId,
  onlyTopNewest,
}) => {
  // staci ze je to resolvnute ak ked je error
  const { data: SmeUserLibData } = useQuery<
    unknown,
    unknown,
    { id?: number; error?: string } | null
  >(['Sme.UserLib.User'], () => null, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  React.useEffect(() => {
    if (discussionType !== 'petitpress' || !discussionId || !SmeUserLibData) {
      return;
    }

    if (!isBrowser() || !(window as any).petitpressDiscussionInstalled) {
      const timeoutID = window.setTimeout(() => {
        scribe(
          'body',
          [
            `<script src="${process.env.REACT_APP_ANZU_FORUM_HOST}/pub/forum-widget/script/${process.env.REACT_APP_ANZU_FORUM_UUID}" defer></script>`,
            '<script>window.petitpressDiscussionInstalled = true;</script>',
          ].join(''),
          'append',
        );
      });
      return () => {
        window.clearTimeout(timeoutID);
      };
    } else if (
      isBrowser() &&
      (window as any).anzu &&
      (window as any).anzu.forum
    ) {
      // reinicializacia
      const timeoutID = window.setTimeout(() => {
        try {
          (window as any).anzu.forum.initializeWidget();
        } catch (e: any) {
          console.error('Anzu reinitialize error', e);
        }
      }, 500);
      return () => {
        window.clearTimeout(timeoutID);
      };
    }
    return;
  }, [discussionType, discussionId, SmeUserLibData]);

  if (discussionType === 'petitpress' && discussionId) {
    const anzuProps: any = {};
    if (onlyTopNewest) {
      anzuProps['data-only-top-newest'] = true;
    } else {
      anzuProps['data-show-profile-crossroads'] = true;
    }
    return (
      <>
        <div
          id="anzu-forum-widget"
          data-topic-id={discussionId}
          data-post-date-link-disabled
          data-login-banner-fallback-disabled
          data-advert-slot-prefix-name="contentBody"
          data-scroll-to-post-offset-callback="window.smeForumMessageHandler.scrollToPostOffset"
          {...anzuProps}
        />
        <div
          id="forum-campaign-placeholder-top-teaser"
          className="is-hidden"
        ></div>
        <div
          id="forum-campaign-placeholder-post-teaser"
          className="is-hidden"
        ></div>
      </>
    );
  }
  return null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
