import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import React from 'react';
import UrlContext from '../../../../contexts/UrlContext';
import { IArticle, ISection } from '../../../../library/App';
import styled from '../../../../theme/styled-components';
import distanceInWordsToNow from '../../../../utilities/distanceInWordsToNow';
import Icon from '../../../Icon';
import Link from '../../../Link';
import ArticleImage from '../../ArticleImage';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
  grid-column-gap: ${rem(24)};
  column-gap: ${rem(24)};

  ${mb('s')} {
    font-size: ${rem(14)};
  }
  ${mb('l')} {
    grid-template-columns: 2fr 3fr;
    font-family: ${({ theme }) => theme.primaryFont.family };
    font-weight: 400;
    font-size: ${rem(18)};
  }
`;

const ResponsiveArticleImage = styled(ArticleImage)`
  padding-top: ${100 / (16 / 9)}% !important;
  margin-bottom: ${rem(16)};
  ${mb('s')} {
    padding-top: ${100 / (4 / 3)}% !important;
  }
`;

const WrapperArticleDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArticleLink = styled(Link)`
  font-family: pt-serif-pro, serif;
  font-weight: 400;
  border: none;
  text-decoration: none;
  outline: none;
  line-height: ${rem(24)};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
  font-size: ${rem(16)};
  ${mb('s')} {
    font-size: ${rem(20)};
  }
`;

const Description = styled.span`
  margin: 0 0 ${rem(8)} 0;
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-size: ${rem(12)};
  line-height: ${rem(17)};
`;

const Pipe = styled.span`
  margin: 0 ${rem(12)};
  opacity: 0.5;
`;

const SectionLink = styled(Link)`
  border: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const SpacedIcon = styled(Icon)`
  margin-right: ${em(4)};
`;

type IOwnProps = {
  article: IArticle;
  section: ISection | null;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article, section }) => {
  const { getArticleUrl, getSectionUrl } = React.useContext(UrlContext);

  const articleDate = new Date(
    article.updated_at || article.valid_from || article.created || '',
  );

  return (
    <Wrapper>
      <ArticleLink to={getArticleUrl(article)}>
        <ResponsiveArticleImage
          article={article}
          naturalWidth={264 * 2}
          naturalHeight={198 * 2}
        />
      </ArticleLink>
      <WrapperArticleDescription>
        <Description>
          {section && (
            <>
              <SectionLink to={getSectionUrl(section)}>
                {section.name}
              </SectionLink>
              <Pipe>|</Pipe>
            </>
          )}
          {distanceInWordsToNow(articleDate)}
        </Description>
        <ArticleLink to={getArticleUrl(article)}>
          {article.is_private && <SpacedIcon name="premium" size={12} />}
          {article.name}
        </ArticleLink>
      </WrapperArticleDescription>
    </Wrapper>
  );
};

export default React.memo(Article);
