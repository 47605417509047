import { rem } from 'polished';
import React from 'react';
import Link from '../../components/Link';
import { ICompetition } from '../../library/Competitions';
import styled from '../../theme/styled-components';
import getCompetitionBaseUrlByShortName from '../../utilities/getCompetitionBaseUrlByShortName';
import getUnionBaseUrlByShortName from '../../utilities/getUnionBaseUrlByShortName';
import getUrlFromAppSpace from '../../utilities/getUrlFromAppSpace';
import __ from '../../utilities/__';

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  overflow-wrap: break-word;
`;

const UnderlineLink = styled(Link)`
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

const UnionLink = styled(UnderlineLink)`
  color: ${({ theme }) => theme.color.fadedText};
  padding-left: ${rem(8)};
  text-transform: uppercase;
`;

const Header = styled.span`
  margin-bottom: ${rem(8)};
`;

const Description = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

type IOwnProps = {
  competition: ICompetition;
};

type IProps = IOwnProps;

const Competition: React.FC<IProps> = ({ competition }) => {
  const appSpaceShortName = React.useMemo(
    () => getUrlFromAppSpace('union', competition.appSpace) || '',
    [competition.appSpace],
  );

  const url = getCompetitionBaseUrlByShortName(
    appSpaceShortName,
    competition.__issfId || competition.competitionGroupId,
  );

  return (
    <Wrapper>
      <Header>
        <UnderlineLink to={url}>{competition.name}</UnderlineLink>
        <UnionLink to={getUnionBaseUrlByShortName(appSpaceShortName)}>
          {appSpaceShortName}
        </UnionLink>
      </Header>
      <Description>
        <UnderlineLink to={`${url}tabulky/`}>{__('Tabuľka')}</UnderlineLink>
        {` | `}
        <UnderlineLink to={`${url}vyhodnotenie/`}>
          {__('Výsledky')}
        </UnderlineLink>
        {` | `}
        <UnderlineLink to={`${url}program/`}>{__('Program')}</UnderlineLink>
      </Description>
    </Wrapper>
  );
};

export default Competition;
