import { parseToRgb } from 'polished';

export default function (color: string = '', fallbackColor = '#fff') {
  try {
    parseToRgb(color);
    return color;
  } catch (e: any) {
    return fallbackColor;
  }
}
