import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import CompetitionsApi from '../../../../api/CompetitionsApi';
import {
  ICompetition,
  ICompetitionPart,
} from '../../../../library/Competitions';

interface LoadCompetitionPartWithResultsTableParams {
  appSpace: string;
  competitionId: string | number;
  competitionPartId: string;
}
const loadCompetitionPartWithResultsTable = createQueryFn(
  QueryNameSpace.competitionPartWithResultsTable,
  async ({
    queryKey,
  }: QueryFunctionContext<
    [string] | [string, LoadCompetitionPartWithResultsTableParams]
  >) => {
    const [, parameters] = queryKey;

    if (
      !parameters ||
      !parameters.competitionId ||
      !parameters.competitionPartId
    ) {
      throw new Error(
        'Must provide required parameters [appSpace, competitionId, competitionPartId]!',
      );
    }

    const { appSpace, competitionId, competitionPartId } = parameters;

    // nacitame sutaz s castami sutaze
    const competition = (await CompetitionsApi.getCompetitionById(
      appSpace,
      String(competitionId),
    )) as ICompetition;

    // v ramci sutaze hladame cast podla ID (mozno usetrime jeden request)
    const competitionPartExists = competition.parts?.find(
      (part) => part._id === competitionPartId,
    );

    if (!competitionPartExists) {
      throw new Error(`COMPETITION_PART_NOT_FOUND`);
    }

    // nacitame cast sutaze (kvoli teamom atd.)
    const competitionPart = (await CompetitionsApi.getCompetitionPartById(
      appSpace,
      String(competitionId),
      competitionPartId,
    )) as ICompetitionPart;

    // nacitame vysledkovu tabulku
    const resultsTable =
      await CompetitionsApi.getPublicCompetitionPartCurrentResultTable(
        String(competitionId),
        competitionPartId,
      );

    return {
      _id: competitionPart._id,
      name: competitionPart.name,
      teams: competitionPart.teams || [],
      rules: competitionPart.rules,
      publicComment: competitionPart.publicComment,
      resultsTable,
      competition: {
        _id: competition._id,
        name: competition.name,
        season: competition.season,
      },
    };
  },
);

export default loadCompetitionPartWithResultsTable;
