import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9
    C21,7,17,3,12,3z M12.2,13.9c0.4-0.1,3.7-1.3,3.7-3.9c0-1.7-1.1-3.2-2.7-3.8c-2.1-0.7-4.4,0.4-5.1,2.4C8,9.2,8.2,9.8,8.8,9.9
    c0.5,0.2,1.1-0.1,1.3-0.6c0.4-1,1.5-1.6,2.6-1.2c0.8,0.3,1.3,1,1.3,1.9c0,1-1.7,1.8-2.3,2.1c-0.5,0.2-0.8,0.7-0.6,1.3
    c0.1,0.4,0.5,0.7,0.9,0.7C12,14,12.1,14,12.2,13.9z M13,17c0-0.6-0.4-1-1-1h0c-0.6,0-1,0.4-1,1s0.5,1,1,1S13,17.6,13,17z"
    />
  </svg>
);
