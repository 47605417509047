import PrintLogoImage from '../Logos/PrintLogoImage';
import React from 'react';

const FooterLogos: React.FC = () => {
  return (
    <>
      <PrintLogoImage
        src="/futbalnet/images/futbalnet.svg"
        height={0.65}
        alt="Futbalnet logo"
      />
      <PrintLogoImage
        src="/futbalnet/images/sportnet-logo.svg"
        height={0.75}
        alt="Sportnet logo"
      />
      <PrintLogoImage
        src="/futbalnet/images/logo-sfz.svg"
        height={1}
        alt="SFZ logo"
      />
    </>
  );
};

export default FooterLogos;
