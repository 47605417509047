import * as React from 'react';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import icons from './icons';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
`;

export type IIconName = keyof typeof icons;
export type ISize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | number;

export interface OwnProps {
  size?: ISize | number;
  color?: string;
  className?: string;
  name: IIconName;
  style?: React.CSSProperties;
}

const defaultProps = {
  size: 'm',
};

type Props = OwnProps & { theme: ITheme };

class Icon extends React.PureComponent<Props> {
  static defaultProps = defaultProps;
  sizeToPx(size: ISize) {
    const sizeMapping = {
      xs: 8,
      s: 12,
      m: 16,
      l: 24,
      xl: 40,
      xxl: 110,
    };
    return typeof size === 'string' && size in sizeMapping
      ? sizeMapping[size]
      : sizeMapping.m;
  }

  /**
   * Method resolves deprecated names for icons
   */
  getName(nameFromProps: string | IIconName): IIconName {
    switch (nameFromProps) {
      case 'remove-smarttag':
        return 'close';
      case 'arrow-down-small':
      case 'chevron':
        return 'arrow-down';
      case 'arrow':
        return 'arrow-right';
      default:
        return nameFromProps as IIconName;
    }
  }

  render() {
    const {
      size,
      color,
      className,
      name: nameFromProps,
      ...restProps
    } = this.props;

    const name: IIconName = this.getName(nameFromProps);

    const iconSize = typeof size === 'string' ? this.sizeToPx(size!) : size;
    const iconColor = String(
      this.props.color ? color : this.props.theme.textColor,
    );
    return (
      <Wrapper className={`icon ${className || ''}`} {...restProps}>
        {name in icons ? icons[name](iconSize as never, iconColor) : null}
      </Wrapper>
    );
  }
}

export default withTheme(Icon);
export { default as icons } from './icons';
