import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../theme/styled-components';
import { IDropDownPosition } from './library';

interface OwnProps {
  body: React.ReactNode;
  opened: boolean;
  onClose: () => void;
  className?: string;
  position?: IDropDownPosition;
}

const Wrapper = styled.div`
  position: relative;
`;

const DropdownBody = styled.div<{
  position: IDropDownPosition;
  opened: boolean;
}>`
  position: absolute;
  top: calc(100% - ${rem(4)});
  ${({ position }) =>
    position === 'left'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  z-index: 20000;
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  flex-direction: column;
  background: white;
  border: ${rem(1)} solid ${({theme}) => theme.color.lightBorder};
  box-shadow: 0 ${rem(2)} ${rem(8)} rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
  padding: ${rem(8)} 0;
  min-width: ${rem(200)};
  max-width: ${rem(200)};
  max-height: ${rem(270)};
  overflow-y: auto;
`;

const Dropdown: React.FC<OwnProps> = ({
  className,
  opened,
  onClose,
  children,
  body,
  position = 'right',
}) => {
  const wrapperEl = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!opened) {
      return;
    }
    const handleClickOutside = (e: MouseEvent) => {
      if (!wrapperEl.current?.contains(e.target as Node)) {
        onClose();
      }
    };
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [opened, onClose]);

  return (
    <Wrapper className={className} ref={wrapperEl}>
      {children}
      <DropdownBody
        className="dropdown-body"
        position={position}
        opened={opened}
      >
        {body}
      </DropdownBody>
    </Wrapper>
  );
};

export default Dropdown;
