import { IWidgetExternalArticle } from '@sportnet/content/utilities/library';
import React from 'react';
import __ from '../../../../utilities/__';
import RelatedArticle from '../../RelatedArticle';

type IData = Omit<IWidgetExternalArticle, 'type'>;

interface IOwnProps {
  data?: IData;
  className?: string;
}

type IProps = IOwnProps;

const ExternalArticle: React.FC<IProps> = ({ data, className }) => {
  // Ak `widgetTitle` nepride z API (je undefined), renderujem default text. Ak uzivatel v nastaveniach
  // widgetu setne prazdny string, nerenderujem tuto hlavicku vobec (vid. JSX). V ostatnych pripadoch
  // renderujem co ma widget nastavene.
  const widgetTitle =
    data?.widgetTitle !== undefined
      ? data?.widgetTitle
      : __('Súvisiaci článok');

  return (
    <RelatedArticle
      widgetTitle={widgetTitle}
      articleName={data?.name || ''}
      url={data?.target || ''}
      imageSrc={data?.image || ''}
    />
  );
};

export default React.memo(ExternalArticle);
