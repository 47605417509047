// import Image from '@sportnet/ui/lib/Image';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { getListResults } from '@sportnet/redux-list';
import Link from '../../components/Link';
import config, { COMPETITION_PANEL_SETTINGS } from '../../config';
import { RootState } from '../../configureStore';
import UrlContext, { UrlContextDefaultValue } from '../../contexts/UrlContext';
import useMatchesPanel from '../../hooks/useMatchesPanel';
import { ISection } from '../../library/App';
import { competitionPanelMenuSelector } from '../../pages/App/selectors';
import { panelMatchesSelector } from '../../pages/Competition/selectors';
import { currentSectionSelector } from '../../pages/Section/selectors';
import styled from '../../theme/styled-components';
import MatchesPanelWrapper from './MatchesPanelWrapper';
import MatchesSlider from './MatchesSlider';
import PromoStrip from './PromoStrip';
import MainEventTopPanel from './MainEventTopPanel';
import TertiaryButton from '../../sportnet.sme.sk-ui/TertiaryButton';
import SectionsDropown from './SectionsDropdown';
import SectionBlockIcon from '../../components/v2/SectionBlockIcon';
import getBaseUrl from '../../utilities/getBaseUrl';
import getSectionUrl from '../../utilities/getSectionUrl';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mb('m')} {
    flex-direction: row;
  }
`;

const Description = styled.div<{ hasTopEventPanel: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-shrink: 0;
  padding: ${rem(12)} ${rem(16)};
  border-bottom: ${({ theme }) => theme.border.normalLight};
  ${mb('s')} {
    padding: 0 ${rem(16)} 0 0;
    justify-content: center;
    flex-direction: column;
    align-items: start;
    border-bottom: none;
  }
`;

const ChildSectionsMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(4)};
  flex-shrink: 0;
`;

const SectionLink = styled(Link)`
  flex: 1 0 auto;
  color: ${({ theme }) => theme.color.primaryFontColor};
  text-decoration: underline;
  font-size: ${rem(12)};
  line-height: ${rem(15.6)};
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  :focus,
  :active,
  :hover {
    text-decoration: underline;
  }
  margin-right: ${rem(16)};
`;

const MainSectionLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryFontColor};
  font-size: ${rem(12)};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  :focus,
  :active,
  :hover {
    text-decoration: underline;
  }
  ${mb('s')} {
    margin-bottom: ${rem(12)};
  }
`;

const SectionIconWrapper = styled.div`
  margin-right: ${rem(8)};
  flex-shrink: 0;
`;

const MatchesSliderWrapper = styled.div<{ hasTopEventPanel: boolean }>`
  > div {
    height: 100%;
  }
`;

type IOwnProps = {
  className?: string;
  currentSection?: ISection | null;
};

const mapStateToProps = (state: RootState, props: IOwnProps) => {
  const section =
    props.currentSection || (currentSectionSelector(state) as ISection);
  return {
    section,
    matches: panelMatchesSelector(state),
    isLoading: !getListResults(config.PANEL_COMPETITION_MATCHES)(state),
    contentSection: competitionPanelMenuSelector(state),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const CompetitionPanel: React.FC<IProps> = ({
  section,
  className,
  currentSection = section,
  isLoading,
  matches,
  contentSection,
}) => {
  useMatchesPanel(currentSection?.settings?.MATCHES_PANEL_COMPETITION);

  if (!currentSection?.settings?.MATCHES_PANEL_COMPETITION) {
    return null;
  }

  const visibleSections = (contentSection?.sections ?? []).slice(0, 2);
  const dropdownSection = (contentSection?.sections ?? []).slice(2);

  const {
    MATCHES_PANEL_COMPETITION,
    MATCHES_PANEL_MAINEVENT_LOGO = '',
    MATCHES_PANEL_MAINEVENT_LOGO_ALT = '',
    MATCHES_PANEL_MAINEVENT_COMPETITION = '',
    MATCHES_PANEL_MAINEVENT_ROOT_SECTION = '',
    MATCHES_PANEL_PROMO_STRIP_TEXT = '',
    MATCHES_PANEL_PROMO_STRIP_TEXT_MOBILE = '',
    MATCHES_PANEL_PROMO_STRIP_LOGO_SRC = '',
    MATCHES_PANEL_PROMO_STRIP_LOGO_MARGIN_RIGHT,
    MATCHES_PANEL_PROMO_STRIP_BG_COLOR = '',
    MATCHES_PANEL_PROMO_STRIP_COLOR = '',
    MATCHES_PANEL_PROMO_STRIP_TARGET_URL = '',
  } = currentSection?.settings || {};

  return (
    <>
      <MatchesPanelWrapper isLoading={isLoading} className={className}>
        {MATCHES_PANEL_MAINEVENT_ROOT_SECTION && (
          <MainEventTopPanel
            logoSrc={MATCHES_PANEL_MAINEVENT_LOGO}
            logoAlt={MATCHES_PANEL_MAINEVENT_LOGO_ALT}
            competitionId={MATCHES_PANEL_MAINEVENT_COMPETITION}
            rootSectionId={MATCHES_PANEL_MAINEVENT_ROOT_SECTION}
            currentSection={section}
          />
        )}

        <LayoutWrapper>
          {contentSection && (
            <Description
              hasTopEventPanel={!!MATCHES_PANEL_MAINEVENT_ROOT_SECTION}
            >
              <MainSectionLink
                to={getSectionUrl(getBaseUrl(), contentSection as ISection)}
              >
                {contentSection.icon && (
                  <SectionIconWrapper>
                    <SectionBlockIcon
                      icon={contentSection.icon}
                      alt={contentSection.name!}
                      size={24}
                    />
                  </SectionIconWrapper>
                )}
                {contentSection.name}
              </MainSectionLink>
              <ChildSectionsMenuWrapper>
                {visibleSections.map((s: ISection) => (
                  <TertiaryButton
                    key={`x-compatitionPanel-section-${s?._id}`}
                    href={getSectionUrl(getBaseUrl(), s as ISection)}
                    size="mobile"
                  >
                    {s.name}
                  </TertiaryButton>
                ))}

                <SectionsDropown sections={dropdownSection} />
                {MATCHES_PANEL_COMPETITION ===
                  'international-olympic-committee:61f7e2cffe9c183414c8e6f2' && (
                  <SectionLink href="/olympijske-hry/zimna-olympiada/hokej/">
                    Hokej na ZOH 2022
                  </SectionLink>
                )}
              </ChildSectionsMenuWrapper>
            </Description>
          )}
          <MatchesSliderWrapper
            hasTopEventPanel={!!MATCHES_PANEL_MAINEVENT_ROOT_SECTION}
          >
            <MatchesSlider matches={matches || []} />
          </MatchesSliderWrapper>
        </LayoutWrapper>
      </MatchesPanelWrapper>
      {MATCHES_PANEL_PROMO_STRIP_TEXT && (
        <PromoStrip
          backgroundColor={MATCHES_PANEL_PROMO_STRIP_BG_COLOR}
          color={MATCHES_PANEL_PROMO_STRIP_COLOR}
          text={MATCHES_PANEL_PROMO_STRIP_TEXT}
          textMobile={MATCHES_PANEL_PROMO_STRIP_TEXT_MOBILE}
          logoSrc={MATCHES_PANEL_PROMO_STRIP_LOGO_SRC}
          url={MATCHES_PANEL_PROMO_STRIP_TARGET_URL}
          logoMarginRight={
            !isNaN(Number(MATCHES_PANEL_PROMO_STRIP_LOGO_MARGIN_RIGHT))
              ? Number(MATCHES_PANEL_PROMO_STRIP_LOGO_MARGIN_RIGHT)
              : undefined
          }
        />
      )}
    </>
  );
};

const DefaultExport = connect(mapStateToProps)(CompetitionPanel);
export default DefaultExport;

export const CompetitionPanelDefault = () => {
  return (
    <UrlContext.Provider value={UrlContextDefaultValue}>
      <DefaultExport
        currentSection={{
          name: 'Domov',
          _id:
            process.env.REACT_APP_APPSPACE === 'beta.sportnet.sme.sk'
              ? 8077
              : 4509,
          settings: COMPETITION_PANEL_SETTINGS
          
        }}
      />
    </UrlContext.Provider>
  );
};
