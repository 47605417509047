import {
  addWeeks,
  compareAsc,
  isBefore,
  isSameDay,
  isToday,
  isTomorrow,
  nextFriday,
  nextMonday,
  nextSaturday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
  isYesterday,
} from 'date-fns';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import differenceInCalendarISOWeeks from 'date-fns/differenceInCalendarISOWeeks';
import endOfDay from 'date-fns/endOfDay';
import set from 'date-fns/set';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import __ from '../__';
import { deserializeQuery, serializeQuery } from '@sportnet/query-hoc/useQuery';
import { TvProgramEvent } from '../../library/TvProgram';
import format from '../formatDate';
import differenceInMinutes from 'date-fns/differenceInMinutes';

const HOURS_OF_NEXT_DAY = 7;
export const MAX_DAYS_TO_FUTURE = 21;
const MAX_DAYS_TO_THE_PAST = 1;

export interface DateFilter {
  dateFrom: Date;
  dateTo: Date;
}

/**
 * Default datumovy filter, ak nemam v URL nic, alebo sa nepodari vyparsovat datum.
 * @returns
 */
export const getDefaultDateFilter = () => {
  const date = new Date();
  return {
    dateFrom: startOfDay(date),
    dateTo: addHours(endOfDay(date), HOURS_OF_NEXT_DAY),
  };
};

/**
 * URL slug dna v tyzdni podla poradoveho cisla.
 * @param weekday
 * @returns
 */
const getWeekdayFromNumber = (weekday: number) => {
  switch (weekday) {
    case 1: {
      return 'pondelok';
    }
    case 2: {
      return 'utorok';
    }
    case 3: {
      return 'streda';
    }
    case 4: {
      return 'stvrtok';
    }
    case 5: {
      return 'piatok';
    }
    case 6: {
      return 'sobota';
    }
    case 7: {
      return 'nedela';
    }
    default: {
      return `${weekday}`;
    }
  }
};

/**
 * Poradove cislo dna v tyzdni podla URL slugu.
 * @param weekdaySlug
 * @returns
 */
const getWeekdayNumberFromUrl = (weekdaySlug: string) => {
  switch (weekdaySlug) {
    case 'pondelok': {
      return 1;
    }
    case 'utorok': {
      return 2;
    }
    case 'streda': {
      return 3;
    }
    case 'stvrtok': {
      return 4;
    }
    case 'piatok': {
      return 5;
    }
    case 'sobota': {
      return 6;
    }
    case 'nedela': {
      return 7;
    }
    default: {
      return -1;
    }
  }
};

/**
 * Vrati najblizsi weekday podla skugu k danemu datumu
 * @param weekdaySlug Slug dna v tyzdni
 * @param date datum, ku ktoremu vrati najblizsi denv tyzdni
 * @returns
 */
export const getNearestWeekday = (weekdaySlug: string, date: Date) => {
  switch (weekdaySlug) {
    case 'pondelok': {
      return nextMonday(date);
    }
    case 'utorok': {
      return nextTuesday(date);
    }
    case 'streda': {
      return nextWednesday(date);
    }
    case 'stvrtok': {
      return nextThursday(date);
    }
    case 'piatok': {
      return nextFriday(date);
    }
    case 'sobota': {
      return nextSaturday(date);
    }
    case 'nedela': {
      return nextSunday(date);
    }
    default: {
      return null;
    }
  }
};

/**
 * Vrati datum zo vstupu typu "o dva tyzdne v pondelok"
 * @param weeNumber Cislo, o kolko tyzdov ...
 * @param weekdaySlug Slug dna v tyzdni ...
 */
const getFutureDateFilterFromUrl = (
  weekNumber: number,
  weekdaySlug: string,
) => {
  const weekdayNr = getWeekdayNumberFromUrl(weekdaySlug);
  if (weekdayNr === -1) {
    return getDefaultDateFilter();
  }
  const now = new Date();
  const nowWeekdayNr = parseInt(format(now, 'i'));
  const dateFrom = getNearestWeekday(
    weekdaySlug,
    weekdayNr <= nowWeekdayNr
      ? addWeeks(now, weekNumber - 1)
      : addWeeks(now, weekNumber),
  );
  return dateFrom
    ? { dateFrom: startOfDay(dateFrom), dateTo: endOfDay(dateFrom) }
    : getDefaultDateFilter();
};

/**
 * Vyparsuje URL string, pre ktory zobrazit program a vrati datum.
 * @param urlString Url string dva v tyzdni a za konkretny tyzdenv buducnosti.
 * @returns
 */
export const getDateFilterFromUrlString = (urlString: string) => {
  switch (urlString) {
    case 'vcera': {
      const date = subDays(new Date(), 1);
      return {
        dateFrom: startOfDay(date),
        dateTo: addHours(endOfDay(date), HOURS_OF_NEXT_DAY),
      };
    }
    case 'zajtra': {
      const date = addDays(new Date(), 1);
      return {
        dateFrom: startOfDay(date),
        dateTo: addHours(endOfDay(date), HOURS_OF_NEXT_DAY),
      };
    }
    case 'dnes': {
      return null;
    }

    default: {
      if (!urlString) {
        return getDefaultDateFilter();
      }

      // splitnem cez '-' a urcim, o kolko tyzdnov v buducnosti a pre ktory den v tyzdni
      // podla jeho nazvu vratit datum
      const splitted = urlString.split('-');

      if (splitted.length === 1) {
        const weekday = splitted[0];
        const dateFrom = getNearestWeekday(weekday, new Date());
        if (dateFrom) {
          return {
            dateFrom: startOfDay(dateFrom),
            dateTo: addHours(endOfDay(dateFrom), HOURS_OF_NEXT_DAY),
          };
        }
        return null; // nepodarilo sa vyparsovat
      }

      if (splitted.length > 2) {
        const numUrl = splitted[2];
        const numWeeks = numUrl === 'tyzden' ? 1 : parseInt(numUrl);
        const weekdaySlug = splitted[0];
        if (!isNaN(numWeeks)) {
          return getFutureDateFilterFromUrl(numWeeks, weekdaySlug);
        }
        return null; // nepodarilo sa vyparsovat
      }
      return null; // nepodarilo sa vyparsovat
    }
  }
};

/**
 * Prevedie datum (Date objekt) do URL stringu.
 * @param date
 * @returns
 */
export const getDateToUrlString = (date: Date | null) => {
  if (!date) {
    return '';
  }
  if (isToday(date)) {
    return __('');
  } else if (isYesterday(date)) {
    return __('vcera');
  } else if (isTomorrow(date)) {
    return __('zajtra');
  } else {
    const now = new Date();
    const diffWeeks = differenceInCalendarISOWeeks(date, now);
    const weekdayNum = parseInt(format(date, 'i'));
    const weekday = getWeekdayFromNumber(weekdayNum);
    if (diffWeeks === 0) {
      return weekday;
    }
    if (diffWeeks === 1) {
      return `${weekday}-o-tyzden`;
    } else {
      return `${weekday}-o-${diffWeeks}-tyzdne`;
    }
  }
};

/**
 * Vrati min a max date pre kalendar. Min date je "vcera" a max date je 3 tyzdne
 * od "dnes"
 */
export const getMinMaxDate = () => {
  const today = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return {
    minDate: subDays(today, MAX_DAYS_TO_THE_PAST),
    maxDate: endOfDay(addDays(today, MAX_DAYS_TO_FUTURE)),
  };
};

export const getNextDayUrl = (date: Date) => {
  const nextDay = addDays(date, 1);
  return getDateToUrlString(nextDay);
};

/**
 * Deserializes hash params from URL
 */
export const deserializeHashParams = <THashParams>(
  hash: string,
  defaultParams: THashParams,
) => {
  const serializedHashParams = hash
    ? hash[0] === '#'
      ? hash.slice(1)
      : hash
    : '';
  if (!serializedHashParams) {
    return defaultParams;
  }
  const deserializedHashParams = deserializeQuery(serializedHashParams);
  return {
    ...defaultParams,
    ...deserializedHashParams,
  };
};

export const serializeHashParams = <THashParams>(hashParams: THashParams) => {
  // odstranime parametre, ktore maju falsy hodnotu
  const fitleredhashParams = Object.entries(hashParams as {}).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    },
    {} as THashParams,
  );
  const serializedHashParamsRaw = serializeQuery(fitleredhashParams as { [x: string]: any; });
  const serializedHashParams =
    serializedHashParamsRaw && serializedHashParamsRaw[0] === '?'
      ? serializedHashParamsRaw.slice(1)
      : '';
  return serializedHashParams ? `#${serializedHashParams}` : '';
};

export const getNotFoundEventsDateString = (date: Date) => {
  if (isToday(date)) {
    return __('Dnes');
  } else if (isTomorrow(date)) {
    return __('Zajtra');
  } else {
    return format(date, 'd.M.');
  }
};

export const getDefaultDate = () => {
  const date = new Date();
  return startOfDay(date);
};

const getFutureDateFromUrl = (weekNumber: number, weekdaySlug: string) => {
  const weekdayNr = getWeekdayNumberFromUrl(weekdaySlug);
  if (weekdayNr === -1) {
    return getDefaultDate();
  }
  const now = new Date();
  const nowWeekdayNr = parseInt(format(now, 'i'));
  const dateFrom = getNearestWeekday(
    weekdaySlug,
    weekdayNr <= nowWeekdayNr
      ? addWeeks(now, weekNumber - 1)
      : addWeeks(now, weekNumber),
  );
  return dateFrom ? startOfDay(dateFrom) : getDefaultDate();
};

export const getDateFromUrlString = (urlString: string) => {
  switch (urlString) {
    case 'vcera': {
      const date = subDays(new Date(), 1);
      return startOfDay(date);
    }
    case 'zajtra': {
      const date = addDays(new Date(), 1);
      return startOfDay(date);
    }
    case 'dnes': {
      const date = new Date();
      return startOfDay(date);
    }

    default: {
      const date = new Date();
      if (!urlString) {
        return startOfDay(date);
      }

      // splitnem cez '-' a urcim, o kolko tyzdnov v buducnosti a pre ktory den v tyzdni
      // podla jeho nazvu vratit datum
      const splitted = urlString.split('-');

      if (splitted.length === 1) {
        const weekday = splitted[0];
        const dateFrom = getNearestWeekday(weekday, date);
        if (dateFrom) {
          return startOfDay(dateFrom);
        }
        return null; // nepodarilo sa vyparsovat
      }

      if (splitted.length > 2) {
        const numUrl = splitted[2];
        const numWeeks = numUrl === 'tyzden' ? 1 : parseInt(numUrl);
        const weekdaySlug = splitted[0];
        if (!isNaN(numWeeks)) {
          return getFutureDateFromUrl(numWeeks, weekdaySlug);
        }
        return null; // nepodarilo sa vyparsovat
      }
      return null; // nepodarilo sa vyparsovat
    }
  }
};

/**
 * Roztriedy eventy po datumoch
 */
export interface EventsByDay {
  [key: string]: { date: Date; events: TvProgramEvent[] };
}

const getDateKey = (d: Date) => {
  return startOfDay(new Date(d));
};

export const eventsByDates = (
  dateFrom: Date,
  dateTo: Date,
  allDay: boolean,
  events: TvProgramEvent[],
) => {
  const now = new Date();
  const acc: EventsByDay = {};

  for (let d = new Date(dateFrom); d < dateTo; d = addDays(d, 1)) {
    acc[format(d, 'yyyy-MM-dd')] = {
      date: getDateKey(d),
      events: [],
    };
  }

  const eventsByDate = events.reduce((acc, event) => {
    const eventDateFrom = event.dateFrom;
    const eventDateTo = event.dateTo;
    // zacali dnes
    if (isSameDay(dateFrom, eventDateFrom)) {
      // do vysledku zahrniem len tie, ktore sa zacali po aktualnom case ale aj tie, ktore prave prebiehaju
      // cize zacali sa skor ako je aktualny cas, ale koncia sa pozdejsie.
      const ident = format(dateFrom, 'yyyy-MM-dd');
      if (!(ident in acc)) {
        acc[ident] = {
          date: getDateKey(dateFrom),
          events: [],
        };
      }
      if (isToday(eventDateFrom)) {
        if (allDay || eventDateTo >= now) {
          acc[ident]?.events.push(event);
        }
      } else {
        acc[ident]?.events.push(event);
      }
      // zacali vcera ale este pokracuju (tiez rozlisujeme cely den alebo pokracuju v aktualnom case)
    } else if (
      isBefore(eventDateFrom, dateFrom) &&
      isSameDay(dateFrom, eventDateTo)
    ) {
      if (allDay || eventDateTo >= now) {
        const ident = format(dateFrom, 'yyyy-MM-dd');
        if (!(ident in acc)) {
          acc[ident] = {
            date: getDateKey(dateFrom),
            events: [],
          };
        }
        acc[ident]?.events.push(event);
      }
    } else {
      const ident = format(eventDateFrom, 'yyyy-MM-dd');
      if (!(ident in acc)) {
        acc[ident] = {
          date: getDateKey(eventDateFrom),
          events: [],
        };
      }
      acc[ident].events.push(event);
    }

    return acc;
  }, acc);

  const asArray = Object.values(eventsByDate).reduce((acc, value) => {
    acc.push(value);
    return acc;
  }, [] as { date: Date; events: TvProgramEvent[] }[]) as {
    date: Date;
    events: TvProgramEvent[];
  }[];

  return asArray.sort((a, b) => compareAsc(a.date, b.date));
};

export const getDateString = (date: Date) => {
  if (isToday(date)) {
    return __('Dnes');
  } else if (isYesterday(date)) {
    return __('Včera');
  } else if (isTomorrow(date)) {
    return __('Zajtra');
  } else {
    return format(date, 'd.M.');
  }
};

export const getDateStringEventList = (date: Date) => {
  if (isToday(date)) {
    return `${__('Dnes')} ${format(date, 'd.M.')}`;
  } else if (isYesterday(date)) {
    return `${__('Včera')} ${format(date, 'd.M.')}`;
  } else if (isTomorrow(date)) {
    return `${__('Zajtra')} ${format(date, 'd.M.')}`;
  } else {
    return format(date, 'EEEE d.M.');
  }
};

export const getIcalEventUrl = (eventId: string) => {
  return `${process.env.REACT_APP_TVPROGRAM_API_BASE_URL}/public/events/${eventId}/ical`;
};

export const shoudShowReminderLink = (dateFrom: Date) => {
  const now = new Date();
  return now < dateFrom && differenceInMinutes(dateFrom, now) > 5;
};