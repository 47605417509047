import React from 'react';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const Td = styled.td<{ textRight?: boolean }>`
  padding: ${rem(2)} ${rem(8)};
  vertical-align: middle;
  ${({ textRight = false }) =>
    textRight
      ? css`
          text-align: right;
        `
      : ''};
`;

const PlayerNameWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: ${rem(4)};
`;

interface OwnProps {
  teamSide: 'homeTeam' | 'awayTeam';
  playerName: string;
  icon: JSX.Element | null;
  minute: number;
}

const MatchDetailPrintMatchEvent: React.FC<OwnProps> = ({
  teamSide,
  playerName,
  icon,
  minute,
}) => {
  return (
    <Tr>
      {teamSide === 'homeTeam' ? (
        <>
          <Td textRight={true} width="45%">
            <PlayerNameWrapper>
              {playerName}
              {icon}
            </PlayerNameWrapper>
          </Td>
          <Td textRight={true} width="5%">
            {minute}'
          </Td>
          <Td width="5%" />
          <Td width="45%" />
        </>
      ) : (
        <>
          <Td width="45%" />
          <Td width="5%" />
          <Td width="5%">{minute}'</Td>
          <Td  width="45%">
            <PlayerNameWrapper>
              {icon}
              {playerName}
            </PlayerNameWrapper>
          </Td>
        </>
      )}
    </Tr>
  );
};

export default MatchDetailPrintMatchEvent;
