import { rem } from "polished";
import styled from "styled-components";

export const ContentContainerHeader = styled.div`
  font-weight: ${({theme}) => theme.primaryFont.weight.bold};
  font-size: ${rem(18)};
  letter-spacing: 0px;
  line-height: ${rem(24)};
  padding: ${rem(24)};
`;

export default ContentContainerHeader;