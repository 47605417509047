const scribeBrickElements = (
  placeholder: HTMLElement,
  elements: (HTMLScriptElement | HTMLStyleElement | DocumentFragment)[],
) => {
  const fragment = document.createDocumentFragment();

  elements.forEach((elements) => {
    fragment.appendChild(elements);
  });
  placeholder.after(fragment);
};

export default scribeBrickElements;
