import { rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle } from '../../../library/App';
import styled, { css } from '../../../theme/styled-components';
import distanceInWordsToNow from '../../../utilities/distanceInWordsToNow';
import Link from '../../Link';

const Wrapper = styled.li`
  display: flex;
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;
`;

interface ArticleLinkProps {
  isLast: boolean;
}
const ArticleLinkBase = ({ isLast, ...rest }: ArticleLinkProps) => (
  <Link {...rest} />
);
const ArticleLink = styled(ArticleLinkBase)<ArticleLinkProps | any>`
  font-family: pt-serif-pro, serif;
  font-size: ${rem(18)};
  line-height: ${rem(21.6)};
  border: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
  padding: ${rem(8)} 0 ${rem(32)} 0;
  ${({ isLast }) =>
    isLast &&
    css`
      padding-bottom: 0;
    `}
`;

const Circle = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: 50%;
  border: ${rem(1)} solid ${({ theme }) => theme.color.primary};
  background-color: #fff;
  margin: ${rem(4)} 0;
  flex-shrink: 0;
`;

const Line = styled.div`
  width: ${rem(1)};
  height: 100%;
  background-color: ${({ theme }) => theme.color.separator};
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${rem(12)};
  align-items: center;
  flex-shrink: 0;
`;

const DateTime = styled.span`
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  color: ${({ theme }) => theme.app.secondaryTextColor};
`;

type IOwnProps = {
  article: IArticle;
  isLast: boolean;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article, isLast }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const articleDate = new Date(
    article.updated_at || article.valid_from || article.created || '',
  );

  return (
    <Wrapper>
      <LineWrapper>
        <Circle />
        {!isLast && <Line />}
      </LineWrapper>
      <ArticleWrapper>
        <DateTime>{distanceInWordsToNow(articleDate)}</DateTime>
        <ArticleLink to={getArticleUrl(article)} isLast={isLast}>
          {article.name}
        </ArticleLink>
      </ArticleWrapper>
    </Wrapper>
  );
};

export default React.memo(Article);
