import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import debug from '../../../debug';
import useAdScriber from '../../../hooks/useAdScriber';
import styled from '../../../theme/styled-components';
import { IAdPosition } from '../AdProvider';

const DebugWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  position: relative;
  min-height: ${rem(50)};
  background-color: rgba(255, 0, 0, 0.1);
`;

const DebugName = styled.span`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  background-color: #f00;
  color: #fff;
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(11)};
  padding: ${rem(2)} ${rem(4)};
`;

const AdWrapper = styled.div`
  text-align: center;
  flex-shrink: 0;
  &:empty {
    display: none;
  }
  ${mb('m')} {
    background-color: transparent;
  }
`;

type IOwnProps = {
  name: IAdPosition;
  className?: string;
  style?: React.CSSProperties;
};

type IProps = IOwnProps;

const AdBase: React.FC<IProps> = ({ name, className, style }) => {
  useAdScriber(name, 100);

  if (debug.ad) {
    return (
      <DebugWrapper className={className} style={style}>
        <DebugName>{name}</DebugName>
        <AdWrapper className={name} />
      </DebugWrapper>
    );
  }
  return <AdWrapper className={`${name} ${className || ''}`} style={style} />;
};

export default React.memo(AdBase);
