import { ThirdPartyResultsOdd } from "../../library/thirdPartyResults";


// toto som si vymyslel
const oddWeight = {
  '1': 0,
  '1X': 5,
  X: 10,
  '12': 15,
  X2: 17,
  '2': 20,
};

const oddSortingFn = (odd1: ThirdPartyResultsOdd, odd2: ThirdPartyResultsOdd) => {
  return oddWeight[odd1.type] > oddWeight[odd2.type]
    ? 1
    : oddWeight[odd1.type] === oddWeight[odd2.type]
    ? 0
    : -1;
};

export default oddSortingFn;
