import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { differenceInYears } from 'date-fns';
import getYear from 'date-fns/getYear';
import { rem } from 'polished';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import formatUserName from 'sportnet-utilities/lib/formatUserName';
import Avatar from '../../components/Avatar';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import Icon from '../../components/Icon';
import Link from '../../components/Link';
import MaxWidthBox from '../../components/MaxWidthBox';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import styled from '../../theme/styled-components';
import formatDate from '../../utilities/formatDate';
import getMemberBaseUrl from '../../utilities/getMemberBaseUrl';

import __ from '../../utilities/__';
import { codeListDataSelector } from '../App/selectors';
import { currentMemberSelector } from './selectors';

const PaddedIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const LinkBack = styled(Link)`
  padding: ${rem(8)} 0;
  font-size: ${rem(14)};
  font-weight: 600;
  line-height: ${rem(16.8)};
  display: flex;
  align-items: center;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

const PageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

const MemberPanel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(32)};
  justify-content: center;
  padding-top: ${rem(32)};
`;

const MemberInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const MemberNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${rem(16)};
`;

const Flag = styled.img`
  width: 16px;
  height: 12px;
  margin-right: ${rem(8)};
`;

const MemberName = styled.div`
  font-size: ${rem(24)};
  font-weight: 600;
  text-transform: uppercase;
  line-height: ${rem(28.8)};
  color: ${({ theme }) => theme.app.textColor};
`;

const MemberDetailBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: ${rem(8)};
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${rem(12)};
  font-weight: 400;
  line-height: ${rem(14.4)};
  & > span {
    padding-top: ${rem(4)};
  }

  ${mb('s')} {
    display: block;
    text-align: left;
    & > span:not(:last-child) {
      margin-right: ${rem(12)};
    }
  }
`;

const MemberDetailSeparator = styled.span`
  display: none;
  ${mb('s')} {
    display: inline-block;
  }
`;

const MemberDetailValue = styled.span``;

const getMemberGenderLabel = (gender: string) => {
  if (gender === 'M') {
    return __('Muž');
  } else if (gender === 'F') {
    return __('Žena');
  }
  return '-';
};

const getMemberAge = (birthdate: string) =>
  differenceInYears(new Date(), new Date(birthdate));

const getMemberBirthYear = (birthdate: string) => getYear(new Date(birthdate));

const getFormattedDate = (d: string) => {
  return formatDate(new Date(d), 'dd.MM.yyyy');
};

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  member: currentMemberSelector(state),
  countries: codeListDataSelector(state, 'country'),
});

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ id: string }>;

const Header: React.FC<IProps> = ({
  member,
  location: { search },
  params: { id: memberId },
  countries,
}) => {
  const getBackSearchParams = React.useMemo(() => {
    const query = new URLSearchParams(search);
    query.delete('seasonName');
    const str = query.toString();
    return str ? `?${str}` : '';
  }, [search]);

  const getCountryFlagUrl = React.useCallback(
    (country: string) => {
      if (countries?.items && countries?.items.length) {
        const ctry = countries.items.find((c) => c.label === country);
        return ctry
          ? `https://api.sportnet.online/assets/countries/${ctry.value}.svg`
          : '';
      }
      return '';
    },
    [countries],
  );

  const renderCountryFlag = () => {
    if (member?.country) {
      const flagUrl = getCountryFlagUrl(member.country);
      return flagUrl ? <Flag src={flagUrl} /> : null;
    }
    return null;
  };

  const getProfilePhotoUrl = useCallback(
    (width) => {
      if (!member) {
        return null;
      }
      const bio = member.bio && member.bio.length ? member.bio[0] : null;
      if (bio && bio.photo) {
        return getMediaManagerUrl(bio.photo, width, 0, 'resize', false);
      }
      if (member.photo && member.photo?.public && member.photo?.public_url) {
        return member.photo?.public_url;
      }
      return null;
    },
    [member],
  );

  if (!member) {
    return null;
  }

  let menuItems: any[] = [];

  menuItems.push({
    id: 'profil',
    label: __('Profil'),
    url: `${getMemberBaseUrl({ clen: memberId })}${search}`,
  });

  // ak ma profil hraca, zobrazim prestupy a statistiky
  const isPlayer = member.profiles.findIndex((p) => p.type === 'PLAYER');

  if (isPlayer > -1) {
    menuItems = [
      ...menuItems,
      {
        id: 'prestupy',
        label: __('Prestupy hráča'),
        url: `${getMemberBaseUrl({ clen: memberId })}${search}#prestupy`,
        hash: '#prestupy',
      },
      {
        id: 'statistika',
        label: __('Štatistika hráča'),
        url: `${getMemberBaseUrl({ clen: memberId })}${search}#statistiky`,
        hash: '#statistiky',
      },
      {
        id: 'gdpr',
        label: __('GDPR'),
        url: `${getMemberBaseUrl({ clen: memberId })}${search}#gdpr`,
        hash: '#gdpr',
      },
    ];
  }

  return (
    <>
      <FutbalnetHeader>
        <PageHeader>
          <FutbalnetTitleBlock>
            <LinkBack to={`/futbalnet/clenovia/${getBackSearchParams}`}>
              <PaddedIcon name="back" size="m" />
              {__('Vyhľadať člena')}
            </LinkBack>
          </FutbalnetTitleBlock>
        </PageHeader>

        <MaxWidthBox>
          <MemberPanel>
            <MemberInfo>
              <Avatar
                alt={member.id}
                src={getProfilePhotoUrl(120)}
                width={120}
              />
              <MemberNameWrapper>
                {renderCountryFlag()}
                <MemberName>{formatUserName(member)}</MemberName>
              </MemberNameWrapper>
              <MemberDetailBlock>
                <MemberDetailValue>
                  {getMemberGenderLabel(member.sex)}
                </MemberDetailValue>
                <MemberDetailSeparator>|</MemberDetailSeparator>
                <MemberDetailValue>
                  {getMemberBirthYear(member.birthdate)}
                </MemberDetailValue>
                <MemberDetailSeparator>|</MemberDetailSeparator>
                <MemberDetailValue>
                  {getMemberAge(member.birthdate)}&nbsp;rokov
                </MemberDetailValue>
                <MemberDetailSeparator>|</MemberDetailSeparator>
                <MemberDetailValue>
                  Reg.&nbsp;číslo&nbsp;{member.id}
                </MemberDetailValue>
                {member.datumClenskehoOd && (
                  <>
                    <MemberDetailSeparator>|</MemberDetailSeparator>
                    <MemberDetailValue>
                      Členské&nbsp;
                      {getFormattedDate(member.datumClenskehoOd)}
                      &nbsp;-&nbsp;
                      {getFormattedDate(member.datumClenskehoDo)}
                    </MemberDetailValue>
                  </>
                )}
                {member.card && member.card.ordered && (
                  <>
                    <MemberDetailSeparator>|</MemberDetailSeparator>
                    <MemberDetailValue>
                      Preukaz&nbsp;
                      {getFormattedDate(member.card.ordered)}
                      &nbsp;-&nbsp;
                      {getFormattedDate(member.card.validity)}
                    </MemberDetailValue>
                  </>
                )}
              </MemberDetailBlock>
            </MemberInfo>
          </MemberPanel>
        </MaxWidthBox>
        <FutbalnetMenu items={menuItems} />
      </FutbalnetHeader>
    </>
  );
};

export default compose(
  React.memo,
  withRouter,
  connect(mapStateToProps),
)(Header) as unknown as React.FC<IOwnProps>;
