import React from 'react';

export default (size: number, color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={size}
      height={size}
    >
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,256)">
        <path
          transform="matrix(0.75000002,0,0,-0.75000002,0,192)"
          d="M 128 45.333984 C 128 45.333984 54.618359 45.333531 36.318359 50.269531 C 26.221026 52.985531 18.268979 60.987724 15.570312 71.150391 C 10.667646 89.569057 10.667969 128 10.667969 128 C 10.667969 128 10.667646 166.42828 15.570312 184.84961 C 18.268979 195.01094 26.221026 203.01509 36.318359 205.73242 C 54.618359 210.66709 128 210.66602 128 210.66602 C 128 210.66602 201.38297 210.66709 219.68164 205.73242 C 229.78031 203.01509 237.73102 195.01094 240.42969 184.84961 C 245.33369 166.42828 245.33398 128 245.33398 128 C 245.33398 128 245.33369 89.569057 240.42969 71.150391 C 237.73102 60.987724 229.78031 52.985531 219.68164 50.269531 C 201.38297 45.333531 128 45.333984 128 45.333984 z M 104 93.107422 L 165.33398 128.00195 L 104 162.89258 L 104 93.107422 z "
          fill={color}
        />
        <path d="m 78,69.8311 46,26.168 -46,26.17 z" />
      </g>
    </svg>
  );
};
