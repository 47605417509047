import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M18,23.1H4c-1.7,0-3-1.3-3-3v-14c0-1.7,1.3-3,3-3h7c0.6,0,1,0.4,1,1s-0.4,1-1,1H4c-0.6,0-1,0.4-1,1v14
    c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-7c0-0.6,0.4-1,1-1s1,0.4,1,1v7C21,21.8,19.7,23.1,18,23.1z M8.2,17.1l4-1
    c0.2,0,0.3-0.1,0.5-0.3l9.5-9.5c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0l-9.5,9.5c-0.1,0.1-0.2,0.3-0.3,0.5l-1,4
    c-0.1,0.3,0,0.7,0.3,0.9C7.5,17,7.7,17.1,8,17.1C8.1,17.1,8.2,17.1,8.2,17.1z M19.2,3.3L19.2,3.3c0.4-0.4,1.2-0.4,1.6,0
    c0.4,0.4,0.4,1.1,0,1.6l-9.3,9.3l-2.1,0.5l0.5-2.1L19.2,3.3z M18.5,2.6L18.5,2.6L18.5,2.6z"
    />
  </svg>
);
