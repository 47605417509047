import Loader from '@sportnet/ui/lib/Loader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { RootState } from '../../configureStore';
import { cumulatedListArticlesSelector } from '../../pages/Article/selectors';
import { sectionsEntitiesSelector } from '../../pages/Section/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import Article from '../../components/Articles/ArticlesList/Article';
import getArticleMainSection from '../../utilities/getArticleMainSection';
import ArticlesListHorizontallyScrollable from '../../components/Articles/ArticlesListHorizontallyScrollable';
import SectionBlockHeaderContainer from '../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import { EntitiesState } from '../../pages/App/reducer';
import config from '../../config';
import UrlContext, { UrlContextDefaultValue } from '../../contexts/UrlContext';
import SectionNameSmall from '../../components/Sections/SectionBlockHeader/SectionNameSmall';

const Wrapper = styled.div`
  margin-bottom: ${rem(48)};
  ${mb('s')} {
    margin-bottom: ${rem(0)};
  }
`;

const ArticlesLoader = styled(Loader)`
  margin: 0 auto;
`;

const ArticlesGridWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${rem(32)};
  }
  > li {
    margin: 0;
  }
`;

type IOwnProps = {
  organizationProfile: EntitiesState['organizationProfiles'][string];
  onLoadMoreWithOffset?: (nextOffset: number) => void;
  timelineGrid?: boolean;
  to?: string;
};

const mapStateToProps = (state: RootState) => ({
  articles: cumulatedListArticlesSelector(
    state,
    config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET,
  ),
  nextOffset: getListNextOffset(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET)(
    state,
  ),
  sectionsById: sectionsEntitiesSelector(state),
  isLoading:
    isCommiting(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET)(state) || false,
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FutbalnetUnionNews: React.FC<IProps> = ({
  articles,
  nextOffset,
  sectionsById,
  to,
  isLoading,
}) => {
  if (!articles.length) {
    return null;
  }

  return (
    <Wrapper>
      <>
        <SectionBlockHeaderContainer
          left={
            <SectionNameSmall>{__('Najnovšie články z futbalnetu')}</SectionNameSmall>
          }
        />
        {!isLoading && articles.length > 0 ? (
          <UrlContext.Provider value={UrlContextDefaultValue}>
            <ArticlesGridWrapper>
              {(articles || []).slice(0, 4).map((a) => {
                return (
                  <Article
                    key={`sportnet-article-${a._id!}`}
                    article={a}
                    section={getArticleMainSection(a, sectionsById)}
                    withPerex={false}
                  />
                );
              })}
            </ArticlesGridWrapper>
            <ArticlesListHorizontallyScrollable
              articles={articles}
              sectionsById={sectionsById}
            />

            {/* {nextOffset !== null && (
              <ShowMoreButton
                to={to || ''}
                text={__('Prejsť na články futblanetu')}
              />
            )} */}
          </UrlContext.Provider>
        ) : (
          <ArticlesLoader />
        )}
      </>
    </Wrapper>
  );
};

export default connect(mapStateToProps)(FutbalnetUnionNews);
