import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import {
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../../pages/Competition/selectors';
import React from 'react';
import { currentMatchSelector } from '../../pages/Match/selectors';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getProp } from 'sportnet-utilities';
import cleanRoundName from '../../utilities/cleanRoundName';
import FutbalnetUnionBreadcrumbs from './FutbalnetUnionBreadcrumbs';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getMatchBaseUrl from '../../utilities/getMatchBaseUrl';

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competition: currentCompetitionSelector(state),
});

interface OwnProps {
  subpage?: {
    label: string;
    subpageUrl: string;
  };
}

type IProps = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string; sutaz: string; zapas: string }>;

const MatchBreadcrumbs: React.FC<IProps> = ({
  match,
  organizationProfile,
  competition,
  params: { zvaz, zapas },
  subpage,
}) => {
  const teamA = getProp(match, ['teams', 0, 'organization', 'name']);
  const teamB = getProp(match, ['teams', 1, 'organization', 'name']);
  const roundName = match?.round && `${cleanRoundName(match.round.name)}`;
  const competitionName = `${competition?.name}${
    !!roundName && ` ${roundName}`
  }`;
  const matchBreadcrumbName = `${teamA} - ${teamB}`;

  const breadcrumbUnionName =
    organizationProfile?.shortName || organizationProfile?.name || '';

  return (
    <>
      {breadcrumbUnionName && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: breadcrumbUnionName,
              url: getUnionBaseUrl({ zvaz }),
            },
            {
              label: competitionName,
              url: getCompetitionBaseUrl({
                zvaz,
                sutaz: competition?.__issfId || '',
              }),
            },
            {
              label: matchBreadcrumbName,
              url: getMatchBaseUrl({ zvaz, zapas }),
            },
            ...(subpage
              ? [
                  {
                    label: subpage.label,
                    url: `${getMatchBaseUrl({ zvaz, zapas })}${
                      subpage.subpageUrl
                    }/`,
                  },
                ]
              : []),
          ]}
        />
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(MatchBreadcrumbs) as unknown as React.FC<OwnProps>;
