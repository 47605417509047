import { AsyncReturnType } from '../library/App';
import { IMemberPlayerProfile } from '../library/Competitions';
import FutbalnetApi from '../pages/Member/api';

/**
 * ...len potrebne fieldy pre detail hraca
 */
const reducePlayerProfile = (
  playerProfile: AsyncReturnType<typeof FutbalnetApi['getPlayer']>,
): IMemberPlayerProfile => {
  const reduced: IMemberPlayerProfile = {
    profileStatus: playerProfile.profileStatus,
    clubOrigin: {
      name: playerProfile.club_origin.name,
      id: playerProfile.club_origin.id,
    },
    clubActual: {
      name: playerProfile.club_actual.name,
      id: playerProfile.club_actual.id,
    },
    playerStatus: playerProfile.player_status,
  };
  if (playerProfile.club_hosted) {
    reduced.clubHosted = {
      name: playerProfile.club_hosted.name,
      id: playerProfile.club_hosted.id,
    };
  }
  return reduced;
};

export default reducePlayerProfile;
