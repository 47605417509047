import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import Icon from '../../../../../components/Icon';
import Link from '../../../../../components/Link';
import UrlContext from '../../../../../contexts/UrlContext';
import {
  ISection,
  SectionNode,
  SectionTreeType,
} from '../../../../../library/App';
import styled, {
  createGlobalStyle,
  css,
} from '../../../../../theme/styled-components';
import getSectionUrl from '../../../../../utilities/getSectionUrl';
import __ from '../../../../../utilities/__';
import { getCallbackPredplatneUrl } from '../../../../Authorization';
import {
  FIXED_MENU_HEIGHT,
  MAIN_MENU_HEIGHT_DESKTOP,
  MAIN_MENU_HEIGHT_TABLET,
} from './constants';

const bodyOverflowHiddenClass = 'responsive-menu-opened';

const GlobalStyle = createGlobalStyle`
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
    height: 100vh;
  }
`;

interface ResponsiveNavigationListProps {
  backgroundColor: string;
  borderColor: string;
  isMenuFixed: boolean;
  searchActive: boolean;
}

const ResponsiveNavigationListWrapper = styled.div<{
  searchActive: boolean;
  isMenuFixed: boolean;
  backgroundColor: string;
  borderColor: string;
}>`
  height: 100vh;
  position: absolute;
  top: ${rem(FIXED_MENU_HEIGHT + 1)};
  z-index: 102;
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  max-height: calc(100vh - ${rem(FIXED_MENU_HEIGHT + 1)});
  overflow-y: auto;
  border-bottom: ${rem(1)} solid ${({ borderColor }) => borderColor};
  ${({ searchActive }) =>
    searchActive &&
    css`
      margin-top: ${rem(81)};
    `}

  ${({ isMenuFixed }) =>
    !isMenuFixed &&
    css`
      top: ${rem(MAIN_MENU_HEIGHT_TABLET + 1)};
      max-height: calc(100vh - ${rem(MAIN_MENU_HEIGHT_TABLET + 1)});
      ${mb('l')} {
        top: ${rem(MAIN_MENU_HEIGHT_DESKTOP + 1)};
        max-height: calc(100vh - ${rem(MAIN_MENU_HEIGHT_DESKTOP + 1)});
      }
    `}
`;

const ResponsiveNavigationList = styled.ul<ResponsiveNavigationListProps>`
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: ${rem(16)} 0;
`;

interface SectionLinkProps {
  color: string;
  activeColor: string;
  leftAligned?: boolean;
}
const SectionLink = ({ color, activeColor, ...rest }: SectionLinkProps) => (
  <Link {...rest} />
);
const SectionItem = styled(SectionLink)<SectionLinkProps>`
  cursor: pointer;
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${({ leftAligned }) =>
    !!leftAligned &&
    css`
      justify-content: flex-start;
      > span:first-child {
        margin-right: ${rem(16)};
      }
    `}
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  outline: none;
  background: none;
  margin: 0;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  word-break: break-word;
  color: ${({ color }) => color};
  letter-spacing: ${rem(0.96)};
`;

const PremiumWrapper = styled.div`
  border-top: ${rem(1)} solid #ddd;
  border-bottom: ${rem(1)} solid #ddd;
  padding: ${rem(16)} 0;
`;

const OtherSectionsWrapper = styled.div`
  padding-top: ${rem(16)};
  ${SectionItem} {
    font-weight: normal;
    text-transform: initial;
    letter-spacing: 0px;
    color: #2f3136;
  }
`;

const ResponsiveNavigationListItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
`;

const SideMenuItemsWrapper = styled.div<{ itemsCount: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: ${rem(1)} solid #dddddd;
  > a {
    ${({ itemsCount }) => css`
      width: calc(100% / ${itemsCount});
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ${rem(16)} 0;
      font-weight: bold;
      font-size: ${rem(10)};
      letter-spacing: ${rem(0.8)};
      color: #2f3136;
      text-transform: uppercase;
      border-right: ${rem(1)} solid #ddd;
      > img {
        margin-bottom: ${rem(12)};
      }
    `}
  }
  > div:last-child {
    border-right-width: 0;
  }
`;

interface IOwnProps {
  opened: boolean;
  sections: SectionTreeType;
  otherSections: SectionTreeType;
  sideMenuItems: SectionTreeType;
  searchActive: boolean;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  activeBorderColor: string;
  onClose: () => void;
  isMenuFixed: boolean;
  activeSection: SectionNode | null;
  setActiveSection: (section: SectionNode | null) => void;
}

type IProps = IOwnProps;

const ResponsiveMenu: React.FC<IProps> = ({
  opened,
  sections,
  otherSections,
  sideMenuItems,
  textColor,
  backgroundColor,
  borderColor,
  activeBorderColor,
  onClose,
  isMenuFixed,
  activeSection,
  setActiveSection,
  searchActive,
}) => {
  const { getSectionUrl: ContextGetSectionUrl } = React.useContext(UrlContext);
  React.useEffect(() => {
    if (!opened) {
      setActiveSection(null);
      return;
    }
    document.body.classList.add(bodyOverflowHiddenClass);
    return () => {
      document.body.classList.remove(bodyOverflowHiddenClass);
    };
  }, [opened, setActiveSection]);

  if (!opened) {
    return null;
  }

  function renderSection(section: SectionNode) {
    return (
      <ResponsiveNavigationListItem key={section._id}>
        <SectionItem
          {...((section.sections || []).length === 0
            ? {
                onClick: onClose,
                to: ContextGetSectionUrl(section as ISection),
              }
            : {
                onClick: () => {
                  setActiveSection(section);
                },
              })}
          color={textColor}
          activeColor={activeBorderColor}
        >
          <span>{section.name}</span>
          {(section.sections || []).length > 0 && <Icon name="chevronRight" />}
        </SectionItem>
      </ResponsiveNavigationListItem>
    );
  }

  const getSections = (items: SectionTreeType) => {
    if (!activeSection) return items;
    let rootSection = items.find(
      (i) => activeSection && i._id === activeSection._id
    );
    if (!rootSection) {
      return getSections(
        items.reduce((acc, i) => [...acc, ...(i.sections || [])], [])
      );
    }
    return rootSection!.sections || [];
  };

  return (
    <>
      <GlobalStyle />
      <ResponsiveNavigationListWrapper
        searchActive={searchActive}
        isMenuFixed={isMenuFixed}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
      >
        {sideMenuItems.length > 0 && (
          <SideMenuItemsWrapper itemsCount={sideMenuItems.length}>
            {sideMenuItems.map((i) => (
              <Link
                to={getSectionUrl('', { ...i, _id: i._id!, name: i.name! })}
                onClick={onClose}
              >
                <img
                  alt={i.name}
                  src={i.icon?.public_url}
                  style={{ maxWidth: rem(16), height: '100%' }}
                />
                <div>{i.name}</div>
              </Link>
            ))}
          </SideMenuItemsWrapper>
        )}
        <ResponsiveNavigationList>
          {!!activeSection &&
            renderSection({
              ...activeSection,
              name: __('Všetky články'),
              sections: [],
            })}
          {getSections(sections).map(renderSection)}
          {!activeSection && (
            <>
              <PremiumWrapper>
                <ResponsiveNavigationListItem>
                  <SectionItem
                    leftAligned
                    to={getCallbackPredplatneUrl()}
                    color={textColor}
                    activeColor={activeBorderColor}
                  >
                    <Icon name="premium" color="#333" size={10} />
                    <span>{__('Predplatné')}</span>
                  </SectionItem>
                </ResponsiveNavigationListItem>
              </PremiumWrapper>
              <OtherSectionsWrapper>
                {otherSections.map(renderSection)}
              </OtherSectionsWrapper>
            </>
          )}
        </ResponsiveNavigationList>
      </ResponsiveNavigationListWrapper>
    </>
  );
};

export default React.memo(ResponsiveMenu);
