import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import styled from '../../theme/styled-components';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getMembersNameWithLink from '../../utilities/getMembersNameWithLink';
import Icon from '../Icon';
import { Table, Tbody, Td, Th, Thead, Tr } from '../Table/TableOriginal';

const LadderPlayer = styled.div`
  display: flex;
  align-items: center;
  > span {
    padding-right: ${rem(16)};
  }
`;

const ResultsTableWrapper = styled.div`
  margin: 0 -${rem(24)};
  td {
    font-size: ${rem(12)};
  }
  th:first-child,
  td:first-child {
    padding-left: ${rem(24)};
  }
  th:last-child,
  td:last-child {
    padding-right: ${rem(24)};
  }
`;

const ReadMore = styled.div`
  padding: ${rem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem(14)};
  margin-bottom: -${rem(24)};
  > span {
    padding-left: ${rem(8)};
  }
`;

const TdStrikerGoals = styled(Th)`
  width: 30px;
`;

interface IStriker {
  __issfId: number | null;
  _id: string;
  teamId: string;
  name: string;
  stats: any;
}

interface OwnProps {
  unionId: string;
  competitionId: string;
  strikers: IStriker[];
  currentCompetitionId: string;
}

const StrikersTable: React.FC<OwnProps> = ({
  unionId,
  competitionId,
  strikers,
  currentCompetitionId,
}) => {
  return (
    <ResultsTableWrapper>
      <Table>
        <Thead>
          <Tr>
            <Th>{__('Hráč')}</Th>
            <TdStrikerGoals>{__('G')}</TdStrikerGoals>
          </Tr>
        </Thead>
        <Tbody>
          {strikers.map((striker) => (
            <Tr key={striker.name}>
              <Td>
                <LadderPlayer key={striker.name}>
                  <Icon size="l" name="avatar" />
                  <div>
                    {getMembersNameWithLink(striker.name, striker.__issfId || striker._id)}
                  </div>
                </LadderPlayer>
              </Td>
              <Td>{striker.stats.goals}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Link
        to={`${getCompetitionBaseUrl({
          zvaz: unionId,
          sutaz: competitionId,
        })}statistiky/?competitionId=${currentCompetitionId}`}
      >
        <ReadMore>
          {__('Pozrieť štatistiky')} <Icon name="arrowMore" />
        </ReadMore>
      </Link>
    </ResultsTableWrapper>
  );
};

export default StrikersTable;
