import * as React from 'react';
import { unionArticlesSelector } from '../../Article/selectors';
import { RootState } from '../../../configureStore';
import { sectionsEntitiesSelector } from '../../Section/selectors';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import config from '../../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ArticlesList from '../../../components/Articles/ArticlesList';
import styled from '../../../theme/styled-components';
import NextPrevPaginator from '../../../components/NextPrevPaginator';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import InfiniteArticleListLoader from '../../../containers/InfiniteArticleList/InfiniteArticleListLoader';
import ContentNotFound from '../../../components/ContentNotFound';
import __ from '../../../utilities/__';

export const PositionedArticlesList = styled(ArticlesList)`
  margin: 0;
`;

export const PositionedNextPrevPaginator = styled(NextPrevPaginator)`
  max-width: ${rem(680)};
  margin: ${rem(48)} 0;
  ${mb('s')} {
    margin: ${rem(56)} 0;
  }
`;

const mapStateToProps = (state: RootState) => ({
  articles: unionArticlesSelector(state),
  sectionsById: sectionsEntitiesSelector(state),
  sectionArticlesNextOffset: getListNextOffset(
    config.LIST_UNION_ARTICLES_ARCHIVE,
  )(state),
  isLoadingUnionArticles:
    isCommiting(config.LIST_UNION_ARTICLES_ARCHIVE)(state) || false,
});

interface OwnProps {
  page: number;
}

type IProps = OwnProps & ReturnType<typeof mapStateToProps>;

const UnionArticlesArchive: React.FC<IProps> = ({
  articles,
  sectionsById,
  sectionArticlesNextOffset,
  isLoadingUnionArticles,
  page,
}) => {
  return (
    <>
      {articles && articles.length > 0 ? (
        <>
          <PositionedArticlesList
            articles={articles}
            sectionsById={sectionsById}
          />
          <PositionedNextPrevPaginator
            isPageLast={!sectionArticlesNextOffset}
            loading={isLoadingUnionArticles}
            page={page}
          />
        </>
      ) : isLoadingUnionArticles ? (
        <InfiniteArticleListLoader />
      ) : (
        <ContentNotFound title={__('Nenašli sa žiadne články')} />
      )}
    </>
  );
};

export default compose(connect(mapStateToProps))(UnionArticlesArchive);
