import { getListResultsByParams } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import { entitiesSelector } from '../../pages/App/selectors';
import { unionsStructureSelector } from '../../pages/FutbalnetApp/selectors';
import getCompetitionsCategoryPanes from '../../utilities/getCompetitionsCategoryPanes';

export const listCompetitionsByParamsSelector = createSelector(
  entitiesSelector,
  getListResultsByParams,
  (entities, ids) => at(entities.competitions, ids || []),
);

export const listCompetitionsCategoryPanesByParamsSelector = createSelector(
  listCompetitionsByParamsSelector,
  unionsStructureSelector,
  (competitions, unionsStructure) =>
    getCompetitionsCategoryPanes(competitions, unionsStructure),
);
