import {
  ICompetition,
  Category,
  CategorizedCompetitions,
} from '../library/Competitions';
import { getProp } from 'sportnet-utilities';

// roztriedi sutaze do kategorii (muzi, zeny, others = mladez)
// vrati object { M: [], Z: [], U: [] }
// POZOR v poliach nie su kopie objektov ale referencie!
const getCategorizedCompetitions = (competitions: ICompetition[]) => {
  return (competitions || []).reduce(
    (acc, c) => {
      if (c.parts && c.parts.length) {
        // dospelí (zeny, muzi)
        if (getProp(c, ['parts', 0, 'rules', 'category']) === 'ADULTS') {
          if (getProp(c, ['parts', 0, 'rules', 'gender']) === Category.MALE) {
            acc[Category.MALE].push(c);
          } else if (
            getProp(c, ['parts', 0, 'rules', 'gender']) === Category.FEMALE
          ) {
            acc[Category.FEMALE].push(c);
          }
          // mladez
        } else {
          acc[Category.OTHER].push(c);
        }
      }
      return acc;
    },
    {
      [Category.MALE]: [],
      [Category.FEMALE]: [],
      [Category.OTHER]: [],
    } as CategorizedCompetitions,
  );
};

export default getCategorizedCompetitions;
