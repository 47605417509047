import config from '../../config';
import { TransfersFilter } from '../../library/Transfermarket';
import filterQueryParamsForCanonicalUrl from '../../utilities/filterQueryParamsForCanonicalUrl';
import getBaseUrl from '../../utilities/getBaseUrl';

export const FILTER_MIN_DATE = new Date('1995-12-31T23:00:00Z');

export const MAIN_ARTICLES_DESCRIPTOR = {
  listName: config.LIST_FUTBALNET_HOMEPAGE_ARTICLES,
  parameters: {
    limit: 15,
    offset: 0,
    listingId: config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID,
  },
};

/**
 * Vrati pocet zmien/nastaveni vo filtri oproti defaultu. Ak je pocet zmien nula, vacia `undefined`
 * aby sa vobec nevykreslil badge.
 *
 * @param activeFilter
 * @returns
 */
export const getFilterBadgeNumber = (activeFilter: TransfersFilter) => {
  let num = 0;
  if (activeFilter.ppo) {
    num += 1;
  }
  if (activeFilter.ppoDir !== 'both') {
    num += 1;
  }
  if (activeFilter.transferType !== 'all') {
    num += 1;
  }
  if (activeFilter.age) {
    num += 1;
  }
  if (activeFilter.gender) {
    num += 1;
  }
  if (activeFilter.dateFrom) {
    num += 1;
  }
  if (activeFilter.dateTo) {
    num += 1;
  }
  return num === 0 ? undefined : num;
};

export const getCanonicalUrl = (pathname: string, search: string) => {
  const filteredQueryParams = filterQueryParamsForCanonicalUrl(search);
  return `${getBaseUrl()}${pathname}${
    filteredQueryParams ? `?${filteredQueryParams}` : ''
  }`;
};

export const getFilterInitialState = () =>
  ({
    transferType: 'all',
    ppo: '',
    ppoName: '',
    dateFrom: undefined,
    dateTo: undefined,
    ppoDir: 'both',
    age: undefined,
  } as TransfersFilter);
