import { combineReducers } from 'redux';
import { listReducer } from '@sportnet/redux-list/ducks';
import { SectionTreeType } from './library/App';
import { loadPath, loadSectionTree } from './pages/App/actions';
import {
  authorizationReducer,
  detailReducerFromAction,
  entitiesReducer,
  scrollProgressReducer,
  sectionTreeReducer,
  sportnetMenusReducer,
} from './pages/App/reducer';
import { currentArticleIdReducer } from './pages/Article/reducer';
import {
  loadCommiteeDetail,
  loadResolutionDetail,
} from './pages/Commitee/actions';
import {
  loadCompetition,
  loadCompetitionPart,
  loadCompetitionPartPlayers,
  loadOrganizationProfile,
} from './pages/Competition/actions';
import {
  currentCompetitionAppSpaceReducer,
  currentCompetitionGroupIdReducer,
  currentCompetitionIdReducer,
  currentCompetitionPartIdReducer,
  currentRoundIdReducer,
} from './pages/Competition/reducer';
import {
  appSettingsReducer,
  IAppSettingsState,
} from './pages/DomainResolver/reducer';
import {
  ISeasonsState,
  IUnionsState,
  seasonsReducer,
  unionsReducer,
} from './pages/FutbalnetApp/reducer';
import {
  IUrlMapResult,
  loadByAppSpaceUrl,
} from './pages/FutbalnetUrlMap/actions';
import { loadMatch } from './pages/Match/actions';
import { currentMatchIdReducer } from './pages/Match/reducer';
import {
  loadMember,
  loadMemberMovements,
  loadMemberStats,
} from './pages/Member/actions';
import {
  currentMemberIdReducer,
  currentMemberTypeReducer,
} from './pages/Member/reducer';
import { loadSection } from './pages/Section/actions';
import { currentSectionIdReducer } from './pages/Section/reducer';
import { loadSmartTagByIdentReducer } from './containers/SmartTags/reducer';

const rootReducer = combineReducers({
  application: combineReducers<{
    settings: IAppSettingsState;
    unions: IUnionsState;
    seasons: ISeasonsState;
    authorization: ReturnType<typeof authorizationReducer>;
  }>({
    settings: appSettingsReducer,
    unions: unionsReducer,
    seasons: seasonsReducer,
    authorization: authorizationReducer,
  }),
  entities: entitiesReducer,
  list: listReducer,
  detail: combineReducers({
    sectionById: detailReducerFromAction([loadSection], 'idOrUniqId'),
    comiteeById: detailReducerFromAction([loadCommiteeDetail], 'comiteeId'),
    resolutionByNumber: detailReducerFromAction(
      [loadResolutionDetail],
      'resolutionNumber',
    ),

    organizationProfileById: detailReducerFromAction(
      [loadOrganizationProfile],
      'appSpace',
    ),
    memberById: detailReducerFromAction([loadMember], 'id'),
    memberMovementsById: detailReducerFromAction([loadMemberMovements], 'id'),
    memberStatsById: detailReducerFromAction([loadMemberStats], 'id'),
    competitionById: detailReducerFromAction(
      [loadCompetition],
      'competitionId',
    ),
    competitionPartById: detailReducerFromAction(
      [loadCompetitionPart],
      'competitionPartId',
    ),
    matchById: detailReducerFromAction([loadMatch], 'matchId'),
    pathBySectionId: detailReducerFromAction<
      { path: string[] | number[] },
      any
    >([loadPath], 'id'),
    sectionTreeByParameters: detailReducerFromAction<
      { tree: SectionTreeType },
      any
    >([loadSectionTree], 'sectionIdOrUniqId', 'treelevel', 'appSpace'),
    urlDetailByAppSpaceUrl: detailReducerFromAction<IUrlMapResult['data'], any>(
      [loadByAppSpaceUrl],
      'appSpace',
      'url',
    ),
    playersByPartId: detailReducerFromAction([loadCompetitionPartPlayers], 'competitionPartId'),
  }),
  currentArticleId: currentArticleIdReducer,
  currentSectionId: currentSectionIdReducer,
  scrollProgress: scrollProgressReducer,
  currentCompetitionAppSpace: currentCompetitionAppSpaceReducer,
  currentCompetitionId: currentCompetitionIdReducer,
  currentCompetitionGroupId: currentCompetitionGroupIdReducer,
  currentCompetitionPartId: currentCompetitionPartIdReducer,
  currentRoundId: currentRoundIdReducer,
  currentMatchId: currentMatchIdReducer,
  currentMemberId: currentMemberIdReducer,
  currentMemberType: currentMemberTypeReducer,
  loadSmartTagByIdent: loadSmartTagByIdentReducer,
  sectionTree: sectionTreeReducer,
  sportnetMenus: sportnetMenusReducer,
});

export default rootReducer;
