import { getProp } from 'sportnet-utilities';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import { UserRegnr } from '../../api/CoreApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import InternalServerError from '../../InternalServerError';
import { AsyncReturnType, NormalizedEntities } from '../../library/App';
import {
  IMember,
  IMemberDelegateMProfile,
  IMemberDelegateOProfile,
  IMemberLicense,
  IMemberPlayerProfile,
  IMemberRefereeProfile,
  IMemberTrainerProfile,
} from '../../library/Competitions';
import NotFoundError from '../../NotFoundError';
import isObjectId from '../../utilities/isObjectId';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceDelegateMProfile from '../../utilities/reduceDelegateMProfile';
import reduceDelegateOProfile from '../../utilities/reduceDelegateOProfile';
import reducePlayerProfile from '../../utilities/reducePlayerProfile';
import reduceRefereeProfile from '../../utilities/reduceRefereeProfile';
import reduceTrainerProfile from '../../utilities/reduceTrainerProfile';
import { updateEntities } from '../App/actions';
import FutbalnetApi from './api';
import { currentMemberSelector } from './selectors';

const create = actionCreatorFactory('MEMBER');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const normalizeMembers = (data: IMember[]) => {
  return {
    entities: {
      member: data.reduce((acc, item) => {
        return { ...acc, [String(item.id)]: item };
      }, {}) as { [key: string]: IMember },
    },
    result: data.map((item) => item.id),
  };
};

export const setCurrentMemberId = create<string>('SET_CURRENT_MEMBER_ID');
export const setCurrentMemberType = create<string>('SET_CURRENT_MEMBER_TYPE');
export const setCurentMamberIsPlayer = create<string>(
  'SET_CURRENT_MEMBER_IS_PLAYER',
);

export const loadMember = createAsync<
  { id: string },
  NormalizedEntities<'member'>
>('LOAD_MEMBER', async ({ id }, dispatch, getState, { CoreApi }) => {
  try {
    let regnr = id;
    if (isObjectId(regnr)) {
      const sportnetProfile = await CoreApi.organizationPPOUser(
        config.OWNER_PPO,
        id,
      );
      if (sportnetProfile && (sportnetProfile.regnrs || []).length) {
        const futbalnetRegnr: UserRegnr | undefined = (sportnetProfile.regnrs || []).find((i: UserRegnr) => i.org_profile_id === 'futbalsfz.sk');
        if (futbalnetRegnr) {
          regnr = futbalnetRegnr.regnr!;
        }
      }
    }
    const data = await FutbalnetApi.getPerson(regnr);

    const member: Omit<typeof data, 'photo'> & {
      trainerLicenses?: IMemberLicense[];
      refereeLicenses?: IMemberLicense[];
      delegateMLicenses?: IMemberLicense[];
      delegateOLicenses?: IMemberLicense[];
      playerProfile?: IMemberPlayerProfile;
      trainerProfile?: IMemberTrainerProfile;
      refereeProfile?: IMemberRefereeProfile;
      delegateOProfile?: IMemberDelegateOProfile;
      delegateMProfile?: IMemberDelegateMProfile;
      photo?: any;
    } & { sportnetId?: string } & Pick<
      AsyncReturnType<typeof CoreApi['organizationPPOUser']>,
      'bio' 
    > = data;
    dispatch(setCurrentMemberType(getProp(member, ['profiles', 0, 'type'])));

    const profileTypes = member.profiles.map((p) => p.type);

    const sportnetProfile = await CoreApi.organizationPPOUser(
      config.OWNER_PPO,
      id,
    );

    member.sportnetId = sportnetProfile._id || '';
    member.bio = sportnetProfile.bio || [];
    member.photo = sportnetProfile.photo;

    if (profileTypes.includes('TRAINER')) {
      const licences = await FutbalnetApi.getTrainerLicenses(regnr);
      member.trainerLicenses = licences;
      const profile = await FutbalnetApi.getTrainer(regnr);
      member.trainerProfile = reduceTrainerProfile(profile);
    }
    if (profileTypes.includes('REFEREE')) {
      const licences = await FutbalnetApi.getRefereeLicenses(regnr);
      member.refereeLicenses = licences;
      const profile = await FutbalnetApi.getReferee(regnr);
      member.refereeProfile = reduceRefereeProfile(profile);
    }
    if (profileTypes.includes('DELEGATE_O')) {
      const licences = await FutbalnetApi.getDelegateOLicenses(regnr);
      member.delegateOLicenses = licences;
      const profile = await FutbalnetApi.getDelegateO(regnr);
      member.delegateOProfile = reduceDelegateOProfile(profile);
    }
    if (profileTypes.includes('DELEGATE_M')) {
      const licences = await FutbalnetApi.getDelegateMLicenses(regnr);
      member.delegateMLicenses = licences;
      const profile = await FutbalnetApi.getDelegateM(regnr);
      member.delegateMProfile = reduceDelegateMProfile(profile);
    }
    if (profileTypes.includes('PLAYER')) {
      const playerProfile = await FutbalnetApi.getPlayer(regnr);
      member.playerProfile = reducePlayerProfile(playerProfile);
    }

    const { entities } = normalizeMembers([member]);

    if (isObjectId(id)) {
      member.id = sportnetProfile._id!;
    }

    dispatch(updateEntities(entities));
    return normalizeEntities('member', [{ ...member, _id: member.id }]);
  } catch (e: any) {
    if (e && e.details && e.details.status === 404) {
      throw new NotFoundError(e);
    }
    throw e;
  }
});

export const loadPlayerProfile = createAsync<
  { id: string },
  NormalizedEntities<'member'>
>('LOAD_PLAYER_PROFILE', async ({ id }, dispatch, getState, { CoreApi }) => {
  try {
    const data = currentMemberSelector(getState()) as IMember;
    const member = { ...data };
    const playerProfile = await FutbalnetApi.getPlayer(id);
    member.playerProfile = reducePlayerProfile(playerProfile);
    const { entities } = normalizeMembers([member]);
    dispatch(updateEntities(entities));
    return normalizeEntities('member', [{ ...member, _id: member.id }]);
  } catch (e: any) {
    if (e && e.details && e.details.status === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});

export const loadMemberMovements = createAsync<
  { id: string },
  NormalizedEntities<'member'>
>('LOAD_MEMBER_MOVEMENTS', async ({ id }, dispatch, getState, { CoreApi }) => {
  try {
    const data = currentMemberSelector(getState()) as IMember;
    const member = { ...data };
    const movements = await FutbalnetApi.getPlayerMovements(id);
    member.movements = movements;
    const { entities } = normalizeMembers([member]);
    dispatch(updateEntities(entities));
    return normalizeEntities('member', [{ ...member, _id: member.id }]);
  } catch (e: any) {
    if (e && e.details && e.details.status === 404) {
      throw new NotFoundError(e);
    }
    throw new InternalServerError(e);
  }
});

export const loadMemberStats = createAsync<
  { id: string },
  NormalizedEntities<'member'>
>(
  'LOAD_MEMBER_STATS',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    try {
      const data = currentMemberSelector(getState()) as IMember;
      const member = { ...data };
      const stats = await CompetitionsApi.getPlayerSummaryStatistics(
        member.sportnetId || '',
      );
      member.stats = stats.items || [];
      const { entities } = normalizeMembers([member]);
      dispatch(updateEntities(entities));
      return normalizeEntities('member', [{ ...member, _id: member.id }]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  },
);
