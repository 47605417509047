import { rem } from 'polished';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Icon from '../../components/Icon';
import ItemsList from '../../components/ItemsList';
import Header from '../../components/ItemsList/Header';
import Item from '../../components/ItemsList/Item';
import styled, { css } from '../../theme/styled-components';
import { IPane } from './types';

const RightArrow = styled(Icon)`
  margin-left: auto;
  padding-left: ${rem(8)};
`;

const PaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledItem = styled(Item)<{ padTop: boolean }>`
  ${({ padTop }) =>
    padTop &&
    css`
      margin-top: ${rem(8)};
    `}
`;

type IOwnProps = {
  panes: IPane[];
  header?: React.ReactNode;
  getHeight?: (node: HTMLElement) => void;
  active: boolean;
  className?: string;
  onBack?: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * Wheter component should be unmounted when hidden and mounted when component appears
   */
  unmount?: boolean;
};

type IProps = IOwnProps;

const AnimatedPanes: React.FC<IProps> = ({
  panes,
  header,
  active,
  className,
  getHeight,
  onBack,
  unmount,
}) => {
  const [activeItem, setActiveItem] = React.useState<IPane | null>(null);

  React.useEffect(() => {
    if (!active) {
      setActiveItem(null);
    }
  }, [active]);

  const handleBackChild = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      setActiveItem(null);
    },
    [],
  );

  const handleBack = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      if (onBack) {
        onBack(e);
      }
    },
    [onBack],
  );

  return (
    <PaneWrapper className={className}>
      <CSSTransition
        in={!!activeItem}
        timeout={500}
        classNames="pane-next"
        onExit={active ? getHeight : void 0}
      >
        <PaneWrapper>
          {typeof header === 'string' ? (
            <Header onClick={onBack ? handleBack : void 0}>{header}</Header>
          ) : (
            header
          )}
          <ItemsList>
            {panes.map((pane, idx) => {
              const prevPaneIsOfDifferentType =
                !!panes[idx - 1] && panes[idx - 1].type !== pane.type;
              return (
                <StyledItem
                  key={pane._id}
                  to={pane.url}
                  onClick={
                    pane.url
                      ? void 0
                      : () => {
                          setActiveItem(pane);
                        }
                  }
                  padTop={prevPaneIsOfDifferentType}
                >
                  {pane.icon}
                  {pane.name}
                  {!!pane.children && (
                    <RightArrow name="arrow-right" size={16} />
                  )}
                </StyledItem>
              );
            })}
          </ItemsList>
        </PaneWrapper>
      </CSSTransition>

      {panes.map((pane) => {
        if (!pane.children) {
          return null;
        }
        return (
          <TransitionPane
            key={pane._id}
            active={active}
            isIn={activeItem?._id === pane._id}
            pane={pane}
            getHeight={getHeight}
            onBack={handleBackChild}
            unmount={unmount}
          />
        );
      })}
    </PaneWrapper>
  );
};

const TransitionPane: React.FC<{
  pane: IPane;
  getHeight?: (node: HTMLElement) => void;
  active: boolean;
  isIn: boolean;
  unmount?: boolean;
  onBack?: (e: React.MouseEvent<HTMLElement>) => void;
}> = React.memo(({ pane, active, isIn, getHeight, onBack, unmount }) => {
  return (
    <CSSTransition
      in={isIn}
      timeout={500}
      classNames="pane-prev"
      onEnter={active ? getHeight : void 0}
      mountOnEnter={unmount}
      unmountOnExit={unmount}
    >
      <AnimatedPanes
        active={active}
        panes={pane.children!}
        header={pane.name}
        onBack={onBack}
        getHeight={getHeight}
        className="hidden-pane"
        unmount={unmount}
      />
    </CSSTransition>
  );
});

export default React.memo(AnimatedPanes);
