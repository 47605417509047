import React from 'react';
import styled from '../../../theme/styled-components';
import ArticleLoader from './ArticleLoader';

const Wrapper = styled.div``;

interface OwnProps {
  id?: string;
  items?: number;
}

const ListLoader: React.FC<OwnProps> = ({ id = '', items = 3 }) => {
  return (
    <Wrapper>
      {[...Array(items).keys()].map((key) => {
        return <ArticleLoader key={`article-list-loader-${id}-${key}`} />;
      })}
    </Wrapper>
  );
};

export default React.memo(ListLoader);
