import {
  ISportSectorPhasesItem,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/library/Competitions';

import { IWidgetMatchesList } from '@sportnet/content/utilities/library';
import format from 'date-fns/format';
import CompetitionsApi from '../../../../api/CompetitionsApi';
import { ITeam } from '../../../../library/Competitions';


type IData = Omit<IWidgetMatchesList, 'type'>;

export const getFallbackLogoImage = (baseUrl: string = '') => {
  return `${baseUrl ? baseUrl : ''}/content/crest.png`;
};

export interface IGetMatchesParams {
  competitionId?: string;
  partId?: string;
  roundId?: string;
  dateFrom?: string;
  dateTo?: string;
  closed?: boolean;
  teamId?: string;
  limit?: number;
  offset?: number;
  teamIds?: string[];
  sorter?:
    | 'dateFromAsc'
    | 'dateFromDesc'
    | 'roundDateFromAsc'
    | 'roundDateFromDesc';
}

export enum TeamSide {
  HOME = 'home',
  AWAY = 'away',
}

const getTeamIndex = (teamSide: TeamSide) => {
  switch (teamSide) {
    case TeamSide.HOME:
      return 0;
    case TeamSide.AWAY:
      return 1;
    default:
      return null;
  }
};

export const getTeam = (
  match: IWidgetMatchesListMatchItem,
  teamSide: TeamSide
) => {
  if (match.teams?.length) {
    const team = (match.teams || []).find(
      (t) => (t?.additionalProperties?.homeaway || '') === teamSide
    );
    if (team) {
      return team;
    } else {
      const idx = getTeamIndex(teamSide);
      return idx ? match.teams[idx] ?? null : null;
    }
  }
  return null;
};

export const getTeamsIds = (match: IWidgetMatchesListMatchItem) => {
  const homeTeam = getTeam(match, TeamSide.HOME);
  const homeTeamId = homeTeam ? homeTeam._id : undefined;
  const awayTeam = getTeam(match, TeamSide.AWAY);
  const awayTeamId = awayTeam ? awayTeam._id : undefined;

  return { homeTeamId, awayTeamId };
};

export const getGoalsByTeams = (match: IWidgetMatchesListMatchItem) => {
  let homeTeamGoals = 0;
  let awayTeamGoals = 0;
  if (match.contumationScore && match.contumationScore.length) {
    homeTeamGoals = match.contumationScore[0];
    awayTeamGoals = match.contumationScore[1];
  } else if (match.score && match.score.length) {
    homeTeamGoals = match.score[0];
    awayTeamGoals = match.score[1];
  }
  return { homeTeamGoals, awayTeamGoals };
};

export const getSportSectorPhases = (
  sportSectorPhases: ISportSectorPhasesItem[],
  sport: string
) => {
  const phases =
    sportSectorPhases && (sportSectorPhases || []).find((i) => i._id === sport);
  return phases ? phases.items : [];
};

export interface ILoadMatchesParams {
  dateFrom?: Date;
  dateTo?: Date;
  offset?: number;
  sorter?:
    | 'dateFromAsc'
    | 'dateFromDesc'
    | 'roundDateFromAsc'
    | 'roundDateFromDesc';
}

/**
 * Returns matches of current month, defined by date in `date` input variable.
 *
 * @param widget Matches list widget data (dateFrom, dateTo is ignored)
 * @param date Date from which start and end of the month will be calculated.
 * @returns
 */
export const getMatches = async (
  widget: IData,
  loadMatchsParams: ILoadMatchesParams
) => {
  const params: IGetMatchesParams = {};
  if (widget.competitionId) {
    params.competitionId = widget.competitionId;
  }
  if (widget.partId) {
    params.partId = widget.partId;
  }
  if (widget.roundId) {
    params.roundId = widget.roundId;
  }

  if (typeof widget.closed !== 'undefined') {
    params.closed = widget.closed === 'true' ? true : false;
  }

  // filter podla id zvolenych teamov. osetrena je aj spatna kompatibilita, kedy
  // filter podporoval volbu len jedneho teamu. do requestu idu teamy stale ako
  // pole.
  const teamIds = [widget.teamId, ...(widget.teamIds || [])].filter(
    Boolean
  ) as string[];
  if (teamIds.length) {
    params.teamIds = teamIds;
  }

  params.dateFrom = loadMatchsParams.dateFrom
    ? format(loadMatchsParams.dateFrom, 'yyyy-MM-dd')
    : widget.dateFrom;
  params.dateTo = loadMatchsParams.dateTo
    ? format(loadMatchsParams.dateTo, 'yyyy-MM-dd')
    : widget.dateTo;

  if (typeof widget.limit !== 'undefined') {
    params.limit = widget.limit;
  }

  if (typeof loadMatchsParams.offset !== 'undefined') {
    params.offset = loadMatchsParams.offset;
  }

  if (widget.sorter) {
    params.sorter = widget.sorter;
  }

  const matchesResponse = await CompetitionsApi.getMatches(params);

  return {
    matches:
      matchesResponse.matches as unknown as IWidgetMatchesListMatchItem[],
    total: matchesResponse.total!,
    nextOffset: matchesResponse.nextOffset!,
  };
};

export const cleanRoundName = (roundName: string) => {
  const stepOne = roundName.replace(/(\.){0,1}\s*kolo/g, '').trim();
  return isNaN(Number(stepOne)) ? stepOne : `${stepOne}. kolo`;
};

export const getFlagUrl = (country: string, staticFilesBaseUrl?: string) => {
  return country
    ? `https://api.sportnet.online/assets/countries/${country.toUpperCase()}_square.png`
    : getFallbackLogoImage(staticFilesBaseUrl);
};

export const getPpoLogoSrc = (
  { _id }: { _id?: string },
  staticFilesBaseUrl?: string
) => {
  return _id
    ? `https://api.sportnet.online/data/ppo/${_id}/logo`
    : getFallbackLogoImage(staticFilesBaseUrl);
};

export const is_NOT_FutbalnetMatch = (appSpace: string) => {
  return ['uefa', 'iihf', 'amaury-sport-organisation---cyklistika'].includes(
    appSpace
  );
};

/**
 * Returns src of team logo. If match is "repre", returns flag of the country. If
 * it is match of home competition, returns team logo or PPO logo.
 *
 * @param _matchAppSpace
 * @param team
 */
export const getTeamLogoSrc = (
  matchAppSpace: string,
  isCompetitionInterstate: boolean,
  team?: ITeam | null,
  staticFilesBaseUrl?: string
) => {
  if (!(team && team.organization)) {
    return getFallbackLogoImage(staticFilesBaseUrl);
  }

  if (isCompetitionInterstate) {
    return getFlagUrl(team.organization.country || '');
  } else if (is_NOT_FutbalnetMatch(matchAppSpace)) {
    return getPpoLogoSrc(team.organization);
  } else {
    return getPpoLogoSrc(team.organization);
  }
};

/**
 * Returns name of the team. In case of repre, returns name of the country.
 * Fo other competititons, returns name of the organisation.
 *
 * @param matchAppSpace
 * @param team
 */
export const getTeamName = (
  matchAppSpace: string,
  isCompetitionInterstate: boolean,
  team?: ITeam | null
) => {
  if (!team) {
    return '';
  }
  if (isCompetitionInterstate) {
    return team.displayName || team.name;
  } else if (is_NOT_FutbalnetMatch(matchAppSpace)) {
    return team.displayName || team.name;
  }
  return team.organization?.name ?? '';
};

export const getFutbalnetMatchDetailUrl = (issfId: number) => {
  return `https://sportnet.sme.sk/futbalnet/z/sfz/zapas/${issfId}/`;
};
