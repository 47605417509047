import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { RootState } from '../../configureStore';
import UrlContext from '../../contexts/UrlContext';
import { authUserSelector } from '../../pages/App/selectors';
import { loadArticles } from '../../pages/Article/actions';
import { headerConfigurationSelector } from '../../pages/DomainResolver/selectors';
import { getCallbackAuthUrl, getCallbackLogoutUrl } from '../Authorization';
import context, { ISearchItem } from './context';
import HeaderSimple from './wrappers/HeaderSimple';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: !!authUserSelector(state),
    headerConfiguration: headerConfigurationSelector(state),
  };
};

const mapDispatchToProps = {
  loadArticles: thunkToAction(loadArticles.action),
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{}, {}> &
  typeof mapDispatchToProps;

const Header: React.FC<Props> = ({
  headerConfiguration,
  isLoggedIn,
  router,
  loadArticles,
}) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const type = getProp(headerConfiguration, ['type'], '');
  let component: React.ReactChild = <div />;
  switch (type) {
    case 'simple-1':
      component = <HeaderSimple />;
      break;
    default:
      break;
  }

  const ctxValue = React.useMemo(
    () => ({
      logout: isLoggedIn
        ? () => {
            window.location.href = getCallbackLogoutUrl();
          }
        : undefined,
      login: !isLoggedIn
        ? () => {
            window.location.href = getCallbackAuthUrl();
          }
        : undefined,
      getItems: async (q: string) => {
        const response = await loadArticles({
          q,
        });
        return response.original.map((a) => ({
          label: a.name || '',
          icon: a.picture === null ? undefined : a.picture,
          article: a,
        }));
      },
      onSearchSubmitItem: (item: ISearchItem) => {
        const url = getArticleUrl(item.article);
        if (url.startsWith('http')) {
          window.location.href = url;
        } else {
          router.push({
            pathname: url,
          });
        }
      },
    }),
    [isLoggedIn, loadArticles, router, getArticleUrl],
  );

  return <context.Provider value={ctxValue}>{component}</context.Provider>;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Header);
