import { rem, transparentize } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';
import Link from '../Link';

interface ItemLinkProps {
  color?: string;
  activeColor?: string;
}
const ItemLinkBase = ({ color, activeColor, ...rest }: ItemLinkProps) => (
  <Link {...rest} />
);
const ItemLink = styled(ItemLinkBase)<ItemLinkProps>`
  cursor: pointer;
  border: none;
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  outline: none;
  background: none;
  margin: 0;
  padding: ${rem(8)} ${rem(16)};
  min-height: ${rem(40)};
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  text-align: left;

  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  color: ${({ theme, color }) => color || theme.app.textColor};
  :hover,
  :active,
  :focus {
    color: ${({ theme, activeColor }) => activeColor || theme.color.primary};
    background-color: ${({ theme, activeColor }) =>
      transparentize(0.96, activeColor || theme.color.primary)};
  }
`;

const Li = styled.li`
  flex-shrink: 0;
`;

interface OwnProps {
  color?: string;
  activeColor?: string;
  className?: string;
  to?: string;
  onClick?: () => void;
}

const Item: React.FC<OwnProps> = ({
  className,
  children,
  color,
  activeColor,
  to,
  onClick,
}) => {
  return (
    <Li className={className}>
      <ItemLink
        to={to}
        onClick={onClick}
        color={color}
        activeColor={activeColor}
      >
        {children}
      </ItemLink>
    </Li>
  );
};

export default Item;
