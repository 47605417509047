import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle, ISection } from '../../../library/App';
import styled from '../../../theme/styled-components';
import distanceInWordsToNow from '../../../utilities/distanceInWordsToNow';
import Link from '../../Link';
import Icon from '../../Icon';
import ArticleImage from '../ArticleImage';
import Authors from '../Authors';

const ResponsiveArticleImage = styled(ArticleImage)`
  padding-top: ${100 / (16 / 9)}%;
  margin-bottom: ${rem(16)};
  ${mb('s')} {
    padding-top: ${100 / (4 / 3)}%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;
  margin-bottom: 0;
`;

const ArticleTitle = styled.div`
  font-family: pt-serif-pro, serif;
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  margin-bottom: ${rem(8)};
  ${mb('s')} {
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    margin-bottom: ${rem(12)};
  }
`;

const ArticleLink = styled(Link)`
  border: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const AuthorLink = styled(Link)`
  &:any-link {
    cursor: pointer;
    :hover,
    :active,
    :focus {
      text-decoration: underline;
    }
  }
`;
const DiscussionLink = styled(Link)`
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

const ArticleDescription = styled.div`
  margin: 0 0 ${rem(8)} 0;
  font-size: ${rem(12)};
  line-height: ${rem(17)};
  font-family: ${({ theme }) => theme.primaryFont.family};
  text-align: left;
  font-weight: 600;
  color: #333333;
  opacity: 0.8;
  ${mb('l')} {
    margin: 0 0 ${rem(16)} 0;
  }
`;

const Pipe = styled.span`
  margin: 0 ${rem(6)};
  opacity: 0.5;
`;

// const SectionLink = styled(Link)`
//   border: none;
//   text-decoration: none;
//   outline: none;
//   cursor: pointer;
//   :hover,
//   :active,
//   :focus {
//     text-decoration: underline;
//   }
// `;

// const PEREX_FONT_SIZE = 16;
// const PEREX_LINE_HEIGHT = 1.5;
// const PEREX_LINES = 3;
//
// const Perex = styled.p`
//   margin: ${rem(16)} 0 0 0;
//   padding: 0;
//   font-family: pt-serif-pro, serif;
//   font-size: ${rem(PEREX_FONT_SIZE)};
//   color: ${({ theme }) => theme.app.textColor};
//   font-weight: 300;
//   line-height: ${PEREX_LINE_HEIGHT};

//   max-width: 100%;
//   max-height: ${rem(
//     getMultiLineClapHeight(PEREX_FONT_SIZE, PEREX_LINE_HEIGHT, PEREX_LINES),
//   )};
//   -webkit-line-clamp: ${PEREX_LINES};
//   -webkit-box-orient: vertical;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   display: none;
//   ${mb('l')} {
//     display: block;
//     display: -webkit-box;
//   }
// `;

const DiscussionLogo = styled.span`
  vertical-align: middle;
  margin-bottom: 4px;
`;

const DiscussionNumber = styled.span<{ number: number }>`
  color: ${(props) => (props.number > 20 ? '#CF2528' : '#8d8d8d')};
  padding-left: ${rem(4)};
  opacity: 1;
`;

const ArticleDate = styled.span`
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  opacity: 0.56;
  color: #333333;
  font-weight: normal;
`;

type IOwnProps = {
  article: IArticle;
  section: ISection | null;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article, section }) => {
  const { getArticleUrl, getArticleDiscussionUrl } =
    React.useContext(UrlContext);

  const articleDate = new Date(
    article.updated_at || article.valid_from || article.created || '',
  );

  const discussion = article.discussion?.postCount || 0;

  return (
    <Wrapper>
      <ArticleLink to={getArticleUrl(article)} title={article.name}>
        <ResponsiveArticleImage
          article={article}
          naturalWidth={418 * 2}
          naturalHeight={314 * 2}
        />
        <ArticleTitle>{article.name}</ArticleTitle>
      </ArticleLink>
      <ArticleDescription>
        <Authors
          createAuthorsLink={[
            'pages:sportnet.sme.sk:default',
            'pages:beta.sportnet.sme.sk:default',
          ].includes(article.contentspace ?? '')}
          authors={article.authors}
          AuthorWrapper={AuthorLink}
          after={<Pipe>∙</Pipe>}
        />
        <ArticleDate>{distanceInWordsToNow(articleDate)}</ArticleDate>
        {discussion > 0 && (
          <>
            <Pipe>∙</Pipe>
            <DiscussionLink to={getArticleDiscussionUrl(article)}>
              <DiscussionLogo>
                <Icon
                  name="discussion"
                  size={12}
                  color={discussion > 20 ? '#CF2528' : '#8d8d8d'}
                />
              </DiscussionLogo>
              <DiscussionNumber number={discussion}>
                {discussion}
              </DiscussionNumber>
            </DiscussionLink>
          </>
        )}
      </ArticleDescription>
    </Wrapper>
  );
};

export default React.memo(Article);
