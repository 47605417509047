import { rem } from 'polished';
import * as React from 'react';
import styled from '../../../../theme/styled-components';
import OriginalIcon from '../../../Icon';

const InputIcon: React.FC<any> = (props) => (
  <OriginalIcon {...props} className={`input-icon ${props.className}`} />
);

export default styled(InputIcon)`
  padding: 0 ${rem(6)};
`;
