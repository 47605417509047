import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import NotFoundError from '../../NotFoundError';
import getAppSpaceFromUrl from '../../utilities/getAppSpaceFromUrl';
import isBrowser from '../../utilities/isBrowser';
import { initializeOrSetListParams, loadSectionTree } from '../App/actions';
import { setCurrentAppSpace } from '../Competition/actions';
import { currentCompetitionAppSpaceSelector } from '../Competition/selectors';
import NotFound from '../NotFound';

const mapStateToProps = (state: RootState) => ({
  currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ klub: string; tim?: string }, {}> & {
    dispatch: CustomThunkDispatch;
  };

class Club extends React.PureComponent<Props, { isInitialized: boolean }> {
  constructor (props: Props) {
    super(props);
    this.state = {
      isInitialized: false,
    }
  }
  static async getInitialProps(props: Props) {
    const {
      dispatch,
      params: { klub },
    } = props;
    const appSpace = getAppSpaceFromUrl('club', klub) || klub;

    if (!appSpace) {
      dispatch(setCurrentAppSpace(''));
      throw new NotFoundError(null);
    }

    dispatch(setCurrentAppSpace(appSpace));
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_CLUB_COMPETITIONS,
        params: { appSpace },
      }),
    );
    await dispatch(
      thunkToAction((loadSectionTree as any)({ appSpace }) as any),
    );
  }

  async componentDidMount() {
    this.setState({
      isInitialized: false
    })
    try {
      await Club.getInitialProps(this.props);
    } catch (e) {
      //
    } finally {
      this.setState({
        isInitialized: true
      })
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.klub !== prevProps.params.klub) {
      this.setState({ isInitialized: false });
      try {
        await Club.getInitialProps(this.props);
      } catch (e) {
        //
      } finally {
        this.setState({
          isInitialized: true
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setCurrentAppSpace(''));
  }

  render() {
    const { children, currentCompetitionAppSpace } = this.props;

    if (isBrowser() && !this.state.isInitialized) {
      return null;
    }

    if (!currentCompetitionAppSpace) {
      return <NotFound />;
    }

    return (
      <>
     
      {children}
    </>
    )
  }
}

export default compose(connect(mapStateToProps))(Club);
