import { IMatch } from '../../library/Competitions';
import { isAfter, isSameDay, sub } from 'date-fns';
import { rem } from 'polished';
import Event from './Event';
import Icon, { IIconName as IconName } from '../../components/Icon';
import Match from './Match';
import Race from './Race';
import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 0;
  .slick-slide > div > div {
    border-left: ${({ theme }) => theme.border.normalLight};
  }
  .slick-slider {
    display: flex;
  }
  > div {
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;
          > div {
            height: 100%;
            > div {
              height: 100%;
            }
          }
        }
      }
    }
  }
`;

const Arrow = styled.button<{
  $slickDisabled: boolean;
  $position: 'left' | 'right';
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${rem(32)};
  cursor: pointer;
  outline: none;
  border: none;
  border-left: ${({ theme, $position }) =>
    $position === 'left' ? 'none' : theme.border.normalLight};
  padding: 0;
  margin: 0;
  background-color: transparent;
  ${mb('m')} {
    display: flex;
    border-left: ${({ theme }) => theme.border.normalLight};
    border-right: ${({ theme, $position }) =>
      $position === 'left' ? 'none' : theme.border.normalLight};
    width: ${rem(40)};
  }

  .icon {
    opacity: ${({ $slickDisabled = false }) => ($slickDisabled ? 0.4 : 1)};
  }
`;

interface IArrowProps {
  onClick?: () => void;
  className?: string;
  name: IconName;
}

function SlickArrow({ onClick, className = '', name }: IArrowProps) {
  const disabled = className.includes('slick-disabled');
  return (
    <Arrow
      onClick={onClick}
      $slickDisabled={disabled}
      $position={name === 'arrow-right' ? 'right' : 'left'}
    >
      <Icon name={name} size={16} />
    </Arrow>
  );
}

const sliderSettings = {
  dots: false,
  draggable: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  swipe: true,
  swipeToSlide: true,
  touchMove: true,
  centerMode: false,
  centerPadding: '',
  fade: false,
  nextArrow: <SlickArrow name="arrow-right" />,
  prevArrow: <SlickArrow name="arrow-left" />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1032,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 868,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 766,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 596,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 378,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

type IOwnProps = {
  matches: IMatch[];
};

type IProps = IOwnProps;

const MatchesSlider: React.FC<IProps> = ({ matches }) => {
  const slider = React.useRef<Slider | null>(null);
  const now = new Date();
  let futureMatchIndex = matches.findIndex((m) => {
    const startDate = new Date(m.startDate);
    // ak nie je dnesny datum preskakujem
    if (!isSameDay(startDate, now)) {
      return false;
    }
    // ak sa zapas prave hra, zobrazim ho
    if (!m.closed && isAfter(now, startDate)) {
      return true;
    }
    // ak nie je uzavrety a ani sa nehra - zobrazim neuzavrety ak je hodinu pred zaciatkom
    if (!m.closed && isAfter(now, sub(startDate, { hours: 1 }))) {
      return true;
    }
    return false;
  });
  // nemame co zobrazit, zobrazime prvy dnesny uzavrety zapas
  if (futureMatchIndex === -1) {
    // pohladame zapas ktory zacal pred tromi hodinami
    futureMatchIndex = matches.findIndex((m) => {
      // ak nie je dnesny datum preskakujem
      if (
        isSameDay(new Date(m.startDate), now) &&
        isAfter(new Date(m.startDate), sub(now, { hours: 3 }))
      ) {
        return true;
      }
      return false;
    });
    // totalny fallback na predchadzajuce dva zapasy od najblizsieho
    if (futureMatchIndex === -1) {
      futureMatchIndex = matches.findIndex((m) => {
        if (!m.closed) {
          return true;
        }
        return false;
      });

      // bud zobrazujem dva zapasu od neuzavreteho, alebo ak su vsetky uzavrete,
      // tak posledny zapas.
      if (futureMatchIndex > -1) {
        futureMatchIndex = futureMatchIndex - 2;
      } else {
        futureMatchIndex = matches.length - 1;
      }
    }
  }
  const initialSlide = futureMatchIndex >= 0 ? futureMatchIndex : 0;
  React.useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(initialSlide, true);
    }
  }, [initialSlide, slider]);
  return (
    <SliderWrapper>
      <Slider ref={slider} {...sliderSettings} initialSlide={initialSlide}>
        {matches.map((match, i) => {
          if (match.rules && match.rules.sport_sector === 'cestna-cyklistika') {
            return (
              <div key={match._id}>
                <Race match={match}>{i}</Race>
              </div>
            );
          } else if (match.rules && match.rules.sport_sector === 'other') {
            return (
              <div key={match._id}>
                <Event match={match}>{i}</Event>
              </div>
            );
          }
          return (
            <div key={match._id}>
              <Match match={match}>{i}</Match>
            </div>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default React.memo(MatchesSlider);
