import React from 'react';

export default (size: string, color: string) => {
  const color1 = '#5283e7';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 12.170834 12.170834"
      width={size}
      height={size}
    >
      <g transform="translate(-31.750165,-100.12606)">
        <path
          fill={color1}
          transform="matrix(0.26458333,0,0,0.26458333,31.749648,38.741174)"
          d="m 5.7519531,232.00586 a 5.7519999,5.7519999 0 0 0 -5.74999997,5.75 v 34.5 a 5.7519999,5.7519999 0 0 0 5.74999997,5.75 H 40.251953 a 5.7519999,5.7519999 0 0 0 5.75,-5.75 v -34.5 a 5.7519999,5.7519999 0 0 0 -5.75,-5.75 z m 5.8222659,11.57226 h 22.855469 a 2.8651878,2.8651878 0 0 1 2.855468,2.85547 v 17.14258 a 2.8651878,2.8651878 0 0 1 -2.855468,2.85547 H 11.574219 a 2.8651878,2.8651878 0 0 1 -2.8574221,-2.85547 l 0.013672,-17.14258 a 2.8531889,2.8531889 0 0 1 2.8437501,-2.85547 z m 0,2.85547 v 2.85938 l 11.427734,7.14062 11.427735,-7.14062 v -2.85938 L 23,253.57617 Z"
          style={{ opacity: 1, strokeWidth: 0.999999, stopOpacity: 1 }}
        />
      </g>
    </svg>
  );
};
