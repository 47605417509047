import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import NotFoundError from '../../NotFoundError';
import getAppSpaceFromUrl from '../../utilities/getAppSpaceFromUrl';
import isBrowser from '../../utilities/isBrowser';
import { loadSectionTree } from '../App/actions';
import { setCurrentAppSpace } from '../Competition/actions';
import { currentCompetitionAppSpaceSelector } from '../Competition/selectors';
import NotFound from '../NotFound';

const mapStateToProps = (state: RootState) => ({
  currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string }, {}> & {
    dispatch: CustomThunkDispatch;
  };

class Union extends React.PureComponent<Props, { isInitialized: boolean }> {
  constructor (props: Props) {
    super(props);
    this.state = {
      isInitialized: false,
    }
  }
  static async getInitialProps(props: Props) {
    const {
      params: { zvaz },
      dispatch,
    } = props;
    dispatch(setCurrentAppSpace(''));
    const appSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

    if (!appSpace) {
      dispatch(setCurrentAppSpace(''));
      throw new NotFoundError(null);
    }

    dispatch(setCurrentAppSpace(appSpace));
    await dispatch(
      thunkToAction((loadSectionTree as any)({ appSpace })) as any,
    );
  }

  async componentDidMount() {
    this.setState({
      isInitialized: false
    })
    try {
      await Union.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    } finally {
      this.setState({
        isInitialized: true,
      });
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.zvaz !== prevProps.params.zvaz) {
      try {
        this.setState({
          isInitialized: false
        })
        await Union.getInitialProps(this.props);
      } catch (e: any) {
        //
      } finally {
        this.setState({
          isInitialized: true,
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setCurrentAppSpace(''));
  }

  render() {
    const { children, currentCompetitionAppSpace } = this.props;

    if (isBrowser() && !this.state.isInitialized) {
      return null;
    }

    if (!currentCompetitionAppSpace) {
      return <NotFound />;
    }

    return children;
  }
}

export default compose(connect(mapStateToProps))(Union);
