import { __ } from '@sportnet/content/utilities/utilities';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import { getProp } from 'sportnet-utilities';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import {
  getSportSectorsCrew,
  getSportSectorsDelegates,
  getSportSectorsEvents,
  getSportSectorsInternetBroadcasters,
  getSportSectorsPhases,
  getSportSectorsSettings,
  getSportSectorsTelevisionBroadcasters,
  initializeOrSetListParams,
} from '../App/actions';
import { authUserResolvedSelector, authUserSelector } from '../App/selectors';
import {
  appSettingsSelector,
  layoutSettingsSelector,
} from '../DomainResolver/selectors';
import { loadCompetitionsList, loadUnions, loadUnionSeasons } from './actions';
import { unionsIsFetchingSelector, unionsSelector } from './selectors';

const mapStateToProps = (state: RootState) => ({
  isFetchingSeasonCompetitions: isCommiting(
    config.LIST_COMPETITIONS_BY_SEASON_NAME
  )(state),
  isFetchingUnions: unionsIsFetchingSelector(state),
  unions: unionsSelector(state),
  appSettings: appSettingsSelector(state),
  authUser: authUserSelector(state),
  authUserResolved: authUserResolvedSelector(state),
  layoutSettings: layoutSettingsSelector(state),
});

type Props = {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}>;

class FutbalnetApp extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    await props.dispatch(loadUnions.action());
    await props.dispatch(loadUnionSeasons.action());
    await props.dispatch(
      initializeOrSetListParams({
        listName: config.LIST_COMPETITIONS,
        params: {
          seasonNames: config.ACTIVE_SEASON_NAMES,
        },
      })
    );
    await props.dispatch(loadCompetitionsList(config.LIST_COMPETITIONS));
    let promises: any = [];
    config.SPORT_SECTORS.forEach((sportSector) => {
      promises = [
        ...promises,
        ...[
          props.dispatch(getSportSectorsPhases({ sportSector })),
          props.dispatch(getSportSectorsEvents({ sportSector })),
          props.dispatch(getSportSectorsDelegates({ sportSector })),
          props.dispatch(getSportSectorsCrew({ sportSector })),
          props.dispatch(getSportSectorsSettings({ sportSector })),
          props.dispatch(
            getSportSectorsTelevisionBroadcasters({ sportSector })
          ),
          props.dispatch(getSportSectorsInternetBroadcasters({ sportSector })),
        ],
      ];
    });
    await Promise.all(promises);
  }

  async componentDidMount() {
    await FutbalnetApp.getInitialProps(this.props);
  }

  render() {
    const { appSettings, layoutSettings } = this.props;

    const title = getProp(layoutSettings, ['pageTitle'], '');

    return (
      <>
        <Helmet titleTemplate={`%s | ${title}`} defaultTitle={title}>
          <meta
            property="og:url"
            content={`${appSettings.baseUri}/futbalnet/`}
          />
          <title>{__('Futbalnet - všetky góly v jednej sieti')}</title>
          <meta
            property="og:title"
            content={__('Futbalnet - všetky góly v jednej sieti')}
          />
          <meta
            name="description"
            content={__(
              'Slovenský futbal na jednom mieste. Pozrite, ako sa vyvíja Niké liga, okresné futbalové súťaže. Všetky výsledky a štatistiky na jednom mieste.'
            )}
          />
          <meta
            property="og:description"
            content={__(
              'Slovenský futbal na jednom mieste. Pozrite, ako sa vyvíja Niké liga, okresné futbalové súťaže. Všetky výsledky a štatistiky na jednom mieste.'
            )}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`${appSettings.baseUri}/futbalnet/futbalnet-logo.png`}
          />
        </Helmet>
        {this.props.children}
      </>
    );
  }
}

export default compose(connect(mapStateToProps))(FutbalnetApp);
