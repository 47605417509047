import { isAfter, isSameDay, sub } from 'date-fns';
import { darken, rem } from 'polished';
import React from 'react';
import Slider from 'react-slick';
import Icon, { IIconName } from '../../../components/Icon';
import { IMatch } from '../../../library/Competitions';
import styled from '../../../theme/styled-components';
import Event from './Event';
import Match from '../Match';
import Race from '../Race';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const SliderWrapper = styled.div`
  height: ${rem(48)};
  max-width: 100%;
  overflow: hidden;
  border-width: ${rem(1)} ${rem(1)} 0 ${rem(1)};
  border-radius: 0;

  border-style: solid;
  border-color: ${({ theme }) => theme.color.sportnetGray};

  .slick-slide:not(:first-child) > div > div {
    border-left: ${rem(1)} solid #dddddd;
  }
  .slick-slider {
    display: flex;
  }
  ${mb('m')} {
    max-width: 33%;
    border-width: ${rem(1)} ${rem(1)} 0 0;
    border-radius: 0 ${rem(4)} 0 0;
  }
`;

const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: ${rem(32)};
  cursor: pointer;
  outline: none;
  border: none;
  background: #f9f9f9;
  &:hover {
    background: ${darken(0.05, '#f9f9f9')};
  }
  padding: 0;
  margin: 0;
`;

interface IArrowProps {
  onClick?: () => void;
  className?: string;
  name: IIconName;
}

function SlickArrow({ onClick, className = '', name }: IArrowProps) {
  const disabled = className.includes('slick-disabled');
  return (
    <Arrow onClick={onClick}>
      <Icon name={name} size={16} style={{ opacity: disabled ? 0.4 : 1 }} />
    </Arrow>
  );
}

const sliderSettings = {
  dots: false,
  draggable: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: true,
  touchMove: true,
  centerMode: false,
  centerPadding: '0',
  nextArrow: <SlickArrow name="arrow-right" />,
  prevArrow: <SlickArrow name="arrow-left" />,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type IOwnProps = {
  matches: IMatch[];
};

type IProps = IOwnProps;

const MatchesSlider: React.FC<IProps> = ({ matches }) => {
  const slider = React.useRef<Slider | null>(null);
  const now = new Date();
  let futureMatchIndex = matches.findIndex((m) => {
    const startDate = new Date(m.startDate);
    // ak nie je dnesny datum preskakujem
    if (!isSameDay(startDate, now)) {
      return false;
    }
    // ak sa zapas prave hra, zobrazim ho
    if (!m.closed && isAfter(now, startDate)) {
      return true;
    }
    // ak nie je uzavrety a ani sa nehra - zobrazim neuzavrety ak je hodinu pred zaciatkom
    if (!m.closed && isAfter(now, sub(startDate, { hours: 1 }))) {
      return true;
    }
    // FIX pre ZOH 2022
    // ak je sport_sector oter a je dnesny den zobrazim
    if (m.rules?.sport_sector === 'other' && isSameDay(startDate, now)) {
      return true;
    }
    return false;
  });
  // nemame co zobrazit, zobrazime prvy dnesny uzavrety zapas
  if (futureMatchIndex === -1) {
    // pohladame zapas ktory zacal pred tromi hodinami
    futureMatchIndex = matches.findIndex((m) => {
      // ak nie je dnesny datum preskakujem
      if (
        isSameDay(new Date(m.startDate), now) &&
        isAfter(new Date(m.startDate), sub(now, { hours: 3 }))
      ) {
        return true;
      }
      return false;
    });
    // totalny fallback na predchadzajuce dva zapasy od najblizsieho
    if (futureMatchIndex === -1) {
      futureMatchIndex =
        matches.findIndex((m) => {
          // ak nie je dnesny datum preskakujem
          if (!m.closed) {
            return true;
          }
          return false;
        }) - 2;
    }
  }
  const initialSlide = futureMatchIndex >= 0 ? futureMatchIndex : 0;
  React.useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(initialSlide, true);
    }
  }, [initialSlide, slider]);
  return (
    <SliderWrapper>
      <Slider ref={slider} {...sliderSettings} initialSlide={initialSlide}>
        {matches.map((match, i) => {
          if (match.rules && match.rules.sport_sector === 'cestna-cyklistika') {
            return (
              <div key={match._id}>
                <Race match={match}>{i}</Race>
              </div>
            );
          } else if (match.rules && match.rules.sport_sector === 'other') {
            return (
              <div key={match._id}>
                <Event match={match}>{i}</Event>
              </div>
            );
          }
          return (
            <div key={match._id}>
              <Match match={match}>{i}</Match>
            </div>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
};

export default React.memo(MatchesSlider);
