import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle, ISection } from '../../../library/App';
import { MediaManagerPhoto } from '../../../library/MediaManager';
import styled from '../../../theme/styled-components';
import Icon from '../../Icon';
import Link from '../../Link';
import ArticleIcon from '../ArticleIcon';

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  width: ${rem(128)};

  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;

  margin-right: ${rem(16)};

  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const ArticleLink = styled(Link)`
  font-family: ${({ theme }) => theme.primaryFont.family };
  border: none;
  text-decoration: none;
  outline: none;
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const Header = styled.span`
  display: flex;
  flex-direction: column;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  &,
  & img {
    border-radius: ${rem(4)};
    width: ${rem(128)};
    ${mb('l')} {
      width: ${rem(160)};
    }
  }
`;

const ImageLink = styled(ArticleLink as any)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${rem(16)} ${rem(16)} 0;
  flex-shrink: 0;
  width: 100%;
`;

// const SectionLink = styled(Link)`
//   border: none;
//   text-decoration: none;
//   outline: none;
//   cursor: pointer;
//   :hover,
//   :active,
//   :focus {
//     text-decoration: underline;
//   }
// `;

const SpacedIcon = styled(Icon)`
  margin-right: ${em(4)};
`;

type IOwnProps = {
  article: IArticle;
  section: ISection | null;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article, section }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const description = ((article.picture as MediaManagerPhoto)?.description) || article.name;

  return (
    <Wrapper>
      <ImageLink to={getArticleUrl(article)} title={article.name}>
        <StyledMediaManagerImage
          picture={article.picture || {}}
          script="media"
          naturalWidth={130 * 2}
          naturalHeight={72 * 2}
          webp={true}
          alt={description}
          loading="lazy"
        />
        <ArticleIcon article={article} compactBreakPoint="l" />
      </ImageLink>
      <Header>
        <ArticleLink to={getArticleUrl(article)}>
          {article.is_private && <SpacedIcon name="premium" size={12} />}
          {article.name}
        </ArticleLink>
      </Header>
    </Wrapper>
  );
};

export default React.memo(Article);
