import { rem } from 'polished';
import styled, { css } from '../../../theme/styled-components';

import Ad from '../Ad';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

export default styled(Ad)`
  > .artemis-promo-labels {
    margin-top: 0;
    line-height: normal;
  }

  && {
    ${({ theme }) => css`
      width: calc(100% + ${({ theme }) => rem(theme.app.padding * 2)});
      margin-left: ${rem(-theme.app.padding)};
      margin-right: ${rem(-theme.app.padding)};
      background-color: #f1f1f1;
      margin-top: -16px;
    `};

    ${mb('s')} {
      margin: ${rem(16)} 0;
      width: calc(100% + ${({ theme }) => rem(theme.app.padding)});
      background-color: white;
    }

    &:after {
      content: '';
      height: ${rem(16)};
      background-color: #f1f1f1;
      width: 100%;
      position: absolute;
      left: 0;

      ${mb('s')} {
        height: 0;
        width: 0;
      }
    }
  }
`;
