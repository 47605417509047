import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import { SportSectorCodelistParams } from './getSportSectorCrew';
import { SPORT_SECTOR_DELEGATES } from '../../../../codelists';

const getSportSectorDelegates = createQueryFn(
  QueryNameSpace.codelistSportSetorDelegates,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, SportSectorCodelistParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters || !parameters.sportSector) {
      throw new Error('Must provide required parameters [matsportSectorchId]!');
    }

    const { sportSector } = parameters;

    // sport sector crew
    const sportSectorCrew = SPORT_SECTOR_DELEGATES.find(
      (setting) => setting._id === sportSector,
    );
    if (!sportSectorCrew) {
      throw new Error(
        `Sport sector crew not found by sport sector ${sportSector}`,
      );
    }

    return sportSectorCrew;
  },
);

export default getSportSectorDelegates;
