import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../theme/styled-components';
import Icon from '../Icon';

interface IWrapperProps {
  color?: string;
  activeColor?: string;
  onClick?: () => void;
}
const WrapperBase = ({ color, activeColor, onClick, ...rest }: IWrapperProps) =>
  onClick ? <button onClick={onClick} {...rest} /> : <div {...rest} />;
const Wrapper = styled(WrapperBase)<IWrapperProps>`
  padding: ${rem(16)} 0;
  margin: ${rem(8)} ${rem(16)} ${rem(16)} ${rem(16)};
  border: none;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  text-align: left;

  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  min-height: ${rem(52)};

  text-decoration: none;
  color: inherit;
  outline: none;
  background: none;

  color: ${({ theme, color }) => color || theme.app.textColor};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      :hover,
      :active,
      :focus {
        text-decoration: underline;
      }
    `}
`;

const LeftArrow = styled(Icon)`
  margin-right: ${rem(8)};
`;

interface OwnProps {
  color?: string;
  activeColor?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Header: React.FC<OwnProps> = ({
  className,
  children,
  color,
  activeColor,
  onClick,
}) => {
  return (
    <Wrapper
      className={className}
      onClick={onClick}
      color={color}
      activeColor={activeColor}
    >
      {onClick && <LeftArrow name="arrow-left" size={16} />}
      {children}
    </Wrapper>
  );
};

export default Header;
