import React from 'react';
import { IAdPosition } from '../AdProvider';
import isBrowser from '../../../utilities/isBrowser';

interface OwnProps {
  elementId: string; // must be unique on the page
  name: IAdPosition;
}

const AdReplica: React.FC<OwnProps> = ({ elementId, name }) => {
  const divRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (isBrowser() && divRef.current) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent('sportnet.smeAdvertAdapter.placeAd', {
            detail: { slotParent: name, divId: elementId },
          }),
        );
      }, 0);
    }
  }, [name, elementId, divRef]);
  
  return <div ref={divRef} id={elementId} />;
};

export default React.memo(AdReplica);
