export const SPORT_SECTOR_EVENTS = [
  {
    _id: "futbal",
    items: [
      { _id: "goal_game", label: "Gól z hry", eventType: "goal" },
      {
        _id: "goal_standard",
        label: "Gól zo štandardnej situácie",
        eventType: "goal",
      },
      { _id: "goal_penalty", label: "Pokutový kop", eventType: "goal" },
      {
        _id: "failed_goal_penalty",
        label: "Nepremenený pokutový kop",
        eventType: "failed_goal",
      },
      { _id: "dropped", label: "Vlastný gól", eventType: "goal" },
      { _id: "goal_contumation", label: "Kontumačný gól", eventType: "goal" },
      {
        _id: "goal_shootout",
        label: "Pokutový kop - penáltový rozstrel",
        eventType: "goal",
      },
      { _id: "substitution", label: "Striedanie", eventType: "substitution" },
      { _id: "yellow_card", label: "Žltá karta", eventType: "yellow_card" },
      {
        _id: "second_yellow_card",
        label: "Druhá žltá karta",
        eventType: "second_yellow_card",
      },
      { _id: "red_card", label: "Červená karta", eventType: "red_card" },
    ],
  },
  {
    _id: "beachfutbal",
    items: [
      { _id: "goal_game", label: "Gól z hry", eventType: "goal" },
      {
        _id: "goal_standard",
        label: "Gól zo štandardnej situácie",
        eventType: "goal",
      },
      { _id: "goal_penalty", label: "Pokutový kop", eventType: "goal" },
      { _id: "dropped", label: "Vlastný gól", eventType: "goal" },
      { _id: "goal_contumation", label: "Kontumačný gól", eventType: "goal" },
      {
        _id: "goal_shootout",
        label: "Pokutový kop - penáltový rozstrel",
        eventType: "goal",
      },
      { _id: "yellow_card", label: "Žltá karta", eventType: "yellow_card" },
      {
        _id: "second_yellow_card",
        label: "Druhá žltá karta",
        eventType: "second_yellow_card",
      },
      { _id: "red_card", label: "Červená karta", eventType: "red_card" },
    ],
  },
];
