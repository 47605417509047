import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { Link } from 'react-router';
import Icon from '../../components/Icon';
import { ContextMarginBottomStyles } from '../../components/Layout';
import { Td } from '../../components/Table';
import styled from '../../theme/styled-components';

export const H2 = styled.h2`
  font-size: ${rem(24)};
  font-weight: 600;
  line-height: ${rem(28.8)};
  text-transform: uppercase;
  padding: ${rem(0)} 0 ${rem(24)};
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const H3 = styled.h3`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  padding: ${rem(24)} 0 0;
  margin: 0;
  margin-bottom: ${rem(24)};
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const ResolutionNumber = styled(H3)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const ResolutionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(32)};
`;

export const ResolutionDetailTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0;
`;

export const ResolutionDetailText = styled.p`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
`;

export const ResolutionDetailBackLink = styled(Link)`
  font-size: ${rem(14)};
  :hover {
    color: ${({ theme }) => theme.color.danger};
  }
`;

export const ResolutionListTitle = styled(H2)`
  padding: 0;
`;

export const TdDecisionItem = styled(Td)`
  padding-top: ${rem(24)};
  padding-bottom: ${rem(24)};
`;

export const MembersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Member = styled.div`
  flex-basis: 25%;
`;

export const Secretary = styled(Member)``;

export const TableWrapper = styled.div`
  margin: 0 -${rem(16)};
  margin-bottom: ${rem(16)};
  ${mb('m')} {
    margin: 0;
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: underline;
`;

// na detaile
export const ResolutionDetailTitle = styled.p`
  font-size: $rem(14);
  font-weight: 700;
  margin-top: ${rem(0)};
  margin-bottom: ${rem(16)};
  padding: 0;
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const FilterWrapper = styled.div`
  display: flex;
  margin-bottom: 0;
`;

export const MoreIcon = styled(Icon)`
 
`;

export const FilterButton = styled.button`
  width: 100%;
  cursor: pointer;
  padding: ${rem(8)} 0;
  font-size: ${rem(12)};
  display: flex;
  justify-content: space-between;
  ${mb('m')} {
    border-top: 0;
    border-bottom: 0;
    font-size: ${rem(18)};
    justify-content: flex-start;
  }
  align-items: center;
  background: none;
  border: none;
  outline: none;
  font-weight: normal;
  font-family: ${({ theme }) => theme.primaryFont.family };
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Label = styled.div`
  color: #aaaaaa;
  font-size: ${rem(12)};
`;

export const RowWrapper = styled.div`
  ${ContextMarginBottomStyles}
`;
