import CoreApi from '../api/CoreApi';
import config from '../config';
import { AsyncReturnType } from '../library/App';

export default (
  user: AsyncReturnType<typeof CoreApi['organizationPPOUser']>,
): AsyncReturnType<typeof CoreApi['organizationPPOUser']> => {
  // filtrujem BIO podla PPO futbalsfz.sk
  const bio = (user.bio || []).find(
    (b) => b.org_profile_id === config.OWNER_PPO,
  );

  // filrujem regnrs podla PPO futbalsfz.sk
  const regnrs = (user.regnrs || []).find(
    (r) => (r as any).org_profile_id === config.OWNER_PPO,
  );

  return {
    ...user,
    bio: bio ? [bio] : [],
    regnrs: regnrs ? [regnrs] : [],
  };
};
