import React from 'react';
import { FeedItemInCache } from './features';
import UrlContext from '../../../contexts/UrlContext';
import Link from '../../../components/Link';

interface OwnProps {
  authors: Required<FeedItemInCache>['authors'];
}

const GossipAuthors: React.FC<OwnProps> = ({ authors }) => {
  const { getAuthorUrl } = React.useContext(UrlContext);
  if (Array.isArray(authors) && authors.length > 0) {
    const authorsToRender = authors.reduce((acc, author, idx) => {
      if (author.sportnetId) {
        acc.push({
          url: getAuthorUrl({
            name: author.displayName,
            sportnetId: author.sportnetId,
          }),
          key: `author-${author.sportnetId}`,
          displayName: author.displayName,
        });
      } else {
        acc.push({
          key: `author-${author.displayName}`,
          displayName: author.displayName,
        });
      }
      return acc;
    }, [] as { displayName: string; key: string; url?: string }[]);

    return (
      <>
        {authorsToRender.map((author, idx) => {
          const isLast = idx === authorsToRender.length - 1;
          if (author.url) {
            return (
              <React.Fragment key={author.key}>
                <Link to={author.url}>{author.displayName}</Link>
                {!isLast && <>, </>}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={author.key}>
                {author.displayName}
                {!isLast && <>, </>}
              </React.Fragment>
            );
          }
        })}
      </>
    );
  }

  return null;
};

export default GossipAuthors;
