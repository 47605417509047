import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Loading from '../../components/Loading';
import SectionMeta from '../../components/Sections/SectionMeta';
import { ReduxConnectProps, RootState } from '../../configureStore';
import RempIntegration from '../../containers/RempIntegration';
import { ISection, RouteProps, SectionId } from '../../library/App';
import getIdFromProps from '../../utilities/getIdFromProps';
import getSectionCanonicalUrl from '../../utilities/getSectionCanonicalUrl';
import { selectReduxValue } from '../App/actions';
import { authUserResolvedSelector } from '../App/selectors';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';
import Forbidden from '../Forbidden';
import NotFound from '../NotFound';
import Redirect from '../Redirect';
import { setCurrentSectionId } from '../Section/actions';
import {
  currentSectionErrorSelector,
  currentSectionSelector,
} from '../Section/selectors';
import Unauthorized from '../Unauthorized';
import { loadFutbalnetSection } from './actions';
import MainSection from './pages/MainSection';

interface OwnProps {
  id: SectionId;
}

type IRouteProps = RouteProps<{ id?: string }>;

const mapStateToProps = (state: RootState, props: OwnProps & IRouteProps) => {
  return {
    section: currentSectionSelector(state),
    appSpace: getCurrentCompetitionAppSpaceSelector(state),
    error: currentSectionErrorSelector(state),
    authUserResolved: authUserResolvedSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IRouteProps & IMapStateToProps & ReduxConnectProps;

class Section extends React.PureComponent<Props> {
  static async init(props: Props) {
    const { dispatch, authUserResolved } = props;
    const id = getIdFromProps(props);
    dispatch(setCurrentSectionId(id));
    return authUserResolved ? dispatch(loadFutbalnetSection(String(id))) : null;
  }

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    // get redux props during SSR
    const enhanceProps = dispatch(
      selectReduxValue((state) => mapStateToProps(state, props)),
    );
    const enhancedProps = { ...props, ...enhanceProps };

    return Section.init(enhancedProps);
  }

  async componentDidMount() {
    try {
      await Section.init(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      getIdFromProps(prevProps) !== getIdFromProps(this.props) ||
      prevProps.authUserResolved !== this.props.authUserResolved
    ) {
      try {
        await Section.init(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentSectionId(null));
  }

  render() {
    const { section, appSpace, error, location } = this.props;

    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }

    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }

    if (error && error.identifier === 'REDIRECT') {
      return <Redirect to={error.payload.location} />;
    }

    if (error) {
      return <NotFound />;
    }

    if (!section) {
      return <Loading />;
    }

    return (
      <>
        <SectionMeta
          section={section as ISection}
          canonicalUrl={getSectionCanonicalUrl(section, location.search)}
        />
        <RempIntegration destroy />
        {/* <RempIntegration /> */}
        <MainSection section={section as ISection} appSpace={appSpace} />
      </>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Section) as any;
