import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { getProp } from 'sportnet-utilities';
import { ReduxConnectProps, RootState } from '../../configureStore';
import DispatchContext from '../../contexts/DispatchContext';
import { RecursivePartial } from '../../library/App';
import GlobalStyle from '../../theme/globalStyles';
import {
  sportnetUiTheme,
  ThemeProvider as SportnetUiThemeProvider,
} from '../../theme/sportnetUiTheme';
import { ThemeProvider } from '../../theme/styled-components';
import { getTheme, ITheme } from '../../theme/theme';
import isBrowser from '../../utilities/isBrowser';
import isStandalone from '../../utilities/isStandalone';
import { setAppSettings } from './actions';
import Context from './context';
import { layoutSettingsSelector } from './selectors';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    layoutSettings: layoutSettingsSelector(state),
  };
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReduxConnectProps;

interface IState {
  editModeEnabled: boolean;
}

class DomainResolver extends React.PureComponent<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editModeEnabled: false,
    };
    if (!isStandalone() && isBrowser()) {
      window.addEventListener('message', this.handleMessage);
      this.postMessage('READY');
    }
  }

  postMessage = (type: string, payload = {}) => {
    window.parent.postMessage(
      {
        type,
        payload,
      },
      '*',
    );
  };

  handleMessage = (message: MessageEvent) => {
    const { dispatch } = this.props;
    // console.log('[pages public] <<<', message.data);
    if (message.data.type === 'UPDATE_SETTINGS') {
      dispatch(setAppSettings({ settings: message.data.payload }));
    } else if (message.data.type === 'SET_EDIT_MODE') {
      this.setState({ editModeEnabled: message.data.payload });
    }
  };

  render() {
    const { layoutSettings, dispatch } = this.props;
    const { editModeEnabled } = this.state;

    const theme = getProp(
      layoutSettings,
      ['theme'],
      {},
    ) as RecursivePartial<ITheme>;
    const title = getProp(layoutSettings, ['pageTitle'], '');

    return (
      <Context.Provider
        value={{
          isStandalone: isStandalone(),
          editModeEnabled,
          onClickArea: (id: string) => this.postMessage('CLICK_AREA', id),
        }}
      >
        <DispatchContext.Provider value={dispatch}>
          <SportnetUiThemeProvider theme={sportnetUiTheme}>
            <ThemeProvider theme={getTheme(theme)}>
              <>
                <Helmet titleTemplate={`%s | ${title}`} defaultTitle={title} />
                <GlobalStyle />
                {this.props.children}
              </>
            </ThemeProvider>
          </SportnetUiThemeProvider>
        </DispatchContext.Provider>
      </Context.Provider>
    );
  }
}

export default connect(mapStateToProps)(DomainResolver);
