import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M16,22c-0.6,0-1-0.4-1-1v-2c0-1.7-1.3-3-3-3H5c-1.7,0-3,1.3-3,3v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2
    c0-2.8,2.2-5,5-5h7c2.8,0,5,2.2,5,5v2C17,21.6,16.6,22,16,22z M13.5,7c0-2.8-2.2-5-5-5s-5,2.2-5,5s2.2,5,5,5S13.5,9.8,13.5,7z
     M11.5,7c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S11.5,5.3,11.5,7z M19.7,13.7l4-4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L19,11.6l-1.3-1.3
    c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l2,2c0.2,0.2,0.5,0.3,0.7,0.3S19.5,13.9,19.7,13.7z"
    />
  </svg>
);
