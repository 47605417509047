import { IWidgetSquad } from '@sportnet/content/utilities/library';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../../theme/styled-components';
import getEventMemberUrl from '../../../../utilities/events/getEventMemberUrl';
import Member from './Member';
import { prepareSquadWidgetData } from './utilities';
import { ITeam as ITeam2 } from '../../../../library/Competitions';
import getTeamUrl from '../../../../utilities/events/getTeamUrl';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  clear: both;
`;

const Title = styled.h3`
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0 0 ${rem(16)} 0;
  padding: 0;
`;

const Group = styled.div`
  :not(:last-child) {
    margin-bottom: ${rem(32)};
  }
`;

type IData = Omit<IWidgetSquad, 'type'> & { competitionId?: string };
interface IOwnProps {
  data: IData;
}

const WidgetSquad: React.FC<IOwnProps> = ({ data }) => {
  const groups = React.useMemo(() => {
    if (!data.appSpace) {
      return [];
    }
    return prepareSquadWidgetData(data.appSpace, data.data);
  }, [data]);

  if (!data.appSpace || !data.teamId) {
    return null;
  }

  const team = data.data?.team ?? null;

  const teamUrl = team
    ? getTeamUrl(team as unknown as ITeam2, data.competitionId)
    : '';

  return (
    <Wrapper className="content-widget">
      {groups.map((group) => (
        <Group key={`${data.teamId}:${group.position}`}>
          <Title>{group.positionCategory}</Title>
          {group.members.map((member, i) => {
            const url =
              teamUrl && member.sportnetUser
                ? getEventMemberUrl(teamUrl, member.sportnetUser)
                : undefined;
            return (
              <Member
                key={`${data.teamId}:${group.position}:${i}`}
                member={member}
                url={url}
              />
            );
          })}
        </Group>
      ))}
    </Wrapper>
  );
};

export default WidgetSquad;
