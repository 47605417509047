import React from 'react';
import { ShareButton } from './GossipShareDropdown';
import Icon from '../../../components/Icon';
import { useTheme } from 'styled-components';
import { ITheme } from '../../../theme/theme';
import copyToClipboard from 'clipboard-copy';

interface OwnProps {
  detailUrl: string;
}

const GossipShareCopyButton: React.FC<OwnProps> = ({ detailUrl }) => {
  const theme = useTheme() as ITheme;

  const [isCopyOk, setIsCopyOK] = React.useState(false);

  const handleShareButtonClick = async () => {
    setIsCopyOK(false);
    setTimeout(async () => {
      await copyToClipboard(detailUrl);
      setIsCopyOK(true);
      setTimeout(() => setIsCopyOK(false), 1500);
    }, 100);
  };

  return (
    <ShareButton onClick={handleShareButtonClick}>
      <Icon
        name={isCopyOk ? 'check' : 'link'}
        color={isCopyOk ? theme.color.success : theme.textColor}
      />
    </ShareButton>
  );
};

export default GossipShareCopyButton;
