import { rem } from 'polished';
import { useQ } from '../../../query';
import __ from '../../../utilities/__';
import listTransfers from '../features';
import TransferLoader from '../TransferLoader';
import { StyledTransfer } from '../TransfersList';
import styled from 'styled-components';
import { ContentContainer } from '../../../components/v2/ContentContainer';
import Link from '../../../components/Link';
import React from 'react';

const TransfersWidgetHeader = styled.div`
  padding: ${rem(16)};
  font-size: ${rem(16)};
  font-weight: bold;
  line-height: 1.3;
`;

const ShowMoreLink = styled(Link)`
  font-size: ${rem(13)};
  font-weight: 600;
  color: ${({ theme }) => theme.color.link};
  line-height: 1.3;
  padding: ${rem(16)} 0;
  display: block;
  text-align: center;
`;

const TransfersWidgetWrapper = styled(ContentContainer)`
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  }
`;

const TransfersWidget: React.FC<{ className?: string }> = ({ className }) => {
  const { data, isLoading: isLoadingQuery } = useQ(listTransfers, { limit: 5 });

  const isLoading = data === undefined || isLoadingQuery;

  return (
    <TransfersWidgetWrapper className={className}>
      <TransfersWidgetHeader>{__('Posledné prestupy')}</TransfersWidgetHeader>
      {isLoading ? (
        <>
          <TransferLoader skeletonClassName="skeleton" />
          <TransferLoader skeletonClassName="skeleton" />
          <TransferLoader skeletonClassName="skeleton" />
        </>
      ) : (
        <>
          {data.transfers.map((transfer) => (
            <StyledTransfer
              key={`transfer-${transfer._id}`}
              transfer={transfer}
              forceMobile
            />
          ))}
          <ShowMoreLink to="/futbalnet/prestupy-hostovania/">
            {__('Zobraziť viac')}
          </ShowMoreLink>
        </>
      )}
    </TransfersWidgetWrapper>
  );
};

export default TransfersWidget;
