import Image from '@sportnet/ui/lib/Image';
import PictureFrame from '@sportnet/ui/lib/PictureFrame';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import Link from '../../Link';

const Wrapper = styled.div`
  margin-top: ${rem(48)};
  margin-bottom: ${rem(48)};
  display: block;
  ${mb('s')} {
    margin-top: ${rem(56)};
    margin-bottom: ${rem(56)};
    display: flex;
    flex-direction: row;
    min-height: ${rem(120)};
  }
`;

const ImageWrapper = styled(Link)`
  display: none;
  ${mb('s')} {
    display: flex;
    flex-shrink: 0;
    flex-basis: 160px;
  }
`;

const ArticleImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: ${rem(8)} 0 0 ${rem(8)};
`;

const TextContentWrapper = styled.div`
  display: block;
  ${mb('s')} {
    width: 100%;
    padding: ${rem(24)};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    border: ${rem(1)} solid ${({ theme }) => theme.color.separator || '#ddd'};
    border-radius: 0 ${rem(8)} ${rem(8)} 0;
  }
`;

const WidgetTitle = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(15.6)};
  font-weight: 700;
  margin-bottom: ${rem(12)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  text-transform: uppercase;
  ${mb('s')} {
    color: #cf2528;
  } ;
`;

const TargetLink = styled(Link)`
  font-family: pt-serif-pro, serif;
  text-decoration: none;
  color: #1d81c4;
  :hover {
    text-decoration: underline;
  }
  font-size: ${rem(17)};
  line-height: ${rem(25.5)};
  ${mb('s')} {
    color: #333;
    font-size: ${rem(18)};
    line-height: ${rem(21.6)};
    padding-bottom: ${rem(8)};
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;

interface IOwnProps {
  widgetTitle: string;
  articleName: string;
  url: string;
  imageSrc: string;
}

type IProps = IOwnProps;

const RelatedArticle: React.FC<IProps> = ({
  widgetTitle,
  articleName,
  url,
  imageSrc,
}) => {
  return (
    <Wrapper>
      <ImageWrapper to={url}>
        <PictureFrame width={4} height={3}>
          <ArticleImage src={imageSrc} alt={articleName} loading="lazy" />
        </PictureFrame>
      </ImageWrapper>
      <TextContentWrapper>
        {widgetTitle && <WidgetTitle>{widgetTitle}</WidgetTitle>}
        <TargetLink to={url}>{articleName}</TargetLink>
      </TextContentWrapper>
    </Wrapper>
  );
};

export default React.memo(RelatedArticle);
