import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AdManager from '../../../components/Ads/AdManager';
import BodyAd from '../../../components/Ads/BodyAd';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import FutbalnetBlockTitle from '../../../components/FutbalnetBlockTitle';
import {
  ContextMarginBottomStyles,
  ContextMarginSmallBottomStyles,
  ContextMarginTopStyles,
} from '../../../components/Layout';
import {
  PaddedSidebarLayout,
  ResponsiveSidebar,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import MaxWidthBox from '../../../components/MaxWidthBox';
import OrgProfileWidget from '../../../components/OrgProfileWidget';
import Spacer from '../../../components/Spacer';
import DesktopSectionsTagsList from '../../../components/Tags/DesktopTagsList';
import { ITag } from '../../../components/Tags/library';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import CommiteesWidget from '../../../containers/CommiteesWidget';
import FutbalnetUnionNews from '../../../containers/FutbalnetUnionNews';
import PartnersBlock from '../../../containers/PartnersBlock';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import getAppSpaceFromUrl from '../../../utilities/getAppSpaceFromUrl';
import getCompetitionBaseUrl from '../../../utilities/getCompetitionBaseUrl';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import getUnionByUrl from '../../../utilities/getUnionByUrl';
import __ from '../../../utilities/__';
import { commiteesWithLatestResolutionsSelector } from '../..//Commitee/selectors';
import {
  initializeOrSetListParams,
  loadStaticContent,
} from '../../App/actions';
import { staticContentSelector } from '../../App/selectors';
import { loadArticlesList } from '../../Article/actions';
import { loadCommiteesWithLatestResolutions } from '../../Commitee/actions';
import {
  currentCompetitionAppSpaceSelector,
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../../Competition/selectors';
import LastRoundMatches from './LastRoundMatches';
import UnionArticles from './UnionArticles';
import { competitionsSelectorByUnion } from '../../FutbalnetApp/selectors';


const TagsWraper = styled(MaxWidthBox)`
  ${ContextMarginTopStyles};
  ${ContextMarginSmallBottomStyles};
  > ul {
    display: flex;
    width: auto;
    flex: 1 0 0;
  }
`;

const StyledBodyAd = styled(BodyAd)`
  ${ContextMarginBottomStyles}
`;

const StyledFutbalnetBlockTitle = styled(FutbalnetBlockTitle)`
  margin-top: ${rem(56)};
`;

const mapStateToProps = (state: RootState) => {
  const appSpace = currentCompetitionAppSpaceSelector(state);
  return {
    competitions: competitionsSelectorByUnion(state as never),
    currentAppSpace: getCurrentCompetitionAppSpaceSelector(state),
    organizationProfile: currentOrganizationProfileSelector(state),
    commitees: commiteesWithLatestResolutionsSelector(state),
    staticContentPartners: staticContentSelector(state, {
      appSpace,
      cid: 'partneri',
    }),
    staticContentPartnersUp: staticContentSelector(state, {
      appSpace,
      cid: 'partneri-up',
    }),
  };
};

const mapDispatchToProps = {
  loadCommiteesWithLatestResolutions,
};

const LastRoundMatchesWrapper = styled.div`
  & > div {
    margin-bottom: ${rem(2)};
  }
`;

type Props = ReturnType<typeof mapStateToProps> & {
  dispatch: CustomThunkDispatch;
} & RouteProps<{ zvaz: string }> &
  typeof mapDispatchToProps;

class UnionOverview extends React.PureComponent<Props> {
  static async initCMS(props: Props) {
    const {
      dispatch,
      params: { zvaz },
      organizationProfile,
    } = props;

    const appSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

    // clanky, ktore uverejnuje zvaz
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_UNION_ARTICLES,
        params: {
          limit: config.LIST_UNION_ARTICLES_LIMIT,
          offset: 0,
          appSpace,
          doctypes: [config.UNION_ARTICLE_DOCTYPE_IDENT],
        },
      }),
    );

    // uradne spravy zvazu
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_FUTBALNET_BULLETIN_ARTICLES,
        params: {
          offset: 0,
          limit: config.LIST_FUTBALNET_BULLETIN_ARTICLES_LIMIT,
          doctypes: ['uradnasprava', 'zapisnica', 'delegacny-list'],
          appSpace,
        },
      }),
    );

    const promises = [
      dispatch(loadArticlesList(config.LIST_UNION_ARTICLES)),
      dispatch(loadArticlesList(config.LIST_FUTBALNET_BULLETIN_ARTICLES)),
      dispatch(loadStaticContent.action({ appSpace, cid: 'partneri' })).catch(),
      dispatch(
        loadStaticContent.action({ appSpace, cid: 'partneri-up' }),
      ).catch(),
    ];

    // clanky futbalnetu, ktore uverejnuje redakcia SME a taguje ich skratenymnazvom zvazu
    if (organizationProfile) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET,
          params: {
            offset: 0,
            limit: config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET_LIMIT,
            smarttags: [
              `Zväz:${
                organizationProfile.shortName || organizationProfile.name
              }`,
            ],
          },
        }),
      );

      promises.push(
        dispatch(loadArticlesList(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET)),
      );
    }

    return Promise.all(promises);
  }

  static async getInitialProps(props: Props) {
    const {
      dispatch,
      params: { zvaz },
    } = props;
    await UnionOverview.initCMS(props);
    // `union` object from `shortnames.json`
    const union = getUnionByUrl(zvaz);
    const unionId = union?.__issfId.toString();
    if (unionId) {
      await dispatch(
        loadCommiteesWithLatestResolutions.action({
          unionId,
          season: config.ACTIVE_SEASON_ID,
        }),
      );
    }
  }

  async componentDidMount() {
    await UnionOverview.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.zvaz !== prevProps.params.zvaz) {
      await UnionOverview.getInitialProps(this.props);
    }
  }

  render() {
    const {
      params: { zvaz },
      competitions,
      organizationProfile,
      commitees,
      staticContentPartners,
      staticContentPartnersUp,
    } = this.props;

    // TOP sutaze vyberame ako prvych 10 sutazi, ktore pridu z API uz zoradene
    const topCompetitions = (competitions || []).slice(0, 10);

    const competitionTags: ITag[] = topCompetitions.map((c) => ({
      label: c.name,
      url: getCompetitionBaseUrl({
        zvaz,
        sutaz: c.__issfId || c.competitionGroupId,
      }),
    }));

    const partnersUpContent = staticContentPartnersUp?.content || [];
    const partnersContent = staticContentPartners?.content || [];

    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';

    return (
      <>
        <TagsWraper>
          <DesktopSectionsTagsList tags={competitionTags} />
        </TagsWraper>
        <AdManager site="futbalnet_zvaz" siteId={zvaz} />
        <TopAd />
        <BottomPaddedBigAd name="big_1" />
        <RempIntegration destroy />
        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <UnionArticles zvaz={zvaz} />
            {/* <RelatedArticlesTimeline
              to={`${getUnionBaseUrl({ zvaz })}clanky/`}
              listName={config.LIST_UNION_ARTICLES} /> */}
            <StyledBodyAd name="body_1" />
            {organizationProfile && (
              <FutbalnetUnionNews organizationProfile={organizationProfile} />
            )}
            <PartnersBlock content={partnersUpContent} />
            <StyledFutbalnetBlockTitle>
              {__('Súťaže - aktuálne zápasy')}
            </StyledFutbalnetBlockTitle>
            <Spacer size={7}>
              {competitions && competitions.length > 0 && (
                <LastRoundMatchesWrapper>
                  {competitions.map((competition, idx) => {
                    const closed =
                      idx < config.NUMBER_OF_OPENED_COMPETITIONS_LISTS;
                    return (
                      <LastRoundMatches
                        key={competition._id}
                        competition={competition}
                        isOpened={closed}
                        zvaz={zvaz}
                      />
                    );
                  })}
                </LastRoundMatchesWrapper>
              )}
            </Spacer>
            <PartnersBlock content={partnersContent} title={__('Partneri')} />
            <FutbalnetBlockTitle>{__('Komisie')}</FutbalnetBlockTitle>
            <Spacer size={2}>
              <CommiteesWidget zvaz={zvaz} commitees={commitees} />
            </Spacer>
            <ResponsiveSidebar>
              <OrgProfileWidget organizationProfile={organizationProfile} />
            </ResponsiveSidebar>
          </SidebarLayoutContent>
          <Sidebar>
            <OrgProfileWidget organizationProfile={organizationProfile} />
            <StickySidebarContainer>
              <SideAd name="side_1" />
            </StickySidebarContainer>
          </Sidebar>
        </PaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(connect(mapStateToProps))(UnionOverview);
