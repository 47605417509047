import { darken, rem } from 'polished';
import styled, { css } from '../../../theme/styled-components';

export const CircleShape = styled.span<{ color: string }>`
  width: ${rem(40)};
  height: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  outline: none;
  ${({ color }) => css`
    &:hover {
      background: ${darken(0.05, color)};
    }
    &:focus {
      background: ${darken(0.1, color)};
    }
    &:active {
      background: ${darken(0.2, color)};
    }
  `};
`;

export const RectangleShape = styled.span<{ color: string }>`
  padding: ${rem(4)} ${rem(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rem(2)};
  background-color: ${({ color }) => color};
  outline: none;
  ${({ color }) => css`
    &:hover {
      background: ${darken(0.05, color)};
    }
    &:focus {
      background: ${darken(0.1, color)};
    }
    &:active {
      background: ${darken(0.2, color)};
    }
  `};
`;

export const SocialShareName = styled.span`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 700;
  font-size: ${rem(11)};
  line-height: ${rem(12)};
  text-decoration: none;
  color: #fff;
  margin-left: ${rem(8)};
`;
