import { rem } from 'polished';
import styled from 'styled-components';

const ButtonAsLink = styled.button`
  font-family: ${({theme}) => theme.primaryFont.family};
  font-weight: ${({theme}) => theme.primaryFont.weight.semiBold};
  font-size: ${rem(13)};
  color: ${({theme}) => theme.color.primaryFontColor};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  :hover :active :focus {
    background: none;
    border: none;
  }
`;

export default ButtonAsLink;