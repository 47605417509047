import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../theme/styled-components';

export const Td = styled('td')<{ center?: boolean; right?: boolean }>`
  padding: ${rem(10)} ${rem(16)};
  height: ${({ theme }) => {
    return `${rem(theme.table.cellHeight)}`;
  }};
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.fontFamily};
  text-align: left;
  ${(props) => {
    if (props.center) {
      return `text-align: center;`;
    }
    if (props.right) {
      return `text-align: right;`;
    }
    return ``;
  }};

  .sportnetui-btn {
    padding: 0 ${rem(10)};
    font-size: ${rem(12)};
    min-height: ${rem(26)};
    .icon {
      height: ${rem(12)};
      width: ${rem(12)};
    }
  }
`;
interface ITh {
  center?: boolean;
  right?: boolean;
  width?: number;
}
export const Th = styled('th')<ITh>`
  padding: ${rem(16)} ${rem(16)};
  font-size: ${rem(12)};
  text-transform: uppercase;
  text-align: left;
  ${(props) => {
    if (props.width) {
      return `width: ${rem(props.width)}`;
    }
    return ``;
  }}
  ${(props) => {
    if (props.center) {
      return `text-align: center;`;
    }
    if (props.right) {
      return `text-align: right;`;
    }
    return ``;
  }};
  font-weight: bold;
  color: ${({ theme }) => theme.color.base};
`;

export const Tr = styled('tr')<{ onClick?: () => void }>`
  outline: none;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  th:first-child,
  td:first-child {
    padding-left: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
  th:last-child,
  td:last-child {
    padding-right: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
  ${(props) => {
    if (props.onClick) {
      return css`
        &:hover > td {
          cursor: pointer;
        }
      `;
    }
    return ``;
  }};
`;

export const Tbody = styled.tbody`
  tr:nth-child(odd) {
    background: #f9f9f9;
  }
`;

export const Thead = styled.thead`
  border-bottom: ${rem(2)} solid #f1f1f1;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const TableComponent = styled.table`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};
  border-collapse: collapse;
`;

export const Table = (props: { children: any }) => (
  <TableWrapper>
    <TableComponent>{props.children}</TableComponent>
  </TableWrapper>
);

export default { Table, Tbody, Thead, Tr, Th, Td };
