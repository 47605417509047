import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import getMatchHomeTeam from '../match/getMatchHomeTeam';
import getMatchAwayTeam from '../match/getMatchAwayTeam';
import getTeamName from '../match/getTeamName';
import {
  getGoalsByTeams,
  getTeamLogoSrc,
} from '@sportnet/content/view/widgets/MatchesList/utilities';
import { IMatch, ITeam } from '../../library/Competitions';
import getFallbackClubOrUnionLogo from '../getFallbackClubOrUnionLogo';
import { MatchesListMatch } from '../../library/Print';
import getScoreNote from '../match/getScoreNote';
import getWinningTeam from '../match/getWinningTeam';
import isFutureMatch from '../match/isFutureMatch';

/**
 * TODO: Content by mal mat tento fallback image konfigurovatelny.
 * @param appSpace
 * @param team
 * @returns
 */
export const getTeamLogoSrcWithFixedFallback = (
  appSpace: string,
  team: ITeam,
) => {
  const src = getTeamLogoSrc(appSpace, team);
  return src === '/content/crest.png' ? getFallbackClubOrUnionLogo() : src;
};

const getMatchesListMatchDataForPrint = (match: IMatch): MatchesListMatch => {
  const homeTeam = getMatchHomeTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );
  const awayTeam = getMatchAwayTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );

  const homeTeamName = getTeamName(match.appSpace, homeTeam) ?? '';
  const homeTeamLogoSrc = !!homeTeam
    ? getTeamLogoSrcWithFixedFallback(match.appSpace, homeTeam)
    : getFallbackClubOrUnionLogo();
  const awayTeamName = getTeamName(match.appSpace, awayTeam) ?? '';
  const awayTeamLogoSrc = !!awayTeam
    ? getTeamLogoSrcWithFixedFallback(match.appSpace, awayTeam)
    : getFallbackClubOrUnionLogo();
  const dateFrom = match.startDate ?? null;

  const homeTeamResignation = Boolean(
    homeTeam?.additionalProperties?.resignation,
  );
  const awayTeamResignation = Boolean(
    awayTeam?.additionalProperties?.resignation,
  );

  if (isFutureMatch(match)) {
    return {
      _id: match._id,
      __issfId: match.__issfId,
      dateFrom,
      homeTeam: {
        _id: homeTeam?._id || '',
        name: homeTeamName,
        logoSrc: homeTeamLogoSrc,
        isWinner: false,
        score: null,
        penaltyGoals: null,
        resignation: homeTeamResignation,
      },
      awayTeam: {
        _id: awayTeam?._id || '',
        name: awayTeamName,
        logoSrc: awayTeamLogoSrc,
        isWinner: false,
        score: null,
        penaltyGoals: null,
        resignation: awayTeamResignation,
      },
      scoreNote: '',
    };
  } else {
    // góly
    const { homeTeamGoals, awayTeamGoals } = getGoalsByTeams(
      match as unknown as IWidgetMatchesListMatchItem,
    );

    // penalty
    const [homeTeamPenaltyGoals, awayTeamPenaltyGoals] =
      match.penaltiesScore || [];

    // detekcia vitazneho teamu
    const { homeTeamIsWinner, awayTeamIsWinner } = getWinningTeam(
      homeTeamGoals,
      awayTeamGoals,
      homeTeamPenaltyGoals,
      awayTeamPenaltyGoals,
    );

    // vypocet vysledneho skore
    const homeTeamScore =
      homeTeamPenaltyGoals > awayTeamPenaltyGoals
        ? homeTeamGoals + 1
        : homeTeamGoals;

    const awayTeamScore =
      homeTeamPenaltyGoals < awayTeamPenaltyGoals
        ? awayTeamGoals + 1
        : awayTeamGoals;

    // doplunjúci údaj ku skóre
    const scoreNote = getScoreNote(
      match.rules?.sport_sector ?? 'futbal',
      match.scoreByPhases ?? [],
    );

    return {
      _id: match._id,
      __issfId: match.__issfId,
      dateFrom,
      homeTeam: {
        _id: homeTeam?._id || '',
        name: homeTeamName,
        logoSrc: homeTeamLogoSrc,
        isWinner: homeTeamIsWinner,
        score: homeTeamScore,
        penaltyGoals: homeTeamPenaltyGoals,
        resignation: homeTeamResignation,
      },
      awayTeam: {
        _id: awayTeam?._id || '',
        name: awayTeamName,
        logoSrc: awayTeamLogoSrc,
        isWinner: awayTeamIsWinner,
        score: awayTeamScore,
        penaltyGoals: awayTeamPenaltyGoals,
        resignation: awayTeamResignation,
      },
      scoreNote,
    };
  }
};

export default getMatchesListMatchDataForPrint;
