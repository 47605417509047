import { AsyncReturnType } from '../library/App';
import { IMemberTrainerProfile } from '../library/Competitions';
import FutbalnetApi from '../pages/Member/api';

/**
 * ...len potrebne fieldy pre detail trenera
 */
const reduceTrainerProfile = (
  trainerProfile: AsyncReturnType<typeof FutbalnetApi['getTrainer']>,
): IMemberTrainerProfile => {
  const reduced: IMemberTrainerProfile = {
    profileStatus: trainerProfile.profileStatus,
    registrationDate: trainerProfile.registration_date,
  };
  return reduced;
};

export default reduceTrainerProfile;
