import {
  commit,
  CommitError,
  commitWithParams,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory, thunkToAction } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import {
  ArticleId,
  IArticle,
  NormalizedEntities,
  Pager,
  SectionId,
} from '../../library/App';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceListArticleProps from '../../utilities/reduceListArticleProps';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('ARTICLE');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

// Set current article id
export const setCurrentArticleId = create<ArticleId | null>(
  'SET_CURRENT_ARTICLE_ID',
);

export const loadArticles = createAsync<
  {
    limit?: number;
    q?: string;
    page?: number;
    offset?: number;
    smarttags?: string[];
    doctypes?: string[];
    appSpace?: string;
    authorId?: string;
  },
  NormalizedEntities<'articles'> & Pager
>('LOAD_ARTICLES', async (parameters, dispatch, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());
  const params: {
    offset: number;
    limit: number;
    q?: string;
    smarttags?: string[];
    doctypes?: string[];
    authorId?: string;
  } = {
    offset: 0,
    limit: 12,
  };
  if (parameters.limit || parameters.limit === 0) {
    params.limit = parameters.limit;
  }
  if (parameters.page && parameters.page > 1) {
    params.offset = (parameters.page - 1) * params.limit;
  }
  if (parameters.offset || parameters.offset === 0) {
    params.offset = parameters.offset;
  }
  if (parameters.q || parameters.q === '') {
    params.q = parameters.q;
  }
  if (parameters.smarttags && parameters.smarttags.length > 0) {
    params.smarttags = parameters.smarttags;
  }
  if (parameters.doctypes && parameters.doctypes.length > 0) {
    params.doctypes = parameters.doctypes;
  }
  if (parameters.authorId) {
    params.authorId = parameters.authorId;
  }
  const { articles, limit, offset, total, nextOffset } =
    await CmsApi.getPublicArticles(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      params,
    );

  return {
    ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
    limit: limit!,
    offset: offset!,
    total: total!,
    nextOffset: nextOffset || null,
  };
});

export const loadSimilarArticles = createAsync<
  {
    limit?: number;
    page?: number;
    offset?: number;
    articleId?: ArticleId;
    appSpace?: string;
  },
  NormalizedEntities<'articles'> & Pager
>(
  'LOAD_SIMILAR_ARTICLES',
  async (parameters, dispatch, getState, { CmsApi }) => {
    const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());
    const params: {
      offset: number;
      limit: number;
    } = {
      offset: 0,
      limit: config.LIST_SIMILAR_ARTICLES_LIMIT,
    };
    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }
    if (parameters.page && parameters.page > 1) {
      params.offset = (parameters.page - 1) * params.limit;
    }
    if (parameters.offset || parameters.offset === 0) {
      params.offset = parameters.offset;
    }
    const { articles, limit, offset, total, nextOffset } =
      await CmsApi.getPublicRelatedArticles(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        Number(parameters.articleId),
        params,
      );

    return {
      ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
      limit: limit!,
      offset: offset!,
      total: total!,
      nextOffset: nextOffset || null,
    };
  },
);

export const loadSimilarArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(loadSimilarArticles)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadArticles)(parameters) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadMostPopularArticles = createAsync<
  {
    limit?: number;
    offset?: number;
    hours?: number;
  },
  NormalizedEntities<'articles'> & Pager
>(
  'LOAD_MOST_POPULAR_ARTICLES',
  async (parameters, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());
    const params: {
      offset: number;
      limit: number;
    } = {
      offset: 0,
      limit: 1,
    };
    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }
    if (parameters.offset || parameters.offset === 0) {
      params.offset = parameters.offset;
    }
    const { articles, limit, offset, total, nextOffset } =
      await CmsApi.getMostPopularArticles(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        parameters.hours || 0,
        params,
      );

    return {
      ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
      limit: limit!,
      offset: offset!,
      total: total!,
      nextOffset: nextOffset || null,
    };
  },
);

export const loadMostPopularArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(loadMostPopularArticles)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadSectionArticles = createAsync<
  {
    limit?: number;
    page?: number;
    offset?: number;
    sectionId?: SectionId;
    appSpace?: string;
  },
  NormalizedEntities<'articles'> & Pager
>(
  'LOAD_SECTION_ARTICLES_1',
  async (parameters, dispatch, getState, { CmsApi }) => {
    const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());
    const params: {
      offset: number;
      limit: number;
    } = {
      offset: 0,
      limit: 12,
    };
    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }
    if (parameters.page && parameters.page > 1) {
      params.offset = (parameters.page - 1) * params.limit;
    }
    if (parameters.offset || parameters.offset === 0) {
      params.offset += parameters.offset;
    }
    const { articles, limit, offset, total, nextOffset } =
      await CmsApi.getPublicArticlesBySectionId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        parameters.sectionId || 0,
        params,
      );

    return {
      ...normalizeEntities('articles', articles.map(reduceListArticleProps)),
      limit: limit!,
      offset: offset!,
      total: total!,
      nextOffset: nextOffset || null,
    };
  },
);

export const loadSectionArticlesListByParams = (
  listName: string,
  params: {
    limit?: number;
    page?: number;
    offset?: number;
    sectionId?: SectionId;
    appSpace?: string;
  },
): CustomThunkAction<Promise<void>> => {
  return (dispatch) => {
    return dispatch(
      commitWithParams.action({
        listName,
        params,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadSectionArticles)(params) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadSectionArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(loadSectionArticles)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const putArticleStats = createAsync<
  { id: ArticleId; appSpace?: string },
  void
>('PUT_ARTICLE_STATS', async (parameters, dispatch, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());

  try {
    await CmsApi.putArticleStats(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      parameters.id,
    );
  } catch (e: any) {
    console.error(e);
  }
});

export const loadListingArticles = createAsync<
  {
    limit?: number;
    offset?: number;
    listingId?: string;
  },
  { articles: IArticle[] } & Pager
>(
  'LOAD_LISTING_ARTICLES',
  async (parameters, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());
    const params: {
      offset: number;
      limit: number;
    } = {
      offset: 0,
      limit: 1,
    };
    if (parameters.limit || parameters.limit === 0) {
      params.limit = parameters.limit;
    }
    if (parameters.offset || parameters.offset === 0) {
      params.offset = parameters.offset;
    }
    const { articles, limit, offset, total, nextOffset } =
      await CmsApi.getPublicListingArticles(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        parameters.listingId || '',
        params,
      );

    return {
      articles,
      limit: limit!,
      offset: offset!,
      total: total!,
      nextOffset: nextOffset || null,
    };
  },
);

export const loadListingArticlesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { articles, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(loadListingArticles)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results: articles,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadListingArticlesListByParams = (
  listName: string,
  params: {
    limit?: number;
    offset?: number;
    listingId: string;
  },
): CustomThunkAction<Promise<void>> => {
  return (dispatch) => {
    return dispatch(
      commitWithParams.action({
        listName,
        params,
        load: async () => {
          try {
            const { articles, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadListingArticles)(params) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results: articles,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadArticleTabsList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { articles, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadArticleTabs)(parameters) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results: articles,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadArticleTabs = createAsync<
  {
    articleId?: ArticleId;
    appSpace?: string;
    listingType?: string;
  },
  { articles: IArticle[] } & Pager
>('LOAD_ARTICLE_TABS', async (parameters, dispatch, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());
  const listingType = parameters.listingType || 'article-tabs';
  const { articles, limit, offset, total, nextOffset } =
    await CmsApi.getPublicFixedListingArticles4Article(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      Number(parameters.articleId),
      listingType,
    );

  return {
    articles,
    limit: limit!,
    offset: offset!,
    total: total!,
    nextOffset: nextOffset || null,
  };
});
