import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useQ } from '../../../query';
import loadAllCompetitionMatches from './features/loadAllCompetitionMatches';
import __ from '../../../utilities/__';
import RenderPrintData, {
  EmptyFooter,
  EmptyHeader,
  InfoMessage,
} from './RenderPrintData';
import loadCompetitionById from './features/loadCompetitionById';
import styled from 'styled-components';
import { rem } from 'polished';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';
import RenderPrintDataLoader from './RenderPrintDataLoader';
import FooterLogos from './FooterLogos';
import getAppSpaceFromUrl from '../../../utilities/getAppSpaceFromUrl';
import FutbalnetPrintQrCode from '../FutbalnetPrintQrCode';
import getBaseUrl from '../../../utilities/getBaseUrl';
import { Helmet } from 'react-helmet-async';

export const CompetitionNameLoader = styled.div`
  width: ${rem(400)};
  height: ${rem(43)};
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(24)};
  border-bottom: 2px solid black;
  width: 100%;
  height: 3cm;
`;

export const H1 = styled.h1`
  display: block;
  font-size: ${rem(26)};
  margin: 0;
  padding: 0;
`;

export const H2 = styled.h2`
  display: block;
  font-size: ${rem(24)};
  margin: 0;
  padding: 0;
`;

export const PageBreak = styled.div`
  page-break-after: always;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3cm;
`;

export const Wrapper = styled.div`
  /* @page {
    size: A4;
  } */

  @media screen {
    ${EmptyHeader},
    ${EmptyFooter} {
      height: 0;
    }
  }

  @media print {
    ${EmptyHeader},
    ${EmptyFooter} {
      height: 3cm;
    }
  }

  .page-break {
    break-after: page;
    page-break-after: always;
  }

  ${Header} {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    height: 3cm;
    top: 0;
    border-bottom: 2px solid #000;
  }

  ${Footer} {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 3cm;
    bottom: 0;
    /*			border-top: 2px solid #000;*/
  }

  @media screen {
    ${EmptyHeader},
    ${EmptyFooter} {
      height: 0;
    }

    ${Header} {
      position: relative;
    }

    ${Footer} {
      position: relative;
    }
  }

  /* Safari */
  @supports (-webkit-hyphens: none) {
    @media print {
      ${EmptyHeader},
      ${EmptyFooter} {
        height: 0;
      }

      ${Header} {
        position: relative;
      }

      ${Footer} {
        position: relative;
      }
    }
  }
`;

type RouterProps = RouteComponentProps<
  {
    zvaz: string;
    sutaz: string;
    competitionPartId: string;
  },
  void
>;

const CompetitionMatchesPrint: React.FC<RouterProps> = ({
  params: { zvaz, sutaz, competitionPartId },
}) => {
  const appSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

  // detail sutaze
  const {
    data: competition,
    isError: isCompetitionError,
    isLoading: isCompetititonLoading,
  } = useQ(
    loadCompetitionById,
    { appSpace, id: sutaz },
    { enabled: !!appSpace && !!sutaz, refetchOnWindowFocus: false },
  );

  const competitionId = competition ? competition._id : '';

  // zapasy
  const { data, isError, isLoading } = useQ(
    loadAllCompetitionMatches,
    {
      appSpace,
      competitionId: competitionId,
      competitionPartId,
    },
    {
      enabled: !!appSpace && !!competitionId && !!competitionPartId,
      refetchOnWindowFocus: false,
    },
  );

  const qrCodeUrl = `${getBaseUrl()}/futbalnet/z/${zvaz}/s/${sutaz}/program/?part=${competitionPartId}`;

  const showPrintDialog = !isLoading;

  React.useEffect(() => {
    if (showPrintDialog) {
      // nechajme 100ms pre render
      window.setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [showPrintDialog]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex nofollow" />
      </Helmet>
      <Wrapper>
        <Header>
          {isCompetititonLoading ? (
            <CompetitionNameLoader className={SKELETON_CLASS_NAME} />
          ) : competition ? (
            <H1>
              {competition?.name} - {competition?.season.name}
            </H1>
          ) : null}
          <FutbalnetPrintQrCode url={qrCodeUrl} />
        </Header>
        {competition ? (
          isLoading ? (
            <RenderPrintDataLoader />
          ) : isError ? (
            <InfoMessage
              text={`${__('Pri získavaní dát pre tlač sa vyskytla chyba!')}`}
            />
          ) : data ? (
            <RenderPrintData data={data} />
          ) : null
        ) : isCompetitionError ? (
          <InfoMessage text={`${__('Načítanie súťaže skončilo s chybou!')}`} />
        ) : null}
        <PageBreak />
        <Footer>
          <FooterLogos />
        </Footer>
      </Wrapper>
    </>
  );
};

export default CompetitionMatchesPrint;
