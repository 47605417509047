import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16.2 16.2"
  >
    <path
      fill={color}
      d="M15.4,11.3h-2.6V5.4c0-1.1-0.9-2.1-2.1-2.1L4.9,3.4l0-2.6C4.9,0.3,4.6,0,4.2,0C3.7,0,3.4,0.3,3.4,0.8l0,2.7
    l-2.7,0C0.3,3.4,0,3.8,0,4.2c0,0.4,0.3,0.7,0.8,0.7c0,0,0,0,0,0l2.6,0l-0.1,5.9c0,1.1,0.9,2.1,2.1,2.1h5.9v2.6
    c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2.6h2.6c0.4,0,0.8-0.3,0.8-0.8S15.8,11.3,15.4,11.3z M5.4,11.3c-0.3,0-0.6-0.3-0.6-0.6
    l0.1-5.9l5.9-0.1c0.3,0,0.6,0.3,0.6,0.6v5.9H5.4z"
    />
  </svg>
);
