import CoreApi, {
  OrganizationProfile,
  PPOContactInfo,
  PPOTheme,
} from '../api/CoreApi';
import __ from '../utilities/__';
import { AsyncReturnType } from './App';

export enum Category {
  MALE = 'M',
  FEMALE = 'F',
  OTHER = 'U',
}

export interface CategorizedCompetitions {
  [key: string]: ICompetition[];
}
export interface IResultsTableItemMatch {
  team: {
    _id: string;
    name: string;
    displayName: string;
  };
  result: 'W' | 'L' | 'D';
  score: string;
  matchId: string;
  match?: {
    _id: string;
    __issfId: number;
    startDate: string;
  };
  homeaway: 'home' | 'away';
}

export interface IStats {
  matches: {
    played: number;
    won: number;
    draw: number;
    lost: number;
    // plazovy futbal
    wonNT?: number;
    wonET?: number;
    wonP?: number;
  };
  goals: {
    given: number;
    received: number;
  };
  issf?: {
    poradiePodlaKoeficientuPreU17U19: number;
    koeficientPreU17U19: number;
    FP: number;
    BR: number;
    PM: number;
  };
  points: number;
  sortPoints?: number;
  sortGoalsGiven?: number;
  sortGoalsReceived?: number;
  u17u19?: number;
}

export interface IResultsTableItem {
  team: {
    _id: string;
    name: string;
    resignation?: boolean;
  };
  stats: IStats;
  homeStats?: IStats;
  awayStats?: IStats;
  matches?: IResultsTableItemMatch[];
  homeMatches?: IResultsTableItemMatch[];
  awayMatches?: IResultsTableItemMatch[];
  corrections?: {
    points?: {
      total?: number;
    };
    plusminus?: number;
    reason?: string;
  };
}

export interface ICompetitionTeam {
  _id: string;
  __issfId?: number;
  name: string;
  displayName?: string;
  appSpace: string;
  organization?: {
    _id: string;
    name: string;
    logo_public_url?: string;
  };
  squad?: {
    athletes?: Array<{
      sportnetUser: { _id: string; name: string };
      additionalData: {
        captain: boolean;
        nr: number;
        position: string;
        substitute: boolean;
        __issfId: number;
      };
    }>;
  };
}

export interface ICompetition {
  _id: string;
  __issfId?: number;
  sortvalue?: number;
  aggregatedPlayerStats?: boolean;
  level?: number;
  competitionGroupId: string;
  name: string;
  appSpace: string;
  publication?: {
    logo?: {
      media_url: string;
      public_url: string;
    };
  };
  parts?: Array<{
    _id: string;
    __issfId?: string | number;
    name: string;
    dateFrom?: string;
    dateTo?: string;
    teams?: Array<{
      _id: string;
      __issfId?: number;
      name: string;
      displayName?: string;
      appSpace: string;
      organization?: {
        _id: string;
        name: string;
        logo_public_url?: string;
      };
    }>;
    rules?: { [key: string]: any };
  }>;
  teams?: ICompetitionTeam[];
  season?: {
    _id: string;
    name: string;
  };
  resultsTable?: {
    competitionId?: string;
    competitionPartId?: string;
    results: IResultsTableItem[];
    players: Array<{
      _id: string;
      name: string;
      teamId: string;
      __issfId?: string | number;
      stats: {
        assists: number;
        clean_sheets: number;
        goals: number;
        match_appearances: number;
        match_starts: number;
        minutes: number;
        own_goals: number;
        red_cards: number;
        substitutions: number;
        yellow_cards: number;
      };
    }>;
  };
}

export interface IPlayerStat {
  _id: string;
  teamId: string;
  name: string;
  stats: any;
  __issfId?: string | number;
}

export interface ICompetitionPart {
  _id: string;
  publicComment?: string;
  __issfId?: string | number;
  appSpace?: string;
  competitionId?: string;
  format?: string;
  name: string;
  settings?: {};
  teams?: Array<{
    _id: string;
    __issfId?: number;
    name: string;
    displayName?: string;
    appSpace: string;
    organization?: {
      _id: string;
      name: string;
      logo_public_url?: string;
    };
    logo?: {
      media_url?: string;
      public_url?: string;
    };
    squad?: {
      athletes?: Array<{
        sportnetUser: { _id: string; name: string };
        additionalData: {
          captain: boolean;
          nr: number;
          position: string;
          substitute: boolean;
          __issfId: number;
        };
      }>;
    };
    resignation?: boolean;
  }>;
  rules?: {
    [key: string]: string;
  };
  rounds?: Array<{
    _id: string;
    name: string;
    dateFrom: string;
    dateTo: string;
  }>;
  dateFrom?: string;
  dateTo?: string;
  resultsTable?: {
    results: IResultsTableItem[];
    players?: IPlayerStat[];
  };
}

export interface IUnion {
  _id?: string;
  name?: string;
}

// export interface IBasicCodelistItem {
//   _id: string;
//   label: string;
// }

export type IBasicCodelistItem =
  | {
      _id: string;
      label: string;
    }
  | {
      _id: string;
      value: unknown;
    };

export interface ISportSectorPhase {
  _id: string;
  label: string;
  playPhase?: boolean;
  basePhase?: boolean;
  playTime: number;
  startTime: number;
  endTime: number;
}

export interface ISportSectorBroadcaster {
  _id: string;
  label: string;
  logo_url?: string;
}

export interface ISportSectorPhases {
  _id: string;
  items: ISportSectorPhase[];
}

export interface ISportSectorSetting {
  displaySeconds: boolean;
  overlapTime: boolean;
  reverseTime: boolean;
  contumation: {
    score: number;
  };
  players: {
    field: {
      min: number;
      max: number;
    };
    substitutes: {
      max: number;
    };
  };
}

export interface ISportSectorEvent {
  _id: string;
  label: string;
  eventType: string;
}

export interface ISportSectorEvents {
  _id: string;
  items: ISportSectorEvent[];
}

export interface ISportSectorDelegate {
  _id: string;
  label: string;
}

export interface ISportSectorDelegates {
  _id: string;
  items: ISportSectorDelegate[];
}

export interface ISportSectorCrew {
  _id: string;
  label: string;
}

export interface ISportSectorCrews {
  _id: string;
  items: ISportSectorCrew[];
}

export interface MatchProtocolEvent {
  eventTime: string;
  eventType: string;
  phase: string;
  player?: {
    _id: string;
    name: string;
  };
  crewMember?: {
    _id: string;
    name: string;
  };
  team: string;
  type: string | null;
  replacement?: {
    _id: string;
    name: string;
  };
  substitutionIndex?: number;
  reason?: string;
}

export interface IProtocol {
  [key: string]: any;
  events?: any[];
  audience?: number | null;
  note?: string;
}

export interface ILiveState {
  ongoing: boolean;
  seconds: number;
  phase: string;
  toggleDate: string;
}

export interface IMatchSettings {
  players: {
    field: {
      min: number;
      max: number;
    };
    substitutes: {
      max: number;
    };
  };
  contumation: {
    score: number;
  };
  reverseTime: boolean;
  overlapTime: boolean;
  phases: {};
  displaySeconds?: boolean;
}

export interface IMatchManager {
  user: {
    _id: string;
    name: string;
    __issfId?: string;
  };
  type: {
    label: string;
    value: string;
  };
}

export interface IMatch {
  _id: string;
  __issfId?: number;
  perex?: string;
  h2hType?: string; // pre ucely optimalizacie h2h na zapasovej obrazovke
  appSpace: string;
  livestream?: string;
  score?: number[];
  detailUrl?: string;
  penaltiesScore?: number[];
  scoreByPhases?: number[][];
  startDate: string;
  sportGround?: ISportGround;
  competition: {
    _id: string;
    __issfId?: number;
    name: string;
    competitionGroupId: string;
    interstate?: boolean;
  };
  closed?: boolean;
  contumation?: {
    isContumated?: boolean;
    keepScore?: boolean;
    advantage?: string | null;
  };
  contumationScore?: number[];
  settings?: IMatchSettings;
  rules?: {
    [key: string]: any;
  };
  competitionPart: {
    _id: string;
    name: string;
  };
  season: ISeason;
  round?: {
    _id?: string;
    name: string;
    dateFrom?: string;
    dateTo?: string;
  };
  teams?: ITeam[];
  protocol?: IProtocol;
  liveState?: ILiveState;
  nominations?: IMatchNomination[];
  timer?: {
    [key: string]: {
      start: {
        date: string;
        seconds?: number;
      };
      end?: {
        date: string;
        seconds?: number;
      };
    };
  };
  managers?: IMatchManager[];
  television_broadcasters?: Array<{
    _id: string;
    label: string;
    logo_url?: string;
  }>;
  internet_broadcasters?: Array<{
    _id: string;
    label: string;
    logo_url?: string;
  }>;
}

export interface ISportGround {
  appSpace?: string;
  city?: string;
  country?: string;
  name?: string;
  number?: string;
  owner?: string;
  sportGroundType?: string;
  sportObjectName?: string;
  street?: string;
  zip?: string;
  _id: string;
  additionalData?: any;
}

export interface IAthlete {
  sportnetUser: {
    _id: string;
    name: string;
  };
  additionalData?: any;
}

export interface ITeam {
  _id: string;
  __issfId?: number;
  name: string;
  displayName?: string;
  shortName?: string;
  appSpace: string;
  sport_sector?: string;
  season?: ISeason;
  gender?: string;
  detailUrl?: string;
  category?: string;
  agecategory?: {
    _id: string;
    appSpace: string;
    name: string;
    bornDateFrom: string;
    bornDateTo?: string;
    season: ISeason;
  };
  organization?: PPO;
  athletes?: IAthlete[];
  additionalProperties?: any;
}

export interface ISeason {
  _id: string;
  name: string;
  sport: string;
  appSpace: string;
  dateFrom: string;
  dateTo: string;
}

export interface IMatchNominationCrewItem {
  sportnetId: string;
  displayName: string;
}

export interface IMatchNomination {
  teamId?: string;
  athletes: Array<{
    sportnetUser: {
      _id: string;
      name: string;
    };
    additionalData?: {
      substitute?: boolean;
      __issfId?: number;
      nr?: number;
      position?: string;
      captain?: boolean;
    };
  }>;
  crew?: Array<{
    sportnetUser: {
      _id: string | null;
      name: string | null;
    };
    position: string;
  }>;
  closed?: boolean;
}

export interface PPO extends OrganizationProfile {
  theme?: PPOTheme;
  contactInfo?: PPOContactInfo;
  domain?: string;
  homepageSectionIdOrUniqId?: string;
}

export enum MemberType {
  PLAYER,
  REFEREE,
  DELEGATE_O,
  DELEGATE_M,
  AGENT,
  TRAINER,
}

export interface IMemberLicense {
  number: string;
  type: string;
  validity: { from: string; to: string };
}

export const MEMBER_TYPE_LABELS: { [key: string]: { label: string } } = {
  PLAYER: {
    label: __('Hráč'),
  },
  REFEREE: {
    label: __('Rozhodca'),
  },
  DELEGATE_O: {
    label: __('Pozorovateľ rozhodcov'),
  },
  DELEGATE_M: {
    label: __('Delegát stretnutia'),
  },
  AGENT: {
    label: __('Agent'),
  },
  TRAINER: {
    label: __('Tréner'),
  },
};

export interface IMemberPlayerProfile {
  profileStatus: {
    status: string;
    regDate: string;
  };
  clubOrigin: {
    name: string;
    id: number;
  };
  clubActual: {
    name: string;
    id: number;
  };
  clubHosted?: {
    name: string;
    id: number;
  };
  playerStatus: string;
}

export interface IMemberTrainerProfile {
  profileStatus: {
    status: string;
    regDate: string;
  };
  registrationDate: string;
}

export interface IMemberRefereeProfile {
  profileStatus: {
    status: string;
    regDate: string;
  };
  registrationDate: string;
}

export interface IMemberDelegateOProfile {
  profileStatus: {
    status: string;
    regDate: string;
  };
  registrationDate: string;
}

export interface IMemberDelegateMProfile {
  profileStatus: {
    status: string;
    regDate: string;
  };
  registrationDate: string;
}

export interface IMember {
  id: string;
  sportnetId?: string;
  name: string;
  surname: string;
  regnr: string;
  // photo?: {
  //   description: string;
  //   url: string;
  //   urlpattern: string;
  // };
  isPublic: boolean;
  sex: string;
  country: string;
  card: {
    number: string;
    validity: string;
    ordered: string;
  };
  profiles: Array<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      regnr: string;
    };
  }>;
  birthdate: string;
  datumClenskehoOd: string;
  datumClenskehoDo: string;
  trainerLicenses?: IMemberLicense[];
  refereeLicenses?: IMemberLicense[];
  delegateMLicenses?: IMemberLicense[];
  delegateOLicenses?: IMemberLicense[];
  bio?: AsyncReturnType<(typeof CoreApi)['organizationPPOUser']>['bio'];
  photo?: AsyncReturnType<(typeof CoreApi)['organizationPPOUser']>['photo'];
  stats?: Array<{
    seasonName: string;
    stats: { [key: string]: number };
  }>;
  movements?: Array<{
    club_source: {
      id: number;
      logo: {
        url: string;
        urlpattern: string;
      };
      name: string;
    };
    club_destination: {
      id: number;
      logo: {
        url: string;
        urlpattern: string;
      };
      name: string;
    };
    date: {
      from: string;
      to?: string;
    };
    status: string;
    move_type: string;
  }>;
  playerProfile?: IMemberPlayerProfile;
  trainerProfile?: IMemberTrainerProfile;
  refereeProfile?: IMemberRefereeProfile;
  delegateMProfile?: IMemberDelegateMProfile;
  delegateOProfile?: IMemberDelegateOProfile;
}
