import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import styled, { css } from '../../theme/styled-components';

export const Title = styled('h1')<{ isCentered?: boolean }>`
  display: inline;
  vertical-align: middle;
  line-height: 1.25;
  font-size: ${em(29)};
  color: ${({ theme }) => theme.title.textColor};
  margin: 0;
  padding: 0;
  font-weight: normal;
  word-break: break-word;
  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `}
`;

export const SecondTitle = styled.h2<{ isCentered?: boolean }>`
  display: inline;
  vertical-align: middle;
  line-height: 1.25;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.title.textColor};
  margin: 0;
  padding: 0;
  font-weight: normal;
  word-break: break-word;
  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `}
`;

export const ContextMarginTopStyles = css`
  margin-top: ${rem(24)};
  ${mb('l')} {
    margin-top: ${rem(32)};
  }
`;

export const ContextMarginSmallTopStyles = css`
  margin-top: ${rem(24)};
  ${mb('l')} {
    margin-top: ${rem(32)};
  }
`;
export const ContextMarginSmallBottomStyles = css`
  margin-bottom: ${rem(24)};
  ${mb('l')} {
    margin-bottom: ${rem(32)};
  }
`;

export const ContextMarginBottomStyles = css`
  margin-bottom: ${rem(48)};
  ${mb('s')} {
    margin-bottom: ${rem(56)};
  }
`;

export const ContextMarginBigTopStyles = css`
  margin-top: ${rem(48)};
  ${mb('s')} {
    margin-top: ${rem(56)};
  }
`;

export const ContextMarginBottomStylesReset = css`
  margin-bottom: 0;
  ${mb('s')} {
    margin-bottom: 0;
  }
`;
