import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M8.3,15.1c-0.5,0-1.1-0.2-1.5-0.6L1.1,8.8C0.9,8.7,0.9,8.5,0.9,8.3V1.6c0-0.4,0.3-0.8,0.8-0.8h6.7
      c0.2,0,0.4,0.1,0.5,0.2l5.7,5.7c0.8,0.8,0.8,2.1,0,2.9c0,0,0,0,0,0l-4.8,4.8C9.3,14.9,8.8,15.1,8.3,15.1z M2.4,8l5.5,5.5
      c0.2,0.2,0.6,0.2,0.8,0l4.8-4.8c0.2-0.2,0.2-0.6,0-0.8L8,2.4H2.4V8z M14,9.2L14,9.2L14,9.2z M5.7,4.9c0-0.4-0.3-0.8-0.8-0.8h0
      c-0.4,0-0.7,0.3-0.7,0.8s0.3,0.8,0.8,0.8S5.7,5.4,5.7,4.9z"
    />
  </svg>
);
