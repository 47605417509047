import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import at from 'sportnet-utilities/lib/at';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import config from '../../config';
import { RootState } from '../../configureStore';
import { ISeason } from '../../library/Competitions';
import findUnionInTree from '../../utilities/findUnionInTree';
import getCompetitionsCategoryPanes from '../../utilities/getCompetitionsCategoryPanes';
import { entitiesSelector } from '../App/selectors';
import { currentCompetitionAppSpaceSelector } from '../Competition/selectors';

export const seasonCompetitionsListSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMPETITIONS_BY_SEASON_NAME),
  (entities, ids) => at(entities.competitions, ids || []),
);

const unionsDomainSelector = (state: RootState) => state.application.unions;

export const unionsIsFetchingSelector = createSelector(
  unionsDomainSelector,
  (domain) => domain.isFetching,
);

export const unionsDidFailSelector = createSelector(
  unionsDomainSelector,
  (domain) => !!domain.error,
);

export const unionsSelector = createSelector(
  unionsDomainSelector,
  (domain) =>
    getProp(domain, ['items'], []) as OrganizationProfileStructureItem[],
);

const unionSeasonsDomainSelector = (state: RootState) =>
  state.application.seasons;

export const unionSeasonsIsFetchingSelector = createSelector(
  unionSeasonsDomainSelector,
  (domain) => domain.isFetching,
);

export const unionSeasonsDidFailSelector = createSelector(
  unionSeasonsDomainSelector,
  (domain) => !!domain.error,
);

export const unionSeasonsSelector = createSelector(
  unionSeasonsDomainSelector,
  (domain) => getProp(domain, ['items'], []) as ISeason[],
);

export const competitionsSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMPETITIONS),
  (entities, ids) => at(entities.competitions, ids || []),
);

export const competitionsSelectorByUnion = createSelector(
  competitionsSelector,
  currentCompetitionAppSpaceSelector,
  (competitions, appSpace) => {
    return appSpace ? competitions.filter((competition) => competition.appSpace === appSpace) : []
  },
)

export const topCompetitionsSelector = createSelector(
  competitionsSelector,
  (competitions) => competitions.slice(0, 7),
);

export const favoriteCompetitionsSelector = createSelector(
  competitionsSelector,
  (competitions) => competitions.slice(0, 8),
);

export const unionsStructureSelector = createSelector(
  unionsSelector,
  (unions): OrganizationProfileStructureItem[] => {
    if (unions.length) {
      const { subs: ownerSubs, ...ownerUnion } = unions[0];
      const result = [ownerUnion, ...(ownerSubs || [])];
      // poprehadzovanie zvazov
      const ulkIdx = result.findIndex(
        (union) => union._id === 'ulk.futbalnet.sk',
      );
      if (ulkIdx > 1) {
        result.splice(1, 0, result[ulkIdx]);
        result.splice(ulkIdx + 1, 1);
      }
      const futsalIdx = result.findIndex(
        (union) => union._id === 'futsalslovakia.sk',
      );
      if (futsalIdx > 2) {
        result.splice(2, 0, result[futsalIdx]);
        result.splice(futsalIdx + 1, 1);
      }
      const plazIdx = result.findIndex(
        (union) => union._id === 'issf_union_51',
      );
      if (plazIdx > 3) {
        result.splice(3, 0, result[plazIdx]);
        result.splice(plazIdx + 1, 1);
      }
      return result;
    }
    return [];
  },
);

// selektor podzvazov daneho zvazu
export const unionSubsStructureSelector = createSelector(
  unionsStructureSelector,
  (state: RootState, unionId: string) => unionId,
  (unions, unionId) => {
    const match = findUnionInTree(unionId, unions || []);
    return match?.subs || [];
  },
);

export const competitionsCategoryPanesSelector = createSelector(
  competitionsSelector,
  unionsStructureSelector,
  (competitions, unionsStructure) => {
    return getCompetitionsCategoryPanes(competitions, unionsStructure);
  },
);

export const uniqUnionSeasonsNamesSelector = createSelector(
  unionSeasonsSelector,
  (seasons) => {
    return seasons
      .reduce((acc: string[], i) => {
        if (!acc.includes(i.name)) {
          acc.push(i.name);
        }
        return acc;
      }, [])
      .sort()
      .reverse();
  },
);
