import { rem } from 'polished';
import React from 'react';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import ItemsList from '../../components/ItemsList';
import Item from '../../components/ItemsList/Item';
import OrganizationLogo from '../../components/OrganizationLogo';
import styled, { css } from '../../theme/styled-components';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';

const Wrapper = styled.div`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};

  > ul {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const StyledItemsList = styled(ItemsList)`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};
`;

const UnionItem = styled(Item)<{ padTop: boolean }>`
  > a {
    font-size: ${rem(14)};
    line-height: ${rem(16.8)};
    font-weight: 600;
  }
  ${({ padTop }) =>
    padTop &&
    css`
      margin-top: ${rem(8)};
    `};
`;

const UnionLogo = styled(OrganizationLogo)`
  flex-shrink: 0;
  margin-right: ${rem(16)};
`;

const SubItem = styled(Item)<{ padTop: boolean }>`
  min-height: ${rem(32)};
  > a {
    padding-top: ${rem(6)};
    padding-bottom: ${rem(6)};
    padding-left: ${rem(56)};
  }

  ${({ padTop }) =>
    padTop &&
    css`
      margin-top: ${rem(8)};
    `};
`;

type IOwnProps = {
  unions: OrganizationProfileStructureItem[];
};

const UnionsList: React.FC<IOwnProps> = ({ unions }) => {
  return (
    <Wrapper>
      <StyledItemsList>
        {unions.map((union, idx, thisArray) => {
          const url = getUnionBaseUrl({ zvaz: union._id });
          // ak mal predchadzajuci zvaz podzvazy, aplikujem padding-top.
          const padTop = idx > 0 && (thisArray[idx - 1].subs || []).length > 0;
          return (
            <React.Fragment key={union._id}>
              <UnionItem to={url} padTop={padTop}>
                <UnionLogo alt={union.name} src={getPpoLogoSrc(union)} />
                {union.name}
              </UnionItem>
              {union.subs && union.subs.length > 0 && (
                <>
                  {union.subs.map((sub, sIdx) => (
                    <SubItem
                      key={sub._id}
                      padTop={sIdx === 0}
                      to={getUnionBaseUrl({ zvaz: sub._id })}
                    >
                      {sub.name}
                    </SubItem>
                  ))}
                </>
              )}
            </React.Fragment>
          );
        })}
      </StyledItemsList>
    </Wrapper>
  );
};

export default UnionsList;
