import Col, { Row } from '@sportnet/ui/lib/Grid';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { QueryHocInterface } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Segment, { SegmentWrapper } from '../../../components/Segment';
import UnionCompetitionsWidget from '../../../components/UnionCompetitionsWidget';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { ICompetitionTeam } from '../../../library/Competitions';
import { UnionItem } from '../../../library/Union';
import styled from '../../../theme/styled-components';
import getCompetitionBreadcrumbLabel from '../../../utilities/futbalnet/breadcrumbs/getCompetitionBreadcrumblabel';
import getClubBaseUrl from '../../../utilities/getClubBaseUrl';
import getCompetitionBaseUrl from '../../../utilities/getCompetitionBaseUrl';
import getPpoLogoSrc from '../../../utilities/getPpoLogoSrc';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import __ from '../../../utilities/__';
import { competitionsSelectorByUnion } from '../../FutbalnetApp/selectors';
import { loadCompetitionTeams } from '../actions';
import {
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../selectors';

const Wrapper = styled.div`
  ${SegmentWrapper} {
    margin: ${rem(4)} ${rem(8)};
    ${mb('m')} {
      margin: ${({ theme }) => rem(theme.grid.gutterWidth / 2)} 0;
      padding: ${rem(24)};
      height: calc(100% - ${rem(32)});
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
const Team = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  ${mb('m')} {
    flex-direction: column;
    justify-content: center;
  }
`;
const TeamLogo = styled.div`
  padding-right: ${rem(16)};
  img {
    height: auto;
    width: ${rem(24)};
  }
  ${mb('m')} {
    padding-right: 0;
    padding-bottom: ${rem(24)};
    img {
      width: auto;
      max-height: ${rem(80)};
    }
  }
`;
const TeamName = styled.div`
  font-size: ${rem(14)};
  text-align: left;
  ${mb('m')} {
    text-align: center;
  }
`;

const mapStateToProps = (state: RootState) => ({
  currentCompetition: currentCompetitionSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitions: competitionsSelectorByUnion(state),
});

type Props = { dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

class CompetitionClubs extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return Promise.all([
      dispatch(
        loadCompetitionTeams.action(),
      ),
    ]);
  }

  async componentDidMount() {
    await CompetitionClubs.getInitialProps(this.props);
  }

  renderCompetitionClub = (team: ICompetitionTeam) => {
    const orgLogoSrc = team.organization
      ? getPpoLogoSrc(team.organization)
      : '';

    return (
      <Col xs={12} m={4} xl={3}>
        <Link to={getClubBaseUrl({ klub: team.appSpace })}>
          <Segment>
            <Team>
              <TeamLogo>
                <img alt={team._id} src={orgLogoSrc} />
              </TeamLogo>
              <TeamName>{team.organization?.name}</TeamName>
            </Team>
          </Segment>
        </Link>
      </Col>
    );
  };
  render() {
    const {
      currentCompetition,
      params: { sutaz, zvaz },
      location: { search },
      organizationProfile,
      competitions,
    } = this.props;

    const unionItem = { ...organizationProfile, competitions };

    const title = `${currentCompetition?.name} (${currentCompetition?.season?.name
      }) - ${__('kluby')}`;
    const description = `${currentCompetition?.name}, ${__('kluby')}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale.',
    )}`;
    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';


    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <RempIntegration destroy />
        <TopAd />
        <BottomPaddedBigAd name="big_1" />
        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <Wrapper>
              <Row>
                {(currentCompetition?.teams || []).map(this.renderCompetitionClub)}
              </Row>
            </Wrapper>
            <UnionCompetitionsWidget union={unionItem as UnionItem} />
          </SidebarLayoutContent>
          <Sidebar>
            <StickySidebarContainer>
              <SideAd name="side_1" />
            </StickySidebarContainer>
          </Sidebar>
        </PaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && currentCompetition && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
              {
                label: getCompetitionBreadcrumbLabel(currentCompetition),
                url: getCompetitionBaseUrl({ zvaz, sutaz }),
              },
              {
                label: 'Kluby',
                url: `${getCompetitionBaseUrl({ zvaz, sutaz })}kluby/${search || ''
                  }`,
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(connect(mapStateToProps))(CompetitionClubs);
