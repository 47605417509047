/**
 * Vrati `true` ak video s danou src mozme zobrazit na portali.
 * Poziadavka na zaklade issue #419 ktora specifikuje zakaz zobrazovania
 * videi z JOJ.
 * 
 * @param src 
 */
const isVideoSrcAllowed = (src: string) => {
  return src.search(/http(s)?:\/\/(www\.)?(.*\.)?joj.sk\/.*/);
}

export default isVideoSrcAllowed;