import { linearGradient, rem, transparentize } from 'polished';
import { createGlobalStyle } from './styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import skeletonLoadingStyles from '../components/v2/SkeletonLoader';

export default createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.app.bgColor};
    --anz-font-sans-serif: ${({ theme }) => theme.primaryFont.family};
    --anz-font-sans-serif-bold:${({ theme }) => theme.primaryFont.family};
    --anz-font-weight-bold: 700;
  }

  html {
    font-size: 100%;
    font-family: ${({ theme }) => theme.primaryFont.family};
    overflow-x: hidden;
    scroll-padding-top: 80px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a.button {
    max-width: max-content;
    font-size: ${rem(13)};
    font-family: ${({ theme }) => theme.primaryFont.family};
    color: rgb(51, 51, 51);
    font-weight: 600;
    text-decoration: none;
    outline: none;
    background: none white;
    word-break: break-word;
    line-height: ${rem(16)};
    padding: ${rem(4)} ${rem(8)};
    min-height: ${rem(2)};
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    vertical-align: top;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: ${rem(4)};
    cursor: pointer;
  }

  blockquote {
    margin-left: 0;
    margin-right: 0;
  }

  * {
    box-sizing: border-box;
    min-width: 0;
    font-variant-ligatures: none;
    text-rendering: geometricPrecision;
  }

  .react-tooltip-lite {
    background: #333;
    color: white;
    font-size: 13px;
    * {
      color: white;
    }
  }

  .react-tooltip-lite-arrow {
    border-color: #333;
  }

  /* Style overrides for adverts */
  .artemis-promo-labels:not(.admngr-promo-labels-bfix) span.admngr-c-black-50 {
    padding-left: ${rem(5)};
    font-size: ${rem(12)};
    text-transform: uppercase;
    color: #7f7f7f;
    font-family: ${({ theme }) => theme.primaryFont.family};
  }

  .artemis-ad-position {
     text-align: center;
  }
  .artemis-ad-position:empty { margin:0; }

  .content-widgets .artemis-promo-labels {
    margin-top: 0;
  }
  .content-widgets .content_body_1,
  .content-widgets .content_body_2,
  .content-widgets .content_body_3,
  .content-widgets .content_body_4,
  .content-widgets .content_body_5,
  .content-widgets .content_body_6,
  .content-widgets .content_body_7,
  .content-widgets .content_body_8,
  .content-widgets .content_body_9,
  .content-widgets .content_body_10,
  .content-widgets .content_body_11,
  .content-widgets .content_body_12,
  .content-widgets .content_body_13,
  .content-widgets .content_body_14,
  .content-widgets .content_body_15 {
    margin-bottom: ${rem(24)};
  }

  /* Anzu forum styles */
  #anzu-forum-widget-profile,
  #anzu-forum-widget {
      font-size: 14px;
  }

  /* Petitpress diskusie */
  .is-hidden {
    display: none;
  }

  .unions-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .unions-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .unions-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 48px;
  }

  .paywall-shadow {
    position: absolute;
    ${({ theme }) =>
      linearGradient({
        colorStops: [
          `${transparentize(1, theme.app.bgColor)} 0%`,
          `${theme.app.bgColor} 100%`,
        ],
      })}
    height: ${rem(80)};
    left: 0;
    right: 0;
    bottom: calc(100% + ${rem(48)});
    ${mb('s')} {
      bottom: calc(100% + ${rem(56)});
    }
  }
  
  body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
  }

  ${skeletonLoadingStyles}
`;
