import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import ArticleMeta from '../../components/Articles/ArticleMeta';
import { ReduxConnectProps, RootState } from '../../configureStore';
import RempIntegration from '../../containers/RempIntegration';
import { ArticleId, RouteProps } from '../../library/App';
import getIdFromProps from '../../utilities/getIdFromProps';
import { setScrollProgress } from '../App/actions';
import { setCurrentArticleId } from '../Article/actions';
import { currentArticleSelector } from '../Article/selectors';
import SportnetTracker from '../Article/SportnetTracker';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';
import RegularArticle from './pages/RegularArticle';

interface OwnProps {
  id: ArticleId;
}

type IRouteProps = RouteProps<{ id?: string }>;

const mapStateToProps = (state: RootState, props: OwnProps & IRouteProps) => {
  return {
    article: currentArticleSelector(state),
    appSpace: getCurrentCompetitionAppSpaceSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IRouteProps & IMapStateToProps & ReduxConnectProps;

class Article extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const id = getIdFromProps(props);
    dispatch(setCurrentArticleId(id));
  }

  async componentDidMount() {
    try {
      await Article.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (getIdFromProps(prevProps) !== getIdFromProps(this.props)) {
      try {
        await Article.getInitialProps(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentArticleId(null));
    dispatch(setScrollProgress(null));
  }

  render() {
    const { article, appSpace } = this.props;

    if (!article) {
      return null;
    }

    return (
      <>
        <ArticleMeta article={article} />
        <RempIntegration destroy />
        <RegularArticle article={article} appSpace={appSpace} />
        <SportnetTracker article={article} appSpace={appSpace} />
      </>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(Article) as any;
