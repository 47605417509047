import React from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode';

const QrCanvas = styled.canvas`
  height: 2cm;
  width: auto;
`;

interface OwnProps {
  url: string;
}

const FutbalnetPrintQrCode: React.FC<OwnProps> = ({ url }) => {
  const canvasRef = React.createRef<HTMLCanvasElement>();

  React.useEffect(() => {
    (async () => {
      try {
        if (canvasRef.current) {
          await QRCode.toCanvas(canvasRef.current, url, {
            margin: 0,
            width: 72,
          });
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [canvasRef, url]);

  return <QrCanvas ref={canvasRef} />;
};

export default FutbalnetPrintQrCode;
