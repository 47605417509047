import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProp } from 'sportnet-utilities';
import { css } from 'styled-components';
import { RootState } from '../../configureStore';
import { mainNavigationLinkClass } from '../../containers/Header/wrappers/HeaderSimple/Header/constants';
import LayoutContext from '../../contexts/LayoutContext';
import UrlContext from '../../contexts/UrlContext';
import useClientVariable from '../../hooks/useClientVariable';
import useIsResponsiveLayout from '../../hooks/useIsResponsiveLayout';
import { RouteProps, SectionNode, SectionTreeType } from '../../library/App';
import {
  sportnetMainMenuSelector,
  sportnetSideMenuSelector,
} from '../../pages/App/selectors';
import { MoreIcon } from '../../pages/Commitee/styles';
import { currentSectionIdSelector } from '../../pages/Section/selectors';
import styled from '../../theme/styled-components';
import isBrowser from '../../utilities/isBrowser';
import useResponsiveList from '../../utilities/useResponsiveList';
import SectionsDropDown from '../DropDowns/SectionsDropDown';
import { HideScrollBarStyles } from '../Layout/ScrollStyles';
import Link from '../Link';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import FullWidthPositionedDropdown from './FullWidthPositionedDropdown';
import MenuItem from './MenuItem';
import PositionedDropdown from './PositionedDropdown';
import { IMenuItem } from './types';
import getSportnetSectionUrl from '../../utilities/getSectionUrl';
import NikeLogoTrackingImage from '../NikeLogoTrackingImage';
import config from '../../config';
import CustomImage from '../../sportnet.sme.sk-ui/CustomImage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div<{
  color?: string;
  fixed?: boolean;
}>`
  width: 100%;
  border-bottom: ${rem(1)} solid #ddd;
  position: relative;
  background: ${({ color }) => (color ? color : '#f9f9f9')};
  ${mb('l')} {
    background: #fff;
  }
  ${({ fixed }) =>
    !!fixed &&
    css`
      position: fixed;
      top: 0;
      border-top-width: 0;
      z-index: 14;
    `}
`;

const Content = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family};
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  ${mb(1232)} {
    padding: 0 ${({ theme }) => rem(theme.app.padding)};
  }
  margin: 0 auto ${rem(-1)} auto;
  width: 100%;
  flex: 1 0 0;
  position: relative;
`;

const ScrollWrapper = styled.div<{ overflow: boolean }>`
  ${({ overflow }) =>
    overflow
      ? css`
          overflow-x: auto;
        `
      : css`
  overflow: visible:
  `}
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0 0 ${rem(-1)} 0;
  display: flex;
  // justify-content: space-between;
  ${HideScrollBarStyles};
  scrollbar-width: none;
  > ul:nth-child(1) {
    justify-content: flex-start;
    flex-shrink: 0;
    padding-right: 0;
  }
  > ul:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }
  > ul:nth-child(3) {
    justify-content: flex-end;
    flex-shrink: 0;
    display: none;
    ${mb('l')} {
      display: flex;
    }
  }
`;

const DoxxbetLink = styled.a`
  display: flex;
  align-items: center;
  > span {
    height: ${rem(20)};
  }
  > img {
    height: ${rem(20)};
  }
  max-width: ${rem(140)};
`;

const Menu = styled.ul<{
  initializing: boolean;
  leftPadded?: boolean;
  offsetLastItem?: boolean;
  arrowMenu?: boolean;
}>`
  // &:before {
  //   width: 100%;
  //   height: ${rem(1)};
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   right: calc(100% - ${rem(16)});
  //   background: white;
  // }
  list-style: none;
  margin: 0;
  // width: 100%;
  display: flex;
  padding: 0 ${({ theme }) => rem(theme.app.padding)};
  ${({ offsetLastItem }) =>
    !!offsetLastItem &&
    css`
      > li:last-child {
        padding-right: ${rem(40)};
      }
    `}
  ${mb(1232)} {
    padding: 0;
  }
  > li {
    display: flex;
    flex-shrink: 0;
  }
  > li:last-child > * > div {
    border-right: none;
  }
  ${({ arrowMenu }) =>
    !!arrowMenu &&
    css`
      > li:last-child > * > div {
        border-right: ${rem(1)} solid #ddd;
        margin-right: ${rem(16)};
        width: ${rem(32)};
      }
    `}
  ${({ leftPadded }) =>
    !!leftPadded &&
    css`
      > li:first-child > * > div {
        margin-left: -${rem(16)};
      }
    `}
  ${({ initializing }) =>
    initializing &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

const MoreMenuItem = styled.li`
  width: ${rem(40)};
  > div {
    height: 100%;
    width: 100%;
    > button {
      width: 100%;
      border: none;
      background: transparent;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > span {
        margin: 0;
      }
    }
  }
`;

const FixedContent = styled.div`
  position: absolute;
  width: ${rem(72)};
  height: 100%;
  top: 0;
  z-index: 15;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightFixed = styled(FixedContent)`
  > img {
    height: ${rem(25)};
  }
  > span {
    height: ${rem(16)};
  }
  max-width: ${rem(73)};
  padding: 0 ${rem(4)};
  right: 0;
  background: #fff;
  border-bottom: ${rem(1)} solid #ddd;
  :before {
    border-right: ${rem(1)} solid #ddd;
    width: ${rem(16)};
    height: 100%;
    position: absolute;
    left: 0;
    transform: translate(-100%, 0%);
    background: transparent
      linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.08) 100%) 0%
      0% no-repeat padding-box;
    content: '';
  }
`;

const Icon = styled.img`
  padding-left: ${rem(8)};
  flex-shrink: 0;
  width: ${rem(24)};
  height: auto;
`;

const DoxxMenuItem = styled.li`
  padding: 0 0 0 ${rem(16)};
  align-items: center;
`;

const ArrowIcon = styled(MoreIcon)`
  margin: 0;
`;

interface OwnProps {
  items: IMenuItem[];
  menuId: string;
  activeItemId?: string;
  withoutSubmenu?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    mainMenuSections: sportnetMainMenuSelector(state),
    sideMenuItems: sportnetSideMenuSelector(state),
    sectionId: currentSectionIdSelector(state),
  };
};

type Props = OwnProps & RouteProps & ReturnType<typeof mapStateToProps>;

const NIKE_FANTASY = {
  public_url:
    'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2024/10/nike-fantasy.png',
  media_url:
    'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2024/10/nike-fantasy.png',
};

const NIKE_FANTASY_SHORT = {
  public_url:
    'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2024/10/nike-fantasy-short.png',
  media_url:
    'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2024/10/nike-fantasy-short.png',
};

const ThirdLevelMenu: React.FC<Props> = ({
  mainMenuSections,
  sectionId,
  items,
  location,
  activeItemId: defaultActiveItemId = '',
  sideMenuItems,
  menuId,
  withoutSubmenu = false,
}) => {
  const isMobileLayout = useIsResponsiveLayout(992);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const [activeItemId, setActiveItemId] = React.useState('');
  const hash = useClientVariable(location.hash, '');

  const [moreSectionsOpened, setMoreSectionsOpened] = React.useState(false);

  const { getSectionUrl } = React.useContext(UrlContext);
  const { isMenuFixed, menuPositioner: wrapperElRef } =
    React.useContext(LayoutContext);

  const {
    primaryItems,
    secondaryItems,
    initializing,
    listRef,
    itemSizes,
    listSize,
  } = useResponsiveList(items, 40);

  const dropdownItems = [...items];

  if (activeItemId) {
    const activeItemIdx = items.findIndex((i) => i.id === activeItemId);
    const secondaryItemIdx = secondaryItems.findIndex(
      (i) => i.id === activeItemId
    );
    if (secondaryItemIdx > -1 && primaryItems.length) {
      // menu items
      const lastPrimaryItem = primaryItems[primaryItems.length - 1];
      const secondaryItem = secondaryItems[secondaryItemIdx];
      primaryItems[primaryItems.length - 1] = secondaryItem;
      delete secondaryItems[secondaryItemIdx];
      secondaryItems.unshift(lastPrimaryItem);
      // for dropdown purposes
      if (!isMobileLayout) {
        const firstItemIdx = dropdownItems.findIndex(
          (i) => i.id === lastPrimaryItem.id
        );
        const secondItemIdx = dropdownItems.findIndex(
          (i) => i.id === secondaryItem.id
        );
        const firstItem = dropdownItems[firstItemIdx];
        const secondItem = dropdownItems[secondItemIdx];
        dropdownItems[firstItemIdx] = secondItem;
        dropdownItems[secondItemIdx] = firstItem;
      }
      let currentSize = 0;

      for (let itemIdx = 0; itemIdx < primaryItems.length - 1; itemIdx++) {
        currentSize += itemSizes[itemIdx];
      }
      currentSize += itemSizes[activeItemIdx];
      currentSize += 40;

      if (currentSize > listSize) {
        const nextPrimaryItem = primaryItems[primaryItems.length - 2];
        secondaryItems.unshift(nextPrimaryItem);
        delete primaryItems[primaryItems.length - 2];
      }
    }
  }

  React.useEffect(() => {
    const activeItem = items.find((i) => {
      return i.url && i.url === location.pathname;
    });

    if (activeItem) {
      setActiveItemId(activeItem.id);
    }
  }, [items, location]);

  const isElementInViewport = (el) => {
    if (el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right - 1 <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return {};
  };

  const getScrollParent = (node: Element) => {
    const regex = /(auto|scroll)/;
    const parents = (_node, ps) => {
      if (_node.parentNode === null) {
        return ps;
      }
      return parents(_node.parentNode, ps.concat([_node]));
    };

    const style = (_node, prop) =>
      getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = (_node) =>
      style(_node, 'overflow') +
      style(_node, 'overflow-y') +
      style(_node, 'overflow-x');
    const scroll = (_node) => regex.test(overflow(_node));

    const scrollParent = (_node) => {
      if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
        return;
      }

      const ps = parents(_node.parentNode, []);

      for (let i = 0; i < ps.length; i += 1) {
        if (scroll(ps[i])) {
          return ps[i];
        }
      }

      return document.scrollingElement || document.documentElement;
    };

    return scrollParent(node);
  };

  React.useEffect(() => {
    if (isMobileLayout && isBrowser()) {
      const activeElements = document.getElementsByClassName(
        `${menuId}_activeThirdLevelMenuItem`
      );

      Array.from(activeElements || []).forEach((activeElement) => {
        if (!isElementInViewport(activeElement)) {
          const activeElementParent: Element = getScrollParent(activeElement);
          const { left, width } = activeElement.getBoundingClientRect();

          activeElementParent.scrollTo({
            top: 0,
            left: left - width,
          });
        }
      });
    }
  }, [isMobileLayout, menuId]);

  const handleResetActiveItem = React.useCallback(() => {
    setActiveItemId('');
  }, []);

  React.useEffect(() => {
    handleResetActiveItem();
  }, [location.pathname, handleResetActiveItem]);

  const handleClickOutside = (e: any) => {
    e.stopPropagation();
    if (!wrapperRef.current?.contains(e.target as Node)) {
      handleResetActiveItem();
    }
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.keyCode === 27) {
      handleResetActiveItem();
    }
  };

  if (isBrowser()) {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);
  }

  const handleItemClick = React.useCallback((item: IMenuItem) => {
    if (item.renderDropdown) {
      setActiveItemId((prev) => (prev === item.id ? '' : item.id));
    } else {
      setActiveItemId(item.id);
    }
  }, []);

  const isItemActive = (item: IMenuItem) => {
    return activeItemId
      ? item.id === activeItemId
      : item.hash
      ? hash.startsWith(item.hash)
      : defaultActiveItemId
      ? item.id === defaultActiveItemId
      : false;
  };

  const isAnyItemActive = !!items.find(isItemActive);

  const isSectionsChild = (
    sectionsList: readonly SectionNode[],
    parentId = sectionId
  ) => {
    const isSectionChild = sectionsList.find((i) => i._id === parentId);

    if (!isSectionChild) {
      let childOfSectionChild: SectionNode | null = null;
      sectionsList.some((i) => {
        childOfSectionChild = isSectionsChild(i.sections || [], parentId);
        return childOfSectionChild;
      });
      return childOfSectionChild;
    }
    return isSectionChild;
  };

  const renderMenuItem = (
    item: IMenuItem,
    opt?: { isMainSectionItem?: boolean }
  ) => {
    let active = isItemActive(item);
    if (opt && opt.isMainSectionItem) {
      const rootSection = mainMenuSections.find(
        (i) => Number(item.id) === i._id
      );

      active = rootSection ? !!isSectionsChild([rootSection]) : false;
    }
    return (
      <MenuItem
        key={item.id}
        menuId={menuId}
        item={item}
        active={active}
        routeActive={active}
        onClick={handleItemClick}
        isAnyActive={isAnyItemActive}
      />
    );
  };

  const getDropdownPosition = (idx: number) => {
    const menuEl = listRef.current;
    const wrapperEl = wrapperRef.current;
    if (menuEl && wrapperEl) {
      const pos =
        (menuEl.children[idx]?.getBoundingClientRect().x || 0) -
        wrapperEl.getBoundingClientRect().x;
      return pos > 0 ? pos : 0;
    }
    return 0;
  };

  const renderDropdowns = (item: IMenuItem, idx: number) => {
    if (item.renderDropdown) {
      return (
        <DropdownItem
          key={item.id}
          item={item}
          xPosition={getDropdownPosition(idx)}
          active={isItemActive(item)}
          onClose={handleResetActiveItem}
        />
      );
    }
    return null;
  };

  let parentSection: SectionNode | null = null;
  const currentSection = isSectionsChild(mainMenuSections);
  if (currentSection) {
    parentSection = isSectionsChild(
      mainMenuSections,
      getProp(currentSection, ['parent', 0])
    );
  }
  return (
    <Wrapper>
      {isMobileLayout && (
        <RowWrapper color="#fff">
          <Content>
            <ScrollWrapper overflow>
              <Menu leftPadded>
                {mainMenuSections.map((i) =>
                  renderMenuItem(
                    {
                      id: i._id ? String(i._id) : '',
                      label: i.name || '',
                      url: `/${i.url || ''}/`.replace('//', '/'),
                    },
                    { isMainSectionItem: true }
                  )
                )}
              </Menu>
            </ScrollWrapper>
          </Content>
        </RowWrapper>
      )}
      {!withoutSubmenu && (
        <RowWrapper fixed={isMenuFixed}>
          <Content ref={wrapperRef}>
            {isMobileLayout && (
              <Link
                to={`https://www.nike.sk/clanky/nike-liga-fantasy?bn=${config.nike.LOGO_TRACKING_BN_MOBILE}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <NikeLogoTrackingImage
                  bn={config.nike.LOGO_TRACKING_BN_MOBILE}
                />
                <RightFixed>
                  <CustomImage
                    src={getMediaManagerUrl(
                      NIKE_FANTASY_SHORT,
                      0,
                      50,
                      'resize',
                      true
                    )}
                    alt="Fantasy Liga logo"
                    height="100%"
                  />
                </RightFixed>
              </Link>
            )}
            <ScrollWrapper ref={scrollRef} overflow={isMobileLayout}>
              <Menu arrowMenu>
                {!!parentSection && (
                  <MenuItem
                    menuId={menuId}
                    isAnyActive={isAnyItemActive}
                    className="visibleMenuItem"
                    item={{
                      id: '',
                      label: '',
                      ...(!!parentSection.url && {
                        url: getSectionUrl({
                          ...parentSection,
                          _id: parentSection._id!,
                          name: parentSection.name!,
                        }),
                      }),
                      icon: () => (
                        <ArrowIcon name="arrow-left" size={14} color="#333" />
                      ),
                    }}
                  />
                )}
              </Menu>
              <Menu
                offsetLastItem={isMobileLayout}
                leftPadded
                initializing={initializing}
                ref={listRef}
              >
                {isMobileLayout
                  ? dropdownItems.map((i) => renderMenuItem(i))
                  : primaryItems.map((i) => renderMenuItem(i))}
                {!isMobileLayout && secondaryItems.length > 0 && (
                  <MoreMenuItem>
                    <SectionsDropDown
                      sections={
                        secondaryItems.map((i) => {
                          return {
                            ...i,
                            _id: !isNaN(Number(i.id)) ? Number(i.id) : i.id,
                            name: i.label || '',
                            url: i.url || '',
                            onClick: handleItemClick,
                          };
                        }) as SectionTreeType
                      }
                      opened={moreSectionsOpened}
                      onClose={() => setMoreSectionsOpened(false)}
                      color={'#333'}
                      activeColor={'#CF2528'}
                    >
                      <button
                        className={mainNavigationLinkClass}
                        onClick={() => {
                          if (moreSectionsOpened) {
                            setMoreSectionsOpened(false);
                          } else {
                            setMoreSectionsOpened(true);
                          }
                        }}
                      >
                        <MoreIcon name="more" size={12} color="#CF2528" />
                      </button>
                    </SectionsDropDown>
                  </MoreMenuItem>
                )}
              </Menu>
              {!isMobileLayout && (sideMenuItems || []).length > 0 && (
                <Menu>
                  {sideMenuItems?.map((sideMenuItem, idx) => (
                    <MenuItem
                      key={`menu-item-${menuId}-${idx}`}
                      menuId={menuId}
                      className="visibleMenuItem"
                      isAnyActive={isAnyItemActive}
                      isSideItem
                      item={{
                        id: String(sideMenuItem._id!),
                        label: sideMenuItem.name!,
                        ...(!!sideMenuItem.url && {
                          url: getSportnetSectionUrl('', {
                            ...sideMenuItem,
                            _id: sideMenuItem._id!,
                            name: sideMenuItem.name!,
                          }),
                        }),
                        ...(!!sideMenuItem.icon && {
                          icon: () => {
                            const src = getMediaManagerUrl(
                              sideMenuItem.icon,
                              24,
                              24,
                              'media',
                              false
                            );
                            return (
                              <Icon
                                src={src}
                                alt={sideMenuItem.name}
                                height={24}
                              />
                            );
                          },
                        }),
                      }}
                    />
                  ))}
                  <DoxxMenuItem className="visibleMenuItem">
                    <DoxxbetLink
                      href={`https://www.nike.sk/clanky/nike-liga-fantasy?bn=${config.nike.LOGO_TRACKING_BN_DESKTOP}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <CustomImage
                        src={getMediaManagerUrl(
                          NIKE_FANTASY,
                          0,
                          40,
                          'resize',
                          true
                        )}
                        alt="Fantasy Liga logo"
                        height="100%"
                      />
                    </DoxxbetLink>
                    <NikeLogoTrackingImage
                      bn={config.nike.LOGO_TRACKING_BN_DESKTOP}
                    />
                  </DoxxMenuItem>
                </Menu>
              )}
            </ScrollWrapper>
            {dropdownItems.map(renderDropdowns)}
          </Content>
        </RowWrapper>
      )}

      <div className="ahoj" ref={wrapperElRef} />
    </Wrapper>
  );
};

export { PositionedDropdown, FullWidthPositionedDropdown, Dropdown };

export default connect(mapStateToProps)<
  OwnProps & ReturnType<typeof mapStateToProps>
>(withRouter(ThirdLevelMenu));
