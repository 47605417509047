import React from 'react';
import RenderCompetitionStandingTableHeader from './RenderCompetitionStandingTableHeader';
import {
  CompetitionStandingPrintData,
  IBaseObjectName,
} from '../../../library/CompetitionStanding';
import RenderCompetitionStandingPrintDataItem from './RenderCompetitionStandingPrintDataItem';
import {
  EmptyFooter,
  EmptyHeader,
  Table,
  Tr,
} from '../CompetitionMatchesPrint/RenderPrintData';
import { getRandomString } from '@sportnet/content/utilities/utilities';
import getCorrections from '../../../utilities/competitionStanding/getCorrections';
import { Td } from '../CompetitionMatchesPrint/MatchListItem';
import getResultsByPoints from '../../../utilities/competitionStanding/getResultsByPoints';
import sortResults from '../../../utilities/competitionStanding/sortResults';

interface OwnProps {
  competitionStandingPrintData: CompetitionStandingPrintData;
  tableView: IBaseObjectName;
  highlightTeamId?: string;
}

const RenderCompetitionStandingPrintData: React.FC<OwnProps> = ({
  competitionStandingPrintData,
  tableView,
  highlightTeamId,
}) => {
  const teams = competitionStandingPrintData.teams ?? [];
  const sportSector = competitionStandingPrintData?.rules?.sport_sector;
  const isStatsIssf = Boolean(
    competitionStandingPrintData?.resultsTable?.results[0]?.stats.issf,
  );

  const numberOfCols = sportSector === 'beachfutbal' ? 9 : isStatsIssf ? 12 : 9;

  const corrections = competitionStandingPrintData.resultsTable?.results
    ? getCorrections(competitionStandingPrintData.resultsTable?.results)
    : undefined;

  const renderResultItem = (resultsTableItem, idx) => {
    const competitionPartTeam = teams?.find(
      (team) => team._id === resultsTableItem.team._id,
    );
    return !!competitionPartTeam ? (
      <RenderCompetitionStandingPrintDataItem
        key={`competitionStanding-resultsTableItem-${competitionPartTeam._id
          }-${getRandomString(5)}`}
        idx={idx}
        resultsTableItem={resultsTableItem}
        tableView={tableView}
        team={competitionPartTeam}
        sportSector={sportSector}
        highlighted={
          !!highlightTeamId &&
          (highlightTeamId === competitionPartTeam._id ||
            highlightTeamId ===
            String(competitionPartTeam.__issfId ?? ''))
        }
      />
    ) : null;
  }

  const resultsByPoints = getResultsByPoints(competitionStandingPrintData.resultsTable?.results || [], tableView);

  const renderTableResults = () => {
    let order = -1;
    return Object.keys(resultsByPoints).map(Number).sort((a, b) => {
      return a < b ? 1 : -1;
    }).map((pointsLevel) => {
      return sortResults(resultsByPoints[pointsLevel], tableView, resultsByPoints[pointsLevel].length > 1).map((i) => {
        order++;
        return renderResultItem(i, order)
      })
    })
  }

  return (
    <>
      <Table>
        <thead>
          <EmptyHeader>
            <tr>
              <td colSpan={numberOfCols}>&nbsp;</td>
            </tr>
          </EmptyHeader>
          <RenderCompetitionStandingTableHeader
            sportSector={sportSector}
            isStatsIssf={isStatsIssf}
          />
        </thead>
        <tbody>
          {renderTableResults()}

          {competitionStandingPrintData.publicComment && (
            <Tr>
              <Td colSpan={numberOfCols}>
                {competitionStandingPrintData.publicComment}
              </Td>
            </Tr>
          )}
          {corrections &&
            corrections?.length > 0 &&
            corrections.map((correction, idx) => {
              return (
                <Tr key={`correction-${idx}`}>
                  <Td colSpan={numberOfCols}>{correction}</Td>
                </Tr>
              );
            })}
        </tbody>
        <EmptyFooter>
          <tr>
            <td colSpan={numberOfCols}>&nbsp;</td>
          </tr>
        </EmptyFooter>
      </Table>
    </>
  );
};

export default RenderCompetitionStandingPrintData;
