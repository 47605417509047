import { SectionNode } from '../library/App';

export default function (baseUri: string, section: SectionNode) {
  if (section.redirecturl) {
    if (!section.redirecturl.startsWith('http')) {
      return `${baseUri}${section.redirecturl}`
    }
    return section.redirecturl;
  }

  if (section.url) {
    return `${baseUri}/${section.url}/`;
  }

  return '/#';
}
