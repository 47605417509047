import Loader from '@sportnet/ui/lib/Loader';
import { darken, rem } from 'polished';
import * as React from 'react';
import styled, {
  css,
  ThemeProvider,
  withTheme,
} from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import Icon from '../Icon';

type ButtonSize = 's' | 'm' | 'l';

interface IButtonTheme {
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderColor?: string;
  buttonBoxShadow?: string;
  size: ButtonSize;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
}

const customDarken = (amount: number, color: string) => {
  if (color === 'transparent') {
    return `rgba(0, 0, 0, ${amount})`;
  }
  return darken(amount, color);
};
type IWrapper = {
  disabled?: boolean;
  block?: boolean;
  theme?: IButtonTheme;
};
const Wrapper = styled('button')<IWrapper>`
  margin: 0;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  background: ${({ theme }) => theme.buttonBackgroundColor};
  color: ${({ theme }) => theme.buttonTextColor};
  vertical-align: baseline;
  text-transform: none;
  text-shadow: none;
  font-family: ${({ theme }) => theme.primaryFont.family };
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
  border-radius: ${rem(4)};
  border: ${rem(1)} solid ${({ theme }) => theme.buttonBorderColor};
  ${({ theme }) =>
    theme.buttonBoxShadow &&
    css`
      box-shadow: ${theme.buttonBoxShadow};
    `};
  ${({ theme }) => {
    switch (theme.size) {
      case 's':
        return css`
          font-size: ${rem(13)};
          font-weight: 400;
          min-height: ${rem(32)};
          padding: ${rem(7)} ${rem(14)};
        `;
      case 'm':
        return css`
          font-size: ${rem(theme.fontSize || 14)};
          font-weight: ${theme.fontWeight || 600};
          min-height: ${rem(40)};
          padding: ${rem(10)} ${rem(16)};
        `;
      case 'l':
        return css`
          font-size: ${rem(14)};
          font-weight: 600;
          min-height: ${rem(48)};
          padding: 0 ${rem(22)};
        `;
      default:
        return '';
    }
  }}
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  ${({ disabled, theme }) =>
    disabled
      ? ''
      : css`
          &:hover {
            background: ${customDarken(0.05, theme.buttonBackgroundColor)};
          }
          &:focus {
            background: ${customDarken(0.1, theme.buttonBackgroundColor)};
          }
          &:active {
            background: ${customDarken(0.2, theme.buttonBackgroundColor)};
          }
        `};
`;

const StyledLoader = styled(Loader)<{ loading: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left top;
  ${({ loading }) => {
    if (loading) {
      return css`
        opacity: 1;
        transform: scale(1) translate(-50%, -50%);
      `;
    }
    return css`
      opacity: 0;
      transform: scale(0) translate(-50%, -50%);
    `;
  }};
`;

const Content = styled('div')<{ isLoading: boolean }>`
  transition: opacity 0.2s;
  opacity: ${({ isLoading }) => (isLoading ? 0.25 : 1)};
  display: flex;
  align-items: center;
`;

// React.PropsWithoutRef<JSX.IntrinsicElements["button"]>
// React.HTMLProps<HTMLButtonElement>
export interface OwnProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children?: React.ReactNode;
  primary?: boolean;
  basic?: boolean;
  secondary?: boolean;
  danger?: boolean;
  success?: boolean;
  warning?: boolean;
  block?: boolean;
  size?: ButtonSize;
  loading?: boolean;
  disabled?: boolean;
}

type Props = OwnProps & {
  theme: ITheme;
};

class Button extends React.PureComponent<Props> {
  static defaultProps: OwnProps = {
    className: '',
    children: null,
    primary: false,
    danger: false,
    success: false,
    warning: false,
    block: false,
    secondary: false,
    basic: false,
    loading: false,
    disabled: false,
    size: 'l',
    type: 'button',
  };

  render() {
    const {
      theme,
      primary,
      danger,
      success,
      warning,
      block,
      basic,
      secondary,
      loading,
      disabled,
      onClick,
      className,
      children,
      size,
      ...restProps
    } = this.props;

    let buttonTheme: IButtonTheme = {
      buttonBackgroundColor: 'transparent',
      buttonTextColor: theme.textColor,
      size: size!,
    };

    // basic states
    if (primary) {
      if (basic) {
        buttonTheme = {
          ...buttonTheme,
          buttonTextColor: theme.color.primary,
        };
      } else {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: theme.color.primary,
          buttonTextColor: '#fff',
        };
      }
    } else if (danger) {
      if (basic) {
        buttonTheme = {
          ...buttonTheme,
          buttonTextColor: theme.color.danger,
        };
      } else {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: theme.color.danger,
          buttonTextColor: '#fff',
        };
      }
    } else if (success) {
      if (basic) {
        buttonTheme = {
          ...buttonTheme,
          buttonTextColor: theme.color.success,
        };
      } else {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: theme.color.success,
          buttonTextColor: '#fff',
        };
      }
    } else if (warning) {
      if (basic) {
        buttonTheme = {
          ...buttonTheme,
          buttonTextColor: theme.color.warning,
        };
      } else {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: theme.color.warning,
          buttonTextColor: '#fff',
        };
      }
    } else if (secondary) {
      buttonTheme = {
        ...buttonTheme,
        buttonBorderColor: theme.color.separator,
        buttonBoxShadow: `0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.12)`,
        buttonBackgroundColor: '#fff',
        buttonTextColor: theme.app.textColor,
        fontSize: 13,
        fontWeight: 400,
      };
    }

    if (disabled) {
      if (basic) {
        buttonTheme = {
          ...buttonTheme,
          buttonTextColor: theme.app.secondaryTextColor,
        };
      } else if (secondary) {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: customDarken(0.1, '#fff'),
        };
      } else {
        buttonTheme = {
          ...buttonTheme,
          buttonBackgroundColor: theme.app.secondaryTextColor,
          buttonTextColor: '#fff',
        };
      }
    }

    // nastavime spravny border
    if (basic) {
      buttonTheme = {
        ...buttonTheme,
        buttonBorderColor: buttonTheme.buttonTextColor,
      };
    } else if (secondary) {
      buttonTheme = {
        ...buttonTheme,
        buttonBorderColor: theme.color.separator,
      };
    } else {
      buttonTheme = {
        ...buttonTheme,
        buttonBorderColor: buttonTheme.buttonBackgroundColor,
      };
    }

    return (
      <ThemeProvider theme={buttonTheme as any}>
        <Wrapper
          className={`sportnetui-btn ${className}`}
          block={block}
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            if (!disabled && onClick) {
              onClick(e);
            }
          }}
          {...restProps}
        >
          <Content isLoading={loading!}>
            {Array.isArray(children)
              ? React.Children.map(children, (child: any, idx: number) => {
                  if (!child) {
                    return child;
                  } else if (child.type === Icon) {
                    if (idx === 0 && children.length > 1) {
                      return React.cloneElement(child, {
                        style: { margin: `0 ${rem(8)} 0 0` },
                      });
                    } else if (
                      idx === children.length - 1 &&
                      children.length > 1
                    ) {
                      return React.cloneElement(child, {
                        style: { margin: `0 0 0 ${rem(8)}` },
                      });
                    }
                  }
                  return child;
                })
              : children}
          </Content>
          <StyledLoader
            size="l"
            loading={loading!}
            color={buttonTheme.buttonTextColor}
          />
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default withTheme(Button);
