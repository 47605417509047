import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { IMatch } from './../library/Competitions';

export default (
  match: IMatch | IWidgetMatchesListMatchItem,
  { homeTeamId, awayTeamId }: { homeTeamId: string; awayTeamId: string },
) => {
  let PPWinnerTeamId: any = null;
  let SNWinnerTeamId: any = null;
  if ((match.penaltiesScore || []).length) {
    const [homeTeamSNGoals, awayTeamSNGoals] = match.penaltiesScore || [];
    SNWinnerTeamId =
      homeTeamSNGoals > awayTeamSNGoals ? homeTeamId : awayTeamId;
  } else if (match.timer && match.timer.OT) {
    const [homeTeamPPGoals, awayTeamPPGoals] = match.scoreByPhases
      ? match.scoreByPhases[3] || []
      : [];
    PPWinnerTeamId =
      homeTeamPPGoals > awayTeamPPGoals ? homeTeamId : awayTeamId;
  }
  return { PPWinnerTeamId, SNWinnerTeamId };
};
