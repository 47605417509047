import React from 'react';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

interface Props {
  content: React.ReactNode;
  size?: string | number;
  color?: string;
}

const InfoTooltip: React.FC<Props> = ({ content, size = 'm', color }) => {
  return (
    <Tooltip content={content}>
      <Icon name="tooltip" size={size as any} color={color} />
    </Tooltip>
  );
};

export default InfoTooltip;
