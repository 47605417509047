import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { RootState } from '../../configureStore';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';
import {
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from './selectors';

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  appSettings: appSettingsSelector(state),
});

type Props = RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  ReturnType<typeof mapStateToProps>;

class CompetitionPageWrapper extends React.PureComponent<Props> {
  render() {
    const {
      competition,
      appSettings,
      organizationProfile,
      params: { zvaz, sutaz },
    } = this.props;

    if (!competition) {
      return null;
    }

    const title = `${competition.name} (${competition.season?.name})`;

    const orgLogoSrc = organizationProfile
      ? getPpoLogoSrc(
          organizationProfile as Required<typeof organizationProfile>,
        )
      : '';

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${appSettings.baseUri}${getCompetitionBaseUrl({
              zvaz,
              sutaz,
            })}`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content={`${competition.name}: ${__(
              'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale',
            )}`}
          />
          <meta
            property="og:description"
            content={`${competition.name}: ${__(
              'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale',
            )}`}
          />
          {!!orgLogoSrc && <meta property="og:image" content={orgLogoSrc} />}
        </Helmet>
        {this.props.children}
      </>
    );
  }
}

export default compose(connect(mapStateToProps))(CompetitionPageWrapper);
