import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20.8,19.1c0,0.4-0.3,0.8-0.8,0.8h-5.5v1.9c0,0.4-0.3,0.8-0.8,0.8S13,22.2,13,21.8v-5.3c0-0.4,0.3-0.8,0.8-0.8
	s0.8,0.3,0.8,0.8v1.9H20C20.4,18.4,20.8,18.7,20.8,19.1z M10.2,18.4H4c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h6.2
	c0.4,0,0.8-0.3,0.8-0.8S10.6,18.4,10.2,18.4z M20,11.2h-8c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h8c0.4,0,0.8-0.3,0.8-0.8
	C20.8,11.6,20.4,11.2,20,11.2z M4,12.8h3.7v1.9c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V9.3c0-0.4-0.3-0.8-0.8-0.8S7.7,8.9,7.7,9.3
	v1.9H4c-0.4,0-0.8,0.3-0.8,0.8C3.2,12.4,3.6,12.8,4,12.8z M4,5.6h8c0.4,0,0.8-0.3,0.8-0.8S12.4,4.1,12,4.1H4c-0.4,0-0.8,0.3-0.8,0.8
	S3.6,5.6,4,5.6z M15.6,8.3c0.4,0,0.8-0.3,0.8-0.8V5.6H20c0.4,0,0.8-0.3,0.8-0.8S20.4,4.1,20,4.1h-3.7V2.2c0-0.4-0.3-0.8-0.8-0.8
	s-0.8,0.3-0.8,0.8v5.3C14.8,8,15.1,8.3,15.6,8.3z"
    />
  </svg>
);
