import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    viewBox="0 0 106.521 14"
  >
    <desc>Futbalnet</desc>
    <g transform="translate(-0.5 -0.4)">
      <path
        d="M33.857,1.109V8.92c0,3.246-2.181,4.007-5.478,5.529C25.487,12.978,22.9,12.167,22.9,8.92V1.109c0-.457.2-.609.609-.609H33.3C33.6.5,33.857.652,33.857,1.109Z"
        transform="translate(-11.038 -0.049)"
        fill="#35a8e0"
      />
      <g transform="translate(0.5 0.4)">
        <path
          d="M10.645,1.009V2.733c0,.355-.1.609-.609.609H3.746V5.777H9.123c.457,0,.609.2.609.609V8.009c0,.355-.1.609-.609.609H3.746v4.87c0,.558-.1.609-.609.609H1.109c-.457,0-.609-.2-.609-.609V1.009C.5.654.6.4,1.109.4h8.928A.537.537,0,0,1,10.645,1.009Z"
          transform="translate(-0.5 -0.4)"
          fill="#00599d"
        />
        <path
          d="M56.955,1.009V2.733c0,.355-.1.609-.609.609h-3.2V13.487c0,.457-.2.609-.609.609H50.513c-.355,0-.609-.1-.609-.609V3.342h-3.2c-.457,0-.609-.2-.609-.609V1.009c0-.355.1-.609.609-.609h9.638A.537.537,0,0,1,56.955,1.009Z"
          transform="translate(-22.97 -0.4)"
          fill="#00599d"
        />
        <g transform="translate(35 0)">
          <g>
            <path
              d="M79.239,6.994a2.927,2.927,0,0,0,1.522-2.841C80.761,2.226,79.29.4,75.942.4H70.109a.537.537,0,0,0-.609.609V13.487c0,.355.1.609.609.609H76.2c2.891,0,4.92-1.37,4.92-4.007A3.214,3.214,0,0,0,79.239,6.994Zm-3.348-3.7c1.065,0,1.725.2,1.725,1.167,0,.812-.507,1.268-1.725,1.268H72.746V3.291Zm.355,7.812h-3.5V8.465h3.348c1.014,0,1.725.3,1.725,1.268C77.87,10.7,77.261,11.1,76.246,11.1Z"
              transform="translate(-69.5 -0.4)"
              fill="#00599d"
            />
          </g>
        </g>
        <path
          d="M105.776,14.045h-2.181c-.457,0-.609-.1-.812-.609l-.558-1.623H97.153l-.558,1.623c-.152.507-.355.609-.812.609H93.6c-.457,0-.457-.355-.355-.558L97.863,1.059A.82.82,0,0,1,98.725.4H100.7c.457,0,.659.2.862.659l4.565,12.428C106.232,13.741,106.232,14.045,105.776,14.045Zm-4.514-5.174L99.689,4.306,98.117,8.871Z"
          transform="translate(-46.175 -0.4)"
          fill="#00599d"
        />
        <path
          d="M130.141,11.762v1.725c0,.355-.1.609-.609.609h-8.623c-.457,0-.609-.2-.609-.609V1.009c0-.457.2-.609.609-.609h2.08c.355,0,.609.1.609.609V11.154h5.986C130.039,11.154,130.141,11.357,130.141,11.762Z"
          transform="translate(-59.532 -0.4)"
          fill="#00599d"
        />
        <path
          d="M152.758,1.309V13.736c0,.355-.1.609-.609.609h-1.877c-.507,0-.659-.3-.913-.71l-4.413-7.1v7.2a.537.537,0,0,1-.609.609h-2.029c-.355,0-.609-.1-.609-.609V1.309c0-.355.1-.609.609-.609h1.928c.507,0,.659.3.913.71l4.413,7.1v-7.2c0-.457.2-.609.609-.609h1.978A.537.537,0,0,1,152.758,1.309Z"
          transform="translate(-70.077 -0.548)"
          fill="#00599d"
        />
        <path
          d="M178,12.062v1.725c0,.355-.1.609-.609.609h-9.283c-.457,0-.609-.2-.609-.609V1.309c0-.355.1-.609.609-.609h8.928c.457,0,.609.2.609.609V3.033c0,.355-.1.609-.609.609H170.8V5.925h5.58a.537.537,0,0,1,.609.609V8.157c0,.355-.1.609-.609.609H170.8V11.5h6.594C177.9,11.454,178,11.707,178,12.062Z"
          transform="translate(-82.79 -0.548)"
          fill="#00599d"
        />
        <path
          d="M199.955,1.309V3.033c0,.355-.1.609-.609.609h-3.2V13.787c0,.457-.2.609-.609.609h-2.029c-.355,0-.609-.1-.609-.609V3.642h-3.2c-.456,0-.609-.2-.609-.609V1.309c0-.355.1-.609.609-.609h9.638C199.854.7,199.955.954,199.955,1.309Z"
          transform="translate(-93.433 -0.548)"
          fill="#00599d"
        />
      </g>
    </g>
  </svg>
);
