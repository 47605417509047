import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import { ReduxConnectProps, RootState } from '../../../configureStore';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { RouteProps } from '../../../library/App';
import { ICompetition, IMatch, ITeam } from '../../../library/Competitions';
import { ITheme } from '../../../theme/theme';
import {
  currentCompetitionPartSelector,
  currentCompetitionSelector,
} from '../../Competition/selectors';
import Standings from '../../Competition/Tables/standings';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import { currentMatchSelector } from '../selectors';

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state) as IMatch,
  competitionPart: currentCompetitionPartSelector(state),
  competition: currentCompetitionSelector(state),
});

type OwnProps = {};

type Props = RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  ReturnType<typeof mapStateToProps> & { theme: ITheme } & OwnProps &
  ReduxConnectProps;

const C: React.FC<Props> = ({
  match,
  competitionPart,
  competition,
  params: { zvaz },
}) => {
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <Standings
            highligtedTeamIds={(match.teams || []).map((t: ITeam) => t._id)}
            currentCompetitionPart={competitionPart!}
            zvaz={zvaz}
            currentCompetition={competition as ICompetition}
          />
          <br />
          <br />
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      <FutbalnetMatchBreadcrumbs />
    </>
  );
};

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(C) as unknown as React.FC<OwnProps>;
