import config from '../config';
import { ICompetition } from './../library/Competitions';
import sortTeams from './sortTeams';

export default (competitions: ICompetition[]) => {
  const defaultSeasonCompetitionParts = competitions
    .filter((c) => c.season?.name === config.ACTIVE_SEASON_NAME)
    .reduce(
      (acc, c) => [
        ...acc,
        ...(c.parts || []).map((p) => ({
          ...p,
          competitionId: c.__issfId || c._id,
        })),
      ],
      [],
    );

  const defaultSeasonUniqTeamIds: string[] = [];
  const defaultSeasonTeams = defaultSeasonCompetitionParts
    .reduce(
      (
        acc: Array<{
          _id: string;
          __issfId?: number;
          competitionId: number | string;
          appSpace: string;
          name: string;
          gender: string;
        }>,
        p,
      ) => {
        const flattedPartTeams = (p.teams || []).map((t) => ({
          ...t,
          gender: p.rules?.gender,
          category: p.rules?.category,
          competitionId: p.competitionId,
        }));
        flattedPartTeams.forEach((t) => {
          if (!defaultSeasonUniqTeamIds.includes(t._id)) {
            defaultSeasonUniqTeamIds.push(t._id);
            acc.push(t);
          }
        });
        return acc;
      },
      [],
    )
    .sort(sortTeams);

  const defaultTeamId =
    defaultSeasonTeams.length &&
    (defaultSeasonTeams[0].__issfId || defaultSeasonTeams[0]._id);

  if (defaultSeasonTeams.length) {
    return {
      competitionId: defaultSeasonTeams[0].competitionId,
      teamId: defaultTeamId,
    };
  }
  return null;
};
