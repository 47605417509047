import copyToClipboard from 'clipboard-copy';
import debounce from 'lodash.debounce';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import __ from '../../../utilities/__';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';
import { CircleShape } from '../Common';
import { ShareButtonShape } from '../library';

const SecondaryCircleShape = styled(CircleShape)`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border: ${rem(1)} solid rgba(51, 51, 51, 0.16);
  cursor: pointer;
`;

interface IOwnProps {
  url: string;
  shape: ShareButtonShape;
}

type IProps = IOwnProps;

const NativeShare: React.FC<IProps> = ({ url, shape }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  const closeTooltipDelayed = React.useMemo(() => {
    return debounce(() => setTooltipIsOpen(false), 4000);
  }, []);

  if (shape === 'circle') {
    return (
      <Tooltip
        content={__('Odkaz na článok bol skopírovaný do schránky')}
        isOpen={tooltipIsOpen}
        direction="right"
      >
        <SecondaryCircleShape
          color="#fff"
          as="button"
          onClick={() => {
            copyToClipboard(url);
            setTooltipIsOpen(true);
            closeTooltipDelayed();
          }}
          title={__('Kopírovať odkaz na článok')}
        >
          <Icon name="link" size={24} color="#000" />
        </SecondaryCircleShape>
      </Tooltip>
    );
  }

  return null;
};

export default NativeShare;
