import React from 'react';
import isBrowser from '../../utilities/isBrowser';

type IOwnProps = {
  to: string;
};

type IProps = IOwnProps;

const Redirect: React.FC<IProps> = ({ to }) => {
  if (isBrowser()) {
    window.location.replace(to);
  }
  return null;
};

export default Redirect;
