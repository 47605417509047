import { DropDownItemProps } from '../types/DropDown';
import { Login } from './Login/Login';
import { rem } from 'polished';
import CorporateHeaderSubscriptionButton from './CorporateHeaderSubscriptionButton';
import LoggedInUserDropdown from './LoggedInUserDropdown';
import React from 'react';
import SmeLogoLink from './SmeLogoLink';
import styled from 'styled-components';
import MaxWidthBox from '../../components/MaxWidthBox';
import Loader from '../../components/Loading';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  width: 100%;
  height: ${rem(40 + 1)};
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
`;

const StyledMaxWidthBox = styled(MaxWidthBox)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const RightCenteredWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCorporateHeaderSubscriptionButton = styled(
  CorporateHeaderSubscriptionButton
)`
  margin-right: ${rem(16)};
`;

type OwnProps =  {
  className?: string;
  /**
   * URL pre kupu predplatneho
   */
  subscriptionUrl: string;
  /**
   * Text odkliku pre kupu predplatneho.
   */
  subscriptionText: string;
  /**
   * URL, pre prihlasenie.
   */
  loginUrl: string;
  /**
   * Text ktory sa zobrazi v tlacidle pre prihlasenie.
   */
  loginText: string;
  /**
   * V praxi cakame na custom event az po ktorom mozme spustit
   * proces prihlasenia uzivatela.
   */
  isInitialized?: boolean;
  /**
   * Priznak procesu nacitavania/prihlasovania uzivatela
   */
  isUserLoading?: boolean;
  /**
   * Ak je user prihlaseny, ...
   */
  username?: string;
  /**
   *
   */
  isSubscribed?: boolean;
  /**
   *
   */
  loggedInMenuItems: DropDownItemProps[];
};

export const CorporateHeader: React.FC<OwnProps> = ({
  className,
  subscriptionUrl,
  subscriptionText,
  loginUrl,
  loginText,
  username,
  isInitialized = true,
  isUserLoading,
  isSubscribed,
  loggedInMenuItems,
}) => {
  const [canBeRendered, setCanBeRendered] = React.useState(false);

  // Tento useEffect zabezpeci, ze sa elementy pre rihlasenie a kupi predplatneho
  // nebudu renderovat na SSR nakolko to je zbytocne (az kontraprodukctivne).
  React.useEffect(() => {
    setCanBeRendered(true);
  }, []);

  return (
    <Wrapper className={className}>
      <StyledMaxWidthBox>
        <SmeLogoLink />
        <RightCenteredWrapper>
          {canBeRendered && isInitialized ? (
            isUserLoading ? (
              <Loader size="m" />
            ) : username ? (
              <>
                {!isSubscribed && (
                  <StyledCorporateHeaderSubscriptionButton
                    url={subscriptionUrl}
                    text={subscriptionText}
                  />
                )}
                <LoggedInUserDropdown
                  username={username}
                  items={loggedInMenuItems}
                />
              </>
            ) : (
              <>
                <StyledCorporateHeaderSubscriptionButton
                  url={subscriptionUrl}
                  text={subscriptionText}
                />
                <Login url={loginUrl} text={loginText} />
              </>
            )
          ) : (
            <Loader size="m" />
          )}
        </RightCenteredWrapper>
      </StyledMaxWidthBox>
    </Wrapper>
  );
};
