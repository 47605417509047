/**
 * True/False podla toho, ci zvaz obsahuje mladeznicke sutaze.
 *
 */
import { getProp } from 'sportnet-utilities';
import { UnionItem } from '../library/Union';

const hasUCompetitions = (union: UnionItem) =>
  union &&
  (union.competitions || []).find(
    (i) =>
      i.parts &&
      i.parts.length &&
      getProp(i, ['parts', 0, 'rules', 'category']) !== 'ADULTS',
  );

export default hasUCompetitions;
