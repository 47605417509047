import * as React from 'react';
import { futbalnetArticlesSelector } from '../../Article/selectors';
import { RootState } from '../../../configureStore';
import { sectionsEntitiesSelector } from '../../Section/selectors';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import config from '../../../config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import InfiniteArticleListLoader from '../../../containers/InfiniteArticleList/InfiniteArticleListLoader';
import ContentNotFound from '../../../components/ContentNotFound';
import __ from '../../../utilities/__';
import { PositionedArticlesList, PositionedNextPrevPaginator } from './UnionArticlesArchive';
import UrlContext, { UrlContextDefaultValue } from '../../../contexts/UrlContext';

const mapStateToProps = (state: RootState) => ({
  articles: futbalnetArticlesSelector(state),
  sectionsById: sectionsEntitiesSelector(state),
  sectionArticlesNextOffset: getListNextOffset(
    config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET,
  )(state),
  isLoadingUnionArticles:
    isCommiting(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET)(state) || false,
});

interface OwnProps {
  page: number;
}

type IProps = OwnProps & ReturnType<typeof mapStateToProps>;

const FutbalnetArticlesArchive: React.FC<IProps> = ({
  articles,
  sectionsById,
  sectionArticlesNextOffset,
  isLoadingUnionArticles,
  page,
}) => {
  return (
    <UrlContext.Provider value={UrlContextDefaultValue}>
      {articles && articles.length > 0 ? (
        <>
          <PositionedArticlesList
            articles={articles}
            sectionsById={sectionsById}
          />
          <PositionedNextPrevPaginator
            isPageLast={!sectionArticlesNextOffset}
            loading={isLoadingUnionArticles}
            page={page}
          />
        </>
      ) : isLoadingUnionArticles ? (
        <InfiniteArticleListLoader />
      ) : (
        <ContentNotFound title={__('Nenašli sa žiadne články')} />
      )}
    </UrlContext.Provider>
  );
};

export default compose(connect(mapStateToProps))(FutbalnetArticlesArchive);
