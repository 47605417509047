import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 32 32"
  >
    <path
      fill={color}
      d="M14.8,28c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.6-0.6-0.6-1l1-7.7H6c-0.4,0-0.7-0.2-0.9-0.6c-0.2-0.4-0.1-0.8,0.1-1.1l11-13.2
C16.5,4,17,3.9,17.4,4.1c0.4,0.2,0.6,0.6,0.6,1l-1,7.7h8.8c0.4,0,0.7,0.2,0.9,0.6c0.2,0.4,0.1,0.8-0.1,1.1l-11,13.2
C15.4,27.9,15.1,28,14.8,28z M8.1,17.2h7.8c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.2,0.8l-0.7,5.4l7.4-8.9h-7.8
c-0.3,0-0.6-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.2-0.8l0.7-5.4L8.1,17.2z"
    />
  </svg>
);
