import React from 'react';
import { useAsyncData } from 'ssr-service';
import config from '../../config';
import DispatchContext from '../../contexts/DispatchContext';
import { initializeOrSetListParams } from '../../pages/App/actions';
import {
  loadArticlesList,
  loadMostPopularArticlesList,
} from '../../pages/Article/actions';

export type ITopArticlesSidebarTab =
  | 'top|4'
  | 'top|24'
  | 'top|168'
  | 'newest'
  | 'premium'
  | 'event';

function useTopArticlesSidebar(): [
  ITopArticlesSidebarTab,
  (tab: ITopArticlesSidebarTab) => void,
] {
  const dispatch = React.useContext(DispatchContext);

  const [activeTab, setActiveTab] =
    React.useState<ITopArticlesSidebarTab>('top|4');

  useAsyncData(async () => {
    const [type, subtype] = activeTab.split('|');
    if (type !== 'top') {
      return;
    }
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_TOP_ARTICLES,
        params: {
          offset: 0,
          limit: config.LIST_TOP_ARTICLES_LIMIT,
          hours: Number(subtype),
        },
      }),
    );
    await dispatch(loadMostPopularArticlesList(config.LIST_TOP_ARTICLES));
  }, [dispatch, activeTab]);

  useAsyncData(async () => {
    if (activeTab !== 'newest') {
      return;
    }
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_NEWEST_ARTICLES,
        params: {
          offset: 0,
          limit: config.LIST_NEWEST_ARTICLES_LIMIT,
        },
      }),
    );
    await dispatch(loadArticlesList(config.LIST_NEWEST_ARTICLES));
  }, [dispatch, activeTab]);

  // NOTE: we ignore premium / event articles because they are used only on homepage and are already loaded

  return [activeTab, setActiveTab];
}

export default useTopArticlesSidebar;
