import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 32 32"
  >
    <path
      fill={color}
      d="M25.1,12.9c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0-0.1,0h-2.5c-0.1,0-0.2,0-0.2,0.1l-0.8,5.3c0,0.1,0,0.1,0.1,0.1
	h2.2c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0.1l0,1.4c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0-0.1,0h-2.5c-0.1,0-0.2,0-0.2,0.1L20,26.7
	c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0L18,26.9c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0.8-5.6c0-0.1,0-0.1-0.1-0.1h-5.3
	c-0.1,0-0.2,0-0.2,0.1l-0.9,5.7c0,0.2-0.1,0.3-0.3,0.3c0,0,0,0,0,0L10,26.9c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3l0.8-5.6
	c0-0.1,0-0.1-0.1-0.1H8.3c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0-0.1l0-1.4c0-0.2,0.1-0.3,0.3-0.3c0,0,0,0,0.1,0h2.4c0.1,0,0.2,0,0.2-0.1
	l0.8-5.3c0-0.1,0-0.1-0.1-0.1H9.6c-0.2,0-0.3-0.1-0.3-0.3c0,0,0,0,0-0.1v-1.4c0-0.2,0.1-0.3,0.3-0.3c0,0,0,0,0.1,0H12
	c0.1,0,0.2,0,0.2-0.1l0.9-5.7c0-0.2,0.1-0.3,0.3-0.3c0,0,0,0,0,0l1.7,0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3L14.6,11
	c0,0.1,0,0.1,0.1,0.1h5.3c0.1,0,0.2,0,0.2-0.1L21,5.3C21,5.1,21.1,5,21.3,5c0,0,0,0,0,0L23,5.1c0.1,0,0.2,0,0.2,0.1
	c0.1,0.1,0.1,0.2,0.1,0.3L22.5,11c0,0.1,0,0.1,0.1,0.1h2.2c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0,0,0.1L25.1,12.9z M18.8,18.8
	c0.1,0,0.2,0,0.2-0.1l0.8-5.3c0-0.1,0-0.1-0.1-0.1h-5.3c-0.1,0-0.2,0-0.2,0.1l-0.8,5.3c0,0.1,0,0.1,0.1,0.1H18.8z"
    />
  </svg>
);
