import useQuery, { PageParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManager from '../../../components/Ads/AdManager';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import { initializeOrSetListParams } from '../../App/actions';
import { loadArticlesList } from '../../Article/actions';
import {
  futbalnetArticlesSelector,
} from '../../Article/selectors';
import {
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../../Competition/selectors';
import FutbalnetTabber from '../../../components/FutbalnetTabber';
import UnionArticlesArchive from './UnionArticlesArchive';
import FutbalnetArticlesArchive from './FutbalnetArticlesArchive';

const mapStateToProps = (state: RootState) => ({
  currentAppSpace: getCurrentCompetitionAppSpaceSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  futbalnetArticles: futbalnetArticlesSelector(state),
});

const mapDispatchToProps = {
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadArticlesList,
};

type IOwnProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps<{ zvaz: string }>;

const QUERY_HOC_CONFIG = {
  parameters: {
    page: PageParam(),
  },
};

const Articles: React.FC<IProps> = ({
  params: { zvaz },
  location: { search, pathname, hash },
  router,
  loadArticlesList,
  initializeOrSetListParams,
  currentAppSpace,
  organizationProfile,
}) => {
  const { query } = useQuery(
    search,
    (serializedQuery) => router.push(`${pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG,
  );

  // archiv clankov zvazu
  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_UNION_ARTICLES_ARCHIVE,
      params: {
        page: query.page,
        limit: config.LIST_UNION_ARTICLES_ARCHIVE_LIMIT,
        appSpace: currentAppSpace,
      },
    });
    await loadArticlesList(config.LIST_UNION_ARTICLES_ARCHIVE);
  }, [initializeOrSetListParams, loadArticlesList, currentAppSpace, query]);

  // archiv clankov z futbalnetu
  useAsyncData(async () => {
    if (!organizationProfile) {
      return;
    }
    await initializeOrSetListParams({
      listName: config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET,
      params: {
        offset: query.page,
        limit: config.LIST_UNION_ARTICLES_ARCHIVE_LIMIT,
        smarttags: [
          `Zväz:${organizationProfile.shortName || organizationProfile.name}`,
        ],
      },
    });
    await loadArticlesList(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET);
  }, [
    initializeOrSetListParams,
    loadArticlesList,
    currentAppSpace,
    query,
    organizationProfile,
  ]);

  const breadcrumbUnionName =
    organizationProfile?.shortName || organizationProfile?.name || '';

  const getActiveTab = () => {
    const tabId = hash ? hash.substring(1) : '';
    return tabId || 'clanky-zvazu';
  };

  const activeTab = getActiveTab();

  return (
    <>
      <AdManager site="futbalnet_zvaz" siteId={`${zvaz}-articles`} />
      <RempIntegration destroy />
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <FutbalnetTabber
            active={activeTab}
            tabs={[
              {
                label: 'Články zväzu',
                value: 'clanky-zvazu',
                to: `${getUnionBaseUrl({ zvaz })}clanky/`,
              },
              {
                label: 'Články z Futbalnetu',
                value: 'clanky-z-futbalnetu',
                to: `${getUnionBaseUrl({ zvaz })}clanky/#clanky-z-futbalnetu`,
              },
            ]}
          />
          {activeTab === 'clanky-z-futbalnetu' ? (
            <FutbalnetArticlesArchive page={query.page} />
          ) : (
            <UnionArticlesArchive page={query.page} />
          )}
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      {breadcrumbUnionName && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: breadcrumbUnionName,
              url: getUnionBaseUrl({ zvaz }),
            },
            {
              label: 'Archív článkov',
              url: `${getUnionBaseUrl({ zvaz })}clanky/`,
            },
          ]}
        />
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Articles) as any as React.FC<IOwnProps>;
