import { __ } from '@sportnet/ui/lib/utilities';
import format from 'date-fns/format';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import TopAd from '../../../components/Ads/TopAd';
import Icon from '../../../components/Icon';
import {
  PaddedSidebarLayout,
  SidebarLayoutContent,
} from '../../../components/Layout/SidebarLayout';
import Segment from '../../../components/Segment';
import SegmentHeader from '../../../components/Segment/SegmentHeader';
import Spacer from '../../../components/Spacer';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import { RootState } from '../../../configureStore';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import { MemberType, MEMBER_TYPE_LABELS } from '../../../library/Competitions';
import styled, { withTheme } from '../../../theme/styled-components';
import { ITheme } from '../../../theme/theme';
import formatDate from '../../../utilities/formatDate';
import getPlayerStatus from '../../../utilities/getPlayerStatus';
import { renderClub } from '../common';
import { currentMemberSelector } from '../selectors';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import getMemberDetailUrl from '../../../utilities/getMemberDetailUrl';
import { formatUserName } from 'sportnet-utilities';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const H2 = styled.h2`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  line-height: ${rem(24)};
  margin: 0;
`;

const H3 = styled.h3`
  font-size: ${rem(16)};
  font-weight: 600;
  text-transform: uppercase;
  line-height: ${rem(24)};
  margin-top: ${rem(0)};
  margin-bottom: ${rem(24)};
`;

const StatusIcon = styled(Icon)`
  margin-right: ${rem(12)};
`;

const SegmentContent = styled.div`
  padding-top: ${rem(32)};
`;

const mapStateToProps = (state: RootState) => ({
  activeMemberProfile: currentMemberSelector(state),
});

const mapDispatchToProps = {};

interface IOwnProps {
  isActive: boolean;
}

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ id: string }> &
  typeof mapDispatchToProps & { theme: ITheme };

const MemberProfile: React.FC<Props> = ({
  activeMemberProfile,
  params: { id },
  location: { search },
  theme,
  isActive,
}) => {
  const renderDate = (d: string | undefined) => {
    return d ? formatDate(new Date(d), 'dd.MM.yyyy') : '';
  };

  const renderTrainerProfile = () => {
    if (!activeMemberProfile) {
      return null;
    }
    return (
      <SegmentContent>
        <H3>
          {__('Profil trénera')} (
          {isMemberActive(MemberType.TRAINER) ? __('Aktívny') : __('Neaktívny')}
          / {renderDate(activeMemberProfile?.trainerProfile?.registrationDate)}{' '}
          - )
        </H3>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('Typ licencie')}</Th>
              <Th>{__('Číslo licencie')}</Th>
              <Th>{__('Platnosť od')}</Th>
              <Th>{__('Platnosť do')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(activeMemberProfile.trainerLicenses || []).map((license) => (
              <Tr key={license.number}>
                <Td>{license.type}</Td>
                <Td>{license.number}</Td>
                <Td>{format(new Date(license.validity.from), 'dd.MM.yyyy')}</Td>
                <Td>{format(new Date(license.validity.to), 'dd.MM.yyyy')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </SegmentContent>
    );
  };

  const renderRefereeProfile = () => {
    if (!activeMemberProfile) {
      return null;
    }
    return (
      <SegmentContent>
        <H3>
          {__('Profil rozhodcu')} (
          {isMemberActive(MemberType.REFEREE) ? __('Aktívny') : __('Neaktívny')}
          / {renderDate(activeMemberProfile?.refereeProfile?.registrationDate)}{' '}
          - )
        </H3>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('Typ licencie')}</Th>
              <Th>{__('Číslo licencie')}</Th>
              <Th>{__('Platnosť od')}</Th>
              <Th>{__('Platnosť do')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(activeMemberProfile.refereeLicenses || []).map((license) => (
              <Tr key={license.number}>
                <Td>{license.type}</Td>
                <Td>{license.number}</Td>
                <Td>{format(new Date(license.validity.from), 'dd.MM.yyyy')}</Td>
                <Td>{format(new Date(license.validity.to), 'dd.MM.yyyy')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </SegmentContent>
    );
  };

  const renderDelegateMProfile = () => {
    if (!activeMemberProfile) {
      return null;
    }
    return (
      <SegmentContent>
        <H3>
          {__('Profil delegáta stretnuia')} (
          {isMemberActive(MemberType.DELEGATE_M)
            ? __('Aktívny')
            : __('Neaktívny')}
          /{' '}
          {renderDate(activeMemberProfile?.delegateMProfile?.registrationDate)}{' '}
          - )
        </H3>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('Typ licencie')}</Th>
              <Th>{__('Číslo licencie')}</Th>
              <Th>{__('Platnosť od')}</Th>
              <Th>{__('Platnosť do')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(activeMemberProfile.delegateMLicenses || []).map((license) => (
              <Tr key={license.number}>
                <Td>{license.type}</Td>
                <Td>{license.number}</Td>
                <Td>{format(new Date(license.validity.from), 'dd.MM.yyyy')}</Td>
                <Td>{format(new Date(license.validity.to), 'dd.MM.yyyy')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </SegmentContent>
    );
  };

  const renderDelegateOProfile = () => {
    if (!activeMemberProfile) {
      return null;
    }
    return (
      <SegmentContent>
        <H3>
          {__('Profil pozorovateľa rozhodcov')} (
          {isMemberActive(MemberType.DELEGATE_O)
            ? __('Aktívny')
            : __('Neaktívny')}
          /{' '}
          {renderDate(activeMemberProfile?.delegateOProfile?.registrationDate)}{' '}
          - )
        </H3>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('Typ licencie')}</Th>
              <Th>{__('Číslo licencie')}</Th>
              <Th>{__('Platnosť od')}</Th>
              <Th>{__('Platnosť do')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(activeMemberProfile.delegateOLicenses || []).map((license) => (
              <Tr key={license.number}>
                <Td>{license.type}</Td>
                <Td>{license.number}</Td>
                <Td>{format(new Date(license.validity.from), 'dd.MM.yyyy')}</Td>
                <Td>{format(new Date(license.validity.to), 'dd.MM.yyyy')}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </SegmentContent>
    );
  };

  const renderPlayerProfile = () => (
    <SegmentContent>
      <H3>
        {__('Profil hráča')} (
        {getPlayerStatus(activeMemberProfile?.playerProfile?.playerStatus) ||
          '-'}{' '}
        /{' '}
        {renderDate(activeMemberProfile?.playerProfile?.profileStatus?.regDate)}{' '}
        - )
      </H3>
      <Table>
        <Thead>
          <Tr>
            <Th>{__('Status')}</Th>
            <Th>{__('Prvotný klub')}</Th>
            <Th>{__('Materský klub')}</Th>
            <Th>{__('Hosťujúci klub')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              {getPlayerStatus(
                activeMemberProfile?.playerProfile?.playerStatus,
              )}
            </Td>
            <Td>
              {renderClub(activeMemberProfile?.playerProfile?.clubOrigin)}
            </Td>
            <Td>
              {renderClub(activeMemberProfile?.playerProfile?.clubActual)}
            </Td>
            <Td>
              {renderClub(activeMemberProfile?.playerProfile?.clubHosted)}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </SegmentContent>
  );

  const isMemberActive = (type: MemberType) => {
    switch (type) {
      case MemberType.PLAYER:
        return activeMemberProfile?.playerProfile?.playerStatus === 'INACTIVE'
          ? false
          : true;
      case MemberType.TRAINER: {
        return activeMemberProfile?.trainerProfile?.profileStatus.status ===
          'AKTIVNY'
          ? true
          : false;
      }
      case MemberType.REFEREE: {
        return activeMemberProfile?.refereeProfile?.profileStatus.status ===
          'AKTIVNY'
          ? true
          : false;
      }
      case MemberType.DELEGATE_M: {
        return activeMemberProfile?.delegateMProfile?.profileStatus.status ===
          'AKTIVNY'
          ? true
          : false;
      }
      case MemberType.DELEGATE_O: {
        return activeMemberProfile?.delegateOProfile?.profileStatus.status ===
          'AKTIVNY'
          ? true
          : false;
      }
      default:
        return false;
    }
  };

  return (
    <>
      {isActive && <TopAd />}
      {isActive && <BottomPaddedBigAd name="big_1" />}
      <RempIntegration destroy />
      <Spacer size={4}>
        <div />
        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <Spacer size={4}>
              {(activeMemberProfile?.profiles || []).map((profile) => {
                switch (profile.type) {
                  case 'PLAYER':
                    return (
                      <Segment
                        key={`${id}-${profile.type}`}
                        padding={24}
                        header={
                          <SegmentHeader
                            size="xl"
                            subtitle=""
                            collapsible
                            isCollapsed={false}
                          >
                            <HeaderWrapper>
                              <StatusIcon
                                name="circle"
                                size="xs"
                                color={
                                  isMemberActive(MemberType.PLAYER)
                                    ? theme.memberDetail.active
                                    : theme.memberDetail.inactive
                                }
                              />
                              <H2>{__(MEMBER_TYPE_LABELS.PLAYER.label)}</H2>
                            </HeaderWrapper>
                          </SegmentHeader>
                        }
                      >
                        {renderPlayerProfile()}
                      </Segment>
                    );

                  case 'TRAINER':
                    return (
                      <Segment
                        key={`${id}-${profile.type}`}
                        padding={24}
                        header={
                          <SegmentHeader
                            size="xl"
                            subtitle=""
                            collapsible
                            isCollapsed={false}
                          >
                            <HeaderWrapper>
                              <StatusIcon
                                name="circle"
                                size="xs"
                                color={
                                  isMemberActive(MemberType.TRAINER)
                                    ? theme.memberDetail.active
                                    : theme.memberDetail.inactive
                                }
                              />
                              <H2>{MEMBER_TYPE_LABELS.TRAINER.label}</H2>
                            </HeaderWrapper>
                          </SegmentHeader>
                        }
                      >
                        {renderTrainerProfile()}
                      </Segment>
                    );

                  case 'REFEREE':
                    return (
                      <Segment
                        key={`${id}-${profile.type}`}
                        padding={24}
                        header={
                          <SegmentHeader
                            size="xl"
                            subtitle=""
                            collapsible
                            isCollapsed={false}
                          >
                            <HeaderWrapper>
                              <StatusIcon
                                name="circle"
                                size="xs"
                                color={
                                  isMemberActive(MemberType.REFEREE)
                                    ? theme.memberDetail.active
                                    : theme.memberDetail.inactive
                                }
                              />
                              <H2>{MEMBER_TYPE_LABELS.REFEREE.label}</H2>
                            </HeaderWrapper>
                          </SegmentHeader>
                        }
                      >
                        {renderRefereeProfile()}
                      </Segment>
                    );
                  case 'DELEGATE_M':
                    return (
                      <Segment
                        key={`${id}-${profile.type}`}
                        padding={24}
                        header={
                          <SegmentHeader
                            size="xl"
                            subtitle=""
                            collapsible
                            isCollapsed={false}
                          >
                            <HeaderWrapper>
                              <StatusIcon
                                name="circle"
                                size="xs"
                                color={
                                  isMemberActive(MemberType.DELEGATE_M)
                                    ? theme.memberDetail.active
                                    : theme.memberDetail.inactive
                                }
                              />
                              <H2>{MEMBER_TYPE_LABELS.DELEGATE_M.label}</H2>
                            </HeaderWrapper>
                          </SegmentHeader>
                        }
                      >
                        {renderDelegateMProfile()}
                      </Segment>
                    );
                  case 'DELEGATE_O':
                    return (
                      <Segment
                        key={`${id}-${profile.type}`}
                        padding={24}
                        header={
                          <SegmentHeader
                            size="xl"
                            subtitle=""
                            collapsible
                            isCollapsed={false}
                          >
                            <HeaderWrapper>
                              <StatusIcon
                                name="circle"
                                size="xs"
                                color={
                                  isMemberActive(MemberType.DELEGATE_O)
                                    ? theme.memberDetail.active
                                    : theme.memberDetail.inactive
                                }
                              />
                              <H2>{MEMBER_TYPE_LABELS.DELEGATE_O.label}</H2>
                            </HeaderWrapper>
                          </SegmentHeader>
                        }
                      >
                        {renderDelegateOProfile()}
                      </Segment>
                    );
                  default:
                    return null;
                }
              })}
            </Spacer>
          </SidebarLayoutContent>
        </PaddedSidebarLayout>
      </Spacer>
      {isActive && activeMemberProfile && (
        <>
          <MegaboardAd />
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: 'Členovia',
                url: '/futbalnet/clenovia/',
              },
              {
                label: formatUserName(activeMemberProfile),
                url: getMemberDetailUrl(activeMemberProfile.id),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(MemberProfile) as unknown as React.FC<IOwnProps>;
