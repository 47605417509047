import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import startOfDay from 'date-fns/startOfDay';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import styled, { css, withTheme } from 'styled-components';
import { RootState } from '../../../configureStore';
import useIsResponsiveLayout from '../../../hooks/useIsResponsiveLayout';
import { ISportSectorPhase, ITeam } from '../../../library/Competitions';
import getMembersNameWithLink from '../../../utilities/getMembersNameWithLink';
import __ from '../../../utilities/__';
import {
  allSportSectorEventsSelector,
  allSportSectorPhasesSelector,
  allSportSectorSettingsSelector,
} from '../../App/selectors';
import { currentMatchSelector } from '../selectors';

export const Icon = (
  props: Theme & {
    eventType: string;
    subType?: string;
    title: string;
    size?: number;
  },
) => {
  switch (props.eventType) {
    case 'phase':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.inactiveColor}
            d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM12.5 8H11v6l4.75 2.85.75-1.23-4-2.37V8zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
          />
        </svg>
      );
    case 'goal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill={
              props.subType && props.subType === 'dropped'
                ? props.theme.color.danger
                : '#333'
            }
            d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm0 2.5c.7 0 1.4.1 2.2.2L16 4.2l-2.2-1.5c.7-.2 1.5-.2 2.2-.2zm-5.2 1l4.4 3.2.8.6.7-.5 4.4-3.2c2 .8 3.7 2.1 5.1 3.7l-1.7 5.3-.3.8.7.5 4.5 3.3c-.2 2.2-.8 4.2-1.9 6h-6.4l-.3.8-1.7 5.3c-1 .2-2 .3-3.1.3s-2.2-.1-3.2-.4l-1.7-5.3-.3-.9H4.4c-1.1-1.8-1.7-3.8-1.9-6L7 13.8l.7-.5-.3-.8-1.7-5.3c1.4-1.6 3.1-2.9 5.1-3.7zM16 8.7l-.7.5-5.6 4.1-.7.6.3.8 2.2 6.6.3.8H20.5l.3-.8 2.2-6.6.3-.8-.7-.5L17 9.3l-1-.6zm12 1c.7 1.3 1.1 2.7 1.3 4.2l-2.2-1.6.9-2.6zM4 9.8l.8 2.5-2.2 1.6c.3-1.4.7-2.8 1.4-4.1zm12 2l4.2 3-1.6 4.9h-5.2l-1.6-4.9 4.2-3zm6.9 13.7h2.8c-1 1.1-2.3 2-3.6 2.7l.8-2.7zm-16.6 0H9l.8 2.6c-1.2-.7-2.4-1.6-3.5-2.6z"
          />
        </svg>
      );
    case 'failed_goal':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.color.warning}
            d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"
          />
        </svg>
      );
    case 'yellow_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#ffc600"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'red_card':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          id="card"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M385.2 512H126.8c-27.5 0-50-22.5-50-50V50c0-27.5 22.5-50 50-50h258.4c27.5 0 50 22.5 50 50v412c0 27.5-22.5 50-50 50z"
          />
        </svg>
      );
    case 'second_yellow_card':
      return (
        <svg
          version="1.1"
          viewBox="0 0 512 512"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <g>
            <path
              fill="#FF0C00"
              d="M367.6,512c37.6,0,67.8-30.5,67.8-68.2V68.4c0-16.3-5.7-31.5-15.2-43.3L92.3,487.5   c12.5,15.1,31.1,24.5,52.2,24.5H367.6z"
            />
            <path
              fill="#FFC700"
              d="M76.7,68.4v375.4c0,16.6,5.9,31.8,15.6,43.7L420.1,25.2C407.7,9.9,388.9,0,367.6,0H144.4   C106.8,0,76.7,30.8,76.7,68.4z"
            />
          </g>
        </svg>
      );
    case 'substitution':
      return (
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width={props.size || 20}
          height={props.size || 20}
        >
          <title>{props.title}</title>
          <path
            fill="#FF0C00"
            d="M303.1,133.3L151,379.2L0,132.8h303.4L303.1,133.3z"
          />
          <path
            fill="#63C509"
            d="M208.9,378.7l152.1-246l151,246.5H208.6L208.9,378.7z"
          />
        </svg>
      );
    case 'exclusion':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill={props.theme.color.danger}
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"
          />
        </svg>
      );
    case 'timeout':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size || 20}
          height={props.size || 20}
          viewBox="0 0 24 24"
        >
          <title>{props.title}</title>
          <path
            fill="#333"
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
          <path fill="#333" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
        </svg>
      );
    default:
      return null;
  }
};

const Wrapper = styled.div`
  text-align: center;
  color: #333;
`;

const Events = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: '';
    height: calc(100% - ${rem(70)});
    width: ${rem(1)};
    background: ${({ theme }) => theme.separatorColor};
    position: absolute;
    top: calc(0% + ${rem(35)});
    left: calc(50% - ${rem(1)});
  }
`;
const EventItem = styled.div<{
  isPhase?: boolean;
  isMobile?: boolean;
  align?: string;
  last?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ isPhase, isMobile }) =>
    !!isPhase &&
    !!isMobile &&
    css`
      ${Spacer} {
        display: none;
      }
      ${Minute} {
        display: none;
      }
      ${EventItemInfo} {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      ${EventIcon} {
        flex-direction: column;
        background: white;
        z-index: 1;
        padding: ${rem(8)} 0;
      }
      ${Player} {
        text-align: center !important;
      }
    `}
  ${({ last }) => {
    if (last) {
      return css`
        ${Minute} {
          &:after {
            height: 0;
          }
        }
      `;
    }
    return css``;
  }}
  ${({ align }) => {
    if (align === 'left') {
      return css`
        ${mb('s')} {
          flex-direction: row;
          justify-content: flex-end;
        }
      `;
    }
    return css``;
  }}
`;
const EventItemPart = styled.div`
  width: 100%;
  padding: ${rem(4)} ${rem(8)};
  ${mb('s')} {
    padding: ${rem(4)} 0;
    width: calc(50% - ${rem(32.5)});
  }
`;
const Spacer = styled(EventItemPart)`
  display: block;
`;
const Minute = styled.div<{ phase?: boolean }>`
  text-align: center;
  padding: ${rem(8)};
  border: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  border-width: 0;
  background: white;
  font-weight: bold;
  color: #333;
  border-radius: 100%;
  height: ${rem(40)};
  width: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  ${mb('s')} {
    margin: ${rem(8)} ${rem(16)};
  }
  font-size: ${rem(12)};
  z-index: 1;
  ${({ phase }) => {
    if (phase) {
      return css`
        border-width: 0;
        visibility: hidden;
      `;
    }
    return css``;
  }}
`;
const MinuteMask = styled.div<{ align: string }>`
  /* position: absolute;
  left: ${({ align }) => (align === 'left' ? rem(-1) : rem(17.5))};
  width: ${rem(35 / 2)};
  height: 120%;
  background: white;
  transform: translate(-${rem(1)}, 0); */
`;
const EventItemInfo = styled(EventItemPart)``;
const EventIcon = styled.div<{ align: string }>`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background: white;
  padding: ${rem(8)} 0;
  ${mb('s')} {
    z-index: 0;
    background: none;
    padding: 0;
  }
  ${({ align }) => {
    if (align === 'left') {
      return css`
        justify-content: flex-start;
        flex-direction: row;
        ${mb('s')} {
          flex-direction: row-reverse;
        }
        ${Player} {
          text-align: left;
          ${mb('s')} {
            text-align: right;
          }
        }
      `;
    } else if (align === 'right') {
      return css`
        flex-direction: row-reverse;
        ${mb('s')} {
          flex-direction: row;
        }
        ${Player} {
          text-align: right;
          ${mb('s')} {
            text-align: left;
          }
        }
      `;
    }
    return css``;
  }}
`;
const Player = styled.div`
  padding: 0 ${rem(15)};
  text-align: left;
`;
const PlayerName = styled.div<{ phase?: boolean }>`
  font-size: ${rem(12)};
  font-weight: ${({ phase }) => (phase ? 'normal' : 'bold')};
`;
const EventType = styled.div<{ mobile?: boolean }>`
  font-size: ${rem(11)};
  color: ${({ mobile }) => (mobile ? 'inherit' : '#bbb')};
  ${({ mobile }) => `${mb('s')} {
    display: ${mobile ? 'none' : 'block'};
  }`}
`;
const IconWrapper = styled.div`
  min-width: ${rem(20)};
  display: flex;
  align-items: center;
`;

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state),
  allEventTypes: allSportSectorEventsSelector(state),
  allPhases: allSportSectorPhasesSelector(state),
  allSettings: allSportSectorSettingsSelector(state),
});

type IOwnProps = {
  goalsOnly?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> & Theme & IOwnProps;

const MatchTimeLine: React.FC<Props> = (props) => {
  const [live, setLive] = React.useState<{
    timer: any;
    liveState: {
      ongoing: boolean;
      seconds: number;
      phase: string;
      toggleDate: string;
    };
    protocol: {
      events: any[];
    };
  }>({
    timer: {},
    liveState: {
      ongoing: false,
      seconds: 0,
      phase: '',
      toggleDate: '',
    },
    protocol: {
      events: [],
    },
  });
  const liveRef = React.useRef<any>();
  const socket = React.useRef<SocketIOClient.Socket | null>(null);
  liveRef.current = live;

  React.useEffect(() => {
    if (
      props.match &&
      !props.match.closed &&
      !socket.current &&
      isEqual(
        startOfDay(new Date()),
        startOfDay(new Date(props.match.startDate)),
      )
    ) {
      setLive({
        liveState: props.match.liveState || live.liveState,
        timer: props.match.timer || live.timer,
        protocol: {
          ...(props.match.protocol || {
            events: [],
          }),
          events: getProp(props.match.protocol, ['events'], []),
        },
      });
    } else if (props.match) {
      setLive({
        ...liveRef.current,
        protocol: {
          events: props.match.protocol ? props.match.protocol.events || [] : [],
        },
        timer: props.match.timer,
      });
    }
  }, [props.match, socket, live.liveState, live.timer]);

  // React.useEffect(() => {
  //   return function cleanup() {
  //     if (socket.current) {
  //       socket.current.disconnect();
  //     }
  //   };
  // }, []);

  const mobileLayout = useIsResponsiveLayout(600);

  const getSettings = (sportSector: string) => {
    return {
      ...(props.allSettings[sportSector].competitionPartSettings as any).value,
      ...(props.match?.settings || {}),
    };
  };

  const returnPlayerIssfId = (
    teamId: string,
    playerSportnetId: string,
  ): string | number | null => {
    // hladam hraca v nominaciach podla teamu a nasledne podla sportnetId
    const team = (match?.nominations || []).find(
      (nomination: any) => nomination.teamId === teamId,
    );
    if (team) {
      const player = (team.athletes || []).find(
        (athlete: any) => athlete.sportnetUser._id === playerSportnetId,
      );
      return player?.additionalData?.__issfId || null;
    }
    return null;
  };

  const renderEventDetail = (
    sportSector: string,
    event: any,
    currentTeam: ITeam,
    align: string,
  ) => {
    const phase = props.allEventTypes[sportSector][event.type];
    // const team = props.teams.find(t => t._id === event.team);
    return (
      <EventItemInfo>
        <EventIcon align={align}>
          <IconWrapper>
            <Icon
              title={phase ? phase.label : ''}
              eventType={event.eventType}
              subType={event.type}
              theme={props.theme}
            />
          </IconWrapper>
          <Player>
            {!!event.player && (
              <div>
                <PlayerName>
                  {getMembersNameWithLink(
                    event.player.name,
                    returnPlayerIssfId(event.team, event.player._id) || event.player._id,
                  )}
                </PlayerName>
              </div>
            )}
            {!!event.crewMember && (
              <div>
                <PlayerName>
                  {getMembersNameWithLink(event.crewMember.name, event.crewMember._id)}
                </PlayerName>
              </div>
            )}
            {event.eventType === 'timeout' && (
              <PlayerName>{__('Timeout')}</PlayerName>
            )}
            {!!event.replacement && (
              <EventType>
                Striedajúci hráč:&nbsp;
                {getMembersNameWithLink(
                  event.replacement.name,
                  returnPlayerIssfId(event.team, event.replacement._id) || event.replacement._id,
                )}
              </EventType>
            )}
            {!!event.assist && (
              <EventType>
                Asistencia:{' '}
                {getMembersNameWithLink(
                  event.assist.name,
                  returnPlayerIssfId(event.team, event.assist._id) || event.assist._id,
                )}
              </EventType>
            )}
            {!!event.type &&
              getProp(props.allEventTypes, [sportSector, event.type, 'label']) ? (
              <EventType>
                {props.allEventTypes[sportSector][event.type]!.label}
              </EventType>
            ) : null}
            {!!event.reason && <EventType>{event.reason}</EventType>}
          </Player>
        </EventIcon>
      </EventItemInfo>
    );
  };

  const getTeam = (teamSide: string) =>
    getProp(props.match, ['teams'], []).find(
      (i) => getProp(i, ['additionalProperties', 'homeaway'], '') === teamSide,
    );

  const getTeamsIds = () => {
    const homeTeam = getTeam('home');
    const homeTeamId = homeTeam
      ? homeTeam._id
      : getProp(props.match, ['teams', 0, '_id']);
    const awayTeam = getTeam('away');
    const awayTeamId = awayTeam
      ? awayTeam._id
      : getProp(props.match, ['teams', 1, '_id']);

    return { homeTeamId, awayTeamId };
  };

  const getEventTime = (sportSector: string, event: any) => {
    const sportSettings = getSettings(sportSector);

    let minutes = `${Math.ceil(event.eventTime / 60)}`;
    if (sportSettings.displaySeconds) {
      minutes = `${Math.floor(event.eventTime / 60)}`;
      const seconds = String(event.eventTime % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
    if (sportSettings.overlapTime) {
      const phase = (props.match?.settings?.phases as any)[event.phase];
      if (phase.endTime && phase.endTime < event.eventTime) {
        const remaind = Math.ceil((event.eventTime - phase.endTime) / 60);
        return <div>{`${phase.endTime / 60}+${remaind}'`}</div>;
      }
      return `${minutes}'`;
    }
    return `${minutes}'`;
  };

  const renderEventItem = (
    sportSector: string,
    allTeams: ITeam[],
    event: any,
  ) => {
    const currentTeam = allTeams.find((i) => i._id === event.team)!;
    const { homeTeamId } = getTeamsIds();

    return (
      <EventItem align={event.team === homeTeamId ? 'left' : 'right'}>
        {event.team === homeTeamId ? (
          renderEventDetail(sportSector, event, currentTeam, 'left')
        ) : (
          <Spacer />
        )}
        <Minute>
          <MinuteMask align={event.team === homeTeamId ? 'left' : 'right'} />
          <div style={{ position: 'relative' }}>
            {getEventTime(sportSector, event)}
          </div>
        </Minute>
        {event.team === homeTeamId ? (
          <Spacer />
        ) : (
          renderEventDetail(sportSector, event, currentTeam, 'right')
        )}
      </EventItem>
    );
  };

  const renderMobileEventItem = (
    sportSector: string,
    allTeams: ITeam[],
    event: any,
  ) => {
    const currentTeam = allTeams.find((i) => i._id === event.team)!;
    const { homeTeamId } = getTeamsIds();

    return (
      <EventItem>
        {event.team === homeTeamId && (
          <>
            {' '}
            <Minute>
              <MinuteMask
                align={event.team === homeTeamId ? 'left' : 'right'}
              />
              <div style={{ position: 'relative' }}>
                {getEventTime(sportSector, event)}
              </div>
            </Minute>
            {renderEventDetail(sportSector, event, currentTeam, 'left')}
          </>
        )}
        {event.team !== homeTeamId && (
          <>
            {renderEventDetail(sportSector, event, currentTeam, 'right')}
            <Minute>
              <MinuteMask
                align={event.team === homeTeamId ? 'left' : 'right'}
              />
              <div style={{ position: 'relative' }}>
                {getEventTime(sportSector, event)}
              </div>
            </Minute>
          </>
        )}
      </EventItem>
    );
  };

  const { match, allPhases } = props;
  const { protocol, timer } = live;

  if (!match || !match.rules) {
    return null;
  }
  const matchSportSector = match.rules.sport_sector;
  const phases: any = allPhases[matchSportSector];
  const eventTypes = props.allEventTypes[matchSportSector];

  if (!phases || !eventTypes) {
    return null;
  }

  const { teams } = match;
  const playablePhasesNames = Object.keys(phases).filter(
    (phaseName) => phases[phaseName].playPhase,
  );
  let playablePhases: ISportSectorPhase[] = [];
  playablePhasesNames.forEach((phaseName) => {
    playablePhases.push(phases[phaseName]);
  });

  playablePhases = playablePhases.sort((a, b) => {
    if (a.startTime < b.startTime) {
      return 1;
    } else if (a.startTime > b.startTime) {
      return -1;
    }
    return 0;
  });

  const PHASES = playablePhases.reduce((acc: any, phase: any) => {
    if (timer && timer[phase._id]) {
      return [
        ...acc,
        {
          ...phase,
          startDate: getProp(timer[phase._id], ['start', 'date']),
          endDate: getProp(timer[phase._id] || {}, ['end', 'date']),
          startTime:
            getProp(timer[phase._id], ['start', 'seconds']) ||
            getProp(props.allPhases, [
              matchSportSector,
              'phases',
              phase._id,
              'startTime',
            ]),
          endTime:
            getProp(timer[phase._id] || {}, ['end', 'seconds']) ||
            getProp(props.allPhases, [
              matchSportSector,
              'phases',
              phase._id,
              'endTime',
            ]),
        },
      ];
    }
    return [
      ...acc,
      {
        ...phase,
      },
    ];
  }, []);

  let events = ((protocol || { events: [] }).events || [])
    .filter((event) => event.eventTime && event.type !== 'goal_shootout')
    .map((event) => {
      const timeParts = event.eventTime.split(':');
      const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
      return {
        ...event,
        eventTime: seconds,
      };
    });

  let hasGoals = false;
  if (props.goalsOnly) {
    events = events.filter((e) => e.eventType === 'goal');
    if (events.length) {
      hasGoals = true;
    }
  }

  const settings = getSettings(matchSportSector);

  if (settings.reverseTime) {
    events = events.sort((a, b) => {
      if (a.eventTime > b.eventTime) {
        return -1;
      } else if (a.eventTime < b.eventTime) {
        return 1;
      }
      return 0;
    });
  }

  // ak zapas prebieha, timeline renderujem najnovsich eventov. ak je zapas ukonceny,
  // timeline renderujem od najstarsich eventov.
  const renderFromOldest = match.closed;

  if (renderFromOldest) {
    events = events.sort((a, b) => (a.eventTime > b.eventTime ? 1 : -1));
  } else {
    events = events.sort((a, b) => (a.eventTime > b.eventTime ? -1 : 1));
  }

  // roztriedi eventy do faz zapasu - objekt, ktoreho kluc je "faza" a hodnota pole eventov
  const eventsByPhase = events.reduce(
    (acc, event) => {
      return {
        ...acc,
        [event.phase]: [...(acc[event.phase] || []), event],
      };
    },
    PHASES.reduce((acc: any, phase: any) => {
      if (phase && phase.startDate) {
        return { ...acc, [phase._id]: [] };
      }
      return acc;
    }, {}),
  );

  // pole faz zapasu, ktore mozem dodatocne sortovat podla casu danej fazy.
  // vzniklo pre poziadavku renderovat timeline od zaciatku zapasu, alebo od zadu,
  // podla toho ci zapas prebieha, alebo je ukonceny
  // TODO: Zjednodusit tak, ze sa zjednoti z `reduce` v predchadzajucom kroku, kde sa
  //       eventy triedia podla faz zapasu.
  const sortedEventsByPhases = Object.keys(eventsByPhase).reduce((acc, e) => {
    const phase = PHASES.find((p: any) => p._id === e);
    if (phase && e !== 'shootout') {
      acc.push({
        phaseId: e,
        startTime: phase.startTime || 0,
        events: eventsByPhase[e],
        phaseData: phase,
      });
    }
    return acc;
  }, [] as Array<{ phaseId: string; startTime: number; phaseData: any; events: Array<typeof eventsByPhase> }>);

  if (renderFromOldest) {
    sortedEventsByPhases.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
  } else {
    sortedEventsByPhases.sort((a, b) => (a.startTime > b.startTime ? -1 : 1));
  }

  const renderGoalsOnly = () => {
    return (
      <>
        {!hasGoals ? (
          <>
            {match.closed ? (
              <>{__('V zápase nepadli žiadne góly.')}</>
            ) : (
              <>{__('V zápase zatiaľ nepadli žiadne góly.')}</>
            )}
          </>
        ) : (
          <>
            {sortedEventsByPhases.length > 0 &&
              sortedEventsByPhases.map((p) => (
                <React.Fragment key={p.phaseId}>
                  {p.events.map((e, idx) => (
                    <div key={`event_${idx}`}>
                      <>
                        {mobileLayout
                          ? renderMobileEventItem(
                              matchSportSector,
                              teams || [],
                              e,
                            )
                          : renderEventItem(matchSportSector, teams || [], e)}
                      </>
                    </div>
                  ))}
                </React.Fragment>
              ))}
          </>
        )}
      </>
    );
  };

  const renderFullTimeline = () => (
    <>
      {sortedEventsByPhases.map((p) => (
        <React.Fragment key={p.phaseId}>
          <EventItem align="right" isPhase isMobile={mobileLayout}>
            <Spacer />
            <Minute phase={!!p.phaseId}>
              {Math.floor(p.phaseData.startTime / 60)}'
            </Minute>
            <EventItemInfo>
              <EventIcon align="right">
                <IconWrapper>
                  <Icon
                    title={p.phaseData.label}
                    eventType="phase"
                    theme={props.theme}
                  />
                </IconWrapper>
                <Player>
                  <PlayerName phase={!!p.phaseId}>{`${
                    renderFromOldest ? 'Začiatok' : 'Koniec'
                  } - ${p.phaseData.label}`}</PlayerName>
                  {renderFromOldest ? (
                    <>
                      {p.phaseData.startDate && (
                        <EventType>
                          {format(new Date(p.phaseData.startDate), 'HH:mm')}
                        </EventType>
                      )}
                    </>
                  ) : (
                    <>
                      {p.phaseData.endDate && (
                        <EventType>
                          {format(new Date(p.phaseData.endDate), 'HH:mm')}
                        </EventType>
                      )}
                    </>
                  )}
                </Player>
              </EventIcon>
            </EventItemInfo>
          </EventItem>
          {p.events.map((event: any, idx: number) => (
            <div key={`event_${idx}`}>
              {mobileLayout
                ? renderMobileEventItem(matchSportSector, teams || [], event)
                : renderEventItem(matchSportSector, teams || [], event)}
            </div>
          ))}
          <EventItem align="right" isPhase isMobile={mobileLayout}>
            <Spacer />
            <Minute phase={!!p.phaseId}>
              {Math.floor((p.phaseData.startTime + p.phaseData.playTime) / 60)}'
            </Minute>
            <EventItemInfo>
              <EventIcon align="right">
                <IconWrapper>
                  <Icon
                    title={p.phaseData.label}
                    eventType="phase"
                    theme={props.theme}
                  />
                </IconWrapper>
                <Player>
                  <PlayerName phase={!!p.phaseId}>{`${
                    renderFromOldest ? 'Koniec' : 'Začiatok'
                  } - ${p.phaseData.label}`}</PlayerName>
                  {renderFromOldest ? (
                    <>
                      {p.phaseData.endDate && (
                        <EventType>
                          {format(new Date(p.phaseData.endDate), 'HH:mm')}
                        </EventType>
                      )}
                    </>
                  ) : (
                    <>
                      {p.phaseData.startDate && (
                        <EventType>
                          {format(new Date(p.phaseData.startDate), 'HH:mm')}
                        </EventType>
                      )}
                    </>
                  )}
                </Player>
              </EventIcon>
            </EventItemInfo>
          </EventItem>
        </React.Fragment>
      ))}
    </>
  );

  return (
    <Wrapper>
      <Events>
        {props.goalsOnly ? (
          <>{renderGoalsOnly()}</>
        ) : (
          <>{renderFullTimeline()}</>
        )}
      </Events>
    </Wrapper>
  );
};

export default compose(withTheme, connect(mapStateToProps))(MatchTimeLine);
