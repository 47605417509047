import { css, keyframes } from 'styled-components';

export const SKELETON_CLASS_NAME = 'skeleton';

export const skeletonLoadingAnimation = keyframes`
0% {
  background-color: rgba(204, 204, 204, 0.24);
}
100% {
  background-color: rgba(204, 204, 204, 0.56);
}
`;

export const skeletonLoadingStyles = css`
  .${SKELETON_CLASS_NAME} {
    animation: ${skeletonLoadingAnimation} 1s linear infinite alternate;
  }
`;

export default skeletonLoadingStyles;
