import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import config from '../../config';
import { RootState } from '../../configureStore';
import UrlContext from '../../contexts/UrlContext';
import { Breadcrumb, ISection } from '../../library/App';
import { sectionPathSelector } from '../../pages/App/selectors';
import { currentSectionIdSelector } from '../../pages/Section/selectors';

export const ROOT_BREADCRUMB: Breadcrumb = {
  label: 'Domov',
  url: config.HOMEPAGE_URL,
};

const EXCLUDE_ROOT = [
  'ms-v-hokeji',
  'euro',
  'tour-de-france'
]

type IOwnProps = {
  className?: string;
  additionalCrumbs?: Breadcrumb[];
};

const mapStateToProps = (state: RootState) => ({
  path: sectionPathSelector(state, currentSectionIdSelector(state)),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const SectionsBreadcrumbs: React.FC<IProps> = ({
  path,
  className,
  additionalCrumbs,
}) => {
  const { getSectionUrl } = React.useContext(UrlContext);

  const crumbs: Breadcrumb[] = React.useMemo(() => {
    if (!path) {
      return [ROOT_BREADCRUMB];
    }
    const finalBreadCrumbs = path.map(
      (section) =>
        ({
          label: section.name!,
          url: getSectionUrl(section as ISection),
        } as Breadcrumb),
    );
    if (path && path[0].uniqid === 'vysledky') {
      finalBreadCrumbs.unshift(ROOT_BREADCRUMB);
    }
    if (
      path && path[0].uniqid && 
      EXCLUDE_ROOT.includes(path[0].uniqid)
    ) {
      finalBreadCrumbs[0] = ROOT_BREADCRUMB;
    }
    return [...finalBreadCrumbs, ...(additionalCrumbs ?? [])];
  }, [path, getSectionUrl, additionalCrumbs]);

  return <Breadcrumbs className={className} crumbs={crumbs} />;
};

export default connect(mapStateToProps)(SectionsBreadcrumbs);
