import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import Icon from '../../components/Icon';
import styled from '../../theme/styled-components';

const FutbalnetLink = styled(Link)`
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
  padding: ${rem(8)} 0;
  ${mb('s')} {
    svg {
      height: ${rem(16)};
    }
  }
`;

type IOwnProps = {
  size?: number;
};

type IProps = IOwnProps;

const FutbalnetLogo: React.FC<IProps> = ({ size = 10 }) => {
  return (
    <FutbalnetLink to="/futbalnet/">
      <Icon name="futbalnet" size={size} />
    </FutbalnetLink>
  );
};

export default FutbalnetLogo;
