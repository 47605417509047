import React from 'react';
import FutbalnetBlockTitle from '../../components/FutbalnetBlockTitle';
import { ContextMarginBottomStyles } from '../../components/Layout';
import Widgets from '../../components/Widgets';
import { IWidgets } from '../../library/App';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  ${ContextMarginBottomStyles}
`;

type IOwnProps = {
  content: IWidgets;
  title?: string;
};

type IProps = IOwnProps;

const PartnersBlock: React.FC<IProps> = ({ content, title }) => {
  if (content.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {title && <FutbalnetBlockTitle>{title}</FutbalnetBlockTitle>}
      <Widgets items={content} />
    </Wrapper>
  );
};

export default PartnersBlock;
