import { SPORT_SECTOR_PHASES } from "../../codelists/sport_sector_phases";


/**
 * Presunut nacitavanie z react query ... bude to optimalnejsie ak to drzat v zdrojaku
 * @param phaseId
 * @param sport
 * @returns
 */
const getPhaseLabel = (phaseId: string, sport: string) => {
  const phasesForSport = SPORT_SECTOR_PHASES.find((item) => item._id === sport);
  if (phasesForSport && Array.isArray(phasesForSport.items)) {
    const phase = phasesForSport.items.find((p) => p._id === phaseId);
    return phase?.label || phaseId;
  }
  return phaseId;
};

export default getPhaseLabel;