import styled from '../../../theme/styled-components';
import {
  ContextMarginBigTopStyles,
  ContextMarginBottomStylesReset,
} from '../../Layout';
import BodyAd from '../BodyAd';

export default styled(BodyAd)`
  ${ContextMarginBottomStylesReset}
  ${ContextMarginBigTopStyles}
`;
