import { Article_Author } from '../../api/CmsApi';
import { getAuthorUrlNameFromDisplayName } from '../getAuthorUrl';


/**
 * Vrati hodnotu pre adverts ktora sa pushuje `window.smeAdvertTargeting`
 *
 * Refs.: https://gitlab.bart.sk/sportnet/csm/sportnet.sme.sk/-/issues/536
 *
 * Zmena v sposobe odosielania:
 * https://gitlab.bart.sk/sportnet/csm/sportnet.sme.sk/-/issues/732
 *
 * 22.2.2024 pridana agentura reuters na zaklade videohovoru s M. Cholevom
 *
 * @param authors
 */
export const getAuthorTag = (authors?: Article_Author[]) => {
  if (Array.isArray(authors)) {
    if (authors.length > 1) {
      return 'viac.autorov';
    } else if (authors.length === 1) {
      const author = authors[0];
      if (author.sportnetId) {
        return getAuthorUrlNameFromDisplayName(author.name);
      } else {
        const name = getAuthorUrlNameFromDisplayName(author.name);
        switch (name) {
          case 'oh.my.hockey':
          case 'sportnet':
          case 'tasr':
          case 'reuters':
          case 'sita': {
            return name;
          }
          default: {
            return 'other';
          }
        }
      }
    }
  }
  return '';
};



