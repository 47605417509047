import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M18,4v2v4h-2V6H8v4H6V6V4h2h8H18z M21,19v-6c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6
    C20.6,20,21,19.6,21,19z M15,14h4v4h-4V14z M11,19v-6c0-0.6-0.4-1-1-1H4c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6
    C10.6,20,11,19.6,11,19z M5,14h4v4H5V14z"
    />
  </svg>
);
