import React from 'react';
import { MatchDetailPrintTeamNominationAthlete } from '../../../library/Print';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import { Td } from './MatchDetailPrintMatchEvent';
import styled, { css } from 'styled-components';
import __ from '../../../utilities/__';

export const NominationItemWrapper = styled.span<{
  percentWidth?: number;
  textRight?: boolean;
}>`
  display: inline-block;
  ${({ percentWidth }) =>
    typeof percentWidth !== 'undefined'
      ? css`
          width: ${percentWidth}%;
        `
      : ''};
  ${({ textRight = false }) =>
    textRight
      ? css`
          text-align: right;
        `
      : ''};
`;

interface OwnProps {
  homeTeamPersons: MatchDetailPrintTeamNominationAthlete[];
  awayTeamPersons: MatchDetailPrintTeamNominationAthlete[];
}

const MatchDetailPrintNominations: React.FC<OwnProps> = ({
  homeTeamPersons,
  awayTeamPersons,
}) => {
  const numberOfRows = Math.max(homeTeamPersons.length, awayTeamPersons.length);
  return numberOfRows > 0 ? (
    <>
      {Array(numberOfRows)
        .fill(null)
        .map((_, idx) => (
          <Tr key={`match-detail-nominations-${idx}`}>
            <Td colSpan={4}>
              {homeTeamPersons.length === 0 && idx === 0 ? (
                <NominationItemWrapper percentWidth={50}>
                  {__('Nominácia nebola uzatvorená.')}
                </NominationItemWrapper>
              ) : (
                <>
                  <NominationItemWrapper percentWidth={5}>
                    {homeTeamPersons[idx]?.nr ?? ''}
                  </NominationItemWrapper>
                  <NominationItemWrapper percentWidth={45}>
                    {homeTeamPersons[idx]?.name ?? ''}
                  </NominationItemWrapper>
                </>
              )}
              {awayTeamPersons.length === 0 && idx === 0 ? (
                <NominationItemWrapper percentWidth={50} textRight={true}>
                  {__('Nominácia nebola uzatvorená.')}
                </NominationItemWrapper>
              ) : (
                <>
                  <NominationItemWrapper percentWidth={45} textRight={true}>
                    {awayTeamPersons[idx]?.name ?? ''}
                  </NominationItemWrapper>
                  <NominationItemWrapper percentWidth={5} textRight={true}>
                    {awayTeamPersons[idx]?.nr ?? ''}
                  </NominationItemWrapper>
                </>
              )}
            </Td>
          </Tr>
        ))}
    </>
  ) : (
    <Tr>
      <Td colSpan={4}>
        <NominationItemWrapper percentWidth={50}>
          {__('Nominácia nebola uzatvorená.')}
        </NominationItemWrapper>
        <NominationItemWrapper percentWidth={50} textRight={true}>
          {__('Nominácia nebola uzatvorená.')}
        </NominationItemWrapper>
      </Td>
    </Tr>
  );
};

export default MatchDetailPrintNominations;
