import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <style type="text/css">
      .st0X{'{'}fill:#63C509;{'}'}
      .st1X{'{'}fill:url(#SVGID_2_);{'}'}
    </style>
    <path className="st0X" d="M6,2l6,8H0L6,2z" />
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="6"
      y1="9.5"
      x2="6"
      y2="2.8333"
    >
      <stop offset="0" style={{ stopColor: '#63C509' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0.4 }} />
    </linearGradient>
    <path className="st1X" d="M11,9.5H1l5-6.7L11,9.5z" />
    <path className="st0X" d="M6,3.7L2,9h8L6,3.7 M6,2l6,8H0L6,2z" />
  </svg>
);
