export interface NikeMatchParticipantProps {
  participantId: number;
  participantName: string;
  participantType: 'team';
}

export interface NikeSelectionProps {
  odds: number;
  /**
   * 49 - "1"
   * 88 - "X"
   * 50 - "2"
   * 52 - "1X"
   * 53 - "X2"
   * 51 - "12"
   */
  code: number;
  name: string;
  winning: boolean;
  url?: string;
}

export interface NikeMatchProps {
  matchId: number;
  type: 'Result' | 'Prematch' | 'Live';
  name: string;
  date: string;
  statsUrl?: string;
  matchUrl?: string;
  participants: NikeMatchParticipantProps[];
  score?: {
    home: string;
    away: string;
  };
  selections: NikeSelectionProps[];
  /**
   * Live zapas ma k dispozicii video stream.
   */
  stream?: boolean;
  /**
   * Live zapas ma k dispozicii matchtracker (animácia zápasu) od Betradar-u.
   */
  infocenter?: boolean;
  /**
   * Perioda zapasu - hodnoty: "1. polčas", "2. polčas" a pod.
   */
  currentPeriod?: string;
  /**
   * Prebiehajuca minuta zapasu
   */
  timerMinutes?: number;
}

export interface NikeMatchesGroup {
  sportId: number;
  countryId: number;
  tournamentId: number;
  sportSlug: string;
  sportName: string;
  countryName: string;
  tournamentName: string;
  matches: NikeMatchProps[];
}

export type NikeSportnetApiResponse = NikeMatchesGroup[];

export enum NikeSport {
  TENIS = 7,
  ICE_HOCKEY = 3,
  SOCCER = 1,
  BASKETBALL = 5,
  TABLE_TENIS = 61,
  SNOOKER = 85,
  KRIKET = 124,
  SQUASH = 148,
}

export enum NikeOfferType {
  TEAM_ONE = '1',
  DRAW = 'X',
  TEAM_TWO = '2',
}
