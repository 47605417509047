import { isAfter, isBefore } from 'date-fns';
import useQuery, { IQueryHocProps, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import AdManager from '../../components/Ads/AdManager';
import Loading from '../../components/Loading';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import NotFoundError from '../../NotFoundError';
import getCleandedPathFromLocation from '../../utilities/adverts/getCleanedPathFromLocation';
import getAppSpaceFromUrl from '../../utilities/getAppSpaceFromUrl';
import isBrowser from '../../utilities/isBrowser';
import { initializeOrSetListParams } from '../App/actions';
import { isLoadingList } from '../App/selectors';
import NotFound from '../NotFound';
import {
  loadCompetitionItem,
  loadCompetitionParts,
  loadCompetitionsByCompetitionGroup,
  setCurrentAppSpace,
  setCurrentCompetitionId,
  setCurrentCompetitionPartId,
} from './actions';
import {
  competitionPartsSelector,
  competitionsByCompetititonGroupSelector,
  currentCompetitionAppSpaceSelector,
  currentCompetitionIdSelector,
  currentCompetitionIsFetchingSelector,
  currentCompetitionSelector,
} from './selectors';

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
  currentCompetitionId: currentCompetitionIdSelector(state),
  competitions: competitionsByCompetititonGroupSelector(state),
  isFetchingCompetition: currentCompetitionIsFetchingSelector(state) !== false,
  isLoadingCompetitions: isLoadingList(
    state,
    config.LIST_COMPETITIONS_BY_GROUP_ID,
  ),
  currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
  parts: competitionPartsSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  IQueryHocProps<{
    parameters: {
      competitionId: { type: QueryHocTypes.String };
      part: { type: QueryHocTypes.String };
    };
  }> & { dispatch: CustomThunkDispatch };

class CompetitionGroup extends React.PureComponent<
  Props,
  { isInitialized: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isInitialized: false,
    };

  }

  static async getInitialProps(props: Props) {
    const {
      dispatch,
      query,
      params: { zvaz, sutaz },
    } = props;

    const appSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

    if (!appSpace) {
      dispatch(setCurrentAppSpace(''));
      throw new NotFoundError(null);
    }

    dispatch(setCurrentAppSpace(appSpace));

    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_COMPETITIONS_BY_GROUP_ID,
        params: {
          competitionGroupId: sutaz,
          appSpace,
          activeCompetitionId: query.competitionId || '',
        },
      }),
    );
    await dispatch(
      loadCompetitionsByCompetitionGroup({
        appSpace,
        competitionGroupId: sutaz,
        activeCompetitionId: query.competitionId
          ? String(query.competitionId)
          : '',
      }),
    );

    await dispatch(
      loadCompetitionParts({
        listName: config.LIST_COMPETITION_PARTS,
        activeCompetitionPartId: props.query.part
          ? String(props.query.part)
          : undefined,
      }),
    );
  }

  async componentDidMount() {
    this.setState({
      isInitialized: false,
    });
   
    this.props.dispatch(
      setCurrentCompetitionId(String(this.props.params.sutaz)),
    );
    if (this.props.query.competitionId) {
      this.props.dispatch(
        setCurrentCompetitionId(String(this.props.query.competitionId)),
      );
    }
    try {
      await CompetitionGroup.getInitialProps(this.props);
    } catch (e: any) {
      //
    } finally {
      this.setState({
        isInitialized: true,
      });
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.sutaz !== prevProps.params.sutaz) {
      this.setState({ isInitialized: false });
      this.props.dispatch(
        setCurrentCompetitionId(String(this.props.params.sutaz)),
      );
      try {
        await CompetitionGroup.getInitialProps(this.props);
      } catch (e: any) {
        //
      } finally {
        this.setState({
          isInitialized: true,
        });
      }
    }
    if (
      (this.props.query.part || prevProps.query.part) &&
      this.props.query.part !== prevProps.query.part
    ) {
      if (!this.props.query.part) {
        const recentCompetitionPart =
          (this.props.parts || []).find((part) => {
            return (
              isBefore(new Date(part.dateTo || ''), new Date()) &&
              isAfter(new Date(part.dateFrom || ''), new Date())
            );
          }) || this.props.parts.length
            ? this.props.parts[0]
            : undefined;
        this.props.dispatch(
          setCurrentCompetitionPartId(
            recentCompetitionPart
              ? String(
                recentCompetitionPart.__issfId || recentCompetitionPart._id,
              )
              : '',
          ),
        );
      } else {
        this.props.dispatch(
          setCurrentCompetitionPartId(
            this.props.query.part ? String(this.props.query.part) : '',
          ),
        );
      }
    }
    if (
      this.props.query.competitionId &&
      this.props.query.competitionId !== prevProps.query.competitionId
    ) {
      this.props.dispatch(
        setCurrentCompetitionId(String(this.props.query.competitionId)),
      );
      await this.props.dispatch(loadCompetitionItem.action());
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setCurrentCompetitionId(''));
    this.props.dispatch(setCurrentCompetitionPartId(''));
    this.props.dispatch(setCurrentAppSpace(''));
  }

  render() {
    const {
      isLoadingCompetitions,
      isFetchingCompetition,
      currentCompetitionId,
      competition,
      children,
      currentCompetitionAppSpace,
      location,
    } = this.props;

    if (isBrowser() && !this.state.isInitialized) {
      return null;
    }

    if (!currentCompetitionAppSpace) {
      return <NotFound />;
    }

    if (
      !currentCompetitionId ||
      isLoadingCompetitions ||
      (isFetchingCompetition && !competition)
    ) {
      return <Loading />;
    }

    return (
      <>
        <AdManager
          site="futbalnet_sutaz"
          siteId={getCleandedPathFromLocation(location)}
        />
        {children}
      </>
    );
  }
}

export default compose(
  useQuery({
    parameters: {
      competitionId: { type: QueryHocTypes.String },
      part: { type: QueryHocTypes.String },
    },
  }),
  connect(mapStateToProps),
)(CompetitionGroup);
