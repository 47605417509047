import NotFound from '@sportnet/ui/lib/NotFound';
import { rem } from 'polished';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import config from '../../config';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import MaxWidthBox from '../MaxWidthBox';

const Wrapper = styled.div<{ minHeight?: string }>`
  display: flex;
  min-height: ${({ minHeight = '50vh' }) => minHeight};
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

type Props = {
  title: string;
  actionLabel?: string;
  onClickAction?: () => void;
  theme: ITheme;
  minHeight?: string;
};

const ContentNotFound: React.FC<Props> = ({
  title,
  actionLabel,
  onClickAction,
  theme,
  minHeight,
}) => {
  return (
    <MaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      <Wrapper minHeight={minHeight}>
        <ThemeProvider
          theme={{
            inactiveColor: '#b5b5b5',
            largeBorderRadius: rem(4),
            fontFamily: "Georgia, 'Times New Roman', Times, serif",
            color: {
              primary: theme.color.primary,
            },
            textColor: theme.app.textColor,
          }}
        >
          <NotFound
            title={title}
            actionLabel={actionLabel}
            onClickAction={onClickAction}
          />
        </ThemeProvider>
      </Wrapper>
    </MaxWidthBox>
  );
};

export default withTheme(ContentNotFound);
