import Loader from '@sportnet/ui/lib/Loader';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import CollapsibleHeader from '../../../../components/CollapsibleHeader';
import MatchPreview from '../../../../components/MatchesSlider/MatchPreview';
import { RootState } from '../../../../configureStore';
import useIsResponsiveLayout from '../../../../hooks/useIsResponsiveLayout';
import styled, { withTheme } from '../../../../theme/styled-components';
import { ITheme } from '../../../../theme/theme';
import getCompetitionBaseUrl from '../../../../utilities/getCompetitionBaseUrl';
import __ from '../../../../utilities/__';
import { loadLastRoundMatches } from '../../actions';
import { lastRoundMatchesSelector } from '../../selectors';
import { useQ } from '../../../../query';
import loadNikeEventsForFutbalnet from '../../../../query-fns/loadNikeEventsForFutbalnet';
import getThirdPartyEventBySportnetMatch from '../../../../utilities/thirdPartyResults/getThirdPartyEventBySportnetMatch';
import { ICompetition } from '../../../../library/Competitions';

const MatchesWrapper = styled.div`
  > a:nth-child(odd) > div {
    background: ${({ theme }) => theme.collapsibleList.oddRowBg};
  }
`;

const MatchItemWrapper = styled.div`
  font-size: ${rem(12)};
  padding: ${rem(8)} ${rem(16)} ${rem(8)} ${rem(16)};
`;

interface IOwnProps {
  competition: ICompetition;
  isOpened?: boolean;
  mobileLayout: boolean;
  zvaz: string;
}

const mapStateToProps = (state: RootState, { competition }: IOwnProps) => ({
  lastRoundMatches: lastRoundMatchesSelector(state, competition._id),
});

const mapDispatchToProps = {
  loadLastRoundMatches,
};

const LoaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.collapsibleList.oddRowBg};
  padding: ${rem(12)} ${rem(16)} ${rem(11)} ${rem(16)};
  font-size: ${rem(12)};
`;

const PositionedLoader = styled(Loader)`
  margin: auto;
`;

type IProps = { theme: ITheme } & IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const LastRoundMatches: React.FC<IProps> = ({
  competition,
  isOpened = false,
  loadLastRoundMatches,
  lastRoundMatches,
  theme,
  zvaz,
}) => {
  const [opened, setOpened] = React.useState<boolean>(isOpened);
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data: thirdPartyResultsData } = useQ(
    loadNikeEventsForFutbalnet,
    { segment: 'all' },
    { enabled: true },
  );

  const mobileLayout = useIsResponsiveLayout();

  useAsyncData(async () => {
    if (!opened) {
      return;
    }
    try {
      setLoading(true);
      await loadLastRoundMatches({
        competitionId: competition._id,
      });
    } catch {
      /* */
    } finally {
      setLoading(false);
    }
  }, [opened]);

  const handleChangeHeaderState = (openedState: boolean) => {
    setOpened(openedState);
  };

  const renderMatches = () => (
    <MatchesWrapper>
      {lastRoundMatches && lastRoundMatches.length > 0 ? (
        <>
          {(lastRoundMatches || []).map((i) => {
            return (
              <MatchItemWrapper key={i._id}>
                <MatchPreview
                  view={mobileLayout ? 'compact' : 'stretched'}
                  showCompetitionName={false}
                  theme={theme}
                  match={i}
                  thirdPartyEvent={getThirdPartyEventBySportnetMatch(i, thirdPartyResultsData)}
                  withThirdPartyEvent
                />
              </MatchItemWrapper>
            );
          })}
        </>
      ) : (
        <LoaderWrapper>
          {loading ? (
            <PositionedLoader />
          ) : (
            <div>{__('Nenašli sa žiadne aktuálne zápasy v tejto súťaži.')}</div>
          )}
        </LoaderWrapper>
      )}
    </MatchesWrapper>
  );

  return (
    <CollapsibleHeader
      header={{
        name: competition.name,
        url: getCompetitionBaseUrl({ zvaz, sutaz: String(competition.__issfId) }),
      }}
      isOpened={isOpened}
      collapsible
      onChange={(openedState) => {
        handleChangeHeaderState(openedState);
      }}
      isLoading={loading}
    >
      <div>{renderMatches()}</div>
    </CollapsibleHeader>
  );
};

export default compose(
  withTheme,
  connect(mapStateToProps, mapDispatchToProps),
)(LastRoundMatches);
