import { DropDownItemProps } from '../../types/DropDown';
import { rem } from 'polished';

import React from 'react';
import styled from 'styled-components';
import Link from '../../../components/Link';

const Wrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 0%;
  min-width: 100%;
  background: #fff;
  padding-top: ${rem(8)};
  z-index: 20000;
  overflow: none;
`;

const TopBarMenu = styled.ul`
  min-width: 100%;
  background: #fff;
  box-shadow: 0px 2px 8px #00000014;
  border: 1px solid ${({ theme }) => theme.color.darkBorder};
  border-radius: 0px 0px 4px 4px;
  margin: -1px;
  padding: ${rem(8)};
  list-style-type: none;
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-size: ${rem(12)};
  z-index: 20000;
`;

const TopbarMenuItem = styled.li`
  margin: 0;
  padding: ${rem(8)};
  > a {
    white-space: nowrap;
    :hover {
      text-decoration: underline;
    }
  }
`;

type OwnProps =  {
  className?: string;
  visible: boolean;
  items: DropDownItemProps[];
};

export const LoggedInUserDropdownBody: React.FC<OwnProps> = ({
  items,
  visible,
}) => (
  <Wrapper visible={visible}>
    <TopBarMenu>
      {items.map((item) => (
        <TopbarMenuItem key={`top-bar-menu-link-${item.id}`}>
          <Link to={item.href!} rel={item.linkRel} target={item.linkTarget}>
            {item.label}
          </Link>
        </TopbarMenuItem>
      ))}
    </TopBarMenu>
  </Wrapper>
);
