import { getListResults, getListResultsByParams, ListParameters } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import { IArticle } from '../../library/App';
import {
  cumulatedListResultsSelector,
  entitiesSelector,
} from '../../pages/App/selectors';

// Current article id
export const currentArticleIdSelector = (state: RootState) =>
  state.currentArticleId;

export const currentArticleSelector = createSelector(
  entitiesSelector,
  currentArticleIdSelector,
  (entities, id) =>
    id !== null && id in entities.articles ? entities.articles[id] : null,
);

export const similarArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SIMILAR_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const articleTabsSelector = (state: RootState) =>
  (getListResults(config.LIST_ARTICLE_TABS_ARTICLES)(state) ||
    []) as IArticle[];

export const topArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_TOP_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const newestArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_NEWEST_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const listArticlesByParamsSelector = createSelector(
  entitiesSelector,
  (_: RootState, listParams: { listName: string; parameters: ListParameters}) => getListResultsByParams(_, listParams),
  (entities, ids) => at(entities.articles, ids || []),
);

export const sectionArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SECTION_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const smarttagPageArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SMARTTAG_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
)

export const cumulatedListArticlesSelector = createSelector(
  entitiesSelector,
  cumulatedListResultsSelector,
  (entities, ids) => at(entities.articles, ids || []),
);

export const featuredVideoArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_FEATURED_VIDEO_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);

export const unionArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_UNION_ARTICLES_ARCHIVE),
  (entities, ids) => at(entities.articles, ids || []),
);

export const futbalnetArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_FUTBALNET_ARTICLES_BY_SPORTNET),
  (entities, ids) => at(entities.articles, ids || []),
);

export const competitionArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMPETITION_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);
