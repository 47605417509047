import React from 'react';
import { IArticle } from '../../../library/App';
import styled from '../../../theme/styled-components';
import ArticleSmall from './ArticleSmall';
import Article from './Article';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`;

type IOwnProps = {
  articles: IArticle[];
  // kompaktnejsi design sa pouziva v boxe najnovsich clankov na titulke.
  // normalny design v uradnych srpavach na futbalnetovej stranke zvazu.
  small?: boolean;
};

type IProps = IOwnProps;

const ArticlesListTimeline: React.FC<IProps> = ({
  articles,
  small = true,
}) => {
  const renderArticle = (article: IArticle, idx: number) => {
    return small ? (
      <ArticleSmall
        key={`${article._id}-${article.modified}`}
        article={article}
        isLast={idx === articles.length - 1}
      />
    ) : (
      <Article
        key={`${article._id}-${article.modified}`}
        article={article}
        isLast={idx === articles.length - 1}
      />
    );
  };

  return <List>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesListTimeline);
