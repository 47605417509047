import { rem } from 'polished';
import Icon from '../Icon';
import OddBodyCell from './OddBodyCell';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { ThirdPartyGroupedEventsEvent } from '../../library/thirdPartyResults';
import __ from '../../utilities/__';
import oddSortingFn from '../../utilities/thirdPartyResults/oddSortingFn';
import getEmptyOdds from '../../utilities/thirdPartyResults/getEmptyOdds';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const Wrapper = styled.div`
  display: flex;
  height: ${rem(24)};
  font-size: ${rem(11)};
  line-height: ${rem(14.4)};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => rem(theme.border.borderRadiusSmall)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.darkBorder};
  padding: 0 ${rem(4)};
  & a:hover {
    text-decoration: underline;
  }
  ${mb('m')} {
    padding: 0 ${rem(8)};
  }
`;

const LogoStyles = css<{ hideLogoOnMobile?: boolean }>`
  margin-top: ${rem(-2)};
  > span {
    height: 100%;
  }

  height: ${rem(11)};

  padding: 0 ${rem(8)};
  ${({ hideLogoOnMobile = false }) =>
    hideLogoOnMobile
      ? css`
          display: none;
          ${mb('m')} {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
`;

export const NikeShortLogo = styled.a<{ hideLogoOnMobile?: boolean }>`
  ${LogoStyles}
`;

const NikeShortLogoSpan = styled.span<{ hideLogoOnMobile?: boolean }>`
  ${LogoStyles}
`;

const OdssWrapper = styled.div`
  display: flex;
  margin-top: ${rem(-1)};
`;

interface OwnProps {
  className?: string;
  nikeSportId: number;
  event: ThirdPartyGroupedEventsEvent;
  /**
   * Skryje Doxxbet logo pri rozliseni 'm'. Pouziva sa pri kurzoch na
   * Home Page.
   */
  hideLogoOnMobile?: boolean;
}

export const NikeOddRates: React.FC<OwnProps> = ({
  className,
  event,
  nikeSportId,
  hideLogoOnMobile = false,
}) => {
  const theme = useTheme();
  return (
    <Wrapper className={className}>
      {event.matchUrl || event.statsUrl ? (
        <NikeShortLogo
          href={event.matchUrl || event.statsUrl || '#'}
          target="_blank"
          rel="nofollow noopener noreferrer"
          title={__('vsadiť na zápas')}
          hideLogoOnMobile={hideLogoOnMobile}
        >
          <Icon name="nike" size={11} color={(theme as any).branding.nike} />
        </NikeShortLogo>
      ) : (
        <NikeShortLogoSpan hideLogoOnMobile={hideLogoOnMobile}>
          <Icon name="nike" size={11} color={(theme as any).branding.nike} />
        </NikeShortLogoSpan>
      )}

      <OdssWrapper>
        {event.odds.length > 0
          ? event.odds.sort(oddSortingFn).map((odd, index) => {
              return (
                <OddBodyCell
                  key={`odd-${odd.id ?? index}`}
                  id={odd.id ?? index}
                  type={odd.type}
                  isResult={event.type === 'results'}
                  live={event.live}
                  nikeUrl={odd.url}
                  rate={odd.rate}
                  winning={odd.winning}
                />
              );
            })
          : getEmptyOdds(nikeSportId).map((odd, index) => {
              return (
                <OddBodyCell
                  id={index}
                  type={odd.type}
                  key={`empty-odd-${odd.type}`}
                  live={false}
                />
              );
            })}
      </OdssWrapper>
    </Wrapper>
  );
};
