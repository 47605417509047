import React, { PureComponent } from 'react';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 4px;
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

interface Props {
  data: {
    id: string;
  };
}

const VOTING_SERVER = 'https://hlasovanie.sportnet.online';

class WidgetVoting extends PureComponent<Props> {
  iframe: any;
  constructor(props: Props) {
    super(props);
    this.iframe = null;
  }

  componentDidMount() {
    if (!(window as any).votingIframeResizer) {
      (window as any).votingIframeResizer = (event: any) => {
        const iframeWrapper = document.getElementById(
          `voting_${getProp(event, [
            'data',
            'votingContainerSize',
            'votingId',
          ])}_wrapper`,
        );
        if (!VOTING_SERVER.startsWith(event.origin)) {
          return;
        }
        if (event.data.votingContainerSize) {
          if (iframeWrapper) {
            iframeWrapper.style.height = `${
              event.data.votingContainerSize.height + 0
            }px`;
          }
        }
        if (event.data.votingContainerScroll) {
          if (document !== null) {
            const el = document.getElementById(
              `voting_${getProp(event, [
                'data',
                'votingContainerSize',
                'votingId',
              ])}_wrapper`,
            );

            if (el) {
              el.scrollIntoView(true);
            }
          }
        }
      };
      (window as any).votingIframeScrolltop = (iframe: any) => {
        const iframeId = iframe.getAttribute('id');
        if (!(window as any).votingIframeScrolltopCounter[iframeId]) {
          (window as any).votingIframeScrolltopCounter[iframeId] = 1;
        } else {
          (window as any).votingIframeScrolltopCounter[iframeId] += 1;
        }
        if ((window as any).votingIframeScrolltopCounter[iframeId] > 1) {
          iframe.scrollIntoView(true);
        }
      };
      (window as any).votingIframeScrolltopCounter = {};
      window.addEventListener(
        'message',
        (window as any).votingIframeResizer,
        false,
      );
    }
  }

  render() {
    const {
      data: { id },
    } = this.props;
    return (
      <Wrapper>
        <Iframe
          id={`voting_${id}_wrapper`}
          ref={(iframe: any) => {
            this.iframe = iframe;
          }}
          src={`${VOTING_SERVER}/hlasuj/${id}`}
          frameBorder="0"
          allowFullScreen
        />
      </Wrapper>
    );
  }
}

export default WidgetVoting;
