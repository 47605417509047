import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { ContextMarginBottomStyles } from '../../Layout';
import Ad from '../Ad';
import { IAdPosition } from '../AdProvider';

const StyledAd = styled(Ad)`
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  margin: 0 auto;
  width: 100%;
  ${ContextMarginBottomStyles}
  margin-bottom: 0;
  background-color: #f1f1f1;
  ${mb('m')} {
    background-color: white;
    padding-bottom: 0;
  }
`;

type IOwnProps = {
  name: IAdPosition;
  className?: string;
};

type IProps = IOwnProps;

const BodyAd: React.FC<IProps> = ({ name, className }) => {
  return <StyledAd name={name} className={className} />;
};

export default React.memo(BodyAd);
