class RedirectError extends Error {
  identifier: string;
  error: any;
  payload: {
    location: string;
    from?: string;
    permanent: boolean;
  };
  constructor(payload: any) {
    super('REDIRECT');
    this.payload = payload;
    this.identifier = 'REDIRECT';
  }
}

export default RedirectError;
