import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import at from 'sportnet-utilities/lib/at';
import { RootState } from '../../configureStore';
import { ISection, SectionId } from '../../library/App';
import {
  detailDataSelector,
  detailTypeSelector,
  entitiesSelector,
} from '../../pages/App/selectors';
import { currentArticleSelector } from '../../pages/Article/selectors';
import getArticleMainSectionId from '../../utilities/getArticleMainSectionId';
import { IDetailInitialState } from '../App/reducer';

// Current section id - that has been set by action
const currentSetSectionIdSelector = (state: RootState) =>
  state.currentSectionId;

export const currentSectionIdSelector = createSelector(
  currentSetSectionIdSelector,
  currentArticleSelector,
  (sectionId, article) => {
    if (article && article.sectionid) {
      return getArticleMainSectionId(article);
    }
    return sectionId;
  },
);

// Section CONTENT
const currentSectionDomain = createSelector(
  currentSectionIdSelector,
  detailTypeSelector('sectionById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentSectionIsFetchingSelector = createSelector(
  currentSectionDomain,
  (domain) => !!domain.isFetching,
);

export const currentSectionErrorSelector = createSelector(
  currentSectionDomain,
  (domain) => domain.error,
);

export const currentSectionSelector = createSelector(
  entitiesSelector,
  currentSectionIdSelector,
  (entities, id) =>
    id !== null && id in entities.sections ? entities.sections[id] : null,
);

// Path
export const pathSelector = (sectionId: SectionId | null) =>
  createSelector(
    entitiesSelector,
    detailDataSelector('pathBySectionId', { id: String(sectionId) }),
    (entities, pathData) =>
      at(entities.sections, getProp(pathData, ['path'], [])),
  );

export const sectionsEntitiesSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sections,
);

const idOrUniqIdSelector = (_: RootState, idOrUniqId: string) => idOrUniqId;

export const sectionSelector = createSelector(
  sectionsEntitiesSelector,
  idOrUniqIdSelector,
  (byId, idOrUniqId) => {
    let id: string | undefined = idOrUniqId;

    if (id && !(id in byId)) {
      id = Object.keys(byId).find((sId) => byId[sId].uniqid === id);
    }

    if (!id) {
      return null;
    }

    return (byId[id] as ISection) || null;
  },
);
