import { IArticle, ISection } from '../library/App';
import { EntitiesState } from '../pages/App/reducer';
import getArticleMainSectionId from './getArticleMainSectionId';

export default (article: IArticle, sectionsById: EntitiesState['sections']) => {
  const articleSectionId = getArticleMainSectionId(article) || 0;
  const section =
    articleSectionId in sectionsById ? sectionsById[articleSectionId] : null;
  return section as ISection | null;
};
