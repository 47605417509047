/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description?: string;

  payload?: {};

  userinfo?: string;
};
export type feedId = string;
export type itemId = string;
export type feedItemId = string;
export type objectId = string;
export type Pager = {
  total?: number;

  limit?: number;

  offset?: number;

  nextOffset?: number;
};
export type Widget = {
  type: string;
};
export type Confirmation_Message = {
  message: string;
};
export type Author = {
  displayName: string;

  sportnetId?: string;
};
export type PublishedState = 'published' | 'unpublished';
export type NotificationRequestStatus = {
  ok: boolean;

  requestId?: string;

  notificationDate?: string;

  created?: string;
};
export type NotificationRequestUser = {
  sportnetId: string;

  username?: string;

  name?: string;

  surname?: string;

  email?: string;

  phone?: string;
};
export type NotificationRequestHistoryItem = {
  notificationSentAt: string;

  title: string;

  description: string;

  date: string;

  type: 'auto' | 'manual';

  user: {
    sportnetId: string;

    username?: string;

    name?: string;

    surname?: string;

    email?: string;

    phone?: string;
  };

  status: {
    ok: boolean;

    requestId?: string;

    notificationDate?: string;

    created?: string;
  };
};
export type FeedItem_Create = {
  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  extendedDescription?: Array<{
    type: string;
  }>;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';
};
export type FeedItem_Create_From_App = {
  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  extendedDescription?: Array<{
    type: string;
  }>;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';
};
export type FeedItem_Update = {
  title?: string;

  subtitle?: string;

  category?: string;

  description?: string;

  date?: string;

  url?: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  extendedDescription?: Array<{
    type: string;
  }>;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';
};
export type FeedItem_Discussion_Stats = {
  _id?: string;

  appSpace?: string;

  externalIds?: Array<{
    service?: string;

    externalId?: string;

    lastUpdate?: string;

    error?: string;
  }>;

  discussion?: {
    type?: string;

    discussionId?: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type FeedItem_Discussion = {
  type: string;

  discussionId: number;

  postCount?: number;

  active?: boolean;

  url?: string;
};
export type FeedItem = {
  _id: string;

  itemId: string;

  appId: string;

  appSpace: string;

  feedId: string;

  createdDate: string;

  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  sorter?: number;

  extendedDescription?: Array<{
    type: string;
  }>;

  extendedDescriptionExcerpt?: string;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';

  discussion?: {
    type: string;

    discussionId: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };

  ogImageBackground?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };
};
export type FeedItem_List = {
  _id: string;

  itemId: string;

  appId: string;

  appSpace: string;

  feedId: string;

  createdDate: string;

  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  sorter?: number;

  extendedDescriptionExcerpt?: string;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';

  discussion?: {
    type: string;

    discussionId: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };
};
export type FeedItemWithHighlights = {
  _id: string;

  itemId: string;

  appId: string;

  appSpace: string;

  feedId: string;

  createdDate: string;

  title: string;

  subtitle?: string;

  image?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  category?: string;

  description?: string;

  date: string;

  url: string;

  payload?: {};

  smarttags?: Array<{
    key: string;

    label?: string;

    values: Array<{
      key: string;

      label?: string;
    }>;
  }>;

  sorter?: number;

  extendedDescriptionExcerpt?: string;

  authors?: Array<{
    displayName: string;

    sportnetId?: string;
  }>;

  publishedState?: 'published' | 'unpublished';

  discussion?: {
    type: string;

    discussionId: number;

    postCount?: number;

    active?: boolean;

    url?: string;
  };

  statsViewTotal?: number;

  highlights?: Array<{
    score?: number;

    path?: string;

    texts?: Array<{
      value?: string;

      type?: 'text' | 'hit';
    }>;
  }>;
};
export type SmartTag = {
  key: string;

  label?: string;

  values: Array<{
    key: string;

    label?: string;
  }>;
};
export type Image = {
  media_url: string;

  public_url: string;

  dimensions?: {
    X: number;

    Y: number;

    W: number;

    H: number;
  };
};
export type Feed_Create = {
  title: string;

  description?: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;

  allowedFields?: Array<string>;

  ogImageBackground?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  showPvInList?: boolean;
};
export type Feed_Update = {
  title?: string;

  description?: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;

  allowedFields?: Array<string>;

  ogImageBackground?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  showPvInList?: boolean;
};
export type Feed = {
  _id: string;

  id: string;

  title: string;

  description?: string;

  appSpace: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;

  createdDate: string;

  allowedFields?: Array<string>;

  feedItemUrlPattern?: string;

  pushNotifications?: {
    mode: 'manual' | 'auto';

    apps: Array<string>;
  };

  ogImageBackground?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  showPvInList?: boolean;
};
export type FeedWithHighlights = {
  _id: string;

  id: string;

  title: string;

  description?: string;

  appSpace: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;

  createdDate: string;

  allowedFields?: Array<string>;

  feedItemUrlPattern?: string;

  pushNotifications?: {
    mode: 'manual' | 'auto';

    apps: Array<string>;
  };

  ogImageBackground?: {
    media_url: string;

    public_url: string;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };

  showPvInList?: boolean;

  highlights?: Array<{
    score?: number;

    path?: string;

    texts?: Array<{
      value?: string;

      type?: 'text' | 'hit';
    }>;
  }>;
};
export type Feed_Public = {
  _id: string;

  id: string;

  title: string;

  description?: string;

  length?: number;

  sorter?: 'manual' | 'auto';

  fix?: number;
};
export type BannerContent = Array<{
  type: 'banners';

  mode:
    | 'full-rotation'
    | 'full-flat'
    | 'single-rotation'
    | 'single-random-rotation';

  title?: string;

  interval?: number;

  width?: number;

  items: Array<{
    picture: {
      media_url: string;

      public_url: string;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };

    url?: string;

    title?: string;
  }>;
}>;
export type Banner_Create = {
  title: string;

  dateFrom: string;

  dateTo?: string;

  content: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions: Array<number>;

  feeds: Array<string>;
};
export type Banner_Update = {
  title?: string;

  dateFrom?: string;

  dateTo?: string;

  content?: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions?: Array<number>;

  feeds?: Array<string>;
};
export type Banner = {
  _id: string;

  appSpace: string;

  createdDate?: string;

  title: string;

  dateFrom: string;

  dateTo?: string;

  content: Array<{
    type: 'banners';

    mode:
      | 'full-rotation'
      | 'full-flat'
      | 'single-rotation'
      | 'single-random-rotation';

    title?: string;

    interval?: number;

    width?: number;

    items: Array<{
      picture: {
        media_url: string;

        public_url: string;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };

      url?: string;

      title?: string;
    }>;
  }>;

  positions: Array<number>;

  feeds: Array<string>;
};
export type Highlight = {
  score?: number;

  path?: string;

  texts?: Array<{
    value?: string;

    type?: 'text' | 'hit';
  }>;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class FeedsApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class FeedsApi {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected apiKey: string | null = null;

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.apiKey) {
      headers.append('apikey', this.apiKey);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name FeedsApi#getFeedItemsWithBanners
   */
  getFeedItemsWithBanners(
    appSpace: string,
    feedId: string,
    parameters: {
      offset?: number;
      limit?: number;
      smarttags?: Array<string>;
      publishedState?: 'published' | 'unpublished';
    } = {},
  ): Promise<{
    total?: number;

    limit?: number;

    offset?: number;

    nextOffset?: number;

    feeditems?: Array<{}>;
  }> {
    let path = '/public/{appSpace}/feeds/{feedId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
      'joinUsingPipes',
    );

    if (typeof parameters['publishedState'] !== 'undefined') {
      queryParameters['publishedState'] = parameters['publishedState'];
    }

    queryParameters['publishedState'] = this.transformParameter(
      queryParameters['publishedState'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name FeedsApi#getPublicFeed
   */
  getPublicFeed(
    appSpace: string,
    feedId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    id: string;

    title: string;

    description?: string;

    length?: number;

    sorter?: 'manual' | 'auto';

    fix?: number;
  }> {
    let path = '/public/{appSpace}/feed/{feedId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name FeedsApi#getNumberOfFeedItems
   */
  getNumberOfFeedItems(
    appSpace: string,
    feedId: string,
    parameters: {
      dateFrom?: string;
      feedItemId?: string;
    } = {},
  ): Promise<{
    numberOfFeedItems: number;

    filter: {
      dateFrom: string;
    };
  }> {
    let path = '/public/{appSpace}/feeds/{feedId}/count';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['feedItemId'] !== 'undefined') {
      queryParameters['feedItemId'] = parameters['feedItemId'];
    }

    queryParameters['feedItemId'] = this.transformParameter(
      queryParameters['feedItemId'],
    );

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedId}', feedId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name FeedsApi#getFeedItemById
   */
  getFeedItemById(
    feedItemId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    itemId: string;

    appId: string;

    appSpace: string;

    feedId: string;

    createdDate: string;

    title: string;

    subtitle?: string;

    image?: {
      media_url: string;

      public_url: string;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };

    category?: string;

    description?: string;

    date: string;

    url: string;

    payload?: {};

    smarttags?: Array<{
      key: string;

      label?: string;

      values: Array<{
        key: string;

        label?: string;
      }>;
    }>;

    sorter?: number;

    extendedDescription?: Array<{
      type: string;
    }>;

    extendedDescriptionExcerpt?: string;

    authors?: Array<{
      displayName: string;

      sportnetId?: string;
    }>;

    publishedState?: 'published' | 'unpublished';

    discussion?: {
      type: string;

      discussionId: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };

    ogImageBackground?: {
      media_url: string;

      public_url: string;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }> {
    let path = '/detail/{feedItemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{feedItemId}', feedItemId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Edit forum posts count for article
   * @method
   * @name FeedsApi#putFeedItemDiscussionStats
   */
  putFeedItemDiscussionStats(
    appSpace: string,
    feedItemId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    appSpace?: string;

    externalIds?: Array<{
      service?: string;

      externalId?: string;

      lastUpdate?: string;

      error?: string;
    }>;

    discussion?: {
      type?: string;

      discussionId?: number;

      postCount?: number;

      active?: boolean;

      url?: string;
    };
  }> {
    let path = '/public/{appSpace}/discussionstats/{feedItemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedItemId}', feedItemId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Pocet zorbazeni feed polozky
   * @method
   * @name FeedsApi#putFeedItemStats
   */
  putFeedItemStats(
    appSpace: string,
    feedItemId: string,
    parameters: {} = {},
  ): Promise<{
    message: string;
  }> {
    let path = '/public/{appSpace}/feeditemstats/{feedItemId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{feedItemId}', feedItemId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new FeedsApi();
export { FeedsApi };
