import { getYoutubeEmbedUrl } from '@sportnet/content/editor/widgets/Video/utils';
import React from 'react';
import { IWidgets } from '../../library/App';
import isBrowser from '../../utilities/isBrowser';
import scribe from '../../utilities/scribe';

export default function useWidgetAdInjector(items: IWidgets) {
  const itemsHash = JSON.stringify(items);
  const youtubeWidgetIsPresent = React.useMemo(() => {
    return items.some((widget) => {
      return (
        widget.type === 'video' && widget.url && getYoutubeEmbedUrl(widget.url)
      );
    });
  }, [items]);

  React.useEffect(() => {
    if (youtubeWidgetIsPresent) {
      // import skriptu
      if (!window || !(window as any).smeVideoPlayback) {
        const timeoutID = window.setTimeout(() => {
          scribe(
            'body',
            `<script>
var smeVideoPlayback = smeVideoPlayback || {};
smeVideoPlayback.vendor = smeVideoPlayback.vendor || [];
smeVideoPlayback.vendor.push({'id':'smevideoplayer-asset-style', 'url':'/smevideoplayer/sme_videoplayer_sportnet_css_v1_0.css'});
smeVideoPlayback.vendor.push({'id':'smevideoplayer-asset-base', 'url':'/smevideoplayer/sme_videoplayer_sportnet_base_v1_0.js'});
smeVideoPlayback.vendor.push({'id':'smevideoplayer-asset-ima3', 'url':'https://imasdk.googleapis.com/js/sdkloader/ima3.js', 'optional':true});
smeVideoPlayback.vendor.push({'id':'smevideoplayer-asset-playback-plugins', 'url':'/smevideoplayer/sme_videoplayer_sportnet_plugins_v1_1.js'});
smeVideoPlayback.localSetup = ${JSON.stringify({
              service: {
                jwvideo: {
                  enabled: false,
                },
              },
              autoplay: false,
              positionViewport: {
                enabled: false,
              },
              advert: {
                enabled: 'auto-ad-manager',
                serviceBlacklist: ['vimeo'],
                list: [
                  {
                    provider: 'dfp',
                    offset: 'pre',
                    code: 'https://pubads.g.doubleclick.net/gampad/ads?sz=497x280%7C400x300%7C640x480%7C1280x720%7C640x360%7C1920x1080&iu=/43112356/ext_futbalnet/vembeda&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=sme.sk&plcmt=1&description_url=sme.sk&correlator=1000&vpos=preroll',
                  },
                  {
                    provider: 'dfp',
                    offset: 'pre',
                    code: 'https://pubads.g.doubleclick.net/gampad/ads?sz=497x280%7C400x300%7C640x480%7C1280x720%7C640x360%7C1920x1080&iu=/43112356/ext_futbalnet/vembedb&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=sme.sk&plcmt=1&description_url=sme.sk&correlator=1000&vpos=preroll',
                  },
                ],
              },
              playlist: {
                enabled: false,
              },
            })};

// for debug purpose
if (window.location.hash.indexOf('PPSmeDebug') > 0) {
  for (var i=0; i<smeVideoPlayback.localSetup.advert.list.length; i++) {
    smeVideoPlayback.localSetup.advert.list[i].code = 'https://pubads.g.doubleclick.net/gampad/ads?sz=497x280|400x300|640x480|1280x720|640x360|1920x1080&iu=/43112356/test_pr/vast_allwaysads&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&url=[referrer_url]&plcmt=1&description_url=[description_url]&correlator=[timestamp]&vpos=preroll';
  }
}
</script>
<script src="/smevideoplayer/sme_videoplayer_sportnet_core_v1_6.js" async></script>`,
            'append',
          );
        }, 0);
        return () => {
          window.clearTimeout(timeoutID);
        };
      } else if (isBrowser()) {
        // skript uz mame, zaregistrujeme event
        window.dispatchEvent(new CustomEvent('SportNet.Page.Navigate'));
      }
    }
    return;
  }, [youtubeWidgetIsPresent, itemsHash]);
}
