import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Breadcrumbs';
import config from '../../config';
import { RootState } from '../../configureStore';
import UrlContext from '../../contexts/UrlContext';
import { ISection, SectionNode } from '../../library/App';
import {
  getSectionSubTreeByParameters,
  sectionPathSelector,
} from '../../pages/App/selectors';
import {
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../../pages/Competition/selectors';
import findSectionPath from '../../utilities/findSectionPath';
import { ROOT_BREADCRUMB } from './FutbalnetUnionBreadcrumbs';
import { ROOT_BREADCRUMB as PORTAL_ROOT_BREADCRUMB } from '../SectionsBreadcrumbs';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';

type IOwnProps = {
  className?: string;
  section: SectionNode | null;
  zvaz: string;
};

const mapStateToProps = (state: RootState, { section }: IOwnProps) => {
  const currentAppSpace = getCurrentCompetitionAppSpaceSelector(state);
  return {
    organizationProfile: currentOrganizationProfileSelector(state),
    path: sectionPathSelector(
      state,
      section?._id ? String(section?._id) : null,
    ),
    menuSections:
      getSectionSubTreeByParameters(
        state,
        config.CLUB_SECTIONS_MENU_SECTIONID_OR_UNIQ_ID,
        { appSpace: currentAppSpace },
      )?.sections || [],
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FutbalnetSectionsBreadcrumbs: React.FC<IProps> = ({
  menuSections,
  className,
  section,
  organizationProfile,
  zvaz,
}) => {
  const { getSectionUrl } = React.useContext(UrlContext);

  const sectionIdOrUniqId = section?._id || '';
  const unionName =
    organizationProfile?.shortName || organizationProfile?.name || '';

  const crumbs: Array<{
    label: string;
    url?: string;
  }> = React.useMemo(() => {
    const path = findSectionPath(sectionIdOrUniqId, menuSections) ?? [];
    const finalBreadCrumbs = path.map((section) => ({
      label: section.name!,
      url: getSectionUrl(section as ISection),
    }));
    const union = {
      label: unionName,
      url: getUnionBaseUrl({ zvaz }),
    };
    return [
      PORTAL_ROOT_BREADCRUMB,
      ROOT_BREADCRUMB,
      union,
      ...finalBreadCrumbs,
    ];
  }, [sectionIdOrUniqId, menuSections, getSectionUrl, zvaz, unionName]);

  return <Breadcrumbs className={className} crumbs={crumbs} />;
};

export default connect(mapStateToProps)(FutbalnetSectionsBreadcrumbs);
