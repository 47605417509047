import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M12,1.9h-0.6V1.3c0-0.4-0.3-0.8-0.8-0.8S9.9,0.9,9.9,1.3v0.6H6.1V1.3c0-0.4-0.3-0.8-0.8-0.8S4.6,0.9,4.6,1.3
      v0.6H4c-1.5,0-2.8,1.2-2.8,2.8v8c0,1.5,1.2,2.8,2.8,2.8h8c1.5,0,2.8-1.2,2.8-2.8v-8C14.8,3.1,13.5,1.9,12,1.9z M4,3.4h0.6V4
      c0,0.4,0.3,0.8,0.8,0.8S6.1,4.4,6.1,4V3.4h3.8V4c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V3.4H12c0.7,0,1.2,0.6,1.2,1.2v1.2H2.8V4.7
      C2.8,4,3.3,3.4,4,3.4z M12,13.9H4c-0.7,0-1.2-0.6-1.2-1.2V7.4h10.5v5.2C13.2,13.4,12.7,13.9,12,13.9z"
    />
  </svg>
);
