import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

type Props = {
  text: string;
};

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: ${rem(24)} 0;
`;

const PrintNotFound: React.FC<Props> = ({ text }) => {
  return <Wrapper>{text}</Wrapper>;
};

export default PrintNotFound;
