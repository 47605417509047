
import React from 'react';

const Twitter = (size: string, color: string) => (
  <svg
    version="1.1"
    id="_x32_4x24"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M14.2,10.2L23,0h-2.1l-7.6,8.8L7.3,0h-7l9.2,13.3L0.3,24h2.1l8-9.3l6.4,9.3h7L14.2,10.2z M11.4,13.5L11.4,13.5l-0.9-1.3
	L3.1,1.6h3.2l6,8.5l0.9,1.3l7.8,11.1h-3.2L11.4,13.5z"
    />
  </svg>
);

export default Twitter;
