import urlConverter from '../urlConverter';

export default ({ klub }: { klub: string | number }) => {
  let clubId = klub;
  const convertedUnionData = urlConverter.find(
    (i) => i._id === klub || i.__issfId === Number(klub),
  );
  if (convertedUnionData) {
    clubId = convertedUnionData.url;
  }
  return `/futbalnet/k/${clubId}/`;
};
