import { getBrickSpaBlocks } from '../../features/petitpress/getBrickSpaBlocks';
import { useQ } from '../../query';
import React from 'react';
import getHtmlElementsFromBricks from '../../utilities/petitpress/getHtmlElementsFromBricks';
import scribeBrickElements from '../../utilities/petitpress/scribeBrickElements';

/**
 * Ziska HTML, Script, Styles kody z Petitpress sluzby a osadi ich na vhodne miesta.
 * @returns
 */
const BrickSpaBlocks: React.FC = () => {
  const { data: blocks } = useQ(getBrickSpaBlocks, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    const headerPlaceholder = document.getElementById('sme-header');
    const pagerPlaceholder = document.getElementById('sme-pager');
    if (
      blocks &&
      blocks['sme-header'] &&
      blocks['sme-pager'] &&
      headerPlaceholder &&
      pagerPlaceholder
    ) {
      try {
        // osadime header do head casti webu
        const headElements = getHtmlElementsFromBricks(blocks['sme-header']);
        scribeBrickElements(headerPlaceholder, headElements);

        // osadime pager
        const pagerElements = getHtmlElementsFromBricks(blocks['sme-pager']);
        scribeBrickElements(pagerPlaceholder, pagerElements);
      } catch (e) {
        console.error('Error integrating bricks!', e);
      }
    }
  }, [blocks]);

  return null;
};

export default BrickSpaBlocks;
