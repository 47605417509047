import { QueryHocInterface } from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManagerContextProvider from '../../components/Ads/AdManager/AdManagerContextProvider';
import Loading from '../../components/Loading';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import pageViewWithDimensions from '../../library/pageViewWithDimensions';
import { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import {
  loadOrganizationProfileItem,
} from './actions';
import CompetitionHeader from './CompetitionHeader';
import {
  currentCompetitionPartIsFetchingSelector,
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from './selectors';

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
  currentCompetitionPart: currentCompetitionPartSelector(state),
  isFetchingCompetitionParts:
    currentCompetitionPartIsFetchingSelector(state) !== false,
  organizationProfile: currentOrganizationProfileSelector(state),
});

type Props = { theme: ITheme } & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

class Competition extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    // dispatch(setCurrentCompetitionPartId(''));
    await dispatch(thunkToAction(loadOrganizationProfileItem)({}) as any);
  }

  pageViewWithDimensions() {
    const { competition, organizationProfile, currentCompetitionPart } =
      this.props;
    const competitionPart =
      currentCompetitionPart || (competition?.parts || [])[0];
    pageViewWithDimensions({
      unionName: organizationProfile?.name,
      seasonName: competition?.season?.name,
      competitionLevel: competition?.level,
      competitionCategory: competitionPart?.rules?.category,
      competitionGender: competitionPart?.rules?.gender,
      competitionName: competition?.name,
    });
  }

  async componentDidMount() {
    try {
      await Competition.getInitialProps(this.props);
      this.pageViewWithDimensions();
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      this.pageViewWithDimensions();
    }
  }

  render() {
    const {
      children,
      competition,
      organizationProfile,
      currentCompetitionPart,
    } = this.props;

    if (
      !competition ||
      !organizationProfile
      // (competition && !competition.resultsTable)
    ) {
      return <Loading />;
    }

    const competitionPart =
      currentCompetitionPart || (competition.parts || [])[0];

    return (
      <>
        <CompetitionHeader />
        <AdManagerContextProvider
          origin="futbalnet"
          pagetype="table"
          seasonName={competition.season?.name}
          competitionLevel={competition.level?.toString()}
          competitionCategory={competitionPart?.rules?.category}
          competitionGender={competitionPart?.rules?.gender}
          competitionName={competition.name}

        >
          {children}
        </AdManagerContextProvider>
      </>
    );
  }
}

export default compose(withTheme, connect(mapStateToProps))(Competition);
