import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: ${rem(1)};
  background-color: ${({ theme }) => theme.color.separator};
  margin: 0 0 ${rem(48)} 0;
  ${mb('s')} {
    margin: 0 0 0 ${rem(56)} 0;
  }
`;
