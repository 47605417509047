import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../query';
import { COUNTRY_SLOVAKIA, fetchAndParseNikeData } from './loadNikeEvents';
import { ThirdPartyEvent, ThirdPartyGrouppedEvents } from '../library/thirdPartyResults';

interface LoadNikeEventsForFutbalnetProps {
  segment: ThirdPartyEvent['type'];
}

const loadNikeEventsForFutbalnet = createQueryFn(
  QueryNameSpace.thirdPartyResultsWithSportnetIds,
  async ({
    queryKey,
  }: QueryFunctionContext<[string, LoadNikeEventsForFutbalnetProps]>): Promise<
    ThirdPartyGrouppedEvents[]
  > => {

    const [, params] = queryKey;

    if (!params.segment) {
      throw new Error('Provide required parameters [loadDoxxBet]!');
    }

    const { segment } = params;

    const thirdPartyData = await fetchAndParseNikeData({
      segment,
      sportSlug: 'futbal',
      region: String(COUNTRY_SLOVAKIA),
    });

    return thirdPartyData;
  },
);

export default loadNikeEventsForFutbalnet;
