import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from './actions';

export const currentCompetitionAppSpaceReducer = reducerWithInitialState<
  string | null
>(null).case(actions.setCurrentAppSpace, (state, appSpace) => appSpace || null);

export const currentCompetitionIdReducer = reducerWithInitialState<
  string | null
>(null).case(
  actions.setCurrentCompetitionId,
  (state, competitionId) => competitionId || null,
);

export const currentCompetitionGroupIdReducer = reducerWithInitialState<
  string | null
>(null).case(
  actions.setCurrentCompetitionGroupId,
  (state, competitionGroupId) => competitionGroupId || null,
);

export const currentRoundIdReducer = reducerWithInitialState<string | null>(
  null,
).case(actions.setCurrentRoundId, (state, roundId) => roundId || null);

export const currentCompetitionPartIdReducer = reducerWithInitialState<
  string | null
>(null).case(
  actions.setCurrentCompetitionPartId,
  (state, competitionPartId) => competitionPartId || null,
);
