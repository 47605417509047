import {
  IRenderLink,
  IWidgetPhotoGallery,
  IWidgetPicture,
  IWidgetType,
} from '@sportnet/content/utilities/library';
import InternalContentArticle, {
  IWidgetRealtedContentArticle,
} from './InternalContentArticle';
import InternalContentPhotogallery from './InternalContentPhotogallery';
import React from 'react';

export type IRenderEmpty = (type: IWidgetType) => React.ReactNode;

export interface IWidgetInternalContentPhotogallery {
  _id: number;
  doctype: string;
  name: string;
  valid_from: string | null;
  url: string;
  picture?: IWidgetPicture;
  widgets: IWidgetPhotoGallery[];
  perex?: string;
}

export interface IWidgetInternalContent {
  type: 'internalContent';
  articleId?: number;
  showTitle?: boolean;
  customTitle?: string;
  data?: IWidgetRealtedContentArticle | IWidgetInternalContentPhotogallery;
  customWidgetTitle?: string;
  showWidgetTitle?: boolean;
}

type IData = Omit<IWidgetInternalContent, 'type'>;

interface IOwnProps {
  data?: IData;
  renderEmpty?: IRenderEmpty;
  renderLink?: IRenderLink;
}

export const isPhotogalleryPreview = (
  articlePreview:
    | IWidgetRealtedContentArticle
    | IWidgetInternalContentPhotogallery
): articlePreview is IWidgetInternalContentPhotogallery => {
  return articlePreview.doctype === 'photogallery';
};

export const isArticlePreview = (
  articlePreview:
    | IWidgetRealtedContentArticle
    | IWidgetInternalContentPhotogallery
): articlePreview is IWidgetRealtedContentArticle => {
  return articlePreview.doctype !== 'photogallery';
};

const InternalContent: React.FC<IOwnProps> = ({
  data,
  renderEmpty,
  renderLink,
}) => {
  const articlePreview = data?.data;

  if (!articlePreview || !renderLink) {
    if (renderEmpty) {
      return <>{renderEmpty('internalContent' as any)}</>;
    }
    return null;
  }

  const customTitle = (data?.customTitle ?? '').trim();
  const customWidgetTitle = (data?.customWidgetTitle ?? '').trim();
  const showWidgetTitle = data?.showWidgetTitle ?? true;

  return (
    <>
      {isPhotogalleryPreview(articlePreview) ? (
        <InternalContentPhotogallery
          articlePreview={articlePreview}
          renderLink={renderLink}
          customTitle={customTitle}
          customWidgetTitle={customWidgetTitle}
          showWidgetTitle={showWidgetTitle}
          showTitle={data?.showTitle ?? false}
        />
      ) : isArticlePreview(articlePreview) ? (
        <InternalContentArticle
          articlePreview={articlePreview}
          renderLink={renderLink}
          customTitle={customTitle}
          customWidgetTitle={customWidgetTitle}
          showWidgetTitle={showWidgetTitle}
          showTitle={data?.showTitle ?? false}
        />
      ) : null}
    </>
  );
};

export default InternalContent;
