import 'isomorphic-fetch';
import Cookies from 'js-cookie';
import * as React from 'react';
import { connect } from 'react-redux';
import { objectToParams } from 'sportnet-utilities';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { ReduxConnectProps } from '../../configureStore';
import { RouteProps } from '../../library/App';
import {
  setAuthUser,
  performLogin,
  verifyToken,
} from '../../pages/App/actions';
import getBaseUrl from '../../utilities/getBaseUrl';
import isBrowser from '../../utilities/isBrowser';
import { useQueryClient } from '@tanstack/react-query';
import useWaitForSmeUserAndTriggerLogin from '../../hooks/useWaitForSmeUserAndTriggerLogin';

export const TOKEN_COOKIE_KEY = '_accessToken';

export const getHost = () => {
  return process.env.REACT_APP_HOST || '';
};

export function getCallbackAuthUrl() {
  let redirectUri = '';
  if (isBrowser()) {
    redirectUri = window.location.href;
  } else {
    redirectUri = getHost();
  }
  const params = {
    url: redirectUri,
  } as any;
  return `${
    process.env.REACT_APP_SME_LOGIN_BASE_URL
  }/refresh-token?${objectToParams(params)}`;
}

export function getCallbackPredplatneUrl() {
  let redirectUri = '';
  if (isBrowser()) {
    redirectUri = window.location.href;
  } else {
    redirectUri = getHost();
  }
  const params = {
    url: redirectUri,
  } as any;
  return `${process.env.REACT_APP_SME_PREPLATNE_BASE_URL}?${objectToParams(
    params,
  )}`;
}

export function getCallbackLogoutUrl() {
  let redirectUri = '';
  if (isBrowser()) {
    redirectUri = window.location.href;
  } else {
    redirectUri = getHost();
  }
  const params = {
    uri: redirectUri,
  } as any;
  return `${getBaseUrl()}/logout/?${objectToParams(params)}`;
}

type IOwnProps = {
  children?: React.ReactNode;
};

const mapDispatchToProps = {
  setAuthUser,
  performLogin: thunkToAction(performLogin.action),
  verifyToken: thunkToAction(verifyToken.action),
};

type IProps = IOwnProps &
  typeof mapDispatchToProps &
  ReduxConnectProps &
  RouteProps;

const Authorization: React.FC<IProps> = ({
  setAuthUser,
  verifyToken,
  performLogin,
  children,
}) => {
  const queryClient = useQueryClient();

  useWaitForSmeUserAndTriggerLogin(queryClient);

  React.useEffect(() => {
    (async () => {
      if (isBrowser()) {
        // [optimalizácia] ak nemame ppp cookie, ide o prvu navstevu portalu a je zbytocne robit request na /login/ ktory aj
        // tak skonci 401
        const ppp = Cookies.get('ppp');
        if (ppp) {
          await performLogin();
        } else {
          setAuthUser({ fetching: 0 });
        }
      } else {
        setAuthUser({ fetching: 3 });
      }
    })();
  }, [setAuthUser, performLogin, verifyToken]);

  return <>{children}</>;
};

export default connect(null, mapDispatchToProps)(Authorization);
