import React from 'react';
import {
  getInitialLimitByOffset,
  getOffsetByPageNumber,
} from '../../containers/InfiniteArticleList/v2/utils';

const useInfiniteListParams = (
  page: number,
  firstLoadLimit?: number,
  furtherLoadLimit?: number,
) => {
  const [initialData] = React.useState<{
    initialLimit: number;
    initialOffset: number;
  }>({
    initialLimit: getInitialLimitByOffset(
      getOffsetByPageNumber(page),
      firstLoadLimit,
      furtherLoadLimit,
    ),
    initialOffset: getOffsetByPageNumber(
      page,
      firstLoadLimit,
      furtherLoadLimit,
    ),
  });

  return {
    ...initialData,
    nextOffset: getOffsetByPageNumber(page, firstLoadLimit, furtherLoadLimit),
  };
};

export default useInfiniteListParams;
