import React from 'react';
import emptyFn from '../../utilities/emptyFn';

export interface ILayoutContext {
  isMenuFixed: boolean;
  setIsMenuFixed: (state: boolean) => void;
  isDropdownActive: boolean;
  setIsDropdownActive: (state: boolean) => void;
  menuPositioner: React.RefObject<HTMLDivElement> | null;
  sidebarRef: React.RefObject<HTMLDivElement> | null;
}

const LayoutContext = React.createContext<ILayoutContext>({
  isMenuFixed: false,
  setIsMenuFixed: emptyFn,
  isDropdownActive: false,
  setIsDropdownActive: emptyFn,
  menuPositioner: null,
  sidebarRef: null,
});

export default LayoutContext;
