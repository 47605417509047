import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <style type="text/css">
      .st0{'{'}fill:#FF0C00;{'}'}
      .st1{'{'}fill:url(#SVGID_1_);{'}'}
    </style>
    <path className="st0" d="M6,10L0,2h12L6,10z" />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="6"
      y1="9.1667"
      x2="6"
      y2="2.5"
    >
      <stop offset="0" style={{ stopColor: '#FF0C00' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0.4 }} />
    </linearGradient>
    <path className="st1" d="M1,2.5h10L6,9.2L1,2.5z" />
    <path className="st0" d="M6,8.3L10,3H2L6,8.3 M6,10L0,2h12L6,10z" />
  </svg>
);
