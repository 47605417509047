import { __ } from '@sportnet/ui/lib/utilities';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import MatchesList from '../../../components/MatchesList';
import Spacer from '../../../components/Spacer';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { RouteProps } from '../../../library/App';
import { isLoadingList } from '../../App/selectors';
import PrintNotFound from '../../FutbalnetPrint/PrintNotFound';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import {
  loadH2hMatches,
  loadLastAwayTeamMatches,
  loadLastHomeTeamMatches,
  loadMutualMatches,
} from '../actions';
import {
  currentMatchH2HMatchesSelector,
  currentMatchSelector,
} from '../selectors';

const mapStateToProps = (state: RootState) => ({
  currentMatch: currentMatchSelector(state),
  matches: currentMatchH2HMatchesSelector(state),
  isLoading: isLoadingList(state, config.LIST_H2H_MATCHES),
});

const mapDispatchToProps = {
  loadMutualMatches: thunkToAction(loadMutualMatches.action),
  loadLastHomeTeamMatches: thunkToAction(loadLastHomeTeamMatches.action),
  loadLastAwayTeamMatches: thunkToAction(loadLastAwayTeamMatches.action),
  loadH2HMatches: thunkToAction(loadH2hMatches.action),
};

type OwnProps = {
  print?: boolean;
};

type Props = ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  OwnProps &
  typeof mapDispatchToProps;

const MatchHeadToHead: React.FC<Props> = ({
  matches,
  currentMatch,
  loadH2HMatches,
  isLoading,
  print,
}) => {
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  useAsyncData(async () => {
    try {
      await loadH2HMatches({ limit: config.LIST_H2H_MATCHES_LIMIT });
    } catch (e: any) {
      /* console.error(e); */
    }
  }, [loadH2HMatches]);

  const homeTeam = (currentMatch?.teams || []).find(
    (t) => t.additionalProperties && t.additionalProperties.homeaway === 'home'
  );
  const awayTeam = (currentMatch?.teams || []).find(
    (t) => t.additionalProperties && t.additionalProperties.homeaway === 'away'
  );

  if (isLoading) {
    return <Loading />;
  }

  const mutualMatches = matches.filter((i) => i.h2hType === 'common');
  const lastHomeTeamMatches = matches.filter((i) => i.h2hType === 'firstTeam');
  const lastAwayTeamMatches = matches.filter((i) => i.h2hType === 'secondTeam');

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {matches.length > 0 ? (
            <Spacer size={4}>
              {mutualMatches.length > 0 && (
                <MatchesList
                  title={__('Vzájomné zápasy')}
                  matches={mutualMatches}
                  isFetching={false}
                  simpleTable
                />
              )}
              {lastHomeTeamMatches.length > 0 && (
                <MatchesList
                  title={`${__('Posledné zápasy')} - ${homeTeam?.name}`}
                  matches={lastHomeTeamMatches}
                  isFetching={false}
                  simpleTable
                />
              )}
              {lastAwayTeamMatches.length > 0 && (
                <MatchesList
                  title={`${__('Posledné zápasy')} - ${awayTeam?.name}`}
                  matches={lastAwayTeamMatches}
                  isFetching={false}
                  simpleTable
                />
              )}
            </Spacer>
          ) : (
            <>
              {print ? (
                <PrintNotFound text={__('Žiadne posledné zápasy')} />
              ) : (
                <ContentNotFound title={__('Žiadne posledné zápasy')} />
              )}
            </>
          )}
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      <FutbalnetMatchBreadcrumbs />
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MatchHeadToHead) as unknown as React.FC<OwnProps>;
