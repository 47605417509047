import { IResultsTableItem } from '@sportnet/content/library/Competitions';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import Tooltip from 'react-tooltip-lite';
import styled, { css } from '../../../../theme/styled-components';
import getTeamUrl from '../../../../utilities/events/getTeamUrl';
import getPublicCountryCode from '../../../../utilities/match/getPublicCountryCode';
import getTeamName from '../../../../utilities/match/getTeamName';
import __ from '../../../../utilities/__';
import {
  Legend,
  ResponsiveSpacer,
  StickySeparator,
  StickyTd,
  Table,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Thead,
  TrLink,
} from '../../../Table/DivTable';
import { TeamLogo, TeamLogoWrapper, TeamName } from './components';
import { ITeam, IWidgetResultsTable } from './types';
import { ITeam as ITeam2 } from '../../../../library/Competitions';
import getFallbackClubOrUnionLogo from '../../../../utilities/getFallbackClubOrUnionLogo';

const STICKY_INDEX_WIDTH = 64;
const STICKY_TEAM_NAME_WIDTH = 96;
const STICKY_SEPARATOR_OFFSET = STICKY_INDEX_WIDTH + STICKY_TEAM_NAME_WIDTH;

const Index = styled.div<{ indexType: '1' | '2' | '3' }>`
  margin: auto;
  width: ${rem(24)};
  height: ${rem(24)};
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${rem(11)};
  line-height: ${rem(14.4)};
  text-align: center;
  ${({ indexType }) =>
    indexType === '1'
      ? css`
          color: #fff;
          background-color: #5bd084;
        `
      : indexType === '2'
      ? css`
          // color: #fff;
          // background-color: #e94141;
        `
      : ''}
`;

const ResponsiveTeamLogoWrapper = styled(TeamLogoWrapper)<{ appSpace: string }>`
  display: inline-block;
  ${({ appSpace }) =>
    appSpace &&
    appSpace !== 'uefa' &&
    css`
      display: none;
      ${mb('m')} {
        display: inline-block;
      }
    `}
`;

const DesktopText = styled.span`
  display: none;
  ${mb('m')} {
    display: inline;
  }
`;

const MobileText = styled.span`
  ${mb('m')} {
    display: none !important;
  }
`;

const MobileTeamName = styled(MobileText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TeamStickyTd = styled(StickyTd)`
  min-width: ${rem(STICKY_TEAM_NAME_WIDTH)};
  ${mb('m')} {
    min-width: ${rem(120)};
  }
`;

interface IOwnProps {
  data: IWidgetResultsTable;
}

const Condition = styled.div`
  display: flex;
`;
const ConditionItem = styled.div<{ result: string }>`
  width: ${rem(24)};
  height: ${rem(24)};
  font-size: ${rem(12)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme, result }) => {
    if (result === 'W') {
      return theme.color.success;
    } else if (result === 'D') {
      return theme.color.warning;
    } else if (result === 'L') {
      return theme.color.danger;
    }
    return theme.color.primary;
  }};
  color: white;
  margin: 0 ${rem(2)};
`;

interface IOwnProps {
  data: IWidgetResultsTable;
}

const getResultLabel = (result: 'W' | 'D' | 'L') => {
  switch (result) {
    case 'W':
      return 'V';
    case 'D':
      return 'R';
    case 'L':
      return 'P';
    default:
      return '';
  }
};

const FootBallResultsTable: React.FC<IOwnProps> = ({ data }) => {
  const results: IResultsTableItem[] = data?.data?.results || [];
  const teams = data?.data?.teams || [];
  const teamById = teams.reduce((acc: Record<string, ITeam>, t) => {
    acc[t._id] = t;
    return acc;
  }, {});

  const { competition } = data.data ?? {};
  const { _id: competitionId = '' } = competition ?? {};

  const getTeamCondition = (result: IResultsTableItem) => {
    const lastFive = [...(result.matches || [])].slice(0, 5);
    return lastFive.map((m) => {
      const matchLabel = `${
        m.homeaway === 'home' ? result.team && result.team.name : m.team.name
      } - ${
        m.homeaway === 'away' ? result.team && result.team.name : m.team.name
      }`;
      return (
        <Tooltip
          key={matchLabel}
          content={
            <div>
              <b>{m.score}</b> ({matchLabel})
            </div>
          }
        >
          <ConditionItem result={m.result}>
            {getResultLabel(m.result)}
          </ConditionItem>
        </Tooltip>
      );
    });
  };

  return (
    <>
      <TableWrapper>
        <Table>
          <Thead>
            <Th>
              <StickyTd
                style={{
                  width: rem(STICKY_INDEX_WIDTH),
                  minWidth: rem(STICKY_INDEX_WIDTH),
                  left: 0,
                }}
              />
              <TeamStickyTd style={{ left: rem(STICKY_INDEX_WIDTH) }}>
                {data.appSpace === 'uefa' ? (
                  <>
                    <DesktopText>{__('Názov krajiny')}</DesktopText>
                    <MobileText>{__('Štát')}</MobileText>
                  </>
                ) : (
                  <>
                    <DesktopText>{__('Názov družstva')}</DesktopText>
                    <MobileText>{__('Družstvo')}</MobileText>
                  </>
                )}
              </TeamStickyTd>
              <StickyTd
                style={{ padding: 0, left: rem(STICKY_SEPARATOR_OFFSET) }}
              >
                <StickySeparator />
              </StickyTd>
              <ResponsiveSpacer />
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Zápasy')}>
                  {__('Z')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Výhry')}>
                  {__('V')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Remízy')}>
                  {__('R')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Prehry')}>
                  {__('P')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(36) }}>{__('Skóre')}</Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Body')}>
                  {__('B')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(160) }}>{__('Forma')}</Td>
            </Th>
          </Thead>
          <Tbody>
            {results.map((result, idx) => {
              const team = teamById[result.team._id] || result.team;
              const teamName = getTeamName(data.appSpace!, team);
              const teamLogoSrc = getTeamLogoSrc(data.appSpace || '', team);
              const stats = result.stats as any;
              const indexType =
                data.appSpace! === 'uefa'
                  ? data.partId === '60caf5deb70ae367f3d200fa' && idx < 4
                    ? '1'
                    : idx < 2
                    ? '1'
                    : idx === results.length - 1
                    ? '2'
                    : '3'
                  : '3';

              const teamUrl = team
                ? getTeamUrl(team as unknown as ITeam2, competitionId)
                : '';
              return (
                <TrLink key={team._id} to="">
                  <StickyTd style={{ left: 0 }}>
                    <Index indexType={indexType}>{idx + 1}</Index>
                  </StickyTd>
                  <StickyTd style={{ left: rem(STICKY_INDEX_WIDTH) }}>
                    <FlexWrapper>
                      {teamLogoSrc && (
                        <ResponsiveTeamLogoWrapper appSpace={data.appSpace}>
                          <TeamLogo
                            fallbackSrc={getFallbackClubOrUnionLogo()}
                            alt={teamName}
                            src={teamLogoSrc}
                            loading="lazy"
                          />
                        </ResponsiveTeamLogoWrapper>
                      )}
                      <TeamName href={teamUrl} as={teamUrl ? 'a' : 'span'}>
                        <DesktopText>{teamName}</DesktopText>
                        {data.appSpace === 'uefa' ? (
                          <MobileTeamName
                            style={{ maxWidth: rem(32) }}
                            title={teamName}
                          >
                            {getPublicCountryCode(team.organization?.country) ||
                              teamName}
                          </MobileTeamName>
                        ) : (
                          <MobileTeamName
                            style={{ maxWidth: rem(96) }}
                            title={teamName}
                          >
                            {teamName}
                          </MobileTeamName>
                        )}
                      </TeamName>
                    </FlexWrapper>
                  </StickyTd>
                  <StickyTd
                    style={{ padding: 0, left: rem(STICKY_SEPARATOR_OFFSET) }}
                  >
                    <StickySeparator />
                  </StickyTd>
                  <ResponsiveSpacer />
                  <Td>{stats.matches.played}</Td>
                  <Td>{stats.matches.won}</Td>
                  <Td>{stats.matches.draw}</Td>
                  <Td>{stats.matches.lost}</Td>
                  <Td>
                    {stats.goals.given}:{stats.goals.received}
                  </Td>
                  <Td>{stats.points}</Td>
                  <Td>
                    <Condition>{getTeamCondition(result)}</Condition>
                  </Td>
                </TrLink>
              );
            })}
          </Tbody>
        </Table>
      </TableWrapper>
      <Legend>
        {__('Z = zápasy, V = Výhry, R = Remízy, P = Prehry, B = Body')}
      </Legend>
    </>
  );
};

export default React.memo(FootBallResultsTable);
