import { commit, getListParameters } from '@sportnet/redux-list/ducks';
import { CustomThunkAction } from '../../../configureStore';
import { ICreditItem } from '../../../library/App';

export const loadCreditItems = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState, { EshopApi }) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          const response = (await EshopApi.exportCreditItems(
            parameters.businessId,
            {
              fromDate: parameters.fromDate,
              toDate: parameters.toDate,
            },
          )) as { items: ICreditItem[] };
          return {
            results: response.items || [],
            total: response.items.length,
          };
        },
      }),
    );
  };
};
