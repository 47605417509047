import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import ArticleSectionHeader from '../../components/Articles/ArticleSectionHeader';
import ArticleSeparatorLine from '../../components/Articles/ArticleSeparatorLine';
import ArticlesList from '../../components/Articles/ArticlesList';
import { RootState } from '../../configureStore';
import { IArticle } from '../../library/App';
import { sectionsEntitiesSelector } from '../../pages/Section/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const PositionedArticlesList = styled(ArticlesList)`
  margin: 0 0 ${rem(48)} 0;
  ${mb('s')} {
    margin: 0 0 ${rem(56)} 0;
  }
`;

type IOwnProps = {
  article: IArticle;
};

const mapStateToProps = (state: RootState) => {
  return {
    sectionsById: sectionsEntitiesSelector(state),
  };
};

const mapDispatchToProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const ArticleListPreview: React.FC<IProps> = ({ sectionsById, article }) => {
  return (
    <>
      <ArticleSectionHeader>{__('Náhlad článku')}</ArticleSectionHeader>
      <PositionedArticlesList
        articles={[article]}
        sectionsById={sectionsById}
      />
      <ArticleSeparatorLine />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleListPreview);
