const getWinningTeam = (
  homeTeamGoals: number,
  awayTeamGoals: number,
  homeTeamPenaltyGoals: number,
  awayTeamPenaltyGoals: number,
) => {
  if (
    homeTeamGoals > awayTeamGoals ||
    (homeTeamGoals === awayTeamGoals &&
      homeTeamPenaltyGoals > awayTeamPenaltyGoals)
  ) {
    return { homeTeamIsWinner: true, awayTeamIsWinner: false };
  } else if (
    homeTeamGoals < awayTeamGoals ||
    (homeTeamGoals === awayTeamGoals &&
      awayTeamPenaltyGoals > homeTeamPenaltyGoals)
  ) {
    return { homeTeamIsWinner: false, awayTeamIsWinner: true };
  }
  return { homeTeamIsWinner: false, awayTeamIsWinner: false };
};

export default getWinningTeam;
