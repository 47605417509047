import * as React from 'react';
import Link from '../../components/Link';
import styled from '../../theme/styled-components';
import getClubBaseUrl from '../../utilities/getClubBaseUrl';

const CustomLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

export const renderClub = (club?: { name: string; id: number }) => {
  return club ? (
    <CustomLink to={getClubBaseUrl({ klub: club.id })}>{club.name}</CustomLink>
  ) : (
    '-'
  );
};
