import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RootState } from '../../../../configureStore';
import { generalInformationsMenuSelector } from '../../../../pages/App/selectors';
import { footerConfigurationSelector } from '../../../../pages/DomainResolver/selectors';
import FooterDefault from './Footer';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  const footerConfiguration = footerConfigurationSelector(state);

  return {
    footerConfiguration,
    sections: generalInformationsMenuSelector(state),
  };
};

const mapDispatchToProps = {};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const FooterSimple: React.FC<Props> = ({ footerConfiguration, sections }) => {
  return (
    <FooterDefault
      configuration={footerConfiguration.config || {}}
      sections={sections}
    />
  );
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FooterSimple,
);
