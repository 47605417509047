import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M6,12.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1L8.9,8L5.5,4.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0
      l4,4c0.3,0.3,0.3,0.8,0,1.1l-4,4C6.4,12.7,6.2,12.8,6,12.8z"
    />
  </svg>
);
