import { __ } from '@sportnet/content/utilities/utilities';
import Loader from '@sportnet/ui/lib/Loader';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import useQuery, { PageParam, StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import CoreApi, { Codelist } from '../../api/CoreApi';
import AdManager from '../../components/Ads/AdManager';
import BottomPaddedBigAd from '../../components/Ads/BottomPaddedBigAd';
import ListBodyRespAd from '../../components/Ads/ListBodyRespAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import SideAd from '../../components/Ads/SideAd';
import TopAd from '../../components/Ads/TopAd';
import Avatar from '../../components/Avatar';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import MaxWidthBox from '../../components/MaxWidthBox';
import NextPrevPaginator from '../../components/NextPrevPaginator';
import SearchInput from '../../components/SearchInput';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/Table';
import config from '../../config';
import { RootState } from '../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../containers/RempIntegration';
import { AsyncReturnType, RouteProps } from '../../library/App';
import { ISportExpertOrg, ISportOrg } from '../../library/Sportnet';
import styled from '../../theme/styled-components';
import getMemberBaseUrl from '../../utilities/getMemberBaseUrl';
import getPPOUserFullName from '../../utilities/getPPOUserFullName';
import { fetchCodeList, initializeOrSetListParams } from '../App/actions';
import { codeListDataSelector } from '../App/selectors';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import { loadMembers } from './actions';
import { searchedMemberSelector } from './selectors';

const StyledMaxWidthBox = styled(MaxWidthBox)`
  && {
    margin: ${rem(24)} auto;
    ${mb('s')} {
      margin: ${rem(32)} auto;
    }
  }
`;

const ResultsWrapper = styled.div`
  min-height: 50vh;
`;

const PositionedNextPrevPaginator = styled(NextPrevPaginator)`
  margin: ${rem(48)} auto 0 auto;
  ${mb('s')} {
    margin: ${rem(56)} auto 0 auto;
  }
`;

const MemberType = styled.span`
  padding: ${rem(2)} ${rem(8)} ${rem(3)} ${rem(8)};
  background-color: ${({ theme }) => theme.memberSearch.memberTypeBg};
`;

const CustomTh = styled(Th)`
  font-weight: 600;
  padding-left: ${rem(56)};
  padding-right: 0;
`;

const ThName = styled(Th)`
  font-weight: 600;
  padding-left: ${rem(16)};
`;

const TableRow = styled(Tr)`
  height: ${rem(64)};
`;

const TdAvatar = styled(Td)`
  padding: 0 !important;
  width: ${rem(64)};
  > a {
    padding: ${rem(8)} ${rem(0)} ${rem(8)} ${rem(16)};
    display: block;
  }
`;

const TdMemberTypes = styled(Td)`
  padding: 0;
  > a {
    display: block;
    padding: ${rem(21)} 0 ${rem(21)} ${rem(56)};
    :hover {
      text-decoration: none;
    }

    & > span:not(:first-child) {
      margin-left: ${rem(4)};
    }
  }
`;

const MemberName = styled.span`
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  font-weight: 600;
`;

const MemberLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const mapDispatchToProps = {
  fetchCodeList,
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadMembers: thunkToAction(loadMembers),
};

const NoResultsFound = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.app.secondaryTextColor};
`;

const TdName = styled(Td)`
  width: 1%;
  white-space: nowrap;
  padding: 0;
  > a {
    padding: ${rem(22)} 0 ${rem(22)} ${rem(16)};
  }
`;

const TdCustom = styled(Td)`
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  width: 1%;
  white-space: nowrap;
  padding: 0;

  > a {
    padding: ${rem(22)} 0 ${rem(22)} ${rem(56)};
  }
`;

const mapStateToProps = (state: RootState) => ({
  members: searchedMemberSelector(state),
  searchResultNextOffset: getListNextOffset(config.LIST_SEARCH_MEMBERS)(state),
  isFetchingUsers: isCommiting(config.LIST_SEARCH_MEMBERS)(state) || false,
  sportCompetenceTypes: codeListDataSelector(
    state,
    'sport-org-competence-type',
  ),
  sportExpertCompetenceTypes: codeListDataSelector(
    state,
    'sport-expert-competence-type',
  ),
});

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps;

type Member = AsyncReturnType<typeof CoreApi['organizationPPOUser']>;

const QUERY_HOC_CONFIG = {
  parameters: {
    page: PageParam(1),
    q: StringParam(''),
  },
};

const SearchMembers: React.FC<Props> = ({
  fetchCodeList,
  initializeOrSetListParams,
  isFetchingUsers,
  loadMembers,
  location: { search, pathname },
  members,
  router,
  searchResultNextOffset,
  sportCompetenceTypes,
  sportExpertCompetenceTypes,
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);

  const [inputFocused, setInputFocused] = React.useState(false);

  const inputFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setTimeout(() => {
      setInputFocused(true);
    }, 0);
  };

  const { query, setQuery } = useQuery(
    search,
    (serializedQuery) => router.push(`${pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG,
  );

  const cleanedQuery = React.useMemo(() => {
    return query.q ? decodeURIComponent(query.q).replace(/\+/g, ' ') : '';
  }, [query.q]);

  React.useEffect(() => {
    setSearchValue(cleanedQuery);
    if (searchInputRef) {
      searchInputRef?.current?.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useAsyncData(async () => {
    await Promise.all([
      fetchCodeList({ codeListID: 'sport-org-competence-type' }),
      fetchCodeList({ codeListID: 'sport-expert-competence-type' }),
    ]);
  }, [fetchCodeList]);

  useAsyncData(async () => {
    initializeOrSetListParams({
      listName: config.LIST_SEARCH_MEMBERS,
      params: {
        q: cleanedQuery,
        limit: config.LIST_SEARCH_MEMBERS_LIMIT,
        page: query.page,
      },
    });
    if (!cleanedQuery) {
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    await loadMembers({});
  }, [initializeOrSetListParams, loadMembers, cleanedQuery, query.page]);

  const getBio = (member: Member) => {
    // filtrovanie podla futbalsfz.sk mam v akcii (pozri utility fn `reduceListMembersProps`)
    return member.bio && member.bio.length ? member.bio[0] : null;
  };

  const getRegNr = (member: Member) => {
    // filtrovanie podla futbalsfz.sk mam v akcii (pozri utility fn `reduceListMembersProps`)
    return member.regnrs && member.regnrs.length
      ? (member.regnrs[0] as any).regnr
      : null;
  };

  const getProfilePhotoUrl = (member: Member) => {
    const bio = getBio(member);
    if (bio && bio.photo) {
      return getMediaManagerUrl(bio.photo, 100, 0, 'resize', true);
    }
    if (member.photo && member.photo?.public && member.photo?.public_url) {
      return member.photo?.public_url;
    }
    return null;
  };

  const getUniqueCompetenceTypes = (orgs: ISportOrg[] | ISportExpertOrg[]) => {
    return (orgs || []).reduce((acc, org) => {
      if (org.competence_type && !acc.includes(org.competence_type)) {
        acc.push(org.competence_type);
      }
      return acc;
    }, [] as string[]);
  };

  const getMemberTypeStringValues = (
    types: string[],
    codeList: Readonly<{ items: Codelist['codelist'] }>,
  ) => {
    return types.map((type) => {
      const c = codeList?.items?.find((clItem) => clItem.value === type);
      if (c) {
        return type === 'player' ? 'Hráč' : c.label;
      }
      return type;
    });
  };

  const getMemberTypes = (member: Member) => {
    // vytiahni unikatne typy zo `sport_orgs`
    const orgCompetenceTypes = getUniqueCompetenceTypes(
      member.sport_orgs || [],
    );

    // human-readable interpretacia z ciselnika
    const orgCompetenceTypesStr = getMemberTypeStringValues(
      orgCompetenceTypes,
      sportCompetenceTypes,
    );

    // vytiahni unikatne typy zo `sport_orgs_expert`
    const expertCompetenceTypes = getUniqueCompetenceTypes(
      member.sport_expert_orgs || [],
    );

    // human-readable interpretacia z ciselnika
    const expertCompetenceTypesStr = getMemberTypeStringValues(
      expertCompetenceTypes,
      sportExpertCompetenceTypes,
    );

    return [...orgCompetenceTypesStr, ...expertCompetenceTypesStr];
  };

  const onSearchSubmit = () => {
    setQuery({ page: 1, q: searchValue });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const onSearchClear = () => {
    setSearchValue('');
    setQuery({ page: 1, q: '' });
  };

  const renderResults = () => {
    return (
      <ResultsWrapper>
        {members && members.length > 0 ? (
          <>
            <Table>
              <Thead>
                <Tr>
                  <CustomTh />
                  <ThName>{__('Meno')}</ThName>
                  <CustomTh>
                    {__('Rok')}&nbsp;{__('nar.')}
                  </CustomTh>
                  <CustomTh>
                    {__('Reg.')}&nbsp;{__('číslo')}
                  </CustomTh>
                  <CustomTh>{__('Typ')}</CustomTh>
                </Tr>
              </Thead>
              <Tbody>
                {members.map((member: any) => {
                  return (
                    <TableRow key={member._id}>
                      <TdAvatar>
                        <MemberLink
                          to={`${getMemberBaseUrl({
                            clen: getRegNr(member),
                          })}`}
                        >
                          <Avatar
                            src={getProfilePhotoUrl(member)}
                            alt={getPPOUserFullName(member)}
                            width={48}
                            withBorder={false}
                          />
                        </MemberLink>
                      </TdAvatar>
                      <TdName>
                        <MemberLink
                          to={`${getMemberBaseUrl({
                            clen: getRegNr(member),
                          })}${search}`}
                        >
                          <MemberName>{getPPOUserFullName(member)}</MemberName>
                        </MemberLink>
                      </TdName>
                      <TdCustom>
                        <MemberLink
                          to={`${getMemberBaseUrl({
                            clen: getRegNr(member),
                          })}${search}`}
                        >
                          {member.birthyear}
                        </MemberLink>
                      </TdCustom>
                      <TdCustom>
                        <MemberLink
                          to={`${getMemberBaseUrl({
                            clen: getRegNr(member),
                          })}${search}`}
                        >
                          {getRegNr(member)}
                        </MemberLink>
                      </TdCustom>
                      <TdMemberTypes>
                        <MemberLink
                          to={`${getMemberBaseUrl({
                            clen: getRegNr(member),
                          })}${search}`}
                        >
                          {(getMemberTypes(member) || []).map((type, idx) => (
                            <MemberType key={`${member}-${idx}`}>
                              {type}
                            </MemberType>
                          ))}
                        </MemberLink>
                      </TdMemberTypes>
                    </TableRow>
                  );
                })}
              </Tbody>
            </Table>
            <PositionedNextPrevPaginator
              isPageLast={!searchResultNextOffset}
              loading={isFetchingUsers}
              page={query.page}
            />
          </>
        ) : (
          <>
            {!isFetchingUsers ? (
              <>
                {isSearching && (
                  <NoResultsFound>
                    {__('Nenašli sa žiadne výsledky')}
                  </NoResultsFound>
                )}
              </>
            ) : (
              <NoResultsFound>
                <Loader size="l" />
              </NoResultsFound>
            )}
          </>
        )}
      </ResultsWrapper>
    );
  };

  return (
    <>
      <AdManager
        site="futbalnet_clen"
        siteId={`search`}
        origin="futbalnet"
        pagetype="other"
      />
      <RempIntegration destroy />
      <FutbalnetHomeHeader />
      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <StyledMaxWidthBox className="sptn-main-content">
        <SearchInput
          value={searchValue}
          setValue={setSearchValue}
          inputFocused={inputFocused}
          setInputFocused={setInputFocused}
          inputFocus={inputFocus}
          inputRef={searchInputRef}
          placeholder={__('Zadajte meno alebo registračné číslo')}
          isFetching={isFetchingUsers}
          onKeyDown={handleKeyDown}
          onSearchClear={onSearchClear}
        />
       
      </StyledMaxWidthBox>

      <ListBodyRespAd name="list_body_1" className="advertisement-margin-24px" />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>{renderResults()}</SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      <FutbalnetUnionBreadcrumbs
        crumbs={[
          {
            label: 'Členovia',
            url: '/futbalnet/clenovia/',
          },
        ]}
      />
    </>
  );
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(SearchMembers),
);
