import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 26 26"
  >
    <path
      fill={color}
      d="M20.3,21.3c0.3,0.5,0.1,1.1-0.4,1.4C18.4,23.6,16.7,24,15,24c-1.7,0-3.4-0.4-4.9-1.3c-2.2-1.3-3.9-3.3-4.6-5.7
    H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h1C5,14.3,5,13.7,5,13H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.2-0.7,0.5-1.3,0.8-1.9
    c1.7-2.9,4.6-4.7,7.7-5v2c-2.4,0.3-4.7,1.7-6,4c-0.2,0.3-0.3,0.6-0.4,0.9H17c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.1c-0.1,0.7-0.1,1.3,0,2
    H17c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.6c0.7,1.7,1.9,3.1,3.5,4c2.4,1.4,5.4,1.4,7.9,0C19.4,20.7,20,20.9,20.3,21.3z M24,5h-3V2h-2v3h-3
    v2h3v3h2V7h3V5z"
    />
  </svg>
);
