import { mb } from '@sportnet/ui/lib/Themes/utilities';
import ContentContext, {
  IContentContext,
} from '@sportnet/content/common/ContentContext';
import { IWidget } from '@sportnet/content/utilities/library';
import { getTheme } from '@sportnet/content/theme/theme';
import Content from '@sportnet/content/view';
import { rem } from 'polished';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import SDWidgets from '../../containers/StructuredData/SDWidgets';
import { IWidgets } from '../../library/App';
import styled, { IThemeProps, withTheme } from '../../theme/styled-components';
import ContentContextHelpers from './ContentContextHelpers';
import Overrides from './Overrides';
import renderLink from './renderLink';
import StyleOverrides from './StyleOverrides';
import useWidgetAdInjector from './useWidgetAdInjector';
import WidgetsContext, { IWidgetsContext } from './WidgetsContext';

const SpacedContent = styled(Content)`
  position: relative;
  font-family: pt-serif-pro, serif;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(17)};
  line-height: 1.5;
  font-weight: normal;
  ${mb('s')} {
    font-size: ${rem(19)};
  }
  margin: 0 auto;
  ${mb('l')} {
    max-width: ${rem(680)};
  }
  img {
    max-width: 100%;
  }
`;

type IOwnProps = {
  items: IWidgets;
  context?: IContentContext & IWidgetsContext;
  override?: {
    [key: string]: (widget: IWidget) => React.ReactNode;
  };
  className?: string;
};

type IProps = IOwnProps & IThemeProps;

const Widgets: React.FC<IProps> = ({
  items,
  context = {},
  theme,
  override = Overrides,
  className,
}) => {
  const contentTheme = React.useMemo(() => {
    return getTheme({
      app: {
        bgColor: theme.app.bgColor,
        maxWidth: 992,
        padding: 0,
        textColor: theme.app.textColor,
      },
      color: {
        separator: theme.color.separator,
      },
    } as any);
  }, [theme]);

  useWidgetAdInjector(items);

  return (
    <ThemeProvider theme={contentTheme}>
      <WidgetsContext.Provider value={context}>
        <ContentContext.Provider
          value={{ ...context, helpers: ContentContextHelpers }}
        >
          <StyleOverrides>
            <SpacedContent
              className={className}
              items={items}
              renderLink={renderLink}
              override={override}
            />
            <SDWidgets items={items} />
          </StyleOverrides>
        </ContentContext.Provider>
      </WidgetsContext.Provider>
    </ThemeProvider>
  );
};

export default withTheme(Widgets);
