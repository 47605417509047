import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20,2H4C2.3,2,1,3.3,1,5v10c0,1.7,1.3,3,3,3h7v2H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3
    v-2h7c1.7,0,3-1.3,3-3V5C23,3.3,21.7,2,20,2z M21,15c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h16c0.6,0,1,0.4,1,1V15z"
    />
  </svg>
);
