import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link, LinkProps, withRouter } from 'react-router';
import { RouteProps } from '../../../library/App';
import styled, { css } from '../../../theme/styled-components';
import Icon from '../../Icon';
import { IMenuItem } from './types';

const Wrapper = styled.li`
  margin: 0;
  &:not(:last-child) {
    padding-right: ${rem(4)};
  }
  height: ${rem(40)};
  ${mb('s')} {
    height: ${rem(48)};
  }
`;

const itemStyles = css`
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 0 ${rem(16)};
  font-weight: bold;
  height: 100%;
  border-radius: ${rem(8)} ${rem(8)} 0 0;
  background-color: #f9f9f9;
  letter-spacing: ${rem(0.88)};
  border: ${rem(1)} solid #f9f9f9;
  border-bottom: none;
  font-family: inherit;
  color: inherit;
  outline: none;
  transition: background-color 150ms linear, border-color 150ms linear;
  position: relative;

  font-size: ${rem(11)};
  line-height: ${rem(14.4)};
  text-transform: uppercase;
`;

const activeItemStyles = css`
  background-color: #fff;
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  border-bottom: none;
  z-index: 12;
`;

interface ItemLinkProps extends LinkProps {
  active: boolean;
}
const ItemLink = ({ active, ...rest }: ItemLinkProps) => <Link {...rest} />;
const LinkLabel = styled(ItemLink)<ItemLinkProps>`
  ${itemStyles}

  ${({ active }) => active && activeItemStyles}
  &.active {
    ${activeItemStyles}
  }
`;

const Label = styled.button<{ active: boolean }>`
  ${itemStyles}
  ${({ active }) => active && activeItemStyles}
`;

const ChevronIcon = styled(Icon)`
  margin-left: ${rem(6)};
`;

interface OwnProps {
  item: IMenuItem;
  onClick: (item: IMenuItem) => void;
  active: boolean;
  isAnyActive: boolean;
}

type Props = OwnProps & RouteProps;

const MenuItem: React.FC<Props> = ({
  item,
  onClick,
  active,
  isAnyActive,
  router,
}) => {
  const wrapperRef = React.useRef<null | HTMLLIElement>(null);
  const routeActive =
    active ||
    (item.url && !item.hash ? router.isActive(item.url, true) : false);

  React.useEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (routeActive && wrapperEl) {
      try {
        wrapperEl.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      } catch (e: any) {
        wrapperEl.scrollIntoView(false);
      }
    }
  }, [routeActive]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (item.renderDropdown) {
      e.preventDefault();
    }
    e.stopPropagation(); // stops react event listeners
    e.nativeEvent.stopImmediatePropagation(); // stops native event from bubbling to handlers registered with document.addEventListeners
    onClick(item);
  };

  const labelComp =
    typeof item.label === 'string' ? (
      <>
        {item.label}
        {!!item.renderDropdown && <ChevronIcon name="chevron" size={16} />}
      </>
    ) : (
      item.label()
    );

  return (
    <Wrapper ref={wrapperRef}>
      {item.url ? (
        <LinkLabel
          to={item.url}
          activeClassName={isAnyActive || item.hash ? void 0 : 'active'}
          active={active}
          onClick={handleClick}
          onlyActiveOnIndex
        >
          {labelComp}
        </LinkLabel>
      ) : (
        <Label active={active} onClick={handleClick}>
          {labelComp}
        </Label>
      )}
    </Wrapper>
  );
};

export default withRouter(React.memo(MenuItem)) as React.FC<OwnProps>;
