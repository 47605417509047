import * as React from 'react';


export default (size: string, color: string)  => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116.49 63.54"
    height="100%"
  >
    <path
      fill={color}
      d="m99.59,41.82c.46-3.9,4.11-17.31,7.83-17.31,1.11,0,1.76,1.1,1.76,3.31,0,5.34-4.96,13.23-9.59,14m-1.3,10.27c0-2.29.59-5.85.91-7.13,5.94-1.44,17.29-5.26,17.29-15.69,0-5.01-3-7.47-6.53-7.47-10.57,0-20.49,14.08-20.49,27.66,0,8.23,3.39,14.08,10.05,14.08,5.94,0,10.05-4.67,13.83-10.01l-1.31-1.87c-2.42,3.05-5.42,5.94-8.94,5.94-4.05,0-4.83-3.39-4.83-5.51m-71.46,2.97c0-.59.13-1.53.33-2.54l4.11-18.16c.26-1.02.72-3.73.72-5.09,0-4.16-2.09-7.47-5.42-7.47-4.83,0-10.25,5.94-13.9,15.86l-.13-.17,3.52-14.76-.65-.93c-3.26,1.78-9.14,2.38-12.53,2.38l-.33,2.29c2.74,0,3.79.17,3.79,2.04,0,.93-.2,3.05-.52,4.58L0,62.44h8.88l3-13.91c1.5-6.87,5.22-19.51,9.66-19.51.91,0,1.63.51,1.63,2.21,0,.68-.13,1.53-.39,2.63l-3.59,16.12c-.65,2.97-1.18,5.85-1.18,7.55,0,3.9,1.57,6.02,3.85,6.02,5.29,0,8.35-6.62,11.16-11.45l-1.37-1.27c-.65,1.27-2.81,5.51-4.18,5.51-.46,0-.65-.34-.65-1.27m25.29-33.26c-3.26,1.78-9.14,2.38-13.38,2.38l-.33,2.29c2.87.25,3.85.25,3.85,1.95,0,.85-.26,3.05-.65,4.67l-3.98,18.07c-.59,2.8-.91,5.01-.91,6.79,0,3.99,1.43,5.6,3.85,5.6,5.29,0,8.68-6.28,11.22-11.45l-1.37-1.1c-.98,1.78-2.68,5.34-4.24,5.34-.46,0-.65-.34-.65-1.02,0-.34.07-.68.13-1.02l7.11-31.39-.65-1.1Zm2.76-11.28c0-3.28-2.06-5.88-4.58-5.88s-4.52,2.6-4.52,5.88,2,5.96,4.52,5.96,4.58-2.67,4.58-5.96m29.46,18.66c2.15-2.12,4.18-3.99,5.68-3.99v-2.29h-13.05l-.2,2.29c1.63,0,3.26.93,3.26,2.55,0,1.53-1.31,4.07-5.48,7.97l-7.24,7.13-.13-.17L76.17,1.1l-.72-1.1c-3.85,2.12-9.07,2.29-12.99,2.55l-.33,2.29c3.07.08,3.59.43,3.59,1.95,0,.85-.07,2.03-.65,4.67l-11.03,50.99h8.81l3.39-15.53,2.87-2.46c2.28,15.27,4.11,19.09,8.94,19.09,5.29,0,7.38-4.67,9.07-10.35l-1.37-.93c-.65,1.78-1.37,4.07-3.13,4.07-1.96,0-3.13-1.53-5.81-19.26l7.51-7.89Zm31.28-17.62c.44-1.78-.25-3.67-2.05-4.43-1.12-.47-2.79-.11-4.14,1.19l-11.67,8.27,2.92,1.23,11.85-2.93c1.3-.25,2.57-1.23,3.1-3.33"
    />
  </svg>
);

