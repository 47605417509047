import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C16,14.2,14.2,16,12,16z M12,10c-1.1,0-2,0.9-2,2
    c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2C14,10.9,13.1,10,12,10z M12.1,24c-1.7,0-3-1.3-3-3c0-0.3-0.2-0.6-0.4-0.7
    c-0.3-0.1-0.6-0.1-0.8,0.1c-1.2,1.2-3.1,1.2-4.3,0.1c-0.6-0.6-0.9-1.3-0.9-2.1s0.3-1.6,0.9-2.1c0.2-0.2,0.2-0.3,0.2-0.5
    c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2H3c-1.7,0-3-1.3-3-3s1.3-3,3-3c0.3,0,0.6-0.2,0.7-0.4c0.1-0.3,0.1-0.6-0.1-0.8
    C2.3,6.7,2.3,4.8,3.5,3.6s3.1-1.2,4.2,0C8,3.8,8.3,3.9,8.5,3.8c0.1,0,0.1,0,0.2-0.1C8.9,3.6,9,3.4,9,3.2V3c0-1.7,1.3-3,3-3
    s3,1.3,3,3c0,0.3,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0,0,0,0,0,0c0.2,0,0.3-0.1,0.5-0.2c1.2-1.2,3.1-1.2,4.3,0
    c1.2,1.2,1.2,3.1,0,4.2c-0.2,0.2-0.3,0.5-0.2,0.8c0,0.1,0,0.1,0.1,0.2C20.4,8.9,20.6,9,20.8,9H21c1.7,0,3,1.3,3,3s-1.3,3-3,3
    c-0.3,0-0.6,0.2-0.7,0.4c0,0,0,0,0,0c-0.1,0.2-0.1,0.5,0.1,0.7c1.2,1.2,1.2,3.1,0.1,4.3s-3.1,1.2-4.2,0c-0.2-0.2-0.3-0.2-0.5-0.2
    c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5V21C15.1,22.7,13.7,24,12.1,24z M8.3,18.3c0.4,0,0.7,0.1,1.1,0.2
    c1,0.4,1.6,1.3,1.7,2.4c0,0,0,0.1,0,0.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-0.2c0-0.7,0.3-1.3,0.8-1.8c0.5-0.5,1.2-0.8,1.9-0.8
    c0.7,0,1.4,0.3,1.9,0.8c0,0,0.1,0.1,0.1,0.1c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4c-0.8-0.8-1-2-0.6-3c0.4-1,1.4-1.6,2.4-1.6
    c0.6,0,1.1-0.4,1.1-1s-0.4-1-1-1h-0.2c-1.1,0-2-0.6-2.4-1.6c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.9-0.1-2,0.6-2.7c0.5-0.5,0.5-1.1,0.1-1.5
    c-0.4-0.4-1-0.4-1.4,0c0,0-0.1,0.1-0.1,0.1c-0.5,0.5-1.1,0.7-1.8,0.8c-0.7,0-1.4-0.3-1.9-0.8C13.3,4.5,13,3.8,13,3.1
    C13,2.4,12.6,2,12,2s-1,0.4-1,1v0.2c0,1.1-0.6,2-1.6,2.4c-0.1,0-0.2,0.1-0.3,0.1C8.2,6,7.1,5.8,6.4,5.1C5.9,4.6,5.3,4.6,4.9,5
    c-0.4,0.4-0.4,1,0,1.4c0.8,0.8,1,2,0.6,3c-0.4,1-1.3,1.6-2.4,1.7c0,0-0.1,0-0.1,0c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.2
    c0.7,0,1.3,0.3,1.8,0.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.3,0.4-0.3,0.7
    s0.1,0.5,0.3,0.7c0.4,0.4,1,0.4,1.4,0C7,18.5,7.7,18.3,8.3,18.3z M19.4,15L19.4,15L19.4,15z"
    />
  </svg>
);
