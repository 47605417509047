import { rem } from 'polished';
import styled from '../../../theme/styled-components';
import Link from '../../Link';

export default styled(Link)`
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-weight: 400;
  word-break: break-word;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  margin: 0 0 ${rem(16)} 0;
  padding: 0;
  border: none;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  background: none;
  display: inline-block;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;
