import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import FutbalnetTitle from '../../components/FutbalnetHeader/Title';
import { RootState } from '../../configureStore';
import CompetitionArchiveDropdown from '../../containers/CompetitionArchiveDropdown';
import { RouteProps } from '../../library/App';
import styled from '../../theme/styled-components';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import __ from '../../utilities/__';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import {
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from './selectors';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import config from '../../config';

const CompetitionTitle = styled.div`
  display: flex;
  align-items: center;
  > img {
    flex: 0 0 auto;
    margin-right: ${rem(8)};
    max-height: ${rem(32)};
    max-width: ${rem(56)};
    ${mb('s')} {
      margin-right: ${rem(12)};
      max-width: ${rem(80)};
    }
  }
`;

const SeasonName = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.color.fadedText};
`;

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitionPart: currentCompetitionPartSelector(state)
});

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string; sutaz: string }>;

const CompetitionHeader: React.FC<IProps> = ({
  competition,
  params: { zvaz, sutaz },
  competitionPart,
}) => {
  const competitionBaseUrl = React.useMemo(() => {
    return getCompetitionBaseUrl({
      zvaz,
      sutaz,
    });
  }, [zvaz, sutaz]);

  return (
    <>
      <FutbalnetHomeHeader />
      <FutbalnetHeader>
        <FutbalnetTitleBlock>
          <CompetitionTitle>
            {competition?.publication?.logo?.media_url && (
              <img
                src={getMediaManagerUrl(competition?.publication?.logo, 0, 64)}
                alt={competition?.name || ''}
              />
            )}
            <FutbalnetTitle>
              {competition?.name}
              {competition?.season?.name && (
                <SeasonName> ({competition.season.name})</SeasonName>
              )}
            </FutbalnetTitle>
          </CompetitionTitle>
        </FutbalnetTitleBlock>
        <FutbalnetMenu
          items={[
            {
              id: 'overview',
              label: __('Prehľad'),
              url: `${competitionBaseUrl}`,
            },
            {
              id: 'results',
              label: __('Výsledky'),
              url: `${competitionBaseUrl}vyhodnotenie/`,
            },
            {
              id: 'program',
              label: __('Program'),
              url: `${competitionBaseUrl}program/`,
            },
            {
              id: 'tables',
              label:
                competition &&
                  (config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
                    competition._id,
                  )
                  // competitionPart && competitionPart.format === 'draw'
                  ? __('Pavúk')
                  : __('Tabuľky'),
              url: `${competitionBaseUrl}tabulky/`,
            },
            {
              id: 'statistics',
              label: __('Štatistiky'),
              url: `${competitionBaseUrl}statistiky/`,
            },
            {
              id: 'clubs',
              label: __('Kluby'),
              url: `${competitionBaseUrl}kluby/`,
            },
            {
              id: 'archive',
              label: __('Archív'),
              renderDropdown: (dropdownProps) => {
                return <CompetitionArchiveDropdown {...dropdownProps} />;
              },
            },
          ]}
        />
      </FutbalnetHeader>
    </>
  );
};

export default compose(
  React.memo,
  withRouter,
  connect(mapStateToProps),
)(CompetitionHeader) as unknown as React.FC<IOwnProps>;
