import * as React from 'react';
import { IndexRoute, Redirect, Route, Router } from 'react-router';
import Authorization from './containers/Authorization';
import Page from './containers/Page';
import App from './pages/App';
import Club from './pages/Club';
import ClubDetail from './pages/Club/detail';
import ClubDisclosures from './pages/Club/Disclosures';
import ClubFixtures from './pages/Club/Fixtures';
import ClubOverview from './pages/Club/Overview';
import ClubResults from './pages/Club/Results';
import ClubSquad from './pages/Club/Squad';
import ClubTables from './pages/Club/Tables';
import Comittee from './pages/Commitee';
import ResolutionDetail from './pages/Commitee/ResolutionDetail';
import CompetitionGroup from './pages/Competition';
import CompetitionClubs from './pages/Competition/Clubs';
import Competition from './pages/Competition/competition';
import CompetitionDetail from './pages/Competition/detail';
import CompetitionFixtures from './pages/Competition/Fixtures';
import CompetitionPageWrapper from './pages/Competition/pageWrapper';
import CompetitionResults from './pages/Competition/Results';
import CompetitionStatistics from './pages/Competition/Statistics';
import CompetitionTables from './pages/Competition/Tables';
import DomainResolver from './pages/DomainResolver';
import FutbalnetApp from './pages/FutbalnetApp';
import FutbalnetHome from './pages/FutbalnetHome';
import FutbalnetSection from './pages/FutbalnetSection';
import FutbalnetUrlMap from './pages/FutbalnetUrlMap';
import Match from './pages/Match';
import MatchDetail from './pages/Match/detail';
import MatchLive from './pages/Match/Live';
import MatchVideo from './pages/Match/Video';
import Member from './pages/Member';
import MemberDetail from './pages/Member/detail';
import SearchMembers from './pages/SearchMembers';
import Union from './pages/Union';
import UnionArticles from './pages/Union/Articles';
import UnionDetail from './pages/Union/detail';
import UnionOverview from './pages/Union/Overview';
import FutbalnetNotFound from './pages/FutbalnetNotFound';
import Transfermarket from './pages/Transfers';
import CompetitionMatchesPrint from './pages/FutbalnetPrint/CompetitionMatchesPrint';
import TeamMatchesPrint from './pages/FutbalnetPrint/TeamMatchesPrint';
import CompetitionStandingPrint from './pages/FutbalnetPrint/CompetitionStandingPrint';
import MatchDetailPrint from './pages/FutbalnetPrint/MatchDetailPrint';
import ClubStandingPrint from './pages/FutbalnetPrint/ClubStandingPrint';
import Gossips from './pages/Gossips';
import GossipSection from './pages/Gossips/detail';

const Routes: React.FC<any> = (props) => {
  return (
    <Router {...props}>
      <Redirect from="/futbalnet/zvaz/:zvaz/*" to="/futbalnet/z/:zvaz/*" />
      <Route component={DomainResolver}>
        <Route component={Authorization}>
          {/* PRINTS - zoznam zapasov sutaze */}
          <Route
            path="/futbalnet/z/:zvaz/s/:sutaz/part/:competitionPartId/print"
            component={CompetitionMatchesPrint}
          />
          {/* PRINTS - zoznam zapasov timu */}
          <Route
            path="/futbalnet/k/:klub/tim/:tim/print"
            component={TeamMatchesPrint}
          />
          {/* PRINTS - tabulka sutaze */}
          <Route
            path="/futbalnet/z/:zvaz/s/:sutaz/part/:competitionPartId/tabulky/print"
            component={CompetitionStandingPrint}
          />
          {/* PRINTS - tabulka klubu */}
          <Route
            path="/futbalnet/k/:klub/tim/:tim/z/:zvaz/s/:sutaz/part/:competitionPartId/tabulky/print"
            component={ClubStandingPrint}
          />
          {/* PRINTS - detail zapasu */}
          <Route
            path="/futbalnet/z/:zvaz/zapas/:matchId/print"
            component={MatchDetailPrint}
          />
          <Route component={App}>
            <Route component={Page}>
              <Redirect from="/" to="/futbalnet" />
              <Route path="/futbalnet" component={FutbalnetApp}>
                <IndexRoute component={FutbalnetHome} />
                {/* Transfermarket */}
                <Route path="prestupy-hostovania" component={Transfermarket} />
                {/* Member */}
                <Route path="clenovia" component={SearchMembers} />
                <Route path="clen/:id" component={Member}>
                  <Route path="" component={MemberDetail}>
                    <IndexRoute component={() => null} />
                    <Route path="prestupy" component={() => null} />
                    <Route path="statistiky" component={() => null} />
                  </Route>
                </Route>
                {/* CLUB */}
                <Route path="k/:klub" component={Club}>
                  <Route path="" component={ClubDetail}>
                    <IndexRoute component={ClubOverview} />
                    <Route path="vysledky" component={ClubResults} />
                    <Route path="program" component={ClubFixtures} />
                    <Route path="tabulky" component={ClubTables} />
                    <Route path="zverejnovanie" component={ClubDisclosures} />
                    <Route path="hraci" component={ClubSquad} />
                    <Route path="spravy/*" component={FutbalnetUrlMap} />
                    <Route path="sekcia/:id" component={FutbalnetSection} />
                  </Route>
                </Route>
                {/* Team */}
                <Route path="k/:klub/tim/:tim" component={Club}>
                  <Route path="" component={ClubDetail}>
                    <IndexRoute component={ClubResults} />
                    <Route path="program" component={ClubFixtures} />
                    <Route path="tabulky" component={ClubTables} />
                    <Route path="zverejnovanie" component={ClubDisclosures} />
                    <Route path="hraci" component={ClubSquad} />
                    <Route path="spravy/*" component={FutbalnetUrlMap} />
                    <Route path="sekcia/:id" component={FutbalnetSection} />
                  </Route>
                </Route>
                {/* COMPETITION */}
                <Route path="z/:zvaz/s/:sutaz" component={CompetitionGroup}>
                  <Route path="" component={Competition}>
                    <Route path="" component={CompetitionPageWrapper}>
                      <IndexRoute component={CompetitionDetail} />
                      <Route path="tabulky" component={CompetitionTables} />
                      <Route
                        path="vyhodnotenie"
                        component={CompetitionResults}
                      />
                      <Route path="program" component={CompetitionFixtures} />
                      <Route
                        path="statistiky"
                        component={CompetitionStatistics}
                      />
                      <Route path="kluby" component={CompetitionClubs} />
                    </Route>
                  </Route>
                </Route>
                {/* MATCH */}
                <Route path="z/:zvaz/zapas/:zapas" component={Match}>
                  <Route path="" component={MatchDetail}>
                    <IndexRoute component={() => null} />
                    <Route path="zostavy" component={() => null} />
                    <Route path="tabulka" component={() => null} />
                    <Route path="videozaznam" component={MatchVideo} />
                    <Route path="h2h" component={() => null} />
                    <Route path="live" component={MatchLive} />
                  </Route>
                </Route>
                {/* UNION */}
                <Route path="z/:zvaz" component={Union}>
                  <Route path="" component={UnionDetail}>
                    <IndexRoute component={UnionOverview} />
                    <Route path="clanky" component={UnionArticles} />
                    <Route path="spravy/*" component={FutbalnetUrlMap} />
                    <Route path="sekcia/:id" component={FutbalnetSection} />
                    <Route path="komisia/:comiteeId" component={Comittee} />
                    <Route
                      path="komisia/:comiteeId/rozhodnutie/:seasonId/:resolutionNumber"
                      component={ResolutionDetail}
                    />
                  </Route>
                </Route>
                <Route path="pikosky" component={Gossips}></Route>
                <Route
                  path="pikosky/:id/(:slug)"
                  component={GossipSection}
                ></Route>
              </Route>
              <Route path="*" component={FutbalnetNotFound} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
