import React from 'react';
import { connect } from 'react-redux';
import config from '../../../config';
import { IArticle } from '../../../library/App';
import { putArticleStats } from '../actions';

type IOwnProps = {
  article: IArticle;
  appSpace?: string;
};

const mapDispatchToProps = {
  putArticleStats,
};

type IProps = IOwnProps & typeof mapDispatchToProps;

const SportnetTracker: React.FC<IProps> = ({
  article,
  appSpace,
  putArticleStats,
}) => {
  const articleId = article._id!;

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    const timeoutID = window.setTimeout(() => {
      putArticleStats({ id: articleId, appSpace });
    }, config.ARTICLE_STATS_DELAY);
    return () => {
      window.clearTimeout(timeoutID);
    };
  }, [putArticleStats, articleId, appSpace]);

  return null;
};

export default React.memo(connect(null, mapDispatchToProps)(SportnetTracker));
