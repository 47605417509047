export interface ParsedData {
  issfSutazId: number;
  sutazID: string;
  sutazName: string;
  sutazRocnik: string;
  issfTeamId: number;
  teamId: string;
  teamDisplayName: string;
  nikeSutazId: number;
  nikeTeamID: number;
}

export const NIKE_MAP: ParsedData[] = [
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 61938,
    teamId: "66604457f8f64d2a889579ca",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 9027,
    nikeTeamID: 232235,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 63056,
    teamId: "666ebd2528584ce5cef7ff0f",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 9027,
    nikeTeamID: 232237,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 63521,
    teamId: "6671377928584ce5ce883416",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 9027,
    nikeTeamID: 54549,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62380,
    teamId: "6666db0c9d0d9c12e92c564b",
    teamDisplayName: "FC TATRAN Prešov",
    nikeSutazId: 9027,
    nikeTeamID: 41754,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 67477,
    teamId: "6687bbf58f47d96194f32cd4",
    teamDisplayName: "KFC Komárno futbal",
    nikeSutazId: 9027,
    nikeTeamID: 355461,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62574,
    teamId: "66682bfa9d0d9c12e92f3852",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 9027,
    nikeTeamID: 6873,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62515,
    teamId: "666807599d0d9c12e9f4a9a6",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 9027,
    nikeTeamID: 39361,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62592,
    teamId: "66682c709d0d9c12e92fe378",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 9027,
    nikeTeamID: 23281,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62165,
    teamId: "6661af74f8f64d2a885f5539",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 9027,
    nikeTeamID: 25760,
  },
  {
    issfSutazId: 4879,
    sutazID: "665736e2f8f64d2a8844de8d",
    sutazName: "DEMIŠPORT liga - Ženy",
    sutazRocnik: "2024/2025",
    issfTeamId: 62262,
    teamId: "6664ca1b9d0d9c12e9edc6ee",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 9027,
    nikeTeamID: 25761,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 61926,
    teamId: "66604396f8f64d2a8894743e",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 6013,
    nikeTeamID: 11544,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62404,
    teamId: "666801829d0d9c12e9eb5173",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 6013,
    nikeTeamID: 219587,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 63041,
    teamId: "666ebd9628584ce5cef8dd90",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 6013,
    nikeTeamID: 258089,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 63423,
    teamId: "666ffb0928584ce5ce4d4e09",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 6013,
    nikeTeamID: 3540,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62665,
    teamId: "66697ac69d0d9c12e9006891",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 6013,
    nikeTeamID: 25428,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62998,
    teamId: "666c0720cd3f8c5d104c435f",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 6013,
    nikeTeamID: 290583,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62704,
    teamId: "666ace8ccd3f8c5d1058eaf6",
    teamDisplayName: "FK Poprad",
    nikeSutazId: 6013,
    nikeTeamID: 51062,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62192,
    teamId: "6662cbb020d61d2191f05622",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 6013,
    nikeTeamID: 14218,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62565,
    teamId: "66680f2c9d0d9c12e9008d5e",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 6013,
    nikeTeamID: 11685,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62516,
    teamId: "666806879d0d9c12e9f37217",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 6013,
    nikeTeamID: 12146,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 63113,
    teamId: "666ec04928584ce5cefdda0d",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 6013,
    nikeTeamID: 2539,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62571,
    teamId: "66680f6b9d0d9c12e900ef8e",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 6013,
    nikeTeamID: 11545,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 63017,
    teamId: "666c13c5cd3f8c5d105d934e",
    teamDisplayName: "RSC HAMSIK ACADEMY Banská Bystrica o.z.",
    nikeSutazId: 6013,
    nikeTeamID: 288291,
  },
  {
    issfSutazId: 4872,
    sutazID: "6657350df8f64d2a884195a1",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2024/2025",
    issfTeamId: 62252,
    teamId: "6664c9159d0d9c12e9ebda23",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 6013,
    nikeTeamID: 9849,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63509,
    teamId: "66703d8528584ce5ceb0d35e",
    teamDisplayName: "FC - Žolík Malacky",
    nikeSutazId: 9152,
    nikeTeamID: 26061,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62849,
    teamId: "666acf8ccd3f8c5d105a59ee",
    teamDisplayName: "FC LOKOMOTÍVA KOŠICE",
    nikeSutazId: 9994,
    nikeTeamID: 872,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63409,
    teamId: "667137e228584ce5ce8921af",
    teamDisplayName: "FC Slovan Galanta",
    nikeSutazId: 9152,
    nikeTeamID: 8654,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63448,
    teamId: "66701f1d28584ce5ce836282",
    teamDisplayName: "FK Beluša",
    nikeSutazId: 9152,
    nikeTeamID: 19247,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62550,
    teamId: "6668098a9d0d9c12e9f7e8f7",
    teamDisplayName: "FK Inter Bratislava",
    nikeSutazId: 9152,
    nikeTeamID: 870,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62703,
    teamId: "666ace5dcd3f8c5d10589c9e",
    teamDisplayName: "FK Poprad",
    nikeSutazId: 9994,
    nikeTeamID: 5447,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63349,
    teamId: "666fe26628584ce5ceedda14",
    teamDisplayName: "FK Rača Bratislava",
    nikeSutazId: 9152,
    nikeTeamID: 5445,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62064,
    teamId: "6669e82ecd3f8c5d101e28ba",
    teamDisplayName: "FK Slovan Duslo Šaľa",
    nikeSutazId: 9152,
    nikeTeamID: 70438,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62358,
    teamId: "66682a259d0d9c12e92c3fe9",
    teamDisplayName: "FKM Nové Zámky",
    nikeSutazId: 9152,
    nikeTeamID: 864,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62743,
    teamId: "66697cc1cd3f8c5d10222bf4",
    teamDisplayName: "FTC Fiľakovo",
    nikeSutazId: 9994,
    nikeTeamID: 19562,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62294,
    teamId: "6666ad0e9d0d9c12e9cb0dce",
    teamDisplayName: "Futbalový klub Podkonice",
    nikeSutazId: 9152,
    nikeTeamID: 221933,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62442,
    teamId: "666bf330cd3f8c5d102ed4a6",
    teamDisplayName: "Futbalový klub Spišská Nová Ves",
    nikeSutazId: 9994,
    nikeTeamID: 5449,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67531,
    teamId: "668cfbcdb1444d2ac61d1229",
    teamDisplayName: "MFK Dolný Kubín, s. r. o.",
    nikeSutazId: 9994,
    nikeTeamID: 4506,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62921,
    teamId: "666bdf9ccd3f8c5d10de0bf2",
    teamDisplayName: "MFK Snina",
    nikeSutazId: 9994,
    nikeTeamID: 6804,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62454,
    teamId: "6669e5a9cd3f8c5d1019f40e",
    teamDisplayName: "MFK Vranov nad Topľou",
    nikeSutazId: 9994,
    nikeTeamID: 1492,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62983,
    teamId: "666bff4bcd3f8c5d104064a8",
    teamDisplayName: "MŠK FOMAT Martin",
    nikeSutazId: 9152,
    nikeTeamID: 1231,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61907,
    teamId: "66682ca99d0d9c12e93046cd",
    teamDisplayName: "MŠK NOVOHRAD Lučenec",
    nikeSutazId: 9994,
    nikeTeamID: 19563,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63320,
    teamId: "666f4a1928584ce5ce41cae9",
    teamDisplayName: "MŠK Námestovo",
    nikeSutazId: 9994,
    nikeTeamID: 10096,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62392,
    teamId: "666bf330cd3f8c5d102ed499",
    teamDisplayName: "MŠK Rimavská Sobota",
    nikeSutazId: 9994,
    nikeTeamID: 2244,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63205,
    teamId: "666ed53428584ce5ce5e7bec",
    teamDisplayName: "MŠK Spišské Podhradie",
    nikeSutazId: 9994,
    nikeTeamID: 26156,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62368,
    teamId: "6666da039d0d9c12e92a4158",
    teamDisplayName: "MŠK Tesla Stropkov",
    nikeSutazId: 9994,
    nikeTeamID: 4505,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62535,
    teamId: "6668079c9d0d9c12e9f50f63",
    teamDisplayName: "OFK Baník Lehota pod Vtáčnikom",
    nikeSutazId: 9152,
    nikeTeamID: 29908,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62853,
    teamId: "666ab701cd3f8c5d10365863",
    teamDisplayName: "OK Častkovce",
    nikeSutazId: 9152,
    nikeTeamID: 19250,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62090,
    teamId: "666bdebbcd3f8c5d10dcd75e",
    teamDisplayName: "Partizán Bardejov BŠK",
    nikeSutazId: 9994,
    nikeTeamID: 7798,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63014,
    teamId: "666c13a7cd3f8c5d105d5a5a",
    teamDisplayName: "RSC HAMSIK ACADEMY Banská Bystrica o.z.",
    nikeSutazId: 9152,
    nikeTeamID: 288291,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62863,
    teamId: "666bde4bcd3f8c5d10dc356b",
    teamDisplayName: "Slávia TU Košice",
    nikeSutazId: 9994,
    nikeTeamID: 32001,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62575,
    teamId: "66680eb89d0d9c12e9ffe7ed",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 9152,
    nikeTeamID: 1860,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62698,
    teamId: "66697be89d0d9c12e901ebc5",
    teamDisplayName: "TJ Baník Kalinovo",
    nikeSutazId: 9994,
    nikeTeamID: 8796,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62938,
    teamId: "666bf330cd3f8c5d102ed4cb",
    teamDisplayName: "TJ Družstevník Veľké Ludince",
    nikeSutazId: 9152,
    nikeTeamID: 19263,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63397,
    teamId: "666ffec028584ce5ce52c54f",
    teamDisplayName: "TJ Tatran Krásno nad Kysucou",
    nikeSutazId: 9152,
    nikeTeamID: 19553,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63089,
    teamId: "666edfb228584ce5ce711c08",
    teamDisplayName: "Telovýchovná jednota JEDNOTA Bánová",
    nikeSutazId: 9152,
    nikeTeamID: 5448,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61917,
    teamId: "665f0a71f8f64d2a88f81b28",
    teamDisplayName: "ŠK Odeva Lipany",
    nikeSutazId: 9994,
    nikeTeamID: 4507,
  },
  {
    issfSutazId: 4878,
    sutazID: "66573685f8f64d2a88445170",
    sutazName: "III. liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63471,
    teamId: "667020cb28584ce5ce867501",
    teamDisplayName: "ŠKF Sereď",
    nikeSutazId: 9152,
    nikeTeamID: 6802,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63152,
    teamId: "666ed53c28584ce5ce5e9f47",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 15584,
    nikeTeamID: 298,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62105,
    teamId: "6661a092f8f64d2a884a7a0d",
    teamDisplayName: "FC TATRAN Prešov",
    nikeSutazId: 15584,
    nikeTeamID: 303,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62181,
    teamId: "6662bb02f627c48247faf3cc",
    teamDisplayName: "FC ViOn Zlaté Moravce - Vráble",
    nikeSutazId: 15584,
    nikeTeamID: 1200,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63198,
    teamId: "666ed53428584ce5ce5e7be8",
    teamDisplayName: "FC ŠTK 1914 Šamorín",
    nikeSutazId: 15584,
    nikeTeamID: 7641,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61990,
    teamId: "666c06c4cd3f8c5d104b440b",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 15584,
    nikeTeamID: 4503,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62071,
    teamId: "665ee73ff8f64d2a88bf4873",
    teamDisplayName: "MFK Tatran Liptovský Mikuláš",
    nikeSutazId: 15584,
    nikeTeamID: 4115,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62233,
    teamId: "666804d19d0d9c12e9f0b841",
    teamDisplayName: "MFK Zvolen",
    nikeSutazId: 15584,
    nikeTeamID: 6805,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62189,
    teamId: "6662bb25f627c48247fb22ff",
    teamDisplayName: "MŠK Považská Bystrica",
    nikeSutazId: 15584,
    nikeTeamID: 8797,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61946,
    teamId: "665d72f6f8f64d2a88ad4624",
    teamDisplayName: "MŠK Púchov",
    nikeSutazId: 15584,
    nikeTeamID: 865,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61947,
    teamId: "6659c7d3f8f64d2a88aef55c",
    teamDisplayName: "MŠK Žilina B",
    nikeSutazId: 15584,
    nikeTeamID: 7272,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67202,
    teamId: "66840080a2199fa07c051711",
    teamDisplayName: "OFK Dynamo Malženice",
    nikeSutazId: 15584,
    nikeTeamID: 12243,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62920,
    teamId: "666f3c7628584ce5ce25da77",
    teamDisplayName: "Stará Ľubovňa Redfox Football Club",
    nikeSutazId: 15584,
    nikeTeamID: 26187,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67203,
    teamId: "66841701a2199fa07c281597",
    teamDisplayName: "futbalový klub Humenné, s. r. o.",
    nikeSutazId: 15584,
    nikeTeamID: 297,
  },
  {
    issfSutazId: 4871,
    sutazID: "665734c1f8f64d2a88410e1e",
    sutazName: "MONACObet liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62251,
    teamId: "666ffb0f28584ce5ce4d6318",
    teamDisplayName: "ŠK Slovan Bratislava futbal B",
    nikeSutazId: 15584,
    nikeTeamID: 12218,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63538,
    teamId: "66713a6828584ce5ce8e29f3",
    teamDisplayName: "TJD Príbelce",
    nikeSutazId: 24600,
    nikeTeamID: 39244,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 64559,
    teamId: "6678719528584ce5ce84cb28",
    teamDisplayName: "FK Slávia Staškov",
    nikeSutazId: 24600,
    nikeTeamID: 24587,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63555,
    teamId: "66713ac828584ce5ce8ef78e",
    teamDisplayName: "Futbalový klub Čadca",
    nikeSutazId: 24600,
    nikeTeamID: 3539,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62977,
    teamId: "6670204c28584ce5ce85837d",
    teamDisplayName: "MFK Bytča",
    nikeSutazId: 24600,
    nikeTeamID: 19544,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63539,
    teamId: "66713a7e28584ce5ce8e5acf",
    teamDisplayName: "MŠK Kysucké Nové Mesto",
    nikeSutazId: 24600,
    nikeTeamID: 19550,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62653,
    teamId: "6669376d9d0d9c12e990bc6c",
    teamDisplayName: "OŠK BEŠEŇOVÁ",
    nikeSutazId: 24600,
    nikeTeamID: 29302,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61906,
    teamId: "665ac6b5f8f64d2a8804d0c7",
    teamDisplayName: "OŠK Baník Stráňavy",
    nikeSutazId: 24600,
    nikeTeamID: 19302,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63261,
    teamId: "666edd6328584ce5ce6d2380",
    teamDisplayName: "TJ PARTIZÁN OSRBLIE",
    nikeSutazId: 24600,
    nikeTeamID: 288279,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63452,
    teamId: "66701f4b28584ce5ce83a93d",
    teamDisplayName: "TJ Sokol Medzibrod",
    nikeSutazId: 24600,
    nikeTeamID: 24596,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62027,
    teamId: "665ccb0ef8f64d2a88c7d8b4",
    teamDisplayName: "TJ Sokol Zubrohlava",
    nikeSutazId: 24600,
    nikeTeamID: 39246,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63213,
    teamId: "666ed53428584ce5ce5e7ba9",
    teamDisplayName: "TJ Tatran Oravské Veselé",
    nikeSutazId: 24600,
    nikeTeamID: 19317,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63046,
    teamId: "666c31cdcd3f8c5d108a7496",
    teamDisplayName: "ŠK Badín, občianske združenie",
    nikeSutazId: 24600,
    nikeTeamID: 52330,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 63216,
    teamId: "666ed53428584ce5ce5e80fd",
    teamDisplayName: "ŠK Javorník Makov",
    nikeSutazId: 24600,
    nikeTeamID: 18122,
  },
  {
    issfSutazId: 4893,
    sutazID: "6658c7bcf8f64d2a8854cdc8",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 64755,
    teamId: "667703ea28584ce5cec3dd13",
    teamDisplayName: "ŠK Prameň Kováčová",
    nikeSutazId: 24600,
    nikeTeamID: 19304,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63165,
    teamId: "666f3cdc28584ce5ce26bce1",
    teamDisplayName: "1. MFK Kežmarok",
    nikeSutazId: 24599,
    nikeTeamID: 52341,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63432,
    teamId: "66799f5728584ce5ce1603c0",
    teamDisplayName: "1.FK Svidník",
    nikeSutazId: 24599,
    nikeTeamID: 32002,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64518,
    teamId: "6679284c28584ce5ce5d68e8",
    teamDisplayName: "FC Pivovar Šariš Veľký Šariš",
    nikeSutazId: 24599,
    nikeTeamID: 26184,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 61951,
    teamId: "666850fd9d0d9c12e967debf",
    teamDisplayName: "FK Gerlachov",
    nikeSutazId: 24599,
    nikeTeamID: 26186,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62707,
    teamId: "6676e12828584ce5ce9312de",
    teamDisplayName: "FK Kechnec",
    nikeSutazId: 24599,
    nikeTeamID: 32000,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64174,
    teamId: "667443e528584ce5ce892e26",
    teamDisplayName: "FK Čaňa",
    nikeSutazId: 24599,
    nikeTeamID: 1202,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63808,
    teamId: "6672d8d028584ce5ce7efd78",
    teamDisplayName: "Futbalový klub Sobrance -Sobranecko",
    nikeSutazId: 24599,
    nikeTeamID: 26162,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62776,
    teamId: "6669e516cd3f8c5d1018996b",
    teamDisplayName: "MFK Rožňava",
    nikeSutazId: 24599,
    nikeTeamID: 26167,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62056,
    teamId: "665df772f8f64d2a8895675d",
    teamDisplayName: "MFK Slovan Sabinov",
    nikeSutazId: 24599,
    nikeTeamID: 219255,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 65072,
    teamId: "66785f8428584ce5ce688e0f",
    teamDisplayName: "MFK Spartak Medzev",
    nikeSutazId: 24599,
    nikeTeamID: 19556,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63075,
    teamId: "666c4b9ecd3f8c5d10af7fbb",
    teamDisplayName: "MŠK SPARTAK Medzilaborce",
    nikeSutazId: 24599,
    nikeTeamID: 26155,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62884,
    teamId: "666fd5d428584ce5ceda606d",
    teamDisplayName: "OFK - SIM Raslavice",
    nikeSutazId: 24599,
    nikeTeamID: 19559,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64745,
    teamId: "6676d30b28584ce5ce809bf4",
    teamDisplayName: "OŠK Pavlovce nad Uhom",
    nikeSutazId: 24599,
    nikeTeamID: 39249,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62651,
    teamId: "667069f728584ce5cef9b59c",
    teamDisplayName: "OŠK Rudňany",
    nikeSutazId: 24599,
    nikeTeamID: 26163,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62393,
    teamId: "6666dfa79d0d9c12e935ce2f",
    teamDisplayName: "PWG Sokol Ľubotice",
    nikeSutazId: 24599,
    nikeTeamID: 7720,
  },
  {
    issfSutazId: 4818,
    sutazID: "6654ef88f8f64d2a8836c953",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63923,
    teamId: "6672f91828584ce5ceba1547",
    teamDisplayName: "ŠK Záhradné",
    nikeSutazId: 24599,
    nikeTeamID: 26180,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63170,
    teamId: "666ed53428584ce5ce5e80fa",
    teamDisplayName: "FC Rohožník",
    nikeSutazId: 13821,
    nikeTeamID: 19289,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 66491,
    teamId: "667d835028584ce5ce32f6bf",
    teamDisplayName: "FC Slovan Modra",
    nikeSutazId: 13821,
    nikeTeamID: 26147,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63877,
    teamId: "6673c37f28584ce5cecb9add",
    teamDisplayName: "FK Slovan Ivanka pri Dunaji",
    nikeSutazId: 13821,
    nikeTeamID: 19274,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63362,
    teamId: "666fe26728584ce5ceeddb3a",
    teamDisplayName: "FK Slovan Most pri Bratislave",
    nikeSutazId: 13821,
    nikeTeamID: 19280,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63319,
    teamId: "666f3d0228584ce5ce270c19",
    teamDisplayName: "Lokomotíva Devínska Nová Ves",
    nikeSutazId: 13821,
    nikeTeamID: 19294,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63481,
    teamId: "6670211828584ce5ce871333",
    teamDisplayName: "MFK Rusovce",
    nikeSutazId: 13821,
    nikeTeamID: 19291,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64998,
    teamId: "6678437328584ce5ce3d69c5",
    teamDisplayName: "MŠK Senec",
    nikeSutazId: 13821,
    nikeTeamID: 34701,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 65604,
    teamId: "6679d5ef28584ce5ce6db108",
    teamDisplayName: "NŠK 1922 Bratislava",
    nikeSutazId: 13821,
    nikeTeamID: 288288,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63158,
    teamId: "666ed53428584ce5ce5e7c3b",
    teamDisplayName: "OFK Dunajská Lužná",
    nikeSutazId: 13821,
    nikeTeamID: 6803,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63030,
    teamId: "666c1aabcd3f8c5d10662e39",
    teamDisplayName: "PŠC Pezinok",
    nikeSutazId: 13821,
    nikeTeamID: 19290,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62113,
    teamId: "66606858f8f64d2a88c9bc29",
    teamDisplayName: "SDM Domino",
    nikeSutazId: 13821,
    nikeTeamID: 10095,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 66542,
    teamId: "667dcdf928584ce5cea17e1e",
    teamDisplayName: "TJ Rovinka",
    nikeSutazId: 13821,
    nikeTeamID: 19300,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64851,
    teamId: "6677bfc428584ce5ce8ebc91",
    teamDisplayName: "TJ Záhoran Jakubov",
    nikeSutazId: 13821,
    nikeTeamID: 19295,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62617,
    teamId: "666fe3af28584ce5ceeffc0d",
    teamDisplayName: "ŠK Bernolákovo",
    nikeSutazId: 13821,
    nikeTeamID: 19297,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63039,
    teamId: "666c1d21cd3f8c5d1069ec63",
    teamDisplayName: "ŠK Tomášov",
    nikeSutazId: 13821,
    nikeTeamID: 19285,
  },
  {
    issfSutazId: 5064,
    sutazID: "666b4b79cd3f8c5d1044a255",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63148,
    teamId: "666ed53428584ce5ce5e7bd5",
    teamDisplayName: "Športový klub Nová Dedinka",
    nikeSutazId: 13821,
    nikeTeamID: 26138,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67562,
    teamId: "6693dddeb1444d2ac63e1dfa",
    teamDisplayName: "4FSC, o.z.",
    nikeSutazId: 11028,
    nikeTeamID: 219414,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67572,
    teamId: "6693ddfcb1444d2ac63e454f",
    teamDisplayName: "Futsal Team Levice",
    nikeSutazId: 11028,
    nikeTeamID: 27023,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62494,
    teamId: "6693ddbdb1444d2ac63de090",
    teamDisplayName: "Mimel Lučenec",
    nikeSutazId: 11028,
    nikeTeamID: 39765,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67575,
    teamId: "6693de0eb1444d2ac63e6432",
    teamDisplayName: "MŠK MAYERSON Nové Zámky",
    nikeSutazId: 11028,
    nikeTeamID: 23442,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67573,
    teamId: "6693de0eb1444d2ac63e6440",
    teamDisplayName: "Pinerola 1994 Futsal Academy",
    nikeSutazId: 11028,
    nikeTeamID: 9576,
  },
  {
    issfSutazId: 4989,
    sutazID: "6664dd3a9d0d9c12e90dc8d4",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67545,
    teamId: "6693dddeb1444d2ac63e1e05",
    teamDisplayName: "Podpor Pohyb Košice",
    nikeSutazId: 11028,
    nikeTeamID: 264906,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61925,
    teamId: "66604388f8f64d2a88945c99",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 30,
    nikeTeamID: 300,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61950,
    teamId: "6664bfac9d0d9c12e9d7cb8a",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 30,
    nikeTeamID: 299,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61949,
    teamId: "666ffaf828584ce5ce4d25e1",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 30,
    nikeTeamID: 304,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61905,
    teamId: "665da190f8f64d2a88073a96",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 30,
    nikeTeamID: 702,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61924,
    teamId: "66600f85f8f64d2a884c3d60",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 30,
    nikeTeamID: 868,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 67476,
    teamId: "6687bb378f47d96194f1ee69",
    teamDisplayName: "KFC Komárno futbal",
    nikeSutazId: 30,
    nikeTeamID: 24580,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62075,
    teamId: "6668309a9d0d9c12e93677ce",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 30,
    nikeTeamID: 301,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62061,
    teamId: "665dbcf5f8f64d2a883853bc",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 30,
    nikeTeamID: 306,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61941,
    teamId: "665ebddbf8f64d2a887e94e2",
    teamDisplayName: "MFK Skalica",
    nikeSutazId: 30,
    nikeTeamID: 9864,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 62851,
    teamId: "66714da828584ce5ceba5116",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 30,
    nikeTeamID: 2474,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61945,
    teamId: "665ef7a6f8f64d2a88da15a1",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 30,
    nikeTeamID: 7843,
  },
  {
    issfSutazId: 4809,
    sutazID: "6673d92d28584ce5ceed95c2",
    sutazName: "Niké liga",
    sutazRocnik: "2024/2025",
    issfTeamId: 61989,
    teamId: "665cc817f8f64d2a88c06ab9",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 30,
    nikeTeamID: 294,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63538,
    teamId: "66713a6828584ce5ce8e29f3",
    teamDisplayName: "TJD Príbelce",
    nikeSutazId: 76,
    nikeTeamID: 39244,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63165,
    teamId: "666f3cdc28584ce5ce26bce1",
    teamDisplayName: "1. MFK Kežmarok",
    nikeSutazId: 76,
    nikeTeamID: 52341,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61925,
    teamId: "66604388f8f64d2a88945c99",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 76,
    nikeTeamID: 300,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63509,
    teamId: "66703d8528584ce5ceb0d35e",
    teamDisplayName: "FC - Žolík Malacky",
    nikeSutazId: 76,
    nikeTeamID: 26061,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61950,
    teamId: "6664bfac9d0d9c12e9d7cb8a",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 76,
    nikeTeamID: 299,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62849,
    teamId: "666acf8ccd3f8c5d105a59ee",
    teamDisplayName: "FC LOKOMOTÍVA KOŠICE",
    nikeSutazId: 76,
    nikeTeamID: 872,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63152,
    teamId: "666ed53c28584ce5ce5e9f47",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 76,
    nikeTeamID: 298,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63170,
    teamId: "666ed53428584ce5ce5e80fa",
    teamDisplayName: "FC Rohožník",
    nikeSutazId: 76,
    nikeTeamID: 19289,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63409,
    teamId: "667137e228584ce5ce8921af",
    teamDisplayName: "FC Slovan Galanta",
    nikeSutazId: 76,
    nikeTeamID: 8654,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61949,
    teamId: "666ffaf828584ce5ce4d25e1",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 76,
    nikeTeamID: 304,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62105,
    teamId: "6661a092f8f64d2a884a7a0d",
    teamDisplayName: "FC TATRAN Prešov",
    nikeSutazId: 76,
    nikeTeamID: 303,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62181,
    teamId: "6662bb02f627c48247faf3cc",
    teamDisplayName: "FC ViOn Zlaté Moravce - Vráble",
    nikeSutazId: 76,
    nikeTeamID: 1200,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63198,
    teamId: "666ed53428584ce5ce5e7be8",
    teamDisplayName: "FC ŠTK 1914 Šamorín",
    nikeSutazId: 76,
    nikeTeamID: 7641,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63448,
    teamId: "66701f1d28584ce5ce836282",
    teamDisplayName: "FK Beluša",
    nikeSutazId: 76,
    nikeTeamID: 19247,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61905,
    teamId: "665da190f8f64d2a88073a96",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 76,
    nikeTeamID: 702,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61951,
    teamId: "666850fd9d0d9c12e967debf",
    teamDisplayName: "FK Gerlachov",
    nikeSutazId: 76,
    nikeTeamID: 26186,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62550,
    teamId: "6668098a9d0d9c12e9f7e8f7",
    teamDisplayName: "FK Inter Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 870,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62707,
    teamId: "6676e12828584ce5ce9312de",
    teamDisplayName: "FK Kechnec",
    nikeSutazId: 76,
    nikeTeamID: 32000,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61990,
    teamId: "666c06c4cd3f8c5d104b440b",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 76,
    nikeTeamID: 4503,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62703,
    teamId: "666ace5dcd3f8c5d10589c9e",
    teamDisplayName: "FK Poprad",
    nikeSutazId: 76,
    nikeTeamID: 5447,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63349,
    teamId: "666fe26628584ce5ceedda14",
    teamDisplayName: "FK Rača Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 5445,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62064,
    teamId: "6669e82ecd3f8c5d101e28ba",
    teamDisplayName: "FK Slovan Duslo Šaľa",
    nikeSutazId: 76,
    nikeTeamID: 70438,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63362,
    teamId: "666fe26728584ce5ceeddb3a",
    teamDisplayName: "FK Slovan Most pri Bratislave",
    nikeSutazId: 76,
    nikeTeamID: 19280,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61924,
    teamId: "66600f85f8f64d2a884c3d60",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 76,
    nikeTeamID: 868,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62358,
    teamId: "66682a259d0d9c12e92c3fe9",
    teamDisplayName: "FKM Nové Zámky",
    nikeSutazId: 76,
    nikeTeamID: 864,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62743,
    teamId: "66697cc1cd3f8c5d10222bf4",
    teamDisplayName: "FTC Fiľakovo",
    nikeSutazId: 76,
    nikeTeamID: 19562,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62294,
    teamId: "6666ad0e9d0d9c12e9cb0dce",
    teamDisplayName: "Futbalový klub Podkonice",
    nikeSutazId: 76,
    nikeTeamID: 221933,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63808,
    teamId: "6672d8d028584ce5ce7efd78",
    teamDisplayName: "Futbalový klub Sobrance -Sobranecko",
    nikeSutazId: 76,
    nikeTeamID: 26162,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62442,
    teamId: "666bf330cd3f8c5d102ed4a6",
    teamDisplayName: "Futbalový klub Spišská Nová Ves",
    nikeSutazId: 76,
    nikeTeamID: 5449,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63555,
    teamId: "66713ac828584ce5ce8ef78e",
    teamDisplayName: "Futbalový klub Čadca",
    nikeSutazId: 76,
    nikeTeamID: 3539,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 67476,
    teamId: "6687bb378f47d96194f1ee69",
    teamDisplayName: "KFC Komárno futbal",
    nikeSutazId: 76,
    nikeTeamID: 24580,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63319,
    teamId: "666f3d0228584ce5ce270c19",
    teamDisplayName: "Lokomotíva Devínska Nová Ves",
    nikeSutazId: 76,
    nikeTeamID: 19294,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62977,
    teamId: "6670204c28584ce5ce85837d",
    teamDisplayName: "MFK Bytča",
    nikeSutazId: 76,
    nikeTeamID: 19544,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 67531,
    teamId: "668cfbcdb1444d2ac61d1229",
    teamDisplayName: "MFK Dolný Kubín, s. r. o.",
    nikeSutazId: 76,
    nikeTeamID: 4506,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62075,
    teamId: "6668309a9d0d9c12e93677ce",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 76,
    nikeTeamID: 301,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62776,
    teamId: "6669e516cd3f8c5d1018996b",
    teamDisplayName: "MFK Rožňava",
    nikeSutazId: 76,
    nikeTeamID: 26167,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63481,
    teamId: "6670211828584ce5ce871333",
    teamDisplayName: "MFK Rusovce",
    nikeSutazId: 76,
    nikeTeamID: 19291,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62061,
    teamId: "665dbcf5f8f64d2a883853bc",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 76,
    nikeTeamID: 306,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61941,
    teamId: "665ebddbf8f64d2a887e94e2",
    teamDisplayName: "MFK Skalica",
    nikeSutazId: 76,
    nikeTeamID: 9864,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62921,
    teamId: "666bdf9ccd3f8c5d10de0bf2",
    teamDisplayName: "MFK Snina",
    nikeSutazId: 76,
    nikeTeamID: 6804,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 65072,
    teamId: "66785f8428584ce5ce688e0f",
    teamDisplayName: "MFK Spartak Medzev",
    nikeSutazId: 76,
    nikeTeamID: 19556,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62071,
    teamId: "665ee73ff8f64d2a88bf4873",
    teamDisplayName: "MFK Tatran Liptovský Mikuláš",
    nikeSutazId: 76,
    nikeTeamID: 4115,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62454,
    teamId: "6669e5a9cd3f8c5d1019f40e",
    teamDisplayName: "MFK Vranov nad Topľou",
    nikeSutazId: 76,
    nikeTeamID: 1492,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62851,
    teamId: "66714da828584ce5ceba5116",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 76,
    nikeTeamID: 2474,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62233,
    teamId: "666804d19d0d9c12e9f0b841",
    teamDisplayName: "MFK Zvolen",
    nikeSutazId: 76,
    nikeTeamID: 6805,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62983,
    teamId: "666bff4bcd3f8c5d104064a8",
    teamDisplayName: "MŠK FOMAT Martin",
    nikeSutazId: 76,
    nikeTeamID: 1231,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63539,
    teamId: "66713a7e28584ce5ce8e5acf",
    teamDisplayName: "MŠK Kysucké Nové Mesto",
    nikeSutazId: 76,
    nikeTeamID: 19550,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61907,
    teamId: "66682ca99d0d9c12e93046cd",
    teamDisplayName: "MŠK NOVOHRAD Lučenec",
    nikeSutazId: 76,
    nikeTeamID: 19563,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63320,
    teamId: "666f4a1928584ce5ce41cae9",
    teamDisplayName: "MŠK Námestovo",
    nikeSutazId: 76,
    nikeTeamID: 10096,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62189,
    teamId: "6662bb25f627c48247fb22ff",
    teamDisplayName: "MŠK Považská Bystrica",
    nikeSutazId: 76,
    nikeTeamID: 8797,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61946,
    teamId: "665d72f6f8f64d2a88ad4624",
    teamDisplayName: "MŠK Púchov",
    nikeSutazId: 76,
    nikeTeamID: 865,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62392,
    teamId: "666bf330cd3f8c5d102ed499",
    teamDisplayName: "MŠK Rimavská Sobota",
    nikeSutazId: 76,
    nikeTeamID: 2244,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63075,
    teamId: "666c4b9ecd3f8c5d10af7fbb",
    teamDisplayName: "MŠK SPARTAK Medzilaborce",
    nikeSutazId: 76,
    nikeTeamID: 26155,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63205,
    teamId: "666ed53428584ce5ce5e7bec",
    teamDisplayName: "MŠK Spišské Podhradie",
    nikeSutazId: 76,
    nikeTeamID: 26156,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62368,
    teamId: "6666da039d0d9c12e92a4158",
    teamDisplayName: "MŠK Tesla Stropkov",
    nikeSutazId: 76,
    nikeTeamID: 4505,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61945,
    teamId: "665ef7a6f8f64d2a88da15a1",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 76,
    nikeTeamID: 7843,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 65604,
    teamId: "6679d5ef28584ce5ce6db108",
    teamDisplayName: "NŠK 1922 Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 288288,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62535,
    teamId: "6668079c9d0d9c12e9f50f63",
    teamDisplayName: "OFK Baník Lehota pod Vtáčnikom",
    nikeSutazId: 76,
    nikeTeamID: 29908,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63158,
    teamId: "666ed53428584ce5ce5e7c3b",
    teamDisplayName: "OFK Dunajská Lužná",
    nikeSutazId: 76,
    nikeTeamID: 6803,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 67202,
    teamId: "66840080a2199fa07c051711",
    teamDisplayName: "OFK Dynamo Malženice",
    nikeSutazId: 76,
    nikeTeamID: 12243,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62853,
    teamId: "666ab701cd3f8c5d10365863",
    teamDisplayName: "OK Častkovce",
    nikeSutazId: 76,
    nikeTeamID: 19250,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62653,
    teamId: "6669376d9d0d9c12e990bc6c",
    teamDisplayName: "OŠK BEŠEŇOVÁ",
    nikeSutazId: 76,
    nikeTeamID: 29302,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62090,
    teamId: "666bdebbcd3f8c5d10dcd75e",
    teamDisplayName: "Partizán Bardejov BŠK",
    nikeSutazId: 76,
    nikeTeamID: 7798,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63030,
    teamId: "666c1aabcd3f8c5d10662e39",
    teamDisplayName: "PŠC Pezinok",
    nikeSutazId: 76,
    nikeTeamID: 19290,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63014,
    teamId: "666c13a7cd3f8c5d105d5a5a",
    teamDisplayName: "RSC HAMSIK ACADEMY Banská Bystrica o.z.",
    nikeSutazId: 76,
    nikeTeamID: 288291,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62113,
    teamId: "66606858f8f64d2a88c9bc29",
    teamDisplayName: "SDM Domino",
    nikeSutazId: 76,
    nikeTeamID: 10095,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62863,
    teamId: "666bde4bcd3f8c5d10dc356b",
    teamDisplayName: "Slávia TU Košice",
    nikeSutazId: 76,
    nikeTeamID: 32001,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62575,
    teamId: "66680eb89d0d9c12e9ffe7ed",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 76,
    nikeTeamID: 1860,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62920,
    teamId: "666f3c7628584ce5ce25da77",
    teamDisplayName: "Stará Ľubovňa Redfox Football Club",
    nikeSutazId: 76,
    nikeTeamID: 26187,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62698,
    teamId: "66697be89d0d9c12e901ebc5",
    teamDisplayName: "TJ Baník Kalinovo",
    nikeSutazId: 76,
    nikeTeamID: 8796,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62938,
    teamId: "666bf330cd3f8c5d102ed4cb",
    teamDisplayName: "TJ Družstevník Veľké Ludince",
    nikeSutazId: 76,
    nikeTeamID: 19263,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63261,
    teamId: "666edd6328584ce5ce6d2380",
    teamDisplayName: "TJ PARTIZÁN OSRBLIE",
    nikeSutazId: 76,
    nikeTeamID: 288279,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63452,
    teamId: "66701f4b28584ce5ce83a93d",
    teamDisplayName: "TJ Sokol Medzibrod",
    nikeSutazId: 76,
    nikeTeamID: 24596,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62027,
    teamId: "665ccb0ef8f64d2a88c7d8b4",
    teamDisplayName: "TJ Sokol Zubrohlava",
    nikeSutazId: 76,
    nikeTeamID: 39246,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63397,
    teamId: "666ffec028584ce5ce52c54f",
    teamDisplayName: "TJ Tatran Krásno nad Kysucou",
    nikeSutazId: 76,
    nikeTeamID: 19553,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63213,
    teamId: "666ed53428584ce5ce5e7ba9",
    teamDisplayName: "TJ Tatran Oravské Veselé",
    nikeSutazId: 76,
    nikeTeamID: 19317,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63089,
    teamId: "666edfb228584ce5ce711c08",
    teamDisplayName: "Telovýchovná jednota JEDNOTA Bánová",
    nikeSutazId: 76,
    nikeTeamID: 5448,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 67203,
    teamId: "66841701a2199fa07c281597",
    teamDisplayName: "futbalový klub Humenné, s. r. o.",
    nikeSutazId: 76,
    nikeTeamID: 297,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63046,
    teamId: "666c31cdcd3f8c5d108a7496",
    teamDisplayName: "ŠK Badín, občianske združenie",
    nikeSutazId: 76,
    nikeTeamID: 52330,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 62617,
    teamId: "666fe3af28584ce5ceeffc0d",
    teamDisplayName: "ŠK Bernolákovo",
    nikeSutazId: 76,
    nikeTeamID: 19297,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63216,
    teamId: "666ed53428584ce5ce5e80fd",
    teamDisplayName: "ŠK Javorník Makov",
    nikeSutazId: 76,
    nikeTeamID: 18122,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61917,
    teamId: "665f0a71f8f64d2a88f81b28",
    teamDisplayName: "ŠK Odeva Lipany",
    nikeSutazId: 76,
    nikeTeamID: 4507,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 64755,
    teamId: "667703ea28584ce5cec3dd13",
    teamDisplayName: "ŠK Prameň Kováčová",
    nikeSutazId: 76,
    nikeTeamID: 19304,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 61989,
    teamId: "665cc817f8f64d2a88c06ab9",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 76,
    nikeTeamID: 294,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63039,
    teamId: "666c1d21cd3f8c5d1069ec63",
    teamDisplayName: "ŠK Tomášov",
    nikeSutazId: 76,
    nikeTeamID: 19285,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63471,
    teamId: "667020cb28584ce5ce867501",
    teamDisplayName: "ŠKF Sereď",
    nikeSutazId: 76,
    nikeTeamID: 6802,
  },
  {
    issfSutazId: 4875,
    sutazID: "665735cef8f64d2a884300f0",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2024/2025",
    issfTeamId: 63148,
    teamId: "666ed53428584ce5ce5e7bd5",
    teamDisplayName: "Športový klub Nová Dedinka",
    nikeSutazId: 76,
    nikeTeamID: 26138,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62303,
    teamId: "6666db979d0d9c12e92d61d3",
    teamDisplayName: "AFC Nové Mesto nad Váhom",
    nikeSutazId: 24601,
    nikeTeamID: 5444,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62279,
    teamId: "6665f6239d0d9c12e965a292",
    teamDisplayName: "FC Baník Prievidza",
    nikeSutazId: 24601,
    nikeTeamID: 53387,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62422,
    teamId: "6674081b28584ce5ce2c976b",
    teamDisplayName: "FC Nitra",
    nikeSutazId: 24601,
    nikeTeamID: 296,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62682,
    teamId: "6669c600cd3f8c5d10e5d098",
    teamDisplayName: "FC Pata",
    nikeSutazId: 24601,
    nikeTeamID: 31983,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 65610,
    teamId: "6679a5f728584ce5ce1f207e",
    teamDisplayName: "FC ViOn Zlaté Moravce - Vráble B",
    nikeSutazId: 24601,
    nikeTeamID: 218528,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63128,
    teamId: "6670296828584ce5ce943b6a",
    teamDisplayName: "FK Slovan Levice",
    nikeSutazId: 24601,
    nikeTeamID: 863,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 67346,
    teamId: "6685505ba2199fa07cfc6236",
    teamDisplayName: "FK Spartak Dubnica nad Váhom",
    nikeSutazId: 24601,
    nikeTeamID: 295,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62630,
    teamId: "666936f19d0d9c12e99006a5",
    teamDisplayName: "OFK Trebatice",
    nikeSutazId: 24601,
    nikeTeamID: 17818,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64001,
    teamId: "6673b0c828584ce5ceaeb289",
    teamDisplayName: "OŠK Trenčianske Stankovce",
    nikeSutazId: 24601,
    nikeTeamID: 24582,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63717,
    teamId: "6671de4d28584ce5ced3924d",
    teamDisplayName: "PFK Piešťany",
    nikeSutazId: 24601,
    nikeTeamID: 230829,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 63859,
    teamId: "6672a4c028584ce5ce213427",
    teamDisplayName: "TJ Imeľ",
    nikeSutazId: 24601,
    nikeTeamID: 24578,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62609,
    teamId: "666856d49d0d9c12e9706513",
    teamDisplayName: "TJ NAFTA GBELY",
    nikeSutazId: 24601,
    nikeTeamID: 26106,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64246,
    teamId: "66745cef28584ce5ceb467bf",
    teamDisplayName: "TJ Slavoj Boleráz",
    nikeSutazId: 24601,
    nikeTeamID: 19264,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62843,
    teamId: "666aeb2fcd3f8c5d10b607ec",
    teamDisplayName: "ŠK 1923 Gabčíkovo",
    nikeSutazId: 24601,
    nikeTeamID: 14879,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62332,
    teamId: "66670e769d0d9c12e998c419",
    teamDisplayName: "ŠK LR CRYSTAL",
    nikeSutazId: 24601,
    nikeTeamID: 9882,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 62769,
    teamId: "66698f3dcd3f8c5d103fa8d2",
    teamDisplayName: "ŠK Šoporňa",
    nikeSutazId: 24601,
    nikeTeamID: 26066,
  },
  {
    issfSutazId: 4850,
    sutazID: "6656053bf8f64d2a88907d70",
    sutazName: "Majstrovstvá regiónu - IV. liga HUMMEL ZsFZ",
    sutazRocnik: "2024/2025",
    issfTeamId: 64031,
    teamId: "6673c8e228584ce5ced59ac5",
    teamDisplayName: "ŠPORTOVÉ KLUBY BLAVA 1928",
    nikeSutazId: 24601,
    nikeTeamID: 24577,
  },
];
