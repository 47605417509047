import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 13.8 15.2"
  >
    <path
      fill={color}
      d="M 11.2,1.4 H 10.3 V 0.6 C 10.3,0.3 10,0 9.7,0 9.4,0 9.1,0.3 9.1,0.6 V 1.4 H 4.7 V 0.6 C 4.7,0.3 4.4,0 4.1,0 3.8,0 3.5,0.3 3.5,0.6 V 1.4 H 2.6 C 1.2,1.4 0,2.6 0,4 v 8.6 c 0,1.4 1.2,2.6 2.6,2.6 h 8.6 c 1.4,0 2.6,-1.2 2.6,-2.6 V 4 C 13.8,2.6 12.6,1.4 11.2,1.4 Z M 2.6,2.6 H 3.5 V 3.4 C 3.5,3.7 3.8,4 4.1,4 4.4,4 4.7,3.7 4.7,3.4 V 2.6 H 9 V 3.4 C 9,3.7 9.3,4 9.6,4 9.9,4 10.2,3.7 10.2,3.4 V 2.6 h 0.9 c 0.8,0 1.4,0.6 1.4,1.4 V 5.6 H 1.2 V 4 C 1.2,3.2 1.8,2.6 2.6,2.6 Z M 11.2,14 H 2.6 C 1.8,14 1.2,13.4 1.2,12.6 V 6.8 h 11.3 v 5.8 C 12.6,13.4 12,14 11.2,14 Z"
    />
  </svg>
);
