import { IQueryHocProps, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Loading from '../../components/Loading';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import NotFoundError from '../../NotFoundError';
import getAppSpaceFromUrl from '../../utilities/getAppSpaceFromUrl';
import isObjectId from '../../utilities/isObjectId';
import { sportSectorSettingsSelector } from '../App/selectors';
import {
  setCurrentAppSpace,
  setCurrentCompetitionGroupId,
  setCurrentCompetitionId,
  setCurrentCompetitionPartId,
  setCurrentRoundId,
} from '../Competition/actions';
import {
  currentCompetitionAppSpaceSelector,
  getCurrentCompetitionIdSelector,
} from '../Competition/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NotFound from '../NotFound';
import {  loadMatch, setCurrentMatchId } from './actions';
import { currentMatchErrorSelector, currentMatchSelector } from './selectors';

const mapStateToProps = (state: RootState) => {
  return {
    match: currentMatchSelector(state),
    matchError: currentMatchErrorSelector(state),
    currentCompetitionId: getCurrentCompetitionIdSelector(state),
    sportSectorSettings: sportSectorSettingsSelector(state),
    appSettings: appSettingsSelector(state),
    currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string; zapas: string }, {}> &
  IQueryHocProps<{
    parameters: {
      view: { type: QueryHocTypes.String };
    };
  }> & { dispatch: CustomThunkDispatch };

class Match extends React.PureComponent<Props> {
  static async init(props: Props) {
    const {
      dispatch,
      params: { zvaz, zapas },
    } = props;

    const appSpace = getAppSpaceFromUrl('union', zvaz) || zvaz;

    if (!appSpace) {
      dispatch(setCurrentAppSpace(''));
      throw new NotFoundError(null);
    }

    dispatch(setCurrentAppSpace(appSpace));

    await dispatch(setCurrentMatchId(zapas));

    await dispatch(loadMatch({ matchId: zapas }));
    
  }

  static async getInitialProps(props: Props) {
    await Match.init(props);
  }

  async componentDidMount() {
    try {
      await Match.getInitialProps(this.props);
    } catch (e: any) {
      //
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.params.zapas !== this.props.params.zapas) {
      await this.props.dispatch(setCurrentMatchId(this.props.params.zapas));
      try {
        await Match.init(this.props);
      } catch (e: any) {
        //
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setCurrentAppSpace(''));
    this.props.dispatch(setCurrentCompetitionId(''));
    this.props.dispatch(setCurrentCompetitionPartId(''));
    this.props.dispatch(setCurrentCompetitionGroupId(''));
    this.props.dispatch(setCurrentRoundId(''));
    this.props.dispatch(setCurrentMatchId(''));
  }

  render() {
    const {
      children,
      currentCompetitionId,
      sportSectorSettings,
      matchError,
      match,
      params: { zvaz, zapas },
      appSettings,
      currentCompetitionAppSpace,
    } = this.props;

    if (!currentCompetitionAppSpace) {
      return <NotFound />;
    }

    if (matchError) {
      return <NotFound />;
    }

    if (!currentCompetitionId || !match || !sportSectorSettings) {
      return <Loading />;
    }
    const isIssfId = !isObjectId(zapas);
    if (!isIssfId && match?.__issfId) {
      return (
        <>
          <Helmet>
            <link
              rel="canonical"
              href={`${appSettings.baseUri}/futbalnet/z/${zvaz}/zapas/${match?.__issfId}/`}
            />
          </Helmet>
          {children}
        </>
      );
    }
    return children;
  }
}

export default compose(connect(mapStateToProps))(Match);
