import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20.3,19.3c0.3,0.5,0.1,1.1-0.4,1.4C18.4,21.6,16.7,22,15,22c-1.7,0-3.4-0.4-4.9-1.3c-2.2-1.3-3.9-3.3-4.6-5.7
	H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h1C5,12.3,5,11.7,5,11H4c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.5c0.2-0.7,0.5-1.3,0.8-1.9
	C9,2.3,15.1,0.6,19.9,3.3c0.5,0.3,0.7,0.9,0.4,1.4C20,5.1,19.4,5.3,18.9,5c-3.8-2.2-8.7-0.8-10.9,3C7.9,8.4,7.7,8.7,7.6,9H17
	c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.1c-0.1,0.7-0.1,1.3,0,2H17c0.6,0,1,0.4,1,1s-0.4,1-1,1H7.6c0.7,1.7,1.9,3.1,3.5,4
	c2.4,1.4,5.4,1.4,7.9,0C19.4,18.7,20,18.9,20.3,19.3z"
    />
  </svg>
);
