import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IsolatedCheckBox } from '.';
import styled, { css } from '../../theme/styled-components';
import Icon from '../Icon';
import { Td, Tr } from '../Table';

const TdHandle = styled(Td)`
  cursor: grab;
`;

const TdPointer = styled(Td)`
  cursor: pointer;
`;

type Row = React.ReactNode;

interface IOwnProps {
  compact: boolean;
  sticky: boolean;
  onClickRow?: (originalItem: any, rowIdx: number) => void;
  renderRow: (item: any, rowIdx: number) => Row[];
  isSelectable: boolean;
  isSelected?: boolean;
  onSelectRow?: (originalItem: any, rowIdx: number) => void;
  row: any;
  rowIdx: number;
  onReorder?: (rows: any[]) => void;
}

export const TrStyled = styled(Tr)<{ compact: boolean; sticky: boolean }>`
  ${({ compact }) =>
    compact &&
    css`
      > td {
        padding: ${rem(6)};
      }
    `}
  ${({ sticky }) =>
    sticky &&
    css`
      > td:nth-child(1) {
        position: sticky;
        left: 0;
        z-index: 1;
        min-width: ${rem(150)};
        :after {
          content: '';
          position: absolute;
          height: 100%;
          top: 0;
          right: 0;
          width: 100%;
          border-right: ${rem(2)} solid #f1f1f1;
        }
        ${mb('m')} {
          position: relative;
          :after {
            display: none;
          }
        }
      }
      &:nth-child(odd) {
        > td:nth-child(1) {
          background: #f9f9f9;
        }
      }
      &:nth-child(even) {
        > td:nth-child(1) {
          background: #fff;
        }
      }
    `}
  transition: box-shadow 0.4s;
  box-shadow: none;
  &.slip-reordering {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    > td {
      background-color: #ddd !important;
    }
  }
`;

type IProps = IOwnProps;

const BasicTableRow: React.FC<IProps> = ({
  onClickRow,
  row,
  rowIdx,
  onReorder,
  renderRow,
  onSelectRow,
  isSelectable,
  compact,
  isSelected,
  sticky,
}) => {
  const handleClickRow =
    onClickRow &&
    (() => {
      onClickRow(row, rowIdx);
    });

  return (
    <TrStyled
      sticky={sticky}
      compact={compact}
      onClick={onClickRow ? handleClickRow : void 0}
    >
      {onReorder && (
        <TdHandle className="drag-handle-cell">
          <Icon name="drag-handle" />
        </TdHandle>
      )}
      {isSelectable && (
        <TdPointer
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (onSelectRow) {
              onSelectRow(row, rowIdx);
            }
          }}
        >
          <IsolatedCheckBox
            checked={!!isSelected}
            onChange={() => {
              //
            }}
          />
        </TdPointer>
      )}
      {renderRow(row, rowIdx).map((item, idx) => (
        <Td key={idx}>{item}</Td>
      ))}
    </TrStyled>
  );
};

export default React.memo(BasicTableRow);
