import Image from '@sportnet/ui/lib/Image';
import Segment from '@sportnet/ui/lib/Segment';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { isAfter } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import Tooltip from 'react-tooltip-lite';
import { getProp } from 'sportnet-utilities';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import {
  ICompetition,
  ICompetitionPart,
  IResultsTableItemMatch,
} from '../../../library/Competitions';
import styled, { css } from '../../../theme/styled-components';
import getFallbackClubOrUnionLogo from '../../../utilities/getFallbackClubOrUnionLogo';
import getMatchBaseUrl from '../../../utilities/getMatchBaseUrl';
import getPpoLogoSrc from '../../../utilities/getPpoLogoSrc';
import getTeamBaseUrl from '../../../utilities/getTeamBaseUrl';
import __ from '../../../utilities/__';
import getCorrections from '../../../utilities/competitionStanding/getCorrections';
import sortResults from '../../../utilities/competitionStanding/sortResults';
import getResultsByPoints from '../../../utilities/competitionStanding/getResultsByPoints';

const PublicComment = styled.div`
  margin-top: ${rem(16)};
  font-size: ${rem(13)};
`;
const TeamLogoWrapper = styled.div`
  width: ${rem(30)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TeamLogo = styled.div`
  img {
    max-height: ${rem(16)};
  }
`;

const TeamName = styled.div`
  white-space: nowrap;
  max-width: ${rem(100)};
  text-overflow: ellipsis;
  overflow: hidden;
  ${mb('m')} {
    max-width: unset;
    overflow: visible;
  }
`;

const Resignation = styled.span`
  color: ${({ theme }) => theme.color.danger};
`;

const StyledTr = styled(Tr)<{ isHighlighted?: boolean }>`
  border: 0;
  ${({ isHighlighted }) =>
    !!isHighlighted &&
    css`
      > td {
        font-weight: 600;
        background: #fffaef !important;
      }
    `}
`;

const Condition = styled.div`
  display: flex;
`;
const ConditionItem = styled.div<{ result: string }>`
  width: ${rem(24)};
  height: ${rem(24)};
  font-size: ${rem(12)};
  font-weight: normal !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${({ theme, result }) => {
    if (result === 'W') {
      return theme.color.success;
    } else if (result === 'D') {
      return theme.color.warning;
    } else if (result === 'L') {
      return theme.color.danger;
    }
    return theme.color.primary;
  }};
  color: white;
`;

const ResultsTableWrapper = styled.div`
  margin: 0 -${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  width: calc(100% + ${({ theme }) => rem(theme.grid.gutterWidth)});
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const StickyTh = styled(Th)`
  position: sticky;
  background: ${({ theme }) => theme.tables.headerBg};
  z-index: 1;
`;

const PaddedTh = styled(Th)`
  padding-left: ${rem(16)};
  padding-right: ${rem(16)};
`;

const StickyTd = styled(Td)`
  position: sticky;
  z-index: 1;
  text-align: center;
`;

const TeamNameStickyTd = styled(StickyTd)`
  width: ${rem(100)};
  ${mb('m')} {
    width: 100%;
  }
`;

const StickySeparator = styled.div`
  width: ${rem(2)};
  height: 100%;
  background: #f1f1f1;
  ${mb('m')} {
    display: none;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${rem(16)};
  padding: ${rem(8)};
  ${mb('m')} {
    padding: 0;
  }
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const LegendItem = styled.div`
  margin-right: ${rem(16)};
  display: flex;
  align-items: center;
`;
const LegendItemLabel = styled.div`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
`;

interface OwnProps {
  currentCompetitionPart: ICompetitionPart;
  currentCompetition: ICompetition;
  zvaz: string;
  baseObjectName?: 'stats' | 'homeStats' | 'awayStats';
  highligtedTeamIds?: string[];
  teamNamePath?: string[];
}
type Props = OwnProps;

const Standings: React.FC<Props> = ({
  currentCompetitionPart,
  currentCompetition,
  zvaz,
  baseObjectName = 'stats',
  highligtedTeamIds = [],
  teamNamePath = ['name'],
}) => {
  const getTeam = (teamId: string) => {
    const team = currentCompetitionPart?.teams?.find((i) => i._id === teamId);
    return team;
  };

  const renderResultItem = (i, idx) => {
    const team = getTeam(i.team._id);
    let baseObject = i.stats;
    let baseArray = i.matches || [];
    if (baseObjectName === 'homeStats' && i.homeStats) {
      baseObject = i.homeStats;
      baseArray = i.homeMatches || [];
    } else if (baseObjectName === 'awayStats' && i.awayStats) {
      baseObject = i.awayStats;
      baseArray = i.awayMatches || [];
    }
    const background = (idx + 1) % 2 === 0 ? '#fff' : '#f9f9f9';
    const isHighlighted = highligtedTeamIds.includes(i.team._id);

    const orgLogoSrc = team?.organization
      ? getPpoLogoSrc(team.organization)
      : '';

    if (
      currentCompetitionPart.rules &&
      currentCompetitionPart.rules.sport_sector === 'beachfutbal'
    ) {
      return (
        <StyledTr key={i.team._id} isHighlighted={isHighlighted}>
          <StickyTd
            style={{
              left: 0,
              background,
              paddingLeft: rem(8),
              paddingRight: rem(4),
              width: rem(18),
              // overflow: 'show',
            }}
          >
            <div style={{ width: rem(18) }}>{idx + 1}</div>
          </StickyTd>
          <StickyTd
            style={{
              paddingLeft: rem(4),
              paddingRight: rem(4),
              left: rem(30),
              background,
              width: rem(30),
            }}
          >
            <TeamLogoWrapper>
              <TeamLogo>
                <Image
                  src={orgLogoSrc}
                  fallbackSrc={getFallbackClubOrUnionLogo()}
                  loading="lazy"
                />
              </TeamLogo>
            </TeamLogoWrapper>
          </StickyTd>
          <TeamNameStickyTd
            style={{
              left: rem(68),
              textAlign: 'left',
              paddingLeft: rem(4),
              paddingRight: rem(16),
              background,
            }}
          >
            <Link
              to={getTeamBaseUrl({
                klub: team?.appSpace || '',
                teamId: team?.__issfId || team?._id || '',
              })}
            >
              <TeamName>
                {getProp(team || {}, teamNamePath)}{' '}
                {i.team.resignation && (
                  <Resignation>({__('odstúpené')})</Resignation>
                )}
              </TeamName>
            </Link>
          </TeamNameStickyTd>
          <StickyTd style={{ padding: 0, left: rem(188), background }}>
            <StickySeparator />
          </StickyTd>
          <Td style={{ width: rem(30) }}>
            {getProp(baseObject || {}, ['matches', 'played'], 0)}
          </Td>
          <Td style={{ width: rem(30) }}>
            {getProp(baseObject || {}, ['matches', 'wonNT'], 0)}
          </Td>
          <Td style={{ width: rem(30) }}>
            {getProp(baseObject || {}, ['matches', 'wonET'], 0)}
          </Td>
          <Td style={{ width: rem(30) }}>
            {getProp(baseObject || {}, ['matches', 'wonP'], 0)}
          </Td>
          <Td style={{ width: rem(60) }}>
            {getProp(baseObject || {}, ['goals', 'given'], 0)}:
            {getProp(baseObject || {}, ['goals', 'received'], 0)}
          </Td>
          <Td style={{ width: rem(30) }}>
            {getProp(baseObject || {}, ['points'], 0)}
          </Td>
          <Td style={{ width: rem(200) }}>
            <Condition>{getTeamCondition(baseArray, team as any)}</Condition>
          </Td>
        </StyledTr>
      );
    }

    return (
      <StyledTr key={i.team._id} isHighlighted={isHighlighted}>
        <StickyTd
          style={{
            left: 0,
            background,
            paddingLeft: rem(8),
            paddingRight: rem(4),
            width: rem(18),
            // overflow: 'show',
          }}
        >
          <div style={{ width: rem(18) }}>{idx + 1}</div>
        </StickyTd>
        <StickyTd
          style={{
            paddingLeft: rem(4),
            paddingRight: rem(4),
            left: rem(30),
            background,
            width: rem(30),
          }}
        >
          <TeamLogoWrapper>
            <TeamLogo>
              <Image
                src={orgLogoSrc}
                fallbackSrc={getFallbackClubOrUnionLogo()}
                loading="lazy"
              />
            </TeamLogo>
          </TeamLogoWrapper>
        </StickyTd>
        <TeamNameStickyTd
          style={{
            left: rem(68),
            textAlign: 'left',
            paddingLeft: rem(4),
            paddingRight: rem(16),
            background,
          }}
        >
          <Link
            to={getTeamBaseUrl({
              klub: team?.appSpace || '',
              teamId: team?.__issfId || team?._id || '',
            })}
          >
            <TeamName>
              {getProp(team || {}, teamNamePath)}{' '}
              {i.team.resignation && (
                <Resignation>({__('odstúpené')})</Resignation>
              )}
            </TeamName>
          </Link>
        </TeamNameStickyTd>
        <StickyTd style={{ padding: 0, left: rem(188), background }}>
          <StickySeparator />
        </StickyTd>
        <Td style={{ width: rem(30) }}>
          {getProp(baseObject || {}, ['matches', 'played'], 0)}
        </Td>
        <Td style={{ width: rem(30) }}>
          {getProp(baseObject || {}, ['matches', 'won'], 0)}
        </Td>
        <Td style={{ width: rem(30) }}>
          {getProp(baseObject || {}, ['matches', 'draw'], 0)}
        </Td>
        <Td style={{ width: rem(30) }}>
          {getProp(baseObject || {}, ['matches', 'lost'], 0)}
        </Td>
        <Td style={{ width: rem(60) }}>
          {getProp(baseObject || {}, ['goals', 'given'], 0)}:
          {getProp(baseObject || {}, ['goals', 'received'], 0)}
        </Td>
        <Td style={{ width: rem(30) }}>
          {getProp(baseObject || {}, ['points'], 0)}
        </Td>
        {currentCompetitionPart?.resultsTable?.results[0]?.stats.u17u19 && (
          <Td style={{ width: rem(30) }}>{i.stats?.u17u19}</Td>
        )}
        {currentCompetitionPart?.resultsTable?.results[0]?.stats.issf && (
          <>
            <Td style={{ width: rem(30) }}>{i.stats?.issf?.PM}</Td>
            <Td style={{ width: rem(30) }}>{i.stats?.issf?.FP}</Td>
          </>
        )}
        <Td style={{ width: rem(200) }}>
          <Condition>{getTeamCondition(baseArray, team as any)}</Condition>
        </Td>
      </StyledTr>
    );
  };

  const getResultLabel = (result: 'W' | 'D' | 'L') => {
    switch (result) {
      case 'W':
        return __('V');
      case 'D':
        return __('R');
      case 'L':
        return __('P');
      default:
        return '';
    }
  };

  const getTeamCondition = (
    results: IResultsTableItemMatch[],
    team?: {
      _id: string;
      name: string;
      displayName: string;
      organization?: { name: string };
    },
  ) =>
    [...(results || [])]
      .sort((a, b) => {
        if (
          isAfter(
            new Date(a.match?.startDate || ''),
            new Date(b.match?.startDate || ''),
          )
        ) {
          return -1;
        } else if (
          isAfter(
            new Date(b.match?.startDate || ''),
            new Date(a.match?.startDate || ''),
          )
        ) {
          return 1;
        }
        return 0;
      })
      .slice(0, 5)
      .map((m) => (
        <Tooltip
          key={`${m.team._id}-${m.matchId}`}
          hoverDelay={0}
          content={
            <div>
              <b>{m.score}</b> (
              {m.homeaway === 'home'
                ? team && team.organization?.name
                : m.team.name}
              &nbsp;-&nbsp;
              {m.homeaway === 'away'
                ? team && team.organization?.name
                : m.team.name}
              )
            </div>
          }
        >
          <Link
            to={getMatchBaseUrl({
              zvaz,
              zapas: m.match ? m.match.__issfId || m.matchId : m.matchId,
            })}
          >
            <div style={{ padding: `0 ${rem(2)}` }}>
              <ConditionItem result={m.result}>
                {getResultLabel(m.result)}
              </ConditionItem>
            </div>
          </Link>
        </Tooltip>
      ));

  const renderTableHeader = () => {
    if (
      currentCompetitionPart.rules &&
      currentCompetitionPart.rules.sport_sector === 'beachfutbal'
    ) {
      return (
        <Tr>
          <StickyTh
            style={{
              left: 0,
              width: rem(10),
              paddingLeft: rem(16),
              paddingRight: rem(4),
            }}
          />
          <StickyTh style={{ left: 30 }} />
          <StickyTh style={{ left: 68 }}>{__('Klub')}</StickyTh>
          <StickyTh style={{ padding: 0, left: 188 }} />
          <PaddedTh>
            <Tooltip hoverDelay={0} content={<div>{__('Zápasy')}</div>}>
              {__('Z')}
            </Tooltip>
          </PaddedTh>
          <PaddedTh>
            <Tooltip hoverDelay={0} content={<div>{__('Výhry')}</div>}>
              {__('V')}
            </Tooltip>
          </PaddedTh>
          <PaddedTh>
            <Tooltip
              hoverDelay={0}
              content={<div>{__('Výhry po predĺžení')}</div>}
            >
              {__('Vp')}
            </Tooltip>
          </PaddedTh>
          <PaddedTh>
            <Tooltip
              hoverDelay={0}
              content={<div>{__('Výhry po penáltach')}</div>}
            >
              {__('Vpen')}
            </Tooltip>
          </PaddedTh>
          <PaddedTh>{__('Skóre')}</PaddedTh>
          <PaddedTh>
            <Tooltip hoverDelay={0} content={<div>{__('Body')}</div>}>
              {__('B')}
            </Tooltip>
          </PaddedTh>
          <PaddedTh>{__('Forma')}</PaddedTh>
        </Tr>
      );
    }
    return (
      <Tr>
        <StickyTh
          style={{
            left: 0,
            width: rem(10),
            paddingLeft: rem(16),
            paddingRight: rem(4),
          }}
        />
        <StickyTh style={{ left: 30 }} />
        <StickyTh style={{ left: 68 }}>{__('Klub')}</StickyTh>
        <StickyTh style={{ padding: 0, left: 188 }} />
        <PaddedTh>
          <Tooltip hoverDelay={0} content={<div>{__('Zápasy')}</div>}>
            {__('Z')}
          </Tooltip>
        </PaddedTh>
        <PaddedTh>
          <Tooltip hoverDelay={0} content={<div>{__('Výhry')}</div>}>
            {__('V')}
          </Tooltip>
        </PaddedTh>
        <PaddedTh>
          <Tooltip hoverDelay={0} content={<div>{__('Remízy')}</div>}>
            {__('R')}
          </Tooltip>
        </PaddedTh>
        <PaddedTh>
          <Tooltip hoverDelay={0} content={<div>{__('Prehry')}</div>}>
            {__('P')}
          </Tooltip>
        </PaddedTh>
        <PaddedTh>{__('Skóre')}</PaddedTh>
        <PaddedTh>
          <Tooltip hoverDelay={0} content={<div>{__('Body')}</div>}>
            {__('B')}
          </Tooltip>
        </PaddedTh>
        {currentCompetitionPart?.resultsTable?.results[0]?.stats.u17u19 && (
          <PaddedTh>
            <Tooltip
              hoverDelay={0}
              content={<div>{__('Koeficient pre mládež')}</div>}
            >
              {__('K')}
            </Tooltip>
          </PaddedTh>
        )}
        {currentCompetitionPart?.resultsTable?.results[0]?.stats.issf && (
          <>
            <PaddedTh>
              <Tooltip
                hoverDelay={0}
                content={<div>{__('Plus mínus body')}</div>}
              >
                {__('+/-')}
              </Tooltip>
            </PaddedTh>
            <PaddedTh>
              <Tooltip hoverDelay={0} content={<div>{__('Fair Play')}</div>}>
                {__('FP')}
              </Tooltip>
            </PaddedTh>
          </>
        )}
        <PaddedTh>{__('Forma')}</PaddedTh>
      </Tr>
    );
  };

  const corrections = getCorrections(
    currentCompetitionPart.resultsTable?.results ?? [],
  );

  const resultsByPoints = getResultsByPoints(
    currentCompetitionPart?.resultsTable?.results || [],
    baseObjectName,
  );

  const renderTableResults = () => {
    let order = -1;
    return Object.keys(resultsByPoints)
      .map(Number)
      .sort((a, b) => {
        return a < b ? 1 : -1;
      })
      .map((pointsLevel) => {
        return sortResults(
          resultsByPoints[pointsLevel],
          baseObjectName,
          resultsByPoints[pointsLevel].length > 1,
        ).map((i) => {
          order++;
          return renderResultItem(i, order);
        });
      });
  };

  return (
    <ResultsTableWrapper>
      <Table>
        <Thead>{renderTableHeader()}</Thead>
        <Tbody>{renderTableResults()}</Tbody>
      </Table>
      <LegendWrapper>
        <Legend>
          <LegendItem>
            <ConditionItem result="W">{__('V')}</ConditionItem>
            <LegendItemLabel>{__('Výhra')}</LegendItemLabel>
          </LegendItem>
          <LegendItem>
            <ConditionItem result="D">{__('R')}</ConditionItem>
            <LegendItemLabel>{__('Remíza')}</LegendItemLabel>
          </LegendItem>
          <LegendItem>
            <ConditionItem result="L">{__('P')}</ConditionItem>
            <LegendItemLabel>{__('Prehra')}</LegendItemLabel>
          </LegendItem>
        </Legend>
      </LegendWrapper>
      {currentCompetitionPart?.publicComment && (
        <PublicComment>
          <Segment noBottomGutter secondary>
            {currentCompetitionPart.publicComment}
          </Segment>
        </PublicComment>
      )}
      {(corrections || []).length > 0 && (
        <PublicComment>
          <Segment noBottomGutter secondary>
            {corrections?.map((correction) => {
              return <div>{correction}</div>;
            })}
          </Segment>
        </PublicComment>
      )}
    </ResultsTableWrapper>
  );
};

export default Standings;
