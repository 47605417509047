import { rem } from 'polished';
import React from 'react';
import styled from '../../../../theme/styled-components';
import FootballResultsTable from './FootballResultsTable';
import HockeyResultsTable from './HockeyResultsTable';
import { IWidgetResultsTable } from './types';

const Wrapper = styled.div`
  clear: both;
  font-size: ${rem(14)};
  line-height: ${rem(14.4)};
  font-family: ${({ theme }) => theme.primaryFont.family };
`;

const Title = styled.h2`
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0 0 ${rem(16)} 0;
  padding: 0;
`;

interface IOwnProps {
  data: IWidgetResultsTable;
  renderEmpty?: (type: string) => React.ReactNode;
}

const WidgetResultsTable: React.FC<IOwnProps> = ({ data, renderEmpty }) => {
  if ((!data.competitionId || !data.partId) && renderEmpty) {
    return <>{renderEmpty('resultsTable')}</>;
  }

  const sport = data.data?.rules?.sport_sector;
  let table: React.ReactNode = null;
  switch (sport) {
    case 'ice-hockey':
      table = <HockeyResultsTable data={data} />;
      break;
    default:
      table = <FootballResultsTable data={data} />;
      break;
  }

  return (
    <Wrapper className="content-widget">
      {data.title && <Title>{data.title}</Title>}
      {table}
    </Wrapper>
  );
};

export default WidgetResultsTable;
