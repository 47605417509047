import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getListResults } from '@sportnet/redux-list';
import { getProp } from 'sportnet-utilities';
import { useAsyncData } from 'ssr-service';
import BodyRespAd from '../../../components/Ads/BodyRespAd';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import FutbalnetHomeTopArticlesGrid from '../../../components/Articles/FutbalnetHomeTopArticlesGrid';
import Icon from '../../../components/Icon';
import {
  ContextMarginSmallBottomStyles,
} from '../../../components/Layout';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import SectionBlockHeaderContainer from '../../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionName from '../../../components/Sections/SectionBlockHeader/SectionName';
import ShowMoreButton from '../../../components/ShowMoreButton';
import Tabber from '../../../components/Tabber';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { IArticle, RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import getMembersNameWithLink from '../../../utilities/getMembersNameWithLink';
import __ from '../../../utilities/__';
import { initializeOrSetListParams } from '../../App/actions';
import { loadListingArticlesList } from '../../Article/actions';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import { sectionsEntitiesSelector } from '../../Section/selectors';
import { currentMatchSelector } from '../selectors';
import MatchTimeLine from './MatchTimeLine';
import {
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../../Competition/selectors';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';
import shouldRenderMatchOverview from '../../../utilities/match/shouldRenderMatchOverview';
import PrintButton from '../../FutbalnetPrint/PrintButton';
import getMatchBaseUrl from '../../../utilities/getMatchBaseUrl';

const PrintButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin: ${rem(16)} 0 ${rem(24)} 0;
`;

const Th2 = styled(Th)`
  text-transform: none;
`;

enum Tab {
  QUICK_VIEW = 'Q',
  DETAIL_VIEW = 'D',
}

const mapStateToProps = (state: RootState) => ({
  articles: (getListResults(config.LIST_MATCH_ARTICLES)(state) ||
    []) as IArticle[],
  sectionsById: sectionsEntitiesSelector(state),
  match: currentMatchSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competition: currentCompetitionSelector(state),
});

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadListingArticlesList,
};

const TabPane = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

const TabPaneWrapper = styled.div`
  padding-bottom: ${rem(24)};
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

const Tables = styled.div`
  display: flex;
  flex-direction: column;
  ${ContextMarginSmallBottomStyles}
`;

const MatchPreviewWrapper = styled.div`
  ${ContextMarginSmallBottomStyles}
`;

const TablesLayout = styled.div`
  display: flex;
  flex-direction: column;

  margin: ${rem(-16)};
  > * {
    margin: ${rem(16)};
    width: auto;
  }

  ${mb('m')} {
    > * {
      flex-basis: 50%;
    }
    flex-direction: row;
  }
`;

const PaddedIcon = styled(Icon)`
  margin-right: ${rem(12)};
`;

const PrintWrapper = styled.div`
  display: flex;
`;

interface IOwnProps {
  isActive: boolean;
  print?: boolean;
}

type IProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  IOwnProps;

const MatchOverview: React.FC<IProps> = ({
  initializeOrSetListParams,
  loadListingArticlesList,
  articles,
  sectionsById,
  match,
  isActive,
  print,
  params: { zvaz },
}) => {
  const [activeTab, setActiveTab] = React.useState<Tab>(Tab.QUICK_VIEW);
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_MATCH_ARTICLES,
      params: {
        offset: 0,
        limit: config.LIST_MATCH_ARTICLES_LIMIT,
        listingId: config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID,
      },
    });
    await loadListingArticlesList(config.LIST_MATCH_ARTICLES);
  }, [initializeOrSetListParams, loadListingArticlesList]);

  // ak sa zapas nezacal, nerenderujem rychly ani detailny prehlad

  const renderTabPanes = () => (
    <TabPaneWrapper>
      <TabPane active={activeTab === Tab.QUICK_VIEW}>
        <MatchTimeLine goalsOnly={true} />
      </TabPane>
      <TabPane active={activeTab === Tab.DETAIL_VIEW}>
        <MatchTimeLine />
      </TabPane>
    </TabPaneWrapper>
  );

  const getTableValue = (
    val?: string | number,
    suffix: string = '',
    ifnull: string = '-',
  ) => {
    return val !== undefined && val !== '' ? `${val} ${suffix}` : ifnull;
  };

  const renderSportGround = (isPrint?: boolean) => {
    return (
      <Table>
        {!isPrint && (
          <Thead>
            <Tr>
              <Th2 colSpan="2">{__('Štadión')}</Th2>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <Tr>
            <Td>{__('Dĺžka')}</Td>
            <Td>
              {getTableValue(
                match?.sportGround?.additionalData?.length,
                'metrov',
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('Šírka')}</Td>
            <Td>
              {getTableValue(
                match?.sportGround?.additionalData?.width,
                'metrov',
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('Miest na sedenie')}</Td>
            <Td>
              {getTableValue(
                match?.sportGround?.additionalData?.seatingCapacity,
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('Hosťovský sektor')}</Td>
            <Td>
              {getTableValue(match?.sportGround?.additionalData?.guestCapacity)}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('VIP')}</Td>
            <Td>
              {getTableValue(
                match?.sportGround?.additionalData?.vipSeatingCapacity,
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('Celková kapacita')}</Td>
            <Td>
              {getTableValue(match?.sportGround?.additionalData?.totalCapacity)}
            </Td>
          </Tr>
          <Tr>
            <Td>{__('Majiteľ')}</Td>
            <Td>{getTableValue(match?.sportGround?.owner)}</Td>
          </Tr>
          <Tr>
            <Td>{__('Adresa')}</Td>
            <Td>{`${match?.sportGround?.street || ''} ${
              match?.sportGround?.number
            }, ${match?.sportGround?.zip} ${match?.sportGround?.city}`}</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  };

  const renderNote = (isPrint?: boolean) => {
    if (!match?.protocol?.note) {
      return null;
    }
    return (
      <Table>
        {!isPrint && (
          <Thead>
            <Tr>
              <Th2 colSpan={2}>{__('Poznámka k priebehu')}</Th2>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <Tr>
            <Td colSpan={2}>{match.protocol.note}</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  };

  const renderManagers = (isPrint?: boolean) => {
    return (
      <Table>
        {!isPrint && (
          <Thead>
            <Tr>
              <Th2 colSpan={2}>{__('Delegované osoby')}</Th2>
            </Tr>
          </Thead>
        )}
        <Tbody>
          {match && (match?.managers || []).length > 0 ? (
            (match?.managers || []).map((manager, idx) => (
              <Tr key={idx}>
                <Td>{manager.type.value}</Td>
                <Td>
                  {getMembersNameWithLink(
                    manager.user.name,
                    manager.user.__issfId || manager.user._id,
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="2">
                {__('Zápas medzi')} {getProp(match, ['teams', 0, 'name'])}{' '}
                a&nbsp;
                {getProp(match, ['teams', 1, 'name'])}{' '}
                {__('nemá pridelené žiadne delegované osoby')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  };

  if (print) {
    return (
      <PrintWrapper>
        {renderManagers(print)}
        {renderSportGround(print)}
      </PrintWrapper>
    );
  }

  return (
    <>
      {isActive && <TopAd />}
      {isActive && <BottomPaddedBigAd name="big_1" />}

      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {isActive && <BodyRespAd name="body_1" />}
          {match && shouldRenderMatchOverview(match) && (
            <MatchPreviewWrapper>
              <Tabber
                active={activeTab}
                inWidget={true}
                onChange={(i) => {
                  if (i.value) {
                    setActiveTab(i.value as Tab);
                  }
                }}
                centered
                tabs={[
                  { label: __('Rýchly prehľad'), value: Tab.QUICK_VIEW },
                  { label: __('Detailný prehľad'), value: Tab.DETAIL_VIEW },
                ]}
              />
              {renderTabPanes()}
            </MatchPreviewWrapper>
          )}
          {!!match?.protocol?.note && (
            <div style={{ marginBottom: rem(32) }}>{renderNote()}</div>
          )}
          <Tables>
            <TablesLayout>
              {renderManagers()}
              {renderSportGround()}
            </TablesLayout>
            <PrintButtonWrapper>
              {match && (
                <PrintButton
                  href={`${getMatchBaseUrl({
                    zvaz,
                    zapas: match.__issfId || match._id,
                  })}print/`}
                  gtmEventLabel='zapas'
                />
              )}
            </PrintButtonWrapper>
          </Tables>
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <SectionBlockHeaderContainer
            left={
              <>
                <PaddedIcon name="futbal" color="#CF2528" size={32} />
                <SectionName>{__('Futbalnet')}</SectionName>
              </>
            }
          />
          <FutbalnetHomeTopArticlesGrid
            articles={articles}
            sectionsById={sectionsById}
          />
          <ShowMoreButton to="/futbalnet/" text={__('Zobraziť viac')} />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            {isActive && <SideAd name="side_1" />}
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      {isActive && (
        <>
          <MegaboardAd />
          <FutbalnetMatchBreadcrumbs />
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MatchOverview) as unknown as React.FC<IOwnProps>;
