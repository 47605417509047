import Image from '@sportnet/ui/lib/Image';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import getProp from 'sportnet-utilities/lib/getProp';
import { css } from 'styled-components';
import ContentContext from '@sportnet/content/common/ContentContext';
import { ITeam } from '@sportnet/content/library/Competitions';
import styled from '@sportnet/content/theme/styled-components';
import {
  FALLBACK_LOGO_IMAGE,
  getTeamLogoSrc,
  getTeamName,
} from '@sportnet/content/view/widgets/MatchesList/utilities';
import { ITeam as ITeam2 } from '../../../../library/Competitions'
import getTeamUrl from '../../../../utilities/events/getTeamUrl';

interface ITeamProps {
  align: string;
  compact: boolean;
}

const TeamLogo = styled(Image)`` as any;

export const TeamName = styled.div`
  font-size: ${rem(14)};
  font-weight: bold;
  color: ${({ theme }) => (theme as any).app.textColor};
  text-transform: uppercase;
  padding: ${rem(10)};
  text-align: center;
`;

const TeamWrapper = styled.div`
  display: flex;
  width: 30%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

export const Team = styled.div<ITeamProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ align, compact }) => {
    if (compact && align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: right;
        width: 40%;
        ${TeamName} {
          text-align: right !important;
        }
      `;
    } else if (compact && align === 'right') {
      return css`
        flex-direction: row;
        width: 40%;
        justify-content: left;
        ${TeamName} {
          text-align: left !important;
        }
      `;
    }
    return css``;
  }}
`;

const TeamLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: ${rem(72)};
  height: ${rem(72)};
  ${mb('s')} {
    width: ${rem(80)};
    height: ${rem(80)};
  }
  background: white;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  img {
    padding: ${rem(8)};
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const TeamDetailLink = styled.a<ITeamProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ align, compact }) => {
    if (compact && align === 'left') {
      return css`
        flex-direction: row-reverse;
        justify-content: right;
        width: 40%;
        ${TeamName} {
          text-align: right !important;
        }
      `;
    } else if (compact && align === 'right') {
      return css`
        flex-direction: row;
        width: 40%;
        justify-content: left;
        ${TeamName} {
          text-align: left !important;
        }
      `;
    }
    return css``;
  }}
`;

interface IOwnProps {
  matchAppSpace: string;
  competitionId: string;
  align: 'left' | 'right';
  team: ITeam | null;
  compact: boolean;
}

const TeamComponent: React.FC<IOwnProps> = ({
  matchAppSpace,
  competitionId,
  align,
  team,
  compact,
}) => {
  const context = React.useContext(ContentContext);
  const teamLogoSrc = getProp(
    context,
    ['helpers', 'getTeamLogoSrc'],
    getTeamLogoSrc,
  )(matchAppSpace, team);
  const teamName = getProp(
    context,
    ['helpers', 'getTeamName'],
    getTeamName,
  )(matchAppSpace, team);

  const teamUrl = team
    ? getTeamUrl(team as unknown as ITeam2, competitionId)
    : '';

  const renderTeam = () => (
    <>
      <TeamLogoWrapper>
        <TeamLogo
          className="content-widget-matchOverview-teamLogo"
          title={teamName}
          alt={teamName}
          src={teamLogoSrc}
          fallbackSrc={FALLBACK_LOGO_IMAGE}
        />
      </TeamLogoWrapper>
      <TeamName className="content-widget-matchOverview-teamName">
        {teamName}
      </TeamName>
    </>
  );

  return (
    <TeamWrapper>
      {teamUrl ? (
        <TeamDetailLink
          href={teamUrl}
          align={align}
          compact={!!compact}
        >
          {renderTeam()}
        </TeamDetailLink>
      ) : (
        <Team align={align} compact={!!compact}>
          {renderTeam()}
        </Team>
      )}
    </TeamWrapper>
  );
};

export default React.memo(TeamComponent);
