import React from 'react';
import { formatAdParams, IAdParams } from '../AdProvider';
import AdManagerContext from './AdManagerContext';

type IProps = Partial<IAdParams>;

const AdManagerContextProvider: React.FC<IProps> = ({
  children,
  ...currentParams
}) => {
  const key = formatAdParams(currentParams as any);
  const value = React.useMemo(() => currentParams, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AdManagerContext.Provider value={value}>
      {children}
    </AdManagerContext.Provider>
  );
};

export default AdManagerContextProvider;
