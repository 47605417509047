import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { IMatch } from '../../library/Competitions';
import getMatchHomeTeam from '../match/getMatchHomeTeam';
import getMatchAwayTeam from '../match/getMatchAwayTeam';
import { findInThirdPartyDataMap } from '../../thirdPartyResultsMapApi';
import { ThirdPartyGrouppedEvents } from '../../library/thirdPartyResults';
import findEventInThirdPartyResults from './findEventInThirdPartyResults';

const getThirdPartyEventBySportnetMatch = (
  match?: IMatch,
  thirdPartyResultsData?: ThirdPartyGrouppedEvents[],
) => {
  if (!match || !thirdPartyResultsData) {
    return undefined;
  }

  const startDate = match?.startDate ? new Date(match.startDate) : undefined;
  const competitionIssfId = match?.competition?.__issfId;
  const homeTeam = getMatchHomeTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );
  const awayTeam = getMatchAwayTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );

  const thirdPartyResultsParams =
    competitionIssfId && homeTeam && awayTeam
      ? findInThirdPartyDataMap({
          issfSutazId: competitionIssfId,
          teamHomeId: homeTeam._id,
          teamAwayId: awayTeam._id,
        })
      : undefined;

  const thirdPartyEvent =
    startDate && thirdPartyResultsParams && Array.isArray(thirdPartyResultsData)
      ? findEventInThirdPartyResults(
          {
            startDate,
            leagueCup: thirdPartyResultsParams.leagueCup,
            thirdPartyHomeTeamId: thirdPartyResultsParams.thirdPartyHomeTeamId,
            thirdPartyAwayTeamId: thirdPartyResultsParams.thirdPartyAwayTeamId,
          },
          thirdPartyResultsData,
        )
      : undefined;

  return thirdPartyEvent;
};

export default getThirdPartyEventBySportnetMatch;
