import * as React from 'react';

export default (size: string, color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.373 24" height="100%">
    <path
      fill={color}
      d="M0,21.724H0c6.126,3.514,19.145,4.5,19.189-6.126,0-5.856-9.5-5.766-9.5-8.153,0-2.883,6.037.269,6.622.721.81-2.027,1.668-4.054,2.521-6.081C12.883-1.113,1.261-1.2,1.261,8.438c0,5.81,9.055,5.495,9.055,8.018,0,3.468-7.253-.946-7.659-1.216C1.8,17.4.9,19.564,0,21.726Zm43.155,1.983h8.2L47.66.508h-8.6L35.722,13.031h-.137L32.434.508h-8.6l-3.964,23.2h8.243L29.46,10.282l3.695,13.425h4.954L41.8,10.282l1.352,13.425Zm10.494,0v.044H69.37V17.445H61.848V15.012h6.666V9.065H61.848V6.813h7.343V.506H53.649v23.2Z"
      transform="translate(0.002 -0.147)"
    />
  </svg>
);
