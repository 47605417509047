import { rem, transparentize } from 'polished';
import React from 'react';
import { UrlType } from '../../library/Url';
import styled from '../../theme/styled-components';
import DropDown from '../DropDown';
import { IDropDownPosition } from '../DropDown/library';
import Link from '../Link';

interface ItemLinkProps {
  color?: string;
  activeColor?: string;
  faded?: boolean;
}
const ItemLink = ({ color, activeColor, faded, ...rest }: ItemLinkProps) => (
  <Link {...rest} />
);
const Item = styled(ItemLink)<ItemLinkProps>`
  cursor: pointer;
  border: none;
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  text-decoration: none;
  color: inherit;
  outline: none;
  background: none;
  margin: 0;
  padding: ${rem(12)} ${rem(16)};
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  flex-shrink: 0;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme, color }) => color || theme.app.textColor};
  opacity: ${({ faded }) => faded ? 0.64 : 1};
  :hover,
  :active,
  :focus {
    opacity: 1;
    color: ${({ theme, activeColor }) => activeColor || theme.color.primary};
    background-color: ${({ theme, activeColor }) =>
      transparentize(0.96, activeColor || theme.color.primary)};
  }
  text-align: left;
`;

export interface IItem {
  label: string;
  url?: UrlType;
  onClick?: (item: IItem) => void;
  [key: string]: any;
}

interface OwnProps {
  opened: boolean;
  onClose: () => void;
  items: any[];
  color?: string;
  activeColor?: string;
  className?: string;
  position?: IDropDownPosition;
}

const ItemsDropDown: React.FC<OwnProps> = ({
  className,
  opened,
  onClose,
  items,
  children,
  color,
  activeColor,
  position,
}) => {
  function renderItem(item: IItem, idx: number) {
    return (
      <Item
        key={idx}
        to={item.url}
        onClick={(e: any) => {
          if (!item.url) {
            e.preventDefault();
          }
          item.onClick?.(item);
          onClose();
        }}
        color={color}
        activeColor={activeColor}
        faded={item.faded}
      >
        {item.label}
      </Item>
    );
  }

  const body = <>{items.map(renderItem)}</>;

  return (
    <DropDown
      className={className}
      opened={opened}
      onClose={onClose}
      body={body}
      position={position}
    >
      {children}
    </DropDown>
  );
};

export default ItemsDropDown;
