import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { IMatch } from './../library/Competitions';

export default (
  match: IMatch | IWidgetMatchesListMatchItem,
  { homeTeamId, awayTeamId }: { homeTeamId: string; awayTeamId: string },
) => {
  let PPWinnerTeamId: any = null;
  let SNWinnerTeamId: any = null;
  if ((match.penaltiesScore || []).length) {
    const [homeTeamSNGoals, awayTeamSNGoals] = match.penaltiesScore || [];
    SNWinnerTeamId =
      homeTeamSNGoals > awayTeamSNGoals ? homeTeamId : awayTeamId;
  } else if (match.timer && match.timer['1ET']) {
    const [firstPartHomeTeamPPGoals, firstPartAwayTeamPPGoals] =
      match.scoreByPhases ? match.scoreByPhases[2] || [] : [];
    const [secondPartHomeTeamPPGoals, secondPartAwayTeamPPGoals] =
      match.scoreByPhases ? match.scoreByPhases[3] || [] : [];
    PPWinnerTeamId =
      firstPartHomeTeamPPGoals + secondPartHomeTeamPPGoals >
      firstPartAwayTeamPPGoals + secondPartAwayTeamPPGoals
        ? homeTeamId
        : awayTeamId;
  }
  return { PPWinnerTeamId, SNWinnerTeamId };
};
