import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from '../../theme/styled-components';

export default styled.h1`
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(16)};
  line-height: ${rem(28.8)};
  ${mb('s')} {
    font-size: ${rem(24)};
  }
  font-weight: 600;
  overflow-wrap: break-word;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`;
