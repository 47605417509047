import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { css } from '../../../../theme/styled-components';

export default css`
  .content-widget-sectionlist-tabs {
    background: #f9f9f9;
    border-radius: ${rem(8)};
    display: flex;
    flex-wrap: wrap;
    max-width: max-content;
    padding: 0;
    border: none;

    .widget-sectionlist-link {
      font-family: inherit;
      font-size: ${rem(14)};
      line-height: ${rem(16.8)};
      font-weight: 600;
      padding: ${rem(15)} ${rem(24)};
      margin: 0;
      color: inherit;
      border-radius: ${rem(8)};
      background: transparent;
      border: ${rem(1)} solid transparent;
      transition: all 250ms ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
      ${mb(410)} {
        flex-basis: 50%;
      }
      ${mb('s')} {
        flex-basis: auto;
      }
    }

    .widget-sectionlist-link-active {
      background: #fff;
      border: ${rem(1)} solid #ddd;
    }
  }
`;
