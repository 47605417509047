import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';
import Icon from '../Icon';

const Wrapper = styled.span<{ size: number }>`
  height: ${({ size }) => rem(size)};
  width: ${({ size }) => rem(size)};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

type IOwnProps = {
  src?: string;
  alt?: string;
  size?: number;
  className?: string;
};

type IProps = IOwnProps;

const OrganizationLogo: React.FC<IProps> = ({
  src,
  alt,
  size = 24,
  className,
}) => {
  if (!src) {
    return <Icon className={className} name="logo-placeholder" size={size} />;
  }

  return (
    <Wrapper size={size} className={className}>
      <Image alt={alt} src={src} loading="lazy" />
    </Wrapper>
  );
};

export default OrganizationLogo;
