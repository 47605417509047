import { CustomThunkAction } from '../../configureStore';
import { ISection } from '../../library/App';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';
import { loadSection } from '../Section/actions';

export const loadFutbalnetSection = (
  idOrUniqId: string,
): CustomThunkAction<Promise<ISection>> => {
  return (dispatch, getState) => {
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
    return dispatch(loadSection({ appSpace, idOrUniqId }));
  };
};
