import { __ } from '@sportnet/ui/lib/utilities';
import { endOfDay, startOfDay } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import BodyAd from '../../../components/Ads/BodyAd';
import BodyRespAd from '../../../components/Ads/BodyRespAd';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  BottomPaddedSidebarLayout,
  ResponsiveSidebar,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import MatchesList from '../../../components/MatchesList';
import OrgProfileWidget from '../../../components/OrgProfileWidget';
import Spacer from '../../../components/Spacer';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import PartnersBlock from '../../../containers/PartnersBlock';
import RelatedArticles from '../../../containers/RelatedArticles';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import getDefaultClubCompetition from '../../../utilities/getDefaultClubCompetition';
import getTeamBaseUrl from '../../../utilities/getTeamBaseUrl';
import getClubBaseUrl from '../../../utilities/getClubBaseUrl';
import { initializeOrSetListParams } from '../../App/actions';
import {
  pagesSettingsSelector,
  staticContentSelector,
} from '../../App/selectors';
import { loadArticlesList } from '../../Article/actions';
import { cumulatedListArticlesSelector } from '../../Article/selectors';
import {
  currentCompetitionListFutureMatchesSelector,
  currentCompetitionListPastMatchesSelector,
  currentCompetitionListTodayMatchesSelector,
  currentCompetitionPartSelector,
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../../Competition/selectors';
import { loadClubMatches } from '../actions';
import { currentClubCompetitionsSelector } from '../selectors';
import SectionBlockHeaderContainer from '../../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionNameSmall from '../../../components/Sections/SectionBlockHeader/SectionNameSmall';

const Title = styled.div`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  padding: ${rem(24)} 0;
`;

const mapStateToProps = (state: RootState) => {
  const appSpace = getCurrentCompetitionAppSpaceSelector(state);
  return {
    pastListMatches: currentCompetitionListPastMatchesSelector(state),
    futureListMatches: currentCompetitionListFutureMatchesSelector(state),
    todayListMatches: currentCompetitionListTodayMatchesSelector(state),
    pastListMatchesNextOffset: getListNextOffset(
      config.LIST_PAST_COMPETITION_MATCHES
    )(state),
    futureListMatchesNextOffset: getListNextOffset(
      config.LIST_FUTURE_COMPETITION_MATCHES
    )(state),
    todayListMatchesNextOffset: getListNextOffset(
      config.LIST_TODAY_COMPETITION_MATCHES
    )(state),
    currentAppSpace: appSpace,
    isFetchingListPastMatches: isCommiting(
      config.LIST_PAST_COMPETITION_MATCHES
    )(state),
    isFetchingListFutureMatches: isCommiting(
      config.LIST_FUTURE_COMPETITION_MATCHES
    )(state),
    isFetchingListTodayMatches: isCommiting(
      config.LIST_TODAY_COMPETITION_MATCHES
    )(state),
    competitions: currentClubCompetitionsSelector(state),
    isFetchingListMatches:
      isCommiting(config.LIST_PAST_COMPETITION_MATCHES)(state) ||
      isCommiting(config.LIST_FUTURE_COMPETITION_MATCHES)(state) ||
      isCommiting(config.LIST_TODAY_COMPETITION_MATCHES)(state),
    organizationProfile: currentOrganizationProfileSelector(state),
    currentCompetitionPart: currentCompetitionPartSelector(state),
    numOfArticles: cumulatedListArticlesSelector(
      state,
      config.LIST_CLUB_ARTICLES
    ).length,
    isLoadingArticles:
      (isCommiting(config.LIST_CLUB_ARTICLES)(state) &&
        cumulatedListArticlesSelector(state, config.LIST_CLUB_ARTICLES)
          .length === 0) ||
      false,
    staticContentPartners: staticContentSelector(state, {
      appSpace,
      cid: 'partneri',
    }),
    staticContentPartnersUp: staticContentSelector(state, {
      appSpace,
      cid: 'partneri-up',
    }),
    pagesSettings: pagesSettingsSelector(state, appSpace),
  };
};

type IOwnProps = {};

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
  } & RouteProps<{ klub: string }>;

const ClubOverviewDetail: React.FC<Props> = ({
  dispatch,
  competitions,
  currentAppSpace,
  pastListMatches,
  futureListMatches,
  todayListMatches,
  pastListMatchesNextOffset,
  todayListMatchesNextOffset,
  futureListMatchesNextOffset,
  isFetchingListFutureMatches,
  isFetchingListMatches,
  isFetchingListPastMatches,
  isFetchingListTodayMatches,
  organizationProfile,
  currentCompetitionPart,
  numOfArticles,
  isLoadingArticles,
  params: { klub },
  staticContentPartners,
  staticContentPartnersUp,
  pagesSettings,
}) => {
  const handleLoadMoreArticlesWithOffset = React.useCallback(
    async (offset: number) => {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_CLUB_ARTICLES,
          params: {
            offset,
            limit: config.LIST_CLUB_ARTICLES_LIMIT,
            appSpace: currentAppSpace,
          },
        })
      );
      await dispatch(loadArticlesList(config.LIST_CLUB_ARTICLES));
    },
    [currentAppSpace, dispatch]
  );

  const defaultValues = getDefaultClubCompetition(competitions);
  let qParams = '';
  if (defaultValues) {
    qParams = `?sutaz=${defaultValues.competitionId}`;
  }

  const partnersUpContent = staticContentPartnersUp?.content || [];
  const partnersContent = staticContentPartners?.content || [];

  const social = pagesSettings?.social;

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <RempIntegration destroy />
      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {(isFetchingListMatches &&
            todayListMatches.length +
              futureListMatches.length +
              pastListMatches.length ===
              0) ||
          isLoadingArticles ? (
            <Loading />
          ) : (
            <>
              {numOfArticles > 0 && (
                <>
                  <SectionBlockHeaderContainer
                    left={
                      <SectionNameSmall>{__('Správy klubu')}</SectionNameSmall>
                    }
                  />
                  <RelatedArticles
                    listName={config.LIST_CLUB_ARTICLES}
                    onLoadMoreWithOffset={handleLoadMoreArticlesWithOffset}
                  />
                </>
              )}
              <PartnersBlock content={partnersUpContent} />
              <BodyRespAd name="body_1" />
              <Spacer size={4}>
                {!!todayListMatches.length && (
                  <div>
                    <Title>{__('Dnešné zápasy')}</Title>
                    <MatchesList
                      matches={todayListMatches}
                      isFetching={isFetchingListTodayMatches}
                      {...((!!todayListMatchesNextOffset ||
                        isFetchingListTodayMatches) && {
                        onLoadMore: () => {
                          dispatch(
                            loadClubMatches({
                              listName: config.LIST_TODAY_COMPETITION_MATCHES,
                              dateAfter: startOfDay(new Date()),
                              dateBefore: endOfDay(new Date()),
                              offset: todayListMatchesNextOffset || 0,
                              limit: config.LIST_COMPETITION_MATCHES_LIMIT,
                            })
                          );
                        },
                      })}
                    />
                  </div>
                )}
                {!!futureListMatches.length && (
                  <div>
                    <Title>{__('Najbližšie zápasy')}</Title>
                    <MatchesList
                      matches={futureListMatches}
                      isFetching={isFetchingListFutureMatches}
                      showMoreLink={`${getTeamBaseUrl({
                        klub,
                        teamId: defaultValues?.teamId || '',
                      })}program/${qParams}`}
                      tableLink={`${getTeamBaseUrl({
                        klub,
                        teamId: defaultValues?.teamId || '',
                      })}tabulky/${qParams}`}
                    />
                  </div>
                )}
                <BodyAd name="body_2" />
                {!!pastListMatches.length && (
                  <div>
                    <Title>{__('Posledné výsledky')}</Title>
                    <MatchesList
                      matches={pastListMatches}
                      isFetching={isFetchingListPastMatches}
                      showMoreLink={`${getTeamBaseUrl({
                        klub,
                        teamId: defaultValues?.teamId || '',
                      })}${qParams}`}
                      tableLink={`${getTeamBaseUrl({
                        klub,
                        teamId: defaultValues?.teamId || '',
                      })}tabulky/${qParams}`}
                    />
                  </div>
                )}
              </Spacer>
            </>
          )}
          <ResponsiveSidebar>
            <OrgProfileWidget
              organizationProfile={organizationProfile}
              social={social}
            />
          </ResponsiveSidebar>
          <PartnersBlock content={partnersContent} title={__('Partneri')} />
        </SidebarLayoutContent>
        <Sidebar>
          <OrgProfileWidget
            organizationProfile={organizationProfile}
            social={social}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>
      <MegaboardAd />
      {organizationProfile?.name && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: organizationProfile.name,
              url: getClubBaseUrl({ klub }),
            },
          ]}
        />
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ClubOverviewDetail) as unknown as React.FC<IOwnProps>;
