import React from 'react';
import { browserHistory } from 'react-router';

const ScrollToTop: React.FC<{}> = () => {
  React.useEffect(() => {
    let prevHref = window.location.href;
    let prevPathname = window.location.pathname;

    const unListen = browserHistory.listen(() => {
      if (prevPathname !== window.location.pathname) {
        // if navigated to new page - instant scroll to top
        window.scroll(0, 0);
      } else if (prevHref === window.location.href) {
        // if navigated to same page as active page - instant scroll to top
        window.scroll(0, 0);
      }
      prevPathname = window.location.pathname;
      prevHref = window.location.href;
    });
    return () => {
      unListen();
    };
  }, []);

  return null;
};

export default ScrollToTop;
