import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M17,22c-0.6,0-1-0.4-1-1v-2c0-1.7-1.3-3-3-3H5c-1.7,0-3,1.3-3,3v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2
    c0-2.8,2.2-5,5-5h8c2.8,0,5,2.2,5,5v2C18,21.6,17.6,22,17,22z M14,7c0-2.8-2.2-5-5-5S4,4.2,4,7s2.2,5,5,5S14,9.8,14,7z M12,7
    c0,1.7-1.3,3-3,3S6,8.7,6,7s1.3-3,3-3S12,5.3,12,7z M24,21v-2c0-2.3-1.5-4.3-3.8-4.8c-0.5-0.1-1.1,0.2-1.2,0.7
    c-0.1,0.5,0.2,1.1,0.7,1.2c1.3,0.3,2.2,1.5,2.3,2.9v2c0,0.6,0.4,1,1,1S24,21.6,24,21z M16.2,11.8c1.8-0.5,3.2-1.8,3.6-3.6
    c0.7-2.7-0.9-5.4-3.6-6.1C15.7,2,15.2,2.3,15,2.9c-0.1,0.5,0.2,1.1,0.7,1.2c1.6,0.4,2.6,2,2.2,3.7c-0.3,1.1-1.1,1.9-2.2,2.2
    c-0.5,0.1-0.9,0.7-0.7,1.2c0.1,0.5,0.5,0.8,1,0.8C16.1,11.9,16.2,11.9,16.2,11.8z"
    />
  </svg>
);
