import React from 'react';
import AnimatedPanes from '../../components/AnimatedPanes';
import AnimatedPanesWrapper from '../../components/AnimatedPanes/AnimatedPanesWrapper';
import { IPane } from '../../components/AnimatedPanes/types';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import useDropdownHeight from '../../hooks/useDropdownHeight';

type IOwnProps = {
  xPosition: number;
  active: boolean;
  panes: IPane[];
  onClose: () => void;
  header?: string;
};

type IProps = IOwnProps;

const MultilevelDropdown: React.FC<IProps> = ({
  xPosition,
  active,
  panes,
  onClose,
  header,
}) => {
  const { height, getHeight } = useDropdownHeight(active);

  return (
    <FutbalnetMenu.PositionedDropdown
      xPosition={xPosition}
      active={active}
      onClose={onClose}
    >
      <AnimatedPanesWrapper style={{ height }}>
        <AnimatedPanes
          panes={panes}
          header={header}
          getHeight={getHeight}
          active={active}
          unmount
        />
      </AnimatedPanesWrapper>
    </FutbalnetMenu.PositionedDropdown>
  );
};

export default React.memo(MultilevelDropdown);
