import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import Link from '../../../components/Link';

const StyledLink = styled(Link)`
  border: none;
  background-color: white;
  display: flex;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.color.darkBorder};
  .icon {
    width: auto;
    height: ${rem(12)};
    ${mb('s')} {
      height: ${rem(16)};
    }
  }
`;

type OwnProps = { className?: string };

export const SmeLogoLink: React.FC<OwnProps> = ({ className }) => (
  <StyledLink
    className={className}
    to="https://www.sme.sk/"
    target="_blank"
    title="SME"
    rel="noopener noreferrer"
  >
    <Icon name="sme" color="currentColor" size={12} />
  </StyledLink>
);
