import React from 'react';

export default (size: string, color: string) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={size}>
      <path
        fill={color}
        d="M13.3,14.6c-0.3,0-0.6-0.3-0.6-0.6v-1.3c0-1.1-0.9-2-2-2H5.3c-1.1,0-2,0.9-2,2V14c0,0.3-0.3,0.6-0.6,0.6
	S2,14.3,2,14v-1.3c0-1.8,1.5-3.3,3.3-3.3h5.3c1.8,0,3.3,1.5,3.3,3.3V14C14,14.3,13.7,14.6,13.3,14.6z M11.3,4.7
	c0-1.8-1.5-3.3-3.3-3.3S4.7,2.9,4.7,4.7S6.2,8,8,8S11.3,6.5,11.3,4.7z M10,4.7c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S10,3.5,10,4.7z
	"
      />
    </svg>
  );
};
