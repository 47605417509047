import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import React from 'react';
import ListBodyAd from '../../Ads/ListBodyAd';
import { IAdPosition } from '../../Ads/AdProvider';

const SpacedListBodyAd = styled(ListBodyAd)`
  ${({ theme }) => css`
    width: calc(100% + ${rem(theme.app.padding * 2)});
    background-color: #f1f1f1;
    margin-left: ${rem(-theme.app.padding)};
    margin-top: ${rem(32)};
    margin-bottom: ${rem(32)};
    margin-right: 0;
  `};
  ${mb('s')} {
    margin-top: ${rem(56)};
    margin-bottom: ${rem(56)};
  }
`;

/**
 * Pouziva sa v layoute sekcii medzi obsahom a vylistom clankov.
 */
const SectionBodyAd: React.FC<{ name?: IAdPosition }> = ({
  name = 'list_body_2',
}) => <SpacedListBodyAd name={name} />;

export default SectionBodyAd;
