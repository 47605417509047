import { commit, getListParameters } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceListMembersProps from '../../utilities/reduceListMembersProps';
import { updateEntities } from '../App/actions';

const create = actionCreatorFactory('MEMBERS');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const loadMembers = createAsync<
  {
    limit?: number;
    q?: string;
    page?: number;
    offset?: number;
    appSpace?: string;
  },
  void
>('LOAD_MEMBERS', async (_, dispatch, getState, { CoreApi }) => {
  return dispatch(
    commit.action({
      listName: config.LIST_SEARCH_MEMBERS,
      load: async () => {
        const parameters = getListParameters(config.LIST_SEARCH_MEMBERS)(
          getState(),
        );
        const appSpace = parameters.appSpace || config.OWNER_PPO;
        const params: {
          offset: number;
          q?: string;
        } = {
          offset: 0,
        };

        if (parameters.page && parameters.page > 1) {
          params.offset = (parameters.page - 1) * parameters.limit;
        }

        if (parameters.q || parameters.q === '') {
          params.q = parameters.q;
        }

        const { users, limit, offset, total, next_offset } =
          await CoreApi.organizationPPOUsers(appSpace, params);

        // POZOR: `reduceListMembersProps` filtruje `bio` podla PPO futbalsfz.sk
        // a rovnako `regnrs`
        const { entities, results } = normalizeEntities(
          'members',
          (users || []).map(reduceListMembersProps),
        );

        dispatch(updateEntities(entities));

        return {
          results,
          limit: limit!,
          offset: offset!,
          total: total!,
          nextOffset: next_offset || null,
        };
      },
    }),
  );
});
