import styled, { useTheme } from 'styled-components';
import { Link } from 'react-router';
import Icon from '../../../components/Icon';
import { BottomItmStyles } from './GossipShareDropdown';
import { ITheme } from '../../../theme/theme';
import { getNumberOfCommetsLabel } from '../utils';
import { rem } from 'polished';
import React from 'react';

const BottomItemLink = styled(Link)`
  ${BottomItmStyles}
`;

const BottomItemDiv = styled.div`
  ${BottomItmStyles}
`;

const LabelWrapper = styled.span`
  margin-top: ${rem(-2)};
`;

interface OwnProps {
  discussionPostCount: number;
  discussionIconUrl?: string;
}

const GossipDiscussionCount: React.FC<OwnProps> = ({
  discussionPostCount,
  discussionIconUrl,
}) => {
  const theme = useTheme() as ITheme;
  return discussionIconUrl ? (
    <BottomItemLink to={discussionIconUrl}>
      <Icon name="message" color={theme.color.primary} />
      <LabelWrapper>
        {discussionPostCount} {getNumberOfCommetsLabel(discussionPostCount)}
      </LabelWrapper>
    </BottomItemLink>
  ) : (
    <BottomItemDiv>
      <Icon name="message" color={theme.color.primary} />
      <LabelWrapper>
        {discussionPostCount} {getNumberOfCommetsLabel(discussionPostCount)}
      </LabelWrapper>
    </BottomItemDiv>
  );
};

export default GossipDiscussionCount;
