import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
  >
    <path fill={color} d="M14.7,5.3H1.3v6H4V14h8v-2.7h2.7L14.7,5.3z M10.7,12.7H5.3V9.3h5.3V12.7z M13.3,7.3C13.3,7.7,13,8,12.7,8v0
	C12.3,8,12,7.7,12,7.3c0-0.4,0.3-0.7,0.7-0.7C13,6.7,13.3,7,13.3,7.3C13.3,7.3,13.3,7.3,13.3,7.3z M12,4.7H4V2h8V4.7z"/>
  </svg>
);
