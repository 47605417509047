import React from 'react';

function useDropdownHeight(active: boolean) {
  const [height, setHeight] = React.useState<string | number>('auto');

  React.useEffect(() => {
    if (!active) {
      setHeight('auto');
    }
  }, [active]);

  const getHeight = React.useCallback(
    (node: HTMLElement) => {
      setHeight(node.offsetHeight);
    },
    [setHeight],
  );

  return { height, getHeight, setHeight };
}

export default useDropdownHeight;
