import getYear from 'date-fns/getYear';
/**
 * Nahradi {{year}} aktualnym rokom v copyright texte.
 * @param text Copyright text, kde je namiesto roka pouzity {{year}} placeholder.
 */
export const getCopyrightText = (text: string) => {
  const year = getYear(new Date());
  return text.replace('{{year}}', `${year}`);
};

export default getCopyrightText;
