import React from 'react';
import { MatchesListMatch } from '../../../library/Print';
import styled, { css } from 'styled-components';
import MatchListItem from './MatchListItem';
import { rem } from 'polished';
import NoResults from '../../../components/v2/NoResults';
import __ from '../../../utilities/__';

export const InfoMessage = styled(NoResults)`
  width: 100%;
  margin: 1.5cm 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const RoundName = styled.h2`
  font-size: ${rem(19.5)};
  margin: 0.5cm 0;
`;

export const EmptyHeader = styled.thead``;
export const EmptyFooter = styled.tfoot``;

export const Tr = styled.tr<{ bold?: boolean }>`
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-size: ${rem(13)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  ${({ bold = false }) =>
    bold
      ? css`
          font-weight: 600;
        `
      : ''}
`;

interface OwnProps {
  data: {
    matchesByRound: { competitionName: string; roundName: string; matches: MatchesListMatch[] }[];
  };
  withCompetitionName?: boolean;
}

const RenderPrintData: React.FC<OwnProps> = ({ data, withCompetitionName = false }) => {
  return Array.isArray(data.matchesByRound) &&
    data.matchesByRound.length > 0 ? (
    <Table>
      <EmptyHeader>
        <tr>
          <td colSpan={6}>&nbsp;</td>
        </tr>
      </EmptyHeader>
      <tbody>
        {data.matchesByRound.map((roundData) => {
          return (
            <React.Fragment key={`round-${roundData.roundName}`}>
              <Tr>
                <td colSpan={6}>
                  <RoundName>{withCompetitionName ? `${roundData.competitionName} - ` : '' }{roundData.roundName}</RoundName>
                </td>
              </Tr>
              {roundData.matches.map((match) => (
                <MatchListItem
                  key={`match-${match._id}`}
                  dateFrom={match.dateFrom ? new Date(match.dateFrom) : null}
                  homeTeam={match.homeTeam}
                  awayTeam={match.awayTeam}
                  scoreNote={match.scoreNote}
                />
              ))}
            </React.Fragment>
          );
        })}
      </tbody>
      <EmptyFooter>
        <tr>
          <td colSpan={6}>&nbsp;</td>
        </tr>
      </EmptyFooter>
    </Table>
  ) : (
    <InfoMessage
      text={__(
        'Pre vybranú súťaž, sezónu a jej časť sa nenašli žiadne zápasy!',
      )}
    />
  );
};

export default RenderPrintData;
