import { __ } from '@sportnet/content/utilities/utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManagerContextProvider from '../../components/Ads/AdManager/AdManagerContextProvider';
import ComiteeMenu from '../../components/ComiteeMenu';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import { IMenuItem } from '../../components/FutbalnetHeader/Menu/types';
import FutbalnetTitle from '../../components/FutbalnetHeader/Title';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import CompetitionsDropdown from '../../containers/CompetitionsDropdown';
import CustomSectionsDropDown from '../../containers/CustomSectionsDropDown';
import UnionsDropdown from '../../containers/UnionsDropdown';
import { SectionNode } from '../../library/App';
import pageViewWithDimensions from '../../library/pageViewWithDimensions';
import styled from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import getAppSpaceFromUrl from '../../utilities/getAppSpaceFromUrl';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import getUnionByUrl from '../../utilities/getUnionByUrl';
import { initializeOrSetListParams } from '../App/actions';
import {
  comiteesSelector,
  getSectionSubTreeByParameters,
  isLoadingList,
} from '../App/selectors';
import { loadOrganizationProfileItem } from '../Competition/actions';
import {
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../Competition/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { unionSubsStructureSelector } from '../FutbalnetApp/selectors';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import { loadCommisions, loadUnionMatches } from './actions';
import { unionCompetitionsPanesSelector } from './selectors';
import UnionUrlContextProvider from './UnionUrlContextProvider';

const TitleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${rem(8)};
  > img {
    height: ${rem(24)};
  }
  ${mb('s')} {
    margin-right: ${rem(12)};
    > img {
      height: ${rem(32)};
    }
  }
`;

const TabIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const mapStateToProps = (
  state: RootState,
  { params: { zvaz } }: RouteComponentProps<{ zvaz: string }, {}>,
) => {
  const currentAppSpace = getCurrentCompetitionAppSpaceSelector(state);
  const isFetchingCompetitions = isLoadingList(state, config.LIST_COMPETITIONS);
  const unionId = getAppSpaceFromUrl('union', zvaz) || zvaz;
  return {
    organizationProfile: currentOrganizationProfileSelector(state),
    isFetchingCompetitions,
    appSettings: appSettingsSelector(state),
    comitees: comiteesSelector(state),
    unionSubs: unionSubsStructureSelector(state, unionId),
    menuSections:
      getSectionSubTreeByParameters(
        state,
        config.CLUB_SECTIONS_MENU_SECTIONID_OR_UNIQ_ID,
        { appSpace: currentAppSpace },
      )?.sections || [],
    competitionsPanes: unionCompetitionsPanesSelector(state),
  };
};

type Props = { theme: ITheme } & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string }, {}>;

class UnionDetail extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      params: { zvaz },
      dispatch,
    } = props;
    // `union` object from `shortnames.json`
    const union = getUnionByUrl(zvaz);
    const seasonNames = config.ACTIVE_SEASON_NAMES as unknown as string[];
    dispatch(
      initializeOrSetListParams({
        listName: config.LIST_UNION_COMPETITIONS,
        params: { seasonNames },
      }),
    );
    return Promise.all([
      dispatch(
        thunkToAction((loadOrganizationProfileItem as any)({ expanded: true })),
      ),
      dispatch(
        thunkToAction(
          (loadUnionMatches as any)({
            offset: 0,
            limit: config.LIST_UNION_MATCHES_LIMIT,
          }),
        ),
      ),
      dispatch(
        thunkToAction(
          (loadCommisions as any)({
            unionId: union?.__issfId.toString() || '',
          }),
        ),
      ),
    ]);
  }

  pageViewWithDimensions() {
    pageViewWithDimensions({
      unionName: this.props.organizationProfile?.name,
    });
  }

  async componentDidMount() {
    try {
      await UnionDetail.getInitialProps(this.props);
      this.pageViewWithDimensions();
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.zvaz !== prevProps.params.zvaz) {
      UnionDetail.getInitialProps(this.props).then(() => {
        this.pageViewWithDimensions();
      });
    } else if (this.props.location !== prevProps.location) {
      this.pageViewWithDimensions();
    }
  }

  render() {
    const {
      organizationProfile,
      comitees,
      params: { zvaz },
      isFetchingCompetitions,
      appSettings,
      menuSections,
      unionSubs,
      competitionsPanes,
    } = this.props;

    if (!organizationProfile || isFetchingCompetitions) {
      return <Loading />;
    }

    const url = getUnionBaseUrl({ zvaz });
    const union = getUnionByUrl(zvaz);

    const menuItems: IMenuItem[] = [
      { id: 'overview', label: __('Prehľad'), url },
      ...(competitionsPanes.length > 0
        ? ([
            {
              id: 'competitions',
              label: __('Súťaže'),
              renderDropdown: (dropdownProps) => {
                return (
                  <CompetitionsDropdown
                    union={union?._id}
                    inputSearchPlaceholder={__('Zadajte názov súťaže...')}
                    {...dropdownProps}
                  />
                );
              },
            },
          ] as IMenuItem[])
        : []),
      {
        id: 'comissions',
        label: __('Komisie'),
        renderDropdown: (dropdownProps) => (
          <FutbalnetMenu.Dropdown {...dropdownProps}>
            <ComiteeMenu union={zvaz} comitees={comitees} />
          </FutbalnetMenu.Dropdown>
        ),
      },
      ...(unionSubs.length
        ? [
            {
              id: 'union-subs',
              label: __('Podzväzy'),
              renderDropdown: (dropdownProps) => {
                return <UnionsDropdown {...dropdownProps} unions={unionSubs} />;
              },
            } as IMenuItem,
          ]
        : []),
    ];

    // ostatne (custom) sekcie zvazu do tabu "Viac"
    if ((menuSections || []).length) {
      menuItems.push({
        id: 'sections-menu',
        label: () => (
          <>
            <TabIcon name="more" size={12} />
            {__('Viac')}
          </>
        ),
        renderDropdown: (dropdownProps) => {
          return (
            <CustomSectionsDropDown
              {...dropdownProps}
              sections={
                menuSections as Array<
                  SectionNode & { sections?: SectionNode[] }
                >
              }
            />
          );
        },
      });
    }

    const orgLogoSrc = getPpoLogoSrc(
      organizationProfile as Required<typeof organizationProfile>,
    );

    return (
      <UnionUrlContextProvider>
        <Helmet>
          <title>{organizationProfile.title}</title>
          <meta
            property="og:url"
            content={`${appSettings.baseUri}${getUnionBaseUrl({ zvaz })}`}
          />
          <meta property="og:title" content={organizationProfile.title} />
          <meta property="og:type" content="website" />
          <meta
            name="description"
            content={`${organizationProfile.name}: ${__(
              `Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov ${
                organizationProfile.shortName || organizationProfile.name
              }.`,
            )}`}
          />
          <meta
            property="og:description"
            content={`${organizationProfile.name}: ${__(
              `Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov ${
                organizationProfile.shortName || organizationProfile.name
              }.`,
            )}`}
          />
          {!!orgLogoSrc && <meta property="og:image" content={orgLogoSrc} />}
        </Helmet>
        <FutbalnetHomeHeader />
        <FutbalnetHeader>
          <FutbalnetTitleBlock>
            {!!orgLogoSrc && (
              <TitleLogoWrapper>
                <img
                  alt={
                    organizationProfile.shortName || organizationProfile.name
                  }
                  src={orgLogoSrc}
                />
              </TitleLogoWrapper>
            )}
            <FutbalnetTitle>
              {organizationProfile.shortName || organizationProfile.name}
            </FutbalnetTitle>
          </FutbalnetTitleBlock>
          <FutbalnetMenu items={menuItems} />
        </FutbalnetHeader>
        <AdManagerContextProvider origin="futbalnet" pagetype="other">
          {this.props.children}
        </AdManagerContextProvider>
      </UnionUrlContextProvider>
    );
  }
}

export default compose(connect(mapStateToProps))(UnionDetail);
