import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M18,21.7H6c-0.6,0-1-0.4-1-1v-8.9c-0.4,0.4-1,0.3-1.4,0L0.3,8.4C0.1,8.2,0,8,0,7.7C0,7.4,0.1,7.2,0.3,7L5,2.3
    C5.2,2.1,5.4,2,5.7,2h3.1c0.5,0,0.9,0.3,1,0.8c0.2,1.1,1.2,1.9,2.3,1.9c1.1,0,2.1-0.8,2.3-1.9c0.1-0.5,0.5-0.8,1-0.8h3.1
    c0.3,0,0.5,0.1,0.7,0.3L23.7,7c0.4,0.4,0.4,1,0,1.4l-3.3,3.3C20.2,11.9,20,12,19.7,12c-0.3,0-0.5-0.1-0.6-0.2l0,8.9
    C19,21.2,18.6,21.7,18,21.7z M7,19.7h10v-9.1c0-0.6,0.3-1.1,0.9-1.4c0.5-0.2,1.2-0.1,1.6,0.3l0.1,0.1l1.9-1.9L17.9,4H16
    c-0.7,1.6-2.2,2.7-4,2.7c-1.8,0-3.3-1.1-4-2.7H6.1L2.4,7.7l1.9,1.9l0.1-0.1C4.9,9,5.5,8.9,6.1,9.1C6.6,9.4,7,9.9,7,10.5V19.7z
     M19,10.5L19,10.5C19,10.5,19,10.5,19,10.5z M5,10.5C5,10.5,5,10.5,5,10.5L5,10.5z"
    />
  </svg>
);
