import { AnyAction } from 'redux';
import { getProp } from 'sportnet-utilities';
import { AsyncActionCreators } from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Preview, Section } from '../../api/CmsApi';
import CoreApi, {
  BankAccount,
  Codelist,
  Organization,
  OrganizationProfile,
  UserPublicProfile,
} from '../../api/CoreApi';
import {
  IComiteeListItem,
  ILatestResolutions,
  IResolution,
  ISeason,
} from '../../api/FutbalnetApi';
import { Event, Provider, Sport } from '../../api/TvProgramApi';
import {
  AsyncReturnType,
  IArticle,
  INormalizedSectionTree,
  IPrivateContent,
  IStaticContent,
  SectionNode,
  Writeable,
} from '../../library/App';
import {
  ICompetition,
  ICompetitionPart,
  IMatch,
  IMember,
  ISportSectorBroadcaster,
  ISportSectorCrew,
  ISportSectorDelegate,
  ISportSectorEvent,
  ISportSectorPhase,
  ISportSectorSetting,
} from '../../library/Competitions';
import { IPPOUser } from '../../library/Sportnet';
import serializeParameters from '../../utilities/serializeParameters';
import * as actions from './actions';
import { OptimizedPublicSmartTagResponse } from '../../library/SmartTags';
import { IPublicSettings } from '../../library/Pages';

export type EntitiesState = {
  articles: Readonly<{
    [key: string]: Readonly<IArticle>;
  }>;
  sections: Readonly<{
    [key: string]: Readonly<Section & IPrivateContent>;
  }>;
  smartTags: Readonly<{
    [key: string]: Readonly<OptimizedPublicSmartTagResponse>;
  }>;
  previews: Readonly<{
    [key: string]: Readonly<Preview>;
  }>;
  ppoUsers: Readonly<{
    [key: string]: Readonly<IPPOUser>;
  }>;
  member: Readonly<{ [key: string]: Readonly<IMember> }>;
  members: Readonly<{
    [key: string]: Readonly<
      AsyncReturnType<(typeof CoreApi)['organizationPPOUser']>
    >;
  }>;
  organizationProfiles: Readonly<{
    [key: string]: Readonly<
      OrganizationProfile & {
        invoiceProfile?: Organization & {
          bank_account?: BankAccount;
          invoice_address?: {
            street?: string;
            number: string;
            city?: string;
            zip?: string;
          };
        };
      } & {
        theme?: {
          color?: {
            primary?: string;
          };
        };
      } & { title: string; shortName?: string }
    >;
  }>;
  competitions: Readonly<{
    [key: string]: Readonly<ICompetition>;
  }>;
  clubCompetitions: Readonly<{
    [key: string]: Readonly<ICompetition>;
  }>;
  competitionParts: Readonly<{
    [key: string]: Readonly<ICompetitionPart>;
  }>;
  matches: Readonly<{
    [key: string]: Readonly<IMatch>;
  }>;
  sportSectorTelevisionBroadcasters: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorBroadcaster>;
    };
  }>;
  sportSectorInternetBroadcasters: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorBroadcaster>;
    };
  }>;
  sportSectorPhases: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorPhase>;
    };
  }>;
  sportSectorEvents: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorEvent>;
    };
  }>;
  sportSectorDelegates: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorDelegate>;
    };
  }>;
  sportSectorCrew: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorCrew>;
    };
  }>;
  sportSectorSettings: Readonly<{
    [key: string]: {
      [key: string]: Readonly<ISportSectorSetting>;
    };
  }>;
  comitees: Readonly<{
    [key: string]: Readonly<IComiteeListItem>;
  }>;
  seasons: Readonly<{
    [key: string]: Readonly<ISeason>;
  }>;
  latestResolutions: Readonly<{
    [key: string]: Readonly<IResolution>;
  }>;
  codeLists: Readonly<{
    [key: string]: Readonly<{ items: Codelist['codelist'] }>;
  }>;
  commiteesWithLatestResolutions: Readonly<{
    [key: string]: Readonly<ILatestResolutions>;
  }>;
  lastRoundMatches: Readonly<{
    [key: string]: Readonly<{
      matches: IMatch[];
    }>;
  }>;
  staticContents: Readonly<{
    [key: string]: Readonly<IStaticContent>;
  }>;
  tvProgramEvents: Readonly<{
    [key: string]: Readonly<Event>;
  }>;
  tvProgramProviders: Readonly<{
    [key: string]: Readonly<Provider>;
  }>;
  tvProgramSports: Readonly<{
    [key: string]: Readonly<Sport>;
  }>;
  pagesSettings: Readonly<{
    [key: string]: Readonly<IPublicSettings>;
  }>;
};

export const entitiesReducer = (
  state: EntitiesState = {
    articles: {},
    sections: {},
    smartTags: {},
    previews: {},
    ppoUsers: {},
    member: {},
    members: {},
    organizationProfiles: {},
    competitions: {},
    clubCompetitions: {},
    competitionParts: {},
    matches: {},
    sportSectorTelevisionBroadcasters: {},
    sportSectorInternetBroadcasters: {},
    sportSectorCrew: {},
    sportSectorDelegates: {},
    sportSectorSettings: {},
    sportSectorEvents: {},
    sportSectorPhases: {},
    comitees: {},
    seasons: {},
    latestResolutions: {},
    codeLists: {},
    lastRoundMatches: {},
    commiteesWithLatestResolutions: {},
    staticContents: {},
    tvProgramEvents: {},
    tvProgramProviders: {},
    tvProgramSports: {},
    pagesSettings: {},
  },
  action: AnyAction
): EntitiesState => {
  if (getProp(action.payload, ['result', 'entities'])) {
    return Object.keys(action.payload.result.entities).reduce(
      (acc: any, entity: keyof EntitiesState) => {
        acc[entity] = Object.keys(
          action.payload.result.entities[entity]
        ).reduce(
          (innerAcc: Writeable<EntitiesState[keyof EntitiesState]>, id) => {
            innerAcc[id] = {
              ...innerAcc[id],
              ...action.payload.result.entities[entity][id],
            };
            return innerAcc;
          },
          { ...getProp(state, [entity], {}) }
        );
        return acc;
      },
      { ...state }
    );
  }
  return state;
};

export interface IDetailInitialState<
  R extends any | undefined = undefined,
  E extends any = any
> {
  [key: string]: {
    isFetching: boolean;
    error: E;
    data?: R;
  };
}

export const detailReducerFromAction = <D, E>(
  asyncActionCreators: Array<{
    async: AsyncActionCreators<
      { [key: string]: any },
      { data?: D; [key: string]: any },
      E
    >;
  }>,
  ...parameterKeys: string[]
) =>
  reducerWithInitialState<IDetailInitialState<D>>({})
    .cases(
      asyncActionCreators.map((a) => a.async.started),
      (state, params) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: true,
          },
        };
      }
    )
    .cases(
      asyncActionCreators.map((a) => a.async.done),
      (state, { params, result: { data } }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            data,
            isFetching: false,
            error: null,
          },
        };
      }
    )
    .cases(
      asyncActionCreators.map((a) => a.async.failed),
      (state, { params, error }) => {
        const key = serializeParameters(params, parameterKeys);
        return {
          ...state,
          [key]: {
            ...state[key],
            isFetching: false,
            error,
          },
        };
      }
    );

export const authorizationReducer = reducerWithInitialState<{
  user?: UserPublicProfile | null;
  fetching?: number;
  isEditor?: boolean;
}>({ fetching: 3 }).case(actions.setAuthUser, (state, payload) => {
  return payload;
});

export interface ISectionTreeState {
  isFetching?: boolean;
  error?: any | null;
  tree?: INormalizedSectionTree;
}

export interface SportnetMenusState {
  isFetching?: boolean;
  error?: any | null;
  data?: { [key: string]: SectionNode[] };
}

export const sectionTreeReducer = reducerWithInitialState<ISectionTreeState>({})
  .case(
    actions.loadWholeSectionTree.async.started,
    (state): ISectionTreeState => ({
      ...state,
      isFetching: true,
    })
  )
  .case(
    actions.loadWholeSectionTree.async.done,
    (
      state,
      {
        result: {
          data: { tree },
        },
      }
    ): ISectionTreeState => ({
      ...state,
      isFetching: false,
      error: null,
      tree,
    })
  )
  .case(
    actions.loadWholeSectionTree.async.failed,
    (state, { error }): ISectionTreeState => ({
      ...state,
      isFetching: false,
      error,
    })
  );

export const sportnetMenusReducer = reducerWithInitialState<SportnetMenusState>(
  {}
)
  .case(
    actions.loadWholeSectionTree.async.started,
    (state): SportnetMenusState => ({
      ...state,
      isFetching: true,
    })
  )
  .case(
    actions.loadSportnetMenus.async.done,
    (
      state,
      {
        result: {
          data: { data },
        },
      }
    ): SportnetMenusState => ({
      ...state,
      isFetching: false,
      error: null,
      data,
    })
  )
  .case(
    actions.loadWholeSectionTree.async.failed,
    (state, { error }): SportnetMenusState => ({
      ...state,
      isFetching: false,
      error,
    })
  );

export const scrollProgressReducer = reducerWithInitialState<number | null>(
  null
).case(actions.setScrollProgress, (_, progress) => progress);
