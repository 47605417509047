import { PositionRenderedEvent } from '../../../library/Ads';
import React from 'react';

const AdPositioner: React.FC = () => {
  React.useEffect(() => {
    const handlePositionRendered = (event: PositionRenderedEvent) => {
      if (!event.detail || event.detail.empty) {
        return;
      }

      if (event.detail.position === 'big_1' && event.detail.format === 'gate') {
        // spravny padding pri zobrazeni Gate reklamy
        const widthInViewPort =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.documentElement.getElementsByTagName('body')[0].clientWidth;
        if (1232 >= widthInViewPort) {
          return;
        }
        const mainWrapperList =
          document.querySelectorAll<HTMLElement>('.sptn-main-content');
        mainWrapperList.forEach((wrapElement) => {
          wrapElement.style.width = '1190px';
          wrapElement.style.maxWidth = '1190px';
        });
      } else if (event.detail.elementId) {
        // nastavenie spravnych marginov reklam v sekciach a clankoch
        const advertEl = document.getElementById(event.detail.elementId);
        if (
          advertEl &&
          advertEl.parentElement &&
          event.detail.position &&
          advertEl.parentElement.className.includes(event.detail.position)
        ) {
          const wrapElement = advertEl.parentElement;

          wrapElement.style.height = 'auto';

          if (wrapElement.className.includes('advertisement-margin-32px')) {
            wrapElement.style.marginTop = '32px';
            wrapElement.style.marginBottom = '32px';
          } else if (
            wrapElement.className.includes('advertisement-margin-24px')
          ) {
            wrapElement.style.marginTop = '24px';
            wrapElement.style.marginBottom = '24px';
          }

          if (wrapElement.className.includes('advertisement-gray-background')) {
            wrapElement.style.backgroundColor = '#f1f1f1';
          }
        }
      }
    };

    window.addEventListener(
      'SmeAdManager.slot.positionRendered',
      handlePositionRendered,
    );

    return () => {
      window.removeEventListener(
        'SmeAdManager.slot.positionRendered',
        handlePositionRendered,
      );
    };
  }, []);

  return null;
};

export default AdPositioner;
