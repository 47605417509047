import { Location } from 'history';

/**
 * Vyhodi neziaduc `q` parameter.
 * @param location
 * @returns
 */
const cleanupLocationObject = (location: Location) => {
  const newLocation = { ...location, query: { ...location.query } };
  const { query } = newLocation;
  if (location.pathname === '/futbalnet/clenovia/') {
    delete query.page;
  }
  delete query.q;

  if (
    location.pathname.match(/\/futbalnet\/z\/[a-z0-9A-Z-]+\/komisia\/[0-9]+\//)
  ) {
    delete query.seasonId;
  }

  const serialized = Object.entries(query)
    .map(([key, val]) => {
      return `${key}=${val}`;
    })
    .join('&');
  newLocation.search = serialized ? `?${serialized}` : '';

  return newLocation;
};

export default cleanupLocationObject;
