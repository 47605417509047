import { rem } from 'polished';
import * as React from 'react';
import { Collapse } from 'react-collapse';
import useIsResponsiveLayout from '../../hooks/useIsResponsiveLayout';
import styled, { css } from '../../theme/styled-components';
import getMembersNameWithLink from '../../utilities/getMembersNameWithLink';
import Icon, { IIconName } from '../Icon';
import Segment from '../Segment';

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleContent = styled.div`
  font-weight: 700;
  font-size: ${rem(14)};
  padding: ${rem(8)} ${rem(8)} ${rem(24)} ${rem(8)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  > span {
    padding-right: ${rem(8)};
  }
`;

const Items = styled.div`
  margin: 0 -${rem(16)};
`;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  margin-bottom: ${({ isMobile }) => rem(!!isMobile ? 16 : 32)};
  ${({ isMobile }) =>
    !!isMobile &&
    css`
      ${TitleContent} {
        padding: 0;
      }
      ${Items} {
        padding-top: ${rem(24)};
      }
    `}
`;

const Player = styled.div`
  font-size: ${rem(12)};
`;

const PlayerDetail = styled.div`
  display: flex;
  align-items: center;
`;

const PlayerName = styled.div`
  font-weight: 700;
  padding-bottom: ${rem(4)};
`;

const ClubName = styled.div`
  color: #aaaaaa;
  font-size: ${rem(12)};
`;

const TopItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${rem(16)} ${rem(24)};
  background: #f9f9f9;
  ${PlayerName} {
    font-size: ${rem(14)};
  }
`;
const TopItemRecord = styled.div`
  font-weight: bold;
  font-size: ${rem(24)};
  padding-top: ${rem(8)};
`;
const TeamLogo = styled.div`
  width: ${rem(48)};
  height: ${rem(48)};
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${rem(1)} solid #dddddd;
  border-radius: ${rem(4)};
  > img {
    max-height: 90%;
    max-width: 90%;
  }
  overflow: hidden;
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${rem(12)};
  padding: ${rem(8)} ${rem(24)};
`;
const Index = styled.div`
  font-size: ${rem(12)};
  padding-right: ${rem(16)};
`;

interface ILadderItem {
  _id: string;
  name: string;
  __issfId?: string | number | null;
  team: {
    name: string;
  };
  value: string | number;
}

interface OwnProps {
  title: string;
  initExpanded?: boolean;
  icon?: IIconName;
  items: ILadderItem[];
}

const Ladder: React.FC<OwnProps> = ({ title, icon, items, initExpanded }) => {
  const mobileLayout = useIsResponsiveLayout();

  const [expanded, setExpanded] = React.useState(!!initExpanded);

  const toggleExpanded = () => setExpanded(!expanded);

  const renderItem = (item: ILadderItem, idx: number) => (
    <Item key={idx}>
      <PlayerDetail>
        <Index>{`${idx + 2}.`}</Index>
        <Player>
          <PlayerName>
            {getMembersNameWithLink(item.name, item.__issfId || item._id)}
          </PlayerName>
          <ClubName>{item.team.name}</ClubName>
        </Player>
      </PlayerDetail>
      <div>
        <b>{item.value}</b>
      </div>
    </Item>
  );

  if (!items.length) {
    return null;
  }
  const [firstItem, ...restItems] = items;

  return (
    <Wrapper isMobile={mobileLayout}>
      <Segment>
        <Title onClick={toggleExpanded}>
          <TitleContent>
            {!!icon && <Icon size="l" name={icon} />}
            <div>{title}</div>
          </TitleContent>
          {mobileLayout && (
            <span
              {...(expanded && {
                style: { transform: 'rotate(180deg)' },
              })}
            >
              <Icon size="l" name="chevron" />
            </span>
          )}
        </Title>
        <Collapse isOpened={expanded || !mobileLayout}>
          <Items>
            {!mobileLayout ? (
              <TopItem>
                <PlayerDetail>
                  <Index>1.</Index>
                  <Player>
                    <PlayerName>
                      {getMembersNameWithLink(
                        firstItem.name,
                        firstItem.__issfId || firstItem._id,
                      )}
                    </PlayerName>
                    <ClubName>{firstItem.team.name}</ClubName>
                    <TopItemRecord>{firstItem.value}</TopItemRecord>
                  </Player>
                </PlayerDetail>
                <TeamLogo>
                  <Icon name="avatar" size={48} />
                </TeamLogo>
              </TopItem>
            ) : (
              renderItem(firstItem, -1)
            )}
            {restItems.map(renderItem)}
          </Items>
        </Collapse>
      </Segment>
    </Wrapper>
  );
};

export default Ladder;
