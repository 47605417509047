import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../../theme/styled-components';
import Photo, { ImgWrapper } from '../../Widgets/Overrides/Photo';

const StyledPhoto = styled(Photo)`
  ${({
    theme: {
      app: { padding },
    },
  }) => css`
    && {
      img {
        border-radius: 0;
        width: 100%;
      }
      margin: 0 0 ${rem(24)} 0;
      ${ImgWrapper} {
        margin: 0 ${rem(-padding)};
      }
      ${mb('m')} {
        img {
          border-radius: ${rem(8)};
        }
        ${ImgWrapper} {
          margin: 0;
        }
      }
    }
  `}
`;

interface IOwnProps {
  picture: any;
  className?: string;
}

type IProps = IOwnProps;

const ArticlePicture: React.FC<IProps> = ({ picture, className }) => {
  return (
    <StyledPhoto
      data={{ picture }}
      className={className}
      naturalWidth={992}
      naturalHeight={558}
      script="media"
    />
  );
};

export default React.memo(ArticlePicture);
