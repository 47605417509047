import React from 'react';
import { connect } from 'react-redux';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import ItemsList from '../../components/ItemsList';
import Header from '../../components/ItemsList/Header';
import Item from '../../components/ItemsList/Item';
import { RootState } from '../../configureStore';
import { competitionsByCompetititonGroupSelector } from '../../pages/Competition/selectors';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import __ from '../../utilities/__';

type IOwnProps = {
  xPosition: number;
  active: boolean;
  onClose: () => void;
};

const mapStateToProps = (state: RootState) => {
  return {
    competitions: competitionsByCompetititonGroupSelector(state),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const CompetitionArchiveDropdown: React.FC<IProps> = ({
  xPosition,
  competitions,
  active,
  onClose,
}) => {
  return (
    <FutbalnetMenu.PositionedDropdown
      xPosition={xPosition}
      active={active}
      onClose={onClose}
    >
      <div>
        <Header>{__('Ročník')}</Header>
        <ItemsList>
          {competitions.map((comp) => {
            const url = getCompetitionBaseUrl({
              zvaz: comp.appSpace,
              sutaz: comp.__issfId || comp._id || comp.competitionGroupId,
            });
            return (
              <Item key={comp._id} to={url}>
                {comp.season?.name}
              </Item>
            );
          })}
        </ItemsList>
      </div>
    </FutbalnetMenu.PositionedDropdown>
  );
};

export default React.memo(connect(mapStateToProps)(CompetitionArchiveDropdown));
