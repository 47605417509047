import { rem } from 'polished';
import * as React from 'react';
import styled from '../../theme/styled-components';
import { Wrapper as Label } from '../Label/Label';
import FormGroupContext from './FormGroupContext';

const uniqueId = require('lodash.uniqueid');

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => rem(theme.formGroup.bottomMargin)};
  > ${Label} {
    margin-bottom: ${({ theme }) => rem(theme.formGroup.labelGutter)};
  }
`;

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

class FormGroup extends React.PureComponent<Props> {
  state = {
    id: uniqueId(),
  };

  render() {
    const { children } = this.props;
    return (
      <FormGroupContext.Provider value={this.state}>
        <Wrapper>{children}</Wrapper>
      </FormGroupContext.Provider>
    );
  }
}

export default FormGroup;
