import React from 'react';
import {
  doxxbetLeagueCupIdByCompetitionId,
  doxxbetLeagueCupIdsByTeamId,
} from '../thirdPartyResultsMapApi';
import { IWidgets } from '../library/App';

export default function useWidgetDoxxbetInjector(items: IWidgets) {
  const itemsHash = JSON.stringify(items);
  const matchesWidgets = React.useMemo(() => {
    return items.filter((widget) => {
      return widget.type === 'matchesList';
    });
  }, [items]);
  const doxxbetLeagueCupIds = React.useRef<any[]>([]);
  React.useEffect(() => {
    if (matchesWidgets && matchesWidgets.length) {
      // najdeme si unikatne competitionId
      doxxbetLeagueCupIds.current.push(
        ...[
          ...matchesWidgets
            .map(({ competitionId, teamId }) => {
              if (competitionId) {
                const nikeMapRow = doxxbetLeagueCupIdByCompetitionId(competitionId)
                if (nikeMapRow) {
                  return [nikeMapRow]
                }
              } else if (teamId) {
                return doxxbetLeagueCupIdsByTeamId(teamId);
              }
              return [];
            })
            .reduce((r, lgcids) => {
              lgcids.forEach(({ nikeSutazId }) => {
                r.add(nikeSutazId);
              });
              return r;
            }, new Set()),
        ].filter(Boolean),
      );
    }
    return;
  }, [matchesWidgets, itemsHash]);
}
