import React from 'react';
import { useTheme } from 'styled-components';
import { TertiaryButton } from '../../sportnet.sme.sk-ui/TertiaryButton/TertiaryButton';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';

interface OwnProps {
  className?: string;
  href: string;
  gtmEventLabel: string;
}

const PrintButton: React.FC<OwnProps> = ({
  href,
  className,
  gtmEventLabel,
}) => {
  const [isHydrated, setIsHydrated] = React.useState(false);

  const theme = useTheme() as unknown as ITheme;

  // nerenderujeme na SSR
  React.useEffect(() => {
    setIsHydrated(true);
  }, []);

  const handleClickLink = () => {
    window.dataLayer.push({
      event: 'click',
      eventCategory: 'button',
      eventAction: 'tlac',
      eventLabel: gtmEventLabel,
    });
  };

  return isHydrated ? (
    <TertiaryButton
      href={href}
      className={className}
      linkTarget="_blank"
      linkRel="noreferrer"
      icon="print2"
      iconColor={theme.color.primary}
      onClick={handleClickLink}
    >
      {__('Tlač')}
    </TertiaryButton>
  ) : null
  ;
};

export default PrintButton;
