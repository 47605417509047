import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import { EntitiesState } from '../../../pages/App/reducer';
import styled from '../../../theme/styled-components';
import getArticleMainSection from '../../../utilities/getArticleMainSection';
import Article from './Article';
import ArticlesGrid from './ArticlesGrid';

const ArticlesGridWrapper = styled.div`
  margin-top: ${rem(32)};
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById: EntitiesState['sections'];
  className?: string;
};

type IProps = IOwnProps;

const TopArticlesGrid: React.FC<IProps> = ({
  articles,
  sectionsById,
  className,
  children,
}) => {
  if (!articles.length) {
    return null;
  }

  const mainArticle = articles[0];
  const secondaryArticles = articles.slice(1, 5);

  const mainArticleSection = getArticleMainSection(mainArticle, sectionsById);

  return (
    <div className={className}>
      <Article article={mainArticle} section={mainArticleSection} />
      {children}
      <ArticlesGridWrapper>
        <ArticlesGrid
          articles={secondaryArticles}
          sectionsById={sectionsById}
        />
      </ArticlesGridWrapper>
    </div>
  );
};

export default TopArticlesGrid;
