import { IWidgetSquad } from '@sportnet/content/utilities/library';
import { ICrew } from '@sportnet/content/library/Competitions';
import { MediaManagerPhoto } from '../../../../api/CoreApi';
import { IAthlete } from '../../../../library/Competitions';
import { IPPOUser } from '../../../../library/Sportnet';
import __ from '../../../../utilities/__';

const TeamMemberPositionCategoryNameMap: { [key: string]: string } = {
  goalkeeper: __('Brankári'),
  defender: __('Obrancovia'),
  midfielder: __('Záložníci'),
  forward: __('Útočníci'),
  coach: __('Tréneri'),
  assist_coach_a: __('Asistenti trénera'),
  assist_coach_b: __('Asistenti trénera'),
  coach_goalkeepers: __('Tréneri brankárov'),
  doctor: __('Lekári'),
  masseur: __('Maséri'),
  unknown: __('Členovia tímu'),
};

const getPositionCategoryName = (position: string) => {
  return TeamMemberPositionCategoryNameMap[position] || position;
};

const getBio = (appSpace: string, user: IPPOUser) => {
  return user.bio
    ? user.bio.find((bio) => bio.org_profile_id === appSpace)
    : null;
};

export interface ISquadWidgetTeamMember {
  displayName: string;
  photo?: MediaManagerPhoto;
  nr?: number;
  position: string;
  sportnetId?: string;
  sportnetUser?: IPPOUser;
}

export interface ISquadWidgetTeam {
  _id: string;
  name: string;
  displayName: string;
  detailUrl?: string;
}

type IPreparedSquadDataEntry = {
  position: string;
  positionCategory: string;
  members: ISquadWidgetTeamMember[];
};

export const prepareSquadWidgetData = (
  appSpace: string,
  data: IWidgetSquad['data'],
): IPreparedSquadDataEntry[] => {
  if (!data) {
    return [];
  }
  const { athletes, users, crew } = data;

  const usersById = users.reduce((acc: Record<string, IPPOUser>, user) => {
    acc[user._id!] = user as any;
    return acc;
  }, {});

  const entryByPosition: Record<string, IPreparedSquadDataEntry> = {};

  const members = [...(athletes || []), ...(crew || [])];

  members.forEach((member) => {
    const sportnetUser = usersById[member.sportnetUser?._id || ''];
    const bio = sportnetUser ? getBio(appSpace, sportnetUser) : null;
    const fullName = member.sportnetUser?.name ?? '';

    const teamMember: ISquadWidgetTeamMember = {
      displayName: fullName,
      photo: bio?.photo,
      nr: (member as IAthlete).additionalData?.nr,
      sportnetId: sportnetUser?._id,
      position:
        (member as ICrew).position ||
        (member as IAthlete).additionalData?.position ||
        'unknown',
      sportnetUser,
    };

    const { position } = teamMember;

    const positionCategory = getPositionCategoryName(position);

    if (positionCategory in entryByPosition) {
      entryByPosition[positionCategory].members.push(teamMember);
    } else {
      entryByPosition[positionCategory] = {
        position,
        positionCategory,
        members: [teamMember],
      };
    }
  });
  const infoData = Object.keys(entryByPosition).map((k) => entryByPosition[k]);

  const sortOrderRule = [
    'Brankári',
    'Obrancovia',
    'Záložníci',
    'Útočníci',
    'Ďalší',
    'Tréneri',
    'Asistenti trénera',
    'Asistenti trénera',
    'Tréneri brankárov',
    'Lekári',
    'Maséri',
  ];
  return infoData.sort((a, b) => {
    return (
      sortOrderRule.indexOf(a.positionCategory) -
      sortOrderRule.indexOf(b.positionCategory)
    );
  });
};
