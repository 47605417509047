import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M21.7,5.3l-5-5C16.5,0.1,16.3,0,16,0H7C6.4,0,6,0.4,6,1v3H3C2.4,4,2,4.4,2,5v18c0,0.6,0.4,1,1,1h14
    c0.6,0,1-0.4,1-1v-3h3c0.6,0,1-0.4,1-1V6C22,5.7,21.9,5.5,21.7,5.3z M16,2.4L19.6,6H16V2.4z M16,22H4V6h2v13c0,0.6,0.4,1,1,1h9V22z
     M20,18H8V2h6v5c0,0.6,0.4,1,1,1h5V18z"
    />
  </svg>
);
