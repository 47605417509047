import { rem } from 'polished';
import * as React from 'react';
import { Collapse } from 'react-collapse';
import styled from 'styled-components';
import Icon from '../Icon';
import Link from '../Link';

const Wrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .ReactCollapse--content {
    display: flex;
    flex-direction: column;
  }
`;

const CollapseButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 0;
  background-color: ${({ theme }) => theme.collapsibleList.headerBg};
  margin-left: ${rem(2)};
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CollapseIcon = styled(Icon)<{ opened?: boolean }>`
  color: ${({ theme }) => theme.app.textColor};
  transform: ${({ opened }) => (opened ? 'rotate(0)' : 'rotate(180deg)')};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;

const HeaderLink = styled(Link)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    text-decoration: underline;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.collapsibleList.headerBg};
  width: 100%;
  height: 40px;
  padding-left: ${rem(16)};
  font-size: ${rem(12)};
  font-weight: 600;
`;

interface IOwnProps {
  collapsible?: boolean;
  header: {
    name: string;
    url?: string;
  };
  children: React.ReactNode;
  isOpened?: boolean;
  onChange?: (open: boolean) => void;
  isLoading?: boolean;
}

type IProps = IOwnProps;

const CollapsibleHeader: React.FC<IProps> = ({
  header,
  children,
  collapsible = false,
  isOpened,
  onChange,
  isLoading,
}) => {
  const [open, setOpen] = React.useState<boolean>(isOpened || false);
  return (
    <Wrapper>
      <HeaderWrapper>
        {header.url ? (
          <HeaderTitle>
            <HeaderLink to={header.url}>{header.name}</HeaderLink>
          </HeaderTitle>
        ) : (
          <HeaderTitle>{header.name}</HeaderTitle>
        )}

        <CollapseButton
          onClick={() => {
            setOpen(!open);
            if (onChange) {
              onChange(!open);
            }
          }}
        >
          <CollapseIcon opened={open} name="chevron-table" />
        </CollapseButton>
      </HeaderWrapper>
      {collapsible ? (
        <Collapse isOpened={open}>{children}</Collapse>
      ) : (
        <>{children}</>
      )}
    </Wrapper>
  );
};

export default CollapsibleHeader;
