import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default <T extends any>(items: T[], moreButtonWidth = 0) => {
  const listRef = React.useRef<HTMLUListElement | null>(null);
  const [listSize, setListSize] = React.useState(0);
  const [itemSizes, setItemSizes] = React.useState<number[]>([]);
  const [splitIndex, setSplitIndex] = React.useState(0);
  const [initializing, setInitializing] = React.useState(true);

  React.useEffect(() => {
    setInitializing(true);
  }, [items.length]); // optimization (only checking length)

  React.useEffect(() => {
    if (!listRef.current || !initializing) {
      return;
    }
    // use Array.from instead of spread to support safari
    setItemSizes(
      Array.from(listRef.current.children).map(
        (i) => i.getBoundingClientRect().width,
      ),
    );
  }, [initializing]);

  React.useEffect(() => {
    const listEl = listRef.current;
    if (!listEl) {
      return;
    }

    function handleResize(width: number) {
      let containerWidth = width;
      let newSplitIndex = 0;
      for (let i = 0; i < itemSizes.length; i++) {
        const itemSize = itemSizes[i];
        containerWidth -= itemSize;
        newSplitIndex = i;
        // all items can fit
        if (containerWidth >= 0 && i === itemSizes.length - 1) {
          break;
        }
        // not all items can fit
        if (containerWidth <= 0) {
          containerWidth -= moreButtonWidth;
          while (containerWidth < 0 && newSplitIndex !== -1) {
            containerWidth += itemSizes[newSplitIndex];
            newSplitIndex -= 1;
          }
          break;
        }
      }
      setSplitIndex(newSplitIndex + 1);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        handleResize(entry.contentRect.width);
        setListSize(entry.contentRect.width);
      }
    });

    resizeObserver.observe(listEl);

    setInitializing(false);

    return () => {
      resizeObserver.unobserve(listEl);
      resizeObserver.disconnect();
    };
  }, [itemSizes, moreButtonWidth]);

  const finalSplitIndex = initializing ? 1000 : splitIndex;

  const primaryItems = items.slice(0, finalSplitIndex);
  const secondaryItems = items.slice(finalSplitIndex);

  return {
    primaryItems,
    secondaryItems,
    initializing: initializing && itemSizes.length === 0,
    listRef,
    itemSizes,
    listSize,
  };
};
