import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/ui/lib/utilities';
import format from 'date-fns/format';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
} from '../../../components/Layout/SidebarLayout';
import Spacer from '../../../components/Spacer';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import { RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import { RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import getMemberDetailUrl from '../../../utilities/getMemberDetailUrl';
import { renderClub } from '../common';
import { currentMemberSelector } from '../selectors';

const TableWrapper = styled.div`
  margin: 0 -${rem(16)};
  width: calc(100% + ${rem(32)});
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const Movement = styled.span`
  position: relative;
  &:before {
    display: inline-block;
    width: ${rem(6)};
    height: ${rem(6)};
    border-radius: ${rem(3)};
    margin-right: ${rem(4)};
    margin-bottom: ${rem(2)};
    content: '';
    background: ${({ status }) => {
      switch (status) {
        case 'APROOVED':
          return 'green';
        case 'REJECTED':
          return 'red';
        default:
          return '#aaa';
      }
    }};
  }
`;

const TRANSFER_TYPES: { [key: string]: string } = {
  H: 'Hosťovanie',
  HX: 'Ukončenie hosťovanie',
  S: 'Striedavý štart',
  ZSK: 'Zapožičanie na Slovensko',
  ZW: 'Zapožičanie do zahraničia',
  P: 'Prestup',
  PX: 'Opakovaný prestup',
  PSKN: 'Prestup na Slovensko normálny',
  PSKP: 'Prestup na Slovensko provizorny',
  PWN: 'Prestup do zahraničia normálny',
  PWP: 'Prestup do zahraničia provizórny',
  PSO: 'Prestup s obmedzením',
};

const mapStateToProps = (state: RootState) => ({
  member: currentMemberSelector(state),
});

type IOwnProps = {};

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ id: string }>;

const PlayerMovementsList: React.FC<Props> = ({ member }) => {
  const renderPlayerMovements = React.useMemo(() => {
    if (!(member?.movements || []).length) {
      return (
        <ContentNotFound title={__('Nenašli sa žiadne prestupy hráča.')} />
      );
    }
    return (
      <TableWrapper>
        <Table>
          <Thead>
            <Tr>
              <Th>{__('Typ')}</Th>
              <Th>{__('Východzí klub')}</Th>
              <Th>{__('Cieľový klub')}</Th>
              <Th>{__('Dátum od')}</Th>
              <Th>{__('Dátum do')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(member?.movements || []).map((movement) => (
              <Tr key={`${movement.date.from}-${movement.move_type}`}>
                <Td>
                  <Movement
                    status={movement.status}
                    title={
                      movement.status === 'APROOVED'
                        ? __('Prestup povolený')
                        : __('Prestup zamietnutý')
                    }
                  >
                    {TRANSFER_TYPES[movement.move_type] || movement.move_type}
                  </Movement>
                </Td>
                <Td>{renderClub(movement.club_source)}</Td>
                <Td>{renderClub(movement.club_destination)}</Td>
                <Td>{format(new Date(movement.date.from), 'dd.MM.yyyy')}</Td>
                <Td>
                  {!!movement.date.to
                    ? format(new Date(movement.date.to), 'dd.MM.yyyy')
                    : ''}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableWrapper>
    );
  }, [member]);

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <Spacer size={4}>{renderPlayerMovements}</Spacer>
        </SidebarLayoutContent>
        <Sidebar />
      </PaddedSidebarLayout>
      <MegaboardAd />
      {member && (
        <>
          <MegaboardAd />
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: 'Členovia',
                url: '/futbalnet/clenovia/',
              },
              {
                label: formatUserName(member),
                url: getMemberDetailUrl(member.id),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PlayerMovementsList) as unknown as React.FC<IOwnProps>;
