import React from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-ads';
import './videojs/components/ima';
import './videojs/components/nuevo';
import videojsSK from '../../assets/videojs/lang/sk.json';
import isBrowser from '../../utilities/isBrowser';
import {
  authUserIsNoAdvertsSelector,
  authUserResolvedSelector,
} from '../../pages/App/selectors';
import { RootState } from '../../configureStore';
import { connect } from 'react-redux';

type Props = {
  options: VideoJsPlayerOptions;
  nuevoOptions?: any;
  onReady?: (player: VideoJsPlayer) => void;
  onPlay?: (player?: VideoJsPlayer) => void;
};

const mapStateToProps = (state: RootState) => {
  return {
    authUserResolved: authUserResolvedSelector(state),
    authUserIsNoAdverts: authUserIsNoAdvertsSelector(state),
  };
};

const VideoJS: React.FC<Props & ReturnType<typeof mapStateToProps>> = ({
  options,
  onReady,
  onPlay,
  authUserResolved,
  authUserIsNoAdverts,
  nuevoOptions,
}) => {
  const videoRef = React.useRef<any>();
  const playerRef = React.useRef<any | null>();
  const playerRefOnPlay = React.useRef<any | null>();
  const playerImaInitialized = React.useRef<boolean>(false);

  const adTagUrl = React.useMemo(() => {
    const custParams = ['technology=own-player'];
    if (
      isBrowser() &&
      'undefined' !== typeof (window as any).smeAdvertTargeting
    ) {
      custParams.push(
        ...(window as any).smeAdvertTargeting
          .map((kv) => {
            return Object.entries(kv).map(([k, v]: any[]) => {
              return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
            });
          })
          .reduce((a, b) => [...a, ...b], []),
      );
    }
    return (
      'https://pubads.g.doubleclick.net/gampad/ads?iu=%2F43112356%2Fext_futbalnet%2FvPlayerA&plcmt=1&description_url=%5bplaceholder%5d&env=vp&impl=s&correlator=&tfcd=0&npa=0&gdfp_req=1&output=vast&sz=400x300%7c497x280%7c640x360%7c640x480%7c1280x720%7c1920x1080&unviewed_position_start=1&vpos=preroll&cust_params=' +
      encodeURIComponent(custParams.join('&'))
    );
  }, []);

  React.useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    if (!authUserResolved) {
      return;
    }
    const imaSettings = {
      adTagUrl: authUserIsNoAdverts ? null : adTagUrl,
      showCountdown: true,
      adLabel: 'REKLAMA',
      locale: 'sk',
    };
    // make sure Video.js player is only initialized once
    const nuevoFinalOptions = { contextMenu: false, ...(nuevoOptions || {}) };
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      videojs.addLanguage('sk', videojsSK);

      const finalOptions = { language: 'sk', playsInline: true, ...options };

      playerRef.current = videojs(videoElement, finalOptions, () => {
        if (imaSettings) {
          (playerRef.current as any).ima(imaSettings);
          playerImaInitialized.current = true;
        }
        onReady && onReady(playerRef.current);
        if (onPlay) {
          if (playerRefOnPlay.current) {
            playerRef.current.off('play', playerRefOnPlay.current);
          }
          playerRefOnPlay.current = onPlay;
          playerRef.current.on('play', playerRefOnPlay.current);
        }
      });
      playerRef.current.nuevo(nuevoFinalOptions);
    } else {
      // you can update player here [update player through props]
      playerRef.current.src(options.sources);
      playerRef.current.poster(options.poster);
      playerRef.current.nuevo(nuevoFinalOptions);
      if (imaSettings && playerImaInitialized.current) {
        playerRef.current.ima.changeAdTag(imaSettings?.adTagUrl);
        playerRef.current.ima.requestAds();
      }
      if (onPlay) {
        if (playerRefOnPlay.current) {
          playerRef.current.off('play', playerRefOnPlay.current);
        }
        playerRefOnPlay.current = onPlay;
        playerRef.current.on('play', playerRefOnPlay.current);
      }
    }
  }, [
    authUserResolved,
    adTagUrl,
    options,
    onReady,
    onPlay,
    authUserIsNoAdverts,
    nuevoOptions,
  ]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        playsInline
        poster={options.poster}
      >
        {options.sources?.map((source, i) => {
          return (
            <source
              src={source.src}
              type={source.type}
              key={`videosource-${i}`}
            />
          );
        })}
      </video>
    </div>
  );
};

export default connect(mapStateToProps)(VideoJS);
