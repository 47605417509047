import { rem } from 'polished';
import styled from '../../../theme/styled-components';

export default styled.h2`
  font-family: pt-serif-pro, serif;
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  margin: 0;
  padding: 0;
  font-size: ${rem(32)};
  line-height: ${rem(38.4)};
  margin-bottom: ${rem(32)};
`;
