import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import Hider from '../../components/Hider';
import Icon from '../../components/Icon';
import ItemsList from '../../components/ItemsList';
import Header from '../../components/ItemsList/Header';
import Item from '../../components/ItemsList/Item';
import { PositionedDropdown } from '../../components/ThirdLevelMenu';
import config from '../../config';
import { RootState } from '../../configureStore';
import { loadCompetitionsList } from '../../pages/FutbalnetApp/actions';
import { uniqUnionSeasonsNamesSelector } from '../../pages/FutbalnetApp/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import Content from './Content';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { initializeOrSetListParams } from '../../pages/App/actions';

const RightArrow = styled(Icon)`
  margin-left: auto;
  padding-left: ${rem(8)};
`;

type IOwnProps = {
  xPosition: number;
  active: boolean;
  onClose: () => void;
};

const mapStateToProps = (state: RootState) => {
  return {
    seasonNames: uniqUnionSeasonsNamesSelector(state),
  };
};

const mapDispatchToProps = {
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadCompetitionsList
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const CompetitionsArchiveDropdown: React.FC<IProps> = ({
  xPosition,
  loadCompetitionsList,
  seasonNames,
  active,
  onClose,
  initializeOrSetListParams,
}) => {
  const [activeSeasonName, setActiveSeasonName] = React.useState('');

  React.useEffect(() => {
    if (!active) {
      setActiveSeasonName('');
    }
  }, [active, setActiveSeasonName]);

  React.useEffect(() => {
    (async () => {
      if (activeSeasonName) {
        await initializeOrSetListParams({
          listName: config.LIST_COMPETITIONS,
          params: {
            seasonName: activeSeasonName,
          },
        });
        await loadCompetitionsList(config.LIST_COMPETITIONS);
      }
    })();
  }, [
    activeSeasonName,
    initializeOrSetListParams,
    loadCompetitionsList,
  ]);

  return (
    <PositionedDropdown
      xPosition={xPosition - 1}
      active={active}
      onClose={onClose}
    >
      <Hider hidden={!!activeSeasonName}>
        <Header>{__('Ročník')}</Header>
        <ItemsList>
          {seasonNames.map((seasonName) => {
            return (
              <Item
                key={seasonName}
                onClick={() => {
                  setActiveSeasonName(seasonName);
                }}
              >
                {seasonName}
                <RightArrow name="arrow-right" size={16} />
              </Item>
            );
          })}
        </ItemsList>
      </Hider>
      {!!activeSeasonName && (
        <Content
          active={active}
          seasonName={activeSeasonName}
          setActiveSeasonName={setActiveSeasonName}
        />
      )}
    </PositionedDropdown>
  );
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CompetitionsArchiveDropdown),
);
