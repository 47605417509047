import { css } from '../../theme/styled-components';

export const HideScrollBarStyles = css`
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox

  ::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;
