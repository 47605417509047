import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle, ISection } from '../../../library/App';
import { MediaManagerPhoto } from '../../../library/MediaManager';
import styled from '../../../theme/styled-components';
import distanceInWordsToNow from '../../../utilities/distanceInWordsToNow';
import Icon from '../../Icon';
import Link from '../../Link';
import ArticleIcon from '../ArticleIcon';
import Authors from '../Authors';

const ArticleLink = styled(Link)`
  font-family: pt-serif-pro, serif;
  border: none;
  text-decoration: none;
  outline: none;
  font-size: ${rem(18)};
  line-height: ${rem(22)};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const ImageLink = styled(ArticleLink as any)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${rem(16)} auto 0;
  flex-shrink: 0;
`;

const Wrapper = styled.li`
  display: flex;
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;
  :hover,
  :active,
  :focus {
    color: #1d81c4;
  }
`;

// const Description = styled.span`
//   margin: 0 0 ${rem(8)} 0;
//   color: ${({ theme }) => theme.app.secondaryTextColor};
//   font-size: ${rem(12)};
//   line-height: ${rem(17)};
//   display: none;
//   ${mb('l')} {
//     display: inline;
//   }
// `;

const ArticleDescription = styled.div`
  font-size: ${rem(12)};
  line-height: ${rem(17)};
  margin: ${rem(8)} 0 ${rem(8)} 0;
  font-family: ${({ theme }) => theme.primaryFont.family };
  text-align: left;
  font-weight: 600;
  color: #333333;
  opacity: 0.8;

  ${mb('s')} {
    font-size: ${rem(12)};
    line-height: ${rem(17)};
  }
`;

const Header = styled.span`
  display: flex;
  flex-direction: column;
`;

const Pipe = styled.span`
  margin: 0 ${rem(6)};
  opacity: 0.5;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  &,
  & img {
    border-radius: ${rem(8)};
    width: ${rem(160)};
  }
`;

const AuthorLink = styled(Link)`
  &:any-link {
    cursor: pointer;
    :hover,
    :active,
    :focus {
      text-decoration: underline;
    }
  }
`;
const DiscussionLink = styled(Link)`
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;
// const SectionLink = styled(Link)`
//   border: none;
//   text-decoration: none;
//   outline: none;
//   cursor: pointer;
//   :hover,
//   :active,
//   :focus {
//     text-decoration: underline;
//   }
// `;

const SpacedIcon = styled(Icon)`
  margin-right: ${em(4)};
`;

const DiscussionLogo = styled.span`
  vertical-align: middle;
  margin-bottom: 4px;
`;

const DiscussionNumber = styled.span<{ number: number }>`
  color: ${(props) => (props.number > 20 ? '#CF2528' : '#8d8d8d')};
  padding-left: ${rem(4)};
  opacity: 1;
`;

const ArticleDate = styled.span`
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  opacity: 0.56;
  color: #333333;
  font-weight: normal;
`;

type IOwnProps = {
  article: IArticle;
  section: ISection | null;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article }) => {
  const { getArticleUrl, getArticleDiscussionUrl } =
    React.useContext(UrlContext);

  const articleDate = new Date(
    article.updated_at || article.valid_from || article.created || '',
  );

  const discussion = article.discussion?.postCount || 0;
  const description = ((article.picture as MediaManagerPhoto)?.description) || article.name;

  return (
    <Wrapper>
      <ImageLink to={getArticleUrl(article)} title={article.name}>
        <StyledMediaManagerImage
          picture={article.picture || {}}
          script="media"
          naturalWidth={160 * 2}
          naturalHeight={120 * 2}
          webp={true}
          alt={description}
          loading="lazy"
        />
        <ArticleIcon article={article} compactBreakPoint="l" />
      </ImageLink>
      <Header>
        <ArticleLink to={getArticleUrl(article)}>
          {article.is_private && <SpacedIcon name="premium" size={12} />}
          {article.name}
        </ArticleLink>
        <ArticleDescription>
          <Authors
          createAuthorsLink={[
            'pages:sportnet.sme.sk:default',
            'pages:beta.sportnet.sme.sk:default',
          ].includes(article.contentspace ?? '')}
            authors={article.authors}
            AuthorWrapper={AuthorLink}
            after={<Pipe>∙</Pipe>}
          />
          <ArticleDate>{distanceInWordsToNow(articleDate)}</ArticleDate>
          {discussion > 0 && (
            <>
              <Pipe>∙</Pipe>
              <DiscussionLink to={getArticleDiscussionUrl(article)}>
                <DiscussionLogo>
                  <Icon
                    name="discussion"
                    size={12}
                    color={discussion > 20 ? '#CF2528' : '#8d8d8d'}
                  />
                </DiscussionLogo>
                <DiscussionNumber number={discussion}>
                  {discussion}
                </DiscussionNumber>
              </DiscussionLink>
            </>
          )}
        </ArticleDescription>
      </Header>
    </Wrapper>
  );
};

export default React.memo(Article);
