import React from 'react';
import config from '../config';
import DispatchContext from '../contexts/DispatchContext';
import { initializeOrSetListParams } from '../pages/App/actions';
import { loadPanelMatchesList } from '../pages/Competition/actions';

export default function useMatchesPanel(filter?: string | null) {
  const dispatch = React.useContext(DispatchContext);

  React.useEffect(() => {
    (async () => {
      if (!filter) {
        return;
      }

      const [appSpace, competitionId, dateAfter, dateBefore] =
        filter.split(':');

      await dispatch(
        initializeOrSetListParams({
          listName: config.PANEL_COMPETITION_MATCHES,
          params: {
            appSpace,
            competitionId,
            ...(dateAfter
              ? { dateAfter: new Date(dateAfter).toISOString() }
              : {}),
            ...(dateBefore
              ? { dateBefore: new Date(dateBefore).toISOString() }
              : {}),
          },
        })
      );

      await dispatch(loadPanelMatchesList(config.PANEL_COMPETITION_MATCHES));
    })();
  }, [dispatch, filter]);
}
