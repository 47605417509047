import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import Loading from '../../../components/Loading';
import { ReduxConnectProps, RootState } from '../../../configureStore';
import { RouteProps } from '../../../library/App';
import { ITheme } from '../../../theme/theme';
import {
  loadCompetitionPartItem,
  loadCompetitionPartResults,
} from '../../Competition/actions';
import {
  currentCompetitionPartSelector,
  currentCompetitionSelector,
} from '../../Competition/selectors';
import { currentMatchSelector } from '../selectors';
import Content from './content';

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state),
  competitionPart: currentCompetitionPartSelector(state),
  competition: currentCompetitionSelector(state),
});

type OwnProps = {};

type Props = RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  ReturnType<typeof mapStateToProps> & { theme: ITheme } & OwnProps &
  ReduxConnectProps;

class MatchStandings extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    await dispatch(thunkToAction(loadCompetitionPartItem)({}) as any);
    await dispatch(thunkToAction(loadCompetitionPartResults)({}) as any);
  }

  async componentDidMount() {
    try {
      await MatchStandings.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  render() {
    const { match, competitionPart, competition } = this.props;

    if (
      !match ||
      !competition ||
      !competitionPart ||
      !competitionPart.resultsTable?.results
    ) {
      return <Loading />;
    }

    return <Content />;
  }
}

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(MatchStandings) as unknown as React.FC<OwnProps>;
