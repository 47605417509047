import React from 'react';

import { FeedItemWithCustomSmarttags } from '../../pages/Gossips/features/getFeedItemsWithBanners';
import FeedsApi from '../../api/FeedsApi';
import config from '../../config';

type IOwnProps = {
  feedItemId: FeedItemWithCustomSmarttags['_id'];
  appSpace: string;
};

type IProps = IOwnProps;

const FeedsTracker: React.FC<IProps> = ({ feedItemId, appSpace }) => {

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    const timeoutID = window.setTimeout(() => {
      FeedsApi.putFeedItemStats(
        appSpace,
        feedItemId,
      );
    }, config.FEEDITEM_STATS_DELAY);
    return () => {
      window.clearTimeout(timeoutID);
    };
  }, [feedItemId, appSpace]);

  return null;
};

export default FeedsTracker;
