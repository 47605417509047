import React from 'react';
import { MatchDetailPrintTeamNomination } from '../../../library/Print';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import { Td } from './MatchDetailPrintMatchEvent';
import { NominationItemWrapper } from './MatchDetailPrintNominations';
import { ArrayElement } from '../../../library/App';
import { SPORT_SECTOR_CREW } from '../../../codelists/sport_sector_crew';

interface OwnProps {
  sportSectorCrew: ArrayElement<typeof SPORT_SECTOR_CREW>;
  homeTeamCrew: MatchDetailPrintTeamNomination['crew'];
  awayTeamCrew: MatchDetailPrintTeamNomination['crew'];
}

const MatchDetailPrintCrew: React.FC<OwnProps> = ({
  homeTeamCrew,
  awayTeamCrew,
  sportSectorCrew,
}) => {
  // vytvorime riadky tabulky
  const rows = sportSectorCrew
    ? (sportSectorCrew.items as any[]).reduce((acc, item) => {
        // home team
        const homeTeamPerson = homeTeamCrew
          .filter(
            (homeTeamCrewPerson) => homeTeamCrewPerson.position === item._id,
          )
          .map((homeTeamCrewPerson) => ({
            name: homeTeamCrewPerson.name ?? '',
          }))
          .filter((homeTeamCrewPerson) => !!homeTeamCrewPerson.name);
        // away team
        const awayTeamPerson = awayTeamCrew
          .filter(
            (awayTeamCrewPerson) => awayTeamCrewPerson.position === item._id,
          )
          .map((awayTeamCrewPerson) => ({
            name: awayTeamCrewPerson.name ?? '',
          }))
          .filter((awayTeamCrewPerson) => !!awayTeamCrewPerson.name);
        if (awayTeamPerson.length > 0 || homeTeamPerson.length > 0) {
          acc.push({
            positionName: item.label,
            homeTeamPerson,
            awayTeamPerson,
          });
        }

        return acc;
      }, [] as { positionName: string; homeTeamPerson: { name: string }[]; awayTeamPerson: { name: string }[] }[])
    : [];

  return rows.length > 0 ? (
    <>
      {rows.map((row) => {
        return (
          <React.Fragment key={`realizacny-tim-${row.positionName}`}>
            <Tr>
              <Td colSpan={6}>
                <small>
                  <strong>{row.positionName}</strong>
                </small>
              </Td>
            </Tr>
            <Tr>
              <Td colSpan={4}>
                <NominationItemWrapper percentWidth={50}>
                  {row.homeTeamPerson.map((p) => p.name).join(', ')}
                </NominationItemWrapper>
                <NominationItemWrapper percentWidth={50} textRight={true}>
                  {row.awayTeamPerson.map((p) => p.name).join(', ')}
                </NominationItemWrapper>
              </Td>
            </Tr>
          </React.Fragment>
        );
      })}
    </>
  ) : (
    <Tr>
      <Td colSpan={6}>-</Td>
    </Tr>
  );
};

export default MatchDetailPrintCrew;
