import React from 'react';
import { SectionTreeType } from '../../library/App';
import { IDropDownPosition } from '../DropDown/library';
import ItemsDropDown from './ItemsDropDown';

interface OwnProps {
  opened: boolean;
  onClose: () => void;
  sections: SectionTreeType;
  color?: string;
  activeColor?: string;
  className?: string;
  position?: IDropDownPosition;
}

const SectionsDropDown: React.FC<OwnProps> = ({ sections, ...rest }) => {
  const items = React.useMemo(() => {
    return sections.map((s) => ({
      ...s,
      label: s.name || '',
      url: s.url,
    }));
  }, [sections]);

  return <ItemsDropDown {...rest} items={items} />;
};

export default SectionsDropDown;
