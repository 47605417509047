import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="yellow-card"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    width={size}
  >
    <path
      id="Rectangle_2547"
      fill="#FF0000"
      d="M6,2h12c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V4C4,2.9,4.9,2,6,2z"
    />
    <linearGradient
      id="Rectangle_3046_1_"
      gradientUnits="userSpaceOnUse"
      x1="-621.6429"
      y1="376.3889"
      x2="-621.6429"
      y2="375.3889"
      gradientTransform="matrix(14 0 0 -18 8715 6778)"
    >
      <stop offset="0" style={{ stopColor: '#FF0000' }} />
      <stop offset="1" style={{ stopColor: '#FFFFFF', stopOpacity: 0.4 }} />
    </linearGradient>
    <path
      id="Rectangle_3046"
      fill="#FF0000"
      d="M6.5,3h11C18.3,3,19,3.7,19,4.5v15c0,0.8-0.7,1.5-1.5,1.5h-11C5.7,21,5,20.3,5,19.5v-15
C5,3.7,5.7,3,6.5,3z"
    />
  </svg>
);
