import React from 'react';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem, transparentize } from 'polished';
import { Transfer, TransferType } from '../../library/Transfermarket';
import styled, { css } from '../../theme/styled-components';
import getPPOUserFullName from '../../utilities/getPPOUserFullName';
import getTransferTypeLabel from '../../utilities/transfers/getTransferTypeLabel';
import Icon from '../../components/Icon';
import Link, { LinkStyles } from '../../components/Link';
import getClubBaseUrlIfExists from '../../utilities/getClubBaseUrlIfExists';

const Wrapper = styled.div<{ $forceMobile?: boolean }>`
  position: relative;
  padding: ${rem(12)};
  border: none;
  display: flex;
  background-color: white;
  margin-left: ${rem(24)};
  width: 100%;
  flex-direction: column;
  ${({ $forceMobile }) =>
    !$forceMobile &&
    css`
      ${mb('s')} {
        flex-direction: row;
        align-items: center;
        padding: ${rem(16)};
      }
    `}
`;

const PlayerPhoto = styled.img<{ $forceMobile?: boolean }>`
  flex: 0 0 auto;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 100%;
  margin-right: ${rem(8)};
  font-size: ${rem(9)};
  object-fit: cover;
  ${({ $forceMobile }) =>
    !$forceMobile &&
    css`
      ${mb('s')} {
        width: ${rem(40)};
        height: ${rem(40)};
        margin-right: ${rem(16)};
      }
    `}
`;

const NoPhotoAvatar = styled(Icon)<{ $forceMobile?: boolean }>`
  border-radius: 100%;
  margin-right: ${rem(8)};
  flex: 0 0 1;
  width: ${rem(24)};
  height: ${rem(24)};
  ${({ $forceMobile }) =>
    !$forceMobile &&
    css`
      ${mb('s')} {
        width: ${rem(40)};
        height: ${rem(40)};
        margin-right: ${rem(16)};
      }
    `}
`;

const PlayerName = styled(Link)`
  ${LinkStyles}
  font-size: ${rem(12)};
  font-weight: bold;
  ${({ $forceMobile }) =>
    !$forceMobile &&
    css`
      ${mb('s')} {
        margin-right: ${rem(48)};
      }
    `}
`;

const BirdthYear = styled.span`
  font-size: ${rem(12)};
  font-weight: normal;
  color: ${({ theme }) => theme.color.secondaryFontColor};
`;

const PlayerDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
  flex: 0 0 auto;
  ${({ $forceMobile }) =>
    !$forceMobile &&
    css`
      ${mb('s')} {
        margin-bottom: 0;
        width: ${rem(250)};
      }
    `}
`;

const PpoDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: 12px minmax(auto, max-content) 12px auto;
  grid-gap: ${rem(8)};
`;

const PpoName = styled(Link)`
  ${LinkStyles}
  font-size: ${rem(12)};
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PpoNameNotLink = styled.span`
  font-size: ${rem(12)};
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.color.primaryFontColor};
`;

const TransferTypeLabel = styled.div<{ type: TransferType }>`
  position: absolute;
  width: ${rem(24)};
  height: 100%;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: ${rem(8)};
  letter-spacing: ${rem(0.32)};
`;

const TransferTypeWrapper = styled.div<{ type: TransferType }>`
  display: flex;
  align-items: center;
  position: relative;
  ${({ type = 'transfer', theme }) =>
    type === 'hosting'
      ? css`
          color: ${theme.color.link};
          background-color: ${transparentize(0.92, theme.color.link)};
        `
      : css`
          color: ${theme.color.sportnetRed};
          background-color: ${transparentize(0.92, theme.color.sportnetRed)};
        `}
`;

const Photo: React.FC<{ transfer: Transfer; forceMobile?: boolean }> = ({
  transfer,
  forceMobile,
}) => {
  const { photo } = transfer;
  if (!photo || !photo.public) {
    return <NoPhotoAvatar name="avatar" size={40} $forceMobile={forceMobile} />;
  }
  const alt = `${transfer.name} ${transfer.surname}`;
  return (
    <PlayerPhoto src={photo.public_url} alt={alt} $forceMobile={forceMobile} />
  );
};

/**
 * Ak klub neexistuje v shortnames, tak negenerujem link (napr. zahranicne kluby).
 */
const PpoNameW: React.FC<{ ppo: string; ppoName: string }> = ({
  ppo,
  ppoName,
}) => {
  const clubUrl = getClubBaseUrlIfExists({ klub: ppo });
  return clubUrl ? (
    <PpoName to={clubUrl} title={ppoName}>
      {ppoName}
    </PpoName>
  ) : (
    <PpoNameNotLink title={ppoName}>{ppoName}</PpoNameNotLink>
  );
};

const Ppo: React.FC<{ transfer: Transfer['transfer'] }> = ({ transfer }) => {
  return (
    <>
      {transfer.source_ppo_name && (
        <>
          <Icon name="redArrowLeft" size={12} />
          <PpoNameW
            ppo={transfer.source_ppo!}
            ppoName={transfer.source_ppo_name!}
          />
        </>
      )}
      <Icon name="greenArrowRight" size={12} />
      <PpoNameW
        ppo={transfer.org_profile_id}
        ppoName={transfer.org_profile_name}
      />
    </>
  );
};

interface OwnProps {
  transfer: Transfer;
  forceMobile?: boolean;
  widgetLayout?: boolean;
  className?: string;
}

const TransferComponent: React.FC<OwnProps> = ({
  transfer,
  className,
  forceMobile = false,
}) => {
  const { transfer: transferData } = transfer;

  return (
    <TransferTypeWrapper
      type={transferData.transfer_type}
      className={className}
    >
      <TransferTypeLabel type={transferData.transfer_type}>
        {getTransferTypeLabel(transferData.transfer_type as TransferType)}
      </TransferTypeLabel>
      <Wrapper $forceMobile={forceMobile}>
        <PlayerDetailWrapper $forceMobile={forceMobile}>
          <Photo transfer={transfer} forceMobile={forceMobile} />
          <PlayerName
            to={`/futbalnet/clen/${transfer.issfId}/#prestupy`}
            $forceMobile={forceMobile}
          >
            {getPPOUserFullName(transfer)}
            {transfer.birthyear && (
              <>
                {' '}
                <BirdthYear>({transfer.birthyear})</BirdthYear>
              </>
            )}
          </PlayerName>
        </PlayerDetailWrapper>
        <PpoDesktopWrapper>
          <Ppo transfer={transferData} />
        </PpoDesktopWrapper>
      </Wrapper>
    </TransferTypeWrapper>
  );
};

export default TransferComponent;
