
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import Link from '../../../components/Link';

const StyledLink = styled(Link)`
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  height: ${rem(24)};
  background-color: ${({ theme }) => theme.color.yellow};
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-weight: ${({ theme }) => theme.primaryFont.weight.semiBold};
  line-height: 1.3;
  padding: 0 ${rem(12)};
  ${mb('s')} {
    font-size: ${rem(12)};
  }
  :hover {
    text-decoration: underline;
  }
`;

type OwnProps =  {
  className?: string;
  /**
   * URL, pre odklik na kupu predplatneho.
   */
  url: string;
  /**
   * Text ktory sa zobrazi v tlacidle.
   */
  text: string;
};

export const CorporateHeaderSubscriptionButton: React.FC<OwnProps> = ({
  className,
  url,
  text,
}) => {
  return (
    <StyledLink
      className={className}
      to={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      {text}
    </StyledLink>
  );
};
