import config from '../config';
import { IArticle, IArticleContentType } from '../library/App';

// return 2D array, first line is type for video and galery, second is for everything else
export default (article: IArticle): IArticleContentType[][] => {
  const type: IArticleContentType[][] = [[], []];

  const tags = (article.smarttags || []).find(
    (t) => t.key === config.ARTICLE_CONTENT_TYPE_SMARTTAG_KEY,
  );

  if (tags && tags.values && tags.values.length > 0) {
    tags.values.forEach((tag) => {
      if (['video', 'gallery', 'podcast'].includes(tag.key)) {
        type[0].push(tag.key as IArticleContentType);
      } else {
        type[1].push(tag.key as IArticleContentType);
      }
    });
  }

  return type;
};
