
import { LinkProps } from 'react-router';
import Link from '../../components/Link';
import Icon, {ISize, IIconName as IconName } from '../../components/Icon';
import styled from 'styled-components';
import { ButtonSize } from '../types/Button';
import { StyledButton } from '../PrimaryButton/PrimaryButton';
import React from 'react';

const Button = styled(StyledButton)<{
  $hasChildren?: boolean;
  $noBorder?: boolean;
}>`
  && {
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.color.primaryFontColor};
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme, $noBorder = false }) =>
      $noBorder ? 'none' : theme.border.normalDark};
  }
`;

interface OwnProps {
  /**
   * Pretaženie styled-components. Štýluje sa priamo <button> element.
   */
  className?: string;
  /**
   * Children komponenty, ktore sa renderuje v ramci `<button></button>`.
   */
  children?: React.ReactNode;
  /**
   * Ak je zadany `href` renderuje `<a`>. Ma prednost pred `onClick` handlerom.
   * @returns
   */
  href?: LinkProps['to'];
  /**
   * Ak nie je zadany `href` pripaja `onClick` event. Ak je zadany `href`
   * a `onClick` sucasne, `href` ma prednost a renderuje `<a>` tag namiesto `<button>`.
   * @returns
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Velkost tlacidla.
   */
  size?: ButtonSize;
  /**
   * Nastavuje atribut `disabled`.
   */
  disabled?: boolean;
  /**
   * Nastavuje `title` atribut.
   */
  title?: string;
  /**
   * Ikona pred text buttonu. POZOR: Musí mať štvorcový formát!
   */
  icon?: IconName;
  /**
   * Nastavuje velkost ikony. Default je `m`, co je 16px.
   */
  iconSize?: ISize;
  /**
   * Vypína border (používa sa v reklamnom banneri PARTNERS)
   */
  noBorder?: boolean;
}

export const SecondaryButton: React.FC<OwnProps> = ({
  className,
  children,
  href = '',
  onClick,
  size = 'large',
  iconSize = 'm',
  disabled = false,
  title,
  icon,
  noBorder,
}) => (
  <Button
    onClick={!href ? onClick : undefined}
    className={className}
    $size={size}
    disabled={disabled}
    title={title}
    $hasChildren={typeof children !== 'undefined'}
    {...(href ? { to: href } : undefined)}
    as={href ? Link : 'button'}
    $noBorder={noBorder}
  >
    {icon && <Icon name={icon} size={iconSize} color="currentColor" />}
    {children}
  </Button>
);
