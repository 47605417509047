import React from 'react';
import getProp from 'sportnet-utilities/lib/getProp';
import {
  ISettingsBaseGoogleAnalytics,
  ISettingsBaseGoogleTagmanager,
} from '../../library/Pages';

interface IAnalyticsSettings {
  googleAnalytics?: ISettingsBaseGoogleAnalytics;
  googleTagmanager?: ISettingsBaseGoogleTagmanager;
}

function useAnalytics(analytics: IAnalyticsSettings) {
  const tagsObject = React.useMemo(() => {
    const script: JSX.Element[] = [];
    const noscript: JSX.Element[] = [];

    // Google analytics
    const googleAnalyticsId = getProp(analytics, ['googleAnalytics', 'id'], '');
    if (googleAnalyticsId) {
      script.push(
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
          key="analytics.gtag.script"
        />,
      );
      const config = `gtag("config", "${googleAnalyticsId}");`;
      script.push(
        <script key="analytics.gtag.datalayer">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());${config}`}</script>,
      );
    }

    // Google tag manager
    const googleTagManagerId = getProp(
      analytics,
      ['googleTagmanager', 'id'],
      '',
    );
    if (googleTagManagerId) {
      script.push(
        <script key="analytics.googleTagManager">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');`}</script>,
      );
      noscript.push(
        <noscript key="analytics.googleTagManager">
          <iframe
            title="analytics.googleTagManager"
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>,
      );
    }

    return {
      script,
      noscript,
    };
  }, [analytics]);

  return tagsObject;
}

export default useAnalytics;
