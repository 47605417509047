import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { Tr } from './RenderPrintData';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';

const Td = styled.td`
  padding: ${rem(16)} ${rem(8)};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  vertical-align: middle;
  text-transform: uppercase;
`;

const ContentLoader = styled.span`
  display: inline-block;
  width: 100%;
  height: ${rem(32)};
`

const MatchListItemLoader: React.FC = () => {
  return (
    <Tr>
      <Td colSpan={6}><ContentLoader className={SKELETON_CLASS_NAME} /></Td>
    </Tr>
  );
};

export default MatchListItemLoader;
