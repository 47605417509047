import urlConverter from '../urlConverter';

export default ({ klub }: { klub: string | number }) => {
  const convertedUnionData = urlConverter.find(
    (i) => i._id === klub || i.__issfId === Number(klub),
  );
  if (convertedUnionData) {
    return `/futbalnet/k/${convertedUnionData.url}/`;
  }
  return undefined;
};
