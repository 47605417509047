import React from 'react';
import { IArticle, ISection } from '../../library/App';
import { IPPOUser } from '../../library/Sportnet';
import getArticleUrl from '../../utilities/getArticleUrl';
import getSectionUrl from '../../utilities/getSectionUrl';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import getArticlePagesEditUrl from '../../utilities/getArticlePagesEditUrl';
import { Article_Author } from '../../api/CmsApi';
import getSmarttagPageUrl from '../../utilities/smartTags/getSmarttagPageUrl';
import { OptimizedPublicSmartTagResponse } from '../../library/SmartTags';

export interface IUrlContext {
  getArticleUrl: (article: IArticle) => string;
  getSectionUrl: (section: ISection) => string;
  getFullArticleUrl: (article: IArticle) => string;
  getFullSectionUrl: (section: ISection) => string;
  getFullSmarttagPageUrl: (section: OptimizedPublicSmartTagResponse) => string;
  getAuthorUrl: (author: IPPOUser | Article_Author) => string;
  getArticlePagesEditUrl: (
    appSpace: string,
    articleId: number | undefined,
  ) => string;
  getArticleDiscussionUrl: (article: IArticle) => string;
}

export const UrlContextDefaultValue: IUrlContext = {
  getArticleUrl: (a) =>
    getArticleUrl(a.doctype === 'article' ? '/spravy' : '', a),
  getSectionUrl: (s) => getSectionUrl('', s),
  getFullArticleUrl: (a) => getArticleUrl('/spravy', a),
  getFullSectionUrl: (s) => getSectionUrl('', s),
  getFullSmarttagPageUrl: (s) => getSmarttagPageUrl('', s),
  getAuthorUrl: (a) => getAuthorUrl('/redakcia', a),
  getArticlePagesEditUrl: (s, id) => getArticlePagesEditUrl(s, id),
  getArticleDiscussionUrl: (a) => getArticleUrl('/diskusia', a),
};

const UrlContext = React.createContext<IUrlContext>(UrlContextDefaultValue);

export default UrlContext;
