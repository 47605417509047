import { emphasizeColor } from '@sportnet/ui/lib/Themes/utilities';
import {
  ITeam,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/library/Competitions';
import { __ } from '@sportnet/content/utilities/utilities';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import { format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import getIceHockeyEndResultType from '../../../../utilities/getIceHockeyEndResultType';
import Link from '../../../Link';
import isMatchLive from '@sportnet/content/utilities/matches/isMatchLive';
import getFootballEndResultType from '../../../../utilities/getFootballEndResultType';
import getFallbackClubOrUnionLogo from '../../../../utilities/getFallbackClubOrUnionLogo';
import Image from '@sportnet/ui/lib/Image';

export const TeamLogoWrapper = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
  flex-shrink: 0;
`;

export const TeamLogo = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
`;

export const SEGMENT_HEIGHT = 80;

const SegmentMatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .active {
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => emphasizeColor(1, theme.color.primary)};
  }
  > div:first-child {
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
    margin-bottom: -${rem(2)};
  }
`;

const SegmentRow = styled.div<{ isLive?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: ${rem(11)};
  align-items: center;
  padding: 0 ${rem(8)};
  height: ${rem(SEGMENT_HEIGHT / 2)};
  color: ${({ isLive = false, theme }) =>
    isLive ? theme.color.danger : theme.color.text};
`;

const MatchesResults = styled.div<{ winningSide: boolean }>`
  align-items: center;
  display: flex;
  font-size: ${rem(14)};
  font-weight: ${({ winningSide }) => (winningSide ? '800' : 'normal')};
  & > div {
    padding: ${`0 ${rem(3)}`};
  }
  a {
    color: inherit;
  }
  sup {
    font-size: ${rem(10)};
    font-weight: 700;
  }
`;

const SegmentTeam = styled.div`
  display: flex;
  width: ${rem(SEGMENT_HEIGHT * 4 - 65)};
  align-items: center;
`;

const SegmentTeamName = styled.div<{ winningSide: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  font-size: ${rem(14)};
  font-weight: ${({ winningSide }) => (winningSide ? '800' : 'normal')};
`;

interface IOwnProps {
  appSpace: string; // appSpace sutaze, resp zapasu (nie appSpace teamu)
  segmentMatches: IWidgetMatchesListMatchItem[];
  homeTeam?: ITeam;
  awayTeam?: ITeam;
}

const SegmentMatches: React.FC<IOwnProps> = ({
  appSpace,
  segmentMatches,
  homeTeam,
  awayTeam,
}) => {
  const renderSegmentRow = (
    team?: ITeam,
    opposingTeam?: ITeam,
    isWinningSide?: boolean,
    hasWinningSide?: boolean,
    idx?: number
  ) => {
    return (
      <SegmentRow
        isLive={segmentMatches[0] ? isMatchLive(segmentMatches[0]) : false}
      >
        <SegmentTeam>
          {team && (
            <>
              <TeamLogoWrapper style={{ marginRight: rem(12) }}>
                <TeamLogo
                  fallbackSrc={getFallbackClubOrUnionLogo()}
                  alt={team && (team.displayName || team.name)}
                  src={!!team ? getTeamLogoSrc(appSpace, team) : ''}
                  loading="lazy"
                />
              </TeamLogoWrapper>
              <SegmentTeamName winningSide={!!isWinningSide}>
                {team && (team.displayName || team.name)}
              </SegmentTeamName>
            </>
          )}
        </SegmentTeam>
        <MatchesResults winningSide={!!isWinningSide}>
          {segmentMatches.map((m) => {
            const scoreIdx =
              team?.additionalProperties.homeaway === 'home' ? 0 : 1;
            let teamGoals =
              m.score && m.score.length > 0 ? m.score[scoreIdx] : '';
            if (
              m.penaltiesScore &&
              m.penaltiesScore[scoreIdx] >
                m.penaltiesScore[scoreIdx === 0 ? 1 : 0]
            ) {
              teamGoals = (teamGoals as number) + 1;
            }
            let PPWinnerTeamId = null;
            let SNWinnerTeamId = null;
            let PENWinnerTeamId = null;
            if (appSpace === 'iihf' && m.timer && m.timer.OT) {
              const result = getIceHockeyEndResultType(m, {
                homeTeamId:
                  idx === 0 ? team?._id || '' : opposingTeam?._id || '',
                awayTeamId:
                  idx === 1 ? opposingTeam?._id || '' : team?._id || '',
              });
              PPWinnerTeamId = result.PPWinnerTeamId;
              SNWinnerTeamId = result.SNWinnerTeamId;
            } else if (appSpace === 'uefa' && m.timer && m.timer['1ET']) {
              const result = getFootballEndResultType(m, {
                homeTeamId:
                  idx === 0 ? team?._id || '' : opposingTeam?._id || '',
                awayTeamId:
                  idx === 1 ? team?._id || '' : opposingTeam?._id || '',
              });

              PPWinnerTeamId = result.PPWinnerTeamId;
              PENWinnerTeamId = result.SNWinnerTeamId;
            }
            if (hasWinningSide) {
              return (
                <div key={m._id}>
                  {teamGoals}
                  {PPWinnerTeamId === team?._id && <sup>{__('PP')}</sup>}
                  {SNWinnerTeamId === team?._id && <sup>{__('SN')}</sup>}
                  {PENWinnerTeamId === team?._id && <sup>{__('p.k.')}</sup>}
                  {(PPWinnerTeamId || SNWinnerTeamId || PENWinnerTeamId) &&
                    PPWinnerTeamId !== team?._id &&
                    SNWinnerTeamId !== team?._id &&
                    PENWinnerTeamId !== team?._id && <sup />}
                </div>
              );
            } else if (idx === 0) {
              return (
                <div key={m._id} style={{ fontSize: rem(12) }}>
                  {format(new Date(m.startDate), 'd.M.Y HH:mm')}
                </div>
              );
            }
            return <div key={m._id} />;
          })}
        </MatchesResults>
      </SegmentRow>
    );
  };

  let winningSide: string | null | undefined = null;
  if (
    (segmentMatches.length &&
      (segmentMatches[0].score || []).length &&
      segmentMatches[0].score![0] > segmentMatches[0].score![1]) ||
    (segmentMatches.length &&
      (segmentMatches[0].penaltiesScore || []).length &&
      segmentMatches[0].penaltiesScore![0] >
        segmentMatches[0].penaltiesScore![1])
  ) {
    winningSide = homeTeam?._id;
  } else if (
    (segmentMatches.length &&
      (segmentMatches[0].score || []).length &&
      segmentMatches[0].score![1] > segmentMatches[0].score![0]) ||
    (segmentMatches.length &&
      (segmentMatches[0].penaltiesScore || []).length &&
      segmentMatches[0].penaltiesScore![1] >
        segmentMatches[0].penaltiesScore![0])
  ) {
    winningSide = awayTeam?._id;
  }

  const content = (
    <SegmentMatchesWrapper>
      {renderSegmentRow(
        homeTeam,
        awayTeam,
        winningSide === homeTeam?._id,
        !!winningSide,
        0
      )}
      {renderSegmentRow(
        awayTeam,
        homeTeam,
        winningSide === awayTeam?._id,
        !!winningSide,
        1
      )}
    </SegmentMatchesWrapper>
  );

  const detailUrl = getProp(segmentMatches, [0, 'detailUrl'], '');

  if (detailUrl) {
    return <Link to={detailUrl}>{content}</Link>;
  }
  return content;
};

export default SegmentMatches;
