import React from 'react';
import { rem } from 'polished';
import styled from '../../../theme/styled-components';
import Image from '@sportnet/ui/lib/Image';
import { connect } from 'react-redux';
import { RootState } from '../../../configureStore';
import { ISection, SectionNode } from '../../../library/App';
import { currentSectionSelector } from '../../../pages/Section/selectors';
import { getSectionSubTree } from '../../../pages/App/selectors';
import { panelMainEventMatchesSelector } from '../../../pages/Competition/selectors';
import { getListResults } from '@sportnet/redux-list';
import config from '../../../config';
import Link from '../../../components/Link';
import UrlContext from '../../../contexts/UrlContext';
import useMatchesPanelMainEventMatches from '../../../hooks/useMatchesPanelMainEventMatches';
import MatchesSlider from './MatchesSlider';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { HideScrollBarStyles } from '../../../components/Layout/ScrollStyles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mb('m')} {
    height: ${rem(48)};
    border-radius: ${rem(4)} ${rem(4)} 0 0;
    flex-direction: row;
  }
`;

// const MobileHeaderWrapper = styled.div`
//   display: none;
//   height: ${rem(70)};
//   ${mb('m')} {
//     display: none;
//   }
// `;

const DesktopHeaderWrapper = styled.div`
  display: none;
  ${mb('m')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${rem(8)} ${rem(16)};
    width: 100%;
    height: 100%;
    border-width: ${rem(1)} ${rem(1)} 0 ${rem(1)};
    border-style: solid;
    border-color: ${({ theme }) => theme.color.sportnetGray};
    border-radius: ${rem(4)} 0 0 0;
    background-color: #fff;
  }
`;

const MobileHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-width: ${rem(1)} ${rem(1)} 0 ${rem(1)};
  border-style: solid;
  border-color: ${({ theme }) => theme.color.sportnetGray};
  border-radius: ${rem(4)} ${rem(4)} 0 0;
  height: 100%;
  background-color: #fff;
  padding: ${rem(16)};
  ${mb('m')} {
    display: none;
  }
`;

const LogoWithMainTitleWrapper = styled.div`
  display: none;
  ${mb('m')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: ${rem(24)};
    flex: 1 0 auto;
  }
`;

const MobileMainAndChildSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChildSectionsMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
  margin: 0 0 ${rem(-1)} 0;
  display: flex;
  ${HideScrollBarStyles};
  scrollbar-width: none;
`;

const MainImage = styled(Image)`
  flex-shrink: 0;
  width: ${rem(32)};
  height: ${rem(32)};
  object-fit: contain;
  margin-right: ${rem(16)};
  vertical-align: bottom; /* fix for bottom gap */
`;

const MainSectionLink = styled(Link)`
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  :focus,
  :active,
  :hover {
    text-decoration: underline;
  }
  margin-bottom: ${rem(4)};
  ${mb('m')} {
    margin-bottom: 0;
    flex: 1 0 auto;
  }
`;

const SectionLink = styled(Link)`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.app.textColor};
  text-decoration: underline;
  font-size: ${rem(12)};
  line-height: ${rem(15.6)};
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  :focus,
  :active,
  :hover {
    text-decoration: underline;
  }
  margin-right: ${rem(16)};
`;

const MobileThirdMenuSectionLinkWrapper = styled.div`
display: none;
  ${mb('s')} {
    display: flex;
    flex: 0 0 auto;
  }
`;

/**
MATCHES_PANEL_MAINEVENT_LOGO=https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2022/01/beijing-2022-olympic-color.svg
MATCHES_PANEL_MAINEVENT_LOGO_ALT=ZOH 2022
MATCHES_PANEL_MAINEVENT_COMPETITION=international-olympic-committee:61f7e2cffe9c183414c8e6f2
MATCHES_PANEL_MAINEVENT_ROOT_SECTION=zoh-2022-peking
MATCHES_PANEL_MAINEVENT_THIRD_MENU_SECTION=zoh-2022-medailova-bilancia
MATCHES_PANEL_COMPETITION=iihf:61f78f49383ee3de7110fc78
MATCHES_PANEL_ROOT_SECTION=zoh-2022-hokej
MATCHES_PANEL_PROMO_STRIP_BG_COLOR=#003680
MATCHES_PANEL_PROMO_STRIP_COLOR=#fff
MATCHES_PANEL_PROMO_STRIP_LOGO_MARGIN_RIGHT=20
MATCHES_PANEL_PROMO_STRIP_LOGO_SRC=https://mediamanager.ws/images/0x40xmedia/pages/s/sportnet.sme.sk/2022/02/az-logo-negative-rgb.png
MATCHES_PANEL_PROMO_STRIP_TARGET_URL=https://www.allianz.sk/?utm_source=widget&utm_medium=banner&utm_campaign=zoh-peking
MATCHES_PANEL_PROMO_STRIP_TEXT=Bezpečné online poistenie.
 */

const mapStateToProps = (state: RootState, props: IOwnProps) => {
  const section =
    props.currentSection || (currentSectionSelector(state) as ISection);
  let contentSection: SectionNode | null = null;
  let thirdMenuSection: SectionNode | null = null;
  if (section && section.settings?.MATCHES_PANEL_MAINEVENT_ROOT_SECTION) {
    contentSection = getSectionSubTree(
      state,
      section.settings?.MATCHES_PANEL_MAINEVENT_ROOT_SECTION,
    );
  }
  if (section && section.settings?.MATCHES_PANEL_MAINEVENT_THIRD_MENU_SECTION) {
    thirdMenuSection = getSectionSubTree(
      state,
      section.settings?.MATCHES_PANEL_MAINEVENT_THIRD_MENU_SECTION,
    );
  }

  return {
    section,
    matches: panelMainEventMatchesSelector(state),
    isLoading: !getListResults(config.PANEL_MAINEVENT_COMPETITION_MATCHES)(
      state,
    ),
    contentSection,
    thirdMenuSection
  };
};

type IOwnProps = {
  logoSrc: string;
  logoAlt: string;
  competitionId: string;
  rootSectionId: string;
  currentSection?: ISection | null;
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

export const MainEventTopPanel: React.FC<IProps> = ({
  logoSrc,
  logoAlt,
  competitionId,
  contentSection,
  matches,
  thirdMenuSection
}) => {
  const { getSectionUrl } = React.useContext(UrlContext);

  useMatchesPanelMainEventMatches(competitionId);

  if (!contentSection) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <DesktopHeaderWrapper>
          <LogoWithMainTitleWrapper>
            <MainImage src={logoSrc} alt={logoAlt} />
            <MainSectionLink to={getSectionUrl(contentSection as ISection)}>
              {contentSection.name}
            </MainSectionLink>
          </LogoWithMainTitleWrapper>
          <ChildSectionsMenuWrapper>
            {(contentSection.sections || []).slice(0, 2).map((s) => (
              <SectionLink key={s._id} to={getSectionUrl(s as ISection)}>
                {s.name}
              </SectionLink>
            ))}
            {thirdMenuSection && (
              <SectionLink to={getSectionUrl(thirdMenuSection as ISection)}>
                {thirdMenuSection.name}
              </SectionLink>
            )}
          </ChildSectionsMenuWrapper>
        </DesktopHeaderWrapper>
        <MobileHeaderWrapper>
            <MainImage src={logoSrc} alt={logoAlt} />
            <MobileMainAndChildSectionsWrapper>
              <MainSectionLink to={getSectionUrl(contentSection as ISection)}>
                {contentSection.name}
              </MainSectionLink>
              <ChildSectionsMenuWrapper>
                {(contentSection.sections || []).slice(0, 2).map((s) => (
                  <SectionLink key={s._id} to={getSectionUrl(s as ISection)}>
                    {s.name}
                  </SectionLink>
                ))}
                {thirdMenuSection && (
                  <MobileThirdMenuSectionLinkWrapper>
                  <SectionLink to={getSectionUrl(thirdMenuSection as ISection)}>
                    {thirdMenuSection.name}
                  </SectionLink>
                  </MobileThirdMenuSectionLinkWrapper>
                )}
              </ChildSectionsMenuWrapper>
            </MobileMainAndChildSectionsWrapper>
          </MobileHeaderWrapper>
        {matches && matches.length > 0 && <MatchesSlider matches={matches} />}
      </Wrapper>
      
    </>
  );
};

export default connect(mapStateToProps)(MainEventTopPanel);
