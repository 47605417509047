import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../../theme/styled-components';
import FormGroupContext from '../../FormGroup/FormGroupContext';
import { IIconName } from '../../Icon';
import InputButton from './Button';
import InputIcon from './Icon';
import InputLoading from './Loading';
import InputSelect from './Select';
import { getBorderFromProps } from './utils';

const Input = styled.input`
  width: 100%;
  flex: 1 0 0;
  border: 0;
  font-size: ${rem(14)};
  padding: 0 ${rem(14)};
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
  outline: 0;
  background-color: transparent;
  &::placeholder {
    color: ${({ theme }) => theme.color.secondaryFontColor};
  }
  &:read-only {
    cursor: ${({ theme }) => theme.input.readOnlyCursor};
  }
  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.textColor};
    background-color: transparent;
    cursor: ${({ theme }) => theme.input.disabledCursor};
  }
  height: ${rem(40)};
  ${mb('s')} {
    height: ${rem(48)};
  }
`;

export const Label = styled.label`
  font-size: ${rem(11)};
  margin-bottom: ${rem(8)};
  display: inline-block;
  color: ${({ theme }) => theme.color.primaryFontColor};
`;

interface IWrapper {
  focused: boolean;
  readOnly: boolean;
  error: boolean;
  simple?: boolean;
}
const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: row;
  border-left: 0;
  border-right: 0;
  background-color: #fff;
  > * {
    border-radius: 0;
  }
  box-shadow: inset 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
  .clickable {
    cursor: pointer;
  }
  ${(props) => {
    return !!props.simple
      ? css`
          > :first-child {
            padding-left: 0;
          }
          > :last-child {
            padding-right: 0;
          }
          > input,
          > .input-button {
            border-bottom: ${getBorderFromProps(props)};
          }
          > .input-icon,
          > .input-loader-wrapper {
            display: flex;
            border-bottom: ${getBorderFromProps(props)};
            padding: 0;
            width: ${rem(40)};
            justify-content: center;
          }
          > .input-select {
            border-bottom: ${getBorderFromProps(props)};
            padding-right: ${rem(5)};
            background: ${props.theme.separatorColor};
            select {
              border: 0;
              height: 100%;
              background: transparent;
              font-size: 13px;
            }
          }
          > .input-button {
            border-left: 0;
            border-right: 0;
          }
        `
      : css`
          input {
            padding: 0;
          }
          border-radius: ${rem(props.theme.app.borderRadius)};
          > input,
          > .input-button {
            border-top: ${getBorderFromProps(props)};
            border-bottom: ${getBorderFromProps(props)};
          }
          > :first-child {
            border-left: ${getBorderFromProps(props)};
            border-top-left-radius: ${rem(props.theme.app.borderRadius)};
            border-bottom-left-radius: ${rem(props.theme.app.borderRadius)};
            &.input-button {
              border-left: ${getBorderFromProps(props)};
            }
          }
          > :last-child {
            border-right: ${getBorderFromProps(props)};
            border-top-right-radius: ${rem(props.theme.app.borderRadius)};
            border-bottom-right-radius: ${rem(props.theme.app.borderRadius)};
            &.input-button {
              border-right: ${getBorderFromProps(props)};
            }
          }
          > .input-icon,
          > .input-loader-wrapper {
            display: flex;
            border-top: ${getBorderFromProps(props)};
            border-bottom: ${getBorderFromProps(props)};
            padding: 0;
            width: ${rem(40)};
            justify-content: center;
            align-items: center;
          }
          > .input-icon {
            padding: 0;
            width: ${rem(40)};
            justify-content: center;
          }
          > .input-select {
            border-top: ${getBorderFromProps(props)};
            border-bottom: ${getBorderFromProps(props)};
            padding-right: ${rem(5)};
            background: ${props.theme.separatorColor};
            select {
              border: 0;
              height: 100%;
              background: transparent;
              font-size: 13px;
            }
          }
          > .input-button {
            border-left: 0;
            border-right: 0;
          }
        `;
  }}
  ${mb('s')} {
    > .input-icon {
      padding: 0;
      width: ${rem(48)};
      justify-content: center;
    }
    > .input-loader-wrapper {
      display: flex;
      padding: 0;
      width: ${rem(48)};
      justify-content: center;
      align-items: center;
    }
  }
`;

export interface OwnProps {
  simple?: boolean;
  label?: string;
  className?: string;
  id?: string;
  name?: string;
  icon?: IIconName;
  inputRef?: any;
  loading?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: React.EventHandler<React.FocusEvent<HTMLInputElement>>;
  onFocus?: React.EventHandler<React.FocusEvent<HTMLInputElement>>;
  onKeyDown?: React.EventHandler<React.KeyboardEvent<HTMLInputElement>>;
  error?: boolean;
  type?: string;
  value?: string;
  onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
  children?: React.ReactNode;
  onInputClick?: () => void;
}

type Props = OwnProps;

class CompInput extends React.PureComponent<Props> {
  static Button = InputButton;
  static Icon = InputIcon;
  static Loading = InputLoading;
  static Select = InputSelect;

  state = {
    focused: false,
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
    this.setState({ focused: true });
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
    this.setState({ focused: false });
  };

  render() {
    const {
      className,
      children,
      label,
      icon,
      loading,
      inputRef,
      id,
      onInputClick,
      ...restProps
    } = this.props;
    const { focused } = this.state;

    if (!children) {
      // no children basic input
      return (
        <CompInput {...this.props}>
          <input />
          {!!icon && <InputIcon name={icon} />}
          {!!loading && <InputLoading />}
        </CompInput>
      );
    }
    return (
      <FormGroupContext.Consumer>
        {(value) => (
          <div style={{ position: 'relative' }}>
            {label && <Label>{label}</Label>}
            <Wrapper
              focused={focused}
              simple={!!this.props.simple}
              readOnly={!!this.props.readOnly}
              error={!!restProps.error}
              onClick={onInputClick}
            >
              {React.Children.map(children, (child: any) => {
                if (!child) {
                  return child;
                } else if (child.type === 'input') {
                  return (
                    <Input
                      {...restProps}
                      className={className}
                      ref={inputRef}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      id={id || value.id}
                      readonly={this.props.readOnly}
                    />
                  );
                } else if (
                  child.type === InputButton ||
                  child.type === InputIcon ||
                  child.type === InputLoading ||
                  child.type === InputSelect
                ) {
                  return React.cloneElement(child, {
                    error: restProps.error,
                  });
                }
                return undefined;
              })}
            </Wrapper>
          </div>
        )}
      </FormGroupContext.Consumer>
    );
  }
}

export default CompInput;
