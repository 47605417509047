/**
 * Komponent renderuje sutaze daneho zvazu.
 * Pouziva sa na strankach sutazi, kde renderuje sutaze zvazu v aktualnej sezone.
 */
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import config from '../../config';
import { RootState } from '../../configureStore';
import { Category, ICompetition } from '../../library/Competitions';
import { UnionItem } from '../../library/Union';
import { currentCompetitionSelector } from '../../pages/Competition/selectors';
import getCategorizedCompetitions from '../../utilities/getCategorizedCompetitions';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import hasFemaleCompetitions from '../../utilities/hasFemaleCompetititons';
import hasMaleCompetitions from '../../utilities/hasMaleCompetititons';
import hasUCompetitions from '../../utilities/hasUCompetitions';
import sortCompetitionsFn from '../../utilities/sortCompetitionsFn';
import __ from '../../utilities/__';
import ContentNotFound from '../ContentNotFound/withAnimation';
import FutbalnetTabber from '../FutbalnetTabber';
import Link from '../Link';
import {
  Header,
  HeaderTextWrapper,
  LogoImg,
  LogoImgWrapper,
  MainTitle,
  MenuItem,
  MenuItemLabel,
  MenuWrapper,
  OwnerName,
  TabPane,
  TabPaneWrapper,
  UnionName,
  Wrapper,
} from './styles';

interface OwnProps {
  union: UnionItem;
}

const CustomLink = styled(Link)`
  :hover {
    text-decoration: underline;
  }
`;

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
});

const UnionCompetitionsWidget: React.FC<
  ReturnType<typeof mapStateToProps> & OwnProps
> = ({ competition, union }) => {
  const [activeTab, setActiveTab] = React.useState<Category>(Category.MALE);

  const unionId = union._id;

  // sutaze zvazu roztriedene do kategorii (muzi, zeny, mladez) a zotriedene sortovacou
  // funkciou `sortCompetitionsFn`.
  const competitions = React.useMemo(() => {
    if (union && union.competitions && union.competitions.length) {
      const catComps = getCategorizedCompetitions(union.competitions || []);
      return {
        [Category.MALE]: catComps[Category.MALE].sort(sortCompetitionsFn),
        [Category.FEMALE]: catComps[Category.FEMALE].sort(sortCompetitionsFn),
        [Category.OTHER]: catComps[Category.OTHER].sort(sortCompetitionsFn),
      };
    } else {
      return {
        [Category.MALE]: [],
        [Category.FEMALE]: [],
        [Category.OTHER]: [],
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unionId]);

  const getUnionNameLink = () => {
    return union ? (
      <CustomLink to={getUnionBaseUrl({ zvaz: union._id || '' })}>
        {union.name}
      </CustomLink>
    ) : (
      ''
    );
  };

  const renderCompetitions = (renderedCompetitions: ICompetition[]) => (
    <>
      {renderedCompetitions.length > 0 ? (
        <MenuWrapper>
          {renderedCompetitions.map((c) => {
            const baseUrl = getCompetitionBaseUrl({
              zvaz: c.appSpace,
              sutaz: c.__issfId || c.competitionGroupId,
            });
            return (
              <MenuItem to={baseUrl} key={c._id}>
                <MenuItemLabel>{c.name}</MenuItemLabel>
              </MenuItem>
            );
          })}
        </MenuWrapper>
      ) : (
        <ContentNotFound title={__('Nenašli sa žiadne súťaže')} />
      )}
    </>
  );

  const renderTabPanes = () => {
    return (
      <TabPaneWrapper>
        {competitions[Category.MALE].length > 0 && (
          <TabPane active={activeTab === Category.MALE}>
            {renderCompetitions(competitions[Category.MALE])}
          </TabPane>
        )}
        {competitions[Category.FEMALE].length > 0 && (
          <TabPane active={activeTab === Category.FEMALE}>
            {renderCompetitions(competitions[Category.FEMALE])}
          </TabPane>
        )}
        {competitions[Category.OTHER].length > 0 && (
          <TabPane active={activeTab === Category.OTHER}>
            {renderCompetitions(competitions[Category.OTHER])}
          </TabPane>
        )}
      </TabPaneWrapper>
    );
  };

  return (
    <Wrapper>
      <Header>
        <OwnerName>
          <LogoImgWrapper>
            <LogoImg
              alt={union.name}
              src={getPpoLogoSrc(union as Required<UnionItem>)}
            />
          </LogoImgWrapper>
          <HeaderTextWrapper>
            <MainTitle>
              {__('Súťaže')}{' '}
              {competition
                ? competition.season?.name
                : config.ACTIVE_SEASON_NAME}
            </MainTitle>
            <UnionName>{getUnionNameLink()}</UnionName>
          </HeaderTextWrapper>
        </OwnerName>
      </Header>
      <MenuWrapper>
        <FutbalnetTabber
          size="s"
          active={activeTab}
          onChange={(i) => {
            if (i.value) {
              setActiveTab(i.value as Category);
            }
          }}
          tabs={[
            ...(!!hasMaleCompetitions(union)
              ? [{ label: __('Muži'), value: Category.MALE }]
              : []),
            ...(!!hasFemaleCompetitions(union)
              ? [{ label: __('Ženy'), value: Category.FEMALE }]
              : []),
            ...(!!hasUCompetitions(union)
              ? [{ label: __('Mládežnícke súťaže'), value: Category.OTHER }]
              : []),
          ]}
        />
      </MenuWrapper>
      {renderTabPanes()}
    </Wrapper>
  );
};

export default connect(mapStateToProps)(UnionCompetitionsWidget);
