import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { IMatchesList } from '@sportnet/content/library/Competitions';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import MatchPreview from './preview';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  ${({ theme }) => css`
    margin: 0 -${rem(theme.grid.gutterWidth / 2)};
    width: calc(100% + ${rem(theme.grid.gutterWidth)});
  `}
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const MatchWrapper = styled.div`
  padding: ${({ theme }) =>
    `${rem(theme.grid.gutterWidth / 4)} ${rem(theme.grid.gutterWidth / 2)}`};
`;

const MatchesWrapper = styled.div`
  > div {
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  }
`;

const defaultSize = {
  innerHeight: 0,
  innerWidth: 0,
  outerHeight: 0,
  outerWidth: 0,
};

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(defaultSize);

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
        outerHeight: window.outerHeight,
        outerWidth: window.outerWidth,
      });
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}

function useIsResponsiveLayout(breakpoint = 768) {
  const resizeHook = useWindowSize();

  const mobileLayout = React.useMemo(() => {
    return resizeHook.innerWidth ? resizeHook.innerWidth <= breakpoint : false;
  }, [resizeHook.innerWidth, breakpoint]);

  return mobileLayout;
}

interface IOwnProps {
  matchesList: IMatchesList;
}

type Props = IOwnProps;

const MatchesList: React.FC<Props> = ({ matchesList }) => {
  const mobileLayout = useIsResponsiveLayout();

  const renderMatchesList = () => (
    <MatchesWrapper>
      {matchesList.matches.map((match) => (
        <MatchWrapper key={`match-${match._id}`}>
          <MatchPreview
            view={!mobileLayout ? 'streched' : 'compact'}
            match={match}
          />
        </MatchWrapper>
      ))}
    </MatchesWrapper>
  );

  return <Wrapper>{renderMatchesList()}</Wrapper>;
};

export default MatchesList;
