import {
  formatParameters,
  getListByParametersDomain,
  getListParameters,
  getListResults,
  isCommiting,
  listDomain,
} from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import { SectionId, SectionNode, SectionTreeType } from '../../library/App';
import findSectionInTree from '../../utilities/findSectionInTree';
import findSectionPath from '../../utilities/findSectionPath';
import isBrowser from '../../utilities/isBrowser';
import serializeParameters from '../../utilities/serializeParameters';
import { IDetailInitialState } from './reducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

// Detail

export const detailTypeSelector = <T extends keyof RootState['detail']>(
  type: T,
) =>
  createSelector(
    (state: RootState) => state.detail,
    () => type,
    (domain, detailType) => domain[detailType],
  );

export const detailDomainSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(
    detailTypeSelector(type),
    () => params,
    (detailType, parameters) => {
      const key = serializeParameters(parameters, Object.keys(params));
      const r = getProp(detailType, [key], {}) as Partial<
        IDetailInitialState<any>['']
      >;
      return r;
    },
  );

export const isFetchingDetail = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(
    detailDomainSelector(type, params),
    (domain) => !!domain.isFetching,
  );

export const didFailDetail = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(
    detailDomainSelector(type, params),
    (domain) => !!domain.error,
  );

export const errorDetailSelector = (
  type: keyof RootState['detail'],
  params: { [key: string]: any },
) =>
  createSelector(detailDomainSelector(type, params), (domain) => domain.error);

export const detailDataSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(detailDomainSelector(type, params), (domain) => domain.data);

export const detailLoadedSelector = <T extends keyof RootState['detail']>(
  type: T,
  params: { [key: string]: any },
) =>
  createSelector(detailDomainSelector(type, params), (domain) =>
    domain && (domain.data || domain.error) ? true : false,
  );

// Section Tree
export const sectionTreeSelector = (params: {
  sectionIdOrUniqId?: SectionId | string;
  treelevel?: number;
  appSpace?: string;
}) =>
  createSelector(
    entitiesSelector,
    detailDataSelector('sectionTreeByParameters', params),
    (entities, treeData): SectionTreeType => {
      const tree = getProp(treeData, ['tree'], []);

      const traverseTree = (sections: SectionTreeType): SectionTreeType => {
        return sections.map((section) => {
          if (section.sections && section.sections.length > 0) {
            return {
              ...entities.sections[section._id!],
              sections: traverseTree(section.sections),
            };
          }
          return entities.sections[section._id!];
        });
      };

      return traverseTree(tree);
    },
  );

export const getSectionSubTreeByParameters = createSelector(
  (_: RootState, sectionIdOrUniqId: SectionId | string) => sectionIdOrUniqId,
  (
    state: RootState,
    sectionIdOrUniqId: SectionId | string,
    parameters: {
      sectionIdOrUniqId?: SectionId | string;
      treelevel?: number;
      appSpace?: string;
    },
  ) => sectionTreeSelector(parameters)(state),
  (sectionIdOrUniqId, tree): SectionNode | null => {
    return findSectionInTree(sectionIdOrUniqId, tree);
  },
);

export const sectionPathByParameterSelector = createSelector(
  (_: RootState, sectionIdOrUniqId: SectionId | string | null) =>
    sectionIdOrUniqId,
  (
    state: RootState,
    sectionIdOrUniqId: SectionId | string,
    parameters: {
      sectionIdOrUniqId?: SectionId | string;
      treelevel?: number;
      appSpace?: string;
    },
  ) => sectionTreeSelector(parameters)(state),
  (sectionIdOrUniqId, tree): SectionNode[] | null => {
    if (!sectionIdOrUniqId) {
      return null;
    }
    return findSectionPath(sectionIdOrUniqId, tree);
  },
);

const authorizationDomain = (state: RootState) =>
  state.application.authorization;

export const authUserResolvedSelector = createSelector(
  authorizationDomain,
  (domain) => (isBrowser() ? !domain.fetching : true),
);

export const authUserSelector = createSelector(authorizationDomain, (domain) =>
  domain && domain.user ? domain.user : undefined,
);
export const authUserFetchingSelector = createSelector(
  authorizationDomain,
  (domain) => (domain ? domain.fetching : 0),
);
export const authUserIsEditorSelector = createSelector(
  authorizationDomain,
  (domain) => (domain ? domain.isEditor : false),
);
export const authUserIsSubscribedSelector = createSelector(
  authUserSelector,
  (authUser) =>
    authUser
      ? config.REMP_SUBSCRIPTION_GROUPS.some((sg) =>
          ((authUser as any).groups || []).includes(sg),
        )
      : false,
);
export const authUserIsNoAdvertsSelector = createSelector(
  authUserSelector,
  (authUser) => {
    return authUser
      ? config.REMP_NO_ADVERT_GROUPS.some((sg) =>
          ((authUser as any).groups || []).includes(sg),
        )
      : false;
  },
);
export const allSportSectorPhasesSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorPhases,
);
export const sportSectorPhasesSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorPhases[config.SPORT_SECTOR],
);
export const allSportSectorEventsSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorEvents,
);
export const sportSectorEventsSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorEvents[config.SPORT_SECTOR],
);
export const sportSectorTelevisionBroadcastersSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorTelevisionBroadcasters[config.SPORT_SECTOR],
);
export const sportSectorInternetBroadcastersSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorInternetBroadcasters[config.SPORT_SECTOR],
);
export const allSportSectorDelegatesSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorDelegates,
);
export const sportSectorDelegatesSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorDelegates[config.SPORT_SECTOR],
);
export const allSportSectorCrewSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorCrew,
);
export const sportSectorCrewSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorCrew[config.SPORT_SECTOR] || {},
);
export const allSportSectorSettingsSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorSettings,
);
export const sportSectorSettingsSelector = createSelector(
  entitiesSelector,
  (entities) => entities.sportSectorSettings[config.SPORT_SECTOR],
);

export const comiteesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMITEES),
  (entities, ids) => at(entities.comitees, ids || []),
);

export const sectionTreeDomainSelector = (state: RootState) =>
  state.sectionTree;

export const sectionWholeTreeSelector = createSelector(
  entitiesSelector,
  sectionTreeDomainSelector,
  (entities, treeDomain): SectionTreeType => {
    const tree = getProp(treeDomain, ['tree'], []);

    const traverseTree = (sections: SectionTreeType): SectionTreeType => {
      return sections.map((section) => {
        if (section.sections && section.sections.length > 0) {
          return {
            ...entities.sections[section._id!],
            sections: traverseTree(section.sections),
          };
        }
        return entities.sections[section._id!];
      });
    };

    return traverseTree(tree);
  },
);

export const getSectionSubTree = createSelector(
  (_: RootState, sectionIdOrUniqId: SectionId | string | null) =>
    sectionIdOrUniqId,
  sectionWholeTreeSelector,
  (sectionIdOrUniqId, tree): SectionNode | null => {
    return findSectionInTree(sectionIdOrUniqId, tree);
  },
);

export const sectionPathSelector = createSelector(
  (_: RootState, sectionIdOrUniqId: SectionId | string | null) =>
    sectionIdOrUniqId,
  sectionWholeTreeSelector,
  (sectionIdOrUniqId, tree): SectionNode[] | null => {
    if (!sectionIdOrUniqId) {
      return null;
    }
    return findSectionPath(sectionIdOrUniqId, tree);
  },
);

export const ppoUserEntitiesSelector = (state: RootState) =>
  state.entities.ppoUsers;

export const ppoUserSelector = createSelector(
  ppoUserEntitiesSelector,
  (_: RootState, { appSpaceId }: { appSpaceId: string }) => appSpaceId,
  (_: RootState, { userId }: { userId: string }) => userId,
  (byId, appSpaceId, userId) => {
    const id = `${appSpaceId}-${userId}`;
    return byId[id] || null;
  },
);

export const scrollProgressSelector = (state: RootState) =>
  state.scrollProgress;

// you need to have limit and offset parameters set
export const cumulatedListResultsSelector = createSelector(
  (state: RootState, listName: string) => listDomain(listName)(state),
  (state: RootState, listName: string) => getListParameters(listName)(state),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    let limit = parameters.limit || 10;
    const keys: string[] = [];
    while (offset >= 0) {
      if (offset < limit) {
        limit = limit + offset;
        offset = 0;
      }
      keys.unshift(formatParameters({ ...parameters, limit, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    const results =  keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
    return results;
  },
);

export const codeListDataSelector = createSelector(
  (_: RootState, codeListID: string) => codeListID,
  entitiesSelector,
  (codeListID, entities) => entities.codeLists[codeListID],
);

export const isLoadingList = createSelector(
  (state: RootState, listName: string) =>
    isCommiting(listName)(state) !== false,
  (state: RootState, listName: string) => getListResults(listName)(state),
  (committing, results) => committing && !results,
);

export const getListError = createSelector(
  (state: RootState, listName: string) =>
    isCommiting(listName)(state) !== false,
  (state: RootState, listName: string) =>
    getListByParametersDomain(listName)(state),
  (committing, list) => (committing ? null : list?.error),
);

export const staticContentEntitiesSelector = (state: RootState) =>
  state.entities.staticContents;

export const staticContentSelector = createSelector(
  staticContentEntitiesSelector,
  (_: RootState, { appSpace, cid }: { appSpace: string; cid: string }) =>
    `${appSpace}:${cid}`,
  (byId, id) => {
    return id in byId ? byId[id] : null;
  },
);

export const sportnetMenusDomain = (state: RootState) => {
  return state.sportnetMenus;
};

export const sportnetMenusSelector = createSelector(
  sportnetMenusDomain,
  (domain) => domain.data ?? null
)

export const sportnetMainMenuSelector = createSelector(
  sportnetMenusSelector,
  (data) => data ? data['sportnet.sme.sk'] : []
)

export const sportnetSideMenuSelector = createSelector(
  sportnetMenusSelector,
  (data) => data ? data['sidemenu'] : []
)

export const sportnetTopbarMenuSelector = createSelector(
  sportnetMenusSelector,
  (data) => data ? data['topbarmenu'] : []
)

export const generalInformationsMenuSelector = createSelector(
  sportnetMenusSelector,
  (data) => data ? data['vseobecne-informacie'] : []
)

export const competitionPanelMenuSelector = createSelector(
  sportnetMenusSelector,
  (data) => data ? data['competition-panel'] as SectionNode : null 
)

export const getSportnetMainMenuSectionByUiniqId = createSelector(
  sportnetMainMenuSelector,
  (_: RootState, { sectionIdOrUniqId }: { sectionIdOrUniqId: string | SectionId | null }) => sectionIdOrUniqId,
  (sections, sectionIdOrUniqId) => {
    return findSectionInTree(sectionIdOrUniqId, sections)
  }
)

export const pagesSettingsSelector = createSelector(
  (_: RootState, appSpace: string) => appSpace,
  entitiesSelector,
  (appSpace, entities) => entities.pagesSettings[appSpace],
);