import {
  getMediaManagerUrl,
  IPicture,
} from '@sportnet/ui/lib/MediaManagerImage';

export default (
  picture: IPicture | null | undefined | string,
  width: number,
  height: number,
  script: 'media' | 'resize' = 'media',
  webp: boolean = false,
) => {
  let src = '';
  if (typeof picture === 'string') {
    src = picture;
  } else if (typeof picture === 'object') {
    src = getMediaManagerUrl(picture, width, height, script, webp);
  }
  return src;
};
