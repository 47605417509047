import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path id="Path_1829" data-name="Path 1829" d="M0,0H48V48H0Z" fill="none" />
    <path
      id="Path_1830"
      data-name="Path 1830"
      d="M27.18,14,22,19.18,16.82,14,14,16.82,19.18,22,14,27.18,16.82,30,22,24.82,27.18,30,30,27.18,24.82,22,30,16.82ZM22,2A20,20,0,1,0,42,22,19.982,19.982,0,0,0,22,2Zm0,36A16,16,0,1,1,38,22,16.021,16.021,0,0,1,22,38Z"
      transform="translate(2 2)"
      fill={color}
    />
  </svg>
);
