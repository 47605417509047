import { emphasizeColor } from '@sportnet/ui/lib/Themes/utilities';
import { darken, desaturate, lighten, rem, transparentize } from 'polished';
import config from '../config';
import { RecursivePartial } from '../library/App';
import getColor from '../utilities/getColor';

type Color = string;
type Size = number;
// type SizeString = string;
// type Shadow = string;
// type FontFamily = string;

export interface ITheme {
  primaryFont: {
    family: string;
    weight: {
      semiBold: number;
      bold: number;
      extraBold: number;
    };
  };
  input: {
    height: Size;
    fontSize: Size;
    backgroundColor: Color;
    horizontalPadding: Size;
    disabledTextColor: Color;
    disabledBackground: Color;
    disabledCursor: string;
    readOnlyCursor: string;
    borderRadius: Size;
    borderColor: Color;
    shadow: string;
  };
  tooltip: {
    textColor: Color;
    backgroundColor: Color;
    borderRadius: Size;
  };
  form: {
    labelColor: Color;
    placeholderColor: Color;
  };
  textColor: Color;
  color: {
    primary: Color;
    secondary: Color;
    // action
    danger: Color;
    warning: Color;
    success: Color;
    // background
    primaryBg: Color;
    secondaryBg: Color;
    pageBg: Color;
    lightBackground: Color;
    // box shadow
    shadowColor: Color;
    // text
    text: Color;
    fadedText: Color;
    separator: Color;
    placeholder: Color;
    darkBorder: Color;
    lightBorder: Color;
    disabledLabel: Color;
    black: Color;
    yellow: Color;
    [key: string]: Color;
  };
  grid: {
    gutterWidth: Size;
  };
  breadcrumbs: {
    textColor: Color;
    backgroundColor: Color;
  };
  title: {
    textColor: Color;
  };
  contentMetaInformation: {
    textColor: Color;
  };
  author: {
    textColor: Color;
  };
  articleList: {
    bgColor: Color;
  };
  app: {
    fontName: string;
    maxWidth: number;
    padding: number;
    bgColor: Color;
    secondaryBgColor: Color;
    textColor: Color;
    secondaryTextColor: Color;
    borderRadius: number;
    borderRadiusSmall: number;
  };
  loader: {
    color: Color;
  };
  paginator: {
    backgroundColor: Color;
    hoverBackgroundColor: Color;
    activeColor: Color;
    disabledColor: Color;
    borderColor: Color;
    textColor: Color;
  };
  searchInput: {
    textColor: Color;
    placeholderColor: Color;
    separatorColor: Color;
    resetButtonColor: Color;
  };
  responsiveMenuPrimary: Color;
  responsiveMenu: {
    backgroundColor: Color;
    backdropColor: Color;
    linkColor: Color;
    linkColorHighlighted: Color;
    linkHoverColor: Color;
    borderRadius: string;
    siteUrlColor: Color;
    siteUrlBorder: Color;
    closeButtonColor: Color;
  };
  matchPreview: {
    default: Color;
    live: Color;
    status: Color;
  };
  memberSearch: {
    memberTypeBg: Color;
  };
  memberDetail: {
    active: Color;
    inactive: Color;
  };
  collapsibleList: {
    headerBg: Color;
    oddRowBg: Color;
  };
  tables: {
    headerBg: Color;
    oddRowBg: Color;
    evenRowBg: Color;
  };
  shadow: {
    small: string;
    large: string;
  };
  border: {
    normalDark: string;
    normalLight: string;
    borderRadius: number;
    borderRadiusSmall: number;
  };
  branding: {
    nike: Color;
  }
}

export const getTheme = (override: RecursivePartial<ITheme> = {}): ITheme => {
  const primaryFont = {
    family: "open-sans, sans-serif",
    weight: {
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  };
  const color = {
    primary: '#5283e7',
    primaryColor: 'rgba(207, 37, 40, 1)',
    secondary: '#5283e7',
    secondaryFontColor: 'rgba(51, 51, 51, 0.56)',
    // action
    danger: '#CF2528',
    warning: '#E8AE2B',
    success: '#45C46F',
    // background
    primaryBg: '#FAFAFA',
    secondaryBg: '#5283e7',
    pageBg: '#fff',
    lightBackground: '#f9f9f9',
    // text
    text: '#555555',
    fadedText: '#aaa',
    separator: '#ddd',
    placeholder: '#ccc',
    backdrop: 'rgba(0, 0, 0, 0.25)',
    primaryFontColor: 'rgba(51, 51, 51, 1)',
    // links
    blue: 'rgba(29, 129, 196, 1)', // TODO: odstranit blue a nahradit za link
    link: 'rgba(29, 129, 196, 1)',
    sportnetRed: 'rgba(207, 37, 40, 1)',
    sportnetGray: '#dddddd',
    // borders
    darkBorder: 'rgba(204, 204, 204, 1)',
    lightBorder: 'rgba(204, 204, 204, 0.56)',


    // border box'
    shadowColor: 'rgba(0, 0, 0, 0.12)',
    disabledLabel: 'rgba(51, 51, 51, 0.24)',
    black: 'rgba(0, 0 , 0 , 1)',
    yellow:  'rgba(255, 208, 51, 1)',
    white: '#fff',
    facebook: '#1877F2',
    twitter: 'black',
  };

  // safely override colors
  // ensure all color strings are valid
  const overrideColors = override.color || {};
  Object.keys(overrideColors).forEach((key: keyof typeof color) => {
    if (key in color) {
      color[key] = getColor(overrideColors[key]!, color[key]); // ! because it cannot be possible undefined
    }
  });

  const app = {
    fontName: "open-sans, sans-serif",
    maxWidth: config.MAX_WIDTH, // in future if this setting will be configurable please delete config.CONTENT_MAX_WIDTH and config.WIDER_CONTENT_MAX_WIDTH
    padding: 16,
    bgColor: '#fff',
    secondaryBgColor: '#FAFAFA',
    textColor: '#333',
    secondaryTextColor: '#707070',
    borderRadius: 8,
    borderRadiusSmall: 4,
    ...override.app,
  };

  if (override.app) {
    app.bgColor = getColor(override.app.bgColor, app.bgColor);
    app.secondaryBgColor = getColor(
      override.app.secondaryBgColor,
      app.secondaryBgColor,
    );
    app.textColor = getColor(override.app.textColor, app.textColor);
    app.secondaryTextColor = getColor(
      override.app.secondaryTextColor,
      app.secondaryTextColor,
    );
  }

  const grid = {
    gutterWidth: 32,
  };

  const breadcrumbs = {
    textColor: '#aaa',
    backgroundColor: '#fff',
    ...override.breadcrumbs,
  };

  const title = {
    textColor: app.textColor,
    ...override.title,
  };

  const input = {
    height: 40,
    fontSize: 14,
    horizontalPadding: 6,
    backgroundColor: '#fff',
    disabledTextColor: color.fadedText,
    disabledBackground: '#F9F9F9',
    disabledCursor: 'not-allowed',
    readOnlyCursor: 'not-allowed',
    borderRadius: 4,
    borderColor: '#DDDDDD',
    shadow: '0 0 0 rgba(0, 0, 0, 0.08)',
    ...override.input,
  };

  const contentMetaInformation = {
    textColor: app.secondaryTextColor,
    ...override.contentMetaInformation,
  };

  const author = {
    textColor: app.secondaryTextColor,
    ...override.author,
  };

  const articleList = {
    bgColor: app.secondaryBgColor,
    ...override.articleList,
  };

  const loader = {
    color: color.primary,
    ...override.author,
  };

  const paginator = {
    backgroundColor: app.bgColor,
    hoverBackgroundColor: darken(0.03, app.bgColor),
    activeColor: color.primary,
    disabledColor: app.secondaryTextColor,
    borderColor: emphasizeColor(0.1, app.bgColor, true),
    textColor: app.textColor,
    ...override.paginator,
  };

  const responsiveMenuPrimary = override.responsiveMenuPrimary || color.primary;

  const responsiveMenu = {
    backgroundColor: responsiveMenuPrimary,
    backdropColor: 'rgba(0, 0, 0, 0.25)',
    subsectionBackgroundColor: darken(0.03, responsiveMenuPrimary),
    linkColor: lighten(0.9, responsiveMenuPrimary),
    linkColorHighlighted: desaturate(0.1, lighten(0.5, responsiveMenuPrimary)),
    linkHoverColor: darken(0.02, responsiveMenuPrimary),
    borderRadius: rem(4),
    siteUrlColor: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)),
    siteUrlBorder: desaturate(0.3, lighten(0.2, responsiveMenuPrimary)),
    closeButtonColor: '#fff',
    ...override.responsiveMenu,
  };

  const searchInput = {
    textColor: app.textColor,
    placeholderColor: transparentize(0.5, app.textColor),
    separatorColor: emphasizeColor(0.1, app.bgColor, true),
    resetButtonColor: app.textColor,
  };

  const matchPreview = {
    default: app.textColor,
    live: color.danger,
    status: color.fadedText,
  };

  const collapsibleList = {
    headerBg: '#f1f1f1',
    oddRowBg: '#f9f9f9',
  };

  const memberDetail = {
    active: '#45c46f',
    inactive: '#f00',
  };

  const tables = {
    headerBg: '#f1f1f1',
    oddRowBg: '#f9f9f9',
    evenRowBg: '#fff',
  };

  const shadow = {
    large: `0px 2px 8px ${color.shadowColor}`,
    small: `0px 2px 4px ${color.shadowColor}`,
  };

  const border = {
    borderRadius: 8,
    borderRadiusSmall: 4,
    normalDark: `1px solid ${color.darkBorder}`,
    normalLight: `1px solid ${color.lightBorder}`,
  };

  const branding = {
    nike: '#f57e20',
  }

  return {
    primaryFont,
    textColor: '#333',
    color,
    grid,
    breadcrumbs,
    contentMetaInformation,
    author,
    articleList,
    app,
    title,
    loader,
    paginator,
    searchInput,
    responsiveMenuPrimary,
    responsiveMenu,
    input,
    tooltip: {
      textColor: '#fff',
      backgroundColor: '#333',
      borderRadius: 4,
    },
    form: {
      labelColor: '#555',
      placeholderColor: '#aaa',
    },
    matchPreview,
    memberSearch: {
      memberTypeBg: '#f1f1f1',
    },
    collapsibleList,
    memberDetail,
    tables,
    shadow,
    border,
    branding
  };
};

export const theme = getTheme();
