export const SPORT_SECTOR_TELEVISION_BROADCASTERS = [
  {
    _id: "futbal",
    items: [
      {
        _id: "nova_sport",
        label: "Nova Sport",
        logo_url:
          "https://mediamanager.sportnet.online/media/pages/s/sportnet.sme.sk/2021/08/nova_sport.jpg",
      },
      {
        _id: "dajto",
        label: "Dajto",
        logo_url:
          "https://mediamanager.sportnet.online/media/pages/s/sportnet.sme.sk/2021/08/dajto_logo.jpeg",
      },
    ],
  },
  { _id: "beachfutbal", items: [] },
  {
    _id: "ice-hockey",
    items: [
      {
        _id: "joj-sport",
        label: "Joj Šport",
        logo_url: "https://tvprogram.sportnet.sk/assets/joj-sport.png",
      },
      {
        _id: "joj",
        label: "Joj",
        logo_url: "https://tvprogram.sportnet.sk/assets/joj.png",
      },
      {
        _id: "joj-plus",
        label: "Joj Plus",
        logo_url: "https://tvprogram.sportnet.sk/assets/joj-plus.png",
      },
    ],
  },
];
