import { mb } from '@sportnet/ui/lib/Themes/utilities';
import React from 'react';
import styled from '../../../theme/styled-components';
import Ad from '../Ad';

const StyledAd = styled(Ad as any)`
  background-color: #fff;
  bottom: 0;
  z-index: 10;
  ${mb('s')} {
    display: none;
  }
`;

type IOwnProps = {};

type IProps = IOwnProps;

const FixedBottomRespAd: React.FC<IProps> = () => {
  return <StyledAd name="fixed_bottom_resp" style={{ position: 'fixed' }} />;
};

export default React.memo(FixedBottomRespAd);
