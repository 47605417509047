import React from 'react';
import LayoutContext from '../../contexts/LayoutContext';
import Sidebar from '../../components/v2/Sidebar';
import Tabber, { ITab } from '../../components/FutbalnetTabber';
import { TransfersFilter, TransferType } from '../../library/Transfermarket';
import SuggestionSearch from '../../components/v2/SuggestionSearch';
import { IItem } from '../../components/DropDowns/ItemsDropDown';
import CoreApi from '../../api/CoreApi';
import config from '../../config';
import __ from '../../utilities/__';
import urlConverter from '../../urlConverter';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label } from '../../components/v2/Input';
import Button from '../../sportnet.sme.sk-ui/PrimaryButton';
import ButtonAsLink from '../../components/v2/ButtonAsLink';
import { FILTER_MIN_DATE, getFilterInitialState } from './utilities';
import TiedCheckboxGroup, {
  getTiedCheckboxValuesMap,
  TiedCheckbox,
} from './TiedCheckboxGroup';
import DatePickerResponsive from '../../components/v2/DatePicker/DatePickerResponsive';

const StyledTabber = styled(Tabber)`
  margin-bottom: ${rem(24 - 8)};
`;

const StyledLabel = styled(Label)`
  margin: 0;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem(24)};
  > *:not(:last-child) {
    margin-bottom: ${rem(16)};
  }
`;

const FormGroupHorizontal = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: ${({ gap = 24 }) => rem(gap)};
  }
`;

const FormGroupHorizontalFullWidth = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  > *:not(:last-child) {
    margin-right: ${({ gap = 24 }) => rem(gap)};
   
  }
  > * {
    flex: 0 1 50%;
  }
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;

const SidebarFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface OwnProps {
  opened: boolean;
  activeFilter: TransfersFilter;
  onClose: () => void;
  onFilterChange: (newFilter: TransfersFilter) => void;
}

interface FormFields {
  ppo: string;
  ppoName: string;
  clubOptions: IItem[];
  transferType: TransferType;
  dateFrom?: Date;
  dateTo?: Date;
  male: boolean;
  female: boolean;
  ppoDir: TiedCheckbox[];
  age: TiedCheckbox[];
  gender: TiedCheckbox[];
}

const SidebarFilter: React.FC<OwnProps> = ({
  opened,
  activeFilter,
  onClose,
  onFilterChange,
}) => {
  const { sidebarRef } = React.useContext(LayoutContext);

  const [form, setForm] = React.useState<FormFields>({
    ppo: '',
    ppoName: '',
    clubOptions: [],
    transferType: 'all',
    male: true,
    female: true,
    ppoDir: [
      {
        label: 'Do klubu',
        name: 'ppoDirIn',
        value: true,
        disabled: true,
      },

      {
        label: 'Z klubu',
        name: 'ppoDirOut',
        value: true,
        disabled: true,
      },
    ],
    age: [
      {
        label: 'Dospelí (nad 18 rokov)',
        name: 'ageOver18',
        value: true,
      },
      {
        label: 'Mládež (do 18 rokov)',
        name: 'ageUnder18',
        value: true,
      },
    ],
    gender: [
      {
        label: 'Muži',
        name: 'male',
        value: true,
      },
      {
        label: 'Ženy',
        name: 'female',
        value: true,
      },
    ],
  });

  const [isFetchingClubs, setIsFetchingClubs] = React.useState(false);

  const TRANSFER_TYPES_TABS: ITab<TransferType>[] = [
    {
      label: 'Všetko',
      value: 'all',
    },
    {
      label: 'Prestupy',
      value: 'transfer',
    },
    {
      label: 'Hosťovania',
      value: 'hosting',
    },
  ];

  React.useEffect(() => {
    const {
      ppo = '',
      ppoDir,
      ppoName = '',
      transferType = 'all',
      age = '',
      dateFrom,
      dateTo,
      gender,
    } = activeFilter;
    setForm({
      ppo,
      ppoName,
      clubOptions: [],
      transferType,
      dateFrom,
      dateTo,
      male: gender === 'M' || !gender,
      female: gender === 'F' || !gender,
      ppoDir: [
        {
          label: 'Do klubu',
          name: 'ppoDirIn',
          value: ppoDir === 'in' || ppoDir === 'both' || !ppoDir,
          disabled: !ppo,
        },
        {
          label: 'Z klubu',
          name: 'ppoDirOut',
          value: ppoDir === 'out' || ppoDir === 'both' || !ppoDir,
          disabled: !ppo,
        },
      ],
      age: [
        {
          label: 'Dospelí (nad 18 rokov)',
          name: 'ageOver18',
          value: age === '18+' || !age,
        },
        {
          label: 'Mládež (do 18 rokov)',
          name: 'ageUnder18',
          value: age === '18-' || !age,
        },
      ],
      gender: [
        {
          label: 'Muži',
          name: 'male',
          value: gender === 'M' || !gender,
        },
        {
          label: 'Ženy',
          name: 'female',
          value: gender === 'F' || !gender,
        },
      ],
    });
  }, [activeFilter]);

  const getClubId = React.useCallback(({ klub }: { klub: string | number }) => {
    let ppo = klub;
    const convertedUnionData = urlConverter.find(
      (i) => i._id === klub || i.__issfId === Number(klub),
    );
    if (convertedUnionData) {
      ppo = convertedUnionData._id;
    }
    return ppo;
  }, []);

  const handleClubSearchChange = (searchValue: string) => {
    if (!searchValue) {
      setForm((prev) => ({
        ...prev,
        ppo: '',
        ppoName: '',
        clubOptions: [],
        ppoDir: [
          {
            label: 'Do klubu',
            name: 'ppoDirIn',
            value: true,
            disabled: true,
          },
          {
            label: 'Z klubu',
            name: 'ppoDirOut',
            value: true,
            disabled: true,
          },
        ],
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        ppo: '',
        ppoName: searchValue,
        ppoDir: [
          {
            label: 'Do klubu',
            name: 'ppoDirIn',
            value: prev.ppoDir[0].value,
            disabled: false,
          },
          {
            label: 'Z klubu',
            name: 'ppoDirOut',
            value: prev.ppoDir[1].value,
            disabled: false,
          },
        ],
      }));
    }
  };

  const handleClubSearchDebouncedChange = async (searchValue: string) => {
    setForm((prev) => ({
      ...prev,
      ppo: '',
      ppoName: searchValue,
      clubOptions: [],
    }));
    if (searchValue.length > 3) {
      setIsFetchingClubs(true);
      try {
        const res = await CoreApi.organizationPPOListRelatedPPOs(
          config.OWNER_PPO,
          { q: searchValue, ppoType: ['club'] },
        );
        const clubSuggestions = (res.items || []).map((club) => {
          return {
            id: `${getClubId({ klub: club._id || '' })}`,
            label: club.name!,
            onClick: (item: IItem) => {
              setForm((prev) => ({
                ...prev,
                ppo: item.id,
                ppoName: item.label,
                clubOptions: [],
              }));
            },
          };
        });
        setForm((prev) => ({
          ...prev,
          ppo: '',
          ppoName: searchValue,
          clubOptions: clubSuggestions,
        }));
      } catch (e: any) {
        alert(__('Nepodarilo sa získať zoznam klubov'));
      } finally {
        setIsFetchingClubs(false);
      }
    }
  };

  const handleTransferTypeChange = (opt: ITab<TransferType>) => {
    setForm((prev) => ({
      ...prev,
      transferType: opt.value,
    }));
  };

  const handleChangeDateFrom = (date: Date | null) => {
    setForm((prev) => ({
      ...prev,
      dateFrom: date || undefined,
    }));
  };

  const handleChangeDateTo = (date: Date | null) => {
    setForm((prev) => ({
      ...prev,
      dateTo: date || undefined,
    }));
  };

  const handleShowResultsClick = () => {
    const newFilter: TransfersFilter = {
      transferType: form.transferType,
      ppo: form.ppo,
      ppoName: form.ppoName,
      dateFrom: form.dateFrom,
      dateTo: form.dateTo,
    };
    const ppoDirMap = getTiedCheckboxValuesMap(form.ppoDir);
    // PPO direction logic
    if (ppoDirMap.ppoDirIn && ppoDirMap.ppoDirOut) {
      newFilter.ppoDir = 'both';
    } else if (ppoDirMap.ppoDirIn) {
      newFilter.ppoDir = 'in';
    } else if (ppoDirMap.ppoDirOut) {
      newFilter.ppoDir = 'out';
    } else {
      newFilter.ppoDir = 'both';
    }
    // age logic
    const ageMap = getTiedCheckboxValuesMap(form.age);
    if (ageMap.ageOver18 && ageMap.ageUnder18) {
      newFilter.age = undefined;
    } else if (ageMap.ageOver18) {
      newFilter.age = '18+';
    } else if (ageMap.ageUnder18) {
      newFilter.age = '18-';
    } else {
      newFilter.age = undefined;
    }
    // gender logic
    const genderMap = getTiedCheckboxValuesMap(form.gender);
    if (genderMap.male && genderMap.female) {
      newFilter.gender = undefined;
    } else if (genderMap.male) {
      newFilter.gender = 'M';
    } else if (genderMap.female) {
      newFilter.gender = 'F';
    } else {
      newFilter.gender = undefined;
    }
    onFilterChange(newFilter);
  };

  const handleResetFilterClick = () => {
    onFilterChange(getFilterInitialState());
  };

  const handleTiedCheckboxesChange = (
    groupId: string,
    changedCheckboxes: TiedCheckbox[],
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [groupId]: changedCheckboxes,
      };
    });
  };

  return sidebarRef ? (
    <Sidebar
      opened={opened}
      onClose={onClose}
      sidebarPortalRef={sidebarRef}
      header="Filter"
      footer={
        <SidebarFooterWrapper>
          <ButtonAsLink onClick={handleResetFilterClick}>
            Resetovať filter
          </ButtonAsLink>
          <StyledButton onClick={handleShowResultsClick}>
            Zobraziť
          </StyledButton>
        </SidebarFooterWrapper>
      }
    >
      <StyledTabber
        centered
        tabs={TRANSFER_TYPES_TABS}
        active={form.transferType || 'all'}
        onChange={handleTransferTypeChange}
        size="responsive"
      />
      <FormGroup>
        <SuggestionSearch
          label={'Klub'}
          isFetching={isFetchingClubs}
          value={form.ppoName}
          suggestions={form.clubOptions}
          onChange={handleClubSearchChange}
          onDebouncedChange={handleClubSearchDebouncedChange}
          placeholder="Vyhľadať klub"
        />
        <FormGroupHorizontal>
          <TiedCheckboxGroup
            groupId="ppoDir"
            checkboxes={form.ppoDir}
            onChange={handleTiedCheckboxesChange}
          />
        </FormGroupHorizontal>
      </FormGroup>
      <FormGroup>
        <StyledLabel>Vek</StyledLabel>
        <TiedCheckboxGroup
          groupId="age"
          checkboxes={form.age}
          onChange={handleTiedCheckboxesChange}
        />
      </FormGroup>
      <FormGroup>
        <StyledLabel>Pohlavie</StyledLabel>
        <TiedCheckboxGroup
          groupId="gender"
          checkboxes={form.gender}
          onChange={handleTiedCheckboxesChange}
        />
      </FormGroup>
      <FormGroupHorizontalFullWidth gap={16}>
        <DatePickerResponsive
          label="Dátum od"
          value={form.dateFrom || null}
          onChange={handleChangeDateFrom}
          minDate={FILTER_MIN_DATE}
          placeholder="Vybrať"
        />
        <DatePickerResponsive
          label="Dátum do"
          value={form.dateTo || null}
          onChange={handleChangeDateTo}
          minDate={form.dateFrom || FILTER_MIN_DATE}
          placeholder="Vybrať"
        />
      </FormGroupHorizontalFullWidth>
    </Sidebar>
  ) : null;
};

export default React.memo(SidebarFilter);
