import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M13.3,5.4h-0.7v-4c0-0.3-0.3-0.6-0.6-0.6H4C3.7,0.7,3.4,1,3.4,1.3v4H2.7c-1.1,0-2,0.9-2,2v3.3c0,1.1,0.9,2,2,2h0.7v2
	c0,0.3,0.3,0.6,0.6,0.6h8c0.3,0,0.6-0.3,0.6-0.6v-2h0.7c1.1,0,2-0.9,2-2V7.3C15.3,6.3,14.4,5.4,13.3,5.4z M4.6,2h6.8v3.4H4.6V2z
	 M11.4,14H4.6V10h6.8V14z M14,10.7c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.2-0.5,0.2h-0.7v-2c0-0.3-0.3-0.6-0.6-0.6H4C3.7,8.7,3.4,9,3.4,9.3
	v2H2.7c-0.4,0-0.7-0.3-0.7-0.7V7.3c0-0.4,0.3-0.7,0.7-0.7h10.7c0.4,0,0.7,0.3,0.7,0.7V10.7z"
    />
  </svg>
);
