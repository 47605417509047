import React from 'react';
import { ISocialIconVariant } from '.';

export default {
  facebook: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#1877f2';
    let color2 = '#fff';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 46 46.024"
      >
        <g transform="translate(-866.6 -3.7)">
          <path
            d="M912.6 26.7a23 23 0 1 0-26.577 22.716V33.332h-5.874V26.7h5.874v-5.069c0-5.756 3.411-8.954 8.669-8.954a33.01 33.01 0 0 1 5.116.474v5.614h-2.89c-2.866 0-3.766 1.8-3.766 3.6v4.311h6.4l-1.019 6.632h-5.377v16.084A22.933 22.933 0 0 0 912.6 26.7z"
            fill={color1}
          />
          <path
            d="M942.281 62.355l1.019-6.632h-6.4v-4.311c0-1.824.924-3.6 3.766-3.6h2.89v-5.638a33.01 33.01 0 0 0-5.116-.474c-5.258 0-8.669 3.2-8.669 8.954v5.069H923.9v6.632h5.874v16.083a22.646 22.646 0 0 0 7.153 0V62.355z"
            fill={color2}
            transform="translate(-43.727 -28.999)"
          />
        </g>
      </svg>
    );
  },
  pinterest: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#e60019';
    let color2 = '#fff';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 46 46.038"
      >
        <g>
          <circle cx="23" cy="23" r="23" fill={color2} />
          <g>
            <path
              d="M23 0a23.007 23.007 0 0 0-8.386 44.429 22.143 22.143 0 0 1 .076-6.607c.416-1.8 2.688-11.434 2.688-11.434a8.378 8.378 0 0 1-.681-3.407c0-3.2 1.855-5.584 4.165-5.584 1.969 0 2.915 1.477 2.915 3.237 0 1.969-1.249 4.922-1.912 7.667a3.346 3.346 0 0 0 3.407 4.165c4.089 0 7.231-4.316 7.231-10.525 0-5.509-3.956-9.351-9.616-9.351a9.954 9.954 0 0 0-10.393 9.976 8.978 8.978 0 0 0 1.7 5.244.685.685 0 0 1 .151.663c-.17.719-.568 2.291-.644 2.612-.095.416-.341.511-.776.3-2.877-1.344-4.676-5.528-4.676-8.916 0-7.25 5.263-13.914 15.2-13.914 7.97 0 14.179 5.679 14.179 13.289 0 7.932-5 14.311-11.926 14.311-2.328 0-4.524-1.212-5.263-2.65L19 38.976a25.058 25.058 0 0 1-2.858 6.039A23.021 23.021 0 1 0 23 0z"
              fill={color1}
            />
          </g>
        </g>
      </svg>
    );
  },
  instagram: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = 'url(#linear-gradient)';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        viewBox="0 0 46.076 46.076"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1=".073"
            x2=".12"
            y1=".905"
            y2=".077"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#e09b3d" />
            <stop offset=".3" stopColor="#c74c4d" />
            <stop offset=".648" stopColor="#c21975" />
            <stop offset="1" stopColor="#7024c4" />
          </linearGradient>
        </defs>
        <path
          d="M32.349 0H13.726A13.742 13.742 0 0 0 0 13.726v18.623a13.742 13.742 0 0 0 13.726 13.727h18.623a13.742 13.742 0 0 0 13.727-13.727V13.726A13.742 13.742 0 0 0 32.349 0zm9.091 32.349a9.091 9.091 0 0 1-9.091 9.091H13.726a9.091 9.091 0 0 1-9.091-9.091V13.726a9.091 9.091 0 0 1 9.091-9.091h18.623a9.091 9.091 0 0 1 9.091 9.091v18.623z"
          fill={color1}
        />
        <path
          d="M144.917 133a11.917 11.917 0 1 0 11.917 11.917A11.93 11.93 0 0 0 144.917 133zm0 19.2a7.282 7.282 0 1 1 7.282-7.282 7.282 7.282 0 0 1-7.282 7.282z"
          fill={color1}
          transform="translate(-121.879 -121.879)"
        />
        <circle
          cx="2.856"
          cy="2.856"
          r="2.856"
          fill={color1}
          transform="translate(32.122 8.355)"
        />
      </svg>
    );
  },
  youtube: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#ea3223';
    let color2 = '#fff';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        width={size}
        height={size}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,256)">
          <path
            transform="matrix(0.75000002,0,0,-0.75000002,0,192)"
            d="M 128 45.333984 C 128 45.333984 54.618359 45.333531 36.318359 50.269531 C 26.221026 52.985531 18.268979 60.987724 15.570312 71.150391 C 10.667646 89.569057 10.667969 128 10.667969 128 C 10.667969 128 10.667646 166.42828 15.570312 184.84961 C 18.268979 195.01094 26.221026 203.01509 36.318359 205.73242 C 54.618359 210.66709 128 210.66602 128 210.66602 C 128 210.66602 201.38297 210.66709 219.68164 205.73242 C 229.78031 203.01509 237.73102 195.01094 240.42969 184.84961 C 245.33369 166.42828 245.33398 128 245.33398 128 C 245.33398 128 245.33369 89.569057 240.42969 71.150391 C 237.73102 60.987724 229.78031 52.985531 219.68164 50.269531 C 201.38297 45.333531 128 45.333984 128 45.333984 z M 104 93.107422 L 165.33398 128.00195 L 104 162.89258 L 104 93.107422 z "
            fill={color1}
          />
          <path d="m 78,69.8311 46,26.168 -46,26.17 z" fill={color2} />
        </g>
      </svg>
    );
  },
  tumblr: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#001935';
    let color2 = '#fff';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 144.80001 144.8"
      >
        <g transform="translate(-244,-7.3)">
          <path
            fill={color1}
            transform="translate(244,7.3)"
            d="M 28.900391 0 C 13.000391 0 0 13.000391 0 28.900391 L 0 115.90039 C 0 131.80039 13.000391 144.80078 28.900391 144.80078 L 115.90039 144.80078 C 131.80039 144.80078 144.80078 131.80039 144.80078 115.90039 L 144.80078 28.900391 C 144.80078 13.000391 131.80039 -1.1842379e-14 115.90039 0 L 28.900391 0 z M 65.699219 26.099609 L 80 26.099609 L 80 49.400391 L 96.599609 49.400391 L 96.599609 66.400391 L 80 66.400391 L 80 90 C 80 97.1 83.599219 99.5 89.199219 99.5 L 97.300781 99.5 L 97.300781 117.5 L 82 117.5 C 68.3 117.5 58 110.4 58 93.5 L 58 66.400391 L 45.5 66.400391 L 45.5 51.699219 C 59.2 48.099219 64.999219 36.299609 65.699219 26.099609 z "
          />
          <path
            fill={color2}
            d="M 341.3,124.8 H 326 c -13.7,0 -24,-7.1 -24,-24 V 73.7 H 289.5 V 59 C 303.2,55.4 309,43.6 309.7,33.4 H 324 v 23.3 h 16.6 v 17 H 324 V 97.300001 C 324,104.4 327.6,106.8 333.2,106.8 h 8.1 z"
          />
        </g>
      </svg>
    );
  },
  mail: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#5283e7';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 12.170834 12.170834"
        width={size}
        height={size}
      >
        <g transform="translate(-31.750165,-100.12606)">
          <path
            fill={color1}
            transform="matrix(0.26458333,0,0,0.26458333,31.749648,38.741174)"
            d="m 5.7519531,232.00586 a 5.7519999,5.7519999 0 0 0 -5.74999997,5.75 v 34.5 a 5.7519999,5.7519999 0 0 0 5.74999997,5.75 H 40.251953 a 5.7519999,5.7519999 0 0 0 5.75,-5.75 v -34.5 a 5.7519999,5.7519999 0 0 0 -5.75,-5.75 z m 5.8222659,11.57226 h 22.855469 a 2.8651878,2.8651878 0 0 1 2.855468,2.85547 v 17.14258 a 2.8651878,2.8651878 0 0 1 -2.855468,2.85547 H 11.574219 a 2.8651878,2.8651878 0 0 1 -2.8574221,-2.85547 l 0.013672,-17.14258 a 2.8531889,2.8531889 0 0 1 2.8437501,-2.85547 z m 0,2.85547 v 2.85938 l 11.427734,7.14062 11.427735,-7.14062 v -2.85938 L 23,253.57617 Z"
            style={{ opacity: 1, strokeWidth: 0.999999, stopOpacity: 1 }}
          />
        </g>
      </svg>
    );
  },
  livestream: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#fff';
    let color2 = '#1ab7ea';
    if (variant === 'dark') {
      color1 = '#333';
      color2 = 'transparent';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 45.808 45.808"
      >
        <path
          d="M45.808 41.227a4.58 4.58 0 0 1-4.581 4.581H4.581A4.58 4.58 0 0 1 0 41.227V4.581A4.58 4.58 0 0 1 4.581 0h36.646a4.58 4.58 0 0 1 4.581 4.581z"
          fill={color2}
        />
        <path
          d="M47.893 28.619q-.218 4.833-6.707 13.235-6.711 8.772-11.357 8.772-2.874 0-4.863-5.344l-2.657-9.8q-1.477-5.342-3.168-5.344a10.048 10.048 0 0 0-2.585 1.562l-1.546-2q2.434-2.153 4.8-4.31 3.252-2.823 4.875-2.974 3.842-.372 4.73 5.283c.641 4.069 1.08 6.6 1.332 7.589q1.105 5.062 2.435 5.058 1.031 0 3.1-3.283a12.962 12.962 0 0 0 2.214-5q.3-2.834-2.214-2.836a6.153 6.153 0 0 0-2.435.542q2.424-7.994 9.266-7.769 5.074.149 4.78 6.619z"
          fill={color1}
          transform="translate(-9.28 -13.598)"
        />
      </svg>
    );
  },
  twitter: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#1da1f2';
    if (variant === 'dark') {
      color1 = '#1da1f2';
    } else if (variant === 'light') {
      color1 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size * 1.6}
        height={size * 1.6}
        viewBox="0 0 46 46"
      >
        <g>
          <circle cx="23" cy="23" r="23" fill={color1} />
        </g>
        <g transform="translate(10.315 13.168)">
          <path
            d="M98.175 136.465a15.672 15.672 0 0 0 15.778-15.778c0-.242 0-.483-.012-.713a11.361 11.361 0 0 0 2.771-2.875 11.255 11.255 0 0 1-3.186.874 5.542 5.542 0 0 0 2.438-3.071 11.23 11.23 0 0 1-3.519 1.346 5.548 5.548 0 0 0-9.591 3.795 5.081 5.081 0 0 0 .15 1.265 15.731 15.731 0 0 1-11.431-5.8 5.543 5.543 0 0 0 1.725 7.394 5.447 5.447 0 0 1-2.507-.69v.069a5.551 5.551 0 0 0 4.451 5.439 5.528 5.528 0 0 1-1.461.2 5.294 5.294 0 0 1-1.046-.1 5.537 5.537 0 0 0 5.175 3.852 11.147 11.147 0 0 1-6.888 2.369 10.082 10.082 0 0 1-1.323-.081 15.45 15.45 0 0 0 8.476 2.507"
            fill="#fff"
            transform="translate(-89.7 -114.5)"
          />
        </g>
      </svg>
    );
  },
  linkedin: (size: number, variant: ISocialIconVariant = 'color') => {
    let color1 = '#069';
    let color2 = '#fff';
    if (variant === 'dark') {
      color1 = '#333';
    } else if (variant === 'light') {
      color1 = '#fff';
      color2 = 'transparent';
    }
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 45.71 46"
        width={size}
        height={size}
      >
        <g transform="translate(-414.436 -395.745)">
          <g transform="translate(414.436 395.745)">
            <path
              transform="matrix(0.46,0,0,0.46,-0.145,0)"
              d="M 7.5800781 -0.01171875 A 7.2565217 7.2565217 0 0 0 0.31445312 7.1621094 L 0.31445312 92.837891 A 7.2543478 7.2543478 0 0 0 7.6582031 100.01172 L 92.341797 100.01172 A 7.2543478 7.2543478 0 0 0 99.685547 92.837891 L 99.685547 7.1621094 A 7.2565217 7.2565217 0 0 0 92.341797 -0.01171875 L 7.6582031 -0.01171875 A 7.2565217 7.2565217 0 0 0 7.5800781 -0.01171875 z M 22.330078 16.755859 A 7.826087 7.826087 0 0 1 23.035156 16.785156 A 7.8 7.8 0 0 1 31.425781 24.587891 A 7.826087 7.826087 0 0 1 22.943359 32.396484 L 22.935547 32.386719 L 22.837891 32.386719 A 7.7695652 7.7695652 0 0 1 14.542969 24.587891 A 7.826087 7.826087 0 0 1 22.330078 16.755859 z M 66.615234 37.488281 A 14.95 14.95 0 0 1 67.265625 37.5 C 77.139538 37.5 84.542969 43.954654 84.542969 57.824219 L 84.542969 83.714844 L 69.542969 83.714844 L 69.542969 59.558594 C 69.542969 53.471637 67.370329 49.341797 61.935547 49.341797 A 8.2152174 8.2152174 0 0 0 54.234375 54.830078 A 10.286957 10.286957 0 0 0 53.742188 58.494141 L 53.742188 83.710938 L 38.742188 83.710938 L 38.738281 83.716797 C 38.738281 83.716797 38.936107 42.797503 38.738281 38.560547 L 53.738281 38.560547 L 53.738281 44.933594 A 14.95 14.95 0 0 1 66.615234 37.488281 z M 15.447266 38.554688 L 30.447266 38.554688 L 30.447266 83.716797 L 15.447266 83.716797 L 15.447266 38.554688 z "
              fill={color1}
              style={{ strokeWidth: 2.17391 }}
            />
            <path
              style={{ strokeWidth: 0.999999 }}
              d="m 13.860849,38.509788 v -20.775 H 6.9608486 v 20.775 z m -3.452,-23.608 a 3.5999999,3.5999999 0 0 0 3.902,-3.591999 3.5879999,3.5879999 0 0 0 -3.86,-3.5890003 3.5999999,3.5999999 0 0 0 -3.9060004,3.5890003 3.5739999,3.5739999 0 0 0 3.8150004,3.588 h 0.045 z"
              fill={color2}
            />
            <path
              style={{ strokeWidth: 0.999999 }}
              d="m 17.675848,38.506789 h 6.9 v -11.6 a 4.7319998,4.7319998 0 0 1 0.227,-1.685 3.7789999,3.7789999 0 0 1 3.542,-2.525 c 2.5,0 3.5,1.9 3.5,4.7 v 11.112 h 6.9 v -11.91 c 0,-6.38 -3.406,-9.349 -7.948,-9.349 a 6.877,6.877 0 0 0 -6.268,3.5 h 0.046 v -3.012 h -6.9 c 0.091,1.949 0,20.771999 0,20.771999 z"
              fill={color2}
            />
          </g>
        </g>
      </svg>
    );
  },
};
