import GossipLoader from './GossipLoader';
import { GossipsListContentContainer } from './GossipsList';
import React from 'react';

const GossipsListLoader = () => {
  return (
    <GossipsListContentContainer>
      <GossipLoader skeletonClassName="skeleton" />
      <GossipLoader skeletonClassName="skeleton" />
      <GossipLoader skeletonClassName="skeleton" />
      <GossipLoader skeletonClassName="skeleton" />
    </GossipsListContentContainer>
  );
};

export default GossipsListLoader;
