import React from 'react';
import { Articles_List } from '../../../api/CmsApi';
import Article from '../../../components/v2/InfiniteArticleList/Article';
import ListBodyAd from '../../../components/Ads/ListBodyAd';
import styled from 'styled-components';
import { rem } from 'polished';
import AdReplica from '../../../components/Ads/AdReplica';
import InfiniteArticleListLoader from '../../../components/v2/InfiniteArticleList/ListLoader';
import LoadMoreButton from '../../../components/v2/LoadMoreButton';
import NoResults from '../../../components/v2/NoResults';
import __ from '../../../utilities/__';

const FIRST_AD_INDEX = 3;
const ADS_STEP = 10;

const Wrapper = styled.div`
  max-width: ${rem(680)};
  > div {
    margin-bottom: ${rem(24)};
  }
`;

const shouldPlaceAd = (index: number) =>
  index > FIRST_AD_INDEX && (index - FIRST_AD_INDEX) % ADS_STEP === 0;

interface OwnProps {
  className?: string;
  articles: Articles_List['articles'];
  isLoading: boolean;
  isError: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
  initialLimit: number;
  onLoadMore: () => void;
}

const ArticleList: React.FC<OwnProps> = ({
  className,
  isLoading,
  isError,
  isFetchingNextPage,
  hasNextPage,
  articles,
  onLoadMore,
}) => {
  return (
    <div className={className}>
      {articles.length > 0 ? (
        <>
          <Wrapper>
            {articles.map((article, idx) => (
              <React.Fragment key={`article-list-${article._id!}`}>
                <Article
                  key={`article-list-${article._id!}`}
                  article={article}
                />
                {idx === FIRST_AD_INDEX && <ListBodyAd name="list_body_2" />}
                {shouldPlaceAd(idx) && (
                  <AdReplica
                    key={`duplicate-ad-${idx}`}
                    elementId={`duplicate-ad-${idx}`}
                    name="list_body_2"
                  />
                )}
              </React.Fragment>
            ))}
          </Wrapper>
          {isFetchingNextPage && <InfiniteArticleListLoader />}
        </>
      ) : isLoading ? (
        <InfiniteArticleListLoader />
      ) : isError ? (
        <NoResults text={__('Chyba pri načítaní článkov!')} />
      ) : (
        <NoResults text={__('Nenašli sa žiadne články')} />
      )}
      {hasNextPage && <LoadMoreButton onClick={onLoadMore} />}
    </div>
  );
};

export default React.memo(ArticleList);
