import { serializeQuery } from '@sportnet/query-hoc/useQuery';
import { IBaseObjectName } from '../../library/CompetitionStanding';
import removeUndefinedQueryParams from '../urls/removeUndefinedQueryParams';

const getClubStandingPrintUrl = (
  clubAppSpace: string,
  competitionAppSpace: string,
  competitionId: string | number,
  competitionPartId: string,
  tableView: IBaseObjectName,
  team: string | number,
) => {
  const query = serializeQuery(
    removeUndefinedQueryParams({
      tableView: tableView !== 'stats' ? tableView : undefined,
   
    }),
  );
  return `/futbalnet/k/${clubAppSpace}/tim/${team}/z/${competitionAppSpace}/s/${competitionId}/part/${competitionPartId}/tabulky/print/${
    query === '?' ? '' : query
  }`;
};

export default getClubStandingPrintUrl;
