/**
 * Komponent renderuje info zvolenej organizacie ako widget na stranke.
 * Pouziva sa napr. na stranke klubu, alebo na zvaze
 */
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import {
  BankAccount,
  Organization,
  OrganizationProfile,
} from '../../api/CoreApi';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import Icon from '../Icon';
import Segment from '../Segment';
import Spacer from '../Spacer';
import OrgProfileSocialNetworks from './OrgProfileSocialNetworks';

const SegmentTitle = styled.div`
  display: flex;
  font-size: ${rem(18)};
  font-weight: 600;
  align-items: center;
  img {
    height: ${rem(56)};
    width: auto;
    flex-shrink: 0;
    margin-right: ${rem(8)};
  }
`;

const ContactInfoItem = styled.div`
  display: flex;
  font-size: ${rem(13)};
  align-items: center;
  padding: ${rem(6)} 0;
  > div:first-child {
    margin-right: ${rem(8)};
    display: flex;
    span {
      padding: 0;
    }
  }
`;

const Separator = styled.div`
  width: 100%;
  height: ${rem(1)};
  background: #ddd;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
`;

const InvoiceInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${rem(13)};
  padding: ${rem(6)} 0;
  > div:first-child {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: ${rem(4)};
  }
`;

interface OwnProps {
  social?: {
    [key: string]: { url: string };
  };
  organizationProfile?:
    | (OrganizationProfile & {
        invoiceProfile?: Organization & {
          bank_account?: BankAccount;
          invoice_address?: {
            street?: string;
            number: string;
            city?: string;
            zip?: string;
          };
        };
      } & {
        contactInfo?: {
          www?: string;
          email?: string;
          phone?: string;
          contactPerson?: string;
          name?: string;
          address?: {
            street?: string;
            number: string;
            city?: string;
            zip?: string;
          };
        };
      } & {
        theme?: {
          color?: {
            primary?: string;
          };
        };
      })
    | null;
}

const OrgProfileWidget: React.FC<OwnProps> = ({
  organizationProfile,
  social,
}) => {
  const orgLogoSrc = organizationProfile?._id
    ? getPpoLogoSrc({ _id: organizationProfile?._id })
    : '';

  const mergedProfile = {
    ...organizationProfile?.invoiceProfile,
    ...organizationProfile?.contactInfo,
    contact_person:
      organizationProfile?.contactInfo?.contactPerson ||
      organizationProfile?.invoiceProfile?.contact_person ||
      '',
    invoice_address:
      organizationProfile?.contactInfo?.address ||
      organizationProfile?.invoiceProfile?.invoice_address,
  };

  const hasSocial = !!social && Object.keys(social).length > 0;

  return (
    <Segment
      padding={24}
      title={
        <SegmentTitle>
          {!!orgLogoSrc && (
            <img alt={organizationProfile?.name} src={orgLogoSrc} />
          )}
          <div>{organizationProfile?.name}</div>
        </SegmentTitle>
      }
    >
      <Spacer size={3}>
        {(mergedProfile.contact_person ||
          mergedProfile.phone ||
          mergedProfile.fax ||
          mergedProfile.email) && (
          <>
            <ContactInfo>
              {!!mergedProfile.contact_person && (
                <ContactInfoItem>
                  <div>
                    <Icon name="contact-person" />
                  </div>
                  <div>{mergedProfile.contact_person}</div>
                </ContactInfoItem>
              )}
              {!!mergedProfile.phone && (
                <ContactInfoItem>
                  <div>
                    <Icon name="phone" />
                  </div>
                  <div>
                    <a href={`tel:${mergedProfile.phone}`}>
                      {mergedProfile.phone}
                    </a>
                  </div>
                </ContactInfoItem>
              )}
              {!!mergedProfile.fax && (
                <ContactInfoItem>
                  <div>
                    <Icon name="print" />
                  </div>
                  <div>
                    <a href={`tel:${mergedProfile.fax}`}>{mergedProfile.fax}</a>
                  </div>
                </ContactInfoItem>
              )}
              {!!mergedProfile.email && (
                <ContactInfoItem>
                  <div>
                    <Icon name="email" />
                  </div>
                  <div>
                    <a href={`mailto:${mergedProfile.email}`}>
                      {mergedProfile.email}
                    </a>
                  </div>
                </ContactInfoItem>
              )}
            </ContactInfo>
            <Separator />
          </>
        )}

        <InvoiceInfo>
          {!!mergedProfile.invoice_address && (
            <InvoiceInfoItem>
              <div>{__('Adresa')}</div>
              <div>
                {mergedProfile.invoice_address.street}{' '}
                {mergedProfile.invoice_address.number}
                <br />
                {mergedProfile.invoice_address.zip}{' '}
                {mergedProfile.invoice_address.city}
              </div>
            </InvoiceInfoItem>
          )}
          {!!organizationProfile?.invoiceProfile?.business_id && (
            <InvoiceInfoItem>
              <div>{__('IČO')}</div>
              <div>{organizationProfile.invoiceProfile.business_id}</div>
            </InvoiceInfoItem>
          )}
          {!!organizationProfile?.invoiceProfile?.vat_nr && (
            <InvoiceInfoItem>
              <div>{__('DIČ/IČDPH')}</div>
              <div>{organizationProfile.invoiceProfile.vat_nr}</div>
            </InvoiceInfoItem>
          )}
          {!!organizationProfile?.invoiceProfile?.bank_account && (
            <InvoiceInfoItem>
              <div>{__('Banka')}</div>
              <div>{organizationProfile.invoiceProfile.bank_account.IBAN}</div>
            </InvoiceInfoItem>
          )}
        </InvoiceInfo>
      </Spacer>
      {hasSocial && <OrgProfileSocialNetworks social={social} />}
    </Segment>
  );
};

export default OrgProfileWidget;
