import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import {  useQ } from '../../../query';
import loadAllMatches from './features/loadAllMatches';
import loadClubCompetitions from './features/loadClubCompetititons';
import {
  CompetitionNameLoader,
  Footer,
  H1,
  Header,
  PageBreak,
  Wrapper,
} from '../CompetitionMatchesPrint';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';
import RenderPrintDataLoader from '../CompetitionMatchesPrint/RenderPrintDataLoader';
import RenderPrintData, {
  InfoMessage,
} from '../CompetitionMatchesPrint/RenderPrintData';
import __ from '../../../utilities/__';
import FooterLogos from '../CompetitionMatchesPrint/FooterLogos';
import getAppSpaceFromUrl from '../../../utilities/getAppSpaceFromUrl';
import getBaseUrl from '../../../utilities/getBaseUrl';
import FutbalnetPrintQrCode from '../FutbalnetPrintQrCode';
import { Helmet } from 'react-helmet-async';

type RouterProps = RouteComponentProps<
  {
    klub: string;
    tim: string;
    competitionId?: string;
    competitionPartId?: string;
  },
  void
>;

const QUERY_HOC_CONFIG = {
  parameters: {
    competitionId: StringParam(''),
    competitionPartId: StringParam(''),
  },
};

const TeamMatchesPrint: React.FC<RouterProps> = ({
  router,
  location: { search, pathname },
  params: { klub, tim: teamId },
}) => {
  const clubAppSpace = getAppSpaceFromUrl('club', klub) || klub;

  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG,
  );

  const { competitionId, competitionPartId } = query;

  // detail sutaze
  const { data: competition, isLoading: isCompetititonLoading } = useQ(
    loadClubCompetitions,
    { appSpace: clubAppSpace, teamId, competitionId },
    {
      enabled: !!clubAppSpace && !!teamId && !!competitionId,
      refetchOnWindowFocus: false,
    },
  );

  // zapasy
  const { data, isError, isLoading } = useQ(
    loadAllMatches,
    {
      playerAppSpace: clubAppSpace,
      teamId,
      competitionId,
      competitionPartId,
    },
    {
      enabled: !!clubAppSpace && !!klub && !!teamId,
      refetchOnWindowFocus: false,
    },
  );

  const qrCodeUrl = `${getBaseUrl()}/futbalnet/k/${klub}/tim/${teamId}/program/?sutaz=${competitionId}`;

  const showPrintDialog = !isCompetititonLoading && !isLoading;

  React.useEffect(() => {
    if (showPrintDialog) {
      // nechajme 100ms pre render
      window.setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [showPrintDialog]);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex nofollow" />
      </Helmet>
      <Wrapper>
        <Header>
          {competitionId ? (
            isCompetititonLoading ? (
              <CompetitionNameLoader className={SKELETON_CLASS_NAME} />
            ) : competition ? (
              <H1>
                {competition?.name} - {competition?.season.name}
              </H1>
            ) : null
          ) : (
            <H1>Všetky súťaže tímu</H1>
          )}
          <FutbalnetPrintQrCode url={qrCodeUrl} />
        </Header>
        {isLoading ? (
          <RenderPrintDataLoader />
        ) : isError ? (
          <InfoMessage
            text={`${__('Pri získavaní dát pre tlač sa vyskytla chyba!')}`}
          />
        ) : data ? (
          <RenderPrintData data={data} withCompetitionName={!!!competitionId} />
        ) : null}
        <PageBreak />
        <Footer>
          <FooterLogos />
        </Footer>
      </Wrapper>
    </>
  );
};

export default TeamMatchesPrint;
