export default function getPublicCountryCode(iso: any) {
  const slovnik: { [key: string]: string } = {
    DEU: 'GER',
    ROC: 'RUS',
    DNK: 'DEN',
    LVA: 'LAT',
    CHE: 'SUI',
    WLS: 'WAL',
    NLD: 'NED',
    HRV: 'CRO',
    PRT: 'POR',
  };
  return slovnik[iso] || iso;
}
