import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { UrlRedirect } from '../../library/SmartTags';
import * as actions from './actions';

export type LoadSmartTagByIdentState = Readonly<{
  [key: string]: Readonly<{
    isFetching: boolean;
    redirect: Readonly<UrlRedirect | null>;
    error: any;
  }>;
}>;

const URL_DETAIL_INITIAL_STATE: LoadSmartTagByIdentState = {};

export const loadSmartTagByIdentReducer = reducerWithInitialState(
  URL_DETAIL_INITIAL_STATE,
)
  .case(
    actions.loadSmartTagByIdent.async.started,
    (state, { ident }): LoadSmartTagByIdentState => ({
      ...state,
      [ident]: {
        ...state[ident],
        isFetching: true,
        error: null,
      },
    }),
  )
  .case(
    actions.loadSmartTagByIdent.async.done,
    (state, { params: { ident }, result: data }): LoadSmartTagByIdentState => ({
      ...state,
      [ident]: {
        isFetching: false,
        redirect: data.redirect ?? null,
        error: null,
      },
    }),
  )
  .case(
    actions.loadSmartTagByIdent.async.failed,
    (state, { params: { ident }, error }): LoadSmartTagByIdentState => ({
      ...state,
      [ident]: {
        ...state[ident],
        isFetching: false,
        error,
      },
    }),
  );
