import { createSelector } from 'reselect';
import { IDetailInitialState } from '../App/reducer';
import { detailTypeSelector, entitiesSelector } from '../App/selectors';

export const currentMemberIdSelector = (state: any) => state.currentMemberId;

export const getCurrentMemberIdSelector = createSelector(
  currentMemberIdSelector,
  (currentId) => currentId,
);

export const currentMemberTypeSelector = (state: any) =>
  state.currentMemberType;

export const getCurrentMemberTypeSelector = createSelector(
  currentMemberTypeSelector,
  (currentType) => currentType,
);

export const currentMemberSelector = createSelector(
  entitiesSelector,
  currentMemberIdSelector,
  (entities, id) =>
    id !== null && id in entities.member ? entities.member[id] : null,
);

const currentMemberDomain = createSelector(
  currentMemberIdSelector,
  detailTypeSelector('memberById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentMemberIsFetchingSelector = createSelector(
  currentMemberDomain,
  (domain) => domain.isFetching,
);

export const currentMemberErrorSelector = createSelector(
  currentMemberDomain,
  (domain) => domain.error,
);

const currentMemberMovementsDomain = createSelector(
  currentMemberIdSelector,
  detailTypeSelector('memberMovementsById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentMemberMovementsIsFetchingSelector = createSelector(
  currentMemberMovementsDomain,
  (domain) => domain.isFetching,
);

export const currentMemberMovementsErrorSelector = createSelector(
  currentMemberMovementsDomain,
  (domain) => domain.error,
);

const currentMemberStatsDomain = createSelector(
  currentMemberIdSelector,
  detailTypeSelector('memberStatsById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentMemberStatsIsFetchingSelector = createSelector(
  currentMemberStatsDomain,
  (domain) => domain.isFetching,
);

export const currentMemberStatsErrorSelector = createSelector(
  currentMemberStatsDomain,
  (domain) => domain.error,
);
