import React from 'react';
import useIsResponsiveLayout from '../../../hooks/useIsResponsiveLayout';
import DatePickerMobile from './DatePickerMobile';
import DatePicker, { OwnProps } from './DatePicker';

const DatePickerResponsive: React.FC<OwnProps> = (props) => {
  const mobileLayout = useIsResponsiveLayout(600);
  return mobileLayout ? (
    <DatePickerMobile {...props} />
  ) : (
    <DatePicker {...props} />
  );
};

export default DatePickerResponsive;
