const getResolutionDetailUrl = ({
  seasonId,
  resolutionNumber,
  zvaz,
  comiteeId,
}: {
  seasonId: string;
  resolutionNumber: string;
  zvaz: string;
  comiteeId: string;
}) => {
  return `/futbalnet/z/${zvaz}/komisia/${comiteeId}/rozhodnutie/${seasonId}/${resolutionNumber.replace(
    '/',
    '|',
  )}/?seasonId=${seasonId}`;
};

export default getResolutionDetailUrl;
