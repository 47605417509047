import { Table, Tbody, Td, Th, Thead, Tr } from '@sportnet/ui/lib/Table';
import ContentContext from '@sportnet/content/common/ContentContext';
import { IWidgetCompetitionStanding } from '@sportnet/content/utilities/library';
import {
  IResultsStandingItem,
  IResultsTeamStandingItem,
} from '@sportnet/content/library/Competitions';
import { __ } from '@sportnet/content/utilities/utilities';
import WidgetHeader from '@sportnet/content/view/components/Header';
import { rem } from 'polished';
import * as React from 'react';
import { getProp, keyify } from 'sportnet-utilities';
import styled from 'styled-components';
import { ITeam } from '../../../../library/Competitions';
import getTeamUrl from '../../../../utilities/events/getTeamUrl';
import Icon from '../../../Icon';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { LinkStyles } from '../../../Link';

const formatSecondsToString = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const hoursRest = seconds % 3600;
  const m = String(Math.floor(hoursRest / 60)).padStart(2, '0');
  const rest = hoursRest % 60;
  let s = String(Math.round(rest * 100) / 100).padStart(2, '0');
  const secondsSplit = s.split('.');
  if (secondsSplit.length === 2) {
    s = `${
      secondsSplit[0].length < 2 ? `0${secondsSplit[0]}` : secondsSplit[0]
    }.${secondsSplit[1].length < 2 ? `${secondsSplit[1]}0` : secondsSplit[1]}`;
  }
  return `${String(h).padStart(2, '0')}:${m}:${s}`;
};

const Wrapper = styled.div`
  th {
    vertical-align: bottom;
  }
`;

const PhotoNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlayerLink = styled.a`
  ${LinkStyles}
`;

const PlayerPhoto = styled.img`
  flex: 0 0 auto;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 100%;
  margin-right: ${rem(8)};
  font-size: ${rem(9)};
`;

const PlaceHolder = styled(Icon)`
  border-radius: 100%;
  margin-right: ${rem(8)};
  flex: 0 0 1;
`;

const TeamName = styled.div`
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${rem(12)};
`;

const StyledTd = styled(Td)`
  width: ${rem(120)};
`;

const Title = styled(WidgetHeader)`
  && {
    > h2 {
      font-size: ${rem(18)};
      font-weight: bold;
    }
  }
` as any;

type IData = Omit<IWidgetCompetitionStanding, 'type'>;
interface IOwnProps {
  data: IData;
  renderEmpty?: (type: string) => React.ReactNode;
}

const CompetitionStanding: React.FC<IOwnProps> = ({ data, renderEmpty }) => {
  const context = React.useContext(ContentContext);

  if ((!data.competitionId || !data.partId) && renderEmpty) {
    return <>{renderEmpty('competitionStanding')}</>;
  }

  if (data.data) {
    const {
      data: { results = [], teamResults = [], codelist = [] },
    } = data;

    const formatPoints = (value: number, format?: string, diff = false) => {
      if (!format) {
        return value;
      }
      switch (format) {
        case 'time':
          if (diff) {
            return `+ ${formatSecondsToString(value)}`;
          }
          return `${formatSecondsToString(value)}`;
        default:
          return value;
      }
    };

    const filterResults = (
      item: IResultsStandingItem | IResultsTeamStandingItem,
    ) => {
      const point = (item.totalPoints || []).find(
        (i) => i && i.name === data.point?._id,
      );
      if ((point && point.value) || !data.omitEmpty) {
        return item;
      }
      return null;
    };

    const sortResults = (
      a: IResultsStandingItem | IResultsTeamStandingItem,
      b: IResultsStandingItem | IResultsTeamStandingItem,
    ) => {
      const aPoint = (a.totalPoints || []).find(
        (i) => i && i.name === data.sortingPoint?._id,
      );
      const bPoint = (b.totalPoints || []).find(
        (i) => i && i.name === data.sortingPoint?._id,
      );

      if (!aPoint || !aPoint.value) {
        return 1;
      } else if (!bPoint || !bPoint.value) {
        return -1;
      } else if (
        aPoint &&
        bPoint &&
        Number(aPoint.value) > Number(bPoint.value)
      ) {
        return data.sort === 'asc' ? 1 : -1;
      } else if (
        aPoint &&
        bPoint &&
        Number(aPoint.value) < Number(bPoint.value)
      ) {
        return data.sort === 'asc' ? -1 : 1;
      }
      return 0;
    };

    const renderPointLabel = (point: { _id: string; label?: string }) => {
      const pointData = codelist.find((c) => c._id === point?._id);
      if (pointData && pointData.format === 'time') {
        return __('Čas');
      }
      return data.appSpace === 'amaury-sport-organisation---cyklistika'
        ? __('Body')
        : point.label;
    };

    const getTeamNameFunc = getProp(context, ['helpers', 'getTeamName']);

    const slicedResults = results
      .filter(filterResults)
      .sort(sortResults)
      .slice(0, data.limit || results.length);

    const slicedTeamResults = teamResults
      .filter(filterResults)
      .sort(sortResults)
      .splice(0, data.limit || teamResults.length);

    const differenceComputable =
      typeof getProp(
        getProp(slicedResults, [0, 'totalPoints'], []).find(
          (i: any) => i && i.name === data.point?._id,
        ),
        ['value'],
      ) === 'number';

    const points = getProp(slicedResults, [0, 'totalPoints'], []).find(
      (i: any) => i.name === 'points',
    );

    return (
      <Wrapper className="content-widget-competitionStanding-wrapper">
        {data.title && <Title>{data.title}</Title>}
        <Table>
          <Thead>
            <Tr>
              <Th>#</Th>
              {data.displayType !== 'team_total' && (
                <Th>
                  {data.appSpace === 'amaury-sport-organisation---cyklistika'
                    ? __('Cyklista')
                    : __('Pretekár')}
                </Th>
              )}
              <Th>{__('Tím')}</Th>
              <Th width={100}>{renderPointLabel(data.point!)}</Th>
              {(differenceComputable || data.appSpace !== 'fia') && (
                <Th width={100}>{__('Rozdiel')}</Th>
              )}
              {!differenceComputable &&
                data.appSpace === 'fia' &&
                !!points &&
                data.point!._id !== 'points' && (
                  <Th width={100}>{__('Body')}</Th>
                )}
            </Tr>
          </Thead>
          <Tbody>
            {data.displayType === 'individual' && (
              <>
                {slicedResults.map((result, idx) => {
                  const pointObj = (result.totalPoints || []).find(
                    (i: any) => i && i.name === data.point?._id,
                  );
                  const sortingPointObj = (result.totalPoints || []).find(
                    (i: any) => i && i.name === data.sortingPoint?._id,
                  );
                  const diffPointObj = idx
                    ? slicedResults[0].totalPoints.find(
                        (i: any) => i && i.name === data.point?._id,
                      )
                    : undefined;
                  const codelistItem = codelist.find(
                    (c) => c._id === data.point?._id,
                  );

                  const teamUrl = result.team
                    ? getTeamUrl(
                        result.team as unknown as ITeam,
                        data.competitionId,
                      )
                    : '';
                  return (
                    <Tr
                      key={`tr_${result.players
                        .map((p: any) => p._id)
                        .join(',')}`}
                    >
                      <Td>{sortingPointObj?.value}</Td>
                      <Td>
                        {teamUrl ? (
                          <PlayerLink
                            href={`${teamUrl}${result.players[0]._id}/${keyify(
                              result.players[0].name,
                            )}`}
                          >
                            <PhotoNameWrapper>
                              {result.players.length === 1 &&
                                data.playerPhoto && (
                                  <>
                                    {result.players[0].additionalData?.photo ? (
                                      <PlayerPhoto
                                        alt={result.players[0].name}
                                        src={getMediaManagerUrl(
                                          result.players[0].additionalData
                                            .photo,
                                          48,
                                          48,
                                          'media',
                                          true,
                                        )}
                                      />
                                    ) : (
                                      <PlaceHolder name="avatar" size={24} />
                                    )}
                                  </>
                                )}
                              {result.players
                                .map((p: any) => p.name)
                                .join(', ')}
                            </PhotoNameWrapper>
                          </PlayerLink>
                        ) : (
                          <PhotoNameWrapper>
                            {result.players.length === 1 &&
                              data.playerPhoto && (
                                <>
                                  {result.players[0].additionalData?.photo ? (
                                    <PlayerPhoto
                                      alt={result.players[0].name}
                                      src={getMediaManagerUrl(
                                        result.players[0].additionalData.photo,
                                        48,
                                        48,
                                        'media',
                                        true,
                                      )}
                                    />
                                  ) : (
                                    <PlaceHolder name="avatar" size={24} />
                                  )}
                                </>
                              )}
                            {result.players.map((p: any) => p.name).join(', ')}
                          </PhotoNameWrapper>
                        )}
                      </Td>
                      <Td>
                        {' '}
                        <TeamName>
                          {teamUrl ? (
                            <PlayerLink href={teamUrl}>
                              {getTeamNameFunc &&
                                getTeamNameFunc(data.appSpace!, result.team)}
                            </PlayerLink>
                          ) : (
                            getTeamNameFunc &&
                            getTeamNameFunc(data.appSpace!, result.team)
                          )}
                        </TeamName>
                      </Td>
                      <StyledTd>
                        {pointObj &&
                          formatPoints(pointObj.value, codelistItem?.format)}
                      </StyledTd>
                      {pointObj &&
                        diffPointObj &&
                        typeof diffPointObj.value === 'number' && (
                          <StyledTd>
                            {formatPoints(
                              pointObj.value - diffPointObj.value,
                              codelistItem?.format,
                              true,
                            )}
                          </StyledTd>
                        )}
                      {!differenceComputable &&
                        data.appSpace === 'fia' &&
                        !!points && (
                          <StyledTd>
                            {getProp(
                              slicedResults[idx].totalPoints.find(
                                (i) => i.name === 'points',
                              ),
                              ['value'],
                              '',
                            )}
                          </StyledTd>
                        )}
                    </Tr>
                  );
                })}
              </>
            )}
            {data.displayType === 'individual_total' && (
              <>
                {slicedResults.map((result, idx) => {
                  const pointObj = (result.totalPoints || []).find(
                    (i: any) => i.name === data.point?._id,
                  );
                  const sortingPointObj = (result.totalPoints || []).find(
                    (i: any) => i && i.name === data.sortingPoint?._id,
                  );
                  const diffPointObj = idx
                    ? slicedResults[0].totalPoints.find(
                        (i: any) => i && i.name === data.point?._id,
                      )
                    : undefined;
                  const codelistItem = codelist.find(
                    (c) => c._id === data.point?._id,
                  );
                  const teamUrl = result.team
                    ? getTeamUrl(
                        result.team as unknown as ITeam,
                        data.competitionId,
                      )
                    : '';
                  return (
                    <Tr
                      key={`tr_${result.players
                        .map((p: any) => p._id)
                        .join(',')}`}
                    >
                      <Td>{sortingPointObj?.value}</Td>
                      <Td>
                        {teamUrl ? (
                          <PlayerLink
                            href={`${teamUrl}${result.players[0]._id}/${keyify(
                              result.players[0].name,
                            )}`}
                          >
                            <PhotoNameWrapper>
                              {result.players.length === 1 &&
                                (data as any).playerPhoto && (
                                  <>
                                    {result.players[0].additionalData?.photo ? (
                                      <PlayerPhoto
                                        alt={result.players[0].name}
                                        src={getMediaManagerUrl(
                                          result.players[0].additionalData
                                            .photo,
                                          48,
                                          48,
                                          'media',
                                          true,
                                        )}
                                        loading="lazy"
                                      />
                                    ) : (
                                      <PlaceHolder name="avatar" size={24} />
                                    )}
                                  </>
                                )}
                              {result.players
                                .map((p: any) => p.name)
                                .join(', ')}
                            </PhotoNameWrapper>
                          </PlayerLink>
                        ) : (
                          <>
                            <PhotoNameWrapper>
                              {result.players.length === 1 &&
                                (data as any).playerPhoto && (
                                  <>
                                    {result.players[0].additionalData?.photo ? (
                                      <PlayerPhoto
                                        alt={result.players[0].name}
                                        src={getMediaManagerUrl(
                                          result.players[0].additionalData
                                            .photo,
                                          48,
                                          48,
                                          'media',
                                          true,
                                        )}
                                        loading="lazy"
                                      />
                                    ) : (
                                      <PlaceHolder name="avatar" size={24} />
                                    )}
                                  </>
                                )}
                              {result.players
                                .map((p: any) => p.name)
                                .join(', ')}
                            </PhotoNameWrapper>
                          </>
                        )}
                      </Td>
                      <Td>
                        <TeamName>
                          {teamUrl ? (
                            <PlayerLink href={teamUrl}>
                              {getTeamNameFunc &&
                                getTeamNameFunc(data.appSpace!, result.team)}
                            </PlayerLink>
                          ) : (
                            getTeamNameFunc &&
                            getTeamNameFunc(data.appSpace!, result.team)
                          )}
                        </TeamName>
                      </Td>
                      <StyledTd>
                        {pointObj &&
                          formatPoints(pointObj.value, codelistItem?.format)}
                      </StyledTd>
                      <StyledTd>
                        {pointObj &&
                          diffPointObj &&
                          formatPoints(
                            pointObj.value - diffPointObj.value,
                            codelistItem?.format,
                            true,
                          )}
                      </StyledTd>
                    </Tr>
                  );
                })}
              </>
            )}
            {data.displayType === 'team_total' && (
              <>
                {slicedTeamResults.map((result, idx) => {
                  const pointObj = (result.totalPoints || []).find(
                    (i: any) => i.name === data.point?._id,
                  );
                  const sortingPointObj = (result.totalPoints || []).find(
                    (i: any) => i && i.name === data.sortingPoint?._id,
                  );
                  const diffPointObj = idx
                    ? slicedTeamResults[0].totalPoints.find(
                        (i: any) => i && i.name === data.point?._id,
                      )
                    : undefined;
                  const codelistItem = codelist.find(
                    (c) => c._id === data.point?._id,
                  );
                  const teamUrl = result.team
                    ? getTeamUrl(
                        result.team as unknown as ITeam,
                        data.competitionId,
                      )
                    : '';
                  return (
                    <Tr key={`tr_${result.team._id}`}>
                      <Td>{sortingPointObj?.value}</Td>
                      <Td>
                        {teamUrl ? (
                          <PlayerLink href={teamUrl}>
                            {getTeamNameFunc &&
                              getTeamNameFunc(data.appSpace!, result.team)}
                          </PlayerLink>
                        ) : (
                          getTeamNameFunc &&
                          getTeamNameFunc(data.appSpace!, result.team)
                        )}
                      </Td>
                      <StyledTd>
                        {pointObj &&
                          formatPoints(pointObj.value, codelistItem?.format)}
                      </StyledTd>
                      <StyledTd>
                        {pointObj &&
                          diffPointObj &&
                          formatPoints(
                            pointObj.value - diffPointObj.value,
                            codelistItem?.format,
                            true,
                          )}
                      </StyledTd>
                    </Tr>
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </Wrapper>
    );
  }
  return null;
};

export default CompetitionStanding;
