import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill={color}
  >
    <path d="M0,10.5h4v4H0V10.5z M6,6.5h4v8H6V6.5z M12,1.5h4v13h-4V1.5z" />
  </svg>
);
