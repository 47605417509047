import React from 'react';
import Hider from '../Hider';
import { IMenuItem } from './types';

interface OwnProps {
  item: IMenuItem;
  xPosition: number;
  active: boolean;
  onClose: () => void;
}

type Props = OwnProps;

const DropdownItem: React.FC<Props> = ({
  item,
  xPosition,
  active,
  onClose,
}) => {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const wrapperEl = wrapperRef.current;
    if (active && wrapperEl) {
      wrapperEl.focus();
    }
  }, [active]);

  if (!item.renderDropdown) {
    return null;
  }

  return (
    <Hider ref={wrapperRef} hidden={!active}>
      {item.renderDropdown({ xPosition, active, onClose })}
    </Hider>
  );
};

export default React.memo(DropdownItem);
