import React from 'react';
import AdManager from '../../components/Ads/AdManager';
import { Helmet } from 'react-helmet-async';
import RempIntegration from '../../containers/RempIntegration';
import TopAd from '../../components/Ads/TopAd';
import __ from '../../utilities/__';
import SectionName from '../../components/Sections/SectionBlockHeader/SectionName';
import { rem } from 'polished';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import {
  BottomPaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import styled from '../../theme/styled-components';
import Filter from './Filter';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../configureStore';
import { getActiveFilterSelector } from './selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useInfiniteQ } from '../../query';
import listTransfersQ from './features';
import {
  ListTransfersResponse,
  TransfersFilter,
} from '../../library/Transfermarket';
import TransfersList from './TransfersList';
import { HashParams } from '../../library/TvProgram';
import { serializeHashParams } from '../../utilities/tvProgram';
import SidebarFilter from './SidebarFilter';
import format from 'date-fns/format';
import config from '../../config';
import TransfersListLoader from './TransfersListLoader';
import { authUserIsNoAdvertsSelector } from '../App/selectors';
import BigAd from '../../components/Ads/BigAd';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { getCanonicalUrl, getFilterInitialState } from './utilities';
import FutbalnetLogo from '../../components/FutbalnetHeader/FutbalnetLogo';
import NoResults from '../../components/v2/NoResults';
import SideAd from '../../components/Ads/SideAd';
import SectionBodyAd from '../../components/Sections/Ads/SectionBodyAd';
import ListBodyRespAd from '../../components/Ads/ListBodyRespAd';
import useTopArticlesSidebar from '../../hooks/useTopArticlesSidebar';
import ResponsiveTopArticlesSidebar from '../Home/ResponsiveTopArticlesSidebar';
import TopArticlesSidebar from '../../containers/TopArticlesSidebar';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NewsFeedArticlesHeader from '../../components/Articles/NewsFeedArticlesHeader';

import useInfiniteListParams from '../../hooks/useInfiniteListParams';
import InfiniteListingArticleList from '../../containers/InfiniteListingArticleList';
import MaxWidthBox from '../../components/MaxWidthBox';

const StyledFilter = styled(Filter)``;

export const SectionTitleWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(24)};
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

export const SpacedMaxWidthBox = styled(MaxWidthBox)`
  margin-top: ${rem(24)};
`;

export const SpacedBigAd = styled(BigAd)`
  && {
    min-height: ${rem(120)};
    margin-bottom: ${rem(24)};
    ${mb('l')} {
      margin-bottom: ${rem(32)};
    }
  }
`;

const FutbalnetLogoWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: block;
    margin-left: auto;
  }
`;

const mapStateToProps = (
  state: RootState,
  { location: { hash } }: RouterProps
) => {
  const activeFilter = getActiveFilterSelector(hash as never);
  return {
    activeFilter,
    authUserIsNoAdverts: authUserIsNoAdvertsSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type RouterProps = RouteComponentProps<
  {
    ppo?: string;
    transferType?: string;
  },
  void
>;

type Props = ReturnType<typeof mapStateToProps> & RouterProps;

const Transfermarket: React.FC<Props> = ({
  activeFilter,
  appSettings,
  location: { pathname, search, hash },
  router,
  authUserIsNoAdverts,
}) => {
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const { initialLimit, initialOffset, nextOffset } =
    useInfiniteListParams(page);

  const titleRef = React.useRef<HTMLDivElement>();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQ(
      listTransfersQ,
      { ...activeFilter, groupByDay: true },
      {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => lastPage.next_offset,
      }
    );

  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  // all transfers by day
  const allTransfersByDay = React.useMemo(() => {
    return (data?.pages || []).reduce((acc, page) => {
      Object.keys(page.transfersByDay).forEach((date) => {
        if (date in acc) {
          acc[date] = acc[date].concat(page.transfersByDay[date]);
        } else {
          acc[date] = page.transfersByDay[date];
        }
      });
      return acc;
    }, {} as { [key: string]: ListTransfersResponse['transfers'] });
  }, [data]);

  const handleChangeActiveFilter = React.useCallback(
    (filter: HashParams) => {
      router.push({
        pathname,
        search,
        hash: serializeHashParams(filter),
      });
      if (titleRef.current) {
        titleRef.current.scrollIntoView();
      }
    },
    [router, search, pathname]
  );

  const handleSidebarClose = () => {
    setSidebarOpened(false);
  };

  const handleFilterButtonClick = React.useCallback(() => {
    setSidebarOpened(true);
  }, [setSidebarOpened]);

  const handleFilterChange = (newFilter: TransfersFilter) => {
    setSidebarOpened(false);
    router.push({
      pathname,
      search,
      hash: serializeHashParams({
        ...newFilter,
        dateFrom: newFilter.dateFrom
          ? format(newFilter.dateFrom, 'yyyy-MM-dd')
          : undefined,
        dateTo: newFilter.dateTo
          ? format(newFilter.dateTo, 'yyyy-MM-dd')
          : undefined,
      }),
    });

    // kedze pri otvorenom sidebar filtri je mozne skrolovat, musime so
    // zmenou fiiltra naskrolovat spat na nadpis
    if (titleRef.current) {
      titleRef.current.scrollIntoView();
    }
  };

  const handleResetFilterClick = () => {
    handleFilterChange(getFilterInitialState());
  };
  const title = `${__('Prestupy a hosťovania')}`;
  const metaTitle = `${__('Prestupy a hosťovania - futbalnet')}`;
  const metaDescription = `${__(
    'Všetky prestupy a hosťovania v slovenskom futbale na jednom mieste. Vyhľadajte prestup alebo hosťovanie vo všetkých kluboch, filtrujte podľa zväzu alebo dátumu.'
  )}`;
  const canonicalUrl = getCanonicalUrl(pathname, search);

  const siteId = `${pathname}/${search}/${hash}`;

  const hasData =
    data?.pages && data.pages.length > 0 && data.pages[0].total > 0;

  const handleLoadMoreArticles = () => {
    setPage((prev) => ++prev);
  };

  return (
    <>
      <AdManager
        site="rubric"
        siteId={siteId}
        origin="futbalnet"
        pagetype="other"
      />
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${appSettings.baseUri}/futbalnet/futbalnet-logo.png`}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <RempIntegration destroy />
      <FutbalnetHomeHeader />
      <TopAd />
      <ListBodyRespAd name="list_body_1" />
      {!authUserIsNoAdverts && <SpacedBigAd name="big_1" />}
      <SpacedMaxWidthBox className="sptn-main-content">
        <SectionTitleWrapper
          ref={titleRef}
          style={{ paddingTop: 0, paddingBottom: rem(16) }}
        >
          <SectionName as="h1">{title}</SectionName>
          <FutbalnetLogoWrapper>
            <FutbalnetLogo />
          </FutbalnetLogoWrapper>
        </SectionTitleWrapper>
      </SpacedMaxWidthBox>
      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <StyledFilter
            pathname={pathname}
            search={search}
            activeFilter={activeFilter}
            onChangeActiveFilter={handleChangeActiveFilter}
            onFilterButtonClick={handleFilterButtonClick}
          />
          {isLoading ? (
            <TransfersListLoader />
          ) : hasData ? (
            <TransfersList
              tansfersByDay={allTransfersByDay}
              isFetchingNextPage={isFetchingNextPage}
              onLoadMoreClick={hasNextPage ? fetchNextPage : undefined}
            />
          ) : (
            <NoResults
              text={__('Pre zadaný filter sa nenašli žiadne výsledky')}
              onResetFilterClick={handleResetFilterClick}
            />
          )}
          <SectionBodyAd />
          <NewsFeedArticlesHeader />
          <InfiniteListingArticleList
            initialLimit={initialLimit}
            nextOffset={nextOffset}
            initialOffset={initialOffset}
            listingId={config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID}
            onLoadMore={handleLoadMoreArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>
      <MegaboardAd />
      <SpacedMaxWidthBox className="sptn-main-content">
        <ResponsiveTopArticlesSidebar
          activeTab={activeTabTopArticles}
          setActiveTab={setActiveTabTopArticles}
        />
      </SpacedMaxWidthBox>
      <SidebarFilter
        activeFilter={activeFilter}
        opened={sidebarOpened}
        onClose={handleSidebarClose}
        onFilterChange={handleFilterChange}
      />
    </>
  );
};

export default compose(connect(mapStateToProps), React.memo)(Transfermarket);
