import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import { ISeason } from '../../library/Competitions';
import * as actions from './actions';

export interface IUnionsState {
  isFetching?: boolean;
  error?: any | null;
  items?: OrganizationProfileStructureItem[];
}

export interface ISeasonsState {
  isFetching?: boolean;
  error?: any | null;
  items?: ISeason[];
}

export const seasonsReducer = reducerWithInitialState<ISeasonsState>({})
  .case(
    actions.loadUnionSeasons.async.started,
    (state): ISeasonsState => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.loadUnionSeasons.async.done,
    (state, { result }): ISeasonsState => ({
      ...state,
      isFetching: false,
      error: null,
      items: result,
    }),
  )
  .case(
    actions.loadUnionSeasons.async.failed,
    (state, { error }): ISeasonsState => ({
      ...state,
      isFetching: false,
      error,
    }),
  );

export const unionsReducer = reducerWithInitialState<IUnionsState>({})
  .case(
    actions.loadUnions.async.started,
    (state): IUnionsState => ({
      ...state,
      isFetching: true,
    }),
  )
  .case(
    actions.loadUnions.async.done,
    (state, { result }): IUnionsState => ({
      ...state,
      isFetching: false,
      error: null,
      items: result,
    }),
  )
  .case(
    actions.loadUnions.async.failed,
    (state, { error }): IUnionsState => ({
      ...state,
      isFetching: false,
      error,
    }),
  );
