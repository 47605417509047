import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import ListBodyRespAd from '../../components/Ads/ListBodyRespAd';
import ArticlesList from '../../components/Articles/ArticlesList';
import TopArticlesGrid from '../../components/Articles/TopArticlesGrid';
import LoadMorePanel from '../../components/LoadMorePanel';
import { RootState } from '../../configureStore';
import { cumulatedListArticlesSelector } from '../../pages/Article/selectors';
import { sectionsEntitiesSelector } from '../../pages/Section/selectors';
import styled from '../../theme/styled-components';

const PositionedArticlesList = styled(ArticlesList)`
  margin: ${rem(32)} 0 0 0;
  ${mb('s')} {
    margin: ${rem(56)} 0 0 0;
  }
`;

const Wrapper = styled.div`
  margin-bottom: ${rem(48)};
  ${mb('s')} {
    margin-bottom: ${rem(56)};
  }
`;

type IOwnProps = {
  listName: string;
  onLoadMoreWithOffset: (nextOffset: number) => void;
};

const mapStateToProps = (state: RootState, { listName }: IOwnProps) => ({
  articles: cumulatedListArticlesSelector(state, listName),
  loading: isCommiting(listName)(state) || false,
  nextOffset: getListNextOffset(listName)(state),
  sectionsById: sectionsEntitiesSelector(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const RelatedArticles: React.FC<IProps> = ({
  articles,
  loading,
  nextOffset,
  sectionsById,
  onLoadMoreWithOffset,
}) => {
  const mainArticles = articles.slice(0, 5);
  const secondaryArticles = articles.slice(5);

  if (!articles.length) {
    return null;
  }

  return (
    <Wrapper>
      {articles.length >= 5 ? (
        <>
          <TopArticlesGrid articles={mainArticles} sectionsById={sectionsById}>
            <ListBodyRespAd name="list_body_1" />
          </TopArticlesGrid>
          {secondaryArticles.length > 0 && (
            <PositionedArticlesList
              articles={secondaryArticles}
              sectionsById={sectionsById}
            />
          )}
        </>
      ) : (
        <ArticlesList articles={articles} sectionsById={sectionsById} />
      )}
      {nextOffset !== null && (
        <LoadMorePanel
          loading={loading}
          onLoadMore={() => {
            if (nextOffset !== void 0 && !loading) {
              onLoadMoreWithOffset(nextOffset);
            }
          }}
        />
      )}
    </Wrapper>
  );
};

export default connect(mapStateToProps)(RelatedArticles);
