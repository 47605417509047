import slugify from 'slugify';
import { IPPOUser } from '../../library/Sportnet';

const getUrlNameFromDisplayName = (displayName: string): string => {
  return encodeURIComponent(
    slugify(displayName.trim().replace(/\s/g, '-'), { lower: true }),
  );
};

export const getMemberUrlName = (user: IPPOUser) => {
  return getUrlNameFromDisplayName(
    [user.name, user.surname].filter(Boolean).join(' '),
  );
};

export default function getEventMemberUrl(baseUri: string, user: IPPOUser) {
  return (
    [baseUri.replace(/\/$/, ''), user._id, getMemberUrlName(user)]
      .filter(Boolean)
      .join('/') + '/'
  );
}
