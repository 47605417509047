export const SPORT_SECTOR_INTERNET_BROADCASTERS = [
  {
    _id: "futbal",
    items: [
      {
        _id: "futbalnet_tv",
        label: "Futbalnet.tv",
        logo_url:
          "https://mediamanager.sportnet.online/media/pages/s/sportnet.sme.sk/2021/08/futbalnet_tv.jpg",
      },
      {
        _id: "huste_tv",
        label: "Huste.tv",
        logo_url:
          "https://mediamanager.sportnet.online/media/pages/s/sportnet.sme.sk/2021/08/huste_tv.jpg",
      },
      {
        _id: "voyo",
        label: "Voyo",
        logo_url:
          "https://mediamanager.sportnet.online/media/pages/s/sportnet.sme.sk/2021/08/voyo.jpg",
      },
    ],
  },
  { _id: "beachfutbal", items: [] },
];
