import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import styled from '../../../theme/styled-components';
import Article from './Article';

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: ${rem(560)};
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  :not(:last-child) {
    margin-bottom: ${rem(24)};
  }
`;

type IOwnProps = {
  articles: IArticle[];
  className?: string;
};

type IProps = IOwnProps;

const ArticlesListSmall: React.FC<IProps> = ({ articles, className }) => {
  if (!articles.length) {
    return null;
  }

  function renderArticle(article: IArticle) {
    return (
      <ListItem key={`${article._id}-${article.modified}`}>
        <Article article={article} />
      </ListItem>
    );
  }

  return <List className={className}>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesListSmall);
