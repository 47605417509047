import { mb, mediaSizes } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import styled, { css } from '../../../theme/styled-components';
import getArticleContentType from '../../../utilities/getArticleContentType';
import Icon from '../../Icon';

const Wrapper = styled.span<{
  compactBreakPoint: keyof typeof mediaSizes | number;
  aspectRatio: string;
}>`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  width: ${rem(32)};
  height: ${rem(32)};
  bottom: ${rem(12)};
  right: ${rem(12)};

  ${({ compactBreakPoint, aspectRatio }) =>
    compactBreakPoint &&
    css`
      width: ${rem(24)};
      height: ${rem(24)};
      bottom: ${aspectRatio === '4:3' ? rem(8) : rem(4)};
      right: ${rem(4)};
      svg {
        width: 10px;
        height: 10px;
      }
      ${mb(compactBreakPoint)} {
        width: ${rem(32)};
        height: ${rem(32)};
        bottom: ${rem(12)};
        right: ${rem(12)};
        svg {
          width: 16px;
          height: 16px;
        }
      }
    `}
`;

const TextWrapper = styled.span<{
  compactBreakPoint: keyof typeof mediaSizes | number;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  background-color: rgba(0, 0, 0, 0.8);
  width: ${rem(82)};
  height: ${rem(25)};
  top: ${rem(12)};
  left: ${rem(12)};

  ${({ compactBreakPoint }) =>
    compactBreakPoint &&
    css`
      display: none;
      ${mb(compactBreakPoint)} {
        width: ${rem(82)};
        height: ${rem(25)};
        top: ${rem(12)};
        left: ${rem(12)};
      }
    `}
`;

const Text = styled.span<{
  compactBreakPoint: keyof typeof mediaSizes | number;
}>`
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  opacity: 1;
  color: #ffffff;
  font-weight: 600;
  font-family: ${({ theme }) => theme.primaryFont.family };
  white-space: nowrap
    ${({ compactBreakPoint }) =>
      compactBreakPoint &&
      css`
        font-size: ${rem(8)};
        line-height: ${rem(10)};
        ${mb(compactBreakPoint)} {
          font-size: ${rem(12)};
          line-height: ${rem(14)};
        }
      `};
`;
// positionUpLeft?: boolean

type IOwnProps = {
  article: IArticle;
  compactBreakPoint?: keyof typeof mediaSizes | number;
  aspectRatio?: string;
};

type IProps = IOwnProps;

const ArticleContentTypeIcon: React.FC<IProps> = ({
  article,
  compactBreakPoint = 0,
  aspectRatio = '',
}) => {
  const articleContentType = React.useMemo(
    () => getArticleContentType(article),
    [article],
  );

  const typeToShow: string[] = [];
  if (articleContentType[0].length > 0) {
    typeToShow.push(articleContentType[0][0]);
  }
  // join text element with comma
  if (articleContentType[1].length > 0) {
    typeToShow.push(articleContentType[1].join(','));
  }

  const result: any[] = [];

  typeToShow.forEach((type, index) => {
    switch (type) {
      case 'gallery':
      case 'video':
      case 'podcast':
        result.push(
          <Wrapper
            compactBreakPoint={compactBreakPoint}
            style={{ paddingLeft: rem(type === 'video' ? 2 : 0) }}
            aspectRatio={aspectRatio}
            key={`ArticleContentTypeIcon-${index}`}
          >
            <Icon name={type} size={12} color="#fff" />
          </Wrapper>,
        );
        break;
      case '':
        break;
      default:
        result.push(
          <TextWrapper
            compactBreakPoint={compactBreakPoint}
            key={`ArticleContentTypeIcon-${index}`}
          >
            <Text compactBreakPoint={compactBreakPoint}>{type}</Text>
          </TextWrapper>,
        );
        break;
    }
  });
  return <>{result}</>;
};

export default React.memo(ArticleContentTypeIcon);
