import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle } from '../../../library/App';

interface IOwnProps {
  article: IArticle;
  isDiscussion?: boolean;
}

type IProps = IOwnProps;

const ArticleMeta: React.FC<IProps> = ({ article, isDiscussion = false }) => {
  const { getFullArticleUrl, getArticleDiscussionUrl } = React.useContext(UrlContext);

  const url = isDiscussion ? getArticleDiscussionUrl(article) : getFullArticleUrl(article);

  const articleTitlePicture = (article.picture as any) || null;

  const title = article.name;
  const metaTitle = article.meta?.title || article.name;
  const desc = article.perex;
  const metaDesc = article.meta?.description || article.perex;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:title" content={title} />
      {desc && <meta property="og:description" content={desc} />}
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      {articleTitlePicture && (
        <meta
          property="og:image"
          content={getMediaManagerUrl(
            articleTitlePicture,
            1000,
            0,
            'resize',
            false,
          )}
        />
      )}
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default React.memo(ArticleMeta);
