import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 12 12"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M10,3.8H2C1.6,3.8,1.3,3.4,1.3,3S1.6,2.3,2,2.3h8c0.4,0,0.8,0.3,0.8,0.8S10.4,3.8,10,3.8z M10.8,6c0-0.4-0.3-0.8-0.8-0.8H2
C1.6,5.3,1.3,5.6,1.3,6S1.6,6.8,2,6.8h8C10.4,6.8,10.8,6.4,10.8,6z M10.8,9c0-0.4-0.3-0.8-0.8-0.8H2C1.6,8.3,1.3,8.6,1.3,9
S1.6,9.8,2,9.8h8C10.4,9.8,10.8,9.4,10.8,9z"
    />
  </svg>
);
