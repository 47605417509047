import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import Icon from '../../../components/Icon';
import { IArticle } from '../../../library/App';
import { MediaManagerPhoto } from '../../../library/MediaManager';
import styled, { css } from '../../../theme/styled-components';
import ArticleIcon from '../../Articles/ArticleIcon';

const ArticlePictureFrame = styled.div`
  position: relative;
  padding-top: 75%;
  flex-shrink: 0;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CoverImage = styled(MediaManagerImage)<{ borderRadius: boolean }>`
  &,
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #fff;
    overflow: hidden;
    border-radius: ${rem(8)};
    ${({ borderRadius }) =>
      borderRadius &&
      css`
        border-radius: 0;
        ${mb('s')} {
          border-radius: ${rem(8)};
        }
      `}
  }
`;

const PremiumBg = styled(Icon)<{ responsive: boolean }>`
  position: absolute;
  left: -0.6px;
  bottom: ${rem(16)};

  ${({ responsive }) =>
    responsive &&
    css`
      ${mb('s')} {
        top: ${rem(32)};
        right: -0.6px;
        left: auto;
        bottom: auto;
        transform: rotate(180deg);
      }
    `}
`;

const PremiumIcon = styled(Icon)<{ responsive: boolean }>`
  position: absolute;
  bottom: ${rem(48)};
  left: ${rem(-9)};

  ${({ responsive }) =>
    responsive &&
    css`
      ${mb('s')} {
        top: ${rem(64)};
        right: ${rem(-9)};
        left: auto;
        bottom: auto;
      }
    `}
`;

type IOwnProps = {
  article: IArticle;
  naturalHeight: number;
  naturalWidth: number;
  responsive?: boolean;
  className?: string;
  borderRadius?: boolean;
};

type IProps = IOwnProps;

const ArticleImage: React.FC<IProps> = ({
  article,
  naturalWidth,
  naturalHeight,
  responsive = false,
  className,
  borderRadius,
}) => {
  const description =
    (article.picture as MediaManagerPhoto)?.description || article.name;
  return (
    <ArticlePictureFrame
      width={naturalWidth}
      height={naturalHeight}
      className={className}
    >
      <Content>
        <CoverImage
          picture={article.picture || {}}
          script="media"
          naturalWidth={naturalWidth}
          naturalHeight={naturalHeight}
          webp={true}
          loading="lazy"
          borderRadius={!!borderRadius}
          alt={description}
        />
        {article.is_private && naturalHeight > 200 && (
          <>
            <PremiumBg
              name="premium-shape"
              size={76}
              color="#fff"
              responsive={responsive}
              className="premium-bg"
            />
            <PremiumIcon
              name="premium"
              size={12}
              responsive={responsive}
              className="premium-icon"
            />
          </>
        )}
        <ArticleIcon article={article} />
      </Content>
    </ArticlePictureFrame>
  );
};

export default React.memo(ArticleImage);
