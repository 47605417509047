import React from 'react';

export default (size: string, color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        d="M12.1,15.1c-0.1,0-0.2,0-0.3-0.1L8,13l-3.8,2C4,15.1,3.7,15.1,3.4,15c-0.2-0.2-0.3-0.5-0.3-0.7L3.9,10l-3.1-3
	C0.6,6.9,0.5,6.6,0.6,6.3C0.7,6,0.9,5.8,1.2,5.8l4.2-0.6l1.9-3.8c0.3-0.5,1.1-0.5,1.3,0l1.9,3.8l4.2,0.6c0.3,0,0.5,0.2,0.6,0.5
	c0.1,0.3,0,0.6-0.2,0.8l-3.1,3l0.7,4.2c0,0.3-0.1,0.6-0.3,0.7C12.4,15,12.3,15.1,12.1,15.1z M8,11.4c0.1,0,0.2,0,0.3,0.1l2.8,1.5
	l-0.5-3.1c0-0.2,0-0.5,0.2-0.7L13.1,7L10,6.6c-0.2,0-0.5-0.2-0.6-0.4L8,3.4L6.6,6.2C6.5,6.4,6.3,6.5,6,6.6L2.9,7l2.2,2.2
	c0.2,0.2,0.3,0.4,0.2,0.7L4.9,13l2.8-1.5C7.8,11.5,7.9,11.4,8,11.4z"
      />
    </svg>
  );
};
