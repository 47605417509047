import { IMatch } from '../../library/Competitions';
import getBaseUrl from '../getBaseUrl';

export default function getMatchUrl(match: IMatch) {

  if ((match.detailUrl && !match.detailUrl.startsWith('http'))) {
    return `${getBaseUrl()}${match.detailUrl}`;
  }
  return match.detailUrl || '';
}
