import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 2 10"
    height={size}
  >
    <path
      fill={color}
      transform="translate(-787 -653)"
      d="M788,653c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S787.4,653,788,653z M788,657
	c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S787.4,657,788,657z M788,661c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S787.4,661,788,661z"
    />
  </svg>
);
