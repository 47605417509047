import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import AnimatedPanesWrapper from '../../components/AnimatedPanes/AnimatedPanesWrapper';
import Loading from '../../components/Loading';
import SearchInput from '../../components/SearchInput';
import { FullWidthPositionedDropdown } from '../../components/ThirdLevelMenu';
import config from '../../config';
import { RootState } from '../../configureStore';
import LayoutContext from '../../contexts/LayoutContext';
import useDropdownHeight from '../../hooks/useDropdownHeight';
import {
  competitionsCategoryPanesSelector,
  competitionsSelector,
  unionsIsFetchingSelector,
  unionsSelector,
} from '../../pages/FutbalnetApp/selectors';
import styled from '../../theme/styled-components';
import isBrowser from '../../utilities/isBrowser';
import useWindowSize from '../../utilities/useWindowSize';
import TopCompetitionsList from './TopCompetitionsList';

const SearchWrapper = styled.div`
  margin: ${rem(24)} ${rem(16)};
`;

type IOwnProps = {
  xPosition: number;
  active: boolean;
  onClose: () => void;
  omitCompetitions?: boolean;
  inputSearchPlaceholder?: string;
  omitSearch?: boolean;
  union?: string;
};

const mapStateToProps = (state: RootState) => {
  const isLoadingCompetitions =
    isCommiting(config.LIST_COMPETITIONS)(state) !== false &&
    competitionsSelector(state).length === 0;
  const isLoadingUnions =
    unionsIsFetchingSelector(state) !== false &&
    unionsSelector(state).length === 0;
  return {
    panes: competitionsCategoryPanesSelector(state),
    isLoading: isLoadingCompetitions || isLoadingUnions,
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const CompetitionsDropdown: React.FC<IProps> = ({
  active,
  isLoading,
  omitCompetitions,
  inputSearchPlaceholder,
  union,
  omitSearch = false,
}) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [inputFocused, setInputFocused] = React.useState(false);
  const { height, getHeight } = useDropdownHeight(active);
  const resizeHook = useWindowSize();

  const { setIsDropdownActive } = React.useContext(LayoutContext);

  React.useEffect(() => {
    setIsDropdownActive(active);
  }, [active, setIsDropdownActive]);

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const inputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setTimeout(() => {
      setInputFocused(true);
    }, 0);
  };

  React.useEffect(() => {
    if (active) {
      setTimeout(() => {
        inputFocus();
      }, 100);
    }
    return () => {
      setSearchQuery('');
    };
  }, [active]);

  const handleClickOutside = (e: any) => {
    e.stopPropagation();
    if (!inputRef.current?.contains(e.target as Node)) {
      setInputFocused(false);
    }
  };

  if (isBrowser()) {
    document.addEventListener('click', handleClickOutside);
  }

  // recalculate height of dropdown when active or tab changes or is loading
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    const wrapperEl = wrapperRef.current;

    if (active && wrapperEl) {
      getHeight(wrapperEl);
    }
  }, [active, getHeight, isLoading, resizeHook.innerWidth, searchQuery]);

  return (
    <FullWidthPositionedDropdown
      active={active}
      onClose={() => {
        //
      }}
    >
      <AnimatedPanesWrapper
        style={{
          height: searchQuery.length >= 3 ? Number(height) + 40 : height,
        }}
      >
        <div ref={wrapperRef}>
          {isLoading ? (
            <Loading minHeight={rem(212)} />
          ) : (
            <>
              {!omitSearch && (
                <SearchWrapper>
                  <form
                    onSubmit={(e) => {
                      setInputFocused(false);
                      inputRef.current?.blur();
                      e.preventDefault();
                    }}
                  >
                    <SearchInput
                      value={searchQuery}
                      setValue={setSearchQuery}
                      inputFocused={inputFocused}
                      setInputFocused={setInputFocused}
                      inputFocus={inputFocus}
                      inputRef={inputRef}
                      placeholder={inputSearchPlaceholder}
                    />
                  </form>
                </SearchWrapper>
              )}
              <TopCompetitionsList
                recalculateHeight={() => {
                  if (wrapperRef.current) {
                    getHeight(wrapperRef.current);
                  }
                }}
                omitCompetitions={!!omitCompetitions}
                q={searchQuery.length >= 3 ? searchQuery : ''}
                union={union}
                active={active}
              />
            </>
          )}
        </div>
      </AnimatedPanesWrapper>
    </FullWidthPositionedDropdown>
  );
};

export default connect(mapStateToProps)(CompetitionsDropdown);
