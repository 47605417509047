import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M22,15v4c0,1.7-1.3,3-3,3H5c-1.7,0-3-1.3-3-3v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4c0,0.6,0.4,1,1,1h14
    c0.6,0,1-0.4,1-1v-4c0-0.6,0.4-1,1-1S22,14.4,22,15z M7.7,8.7L11,5.4V15c0,0.6,0.4,1,1,1s1-0.4,1-1V5.4l3.3,3.3
    C16.5,8.9,16.7,9,17,9s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-5-5c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.1-0.5-0.1-0.8,0
    c-0.1,0.1-0.2,0.1-0.3,0.2l-5,5c-0.4,0.4-0.4,1,0,1.4S7.3,9.1,7.7,8.7z"
    />
  </svg>
);
