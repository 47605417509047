import { isAfter, isSameDay } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import { getProp } from 'sportnet-utilities';
import Icon from '../../components/Icon';
import Link from '../../components/Link';
import { IMatch } from '../../library/Competitions';
import styled from '../../theme/styled-components';
import formatDate from '../../utilities/formatDate';
import getMatchUrl from '../../utilities/match/getMatchUrl';
import getTeamName from '../../utilities/match/getTeamName';
import __ from '../../utilities/__';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 600;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
`;

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  height: inherit;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #fff;
  transition: background-color 250ms ease-in-out;
  &:hover {
    ${Overlay} {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

const PrimaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
`;

const Team = styled.div`
  display: flex;
  align-items: center;
`;

const TeamName = styled.div`
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  font-weight: 600;
`;

const SportGroundName = styled.div`
  font-size: ${rem(11)};
  line-height: ${rem(15.6)};
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const LiveIndicator = styled.div`
  color: ${({ theme }) => theme.color.danger};
  :before {
    content: '';
    width: ${rem(8)};
    height: ${rem(8)};
    background: ${({ theme }) => theme.color.danger};
    display: inline-block;
    border-radius: 100%;
    margin-right: ${rem(8)};
  }
`;

type IOwnProps = {
  match: IMatch;
};

type IProps = IOwnProps;

const Match: React.FC<IProps> = ({ match }) => {
  const getWinner = () => {
    const sortedResults = getProp(match, ['protocol', 'results'], [])
      .filter((i: any) => (i.points || []).length)
      .sort((a: any, b: any) => {
        const aIndividualResult = (a.points || []).find(
          (point: any) => point && point.name === 'individual',
        );
        const bIndividualResult = (b.points || []).find(
          (point: any) => point && point.name === 'individual',
        );
        if (
          aIndividualResult &&
          bIndividualResult &&
          aIndividualResult.value &&
          bIndividualResult.value &&
          Number(aIndividualResult.value) < Number(bIndividualResult.value)
        ) {
          return -1;
        } else if (
          aIndividualResult &&
          bIndividualResult &&
          aIndividualResult.value &&
          bIndividualResult.value &&
          Number(aIndividualResult.value) > Number(bIndividualResult.value)
        ) {
          return 1;
        } else {
          const aTimeResult = (a.points || []).find(
            (point: any) => point && point.name === 'time',
          );
          const bTimeResult = (b.points || []).find(
            (point: any) => point && point.name === 'time',
          );
          if (
            aTimeResult &&
            bTimeResult &&
            aTimeResult.value < bTimeResult.value
          ) {
            return -1;
          } else if (
            aTimeResult &&
            bTimeResult &&
            aTimeResult.value > bTimeResult.value
          ) {
            return 1;
          } else {
            const aPointsResult = (a.points || []).find(
              (point: any) => point && point.name === 'points',
            );
            const bPointsResult = (b.points || []).find(
              (point: any) => point && point.name === 'points',
            );
            if (
              aPointsResult &&
              bPointsResult &&
              aPointsResult.value < bPointsResult.value
            ) {
              return 1;
            } else if (
              aPointsResult &&
              bPointsResult &&
              aPointsResult.value > bPointsResult.value
            ) {
              return -1;
            }
            return 0;
          }
        }
      });
    return sortedResults[0];
  };

  const isClosed = !!match.closed || getWinner();
  const isLive =
    !match.closed &&
    !getWinner() &&
    match.startDate &&
    isSameDay(new Date(), new Date(match.startDate)) &&
    isAfter(new Date(), new Date(match.startDate));

  const roundName = match.round?.name.startsWith('Matchday')
    ? match.competitionPart.name
    : match.round?.name;

  let winner: null | { label: string; teamId: string } = null;
  let winnerTeam: null | { label: string; detailUrl?: string } = null;
  try {
    const winnerObject = getWinner();
    winner = {
      label: `${winnerObject.players.map((p: any) => p.name).join(', ')}`,
      teamId: winnerObject.teamId,
    };
    const winnerTeamObject = (match.teams || []).find(
      (team) => team._id === winner?.teamId,
    );
    winnerTeam = {
      label: getTeamName(match.appSpace, winnerTeamObject),
      detailUrl: winnerTeamObject?.detailUrl,
    };
  } catch (e: any) {
    //
  }

  return (
    <Wrapper to={getMatchUrl(match)}>
      <PrimaryInfo>
        <Team>
          <TeamName>{roundName}</TeamName>
        </Team>
        <Team>
          <SportGroundName>
            {match.sportGround
              ? `${match.sportGround.sportObjectName} | ${match.sportGround.city}, ${match.sportGround.country}`
              : ''}
          </SportGroundName>
        </Team>
        {!isClosed && !isLive && (
          <Team>
            <SportGroundName>
              {formatDate(new Date(match.startDate), 'dd.MM.yyyy HH:mm')}
            </SportGroundName>
          </Team>
        )}
        {isClosed && winner && (
          <Team>
            <SportGroundName>
              <b>{winner.label}</b> ({winnerTeam?.label})
            </SportGroundName>
          </Team>
        )}
        {isLive && <LiveIndicator>{__('Live')}</LiveIndicator>}
      </PrimaryInfo>
      <Overlay>
        {__('Detail podujatia')}
        <Icon style={{ marginLeft: rem(4) }} name="arrowMore" size={14} />
      </Overlay>
    </Wrapper>
  );
};

export default Match;
