import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../configureStore';
import UrlContext, { IUrlContext } from '../../contexts/UrlContext';
import getArticleUrl from '../../utilities/getArticleUrl';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import getSectionUrl from '../../utilities/getSectionUrl';
import getArticlePagesEditUrl from '../../utilities/getArticlePagesEditUrl';
import { appSettingsSelector } from '../DomainResolver/selectors';
import getSmarttagPageUrl from '../../utilities/smartTags/getSmarttagPageUrl';
import { SmartTagResponse } from '../../api/TagmanagerApi';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const AppUrlContextProvider: React.FC<IProps> = ({ appSettings, children }) => {
  const urlContext: IUrlContext = React.useMemo(() => {
    const baseUrl = appSettings.baseUri || '';
    return {
      getArticleUrl: (a) =>
        getArticleUrl(a.doctype === 'article' ? '/spravy' : '', a),
      getSectionUrl: (s) => getSectionUrl('', s),
      getFullArticleUrl: (a) =>
        getArticleUrl(
          `${baseUrl}${a.doctype === 'article' ? '/spravy' : ''}`,
          a,
        ),
      getFullSectionUrl: (s) => getSectionUrl(baseUrl, s),
      getFullSmarttagPageUrl: (st: SmartTagResponse) =>
        getSmarttagPageUrl(baseUrl, st),
      getAuthorUrl: (a) => getAuthorUrl('/redakcia', a),
      getArticlePagesEditUrl: (s, id) => getArticlePagesEditUrl(s, id),
      getArticleDiscussionUrl: (a) => getArticleUrl(`${baseUrl}/diskusia`, a),
    };
  }, [appSettings.baseUri]);

  return (
    <UrlContext.Provider value={urlContext}>{children}</UrlContext.Provider>
  );
};

export default connect(mapStateToProps)(AppUrlContextProvider);
