import { commit } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import FutbalnetApi, {
  IComiteeDetail,
  ILatestResolutions,
  IResolution,
} from '../../api/FutbalnetApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceComiteeProps from '../../utilities/reduceComiteeProps';
import reduceLatestResolutionProps from '../../utilities/reduceLatestResolutionProps';
import { updateEntities } from '../App/actions';
import { AsyncReturnType } from '../../library/App';

const create = actionCreatorFactory('COMITEES');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const loadCommiteeDetail = createAsync<
  { unionId: string; comiteeId: string },
  IComiteeDetail
>(
  'LOAD_DETAIL',
  async ({ unionId, comiteeId }, dispatch, getState, { FutbalnetApi }) => {
    const comitee = await FutbalnetApi.getComiteeDetail(unionId, comiteeId);
    const { entities } = normalizeEntities(
      'comitees',
      [reduceComiteeProps(comitee)],
      (e) => String(e.id),
    );
    dispatch(updateEntities(entities));
    return comitee;
  },
);

export const loadResolutionDetail = createAsync<
  {
    unionId: string;
    comiteeId: string;
    seasonId: string;
    resolutionNumber: string;
  },
  IResolution
>(
  'LOAD_RESOLUTION_DETAIL',
  async (
    { unionId, comiteeId, seasonId, resolutionNumber },
    dispatch,
    _,
    { FutbalnetApi },
  ) => {
    const resolution = await FutbalnetApi.getResolutionDetail(
      unionId,
      comiteeId,
      seasonId,
      resolutionNumber,
    );
    const { entities } = normalizeEntities(
      'latestResolutions',
      [resolution],
      (e) => String(e.number),
    );
    dispatch(updateEntities(entities));
    return resolution;
  },
);

export const loadSeasonsList = createAsync<
  void,
  AsyncReturnType<typeof FutbalnetApi['getSeasons']>
>(
  'LOAD_SEASONS_LIST',
  async (
    _,
    dispatch,
    __,
    { FutbalnetApi },
  ) => {
    const response = await FutbalnetApi.getSeasons(
      
    );
    const { entities } = normalizeEntities(
      'seasons',
      response,
      (e) => String(e.id),
    );
    dispatch(updateEntities(entities));
    return response
  },
);

export const loadResolutions = createAsync<
  { unionId: string; comiteeId: string; season: string },
  void
>(
  'LOAD_RESOLUTIONS',
  // eslint-disable-next-line
  async ({ unionId, comiteeId, season }, dispatch, _, { FutbalnetApi }) => {
    return dispatch(
      commit.action({
        listName: config.LIST_RESOLUTIONS,
        load: async () => {
          const response = await FutbalnetApi.getLatestResolutions(
            unionId,
            season,
          );

          // iterujem response a najdem zvaz
          if (response && response.length) {
            const comitee = response.find(
              (item: ILatestResolutions) => String(item.id) === comiteeId,
            );
            if (comitee) {
              const latestResolutions: IResolution[] =
                comitee.latestresolutions?.resolutions || [];

              const { entities, results } = normalizeEntities(
                'latestResolutions',
                latestResolutions.map(
                  reduceLatestResolutionProps,
                ) as IResolution[],
                (e) => e.number,
              );

              // dispatch
              dispatch(updateEntities(entities));

              return {
                results,
                total: response.length,
              };
            }
          }
          return {
            results: [],
            total: 0,
          };
        },
      }),
    );
  },
);

export const loadCommiteesWithLatestResolutions = createAsync<
  { unionId: string; season: string },
  void
>(
  'LOAD_COMMITEES_WITH_LATEST_RESOLUTIONS',
  // eslint-disable-next-line
  async ({ unionId, season }, dispatch, _, { FutbalnetApi }) => {
    return dispatch(
      commit.action({
        listName: config.LIST_COMMITEES_WITH_LATEST_RESOLUTIONS,
        load: async () => {
          const response = await FutbalnetApi.getLatestResolutions(
            unionId,
            season,
          );

          const reduced = (response || []).map((item) => {
            const commitee: ILatestResolutions = {
              id: item.id,
              name: item.name,
              latestresolutions: {},
            };
            const resolutions = (item.latestresolutions?.resolutions || []).map(
              (resolution) => {
                return {
                  number: resolution.number,
                  season: resolution.season,
                  title: resolution.title,
                  commision: resolution.commision,
                };
              },
            );
            commitee.latestresolutions.resolutions = resolutions;
            return commitee;
          });

          // iterujem response a najdem zvaz
          const { entities, results } = normalizeEntities(
            'commiteesWithLatestResolutions',
            reduced,
            (e) => e.id.toString(),
          );

          // dispatch
          dispatch(updateEntities(entities));

          return {
            results,
            total: response.length,
          };
        },
      }),
    );
  },
);
