import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M8,0.6C3.9,0.6,0.6,3.9,0.6,8s3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4S12.1,0.6,8,0.6z M8,13.9c-3.3,0-5.9-2.7-5.9-5.9
	S4.7,2.1,8,2.1s5.9,2.7,5.9,5.9S11.3,13.9,8,13.9z M11.4,8c0,0.4-0.3,0.8-0.8,0.8H8.8v1.9c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8
	V8.8H5.3C4.9,8.8,4.6,8.4,4.6,8s0.3-0.8,0.8-0.8h1.9V5.3c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v1.9h1.9C11.1,7.3,11.4,7.6,11.4,8z"
    />
  </svg>
);
