import React from 'react';
export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 4 6"
    xmlSpace="preserve"
  >
    <path fill={color} id="arrow--winner" d="M0,3l4-3v6L0,3z" />
  </svg>
);
