import React from 'react';
import { connect } from 'react-redux';
import AdManager from '.';
import { ReduxConnectProps, RootState } from '../../../configureStore';
import {
  detailLoadedSelector,
  sectionPathByParameterSelector,
} from '../../../pages/App/selectors';
import { getCurrentCompetitionAppSpaceSelector } from '../../../pages/Competition/selectors';
import { currentSectionIdSelector } from '../../../pages/Section/selectors';
import { IAdParams } from '../AdProvider';

type IOwnProps = Omit<IAdParams, 'origin' | 'rubric' | 'subrubric'>;

const mapStateToProps = (state: RootState) => {
  const currentAppSpace = getCurrentCompetitionAppSpaceSelector(state);
  const sectionId = currentSectionIdSelector(state);
  const params = { appSpace: currentAppSpace };
  return {
    path: sectionPathByParameterSelector(state, sectionId || 0, params) || [],
    pathLoaded: detailLoadedSelector('sectionTreeByParameters', params)(state),
  };
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReduxConnectProps;

const FutbalnetCMSAdManager: React.FC<IProps> = ({
  children,
  path,
  pathLoaded,
  dispatch,
  ...props
}) => {
  if (!pathLoaded) {
    return null;
  }

  const rubric = path[1]?.name || undefined;
  const subrubric = path[2]?.name || undefined;

  return (
    <AdManager
      origin="futbalnet"
      rubric={rubric}
      subrubric={subrubric}
      {...props}
    />
  );
};

export default connect(mapStateToProps)(FutbalnetCMSAdManager);
