import React from 'react';
import { RouteComponentProps } from 'react-router';
import {  useQ } from '../../../query';
import getMatch from './features/getMatchDetail';
import {
  EmptyFooter,
  EmptyHeader,
  InfoMessage,
  Table,
} from '../CompetitionMatchesPrint/RenderPrintData';
import {
  CompetitionNameLoader,
  Footer,
  H1,
  Header,
  Wrapper,
} from '../CompetitionMatchesPrint';
import MatchDetailPrintHeader from './MatchDetailPrintHeader';
import __ from '../../../utilities/__';
import MatchesDetailPrintSectionTitle from './MatchesDetailPrintSectionTitle';
import MatchDetailPrintMatchEvent from './MatchDetailPrintMatchEvent';
import { Icon } from '../../Match/Overview/MatchTimeLine';
import { useTheme } from 'styled-components';
import { Theme } from '@sportnet/ui/lib/Themes/styled-components';
import MatchDetailPrintNominations from './MatchDetailPrintNominations';
import MatchDetailPrintCrew from './MatchDetailPrintCrew';
import { getRandomString } from '@sportnet/content/utilities/utilities';
import getSportSectorCrew from './features/getSportSectorCrew';
import getSportSectorDelegates from './features/getSportSectorDelegates';
import MatchDetailPrintDelegates from './MatchDetailPrintDelegates';
import MatchDetailPrintStadium from './MatchDetailPrintStadium';
import FooterLogos from '../CompetitionMatchesPrint/FooterLogos';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';
import getBaseUrl from '../../../utilities/getBaseUrl';
import FutbalnetPrintQrCode from '../FutbalnetPrintQrCode';
import { Helmet } from 'react-helmet-async';

type RouterProps = RouteComponentProps<
  {
    zvaz: string;
    matchId: string;
  },
  void
>;

const MatchDetailPrint: React.FC<RouterProps> = ({
  params: { zvaz, matchId },
}) => {

  const theme = useTheme() as unknown as Theme['theme'];

  // detail zapasu
  const {
    data: match,
    isError: isMatchError,
    isLoading: isMatchLoading,
  } = useQ(
    getMatch,
    { matchId },
    { enabled: !!matchId, refetchOnWindowFocus: false },
  );

  // ciselnik crew
  const {
    data: sportSectorCrew,
    isLoading: isSportSectorCrewLoading,
    isError: isSportSectorCrewError,
  } = useQ(
    getSportSectorCrew,
    { sportSector: match?.sportSector! },
    {
      enabled: Boolean(match?.sportSector),
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  // ciselnik delegatov
  const {
    data: sportSectorDelegates,
    isLoading: isSportSectorDelegatesLoading,
    isError: isSportSectorDelegatesError,
  } = useQ(
    getSportSectorDelegates,
    { sportSector: match?.sportSector! },
    {
      enabled: Boolean(match?.sportSector),
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  const qrCodeUrl = `${getBaseUrl()}/futbalnet/z/${zvaz}/zapas/${matchId}`;

  const showPrintDialog = !isSportSectorCrewLoading && !isSportSectorCrewLoading && !isSportSectorDelegatesLoading;

  React.useEffect(() => {
    if (showPrintDialog) {
      // nechajme 100ms pre render
      window.setTimeout(() => {
        window.print();
      }, 100);
    }
  }, [showPrintDialog]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex nofollow" />
      </Helmet>
      {isMatchError || isSportSectorCrewError || isSportSectorDelegatesError ? (
        <InfoMessage
          text={`${__('Pri získavaní dát pre tlač sa vyskytla chyba!')}`}
        />
      ) : isMatchLoading ||
        isSportSectorCrewLoading ||
        isSportSectorDelegatesLoading ? (
        <CompetitionNameLoader className={SKELETON_CLASS_NAME} />
      ) : match && sportSectorCrew && sportSectorDelegates ? (
        <Wrapper>
          <Header>
            <H1>
              {match.competition.competitionName} -{' '}
              {match.competition.seasonName}, {match.competition.roundName}
            </H1>
            <FutbalnetPrintQrCode url={qrCodeUrl} />
          </Header>
          <Table>
            <EmptyHeader>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            </EmptyHeader>
            <tbody>
              <MatchDetailPrintHeader
                dateFrom={match.dateFrom ? new Date(match.dateFrom) : null}
                homeTeam={match.homeTeam}
                awayTeam={match.awayTeam}
                scoreByPhases={match.scoreByPhases}
                scoreNote={match.scoreNote}
                audience={match.audience}
                sportGround={match.sportGround}
                isFutureMatch={match.isFutureMatch}
                isContumated={match.isContumated}
              />
              {match.shouldRenderMatchOverview && (
                <>
                  <MatchesDetailPrintSectionTitle
                    icon="soccer-ball"
                    title={__('Prehľad')}
                  />
                  {match.phasesWithEvents?.map((phase) => {
                    return phase.events.map((event) => {
                      return (
                        <MatchDetailPrintMatchEvent
                          key={`match-detail-event-${event.phase}-${
                            event.eventTime
                          }-${event.eventType}-${
                            event.player?._id ?? getRandomString(4)
                          }`}
                          teamSide={event.homeTeam ? 'homeTeam' : 'awayTeam'}
                          playerName={
                            event.player?.name || event.crewMember?.name || ''
                          }
                          icon={
                            <Icon
                              title={phase ? phase.label : ''}
                              eventType={event.eventType}
                              subType={event.type ?? undefined}
                              theme={theme}
                              size={16}
                            />
                          }
                          minute={Math.floor(event.eventTime / 60)}
                        />
                      );
                    });
                  })}
                </>
              )}
              <MatchesDetailPrintSectionTitle
                icon="run"
                title={__('Nasadení')}
              />
              <MatchDetailPrintNominations
                homeTeamPersons={match.homeTeamNomination.athletes}
                awayTeamPersons={match.awayTeamNomination.athletes}
              />
              <MatchesDetailPrintSectionTitle
                icon="event-seat"
                title={__('Lavička')}
              />
              <MatchDetailPrintNominations
                homeTeamPersons={match.homeTeamNomination.substitutes}
                awayTeamPersons={match.awayTeamNomination.substitutes}
              />
              <MatchesDetailPrintSectionTitle
                icon="groups"
                title={__('Realizačný tím')}
              />
              <MatchDetailPrintCrew
                sportSectorCrew={sportSectorCrew}
                homeTeamCrew={match.homeTeamNomination.crew}
                awayTeamCrew={match.awayTeamNomination.crew}
              />
              <MatchesDetailPrintSectionTitle
                icon="whistle"
                title={__('Delegované osoby')}
              />
              <MatchDetailPrintDelegates
                sportSectorDelegates={sportSectorDelegates}
                matchDetailManagers={match.managers ?? []}
              />
              <MatchesDetailPrintSectionTitle
                icon="stadium-print"
                title={__('Štadión')}
              />
              <MatchDetailPrintStadium sportGround={match.sportGround} />
            </tbody>
            <EmptyFooter>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
            </EmptyFooter>
          </Table>
          <Footer>
            <FooterLogos />
          </Footer>
        </Wrapper>
      ) : (
        <InfoMessage
          text={`${__('Pri získavaní dát pre tlač sa vyskytla chyba!')}`}
        />
      )}
    </>
  );
};

export default MatchDetailPrint;
