import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M10,12.8c-0.2,0-0.4-0.1-0.5-0.2l-4-4c-0.3-0.3-0.3-0.8,0-1.1l4-4c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L7.1,8
      l3.5,3.5c0.3,0.3,0.3,0.8,0,1.1C10.4,12.7,10.2,12.8,10,12.8z"
    />
  </svg>
);
