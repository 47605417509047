import { MatchsPanelMatchTeamProps } from '../../types/MatchesPanel';
import { rem } from 'polished';
import React from 'react';
import Team, { Text } from '../Team';
import styled from 'styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import CustomImage from '../../CustomImage';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family};
  color: ${({ theme }) => theme.color.primaryFontColor};
  font-size: ${rem(12)};
  width: 100%;
`;

const TeamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
`;

const MobileInfoWrapper = styled.div`
  font-size: ${rem(11)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(8)};
  ${mb('s')} {
    display: none;
  }
`;

const TextSmallMobile = styled.div`
  display: inline-block;
  ${mb(400)} {
    display: none;
  }
`;

const TextWrapper = styled.div`
  display: none;
  ${mb(400)} {
    display: inline-block;
  }
`;

const TvProviewrLogoWrapper = styled.div`
  height: ${rem(12)};
`;

const TvProviderWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.lightBorder};
  margin-top: ${rem(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(6)};
  padding-top: ${rem(8)};
`;

interface OwnProps {
  className?: string;
  homeTeam: MatchsPanelMatchTeamProps;
  homeTeamIsWinning: boolean;
  awayTeam: MatchsPanelMatchTeamProps;
  awayTeamIsWinning: boolean;
  isClosed: boolean;
  isLive: boolean;
  isFuture: boolean;
  roundName: string;
  roundNameMobile: string;
  /**
   * Skratena verzia kola pre zapaas, ktory nie je uzavrety
   * a rozlisenie je mensie ako 400px.
   */
  roundNameShort: string;
  dateOrMatchPhase: string;
  dateOrMatchPhaseMobile: string;
  televisionBroadcasters?: {
    _id: string;
    label: string;
    logo_url: string;
  }[];
}

export const Match: React.FC<OwnProps> = ({
  className,
  homeTeam,
  homeTeamIsWinning,
  awayTeam,
  awayTeamIsWinning,
  isLive,
  isFuture,
  isClosed,
  dateOrMatchPhase,
  dateOrMatchPhaseMobile,
  roundName,
  roundNameMobile,
  roundNameShort,
  televisionBroadcasters
}) => {
  return (
    <Wrapper className={className}>
      <MobileInfoWrapper>
        <Text $color={'faded'}>{dateOrMatchPhaseMobile}</Text>
        <TextSmallMobile>
          <Text $color={isLive ? 'red' : 'faded'}>{roundNameShort}</Text>
        </TextSmallMobile>
        <TextWrapper>
          <Text $color={isLive ? 'red' : 'faded'}>{roundNameMobile}</Text>
        </TextWrapper>
      </MobileInfoWrapper>
      <TeamsWrapper>
        <Team
          teamName={homeTeam.teamName}
          teamNameBold={homeTeamIsWinning}
          teamLogoSrc={homeTeam.teamLogoSrc}
          teamLogoAlt={homeTeam.teamLogoAlt}
          score={homeTeam.score}
          scoreColor={isLive ? 'red' : 'normal'}
          scoreNote={homeTeam.scoreNote}
          scoreWeightBold={homeTeamIsWinning}
          shouldRenderScore={!isFuture}
          text={dateOrMatchPhase}
          textColor={isLive ? 'red' : 'faded'}
          textAtFirstPosition={isClosed}
          matchHasScoreNote={!!homeTeam.scoreNote || !!awayTeam.scoreNote}
        />
        <Team
          teamName={awayTeam.teamName}
          teamNameBold={awayTeamIsWinning}
          teamLogoSrc={awayTeam.teamLogoSrc}
          teamLogoAlt={awayTeam.teamLogoAlt}
          score={awayTeam.score}
          scoreColor={isLive ? 'red' : 'normal'}
          scoreNote={awayTeam.scoreNote}
          scoreWeightBold={awayTeamIsWinning}
          shouldRenderScore={!isFuture}
          text={roundName}
          textColor={'faded'}
          textAtFirstPosition={isClosed}
          matchHasScoreNote={!!homeTeam.scoreNote || !!awayTeam.scoreNote}
        />
      </TeamsWrapper>
      {Array.isArray(televisionBroadcasters) &&
        televisionBroadcasters.length > 0 && (
          <TvProviderWrapper>
            {televisionBroadcasters?.map((televisionBroadcaster) => (
              <TvProviewrLogoWrapper
                key={`broadcaster-logo-${televisionBroadcaster._id}`}
              >
                <CustomImage
                  src={televisionBroadcaster.logo_url}
                  height={'100%'}
                  alt={televisionBroadcaster.label}
                  width="auto"
                />
              </TvProviewrLogoWrapper>
            ))}
          </TvProviderWrapper>
        )}
    </Wrapper>
  );
};
