import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M3,22c-0.1,0-0.3,0-0.4-0.1C2.2,21.8,2,21.4,2,21V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v10c0,1.7-1.3,3-3,3H7.4
    l-3.7,3.7C3.5,21.9,3.3,22,3,22z M5,4C4.4,4,4,4.4,4,5v13.6l2.3-2.3C6.5,16.1,6.7,16,7,16h12c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H5z"
    />
  </svg>
);
