import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M8,6v13.1c0,3.7,2.2,4.9,6.8,7.2c0.3,0.2,0.7,0.3,1,0.5c0.1,0,0.2-0.1,0.3-0.1c2.5-1.1,4.5-2.1,5.8-3.2
c0.8-0.8,1.7-1.9,1.7-4.3l0-13.1H8 M7.1,4h17.5c0.5,0,1,0.3,1,1.1v14c0,5.8-3.9,7.2-9.8,9.9C10.6,26.4,6,24.9,6,19.1v-14
C6,4.3,6.4,4,7.1,4z"
    />
  </svg>
);
