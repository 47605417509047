import styled from 'styled-components';
import SocialIcon, { ISocialIconName } from '../Social/SocialIcon';
import { rem } from 'polished';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  gap: ${rem(4)};
  margin-top: ${rem(12)};
`;

interface OwnProps {
  social: {
    [key: string]: {
      url: string;
    };
  };
}

const OrgProfileSocialNetworks: React.FC<OwnProps> = ({ social }) => {
  const socialData = Object.entries(social).reduce<
    { name: ISocialIconName; url: string }[]
  >((acc, [name, props]) => {
    if (props.url) {
      acc.push({
        name: name as ISocialIconName,
        url: props.url,
      });
    }
    return acc;
  }, []);

  return socialData.length > 0 ? (
    <Wrapper>
      {socialData.map((social) => (
        <a href={social.url} target="_blank" rel="noopener noreferrer">
          <SocialIcon name={social.name} variant="dark" size={24} />
        </a>
      ))}
    </Wrapper>
  ) : null;
};

export default OrgProfileSocialNetworks;
