import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M21,3H3C1.3,3,0,4.3,0,6v12c0,1.7,1.3,3,3,3h18c1.7,0,3-1.3,3-3V6C24,4.3,22.7,3,21,3z M3,5h18c0.6,0,1,0.4,1,1
    v3H2V6C2,5.4,2.4,5,3,5z M21,19H3c-0.6,0-1-0.4-1-1v-7h20v7C22,18.6,21.6,19,21,19z"
    />
  </svg>
);
