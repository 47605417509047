import { __ } from '@sportnet/content/utilities/utilities';
import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import format from 'date-fns/format';
import sk from 'date-fns/locale/sk';
import { rem } from 'polished';
import useQuery, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps, RouteProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import AdManager from '../../components/Ads/AdManager';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import { IMenuItem } from '../../components/FutbalnetHeader/Menu/types';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import MatchPreview from '../../components/MatchesSlider/MatchPreview';
import MaxWidthBox from '../../components/MaxWidthBox';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import RempIntegration from '../../containers/RempIntegration';
import pageViewWithDimensions from '../../library/pageViewWithDimensions';
import styled, { css, withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import cleanRoundName from '../../utilities/cleanRoundName';
import getMatchBaseUrl from '../../utilities/getMatchBaseUrl';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import {
  allSportSectorEventsSelector,
  allSportSectorPhasesSelector,
  allSportSectorSettingsSelector,
} from '../App/selectors';
import {
  loadCompetitionItem,
  loadOrganizationProfileItem,
} from '../Competition/actions';
import {
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../Competition/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import { currentMatchSelector } from './selectors';
import TabPages from './TabPages';
import isVideoSrcAllowed from '../../utilities/match/isVideoSrcAllowed';
import { useAsyncData } from 'ssr-service';
import { useQ } from '../../query';
import loadNikeEventsForFutbalnet from '../../query-fns/loadNikeEventsForFutbalnet';
import getThirdPartyEventBySportnetMatch from '../../utilities/thirdPartyResults/getThirdPartyEventBySportnetMatch';
import { IMatch } from '../../library/Competitions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import config from '../../config';

const TopIcons = css`
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.app.textColor};
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
`;

const SportGround = styled.div`
  display: none;
  ${mb('s')} {
    display: flex;
    ${TopIcons};
    span {
      margin-right: ${rem(8)};
    }
  }
`;

const MatchDate = styled.div`
  display: flex;
  ${TopIcons};
  ${mb('s')} {
    margin-left: ${rem(16)};
  }
  span {
    margin-right: ${rem(8)};
  }
`;

const PageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
  margin: 0 0 ${rem(24)} 0;
`;

const MatchInfo = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
`;

const StatusIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const MatchPreviewWrapper = styled.div`
  margin-top: ${rem(40)};
  margin-bottom: ${rem(32)};
  position: relative;
`;

const CompetitionLogo = styled.div`
  position: absolute;
  pointer-events: none;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0.05;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  > img {
    height: 100%;
    width: auto;
  }
`;

const mapStateToProps = (state: RootState) => ({
  organizationProfile: currentOrganizationProfileSelector(state),
  match: currentMatchSelector(state),
  competition: currentCompetitionSelector(state),
  allEventTypes: allSportSectorEventsSelector(state),
  allPhases: allSportSectorPhasesSelector(state),
  allSettings: allSportSectorSettingsSelector(state),
  appSettings: appSettingsSelector(state),
});

const mapDispatchToProps = {
  loadCompetitionItem: thunkToAction(loadCompetitionItem.action),
  loadOrganizationProfileItem: thunkToAction(
    loadOrganizationProfileItem.action,
  ),
};

type Props = { theme: ITheme; children: React.ReactNode } & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  RouteComponentProps<{ zvaz: string; zapas: string }, {}> &
  QueryHocInterface;

const MatchDetail: React.FC<Props> = ({
  appSettings,
  match,
  allPhases,
  competition,
  location: { pathname, hash },
  organizationProfile,
  loadCompetitionItem,
  loadOrganizationProfileItem,
  params: { zvaz, zapas },
  children,
}) => {
  const { data: thirdPartyResultsData } = useQ(
    loadNikeEventsForFutbalnet,
    { segment: 'all' },
    { enabled: true },
  );

  const thirdPartyEvent = getThirdPartyEventBySportnetMatch(
    match as IMatch | undefined,
    thirdPartyResultsData,
  );

  useAsyncData(async () => {
    await Promise.all([loadCompetitionItem(), loadOrganizationProfileItem({})]);
  }, []);

  const part = (competition?.parts || []).find(
    ({ _id }) => _id === match?.competitionPart._id,
  );
  const unionName = organizationProfile?.name;
  const seasonName = competition?.season?.name || match?.season.name;
  const competitionLevel = competition?.level;
  const competitionCategory = part?.rules?.category;
  const competitionGender = part?.rules?.gender;
  const competitionName = competition?.name;

  React.useEffect(() => {
    pageViewWithDimensions({
      unionName,
      seasonName,
      competitionLevel,
      competitionCategory,
      competitionGender,
      competitionName,
    });
  }, [
    unionName,
    seasonName,
    competitionLevel,
    competitionCategory,
    competitionGender,
    competitionName,
  ]);

  const showTabulka = !(config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
    competition?._id ?? '',
  )

  const menuItems: IMenuItem[] = [
    {
      id: 'overview',
      label: __('Prehľad zápasu'),
      url: `${getMatchBaseUrl({ zvaz, zapas })}`,
    },
    ...(match?.livestream && isVideoSrcAllowed(match?.livestream)
      ? [
          {
            id: 'live',
            label: () => (
              <>
                <StatusIcon name="circle" size={4} color={'#f00'} />
                {__('Live')}
              </>
            ),
            url: `${getMatchBaseUrl({ zvaz, zapas })}live/`,
          },
        ]
      : []),
    {
      id: 'nomination',
      label: __('Zostavy'),
      url: `${getMatchBaseUrl({ zvaz, zapas })}#zostavy`,
      hash: '#zostavy',
    },
    {
      id: 'video',
      label: __('Videozáznam'),
      url: `${getMatchBaseUrl({ zvaz, zapas })}videozaznam/`,
    },
    {
      id: 'h2h',
      label: __('H2H'),
      url: `${getMatchBaseUrl({ zvaz, zapas })}#h2h`,
      hash: '#h2h',
    },
    ...(showTabulka
      ? [
          {
            id: 'table',
            label: __('Tabuľka'),
            url: `${getMatchBaseUrl({ zvaz, zapas })}#tabulka`,
            hash: '#tabulka',
          },
        ]
      : []),
  ];

  const roundName = match?.round && `${cleanRoundName(match?.round.name)}`;

  const teamA = getProp(match, ['teams', 0, 'organization', 'name']);
  const teamB = getProp(match, ['teams', 1, 'organization', 'name']);

  const title = `${teamA} - ${teamB} | ${competition?.name}${
    !!roundName && ` ${roundName}`
  }`;

  const orgLogoSrc = organizationProfile
    ? getPpoLogoSrc(organizationProfile as Required<typeof organizationProfile>)
    : '';

  const siteId = `${pathname}${hash}`;

  const competitionPart = (competition?.parts || []).find(
    ({ _id }) => _id === match?.competitionPart._id,
  );

  const description = `${__(
    'Pozrite si detaily k zápasu',
  )} ${teamA} vs. ${teamB}, ${match?.competition.name}. ${__(
    'Pozrite si výsledky, strelcov gólov, zostavu.',
  )}`;

  if (!organizationProfile || !match || !competition) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          property="og:url"
          content={`${appSettings.baseUri}${getMatchBaseUrl({
            zvaz,
            zapas: match.__issfId || match._id,
          })}`}
        />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {!!orgLogoSrc && <meta property="og:image" content={orgLogoSrc} />}
      </Helmet>

      <RempIntegration destroy />

      <FutbalnetHomeHeader />

      <FutbalnetHeader>
        <PageHeader>
          <FutbalnetTitleBlock>
            <MatchInfo>
              <SportGround>
                <Icon name="stadium" />
                {getProp(match, ['sportGround', 'name'])}
              </SportGround>
              {match.startDate && (
                <MatchDate>
                  <Icon name="calendar-match" />
                  {format(new Date(match.startDate), 'd. MMMM yyyy HH:mm', {
                    locale: sk,
                  })}
                </MatchDate>
              )}
            </MatchInfo>
          </FutbalnetTitleBlock>
        </PageHeader>

        <MaxWidthBox>
          <MatchPreviewWrapper>
            {competition.publication?.logo && (
              <CompetitionLogo>
                <MediaManagerImage
                  naturalHeight={200}
                  naturalWidth={0}
                  picture={competition.publication.logo}
                  webp={true}
                  loading="lazy"
                />
              </CompetitionLogo>
            )}
            {match.rules && (
              <MatchPreview
                view="full"
                match={match}
                phases={allPhases[match.rules.sport_sector]}
                thirdPartyEvent={thirdPartyEvent}
                withThirdPartyEvent
              />
            )}
          </MatchPreviewWrapper>
        </MaxWidthBox>

        <FutbalnetMenu items={menuItems} />
      </FutbalnetHeader>

      <AdManager
        site="futbalnet_zapas"
        siteId={siteId}
        origin="futbalnet"
        pagetype="table"
        unionName={organizationProfile.name}
        seasonName={competition.season?.name}
        competitionLevel={competition.level?.toString()}
        competitionCategory={competitionPart?.rules?.category}
        competitionGender={competitionPart?.rules?.gender}
        competitionName={competition.name}
      />

      <TabPages showTabulka={showTabulka} />

      {children}
    </>
  );
};

export default compose(
  withRouter,
  withTheme,
  useQuery({
    parameters: {
      view: { type: QueryHocTypes.String },
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(MatchDetail);
