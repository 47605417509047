export const getTeamLogoAlt = (
  appSace: string,
  teamName: string,
  competitionName: string
) => {
  if (appSace === 'iihf' && teamName && competitionName) {
    return `${teamName} na ${competitionName}`;
  }
  return teamName;
};
