import React from 'react';
import styled, { useTheme } from 'styled-components';
import GossipListItem from './GossipListItem';
import GossipLoader from './GossipLoader';
import { rem } from 'polished';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import __ from '../../utilities/__';
import { ITheme } from '../../theme/theme';
import { FeedItemWithCustomSmarttags } from './features/getFeedItemsWithBanners';
import { SKELETON_CLASS_NAME } from '../../components/v2/SkeletonLoader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

export const GossipsListContentContainer = styled.div`
  border-radius: 0;
  border: none;
  box-shadow: none;
  overflow: visible;
  > a:not(:last-child) > div,
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  }
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  ${mb('s')} {
    border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
    border: 1px solid ${({ theme }) => theme.color.darkBorder};
    box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
  }
`;

const ListItemWrapper = styled.div`
  &:first-child > div,
  span {
    border-top-right-radius: ${({ theme }) => rem(theme.app.borderRadius)};
    border-top-left-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  }

  &:last-child > div {
    border-bottom-right-radius: ${({ theme }) => rem(theme.app.borderRadius)};
    border-bottom-left-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  }
`;

const LoadMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-size: ${rem(13)};
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  line-height: 1.3;
  cursor: pointer;
  color: #1d81c4;
  border: none;
  outline: none;
  padding: ${rem(24)} 0;
  margin: 0 auto;
  background-color: transparent;
  &:hover {
    text-decoration: underline;
  }
`;

const NewMessagesButtonWrapper = styled.span`
  display: block;
  position: relative;
  border: none;
`;

const NewMessagesButton = styled(Button)`
  border-radius: ${rem(40)};
  min-height: ${rem(40)};
  padding: 0 ${rem(14)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(8)};
  font-size: ${rem(13)};
  font-family: ${({ theme }) => theme.primaryFont.family};
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
`;

type Props = {
  gossips: FeedItemWithCustomSmarttags[];
  isFetchingNextPage?: boolean;
  onLoadMoreClick?: VoidFunction;
  newMessagesCount?: number;
  onNewMessagesButtonClick?: VoidFunction;
  fixedCount?: number;
};

const GossipsList: React.FC<Props> = ({
  gossips,
  isFetchingNextPage,
  onLoadMoreClick,
  newMessagesCount = 1,
  onNewMessagesButtonClick,
  fixedCount,
}) => {
  const theme = useTheme() as ITheme;

  const newMessagesText =
    newMessagesCount === 1
      ? 'nová správa'
      : newMessagesCount < 5
      ? 'nové správy'
      : 'nových správ';

  const firstThatIsNotPinned = fixedCount !== undefined ? fixedCount - 1 : 0;

  return (
    <GossipsListContentContainer>
      {gossips.map((gossip, index) => (
        <React.Fragment key={`gossip-${gossip._id}`}>
          {index === firstThatIsNotPinned && newMessagesCount > 0 && (
            <NewMessagesButtonWrapper>
              <NewMessagesButton primary onClick={onNewMessagesButtonClick}>
                <Icon name="refresh" color={theme.color.white} />
                {newMessagesCount} {__(newMessagesText)}
              </NewMessagesButton>
            </NewMessagesButtonWrapper>
          )}
          <ListItemWrapper key={gossip._id}>
            <GossipListItem
              isPinned={
                fixedCount !== undefined ? index < fixedCount - 1 : false
              }
              title={gossip.title}
              date={gossip.date}
              description={
                gossip.extendedDescriptionExcerpt ?? gossip.description
              }
              smarttags={gossip.smarttags}
              _id={gossip._id}
              discussionPostCount={gossip.discussionPostCount}
              authors={gossip.authors}
            />
          </ListItemWrapper>
        </React.Fragment>
      ))}
      {onLoadMoreClick &&
        (isFetchingNextPage ? (
          <>
            <GossipLoader skeletonClassName={SKELETON_CLASS_NAME} />
            <GossipLoader skeletonClassName={SKELETON_CLASS_NAME} />
            <GossipLoader skeletonClassName={SKELETON_CLASS_NAME} />
          </>
        ) : (
          <LoadMoreButton onClick={onLoadMoreClick}>
            Načítať ďalšie
          </LoadMoreButton>
        ))}
    </GossipsListContentContainer>
  );
};

export default GossipsList;
