import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../query';
import CoreApi from '../../api/CoreApi';
import {
  ListTransfersResponse,
  TransfersFilter,
} from '../../library/Transfermarket';
import format from 'date-fns/format';

interface ListTransfersParams extends TransfersFilter {
  offset?: number;
  limit?: number;
  groupByDay?: boolean;
}

type ListTransfersQueryParams = Omit<
  TransfersFilter,
  'dateFrom' | 'dateTo' | 'transferType'
> & {
  limit: number;
  offset: number;
  dateFrom?: string;
  dateTo?: string;
  transferType?: 'hosting' | 'transfer';
};

const listTransfers = createQueryFn(
  QueryNameSpace.transfers,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string] | [string, ListTransfersParams]>) => {
    const [, parameters] = queryKey;

    const params: ListTransfersQueryParams = {
      offset: pageParam || 0,
      limit: 30,
    };

    const groupByDay = parameters?.groupByDay ?? false;

    if (parameters) {
      if (parameters.transferType && parameters.transferType !== 'all') {
        params.transferType = parameters.transferType;
      }

      if (parameters.ppo) {
        params.ppo = parameters.ppo;
      }

      if (parameters.ppoDir) {
        params.ppoDir = parameters.ppoDir;
      }

      if (parameters.age) {
        params.age = parameters.age;
      }

      if (parameters.gender) {
        params.gender = parameters.gender;
      }

      if (parameters.dateFrom) {
        params.dateFrom = format(parameters.dateFrom, 'yyyy-MM-dd');
      }

      if (parameters.dateTo) {
        params.dateTo = format(parameters.dateTo, 'yyyy-MM-dd');
      }

      if (parameters.limit || parameters.limit === 0) {
        params.limit = parameters.limit;
      }

      if (parameters.offset || parameters.offset === 0) {
        params.offset = parameters.offset;
      }
    }

    const {
      transfers,
      limit,
      offset,
      next_offset: nextOffset,
    } = (await CoreApi.listTransfers(
      'futbalsfz.sk',
      'sport:futbal:futbal',
      params
    )) as ListTransfersResponse;

    if (groupByDay) {
      // zgrupujeme transfery po dnoch
      const transfersByDay = (transfers || []).reduce((acc, transfer) => {
        const { date_from } = transfer.transfer;
        if (date_from in acc) {
          acc[date_from].push(transfer);
        } else {
          acc[date_from] = [transfer];
        }
        return acc;
      }, {} as { [key: string]: ListTransfersResponse['transfers'] });

      return {
        limit,
        offset,
        total: transfers.length,
        next_offset: nextOffset,
        transfersByDay,
        transfers: [] as ListTransfersResponse['transfers']
      };
    } else {
      return {
        limit,
        offset,
        total: transfers.length,
        next_offset: nextOffset,
        transfers,
        transfersByDay: []
      };
    }
  }
);

export type { ListTransfersParams };
export default listTransfers;
