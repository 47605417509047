import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M12.9,15.6c-0.1,0-0.1,0-0.2,0c-2.1-0.2-4.2-1-6-2.1C5,12.4,3.6,10.9,2.5,9.2c-1.2-1.8-1.9-3.9-2.1-6c0-0.5,0.1-1,0.4-1.4
	C1.2,1.3,1.6,1.1,2.2,1c0.1,0,0.1,0,0.2,0h2c0.9,0,1.8,0.7,2,1.7c0.1,0.6,0.2,1.2,0.4,1.7C7,5.2,6.8,6,6.3,6.5L5.8,7
	c0.8,1.3,1.9,2.3,3.1,3.1l0.5-0.5c0.5-0.5,1.4-0.7,2.1-0.4c0.6,0.2,1.1,0.4,1.7,0.4c1,0.1,1.7,1,1.7,2c0,0,0,0,0,0.1v1.9
	c0,0.5-0.2,1-0.6,1.4C13.9,15.4,13.5,15.6,12.9,15.6C12.9,15.6,12.9,15.6,12.9,15.6z M4.3,2.3C4.3,2.3,4.3,2.3,4.3,2.3h-2
	c-0.5,0-0.7,0.4-0.7,0.8C1.8,5,2.5,6.9,3.6,8.6c1,1.5,2.3,2.8,3.8,3.8c1.6,1.1,3.5,1.7,5.5,2c0,0,0,0,0,0c0.2,0,0.4-0.1,0.6-0.2
	c0.1-0.1,0.2-0.3,0.2-0.5v-2c0,0,0,0,0-0.1c0-0.3-0.3-0.6-0.6-0.7c-0.7-0.1-1.4-0.3-2-0.5c-0.3-0.1-0.6,0-0.7,0.2l-0.8,0.8
	c-0.2,0.2-0.5,0.2-0.8,0.1c-1.8-1-3.2-2.5-4.2-4.2C4.3,7,4.3,6.7,4.5,6.5l0.8-0.8c0.2-0.2,0.3-0.5,0.2-0.7C5.3,4.3,5.1,3.6,5,2.9
	C5,2.6,4.7,2.3,4.3,2.3z M12.3,6.9c-0.3,0-0.6-0.2-0.6-0.5c-0.2-1.1-1.1-1.9-2.1-2.1C9.2,4.2,8.9,3.9,9,3.5C9.1,3.2,9.4,3,9.7,3.1
	c1.6,0.3,2.8,1.5,3.1,3.1c0.1,0.3-0.2,0.7-0.5,0.7C12.3,6.9,12.3,6.9,12.3,6.9z M14.9,6.9c-0.3,0-0.6-0.2-0.6-0.6
	C14,3.9,12.1,1.9,9.6,1.6C9.2,1.6,9,1.3,9,0.9c0-0.3,0.3-0.6,0.7-0.6c3.1,0.3,5.5,2.7,5.9,5.8C15.6,6.6,15.3,6.9,14.9,6.9
	C15,6.9,14.9,6.9,14.9,6.9z"
    />
  </svg>
);
