import React from 'react';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from '../../theme/styled-components';

const Wrapper = styled.div`
  position: relative;
  padding: ${rem(16)};
  border: none;
  display: flex;
  background-color: white;
  margin-left: ${rem(24)};
  width: 100%;
  flex-direction: column;
  ${mb('s')} {
    flex-direction: row;
    align-items: center;
  }
`;

const PlayerPhoto = styled.div`
  flex: 0 0 auto;
  width: ${rem(24)};
  height: ${rem(24)};
  border-radius: 100%;
  margin-right: ${rem(8)};
  font-size: ${rem(9)};
  object-fit: cover;
  background-color: ${({ theme }) => theme.color.lightBorder};
  ${mb('s')} {
    width: ${rem(40)};
    height: ${rem(40)};
    margin-right: ${rem(16)};
  }
`;

const PlayerName = styled.div`
  height: ${rem(34)};
  width: ${rem(140)};
  font-size: ${rem(12)};
  font-weight: bold;
  margin-right: ${rem(48)};
  background-color: ${({ theme }) => theme.color.lightBorder};
`;

const PlayerDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
  flex: 0 0 auto;
  ${mb('s')} {
    margin-bottom: 0;
    width: ${rem(250)};
  }
`;

const PpoDesktopWrapper = styled.div`
  display: flex;
  ${mb('s')} {
    display: flex;
  }
`;

const PpoName = styled.div`
  font-size: ${rem(12)};
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${({ theme }) => theme.color.lightBorder};
  height: ${rem(17)};
  width: ${rem(100)};
`;

const TransferTypeLabel = styled.div`
  position: absolute;
  width: ${rem(24)};
  height: 100%;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: ${rem(8)};
  letter-spacing: ${rem(0.32)};
`;

const TransferTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.color.link};
  background-color: ${({ theme }) => theme.color.lightBorder};
`;

const Photo: React.FC<{ skeletonClassName?: string }> = ({
  skeletonClassName = '',
}) => {
  return (
    <PlayerPhoto
      src=""
      alt="Player photo loader"
      className={skeletonClassName}
    />
  );
};

const PpoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PpoLogo = styled.div`
  display: inline;
  width: ${rem(12)};
  height: ${rem(12)};
  background-color: ${({ theme }) => theme.color.lightBorder};
  margin-right: ${rem(8)};
`

const Ppo: React.FC<{ className?: string; skeletonClassName?: string }> = ({
  className,
  skeletonClassName,
}) => {
  return (
    <PpoWrapper className={className}>
      <PpoLogo className={skeletonClassName} />
      <PpoName className={skeletonClassName} />
    </PpoWrapper>
  );
};
const PpoIn = styled(Ppo)`
  margin-right: ${rem(16)};
`;

interface OwnProps {
  skeletonClassName?: string;
}

const TransferComponentLoader: React.FC<OwnProps> = ({
  skeletonClassName = '',
}) => {
  return (
    <TransferTypeWrapper className={skeletonClassName}>
      <TransferTypeLabel  />
      <Wrapper>
        <PlayerDetailWrapper>
          <Photo skeletonClassName={skeletonClassName} />
          <PlayerName className={skeletonClassName} />
        </PlayerDetailWrapper>
        <PpoDesktopWrapper>
          <PpoIn skeletonClassName={skeletonClassName} />
          <Ppo skeletonClassName={skeletonClassName} />
        </PpoDesktopWrapper>
      </Wrapper>
    </TransferTypeWrapper>
  );
};

export default TransferComponentLoader;
