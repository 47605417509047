import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const Wrapper = styled.div<{ width: number; withBorder: boolean }>`
  border-radius: 50%;
  width: ${({ width }) => rem(width)};
  height: ${({ width }) => rem(width)};
  overflow: hidden;
  border: ${({ withBorder }) =>
    withBorder ? `${rem(4)} solid white` : `none`};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;

interface IOwnProps {
  width: number;
  withBorder?: boolean;
  alt: string;
  src?: string | null;
}

type IProps = IOwnProps;

const Avatar: React.FC<IProps> = ({ width, alt, src, withBorder = true }) => {
  return (
    <Wrapper width={width} withBorder={withBorder}>
      {src ? <img alt={alt} src={src} /> : <Icon name="avatar" size={width} />}
    </Wrapper>
  );
};

export default Avatar;
