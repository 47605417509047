import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { em, rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle } from '../../../library/App';
import { MediaManagerPhoto } from '../../../library/MediaManager';
import styled from '../../../theme/styled-components';
import Icon from '../../Icon';
import Link from '../../Link';

const ArticleName = styled.span`
  line-height: ${rem(16.8)};
`;

const ArticleLink = styled(Link as any)`
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.textColor};
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  font-size: ${rem(14)};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    ${ArticleName} {
      text-decoration: underline;
    }
  }
  display: flex;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  &,
  & img {
    border-radius: ${rem(4)};
    width: ${rem(56)};
  }
`;

const ImageWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 ${rem(16)} auto 0;
  flex-shrink: 0;
`;

const SpacedIcon = styled(Icon)`
  margin-right: ${em(4)};
`;

type IOwnProps = {
  article: IArticle;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const description =
    (article.picture as MediaManagerPhoto)?.description || article.name;

  return (
    <ArticleLink to={getArticleUrl(article)} title={article.name}>
      <ImageWrapper>
        <StyledMediaManagerImage
          picture={article.picture || {}}
          script="media"
          naturalWidth={100 * 2}
          naturalHeight={75 * 2}
          webp={true}
          alt={description}
          loading="lazy"
        />
      </ImageWrapper>
      <ArticleName>
        {article.is_private && <SpacedIcon name="premium" size={12} />}
        {article.name}
      </ArticleName>
    </ArticleLink>
  );
};

export default React.memo(Article);
