import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Loading from '../../components/Loading';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { selectReduxValue } from '../App/actions';
import { authUserResolvedSelector } from '../App/selectors';
import Forbidden from '../Forbidden';
import Article from '../FutbalnetArticle';
import Section from '../FutbalnetSection';
import NotFound from '../NotFound';
import Redirect from '../Redirect';
import Unauthorized from '../Unauthorized';
import { loadByUrlFutbalnet } from './actions';
import { urlDetailErrorSelector, urlDetailSelector } from './selectors';

type RouterProps = RouteComponentProps<
  {
    klub?: string;
    zvaz?: string;
    splat: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps & ReduxConnectProps,
) => {
  const pathname = props.params.splat;
  return {
    detail: urlDetailSelector(state, pathname),
    error: urlDetailErrorSelector(state, pathname),
    authUserResolved: authUserResolvedSelector(state),
  };
};

type Props = RouterProps &
  ReduxConnectProps &
  ReturnType<typeof mapStateToProps>;

class FutbalnetUrlMap extends React.PureComponent<Props> {
  static async init(props: Props) {
    const { dispatch, authUserResolved } = props;
    return authUserResolved
      ? dispatch(loadByUrlFutbalnet(props.params.splat))
      : null;
  }

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    // get redux props during SSR
    const enhanceProps = dispatch(
      selectReduxValue((state) => mapStateToProps(state, props)),
    );
    const enhancedProps = { ...props, ...enhanceProps };

    const response = await FutbalnetUrlMap.init(enhancedProps);
    if (response && response.data.urltype === 'section') {
      await Section.getInitialProps({ ...props, id: response.data.object_id });
    } else if (response && response.data.urltype === 'article') {
      await Article.getInitialProps({ ...props, id: response.data.object_id });
    }
  }

  async componentDidMount() {
    try {
      await FutbalnetUrlMap.init(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.authUserResolved !== this.props.authUserResolved
    ) {
      try {
        await FutbalnetUrlMap.init(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  render() {
    const { detail, error } = this.props;

    if (error && error.identifier === 'FORBIDDEN') {
      return <Forbidden />;
    }

    if (error && error.identifier === 'UNAUTHORIZED') {
      return <Unauthorized />;
    }

    if (error && error.identifier === 'REDIRECT') {
      return <Redirect to={error.payload.location} />;
    }

    if (error) {
      return <NotFound />;
    }

    if (!detail) {
      return <Loading />;
    }

    if (detail.urltype === 'section') {
      return <Section id={detail.object_id} />;
    }

    if (detail.urltype === 'article') {
      return <Article id={detail.object_id} />;
    }

    return null;
  }
}

export default compose(connect(mapStateToProps))(FutbalnetUrlMap);
