import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from './actions';

export const currentMemberIdReducer = reducerWithInitialState<string | null>(
  null,
).case(actions.setCurrentMemberId, (state, memberId) => memberId || null);

export const currentMemberTypeReducer = reducerWithInitialState<string | null>(
  null,
).case(actions.setCurrentMemberType, (state, memberType) => memberType || null);
