import React from 'react';

export default (size: string, color: string) => {
  const color1 = '#001935';
  const color2 = '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 144.80001 144.8"
    >
      <g transform="translate(-244,-7.3)">
        <path
          fill={color1}
          transform="translate(244,7.3)"
          d="M 28.900391 0 C 13.000391 0 0 13.000391 0 28.900391 L 0 115.90039 C 0 131.80039 13.000391 144.80078 28.900391 144.80078 L 115.90039 144.80078 C 131.80039 144.80078 144.80078 131.80039 144.80078 115.90039 L 144.80078 28.900391 C 144.80078 13.000391 131.80039 -1.1842379e-14 115.90039 0 L 28.900391 0 z M 65.699219 26.099609 L 80 26.099609 L 80 49.400391 L 96.599609 49.400391 L 96.599609 66.400391 L 80 66.400391 L 80 90 C 80 97.1 83.599219 99.5 89.199219 99.5 L 97.300781 99.5 L 97.300781 117.5 L 82 117.5 C 68.3 117.5 58 110.4 58 93.5 L 58 66.400391 L 45.5 66.400391 L 45.5 51.699219 C 59.2 48.099219 64.999219 36.299609 65.699219 26.099609 z "
        />
        <path
          fill={color2}
          d="M 341.3,124.8 H 326 c -13.7,0 -24,-7.1 -24,-24 V 73.7 H 289.5 V 59 C 303.2,55.4 309,43.6 309.7,33.4 H 324 v 23.3 h 16.6 v 17 H 324 V 97.300001 C 324,104.4 327.6,106.8 333.2,106.8 h 8.1 z"
        />
      </g>
    </svg>
  );
};
