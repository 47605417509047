import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g transform="translate(0.001 0)">
      <path fill="#8AB4F8" d="M14.1,23L9.9,19l8.9-9.1l4.3,4.2L14.1,23z" />
      <path
        fill="#4285F4"
        d="M14.1,5.1L10,0.8l-9.1,9c-1.2,1.2-1.2,3.1,0,4.2l8.9,9l4.2-4l-6.8-7.2L14.1,5.1z"
      />
      <path
        fill="#246FDB"
        d="M23.1,9.9l-9-9C13-0.3,11.1-0.3,9.9,0.8C8.7,2,8.7,3.9,9.8,5c0,0,0,0,0.1,0.1l9,9
      c1.2,1.2,3.1,1.1,4.2-0.1C24.2,12.9,24.2,11,23.1,9.9z"
      />
      <circle id="Ellipse_4" fill="#246FDB" cx="11.9" cy="21.1" r="2.9" />
    </g>
  </svg>
);
