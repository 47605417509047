import React from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from '../../components/Icon';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { ITheme } from '../../theme/theme';
import { FeedItemWithCustomSmarttags } from './features/getFeedItemsWithBanners';
import { getFeedItemDetailUrl } from './utils';
import Link from '../../components/Link';
import { SmartTag, SmartTags } from './detail/GossipDetail';
import GossipDetailTopBar from './detail/GossipDetailTopBar';
import GossipBottomItems from './detail/GossipBottomItems';

const StyledGossipDetailTopBar = styled(GossipDetailTopBar)`
  margin-bottom: ${rem(12)};
`;

const GossipWrapper = styled.div<{ isPinned?: boolean }>`
  padding: ${rem(16)} 0;
  ${mb('m')} {
    padding: ${rem(24)};
  }
  ${({ theme, isPinned = false }) =>
    isPinned ? `background-color: ${theme.color.lightBackground}` : ''};
`;

const TextWrapper = styled.div`
  font-size: ${rem(16)};
  font-family: pt-serif-pro, serif;
  line-height: 1.5;
  ${mb('m')} {
    font-size: ${rem(18)};
  }
`;

const Title = styled(Link)`
  margin: 0;
  :hover {
    text-decoration: underline;
  }
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 700;
  svg {
    width: ${rem(14)};
    height: ${rem(14)};
    transform: translateY(${rem(1)});
    margin-right: ${rem(2)};
  }
  ${mb('m')} {
    svg {
      width: ${rem(20)};
      height: ${rem(20)};
      transform: translateY(${rem(3)});
      margin-right: ${rem(4)};
    }
  }
`;

type Props = Pick<
  FeedItemWithCustomSmarttags,
  | 'smarttags'
  | '_id'
  | 'date'
  | 'extendedDescription'
  | 'discussionPostCount'
  | 'authors'
> & {
  className?: string;
  isPinned?: boolean;
  title?: FeedItemWithCustomSmarttags['title'];
  description?: string;
};

const GossipListItem: React.FC<Props> = ({
  className,
  date,
  smarttags,
  _id,
  isPinned,
  title,
  description,
  discussionPostCount,
  authors,
}) => {
  const detailUrl = getFeedItemDetailUrl(_id, title ?? '');

  const theme = useTheme() as ITheme;

  return (
    <GossipWrapper className={className} isPinned={isPinned ?? false}>
      <StyledGossipDetailTopBar date={date} authors={authors ?? []} />
      <TextWrapper>
        <Title to={detailUrl}>
          {isPinned && <Icon name="pin-2" color={theme.color.primary} />}
          <span>{title}</span>
        </Title>{' '}
        {description}
      </TextWrapper>
      {smarttags.length > 0 && (
        <SmartTags>
          {smarttags.map((tag) => (
            <SmartTag
              key={`gossiptag-${tag.label}`}
              href={tag.url}
              onClick={(event) => event.stopPropagation()}
            >
              {tag.label}
            </SmartTag>
          ))}
        </SmartTags>
      )}
      {/* <GossipDiscussionCount
        discussionPostCount={discussionPostCount ?? 0}
        discussionIconUrl={detailUrl}
      /> */}
      <GossipBottomItems
        detailUrl={detailUrl}
        discussionIconUrl={detailUrl}
        discussionPostCount={discussionPostCount ?? 0}
      />
    </GossipWrapper>
  );
};

export default GossipListItem;
