import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { rem } from 'polished';
import React from 'react';
import { ISection } from '../../../library/App';
import styled from '../../../theme/styled-components';

const StyledMediaManagerImage = styled(MediaManagerImage)`
  margin-right: ${rem(12)};
  flex-shrink: 0;
`;

type IOwnProps = {
  section: ISection;
  size?: number;
};

type IProps = IOwnProps;

const SectionBlockIcon: React.FC<IProps> = ({ section, size = 32 }) => {
  if (!section.icon) {
    return null;
  }

  return (
    <StyledMediaManagerImage
      picture={section.icon}
      naturalHeight={size * 2}
      naturalWidth={size * 2}
      height={size}
      script="media"
      webp={false}
      loading="lazy"
      alt={section.icon.description || ''}
    />
  );
};

export default SectionBlockIcon;
