import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M23,4H12.6h-1.3H1C0.4,4,0,4.4,0,5v3v8v3c0,0.6,0.4,1,1,1h10.4h1.3H23c0.6,0,1-0.4,1-1v-3V8V5C24,4.4,23.6,4,23,4z
M11.4,14.3c-1-0.3-1.8-1.2-1.8-2.3s0.7-2,1.8-2.3V14.3z M12.6,9.7c1,0.3,1.8,1.2,1.8,2.3s-0.7,2-1.8,2.3V9.7z M1.3,9.3h2.5v5.5H1.3
V9.3z M1.3,18.8V16H5V8H1.3V5.3h10.1v3.2c-1.7,0.3-3,1.8-3,3.6s1.3,3.3,3,3.6v3.2H1.3z M22.8,14.8h-2.5V9.3h2.5V14.8z M19,8v8h3.8
v2.8H12.6v-3.2c1.7-0.3,3-1.8,3-3.6s-1.3-3.3-3-3.6V5.3h10.1V8H19z"
    />
  </svg>
);
