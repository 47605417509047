import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from '../../../components/Icon';
import Link from '../../../components/Link';
import { ITheme } from '../../../theme/theme';

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  height: ${rem(24)};
  background-color: inherit;
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
  color: black;
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-weight: ${({ theme }) => theme.primaryFont.weight.semiBold};
  font-size: ${rem(11)};
  line-height: 1.3;
  border: none;
  outline: none;
  cursor: pointer;
  ${mb('s')} {
    font-size: ${rem(12)};
  }
  :hover {
    text-decoration: underline;
  }
`;

const Text = styled.span`
  display: none;
  ${mb('s')} {
    display: inline;
    margin-left: ${rem(8)};
  }
`;

type OwnProps =  {
className?: string;
  /**
   * URL, pre odklik na kupu predplatneho.
   */
  url?: string;
  /**
   * Text ktory sa zobrazi v tlacidle.
   */
  text: string;
};

export const Login: React.FC<OwnProps> = ({ className, url, text }) => {
  const theme = useTheme() as ITheme;
  return (
    <>
      {url ? (
        <StyledLink className={className} to={url} rel="noopener noreferrer">
          <Icon name="account" size={24} color={theme.color.black} />
          <Text>{text}</Text>
        </StyledLink>
      ) : (
        <StyledLink className={className} as="span">
          <Icon name="account" size={24} color={theme.color.black} />
          <Text>{text}</Text>
        </StyledLink>
      )}
    </>
  );
};
