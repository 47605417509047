import React from 'react';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RootState } from '../../../../configureStore';
import { cumulatedListArticlesSelector } from '../../../Article/selectors';
import { sectionsEntitiesSelector } from '../../../Section/selectors';
import config from '../../../../config';
import Loader from '@sportnet/ui/lib/Loader';
import SectionBlockHeaderContainer from '../../../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionNameSmall from '../../../../components/Sections/SectionBlockHeader/SectionNameSmall';
import __ from '../../../../utilities/__';
import ArticlesListTimeline from '../../../../components/Articles/ArticlesListTimeline';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import ArticlesList from '../../../../components/Articles/ArticlesList';
import ShowMoreButton from '../../../../components/ShowMoreButton';
import getUnionBaseUrl from '../../../../utilities/getUnionBaseUrl';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${mb('m')} {
    flex-direction: row;
  }
`;

const LoadingWrapper = styled.div`
  min-height: ${rem(400)};
`;

const BulletinArticlesWrapper = styled.div`
  margin-bottom: ${rem(48)};
  margin-right: 0;
  ${mb('m')} {
    margin-bottom: ${rem(56)};
    margin-right: ${rem(32)};
  }
`;

const UnionArticlesWrapper = styled.div`
  width: 100%;
  margin-bottom: ${rem(32)};
  ${mb('m')} {
    margin-bottom: ${rem(56)};
  }
`;

const ArticlesLoader = styled(Loader)`
  margin: 0 auto;
`;

const mapStateToProps = (state: RootState) => ({
  unionArticles: {
    articles: cumulatedListArticlesSelector(state, config.LIST_UNION_ARTICLES),
    isLoading: isCommiting(config.LIST_UNION_ARTICLES)(state) || false,
    nextOffset: getListNextOffset(config.LIST_UNION_ARTICLES)(state),
  },
  bulletinArticles: {
    articles: cumulatedListArticlesSelector(
      state,
      config.LIST_FUTBALNET_BULLETIN_ARTICLES,
    ),
    nextOffset: getListNextOffset(config.LIST_FUTBALNET_BULLETIN_ARTICLES)(
      state,
    ),
    isLoading:
      isCommiting(config.LIST_FUTBALNET_BULLETIN_ARTICLES)(state) || false,
  },
  sectionsById: sectionsEntitiesSelector(state),
});

interface OwnProps {
  zvaz: string;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const UnionArticles: React.FC<Props> = ({
  unionArticles,
  bulletinArticles,
  sectionsById,
  zvaz,
}) => {
  const isLoading = unionArticles.isLoading || bulletinArticles.isLoading;
  const noData =
    !isLoading &&
    unionArticles.articles.length === 0 &&
    bulletinArticles.articles.length === 0;

  return (
    <>
      {isLoading ? (
        <LoadingWrapper>
          <ArticlesLoader />
        </LoadingWrapper>
      ) : noData ? null : (
        <Wrapper>
          <BulletinArticlesWrapper>
            <SectionBlockHeaderContainer
              left={<SectionNameSmall>{__('Úradné správy')}</SectionNameSmall>}
            />
            <ArticlesListTimeline articles={bulletinArticles.articles} small={false} />
              <ShowMoreButton
                to={`${getUnionBaseUrl({ zvaz })}clanky/`}
                text={__('Viac správ')}
              />
          </BulletinArticlesWrapper>
          <UnionArticlesWrapper>
            <SectionBlockHeaderContainer
              left={<SectionNameSmall>{__('Správy zväzu')}</SectionNameSmall>}
            />
            <ArticlesList
              articles={unionArticles.articles}
              sectionsById={sectionsById}
            />
            {unionArticles.nextOffset !== null && (
              <ShowMoreButton
                to={`${getUnionBaseUrl({ zvaz })}clanky/`}
                text={__('Viac správ')}
              />
            )}
          </UnionArticlesWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default connect(mapStateToProps)(UnionArticles);
