import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M21,18H3c-0.4,0-0.8-0.3-1-0.7c-0.1-0.4,0-0.9,0.4-1.1c0,0,2.6-1.9,2.6-8.2c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7
    c0,6.3,2.5,8.2,2.6,8.2c0.4,0.3,0.5,0.7,0.4,1.1S21.4,18,21,18z M5.1,16h13.7C18,14.5,17,12,17,8c0-2.8-2.2-5-5-5S7,5.2,7,8
    C7,12,6,14.5,5.1,16z M14.6,21.5c0.3-0.5,0.1-1.1-0.4-1.4c-0.5-0.3-1.1-0.1-1.4,0.4c-0.3,0.5-0.9,0.6-1.4,0.4
    c-0.2-0.1-0.3-0.2-0.4-0.4c-0.3-0.5-0.9-0.6-1.4-0.4c-0.5,0.3-0.6,0.9-0.4,1.4c0.3,0.5,0.6,0.8,1.1,1.1c0.5,0.3,1,0.4,1.5,0.4
    C13,23,14,22.5,14.6,21.5z"
    />
  </svg>
);
