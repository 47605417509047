import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g transform="translate(1.5 0)">
      <g transform="translate(15)">
        <path
          fill="#F9AB00"
          d="M0,2.9v18c-0.1,1.6,1.1,3,2.7,3.1c0.1,0,0.1,0,0.2,0c1.6,0,2.9-1.3,2.9-2.9c0-0.1,0-0.2,0-0.3V3
        C5.8,1.4,4.6,0.1,3,0c0,0-0.1,0-0.1,0C1.3,0,0,1.3,0,2.9z"
        />
      </g>
      <g transform="translate(7.5 9.136)">
        <path
          fill="#E37400"
          d="M0,2.9v8.9c-0.1,1.6,1.1,3,2.7,3.1c0.1,0,0.1,0,0.2,0c1.6,0,2.9-1.3,2.9-2.9c0-0.1,0-0.2,0-0.3
        V3C5.8,1.4,4.6,0.1,3,0c0,0-0.1,0-0.1,0C1.3,0,0,1.3,0,2.9z"
        />
      </g>
      <g transform="translate(0 18.273)">
        <circle fill="#E37400" cx="2.9" cy="2.9" r="2.9" />
      </g>
    </g>
  </svg>
);
