import { rem } from 'polished';
import LoadMorePanel from '.';
import styled from '../../theme/styled-components';

export default styled(LoadMorePanel)`
  min-height: ${rem(40)};
  padding: ${rem(8)};
  margin: ${rem(16)} 0 0 0;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
`;
