import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled, { css } from '../../../../../theme/styled-components';

const Line = styled.div<{
  color: string;
  height: number;
  hideOnBreakPoint?: string | number;
  showOnBreakPoint?: string | number;
}>`
  position: relative;
  z-index: 100;
  height: ${({ height }) => rem(height)};
  width: 100%;
  background-color: ${({ color }) => color};
  ${({ showOnBreakPoint }) =>
    showOnBreakPoint &&
    css`
      display: none;
      ${mb(showOnBreakPoint)} {
        display: block;
      }
    `}
  ${({ hideOnBreakPoint }) =>
    hideOnBreakPoint &&
    css`
      ${mb(hideOnBreakPoint)} {
        display: none;
      }
    `}
`;

export default Line;
