import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import { Link } from 'react-router';
import styled from '../../theme/styled-components';

export const TabPane = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

export const TabPaneWrapper = styled.div``;

export const Wrapper = styled.div`
  padding: ${rem(24)} ${rem(24)} ${rem(20)} ${rem(24)};
  background: #fff;
  border: ${rem(1)} solid;
  border-color: ${({ theme }) => theme.color.separator};
  border-radius: ${rem(8)};
  ${mb('m')} {
    max-height: ${rem(600)};
    overflow: auto;
  }
`;

export const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  ${mb('xs')} {
    overflow-x: hidden;
  }
  flex-wrap: wrap;
  padding: ${rem(24)} 0 0 0;
`;

export const MenuItem = styled(Link)`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: normal;
  font-size: ${rem(14)};
  width: 100%;
  line-height: ${rem(16.8)};
  ${mb('m')} {
    width: 33.3%;
  }
  :hover {
    text-decoration: underline;
  }
  margin-top: ${rem(4)};
  margin-bottom: ${rem(0)};
  margin-left: ${rem(0)};
  padding-top: ${rem(4)};
  padding-bottom: ${rem(4)};
`;

export const MenuItemLabel = styled.div``;

export const Header = styled.div`
  border-bottom: ${rem(1)} solid #ddd;
  padding-bottom: ${rem(24)};
`;

export const OwnerName = styled.div`
  display: flex;
  font-size: ${rem(13)};
  font-weight: 600;
`;

export const LogoImgWrapper = styled.div`
  padding: ${rem(5.6)} ${rem(5.6)} ${rem(5.6)} ${rem(5.6)};
  border: ${rem(1)} solid;
  border-color: ${({ theme }) => theme.color.separator};
  border-radius: ${rem(8)};
  width: ${rem(56)};
  height: ${rem(56)};
`;

export const LogoImg = styled.img`
  width: ${rem(44.8)};
`;

export const HeaderTextWrapper = styled.div`
  margin-left: ${rem(16)};
`;

export const MainTitle = styled.h2`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(18)};
  line-height: ${rem(21.6)};
  text-transform: uppercase;
  padding: ${rem(4)} ${rem(0)} ${rem(8)} ${rem(0)};
  margin: ${rem(0)};
`;

export const UnionName = styled.h3`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: normal;
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  padding: ${rem(0)};
  margin: ${rem(0)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
