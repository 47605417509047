import PictureFrame from '@sportnet/ui/lib/PictureFrame';
import * as React from 'react';
import styled, { withTheme } from '../../../../theme/styled-components';
import { IWidgetVideo } from '@sportnet/content/utilities/library';
import {
  getDalilymotionEmbedUrl,
  getIdnesEmbedUrl,
  getSportnetVideoEmbedUrl,
  getTa3EmbedUrl,
  getVimeoEmbedUrl,
  getYoutubeEmbedUrl,
  isLivestreamEmbedUrl,
} from '@sportnet/content/editor/widgets/Video/utils';
import Loader from '@sportnet/ui/lib/Loader';
import { ITheme } from '../../../../theme/theme';
import VideoJS from '../../../VideoJS';

const SmeYoutubeWrapper = styled.div`
  padding-bottom: 56.25%;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const SmeYoutubeLoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const IFrameWrapper = styled(PictureFrame)`
  margin: 0 auto;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

type IData = Omit<IWidgetVideo, 'type'>;

interface IOwnProps {
  data?: IData;
  renderEmpty?: (type: string) => React.ReactNode;
  theme: ITheme;
}

type IProps = IOwnProps;

const C: React.FC<IProps> = ({ data = {} as IData, renderEmpty, theme }) => {
  const [url, service, externalId, poster] = React.useMemo((): any => {
    if (data.public_url || data.uri) {
      return [
        data.public_url ||
          `https://mediamanager.ws/media/${data.uri}`,
        'native',
      ];
    }

    if (!data.url) {
      return [];
    }

    let embedUrl = getYoutubeEmbedUrl(data.url);
    if (embedUrl) {
      return [
        embedUrl,
        'youtube',
        embedUrl.substring(embedUrl.lastIndexOf('/') + 1),
      ];
    }

    embedUrl = getSportnetVideoEmbedUrl(data.url);
    if (embedUrl) {
      return [
        embedUrl.replace('/embed/', '/mp4/'),
        'native',
        undefined,
        embedUrl.replace('/embed/', '/poster/'),
      ];
    }

    if (isLivestreamEmbedUrl(data.url)) {
      return [data.url, 'livestream'];
    }

    embedUrl = getVimeoEmbedUrl(data.url);
    if (embedUrl) {
      return [embedUrl, 'vimeo'];
    }

    embedUrl = getDalilymotionEmbedUrl(data.url);
    if (embedUrl) {
      return [embedUrl, 'dailymotion'];
    }

    embedUrl = getTa3EmbedUrl(data.url);
    if (embedUrl) {
      return [embedUrl, 'ta3'];
    }

    embedUrl = getIdnesEmbedUrl(data.url);
    if (embedUrl) {
      return [embedUrl, 'idnes'];
    }

    return [];
  }, [data]);
  const uniqueElementId = React.useMemo(() => {
    if (service === 'youtube') {
      return `smevideoplayer-${externalId}`;
    }
    return null;
  }, [service, externalId]);
  if (url) {
    if (service === 'native') {
      // implementujeme vlastny videojs plugin
      return (
        <VideoJS
          options={{
            autoplay: false,
            controls: true,
            fluid: true,
            aspectRatio: '16:9',
            poster: poster,
            sources: [
              {
                src: url,
                type: 'video/mp4',
              },
            ],
          }}
          onReady={(videojs) => {
            videojs.on('play', () => {
              if (window && (window as any).videoplayerAnalytics) {
                ((window || {}) as any).videoplayerAnalytics({
                  data: { videoEvent: { video: { title: url } } },
                });
              }
            });
          }}
        />
      );
    } else if (service === 'youtube') {
      // Youtube hijack (https://gitlab.bart.sk/sportnet/csm/sportnet.sme.sk/-/issues/240)
      return (
        <SmeYoutubeWrapper>
          <div
            className="js-smevideo-placeholder smevideo-youtube-placeholder"
            data-id={uniqueElementId}
            data-provider="youtube"
            data-video-id={externalId}
            data-fallback={url}
          >
            <SmeYoutubeLoadingWrapper>
              <Loader size="xl" color={theme.loader.color} />
            </SmeYoutubeLoadingWrapper>
          </div>
          <noscript>
            <IFrameWrapper width={16} height={9}>
              <Iframe
                src={url}
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </IFrameWrapper>
          </noscript>
        </SmeYoutubeWrapper>
      );
    } else {
      return (
        <IFrameWrapper width={16} height={9}>
          <Iframe
            src={url}
            frameBorder="0"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </IFrameWrapper>
      );
    }
  }

  if (renderEmpty) {
    return <>{renderEmpty('video')}</>;
  }

  return null;
};

export default withTheme(C as any);
