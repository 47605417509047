import { DropDownItemProps, IDropDownOpenDirection } from '../types/DropDown';
import { FloatingBoxStyles, HiderStyles } from '../Theme';
import { rem } from 'polished';
import DropDownItem from './DropDownItem';
import React from 'react';
import styled, { css } from 'styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const Wrapper = styled.div`
  position: relative;
`;

const DropDownBody = styled.div<{
  opened: boolean;
  openDirection: IDropDownOpenDirection;
  responsiveOppositeDirection: boolean;
}>`
  ${FloatingBoxStyles}
  ${({ opened }) => !opened && HiderStyles}
  position: absolute;
  top: calc(100% + ${rem(8)});
  ${({ openDirection, responsiveOppositeDirection }) =>
    openDirection === 'left'
      ? responsiveOppositeDirection
        ? css`
            left: 0;
            right: unset;
            ${mb('s')} {
              right: 0;
              left: unset;
            }
          `
        : css`
            right: 0;
          `
      : responsiveOppositeDirection
      ? css`
          right: 0;
          left: unset;
          ${mb('s')} {
            left: 0;
            right: unset;
          }
        `
      : css`
          left: 0;
        `}
  padding: ${rem(8)} 0;
  min-width: ${rem(240)};
  max-width: ${rem(240)};
  max-height: ${rem(270)};
  overflow-y: ${({ opened }) => (opened ? 'auto' : 'none')};
  z-index: 20000;
`;

type OwnProps = { className?: string } & {
  items: DropDownItemProps[];
  opened: boolean;
  controlElement?: React.ReactElement;
  openDirection?: IDropDownOpenDirection;
  /**
   * Na mobile sa otvori do opacnej strany.
   */
  responsiveOppositeDirection?: boolean;
  onClickOutside?: (e: MouseEvent | KeyboardEvent) => void;
};

export const DropDown: React.FC<OwnProps> = ({
  className,
  items,
  opened,
  openDirection = 'right',
  controlElement = '',
  onClickOutside,
  responsiveOppositeDirection = false,
}) => {
  const wrapperEl = React.useRef<HTMLDivElement | null>(null);
  const mainWrapperEl = React.useRef<HTMLDivElement | null>(null);

  // POZN: Neviem ci je toto najvhodnejsie miesto na detekciu kliknutia mimo
  // dropdown, pretoze musime zohladnit klik na tlacidlo, ktore otvara/zatvara
  // dropdown. Detekcia kliku na control tlacidlo je velmi jednoducha -
  // ak je tlacidlo priamy child elementu `mainWrapperEl`, tak bol klik na control tlacidlo.
  // Ak by bol v prop `controlElement` nejaky komplexnejsi prvok, bude to potrebne zohladnit.
  React.useEffect(() => {
    if (!onClickOutside) {
      return;
    }
    const handleClickOutside = (e: MouseEvent) => {
      if (
        !(
          e.target === wrapperEl.current ||
          wrapperEl.current?.contains(e.target as Node) ||
          mainWrapperEl.current?.contains(e.target as Node)
        )
      ) {
        onClickOutside(e);
      }
    };
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onClickOutside(e);
      }
    };
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [mainWrapperEl, wrapperEl, onClickOutside]);

  return (
    <Wrapper ref={mainWrapperEl} className={`${className ?? ''} drop-down`}>
      {controlElement}
      <DropDownBody
        ref={wrapperEl}
        opened={opened}
        openDirection={openDirection}
        className="drop-down-body"
        responsiveOppositeDirection={responsiveOppositeDirection}
      >
        {items.map((item, idx) => (
          <DropDownItem
            key={`dropdown-item-${item.id ?? idx}`}
            label={item.label}
            icon={item.icon}
            iconColor={item.iconColor}
            href={item.href}
            onClick={item.onClick}
            linkRel={item.linkRel}
            linkTarget={item.linkTarget}
          />
        ))}
      </DropDownBody>
    </Wrapper>
  );
};
