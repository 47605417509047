export default function (appSpace: string, articleId: number | undefined) {
  // in order to make URL to edit article
  const urlForEditObj = {
    pathname: `/articles/detail/${articleId}`,
    search: '',
    hash: '',
  };
  const base64URL = Buffer.from(JSON.stringify(urlForEditObj)).toString(
    'base64',
  );
  return `https://pages.sportnet.online/admin/${appSpace}/editor#cms${base64URL}`;
}
