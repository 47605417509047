import { rem } from 'polished';
import styled from '../../theme/styled-components';
import {
  Table,
  Tbody as TbodyOrig,
  Td as TdOrig,
  Th as ThOrig,
  Thead as TheadOrig,
  Tr as TrOrig,
} from './Table';

const Thead = styled(TheadOrig)``;

const Th = styled(ThOrig)`
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
  font-weight: 600;
`;

const Td = styled(TdOrig)`
  height: 33px;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  padding-left: inherit;
  padding-right: inherit;
`;

const Tr = styled(TrOrig)`
  th:first-child,
  td:first-child {
    padding-left: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
  th:last-child,
  td:last-child {
    padding-right: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  }
`;

const Tbody = styled(TbodyOrig)`
  > Tr {
    td:first-child {
      padding-left: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
      width: 200px;
    }
  }
`;

export { Table, Tbody, Thead, Tr, Th, Td };
