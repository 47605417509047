import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <g fill="none" stroke="#ddd" strokeWidth="2">
      <circle cx="12" cy="12" r="12" stroke="none" />
      <circle cx="12" cy="12" r="11" fill="none" />
    </g>
  </svg>
);
