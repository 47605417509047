import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../theme/styled-components';
import useResponsiveList from '../../utilities/useResponsiveList';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import ItemsDropDown from '../DropDowns/ItemsDropDown';
import Icon from '../Icon';
import { ITag } from './library';

const List = styled.ul<{ initializing: boolean }>`
  list-style: none;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  display: none;
  min-width: 0;
  width: 0;
  ${mb('s')} {
    display: flex;
    width: auto;
    flex: 1 0 0;
  }
  transition: opacity 250ms linear;
  ${({ initializing }) =>
    initializing &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

const ListItem = styled.li`
  display: flex;
  flex-shrink: 0;
  padding: 0 ${rem(8)} 0 0;
  :last-child {
    padding: 0;
  }
`;

const StyledItemsDropDown = styled(ItemsDropDown)`
  & > div {
    top: ${rem(36)};
  }
`;

type IOwnProps = {
  tags: ITag[];
  className?: string;
  icon?: string;
};

type IProps = IOwnProps;

const DesktopTagsList: React.FC<IProps> = ({
  tags,
  className,
  icon
}) => {
  const [moreSectionsOpened, setMoreSectionsOpened] = React.useState(false);

  const handleCloseMoreSections = React.useCallback(() => {
    setMoreSectionsOpened(false);
  }, []);

  const { primaryItems, secondaryItems, initializing, listRef } =
    useResponsiveList(tags, 32);

  function renderItem(tag: ITag, idx: number) {
    return (
      <ListItem key={idx}>
        <ButtonLink label={tag.label} url={tag.url} icon={icon} />
      </ListItem>
    );
  }

  return (
    <List className={className} ref={listRef} initializing={initializing}>
      {primaryItems.map(renderItem)}
      {secondaryItems.length > 0 && (
        <ListItem>
          <StyledItemsDropDown
            items={secondaryItems}
            opened={moreSectionsOpened}
            onClose={handleCloseMoreSections}
            position="left"
          >
            <Button
              size="s"
              secondary
              onClick={() => setMoreSectionsOpened((prev) => !prev)}
            >
              <Icon name="more-dots" size={10} />
            </Button>
          </StyledItemsDropDown>
        </ListItem>
      )}
    </List>
  );
};

export default DesktopTagsList;
