import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled from '../../../theme/styled-components';
import getColor from '../../../utilities/getColor';
import ItemsDropDown, { IItem } from '../../DropDowns/ItemsDropDown';
import Input from '../Input';

const Wrapper = styled.div``;

const StyledItemsDropdown = styled(ItemsDropDown)`
  max-width: none;
  min-width: none;
  .dropdown-body {
    top: calc(100% + ${rem(4)});
    max-width: 100%;
    min-width: 100%;
    ${mb('s')} {
      max-width: 350px;
      min-width: 350px;
    }
  }
`;

interface OwnProps {
  className?: string;
  label?: string;
  value: string;
  suggestions: IItem[];
  onChange: (searchValue: string) => void;
  onDebouncedChange: (searchValue: string) => void;
  isFetching: boolean;
  placeholder?: string;
}

const SuggestionSearch: React.FC<OwnProps> = ({
  className,
  value,
  suggestions,
  onChange,
  onDebouncedChange,
  isFetching,
  label,
  placeholder
}) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const timer = React.useRef<number | null>();

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value || '';
    onChange(newValue);
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      onDebouncedChange(newValue);
    }, 750);
  };

  const handleClickBackspace = () => {
    onChange('');
  };

  const toggleOpened = () => {};

  const inputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const textColor = getColor('#333');
  const activeBorderColor = getColor('#CF2528');
  const position = 'right';

  return (
    <Wrapper className={className}>
      <StyledItemsDropdown
        className="clubs-drop-down"
        items={suggestions}
        opened={suggestions.length > 0}
        onClose={toggleOpened}
        color={textColor}
        activeColor={activeBorderColor}
        position={position}
      >
        <Input
          value={value}
          placeholder={placeholder}
          onChange={handleChangeValue}
          inputRef={inputRef}
          label={label}
        >
          {isFetching ? (
            <Input.Loading className="inputLoading" size={'m'} />
          ) : (
            <Input.Icon
              className="inputSearchIcon"
              onClick={inputFocus}
              color="#aaa"
              size={16}
              name="search"
            />
          )}
          <input ref={inputRef} placeholder={placeholder} />
          {(value || '').length > 0 && (
            <Input.Icon
              className="inputBackspace clickable"
              name="backspace"
              onClick={handleClickBackspace}
            />
          )}
        </Input>
      </StyledItemsDropdown>
    </Wrapper>
  );
};

export default SuggestionSearch;
