import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M6,8.3C5.8,8.3,5.6,8.2,5.5,8l-3-3C2.2,4.7,2.2,4.3,2.5,4s0.8-0.3,1.1,0L6,6.4L8.5,4c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1
l-3,3C6.4,8.2,6.2,8.3,6,8.3z"
    />
  </svg>
);
