import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory, thunkToAction } from 'typescript-fsa-redux-thunk';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import NotFoundError from '../../NotFoundError';
import { updateEntities } from '../../pages/App/actions';
import { CSMAppSpaceSelector } from '../../pages/DomainResolver/selectors';
import getSmarttagFromUrl from '../../utilities/smartTags/getSmarttagFromUrl';
import config from '../../config';
import {
  OptimizedPublicSmartTagResponse,
  UrlRedirect,
} from '../../library/SmartTags';
import {
  PublicSmartTagResponse,
  SmartTagResponse,
} from '../../api/TagmanagerApi';
import normalizeEntities from '../../utilities/normalizeEntities';
import { NormalizedEntities } from '../../library/App';
import { commit, CommitError, getListParameters } from '@sportnet/redux-list/ducks';

const create = actionCreatorFactory('SMART_TAG');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

// POZOR: pri povoleni este povolit smart:tag key v index.ts
const ALLOWED_SMARTAGS = ['obsah', 'tema', 'rubrika'];

const reduceSmartTagDetail = (
  smartTag: PublicSmartTagResponse & SmartTagResponse,
): OptimizedPublicSmartTagResponse => {
  return {
    identifier: smartTag.identifier,
    key: smartTag.key,
    value: smartTag.value,
    meta: smartTag.meta,
    url: smartTag.url,
    content: smartTag.content,
  };
};

export const loadSmartTagByIdent = createAsync<
  { ident: string },
  { smarttag?: { key: string; value: string }, redirect?: UrlRedirect }
>(
  'LOAD_SMARTTAG_BY_IDENT',
  async ({ ident }, dispatch, getState, { CmsApi, TagmanagerApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());

    try {
      const smarttag = getSmarttagFromUrl(ident);
      if (!smarttag) {
        throw new NotFoundError(
          `Error getting smart:tag URL from ident: ${ident}!`,
        );
      }

      /**
       * Zatial obmedzíme stránky smart:tagov len na vybrané
       */
      if (!ALLOWED_SMARTAGS.includes(smarttag.key)) {
        throw new NotFoundError({});
      }

      const response = await TagmanagerApi.publicGetSmarttagById(
        appSpace,
        smarttag.key,
        smarttag.values[0].key,
      );

      const smartTags = {
        [response.identifier]: reduceSmartTagDetail(response),
      };

      dispatch(updateEntities({ smartTags }));

      // ak ma smart:tag nastavenu `url`, redirectneme na danu location.
      if (response.url) {
        return {
          redirect: {
            url: ident,
            location: response.url,
            permanent: true,
          },
        };
      }

      return {
        smarttag: {
          key: response.key,
          value: response.value,
        }
      };
    } catch (e) {
      // Ak nenajdem smart:tag, hľadame redirect v URL mape (medzičasom mohol byť v Tagmanageri
      // premenovaný, zlúčený alebo odstránený a mal by existovať 301 redirect v CMS UrlMap).
      // Ak sa mi z URLMapy vrati clanok, sekcia, generujem <NotFound> - nemalo by sa to stávať.
      try {
        const response = await CmsApi.getContentByUrl(
          config.APP_ID,
          appSpace,
          config.DEFAULT_CONTENT_DIVIDER,
          {
            urlPath: ident,
            expandObject: 1,
            expandWidgets: true,
          },
        );

        if (response.urltype === 'redirect') {
          return {
            redirect: {
              url: response.url,
              location: response.location,
              permanent: response.permanent,
            },
          } as { redirect: UrlRedirect };
        } else {
          throw new NotFoundError(e);
        }
      } catch (e) {
        throw new NotFoundError(e);
      }
    }
  },
);

/**
 * Vrati smart:tagy na zaklade identifikatorov `kluc:hodnota`.
 *
 * Na vsutpe je pole smart:tagov uz v tvare pre URL.
 */
export const publicGetSmarttags = createAsync<
  { smarttags?: string[] },
  NormalizedEntities<'smartTags'>
>(
  'PUBLIC_GET_SMART_TAGS',
  async ({ smarttags = [] }, _dispatch, getState, { TagmanagerApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());

    const { values } = await TagmanagerApi.publicGetSmarttags(appSpace, {
      smarttags,
    });

    return {
      ...normalizeEntities(
        'smartTags',
        values.map(reduceSmartTagDetail),
        (entity: any) => entity.identifier,
      ),
      limit: 0,
      offset: 0,
      total: values.length,
      nextOffset: null,
    };
  },
);

export const loadSmartTagsBySerializedKeyValue = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(publicGetSmarttags)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
