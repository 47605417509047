import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M10,11H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h7c0.6,0,1,0.4,1,1v7C11,10.6,10.6,11,10,11z M4,9h5V4H4V9z M21,11
    h-7c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h7c0.6,0,1,0.4,1,1v7C22,10.6,21.6,11,21,11z M15,9h5V4h-5V9z M21,22h-7c-0.6,0-1-0.4-1-1v-7
    c0-0.6,0.4-1,1-1h7c0.6,0,1,0.4,1,1v7C22,21.6,21.6,22,21,22z M15,20h5v-5h-5V20z M10,22H3c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h7
    c0.6,0,1,0.4,1,1v7C11,21.6,10.6,22,10,22z M4,20h5v-5H4V20z"
    />
  </svg>
);
