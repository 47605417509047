import { rem } from 'polished';
import React from 'react';
import styled, {
  css,
  IThemeProps,
  withTheme,
} from '../../theme/styled-components';
import Icon from '../Icon';
import Link from '../Link';

const TagLabel = styled.span``;

const tagStyles = css`
  font-size: ${rem(13)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.textColor};
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  line-height: ${rem(15.6)};
  padding: ${rem(6)} ${rem(12)};
  min-height: ${rem(32)};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.separator};
  border-radius: ${rem(4)};
`;

const TagLink = styled(Link)`
  ${tagStyles}
  cursor: pointer;
  :hover,
  :active,
  :focus {
    ${TagLabel} {
      text-decoration: underline;
    }
  }
  > .icon {
    margin-right: ${rem(8)};
  }
`;

const TagWrapper = styled.div`
  ${tagStyles}
  > .icon {
    margin-right: ${rem(8)};
  }
`;

const Hash = styled.span`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(13)};
  font-weight: 600;
  white-space: pre;
`;

type IOwnProps = {
  label: string;
  url?: string;
  icon?: string;
  leadingCharacter?: string;
};

type IProps = IOwnProps & IThemeProps;

const ButtonLink: React.FC<IProps> = ({
  label,
  url,
  leadingCharacter,
  icon,
  theme,
}) => {
  if (url) {
    return (
      <TagLink to={url}>
        {leadingCharacter && <Hash>{leadingCharacter}</Hash>}
        {icon && <Icon name="tag" size="s" color={theme.color.primary} />}
        <TagLabel>{label}</TagLabel>
      </TagLink>
    );
  }

  return (
    <TagWrapper>
      {leadingCharacter && <Hash>{leadingCharacter}</Hash>}
      {icon && <Icon name="tag" size="s" color={theme.color.primary} />}
      <TagLabel>{label}</TagLabel>
    </TagWrapper>
  );
};

export default withTheme(ButtonLink);
