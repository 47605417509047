import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../theme/styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(32)};
`;

const Button = styled.div<{ active?: boolean }>`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};

  cursor: pointer;
  border-radius: ${rem(4)};
  padding: ${rem(6)} ${rem(16)};
  :not(:last-child) {
    margin-right: ${rem(4)};
  }

  background: #f9f9f9;
  color: #333;
  ${({ active }) =>
    active &&
    css`
      background: #333;
      color: #fff;
    `}
`;

interface IButton {
  label: string;
  value: string;
}

interface OwnProps {
  buttons: IButton[];
  active?: string;
  onChange: (button: IButton) => void;
  className?: string;
}

const ButtonGroup: React.FC<OwnProps> = ({
  buttons,
  onChange,
  active,
  className,
}) => {
  const renderButton = (button: IButton) => (
    <Button
      key={button.value}
      active={active === button.value}
      onClick={() => onChange(button)}
    >
      {button.label}
    </Button>
  );
  return <Wrapper className={className}>{buttons.map(renderButton)}</Wrapper>;
};

export default ButtonGroup;
