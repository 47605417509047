import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
  >
    <circle cx="50%" cy="50%" r={parseInt(size, 10) / 2} fill={color} />
  </svg>
);
