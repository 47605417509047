import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import AppSettings, {
  IFooterSettings,
  IHeaderSettings,
} from '../../library/AppSettings';
import { ILayout } from '../../library/Pages';
import getDefaultLayoutFromSettings from '../../utilities/getDefaultLayoutFromSettings';
import getImageUrl from '../../utilities/getImageUrl';
import { currentSectionSelector } from '../Section/selectors';
import { IAppSettingsState } from './reducer';

const appSettingsDomainSelector = (state: any) =>
  state.application.settings as IAppSettingsState;

export const appSettingsIsFetchingSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => !!domain.isFetching,
);

export const appSettingsDidFailSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => !!domain.error,
);

export const appSettingsSelector = createSelector(
  appSettingsDomainSelector,
  (domain) => getProp(domain, ['data'], {}) as Partial<AppSettings>,
);

export const CSMAppSpaceSelector: any = createSelector(
  appSettingsSelector,
  (settings) => {
    const appSpace = getProp(settings, ['CSMAppSpace'], '');
    return appSpace;
  },
);

export const defaultLayoutSelector = createSelector(
  appSettingsSelector,
  (settings) => {
    return getDefaultLayoutFromSettings(settings);
  },
);

const layoutsSelector = createSelector(appSettingsSelector, (settings) => {
  return settings.layouts || [];
});

interface ISectionLayoutMap {
  [sectionId: number]: string | null;
}

const sectionLayoutMapSelector = createSelector(
  appSettingsSelector,
  (settings) => {
    return (settings.sectionLayoutMap || []).reduce(
      (acc: ISectionLayoutMap, entry) => {
        acc[entry.sectionId] = entry.layoutId;
        return acc;
      },
      {},
    );
  },
);

export const layoutSelector = createSelector(
  layoutsSelector,
  sectionLayoutMapSelector,
  currentSectionSelector,
  defaultLayoutSelector,
  (layouts, sectionLayoutMap, currentSection, defaultLayout) => {
    // use default layout
    let currentLayout = defaultLayout;
    // if current section exists
    if (currentSection) {
      // create path including itself
      const path = [currentSection._id!, ...(currentSection.parent || [])];
      // iterate over path
      for (const sectionId of path) {
        const layoutId = sectionLayoutMap[sectionId];
        // check if layout is set for section id
        if (layoutId) {
          const layout = layouts.find((l) => l._id === layoutId);
          // check if layout exists
          if (layout) {
            // return found layout
            currentLayout = layout;
            break;
          }
        }
      }
    }
    return currentLayout;
  },
);

export const layoutSettingsSelector = createSelector(
  layoutSelector,
  (layout) => {
    return getProp(layout, ['settings'], {}) as Partial<
      Required<ILayout>['settings']
    >;
  },
);

export const headerConfigurationSelector = createSelector(
  layoutSettingsSelector,
  appSettingsSelector,
  (layoutSettings, appSettings) => {
    const headerSettings = getProp(
      layoutSettings,
      ['header'],
      {},
    ) as Partial<IHeaderSettings>;
    const headerConfig: { [key: string]: any } = headerSettings.config || {};

    return {
      ...headerSettings,
      config: {
        ...headerConfig,
        socialFollowUsVariant: getProp(headerConfig, ['social']),
        socialFollowUsConfig: getProp(appSettings, ['social'], {}),
        logoSrc: getImageUrl(headerConfig.logo, 200, 0),
        logoSmallSrc: getImageUrl(headerConfig.logoSmall, 100, 0),
        corporateLogoSmallSrc: getImageUrl(
          headerConfig.corporateLogoSmall,
          100,
          0,
        ),
      },
    } as IHeaderSettings;
  },
);

export const footerConfigurationSelector = createSelector(
  layoutSettingsSelector,
  appSettingsSelector,
  (layoutSettings, appSettings) => {
    const footerSettings = getProp(
      layoutSettings,
      ['footer'],
      {},
    ) as Partial<IFooterSettings>;
    const footerConfig: { [key: string]: any } = footerSettings.config || {};

    return {
      ...footerSettings,
      config: {
        ...footerConfig,
        socialFollowUsVariant: getProp(footerConfig, ['social']),
        socialFollowUsConfig: getProp(appSettings, ['social'], {}),
        backgroundImage: getImageUrl(footerConfig.backgroundImage, 1000, 0),
      },
    } as IFooterSettings;
  },
);

export const homeSectionUniqIdSelector: any = createSelector(
  appSettingsSelector,
  (appSettings) => {
    return appSettings.homepageSectionIdOrUniqId || '';
  },
);
