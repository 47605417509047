export const SPORT_SECTOR_DELEGATES = [
  {
    _id: "futbal",
    items: [
      {
        _id: "Rozhodca",
        label: "Rozhodca",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "1. asistent rozhodcu",
        label: "1. asistent rozhodcu",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "2. asistent rozhodcu",
        label: "2. asistent rozhodcu",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Náhradný rozhodca",
        label: "Náhradný rozhodca",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Delegát stretnutia",
        label: "Delegát stretnutia",
        payload: { acceptedCompetenceTypes: ["delegate"] },
      },
      {
        _id: "Hlavný usporiadateľ",
        label: "Hlavný usporiadateľ",
        payload: { acceptedCompetenceTypes: ["matchmaker"] },
      },
      {
        _id: "Hlásateľ",
        label: "Hlásateľ",
        payload: { acceptedCompetenceTypes: ["hlasatel"] },
      },
      {
        _id: "Pozorovateľ rozhodcov",
        label: "Pozorovateľ rozhodcov",
        payload: { acceptedCompetenceTypes: ["delegate-observer"] },
      },
      {
        _id: "Videotechnik",
        label: "Videotechnik",
        payload: {
          acceptedCompetenceTypes: [
            "obsluhovatel-ecz",
            "videokameraman",
            "technical_worker",
            "clen-technickej-caty",
            "other",
          ],
        },
      },
      {
        _id: "Videorozhodca",
        label: "Videorozhodca",
        payload: {
          acceptedCompetenceTypes: [
            "obsluhovatel-ecz",
            "videokameraman",
            "technical_worker",
            "clen-technickej-caty",
            "other",
          ],
        },
      },
      {
        _id: "Asistent videorozhodcu",
        label: "Asistent videorozhodcu",
        payload: {
          acceptedCompetenceTypes: [
            "obsluhovatel-ecz",
            "videokameraman",
            "technical_worker",
            "clen-technickej-caty",
            "other",
          ],
        },
      },
    ],
  },
  {
    _id: "beachfutbal",
    items: [
      {
        _id: "Rozhodca",
        label: "Rozhodca",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Druhý rozhodca",
        label: "Druhý rozhodca",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Asistent rozhodcu (3. rozhodca)",
        label: "Asistent rozhodcu (3. rozhodca)",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Asistent rozhodcu (Časomerač)",
        label: "Asistent rozhodcu (Časomerač)",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Náhradný asistent rozhodcu",
        label: "Náhradný asistent rozhodcu",
        payload: { acceptedCompetenceTypes: ["referee"] },
      },
      {
        _id: "Delegát stretnutia",
        label: "Delegát stretnutia",
        payload: { acceptedCompetenceTypes: ["delegate"] },
      },
      {
        _id: "Hlavný usporiadateľ",
        label: "Hlavný usporiadateľ",
        payload: { acceptedCompetenceTypes: ["matchmaker"] },
      },
      {
        _id: "Hlásateľ",
        label: "Hlásateľ",
        payload: { acceptedCompetenceTypes: ["hlasatel"] },
      },
      {
        _id: "Pozorovateľ rozhodcov",
        label: "Pozorovateľ rozhodcov",
        payload: { acceptedCompetenceTypes: ["delegate-observer"] },
      },
      {
        _id: "Videotechnik",
        label: "Videotechnik",
        payload: {
          acceptedCompetenceTypes: [
            "obsluhovatel-ecz",
            "videokameraman",
            "technical_worker",
            "clen-technickej-caty",
            "other",
          ],
        },
      },
    ],
  },
];
