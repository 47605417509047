import { Brick } from '../../library/Petitpress';

const getHtmlElementsFromBricks = (bricks: Brick[]) => {
  const range = document.createRange();
  return bricks.reduce((acc, item) => {
    if (item.type === 'asset' && !!item.as) {
      const el = document.createElement(item.as);
      if (item.url) {
        el.setAttribute('src', item.url);
      }
      if (item.attr) {
        Object.entries(item.attr).forEach(([key, val]) => {
          el.setAttribute(key, val);
        });
      }
      if (el.tagName === 'SCRIPT' && el.id === 'pp-cnst-main-lib') {
        el.onload = () => {
          window.dispatchEvent(
            new CustomEvent('SmeConsent.PageModifiersTrigger', {}),
          );
        };
      }
      acc.push(el);
    } else if (item.type === 'html' && item.html) {
      // vyparsujeme HTML
      const el = range.createContextualFragment(item.html);
      acc.push(el);
    }
    return acc;
  }, [] as (HTMLScriptElement | HTMLStyleElement | DocumentFragment)[]);
};

export default getHtmlElementsFromBricks;
