import React from 'react';
import styled from '../../theme/styled-components';
import Tabber, { ITab } from '../../components/FutbalnetTabber';
import {
  ClubFilterSuggestions,
  TransfersFilter,
  TransferType,
} from '../../library/Transfermarket';
import { serializeHashParams } from '../../utilities/tvProgram';
import urlConverter from '../../urlConverter';
import SuggestionSearch from '../../components/v2/SuggestionSearch';
import CoreApi from '../../api/CoreApi';
import config from '../../config';
import __ from '../../utilities/__';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import FilterButton from '../../components/v2/FilterButton';
import { getFilterBadgeNumber } from './utilities';
import useIsResponsiveLayout from '../../hooks/useIsResponsiveLayout';
import isBrowser from '../../utilities/isBrowser';

const MobileFilterFirstRow = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const FilterButtonDesktop = styled(FilterButton)`
  display: none;
  ${mb('s')} {
    display: flex;
    flex: 0 0 auto;
  }
`;

const FilterButtonMobile = styled(FilterButton)`
  display: flex;
  flex: 0 0 auto;
  ${mb('s')} {
    display: none;
  }
`;

const PositionedSuggestionSearch = styled(SuggestionSearch)`
  margin-bottom: ${rem(24)};
  margin-right: ${rem(16)};
  flex: 1 1 auto;
  ${mb('s')} {
    margin-right: ${rem(12)};
    margin-bottom: 0;
  } ;
`;

const PositionedTabber = styled(Tabber)`
  flex: 0 1 auto;
  margin-top: ${rem(-8)};
  margin-bottom: ${rem(-8)};
  margin-right: 0;
  ${mb('s')} {
    margin: ${rem(-8)} ${rem(12)} ${rem(-8)} 0;
    width: auto;
  } ;
`;

const Wrapper = styled.div`
  margin-bottom: ${rem(24)};
  display: flex;
  flex-direction: column;
  ${mb('s')} {
    flex-direction: row;
    align-items: center;
  }
`;

interface OwnProps {
  className?: string;
  activeFilter: TransfersFilter;
  onChangeActiveFilter: (filter: TransfersFilter) => void;
  onFilterButtonClick: () => void;
  pathname: string;
  search?: string;
}

type Props = OwnProps;

const Filter: React.FC<Props> = ({
  className,
  activeFilter,
  pathname,
  search,
  onChangeActiveFilter,
  onFilterButtonClick,
}) => {
  const { ppo, ppoName } = activeFilter ?? { ppo: '', ppoName: '' };
  const [suggestions, setSugestions] = React.useState<ClubFilterSuggestions>({
    query: '',
    options: [],
  });
  const [isFetchingClubs, setIsFetchingClubs] = React.useState(false);
  const TRANSFER_TYPES_TABS: ITab<TransferType>[] = [
    {
      label: 'Všetko',
      value: 'all',
      to: `${pathname}${search}${serializeHashParams({
        ...activeFilter,
        transferType: '',
      })}`,
    },
    {
      label: 'Prestupy',
      value: 'transfer',
      to: `${pathname}${search}${serializeHashParams({
        ...activeFilter,
        transferType: 'transfer',
      })}`,
    },
    {
      label: 'Hosťovania',
      value: 'hosting',
      to: `${pathname}${search}${serializeHashParams({
        ...activeFilter,
        transferType: 'hosting',
      })}`,
    },
  ];

  const mobileLayout = isBrowser() && useIsResponsiveLayout(600);

  // zvyraznovanie tychto filtrov mozme az v browsri po tom, co SSR vygeneruje stranku
  // dolezite je ze cely filter sa riadi cez # ktore SSR server aj tak nevidí.
  const [ssrActiveFilter, setSsrActiveFilter] = React.useState<{
    badgeNumber?: number;
    transferType?: string;
  }>({
    badgeNumber: undefined,
    transferType: undefined,
  });

  React.useEffect(() => {
    if (isBrowser() && activeFilter) {
      setSsrActiveFilter({
        badgeNumber: getFilterBadgeNumber(activeFilter),
        transferType: activeFilter?.transferType || 'all',
      });
    } else {
      setSsrActiveFilter({
        badgeNumber: undefined,
        transferType: undefined,
      });
    }
  }, [setSsrActiveFilter, activeFilter]);

  React.useEffect(() => {
    setSugestions({
      options: [],
      query: ppoName || ppo || '',
    });
  }, [ppoName, ppo]);

  const getClubUrl = React.useCallback(
    ({ klub, ppoName }: { klub: string | number; ppoName: string }) => {
      let ppo = klub;
      const convertedUnionData = urlConverter.find(
        (i) => i._id === klub || i.__issfId === Number(klub),
      );
      if (convertedUnionData) {
        ppo = convertedUnionData._id;
      }
      return `${pathname}${search}${serializeHashParams({
        ...activeFilter,
        ppo,
        ppoName,
      })}`;
    },
    [activeFilter, pathname, search],
  );

  const handleClubSearchChange = (searchValue: string) => {
    if (!searchValue) {
      setSugestions({ query: '', options: [] });
      onChangeActiveFilter({
        ...activeFilter,
        ppo: undefined,
        ppoName: undefined,
      });
    } else {
      setSugestions({
        options: [],
        query: searchValue,
      });
    }
  };

  const handleClubSearchDebouncedChange = async (searchValue: string) => {
    setSugestions((prev) => {
      return {
        options: [],
        query: searchValue,
      };
    });
    if (searchValue.length > 3) {
      setIsFetchingClubs(true);
      try {
        const res = await CoreApi.organizationPPOListRelatedPPOs(
          config.OWNER_PPO,
          { q: searchValue, ppoType: ['club'] },
        );
        const sugg = (res.items || []).reduce(
          (acc, item) => {
            const { _id, name } = item as { _id: string; name: string };
            acc.options.push({
              id: _id,
              label: name,
              url: getClubUrl({ klub: _id || '', ppoName: name }),
            });
            return acc;
          },
          { query: searchValue, options: [] } as ClubFilterSuggestions,
        );
        setSugestions(sugg);
      } catch (e: any) {
        alert(__('Nepodarilo sa získať zoznam klubov'));
      } finally {
        setIsFetchingClubs(false);
      }
    }
  };

  return (
    <Wrapper className={className}>
      <MobileFilterFirstRow>
        <PositionedSuggestionSearch
          isFetching={isFetchingClubs}
          value={suggestions.query}
          suggestions={suggestions.options}
          onChange={handleClubSearchChange}
          onDebouncedChange={handleClubSearchDebouncedChange}
          placeholder="Vyhľadať klub"
        />
        <FilterButtonMobile
          onClick={onFilterButtonClick}
          badgeNumber={ssrActiveFilter.badgeNumber}
        />
      </MobileFilterFirstRow>
      <PositionedTabber
        centered={mobileLayout}
        tabs={TRANSFER_TYPES_TABS}
        active={ssrActiveFilter?.transferType}
        size="responsive"
      />
      <FilterButtonDesktop
        onClick={onFilterButtonClick}
        badgeNumber={ssrActiveFilter.badgeNumber}
      />
    </Wrapper>
  );
};

export default React.memo(Filter);
