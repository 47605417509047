import isSameDay from 'date-fns/isSameDay';
import { ThirdPartyGrouppedEvents } from '../../library/thirdPartyResults';

/**
 * Hladanie Sportnet zapasu prebieha na zaklade ID ligy a ID timov tretej strany
 * 
 * @param startDate 
 * @param params 
 * @param data 
 * @returns 
 */
const findEventInThirdPartyResults = (
  params: {
    startDate: Date,
    leagueCup: number;
    thirdPartyHomeTeamId: number;
    thirdPartyAwayTeamId: number;
  },
  data: ThirdPartyGrouppedEvents[],
) => {
  const competition = data.find((data) => data.leagueCup === params.leagueCup);
  if (competition) {
    return competition.events.find((event) => {
      if (
        Array.isArray(event.thirdpartyTeamIds) &&
        event.thirdpartyTeamIds.length === 2
      ) {
        const homeTeamId = event.thirdpartyTeamIds[0];
        const awayTeamId = event.thirdpartyTeamIds[1];
        if (
          params.thirdPartyHomeTeamId === homeTeamId &&
          params.thirdPartyAwayTeamId === awayTeamId &&
          isSameDay(params.startDate, new Date(event.date))
        ) {
          return true;
        }
      }
      return false;
    });
  }
  return undefined;
};

export default findEventInThirdPartyResults;
