import Cookies from 'js-cookie';
import config from './config';

const ad = Cookies.get(config.DEBUG_AD_COOKIE_KEY) === '1';
const adInit = Cookies.get(config.DEBUG_AD_INIT_COOKIE_KEY) === '1';

export default {
  ad,
  adInit,
};
