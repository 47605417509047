import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M6,8h4v2H6v8h4v2H4v-1v-1v-8V8V3h2V8z M20,6v6c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1V6c0-0.6,0.4-1,1-1h6
    C19.6,5,20,5.4,20,6z M18,7h-4v4h4V7z M20,16v6c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1h6C19.6,15,20,15.4,20,16z
     M18,17h-4v4h4V17z"
    />
  </svg>
);
