import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { ITheme } from '../../../theme/theme';
import ItemsDropDown from '../../../components/DropDowns/ItemsDropDown';
import { getSharingHref } from '../../../components/Share/utils';
import copyToClipboard from 'clipboard-copy';
import Icon from '../../../components/Icon';
import __ from '../../../utilities/__';

export const BottomItmStyles = css`
  display: flex;
  align-items: center;
  gap: ${rem(8)};
  span {
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.color.textColor};
    font-weight: 600;
  }
  ${mb('m')} {
    span {
      font-size: ${rem(13)};
    }
  }
`;

const BottomItem = styled.div`
  ${BottomItmStyles}
`;

export const ShareButton = styled.button`
  border: none;
  background: transparent;
  width: ${rem(30)};
  height: ${rem(30)};
  margin: -${rem(10)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface OwnProps {
  detailUrl: string;
}

const GossipShareDropdown: React.FC<OwnProps> = ({ detailUrl }) => {
  const theme = useTheme() as ITheme;

  const [dropDownOpened, setDropDownOpened] = React.useState(false);
  const [isCopyOk, setIsCopyOK] = React.useState(false);

  const openDropDown = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setDropDownOpened(true);
  };

  return (
    <ItemsDropDown
      position="right"
      items={[
        {
          label: (
            <BottomItem>
              <Icon name="facebook" color={theme.color.facebook} />{' '}
              <span>Facebook</span>
            </BottomItem>
          ),
          onClick: () => {
            window.open(
              getSharingHref('facebook', detailUrl) || '',
              '',
              `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=745,width=600`
            );
          },
        },
        {
          label: (
            <BottomItem>
              <Icon name="twitter" /> <span>X.com</span>
            </BottomItem>
          ),
          onClick: () => {
            window.open(
              getSharingHref('twitter', detailUrl) || '',
              '',
              `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600`
            );
          },
        },
        {
          label: (
            <BottomItem>
              <Icon name="link" /> <span>{__('Kopírovať odkaz')}</span>
            </BottomItem>
          ),
          onClick: () => {
            setIsCopyOK(false);
            setTimeout(async () => {
              await copyToClipboard(detailUrl);
              setIsCopyOK(true);
              setTimeout(() => setIsCopyOK(false), 1500);
            }, 100);
          },
        },
      ]}
      opened={dropDownOpened}
      onClose={() => setDropDownOpened(false)}
    >
      <BottomItem>
        <ShareButton onClick={openDropDown}>
          <Icon
            name={isCopyOk ? 'check' : 'link'}
            color={isCopyOk ? theme.color.success : theme.textColor}
          />
        </ShareButton>
      </BottomItem>
    </ItemsDropDown>
  );
};

export default GossipShareDropdown;
