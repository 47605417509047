import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RootState } from '../../configureStore';
import { appSettingsSelector } from '../../pages/DomainResolver/selectors';
import useAnalytics from './useAnalytics';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & IMapStateToProps;

const Analytics: React.FC<Props> = ({ appSettings }) => {
  const { script, noscript } = useAnalytics(appSettings);

  const anyAnalytics = script.length > 0 || noscript.length > 0;

  if (!anyAnalytics) {
    return null;
  }

  return (
    <>
      <Helmet>{script}</Helmet>
      {noscript}
    </>
  );
};

export default connect(mapStateToProps)(Analytics);
