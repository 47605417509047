import React from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Breadcrumb } from '../../library/App';
import { ROOT_BREADCRUMB as PORTAL_ROOT_BREADCRUMB } from '../SectionsBreadcrumbs';

export const ROOT_BREADCRUMB = {
  label: 'Futbalnet',
  url: '/futbalnet/',
};

type IOwnProps = {
  className?: string;
  crumbs: Breadcrumb[];
};

const FutbalnetUnionBreadcrumbs: React.FC<IOwnProps> = ({
  className,
  crumbs,
}) => {
  return (
    <Breadcrumbs
      className={className}
      crumbs={[PORTAL_ROOT_BREADCRUMB, ROOT_BREADCRUMB, ...crumbs]}
    />
  );
};

export default FutbalnetUnionBreadcrumbs;
