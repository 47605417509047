import React from 'react';
import {
  ICompetitionPart,
  IResultsTableItem,
} from '../../../library/Competitions';
import { IBaseObjectName } from '../../../library/CompetitionStanding';
import { ArrayElement } from '../../../library/App';
import getPpoLogoSrc from '../../../utilities/getPpoLogoSrc';
import { ClubLogo, Td } from '../CompetitionMatchesPrint/MatchListItem';
import __ from '../../../utilities/__';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';

const DEFAULT_IF_UNDEFINED = '-';

interface OwnProps {
  idx: number;
  resultsTableItem: IResultsTableItem;
  tableView: IBaseObjectName;
  team: ArrayElement<Required<ICompetitionPart>['teams']>;
  highlighted: boolean;
  sportSector?: string;
}

const RenderCompetitionStandingPrintDataItem: React.FC<OwnProps> = ({
  idx,
  resultsTableItem,
  tableView,
  team,
  highlighted,
  sportSector,
}) => {
  const { baseObject } = React.useMemo(() => {
    if (tableView === 'homeStats' && resultsTableItem.homeStats) {
      return {
        baseObject: resultsTableItem.homeStats,
        // pouziva sa na zobrazenie kondicie druzstva
        // baseArray: resultsTableItem.homeMatches ?? [],
      };
    } else if (tableView === 'awayStats' && resultsTableItem.awayStats) {
      return {
        baseObject: resultsTableItem.awayStats,
        // pouziva sa na zobrazenie kondicie druzstva
        // baseArray: resultsTableItem.awayMatches ?? [],
      };
    } else {
      return {
        baseObject: resultsTableItem.stats,
        // pouziva sa na zobrazenie kondicie druzstva
        // baseArray: resultsTableItem.matches ?? [],
      };
    }
  }, [resultsTableItem, tableView]);

  const orgLogoSrc = team?.organization ? getPpoLogoSrc(team.organization) : '';

  return sportSector === 'beachfutbal' ? (
    <Tr bold={highlighted}>
      <Td>{idx + 1}</Td>
      <Td>
        <ClubLogo src={orgLogoSrc} alt={team.name}></ClubLogo>
      </Td>
      <Td>
        {team.name}
        {team.resignation ? ` (${__('odstúpené')})` : ''}
      </Td>
      <Td>{baseObject?.matches.played ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.wonNT ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.wonET ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.wonP ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>
        {baseObject?.goals.given ?? DEFAULT_IF_UNDEFINED}:
        {baseObject?.goals.received ?? DEFAULT_IF_UNDEFINED}
      </Td>
      <Td>{baseObject?.points ?? DEFAULT_IF_UNDEFINED}</Td>
    </Tr>
  ) : (
    <Tr bold={highlighted}>
      <Td>{idx + 1}</Td>
      <Td>
        <ClubLogo src={orgLogoSrc} alt={team.name}></ClubLogo>
      </Td>
      <Td>
        {team.name}
        {team.resignation ? ` (${__('odstúpené')})` : ''}
      </Td>
      <Td>{baseObject?.matches.played ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.won ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.draw ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>{baseObject?.matches.lost ?? DEFAULT_IF_UNDEFINED}</Td>
      <Td>
        {baseObject?.goals.given ?? DEFAULT_IF_UNDEFINED}:
        {baseObject?.goals.received ?? DEFAULT_IF_UNDEFINED}
      </Td>
      <Td>{baseObject?.points ?? DEFAULT_IF_UNDEFINED}</Td>
      {resultsTableItem.stats.issf && (
        <>
          <Td>
            {resultsTableItem.stats.issf.koeficientPreU17U19 ??
              DEFAULT_IF_UNDEFINED}
          </Td>
          <Td>{resultsTableItem.stats.issf.PM ?? DEFAULT_IF_UNDEFINED}</Td>
          <Td>{resultsTableItem.stats.issf.FP ?? DEFAULT_IF_UNDEFINED}</Td>
        </>
      )}
    </Tr>
  );
};

export default RenderCompetitionStandingPrintDataItem;
