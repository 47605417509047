import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManagerContextProvider from '../../components/Ads/AdManager/AdManagerContextProvider';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import { IMenuItem } from '../../components/FutbalnetHeader/Menu/types';
import FutbalnetTitle from '../../components/FutbalnetHeader/Title';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import CustomSectionsDropDown from '../../containers/CustomSectionsDropDown';
import MultilevelDropdown from '../../containers/MultilevelDropdown';
import { SectionNode } from '../../library/App';
import pageViewWithDimensions from '../../library/pageViewWithDimensions';
import styled from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import getClubBaseUrl from '../../utilities/getClubBaseUrl';
import getDefaultClubCompetition from '../../utilities/getDefaultClubCompetition';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import getTeamBaseUrl from '../../utilities/getTeamBaseUrl';
import sortTeams from '../../utilities/sortTeams';
import { getSectionSubTreeByParameters, isLoadingList } from '../App/selectors';
import { loadOrganizationProfileItem } from '../Competition/actions';
import {
  currentCompetitionAppSpaceSelector,
  currentOrganizationProfileSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../Competition/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';
import FutbalnetHomeHeader from '../FutbalnetHome/FutbalnetHomeHeader';
import { loadClubCompetitions } from './actions';
import ClubUrlContextProvider from './ClubUrlContextProvider';
import { currentClubCompetitionsSelector } from './selectors';

const TitleLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${rem(8)};
  > img {
    height: ${rem(24)};
  }
  ${mb('s')} {
    margin-right: ${rem(12)};
    > img {
      height: ${rem(32)};
    }
  }
`;

const TabIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const mapStateToProps = (state: RootState) => {
  const currentAppSpace = getCurrentCompetitionAppSpaceSelector(state);
  return {
    organizationProfile: currentOrganizationProfileSelector(state),
    appSettings: appSettingsSelector(state),
    competitions: currentClubCompetitionsSelector(state),
    currentAppSpace: currentCompetitionAppSpaceSelector(state),
    isLoadingCompetitions: isLoadingList(state, config.LIST_CLUB_COMPETITIONS),
    menuSections:
      getSectionSubTreeByParameters(
        state,
        config.CLUB_SECTIONS_MENU_SECTIONID_OR_UNIQ_ID,
        { appSpace: currentAppSpace },
      )?.sections || [],
  };
};

type Props = { theme: ITheme } & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ klub: string; tim?: string }, {}>;

class ClubDetail extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const promises: any[] = [];
    promises.push(
      dispatch(
        thunkToAction((loadOrganizationProfileItem as any)({ expanded: true })),
      ),
    );
    promises.push(dispatch(thunkToAction((loadClubCompetitions as any)())));
    return Promise.all(promises);
  }

  pageViewWithDimensions() {
    pageViewWithDimensions({
      clubName: this.props.organizationProfile?.name,
    });
  }

  async componentDidMount() {
    try {
      await ClubDetail.getInitialProps(this.props);
      this.pageViewWithDimensions();
    } catch (e) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    try {
      if (this.props.currentAppSpace !== prevProps.currentAppSpace) {
        await ClubDetail.getInitialProps(this.props).then(() => {
          this.pageViewWithDimensions();
        });
      } else if (this.props.location !== prevProps.location) {
        this.pageViewWithDimensions();
      }
    } catch (e) {
      /* */
    }
  }

  render() {
    const {
      params: { klub, tim },
      organizationProfile,
      appSettings,
      children,
      menuSections,
      competitions,
      isLoadingCompetitions,
    } = this.props;

    if (!organizationProfile || isLoadingCompetitions) {
      return <Loading />;
    }

    const seasonNames = competitions
      .reduce((acc: string[], c) => {
        if (c.season && !acc.includes(c.season.name)) {
          acc.push(c.season.name);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        if (a > b) {
          return -1;
        }
        return 1;
      });

    const defaultValues = getDefaultClubCompetition(competitions);

    let qParams = this.props.location.search;

    if (
      !this.props.location.query.sutaz &&
      defaultValues &&
      defaultValues.competitionId
    ) {
      qParams = `?sutaz=${defaultValues.competitionId}`;
    }

    const competitionsArchivePane = {
      _id: 'competitionsArchiveRoot',
      name: __('Archív'),
      type: 'seasonsRoot',
      children: seasonNames.map((seasonName) => {
        const seasonCompetitionParts = competitions
          .filter((c) => c.season?.name === seasonName)
          .reduce(
            (acc, c) => [
              ...acc,
              ...(c.parts || []).map((p) => ({
                ...p,
                competitionId: c.__issfId || c._id,
              })),
            ],
            [],
          );
        const uniqTeamIds: string[] = [];
        const seasonTeams = seasonCompetitionParts
          .reduce(
            (
              acc: Array<{
                _id: string;
                __issfId?: number;
                competitionId: number | string;
                appSpace: string;
                name: string;
                gender: string;
              }>,
              p,
            ) => {
              const flattedPartTeams = (p.teams || []).map((t) => ({
                ...t,
                gender: p.rules?.gender,
                category: p.rules?.category,
                competitionId: p.competitionId,
              }));
              flattedPartTeams.forEach((t) => {
                if (!uniqTeamIds.includes(t._id)) {
                  uniqTeamIds.push(t._id);
                  acc.push(t);
                }
              });
              return acc;
            },
            [],
          )
          .sort(sortTeams);
        return {
          _id: `seasonName-${seasonName}`,
          name: seasonName,
          type: 'seasonItem',
          children: seasonTeams.map((t) => {
            return {
              _id: `seasonTeam-${t._id}`,
              name: `${t.name}${t.gender === 'F' ? ` ${__('(Ženy)')}` : ''}`,
              type: 'seasonTeam',
              url: `${getTeamBaseUrl({
                klub: t.appSpace,
                teamId: t.__issfId || t._id,
              })}?sutaz=${String(t.competitionId)}`,
            };
          }),
        };
      }),
    };

    const menuItems: IMenuItem[] = [
      {
        id: 'overview',
        label: __('Prehľad'),
        url: getClubBaseUrl({ klub }),
      },
      {
        id: 'results',
        label: __('Výsledky'),
        url:
          tim || defaultValues?.teamId
            ? `${getTeamBaseUrl({
                klub,
                teamId: tim || defaultValues?.teamId || '',
              })}${qParams}`
            : `${getClubBaseUrl({ klub })}vysledky/${qParams}`,
      },
      {
        id: 'fixtures',
        label: __('Program'),
        url:
          tim || defaultValues?.teamId
            ? `${getTeamBaseUrl({
                klub,
                teamId: tim || defaultValues?.teamId || '',
              })}program/${qParams}`
            : `${getClubBaseUrl({ klub })}program/${qParams}`,
      },
      {
        id: 'tables',
        label: __('Tabuľky'),
        url:
          tim || defaultValues?.teamId
            ? `${getTeamBaseUrl({
                klub,
                teamId: tim || defaultValues?.teamId || '',
              })}tabulky/${qParams}`
            : `${getClubBaseUrl({ klub })}tabulky/${qParams}`,
      },
      {
        id: 'players',
        label: __('Súpiska'),
        url:
          tim || defaultValues?.teamId
            ? `${getTeamBaseUrl({
                klub,
                teamId: tim || defaultValues?.teamId || '',
              })}hraci/${qParams}`
            : `${getClubBaseUrl({ klub })}hraci/${qParams}`,
      },
      {
        id: 'disclosures',
        label: __('Zverejňovanie'),
        url: `${getClubBaseUrl({ klub })}zverejnovanie/`,
      },
      ...((competitionsArchivePane.children || []).length
        ? ([
            {
              id: 'archive',
              label: __('Archív'),
              renderDropdown: (dropdownProps) => {
                return (
                  <MultilevelDropdown
                    {...dropdownProps}
                    panes={competitionsArchivePane.children}
                    header={__('Ročník')}
                  />
                );
              },
            },
          ] as IMenuItem[])
        : []),
    ];

    // ostatne (custom) sekcie klubu do tabu "Viac"
    if ((menuSections || []).length) {
      menuItems.push({
        id: 'sections-menu',
        label: () => (
          <>
            <TabIcon name="more" size={12} />
            {__('Viac')}
          </>
        ),
        renderDropdown: (dropdownProps) => {
          return (
            <CustomSectionsDropDown
              {...dropdownProps}
              sections={
                menuSections as Array<
                  SectionNode & { sections?: SectionNode[] }
                >
              }
            />
          );
        },
      });
    }

    const clubBaseUrl = `${appSettings.baseUri}${getClubBaseUrl({
      klub,
    })}`;

    const orgLogoSrc = getPpoLogoSrc(
      organizationProfile as Required<typeof organizationProfile>,
    );

    const description = `${organizationProfile.name}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, program zápasov, štatistiky, súpisku.',
    )}`;

    return (
      <ClubUrlContextProvider>
        <Helmet>
          <title>{organizationProfile.title}</title>
          <meta property="og:url" content={clubBaseUrl} />
          <meta property="og:title" content={organizationProfile.title} />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
          {!!orgLogoSrc && <meta property="og:image" content={orgLogoSrc} />}
        </Helmet>
        <FutbalnetHomeHeader />
        <FutbalnetHeader>
          <FutbalnetTitleBlock>
            {!!orgLogoSrc && (
              <TitleLogoWrapper>
                <img alt={organizationProfile.name} src={orgLogoSrc} />
              </TitleLogoWrapper>
            )}
            <FutbalnetTitle>{organizationProfile.name}</FutbalnetTitle>
          </FutbalnetTitleBlock>
          <FutbalnetMenu items={menuItems} />
        </FutbalnetHeader>
        <AdManagerContextProvider
          clubName={organizationProfile.name}
          origin="futbalnet"
          pagetype="other"
        >
          {children}
        </AdManagerContextProvider>
      </ClubUrlContextProvider>
    );
  }
}

export default compose(connect(mapStateToProps))(ClubDetail);
