import React from 'react';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import { Td } from './MatchDetailPrintMatchEvent';
import getMatchDetailDataForPrint from '../../../utilities/print/getMatchDetailDataForPrint';
import __ from '../../../utilities/__';
import { NominationItemWrapper } from './MatchDetailPrintNominations';

interface OwnProps {
  sportGround: ReturnType<typeof getMatchDetailDataForPrint>['sportGround'];
}

const MatchDetailPrintStadium: React.FC<OwnProps> = ({ sportGround }) => {
  return (
    <>
      <Tr>
        <Td colSpan={6}>
          <small>
            <strong>{__('Kapacita')}</strong>
          </small>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('Miest na sedenie')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.seatingCapacity || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('Hosťovský sektor')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.guestCapacity || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('VIP')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.vipSeatingCapacity || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('Celková kapacita')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.totalCapacity || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={6}>
          <small>
            <strong>{__('Rozmer ihriska')}</strong>
          </small>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('Dĺžka')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.length || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>
          <NominationItemWrapper percentWidth={25}>
            {__('Šírka')}
          </NominationItemWrapper>
          <NominationItemWrapper percentWidth={75}>
            {sportGround.width || '-'}
          </NominationItemWrapper>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={6}>
          <small>
            <strong>{__('Majiteľ')}</strong>
          </small>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>{sportGround.owner || '-'}</Td>
      </Tr>
      <Tr>
        <Td colSpan={6}>
          <small>
            <strong>{__('Adresa')}</strong>
          </small>
        </Td>
      </Tr>
      <Tr>
        <Td colSpan={4}>{sportGround.address}</Td>
      </Tr>
    </>
  );
};

export default MatchDetailPrintStadium;
