import React from 'react';

export default (size: string, color: string) => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  width={size}
  height={size}
>
  <path
    fill={color}
    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,6c1.9,0,3.5,1.6,3.5,3.5S13.9,13,12,13
s-3.5-1.6-3.5-3.5S10.1,6,12,6z M12,20c-2.4,0-4.6-1.1-6.1-2.9c3.6-2.8,8.7-2.8,12.3,0C16.6,18.9,14.4,20,12,20z"
  />
</svg>
);
