import Col, { Row } from '@sportnet/ui/lib/Grid';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { withTheme } from 'styled-components';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Link from '../../../components/Link';
import Spacer from '../../../components/Spacer';
import Tabber from '../../../components/Tabber';
import { RootState } from '../../../configureStore';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { RouteProps } from '../../../library/App';
import {
  IAthlete,
  IMatch,
  IMatchNomination,
  IMatchNominationCrewItem,
  ITeam,
  PPO,
} from '../../../library/Competitions';
import styled, { css } from '../../../theme/styled-components';
import { ITheme } from '../../../theme/theme';
import getMemberBaseUrl from '../../../utilities/getMemberBaseUrl';
import getPpoLogoSrc from '../../../utilities/getPpoLogoSrc';
import __ from '../../../utilities/__';
import {
  sportSectorCrewSelector,
  sportSectorEventsSelector,
} from '../../App/selectors';
import PrintNotFound from '../../FutbalnetPrint/PrintNotFound';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import { Icon as EventIcon } from '../Overview/MatchTimeLine';
import { currentMatchSelector } from '../selectors';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';

const CustomLink = styled(Link)`
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

const Nomination = styled.div<{ visible: boolean }>`
  color: #333;
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
  ${mb('s')} {
    padding: ${rem(32)} 0;
    display: block;
  }
`;
const NominationTitle = styled.div`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${mb('s')} {
    justify-content: flex-start;
  }
  img {
    width: auto;
    height: ${rem(32)};
    margin-right: ${rem(16)};
    display: none;
    ${mb('s')} {
      display: block;
    }
  }
`;

const NominationItems = styled.div`
  font-size: ${rem(12)};
  padding-top: ${rem(32)};
  & > div {
    margin-left: -${rem(5)};
  }
`;

const NominationSegmentTitle = styled.div`
  font-weight: bold;
  padding-bottom: ${rem(12)};
  padding-left: ${rem(16)};
`;

const ListRow = styled.div<{ lastIndex: boolean }>`
  display: flex;
  font-weight: normal;
  justify-content: space-between;
  ${({ lastIndex }) => {
    if (lastIndex) {
      return css`
        border-width: 0;
      `;
    }
    return css``;
  }}
`;

const ListCol = styled.div<{ flexWrap?: boolean }>`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (!!flexWrap ? 'wrap' : 'no-wrap')};
  align-items: center;
  > div {
    padding: ${rem(8)} ${rem(16)};
  }
`;

const CrewMemberName = styled.div`
  width: ${rem(140)};
`;

const NominationSegment = styled.div`
  margin-left: -${rem(16)};
  margin-right: -${rem(16)};
  ${mb('s')} {
    margin-left: 0;
    margin-right: 0;
  }
  ${ListRow}:nth-child(even) {
    background: #f9f9f9;
  }
  ${ListRow}:nth-child(2) {
    border-top: ${rem(1)} solid #f1f1f1;
  }
`;

const EventIconWrapper = styled.div`
  padding: 0 ${rem(4)} !important;
`;

const TabberWrapper = styled.div`
  ${mb('s')} {
    display: none;
  }
`;

const PrintRow = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  justify-content: space-between;
`;

const mapStateToProps = (state: RootState) => ({
  sportSectorEvents: sportSectorEventsSelector(state),
  sportSectorCrew: sportSectorCrewSelector(state),
  match: currentMatchSelector(state) as IMatch,
});

interface IOwnProps {
  isActive: boolean;
  print?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & {
  theme: ITheme;
} & RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  IOwnProps;

const MatchNomination: React.FC<Props> = ({
  isActive,
  print,
  theme,
  sportSectorCrew,
  sportSectorEvents,
  match,
}) => {
  const [activeNomination, setActiveNomination] = React.useState('homeTeam');
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  const renderAthletesList = (appSpace: string, athletes: IAthlete[]) => {
    return athletes.map((athlete, idx) => {
      let athleteEvents: any[] = [];
      if (match && match.protocol) {
        const events = ((match.protocol || { events: [] }).events || [])
          .filter((event: any) => event.eventTime)
          .map((event: any) => {
            const timeParts = event.eventTime.split(':');
            const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
            return {
              ...event,
              eventTime: seconds,
            };
          })
          .filter((i: any) => i.type !== 'goal_shootout');

        if (athlete.sportnetUser) {
          const athleteSportnetId = athlete.sportnetUser._id;
          athleteEvents = events.filter(
            (event: any) =>
              (event.player && event.player._id === athleteSportnetId) ||
              (event.replacement &&
                event.replacement._id === athleteSportnetId),
          );
        }
      }

      return (
        <ListRow
          key={athlete.sportnetUser._id}
          lastIndex={athletes.length === idx + 1}
        >
          <ListCol>
            {!!athlete.additionalData && !!athlete.additionalData.nr && (
              <div
                style={{ color: 'rgba(170, 170, 170, 1)', minWidth: rem(48) }}
              >
                {athlete.additionalData.nr}
              </div>
            )}
            <CustomLink
              to={getMemberBaseUrl({ clen: athlete.additionalData.__issfId || athlete.sportnetUser._id })}
            >
              {athlete.sportnetUser.name}
              {!!athlete.additionalData && !!athlete.additionalData.captain && (
                <>
                  &nbsp;<>(C)</>
                </>
              )}
            </CustomLink>
          </ListCol>
          <ListCol flexWrap style={{ paddingRight: rem(8) }}>
            {athleteEvents.map((event, eventIdx) => {
              let eventDetail = sportSectorEvents[event.type];
              if (!eventDetail) {
                eventDetail = sportSectorEvents[event.eventType];
              }
              return (
                <EventIconWrapper key={`${event.eventType}_${eventIdx}`}>
                  <EventIcon
                    size={15}
                    title={`${eventDetail ? `${eventDetail.label} - ` : ''
                      }${Math.ceil(event.eventTime / 60)}'`}
                    eventType={event.eventType}
                    subType={event.type}
                    theme={theme as any}
                  />
                </EventIconWrapper>
              );
            })}
          </ListCol>
        </ListRow>
      );
    });
  };

  const renderCrewList = (
    crew:
      | { [key: string]: IMatchNominationCrewItem }
      | Array<{
        position: string;
        sportnetUser: { _id: string; name: string };
      }>,
  ) => {
    if (Array.isArray(crew)) {
      return crew.map((crewMember, idx) => {
        const crewItem = sportSectorCrew[crewMember.position];
        if (crewItem) {
          let crewMemberEvents: any[] = [];
          if (match && match.protocol) {
            const events = ((match.protocol || { events: [] }).events || [])
              .filter((event: any) => event.eventTime)
              .map((event: any) => {
                const timeParts = event.eventTime.split(':');
                const seconds =
                  Number(timeParts[0]) * 60 + Number(timeParts[1]);
                return {
                  ...event,
                  eventTime: seconds,
                };
              });

            if (crewMember.sportnetUser) {
              const crewMemberSportnetId = crewMember.sportnetUser._id;
              crewMemberEvents = events.filter(
                (event: any) =>
                  event.crewMember &&
                  event.crewMember._id === crewMemberSportnetId,
              );
            }
          }
          return (
            <ListRow
              key={`${crewMember.sportnetUser._id}_${idx}`}
              lastIndex={crew.length === idx + 1}
            >
              <ListCol style={{ width: '100%' }}>
                <CrewMemberName>
                  {getProp(crewMember, ['sportnetUser', 'name'])}
                </CrewMemberName>
                <div>{crewItem.label}</div>
              </ListCol>
              <ListCol flexWrap style={{ paddingRight: rem(8) }}>
                {crewMemberEvents.map((event, eventIdx) => {
                  let eventDetail = sportSectorEvents[event.type];
                  if (!eventDetail) {
                    eventDetail = sportSectorEvents[event.eventType];
                  }
                  return (
                    <EventIconWrapper key={`${event.eventType}_${eventIdx}`}>
                      <EventIcon
                        size={15}
                        title={`${eventDetail ? `${eventDetail.label} - ` : ''
                          }${Math.ceil(event.eventTime / 60)}'`}
                        eventType={event.eventType}
                        subType={event.type}
                        theme={theme as any}
                      />
                    </EventIconWrapper>
                  );
                })}
              </ListCol>
            </ListRow>
          );
        }
        return null;
      });
    }
    return Object.keys(crew).map((key, idx) => {
      const crewItem = sportSectorCrew[key];
      if (crewItem) {
        return (
          <ListRow
            key={`${key}_${idx}`}
            lastIndex={Object.keys(crew).length === idx + 1}
          >
            <ListCol>
              <div>
                {getProp(
                  crew as any,
                  [key, 'label'],
                  getProp(crew, [key, 'displayName'], crew[key]),
                )}
              </div>
            </ListCol>
            <ListCol>
              <div>{crewItem.label}</div>
            </ListCol>
          </ListRow>
        );
      }
      return null;
    });
  };

  const renderNomination = (
    homeAway: string,
    team: ITeam,
    nomination?: IMatchNomination | null,
  ) => {
    const athletes = nomination ? nomination.athletes || [] : [];
    const substitutes = athletes.filter(
      (i) => i.additionalData && i.additionalData.substitute,
    );

    const orgLogoSrc = team.organization?._id
      ? getPpoLogoSrc(team.organization as Required<PPO>)
      : '';

    return (
      <Nomination visible={activeNomination === homeAway}>
        <NominationTitle>
          {orgLogoSrc && <img alt="org_logo" src={orgLogoSrc} />}
          <div>{team.organization?.name}</div>
        </NominationTitle>
        {!!nomination && nomination.athletes.length > 0 ? (
          <NominationItems>
            <Spacer size={4}>
              {!!athletes.length && (
                <NominationSegment>
                  <NominationSegmentTitle>
                    {homeAway === 'homeTeam' ? __('Domáci') : __('Hostia')}
                  </NominationSegmentTitle>
                  {renderAthletesList(
                    team.appSpace,
                    nomination.athletes.filter(
                      (i) => !i.additionalData || !i.additionalData.substitute,
                    ),
                  )}
                </NominationSegment>
              )}
              {!!substitutes.length && (
                <NominationSegment>
                  <NominationSegmentTitle>
                    {__('Náhradníci')}
                  </NominationSegmentTitle>
                  {renderAthletesList(team.appSpace, substitutes)}
                </NominationSegment>
              )}
              {((Array.isArray(nomination.crew) &&
                (nomination.crew || []).length) ||
                !!Object.keys(nomination.crew || {}).length) && (
                  <NominationSegment>
                    <NominationSegmentTitle>
                      {__('Realizačný tím')}
                    </NominationSegmentTitle>
                    {renderCrewList(nomination.crew as any)}
                  </NominationSegment>
                )}
            </Spacer>
          </NominationItems>
        ) : (
          <>
            {print ? (
              <PrintNotFound text={__('Nominácia zatiaľ nebola uzatvorená')} />
            ) : (
              <ContentNotFound
                title={__('Nominácia zatiaľ nebola uzatvorená')}
              />
            )}
          </>
        )}
      </Nomination>
    );
  };

  if (!match) {
    return null;
  }

  const homeTeam = (match.teams || []).find(
    (i) => getProp(i, ['additionalProperties', 'homeaway'], '') === 'home',
  );
  const awayTeam = (match.teams || []).find(
    (i) => getProp(i, ['additionalProperties', 'homeaway'], '') === 'away',
  );

  let homeTeamNomination: any = null;
  if (homeTeam && match.nominations) {
    homeTeamNomination = match.nominations.find(
      (i) => i.teamId === homeTeam._id,
    );
  }
  let awayTeamNomination: any = null;
  if (awayTeam && match.nominations) {
    awayTeamNomination = match.nominations.find(
      (i) => i.teamId === awayTeam._id,
    );
  }

  return (
    <>
      <RempIntegration destroy />
      {print ? (
        <>
          <PrintRow>
            {!!homeTeam && !!awayTeam && (
              <>
                <Col xs={12} m={6}>
                  {renderNomination('homeTeam', homeTeam, homeTeamNomination)}
                </Col>

                <Col xs={12} m={6}>
                  {renderNomination('homeTeam', awayTeam, awayTeamNomination)}
                </Col>
              </>
            )}
          </PrintRow>
        </>
      ) : (
        <>
          {isActive && <TopAd />}
          {isActive && <BottomPaddedBigAd name="big_1" />}

          <PaddedSidebarLayout className="sptn-main-content">
            <SidebarLayoutContent>
              <TabberWrapper>
                <Tabber
                  active={activeNomination}
                  onChange={(i) => setActiveNomination(i.value)}
                  centered
                  tabs={[
                    { label: __('Domáci'), value: 'homeTeam' },
                    { label: __('Hostia'), value: 'awayTeam' },
                  ]}
                />
              </TabberWrapper>
              <Row>
                {!!homeTeam && (
                  <Col xs={12} m={6}>
                    {renderNomination('homeTeam', homeTeam, homeTeamNomination)}
                  </Col>
                )}
                {!!awayTeam && (
                  <Col xs={12} m={6}>
                    {renderNomination('awayTeam', awayTeam, awayTeamNomination)}
                  </Col>
                )}
              </Row>
              <ResponsiveTopArticlesSidebar
                activeTab={activeTabTopArticles}
                setActiveTab={setActiveTabTopArticles}
              />
            </SidebarLayoutContent>
            <Sidebar>
              <TopArticlesSidebar
                activeTab={activeTabTopArticles}
                setActiveTab={setActiveTabTopArticles}
              />
              <StickySidebarContainer>
                {isActive && <SideAd name="side_1" />}
              </StickySidebarContainer>
            </Sidebar>
          </PaddedSidebarLayout>
          {isActive && (
            <>
              <MegaboardAd />
              <FutbalnetMatchBreadcrumbs />
            </>
          )}
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  withTheme,
  connect(mapStateToProps),
)(MatchNomination) as unknown as React.FC<IOwnProps>;
