import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import MaxWidthBox from '../../../../../components/MaxWidthBox';
import SearchInput from '../../../../../components/SearchInput';
import styled, {
  createGlobalStyle,
  css,
} from '../../../../../theme/styled-components';
import __ from '../../../../../utilities/__';
import {
  FIXED_MENU_HEIGHT,
  MAIN_MENU_HEIGHT_DESKTOP,
  MAIN_MENU_HEIGHT_TABLET,
} from './constants';

const bodyOverflowHiddenClass = 'search-opened';

const GlobalStyle = createGlobalStyle`
  body.${bodyOverflowHiddenClass} {
    overflow: hidden;
  }
`;
interface SearchRowProps {
  backgroundColor: string;
  borderColor: string;
  isMenuFixed: boolean;
}
const SearchRow = styled.div<SearchRowProps>`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 103;
  background: ${({ backgroundColor }) => backgroundColor};
  border-bottom: ${rem(1)} solid ${({ borderColor }) => borderColor};

  top: ${rem(FIXED_MENU_HEIGHT + 1)};
  max-height: calc(100vh - ${rem(FIXED_MENU_HEIGHT + 1)});

  ${({ isMenuFixed }) =>
    !isMenuFixed &&
    css`
      top: ${rem(MAIN_MENU_HEIGHT_TABLET + 1)};
      max-height: calc(100vh - ${rem(MAIN_MENU_HEIGHT_TABLET + 1)});
      ${mb('l')} {
        top: ${rem(MAIN_MENU_HEIGHT_DESKTOP + 1)};
        max-height: calc(100vh - ${rem(MAIN_MENU_HEIGHT_DESKTOP + 1)});
      }
    `}
`;

const SearchWrapper = styled(MaxWidthBox)`
  height: ${rem(80)};
  display: flex;
  align-items: center;
  & > * {
    flex-grow: 1;
  }
`;

interface IOwnProps {
  searchQuery: string;
  onChangeSearchQuery: (inputValue: string) => void;
  onSearchSubmit: () => void;

  textColor: string;
  backgroundColor: string;
  borderColor: string;

  searchActive: boolean;
  onClose: () => void;
  isMenuFixed: boolean;
}

type IProps = IOwnProps;

const Search: React.FC<IProps> = ({
  searchQuery,
  onChangeSearchQuery,
  onSearchSubmit,

  // textColor,
  backgroundColor,
  borderColor,

  searchActive,
  onClose,

  isMenuFixed,
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const [inputFocused, setInputFocused] = React.useState(false);

  const inputFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    setTimeout(() => {
      setInputFocused(true);
    }, 0);
  };

  React.useEffect(() => {
    const inputEl = searchInputRef.current;
    if (searchActive && inputEl) {
      inputEl.focus();
    }
  }, [searchActive]);

  React.useEffect(() => {
    if (!searchActive) {
      return;
    }
    document.body.classList.add(bodyOverflowHiddenClass);
    return () => {
      document.body.classList.remove(bodyOverflowHiddenClass);
    };
  }, [searchActive]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  if (!searchActive) {
    return null;
  }

  return (
    <SearchRow
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      isMenuFixed={isMenuFixed}
    >
      <SearchWrapper>
        <GlobalStyle />
        <SearchInput
          value={searchQuery}
          setValue={onChangeSearchQuery}
          inputFocused={inputFocused}
          setInputFocused={setInputFocused}
          inputFocus={inputFocus}
          inputRef={searchInputRef}
          placeholder={__('Zadajte hľadaný výraz...')}
          onKeyDown={handleKeyDown}
        />
      </SearchWrapper>
    </SearchRow>
  );
};

export default React.memo(Search);
