import Link from '../../../components/Link';
import { getIconColor } from '../../TertiaryButton/TertiaryButton';
import { rem } from 'polished';
import Icon from '../../../components/Icon';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { BaseButton } from '../../PrimaryButton/PrimaryButton';
import { ITheme } from '../../../theme/theme';
import { DropDownItemProps } from '../../types/DropDown';
import { mb } from '@sportnet/ui/lib/Themes/utilities';


const Wrapper = styled(BaseButton)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  font-size: ${rem(13)};
  padding: 0 ${rem(16)};
  height: ${rem(40)};
  color: ${({ theme }) => theme.color.primaryFontColor};
  border: none;
  background-color: ${({ theme }) => theme.color.white};
  ${mb('s')} {
    font-size: ${rem(14)};
  }
  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
    background-color: ${({ theme }) => theme.color.primaryBackgroundColor};
  }
  .icon {
    margin-right: ${rem(8)};
  }
`;

type OwnProps = { className?: string } & DropDownItemProps;

export const DropDownItem: React.FC<OwnProps> = ({
  id,
  className,
  label,
  href,
  linkRel,
  linkTarget,
  onClick,
  icon,
  iconColor = 'currentColor',
}) => {
  const theme = useTheme() as unknown as ITheme;
  return (
    <Wrapper
      className={`${className ?? ''} drop-down-item`}
      onClick={
        onClick
          ? (e: any) => {
              onClick(id);
            }
          : undefined
      }
      {...(href ? { to: href } : undefined)}
      rel={linkRel}
      target={linkTarget}
      as={href ? Link : 'button'}
    >
      {icon && (
        <Icon name={icon} size={14} color={getIconColor(iconColor, theme)} />
      )}
      {label}
    </Wrapper>
  );
};
