import React from 'react';
import Ad from '../Ad';
import { IAdPosition } from '../AdProvider';
import styled from 'styled-components';
import { rem } from 'polished';

const Wrapper = styled.div`
  max-width: ${rem(336)};
  width: ${rem(336)};
  margin-left: ${rem(-19)};
`;

type IOwnProps = {
  name: IAdPosition;
  className?: string;
};

type IProps = IOwnProps;

const SideAd: React.FC<IProps> = ({ name, className }) => {
  return (
    <Wrapper className={className} >
      <Ad name={name} />
    </Wrapper>
  );
};

export default React.memo(SideAd);
