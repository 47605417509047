import { rem } from 'polished';
import React from 'react';
import ItemsList from '../../components/ItemsList';
import Item from '../../components/ItemsList/Item';
import UrlContext from '../../contexts/UrlContext';
import { ISection, SectionNode } from '../../library/App';
import styled, { css } from '../../theme/styled-components';

const Wrapper = styled.div`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};

  > ul {
    padding-bottom: 0;
  }
`;

const SectionItem = styled(Item)<{ padTop: boolean }>`
  > a {
    font-size: ${rem(14)};
    line-height: ${rem(16.8)};
    font-weight: 600;
  }
  ${({ padTop }) =>
    padTop &&
    css`
      margin-top: ${rem(8)};
    `};
`;

const SubItem = styled(Item)<{ padTop: boolean }>`
  min-height: ${rem(32)};
  > a {
    padding-top: ${rem(6)};
    padding-bottom: ${rem(6)};
    padding-left: ${rem(24)};
  }

  ${({ padTop }) =>
    padTop &&
    css`
      margin-top: ${rem(8)};
    `};
`;

type IOwnProps = {
  sections: SectionNode[];
};

const SectionsList: React.FC<IOwnProps> = ({ sections }) => {
  const { getSectionUrl } = React.useContext(UrlContext);
  return (
    <Wrapper>
      <ItemsList>
        {(sections || []).map((section, idx) => {
          // ak mala predchadzajuca sekcia podsekcie, aplikujem padding-top.
          const padTop =
            idx > 0 && (sections[idx - 1].sections || []).length > 0;
          return (
            <React.Fragment key={section._id}>
              <SectionItem
                to={getSectionUrl(section as ISection)}
                padTop={padTop}
              >
                {section.name}
              </SectionItem>
              {(section.sections || []).map((subSection, subIdx) => (
                <SubItem
                  key={`subsection-${subSection._id}`}
                  padTop={subIdx === 0}
                  to={getSectionUrl(subSection as ISection)}
                >
                  {subSection.name}
                </SubItem>
              ))}
            </React.Fragment>
          );
        })}
      </ItemsList>
    </Wrapper>
  );
};

export default SectionsList;
