import { IBasicCodelistItem } from "../../library/Competitions";

type CodeList = { _id: string; items: IBasicCodelistItem [] };

export const getItemsBySportSector = (
  sportSector: string,
  codeLists?: CodeList[],
): CodeList | null => {
  if (!codeLists) {
    return null;
  }
  const codeListBySportSector = codeLists.find((cl) => cl._id === sportSector);
  return codeListBySportSector ?? null;
};

export default getItemsBySportSector;
