import { format } from 'date-fns';
import React from 'react';
import LayoutContext from '../../../contexts/LayoutContext';
import Input from '../Input';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import sk from 'date-fns/locale/sk';
import { DatePickerWrapper } from './DatePickerWrapper';
import { SidebarBackdrop, SIDEBAR_ZINDEX } from '../Sidebar';
import { OwnProps } from './DatePicker';
import { rem } from 'polished';

const StyledInput = styled(Input)`
  padding: 0 0 0 ${rem(16)} !important;
  &:read-only {
    cursor: text;
  }
`;

const StyledSidebarBackdrop = styled(SidebarBackdrop)`
  z-index: ${SIDEBAR_ZINDEX + 1};
`;

const CalendarWrapper = styled.div`
  position: fixed;
  top: 20%;
  left: calc(50% - 129px);
  z-index: ${SIDEBAR_ZINDEX + 2};
`;

const DatePickerMobile: React.FC<OwnProps> = ({
  name,
  label,
  className,
  value,
  onChange,
  minDate,
  maxDate,
  timeIntervals = 5,
  disabled = false,
  readOnly = false,
  showYearDropdown = true,
  placeholder,
}) => {
  const [pickerOpened, setPickerOpened] = React.useState(false);

  const { sidebarRef } = React.useContext(LayoutContext);

  const handleInputClick = () => {
    setPickerOpened(!pickerOpened);
  };

  return (
    <>
      <StyledInput
        className={className}
        label={label}
        name={name}
        value={value ? format(value, 'dd.MM.yyyy') : ''}
        readOnly={true}
        onInputClick={handleInputClick}
        placeholder={placeholder}
      >
        <input />
        {!!value && (
          <Input.Icon
            className="inputBackspace clickable"
            name="backspace"
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              onChange(null);
            }}
          />
        )}
      </StyledInput>
      {sidebarRef?.current && (
        <>
          {ReactDOM.createPortal(
            <>
              {pickerOpened && (
                <>
                  <StyledSidebarBackdrop
                    isOpen={pickerOpened}
                    onClick={() => {
                      setPickerOpened(false);
                    }}
                    modal={true}
                  />
                  <CalendarWrapper>
                    <DatePickerWrapper>
                      <DatePicker
                        dateFormat="dd.MM.yyyy"
                        timeIntervals={timeIntervals}
                        selected={value}
                        onChange={(d: Date | null) => {
                          onChange(d);
                          setPickerOpened(false);
                        }}
                        disabled={disabled}
                        isClearable={!disabled && !readOnly}
                        showYearDropdown={showYearDropdown}
                        placeholderText="dd.MM.yyyy"
                        timeCaption="Čas"
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        dropdownMode="select"
                        minDate={minDate}
                        maxDate={maxDate}
                        locale={sk}
                        inline
                      />
                    </DatePickerWrapper>
                  </CalendarWrapper>
                </>
              )}
            </>,
            sidebarRef.current,
          )}
        </>
      )}
    </>
  );
};

export default DatePickerMobile;
