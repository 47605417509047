import useQuery, { QueryHocInterface, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import MatchesList from '../../../components/MatchesList';
import Spacer from '../../../components/Spacer';
import Tabber from '../../../components/Tabber';
import UnionCompetitionsWidget from '../../../components/UnionCompetitionsWidget';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { UnionItem } from '../../../library/Union';
import getCompetitionBreadcrumbLabel from '../../../utilities/futbalnet/breadcrumbs/getCompetitionBreadcrumblabel';
import getCompetitionBaseUrl from '../../../utilities/getCompetitionBaseUrl';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import __ from '../../../utilities/__';
import { competitionsSelectorByUnion } from '../../FutbalnetApp/selectors';
import {
  loadCompetitionPartItem,
  loadCompetitionPartMatches,
  setCurrentCompetitionPartId,
} from '../actions';
import {
  competitionPartsSelector,
  currentCompetitionAppSpaceSelector,
  currentCompetitionIdSelector,
  currentCompetitionIsFetchingSelector,
  currentCompetitionPartFutureMatchesSelector,
  currentCompetitionPartIsFetchingSelector,
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../selectors';
import { PrintButtonWrapper } from '../detail';
import PrintButton from '../../FutbalnetPrint/PrintButton';
import getCompetitionPrintUrl from '../../../utilities/print/getCompetitionPrintUrl';
import BodyAd from '../../../components/Ads/BodyAd';

const mapStateToProps = (state: RootState) => ({
  currentCompetitionId: currentCompetitionIdSelector(state),
  currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
  currentCompetition: currentCompetitionSelector(state),
  currentCompetitionPart: currentCompetitionPartSelector(state),
  isFetchingCompetition: currentCompetitionIsFetchingSelector(state) !== false,
  isFetchingCompetitionPart:
    currentCompetitionPartIsFetchingSelector(state) !== false,
  parts: competitionPartsSelector(state),
  matches: currentCompetitionPartFutureMatchesSelector(state),
  isFetchingMatches: isCommiting(config.LIST_FUTURE_COMPETITION_PART_MATCHES)(
    state,
  ),
  matchesNextOffset: getListNextOffset(
    config.LIST_FUTURE_COMPETITION_PART_MATCHES,
  )(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitions: competitionsSelectorByUnion(state),
});

type Props = { dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

class CompetitionFixtures extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return Promise.all([
      dispatch(
        loadCompetitionPartMatches.action({
          listName: config.LIST_FUTURE_COMPETITION_PART_MATCHES,
          limit: config.LIST_FUTURE_COMPETITION_PART_MATCHES_LIMIT,
          closed: false,
          // dateAfter: new Date(),
          offset: 0,
        }),
      ),
    ]);
  }

  async componentDidMount() {
    await CompetitionFixtures.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.query.part &&
      this.props.query.part !== prevProps.query.part
    ) {
      this.props.dispatch(
        setCurrentCompetitionPartId(String(this.props.query.part)),
      );
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartItem)('') as any,
      );
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartMatches)({
          listName: config.LIST_FUTURE_COMPETITION_PART_MATCHES,
          limit: config.LIST_FUTURE_COMPETITION_PART_MATCHES_LIMIT,
          closed: false,
          // dateAfter: new Date(),
          offset: 0,
        }) as any,
      );
    } else if (
      this.props.query.competitionId &&
      this.props.query.competitionId !== prevProps.query.competitionId
    ) {
      await CompetitionFixtures.getInitialProps(this.props);
    }
  }

  render() {
    const {
      parts,
      currentCompetitionPart,
      currentCompetition,
      isFetchingCompetition,
      setParameter,
      matches,
      isFetchingMatches,
      matchesNextOffset,
      dispatch,
      currentCompetitionAppSpace,
      params: { sutaz, zvaz },
      location: { search },
      organizationProfile,
      competitions,
    } = this.props;

    const unionItem = { ...organizationProfile, competitions };

    if (
      (isFetchingCompetition && !currentCompetition) ||
      !currentCompetitionPart
    ) {
      return <Loading />;
    }
    const title = `${currentCompetition?.name} (${
      currentCompetition?.season?.name
    }) - ${__('program')}`;
    const description = `${currentCompetition?.name}, ${__('program')}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale.',
    )}`;

    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';

    const competitionPartId = currentCompetitionPart?.__issfId
      ? String(currentCompetitionPart?.__issfId)
      : currentCompetitionPart?._id;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <TopAd />
        <BottomPaddedBigAd name="big_1" />

        <RempIntegration destroy />

        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <Tabber
              onChange={(tab) => setParameter({ part: tab.value })}
              active={competitionPartId}
              tabs={parts.map((p: any) => ({
                label: p.name,
                value: p.__issfId ? String(p.__issfId) : p._id,
              }))}
            />
            <Spacer size={3}>
              <MatchesList
                ad="list_body_1"
                appSpace={currentCompetitionAppSpace}
                matches={matches}
                isFetching={isFetchingMatches}
                omitCompetitionName={true}
                {...((isFetchingMatches || !!matchesNextOffset) && {
                  onLoadMore: () => {
                    dispatch(
                      loadCompetitionPartMatches.action({
                        listName: config.LIST_FUTURE_COMPETITION_PART_MATCHES,
                        limit:
                          config.LIST_FUTURE_COMPETITION_PART_MATCHES_LIMIT,
                        closed: false,
                        // dateAfter: new Date(),
                        offset: matchesNextOffset || 0,
                      }),
                    );
                  },
                })}
              />
            </Spacer>
            <PrintButtonWrapper>
              {competitionPartId && (
                <PrintButton
                  href={getCompetitionPrintUrl(zvaz, sutaz, competitionPartId)}
                  gtmEventLabel='vysledky'
                />
              )}
            </PrintButtonWrapper>
            <BodyAd className="advertisement-margin-24px" name="body_2" />
            <UnionCompetitionsWidget union={unionItem as UnionItem} />
          </SidebarLayoutContent>
          <Sidebar>
            <StickySidebarContainer>
              <SideAd name="side_1" />
            </StickySidebarContainer>
          </Sidebar>
        </PaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && currentCompetition && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
              {
                label: getCompetitionBreadcrumbLabel(currentCompetition),
                url: getCompetitionBaseUrl({ zvaz, sutaz }),
              },
              {
                label: 'Program',
                url: `${getCompetitionBaseUrl({ zvaz, sutaz })}program/${
                  search || ''
                }`,
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(
  useQuery({
    parameters: {
      part: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      tableView: { type: QueryHocTypes.String, defaultValue: '' },
    },
  }),
  connect(mapStateToProps),
)(CompetitionFixtures);
