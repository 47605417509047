import { rem } from 'polished';
import React from 'react';
import { OrganizationProfileStructureItem } from '../api/CoreApi';
import { IPane } from '../components/AnimatedPanes/types';
import OrganizationLogo from '../components/OrganizationLogo';
import { ICompetition } from '../library/Competitions';
import styled from '../theme/styled-components';
import getCompetitionBaseUrl from './getCompetitionBaseUrl';
import getPpoLogoSrc from './getPpoLogoSrc';
import __ from './__';

const UnionLogo = styled(OrganizationLogo)`
  flex-shrink: 0;
  margin-right: ${rem(16)};
`;

export const categories = [
  {
    _id: 'M',
    name: __('Muži'),
  },
  {
    _id: 'F',
    name: __('Ženy'),
  },
  {
    _id: 'U',
    name: __('Mládež'),
  },
];
export type ICategoryId = typeof categories[number]['_id'];

const getCompetitionCategory = (
  competition: ICompetition,
): ICategoryId | '' => {
  if (competition.parts && competition.parts.length) {
    const rules = competition.parts[0]?.rules;
    if (rules && rules.category) {
      return rules.category === 'ADULTS' ? rules.gender || '' : 'U';
    }
  }
  return '';
};

export default (
  competitions: ICompetition[],
  unionsStructure: OrganizationProfileStructureItem[],
): IPane[] => {
  const competitionsByCategory: any = {};
  const competitionsByUnion: any = {};
  const competitionsById: any = {};

  competitions.forEach((c) => {
    competitionsById[c._id] = c;
    const category = getCompetitionCategory(c);
    if (category) {
      competitionsByCategory[category] = competitionsByCategory[category] || {};
      competitionsByCategory[category][c._id] = true;
    }
    competitionsByUnion[c.appSpace] = competitionsByUnion[c.appSpace] || {};
    competitionsByUnion[c.appSpace][c._id] = true;
  });

  const getUnions = (
    categoryId: string,
    unions: OrganizationProfileStructureItem[],
  ): any[] => {
    const categoryCompsById = competitionsByCategory[categoryId] || {};

    const newUnions: IPane[] = [];

    for (const union of unions) {
      const subs = getUnions(categoryId, union.subs || []);
      // filter comps by union and by category
      const compIds = Object.keys(competitionsByUnion[union._id] || {}).filter(
        (compId) => categoryCompsById[compId],
      );
      const unionCompetitions: IPane[] = compIds.map((compId) => {
        const comp = competitionsById[compId];
        return {
          _id: comp._id,
          name: comp.name,
          type: 'competition',
          url: getCompetitionBaseUrl({
            zvaz: comp.appSpace,
            sutaz: comp.__issfId || comp.competitionGroupId,
          }),
        };
      });

      if (subs.length || unionCompetitions.length) {
        newUnions.push({
          _id: union._id,
          name: union.name,
          type: 'union',
          children: [...unionCompetitions, ...subs],
          icon: <UnionLogo alt={union.name} src={getPpoLogoSrc(union)} />,
        });
      }
    }

    return newUnions;
  };

  const result: IPane[] = [];

  for (const category of categories) {
    const unions = getUnions(category._id, unionsStructure);
    if (unions.length) {
      result.push({
        _id: category._id,
        name: category.name,
        type: 'category',
        children: unions,
      });
    }
  }

  return result;
};
