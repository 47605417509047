import { RootState } from '../../configureStore';
import {
  detailDataSelector,
  errorDetailSelector,
  isFetchingDetail,
} from '../App/selectors';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';

export const urlDetailIsFetchingSelector = (state: RootState, url: string) => {
  const appSpace = getCurrentCompetitionAppSpaceSelector(state);
  return isFetchingDetail('urlDetailByAppSpaceUrl', { appSpace, url })(state);
};

export const urlDetailSelector = (state: RootState, url: string) => {
  const appSpace = getCurrentCompetitionAppSpaceSelector(state);
  return (
    detailDataSelector('urlDetailByAppSpaceUrl', { appSpace, url })(state) ||
    null
  );
};

export const urlDetailErrorSelector = (state: RootState, url: string) => {
  const appSpace = getCurrentCompetitionAppSpaceSelector(state);
  return errorDetailSelector('urlDetailByAppSpaceUrl', { appSpace, url })(
    state,
  );
};
