import { endOfDay, startOfDay, sub } from 'date-fns';
import { rem } from 'polished';
import useQuery, { QueryHocInterface } from '@sportnet/query-hoc';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import {
  getListNextOffset,
  getListResults,
  isCommiting,
} from '@sportnet/redux-list/ducks';
import BodyAd from '../../components/Ads/BodyAd';
import BottomPaddedBigAd from '../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import SideAd from '../../components/Ads/SideAd';
import TopAd from '../../components/Ads/TopAd';
import ArticlesListSmall from '../../components/Articles/ArticlesListSmall';
import {
  BottomPaddedSidebarLayout,
  ResponsiveSidebar,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import Loading from '../../components/Loading';
import MatchesList from '../../components/MatchesList';
import MatchesSlider from '../../components/MatchesSlider';
import MaxWidthBox from '../../components/MaxWidthBox';
import MiniResultsTable from '../../components/MiniResultsTable';
import Segment from '../../components/Segment';
import Space from '../../components/Spacer';
import StrikersTable from '../../components/StrikersTable';
import UnionCompetitionsWidget from '../../components/UnionCompetitionsWidget';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../containers/RempIntegration';
import { UnionItem } from '../../library/Union';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import floorMinutes from '../../utilities/floorMinutes';
import getCompetitionBreadcrumbLabel from '../../utilities/futbalnet/breadcrumbs/getCompetitionBreadcrumblabel';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { loadArticlesList } from '../Article/actions';
import { competitionArticlesSelector } from '../Article/selectors';
import { competitionsSelectorByUnion } from '../FutbalnetApp/selectors';
import {
  currentCompetitionPartSelector,
  currentOrganizationProfileSelector,
} from '../Competition/selectors';
import { loadCompetitionMatches, loadCompetitionPartResults } from './actions';
import {
  currentCompetitionAppSpaceSelector,
  currentCompetitionIdSelector,
  currentCompetitionListFutureMatchesSelector,
  currentCompetitionListPastMatchesSelector,
  currentCompetitionListTodayMatchesSelector,
  currentCompetitionPanelFutureMatchesSelector,
  currentCompetitionPanelPastMatchesSelector,
  currentCompetitionResultsTablePlayersSelector,
  currentCompetitionSelector,
} from './selectors';
import { thunkToAction } from 'typescript-fsa-redux-thunk';

export const PrintButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: ${rem(24)};
  margin-top: ${rem(24)};
`;

const Title = styled.h2`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: ${rem(24)};
  margin-top: 0;
`;

const mapStateToProps = (state: RootState) => ({
  competition: currentCompetitionSelector(state),
  competitionResultsTablePlayers:
    currentCompetitionResultsTablePlayersSelector(state),
  currentCompetitionId: currentCompetitionIdSelector(state),
  pastPanelMatches: currentCompetitionPanelPastMatchesSelector(state),
  futurePanelMatches: currentCompetitionPanelFutureMatchesSelector(state),
  futureListMatches: currentCompetitionListFutureMatchesSelector(state),
  pastListMatches: currentCompetitionListPastMatchesSelector(state),
  todayListMatches: currentCompetitionListTodayMatchesSelector(state),
  todayListMatchesNextOffset: getListNextOffset(
    config.LIST_TODAY_COMPETITION_MATCHES,
  )(state),
  isFetchingPanelMatches:
    isCommiting(config.PANEL_PAST_COMPETITION_MATCHES)(state) ||
    isCommiting(config.PANEL_FUTURE_COMPETITION_MATCHES)(state),
  isFetchingListPastMatches: isCommiting(config.LIST_PAST_COMPETITION_MATCHES)(
    state,
  ),
  isFetchingListFutureMatches: isCommiting(
    config.LIST_FUTURE_COMPETITION_MATCHES,
  )(state),
  isFetchingListTodayMatches: isCommiting(
    config.LIST_TODAY_COMPETITION_MATCHES,
  )(state),
  currentCompetitionAppSpace: currentCompetitionAppSpaceSelector(state),
  currentCompetitionPart: currentCompetitionPartSelector(state),
  isFetchingListMatches:
    isCommiting(config.LIST_PAST_COMPETITION_MATCHES)(state) ||
    isCommiting(config.LIST_FUTURE_COMPETITION_MATCHES)(state) ||
    isCommiting(config.LIST_TODAY_COMPETITION_MATCHES)(state),
  isLoadingArticles:
    isCommiting(config.LIST_COMPETITION_ARTICLES)(state) !== false &&
    !getListResults(config.LIST_COMPETITION_ARTICLES)(state),
  articles: competitionArticlesSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitions: competitionsSelectorByUnion(state),
  isFetchingUnionCompetitions:
    isCommiting(config.LIST_UNION_COMPETITIONS)(state) || false,
});

type Props = { theme: ITheme } & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

class CompetitionDetail extends React.PureComponent<Props> {
  static async initCMS(props: Props) {
    const { dispatch } = props;

    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_COMPETITION_ARTICLES,
        params: {
          limit: config.LIST_COMPETITION_ARTICLES_LIMIT,
          offset: 0,
        },
      }),
    );

    return Promise.all([
      dispatch(loadArticlesList(config.LIST_COMPETITION_ARTICLES)),
    ]);
  }

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return Promise.all([
      CompetitionDetail.initCMS(props),
      dispatch(
        thunkToAction(loadCompetitionPartResults)({
          competitionId: props.currentCompetitionId,
          competitionPartId: props.currentCompetitionPart?.__issfId
            ? String(props.currentCompetitionPart?.__issfId)
            : props.currentCompetitionPart?._id,
        }) as any,
      ),
      // dispatch(
      //   loadCompetitionMatches({
      //     listName: config.PANEL_PAST_COMPETITION_MATCHES,
      //     dateBefore: new Date(),
      //     offset: 0,
      //   }),
      // ),
      dispatch(
        loadCompetitionMatches({
          listName: config.PANEL_FUTURE_COMPETITION_MATCHES,
          dateAfter: floorMinutes(sub(new Date(), { days: 1 })),
          closed: false,
          offset: 0,
        }),
      ),
      dispatch(
        loadCompetitionMatches({
          listName: config.LIST_FUTURE_COMPETITION_MATCHES,
          // dateAfter: startOfDay(addDays(new Date(), 1)),
          closed: false,
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        }),
      ),
      dispatch(
        loadCompetitionMatches({
          listName: config.LIST_PAST_COMPETITION_MATCHES,
          dateBefore: floorMinutes(new Date()),
          closed: true,
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        }),
      ),
      dispatch(
        loadCompetitionMatches({
          listName: config.LIST_TODAY_COMPETITION_MATCHES,
          dateAfter: startOfDay(new Date()),
          dateBefore: endOfDay(new Date()),
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        }),
      ),
    ]);
  }

  async componentDidMount() {
    try {
      await CompetitionDetail.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.currentCompetitionId !== this.props.currentCompetitionId) {
      try {
        await CompetitionDetail.getInitialProps(this.props);
      } catch (e: any) {
        /* */
      }
    }
  }

  handleLoadMoreArticlesWithOffset = async (offset: number) => {
    const { dispatch } = this.props;
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_COMPETITION_ARTICLES,
        params: {
          limit: config.LIST_COMPETITION_ARTICLES_LIMIT,
          offset,
        },
      }),
    );

    return dispatch(loadArticlesList(config.LIST_COMPETITION_ARTICLES));
  };

  render() {
    const {
      articles,
      pastPanelMatches,
      futurePanelMatches,
      isFetchingPanelMatches,
      isFetchingListMatches,
      isFetchingListPastMatches,
      isFetchingListFutureMatches,
      isFetchingListTodayMatches,
      futureListMatches,
      pastListMatches,
      todayListMatches,
      competition,
      currentCompetitionId,
      todayListMatchesNextOffset,
      currentCompetitionAppSpace,
      params: { zvaz, sutaz },
      isLoadingArticles,
      organizationProfile,
      competitions,
      isFetchingUnionCompetitions,
      competitionResultsTablePlayers,
      currentCompetitionPart,
    } = this.props;

    const unionItem = { ...organizationProfile, competitions };
    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';

    if (!competition) {
      return null;
    }

    const strikers = competition.resultsTable
      ? competitionResultsTablePlayers
          .sort((a, b) => (a.stats.goals > b.stats.goals ? -1 : 1))
          .slice(0, 3)
      : [];

    const panelMatches = [...pastPanelMatches.reverse(), ...futurePanelMatches];

    const omitCompetitionName = (competition.parts ?? []).length < 2;

    return (
      <>
        <TopAd />
        <BottomPaddedBigAd name="big_1" />
        <MaxWidthBox className="sptn-main-content">
          <MatchesSlider
            matches={panelMatches}
            appSpace={currentCompetitionAppSpace}
            isFetching={
              isFetchingPanelMatches ||
              typeof isFetchingPanelMatches !== 'boolean'
            }
          />
        </MaxWidthBox>
        <RempIntegration destroy />

        <BottomPaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            {(isFetchingListMatches &&
              todayListMatches.length +
                futureListMatches.length +
                pastListMatches.length ===
                0) ||
            isLoadingArticles ? (
              <Loading />
            ) : (
              <>
                <BodyAd name="body_1" />

                <Space size={7}>
                  {!!todayListMatches.length && (
                    <div>
                      <Title>{__('Dnešné zápasy')}</Title>
                      <MatchesList
                        appSpace={currentCompetitionAppSpace}
                        matches={todayListMatches}
                        isFetching={isFetchingListTodayMatches}
                        omitCompetitionName={omitCompetitionName}
                        {...((isFetchingListTodayMatches ||
                          !!todayListMatchesNextOffset) && {
                          onLoadMore: () => {
                            this.props.dispatch(
                              loadCompetitionMatches({
                                listName: config.LIST_TODAY_COMPETITION_MATCHES,
                                dateAfter: startOfDay(new Date()),
                                dateBefore: endOfDay(new Date()),
                                offset: todayListMatchesNextOffset || 0,
                                limit: config.LIST_COMPETITION_MATCHES_LIMIT,
                              }),
                            );
                          },
                        })}
                      />
                    </div>
                  )}
                  {pastListMatches.length > 0 && (
                    <>
                      <div>
                        <Title>{__('Posledné výsledky')}</Title>
                        <MatchesList
                          appSpace={currentCompetitionAppSpace}
                          matches={pastListMatches}
                          isFetching={isFetchingListPastMatches}
                          omitCompetitionName={omitCompetitionName}
                          showMoreLink={`${getCompetitionBaseUrl({
                            zvaz,
                            sutaz,
                          })}vyhodnotenie/?competitionId=${currentCompetitionId}`}
                          tableLink={`${getCompetitionBaseUrl({
                            zvaz,
                            sutaz,
                          })}tabulky/?competitionId=${currentCompetitionId}`}
                        />
                      </div>
                    </>
                  )}
                  {futureListMatches.length > 0 && (
                    <>
                      <div>
                        <Title>{__('Najbližšie zápasy')}</Title>
                        <MatchesList
                          appSpace={currentCompetitionAppSpace}
                          matches={futureListMatches}
                          isFetching={isFetchingListFutureMatches}
                          omitCompetitionName={omitCompetitionName}
                          showMoreLink={`${getCompetitionBaseUrl({
                            zvaz,
                            sutaz,
                          })}program/?competitionId=${currentCompetitionId}`}
                          tableLink={`${getCompetitionBaseUrl({
                            zvaz,
                            sutaz,
                          })}tabulky/?competitionId=${currentCompetitionId}`}
                        />
                      </div>
                    </>
                  )}
                </Space>

                <BodyAd className="advertisement-margin-24px" name="body_2" />

                {!isFetchingUnionCompetitions && (
                  <UnionCompetitionsWidget union={unionItem as UnionItem} />
                )}

                <ResponsiveSidebar>
                  {!!competition &&
                    !config.PAVUK_COMPETITION_IDS.includes(competition._id) && (
                      <Segment padding={24} title={__('Tabuľka')}>
                        <MiniResultsTable
                          zvaz={zvaz}
                          teams={currentCompetitionPart?.teams || []}
                          resultsTable={currentCompetitionPart?.resultsTable}
                          competitionId={this.props.params.sutaz}
                        />
                      </Segment>
                    )}
                  <Segment padding={24} title={__('Články')}>
                    <ArticlesListSmall articles={articles} />
                  </Segment>
                  <SideAd name="side_1" />
                  {!!strikers.length && (
                    <Segment padding={24} title={__('Najlepší strelci')}>
                      <StrikersTable
                        competitionId={sutaz}
                        currentCompetitionId={currentCompetitionId}
                        unionId={zvaz}
                        strikers={strikers}
                      />
                    </Segment>
                  )}
                </ResponsiveSidebar>
              </>
            )}
          </SidebarLayoutContent>
          <Sidebar>
            <StickySidebarContainer>
              {!!competition &&
                !config.PAVUK_COMPETITION_IDS.includes(competition._id) && (
                  <Segment padding={24} title={__('Tabuľka')}>
                    <MiniResultsTable
                      zvaz={zvaz}
                      teams={currentCompetitionPart?.teams || []}
                      resultsTable={currentCompetitionPart?.resultsTable}
                      competitionId={this.props.params.sutaz}
                    />
                  </Segment>
                )}
              <Segment padding={24} title={__('Články')}>
                <ArticlesListSmall articles={articles} />
              </Segment>
              <SideAd name="side_1" />
              {!!strikers.length && (
                <Segment padding={24} title={__('Najlepší strelci')}>
                  <StrikersTable
                    competitionId={sutaz}
                    currentCompetitionId={currentCompetitionId}
                    unionId={zvaz}
                    strikers={strikers}
                  />
                </Segment>
              )}
            </StickySidebarContainer>
          </Sidebar>
        </BottomPaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
              {
                label: getCompetitionBreadcrumbLabel(competition),
                url: getCompetitionBaseUrl({ zvaz, sutaz }),
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(
  withTheme,
  useQuery({ parameters: {} }),
  connect(mapStateToProps),
)(CompetitionDetail);
