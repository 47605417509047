import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import EntryAnimation from '../../components/EntryAnimation';
import { RootState } from '../../configureStore';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';
import UnauthorizedMessage from './UnauthorizedMessage';

const title = __('Zamknutý obsah');
const subtitle = __(
  'Stránka, ktorú sa pokúšate zobraziť, nie je k dispozícii pre verejnosť. Ak ju chcete zobraziť, prosím prihláste sa.',
);

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & RouteComponentProps<{}, {}>;

const Unauthorized: React.FC<Props> = ({
  appSettings,
  location: { pathname },
}) => {
  return (
    <EntryAnimation key={'not-found'}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={`${appSettings.baseUri}${pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
        <meta name="description" content={subtitle} />
      </Helmet>

      <UnauthorizedMessage title={title} subtitle={subtitle} />
    </EntryAnimation>
  );
};

export default compose(withRouter, connect(mapStateToProps))(Unauthorized);
