import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import { ContextMarginTopStyles } from '../../Layout';
import AdBase from '../Ad/AdBase';

const Wrapper = styled.div`
  position: relative;
  position: sticky;
  top: ${rem(89)};
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  width: 100%;
  margin: 0 auto;
  display: none;
  ${mb('l')} {
    display: block;
  }
  ${ContextMarginTopStyles}
`;

const Ad = styled(AdBase)`
  max-width: 300px;
`;

type IOwnProps = {
  className?: string;
};

type IProps = IOwnProps;

const TopAd: React.FC<IProps> = ({ className }) => {
  return (
    <Wrapper className={className} id="admngr-top_ad_place">
      <Ad
        name="big_1_left_side"
        className="admngr-sky-left admngr-sky-position admngr-sky-left-width"
      />
      <Ad
        name="big_1_right_side"
        className="admngr-sky-right admngr-sky-position admngr-sky-right-width"
      />
    </Wrapper>
  );
};

export default React.memo(TopAd);
