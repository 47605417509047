import { ICompetition } from '../library/Competitions';
import { getProp } from 'sportnet-utilities';

// sortovacia funkcia pre sutaze
const sortCompetitionsFn = (a: ICompetition, b: ICompetition): number => {
  const { sortvalue: aSort = 0, level: aLevel = 100 } = a;
  const { sortvalue: bSort = 0, level: bLevel = 100 } = b;
  const aGender = getProp(a, ['parts', 0, 'rules', 'gender']);
  const aCategory = getProp(a, ['parts', 0, 'rules', 'category']);
  const bGender = getProp(b, ['parts', 0, 'rules', 'gender']);
  const bCategory = getProp(b, ['parts', 0, 'rules', 'category']);

  if (aCategory > bCategory) {
    return -1;
  } else if (aCategory < bCategory) {
    return 1;
  } else if (aGender === 'M' && bGender !== 'M') {
    return -1;
  } else if (aLevel < bLevel) {
    return -1;
  } else if (aLevel > bLevel) {
    return 1;
  } else if (aSort > bSort) {
    return -1;
  } else if (aSort < bSort) {
    return 1;
  }
  return 0;
};

export default sortCompetitionsFn;
