import Image from '@sportnet/ui/lib/Image';
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import {
  ICompetitionTeam,
  IResultsTableItem,
} from '../../library/Competitions';
import styled, { css } from '../../theme/styled-components';
import getCompetitionBaseUrl from '../../utilities/getCompetitionBaseUrl';
import getFallbackClubOrUnionLogo from '../../utilities/getFallbackClubOrUnionLogo';
import getPpoLogoSrc from '../../utilities/getPpoLogoSrc';
import getTeamBaseUrl from '../../utilities/getTeamBaseUrl';
import { TrStyled as BasicTableTr } from '../BasicTable/BasicTableRow';
import Icon from '../Icon';
import { Table, Tbody, Td, Th, Thead, Tr } from '../Table/TableOriginal';
import sortResults from '../../utilities/competitionStanding/sortResults';
import getResultsByPoints from '../../utilities/competitionStanding/getResultsByPoints';

const TrStyled = styled(BasicTableTr)<{ highlighted: boolean }>`
  ${({ highlighted }) =>
    !!highlighted &&
    css`
      > td {
        background: #fffaef;
        font-weight: 600;
      }
    `}
`;

const ResultsTableWrapper = styled.div`
  margin: 0 -${rem(24)};
  td {
    font-size: ${rem(12)};
  }
  th:first-child,
  td:first-child {
    padding-left: ${rem(24)};
  }
  th:last-child,
  td:last-child {
    padding-right: ${rem(24)};
  }
`;

const ReadMore = styled.div`
  padding: ${rem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem(14)};
  margin-bottom: -${rem(24)};
  > span {
    padding-left: ${rem(8)};
  }
`;

const Team = styled.div`
  display: flex;
  align-items: center;
`;
const TeamLogo = styled.div`
  max-width: ${rem(15)};
  max-height: ${rem(15)};
  margin-right: ${rem(8)};
  > img {
    width: 100%;
    height: 100%;
  }
`;
const TeamName = styled.div`
  max-width: ${rem(100)};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Faded = styled.div`
  color: #aaaaaa;
  font-weight: normal !important;
`;

const Center = styled.div`
  text-align: center;
`;

interface OwnProps {
  resultsTable?: { results: IResultsTableItem[] };
  teams: ICompetitionTeam[];
  zvaz: string;
  competitionId: string;
}

const MiniResultsTable: React.FC<OwnProps> = ({
  resultsTable,
  teams,
  zvaz,
  competitionId,
}) => {
  let startingIndex = 0;
  let highlightedTeamId = '';

  const renderResultItem = (i, idx) => {
    const team = (teams || []).find((t) => t._id === i.team._id);

    const teamLogoSrc = team?.organization?._id
      ? getPpoLogoSrc(team?.organization)
      : getFallbackClubOrUnionLogo();

    return [
      <TrStyled
        highlighted={highlightedTeamId === team?._id}
        key={team ? team._id : idx}
        compact
      >
        <Td>
          <Faded>{startingIndex + idx + 1}</Faded>
        </Td>
        <Td>
          <Link
            to={getTeamBaseUrl({
              klub: team?.appSpace || '',
              teamId: team?.__issfId || team?._id || '',
            })}
          >
            <Team>
              <TeamLogo>
                <Image
                  alt={team?._id}
                  src={teamLogoSrc}
                  fallbackSrc={getFallbackClubOrUnionLogo()}
                  loading="lazy"
                />
              </TeamLogo>
              <TeamName>{team?.name}</TeamName>
            </Team>
          </Link>
        </Td>
        {i.stats && i.stats.matches && i.stats.goals && (
          <>
            <Td>
              <Center>{i.stats.matches.played}</Center>
            </Td>
            <Td>
              <Center>{`${i.stats.goals.given}:${i.stats.goals.received}`}</Center>
            </Td>
            <Td>
              <Center>{i.stats.points}</Center>
            </Td>
          </>
        )}
      </TrStyled>,
    ];
  };

  const resultsByPoints = getResultsByPoints(
    resultsTable?.results || [],
    'stats',
  );

  const renderTableResults = () => {
    let order = -1;
    return Object.keys(resultsByPoints)
      .map(Number)
      .sort((a, b) => {
        return a < b ? 1 : -1;
      })
      .map((pointsLevel) => {
        return sortResults(
          resultsByPoints[pointsLevel],
          'stats',
          resultsByPoints[pointsLevel].length > 1,
        ).map((i) => {
          order++;
          return renderResultItem(i, order);
        });
      });
  };

  return (
    <ResultsTableWrapper>
      <Table>
        <Thead>
          <Tr>
            <Th />
            <Th>{__('Klub')}</Th>
            <Th center>{__('Z')}</Th>
            <Th center>{__('G')}</Th>
            <Th center>{__('B')}</Th>
          </Tr>
        </Thead>
        <Tbody>{renderTableResults()}</Tbody>
      </Table>
      <Link
        to={`${getCompetitionBaseUrl({
          zvaz,
          sutaz: competitionId,
        })}tabulky/`}
      >
        <ReadMore>
          {__('Pozrieť tabuľku')} <Icon name="arrowMore" />
        </ReadMore>
      </Link>
    </ResultsTableWrapper>
  );
};

export default MiniResultsTable;
