import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import UrlContext from '../../../contexts/UrlContext';
import { ISection } from '../../../library/App';

interface IOwnProps {
  section: ISection;
  canonicalUrl: string;
  urlOverride?: string;
}

type IProps = IOwnProps;

const SectionMeta: React.FC<IProps> = ({
  section,
  urlOverride,
  canonicalUrl,
}) => {
  const { getFullSectionUrl } = React.useContext(UrlContext);

  const url = urlOverride || getFullSectionUrl(section);

  const sectionTitlePicture = (section.picture as any) || null;

  const metaTitle = section.meta?.title || section.name;
  const metaDesc = section.meta?.description || section.perex;

  // use of meta.title in sections is different from articles.
  // meta.title is rendered for page <title> and for `og:title`.
  return (
    <Helmet>
      <title>{metaTitle}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:title" content={metaTitle} />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      <meta property="og:type" content="section" />
      <meta property="og:url" content={url} />
      {sectionTitlePicture && (
        <meta
          property="og:image"
          content={getMediaManagerUrl(
            sectionTitlePicture,
            1000,
            0,
            'resize',
            false,
          )}
        />
      )}
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default React.memo(SectionMeta);
