import window from 'global';
import document from 'global/document';
import videojs from 'video.js';
const Plugin = videojs.getPlugin('plugin'),
  pc = 'progressControl',
  pt = 'playToggle',
  ld = 'liveDisplay',
  sl = 'seekToLive',
  ct = 'currentTimeDisplay',
  td = 'timeDivider',
  dd = 'durationDisplay',
  ccs = 'customControlSpacer',
  vp = 'volumePanel',
  cb = 'chaptersButton',
  db = 'descriptionsButton',
  sb = 'subsCapsButton',
  ab = 'audioTrackButton',
  pi = 'pictureInPictureToggle',
  ft = 'fullscreenToggle';
videojs.loadScript = function (e) {
  window.videojs = videojs;
  var t = document.createElement('script');
  t.src = e;
  document.body.appendChild(t);
};
function setSkin(e) {
  switch (e) {
    case 'treso':
      videojs.options.controlBar = {
        children: [pc, pt, ld, sl, ct, dd, vp, ccs, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'chrome':
      videojs.options.controlBar = {
        children: [pt, ld, sl, ct, td, dd, ccs, pc, vp, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'party':
      videojs.options.controlBar = {
        children: [pt, ld, sl, pc, ct, td, dd, ccs, vp, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'roundal':
    case 'pinko':
      videojs.options.controlBar = {
        volumePanel: { inline: !1, vertical: !0 },
        children: [pt, ld, sl, ct, pc, td, dd, ccs, vp, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'shaka':
      videojs.options.controlBar = {
        children: [pt, ld, sl, ct, td, dd, pc, ccs, vp, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'flow':
      videojs.options.controlBar = {
        children: [pt, ld, sl, ct, pc, dd, ccs, cb, db, sb, ab, pi, vp, ft],
      };
      break;
    case 'jwlike':
      videojs.options.controlBar = {
        volumePanel: { inline: !1, vertical: !0 },
        children: [pc, pt, ld, sl, vp, ct, td, dd, ccs, cb, db, sb, ab, pi, ft],
      };
      break;
    case 'nuevo':
      videojs.options.controlBar = {
        volumePanel: { inline: !1, vertical: !0 },
        children: [pt, ld, sl, ct, td, pc, dd, ccs, vp, cb, db, sb, ab, pi, ft],
      };
  }
}
function vjs_El(e, t, s) {
  const i = document.createElement(e);
  void 0 !== t && '' !== t && (i.className = t);
  void 0 !== s && '' !== s && (i.innerHTML = s);
  return i;
}
const vjs_skins = [
  'nuevo',
  'chrome',
  'party',
  'treso',
  'roundal',
  'pinko',
  'jwlike',
  'shaka',
  'flow',
];
if (void 0 !== window.nuevoskin) {
  var nskin = window.nuevoskin;
  videojs.options.skin = nskin;
  setSkin(nskin);
} else {
  let e = vjs_El('div');
  const t = document.body;
  t.appendChild(e);
  vjs_skins.forEach((t) => {
    e.className = 'vjs-' + t;
    if (13 === e.offsetWidth) {
      videojs.options.skin = t;
      setSkin(t);
    }
  });
  t.removeChild(e);
}
const defaults = {
  infoSize: 18,
  zoomMenu: !0,
  rate: 1,
  pipButton: !0,
  relatedMenu: !0,
  settingsButton: !0,
  rateMenu: !0,
  hdicon: !0,
  shareMenu: !0,
  zoomInfo: !0,
  chapterMarkers: !0,
  contextMenu: !0,
  timetooltip: !1,
  mousedisplay: !0,
  errordisplay: !0,
  related: {},
  logoposition: 'LT',
  logooffsetX: 10,
  logooffsetY: 10,
  logourl: '',
  url: '',
  title: '',
  embed: '',
  endAction: '',
  pubid: '',
  slideWidth: 192,
  slideHeight: 108,
  slideType: 'vertical',
  limit: 0,
  limitmessage: 'Watch full video on',
  dashQualities: !0,
  hlsQualities: !0,
  minResolution: 2,
  resume: !0,
  video_id: '',
  playlistUI: !0,
  playlistShow: !0,
  playlistAutoHide: !0,
  playlist: !1,
  contextText: '',
  contextUrl: '',
  currentSlide: '',
  infoIcon: '',
  res_num: 0,
  target: '_blank',
  buttonRewind: !0,
  metatitle: '',
  metasubtitle: '',
  isLive: !1,
  liveReload: !1,
  qualityMenu: !1,
  liveTimeout: 10,
  captionsSize: 1.25,
  tooltips: !0,
  offlineImage: '',
  offlineStream: '',
  offline404: !1,
  singlePlay: !0,
  audioInfo: void 0,
  rewindforward: 10,
  isDisposed: !1,
};
if (videojs.browser.IS_ANDROID) {
  try {
    videojs.options.vhs.overrideNative = !0;
  } catch (e) {}
  try {
    videojs.options.hls.overrideNative = !0;
  } catch (e) {}
  videojs.options.html5.nativeAudioTracks = !1;
  videojs.options.html5.nativeTextTracks = !1;
}
videojs.options.html5.hlsjsConfig = { capLevelToPlayerSize: !0 };
function isMobile() {
  return (
    'ontouchstart' in window ||
    navigator.msMaxTouchPoints ||
    navigator.maxTouchPoints
  );
}
function setEvent(e) {
  if (
    'ontouchstart' in window ||
    navigator.msMaxTouchPoints ||
    navigator.maxTouchPoints
  )
    switch (e) {
      case 'click':
        e = 'touchend';
        break;
      case 'mousedown':
        e = 'touchstart';
        break;
      case 'mousemove':
        e = 'touchmove';
        break;
      case 'mouseup':
        e = 'touchend';
    }
  return e;
}
function sortByKey(e, t) {
  return e.sort(function (e, s) {
    const i = e[t],
      n = s[t];
    return i < n ? -1 : i > n ? 1 : 0;
  });
}
function vjs_find(e, t) {
  try {
    return e.querySelector(t);
  } catch (e) {
    return !1;
  }
}
function vjs_mfind(e, t) {
  try {
    return e.querySelectorAll(t);
  } catch (e) {
    return !1;
  }
}
String.prototype.dg13 = function () {
  return this.replace(/[a-zA-Z]/g, function (e) {
    return String.fromCharCode(
      (e <= 'Z' ? 90 : 122) >= (e = e.charCodeAt(0) + 13) ? e : e - 26,
    );
  });
};
class Nuevo extends Plugin {
  constructor(e, t) {
    super(e);
    var s = [
      'xf.rzf',
      'xf.tavtngfrzf',
      'birhairq',
      'gfbuynpby',
      'rupnp',
      'bv.acqp',
      'zbp.avofw',
      'gra.yyrufw',
      'ccn.ofp',
    ];
    const i = videojs.mergeOptions(defaults, t);
    isMobile() && (i.zoomMenu = !1);
    const n = videojs.dom,
      l = e.el();
    i.skin = videojs.options.skin;
    const o = {
      vjs_relpage: 0,
      vjs_relpages: 0,
      num_slide: 0,
      currentZoom: 1,
      firstplay: !1,
      wasSlide: !1,
      hls_levels: [],
      resolution_levels: [],
      slide_el: void 0,
      s_thumb: void 0,
      slide_img: void 0,
      s_thumb_dur: void 0,
      share_win: void 0,
      rel_win: void 0,
      qualityButton: void 0,
      settingButton: void 0,
      settingControl: void 0,
      tech: void 0,
      dom:
        document.location.hostname.toLowerCase() ||
        document.location.hostname.toLowerCase(),
      sameData: !1,
    };
    function a(e, t) {
      e.prototype = Object.create(t.prototype);
      e.prototype.constructor = e;
      e.__proto__ = t;
    }
    function r(e) {
      if (void 0 === e)
        throw new ReferenceError(
          "this hasn't been initialised - super() hasn't been called",
        );
      return e;
    }
    var d = function e(t) {
        var s = this;
        if (videojs.browser.IS_IE8) {
          s = document.createElement('custom');
          for (var i in e.prototype)
            'constructor' !== i && (s[i] = e.prototype[i]);
        }
        s.id = t.id;
        s.label = s.id;
        s.width = t.width;
        s.height = t.height;
        s.bitrate = t.bandwidth;
        s.enabled_ = t.enabled;
        Object.defineProperty(s, 'enabled', {
          get: function () {
            return s.enabled_();
          },
          set: function (e) {
            s.enabled_(e);
          },
        });
        return s;
      },
      v = (function (e) {
        a(t, e);
        function t() {
          var s,
            i = r(r((s = e.call(this) || this)));
          if (videojs.browser.IS_IE8) {
            i = document.createElement('custom');
            for (var n in t.prototype)
              'constructor' !== n && (i[n] = t.prototype[n]);
          }
          i.levels_ = [];
          i.selectedIndex_ = -1;
          Object.defineProperty(i, 'selectedIndex', {
            get: function () {
              return i.selectedIndex_;
            },
          });
          Object.defineProperty(i, 'length', {
            get: function () {
              return i.levels_.length;
            },
          });
          return i || r(s);
        }
        var s = t.prototype;
        s.addQualityLevel = function (e) {
          var t = this.getQualityLevelById(e.id);
          if (t) return t;
          var s = this.levels_.length;
          t = new d(e);
          '' + s in this ||
            Object.defineProperty(this, s, {
              get: function () {
                return this.levels_[s];
              },
            });
          if (t.height) {
            this.levels_.push(t);
            this.trigger({ qualityLevel: t, type: 'addqualitylevel' });
            return t;
          }
        };
        s.removeQualityLevel = function (e) {
          for (var t = null, s = 0, i = this.length; s < i; s++)
            if (this[s] === e) {
              t = this.levels_.splice(s, 1)[0];
              this.selectedIndex_ === s
                ? (this.selectedIndex_ = -1)
                : this.selectedIndex_ > s && this.selectedIndex_--;
              break;
            }
          t && this.trigger({ qualityLevel: e, type: 'removequalitylevel' });
          return t;
        };
        s.getQualityLevelById = function (e) {
          for (var t = 0, s = this.length; t < s; t++) {
            var i = this[t];
            if (i.id === e) return i;
          }
          return null;
        };
        s.dispose = function () {
          this.selectedIndex_ = -1;
          this.levels_.length = 0;
        };
        return t;
      })(videojs.EventTarget);
    v.prototype.allowedEvents_ = {
      change: 'change',
      addqualitylevel: 'addqualitylevel',
      removequalitylevel: 'removequalitylevel',
    };
    for (var c in v.prototype.allowedEvents_) v.prototype['on' + c] = null;
    var u = new v(),
      f = function t() {
        u.dispose();
        e.off('dispose', t);
      };
    e.on('dispose', f);
    e.qualityLevels = function () {
      return u;
    };
    let h = [];
    o.tech = e.$('.vjs-tech');
    function j(e) {
      for (var t = 0; t < e.length; t++) {
        var s = e[t].dg13();
        if (((s = s.split('').reverse().join('')), o.dom.indexOf(s) > -1))
          return !0;
      }
      return !1;
    }
    const p = vjs_find(l, '.vjs-big-play-button'),
      m = vjs_find(l, '.vjs-loading-spinner');
    function g() {
      var e = vjs_find(l, '.vjs-audio-info');
      e && l.removeChild(e);
      if (i.audioInfo && (i.audioInfo.artist || i.audioInfo.title)) {
        var t = vjs_El('span', 'vjs-audio-info'),
          s = '';
        i.audioInfo.url
          ? (s =
              '<a href="' +
              i.audioInfo.url +
              '" rel="nofollow" target="' +
              i.target +
              '">')
          : (t.style.pointerEvents = 'none');
        i.audioInfo.cover &&
          (s +=
            '<span class="vjs-cover"><img src="' +
            i.audioInfo.cover +
            '"/></span>');
        s += '<span class="vjs-audio-item vjs-text">';
        void 0 !== i.audioInfo.artist &&
          (s += '<span class="audio-artist">' + i.audioInfo.artist + '</span>');
        i.audioInfo.title &&
          (s +=
            '<span class="vjs-audio-item vjs-song">' +
            i.audioInfo.title +
            '</span>');
        if (i.audioInfo.genre || i.audioInfo.album || i.audioInfo.year) {
          s += '<span class="vjs-audio-item audio-id">';
          i.audioInfo.genre &&
            (s += '<span>Genre: ' + i.audioInfo.genre + '</span>');
          i.audioInfo.album &&
            (s += '<span>Album: ' + i.audioInfo.album + '</span>');
          i.audioInfo.year &&
            (s += '<span>Year: ' + i.audioInfo.year + '</span>');
          s += '</span>';
        }
        s += '</span>';
        i.audioInfo.url && (s += '</a>');
        t.innerHTML = s;
        l.appendChild(t);
        t.onclick = function () {
          window.open(i.audioInfo.url, i.target);
        };
      }
    }
    e.forward = function () {
      let t = e.duration();
      if (t > 0) {
        let s = e.currentTime() + i.rewindforward;
        s > t ? e.currentTime(t) : e.currentTime(s);
      }
    };
    e.rewind = function () {
      if (e.duration() > 0) {
        let t = e.currentTime() - i.rewindforward;
        t < 0 && (t = 0);
        e.currentTime(t);
      }
    };
    e.related = function () {
      if (
        i.settingsButton &&
        i.related.length > 1 &&
        i.relatedMenu &&
        !0 !== e.seeking()
      ) {
        o.share_win && n.addClass(o.share_win, 'vjs-hidden');
        n.removeClass(vjs_find(l, '.vjs-menu-settings'), 'vjs-lock-showing');
        n.addClass(p, 'vjs-hidden');
        n.removeClass(o.rel_win, 'vjs-hidden');
        J();
        try {
          n.addClass(l, 'vjs-def');
        } catch (e) {}
        e.pause();
      }
    };
    e.share = function () {
      if (i.settingsButton && i.shareMenu && !0 !== e.seeking()) {
        o.rel_win && n.addClass(o.rel_win, 'vjs-hidden');
        n.removeClass(vjs_find(l, '.vjs-menu-settings'), 'vjs-lock-showing');
        let t = i.url || document.location.href,
          s = i.embed || 'N/A';
        vjs_find(l, '.embed-code').value = s;
        vjs_find(l, '.perma').value = t;
        o.share_win && n.removeClass(o.share_win, 'vjs-hidden');
        n.addClass(p, 'vjs-hidden');
        try {
          n.addClass(l, 'vjs-def');
        } catch (e) {}
        e.pause();
      }
    };
    if (!0 !== j(s)) {
      var b = 'deretsiger ton tcudorP',
        _ = vjs_El('div', 'vjs-lcn');
      l.innerHTML = '';
      _.innerHTML = b.split('').reverse().join('');
      l.appendChild(_);
      return;
    }
    !0 !== videojs.browser.TOUCH_ENABLED
      ? document.body.classList.add('no-touch')
      : (i.contextMenu = !1);
    !0 !== i.contextMenu &&
      l.addEventListener(
        'contextmenu',
        function (e) {
          e.preventDefault();
        },
        !1,
      );
    function y(e) {
      for (var t = 0; t < e.length; t++) {
        var s = e[t].dg13();
        if (((s = s.split('').reverse().join('')), o.dom.indexOf(s) > -1))
          return !0;
      }
      return !1;
    }
    let C = 'pictureInPictureToggle',
      x = !0,
      w = vjs_find(l, '.vjs-picture-in-picture-control');
    if ('undefined' === w || null === w) {
      x = !1;
      C = 'fullscreenToggle';
    }
    if (!document.pictureInPictureEnabled && x) {
      n.addClass(w, 'vjs-hidden');
      this.last_el = 'fullscreenToggle';
    }
    if (!0 !== i.pipButton && w) {
      n.addClass(this.pip, 'vjs-hidden');
      C = 'flow' === i.skin ? 'volumePanel' : 'fullscreenToggle';
    }
    !0 !== i.pipButton && (o.tech.disablePictureInPicture = !0);
    if (!0 !== y(s)) {
      l.html = '';
      return;
    }
    if (i.buttonForward) {
      i.buttonRewind && A(!0);
      var k = '';
      10 !== i.rewindforward && (k = ' vjs-eforward');
      let t = e.controlBar.addChild('button', {
        el: n.createEl(
          'button',
          { className: 'vjs-forward-control' + k + ' vjs-control vjs-button' },
          {
            title: e.localize('Forward'),
            type: 'button',
            'aria-disabled': 'false',
          },
        ),
      });
      t.el_.innerHTML =
        '<span aria-hidden="true" class="vjs-icon-placeholder"></span><span class="vjs-control-text" aria-live="polite">' +
        e.localize('Forward') +
        '</span>';
      'party' === i.skin && i.buttonRewind
        ? e.controlBar.el_.insertBefore(
            t.el_,
            vjs_find(l, '.vjs-rewind-control').nextSibling,
          )
        : e.controlBar.el_.insertBefore(
            t.el_,
            e.controlBar.getChild('playToggle').el_.nextSibling,
          );
      t.el_.onclick = function () {
        e.forward();
      };
      isMobile() &&
        (t.el_.ontouchstart = function () {
          e.forward();
        });
    } else i.buttonRewind && A();
    function A(t) {
      let s = 'vjs-rewind-control';
      t && (s = 'vjs-rewind-control vjs-rewind-first');
      10 !== i.rewindforward && (s += ' vjs-erewind');
      let l = e.controlBar.addChild('button', {
        el: n.createEl(
          'button',
          { className: s + ' vjs-control vjs-button' },
          {
            title: e.localize('Rewind'),
            type: 'button',
            'aria-disabled': 'false',
          },
        ),
      });
      l.el_.innerHTML =
        '<span aria-hidden="true" class="vjs-icon-placeholder"></span><span class="vjs-control-text" aria-live="polite">' +
        e.localize('Rewind') +
        '</span>';
      t
        ? 'party' === i.skin
          ? e.controlBar.el_.insertBefore(
              l.el_,
              e.controlBar.getChild('playToggle').el_.nextSibling,
            )
          : e.controlBar.el_.insertBefore(
              l.el_,
              e.controlBar.getChild('playToggle').el_,
            )
        : e.controlBar.el_.insertBefore(
            l.el_,
            e.controlBar.getChild('playToggle').el_.nextSibling,
          );
      l.el_.onclick = function () {
        e.rewind();
      };
      isMobile() &&
        (l.el_.ontouchstart = function () {
          e.rewind();
        });
    }
    let T = e.controlBar.addChild('button', {
        el: n.createEl(
          'div',
          {
            className:
              'vjs-quality-button vjs-menu-button vjs-control vjs-button vjs-hidden',
          },
          { role: 'button', 'aria-haspopup': 'true', 'aria-expanded': 'false' },
        ),
      }),
      I = e.controlBar.addChild('button', {
        el: n.createEl('div', {
          className: 'vjs-control vjs-button vjs-cog-menu-button vjs-hidden',
        }),
      });
    e.controlBar.el_.insertBefore(T.el_, e.controlBar.getChild(C).el_);
    C = 'flow' === i.skin ? T.el_ : e.controlBar.getChild(C).el_;
    e.controlBar.el_.insertBefore(I.el_, C);
    o.settingControl = I.el_;
    let M = n.createEl('div', { className: 'vjs-background-bar' });
    e.el_.insertBefore(M, e.controlBar.el_);
    let E = T.el_,
      L =
        '<span class="quality-span vjs-no-pointer"></span><span class="vjs-control-text" aria-live="polite">' +
        e.localize('Quality') +
        '</span><div class="vjs-menu"><ul class="vjs-menu-content vjs-qlist" role="menu"></ul></div>';
    E.innerHTML = L;
    let P = n.createEl('div', { className: 'vjs-vast-label' });
    P.innerHTML = e.localize('Advertisement');
    let S = 'playToggle';
    'party' === i.skin && (S = 'currentTimeDisplay');
    'treso' === i.skin && (S = 'volumePanel');
    e.controlBar.el_.insertBefore(P, e.controlBar.getChild(S).el_.nextSibling);
    if (i.theaterButton) {
      let t = e.controlBar.addChild('button', {
        el: n.createEl(
          'div',
          {
            className:
              'vjs-control vjs-button vjs-control-button vjs-mode-control',
          },
          { 'aria-live': 'polite', 'aria-disabled': 'false' },
        ),
      });
      t.el_.innerHTML =
        '<span aria-hidden="true" class="vjs-icon-placeholder"></span><span class="vjs-control-text" aria-live="polite">' +
        e.localize('Theater mode') +
        '</span>';
      e.controlBar.el_.insertBefore(
        t.el_,
        e.controlBar.getChild('fullscreenToggle').el_,
      );
      t.el_.onclick = function (s) {
        s.preventDefault();
        s.stopPropagation();
        let i = t.el_;
        if (n.hasClass(i, 'vjs-mode')) {
          n.removeClass(i, 'vjs-mode');
          e.trigger('mode', 'normal');
        } else {
          n.addClass(i, 'vjs-mode');
          e.trigger('mode', 'large');
        }
      };
    }
    let B = vjs_El('div', 'vjs-menu-settings'),
      H = vjs_El('div', 'vjs-menu-div vjs-settings-div');
    B.appendChild(H);
    let z = vjs_El('div', 'vjs-submenu vjs-settings-home');
    H.appendChild(z);
    let q = vjs_El('ul', 'vjs-menu-content vjs-settings-list');
    z.appendChild(q);
    let D = n.createEl(
      'button',
      { className: 'vjs-cog-button vjs-menu-button vjs-button' },
      {
        type: 'button',
        'aria-live': 'polite',
        'aria-expanded': 'false',
        'aria-disabled': 'false',
      },
    );
    D.innerHTML =
      '<span aria-hidden="true" class="vjs-icon-placeholder"></span><span class="vjs-control-text" aria-live="polite">' +
      e.localize('Settings') +
      '</span>';
    o.settingControl.appendChild(D);
    o.settingControl.appendChild(B);
    if (i.downloadButton) {
      let t = e.controlBar.addChild('button', {
        el: videojs.dom.createEl(
          'button',
          {
            className: 'vjs-download-control vjs-control vjs-button',
            title: e.localize('Download'),
          },
          { type: 'button', 'aria-disabled': 'false' },
        ),
      });
      e.controlBar.el_.insertBefore(
        t.el_,
        e.controlBar.getChild('fullscreenToggle').el_,
      );
      t.el_.innerHTML =
        '<span aria-hidden="true" class="vjs-icon-placeholder"></span><span class="vjs-control-text" aria-live="polite">' +
        e.localize('Download') +
        '</span>';
      t.el_.onclick = function () {
        var t = e.currentSource().video_id || i.video_id || null;
        e.trigger('downloadVideo', { source: e.currentSrc(), id: t });
      };
    }
    O();
    function N(t) {
      try {
        if (e.isDisposed()) return;
      } catch (e) {}
      if (i.isDisposed) return;
      '' === t && (t = vjs_find(l, '.vjs-play-control'));
      let s = t.className;
      if ('string' == typeof s || s instanceof String) {
        if (s.indexOf('vjs-menu-item') > -1) return;
        s.indexOf('vjs-chapters-button') < 0 &&
          e.controlBar.getChild('chaptersButton').unpressButton();
        s.indexOf('vjs-descriptions-button') < 0 &&
          e.controlBar.getChild('descriptionsButton').unpressButton();
        if (s.indexOf('vjs-subs-caps-button') < 0)
          try {
            e.controlBar.getChild('subsCapsButton').unpressButton();
          } catch (e) {}
        s.indexOf('vjs-audio-button') < 0 &&
          e.controlBar.getChild('audioTrackButton').unpressButton();
        if (s.indexOf('vjs-quality-button') < 0)
          try {
            var o = vjs_find(E, '.vjs-menu');
            n.hasClass(o, 'vjs-lock-showing') &&
              n.removeClass(o, 'vjs-lock-showing');
          } catch (e) {}
        if (s.indexOf('vjs-cog-button') < 0)
          try {
            let e = vjs_find(l, '.vjs-menu-settings'),
              t = vjs_find(e, '.vjs-zoom-menu'),
              s = vjs_find(e, '.vjs-menu-speed'),
              i = vjs_find(e, '.vjs-quality-menu'),
              o = vjs_find(e, '.vjs-settings-home'),
              a = vjs_find(l, '.vjs-cog-button');
            t && n.addClass(t, 'vjs-hidden');
            i && n.addClass(i, 'vjs-hidden');
            s && n.addClass(s, 'vjs-hidden');
            o && n.removeClass(o, 'vjs-hidden');
            n.removeClass(e, 'vjs-lock-showing');
            a.setAttribute('aria-expanded', 'false');
            n.removeClass(a, 'vjs-cog-active');
          } catch (e) {}
      }
    }
    function O() {
      let e = l.offsetWidth;
      [
        'vjs-1600',
        'vjs-1280',
        'vjs-920',
        'vjs-600',
        'vjs-640',
        'vjs-480',
        'vjs-360',
        'vjs-320',
      ].forEach((e) => {
        n.removeClass(l, e);
      });
      if (e < 360) {
        n.addClass(l, 'vjs-480');
        n.addClass(l, 'vjs-360');
        e < 320 && n.addClass(l, 'vjs-320');
      } else if (e < 480) n.addClass(l, 'vjs-480');
      else if (e < 640) {
        n.addClass(l, 'vjs-640');
        e < 600 && n.addClass(l, 'vjs-600');
      } else
        e < 920
          ? n.addClass(l, 'vjs-920')
          : e < 1280
          ? n.addClass(l, 'vjs-1280')
          : n.addClass(l, 'vjs-1600');
    }
    for (
      var W = vjs_find(l, '.vjs-control-bar'),
        U = vjs_mfind(l, '.vjs-control'),
        Z = 0;
      Z < U.length;
      Z++
    )
      U[Z].removeAttribute('title');
    for (var R = W.querySelectorAll('.vjs-button'), F = 0; Z < R.length; F++)
      R[F].removeAttribute('title');
    window.onresize = function (e) {
      O();
      J();
      X();
      N('');
    };
    function K() {
      try {
        vjs_mfind(l, '.item-quality').forEach((e) => {
          isMobile() && (e.ontouchstart = null);
          e.onclick = null;
        });
      } catch (e) {}
      let e = vjs_mfind(vjs_find(l, '.vjs-menu-quality'), '.item-quality');
      e &&
        e.forEach((e) => {
          e.parentNode.removeChild(e);
        });
      var t = vjs_find('nv,vjs-extend-quality');
      t && n.addClass(t, 'vjs-hidden');
      vjs_find(l, '.vjs-quality-button .vjs-menu .vjs-menu-content').innerHTML =
        '';
      n.addClass(E, 'vjs-hidden');
    }
    function Y(e) {
      try {
        vjs_find(l, '.vjs-reset-zoom').innerHTML = parseInt(e) + '%';
        vjs_find(l, '.zoom-label').innerHTML = parseInt(e) + '%';
      } catch (e) {}
    }
    function Q() {
      let e = E,
        t = vjs_find(e, '.vjs-menu'),
        s = vjs_find(t, '.vjs-menu-content');
      if (i.res_num > 1) {
        function a(e) {
          var i = vjs_find(e.target, '.vjs-control-text');
          if (!0 !== n.hasClass(e.target, 'vjs-quality-button')) return !1;
          if (n.hasClass(e.target, 'vjs-cast')) return !1;
          e.stopPropagation();
          N(e.target);
          let o = vjs_find(e.target, '.vjs-menu');
          if (n.hasClass(t, 'vjs-lock-showing')) {
            e.target.setAttribute('aria-expanded', 'false');
            n.removeClass(o, 'vjs-lock-showing');
            i.removeAttribute('style');
          } else {
            let o = vjs_find(l, '.vjs-control-bar'),
              a = l.offsetHeight - o.offsetHeight - 10;
            s.style.maxHeight = a + 'px';
            n.addClass(t, 'vjs-lock-showing');
            e.target.setAttribute('aria-expanded', 'true');
            i.style.opacity = '0';
          }
        }
        e.onclick = function (e) {
          a(e);
        };
        e.onmousemove = function (e) {
          n.removeClass(t, 'vjs-hidden');
        };
        isMobile() &&
          (e.ontouchstart = function (e) {
            a(e);
          });
        e.onmouseover = function (e) {
          if (!0 !== n.hasClass(t, 'vjs-lock-showing')) {
            var s = vjs_find(e.target, '.vjs-control-text');
            s && s.removeAttribute('style');
          }
        };
      }
      let r = vjs_find(l, '.vjs-settings-div'),
        d = vjs_find(l, '.vjs-menu-speed'),
        v = vjs_find(l, '.vjs-zoom-menu'),
        c = vjs_find(l, '.vjs-menu-quality'),
        u = vjs_find(l, '.vjs-settings-home'),
        f = 100 * o.currentZoom;
      if (d) {
        d.onclick = function (e) {
          e.stopPropagation();
        };
        let e = vjs_find(l, '.vjs-extend-speed');
        function j(e) {
          e.preventDefault();
          e.stopPropagation();
          n.addClass(u, 'vjs-hidden');
          n.removeClass(d, 'vjs-hidden');
          v && n.addClass(v, 'vjs-hidden');
          c && n.addClass(c, 'vjs-hidden');
          r.style.width = h.speedMenu.width + 'px';
          r.style.height = h.speedMenu.height + 'px';
        }
        e.onclick = function (e) {
          j(e);
        };
        isMobile() &&
          (e.ontouchstart = function (e) {
            j(e);
          });
        let t = vjs_find(d, '.vjs-settings-back');
        if (t) {
          function p(e) {
            e.preventDefault();
            e.stopPropagation();
            v && n.addClass(v, 'vjs-hidden');
            d && n.addClass(d, 'vjs-hidden');
            c && n.addClass(c, 'vjs-hidden');
            n.removeClass(u, 'vjs-hidden');
            r.style.width = h.cogMenu.width + 'px';
            r.style.height = h.cogMenu.height + 'px';
            Y(f);
          }
          t.onclick = function (e) {
            p(e);
          };
          isMobile() &&
            (t.ontouchstart = function (e) {
              p(e);
            });
        }
      }
      if (c && i.qualityMenu && i.res_num > 1) {
        n.removeClass(vjs_find(l, '.vjs-cog-menu-button'), 'vjs-hidden');
        c.onclick = function (e) {
          e.preventDefault();
          e.stopPropagation();
        };
        let e = vjs_find(l, '.vjs-extend-quality');
        function m(e) {
          e.preventDefault();
          e.stopPropagation();
          n.addClass(u, 'vjs-hidden');
          n.removeClass(c, 'vjs-hidden');
          v && n.addClass(v, 'vjs-hidden');
          d && n.addClass(d, 'vjs-hidden');
          r.style.height = h.qualityMenu.height + 'px';
          r.style.width = h.qualityMenu.width + 'px';
        }
        e.onclick = function (e) {
          m(e);
        };
        isMobile() &&
          (e.ontouchstart = function (e) {
            m(e);
          });
        let t = vjs_find(c, '.vjs-settings-back');
        if (t) {
          function g(e) {
            e.preventDefault();
            e.stopPropagation();
            n.addClass(c, 'vjs-hidden');
            v && n.addClass(v, 'vjs-hidden');
            d && n.addClass(d, 'vjs-hidden');
            n.removeClass(u, 'vjs-hidden');
            r.style.height = h.cogMenu.height + 'px';
            r.style.width = h.cogMenu.width + 'px';
          }
          t.onclick = function (e) {
            g(e);
          };
          isMobile() &&
            (t.ontouchstart = function (e) {
              g(e);
            });
        }
      }
      if (v) {
        let e = vjs_find(l, '.vjs-extend-zoom');
        function b(e) {
          e.preventDefault();
          e.stopPropagation();
          n.addClass(u, 'vjs-hidden');
          n.addClass(d, 'vjs-hidden');
          n.removeClass(v, 'vjs-hidden');
          d && n.addClass(d, 'vjs-hidden');
          r.style.width = h.zoomMenu.width + 'px';
          r.style.height = h.zoomMenu.height + 'px';
          let t = ((o.currentZoom - 1) / 4) * 100;
          vjs_find(l, '.vjs-zoom-level').height = t + '%';
        }
        e.onclick = function (e) {
          b(e);
        };
        isMobile() &&
          (e.ontouchstart = function (e) {
            b(e);
          });
        let t = vjs_find(v, '.vjs-settings-back');
        if (t) {
          function _(e) {
            e.preventDefault();
            e.stopPropagation();
            v && n.addClass(v, 'vjs-hidden');
            d && n.addClass(d, 'vjs-hidden');
            n.removeClass(u, 'vjs-hidden');
            r.style.width = h.cogMenu.width + 'px';
            r.style.height = h.cogMenu.height + 'px';
            Y(f);
          }
          isMobile()
            ? (t.ontouchstart = function (e) {
                _(e);
              })
            : (t.onclick = function (e) {
                _(e);
              });
        }
      }
      function y(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if (vjs_find(l, '.vjs-tech-chromecast')) return;
        X();
        let t = vjs_find(l, '.vjs-menu-settings');
        v && n.addClass(v, 'vjs-hidden');
        d && n.addClass(d, 'vjs-hidden');
        if (!0 !== n.hasClass(t, 'vjs-lock-showing')) {
          e.target.setAttribute('aria-expanded', 'true');
          N(e.target);
          n.addClass(t, 'vjs-lock-showing');
          vjs_find(D, '.vjs-control-text').style.opacity = 0;
          n.addClass(e.target, 'vjs-cog-active');
          n.removeClass(u, 'vjs-hidden');
          r.style.width = h.cogMenu.width + 'px';
          r.style.height = h.cogMenu.height + 'px';
          Y(f);
        } else {
          e.target.setAttribute('aria-expanded', 'false');
          n.removeClass(e.target, 'vjs-cog-active');
          C();
        }
      }
      function C() {
        v && n.addClass(v, 'vjs-hidden');
        d && n.addClass(d, 'vjs-hidden');
        n.removeClass(z, 'vjs-hidden');
        n.removeClass(B, 'vjs-lock-showing');
        vjs_find(D, '.vjs-control-text').removeAttribute('style');
      }
      D.ontouchstart = function (e) {
        y(e);
      };
      D.onclick = function (e) {
        y(e);
      };
      D.onmouseover = function (e) {
        !0 !== n.hasClass(B, 'vjs-lock-showing') &&
          vjs_find(D, '.vjs-control-text').removeAttribute('style');
      };
    }
    function J() {
      let e = i.related.length,
        t = 0.8;
      isMobile() && (t = 0.9);
      let s = 800;
      if (vjs_find(l, '.rel-block')) {
        n.removeClass(vjs_find(l, '.rel-block'), 'rel-anim');
        let i = l.offsetWidth,
          a = i * t;
        a > s && (a = s);
        let r = parseInt(vjs_find(l, '.vjs-related').style.maxWidth);
        isNaN(r) && (r = 0);
        parseInt(r) < 100 && (r = s);
        a > r && (a = r);
        let d = vjs_find(l, '.vjs-related');
        d.style.maxWidth = s < r ? s + 'px' : '800px';
        d.style.width = 100 * t + '%';
        let v = 3,
          c = 2;
        e < 5 && 3 !== e && (v = 2);
        e < 4 && (c = 1);
        if (a < 480) {
          v = 2;
          c = 1;
        }
        let u = (a / v) * 0.5625,
          f = a / v,
          h = Math.ceil(e / 6);
        o.vjs_relpage > h && (o.vjs_relpage = h);
        o.vjs_relpages = h;
        2 === v && 2 === c && (o.vjs_relpages = Math.ceil(e / 4));
        2 === v && 1 === c && (o.vjs_relpages = Math.ceil(e / 2));
        let j = u * c;
        d.style.height = j + 'px';
        let p = i / 2 - a / 2;
        d.style.top = 0.55 * l.offsetHeight - j / 2 + 'px';
        d.style.left = i / 2 - a / 2 + 'px';
        if (!0 !== isMobile()) {
          let e = vjs_find(l, '.vjs-arrow-prev'),
            t = vjs_find(l, '.vjs-arrow-next'),
            s = parseInt(vjs_find(l, '.vjs-prev').offsetWidth + 5);
          e.style.left = p - s + 'px';
          t.style.left = a + p + 'px';
          n.removeClass(t, 'vjs-disabled');
          n.removeClass(e, 'vjs-disabled');
          o.vjs_relpage === o.vjs_relpages && n.addClass(t, 'vjs-disabled');
          1 === o.vjs_relpage && n.addClass(e, 'vjs-disabled');
        }
        if (o.vjs_relpage > 1) {
          let e = d.offsetWidth,
            t = (o.vjs_relpage - 1) * e;
          vjs_find(l, '.rel-block').style.left = '-' + t + 'px';
        }
        let m = 0,
          g = 0,
          b = l.querySelectorAll('.rel-parent');
        b &&
          b.forEach((e) => {
            e.style.left = m + 'px';
            if (1 === g && c > 1) {
              e.style.top = u + 'px';
              m += f;
            } else e.style.top = 0;
            1 === c && (m += f);
            e.style.width = f + 'px';
            e.style.height = u + 'px';
            c > 1 ? 2 === ++g && (g = 0) : (g = 0);
          });
        n.addClass(vjs_find(l, '.rel-block'), 'rel-anim');
      }
    }
    function X() {
      let e = vjs_find(l, '.vjs-settings-home'),
        t = vjs_find(l, '.vjs-menu-speed'),
        s = vjs_find(l, '.vjs-zoom-menu'),
        o = vjs_find(l, '.vjs-menu-quality'),
        a = vjs_find(l, '.vjs-settings-div'),
        r = vjs_find(l, '.vjs-control-bar');
      n.addClass(r, 'vjs-block');
      a.style.width = 'auto';
      a.style.height = 'auto';
      let d = 10;
      ('shaka' !== i.skin && 'pinko' !== i.skin) || (d = 5);
      'party' === i.skin && (d = 0);
      let v = l.offsetHeight - r.offsetHeight - d;
      a.style.maxHeight = v + 'px';
      t && n.addClass(t, 'vjs-hidden');
      s && n.addClass(s, 'vjs-hidden');
      o && n.addClass(o, 'vjs-hidden');
      let c = vjs_find(l, '.vjs-menu-settings');
      n.removeClass(c, 'vjs-hidden');
      n.addClass(c, 'vjs-invisible');
      h.cogMenu = { width: c.offsetWidth, height: c.offsetHeight };
      if (t) {
        n.addClass(e, 'vjs-hidden');
        s && n.addClass(s, 'vjs-hidden');
        o && n.addClass(o, 'vjs-hidden');
        n.removeClass(t, 'vjs-hidden');
        n.addClass(t, 'vjs-invisible');
        h.speedMenu = { width: t.offsetWidth, height: t.offsetHeight };
        n.removeClass(t, 'vjs-invisible');
        n.addClass(t, 'vjs-hidden');
      }
      if (o && i.qualityMenu) {
        n.addClass(e, 'vjs-hidden');
        s && n.addClass(s, 'vjs-hidden');
        t && n.addClass(t, 'vjs-hidden');
        n.removeClass(o, 'vjs-hidden');
        n.addClass(o, 'vjs-invisible');
        h.qualityMenu = { width: o.offsetWidth + 10, height: o.offsetHeight };
        n.removeClass(o, 'vjs-invisible');
        n.addClass(o, 'vjs-hidden');
        n.removeClass(vjs_find(l, '.vjs-cog-menu-button'), 'vjs-hidden');
      }
      if (s) {
        n.addClass(e, 'vjs-hidden');
        t && n.addClass(t, 'vjs-hidden');
        o && n.addClass(o, 'vjs-hidden');
        n.removeClass(s, 'vjs-hidden');
        n.addClass(s, 'vjs-invisible');
        h.zoomMenu = { width: s.offsetWidth, height: s.offsetHeight };
        n.removeClass(s, 'vjs-invisible');
        n.addClass(s, 'vjs-hidden');
      }
      n.removeClass(r, 'vjs-block');
      n.addClass(vjs_mfind(l, '.vjs-submenu'), 'vjs-hidden');
      n.removeClass(e, 'vjs-hidden');
      n.removeClass(c, 'vjs-invisible');
      n.removeClass(e, 'vjs-hidden');
      (i.speedMenu || i.zoomMenu || i.relatedMenu || i.shareMenu) &&
        i.settingsButton &&
        n.removeClass(vjs_find(l, '.vjs-cog-menu-button'), 'vjs-hidden');
    }
    function G(e, t) {
      let s = parseInt(e),
        n = '',
        o = '',
        a = '';
      if (i.hdicon) {
        a = 'HD';
        s > 2159 && (a = '4K');
        if (s > 719) {
          o = '<i class="vjs-hd-icon vjs-hd-home">' + a + '</i>';
          n = '<i class="vjs-hd-icon vjs-hd-menu">' + a + '</i>';
        }
      }
      i.qualityMenu
        ? (vjs_find(l, '.quality-label').innerHTML = t + o)
        : (vjs_find(l, '.vjs-quality-button span').innerHTML = t + o);
    }
    try {
      vjs_find(l, 'video').controls = !1;
    } catch (e) {}
    '' === i.title && (i.title = i.metatitle = document.title);
    '' === i.infoText && (i.infoText = i.metatitle);
    if (!i.timetooltip) {
      let e = vjs_find(l, '.vjs-play-progress'),
        t = vjs_find(e, '.vjs-time-tooltip');
      e && t && n.addClass(t, 'vjs-abs-hidden');
    }
    try {
      i.mousedisplay ||
        n.addClass(vjs_find(l, '.vjs-mouse-display'), 'vjs-abs-hidden');
    } catch (e) {}
    function V(e, t) {
      return e.res && t.res ? +t.res - +e.res : 0;
    }
    if (i.mirrorButton) {
      e.controlBar.mirrorButton = e.controlBar.addChild('button', {
        el: n.createEl(
          'div',
          {
            text: 'Mirror view',
            className: 'vjs-mirror-button vjs-control vjs-button',
          },
          { role: 'button', 'aria-live': 'polite', 'aria-disabled': 'false' },
        ),
      });
      e.controlBar.mirrorButton.el_.innerHTML =
        '<span class="vjs-control-text" aria-live="polite">' +
        e.localize('Mirror View') +
        '</span>';
      e.controlBar.el_.insertBefore(e.controlBar.mirrorButton.el_, I.el_);
      vjs_find(l, '.vjs-mirror-button').onclick = function (e) {
        e.preventDefault();
        let t = l.className,
          s = !1;
        t.indexOf('vjs-has-started') > 0 && (s = !0);
        t.indexOf('vjs-ended') > 0 && (s = !1);
        if (s) {
          let t = o.tech;
          if (n.hasClass(e.target, 'vjs-mirrored')) {
            n.removeClass(e.target, 'vjs-mirrored');
            t.style.transform = 'rotateY(0)';
            t.style.webkitTransform = 'rotateY(0)';
            t.style.msTransform = 'rotateY(0)';
          } else {
            n.addClass(e.target, 'vjs-mirrored');
            t.style.transform = 'rotateY(180deg)';
            t.style.webkitTransform = 'rotateY(180deg)';
            t.style.msTransform = 'rotateY(180deg)';
          }
        }
      };
    }
    if (i.logocontrolbar) {
      let t = vjs_El('img'),
        s = !1;
      t.src = i.logocontrolbar;
      t.onload = function () {
        this.width + this.height === 0 && (s = !0);
        if (!0 !== s) {
          let s = vjs_El('div', 'vjs-logo-bar');
          if ('' !== i.logourl) {
            let e = vjs_El('a');
            e.href = i.logourl;
            e.target = i.target;
            i.logotitle && e.setAttribute('title', i.logotitle);
            e.appendChild(t);
            s.appendChild(e);
            s.style.cursor = 'pointer';
          } else s.appendChild(t);
          e.controlBar
            .el()
            .insertBefore(s, e.controlBar.getChild('fullscreenToggle').el_);
        }
      };
    }
    if (i.contextMenu) {
      let t = vjs_El('div', 'vjs-context-menu vjs-hidden'),
        s = '';
      if ('' === i.contextText) {
        i.contextText = 'Powered by Nuevodevel.com';
        i.contextUrl = 'https://www.nuevodevel.com/nuevo';
        s =
          '<img src="data:image/gif;base64,R0lGODlhJwASAOZrAPr6+vb29vT09PX19ff39/Ly8ra2tv///+Hh4ebm5tPT09TU1Nzc3Nra2tnZ2dvb2+3t7cHBwX5+fmdnZzc3N0NDQzo6Oq+vr/z8/PDw8GRkZNLS0v39/ZmZmcXFxe7u7srKysjIyPPz83h4eP7+/vj4+Lu7u9/f39HR0XFxcfQAAMQAAHp6euvr6+np6dDQ0GxsbPwAAK0AAHl5eX9/f+fn5/gAAPkAAOIAAIEAALKysjY2Nry8vPHx8Z2dnejo6G5ubvsAAPUAAKIAAPn5+Xt7e7W1tYyMjGtra/oAANYAAGUAAOTk5P0AAOAAAIwAADQAAL0AAD0AAMPDw7GxsW9vb3JycpycnPv7+5qamuXl5U5OTvb396qqqrS0tLe3txQUFJ6enpGRkWVlZaGhoaampkhISJubm42NjXx8fE9PT////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAHoA2sALAAAAAAnABIAAAf/gAABAoSFhoQDh4WJhAEEAZAEBQZrlWsHmJmam5ydnQiWCQoLDKWmpwwNDg2oracPEJgRlhITlre3FBUWuL29FxgZGmsbHAEdvpYeHyCVICHJvSIEI2skmCUm0ScHKJWYKZYqK8kCJSxrHAcYmC3JLgcv3wcwljExKjK95jPpBxsgriWg0atGvHn1KsWwcSMGjhy3zKFTp2MHD3U9fOD6cfDSASD2bIgMImSIJQFEivgzUulID0websGT5xFJyCRClCyJWKKfOpaVWDA5wGGDpaE0MSVc0ySIkydQcPFbiYvbAReVFHRUailIFCm+JFLFNaXEgQFUHGw9UCXarRIDWKyMxXWlwAEsANZmcVsJAgktW+biksAOkzePBTIQ4DJgQInHkPMe6FKJAwYvbhdg4PClMpbPoEODJlCjC5hKYcSM4UumjJlKZ9AcmU279hE0adTw3c3bVyAAOw==" />';
        s += '<span>' + i.contextText + '</span>';
        s =
          '<a class="ctxt" target="' +
          i.target +
          '" href="' +
          i.contextUrl +
          '">' +
          s +
          '</a>';
        $(t);
      } else if (i.contextIcon) {
        let e = vjs_El('img');
        e.src = i.contextIcon;
        e.onload = function () {
          if (this.width + this.height > 0) {
            s = '<img src="' + i.contextIcon + '" />';
            s += '<span>' + i.contextText + '</span>';
            '' !== i.contextUrl &&
              (s =
                '<a class="ctxt" target="' +
                i.target +
                '" href="' +
                i.contextUrl +
                '">' +
                s +
                '</a>');
            $(t);
          }
        };
        e.onerror = function () {
          s = '<span>' + i.contextText + '</span>';
          '' !== i.contextUrl &&
            (s =
              '<a class="ctxt" target="' +
              i.target +
              '" href="' +
              i.contextUrl +
              '">' +
              s +
              '</a>');
          $(t);
        };
      }
      function $(t) {
        t.innerHTML = s;
        e.el().appendChild(t);
        let i;
        l.addEventListener('contextmenu', function (e) {
          clearTimeout(i);
          e.preventDefault();
          n.removeClass(t, 'vjs-hidden');
          let s = t.offsetWidth,
            o = t.offsetHeight,
            a = null,
            r = null;
          e.clientY && (r = e.clientY);
          e.clientX && (a = e.clientX);
          if (null !== r && null !== a) {
            let e = l.getBoundingClientRect(),
              d = r - e.top,
              v = a - e.left;
            d + o > l.offsetHeight && (d = l.offsetTop + l.offsetHeight - o);
            v + s > l.offsetWidth && (v = l.offsetWidth - s);
            t.style.top = d + 'px';
            t.style.left = v + 'px';
            i = setTimeout(function () {
              n.addClass(t, 'vjs-hidden');
            }, 3e3);
            window.onscroll = function (e) {
              n.addClass(t, 'vjs-hidden');
            };
            window.onclick = function (e) {
              n.addClass(t, 'vjs-hidden');
            };
          }
        });
      }
    }
    if (i.logo) {
      let e = vjs_El('img');
      e.src = i.logo;
      let t = vjs_El('div', 'vjs-logo');
      i.logomin && (t.className = 'vjs-logo vjs-logo-min');
      l.appendChild(t);
      e.onload = function () {
        if (this.width + this.height === 0) {
          l.removeChild(t);
          return;
        }
        let s = i.logooffsetX;
        if ('RT' === i.logoposition) {
          t.style.right = s + 'px';
          t.style.top = s + 'px';
        } else if ('LB' === i.logoposition) {
          t.style.left = s + 'px';
          t.className += ' vjs-logo-bottom';
        } else {
          t.style.left = s + 'px';
          t.style.top = s + 'px';
        }
        if ('' !== i.logourl) {
          let s = vjs_El('a');
          s.href = i.logourl;
          s.target = i.target;
          i.logotitle && s.setAttribute('title', i.logotitle);
          s.appendChild(e);
          t.appendChild(s);
        } else t.appendChild(e);
      };
      e.onerror = function () {
        l.removeChild(t);
      };
    }
    if ('treso' === i.skin) {
      let e = vjs_find(l, '.vjs-current-time'),
        t = vjs_find(l, '.vjs-duration'),
        s = vjs_find(l, '.vjs-control-bar');
      s.removeChild(e);
      let i = vjs_find(l, '.vjs-progress-control');
      i.insertBefore(e, i.childNodes[0]);
      s.removeChild(t);
      i.appendChild(t);
    }
    function ee() {
      if (!vjs_find(l, '.vjs-extend-quality')) {
        let t = vjs_El(
          'li',
          'vjs-settings-item vjs-extend-quality vjs-menu-forward vjs-hidden',
          e.localize('Quality') + '<span class="quality-label"></span>',
        );
        vjs_find(l, '.vjs-settings-list').appendChild(t);
        let s = vjs_El(
          'div',
          'vjs-submenu vjs-menu-quality vjs-hidden',
          '<ul class="vjs-menu-content"><li class="vjs-settings-back">' +
            e.localize('Quality') +
            '</li></ul>',
        );
        vjs_find(l, '.vjs-settings-div').appendChild(s);
      }
    }
    function te() {
      if (i.qualityMenu) {
        n.addClass(vjs_find(l, '.vjs-menu-quality'), 'vjs-hidden');
        n.removeClass(vjs_find(l, '.vjs-settings-home'), 'vjs-hidden');
        n.removeClass(vjs_find(l, '.vjs-menu-settings'), 'vjs-lock-showing');
      } else {
        let e = vjs_find(l, '.vjs-quality-button');
        if (e) {
          let t = vjs_find(e, '.vjs-menu'),
            s = vjs_find(e, '.vjs-control-text');
          if (t) {
            t.style.display = 'none';
            n.removeClass(t, 'vjs-lock-showing');
            s.removeAttribute('style');
          }
        }
      }
    }
    e.removeFromPlaylist = function (e) {
      if (i.playlist && i.playlistUI) {
        var t = vjs_find(l, '.vjs-vlist'),
          s = t.childNodes[e];
        t.removeChild(s);
        for (var n = 0; n < t.childNodes.length; n++)
          n != t.childNodes.length - 1 &&
            t.childNodes[n].setAttribute('data-id', n);
      }
    };
    e.addToPlaylist = function (e, t, s) {
      if (i.playlist && i.playlistUI) {
        var n,
          o = vjs_find(l, '.vjs-vlist'),
          a = o.childNodes.length;
        if ('number' == typeof s && 'string' == typeof t) {
          if ('after' === t) {
            n = se(e, s + 1);
            o.insertBefore(n, o.childNodes[s].nextSibling);
          } else if ('before' === t) {
            n = se(e, s);
            o.insertBefore(n, o.childNodes[s]);
          }
          for (var r = 0; r < o.childNodes.length; r++)
            r != a && o.childNodes[r].setAttribute('data-id', r);
        } else {
          n = se(e, a - 1);
          o.insertBefore(n, o.childNodes[a - 2].nextSibling);
        }
      }
    };
    function se(t, s) {
      var i = vjs_El('div', 'vjs-item');
      i.setAttribute('data-id', s);
      var n = vjs_El('div', 'vjs-tmb');
      t.thumb
        ? (n.style.backgroundImage = 'url(' + t.thumb + ')')
        : t.poster && (n.style.backgroundImage = 'url(' + t.poster + ')');
      i.appendChild(n);
      var l = vjs_El('p');
      if (t.title) l.innerHTML = t.title;
      else {
        var o = '';
        if (t.audioInfo && null != typeof t.audioInfo.title) {
          o = t.audioInfo.title;
          null != typeof t.audioInfo.artist &&
            (o = t.audioInfo.artist + ' - ' + o);
        }
        if ('' != o) l.innerHTML = o;
        else {
          var a = (d = t.sources[0].src).substring(d.lastIndexOf('/') + 1);
          if (t.sources.length > 0)
            for (var r = 0; r < t.sources.length; r++)
              if (t.sources[r].default) {
                var d;
                a = (d = t.sources[r].src).substring(d.lastIndexOf('/') + 1);
              }
          a = a.replace(/(\.[^/.]+)+$/, '');
          l.innerHTML = a;
        }
      }
      i.appendChild(l);
      if (t.duration) {
        var v = vjs_El('span');
        v.innerHTML = t.duration;
        i.appendChild(v);
      }
      var c = !1;
      i.onclick = function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        u(e);
      };
      i.ontouchstart = function (e) {
        c = !1;
      };
      i.ontouchmove = function (e) {
        c = !0;
      };
      i.ontouchend = function (e) {
        if (1 != c) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          u(e);
        }
      };
      function u(t) {
        if (!e.adPlaying) {
          var s = t.target.getAttribute('data-id');
          e.playlist.currentItem(parseInt(s));
          if (e.paused()) {
            var i = e.play();
            void 0 !== i &&
              'function' == typeof i.then &&
              i.then(null, function (e) {});
          }
        }
      }
      return i;
    }
    e.on('playlistready', function () {
      i.playlistRepeat && e.playlist.repeat(!0);
      if (!(e.playlist.list().length < 2)) {
        if (!0 !== i.playlist) {
          i.playlist = !0;
          if (i.playlistNavigation) {
            let t = vjs_El(
                'div',
                'vjs-playlist-nav vjs-nav-prev',
                '<div class="vjs-prev vjs-disabled"></div>',
              ),
              s = vjs_El(
                'div',
                'vjs-playlist-nav vjs-nav-next',
                '<div class="vjs-next"></div>',
              );
            l.appendChild(t);
            l.appendChild(s);
            s.onclick = function (t) {
              e.adPlaying ||
                (t.target.className.indexOf('disabled') < 0 &&
                  e.playlist.next());
            };
            t.onclick = function (t) {
              e.adPlaying ||
                (t.target.className.indexOf('disabled') < 0 &&
                  e.playlist.previous());
            };
          }
          if (i.playlistUI) {
            let s = n.createEl(
              'div',
              { className: 'vjs-playlist-button' },
              { title: 'Playlist' },
            );
            l.appendChild(s);
            s.onclick = function (e) {
              n.addClass(o, 'vjs-vplaylist-show');
              i.playlistFirst && n.addClass(o, 'vjs-vplaylist-first');
            };
            let o = vjs_El('div', 'vjs-vplaylist vjs-vplaylist-show'),
              a = vjs_El('div', 'vjs-head', '<span>PLAYLIST</span>');
            o.style.visibility = 'hidden';
            let r = vjs_El('div', 'vjs-back', '<i></i>');
            a.appendChild(r);
            o.appendChild(a);
            l.appendChild(o);
            let d = vjs_El('div', 'vjs-vlist');
            o.appendChild(d);
            r.onclick = function (e) {
              n.removeClass(o, 'vjs-vplaylist-show');
            };
            let v = e.playlist.list();
            for (Z = 0; Z < v.length; Z++) {
              var t = se(v[Z], Z);
              void 0 !== t && d.appendChild(t);
            }
            let c = vjs_El('div', 'vjs-last');
            d.appendChild(c);
            if (!0 !== i.playlistShow) {
              o.className = 'vjs-vplaylist';
              setTimeout(function () {
                o.style.visibility = 'visible';
              }, 500);
            } else o.style.visibility = 'visible';
          }
        }
        e.on('playlist_newitem', function (t, s) {
          var o = e.playlist.currentIndex();
          if (i.playlistUI) {
            let e = vjs_mfind(l, '.vjs-vlist .vjs-item');
            e &&
              e.forEach((e) => {
                n.removeClass(e, 'vjs-active-item');
              });
          }
          e.on('play', function () {
            if (i.playlistUI) {
              let e = vjs_mfind(l, '.vjs-vlist .vjs-item');
              e &&
                e.forEach((e) => {
                  n.removeClass(e, 'vjs-active-item');
                  e.getAttribute('data-id') === o &&
                    n.addClass(e, 'vjs-active-item');
                });
              if (i.playlistAutoHide) {
                let e = vjs_find(l, '.vjs-vplaylist');
                n.removeClass(e, 'vjs-vplaylist-show');
              }
            }
            if (i.playlistNavigation) {
              let t = vjs_find(l, '.vjs-nav-prev'),
                s = vjs_find(l, '.vjs-nav-next');
              0 === e.playlist.currentIndex()
                ? n.addClass(vjs_find(t, '.vjs-prev'), 'vjs-disabled')
                : n.removeClass(vjs_find(t, '.vjs-prev'), 'vjs-disabled');
              e.playlist.currentIndex() === e.playlist.lastIndex()
                ? n.addClass(vjs_find(s, '.vjs-next'), 'vjs-disabled')
                : n.removeClass(vjs_find(s, '.vjs-next'), 'vjs-disabled');
            }
          });
          try {
            if (e.isInPictureInPicture()) {
              e.exitPictureInPicture();
              document.exitPictureInPicture();
              e.isInPictureInPicture(!1);
              let t = !1;
              e.on('play', function () {
                if (!0 !== t) {
                  t = !0;
                  e.requestPictureInPicture();
                }
              });
            }
          } catch (e) {}
        });
      }
    });
    e.resetNuevo = function (t) {
      let s,
        a,
        r = [],
        d = [];
      e.options_.sources.length > 0 && (d = r = e.options_.sources);
      K();
      X();
      try {
        vjs_find(
          l,
          '.vjs-quality-button .vjs-menu .vjs-menu-content',
        ).innerHTML = '';
        n.addClass(E, 'vjs-hidden');
      } catch (e) {}
      let v = o.tech;
      if (!0 !== videojs.browser.IS_IOS)
        try {
          for (; v.firstChild; ) v.removeChild(v.firstChild);
        } catch (e) {}
      e.src(r);
      if (r.length > 1) {
        let t = 0;
        for (var c = 0; c < r.length; c++) (r[c].res || r[c].label) && t++;
        if (t >= 1) {
          let t = '',
            r = 'MediaSource' in window;
          var u = videojs.browser.IS_IOS,
            f = !1,
            h = !1,
            j = d[0].type;
          j.indexOf('application') > -1 && (h = !0);
          j && 1 != u && 1 != r && (h = !1);
          j.indexOf('apple') > -1 && r && (f = !0);
          if (h) return;
          if (f && 1 != r) return;
          for (var p = new Array(), m = 0; m < d.length; m++)
            if (d[m].res && d[m].label) {
              void 0 !== a && (a = d[m]);
              p.push(d[m]);
            }
          p = p.sort(V);
          let v = ' class="item-quality"',
            c = ' class="item-quality vjs-checked"',
            _ = 0;
          for (let e = 0; e < p.length; e++) {
            let n = p[e].res,
              l = parseInt(n),
              o = '',
              r = '';
            if (i.hdicon) {
              r = 'HD';
              l > 1079 && (r = 'FullHD');
              l > 2159 && (r = '4K');
              l > 719 && (o = '<i class="vjs-hd-icon">' + r + '</i>');
            }
            if (p[e].default && p[e] !== a) {
              s = p[e];
              t +=
                '<li' +
                c +
                ' data-height="' +
                e +
                '">' +
                p[e].label +
                o +
                '</li>';
            } else
              t +=
                '<li' +
                v +
                ' data-height="' +
                e +
                '">' +
                p[e].label +
                o +
                '</li>';
            _++;
          }
          if (_ > 1) {
            i.res_num = _;
            if (i.qualityMenu) {
              ee();
              let e = vjs_find(l, '.vjs-menu-quality .vjs-menu-content');
              e.innerHTML = e.innerHTML + t;
              n.removeClass(vjs_find(l, '.vjs-extend-quality'), 'vjs-hidden');
              n.removeClass(D, 'vjs-hidden');
            } else {
              vjs_find(
                l,
                '.vjs-quality-button .vjs-menu .vjs-menu-content',
              ).innerHTML = t;
              n.removeClass(E, 'vjs-hidden');
            }
            let r = vjs_mfind(l, '.item-quality');
            X();
            for (let t = 0; t < r.length; t++) {
              let s = r[t];
              function g(t) {
                t.stopPropagation();
                let s;
                for (let e = 0; e < r.length; e++) {
                  n.removeClass(r[e], 'vjs-checked');
                  r[e] === t.target && (s = e);
                }
                n.addClass(r[s], 'vjs-checked');
                let o = t.target.getAttribute('data-height');
                G(p[o].res, p[o].label);
                let a = p[o];
                var d = e.currentSource().video_id || i.video_id || null;
                e.trigger('resolutionchange', {
                  player: l.id,
                  id: d,
                  resolution: o,
                });
                te();
                b(a);
              }
              let o = !1;
              s.onclick = function (e) {
                g(e);
              };
              if (isMobile()) {
                s.ontouchend = function (e) {
                  !0 !== o && g(e);
                };
                s.ontouchstart = function (e) {
                  o = !1;
                };
                s.ontouchmove = function (e) {
                  o = !0;
                };
              }
            }
            Q();
            if (void 0 !== s)
              if (void 0 !== s && s !== a) {
                G(s.res, s.label);
                e.src(s);
                e.load();
              } else if ('undefined' != typeof ini_source) {
                G(a.res, a.label);
                e.src(a);
                e.load();
              }
            function b(t) {
              let s = e.currentTime(),
                n = e.paused();
              for (
                var l = e.remoteTextTracks(), a = [], r = 0;
                r < l.length;
                r++
              ) {
                var d = { src: l[r].src, kind: l[r].kind };
                l[r].mode && (d.mode = l[r].mode);
                l[r].srclang && (d.srclang = l[r].srclang);
                l[r].label && (d.label = l[r].label);
                a.push(d);
              }
              o.sameData = !0;
              e.src(t);
              e.load();
              e.play();
              1 !== i.rate && e.setRate(i.rate);
              e.one('loadeddata', function () {
                s > 0 && e.currentTime(s);
                n && e.pause();
                for (var t = e.remoteTextTracks(), i = 0; i < a.length; i++)
                  if (a[i].src && a[i].kind) {
                    var l = !1;
                    if (((t && t.length) || 0) > 0)
                      for (var o = 0; o < t.length; o++)
                        a[i].src === t[o].src && (l = !0);
                    !0 !== l && e.addRemoteTextTrack(a[i], !0);
                  }
              });
              e.handleTechSeeked_();
            }
          }
        }
      }
    };
    function ie() {
      let t = vjs_find(l, '.vjs-mouse-display');
      if (!0 !== i.is_audio && i.slideImage && !0 !== isMobile() && t) {
        if ('' === i.slideImage) return;
        if (i.currentSlide === i.slideImage) return;
        i.currentSlide = i.slideImage;
        let t = vjs_find(
          vjs_find(l, '.vjs-play-progress'),
          '.vjs-time-tooltip',
        );
        n.addClass(t, 'vjs-abs-hidden');
        n.addClass(vjs_find(l, '.vjs-mouse-display'), 'vjs-abs-hidden');
        if (vjs_find(l, '.vjs-progress-slide'))
          o.slide_el && n.addClass(o.slide_el, 'vjs-hidden');
        else {
          o.slide_el = vjs_El('div', 'vjs-progress-slide');
          o.s_thumb = vjs_El('div', 'vjs-thumb');
          o.s_thumb_dur = vjs_El('div', 'vjs-thumb-duration');
          o.slide_img = vjs_El('img');
          if ('horizontal' === i.slideType) {
            o.slide_img.style.width = 'auto';
            o.slide_img.style.height = i.slideHeight + 'px';
          } else {
            o.slide_img.style.height = 'auto';
            o.slide_img.style.width = i.slideWidth + 'px';
          }
          o.s_thumb.appendChild(o.slide_img);
          o.s_thumb.appendChild(o.s_thumb_dur);
          o.slide_el.appendChild(o.s_thumb);
          o.s_thumb.style.left = '-' + parseInt(i.slideWidth / 2) + 'px';
          vjs_find(l, '.vjs-progress-control').appendChild(o.slide_el);
          o.slide_el.style.left = '-1000px';
          n.addClass(t, 'vjs-abs-hidden');
          n.addClass(vjs_find(l, '.vjs-mouse-display'), 'vjs-abs-hidden');
          function s(t) {
            parseInt(e.duration());
            let s = vjs_find(l, '.vjs-progress-holder').offsetWidth,
              a = parseFloat(vjs_find(l, '.vjs-mouse-display').style.left),
              r = vjs_find(l, '.vjs-progress-control').offsetWidth,
              d = Number(a) / Number(s),
              v = vjs_find(
                vjs_find(l, '.vjs-mouse-display'),
                '.vjs-time-tooltip',
              ),
              c = '';
            v && (c = v.innerHTML);
            let u = parseInt(d * o.num_slide);
            o.s_thumb.style.width = i.slideWidth + 'px';
            o.s_thumb.style.height = i.slideHeight + 'px';
            if ('horizontal' === i.slideType) {
              let e = u * i.slideWidth;
              o.slide_img.style.left = '-' + e + 'px';
            } else {
              let e = u * i.slideHeight;
              o.slide_img.style.top = '-' + e + 'px';
            }
            '' !== c && (o.s_thumb_dur.innerHTML = c);
            a += vjs_find(l, '.vjs-progress-holder').offsetLeft;
            let f = i.slideWidth / 2,
              h = r - i.slideWidth / 2;
            a > h && (a = h);
            a < f && (a = f);
            o.slide_el.style.left = a + 'px';
            n.addClass(o.s_thumb, 'vjs-thumb-show');
          }
          vjs_find(l, '.vjs-progress-holder').onmousemove = function (e) {
            vjs_find(l, '.vjs-tech-chromecast') ||
              (o.slide_el && '' !== o.slide_img.src && s(e));
          };
          vjs_find(l, '.vjs-progress-holder').onmouseout = function (e) {
            if (!vjs_find(l, '.vjs-tech-chromecast') && o.slide_el) {
              n.removeClass(o.s_thumb, 'vjs-thumb-show');
              o.slide_el.style.left = '-1000px';
            }
          };
          let a = new Image();
          o.slide_img.src = i.slideImage;
          a.src = i.slideImage;
          a.onload = function () {
            let e = this.width,
              t = this.height;
            o.num_slide = e / i.slideWidth;
            'horizontal' !== i.slideType && (o.num_slide = t / i.slideHeight);
            n.removeClass(o.slide_el, 'vjs-hidden');
          };
        }
      }
    }
    e.loadTracks = function (t) {
      let s = new Array(),
        i = new Array();
      Array.isArray(t) ? (s = t) : ((s = new Array())[0] = t);
      var n = !1,
        l = !1,
        o = e.textTracks();
      for (let t = 0; t < s.length; t++) {
        let a = s[t];
        if ('chapters' == a.kind)
          for (let t = 0; t < o.length; t++)
            if ('chapters' == o[t].kind) {
              e.remoteTextTracks().removeTrack(o[t]);
              break;
            }
        if (a.kind && a.src) {
          i[t] = e.addRemoteTextTrack(a, !0);
          i[t].addEventListener('load', function () {
            if ('chapters' === this.kind && !0 !== n) {
              n = !0;
              le();
            }
            if ('metadata' === this.kind && !0 !== l) {
              l = !0;
              let t = e.textTracks().length;
              e.textTracks()[t - 1].src = this.src;
              ne();
            }
          });
        }
      }
    };
    function ne() {
      let t = !1,
        s = e.textTracks();
      if (s.length > 0)
        for (let i = 0; i < s.length; i++)
          'metadata' === s[i].kind &&
            s[i].src &&
            !0 !== t &&
            e.trigger('medialoaded');
    }
    function le() {
      n.removeClass(l, 'vjs-markers');
      let t = e.textTracks();
      if (i.chapterMarkers) {
        let s = l.getElementsByClassName('vjs-marker');
        if (s) for (; s.length > 0; ) s[0].parentNode.removeChild(s[0]);
        let i = !1,
          o = 'undefined';
        if (t.length > 0)
          for (let e = 0; e < t.length; e++)
            if ('chapters' === t[e].kind && t[e].cues && !0 !== i) {
              i = !0;
              o = t[e];
            }
        if ('undefined' !== o) {
          let t = o.cues;
          if (t) {
            try {
              n.addClass(vjs_find(l, '.vjs-mouse-display'), 'vjs-abs-hidden');
            } catch (e) {}
            let s = [],
              i = vjs_find(l, '.vjs-progress-holder'),
              o = e.duration();
            for (let e = 0; e < t.length; e++)
              if (t[e].startTime > 0) {
                n.addClass(l, 'vjs-markers');
                s[e] = t[e].startTime;
                let a = vjs_El('div', 'vjs-marker'),
                  r = vjs_El('div', 'vjs-marker-inn'),
                  d = vjs_El('div', 'vjs-marker-tooltip');
                a.appendChild(r);
                a.appendChild(d);
                d.innerHTML = t[e].text;
                a.style.left = (s[e] / o) * 100 + '%';
                i.appendChild(a);
                a.onmouseenter = function () {
                  let e = this.offsetLeft,
                    t = vjs_find(this, '.vjs-marker-tooltip'),
                    s = t.offsetWidth / 2,
                    i = vjs_find(l, '.vjs-progress-control'),
                    n = (i.offsetLeft, i.offsetWidth),
                    o = -1 * e + s,
                    a = (t.offsetWidth / 2) * -1 + (n - e);
                  e - s < 0
                    ? (t.style.left = o + 'px')
                    : e + s > n
                    ? (t.style.left = a + 'px')
                    : t.removeAttribute('style');
                };
              }
          }
        }
      }
    }
    function oe() {
      let t = e.textTracks(),
        s = [];
      if (e.textTracks().length > 0)
        for (let e = 0; e < t.length; e++) {
          let i = new Object(),
            n = t[e];
          if ('captions' === n.kind) {
            i.src = n.src;
            i.language = n.language;
            i.label = n.label;
            'showing' === n.mode && (i.mode = 'showing');
            s.push(i);
          }
        }
      s.length > 0 && (e.captions = s);
    }
    e.reconnect = function () {
      let t = e.src();
      e.reset();
      e.src(t);
      e.load();
      o.tech = e.$('.vjs-tech');
      e.resetNuevo(!0);
      e.play();
    };
    e.setRate = function (t) {
      try {
        if (parseFloat(t) > 0) {
          let s = t + 'x';
          e.playbackRate(t);
          i.rate = t;
          let o = l.querySelectorAll('.vjs-speed');
          for (let e = 0; e < o.length; e++) {
            n.removeClass(o[e], 'vjs-checked');
            s === o[e].innerHTML && n.addClass(o[e], 'vjs-checked');
          }
          '1' === t && (s = 'Normal');
          vjs_find(l, '.vjs-extend-speed span').innerHTML = s;
        }
      } catch (e) {}
    };
    e.changeSrc = function (t) {
      if (n.hasClass(l, 'vjs-ad-playing') || n.hasClass(l, 'vjs-dai')) return;
      i.title = '';
      i.metatitle = '';
      i.metasubtitle = '';
      let s = e.remoteTextTracks(),
        a = (s && s.length) || 0;
      for (; a--; ) e.removeRemoteTextTrack(s[a]);
      let r = e.videoTracks();
      for (let t = r.length - 1; t >= 0; t--) e.videoTracks().removeTrack(r[t]);
      let d = e.audioTracks();
      for (let t = d.length - 1; t >= 0; t--) e.videoTracks().removeTrack(d[t]);
      if (t) {
        'string' == typeof t && (t = { sources: [{ src: t }] });
        t.src && (t = { sources: [t] });
        if (t.sources) {
          i.rate = 1;
          e.setRate(1);
          i.video_id = void 0;
          void 0 !== t.video_id && (i.video_id = t.video_id);
          void 0 !== t.audioInfo
            ? (i.audioInfo = t.audioInfo)
            : (i.audioInfo = void 0);
          void 0 !== t.slideImage
            ? (i.slideImage = t.slideImage)
            : (i.slideImage = '');
          void 0 !== t.thumbnails
            ? (i.thumbnails = t.thumbnails)
            : (i.thumbnails = null);
          void 0 !== t.title && (i.metatitle = i.title = t.title);
          void 0 !== t.shareTitle && (i.metatitle = i.title = t.shareTitle);
          void 0 !== t.embed && (i.embed = t.embed);
          void 0 !== t.metatitle && (i.metatitle = t.metatitle);
          void 0 !== t.subtitle && (i.metasubtitle = t.subtitle);
          void 0 !== t.metasubtitle && (i.metasubtitle = t.metasubtitle);
          void 0 !== t.infoTitle
            ? (i.infoTitle = t.infoTitle)
            : (i.infoTitle = '');
          void 0 !== t.infoDescription
            ? (i.infoDescription = t.infoDescription)
            : (i.infoDescription = '');
          void 0 !== t.infoUrl ? (i.infoUrl = t.infoUrl) : (i.infoUrl = '');
          void 0 !== t.infoIcon && (i.infoIcon = t.infoIcon);
          void 0 !== t.url && (i.url = t.url);
          for (let e = 0; e < t.sources.length; e++) {
            t.sources[e].metatitle ||
              t.sources[e].title ||
              (t.sources[e].metatitle = i.metatitle);
            !t.sources[e].metatitle &&
              t.sources[e].title &&
              (t.sources[e].metatitle = t.sources[e].title);
            t.sources[e].metasubtitle ||
              t.sources[e].subtitle ||
              (t.sources[e].metasubtitle = i.metasubtitle);
            !t.sources[e].metasubtitle &&
              t.sources[e].subtitle &&
              (t.sources[e].metasubtitle = t.sources[e].subtitle);
          }
          de();
          g();
          e.options_.sources = t.sources;
          e.captions = null;
          if (void 0 !== t.tracks) {
            let e = t.tracks;
            for (let t = 0; t < e.length; t++)
              if (void 0 !== e[t].src && 'captions' == e[t].kind) {
                e[t].default && (e[t].mode = 'showing');
                e[t].language = e[t].srclang;
              }
          }
          s.length > 0 && (e.captions = s);
          o.tech = e.$('.vjs-tech');
          !0 !== i.pipButton && (o.tech.disablePictureInPicture = !0);
          e.src(t.sources);
          setTimeout(function () {
            let s = vjs_find(l, '.vjs-poster');
            if (void 0 !== t.poster) {
              s.style.backgroundImage = 'url(' + t.poster + ')';
              e.poster(t.poster);
            } else void 0 !== i.firstplay && e.poster(null);
            n.removeClass(s, 'vjs-hidden');
            i.firstplay = !0;
          }, 200);
          e.resetNuevo(!0, t);
          setTimeout(function () {
            if (void 0 !== t.tracks) {
              let s = t.tracks;
              for (let t = 0; t < s.length; t++)
                if (void 0 !== s[t].src) {
                  if (s[t].default && 'captions' === s[t].kind) {
                    s[t].mode = 'showing';
                    e.currentTrack = s[t].src;
                  }
                  e.addRemoteTextTrack(s[t], !0).addEventListener(
                    'load',
                    function () {
                      'chapters' === this.kind && le();
                      if ('metadata' === this.kind) {
                        let i = e.textTracks().length;
                        e.textTracks()[i - 1].src = s[t].src;
                        ne();
                      }
                    },
                  );
                }
            } else if (void 0 !== t.track && void 0 !== t.track.src) {
              'captions' === t.track.kind && (t.track.mode = 'showing');
              e.addRemoteTextTrack(t.track, !0).addEventListener(
                'load',
                function () {
                  'chapters' === this.kind && le();
                  if ('metadata' === this.kind) {
                    let t = e.textTracks().length;
                    e.textTracks()[t - 1].src = this.src;
                    ne();
                  }
                },
              );
            }
          }, 200);
        }
      }
    };
    e.changeSource = function (t) {
      if (e.adPlaying) return;
      if (!t) return;
      let s = [];
      if (t.sources) s = t;
      else {
        'string' == typeof t && (s = { sources: [{ src: t }] });
        t.src && (s = { sources: [t] });
      }
      if (!s.sources) return;
      let i = 1;
      e.paused() && (i = 2);
      e.changeSrc(s);
      if (2 === i) {
        e.pause();
        n.removeClass(p, 'vjs-abs-hidden');
      } else e.play();
    };
    e.setSource = function (t) {
      e.changeSource(t);
    };
    e.on('loadeddata', function () {
      if (!1 !== o.sameData) {
        o.sameData = !1;
        return;
      }
      'AUDIO' === o.tech.nodeName &&
        videojs.browser.IS_IOS &&
        n.addClass(vjs_find(l, '.vjs-subs-caps-button'), 'vjs-hidden');
      i.is_audio = !1;
      n.removeClass(l, 'vjs-audio');
      if (e.currentType().indexOf('audio') > -1) {
        n.addClass(l, 'vjs-audio');
        i.is_audio = !0;
      }
      ie();
      e.trigger('medialoaded');
      if (i.reload) i.reload = !1;
      else {
        i.isLive = n.hasClass(l, 'vjs-live');
        if (!0 !== i.tooltips)
          for (
            var t = vjs_mfind(l, '.vjs-control-text'), s = 0;
            s < t.length;
            s++
          )
            n.addClass(t[s], 'vjs-hidden');
        var a = [
          vjs_find(l, '.vjs-play-control'),
          vjs_find(l, '.vjs-fullscreen-control'),
          vjs_find(l, '.vjs-mute-control'),
        ];
        for (s = 0; s < a.length; s++)
          a[s].onmouseover = function (e) {
            e.target.removeAttribute('title');
          };
        var r = vjs_mfind(l, '.vjs-menu-button-popup');
        for (s = 0; s < r.length; s++) {
          r[s].onclick = function (e) {
            d(e);
          };
          r[s].ontouchstart = function (e) {
            d(e);
          };
          r[s].onmousemove = function (e) {
            var t =
              vjs_find(e.target, '.vjs-menu') ||
              vjs_find(e.target.parentNode, '.vjs-menu');
            if (t)
              if (n.hasClass(t, 'vjs-lock-showing'));
              else {
                var s =
                  e.target.querySelector('.vjs-control-text') ||
                  e.target.parentNode.querySelector('.vjs-control-text');
                s && s.removeAttribute('style');
              }
          };
          function d(e) {
            for (
              var t = l.querySelectorAll('.vjs-control-text'), s = 0;
              s < t.length;
              s++
            )
              t[s].removeAttribute('style');
            var i = vjs_find(e.target, '.vjs-control-text');
            'true' === e.target.getAttribute('aria-expanded')
              ? (i.style.opacity = '0')
              : i.removeAttribute('style');
            N(e.target);
            var n =
                vjs_find(e.target, '.vjs-menu-content') ||
                vjs_find(e.target.parentNode, '.vjs-menu-content'),
              o = vjs_find(l, '.vjs-control-bar'),
              a = l.offsetHeight - o.offsetHeight - 10;
            n.style.maxHeight = a + 'px';
          }
        }
        le();
        var v = new Array();
        v.length = 0;
        var c = void 0;
        if (e.qualityLevels && e.qualityLevels()) {
          c = e.qualityLevels();
          for (s = 0; s < c.length; s++) {
            if (V(c[s])) {
              var u = c[s],
                f = new Object();
              f.id = u.id;
              f.index = 'vjs-' + s;
              f.height = u.height;
              f.width = u.width;
              f.label = u.label;
              f.bitrate = u.bitrate;
              f.bandwidth = u.bitrate;
              v.push(f);
            }
          }
        }
        1 == v.length && K();
        if (v.length > 1) {
          K();
          ee();
          for (
            var h = 0, j = 0, g = !1, b = v, _ = ((s = 0), b.length);
            s < _;
            s++
          ) {
            b[s].height > 0 && h++;
            b[s].bitrate > 0 && j++;
            for (var y = 0, C = 0; C < b.length; C++)
              b[s].height == b[C].height && y++;
            y > 1 && (g = !0);
          }
          var x = 'bitrate';
          h >= j && (x = 'height');
          b = (b = sortByKey(b, x)).reverse();
          e.hlsLevels = b;
          var w = 'vjs-menu-item item-quality',
            k = 'vjs-menu-item item-quality vjs-checked',
            A = '';
          i.res_num = b.length + 1;
          for (
            new Array(), new Array(), u = new Array(), s = 0;
            s < b.length;
            s++
          ) {
            var T = '',
              I = '',
              M = parseInt(b[s].height);
            if (i.hdicon) {
              var L = 'HD';
              M > 1079 && (L = 'FullHD');
              M > 2159 && (L = '4K');
              M > 719 && (I = '<i class="vjs-hd-icon">' + L + '</i>');
            }
            var P = b[s].bitrate,
              S = b[s].height,
              B = parseInt(P / 1e3);
            if (b[s].height > 0 || b[s].bitrate > 0) {
              var H = w;
              g
                ? (A +=
                    h == j
                      ? '<li data-id="' +
                        b[s].index +
                        '" class="' +
                        H +
                        '" data-bitrate="' +
                        P +
                        '" data-height="' +
                        b[s].height +
                        '"' +
                        T +
                        ' role="menuitem" aria-live="polite" aria-disabled="false">' +
                        S +
                        'p (' +
                        B +
                        ' kbps)</li>'
                      : h > j
                      ? '<li data-id="' +
                        b[s].index +
                        '" class="' +
                        H +
                        '" data-bit="' +
                        P +
                        '" data-height="' +
                        b[s].height +
                        '"' +
                        T +
                        ' role="menuitem" aria-live="polite" aria-disabled="false">' +
                        S +
                        'p' +
                        I +
                        '</li>'
                      : '<li data-id="' +
                        b[s].index +
                        '" class="' +
                        H +
                        '" data-bitrate="' +
                        P +
                        '" data-height="' +
                        b[s].height +
                        '"' +
                        T +
                        ' role="menuitem" aria-live="polite" aria-disabled="false">' +
                        B +
                        ' kbps</li>')
                : b[s].bitrate > 0 && j > h
                ? (A +=
                    '<li data-id="' +
                    b[s].index +
                    '" class="' +
                    H +
                    '" data-bitrate="' +
                    P +
                    '" data-height="' +
                    b[s].height +
                    '"' +
                    T +
                    ' role="menuitem" aria-live="polite" aria-disabled="false">' +
                    S +
                    'p (' +
                    B +
                    ' kbps)</li>')
                : (A +=
                    '<li data-id="' +
                    b[s].index +
                    '" class="' +
                    H +
                    '" data-bitrate="' +
                    P +
                    '" data-height="' +
                    b[s].height +
                    '"' +
                    T +
                    ' role="menuitem" aria-live="polite" aria-disabled="false">' +
                    S +
                    'p' +
                    I +
                    '</li>');
            }
          }
          A +=
            '<li id="auto" class="vjs-menu-item item-quality auto-res" data-height="Autores"' +
            k +
            ' role="menuitem" aria-live="polite" aria-disabled="false">Auto<i class="autores"></i></li>';
          vjs_find(l, '.quality-span').innerHTML = 'Auto';
          if (i.qualityMenu) {
            ee();
            var z = vjs_find(l, '.vjs-menu-quality .vjs-menu-content');
            z.innerHTML = z.innerHTML + A;
            n.removeClass(vjs_find(l, '.vjs-extend-quality'), 'vjs-hidden');
            n.removeClass(D, 'vjs-hidden');
          } else {
            vjs_find(
              l,
              '.vjs-quality-button .vjs-menu .vjs-menu-content',
            ).innerHTML = A;
            n.removeClass(vjs_find(l, '.vjs-quality-button'), 'vjs-hidden');
          }
          var q = vjs_mfind(l, '.item-quality');
          Q();
          var O = e.currentSource(),
            W = e.options(),
            U = W.html5.vhs || W.html5.hls;
          void 0 !== W.html5.hlsjsConfig.startLevel
            ? J(W.html5.hlsjsConfig.startLevel)
            : void 0 !== U && void 0 !== U.startLevel
            ? J(U.startLevel)
            : void 0 !== O.startLevel
            ? J(O.startLevel)
            : Y(!1);
          X();
          c.on('change', function () {
            e.qualityLevels();
            var t = vjs_find(l, '.auto-res');
            if (t) {
              t.className.indexOf('vjs-checked') > -1 && Y();
            }
          });
          for (s = 0; s < q.length; s++) {
            var Z = q[s],
              R = function (t) {
                t.stopPropagation();
                o.sameData = !0;
                e.paused();
                for (var s = 0; s < q.length; s++)
                  n.removeClass(q[s], 'vjs-checked');
                n.removeClass(vjs_find(l, '.auto-res'), 'vjs-checked');
                n.addClass(t.target, 'vjs-checked');
                var i = t.target.getAttribute('id');
                te();
                c && ('auto' == i ? Y(!0) : G(t.target));
              };
            if (Z.className.indexOf('vjs-hidden') < 0) {
              var F = !1;
              Z.ontouchend = function (e) {
                1 != F && R(e);
              };
              Z.ontouchstart = function (e) {
                F = !1;
              };
              Z.ontouchmove = function (e) {
                F = !0;
              };
              Z.onclick = function (e) {
                R(e);
              };
            }
          }
          1 != i.qualityMenu && n.removeClass(E, 'vjs-hidden');
          function Y(t) {
            if (n.hasClass(l, 'vjs-has-started')) {
              !0 !== e.paused() && n.addClass(p, 'vjs-hidden');
              n.addClass(m, 'vjs-hidden');
            }
            if (t) for (var s = 0; s < c.length; s++) c[s].enabled = !0;
            var o = vjs_find(l, '.auto-res');
            o &&
              (o.className = 'vjs-menu-item item-quality auto-res vjs-checked');
            var a = c[c.selectedIndex];
            if (a) {
              var r = '',
                d = 0;
              a.height && (d = parseInt(a.height));
              r = d > 0 ? a.height + 'p' : parseInt(a.bitrate / 1e3) + 'kbps';
              o && (o.innerHTML = 'Auto<i class="autores">' + r + '</i>');
              var v = '';
              if (i.hdicon) {
                d >= 720 && (v = 'HD');
                d > 2159 && (v = '4K');
              }
            }
            vjs_find(l, '.quality-span').innerHTML =
              'Auto<i class="vjs-hd-icon vjs-hd-home">' + v + '</i>';
          }
          function J(t) {
            e.qualityLevels();
            for (s = 0; s < q.length; s++) {
              var i = q[s].getAttribute('data-bitrate'),
                l = q[s].getAttribute('data-height');
              if (i == t || t == l) {
                G(q[s]);
                e.paused() && n.addClass(m, 'vjs-hidden');
                break;
              }
            }
          }
          function G(t) {
            e.currentTime();
            if (n.hasClass(l, 'vjs-has-started')) {
              1 == e.paused() && n.addClass(p, 'vjs-hidden');
              n.addClass(m, 'vjs-hidden');
            }
            vjs_find(l, '.auto-res').innerHTML = 'Auto<i class="autores"></i>';
            var s = parseInt(t.getAttribute('data-height')),
              o = parseInt(t.getAttribute('data-bitrate')),
              a = e.qualityLevels();
            n.addClass(t, 'vjs-checked');
            var r = e.currentSource().video_id || i.video_id;
            e.trigger('resolutionchange', {
              player: l.id,
              id: r,
              resolution: s,
            });
            for (var d = 0; d < a.length; d++)
              a[d].height == s || a[d].bitrate == o
                ? (c[d].enabled = !0)
                : (c[d].enabled = !1);
            var v = '',
              u = vjs_find(l, '.quality-span');
            if (s > 0) {
              s > 719 && (v = '<i class="vjs-hd-icon vjs-hd-home">HD</i>');
              u.innerHTML = s + 'p' + v;
            } else o > 0 && (u.innerHTML = parseInt(o / 1e3) + 'kB');
          }
        }
      }
      function V(t) {
        if (e.tech_.vhs) var s = e.tech_.vhs;
        else if (e.tech_.hls) s = e.tech_.hls;
        if (s && s.representations) {
          var i = s.representations();
          if (i) {
            for (var n = 0; n < i.length; n++)
              if (t.bitrate === i[n].bandwidth) return !0;
            return !1;
          }
        }
        return !0;
      }
    });
    if (i.timetooltip) {
      let e = vjs_find(vjs_find(l, '.vjs-play-progress'), '.vjs-time-tooltip');
      e && (e.className = 'vjs-time-tooltip');
    }
    if (i.mousedisplay) {
      let e = vjs_find(l, '.vjs-mouse-display');
      e && (e.className = 'vjs-mouse-display');
    }
    let ae = vjs_find(l, '.vjs-info');
    ae && l.removeChild(ae);
    let re = vjs_find(l, '.vjs-audio-info');
    re && l.removeChild(re);
    de();
    function de() {
      var e = vjs_find(l, '.vjs-info');
      e && l.removeChild(e);
      if (
        null == i.audioInfo &&
        i.videoInfo &&
        (i.infoText || i.infoTitle || '' !== i.title)
      ) {
        let e = vjs_El('span');
        i.infoBottom
          ? (e.className = 'vjs-info vjs-info-bottom')
          : (e.className = 'vjs-info vjs-info-top');
        let s = i.infoSize,
          n = '',
          o = '';
        (parseInt(i.infoSize) < 15 || parseInt(i.infoSize) > 24) && (s = 18);
        18 !== s && (n = 'font-size:' + s + 'px;');
        i.infoBold && (n += 'font-weight:bold;');
        i.infoFont && (n += 'font-family:' + i.infoFont);
        '' !== n && (e.style = n);
        l.appendChild(e);
        i.infoUrl
          ? (o = '<a href="' + i.infoUrl + '" target="' + i.target + '">')
          : (e.style.pointerEvents = 'none');
        if (i.infoText) var t = i.infoText;
        else if (i.infoTitle) t = i.infoTitle;
        else if ('' !== i.title) t = i.title;
        let a = '';
        '' !== i.infoIcon &&
          (a = '<span class="vjs-icon"><img src="' + i.infoIcon + '"/></span>');
        o += a;
        o += '<span class="vjs-text"><span class="vjs-ttl">' + t + '</span>';
        i.infoDescription &&
          (o += '<span class="vjs-dsc">' + i.infoDescription + '</span>');
        i.infoUrl ? (o += '</span></a></span>') : (o += '</span>');
        e.innerHTML = o;
        e.onclick = function () {
          window.open(i.infoUrl, i.target);
        };
      }
    }
    g();
    e.audioInfo = function () {
      return (
        !(!i.audioInfo || (!i.audioInfo.artist && !i.audioInfo.title)) &&
        i.audioInfo
      );
    };
    let ve = 'MediaSource' in window;
    ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0 && (ve = !1);
    let ce = new Array();
    if ((ce = e.options_.sources).length > 0) {
      let t = '';
      try {
        -1 !== (t = e.options_.sources[0].type).indexOf('dash') &&
          ve &&
          e.src(ce);
      } catch (e) {}
      e.resetNuevo(!1);
      oe();
      le();
    } else {
      e.resetNuevo(!1);
      oe();
      le();
    }
    function ue(e) {
      try {
        e.preventDefault();
        e.stopPropagation();
      } catch (e) {}
      o.tech.style.left = o.tech.offsetWidth / 2 - l.offsetWidth / 2 + 'px';
      o.tech.style.top = o.tech.offsetHeight / 2 - l.offsetHeight / 2 + 'px';
    }
    function fe() {
      he();
      o.currentZoom = 1;
      n.removeClass(p, 'vjs-hidden');
      try {
        vjs_find(l, '.vjs-zoom-level').style.height = '0';
      } catch (e) {}
      let e = vjs_find(l, '.vjs-tech');
      je(e, 1);
      e.style.top = 0;
      e.style.left = 0;
      Y(100);
      let t = vjs_find(l, '.vjs-zoom-parent');
      !0 !== n.hasClass(t, 'vjs-hidden') && n.addClass(t, 'vjs-hidden');
      videojs.options.blockKeys = !1;
      return !1;
    }
    function he() {
      let e = ((o.currentZoom - 1) / 4) * 100,
        t = vjs_find(l, '.zoom-thumb');
      t && (t.style.height = e + '%');
    }
    function je(e, t) {
      he();
      e.style.transform = 'scale(' + t + ')';
      e.style.webkitTransform = 'scale(' + t + ')';
      e.style.msTransform = 'scale(' + t + ')';
      n.removeClass(vjs_find(l, '.vjs-poster'), 'vjs-hidden');
    }
    function pe(e) {
      let t = e.offsetTop;
      for (; (e = e.offsetParent); ) t += e.offsetTop;
      return t;
    }
    function me() {
      document.body.style.MozUserSelect = 'none';
      document.body.style.webkitUserSelect = 'none';
      document.body.focus();
      document.onselectstart = function () {
        return !1;
      };
    }
    function ge() {
      document.body.style.MozUserSelect = 'text';
      document.body.style.webkitUserSelect = 'text';
      document.onselectstart = function () {
        return !0;
      };
    }
    e.ready(function () {
      e.trigger('nuevoReady');
      i.isDisposed = !1;
      e.on('dispose', function () {
        i.isDisposed = !0;
      });
      function t(e) {
        N(e.target);
      }
      isMobile() &&
        (o.tech.ontouchstart = function (e) {
          t(e);
        });
      document.onclick = function (e) {
        t(e);
      };
      let s = this.textTrackSettings,
        a = 1.25;
      parseInt(i.captionsSize) > 0.5 && (a = i.captionsSize);
      s.setValues({
        backgroundColor: 'rgba(0,0,0,0)',
        backgroundOpacity: '0',
        edgeStyle: 'raised',
        fontPercent: a,
      });
      s.updateDisplay();
      vjs_find(l, '.vjs-text-track-display').style.zIndex = '1';
      let r = vjs_find(l, '.vjs-progress-holder');
      vjs_find(l, '.vjs-play-progress');
      function d(e) {
        if (i.isLive);
        else {
          e.preventDefault();
          n.addClass(p, 'vjs-hidden');
        }
      }
      r.addEventListener('mousedown', d, !1);
      r.addEventListener('touchstart', d, { passive: !0 });
      e.on('timeupdate', function () {
        if (n.hasClass(l, 'vjs-ad-playing') || n.hasClass(l, 'vjs-dai'))
          return !1;
        var t = e.currentSource().video_id || i.video_id || void 0;
        if (i.resume && void 0 !== t) {
          let s = e.currentTime();
          (s = s.toFixed(2)) > 0 &&
            localStorage.setItem('vjs_resume', t + ',' + s);
        }
      });
      e.on('volumechange', function () {
        e.muted() || localStorage.setItem('volume', e.volume());
      });
      e.on('ended', function () {
        if (n.hasClass(l, 'vjs-ad-playing') || n.hasClass(l, 'vjs-dai'))
          return !1;
        let t = !0;
        n.addClass(p, 'vjs-hidden');
        if (i.playlist) {
          t = !1;
          e.playlist.currentIndex() === e.playlist.lastIndex() &&
            !0 !== e.playlist.repeat &&
            (t = !0);
        }
        if (t) {
          if (i.resume) {
            void 0 !== (e.currentSource().video_id || i.video_id || void 0) &&
              localStorage.setItem('vjs_resume', i.vid + ',0');
          }
          if ('' !== i.endAction)
            i.settingsButton && 'share' === i.endAction && i.shareMenu
              ? e.share()
              : i.settingsButton &&
                'related' === i.endAction &&
                i.relatedMenu &&
                i.related.length > 1 &&
                e.related();
          else {
            n.removeClass(p, 'vjs-abs-hidden');
            n.removeClass(p, 'vjs-hidden');
            p.removeAttribute('style');
          }
        }
      });
      e.on('playing', function () {
        isMobile() && n.addClass(p, 'vjs-abs-hidden');
        if (n.hasClass(l, 'vjs-ad-playing') || n.hasClass(l, 'vjs-dai'))
          return !1;
        try {
          n.removeClass(l, 'vjs-def');
        } catch (e) {}
        o.rel_win && n.addClass(o.rel_win, 'vjs-hidden');
        o.share_win && n.addClass(o.share_win, 'vjs-hidden');
        n.removeClass(p, 'vjs-hidden');
        n.removeClass(p, 'vjs-abs-hidden');
        n.removeClass(m, 'vjs-hidden');
        n.removeClass(m, 'vjs-abs-hidden');
        (e.remoteTextTracks ? e.remoteTextTracks() : []).length < 1 &&
          videojs.browser.IS_IOS &&
          n.addClass(vjs_find(l, '.vjs-subs-caps-button'), 'vjs-hidden');
      });
    });
    e.on('pause', function (e) {
      n.removeClass(p, 'vjs-no-pointer');
      let t = vjs_find(l, '.vjs-tech-chromecast');
      videojs.browser.IS_ANDROID || videojs.browser.IS_IOS
        ? n.addClass(p, 'vjs-abs-hidden')
        : t
        ? n.addClass(p, 'vjs-abs-hidden')
        : n.removeClass(p, 'vjs-abs-hidden');
    });
    e.on('userinactive', function () {
      !0 !== e.paused() && N('');
    });
    e.on('contentupdate', function () {
      let e = o.tech;
      e.style.top = '0';
      e.style.left = '0';
      e.style.transform = 'scale(1)';
      e.style.webkitTransform = 'scale(1)';
      e.style.msTransform = 'scale(1)';
      o.currentZoom = 1;
    });
    function be() {
      if (videojs.ima || videojs.vroll || e.vastAds) return !1;
      if (e.isPreroll || e.resumed) return !1;
      var t = e.currentSource().video_id || i.video_id || void 0;
      e.resumed = !0;
      if (
        i.resume &&
        void 0 !== t &&
        localStorage &&
        localStorage.getItem('vjs_resume')
      ) {
        let t = localStorage.getItem('vjs_resume');
        if (2 === (t = t.split(',')).length) {
          let s = t[0];
          if (s === i.video_id && Number(t[1]) > 3) {
            let i = Number(t[1]);
            setTimeout(function () {
              localStorage.setItem('vjs_resume', s + ',0');
              e.currentTime(i);
            }, 500);
          }
        }
      }
    }
    e.on('canplaythrough', function () {
      if (videojs.ima) return !1;
      videojs.browser.IS_IOS && be();
    });
    e.on('play', function () {
      if (o.singlePlay) {
        let t = vjs_mfind(document, 'video');
        if (t.length > 1)
          for (let s = 0; s < t.length; s++) {
            let i = e.$('.vjs-tech');
            t[s] !== i && t[s].pause();
          }
      }
      n.addClass(p, 'vjs-no-pointer');
      if (o.firstplay) return;
      o.firstplay = !0;
      O();
      let t = vjs_find(l, '.vjs-info');
      if (t) {
        n.removeClass(t, 'vjs-info-bottom');
        n.addClass(t, 'vjs-info-top');
      }
      !0 !== i.is_audio &&
        n.addClass(vjs_find(l, '.vjs-poster'), 'vjs-no-pointer');
      !0 !== videojs.browser.IS_IOS && be();
      try {
        vjs_find(l, 'video').controls = !1;
      } catch (e) {}
      if (i.limit > 0 && (i.limiturl || '' !== i.url)) {
        let t = !1;
        e.on('timeupdate', function () {
          if (e.currentTime() > i.limit) {
            e.pause();
            p.style.display = 'none';
            if (!0 !== t) {
              i.limiturl || (i.limiturl = i.url);
              t = !0;
              let n = vjs_El('div', 'vjs-limit-overlay'),
                l = vjs_El('a', 'vjs-limit');
              l.href = i.limiturl;
              l.target = i.target;
              l.style.textDecoration = 'none';
              n.appendChild(l);
              function s() {
                let e = vjs_El('span');
                e.innerHTML =
                  i.limitmessage + '<span>' + i.limiturl + '</span>';
                l.appendChild(e);
              }
              if (i.limitimage) {
                let e = vjs_El('img');
                e.src = i.limitimage;
                e.onload = function () {
                  l.innerHTML = '<img src="' + i.limitimage + '" />';
                };
                e.onerror = function () {
                  s();
                };
              } else s();
              e.el().appendChild(n);
            }
          }
        });
      }
      localStorage &&
        localStorage.getItem('volume') &&
        !0 !== e.muted() &&
        e.volume(localStorage.getItem('volume'));
      let s = !1;
      i.settingsButton &&
        (i.relatedMenu || i.shareMenu || i.rateMenu || i.zoomMenu) &&
        (s = !0);
      !0 !== s &&
        i.res_num > 1 &&
        i.qualityMenu &&
        n.removeClass(D, 'vjs-hidden');
      if (s) {
        let t = '',
          s = !1,
          g = !1;
        i.shareMenu &&
          (t +=
            '<li class="vjs-settings-item vjs-share-button">' +
            e.localize('Share') +
            '<span class="vjs-share-icon"></span></li>');
        i.relatedMenu &&
          i.related.length > 1 &&
          (t +=
            '<li class="vjs-settings-item vjs-related-button">' +
            e.localize('Related') +
            '<span class="vjs-related-icon"></span></li>');
        if (i.zoomMenu) {
          t +=
            '<li class="vjs-settings-item vjs-extend-zoom vjs-menu-forward">' +
            e.localize('Zoom') +
            '<span class="zoom-label">100%</span></li>';
          g = vjs_El(
            'div',
            'vjs-submenu vjs-zoom-menu vjs-hidden',
            '<div class="vjs-settings-back vjs-zoom-return"><span>' +
              e.localize('Zoom') +
              '</span></div><div class="vjs-zoom-slider"><div class="vjs-zoom-back"></div><div class="vjs-zoom-level"></div></div><div class="vjs-zoom-reset">RESET</div>',
          );
        }
        if (i.rateMenu) {
          t +=
            '<li class="vjs-settings-item vjs-extend-speed vjs-menu-forward">' +
            e.localize('Speed') +
            '<span>Normal</span></li>';
          s = vjs_El(
            'div',
            'vjs-submenu vjs-menu-speed vjs-hidden',
            '<ul class="vjs-menu-content"><li class="vjs-settings-back">' +
              e.localize('Speed') +
              '</li><li class="vjs-speed">0.25x</li><li class="vjs-speed">0.5x</li><li class="vjs-speed vjs-checked">1x</li><li class="vjs-speed">1.25x</li><li class="vjs-speed">1.5x</li><li class="vjs-speed">2x</li></ul>',
          );
        }
        let b = vjs_find(l, '.vjs-settings-list');
        if ('' !== t) {
          b.innerHTML = t + b.innerHTML;
          s && vjs_find(l, '.vjs-settings-div').appendChild(s);
          g && vjs_find(l, '.vjs-settings-div').appendChild(g);
          n.removeClass(o.settingControl, 'vjs-hidden');
          Q();
          X();
          let i = !1;
          function a(t) {
            t.stopPropagation();
            let s = t.target.innerHTML;
            s = s.replace('x', '');
            e.setRate(s);
          }
          s.ontouchmove = function (e) {
            i = !0;
          };
          let r = l.querySelectorAll('.vjs-speed');
          for (let e = 0; e < r.length; e++) {
            r[e].onclick = function (e) {
              a(e);
            };
            r[e].ontouchend = function (e) {
              i ? (i = !1) : a(e);
            };
          }
        }
        if (i.related.length > 1 && i.relatedMenu) {
          o.relnum = i.related.length;
          o.rel_win = vjs_El('div');
          let t = isMobile(),
            s = '<div class="vjs-close-btn"></div>';
          !0 !== t &&
            (s +=
              '<div class="vjs-arrow vjs-arrow-prev vjs-disabled"><div class="vjs-prev"></div></div><div class="vjs-arrow vjs-arrow-next"><div class="vjs-next"></div></div>');
          o.rel_win.innerHTML = s;
          o.rel_win.className = 'vjs-grid vjs-hidden';
          let a = vjs_El('p');
          a.innerHTML = e.localize('Related');
          let r = vjs_El('div', 'vjs-related');
          t && (r.className = 'vjs-related vjs-scroll');
          let d = l.offsetWidth,
            v = 0.8 * d;
          t && (v = 0.9 * d);
          o.rel_win.appendChild(a);
          o.rel_win.appendChild(r);
          l.appendChild(o.rel_win);
          let c = vjs_find(o.rel_win, '.vjs-arrow-prev'),
            u = vjs_find(o.rel_win, '.vjs-arrow-next');
          if (!0 !== t) {
            let e = parseInt(vjs_find(l, '.vjs-prev').offsetWidth) + 5;
            c && (c.style.left = parseInt(r.style.left) - e + 'px');
            u && (u.style.left = v + parseInt(r.style.left) + 'px');
          }
          let f = vjs_El('div', 'rel-block rel-anim');
          r.appendChild(f);
          let h = i.related;
          o.vjs_relpage = 1;
          for (let e = 0; e < o.relnum; e++) {
            let t = vjs_El('div', 'rel-parent'),
              s = vjs_El('div', 'rel-item');
            t.appendChild(s);
            f.appendChild(t);
            s.innerHTML =
              '<a class="rel-url" target="' +
              i.target +
              '" href="' +
              h[e].url +
              '" alt="' +
              h[e].title +
              '"><span class="rel-bg" style="background-image:url(' +
              h[e].thumb +
              ');"></span><label>' +
              h[e].title +
              '</label><i>' +
              h[e].duration +
              '</i></a>';
          }
          if (o.relnum < 7 && !0 !== t) {
            c && n.addClass(c, 'vjs-hidden');
            u && n.addClass(u, 'vjs-hidden');
          }
          !0 !== t &&
            u &&
            (u.onclick = function (e) {
              e.stopPropagation();
              if (!n.hasClass(u, 'vjs-disabled')) {
                let e = r.offsetWidth;
                o.vjs_relpage++;
                n.removeClass(u, 'vjs-disabled');
                let t = (o.vjs_relpage - 1) * e;
                f.style.left = '-' + t + 'px';
                o.vjs_relpage === o.vjs_relpages &&
                  n.addClass(u, 'vjs-disabled');
                n.removeClass(c, 'vjs-disabled');
              }
            });
          !0 !== t &&
            c &&
            (c.onclick = function (e) {
              e.stopPropagation();
              if (!n.hasClass(c, 'vjs-disabled')) {
                let e = vjs_find(l, '.vjs-related').offsetWidth;
                o.vjs_relpage = o.vjs_relpage - 1;
                let s = (o.vjs_relpage - 1) * e;
                vjs_find(l, '.rel-block').style.left = '-' + s + 'px';
                if (!0 !== t) {
                  1 === o.vjs_relpage && n.addClass(c, 'vjs-disabled');
                  n.removeClass(u, 'vjs-disabled');
                }
              }
            });
          let j = vjs_find(l, '.vjs-related-button');
          j.onclick = function (t) {
            e.related();
          };
          isMobile() &&
            (j.ontouchstart = function (t) {
              e.related();
            });
          o.rel_win.onclick = function (t) {
            n.addClass(o.rel_win, 'vjs-hidden');
            n.addClass(p, 'vjs-hidden');
            e.play();
          };
        }
        if (i.shareMenu) {
          o.share_win = vjs_El('div', 'vjs-sharing-overlay vjs-hidden');
          let t = vjs_El('div', 'vjs-sharing-container'),
            s = vjs_El('div', 'vjs-sharing-body'),
            a = vjs_El('div', 'vjs-close-btn vjs-share-close'),
            c =
              '<div class="vjs-inputs-body"><h2>' +
              e.localize('Link') +
              '</h2><input type="text" readonly class="perma"><h2>' +
              e.localize('Embed') +
              '</h2><input class="embed-code" readonly type="text"></div>';
          c =
            c +
            '<div class="vjs-inputs-body"><h2>' +
            e.localize('Social') +
            '</h2></div>';
          c +=
            '<div class="vjs-share-block"><i title="Facebook" id="share_facebook" class="vjs-share-icon nv vjs-facebook-square" role="button" aria-live="polite" tabindex="0"></i>';
          c +=
            '<i title="Twitter" id="share_twitter" class="vjs-share-icon nv vjs-twitter-square" role="button" aria-live="polite" tabindex="0"></i>';
          c +=
            '<i title="Pinterest" id="share_pinterest" class="vjs-share-icon nv vjs-pinterest-square" role="button" aria-live="polite" tabindex="0"></i>';
          c +=
            '<i title="LinkedIn" id="share_linkedin" class="vjs-share-icon nv vjs-linkedin-square" role="button" aria-live="polite" tabindex="0"></i></div>';
          s.innerHTML = c;
          t.appendChild(s);
          o.share_win.appendChild(a);
          o.share_win.appendChild(t);
          let u = i.url || document.location.href;
          e.el().appendChild(o.share_win);
          var r = vjs_find(l, '.vjs-share-button');
          r.onclick = function (t) {
            e.share();
          };
          isMobile() &&
            (r.ontouchstart = function (t) {
              e.share();
            });
          var d = new Array();
          let f = function (e) {
            let t = [];
            for (let s in e)
              t.push(encodeURIComponent(s) + '=' + encodeURIComponent(e[s]));
            return t.join('&');
          };
          function v(t) {
            t.stopPropagation();
            d = {
              url: u,
              title: i.metatitle || i.title || document.title,
              description:
                e.localize('Check out this cool video on') + ' ' + i.url,
              pubid: i.pubid || null,
            };
            var s = '';
            switch (t.target.id.split('_')[1]) {
              case 'facebook':
                s = 'facebook';
                break;
              case 'twitter':
                s = 'twitter';
                break;
              case 'pinterest':
                s = 'pinterest';
                break;
              case 'linkedin':
                s = 'linkedin';
            }
            window.open(
              'http://api.addthis.com/oexchange/0.8/forward/' +
                s +
                '/offer?' +
                f(d),
              'AddThis',
              'height=450,width=550,modal=yes,alwaysRaised=yes',
            );
          }
          let h = l.querySelectorAll('.vjs-share-icon');
          for (let e = 0; e < h.length; e++)
            h[e].addEventListener('click', v, !1);
          vjs_find(o.share_win, '.embed-code').onclick = function (e) {
            e.stopImmediatePropagation();
            this.select();
          };
          vjs_find(o.share_win, '.perma').onclick = function (e) {
            e.stopImmediatePropagation();
            this.select();
          };
          o.share_win.onclick = function (t) {
            n.addClass(o.share_win, 'vjs-hidden');
            n.removeClass(p, 'vjs-hidden');
            e.play();
          };
        }
        if (i.zoomMenu) {
          let t,
            s,
            a,
            r = vjs_find(l, '.vjs-poster');
          var c;
          if (i.zoomInfo) {
            c = vjs_El('div', 'vjs-zoom-parent vjs-hidden');
            let t = vjs_El('div', 'vjs-reset-zoom');
            t.innerHTML = '100%';
            c.appendChild(t);
            let s = vjs_El('div', 'vjs-reset-center'),
              o = vjs_El('div', 'vjs-reset-cancel'),
              a = vjs_El('div', 'vjs-reset-info');
            c.appendChild(s);
            c.appendChild(o);
            c.appendChild(a);
            let r = vjs_El('div', 'vjs-zoom-help vjs-hidden');
            i.zoomWheel
              ? (r.innerHTML =
                  '<div class="zoom-close">x</div><div>' +
                  e.localize('ZOOM HELP') +
                  '</div>' +
                  e.localize(
                    'Use ZOOM slider or mouse wheel to ZOOM in video.',
                  ) +
                  '<div>' +
                  e.localize('Drag zoomed area using your mouse or a finger.') +
                  '</div>')
              : (r.innerHTML =
                  '<div class="zoom-close">x</div><div>' +
                  e.localize('ZOOM HELP') +
                  '</div>' +
                  e.localize('Drag zoomed area using your mouse or a finger.') +
                  '</div>');
            c.appendChild(a);
            a.onclick = function (e) {
              e.preventDefault();
              e.stopPropagation();
              if (n.hasClass(r, 'vjs-hidden')) {
                n.removeClass(r, 'vjs-hidden');
                n.addClass(p, 'vjs-hidden');
              }
            };
            vjs_find(r, '.zoom-close').onclick = function () {
              n.addClass(r, 'vjs-hidden');
              n.addClass(r, 'vjs-hidden');
              n.removeClass(p, 'vjs-hidden');
            };
            l.appendChild(r);
            o.onmouseup = function () {
              fe();
            };
            o.ontoucend = function () {
              fe();
            };
            s.onmouseup = function () {
              ue();
            };
            s.ontouchend = function () {
              ue();
            };
            l.appendChild(c);
          }
          let d = vjs_find(l, '.vjs-zoom-reset');
          d && d.addEventListener('mouseup', fe, !1);
          let v = e.el();
          v.hasAttribute('tabIndex') || v.setAttribute('tabIndex', '-1');
          if (i.zoomWheel) {
            r.style.pointerEvents = 'auto';
            r.addEventListener('mousewheel', u, { passive: !1 });
            r.addEventListener('DOMMouseScroll', u, { passive: !1 });
            function u(e) {
              e.preventDefault();
              e.stopPropagation();
              let t = 20 * Math.max(-1, Math.min(1, e.wheelDelta || -e.detail)),
                s = 100 * o.currentZoom + t;
              o.currentZoom = s / 100;
              o.currentZoom < 1 && (o.currentZoom = 1);
              o.currentZoom > 5 && (o.currentZoom = 5);
              if (1 === o.currentZoom) {
                fe();
                i.zoomInfo && n.addClass(c, 'vjs-hidden');
                n.removeClass(p, 'vjs-hidden');
              } else {
                i.zoomInfo && n.removeClass(c, 'vjs-hidden');
                je(o.tech, o.currentZoom);
                n.addClass(p, 'vjs-hidden');
              }
              let a = vjs_find(l, '.vjs-zoom-menu');
              if (!0 !== n.hasClass(a, 'vjs-hidden')) {
                let e = ((o.currentZoom - 1) / 4) * 100;
                vjs_find(l, '.vjs-zoom-level').style.height = e + '%';
                Y(100 * o.currentZoom);
              }
              return !1;
            }
          }
          function f(s) {
            if (o.currentZoom > 1) {
              s.preventDefault();
              s.stopPropagation();
              t = !0;
              var i = e.$('.vjs-tech');
              try {
                a = [i.offsetLeft - s.clientX, i.offsetTop - s.clientY];
              } catch (s) {}
              try {
                a = [
                  i.offsetLeft - s.touches[0].clientX,
                  i.offsetTop - s.touches[0].clientY,
                ];
              } catch (s) {}
              r.style.pointerEvents = 'none';
              document.addEventListener('mouseup', j, !0);
              document.addEventListener('mousemove', h, !0);
            }
          }
          function h(i) {
            i.preventDefault();
            if (t) {
              try {
                s = { x: i.clientX, y: i.clientY };
              } catch (i) {}
              var n = e.$('.vjs-tech');
              let t = s.x + a[0],
                r = s.y + a[1],
                d = (l.offsetWidth / 2) * (o.currentZoom - 1),
                v = (l.offsetHeight / 2) * (o.currentZoom - 1);
              t > d && (t = d);
              t < -1 * d && (t = -1 * d);
              r > v && (r = v);
              r < -1 * v && (r = -1 * v);
              n.style.left = t + 'px';
              n.style.top = r + 'px';
            }
          }
          function j(e) {
            r.style.pointerEvents = 'auto';
            t = !1;
            document.removeEventListener('mouseup', j, !0);
            document.removeEventListener('mousemove', h, !0);
            e.preventDefault();
            e.stopPropagation();
          }
          r.onmousedown = function (e) {
            f(e);
          };
          function m(t, s) {
            r.style.pointerEvents = 'auto';
            let i = !1;
            try {
              i = t.pageY;
            } catch (t) {}
            if (!1 === i) return;
            let a = s.offsetHeight,
              d = i - pe(s);
            d > a && (d = a);
            d < 0 && (d = 0);
            let v = parseInt(100 - (d / a) * 100);
            v < 0 && (v = 0);
            v > 100 && (v = 100);
            try {
              vjs_find(l, '.vjs-zoom-level').style.height = v + '%';
            } catch (t) {}
            try {
              vjs_find(l, '.zoom-thumb').style.height = v + '%';
            } catch (t) {}
            let c = 1 + (4 * v) / 100;
            o.currentZoom = c;
            je(e.$('.vjs-tech'), c);
            Y(100 * c);
            if (c > 1) {
              videojs.options.blockKeys = !0;
              n.removeClass(vjs_find(l, '.vjs-zoom-parent'), 'vjs-hidden');
              n.addClass(p, 'vjs-hidden');
            } else {
              fe();
              videojs.options.blockKeys = !1;
              n.addClass(vjs_find(l, '.vjs-zoom-parent'), 'vjs-hidden');
              n.removeClass(p, 'vjs-hidden');
            }
          }
          vjs_find(l, '.vjs-zoom-slider').onclick = function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
          };
          vjs_find(l, '.vjs-zoom-slider').addEventListener(
            setEvent('mousedown'),
            function (e) {
              e.preventDefault();
              e.stopImmediatePropagation();
              me();
              var t = vjs_find(l, '.vjs-zoom-slider');
              m(e, t);
              function s(e) {
                e.preventDefault();
                e.stopPropagation();
                m(e, t);
              }
              function i(e) {
                e.preventDefault();
                e.stopPropagation();
                ge();
                document.removeEventListener(setEvent('mouseup'), i);
                document.removeEventListener(setEvent('mousemove'), s);
              }
              document.addEventListener(setEvent('mousemove'), s, !1);
              document.addEventListener(setEvent('mouseup'), i, !1);
            },
            !1,
          );
        }
      }
    });
    l.style.visibility = 'visible';
  }
}
videojs.registerPlugin('nuevo', Nuevo);
export default Nuevo;
