import { formatRelative } from 'date-fns';
import format from 'date-fns/format';
import sk from 'date-fns/locale/sk';

export default (date: number | Date, formatStr: string) => {
  return format(date, formatStr, {
    locale: sk,
  });
};

export const formatRelativeDate = (date: Date, baseDate: Date = new Date()) => {
  if (!(date instanceof Date)) {
    return date;
  }
  return formatRelative(date, baseDate, {
    locale: sk,
  });
};
