import * as React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line
import { RouteComponentProps } from 'react-router';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManager from '../../components/Ads/AdManager';
import BottomPaddedBigAd from '../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import SideAd from '../../components/Ads/SideAd';
import TopAd from '../../components/Ads/TopAd';
import TopPaddedBodyAd from '../../components/Ads/TopPaddedBodyAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/Table';
import config from '../../config';
import { RootState } from '../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../containers/RempIntegration';
import formatDate from '../../utilities/formatDate';
import getResolutionDetailUrl from '../../utilities/futbalnet/committee/getResolutionDetailUrl';
import getCommiteeBaseUrl from '../../utilities/getCommiteeBaseUrl';
import getResolutionStatus from '../../utilities/getResolutionStatus';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import getUnionByUrl from '../../utilities/getUnionByUrl';
import __ from '../../utilities/__';
import { currentOrganizationProfileSelector } from '../Competition/selectors';
import { loadResolutionDetail } from './actions';
import { resolutionDetailSelector } from './selectors';
import {
  H2,
  H3,
  ResolutionDetailBackLink,
  ResolutionDetailText,
  ResolutionDetailTitle,
  ResolutionDetailTitleWrapper,
  ResolutionNumber,
} from './styles';

type RouterProps = RouteComponentProps<
  {
    comiteeId: string;
    zvaz: string;
    resolutionNumber: string;
    seasonId: string;
  },
  void
>;

const mapStateToProps = (state: RootState, props: RouterProps) => {
  const { resolutionNumber } = props.params;
  return {
    resolutionDetail: resolutionDetailSelector(
      resolutionNumber.replace('|', '/'),
    )(state),
    organizationProfile: currentOrganizationProfileSelector(state),
  };
};

const mapDispatchToProps = {
  loadResolutionDetail: thunkToAction(loadResolutionDetail.action),
};

type IProps = RouterProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const ResolutionDetail: React.FC<IProps> = ({
  loadResolutionDetail,
  params,
  location: { search },
  resolutionDetail,
  organizationProfile,
}) => {
  // parameters from url
  const { zvaz, comiteeId, seasonId, resolutionNumber } = params;

  const title = resolutionDetail?.number
    ? `${__('Rozhodnutie č.')} ${resolutionDetail?.number}`
    : '';

  const unionId = React.useMemo(() => {
    // `union` object from `shortnames.json`
    const union = getUnionByUrl(zvaz);
    return union?.__issfId.toString();
  }, [zvaz]);

  useAsyncData(async () => {
    if (unionId) {
      try {
        await loadResolutionDetail({
          unionId,
          comiteeId,
          seasonId,
          resolutionNumber,
        });
      } catch (e: any) {
        /* console.error(e); */
      }
    }
  }, [unionId, comiteeId, seasonId, resolutionNumber]);

  const getComiteeListUrl = () => {
    return `/futbalnet/z/${zvaz}/komisia/${comiteeId}/?seasonId=${seasonId}`;
  };

  const breadcrumbUnionName =
    organizationProfile?.shortName || organizationProfile?.name || '';

  const renderDetail = () => (
    <>
      <ResolutionDetailTitleWrapper>
        <H2>{resolutionDetail?.commision?.name}</H2>
        <ResolutionDetailBackLink to={getComiteeListUrl()}>
          {__('Späť na detail komisie')}
        </ResolutionDetailBackLink>
      </ResolutionDetailTitleWrapper>
      <ResolutionNumber>{title}</ResolutionNumber>
      <ResolutionDetailTitle>{resolutionDetail?.title}</ResolutionDetailTitle>
      <ResolutionDetailText>{resolutionDetail?.text}</ResolutionDetailText>

      <table>
        <tbody>
          <tr>
            <td>{__('Rozhodnutie')}:</td>
            <td>{resolutionDetail?.number}</td>
          </tr>
          <tr>
            <td>{__('Dátum zaevidovania')}:</td>
            <td>
              {resolutionDetail?.created_date
                ? formatDate(
                    new Date(resolutionDetail.created_date),
                    config.DATETIME_FORMAT_MINUTES_ONLY,
                  )
                : ''}
            </td>
          </tr>
          <tr>
            <td>{__('Stav')}:</td>
            <td>{getResolutionStatus(resolutionDetail?.status || '')}</td>
          </tr>
        </tbody>
      </table>

      {resolutionDetail?.fees && resolutionDetail?.fees.length > 0 && (
        <>
          <H3>{__('Poplatky')}</H3>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Typ')}</Th>
                <Th>{__('Osoba')}</Th>
                <Th>{__('Dôvod')}</Th>
                <Th>{__('Suma')}</Th>
                <Th>{__('Dátum')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {resolutionDetail?.fees.map((fee) => (
                <Tr key={fee.datetime}>
                  <Td>{fee.fee.type ? `${fee.fee.type}` : '-'}</Td>
                  <Td>
                    {fee.person
                      ? `${fee.person.name} ${fee.person.surname}`
                      : ''}
                  </Td>
                  <Td>{fee.reason}</Td>
                  <Td>
                    {fee.fee.amount} {fee.fee.currency}
                  </Td>
                  <Td>
                    {formatDate(
                      new Date(fee.datetime),
                      config.DATETIME_FORMAT_MINUTES_ONLY,
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      {resolutionDetail?.penalties && resolutionDetail?.penalties.length > 0 && (
        <>
          <H3>{__('Pokuty')}</H3>
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Popis')}</Th>
                <Th>{__('Suma')}</Th>
                <Th>{__('Dátum')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {resolutionDetail?.penalties.map((penalty) => (
                <Tr
                  key={formatDate(
                    new Date(penalty.datetime),
                    config.DATETIME_FORMAT_MINUTES_ONLY,
                  )}
                >
                  <Td>{penalty.description}</Td>
                  <Td>
                    {penalty.penalty.amount}&nbsp;{penalty.penalty.currency}
                  </Td>
                  <Td>
                    {formatDate(
                      new Date(penalty.datetime),
                      config.DATETIME_FORMAT_MINUTES_ONLY,
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      {resolutionDetail?.restrictions &&
        resolutionDetail?.restrictions.length > 0 && (
          <>
            <H3>{__('Sankcie')}</H3>
            <Table>
              <Thead>
                <Tr>
                  <Th>{__('Popis')}</Th>
                  <Th>{__('Dátum\u00a0od')}</Th>
                  <Th>{__('Zápasy')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {resolutionDetail?.restrictions.map((sanction, idx) => (
                  <Tr key={`sanction-${idx}`}>
                    <Td>{sanction.description}</Td>
                    <Td>
                      {formatDate(
                        new Date(sanction.validity.from),
                        config.DATE_FORMAT,
                      )}
                    </Td>
                    <Td>{sanction.validity.matches}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </>
        )}

      <TopPaddedBodyAd name="body_1" />
    </>
  );

  return (
    <>
      <RempIntegration destroy />
      <AdManager
        site="futbalnet_zvaz"
        siteId={`${zvaz}-komisia-${comiteeId}-rozhodnutie-${resolutionNumber}-season-${seasonId}`}
      />
      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>{renderDetail()}</SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      {breadcrumbUnionName && resolutionDetail?.commision?.name && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: breadcrumbUnionName,
              url: getUnionBaseUrl({ zvaz }),
            },
            {
              label: resolutionDetail?.commision?.name,
              url: getCommiteeBaseUrl({
                zvaz,
                comiteeId: String(resolutionDetail?.commision?.id),
              }),
            },
            {
              label: title,
              url: getResolutionDetailUrl({
                seasonId: seasonId,
                resolutionNumber: resolutionDetail.number,
                zvaz,
                comiteeId,
              }),
            },
          ]}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResolutionDetail);
