import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import ItemsDropDown from '../../../components/DropDowns/ItemsDropDown';
import Icon from '../../../components/Icon';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
} from '../../../components/Layout/SidebarLayout';
import Spacer from '../../../components/Spacer';
import { Table, Tbody, Td, Tr } from '../../../components/Table';
import { RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import { RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import getMemberDetailUrl from '../../../utilities/getMemberDetailUrl';
import { currentMemberSelector } from '../selectors';

const TableWrapper = styled.div`
  margin: 0 -${rem(16)};
  width: calc(100% + ${rem(32)});
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
  td:nth-child(1) {
    font-weight: bold;
  }
  td:nth-child(2) {
    width: ${rem(100)};
  }
`;

const Label = styled.div`
  color: #aaaaaa;
  font-size: ${rem(12)};
`;

const Filters = styled.div<{ fullWidth: boolean }>`
  margin: -${rem(16)} -${rem(16)} ${rem(24)} -${rem(16)};
  display: flex;
  flex-wrap: wrap;
  ${mb('m')} {
    flex-wrap: nowrap;
    margin: 0 0 ${rem(32)} 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
    padding-left: ${rem(16)};
    ${Label} {
      display: none;
      ${mb('m')} {
        display: block;
      }
    }
    ${mb('m')} {
      width: auto;
      border: 0;
      padding-left: ${rem(56)};
    }
  }
  > div:first-child {
    width: 100%;
    border: 0 !important;
    padding-bottom: ${rem(8)};
    ${Label} {
      display: block !important;
    }
    button {
      font-size: ${rem(18)} !important;
    }
    ${mb('m')} {
      width: auto;
      padding: 0;
    }
  }
  > div:last-child {
    ${mb('m')} {
      margin-left: auto;
    }
  }
  align-items: center;
  button {
    width: 100%;
    cursor: pointer;
    padding: ${rem(8)} 0;
    font-size: ${rem(12)};
    display: flex;
    justify-content: space-between;
    ${mb('m')} {
      border-top: 0;
      border-bottom: 0;
      font-size: ${rem(18)};
      justify-content: flex-start;
    }
    display: flex;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    font-weight: normal;
    font-family: ${({ theme }) => theme.primaryFont.family };
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const MoreIcon = styled(Icon)`
  margin: 0 ${rem(16)} 0 ${rem(16)};
`;

const STAT_NAMES: { [key: string]: string } = {
  minutes: __('Odohrané minúty'),
  goals: __('Strelené góly'),
  yellow_cards: __('Žlté karty'),
  red_cards: __('Červené karty'),
};

const mapStateToProps = (state: RootState) => ({
  member: currentMemberSelector(state),
});

const QUERY_HOC_CONFIG = {
  parameters: {
    seasonName: StringParam('summary'),
  },
};

type IOwnProps = {};

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ id: string }>;

const PlayerStatsDetail: React.FC<Props> = ({
  member,
  params: { id },
  location: { search, pathname, hash },
  router,
}) => {
  const [seasonFilterOpened, setSeasonFilterOpened] = React.useState(false);

  // query parameter for selected season
  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG,
  );

  const selectedSeasonName = React.useMemo(() => {
    return decodeURIComponent(query.seasonName);
  }, [query.seasonName]);

  const renderPlayerStats = React.useMemo(() => {
    if (member && member.stats) {
      const stats = (member.stats || []).find(
        (i) => i.seasonName === selectedSeasonName,
      );
      if (Object.keys(stats?.stats || {}).length) {
        return (
          <TableWrapper>
            <Table>
              <Tbody>
                {['goals', 'minutes', 'yellow_cards', 'red_cards'].map(
                  (stat) => {
                    return (
                      <Tr key={stat}>
                        <Td>{STAT_NAMES[stat] || stat}</Td>
                        <Td>{stats?.stats[stat]}</Td>
                      </Tr>
                    );
                  },
                )}
              </Tbody>
            </Table>
          </TableWrapper>
        );
      }
      return (
        <ContentNotFound
          title={__('Nenašli sa žiadne štatistiky k zobrazeniu.')}
        />
      );
    }
    return null;
  }, [member, selectedSeasonName]);

  const toggleSeasonFilter = () => setSeasonFilterOpened(!seasonFilterOpened);

  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <Spacer size={4}>
            <Filters>
              <ItemsDropDown
                items={(member?.stats || []).map((i) => ({
                  id: i.seasonName,
                  label:
                    i.seasonName === 'summary' ? __('Celkovo') : i.seasonName,
                  url: {
                    pathname,
                    query: {
                      ...query,
                      seasonName: encodeURIComponent(i.seasonName),
                    },
                    hash,
                  },
                }))}
                opened={seasonFilterOpened}
                onClose={() => {
                  setSeasonFilterOpened(!seasonFilterOpened);
                }}
                color="#333"
                activeColor="#CF2528"
                position="left"
              >
                <div>
                  <Label>{__('Sezóna')}</Label>
                  <button onClick={toggleSeasonFilter}>
                    <div>
                      {selectedSeasonName === 'summary'
                        ? __('Celkovo')
                        : selectedSeasonName}
                    </div>
                    <MoreIcon name="arrow-down" size={24} />
                  </button>
                </div>
              </ItemsDropDown>
            </Filters>
            {renderPlayerStats}
          </Spacer>
        </SidebarLayoutContent>
        <Sidebar />
      </PaddedSidebarLayout>
      <MegaboardAd />
      {member && (
        <>
          <MegaboardAd />
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: 'Členovia',
                url: '/futbalnet/clenovia/',
              },
              {
                label: formatUserName(member),
                url: getMemberDetailUrl(member.id),
              },
            ]}
          />
        </>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PlayerStatsDetail) as unknown as React.FC<IOwnProps>;
