import React from 'react';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import UnionsList from './UnionsList';

type IOwnProps = {
  xPosition: number;
  unions?: OrganizationProfileStructureItem[];
  active: boolean;
  onClose: () => void;
};

type IProps = IOwnProps;

const UnionsDropdown: React.FC<IProps> = ({
  xPosition,
  unions,
  active,
  onClose,
}) => {
  return (
    <FutbalnetMenu.PositionedDropdown
      xPosition={xPosition}
      active={active}
      onClose={onClose}
    >
      <UnionsList unions={unions || []} />
    </FutbalnetMenu.PositionedDropdown>
  );
};

export default UnionsDropdown;
