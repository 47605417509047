import React from 'react';
import { connect } from 'react-redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import NewsFeedArticlesHeader from '../../components/Articles/NewsFeedArticlesHeader';
import { RootState } from '../../configureStore';
import { IArticle } from '../../library/App';
import { cumulatedListResultsSelector } from '../../pages/App/selectors';
import NewsFeedArticlesBase from './NewsFeedArticlesBase';

type IOwnProps = {
  listName: string;
  onLoadMoreWithOffset: (nextOffset: number) => void;
  widthAd?: boolean;
  forceListOffset?: number;
};

const mapStateToProps = (state: RootState, { listName }: IOwnProps) => ({
  articles: (cumulatedListResultsSelector(state, listName) || []) as IArticle[],
  loading: isCommiting(listName)(state) || false,
  nextOffset: getListNextOffset(listName)(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const ListingNewsFeedArticles: React.FC<IProps> = ({
  articles,
  loading,
  nextOffset,
  onLoadMoreWithOffset,
  widthAd,
  forceListOffset,
}) => {
  return (
    <>
      <NewsFeedArticlesHeader />
      <NewsFeedArticlesBase
        articles={forceListOffset ? articles.slice(forceListOffset) : articles}
        loading={loading}
        nextOffset={nextOffset}
        onLoadMoreWithOffset={onLoadMoreWithOffset}
        widthAd={widthAd}
      />
    </>
  );
};

export default connect(mapStateToProps)(ListingNewsFeedArticles);
