import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import styled, { css } from '../../theme/styled-components';

const Positioner = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;

  padding: 0 0 ${rem(16)} 0;
  ${mb('m')} {
    ${({ theme }) => css`
      padding: 0 ${rem(theme.app.padding)} ${rem(16)} ${rem(theme.app.padding)};
    `}
  }
  z-index: 20000;
  overflow: hidden;
  top: ${rem(39)};
  ${mb('s')} {
    top: ${rem(55)};
  }
`;

const Wrapper = styled.div`
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0 0 ${rem(4)} ${rem(4)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  border-left: none;
  border-right: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  ${mb('m')} {
    border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  }
`;

type Props = {
  onClose: () => void;
  active: boolean;
};

const Dropdown: React.FC<Props> = ({ children, onClose, active }) => {
  const ref = useClickOutside(onClose, active);

  return (
    <Positioner>
      <Wrapper ref={ref}>{children}</Wrapper>
    </Positioner>
  );
};

export default Dropdown;
