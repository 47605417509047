import slugify from 'slugify';
import { Article_Author } from '../api/CmsApi';
import { IPPOUser } from '../library/Sportnet';

export const getAuthorUrlNameFromDisplayName = (displayName: string): string => {
  return encodeURIComponent(
    slugify(displayName.replace(/\s/g, '.').toLowerCase().trim()),
  );
};

export const getAuthorUrlName = (author: IPPOUser) => {
  return getAuthorUrlNameFromDisplayName(
    [author.name, author.surname].filter(Boolean).join(' '),
  );
};

export default function (baseUri: string, author: IPPOUser | Article_Author) {
  if ((author as IPPOUser)._id !== undefined) {
    return (
      [baseUri, (author as IPPOUser)._id, getAuthorUrlName(author as IPPOUser)]
        .filter(Boolean)
        .join('/') + '/'
    );
  } else {
    return (
      [
        baseUri,
        (author as Article_Author).sportnetId,
        getAuthorUrlNameFromDisplayName(author.name),
      ]
        .filter(Boolean)
        .join('/') + '/'
    );
  }
}
