import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 139.18"
    height={size}
  >
    <path
      fill={color}
      d="M51.05,100.38l2-11.2h54.75c4,0,5.72-.7,6.25-3.67l1.55-8.83c.5-2.81-1-3.77-4.93-3.77H73.82c-14.43,0-18.88-6-17.3-14.95l.74-4.2c1.57-8.92,8.14-15,22.57-15H133.1l-2,11.11H77.87c-3.76,0-5.85,1-6.35,3.76l-.77,4.38c-.46,2.62,1,3.76,5,3.76H112.6c14.43,0,18.87,6,17.3,15l-1.51,8.57c-1.57,8.92-8.16,15-22.59,15ZM157.2,78.07l-3.93,22.31H139L149.87,38.8h51.69c14.34,0,18.7,6,17.15,14.87L217,63.2C215.47,72,209,78.07,194.64,78.07Zm47.2-24.14c.42-2.36-.69-4-3.48-4H162.17l-3,17H198c2.8,0,4.34-1.32,4.74-3.59Zm37.36,46.45c-14.34,0-18.7-6-17.14-14.87l5.61-31.84c1.56-8.83,8.05-14.87,22.39-14.87h41.72c14.34,0,18.7,6,17.14,14.87l-5.61,31.84c-1.56,8.83-8,14.87-22.39,14.87Zm55.5-46.88c.43-2.45-1.29-3.59-4.88-3.59H250.66c-3.59,0-5.71,1.14-6.14,3.59l-5.66,32.09c-.45,2.54,1.29,3.59,4.88,3.59h41.71c3.59,0,5.7-1.05,6.15-3.59Zm78.12,46.88L366.48,73H337.09l-4.83,27.38H318L328.87,38.8h53.52c14.34,0,18.79,6,17.24,14.87l-.79,4.46c-1.3,7.35-5.83,13.21-17.11,14.7l9,27.55Zm9.93-46.88c.43-2.45-1.29-3.59-4.88-3.59H341.16l-2.11,12h39.27c3.76,0,5.63-.7,6.16-3.67Zm47.25,46.88,8.9-50.47H409.71l2-11.11h77.14l-2,11.11h-31l-8.9,50.47Z"
    />
    <path
      fill={color}
      d="M566,100.38l-56.2-44.87-7.91,44.87H489.48L500.34,38.8h12.33L563.93,81l7.43-42.16h12.5L573,100.38Zm22.11,0L599,38.8h64.89l-2,11.11H611.27L609.1,62.24H657l-2,11.2H607.12l-2.77,15.74H655l-2,11.2Zm106.59,0,8.9-50.47H671.86l2-11.11H751l-2,11.11H718l-8.9,50.47Z"
    />
    <path
      fill={color}
      d="M441.9,127.83h-420c-6.84,0-11.42-5.56-10.21-12.39l16.16-91.7c1.21-6.83,7.75-12.39,14.59-12.39h420l2-11.35h-420C31.37,0,18.85,10.65,16.54,23.74L.37,115.44c-2.31,13.09,6.46,23.74,19.56,23.74h420Z"
    />
    <path
      fill={color}
      d="M780.07,0H506.51l-2,11.35H778.07c6.84,0,11.42,5.56,10.21,12.39l-16.16,91.7c-1.21,6.83-7.75,12.39-14.59,12.39H484l-2,11.35H755.53c13.1,0,25.63-10.65,27.93-23.74l16.17-91.7C801.94,10.65,793.17,0,780.07,0Z"
    />
  </svg>
);
