import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ScrollToTop from '../../components/ScrollToTop';
import { ReduxConnectProps } from '../../configureStore';
import Analytics from '../../containers/Analytics';
import { IUrlContext } from '../../contexts/UrlContext';
import styled from '../../theme/styled-components';
import isStandalone from '../../utilities/isStandalone';
import { loadSportnetMenus } from './actions';
import AppUrlContextProvider from './AppUrlContextProvider';
import { COMPETITION_PANEL_SETTINGS } from '../../config';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type IOwnProps = {};

type IProps = IOwnProps & RouteComponentProps<void, void> & ReduxConnectProps;

interface IState {
  urlContext: IUrlContext;
}

class App extends React.PureComponent<IProps, IState> {
  static async getInitialProps(props: IProps) {
    const { dispatch } = props;
    // return dispatch(loadWholeSectionTree.action());
    return dispatch(
      (loadSportnetMenus as any)({
        competitionPanelSectionId:
          COMPETITION_PANEL_SETTINGS?.MATCHES_PANEL_ROOT_SECTION ?? '',
      }),
    );
  }

  async componentDidMount() {
    try {
      await App.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  render() {
    const { children } = this.props;
    return (
      <Wrapper>
        <AppUrlContextProvider>
          {children}
          {isStandalone() && <Analytics />}
          <ScrollToTop />
        </AppUrlContextProvider>
      </Wrapper>
    );
  }
}

export default connect()(App);
