import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import DefaultArticleList from '@sportnet/content/view/widgets/ArticleList';
import {
  ARTICLE_LIST_DISPLAY_DEFAULT_VALUE,
  IMAGE_ASPECT_RATIO_DEFAULT_VALUE,
} from '@sportnet/content/view/widgets/ArticleList/options';
import React from 'react';
import UrlContext from '../../../../contexts/UrlContext';
import RelatedArticle from '../../RelatedArticle';

interface IOwnProps {
  data?: any;
  renderLink: (link: any) => React.ReactNode;
  renderEmpty?: (type: string) => React.ReactNode;
  override?: any;
}

type IProps = IOwnProps;

const ArticleList: React.FC<IProps> = ({
  data = {
    header: '',
    display: ARTICLE_LIST_DISPLAY_DEFAULT_VALUE,
    data: {},
    articleFilter: [],
    imageAspectRatio: IMAGE_ASPECT_RATIO_DEFAULT_VALUE,
  },
  renderLink,
  renderEmpty,
  override = {},
}) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  // custom widget renderujem, ak mam vo filtri prave jeden suvisiaci clanok
  const shouldRenderCustomWidget =
    data &&
    data.data.articleFilter &&
    data.data.articleFilter.articles &&
    data.data.articleFilter.articles.length === 1 &&
    data.data.articleFilter.articles[0].doctype === 'article';

  if (shouldRenderCustomWidget) {
    const article = data.data.articleFilter.articles[0];
    return (
      <RelatedArticle
        widgetTitle={data.header}
        articleName={article.name}
        url={getArticleUrl(article)}
        imageSrc={getMediaManagerUrl(article.picture, 320, 240, 'media', false)}
      />
    );
  }
  return (
    <DefaultArticleList
      data={data}
      renderLink={renderLink}
      renderEmpty={renderEmpty}
      override={override}
    />
  );
};

export default React.memo(ArticleList);
