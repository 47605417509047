import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { IMatch } from '../../library/Competitions';

const getPhaseFromTimer = (timer: IMatch['timer']) => {
  if (!timer) {
    return undefined;
  }
  const now = new Date();
  // hladame fazu podla casu
  const actualPhase = Object.keys(timer).find((key) => {
    const val = timer[key];
    const startDate =
      val.start && val.start.date ? new Date(val.start.date) : null;
    const endDate = val.end && val.end.date ? new Date(val.end.date) : null;

    if (
      startDate &&
      isBefore(startDate, now) &&
      (!endDate || isAfter(endDate, now))
    ) {
      return true;
    }

    return false;
  });

  return actualPhase;
};

export default getPhaseFromTimer;
