import { IMatch } from '../../library/Competitions';
import { MatchesListMatch } from '../../library/Print';
import cleanRoundName from '../cleanRoundName';
import formatDate from '../formatDate';
import getMatchesListMatchDataForPrint from './getMatchesListMatchDataForPrint';

const groupMatchesByRound = (matches: IMatch[]) => {
  const mapped = matches.reduce((acc, m) => {
    if (m.round) {
      const key = `${formatDate(
        m.round.dateFrom && m.startDate
          ? new Date(m.round.dateFrom)
          : new Date(),
        'yyyy-MM-dd',
      )}|${m.competitionPart.name}|${m.round.name}`;

      if (key in acc) {
        acc[key].matches.push(getMatchesListMatchDataForPrint(m));
      } else {
        acc[key] = {
          competitionName: m.competition.name,
          roundName: cleanRoundName(m.round.name),
          matches: [getMatchesListMatchDataForPrint(m)],
        };
      }

      return acc;
    }
    return acc;
  }, {} as { [key: string]: { competitionName: string; roundName: string; matches: MatchesListMatch[] } });
  return Object.values(mapped);
};

export default groupMatchesByRound;
