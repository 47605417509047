import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../theme/styled-components';
import Link from '../../components/Link';

/**
 * Kvoli tienu aktivneho tabu pridavame padding 8px zhora/zdola a
 * zapornym marginom -8px opravime poziciu.
 */
const RelativeWrapper = styled.div`
  width: auto;
  flex-shrink: 0;
  padding: ${rem(8)} 0;
  margin: ${-rem(8)} 0;
  overflow-x: auto;
  ${mb('m')} {
    width: 100%;
  }
`;

/**
 * Vnutorny box-shadow simuluje vnutorny border.
 */
const Wrapper = styled.div<{ centered?: boolean; size?: 'm' | 's' }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.color.lightBackground};
  border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.color.lightBorder};
  ${({ size }) =>
    size === 's'
      ? css`
          border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
        `
      : css`
          border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
        `};
  ${({ centered }) =>
    !centered &&
    css`
      width: max-content;
    `}
`;

export const Tab = styled.div<{
  $active: boolean;
  $centered?: boolean;
  width?: number;
  size?: 'm' | 's';
}>`
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;
  padding: 0 ${rem(24)};
  display: flex;
  align-items: center;
  border: ${rem(1)} solid transparent;
  ${({ size }) =>
    size === 's'
      ? css`
          height: ${rem(32)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
        `
      : size === 'm'
      ? css`
          height: ${rem(48)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
        `
      : css`
          height: ${rem(32)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
          ${mb('s')} {
            height: ${rem(48)};
            border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
          }
        `};
  line-height: ${rem(19)};
  font-size: ${rem(11)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  letter-spacing: ${rem(0.88)};
  font-weight: bold;
  text-transform: uppercase;
  ${({ $centered, width }) =>
    !!$centered &&
    !!width &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: ${width}%;
    `}
  ${({ $active }) =>
    !!$active &&
    css`
      z-index: 1;
      background-color: #fff;
      color: ${({ theme }) => theme.color.sportnetRed};
      border: ${rem(1)} solid ${({ theme }) => theme.color.sportnetRed};
      box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
    `}
`;

const TabLink = styled(Link)<{
  $active: boolean;
  $centered?: boolean;
  width?: number;
  size?: 'm' | 's';
}>`
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;
  padding: 0 ${rem(24)};
  display: flex;
  align-items: center;
  border: ${rem(1)} solid transparent;
  ${({ size }) =>
    size === 's'
      ? css`
          height: ${rem(32)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
        `
      : size === 'm'
      ? css`
          height: ${rem(48)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
        `
      : css`
          height: ${rem(32)};
          border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
          ${mb('s')} {
            height: ${rem(48)};
            border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
          }
        `};
  line-height: ${rem(19)};
  font-size: ${rem(11)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  letter-spacing: ${rem(0.88)};
  font-weight: bold;
  text-transform: uppercase;
  ${({ $centered, width }) =>
    !!$centered &&
    !!width &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: ${width}%;
    `}
  ${({ $active }) =>
    !!$active &&
    css`
      z-index: 1;
      background-color: #fff;
      color: ${({ theme }) => theme.color.sportnetRed};
      border: ${rem(1)} solid ${({ theme }) => theme.color.sportnetRed};
      box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
    `}
`;

export interface ITab<T = string> {
  label: string;
  value: T;
  to?: string;
}

interface OwnProps {
  tabs: ITab[];
  onChange?: (tab: ITab) => void;
  centered?: boolean;
  active?: string;
  withBorder?: boolean;
  className?: string;
  inWidget?: boolean;
  size?: 'm' | 's' | 'responsive';
}

const Tabber: React.FC<OwnProps> = ({
  tabs,
  active,
  centered,
  onChange,
  className,
  size = 'm',
}) => {
  const renderTab = (tab: ITab) =>
    typeof onChange !== 'undefined' ? (
      <Tab
        key={tab.value}
        onClick={() => onChange(tab)}
        $active={active && active === tab.value}
        {...(!!centered && {
          $centered: true,
          width: 100 / tabs.length,
        })}
        size={size}
      >
        {tab.label}
      </Tab>
    ) : (
      <TabLink
        to={tab.to}
        key={tab.value}
        $active={active && active === tab.value}
        {...(!!centered && {
          $centered: true,
          width: 100 / tabs.length,
        })}
        size={size}
      >
        {tab.label}
      </TabLink>
    );
  return (
    <RelativeWrapper className={className}>
      <Wrapper size={size} centered={centered}>
        {tabs.map(renderTab)}
      </Wrapper>
    </RelativeWrapper>
  );
};

export default Tabber;
