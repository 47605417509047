import React from 'react';

export interface IWidgetsContext {
  layout?: 'wideform' | 'standard';
}

const WidgetsContext = React.createContext<IWidgetsContext>({
  layout: 'standard',
});

export default WidgetsContext;
