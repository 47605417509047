import Loader from '@sportnet/ui/lib/Loader';
import getYear from 'date-fns/getYear';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { getListResults, isCommiting } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ItemsDropDown from '../../../components/DropDowns/ItemsDropDown';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { RouteProps } from '../../../library/App';
import getClubBaseUrl from '../../../utilities/getClubBaseUrl';
import __ from '../../../utilities/__';
import { initializeOrSetListParams } from '../../App/actions';
import {
  currentCompetitionAppSpaceSelector,
  currentOrganizationProfileSelector,
} from '../../Competition/selectors';
import { loadCreditItems } from './actions';
import {
  FilterButton,
  FilterWrapper,
  Label,
  MoreIcon,
  TdCenter,
  TdRight,
} from './styles';
import { uniqUnionSeasonsNamesSelector } from '../../FutbalnetApp/selectors';
import AdManager from '../../../components/Ads/AdManager';
import getCleandedPathFromLocation from '../../../utilities/adverts/getCleanedPathFromLocation';

const mapStateToProps = (state: RootState) => {
  return {
    organizationProfile: currentOrganizationProfileSelector(state),
    creditItems: getListResults(config.LIST_CREDIT_ITEMS)(state),
    isFetching: isCommiting(config.LIST_CREDIT_ITEMS)(state) || false,
    seasonNames: uniqUnionSeasonsNamesSelector(state),
    appSpace: currentCompetitionAppSpaceSelector(state),
  };
};

const mapDispatchToProps = {
  loadCreditItems,
  initializeOrSetListParams,
};

const ACTUAL_YEAR = getYear(new Date());

type IProps = RouteProps<{ klub: string }> &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const QUERY_HOC_CONFIG = {
  parameters: {
    year: StringParam(ACTUAL_YEAR.toString()),
    season: StringParam(''),
  },
};

const Disclosures: React.FC<IProps> = ({
  creditItems,
  initializeOrSetListParams,
  isFetching,
  loadCreditItems,
  location,
  location: { search, pathname },
  organizationProfile,
  params: { klub },
  router,
  seasonNames,
  appSpace,
}) => {
  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG,
  );
  const businessId: string | undefined =
    organizationProfile?.invoiceProfile?.business_id;
  const FILTER_YEAR: number = parseInt(query.year, 10);

  const [isYearFilterOpened, setIsYearFilterOpened] =
    React.useState<boolean>(false);
  const [isSeasonFilterOpened, setIsSeasonFilterOpened] =
    React.useState<boolean>(false);

  useAsyncData(async () => {
    const fromDate = new Date(FILTER_YEAR, 0, 1).toJSON();
    const toDate = new Date(FILTER_YEAR + 1, 0, 1).toJSON();

    if (businessId) {
      await initializeOrSetListParams({
        listName: config.LIST_CREDIT_ITEMS,
        params: {
          businessId,
          fromDate,
          toDate,
        },
      });
      await loadCreditItems(config.LIST_CREDIT_ITEMS);
    }
  }, [businessId, FILTER_YEAR]);

  /**
   * Vrati options so zoznamom obdobi pre filter
   */
  const getYearOptionsNew = (): Array<{
    label: string;
    url: { pathname: string; query: { [key: string]: string } };
  }> => {
    return Array.apply(
      null,
      new Array(ACTUAL_YEAR - config.DISCLOSURE_YEAR_START + 1),
    ).map((_, idx) => {
      const year = `${ACTUAL_YEAR - idx}`;
      return {
        label: year,
        url: { pathname, query: { ...query, year } },
      };
    });
  };

  /**
   * Vrati options so zoznamom obdobi pre filter
   */
  const seasonOptions = React.useMemo(() => {
    return seasonNames.map((season) => ({
      label: season,
      url: { pathname, query: { ...query, season } },
    }));
  }, [query, seasonNames, pathname]);
  const selectedSeason = React.useMemo(() => {
    return query.season || seasonNames[0];
  }, [query.season, seasonNames]);

  const [isFetchingCounts, setIsFetchingCounts] = React.useState(true);
  const [counts, setCounts] = React.useState([]);

  React.useEffect(() => {
    if (!selectedSeason || !appSpace) {
      return;
    }
    setIsFetchingCounts(true);
    fetch(
      `https://bi.sportnet.online/v1/active-players/${encodeURIComponent(
        appSpace,
      )}/${encodeURIComponent(selectedSeason)}`,
    )
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          console.error('Invalid response from BI request: ', r);
          return { counts: [] };
        }
      })
      .then(({ counts }) => {
        setCounts(counts);
      })
      .finally(() => {
        setIsFetchingCounts(false);
      });
  }, [selectedSeason, appSpace]);

  const renderPrice = (price: number | undefined | null): string => {
    if (typeof price === 'number') {
      return price.toFixed(2);
    }
    return '';
  };

  const ageCategoryLabel = React.useCallback((ac) => {
    return ac === 'ADULTS' ? 'Dospelí' : ac;
  }, []);

  const genderLabel = React.useCallback((g) => {
    return g === 'M' ? 'Muž' : g === 'F' ? 'Žena' : g;
  }, []);

  const [openedAgeCategory, setOpenedAgeCategory] = React.useState<{
    [key: string]: Boolean;
  }>({});
  const toggleAgeCategory = (ageCategory) => {
    setOpenedAgeCategory((c) => {
      return { ...c, [ageCategory]: !!!c[ageCategory] };
    });
  };
  const [playersIsLoading, setPlayersIsLoading] = React.useState<{
    [key: string]: Boolean;
  }>({});
  const [players, setPlayers] = React.useState<{ [key: string]: any[] }>({});
  React.useEffect(() => {
    // ak loadujeme, nic nerobime
    // ak neloadujeme ale mame, tiez nic nerobime
    // prechadzame kazdou otvorenou kategoriou
    Object.entries(openedAgeCategory).forEach(([ageCategory, isOpened]) => {
      if (
        isOpened &&
        !playersIsLoading[ageCategory] &&
        !!!players[ageCategory]
      ) {
        setPlayersIsLoading((c) => ({ ...c, [ageCategory]: true }));
        fetch(
          `https://bi.sportnet.online/v1/active-players/${encodeURIComponent(
            appSpace,
          )}/${encodeURIComponent(selectedSeason)}/${encodeURIComponent(
            ageCategory,
          )}`,
        )
          .then((r) => {
            if (r.ok) {
              return r.json();
            } else {
              console.error('Invalid response from BI request: ', r);
              return { players: [] };
            }
          })
          .then(({ players }) => {
            setPlayers((c) => ({ ...c, [ageCategory]: players }));
          })
          .finally(() => {
            setPlayersIsLoading((c) => ({ ...c, [ageCategory]: false }));
          });
      }
    });
  }, [openedAgeCategory, players, playersIsLoading, appSpace, selectedSeason]);

  const renderContent = () => (
    <>
      <h3>
        Podpora mládeže - prehľad nákupov v kreditnom eshope{' '}
        <a href="https://futbalnet.shop" target="_blank" rel="noreferrer">
          futbalnet.shop
        </a>
      </h3>
      <FilterWrapper>
        <ItemsDropDown
          items={getYearOptionsNew()}
          opened={isYearFilterOpened}
          onClose={() => {
            setIsYearFilterOpened(!isYearFilterOpened);
          }}
          color="#333"
          activeColor="#CF2528"
        >
          <div>
            <Label>{__('Obdobie')}</Label>
            <FilterButton
              onClick={() => {
                setIsYearFilterOpened(!isYearFilterOpened);
              }}
            >
              <div>{query.year}</div>
              <MoreIcon name="arrow-down" size={16} />
            </FilterButton>
          </div>
        </ItemsDropDown>
      </FilterWrapper>
      <Table>
        <Thead>
          <Tr>
            <Th>{__('Názov produktu')}</Th>
            <Th>{__('Dodávateľ')}</Th>
            <Th>{__('Číslo faktúry')}</Th>
            <Th right>{__('Množstvo')}</Th>
            <Th right>{__(' Cena celkom s DPH ')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isFetching ? (
            <Tr>
              <Td colSpan="5">
                <Loader />
              </Td>
            </Tr>
          ) : creditItems && creditItems.length > 0 ? (
            <>
              {creditItems.map((item, idx) => (
                <Tr key={`${item.invoicenr}-${idx}`}>
                  <Td>{item.item_name}</Td>
                  <Td>{item.supplier_name}</Td>
                  <Td>{item.invoicenr}</Td>
                  <TdRight>
                    {item.quantity}&times;
                    {renderPrice(item.unitprice?.amount)}
                    &nbsp;
                    {item.unitprice?.currency}
                  </TdRight>
                  <TdRight>
                    {renderPrice(item.totalprice?.amount)}
                    &nbsp;{item.totalprice?.currency}
                  </TdRight>
                </Tr>
              ))}
            </>
          ) : (
            <Tr>
              <Td colSpan="5">
                {__('V zvolenom období nie sú zverejnené žiadne objednávky.')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <h3>{__('Aktuálny počet aktívnych športovcov (minimálne 3 štarty)')}</h3>
      <FilterWrapper>
        <ItemsDropDown
          items={seasonOptions}
          opened={isSeasonFilterOpened}
          onClose={() => {
            setIsSeasonFilterOpened(!isSeasonFilterOpened);
          }}
          color="#333"
          activeColor="#CF2528"
        >
          <div>
            <Label>{__('Sezóna')}</Label>
            <FilterButton
              onClick={() => {
                setIsSeasonFilterOpened(!isSeasonFilterOpened);
              }}
            >
              <div>{query.season || seasonOptions[0].label}</div>
              <MoreIcon name="arrow-down" size={16} />
            </FilterButton>
          </div>
        </ItemsDropDown>
      </FilterWrapper>
      <Table>
        <Thead>
          <Tr>
            <Th>{__('Veková kategória')}</Th>
            <Th center>{__('Muži')}</Th>
            <Th center>{__('Ženy')}</Th>
            <Th center>{__('Spolu')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {isFetchingCounts ? (
            <Tr>
              <Td colSpan="4">
                <Loader />
              </Td>
            </Tr>
          ) : (
            counts &&
            counts.length > 0 && (
              <>
                {counts.map(({ ageCategory, playersCount, gender = {} }) => (
                  <>
                    <Tr
                      key={`${ageCategory}`}
                      onClick={() => toggleAgeCategory(ageCategory)}
                    >
                      <Td>
                        <strong>{ageCategoryLabel(ageCategory)}</strong>
                      </Td>
                      <TdCenter>{gender['M'] || '-'}</TdCenter>
                      <TdCenter>{gender['F'] || '-'}</TdCenter>
                      <TdCenter>
                        <strong>{playersCount}</strong>
                      </TdCenter>
                    </Tr>
                    {!!openedAgeCategory[ageCategory] &&
                      (!!playersIsLoading[ageCategory] ||
                      playersIsLoading[ageCategory] === undefined ? (
                        <Tr className="datarow" key={`${ageCategory}-datarow`}>
                          <Td colSpan="4">
                            <Loader />
                          </Td>
                        </Tr>
                      ) : (
                        <Tr className="datarow" key={`${ageCategory}-datarow`}>
                          <Td colSpan="4">
                            <Table>
                              <Thead>
                                <Tr>
                                  <Th>{__('Meno a priezvisko')}</Th>
                                  <Th center>{__('Registračné číslo')}</Th>
                                  <Th center>{__('Pohlavie')}</Th>
                                  <Th center>{__('Počet štartov')}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {(players[ageCategory] || []).map((player) => {
                                  return (
                                    <Tr key={`${player.sportnetId}`}>
                                      <Td>
                                        <a
                                          href={`/futbalnet/clen/${player.issfId}/`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {player.displayName}
                                        </a>
                                      </Td>
                                      <Td center>{player.issfId}</Td>
                                      <Td center>
                                        {genderLabel(player.gender)}
                                      </Td>
                                      <Td center>{player.nrOfMatches}</Td>
                                    </Tr>
                                  );
                                })}
                              </Tbody>
                            </Table>
                          </Td>
                        </Tr>
                      ))}
                  </>
                ))}
              </>
            )
          )}
        </Tbody>
        <Thead>
          <Tr>
            <Th>{__('Spolu')}</Th>
            <Th center>
              {counts.reduce((cc, { gender: { M: c = 0 } = {} }) => cc + c, 0)}
            </Th>
            <Th center>
              {counts.reduce((cc, { gender: { F: c = 0 } = {} }) => cc + c, 0)}
            </Th>
            <Th center>
              {counts.reduce((cc, { playersCount: c }) => cc + c, 0)}
            </Th>
          </Tr>
        </Thead>
      </Table>
    </>
  );

  const pageName = __('zverejňovanie');
  const title = `${organizationProfile?.title} - ${pageName}`;
  const description = `${organizationProfile?.name}, ${pageName}: ${__(
    'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, program zápasov, štatistiky, súpisku.',
  )}`;

  // breadcrumbs
  const baseUrl = getClubBaseUrl({ klub });

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <AdManager
          site="futbalnet_klub"
          siteId={getCleandedPathFromLocation(location)}
        />
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <RempIntegration destroy />
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      {organizationProfile?.name && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: organizationProfile.name,
              url: baseUrl,
            },
            {
              label: 'Zverejňovanie',
              url: `${baseUrl}zverejnovanie/${search || ''}`,
            },
          ]}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Disclosures);
