import isBrowser from '../utilities/isBrowser';

export default ({
  unionName,
  clubName,
  seasonName,
  competitionLevel,
  competitionName,
  competitionCategory,
  competitionGender,
}: {
  unionName?: string;
  clubName?: string;
  seasonName?: string;
  competitionLevel?: number;
  competitionName?: string;
  competitionCategory?: string;
  competitionGender?: string;
}) => {
  if (isBrowser()) {
    const dataLayer = (window as any).dataLayer as any[];
    if (dataLayer) {
      const dimensions = {
        dimension1: unionName,
        dimension2: clubName,
        dimension3: seasonName,
        dimension4: competitionLevel,
        dimension5: competitionName,
        dimension6: competitionCategory,
        dimension7: competitionGender,
      };
      dataLayer.push({
        event: 'pageViewWithDimensions',
        ...dimensions,
      });
      // clearneme dimensions
      dataLayer.push({
        dimension1: undefined,
        dimension2: undefined,
        dimension3: undefined,
        dimension4: undefined,
        dimension5: undefined,
        dimension6: undefined,
        dimension7: undefined,
      });
    }
  }
};
