import CoreApi from '../api/CoreApi';
import { AsyncReturnType } from '../library/App';
import { IPPOUser } from '../library/Sportnet';

export default (
  ppoUser: AsyncReturnType<typeof CoreApi['organizationPPOUser']>,
): IPPOUser => {
  const { _id, name, surname, titles, bio, custom, photo } = ppoUser;
  const result: IPPOUser = {
    _id: _id!,
    name: name!,
    surname: surname!,
  };
  if (titles) {
    result.titles = titles;
  }
  if (photo?.public_url) {
    result.photoPublicUrl = photo.public_url;
  }
  if (bio) {
    result.bio = bio.map((b) => ({
      org_profile_id: b.org_profile_id!,
      facebook: b.facebook,
      instagram: b.instagram,
      perex: b.perex,
      photo: b.photo,
      twitter: b.twitter,
      widgets: b.widgets,
    }));
  }
  if (custom) {
    result.custom = custom.map(({ label, value, org_profile_id }) => ({
      org_profile_id,
      label: label || '',
      value: value || '',
    }));
  }
  return result;
};
