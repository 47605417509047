export const SPORT_SECTOR_CREW = [
  {
    _id: "futbal",
    items: [
      {
        _id: "coach",
        label: "Tréner",
        payload: { acceptedCompetenceTypes: ["coach"], order: 0 },
      },
      {
        _id: "assist_coach",
        label: "Asistent trénera",
        payload: { acceptedCompetenceTypes: ["coach"], order: 1 },
      },
      { _id: "manager", label: "Technický vedúci", payload: { order: 8 } },
      {
        _id: "coach_goalkeepers",
        label: "Tréner brankárov",
        payload: { acceptedCompetenceTypes: ["coach"], order: 2 },
      },
      {
        _id: "mental_coach",
        label: "Mentálny tréner",
        payload: {
          acceptedCompetenceTypes: ["coach", "mental_trainer"],
          order: 6,
        },
      },
      {
        _id: "conditioning_coach",
        label: "Kondičný tréner",
        payload: { acceptedCompetenceTypes: ["coach"], order: 3 },
      },
      {
        _id: "doctor",
        label: "Lekár",
        payload: { acceptedCompetenceTypes: ["doctor"], order: 9 },
      },
      {
        _id: "physiotherapist",
        label: "Fyzioterapeut",
        payload: {
          acceptedCompetenceTypes: ["therapist", "masseur"],
          order: 10,
        },
      },
      {
        _id: "masseur",
        label: "Masér",
        payload: {
          acceptedCompetenceTypes: ["therapist", "masseur"],
          order: 11,
        },
      },
      { _id: "custodian", label: "Kustód", payload: { order: 12 } },
      { _id: "videoanalyst", label: "Videoanalytik", payload: { order: 5 } },
      {
        _id: "club_representative",
        label: "Člen realizačného tímu",
        payload: { order: 14 },
      },
      {
        _id: "team_technical_manager",
        label: "Technický manažér tímu",
        payload: { order: 4 },
      },
      {
        _id: "expedition_leader",
        label: "Vedúci výpravy",
        payload: { order: 15 },
      },
      {
        _id: "psychologist",
        label: "Športový psychológ",
        payload: { order: 7 },
      },
      { _id: "media_manager", label: "Media manažér", payload: { order: 13 } },
      {
        _id: "team_ambassador",
        label: "Ambasádor tímu",
        payload: { order: 16 },
      },
    ],
  },
  {
    _id: "beachfutbal",
    items: [
      {
        _id: "coach",
        label: "Tréner",
        payload: { acceptedCompetenceTypes: ["coach"] },
      },
      {
        _id: "assist_coach_a",
        label: "Asistent trénera 1",
        payload: { acceptedCompetenceTypes: ["coach"] },
      },
      {
        _id: "assist_coach_b",
        label: "Asistent trénera 2",
        payload: { acceptedCompetenceTypes: ["coach"] },
      },
      { _id: "manager", label: "Vedúci družstva" },
      {
        _id: "coach_goalkeepers",
        label: "Tréner brankárov",
        payload: { acceptedCompetenceTypes: ["coach"] },
      },
      {
        _id: "doctor",
        label: "Lekár",
        payload: { acceptedCompetenceTypes: ["doctor"] },
      },
      {
        _id: "masseur",
        label: "Masér",
        payload: { acceptedCompetenceTypes: ["masseur"] },
      },
      { _id: "club_representative", label: "Zástupca klubu" },
    ],
  },
];
