import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { IResultsTableItem } from '@sportnet/content/library/Competitions';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import { rem } from 'polished';
import React from 'react';
import Tooltip from 'react-tooltip-lite';
import styled, { css } from '../../../../theme/styled-components';
import getPublicCountryCode from '../../../../utilities/match/getPublicCountryCode';
import getTeamName from '../../../../utilities/match/getTeamName';
import __ from '../../../../utilities/__';
import {
  Legend,
  ResponsiveSpacer,
  StickySeparator,
  StickyTd,
  Table,
  TableWrapper,
  Tbody,
  Td,
  Th,
  Thead,
  TrLink,
} from '../../../Table/DivTable';
import { TeamLogo, TeamLogoWrapper, TeamName } from './components';
import { ITeam, IWidgetResultsTable } from './types';
import getTeamUrl from '../../../../utilities/events/getTeamUrl';
import { ITeam as ITeam2 } from '../../../../library/Competitions';
import getFallbackClubOrUnionLogo from '../../../../utilities/getFallbackClubOrUnionLogo';

const STICKY_INDEX_WIDTH = 64;
const STICKY_TEAM_NAME_WIDTH = 96;
const STICKY_SEPARATOR_OFFSET = STICKY_INDEX_WIDTH + STICKY_TEAM_NAME_WIDTH;

const Index = styled.div<{ indexType: '1' | '2' | '3' }>`
  margin: auto;
  width: ${rem(24)};
  height: ${rem(24)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: ${rem(11)};
  line-height: ${rem(14.4)};
  text-align: center;
  ${({ indexType }) =>
    indexType === '1'
      ? css`
          color: #fff;
          background-color: #5bd084;
        `
      : indexType === '2'
      ? css`
          // color: #fff;
          // background-color: #e94141;
        `
      : ''}
`;

const DesktopText = styled.span`
  display: none;
  ${mb('m')} {
    display: inline;
  }
`;

const MobileText = styled.span`
  ${mb('m')} {
    display: none;
  }
`;

const MobileTeamName = styled(MobileText)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TeamStickyTd = styled(StickyTd)`
  min-width: ${rem(STICKY_TEAM_NAME_WIDTH)};
  ${mb('m')} {
    min-width: ${rem(120)};
  }
`;

interface IOwnProps {
  data: IWidgetResultsTable;
}

const HockeyResultsTable: React.FC<IOwnProps> = ({ data }) => {
  const results: IResultsTableItem[] = data?.data?.results || [];
  const teams = data?.data?.teams || [];
  const teamById = teams.reduce((acc: Record<string, ITeam>, t) => {
    acc[t._id] = t;
    return acc;
  }, {});

  const { competition } = data.data ?? {};
  const { _id: competitionId = '' } = competition ?? {};

  return (
    <>
      <TableWrapper>
        <Table>
          <Thead>
            <Th>
              <StickyTd
                style={{
                  width: rem(STICKY_INDEX_WIDTH),
                  minWidth: rem(STICKY_INDEX_WIDTH),
                  left: 0,
                }}
              />
              <TeamStickyTd style={{ left: rem(STICKY_INDEX_WIDTH) }}>
                <DesktopText>{__('Názov krajiny')}</DesktopText>
                <MobileText>{__('Štát')}</MobileText>
              </TeamStickyTd>
              <StickyTd
                style={{ padding: 0, left: rem(STICKY_SEPARATOR_OFFSET) }}
              >
                <StickySeparator />
              </StickyTd>
              <ResponsiveSpacer />
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Zápasy')}>
                  {__('Z')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Výhry')}>
                  {__('V')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Výhry po predĺžení')}>
                  {__('Vp')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Prehry po predĺžení')}>
                  {__('Pp')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Prehry')}>
                  {__('P')}
                </Tooltip>
              </Td>
              <Td style={{ width: rem(36) }}>{__('Skóre')}</Td>
              <Td style={{ width: rem(16) }}>
                <Tooltip hoverDelay={0} content={__('Body')}>
                  {__('B')}
                </Tooltip>
              </Td>
            </Th>
          </Thead>
          <Tbody>
            {results.map((result, idx) => {
              const team = teamById[result.team._id] || result.team;
              const teamName = getTeamName(data.appSpace!, team);
              const teamLogoSrc = getTeamLogoSrc(data.appSpace || '', team);
              const stats = result.stats as any;
              const indexType =
                idx < 4 ? '1' : idx === results.length - 1 ? '2' : '3';

              const teamUrl = team
                ? getTeamUrl(team as unknown as ITeam2, competitionId)
                : '';

              return (
                <TrLink key={team._id} to="">
                  <StickyTd style={{ left: 0 }}>
                    <Index indexType={indexType}>{idx + 1}</Index>
                  </StickyTd>
                  <StickyTd style={{ left: rem(STICKY_INDEX_WIDTH) }}>
                    <FlexWrapper>
                      {teamLogoSrc && (
                        <TeamLogoWrapper>
                          <TeamLogo
                            fallbackSrc={getFallbackClubOrUnionLogo()}
                            alt={teamName}
                            src={teamLogoSrc}
                            loading="lazy"
                          />
                        </TeamLogoWrapper>
                      )}
                      <TeamName href={teamUrl} as={teamUrl ? 'a' : 'span'}>
                        <DesktopText>{teamName}</DesktopText>
                        <MobileTeamName
                          style={{ maxWidth: rem(32) }}
                          title={teamName}
                        >
                          {getPublicCountryCode(team.organization?.country) ||
                            teamName}
                        </MobileTeamName>
                      </TeamName>
                    </FlexWrapper>
                  </StickyTd>
                  <StickyTd
                    style={{ padding: 0, left: rem(STICKY_SEPARATOR_OFFSET) }}
                  >
                    <StickySeparator />
                  </StickyTd>
                  <ResponsiveSpacer />
                  <Td>{stats.matches.played}</Td>
                  <Td>{stats.matches.won}</Td>
                  <Td>{stats.ctk?.vp}</Td>
                  <Td>{stats.ctk?.pp}</Td>
                  <Td>{stats.matches.lost}</Td>
                  <Td>
                    {stats.goals.given}:{stats.goals.received}
                  </Td>
                  <Td>{stats.points}</Td>
                </TrLink>
              );
            })}
          </Tbody>
        </Table>
      </TableWrapper>
      <Legend>
        {__(
          'Z = zápasy, V = Výhry, VP = Výhry po predĺžení, PP = Prehry po predĺžení, P = Prehry, B = Body',
        )}
      </Legend>
    </>
  );
};

export default React.memo(HockeyResultsTable);
