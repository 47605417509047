import React from 'react';
import SDMatches from '../../containers/StructuredData/SDMatches';
import { IWidgets } from '../../library/App';
import { IMatch } from '../../library/Competitions';

type IOwnProps = {
  items: IWidgets;
};

type IProps = IOwnProps;

interface ISD {
  matchesById: Record<string, IMatch>;
  raceMatchesById: Record<string, IMatch>;
}

const SDWidgets: React.FC<IProps> = ({ items }) => {
  const { matchesById, raceMatchesById } = items.reduce(
    (acc: ISD, widget) => {
      switch (widget.type) {
        case 'matchesList': {
          (widget.data?.matches || []).forEach((m: IMatch) => {
            acc.matchesById[m._id] = m;
          });
          break;
        }
        case 'raceMatchesList': {
          (widget.data?.matches || []).forEach((m: IMatch) => {
            acc.raceMatchesById[m._id] = m;
          });
          break;
        }
        case 'matchDetail':
        case 'matchOverview': {
          acc.matchesById[widget.data.match._id] = widget.data.match;
          break;
        }
        default:
          break;
      }
      return acc;
    },
    { matchesById: {}, raceMatchesById: {} },
  );

  const matches = Object.keys(matchesById).map((k) => matchesById[k]);
  const raceMatches = Object.keys(raceMatchesById).map(
    (k) => raceMatchesById[k],
  );
  return <SDMatches matches={matches} raceMatches={raceMatches} />;
};

export default React.memo(SDWidgets);
