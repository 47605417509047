import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle, ISection } from '../../../library/App';
import styled from '../../../theme/styled-components';
import distanceInWordsToNow from '../../../utilities/distanceInWordsToNow';
import getMultiLineClapHeight from '../../../utilities/getMultiLineClapHeight';
import Link from '../../Link';
import ArticleImage from './ArticleImage';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.app.textColor};
  word-break: break-word;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-shrink: 0;
  ${mb('s')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${rem(32)};
    gap: ${rem(32)};
  }
`;

const WrapperArticleDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArticleLink = styled(Link)`
  display: flex;
  flex-direction: column;
  font-family: pt-serif-pro, serif;
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  font-size: ${rem(18)};
  line-height: ${rem(21.6)};
  ${mb('s')} {
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  }
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

const Description = styled.span`
  color: ${({ theme }) => theme.app.secondaryTextColor};
  font-size: ${rem(12)};
  line-height: ${rem(17)};
  margin-bottom: ${rem(8)};
  margin-top: ${rem(16)};
  ${mb('s')} {
    margin-top: 0;
    margin-bottom: ${rem(16)};
  }
`;

const PEREX_FONT_SIZE = 16;
const PEREX_LINE_HEIGHT = 1.5;
const PEREX_LINES = 3;

const Perex = styled.p`
  margin: ${rem(16)} 0 0 0;
  padding: 0;
  font-family: pt-serif-pro, serif;
  font-size: ${rem(PEREX_FONT_SIZE)};
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 300;
  line-height: ${PEREX_LINE_HEIGHT};

  max-width: 100%;
  max-height: ${rem(
    getMultiLineClapHeight(PEREX_FONT_SIZE, PEREX_LINE_HEIGHT, PEREX_LINES),
  )};
  -webkit-line-clamp: ${PEREX_LINES};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  display: none;
  ${mb('l')} {
    display: block;
    display: -webkit-box;
  }
`;

type IOwnProps = {
  article: IArticle;
  section: ISection | null;
};

type IProps = IOwnProps;

const Article: React.FC<IProps> = ({ article, section }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const articleDate = new Date(
    article.updated_at || article.valid_from || article.created || '',
  );

  return (
    <Wrapper>
      <ArticleLink to={getArticleUrl(article)}>
        <ArticleImage
          article={article}
          naturalWidth={418 * 2}
          naturalHeight={314 * 2}
        />
      </ArticleLink>
      <WrapperArticleDescription>
        <Description>{distanceInWordsToNow(articleDate)}</Description>
        <ArticleLink to={getArticleUrl(article)}>{article.name}</ArticleLink>
        {article.perex && <Perex>{article.perex}</Perex>}
      </WrapperArticleDescription>
    </Wrapper>
  );
};

export default React.memo(Article);
