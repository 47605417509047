import { NikeSport } from "../../library/nike";
import { ThirdPartyResultsOdd } from "../../library/thirdPartyResults";

const BEZ_REMIZY = [
  NikeSport.TENIS,
  NikeSport.TABLE_TENIS,
  NikeSport.KRIKET,
  NikeSport.SQUASH,
];

/**
 * AK dany event nema ziadne odds vykreslujeme len pomlcky "-". Podla typu sportu ale mozu byt dve alebo tri.
 * @param event
 * @returns
 */
const getEmptyOdds = (
  thirdPartyResultsSportId: number
): { type: ThirdPartyResultsOdd['type']; rate: number }[] => {
  if (BEZ_REMIZY.includes(thirdPartyResultsSportId)) {
    return [
      {
        type: '1',
        rate: 0,
      },
      {
        type: '2',
        rate: 0,
      },
    ];
  }
  return [
    {
      type: '1',
      rate: 0,
    },
    {
      type: 'X',
      rate: 0,
    },
    {
      type: '2',
      rate: 0,
    },
  ];
};

export default getEmptyOdds;
