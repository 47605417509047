import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from 'styled-components';
import Icon from '../../Icon';
import React from 'react';
import Notification from '../Notification';

const Button = styled.button`
  margin: 0;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  color: ${({ theme }) => theme.color.primaryFontColor};
  vertical-align: baseline;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.color.darkBorder};
  border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  box-shadow: 0px 1px 2px ${({ theme }) => theme.color.shadowColor};
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
  letter-spacing: 0.88px;
  line-height: ${rem(16.8)};
  height: ${rem(40)};
  width: ${rem(40)};
  background: none;
  font-size: ${rem(11)};
  :hover,
  :active {
    background: none;
  }
  ${mb('s')} {
    height: ${rem(48)};
    width: auto;
    padding: 0 ${rem(24)};
  }
`;

const Label = styled.span`
  display: none;
  margin-left: 0;
  ${mb('s')} {
    display: inline;
    margin-left: ${rem(8)};
  }
`;

const PositionedNotification = styled(Notification)`
  position: absolute;
  top: ${rem(-4)};
  right: ${rem(-4)};
  ${mb('s')} {
    top: ${rem(4)};
    left: ${rem(40)};
  }
`;

interface OwnProps {
  className?: string;
  onClick?: () => void;
  badgeNumber?: number;
}

const FilterButton: React.FC<OwnProps> = ({
  className,
  onClick,
  badgeNumber,
}) => {
  return (
    <Button onClick={onClick} className={className}>
      {typeof badgeNumber !== 'undefined' && (
        <PositionedNotification>{badgeNumber}</PositionedNotification>
      )}
      <Icon name="filter" size={24} />
      <Label>Filter</Label>
    </Button>
  );
};

export default FilterButton;
