const translateSportGroundType = (i: string) => {
  if (i === 'rovinatý') {
    return { src: '/content/flat.png', label: i };
  } else if (i === 'kopcovitý') {
    return { src: '/content/hilly.png', label: i };
  } else if (i === 'hornatý') {
    return { src: '/content/mountain.png', label: i };
  } else if (i === 'individuálna časovka') {
    return { src: '/content/time-trial.png', label: i };
  }
  return { label: i };
};

const sportGroundAdditionalProperties: {
  [key: string]: (i: string) => {
    label?: string;
    src?: string;
  };
} = {
  length: (i: string) => ({ label: i }),
  type: (i: string) => translateSportGroundType(i),
};

export const translateSportGroundAdditionalProperties = (properties: {
  [key: string]: string;
}) => {
  return Object.keys(properties)
    .sort()
    .map((prop) => sportGroundAdditionalProperties[prop](properties[prop]));
};
