import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import { RootState } from '../../../configureStore';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { RouteProps } from '../../../library/App';
import isVideoSrcAllowed from '../../../utilities/match/isVideoSrcAllowed';
import __ from '../../../utilities/__';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import { currentMatchSelector } from '../selectors';

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state),
});

interface IOwnProps {
  isActive?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  IOwnProps;

const MatchLive: React.FC<Props> = ({ match, isActive = true }) => {
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  return (
    <>
      {isActive && <TopAd />}
      {isActive && <BottomPaddedBigAd name="big_1" />}

      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          {match && match.livestream && isVideoSrcAllowed(match.livestream) ? (
            <iframe
              title={match._id}
              style={{ width: '100%', minHeight: rem(400), border: 0 }}
              src={match.livestream}
            />
          ) : (
            <ContentNotFound
              title={__('Žiadne video zatiaľ nebolo pridané.')}
            />
          )}
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      {isActive && (
        <>
          <MegaboardAd />
          <FutbalnetMatchBreadcrumbs
            subpage={{ label: 'Live', subpageUrl: 'live' }}
          />
        </>
      )}
    </>
  );
};

export default compose(connect(mapStateToProps))(
  MatchLive,
) as unknown as React.FC<IOwnProps>;
