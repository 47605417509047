/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description: string;

  payload?: {};

  userinfo?: string;
};
export type Pager = {
  total: number;

  limit: number;

  offset: number;

  nextOffset: number;
};
export type EventLink = {
  name: string;

  url: string;
};
export type Event = {
  _id: string;

  dateFrom: string;

  dateTo: string;

  duration: number;

  name: string;

  note: string;

  sport: {
    _id: string;

    name: string;

    priority?: number;
  };

  competition: {
    _id: string;

    name: string;

    priority?: number;
  };

  gender?: string;

  provider: {
    _id: string;

    name: string;

    priority?: number;

    logo?: {
      src: string;

      alt: string;
    };
  };

  priority?: number;

  links?: Array<{
    name: string;

    url: string;
  }>;

  externalId: string;
};
export type Provider = {
  _id: string;

  name: string;

  priority?: number;

  logo?: {
    src: string;

    alt: string;
  };
};
export type Sport = {
  _id: string;

  name: string;

  priority?: number;
};
export type Competition = {
  _id: string;

  name: string;

  priority?: number;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class TvProgramApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class TvProgramApi {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];
  protected apiKey: string | null = null;

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  public setApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.apiKey) {
      headers.append('apikey', this.apiKey);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Zoznam providerov pre admin rozhranie.
   * @method
   * @name TvProgramApi#adminGetProviders
   */
  adminGetProviders(parameters: {} = {}): Promise<{
    providers: Array<{
      _id: string;

      name: string;

      priority?: number;

      logo?: {
        src: string;

        alt: string;
      };
    }>;
  }> {
    let path = '/admin/providers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam sportov pre admin rozhranie.
   * @method
   * @name TvProgramApi#adminGetSports
   */
  adminGetSports(parameters: {} = {}): Promise<{
    sports: Array<{
      _id: string;

      name: string;

      priority?: number;
    }>;
  }> {
    let path = '/admin/sports';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam sutazi pre admin rozhranie.
   * @method
   * @name TvProgramApi#adminGetCompetitions
   */
  adminGetCompetitions(parameters: {} = {}): Promise<{
    competitions?: Array<{
      _id: string;

      name: string;

      priority?: number;
    }>;
  }> {
    let path = '/admin/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam programov.
   * @method
   * @name TvProgramApi#publicGetEvents
   */
  publicGetEvents(
    parameters: {
      offset?: number;
      limit?: number;
      dateFrom?: string;
      dateTo?: string;
      sport?: Array<string>;
      provider?: Array<string>;
      competition?: Array<string>;
      priority?: number;
      live?: string;
    } = {},
  ): Promise<{
    total: number;

    limit: number;

    offset: number;

    nextOffset: number;

    events: Array<{
      _id: string;

      dateFrom: string;

      dateTo: string;

      duration: number;

      name: string;

      note: string;

      sport: {
        _id: string;

        name: string;

        priority?: number;
      };

      competition: {
        _id: string;

        name: string;

        priority?: number;
      };

      gender?: string;

      provider: {
        _id: string;

        name: string;

        priority?: number;

        logo?: {
          src: string;

          alt: string;
        };
      };

      priority?: number;

      links?: Array<{
        name: string;

        url: string;
      }>;

      externalId: string;
    }>;
  }> {
    let path = '/public/events';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['sport'] !== 'undefined') {
      queryParameters['sport'] = parameters['sport'];
    }

    queryParameters['sport'] = this.transformParameter(
      queryParameters['sport'],
    );

    if (typeof parameters['provider'] !== 'undefined') {
      queryParameters['provider'] = parameters['provider'];
    }

    queryParameters['provider'] = this.transformParameter(
      queryParameters['provider'],
    );

    if (typeof parameters['competition'] !== 'undefined') {
      queryParameters['competition'] = parameters['competition'];
    }

    queryParameters['competition'] = this.transformParameter(
      queryParameters['competition'],
    );

    if (typeof parameters['priority'] !== 'undefined') {
      queryParameters['priority'] = parameters['priority'];
    }

    queryParameters['priority'] = this.transformParameter(
      queryParameters['priority'],
    );

    if (typeof parameters['live'] !== 'undefined') {
      queryParameters['live'] = parameters['live'];
    }

    queryParameters['live'] = this.transformParameter(queryParameters['live']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * iCal event.
   * @method
   * @name TvProgramApi#publicExportEventToICal
   */
  publicExportEventToICal(eventId: string, parameters: {} = {}): Promise<File> {
    let path = '/public/events/{eventId}/ical';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{eventId}', eventId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam providerov, ktorí majú zaradený nejaký program vo vysielaní v aktuálnom vysielacom období (relatívne k aktuálnemu dňu je to -1 deň a 21 dní do budúcnosti).
   * @method
   * @name TvProgramApi#publicGetProviders
   */
  publicGetProviders(parameters: {} = {}): Promise<{
    providers?: Array<{
      _id: string;

      name: string;

      priority?: number;

      logo?: {
        src: string;

        alt: string;
      };
    }>;
  }> {
    let path = '/public/providers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Detail providera.
   * @method
   * @name TvProgramApi#publicGetProvider
   */
  publicGetProvider(
    providerId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    priority?: number;

    logo?: {
      src: string;

      alt: string;
    };
  }> {
    let path = '/public/providers/{providerId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{providerId}', providerId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam športov.
   * @method
   * @name TvProgramApi#publicGetSports
   */
  publicGetSports(parameters: {} = {}): Promise<{
    sports?: Array<{
      _id: string;

      name: string;

      priority?: number;
    }>;
  }> {
    let path = '/public/sports';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Detail športu.
   * @method
   * @name TvProgramApi#publicGetSport
   */
  publicGetSport(
    sportId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    priority?: number;
  }> {
    let path = '/public/sports/{sportId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{sportId}', sportId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Zoznam súťaží.
   * @method
   * @name TvProgramApi#publicGetCompetitions
   */
  publicGetCompetitions(parameters: {} = {}): Promise<{
    competitions?: Array<{
      _id: string;

      name: string;

      priority?: number;
    }>;
  }> {
    let path = '/public/competitions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Detail súťaže.
   * @method
   * @name TvProgramApi#publicGetCompetition
   */
  publicGetCompetition(
    competitionId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    name: string;

    priority?: number;
  }> {
    let path = '/public/competitions/{competitionId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{competitionId}', competitionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Spustí import programov z externého zdroja
   * @method
   * @name TvProgramApi#publicTriggerImport
   */
  publicTriggerImport(parameters: { apiKey: string }): Promise<void> {
    let path = '/public/trigger-import';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['apiKey'] !== 'undefined') {
      queryParameters['apiKey'] = parameters['apiKey'];
    }

    queryParameters['apiKey'] = this.transformParameter(
      queryParameters['apiKey'],
    );

    if (typeof parameters['apiKey'] === 'undefined') {
      throw new Error('Missing required parameter: apiKey');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new TvProgramApi();
export { TvProgramApi };
