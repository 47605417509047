/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

interface IComiteeMember {
  name: string;
  surname: string;
  fullname: string;
}

interface IComiteeSecretary {
  __uri__?: string;
  id: string;
  name: string;
  surname: string;
  regnr?: string;
  photo?: {
    description: string;
    url: string;
    urlpattern: string;
  };
  isPublic?: boolean;
}

export interface IComiteeDetail {
  __uri__?: string;
  id: number;
  name: string;
  type?: {
    id: string;
  };
  secretary: Array<IComiteeSecretary>;
  members: Array<IComiteeMember>;
}

export interface ISeason {
  __uri__: string;
  id: number;
  txtid: string;
  name: string;
  actual: boolean;
  dates: {
    from: string;
    to: string;
  };
}

export interface IResolution {
  __uri__?: string;
  number: string;
  commision?: {
    __uri__: string;
    id: number;
    name?: string;
    type?: {
      id: string;
    };
  };
  season?: ISeason;
  title: string;
  text?: string;
  status?: string;
  created_date?: string;
  club?: {
    __redis__: string;
    __uri__: string;
    id: number;
    logo: {
      url: string;
      urlpattern: string;
    };
    name: string;
  };
  fees?: [
    {
      datetime: string;
      reason: string;
      fee: {
        type: string;
        amount: number;
        currency: string;
      };
      person: {
        name: string;
        surname: string;
      };
    },
  ];
  restrictions?: [
    {
      type: string;
      player: {
        __uri__: string;
        type: string;
        person: object;
      };
      validity: {
        from: string;
        matches: number;
      };
      description: string;
    },
  ];
  penalties?: [
    {
      description: string;
      datetime: string;
      penalty: {
        amount: number;
        currency: string;
      };
    },
  ];
}

export interface IComiteeListItem {
  __uri__?: string;
  id: number;
  name: string;
  type?: {
    id: string;
  };
  secretary?: Array<IComiteeSecretary>;
  members?: Array<IComiteeMember>;
}

export interface ILatestResolutions extends IComiteeListItem {
  latestresolutions: {
    resolutions?: IResolution[];
  };
}

/**
 * RESTful API specification.

 * @class PagesApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class FutbalnetApi {
  protected baseUrl: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, {
      method,
      headers,
      body,
    });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  getComitees(unionId: string): Promise<IComiteeListItem[]> {
    let path = '/unions/{unionId}/commisions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{unionId}', unionId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  getComiteeDetail(
    unionId: string,
    comiteeId: string,
  ): Promise<IComiteeDetail> {
    let path = '/unions/{unionId}/commisions/{comiteeId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    path = path.replace('{unionId}', unionId);
    path = path.replace('{comiteeId}', comiteeId);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  getResolutionDetail(
    unionId: string,
    comiteeId: string,
    season: string,
    resolutionNumber: string,
  ): Promise<IResolution> {
    let path =
      '/unions/{unionId}/commisions/{comiteeId}/resolutions/{season}/{resolutionNumber}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    path = path.replace('{unionId}', unionId);
    path = path.replace('{comiteeId}', comiteeId);
    path = path.replace('{season}', season);
    path = path.replace('{resolutionNumber}', resolutionNumber);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  getLatestResolutions(
    unionId: string,
    season: string,
  ): Promise<ILatestResolutions[]> {
    let path = '/unions/{unionId}/commisions/latestresolutions/{season}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    path = path.replace('{unionId}', unionId);
    path = path.replace('{season}', season);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  getSeasons(): Promise<Array<ISeason>> {
    let path = `/season`;
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new FutbalnetApi();
