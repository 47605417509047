import {
  formatParameters,
  getListParameters,
  getListResults,
  listDomain,
} from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import {
  ICompetition,
  ICompetitionPart,
  IPlayerStat,
} from '../../library/Competitions';
import { IDetailInitialState } from '../App/reducer';
import { detailTypeSelector, entitiesSelector } from '../App/selectors';

/***
 * V ramci sutaze `competition`, vybera hracov z `resultsTable` a doplni
 * im `__issfId` z `teams.squad.athletes.additionalData` (ak existuje).
 */
const getResultsTablePlayersWithIssfIds = (
  competition: ICompetition | ICompetitionPart | null,
) => {
  if (!competition) {
    return [];
  }
  const teams = competition?.teams || [];
  // vrat
  const players = competition?.resultsTable?.players || [];
  const playersWithIds = players.map((player: IPlayerStat) => {
    // hladam v teams podla team id
    const team = teams.find((t) => t._id === player.teamId);
    if (team) {
      // hladam v ramci temu v squad.athletes podla sportnetUser._id
      const athlete = (team.squad?.athletes || []).find(
        (a) => a.sportnetUser._id === player._id,
      );
      if (athlete) {
        return {
          ...player,
          __issfId: player.__issfId ? Number(player.__issfId) : athlete.additionalData.__issfId || null,
        };
      }
    }
    return {
      ...player,
      __issfId: player.__issfId ? Number(player.__issfId) : null,
    };
  });
  return playersWithIds;
};

export const currentCompetitionIdSelector = (state: any) =>
  state.currentCompetitionId;

export const getCurrentCompetitionIdSelector = createSelector(
  currentCompetitionIdSelector,
  (currentId) => currentId,
);

export const currentCompetitionGroupIdSelector = (state: any) =>
  state.currentCompetitionGroupId;

export const getCurrentCompetitionGroupIdSelector = createSelector(
  currentCompetitionGroupIdSelector,
  (currentGroupId) => currentGroupId,
);

export const currentCompetitionPartIdSelector = (state: any) =>
  state.currentCompetitionPartId;

export const getCurrentCompetitionPartIdSelector = createSelector(
  currentCompetitionPartIdSelector,
  (partId) => partId,
);

export const currentRoundIdSelector = (state: any) => state.currentRoundId;

export const getCurrentRoundIdSelector = createSelector(
  currentRoundIdSelector,
  (roundId) => roundId,
);

export const currentCompetitionAppSpaceSelector = (state: any) =>
  state.currentCompetitionAppSpace;

export const getCurrentCompetitionAppSpaceSelector = createSelector(
  currentCompetitionAppSpaceSelector,
  (appSpace) => appSpace,
);

const currentOrganizationProfileDomain = createSelector(
  currentCompetitionAppSpaceSelector,
  detailTypeSelector('organizationProfileById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentOrganizationProfileIsFetchingSelector = createSelector(
  currentOrganizationProfileDomain,
  (domain) => domain.isFetching,
);

export const currentOrganizationProfileErrorSelector = createSelector(
  currentOrganizationProfileDomain,
  (domain) => domain.error,
);

export const currentOrganizationProfileSelector = createSelector(
  entitiesSelector,
  currentCompetitionAppSpaceSelector,
  (entities, id) =>
    id !== null && id in entities.organizationProfiles
      ? entities.organizationProfiles[id]
      : null,
);

const currentCompetitionDomain = createSelector(
  currentCompetitionIdSelector,
  detailTypeSelector('competitionById'),
  (id, byId) => {
    return byId[id || ''] || ({} as Partial<IDetailInitialState['']>);
  },
);

const currentCompetitionPartDomain = createSelector(
  currentCompetitionPartIdSelector,
  detailTypeSelector('competitionPartById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

const currentCompetitionPartPlayersDomain = createSelector(
  currentCompetitionPartIdSelector,
  detailTypeSelector('playersByPartId'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentCompetitionIsFetchingSelector = createSelector(
  currentCompetitionDomain,
  (domain) => domain.isFetching,
);

export const currentCompetitionErrorSelector = createSelector(
  currentCompetitionDomain,
  (domain) => domain.error,
);

export const currentCompetitionSelector = createSelector(
  entitiesSelector,
  currentCompetitionIdSelector,
  (entities, id) =>
    id !== null && id in entities.competitions
      ? entities.competitions[id]
      : null,
);

export const currentCompetitionPartIsFetchingSelector = createSelector(
  currentCompetitionPartDomain,
  (domain) => domain.isFetching,
);

export const currentCompetitionPartErrorSelector = createSelector(
  currentCompetitionPartDomain,
  (domain) => domain.error,
);

export const currentCompetitionPartPlayersIsFetchingSelector = createSelector(
  currentCompetitionPartPlayersDomain,
  (domain) => domain.isFetching,
);

export const currentCompetitionPartSelector = createSelector(
  entitiesSelector,
  currentCompetitionPartIdSelector,
  (entities, id) => {
    return id !== null && id in entities.competitionParts
      ? entities.competitionParts[id]
      : null;
  },
);

export const currentCompetitionPanelPastMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.PANEL_PAST_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentCompetitionPanelFutureMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.PANEL_FUTURE_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const panelMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.PANEL_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const panelMainEventMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.PANEL_MAINEVENT_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const teamMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_TEAM_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

const currentCompetitionListFutureMatchesIdsSelector = createSelector(
  listDomain(config.LIST_FUTURE_COMPETITION_MATCHES),
  getListParameters(config.LIST_FUTURE_COMPETITION_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_COMPETITION_MATCHES_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentCompetitionListFutureMatchesSelector = createSelector(
  entitiesSelector,
  currentCompetitionListFutureMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

const currentCompetitionListPastMatchesIdsSelector = createSelector(
  listDomain(config.LIST_PAST_COMPETITION_MATCHES),
  getListParameters(config.LIST_PAST_COMPETITION_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_COMPETITION_MATCHES_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentCompetitionListPastMatchesSelector = createSelector(
  entitiesSelector,
  currentCompetitionListPastMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

const currentCompetitionListTodayMatchesIdsSelector = createSelector(
  listDomain(config.LIST_TODAY_COMPETITION_MATCHES),
  getListParameters(config.LIST_TODAY_COMPETITION_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_COMPETITION_MATCHES_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentCompetitionListTodayMatchesSelector = createSelector(
  entitiesSelector,
  currentCompetitionListTodayMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

export const competitionsByCompetititonGroupSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMPETITIONS_BY_GROUP_ID),
  (entities, ids) => at(entities.competitions, ids || []),
);

export const competitionPartsSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMPETITION_PARTS),
  (entities, ids) => at(entities.competitionParts, ids || []),
);

const currentCompetitionPartPastMatchesIdsSelector = createSelector(
  listDomain(config.LIST_PAST_COMPETITION_PART_MATCHES),
  getListParameters(config.LIST_PAST_COMPETITION_PART_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || 16;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentCompetitionPartPastMatchesSelector = createSelector(
  entitiesSelector,
  currentCompetitionPartPastMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

const currentCompetitionPartFutureMatchesIdsSelector = createSelector(
  listDomain(config.LIST_FUTURE_COMPETITION_PART_MATCHES),
  getListParameters(config.LIST_FUTURE_COMPETITION_PART_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || 16;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentCompetitionPartFutureMatchesSelector = createSelector(
  entitiesSelector,
  currentCompetitionPartFutureMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

export const homepagePastMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.HOMEPAGE_PAST_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const homepageFutureMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.HOMEPAGE_FUTURE_COMPETITION_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentCompetitionResultsTablePlayersSelector = createSelector(
  currentCompetitionSelector,
  (competition) => getResultsTablePlayersWithIssfIds(competition),
);

export const currentCompetitionPartResultsTablePlayersSelector = createSelector(
  currentCompetitionPartSelector,
  (competition) => getResultsTablePlayersWithIssfIds(competition),
);
