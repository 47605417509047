import config from '../../config';
import AppSettings from '../../library/AppSettings';
import { ILayout } from '../../library/Pages';

const createDefaultLayout = (override: Partial<ILayout> = {}) => {
  return {
    _id: '5f170796be84cc0033092607',
    name: 'Default',
    default: true,
    settings: {
      header: {
        type: 'simple-1',
        config: {
          social: '',
          sectionIdOrUniqId: 'sportnet.sme.sk',
          logo: {
            media_url:
              'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2020/09/logo.svg',
            public_url:
              'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2020/09/logo.svg',
          },
          logoLinkUrl: config.HOMEPAGE_URL,
          textColor: '#333333',
          backgroundColor: '#ffffff',
          borderColor: '#DDDDDD',
          logoSmall: {
            media_url:
              'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2021/01/symbol-v2.svg',
            public_url:
              'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2021/01/symbol-v2.svg',
          },
          activeBorderColor: '#CF2528',
        },
      },
      footer: {
        type: 'simple-1',
        config: {
          sectionIdOrUniqId: 'vseobecne-informacie',
          textColorNav: '#AAAAAA',
          backgroundColorNav: '#2B2B2B',
          textColor: '#AAAAAA',
          backgroundColor: '#2B2B2B',
          textContent1: '© Copyright {{year}}. Športnet, s.r.o.',
          social: 'light',
          logoColored: {
            media_url:
              'https://mediamanager.ws/images/{size}/pages/b/beta.sportnet.sme.sk/2021/03/sportnet-icon-footer-color.svg',
            public_url:
              'https://mediamanager.ws/media/pages/b/beta.sportnet.sme.sk/2021/03/sportnet-icon-footer-color.svg',
          },
          logoGray: {
            media_url:
              'https://mediamanager.ws/images/{size}/pages/b/beta.sportnet.sme.sk/2021/03/sportnet-icon-footer-gray.svg',
            public_url:
              'https://mediamanager.ws/media/pages/b/beta.sportnet.sme.sk/2021/03/sportnet-icon-footer-gray.svg',
          },
        },
      },
      theme: {
        color: {
          primary: '#CF2528',
        },
        app: {
          bgColor: '#ffffff',
          secondaryBgColor: '#333333',
          textColor: '#333',
          secondaryTextColor: '#8D8D8D',
        },
      },
      pageTitle: 'SPORTNET',
      logo: {
        media_url:
          'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2020/09/logo.svg',
        public_url:
          'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2020/09/logo.svg',
      },
      favicon: {
        media_url:
          'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2021/01/symbol-v2.png',
        public_url:
          'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2021/01/symbol-v2.png',
      },
      logoSmall: {
        media_url:
          'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2021/01/symbol-v2.svg',
        public_url:
          'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2021/01/symbol-v2.svg',
      },
    },
    ...override,
  };
};

const appSettings: AppSettings = {
  CSMAppSpace: process.env.REACT_APP_APPSPACE!,
  baseUri: process.env.REACT_APP_HOST!,
  homepageSectionIdOrUniqId: 'sportnet.sme.sk',
  domain: process.env.REACT_APP_BASE_HOSTNAME!,
  layouts: [
    createDefaultLayout(),
    createDefaultLayout({
      _id: '5f6b267544a62a0012e7bcf2',
      default: false,
      name: 'tema',
    }),
    createDefaultLayout({
      _id: '5f6b267544a62a0012e7bcf3',
      default: false,
      name: 'vysledky',
    }),
    createDefaultLayout({
      _id: '5f6b267544a62a0012e7bcf4',
      default: false,
      name: 'mma',
    }),
    createDefaultLayout({
      _id: '5f6b267544a62a0012e7bcf5',
      default: false,
      name: config.EVENT_SECTION_LAYOUT_NAME,
    }),
  ],
  sectionLayoutMap: [
    {
      sectionId: 4807, // Aktualna tema
      layoutId: '5f6b267544a62a0012e7bcf2',
    },
    {
      sectionId: 8118, // Aktualna tema beta
      layoutId: '5f6b267544a62a0012e7bcf2', 
    },
    {
      sectionId: 7512, // Aktualne temy
      layoutId: '5f6b267544a62a0012e7bcf2',
    },
    {
      sectionId: 6142,
      layoutId: '5f6b267544a62a0012e7bcf3',
    },
    {
      sectionId: 6168,
      layoutId: '5f6b267544a62a0012e7bcf3',
    },
    {
      sectionId: 8557,
      layoutId: '5f6b267544a62a0012e7bcf3',
    },
    {
      sectionId: 6183, // Svet MMA na produkcii
      layoutId: '5f6b267544a62a0012e7bcf4',
    },
    {
      sectionId: 6165, // Svet MMA na bete
      layoutId: '5f6b267544a62a0012e7bcf4',
    },
    {
      sectionId: 6390, // MS v hokeji (parent sekcia pre vsetky MS) na produkcii
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 8584, // MS v hokeji na bete
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 9256, // MS vo futbale 2022
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 6565, // ME vo futbale na produkcii
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 6639, // Tour de France na produkcii
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 8698, // Tour de France na bete
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 6731, // OH na produkcii
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 7693, // ZOH 2022, Hokejovy turnaj na produkcii
      layoutId: '5f6b267544a62a0012e7bcf5',
    },
    {
      sectionId: 7727, // ZOH 2022, Hokejovy turnaj na bete
      layoutId: '5f6b267544a62a0012e7bcf5',
    }
  ],
  social: {
    facebook: {
      url: 'https://www.facebook.com/Sportnet.sk/',
      text: 'sportnet.sk',
    },
    instagram: {
      url: 'https://www.instagram.com/sportnet.sk/',
      text: 'sportnet.sk',
    },
    youtube: {
      url: 'https://www.youtube.com/channel/UCJjseHKRhA-ukIqBsgYKXzA',
      text: 'Sportnet',
    },
    twitter: {
      url: 'https://www.twitter.com/sportnet_sk/',
      text: 'sportnet_sk',
    },
    facebookFutbalnet: {
      url: 'https://www.facebook.com/futbalnet/',
      text: 'futbalnet.sk',
    },
  },
};

export default appSettings;
