import { mb } from '@sportnet/ui/lib/Themes/utilities';
import ContentContext, {
  IContentContext,
} from '@sportnet/content/common/ContentContext';
import { IWidget } from '@sportnet/content/utilities/library';
import { getTheme } from '@sportnet/content/theme/theme';
import Content from '@sportnet/content/view';
import { em, rem } from 'polished';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import SDWidgets from '../../containers/StructuredData/SDWidgets';
import { IWidgets } from '../../library/App';
import styled, {
  css,
  IThemeProps,
  withTheme,
} from '../../theme/styled-components';
import ContentContextHelpers from './ContentContextHelpers';
import Overrides from './Overrides';
import SectionListTabsStyleOverrides from './Overrides/Sectionlist/SectionListTabsStyleOverrides';
import renderLink from './renderLink';
import useWidgetAdInjector from './useWidgetAdInjector';
import WidgetsContext, { IWidgetsContext } from './WidgetsContext';

const SpacedContent = styled(Content)`
  position: relative;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(16)};
  font-family: pt-serif-pro, serif;
  line-height: 1.5;
  ${mb('s')} {
    font-size: ${rem(18)};
  }
  img {
    max-width: 100%;
  }
`;

const hOverrides = css`
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  text-align: initial;
`;

const h2OverridesNoSpacing = css`
  ${hOverrides}
  font-size: ${rem(24)};
  line-height: ${rem(28.8)};
`;

const h2Overrides = css`
  ${h2OverridesNoSpacing}
  margin: 0 0 ${rem(32)} 0;
  padding: 0 0 ${rem(16)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

const h3OverridesNoSpacing = css`
  ${hOverrides}
  font-size: ${rem(16)};
  line-height: ${rem(24)};
`;

const h3Overrides = css`
  ${h3OverridesNoSpacing}
  margin: 0 0 ${rem(16)} 0;
`;

const StyleOverrides = styled.div`
  .content-widgets {
    && {
      margin: 0;
    }

    .content-widget {
      margin: ${rem(48)} 0;
      ${mb('s')} {
        margin: ${rem(56)} 0;
      }
    }

    .gossip-title {
      color: rgba(207, 37, 40, 1);
      font-weight: 700;
    }

    .content-text + .content-widget {
      margin-top: 0;
      margin-bottom: ${rem(32)};
    }
    .content-widget-table {
      margin-bottom: 0;
    }
    .content-widget:first-child {
      margin-top: 0;
    }
    .content-widget:last-child {
      margin-bottom: 0;
    }
    .js-advm-provider-dfp.admngr-overflow-hidden {
      margin-bottom: ${em(-27)};
    }
    .content-widget-header {
      ${h3Overrides}
      justify-content: initial;
      h2 {
        ${h3OverridesNoSpacing}
      }
    }
    .content-widget-header-h3 {
      ${h3Overrides}
    }
    .content-text,
    .content-widget-table {
      h2 {
        ${h2Overrides}
      }
      h3 {
        ${h3Overrides}
      }
      h4,
      h5,
      h6 {
        ${hOverrides}
        margin-bottom: ${em(19)};
        ${mb('s')} {
          margin-bottom: ${em(23.7)};
        }
      }

      hr {
        height: 1px;
        border: 0;
        background: #dddddd;
      }

      a {
        color: #1d81c4;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }

      a.button {
        max-width: max-content;
        font-size: ${rem(13)};
        font-family: ${({ theme }) => theme.primaryFont.family};
        color: rgb(51, 51, 51);
        font-weight: 600;
        text-decoration: none;
        outline: none;
        background: none white;
        word-break: break-word;
        line-height: ${rem(16)};
        padding: ${rem(4)} ${rem(8)};
        min-height: ${rem(2)};
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        vertical-align: top;
        box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px;
        border: 1px solid rgb(221, 221, 221);
        border-radius: ${rem(4)};
        cursor: pointer;
      }

      strong {
        color: #000;
        font-weight: bold;
      }

      a strong {
        color: inherit;
      }
    }
    .contet-text {
      p {
        margin-top: ${em(27)};
        margin-bottom: ${em(27)};
      }
    }
    .content-widget-textBlock {
      .content-text > *:first-child {
        margin-top: 0;
      }
      .content-text > *:last-child {
        margin-bottom: 0;
      }
    }

    .widget-multiblock-wrapper {
      margin: ${rem(-16)};
      .widget-multiblock-cellContent {
        margin: ${rem(16)};
      }
    }

    .content-widget-matchOverview-teamLogo {
      border-radius: 50%;
    }

    .content-widget-matchDetail-teamLogo {
      border-radius: 50%;
    }

    .content-widget-matchDetail {
      font-family: ${({ theme }) => theme.primaryFont.family};
    }

    .content-widget-matchOverview {
      font-family: ${({ theme }) => theme.primaryFont.family};
    }

    ${SectionListTabsStyleOverrides}
  }
`;

type IOwnProps = {
  items: IWidgets;
  context?: IContentContext & IWidgetsContext;
  override?: {
    [key: string]: (widget: IWidget) => React.ReactNode;
  };
  className?: string;
};

type IProps = IOwnProps & IThemeProps;

const EventWidgets: React.FC<IProps> = ({
  items,
  context = {},
  theme,
  override = Overrides,
  className,
}) => {
  const contentTheme = React.useMemo(() => {
    return getTheme({
      app: {
        bgColor: theme.app.bgColor,
        maxWidth: 992,
        padding: 0,
        textColor: theme.app.textColor,
      },
      color: {
        separator: theme.color.separator,
      },
    } as any);
  }, [theme]);

  useWidgetAdInjector(items);

  return (
    <ThemeProvider theme={contentTheme}>
      <WidgetsContext.Provider value={context}>
        <ContentContext.Provider
          value={{ ...context, helpers: ContentContextHelpers }}
        >
          <StyleOverrides>
            <SpacedContent
              className={className}
              items={items}
              renderLink={renderLink}
              override={override}
            />
            <SDWidgets items={items} />
          </StyleOverrides>
        </ContentContext.Provider>
      </WidgetsContext.Provider>
    </ThemeProvider>
  );
};

export default withTheme(EventWidgets);
