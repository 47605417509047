import { IResolution } from '../api/FutbalnetApi';

export default (resolution: IResolution): IResolution => ({
  number: resolution.number,
  title: resolution.title,
  text: resolution.text,
  status: resolution.status,
  created_date: resolution.created_date,
  fees: resolution.fees,
  restrictions: resolution.restrictions,
  penalties: resolution.penalties,
});
