import OriginalLoader from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import styled from '../../../../theme/styled-components';

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0
`;

export interface OwnProps {
  className?: string;
  size?: string; 
}

const InputLoading: React.FC<any> = (props: OwnProps) => (
  <StyledLoader className={`input-loader-wrapper ${props.className}`}>
    <OriginalLoader size={props.size} />
  </StyledLoader>
);

export default InputLoading;
