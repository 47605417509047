import { Omit } from './../../../library/App';
import { FeedItem } from './../../../api/FeedsApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../query';
import FeedsApi from '../../../api/FeedsApi';
import config from '../../../config';
import TagmanagerApi, {
  PublicSmartTagResponse,
} from '../../../api/TagmanagerApi';
import getBaseUrl from '../../../utilities/getBaseUrl';

type FeedItemExtended = FeedItem & {
  type: string;
};

export type LabelUrl = {
  label: string;
  url: string;
};

export type FeedItemWithCustomSmarttags = Omit<
  FeedItem,
  'smarttags' | 'appId' | 'appSpace' | 'feedId' | 'createdDate' | 'itemId' | 'discussion'
> & {
  smarttags: LabelUrl[];
  discussionPostCount?: number;
};

interface ListGossipsParams {
  feedId: string;
  offset?: number;
  limit?: number;
}

const getFeedItemsWithBanners = createQueryFn(
  QueryNameSpace.gossips,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string] | [string, ListGossipsParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters?.feedId) {
      throw new Error('Missig required parameter [feedId]');
    }

    const feedId = parameters.feedId;

    const params: Omit<ListGossipsParams, 'feedId'> = {
      offset: pageParam || 0,
      limit: parameters?.limit ?? 12,
    };

    if (parameters) {
      if (parameters.offset || parameters.offset === 0) {
        params.offset = parameters.offset;
      }
    }

    const {
      feeditems = [],
      limit,
      offset,
      nextOffset,
    } = await FeedsApi.getFeedItemsWithBanners(
      config.FEEDS_GOSSIP_APP_SPACE,
      feedId,
      {
        ...params,
        publishedState: 'published',
      },
    );

    let filteredFeedItems = feeditems.filter(
      (item: FeedItemExtended) => item.type === 'feed-item',
    ) as FeedItemExtended[];

    const smarttags = new Set();

    filteredFeedItems.forEach((item) => {
      item?.smarttags?.forEach((smarttag) => {
        smarttag?.values?.forEach((value) => {
          smarttags.add(`${smarttag.key}:${value.key}`);
        });
      });
    });

    const smarttagData = await TagmanagerApi.publicGetSmarttags(
      config.FEEDS_GOSSIP_APP_SPACE,
      {
        smarttags: [...smarttags] as string[],
      },
    );

    const smarttagsMapped: { [key: string]: PublicSmartTagResponse } = {};

    smarttagData?.values?.forEach((tag) => {
      smarttagsMapped[`${tag.key}:${tag.value}`] = tag;
    });

    const feedItemsWithCustomSmarttags: FeedItemWithCustomSmarttags[] =
      filteredFeedItems.map((item) => {
        const newSmarttags: LabelUrl[] = [];

        item.smarttags?.forEach((smarttag) => {
          smarttag.values.forEach((value) => {
            const tag = smarttagsMapped[`${smarttag.key}:${value.key}`];

            newSmarttags.push({
              label: value.key,
              url: tag?.url ?? `${getBaseUrl()}/t/${tag.identifier}`,
            });
          });
        });

        return {
          ...item,
          smarttags: newSmarttags,
          discussionPostCount: item.discussion?.postCount
        };
      });

    return {
      feeditems: feedItemsWithCustomSmarttags,
      limit,
      offset,
      nextOffset,
      total: filteredFeedItems.length,
    };
  },
);

export type { ListGossipsParams };
export default getFeedItemsWithBanners;

interface NumberOfFeedsItemsParams {
  dateFrom?: string;
  feedItemId?: string;
}

const getNumberOfFeedItems = createQueryFn(
  QueryNameSpace.gossipsNewCount,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, NumberOfFeedsItemsParams]>) => {
    const [, parameters] = queryKey;

    const { numberOfFeedItems } = await FeedsApi.getNumberOfFeedItems(
      config.FEEDS_GOSSIP_APP_SPACE,
      config.FEEDS_GOSSIP_ID,
      {
        dateFrom: parameters?.dateFrom,
        feedItemId: parameters?.feedItemId,
      },
    );

    return {
      numberOfFeedItems,
    };
  },
);

export type { NumberOfFeedsItemsParams };
export { getNumberOfFeedItems };
