import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M4,17v-1c0.1-1.7,1.5-3,3.2-3h3.7c1.7,0,3.1,1.3,3.1,3l0,1c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-1
    c0-0.6-0.5-1.1-1.1-1H7.1C6.5,15,6,15.4,6,16l0,1c0,0.6-0.4,1-1,1S4,17.6,4,17z M6,9c0-1.7,1.3-3,3-3s3,1.3,3,3s-1.3,3-3,3
    S6,10.7,6,9z M8,9c0,0.6,0.4,1,1,1s1-0.4,1-1S9.6,8,9,8S8,8.4,8,9z M24,4v16c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2
    h20C23.1,2,24,2.9,24,4z M22,4H2h0l0,16h20V4z M20,7h-5v2h5V7z M17,13h3v-2h-3V13z"
    />
  </svg>
);
