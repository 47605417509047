import { QueryClient } from '@tanstack/react-query';
import { QueryNameSpace } from '../../query';
import {
  SmeUserLibCustomEventError,
  SmeUserLibCustomEventSuccess,
} from '../../library/Petitpress';
import React from 'react';
import appendToDebugCache from '../../utilities/appendToDebugCache';
import getBaseUrl from '../../utilities/getBaseUrl';

const useWaitForSmeUserAndTriggerLogin = (queryClient: QueryClient) => {
  /**
   * Uzivatela mozem prihlasit az po tom co dostaneme info ze je prihlaseny v SME.SK. Sluzi na to
   * event, ktory na zaklade `ppp`, `pps` a `ppi` cookie vyhodnocuje ze je uzivatel prihlaseny.
   */
  React.useEffect(() => {
    const userLoadSuccessHandler = (e: SmeUserLibCustomEventSuccess) => {
      queryClient.setQueryData(['Sme.UserLib.User'], { id: e.detail.id });
      appendToDebugCache(
        queryClient,
        `Sme.UserLib.User.load.success: ${JSON.stringify({
          id: e.detail.id,
        })}`,
      );
    };

    const userLoadErrorHandler = async (e: SmeUserLibCustomEventError) => {
      queryClient.setQueryData(['Sme.UserLib.User'], {
        error: e.detail || 'Sme.UserLib.User.load.error',
      });
      queryClient.removeQueries([QueryNameSpace.user]);
      appendToDebugCache(
        queryClient,
        `Sme.UserLib.User.load.error ${JSON.stringify({
          error: e.detail || 'Sme.UserLib.User.load.error',
        })}`,
      );
      // v pripade chyby overenia uzivatela zo strany Petitpress revokujeme
      // aj Sportnet token
      try {
        await fetch( process.env.NODE_ENV === 'development'
          ? 'https://beta.sportnet.sme.sk/api/logout/?xhr=1'
          : `${getBaseUrl()}/api/logout/?xhr=1`);
      } catch (e) {
        console.log(e);
      }
    };
    document.body.addEventListener(
      'Sme.UserLib.User.load.success',
      userLoadSuccessHandler,
    );

    document.body.addEventListener(
      'Sme.UserLib.User.load.error',
      userLoadErrorHandler,
    );

    return () => {
      document.body.removeEventListener(
        'Sme.UserLib.User.load.success',
        userLoadSuccessHandler,
      );
      document.body.removeEventListener(
        'Sme.UserLib.User.load.error',
        userLoadErrorHandler,
      );
    };
  }, [queryClient]);

  /**
   * FALLBACK: Ak zlyhaju, alebo nezbehnu eventy `Sme.UserLib.User.load.success` alebo
   * `Sme.UserLib.User.load.error`, o 10 sekund nastavime cache, aby sme mohli inicializovat
   * listu pre prihlasenie.
   */
  const smeUserTimerRef = React.useRef<number>();
  React.useEffect(() => {
    const checkSmeUserCache = () => {
      const d = queryClient.getQueryData(['Sme.UserLib.User']);
      if (!d) {
        queryClient.setQueryData(['Sme.UserLib.User'], {
          error: 'Sme.UserLib.User.load.timeout',
        });
        appendToDebugCache(
          queryClient,
          `Sme.UserLib.User.load.timeout ${JSON.stringify({
            error: 'Sme.UserLib.User.load.timeout',
          })}`,
        );
      }
    };
    smeUserTimerRef.current = window.setTimeout(checkSmeUserCache, 10000);
    return () => {
      if (smeUserTimerRef.current) {
        window.clearTimeout(smeUserTimerRef.current);
      }
    };
  }, [queryClient]);
};

export default useWaitForSmeUserAndTriggerLogin;
