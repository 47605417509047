import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { entitiesSelector } from '../App/selectors';

export const searchedMemberSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_SEARCH_MEMBERS),
  (entities, ids) => at(entities.members, ids || []),
);
