import * as React from 'react';
import { Link as RouterLink } from 'react-router';
import { css } from '../../theme/styled-components';
import isFutbalnetUrl from '../../utilities/urls/isFutbalnetUrl';

export const LinkStyles = css`
  color: #1d81c4;
  text-decoration: none;
  :hover,
  :active,
  :focus {
    color: #1d81c4;
    text-decoration: underline;
  }
`;

const Link = (props: any) => {
  const {
    to,
    children,
    blackAndWhite,
    isActive,
    overflow,
    isSideItem,
    isSectionMenuItem,
    ...restProps
  } = props;

  if (!to && to !== '') {
    return <button {...restProps}>{children}</button>;
  }

  if (typeof to === 'string' && !isFutbalnetUrl(to)) {
    return (
      <a href={to} {...restProps}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={to} {...restProps}>
      {children}
    </RouterLink>
  );
};

export default Link;
