export default (
  fontSize: number,
  lineHeight: number,
  numberOfLines: number,
) => {
  return (
    fontSize * lineHeight * numberOfLines -
    (fontSize * lineHeight - fontSize) / 2
  );
};
