import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import Icon from '../../../components/Icon';
import { Td, Th } from '../../../components/Table';
import styled from '../../../theme/styled-components';

export const H2 = styled.h2`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  padding: ${rem(24)} 0;
`;

export const H3 = styled.h3`
  font-size: ${rem(16)};
  font-weight: 600;
  text-transform: uppercase;
  padding: ${rem(24)} 0;
`;

export const MoreIcon = styled(Icon)`
  margin: 0 ${rem(16)} 0 ${rem(16)};
`;

export const FilterButton = styled.button`
  width: 100%;
  cursor: pointer;
  padding: ${rem(8)} 0;
  font-size: ${rem(12)};
  display: flex;
  justify-content: space-between;
  ${mb('m')} {
    border-top: 0;
    border-bottom: 0;
    font-size: ${rem(18)};
    justify-content: flex-start;
  }
  align-items: center;
  background: none;
  border: none;
  outline: none;
  font-weight: normal;
  font-family: ${({ theme }) => theme.primaryFont.family};
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TdRight = styled(Td)`
  text-align: right;
  padding-right: 0;
`;

export const TdCenter = styled(Td)`
  text-align: center;
`;

export const ThRight = styled(Th)`
  text-align: right;
  text-transform: none;
`;

// netransformuje text na uppercase
export const ThNormal = styled(Th)`
  text-transform: none;
`;

export const FilterWrapper = styled.div`
  display: flex;
  padding-bottom: ${rem(16)};
`;

export const H3Centered = styled(H3)`
  text-align: center;
`;

export const Label = styled.div`
  color: #aaaaaa;
  font-size: ${rem(12)};
`;
