import { Picture } from './../api/CmsApi';
export default (picture?: Picture) => {
  return (
    picture || {
      media_url:
        'https://mediamanager.ws/images/{size}/pages/s/sportnet.sme.sk/2021/01/symbol-v2.png',
      public_url:
        'https://mediamanager.ws/media/pages/s/sportnet.sme.sk/2021/01/symbol-v2.png',
    }
  );
};
