import {
  SPORT_SECTOR_EVENTS,
  SPORT_SECTOR_PHASES,
  SPORT_SECTOR_SETTINGS,
} from '../../codelists';
import { ArrayElement } from '../../library/App';
import {
  IMatch,
  IMatchNomination,
  MatchProtocolEvent,
} from '../../library/Competitions';
import {
  MatchDetailPrint,
  MatchDetailPrintTeamNomination,
} from '../../library/Print';
import cleanRoundName from '../cleanRoundName';
import isFutureMatch from '../match/isFutureMatch';
import getMatchesListMatchDataForPrint from './getMatchesListMatchDataForPrint';

interface MatchPlayablePhase {
  _id: string;
  label: string;
  playPhase?: boolean;
  basePhase?: boolean;
  playTimeOrder?: number;
  startDate: string;
  endDate?: string;
  startTime: number;
  endTime?: number;
}

const getMatchPlayablePhases = (
  matchTimer: Required<IMatch>['timer'],
  sportSectorPhaseSettings: ArrayElement<typeof SPORT_SECTOR_PHASES>,
) => {
  const playablePhases = sportSectorPhaseSettings.items
    .filter((phase) => phase.playPhase)
    .sort((a, b) => {
      if (
        typeof a.startTime !== 'undefined' &&
        typeof b.startTime !== 'undefined'
      ) {
        if (a.startTime < b.startTime) {
          return 1;
        } else if (a.startTime > b.startTime) {
          return -1;
        }
        return 0;
      }
      return 0;
    });

  return playablePhases.reduce((acc, phase) => {
    const matchTimerPhase = matchTimer[phase._id];

    if (matchTimerPhase) {
      acc.push({
        _id: phase._id,
        label: phase.label,
        playPhase: phase.playPhase,
        basePhase: phase.basePhase,
        playTimeOrder: phase.playTimeOrder,
        startDate: matchTimerPhase.start.date,
        endDate: matchTimerPhase.end?.date,
        startTime: matchTimerPhase.start.seconds || phase.startTime!,
        endTime: matchTimerPhase.end?.seconds || phase.endTime!,
      });
    }

    return acc;
  }, [] as MatchPlayablePhase[]);
};

const getMatchEvents = (
  events: MatchProtocolEvent[],
  reverseTime: boolean,
  homeTeamId: string,
  awayTeamId: string,
) => {
  const matchEvents = events
    .filter((event) => event.eventTime && event.type !== 'goal_shootout')
    .map((event) => {
      const timeParts = event.eventTime.split(':');
      const seconds = Number(timeParts[0]) * 60 + Number(timeParts[1]);
      return {
        ...event,
        eventTime: seconds,
        homeTeam: event.team === homeTeamId,
        awayTeam: event.team === awayTeamId,
      };
    });
  if (reverseTime) {
    matchEvents.sort((a, b) => {
      if (a.eventTime > b.eventTime) {
        return -1;
      } else if (a.eventTime < b.eventTime) {
        return 1;
      }
      return 0;
    });
  }
  return matchEvents;
};

const getMatchSettings = (
  sportSectorSettings: ArrayElement<typeof SPORT_SECTOR_SETTINGS>,
  matchSettings: IMatch['settings'],
) => {
  const competitionPartSettings = sportSectorSettings.items.find(
    (item) => item._id === 'competitionPartSettings',
  );
  if (competitionPartSettings) {
    return {
      ...competitionPartSettings.value,
      ...(matchSettings ?? {}),
    };
  }
  return null;
};

const getEventsByPhases = (
  matchEvents: ReturnType<typeof getMatchEvents>,
  matchPhases: ReturnType<typeof getMatchPlayablePhases>,
) => {
  const initialAccumulator = matchPhases.reduce((acc, phase) => {
    acc[phase._id] = {
      label: phase.label,
      startDate: phase.startDate,
      startTime: phase.startTime,
      endDate: phase.endDate,
      events: [],
    };
    return acc;
  }, {} as { [phaseId: string]: { label: string; startDate: string; startTime: number; endDate?: string; events: typeof matchEvents } });

  return matchEvents.reduce((acc, matchEvent) => {
    if (acc[matchEvent.phase]) {
      acc[matchEvent.phase].events.push(matchEvent);
    }
    return acc;
  }, initialAccumulator);
};

const getMatchPhasesWithEvents = (
  eventsByPhases: ReturnType<typeof getEventsByPhases>,
  renderFromOldest: boolean,
) => {
  const pahsesWithEvents = Object.keys(eventsByPhases).reduce(
    (acc, phaseId) => {
      if (phaseId !== 'shootout') {
        acc.push({
          ...eventsByPhases[phaseId],
          phaseId,
        });
      }
      return acc;
    },
    [] as {
      phaseId: string;
      label: string;
      startDate: string;
      startTime: number;
      endDate?: string;
      events: ReturnType<typeof getMatchEvents>;
    }[],
  );

  if (renderFromOldest) {
    pahsesWithEvents.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
  } else {
    pahsesWithEvents.sort((a, b) => (a.startTime > b.startTime ? -1 : 1));
  }

  return pahsesWithEvents;
};

const transformMatchNomination = (
  nominations: IMatchNomination,
): MatchDetailPrintTeamNomination => {
  const { athletes: originalAtheletes, crew: originalCrew = [] } = nominations;
  const { athletes, substitutes } = originalAtheletes.reduce(
    (acc, athlete) => {
      const ath = {
        _id: athlete.sportnetUser._id,
        name: athlete.sportnetUser.name,
        nr: String(athlete.additionalData?.nr) ?? '',
        position: athlete.additionalData?.position ?? '',
        substitute: athlete.additionalData?.substitute ?? false,
      };
      if (athlete.additionalData?.substitute) {
        acc.substitutes.push(ath);
      } else {
        acc.athletes.push(ath);
      }
      return acc;
    },
    { athletes: [], substitutes: [] } as Pick<
      MatchDetailPrintTeamNomination,
      'athletes' | 'substitutes'
    >,
  );

  const crew = originalCrew.map((crewMember) => {
    return {
      _id: crewMember.sportnetUser._id,
      name: crewMember.sportnetUser.name,
      position: crewMember.position,
    };
  });

  return {
    athletes,
    substitutes,
    crew,
  };
};

const getTeamNomination = (
  matchNominations: Required<IMatch>['nominations'],
  teamId: string,
) => {
  const nom = matchNominations.find(
    (nomination) => nomination.teamId === teamId,
  );
  if (!nom) {
    return {
      athletes: [],
      substitutes: [],
      crew: [],
    } as MatchDetailPrintTeamNomination;
  }
  return transformMatchNomination(nom);
};

const getMatchManagers = (managers: Required<IMatch>['managers']) => {
  return managers.map((manager) => {
    return {
      _id: manager.user._id,
      name: manager.user.name,
      type: manager.type.value,
    };
  });
};

const getMatchDetailDataForPrint = (
  match: IMatch,
  codelists: {
    sportSectorPhases: ArrayElement<typeof SPORT_SECTOR_PHASES>;
    sportSectorEvent: ArrayElement<typeof SPORT_SECTOR_EVENTS>;
    sportSectorSetings: ArrayElement<typeof SPORT_SECTOR_SETTINGS>;
  },
) => {
  const basicData = getMatchesListMatchDataForPrint(match);

  const competitionName = match.competition.name || '';
  const matchRoundName = match.round?.name || '';
  const roundName = matchRoundName ? cleanRoundName(matchRoundName) : '';
  const seasonName = match.season?.name ?? '';

  const scoreByPhases = Array.isArray(match.scoreByPhases)
    ? match.scoreByPhases.map((phaseScore) => phaseScore.join(':'))
    : undefined;

  const isContumated = Boolean(match.contumation?.isContumated);

  const audience =
    typeof match.protocol?.audience !== 'undefined' &&
    match.protocol?.audience !== null &&
    !isNaN(match.protocol?.audience)
      ? match.protocol?.audience
      : undefined;

  const sportGround = {
    name: match.sportGround?.name ?? '',
    length: (match.sportGround?.additionalData?.length ?? '') as string,
    width: (match.sportGround?.additionalData?.width ?? '') as string,
    totalCapacity: match.sportGround?.additionalData?.totalCapacity ?? '',
    guestCapacity: match.sportGround?.additionalData?.guestCapacity ?? '',
    seatingCapacity: match.sportGround?.additionalData?.seatingCapacity ?? '',
    vipSeatingCapacity:
      match.sportGround?.additionalData?.vipSeatingCapacity ?? '',
    owner: match.sportGround?.owner ?? '',
    address: `${match?.sportGround?.street || ''}${
      match?.sportGround?.number ? ' ' + match?.sportGround?.number : ''
    }, ${match?.sportGround?.zip} ${match?.sportGround?.city}`,
  };

  const sportSector = match.rules
    ?.sport_sector as MatchDetailPrint['sportSector'];

  if (!sportSector) {
    throw new Error('SPORT_SECTOR_IS_NULL');
  }

  const matchPhases = match.timer
    ? getMatchPlayablePhases(match.timer, codelists.sportSectorPhases)
    : [];

  const matchSettings = getMatchSettings(
    codelists.sportSectorSetings,
    match.settings,
  );

  if (!matchSettings) {
    throw new Error(`Could not obtain match settings!`);
  }

  const matchEvents =
    match.protocol && Array.isArray(match.protocol.events)
      ? getMatchEvents(
          match.protocol.events as MatchProtocolEvent[],
          matchSettings?.reverseTime,
          // potrebujeme vediet, ci event patri domacemu alebo hostovskemu timu
          basicData.homeTeam._id,
          basicData.awayTeam._id,
        )
      : [];

  // mapa jednotlivych faz zapasu a eventov,ktore nastali v danej faze
  const eventsByPhases = getEventsByPhases(matchEvents, matchPhases);

  // mapu faz prevedieme do poľa zoradeneho podlačasu faz. toto pole už renderujeme na FE ako
  // detailny priebeh zapasu.
  const phasesWithEvents = getMatchPhasesWithEvents(eventsByPhases, true);

  const homeTeamNomination = getTeamNomination(
    match.nominations ?? [],
    basicData.homeTeam._id,
  );
  const awayTeamNomination = getTeamNomination(
    match.nominations ?? [],
    basicData.awayTeam._id,
  );

  const managers = getMatchManagers(match.managers ?? []);

  return {
    ...basicData,
    scoreByPhases,
    sportSector,
    competition: {
      competitionName,
      roundName,
      seasonName,
    },
    isContumated,
    audience,
    sportGround,
    phasesWithEvents,
    homeTeamNomination,
    awayTeamNomination,
    managers,
    // ma zmysel zobrazovat prehlad zapasu, ak zapas prebieha alebo je ukonceny, tiez
    // ak niektory z timov neodstupil alebo nebol zpas kontumovany
    shouldRenderMatchOverview:
      !isFutureMatch(match) &&
      !basicData.homeTeam.resignation &&
      !basicData.awayTeam.resignation &&
      !isContumated,
    isFutureMatch: isFutureMatch(match),
  };
};

export default getMatchDetailDataForPrint;
