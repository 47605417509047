import Image from '@sportnet/ui/lib/Image';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import React from 'react';
import Masonry from 'react-masonry-css';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { getProp, removeAccents } from 'sportnet-utilities';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import FutbalnetTabber from '../../components/FutbalnetTabber';
import { RootState } from '../../configureStore';
import { ICompetition } from '../../library/Competitions';
import {
  competitionsSelector,
  unionsStructureSelector,
} from '../../pages/FutbalnetApp/selectors';
import styled, { css } from '../../theme/styled-components';
import getCompetitionBaseUrlByShortName from '../../utilities/getCompetitionBaseUrlByShortName';
import { categories } from '../../utilities/getCompetitionsCategoryPanes';
import getFallbackClubOrUnionLogo from '../../utilities/getFallbackClubOrUnionLogo';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import getUnionBaseUrlByShortName from '../../utilities/getUnionBaseUrlByShortName';
import getUrlFromAppSpace from '../../utilities/getUrlFromAppSpace';

const PositionedTabber = styled(FutbalnetTabber)`
  margin: 0.5rem 1rem 1rem 1rem;
`;

const Wrapper = styled.div`
  padding: ${rem(16)} 0 0 0;
  margin: 0.5rem 1rem 1rem 1rem;
  a:hover {
    text-decoration: underline;
  }
`;

const UnionLabel = styled.div`
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  font-size: ${rem(14)};
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  img {
    height: ${rem(24)};
    width: auto;
    flex-shrink: 0;
    margin-right: ${rem(8)};
  }
`;

const SubUnionsLabel = styled.div`
  color: ${({ theme }) => theme.textColor};
  opacity: 0.56;
  font-weight: bold;
  font-size: ${rem(11)};
  letter-spacing: ${rem(0.88)};
  text-transform: uppercase;
`;

const SubUnionImg = styled.img`
  width: ${rem(16)};
  height: auto;
  flex-shrink: 0;
  margin-right: ${rem(8)};
`;

const GenderCategoryLabel = styled.div`
  margin: ${rem(24)} 0 ${rem(16)} 0;
  font-size: ${rem(12)};
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  opacity: 0.56;
`;

const SearchResult = styled.div`
  margin: 0rem 1rem;
  > div:first-child > ${GenderCategoryLabel}:first-child {
    margin-top: 0;
  }
`;

const FilteredCompetitions = styled.div`
  margin-bottom: ${rem(8)};
`;

const FilteredCompetitionItemName = styled.div`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.textColor};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FilteredCompetitionItem = styled.div`
  margin: ${rem(8)} 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  > a {
    display: flex;
    height: 100%;
    align-items: center;
    margin-bottom: ${rem(8)};
  }
  a:hover > div {
    color: #cf2528;
    text-decoration: underline;
  }
  a:hover > span {
    text-decoration: underline;
  }
  ${mb('m')} {
    align-items: stretch;
    > a {
      margin-bottom: 0;
    }
  }
  > a:nth-child(2) > *:first-child {
    height: ${rem(16)};
    width: auto;
    margin-left: 0;
    margin-right: ${rem(8)};
    ${mb('m')} {
      margin-left: ${rem(16)};
    }
  }
  > a > span {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.textColor};
    opacity: 0.56;
  }
`;

const FilteredCompetitionItemDetail = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  > a {
    display: flex;
    height: 100%;
    align-items: center;
    margin-bottom: ${rem(4)};
  }
  ${mb('m')} {
    flex-direction: row;
    > a {
      margin-bottom: 0;
    }
  }
  > a:nth-child(2) > *:first-child {
    height: ${rem(16)};
    width: auto;
    margin-left: 0;
    margin-right: ${rem(8)};
    ${mb('m')} {
      margin-left: ${rem(16)};
    }
  }
  > a > span {
    font-size: ${rem(14)};
    color: ${({ theme }) => theme.textColor};
    opacity: 0.56;
  }
`;

const MobileOwnerImageWrapper = styled.div`
  > * {
    height: ${rem(16)};
    width: auto;
    margin-left: 0;
    margin-right: ${rem(12)};
  }
  ${mb('m')} {
    display: none;
  }
`;
const DesktopOwnerImage = styled(Image)`
  display: none;
  ${mb('m')} {
    display: block;
  }
`;

const More = styled.div<{ shown?: boolean }>`
  cursor: pointer;
  color: ${({ theme }) => theme.textColor};
  text-decoration: underline;
  margin: ${rem(8)} 0;
  > div {
    display: flex;
    align-items: center;
    font-size: ${rem(14)};
  }
  ${({ shown }) =>
    !shown &&
    css`
      display: none;
    `}
`;

const Competitions = styled.div`
  margin: ${rem(8)} 0;
  display: flex;
  flex-direction: column;
  a {
    color: #333;
    margin: ${rem(8)} 0;
    :hover {
      color: ${({ theme }) => theme.color.primary};
    }
    > div {
      display: flex;
      align-items: center;
      font-size: ${rem(14)};
      .icon {
        margin-right: ${rem(8)};
      }
    }
  }
`;

const RegionalUnions = styled.div<{ topGutter: boolean }>`
  ${({ topGutter }) =>
    topGutter &&
    css`
      margin-top: ${rem(24)};
    `}
`;

type IOwnProps = {
  union?: string;
  active?: boolean;
  q?: string;
  omitCompetitions?: boolean;
  recalculateHeight: () => void;
};

const mapStateToProps = (state: RootState, props: IOwnProps) => ({
  competitions: competitionsSelector(state),
  unions: unionsStructureSelector(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const tabs = [
  {
    value: 'M',
    label: <>{__('Muži')}</>,
  },
  {
    value: 'F',
    label: __('Ženy'),
  },
  {
    value: 'U',
    label: __('Mládež'),
  },
] as const;
type ITab = typeof tabs[number]['value'];

const TopCompetitionsList: React.FC<IProps> = ({
  competitions,
  unions,
  recalculateHeight,
  omitCompetitions,
  q = '',
  union = '',
  active = false,
}) => {
  const [activeTab, setActiveTab] = React.useState<ITab>('M');

  const [visibleUnions, setVisibleUnions] = React.useState<{
    [key: string]: boolean;
  }>({});

  React.useEffect(() => {
    if (!active) {
      setActiveTab('M');
    }
  }, [active]);

  React.useEffect(() => {
    setVisibleUnions({});
  }, [activeTab]);

  React.useEffect(() => {
    recalculateHeight();
  }, [visibleUnions, recalculateHeight]);

  const filterCompetitionsByGender = (items: ICompetition[]) => {
    return items.filter((competition) => {
      const competitionRules = getProp(competition, ['parts', 0, 'rules']);
      if (!competitionRules) {
        return false;
      }
      if (
        activeTab === competitionRules.gender &&
        competitionRules.category === 'ADULTS'
      ) {
        return true;
      } else if (activeTab === 'U' && competitionRules.category !== 'ADULTS') {
        return true;
      }
      return false;
    });
  };

  const flattedUnions = unions.reduce((acc, u) => {
    return [...acc, u, ...(u.subs || [])];
  }, []);

  const genderFilteredCompetitions = filterCompetitionsByGender(
    competitions as ICompetition[],
  );

  let filteredCompetitions = (
    omitCompetitions || q ? competitions : genderFilteredCompetitions
  ).map((i) => {
    if (
      i.name.toLowerCase().includes('.liga') &&
      !i.name.toLowerCase().includes(' .liga')
    ) {
      const modifiedCompetitionName = i.name
        .replace('1.', '1. ')
        .replace('2.', '2. ')
        .replace('3.', '3. ')
        .replace('4.', '4. ')
        .replace('5.', '5. ')
        .replace('6.', '6. ')
        .replace('7.', '7. ')
        .replace('8.', '8. ')
        .replace('I.', 'I. ')
        .replace('V.', 'V. ')
        .replace('X.', 'X. ');
      return { ...i, name: modifiedCompetitionName };
    }
    return i;
  });

  if (q) {
    filteredCompetitions = filteredCompetitions.filter((c: ICompetition) => {
      const regex = new RegExp(removeAccents(q.toLowerCase()), 'i');
      if (removeAccents(c.name.toLowerCase()).match(regex)) {
        return true;
      }
      return false;
    });
  }

  let displayedUnions = [...unions];
  let nonFilteredUnionCompetitions: Readonly<ICompetition[]> = [];

  if (union) {
    const unionData = flattedUnions.find((u) => u._id === union);
    if (unionData) {
      const unionSubsIds = (unionData.subs || []).map((u) => u._id);
      filteredCompetitions = filteredCompetitions.filter((c: ICompetition) => {
        return c.appSpace === union || unionSubsIds.includes(c.appSpace);
      });
      nonFilteredUnionCompetitions = competitions.filter((c: ICompetition) => {
        return c.appSpace === union || unionSubsIds.includes(c.appSpace);
      });
      displayedUnions = [unionData, ...(unionData.subs || [])];
    }
  } else {
    nonFilteredUnionCompetitions = competitions;
  }

  const reduceCompetitionsByUnion = (
    items: OrganizationProfileStructureItem[],
  ) => {
    return items.reduce(
      (
        acc: Array<{
          union: OrganizationProfileStructureItem;
          competitions: ICompetition[];
        }>,
        union,
      ) => {
        const unionCompetitions = filteredCompetitions.filter(
          (competition: ICompetition) => competition.appSpace === union._id,
        );
        let filteredSubs = union.subs || [];
        if (
          q &&
          genderFilteredCompetitions.filter(
            (competition: ICompetition) => competition.appSpace === union._id,
          ).length
        ) {
          filteredSubs = filteredSubs.filter((sub) => {
            const regex = new RegExp(removeAccents(q).toLowerCase(), 'i');
            if (removeAccents(sub.name.toLowerCase()).match(regex)) {
              return true;
            }
            return false;
          });
          acc.push({
            union: {
              ...union,
              subs: filteredSubs.filter((i) => {
                const subUnionCompetitions = filteredCompetitions.filter(
                  (competition: ICompetition) => competition.appSpace === i._id,
                );
                return !!subUnionCompetitions.length;
              }),
            },
            competitions: unionCompetitions as ICompetition[],
          });
        } else if (unionCompetitions.length || omitCompetitions) {
          acc.push({
            union: {
              ...union,
              subs: filteredSubs.filter((i) => {
                const subUnionCompetitions = filteredCompetitions.filter(
                  (competition: ICompetition) => competition.appSpace === i._id,
                );
                return !!subUnionCompetitions.length || omitCompetitions;
              }),
            },
            competitions: unionCompetitions as ICompetition[],
          });
        }
        return acc;
      },
      [],
    );
  };

  const renderFilteredCompetitions = (items: ICompetition[]) => {
    const competitionsByGender: { [key: string]: ICompetition[] } = {
      M: [],
      F: [],
      U: [],
    };
    items.forEach((competition) => {
      const competitionRules = getProp(competition, ['parts', 0, 'rules']);
      if (competitionRules) {
        if (competitionRules.category === 'ADULTS') {
          competitionsByGender[competitionRules.gender].push(competition);
        } else if (competitionRules.category !== 'ADULTS') {
          competitionsByGender['U'].push(competition);
        }
      }
    });

    return Object.keys(competitionsByGender).map((gender) => {
      const genderCategory = categories.find((c) => c._id === gender);
      if (!competitionsByGender[gender].length) {
        return null;
      }
      return (
        <div key={`g-${gender}`}>
          <GenderCategoryLabel>
            {!!genderCategory ? genderCategory.name : ''}
          </GenderCategoryLabel>
          <FilteredCompetitions>
            {competitionsByGender[gender].map((item) => {
              const owner = flattedUnions.find((u) => u._id === item.appSpace);
              if (q) {
                const regex = new RegExp(removeAccents(q.toLowerCase()), 'i');
                const match = removeAccents(item.name.toLowerCase()).match(
                  regex,
                );

                if (match && match.length) {
                  const aStr = item.name.substr(0, match.index);
                  const bStr = item.name.substr(match.index || 0, q.length);
                  const cStr = item.name.substr((match.index || 0) + q.length);
                  const appSpaceShortName =
                    getUrlFromAppSpace('union', item.appSpace) || '';
                  const competitionUrl = getCompetitionBaseUrlByShortName(
                    appSpaceShortName,
                    item.__issfId || item.competitionGroupId,
                  );
                  const unionUrl =
                    getUnionBaseUrlByShortName(appSpaceShortName);

                  return (
                    <FilteredCompetitionItem key={item._id}>
                      <MobileOwnerImageWrapper>
                        <Image
                          alt={owner ? owner.name : item.name}
                          src={
                            owner
                              ? owner.logo_public_url || getFallbackClubOrUnionLogo()
                              : getFallbackClubOrUnionLogo()
                          }
                        />
                      </MobileOwnerImageWrapper>
                      <FilteredCompetitionItemDetail>
                        <Link to={competitionUrl}>
                          <FilteredCompetitionItemName>
                            <span>{aStr}</span>
                            <b>{bStr}</b>
                            <span>{cStr}</span>
                          </FilteredCompetitionItemName>
                        </Link>
                        <Link to={unionUrl}>
                          <DesktopOwnerImage
                            alt={owner ? owner.name : item.name}
                            src={
                              owner
                                ? owner.logo_public_url || getFallbackClubOrUnionLogo()
                                : getFallbackClubOrUnionLogo()
                            }
                          />
                          {!!owner && <span>{owner.name}</span>}
                        </Link>
                      </FilteredCompetitionItemDetail>
                    </FilteredCompetitionItem>
                  );
                }
              } else if (union) {
                const appSpaceShortName =
                  getUrlFromAppSpace('union', item.appSpace) || '';
                const competitionUrl = getCompetitionBaseUrlByShortName(
                  appSpaceShortName,
                  item.__issfId || item.competitionGroupId,
                );
                return (
                  <FilteredCompetitionItem key={item._id}>
                    <Link to={competitionUrl}>
                      <FilteredCompetitionItemName>
                        {item.name}
                      </FilteredCompetitionItemName>
                    </Link>
                  </FilteredCompetitionItem>
                );
              }
              return null;
            })}
          </FilteredCompetitions>
        </div>
      );
    });
  };

  const renderUnions = (
    items: Array<{
      union: OrganizationProfileStructureItem;
      competitions: ICompetition[];
    }>,
  ) => {
    const breakpoints = {
      default: 4,
      768: 2,
      600: 1,
    };
    return (
      <Masonry
        breakpointCols={breakpoints}
        className="unions-masonry-grid"
        columnClassName="unions-masonry-grid_column"
      >
        {items.map((item) => {
          const id = `${activeTab}:${item.union._id}`;
          const slicedCompetitions = visibleUnions[id]
            ? item.competitions
            : item.competitions.slice(0, 5);
          if (
            !item.competitions.length &&
            !(item.union.subs || []).length &&
            !omitCompetitions
          ) {
            return null;
          }
          const unionLabel = (
            <UnionLabel>
              <img
                alt={item.union.name}
                src={item.union.logo_public_url || getFallbackClubOrUnionLogo()}
              />
              <div>{item.union.name}</div>
            </UnionLabel>
          );

          return (
            <div>
              {!union || item.union._id !== union ? (
                <Link to={getUnionBaseUrl({ zvaz: item.union._id })}>
                  {unionLabel}
                </Link>
              ) : (
                unionLabel
              )}
              <Competitions>
                {!omitCompetitions &&
                  slicedCompetitions.map((competition) => {
                    const appSpaceShortName =
                      getUrlFromAppSpace('union', competition.appSpace) || '';
                    const url = getCompetitionBaseUrlByShortName(
                      appSpaceShortName,
                      competition.__issfId || competition.competitionGroupId,
                    );
                    return (
                      <Link key={competition._id} to={url}>
                        <div>
                          <span>{competition.name}</span>
                        </div>
                      </Link>
                    );
                  })}
                <More
                  shown={
                    !omitCompetitions &&
                    !visibleUnions[id] &&
                    item.competitions.length > 5
                  }
                >
                  <div>
                    <span
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setVisibleUnions((prev) => ({
                          ...prev,
                          [id]: true,
                        }));
                      }}
                    >
                      {__('Viac')}
                    </span>
                  </div>
                </More>
                {!union && (item.union.subs || []).length > 0 && (
                  <RegionalUnions topGutter={!omitCompetitions}>
                    {!omitCompetitions && (
                      <SubUnionsLabel>
                        <div>{__('Oblastné súťaže')}</div>
                      </SubUnionsLabel>
                    )}
                    <Competitions>
                      {item.union.subs?.map((unionSub) => (
                        <Link
                          key={unionSub._id}
                          to={getUnionBaseUrl({ zvaz: unionSub._id })}
                        >
                          <div>
                            <SubUnionImg
                              src={
                                unionSub.logo_public_url || getFallbackClubOrUnionLogo()
                              }
                            />
                            <span>{unionSub.name}</span>
                          </div>
                        </Link>
                      ))}
                    </Competitions>
                  </RegionalUnions>
                )}
              </Competitions>
            </div>
          );
        })}
      </Masonry>
    );
  };

  const firstLineUnions = [
    'futbalsfz.sk',
    'ulk.futbalnet.sk',
    'futsalslovakia.sk',
    'issf_union_51',
  ];

  let firstRowCompetitionsByUnion = reduceCompetitionsByUnion(
    displayedUnions.filter((u) => firstLineUnions.includes(u._id)),
  );
  let secondRowCompetitionsByUnion = reduceCompetitionsByUnion(
    displayedUnions.filter((u) => !firstLineUnions.includes(u._id)),
  ).sort((a, b) => {
    if (a.union._id === 'BFZ') {
      return -1;
    } else if (a.union._id === 'ZsFZ' && b.union._id !== 'BFZ') {
      return -1;
    } else if (
      a.union._id === 'SsFZ' &&
      !['BFZ', 'ZsFZ'].includes(b.union._id)
    ) {
      return -1;
    } else if (
      a.union._id === 'VsFZ' &&
      !['BFZ', 'ZsFZ', 'SsFZ'].includes(b.union._id)
    ) {
      return -1;
    }
    return 0;
  });

  if (union) {
    firstRowCompetitionsByUnion = reduceCompetitionsByUnion(displayedUnions);
    secondRowCompetitionsByUnion = [];
  }

  if (q) {
    return (
      <SearchResult>
        {renderFilteredCompetitions(filteredCompetitions)}
      </SearchResult>
    );
  }

  const unionCompetitionParts = nonFilteredUnionCompetitions.reduce(
    (acc, i) => {
      return [...acc, ...(i.parts || [])];
    },
    [],
  );

  const tabber = (
    <PositionedTabber
      size="s"
      onChange={(tab: { value: ITab }) => {
        setActiveTab(tab.value);
      }}
      active={activeTab}
      tabs={tabs.filter((i) => {
        if (i.value === 'M') {
          const hasMalePart = unionCompetitionParts.find(
            (p) => p.rules?.gender === 'M' && p.rules?.category === 'ADULTS',
          );
          return !!hasMalePart;
        } else if (i.value === 'F') {
          const hasFemalePart = unionCompetitionParts.find(
            (p) => p.rules?.gender === 'F' && p.rules?.category === 'ADULTS',
          );
          return !!hasFemalePart;
        } else if (i.value === 'U') {
          const hasUPart = unionCompetitionParts.find(
            (p) => p.rules?.category !== 'ADULTS',
          );
          return !!hasUPart;
        }
        return false;
      })}
    />
  );

  return (
    <>
      {!!!omitCompetitions && q.length < 3 && tabber}
      <Wrapper>
        {renderUnions([
          ...firstRowCompetitionsByUnion,
          ...secondRowCompetitionsByUnion,
        ])}
        <div />
      </Wrapper>
    </>
  );
};

export default connect(mapStateToProps)(TopCompetitionsList);
