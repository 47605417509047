import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(32)};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 ${rem(16)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 0 ${rem(16)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.separator};
  flex: 1 0 0;
`;

type IOwnProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  borderBottom?: boolean;
  marginBottom?: number;
  className?: string;
};

type IProps = IOwnProps;

const SectionBlockHeaderContainer: React.FC<IProps> = ({
  left = null,
  right = null,
  borderBottom = false,
  marginBottom = 32,
  className
}) => {
  return (
    <Wrapper marginBottom={marginBottom} className={className}>
      <Left borderBottom={borderBottom}>{left}</Left>
      <Right borderBottom={borderBottom}>{right}</Right>
    </Wrapper>
  );
};

export default SectionBlockHeaderContainer;
