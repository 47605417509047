import { rem } from 'polished';
import styled from 'styled-components';

export const StyledCalendarWrapper = styled.div`
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.primaryFont.family};
    font-size: 12px;
    color: ${({ theme }) => theme.color.primaryFontColor};
    overflow: hidden;
    display: inline-block;
    position: relative;
    border: none;
  }

  .react-datepicker__header {
    text-align: center;
    position: relative;
    background-color: white;
    border: none;
    padding-top: 4px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    margin-top: 0;
    color: #333333;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 0;
    border: none;
  }

  .react-datepicker__current-month::first-letter {
    text-transform: capitalize;
  }

  .react-datepicker__navigation {
    background: none;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: -2px;
    padding: 0;
    z-index: 1;
    text-indent: -999em;
    background-size: cover;
    border: none;
    outline: none;
    display: block;
    width: 32px;
    height: 32px;
    &:active,
    &:focus {
      outline: transparent;
    }
  }

  .react-datepicker__navigation--previous {
    left: 0;
    background-image: url('/icons/arrow-left-small.svg');
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
  }

  .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
    cursor: default;
  }

  .react-datepicker__navigation--next {
    right: 0;
    background-image: url('/icons/arrow-right-small.svg');
  }

  .react-datepicker__navigation--next--with-time:not(
      .react-datepicker__navigation--next--with-today-button
    ) {
    right: 95px;
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
  }

  .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
  }

  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }

  .react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
  }

  .react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }

  .react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
  }

  .react-datepicker__month-container {
    float: left;
  }

  .react-datepicker__year {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__year-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 180px;
  }

  .react-datepicker__year .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }

  .react-datepicker__month {
    text-align: center;
    margin: 0;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }

  .react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
  }

  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
  }

  .react-datepicker__day-names {
    padding-top: 32px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.primaryFontColor};
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    white-space: nowrap;
  }

  .react-datepicker__day-name {
    display: inline-block;
    width: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #f1f1f1;
    border-radius: 999px;
  }

  .react-datepicker__day,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-name {
    color: #333333;
    display: inline-block;
    width: 32px;
    line-height: 32px;
    text-align: center;
    transition: backround 250ms ease-in-out;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    border-radius: 999px;
    background-color: #216ba5;
    color: ${({ theme }) => theme.color.white};
    outline: none;
  }

  .react-datepicker__day {
    margin: 0;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 999px;
    background-color: #f1f1f1;
    cursor: pointer;
    outline: none;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    font-weight: bold;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 999px;
    background-color: ${({ theme }) => theme.color.primaryColor};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    outline: none;
    border: none;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border-radius: 999px;
    background-color: ${({ theme }) => theme.color.primaryColor};
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    outline: none;
    border: none;
  }

  .react-datepicker__day--disabled {
    color: #aaaaaa;
    cursor: auto;
  }

  .react-datepicker__day--disabled:hover,
  .react-datepicker__month-text--disabled:hover,
  .react-datepicker__quarter-text--disabled:hover,
  .react-datepicker__year-text--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__day--disabled:active,
  .react-datepicker__day--disabled:hover,
  .react-datepicker__day--disabled:focus {
    color: #aaa;
    font-weight: normal;
    background-color: none;
    cursor: auto;
    outline: none;
    border: none;
  }

  .react-datepicker__year-dropdown:hover,
  .react-datepicker__year-read-view:hover {
    cursor: pointer;
  }

  .react-datepicker__year-read-view {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__current-month--hasYearDropdown {
    margin-bottom: 16px;
  }

  .react-datepicker__year-read-view--down-arrow {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url('/icons/arrow-right-small.svg');
    transform: rotate(90deg);
    margin-right: 4px;
    border: none;
    top: 3px;
  }

  .react-datepicker__year-dropdown {
    margin-top: 16px;
  }

  .react-datepicker__navigation--years-upcoming {
    background-image: url('/icons/chevron-left.svg');
    transform: rotate(90deg);
  }

  .react-datepicker__navigation--years-previous {
    background-image: url('/icons/arrow-right-small.svg');
    transform: rotate(90deg);
  }

  .react-datepicker__aria-live {
    display: none;
  }
`;

export const DatePickerWrapper = styled(StyledCalendarWrapper)`
  .react-datepicker-popper {
    z-index: 1;
  }
  .react-datepicker {
    padding-top: 0;
    font-size: ${rem(14)};
    line-height: ${rem(16.8)};
    max-width: ${rem(288)};
    width: 100%;
    padding: ${rem(16)} ${rem(16)};
    overflow-x: auto;
    box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
    border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
    border: 1px solid ${({ theme }) => theme.color.darkBorder};
  }

  .react-datepicker__input-container {
    button.react-datepicker__close-icon {
      cursor: pointer;
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 0px 6px 0px 0px;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }
  }

  .react-datepicker__navigation--previous {
    left: ${rem(16)};
    top: ${rem(24)};
    background-image: url('/icons/chevron-left.svg');
  }

  .react-datepicker__navigation--next {
    right: ${rem(16)};
    top: ${rem(24)};
    background-image: url('/icons/chevron-right.svg');
  }

  .react-datepicker__year-select {
    background-color: white;
    border: none;
  }

  .react-datepicker__day {
    cursor: pointer;
  }
`;
