import { darken, rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import styled from '../../theme/styled-components';
import Icon from '../Icon';

const Wrapper = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${rem(48)};
  padding: ${rem(12)};
  cursor: pointer;
  margin: ${rem(32)} 0 0 0;
  text-decoration: underline;
  color: ${({ theme }) => theme.app.textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  border-radius: ${rem(4)};
  outline: none;
  border: none;
  background: #f1f1f1;
  &:hover {
    background: ${darken(0.05, '#f1f1f1')};
  }
  &:focus {
    background: ${darken(0.1, '#f1f1f1')};
  }
  &:active {
    background: ${darken(0.2, '#f1f1f1')};
  }
`;

const Text = styled.span`
  padding-right: ${rem(8)};
`;

interface IOwnProps {
  to: string;
  text: string;
  className?: string;
}

type IProps = IOwnProps;

const ShowMoreButton: React.FC<IProps> = ({ to, text, className }) => {
  return (
    <Wrapper to={to} className={className}>
      <Text>{text}</Text>
      <Icon name="arrowMore" size={16} />
    </Wrapper>
  );
};

export default ShowMoreButton;
