import getProp from "sportnet-utilities/lib/getProp";
import { SortersEnum } from "../../library/CompetitionStanding";

export const resultsTableSorters: SortersEnum = {
  '60b5f4089fadc656fc775ef0': (
    a,
    b,
    nextSorters = [],
    baseObjectName = 'stats',
  ) => {
    if (
      a.team._id === '60c38c3e9913cbe1ef623087' &&
      b.team._id === '60c89ae2cba9157034129f8c'
    ) {
      return -1;
    } else if (
      a.team._id === '60c89ae2cba9157034129f8c' &&
      b.team._id === '60c38c3e9913cbe1ef623087'
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  resignation: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (a.team.resignation && !b.team.resignation) {
      return 1;
    } else if (!a.team.resignation && b.team.resignation) {
      return -1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  points: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      getProp(a, [baseObjectName, 'sortPoints']) >
      getProp(b, [baseObjectName, 'sortPoints'])
    ) {
      return -1;
    } else if (
      getProp(a, [baseObjectName, 'sortPoints']) <
      getProp(b, [baseObjectName, 'sortPoints'])
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  goalDifference: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    const aGoalsDifference =
      getProp(a, [baseObjectName, 'sortGoalsGiven'], 0) -
      getProp(a, [baseObjectName, 'sortGoalsReceived'], 0);
    const bGoalsDifference =
      getProp(b, [baseObjectName, 'sortGoalsGiven'], 0) -
      getProp(b, [baseObjectName, 'sortGoalsReceived'], 0);
    if (aGoalsDifference > bGoalsDifference) {
      return -1;
    } else if (aGoalsDifference < bGoalsDifference) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  goalsGiven: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      getProp(a, [baseObjectName, 'sortGoalsGiven'], 0) >
      getProp(b, [baseObjectName, 'sortGoalsGiven'], 0)
    ) {
      return -1;
    } else if (
      getProp(a, [baseObjectName, 'sortGoalsGiven'], 0) <
      getProp(b, [baseObjectName, 'sortGoalsGiven'], 0)
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  wins: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      getProp(a, [baseObjectName, 'matches', 'won'], 0) >
      getProp(b, [baseObjectName, 'matches', 'won'], 0)
    ) {
      return -1;
    } else if (
      getProp(a, [baseObjectName, 'matches', 'won'], 0) <
      getProp(b, [baseObjectName, 'matches', 'won'], 0)
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  draws: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      getProp(a, [baseObjectName, 'matches', 'draw'], 0) >
      getProp(b, [baseObjectName, 'matches', 'draw'], 0)
    ) {
      return -1;
    } else if (
      getProp(a, [baseObjectName, 'matches', 'draw'], 0) <
      getProp(b, [baseObjectName, 'matches', 'draw'], 0)
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  awayWins: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      a.awayStats &&
      b.awayStats &&
      a.awayStats.matches.won > b.awayStats.matches.won
    ) {
      return -1;
    } else if (
      a.awayStats &&
      b.awayStats &&
      a.awayStats.matches.won < b.awayStats.matches.won
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  awayDraws: (a, b, nextSorters = [], baseObjectName = 'stats') => {
    if (
      a.awayStats &&
      b.awayStats &&
      a.awayStats.matches.draw > b.awayStats.matches.draw
    ) {
      return -1;
    } else if (
      a.awayStats &&
      b.awayStats &&
      a.awayStats.matches.draw < b.awayStats.matches.draw
    ) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  commonMatchesPoints: (a, b, nextSorters = [], baseObjectName) => {
    const commonMatches = (a.matches || []).filter(
      (m) => m.team._id === b.team._id,
    );
    const aWins = commonMatches.filter((match) => match.result === 'W');
    const bWins = commonMatches.filter((match) => match.result === 'L');
    const draws = commonMatches.filter((match) => match.result === 'D');

    const aPoints = aWins.length * 3 + draws.length;
    const bPoints = bWins.length * 3 + draws.length;

    if (aPoints > bPoints) {
      return -1;
    } else if (aPoints < bPoints) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  commonMatchesGoalDifference: (a, b, nextSorters = [], baseObjectName) => {
    const commonMatches = (a.matches || []).filter(
      (m) => m.team._id === b.team._id,
    );
    let aGoals = 0;
    let bGoals = 0;
    commonMatches.forEach((match) => {
      const [firstScore, secondScore] = match.score.split(':');
      if (match.homeaway === 'home') {
        aGoals = aGoals + Number(firstScore);
        bGoals = bGoals + Number(secondScore);
      } else {
        aGoals = aGoals + Number(secondScore);
        bGoals = bGoals + Number(firstScore);
      }
    });
    if (commonMatches.length > 1 && aGoals > bGoals) {
      return -1;
    } else if (commonMatches.length > 1 && aGoals < bGoals) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
  commonMatchesAwayGoals: (a, b, nextSorters = [], baseObjectName) => {
    let aAwayGoals = 0;
    let bAwayGoals = 0;
    const commonMatches = (a.matches || []).filter(
      (m) => m.team._id === b.team._id,
    );
    commonMatches.forEach((match) => {
      const [, secondScore] = match.score.split(':');
      if (match.homeaway === 'home') {
        bAwayGoals = bAwayGoals + Number(secondScore);
      } else {
        aAwayGoals = aAwayGoals + Number(secondScore);
      }
    });
    if (aAwayGoals > bAwayGoals) {
      return -1;
    } else if (aAwayGoals < bAwayGoals) {
      return 1;
    }
    if ((nextSorters || []).length) {
      const [nextSorter, ...restSorters] = nextSorters;
      return nextSorter(a, b, restSorters, baseObjectName);
    }
    return 0;
  },
};

export default resultsTableSorters;