import { __ } from '@sportnet/content/utilities/utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { debounce } from '@sportnet/ui/lib/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import CoreApi from '../../api/CoreApi';
import SearchInput from '../../components/SearchInput';
import config from '../../config';
import styled from '../../theme/styled-components';
import getClubBaseUrl from '../../utilities/getClubBaseUrl';

const Wrapper = styled.div`
  background: #fff;
  border-radius: 0px 0px ${rem(4)} ${rem(4)};
`;

const ListItem = styled.li`
  font-size: ${rem(12)};
  a {
    display: flex;
    padding: ${rem(12)} ${rem(16)};
    height: 100%;
    width: 100%;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  max-height: ${rem(265)};
  overflow: auto;
  margin: ${rem(24)} -${rem(24)} 0 -${rem(24)};
  ${mb('m')} {
    margin: ${rem(24)} 0 0 0;
  }
  > li:nth-child(odd) {
    background: #f9f9f9;
  }
`;

interface OwnProps {
  className?: string;
  clubLinkFn?: ({ klub }: { klub: string | number }) => string;
}

const ClubSearch: React.FC<OwnProps> = ({ className, clubLinkFn = getClubBaseUrl }) => {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [inputFocused, setInputFocused] = React.useState(false);

  const [clubs, setClubs] = React.useState<
    Array<{ _id?: string; name?: string }>
  >([]);
  const [isFetchingClubs, setIsFetchingClubs] = React.useState(false);

  const inputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setTimeout(() => {
      setInputFocused(true);
    }, 0);
  };

  const fetchClubs = React.useMemo(() => {
    setClubs([]);
    return debounce(async (q: string) => {
      if (q.length > 3) {
        setIsFetchingClubs(true);
        try {
          const res = await CoreApi.organizationPPOListRelatedPPOs(
            config.OWNER_PPO,
            { q, ppoType: ['club'] },
          );
          setClubs(res.items || []);
        } catch (e: any) {
          alert(__('Nepodarilo sa získať zoznam klubov'));
        } finally {
          setIsFetchingClubs(false);
        }
      }
    }, 300);
  }, []);

  React.useEffect(() => {
    if (searchQuery.length <= 3) {
      setClubs([]);
    } else {
      fetchClubs(searchQuery);
    }
  }, [fetchClubs, searchQuery]);

  return (
    <Wrapper className={className}>
      <SearchInput
        value={searchQuery}
        setValue={setSearchQuery}
        inputFocused={inputFocused}
        setInputFocused={setInputFocused}
        inputFocus={inputFocus}
        inputRef={inputRef}
        placeholder={__('Zadajte názov klubu')}
        isFetching={isFetchingClubs}
      />
      {clubs.length > 0 && (
        <List>
          {clubs.map((club, idx) => (
            <ListItem key={club._id || idx}>
              <Link to={clubLinkFn({ klub: club._id || '' })}>
                {club.name}
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default ClubSearch;
