import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M16,3C8.8,3,3,8.8,3,16s5.8,13,13,13s13-5.8,13-13C29,8.8,23.2,3,16,3z M16,5c0.6,0,1.2,0.1,1.8,0.2L16,6.4
	l-1.8-1.3C14.8,5.1,15.4,5,16,5z M11.8,5.8l3.6,2.6L16,8.9l0.6-0.4l3.6-2.6c1.6,0.7,3,1.7,4.1,3l-1.4,4.3l-0.2,0.7l0.6,0.4l3.6,2.7
	c-0.1,1.7-0.6,3.4-1.5,4.8h-5.3l-0.2,0.7l-1.4,4.3C17.7,26.9,16.8,27,16,27c-0.9,0-1.7-0.1-2.6-0.3L12,22.4l-0.2-0.7H6.6
	c-0.9-1.5-1.4-3.2-1.5-4.9l3.6-2.6l0.6-0.4L9,13.1L7.6,8.9C8.8,7.5,10.2,6.5,11.8,5.8z M16,10.1l-0.6,0.4l-4.6,3.3l-0.6,0.4l0.2,0.7
	l1.8,5.3l0.2,0.7h7.1l0.2-0.7l1.8-5.3l0.2-0.7l-0.6-0.4l-4.6-3.3L16,10.1z M25.8,10.9c0.5,1.1,0.9,2.2,1.1,3.4L25.1,13L25.8,10.9z
	 M6.2,11L6.9,13l-1.8,1.3C5.3,13.1,5.7,12,6.2,11L6.2,11z M16,12.6l3.4,2.4l-1.3,4h-4.2l-1.3-4L16,12.6z M21.6,23.7h2.3
	c-0.8,0.9-1.8,1.6-2.9,2.2L21.6,23.7z M8.2,23.7h2.2l0.7,2.1C10,25.3,9,24.6,8.2,23.7z"
    />
  </svg>
);
