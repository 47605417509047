import ContentContext from '@sportnet/content/common/ContentContext';
import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { getTeamName } from '@sportnet/content/view/widgets/RaceMatchesList/utilities';
import { format, isAfter, isSameDay } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled, { css } from 'styled-components';
import Link from '../../../Link';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${rem(8)};
`;
const MatchWrapper = styled.div<{ isLive?: boolean }>`
  padding: ${rem(8)};
  ${({ isLive }) =>
    isLive &&
    css`
      color: ${({ theme }) => theme.color.danger};
    `}
`;
const MatchStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: ${rem(13)};
`;
const DateInfo = styled.div``;
const MatchStatusInfo = styled.div``;
const LiveIndicator = styled.div`
  color: ${({ theme }) => theme.color.danger};
  :before {
    content: '';
    width: ${rem(8)};
    height: ${rem(8)};
    background: ${({ theme }) => theme.color.danger};
    display: inline-block;
    border-radius: 100%;
    margin-right: ${rem(8)};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
`;

const StrechWidthScore = styled.div`
  display: flex;
  width: 50%;
  font-size: ${rem(11)};
`;

const SportGroundAdditionalProperties = styled.div`
  display: flex;
  font-size: ${rem(11)};
  align-items: center;
  img {
    width: ${rem(16)};
    margin-right: ${rem(4)};
  }
  > *:not(:last-child):after {
    content: '';
    border-right: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
    height: 100%;
    margin-right: ${rem(4)};
    margin-left: ${rem(4)};
  }
`;

const Label = styled.div<{ center?: boolean }>`
  width: 25%;
  ${({ center }) =>
    !!center &&
    css`
      ${LiveIndicator} {
        justify-content: center;
      }
    `}
`;

const ScoreTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(13)};
`;

const Faded = styled.span`
  color: ${({ theme }) => theme.color.fadedText};
`;

interface IOwnProps {
  match: IWidgetMatchesListMatchItem & { detailUrl?: string };
  view?: 'compact' | 'streched' | 'full';
  showCompetitionName?: boolean;
  phases?: {
    [key: string]: Readonly<any>;
  };
}

export type Props = IOwnProps;

// const useForceUpdate = () => React.useState()[1];

const MatchPreview: React.FC<Props> = ({
  match,
  view = 'compact',
  showCompetitionName,
}) => {
  const context = React.useContext(ContentContext);
  const getTeamNameFunc = getProp(
    context,
    ['helpers', 'getTeamName'],
    getTeamName,
  );
  const translateSportGroundAdditionalProperties = getProp(context, [
    'helpers',
    'translateSportGroundAdditionalProperties',
  ]);

  const renderMatchStatus = () => {
    if (match.contumation && match.contumation.isContumated) {
      return 'Kontumované';
    } else if (match.closed || getWinner()) {
      return 'Koniec';
    } else if (
      !match.closed &&
      match.startDate &&
      isSameDay(new Date(), new Date(match.startDate)) &&
      isAfter(new Date(), new Date(match.startDate))
    ) {
      return <LiveIndicator>Live</LiveIndicator>;
    } else if (!match.closed) {
      return '';
    } else {
      return <LiveIndicator>Live</LiveIndicator>;
    }
  };

  const getWinner = () => {
    const sortedResults = getProp(match, ['protocol', 'results'], [])
      .filter((i: any) => (i.points || []).length)
      .sort((a: any, b: any) => {
        const aIndividualResult = (a.points || []).find(
          (point: any) => point && point.name === 'individual',
        );
        const bIndividualResult = (b.points || []).find(
          (point: any) => point && point.name === 'individual',
        );
        if (
          aIndividualResult &&
          bIndividualResult &&
          aIndividualResult.value &&
          bIndividualResult.value &&
          Number(aIndividualResult.value) < Number(bIndividualResult.value)
        ) {
          return -1;
        } else if (
          aIndividualResult &&
          bIndividualResult &&
          aIndividualResult.value &&
          bIndividualResult.value &&
          Number(aIndividualResult.value) > Number(bIndividualResult.value)
        ) {
          return 1;
        } else {
          const aTimeResult = (a.points || []).find(
            (point: any) => point && point.name === 'time',
          );
          const bTimeResult = (b.points || []).find(
            (point: any) => point && point.name === 'time',
          );
          if (
            aTimeResult &&
            bTimeResult &&
            aTimeResult.value < bTimeResult.value
          ) {
            return -1;
          } else if (
            aTimeResult &&
            bTimeResult &&
            aTimeResult.value > bTimeResult.value
          ) {
            return 1;
          } else {
            const aPointsResult = (a.points || []).find(
              (point: any) => point && point.name === 'points',
            );
            const bPointsResult = (b.points || []).find(
              (point: any) => point && point.name === 'points',
            );
            if (
              aPointsResult &&
              bPointsResult &&
              aPointsResult.value < bPointsResult.value
            ) {
              return 1;
            } else if (
              aPointsResult &&
              bPointsResult &&
              aPointsResult.value > bPointsResult.value
            ) {
              return -1;
            }
            return 0;
          }
        }
      });
    return sortedResults[0];
  };

  const dateFormat = 'dd.MM.yyyy';

  let winner: null | { label: string; teamId: string } = null;
  let winnerTeam: null | { label: string; detailUrl?: string } = null;
  try {
    const winnerObject = getWinner();
    winner = {
      label: `${winnerObject.players.map((p) => p.name).join(', ')}`,
      teamId: winnerObject.teamId,
    };
    const winnerTeamObject = match.teams.find(
      (team) => team._id === winner?.teamId,
    );
    winnerTeam = {
      label: getTeamNameFunc(match.appSpace, winnerTeamObject),
      detailUrl: winnerTeamObject?.detailUrl,
    };
  } catch (e: any) {
    //
  }

  let content: any = null;

  if (view === 'streched') {
    content = (
      <MatchWrapper>
        <Row>
          <Label>
            {!!showCompetitionName && match.competition.name}{' '}
            {match.startDate ? (
              <span>
                {format(new Date(match.startDate), dateFormat)}
                <br />
                <b>{format(new Date(match.startDate), 'HH:mm')}</b>
              </span>
            ) : (
              '-'
            )}
          </Label>
          <StrechWidthScore>
            <div>
              <b style={{ fontSize: rem(12) }}>{match.round?.name}</b>
              <br />
              <span>{`${match.sportGround.sportObjectName}`}</span>
              {Object.keys(match.sportGround.additionalData || {}).length >
                0 && (
                <>
                  <SportGroundAdditionalProperties>
                    {translateSportGroundAdditionalProperties &&
                      translateSportGroundAdditionalProperties(
                        match.sportGround.additionalData || {},
                      ).map((prop) => {
                        let sportGroundContent: any[] = [];
                        if (prop.src) {
                          sportGroundContent = [
                            ...sportGroundContent,
                            <img
                              key={prop.src}
                              alt={prop.label}
                              src={prop.src}
                            />,
                          ];
                        }
                        if (prop.label) {
                          sportGroundContent = [
                            ...sportGroundContent,
                            <div key={prop.label}>{prop.label}</div>,
                          ];
                        }
                        return sportGroundContent;
                      })}
                  </SportGroundAdditionalProperties>
                </>
              )}
            </div>
          </StrechWidthScore>
          <Label style={{ textAlign: 'right' }}>
            {winner ? (
              <span>
                <b style={{ fontWeight: 800, fontSize: rem(14) }}>
                  {winner.label}
                </b>
                <br />
                {winnerTeam?.label}
              </span>
            ) : (
              renderMatchStatus()
            )}
          </Label>
        </Row>
      </MatchWrapper>
    );
  } else if (view === 'compact') {
    content = (
      <Wrapper>
        <MatchStatus>
          <DateInfo>
            {!!showCompetitionName && match.competition.name}{' '}
            {match.startDate ? (
              <span>
                {`${format(new Date(match.startDate), dateFormat)} ${format(
                  new Date(match.startDate),
                  'HH:mm',
                )}`}
              </span>
            ) : (
              '-'
            )}
          </DateInfo>
          <MatchStatusInfo>{renderMatchStatus()}</MatchStatusInfo>
        </MatchStatus>
        <ScoreTable>
          <Faded>
            <b>{match.round?.name}</b>&nbsp;
            <span
              style={{ fontSize: rem(11) }}
            >{`${match.sportGround.sportObjectName}`}</span>
            {Object.keys(match.sportGround.additionalData || {}).length > 0 && (
              <SportGroundAdditionalProperties>
                {translateSportGroundAdditionalProperties &&
                  translateSportGroundAdditionalProperties(
                    match.sportGround.additionalData || {},
                  ).map((prop) => {
                    let sportGroundContent: any[] = [];
                    if (prop.src) {
                      sportGroundContent = [
                        ...sportGroundContent,
                        <img key={prop.src} alt={prop.label} src={prop.src} />,
                      ];
                    }
                    if (prop.label) {
                      sportGroundContent = [
                        ...sportGroundContent,
                        <div key={prop.label}>{prop.label}</div>,
                      ];
                    }
                    return sportGroundContent;
                  })}
              </SportGroundAdditionalProperties>
            )}
          </Faded>
          {winner && (
            <>
              <br />
              <span>
                <b style={{ fontWeight: 800, fontSize: rem(14) }}>
                  {winner.label}
                </b>
                <br />
                {winnerTeam?.label}
              </span>
            </>
          )}
        </ScoreTable>
      </Wrapper>
    );
  }
  if (match.detailUrl) {
    return <Link to={match.detailUrl}>{content}</Link>;
  }
  return content;
};

export default MatchPreview;
