import Button from '@sportnet/ui/lib/Button';
import { em, linearGradient, rem, transparentize } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { SecondTitle } from '../../components/Layout';
import MaxWidthBox from '../../components/MaxWidthBox';
import config from '../../config';
import { RootState } from '../../configureStore';
import { getCallbackAuthUrl } from '../../containers/Authorization';
import styled from '../../theme/styled-components';
import isBrowser from '../../utilities/isBrowser';
import __ from '../../utilities/__';
import { appSettingsSelector } from '../DomainResolver/selectors';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)};
`;

const StyledLink = styled('a')`
  text-decoration: none;
`;

const Subtitle = styled('p')`
  font-size: ${em(16)};
  text-align: center;
  line-height: 1.4;
`;

const Shadow = styled.div`
  position: absolute;
  ${({ theme }) =>
    linearGradient({
      colorStops: [
        `${transparentize(1, theme.app.bgColor)} 0%`,
        `${theme.app.bgColor} 100%`,
      ],
    })}
  height: ${rem(80)};
  bottom: 100%;
  left: 0;
  right: 0;
`;

const RelativeMaxWidthBox = styled(MaxWidthBox)`
  position: relative;
`;

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface IOwnProps {
  title: string;
  subtitle: string;
  showShadow?: boolean;
}

type IProps = IMapStateToProps & IOwnProps;

const UnauthorizedMessage: React.FC<IProps> = ({
  title,
  subtitle,
  showShadow,
}) => {
  return (
    <RelativeMaxWidthBox width={config.CONTENT_MAX_WIDTH}>
      {showShadow && <Shadow />}
      <ContentWrapper>
        <SecondTitle isCentered>{title}</SecondTitle>
        <Subtitle>{subtitle}</Subtitle>
        {/* Fix SSR rendering */}
        {isBrowser() && (
          <StyledLink href={getCallbackAuthUrl()}>
            <Button primary>{__('Prihlásiť sa')}</Button>
          </StyledLink>
        )}
      </ContentWrapper>
    </RelativeMaxWidthBox>
  );
};

export default connect(mapStateToProps)(UnauthorizedMessage);
