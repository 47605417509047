import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
// import { Tab } from '../../../components/Tabber';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useIsResponsiveLayout from '../../../hooks/useIsResponsiveLayout';
import { ITopArticlesSidebarTab } from '../../../hooks/useTopArticlesSidebar';
import styled from '../../../theme/styled-components';
import __ from '../../../utilities/__';
// Toto je standard
import Icon from '../../../components/Icon';

const PositionedTopArticlesSidebar = styled(TopArticlesSidebar)`
  margin: 0 0 ${rem(48)} 0;
  ${mb('l')} {
    display: none;
  }

  .tabber-tab {
    padding: ${rem(16)} ${rem(4)};
    width: auto;
    flex-grow: 1;
  }
`;

type IOwnProps = {
  className?: string;
  activeTab: ITopArticlesSidebarTab;
  setActiveTab: (tab: ITopArticlesSidebarTab) => void;
};

type IProps = IOwnProps;

// Toto sa pouziva pri eventoch
// const tabs = [
//   { label: __('OH Peking 2022'), value: 'event' },
//   { label: __('Najčítanejšie'), value: 'top' },
//   { label: <Icon name="premium" size={12} />, value: 'premium' }
// ];

// Toto je standard
const tabs = [
  { label: __('Najčítanejšie'), value: 'top' },
  { label: __('Najnovšie'), value: 'newest' },
  { label: <Icon name="premium" size={12} />, value: 'premium' },
];

const ResponsiveTopArticlesSidebar: React.FC<IProps> = ({
  className,
  activeTab,
  setActiveTab,
}) => {
  const mobileLayout = useIsResponsiveLayout(992);

  React.useEffect(() => {
    if (mobileLayout) {
      // Toto je standard
      setActiveTab('top|4');
      // Toto sa pouziva pri eventoch
      // setActiveTab('event');
    }
  }, [mobileLayout, setActiveTab]);

  return (
    <PositionedTopArticlesSidebar
      className={className}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      tabs={tabs}
    />
  );
};

export default ResponsiveTopArticlesSidebar;
