import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from 'styled-components';

export default styled.div`
  width: ${rem(40)};
  height: ${rem(1)};
  background-color: ${({ theme }) => theme.app.textColor};
  margin: ${rem(32)} auto ${rem(48)} 0;
  ${mb('s')} {
    margin: ${rem(40)} auto ${rem(56)} 0;
  }
`;
