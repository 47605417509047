import styled from '../../theme/styled-components';

export default styled.div`
  transition: height 500ms ease;
  overflow: hidden;
  position: relative;

  /* next */
  .pane-next-enter {
    position: absolute !important;
    transform: translateX(0%) !important;
  }
  .pane-next-enter-active {
    transform: translateX(-100%) !important;
    transition: all 500ms ease !important;
  }
  .pane-next-enter-done {
    position: absolute !important;
    transform: translateX(-100%) !important;
  }

  .pane-next-exit {
    position: absolute !important;
    transform: translateX(-100%) !important;
  }
  .pane-next-exit-active {
    transform: translateX(0%) !important;
    transition: all 500ms ease !important;
  }
  .pane-next-exit-done {
    transform: translateX(0%) !important;
  }

  /* prev */
  .pane-prev-enter {
    position: absolute !important;
    transform: translateX(100%) !important;
  }
  .pane-prev-enter-active {
    transform: translateX(0%) !important;
    transition: all 500ms ease !important;
  }
  .pane-prev-enter-done {
    transform: translateX(0%) !important;
  }

  .pane-prev-exit {
    position: absolute !important;
    transform: translateX(0%) !important;
  }
  .pane-prev-exit-active {
    transform: translateX(100%) !important;
    transition: all 500ms ease !important;
  }
  .pane-prev-exit-done {
    position: absolute !important;
    transform: translateX(100%) !important;
  }

  .hidden-pane {
    position: absolute;
    transform: translateX(100%);
  }
`;
