import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { RootState } from '../../configureStore';
import { footerConfigurationSelector } from '../../pages/DomainResolver/selectors';
import FooterSimple from './wrappers/FooterSimple';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    footerConfiguration: footerConfigurationSelector(state),
  };
};

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const Footer: React.FC<Props> = ({ footerConfiguration }) => {
  const type = getProp(footerConfiguration, ['type'], '');
  switch (type) {
    case 'simple-1':
      return <FooterSimple />;
    default:
      return <div />;
  }
};

export default compose(connect(mapStateToProps))(Footer);
