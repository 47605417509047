import { rem } from 'polished';
import Icon from '../Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import getTooltipContent from '../../utilities/thirdPartyResults/getTooltipContent';
import formatOddValue from '../../utilities/thirdPartyResults/formatOddValue';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { ThirdPartyResultsOdd } from '../../library/thirdPartyResults';

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: ${rem(36)};
  ${mb('m')} {
    width: ${rem(40)};
  }
`;

const OddValue = styled.span<{
  anim: boolean;
  isResult: boolean;
  winning?: boolean;
}>`
  display: flex;
  align-items: center;
  ${({ isResult, winning }) =>
    isResult
      ? winning
        ? css`
            color: ${({ theme }) => theme.color.primaryFontColor};
            font-weight: ${({ theme }) => theme.primaryFont.weight.semiBold};
          `
        : css`
            color: ${({ theme }) => theme.color.secondaryFontColor};
            font-weight: normal;
          `
      : css`
          color: ${({ theme }) => theme.color.primaryFontColor};
          font-weight: normal;
        `}
  ${({ anim }) => {
    return anim
      ? css`
          animation-name: alert;
          animation-duration: 0.5s;
          border-radius: 2px;
          padding: 2px;
          @keyframes alert {
            from {
              background-color: rgba(207, 37, 40, 0.5);
            }
            to {
              background-color: none;
            }
          }
        `
      : '';
  }}
`;

interface OwnProps {
  id: number;
  type: ThirdPartyResultsOdd['type'];
  isResult?: boolean;
  live?: boolean;
  nikeUrl?: string;
  rate?: number;
  winning?: ThirdPartyResultsOdd['winning'];
}

const OddBodyCell: React.FC<OwnProps> = ({
  id,
  rate,
  type,
  live,
  nikeUrl,
  isResult,
  winning,
}) => {
  return (
    <Wrapper key={`${id}-${(rate ?? 0) * 100}`}>
      <OddValue anim={!!live} winning={winning} isResult={!!isResult}>
        {rate !== undefined && rate !== 1 ? (
          nikeUrl ? (
            <a
              href={nikeUrl}
              target="_blank"
              rel="nofollow noopener noreferrer"
              title={getTooltipContent(type)}
            >
              {formatOddValue(rate)}
            </a>
          ) : (
            <span title={getTooltipContent(type)}>{formatOddValue(rate)}</span>
          )
        ) : (
          <Icon name="lock" size={12} />
        )}
      </OddValue>
    </Wrapper>
  );
};

export default React.memo(OddBodyCell);
