import React from 'react';

export default (size: string, color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      viewBox="0 0 46.076 46.076"
    >
      <path
        d="M32.349 0H13.726A13.742 13.742 0 0 0 0 13.726v18.623a13.742 13.742 0 0 0 13.726 13.727h18.623a13.742 13.742 0 0 0 13.727-13.727V13.726A13.742 13.742 0 0 0 32.349 0zm9.091 32.349a9.091 9.091 0 0 1-9.091 9.091H13.726a9.091 9.091 0 0 1-9.091-9.091V13.726a9.091 9.091 0 0 1 9.091-9.091h18.623a9.091 9.091 0 0 1 9.091 9.091v18.623z"
        fill={color}
      />
      <path
        d="M144.917 133a11.917 11.917 0 1 0 11.917 11.917A11.93 11.93 0 0 0 144.917 133zm0 19.2a7.282 7.282 0 1 1 7.282-7.282 7.282 7.282 0 0 1-7.282 7.282z"
        fill={color}
        transform="translate(-121.879 -121.879)"
      />
      <circle
        cx="2.856"
        cy="2.856"
        r="2.856"
        fill={color}
        transform="translate(32.122 8.355)"
      />
    </svg>
  );
};
