import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from '../../theme/styled-components';

/**
 * Title (nadpis témy) je spoločný názov pre nadpis, ktorý je použitý na 
 * stránkach tém ako: 
 * - https://sportnet.sme.sk/giro-ditalia-2022/
 * - https://sportnet.sme.sk/search/?q=petra%20vlhova
 * - https://sportnet.sme.sk/redakcia/5f9c0503a20a18001aa0617e/pavol.spal/ (meno redaktora)
 */
export default styled.h1`
  font-family: pt-serif-pro, serif;
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 600;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  margin: 0;
  padding: 0;
  font-size: ${rem(32)};
  line-height: ${rem(38.4)};
  ${mb('s')} {
    font-size: ${rem(48)};
    line-height: ${rem(62.4)};
  }
`;
