import React from 'react';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import { Td } from './MatchDetailPrintMatchEvent';
import { NominationItemWrapper } from './MatchDetailPrintNominations';
import { ArrayElement } from '../../../library/App';
import getMatchDetailDataForPrint from '../../../utilities/print/getMatchDetailDataForPrint';
import { SPORT_SECTOR_DELEGATES } from '../../../codelists/sport_sector_delegates';

interface OwnProps {
  sportSectorDelegates: ArrayElement<typeof SPORT_SECTOR_DELEGATES>;
  matchDetailManagers: ReturnType<
    typeof getMatchDetailDataForPrint
  >['managers'];
}

const MatchDetailPrintDelegates: React.FC<OwnProps> = ({
  sportSectorDelegates,
  matchDetailManagers,
}) => {
  // vytvorime riadky tabulky
  const rows = sportSectorDelegates
    ? sportSectorDelegates.items.reduce((acc, item) => {
        const delegates = matchDetailManagers.filter(
          (manager) => manager.type === item._id,
        );
        if (delegates && delegates.length > 0) {
          acc.push({
            positionName: item.label,
            names: delegates.map((delegate) => delegate.name),
          });
        }
        return acc;
      }, [] as { positionName: string; names: string[] }[])
    : [];

  return rows.length > 0 ? (
    <>
      {rows.map((row) => {
        return (
          <React.Fragment key={`delegati-stretnutia-${row.positionName}`}>
            <Tr>
              <Td colSpan={4}>
                <NominationItemWrapper percentWidth={25}>
                  {row.positionName}
                </NominationItemWrapper>
                <NominationItemWrapper percentWidth={75} textRight={true}>
                  {row.names.filter(Boolean).join(', ')}
                </NominationItemWrapper>
              </Td>
            </Tr>
          </React.Fragment>
        );
      })}
    </>
  ) : (
    <Tr>
      <Td colSpan={6}>-</Td>
    </Tr>
  );
};

export default MatchDetailPrintDelegates;
