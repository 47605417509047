import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { LinkProps, withRouter } from 'react-router';
import Link from '../../components/Link';
import { RouteProps } from '../../library/App';
import styled, { css } from '../../theme/styled-components';
import Icon from '../Icon';
import { IMenuItem } from './types';

const LabelText = styled.div``;

const Wrapper = styled.li<{ isSectionMenuItem: boolean }>`
  margin: 0;
  height: ${rem(40)};
  ${mb('l')} {
    height: ${rem(56)};
  }
  ${({ isSectionMenuItem }) =>
    !!isSectionMenuItem
      ? css`
          margin-right: -${rem(1)};
        `
      : css`
          &.visibleMenuItem {
            ${LabelText} {
              // border: none;
              padding: 0 ${rem(16)} 0 ${rem(8)};
            }
          }
        `}
`;

const itemStyles = css`
  display: flex;
  background: transparent;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: bold;
  height: 100%;
  padding: 0;
  letter-spacing: ${rem(0.88)};
  text-transform: uppercase;
  border: none;
  font-family: inherit;
  color: #333;
  outline: none;
  position: relative;
  font-size: ${rem(11)};
  line-height: ${rem(14.4)};
  :hover {
    text-decoration: underline;
  }
  > div {
    display: flex;
    align-items: center;
  }
  > div > div {
    padding: 0 ${rem(16)};
    margin-right: -${rem(1)};
    border-right: ${rem(1)} solid #ddd;
  }
`;

const activeItemStyles = css`
  border: none;
  z-index: 14;
`;

interface ItemLinkProps extends LinkProps {
  active: boolean;
  isSectionMenuItem: boolean;
  isSideItem: boolean;
}
const ItemLink = ({ active, ...rest }: ItemLinkProps) => <Link {...rest} />;
const LinkLabel = styled(ItemLink)<
  ItemLinkProps & { isLastSectionItem?: boolean; routeActive: boolean }
>`
  ${itemStyles}

  ${({ active }) => active && activeItemStyles}
  &.active {
    ${activeItemStyles}
  }
  ${({ isSectionMenuItem, isSideItem }) =>
    (!!isSectionMenuItem || isSideItem!!) &&
    css`
      &.active {
        text-decoration: none;
      }
    `}
  :hover {
    text-decoration: underline;
  }
  ${({ isLastSectionItem }) =>
    !!isLastSectionItem &&
    css`
      border-right: ${rem(1)} solid #ddd;
      border-left: none !important;
    `}
  ${({ routeActive }) =>
    routeActive &&
    css`
      color: #cf2528;
    `}
`;

const Label = styled.button<{
  active: boolean;
  isLastSectionItem?: () => void;
}>`
  ${itemStyles}
  ${({ active }) => active && activeItemStyles}
  ${({ active }) =>
    active &&
    css`
      text-decoration: underline;
    `}
  ${({ isLastSectionItem }) =>
    !!isLastSectionItem &&
    css`
      border-right: ${rem(1)} solid #ddd;
    `}
  ${({ routeActive, active }) =>
    !routeActive &&
    active &&
    css`
      ${LabelText} {
        border-right: none;
      }
    `}
  ${({ routeActive }) =>
    routeActive &&
    css`
      color: #cf2528;
      border-left: none !important;
      border-right: none !important;
    `}
`;

const ChevronIcon = styled(Icon)`
  margin-left: ${rem(6)};
`;

interface OwnProps {
  className?: string;
  item: IMenuItem;
  menuId: string;
  onClick?: (item: IMenuItem) => void;
  routeActive?: boolean;
  active?: boolean;
  isAnyActive: boolean;
  isSectionItem?: boolean;
  isSideItem?: boolean;
  isLastSectionItem?: boolean;
}

type Props = OwnProps & RouteProps;

const MenuItem: React.FC<Props> = ({
  className,
  item,
  onClick,
  routeActive: itemRouteActive,
  active,
  isAnyActive,
  router,
  location,
  isSectionItem,
  isSideItem,
  isLastSectionItem,
  menuId
}) => {
  const wrapperRef = React.useRef<null | HTMLLIElement>(null);
  const routeActive =
    !isSectionItem &&
    !isSideItem &&
    (itemRouteActive ||
      (item.url && !item.hash ? router.isActive(item.url, true) : false) ||
      (item.matchUrl
        ? (location.pathname.match(new RegExp(item.matchUrl, 'g')) || [])
            .length > 0
        : false) ||
      (!item.url && !item.matchUrl && active));

  React.useEffect(() => {
    const wrapperEl = wrapperRef.current;

    if (routeActive && wrapperEl) {
      try {
        wrapperEl.scrollIntoView({ block: 'nearest', inline: 'center' });
      } catch (e: any) {
        wrapperEl.scrollIntoView(false);
      }
    }
  }, [routeActive]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (item.renderDropdown) {
      e.preventDefault();
    }
    e.stopPropagation(); // stops react event listeners
    e.nativeEvent.stopImmediatePropagation(); // stops native event from bubbling to handlers registered with document.addEventListeners
    if (onClick) {
      onClick(item);
    }
  };

  const LabelIcon = item.icon as any;

  const labelComp =
    typeof item.label === 'string' ? (
      <>
        {!!item.icon && <LabelIcon />}
        <LabelText>
          {item.label}{' '}
          {!!item.renderDropdown && (
            <ChevronIcon
              {...(routeActive &&
                (!isAnyActive || active) && { color: '#CF2528' })}
              name="chevron"
              size={12}
            />
          )}
        </LabelText>
      </>
    ) : (
      <LabelText>{item.label()}</LabelText>
    );

  return (
    <Wrapper
      {...(!!className && { className })}
      ref={wrapperRef}
      isSectionMenuItem={!!isSectionItem}
    >
      {item.url ? (
        <LinkLabel
          to={item.url}
          activeClassName={
            isAnyActive || item.hash
              ? void 0
              : `active ${menuId}_activeThirdLevelMenuItem`
          }
          className={
            routeActive && (!isAnyActive || active)
              ? `active ${menuId}_activeThirdLevelMenuItem`
              : ''
          }
          active={active}
          routeActive={routeActive && (!isAnyActive || active)}
          onClick={handleClick}
          onlyActiveOnIndex
          isLastSectionItem={!!isLastSectionItem}
          isSectionMenuItem={!!isSectionItem}
          isSideItem={!!isSideItem}
        >
          <div>{labelComp}</div>
        </LinkLabel>
      ) : (
        <Label
          routeActive={routeActive && (!isAnyActive || active)}
          active={active}
          isLastSectionItem={!!isLastSectionItem}
          {...(!!onClick && { onClick: handleClick })}
        >
          <div>{labelComp}</div>
        </Label>
      )}
    </Wrapper>
  );
};

export default withRouter(React.memo(MenuItem)) as React.FC<OwnProps>;
