import { NIKE_MAP, ParsedData } from './nike-map';

export const findAllUniqueLeagueCupId = (issfSutazId: number) => {
  const resultSet = NIKE_MAP.reduce((acc, row) => {
    if (row.issfSutazId === issfSutazId) {
      acc.add(row.nikeSutazId);
    }
    return acc;
  }, new Set<number>());
  return [...resultSet];
};

const findInThirdPartyResultsMap = ({
  issfSutazId,
  competitionId,
  teamId,
}: {
  issfSutazId?: number;
  competitionId?: string;
  teamId?: string;
}): ParsedData | null => {
  const result = NIKE_MAP.find((d) => {
    return issfSutazId
      ? d.issfSutazId === issfSutazId &&
          (teamId === undefined || d.teamId === teamId) &&
          (teamId === undefined || d.nikeTeamID) &&
          d.nikeSutazId
      : competitionId
      ? d.sutazID === competitionId &&
        (teamId === undefined || d.teamId === teamId) &&
        (teamId === undefined || d.nikeTeamID) &&
        d.nikeSutazId
      : undefined;
  });
  return result ?? null;
};

/**
 * Vrati ID ligy, home timu a away timu tretej strany.
 * @param param0
 * @returns
 */
export const findInThirdPartyDataMap = ({
  issfSutazId,
  competitionId,
  teamHomeId,
  teamAwayId,
}: {
  issfSutazId?: number;
  competitionId?: string;
  teamHomeId: string;
  teamAwayId: string;
}):
  | {
      leagueCup: number;
      thirdPartyHomeTeamId: number;
      thirdPartyAwayTeamId: number;
    }
  | undefined => {
  // find home team
  const nikeHomeTeamRow = findInThirdPartyResultsMap({
    issfSutazId,
    competitionId,
    teamId: teamHomeId,
  });

  // find away team
  if (nikeHomeTeamRow) {
    const nikeAwayTeamRow = findInThirdPartyResultsMap({
      issfSutazId,
      competitionId,
      teamId: teamAwayId,
    });

    if (nikeAwayTeamRow) {
      return {
        leagueCup: nikeHomeTeamRow.nikeSutazId,
        thirdPartyHomeTeamId: nikeHomeTeamRow.nikeTeamID,
        thirdPartyAwayTeamId: nikeAwayTeamRow.nikeTeamID,
      };
    }
  }

  return undefined;
};

export const doxxbetLeagueCupIdByCompetitionId = (
  competitionId: string,
): ParsedData | null => {
  return (
    NIKE_MAP.find((d) => {
      return d.sutazID === competitionId;
    }) ?? null
  );
};

export const doxxbetLeagueCupIdsByTeamId = (teamId: string): ParsedData[] => {
  return NIKE_MAP.filter((d) => {
    return d.teamId === teamId;
  });
};
