import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import CompetitionsApi from '../../../../api/CompetitionsApi';

interface LoadClubCompetititons {
  appSpace: string;
  teamId: string;
  competitionId: string;
}
const loadClubCompetitions = createQueryFn(
  QueryNameSpace.clubCompetitions,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string] | [string, LoadClubCompetititons]>) => {
    const [, parameters] = queryKey;

    if (!parameters || !parameters.appSpace || !parameters.teamId || !parameters.competitionId) {
      throw new Error('Must provide required parameters [appSpace, teamId, competitionId]!');
    }

    const { appSpace, teamId, competitionId } = parameters;

    const result = await CompetitionsApi.getTeamCompetition(
      appSpace,
      teamId,
      competitionId,
    );

    return result;
  },
);

export default loadClubCompetitions;
