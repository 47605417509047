import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      color={color}
      id="Path_1341"
      d="M22,5.7l-4.6-3.9l-1.3,1.5l4.6,3.9L22,5.7z M7.9,3.4L6.6,1.9L2,5.7l1.3,1.5L7.9,3.4z M12.5,8H11v6l4.8,2.9
l0.8-1.2l-4-2.4V8z M12,4c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S17,4,12,4z M12,20c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7
C19,16.9,15.9,20,12,20C12,20,12,20,12,20z"
    />
  </svg>
);
