import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import { SKELETON_CLASS_NAME } from '../SkeletonLoader';

const ArticleTitleWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${rem(4)};
  }
`;

const ArticleTitle = styled.div`
  height: ${rem(18)};
  background-color: ${({ theme }) => theme.color.lightBorder};
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
  ${mb('s')} {
    height: ${rem(20)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  margin-bottom: ${rem(24)};
  ${mb('s')} {
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: none;
  }
`;

const StyledMediaManagerImage = styled.div`
  background-color: ${({ theme }) => theme.color.lightBorder};
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
  width: ${rem(88)};
  height: ${rem(66)};
  ${mb('s')} {
    width: ${rem(160)};
    height: ${rem(120)};
  }
`;

const DesktopArticleDescription = styled.div`
  display: none;
  ${mb('s')} {
    display: flex;
    margin-bottom: ${rem(8)};
    > div:not(:last-child) {
      margin-right: ${rem(8)};
    }
  }
`;

const Header = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Pipe = styled.div`
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: ${({ theme }) => theme.color.lightBorder};
`;

const ImageLink = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0 0 auto ${rem(16)};
  flex-shrink: 0;
  ${mb('s')} {
    margin: 0 ${rem(24)} auto 0;
  }
`;

const ArticleDate = styled.div`
  height: ${rem(12)};
  width: ${rem(68)};
  background-color: ${({ theme }) => theme.color.lightBorder};
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
`;

const Perex = styled.span`
  display: none;
  ${mb('s')} {
    display: flex;
    margin: ${rem(8)} 0 0 0;
    height: ${rem(14)};
    width: 100%;
    background-color: ${({ theme }) => theme.color.lightBorder};
    border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
  }
`;

const MobileArticleDescription = styled.div`
  margin: ${rem(12)} ${rem(-82)} ${rem(24)} 0;
  display: flex;
  > div:not(:last-child) {
    margin-right: ${rem(8)};
  }
  ${mb('s')} {
    display: none;
  }
`;

const Authors = styled.div`
  height: ${rem(12)};
  width: ${rem(68)};
  background-color: ${({ theme }) => theme.color.lightBorder};
  border-radius: ${({ theme }) => rem(theme.app.borderRadiusSmall)};
`;

type IOwnProps = {
  className?: string;
};

type IProps = IOwnProps;

const ArticleLoader: React.FC<IProps> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <ImageLink>
        <StyledMediaManagerImage className={SKELETON_CLASS_NAME} />
      </ImageLink>
      <Header>
        <DesktopArticleDescription>
          <Authors className={SKELETON_CLASS_NAME} />
          <Pipe>∙</Pipe>
          <ArticleDate className={SKELETON_CLASS_NAME} />
        </DesktopArticleDescription>

        <ArticleTitleWrapper>
          <ArticleTitle
            style={{ width: '100%' }}
            className={SKELETON_CLASS_NAME}
          />
          <ArticleTitle
            style={{ width: rem(110) }}
            className={SKELETON_CLASS_NAME}
          />
        </ArticleTitleWrapper>

        <Perex className={SKELETON_CLASS_NAME} />

        <MobileArticleDescription>
          <Authors className={SKELETON_CLASS_NAME} />
          <Pipe className={SKELETON_CLASS_NAME}>∙</Pipe>

          <ArticleDate className={SKELETON_CLASS_NAME} />
        </MobileArticleDescription>
      </Header>
    </Wrapper>
  );
};

export default React.memo(ArticleLoader);
