import __ from './__';

export default (status: string) => {
  switch (status) {
    case 'NEW':
      return __('Nové');
    case 'RESOLVED':
      return __('Vyriešené');
    default:
      return status;
  }
};
