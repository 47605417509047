import urlConverter from '../urlConverter';

export default ({ zvaz }: { zvaz: string | number }) => {
  let unionId = zvaz;
  const convertedUnionData = urlConverter.find(
    (i) => i._id === zvaz || i.__issfId === Number(zvaz),
  );
  if (convertedUnionData) {
    unionId = convertedUnionData.url;
  }
  return `/futbalnet/z/${unionId}/`;
};
