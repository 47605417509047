import urlConverter from '../urlConverter';

export default ({
  klub,
  teamId,
}: {
  klub: string | number;
  teamId: string | number;
}) => {
  let clubId = klub;
  const convertedUnionData = urlConverter.find(
    (i) => i._id === klub || i.__issfId === Number(klub),
  );
  if (convertedUnionData) {
    clubId = convertedUnionData.url;
  }
  return `/futbalnet/k/${clubId}/tim/${teamId}/`;
};
