const getSmarttagFromUrl = (url?: string) => {
  if (!url) {
    return null;
  }
  const [key, value] = decodeURIComponent(url).split('/');
  if (!key || !value) {
    return null;
  }
  return {
    key,
    values: [{ key: value }],
  };
};

export default getSmarttagFromUrl;
