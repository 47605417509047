import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { MatchesListTeam } from '../../../library/Print';
import formatDate from '../../../utilities/formatDate';
import { Tr } from './RenderPrintData';

const FONT_SECONDARY_COLOR = 'rgba(0, 0, 0, 0.56)';

export const Td = styled.td`
  padding: ${rem(8)};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  vertical-align: middle;
  text-transform: uppercase;
`;

const TdDate = styled(Td)`
  width: 120px;
  color: ${FONT_SECONDARY_COLOR};
`;

const TdTextRight = styled(Td)`
  text-align: right;
`;

const Club = styled.div<{ $alignRight?: boolean; $bold?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: ${({ $bold = false, theme }) =>
    $bold ? theme.primaryFont.weight.bold : 'normal'};
  gap: ${rem(12)};
  ${({ $alignRight = false }) =>
    $alignRight
      ? css`
          justify-content: end;
        `
      : ''}
`;

const ClubName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClubLogo = styled.img`
  display: table-cell;
  width: ${rem(32)};
  height: ${rem(32)};
`;

const TdScore = styled(Td)`
  width: ${rem(32)};
`;

const TdScoreSeparator = styled(Td)`
  width: ${rem(32)};
  padding-left: 0;
  padding-right: 0;
  text-align: center;
`;

const ScoreNote = styled.p`
  text-transform: none;
  font-size: ${rem(11)};
  line-height: 1;
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(32)};
  height: ${rem(32)};
  border: 1px solid black;
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
`;

const ResignationLabel = styled.div`
  font-size: ${rem(10)};
  text-transform: none;
`;

const ScoreDash = styled.p`
  margin: ${rem(-4)} 0;
`;

interface OwnProps {
  dateFrom: Date | null;
  homeTeam: MatchesListTeam;
  awayTeam: MatchesListTeam;
  scoreNote?: string;
}

const MatchListItem: React.FC<OwnProps> = ({
  dateFrom,
  homeTeam,
  awayTeam,
  scoreNote,
}) => {
  return (
    <Tr>
      <TdDate>{dateFrom ? formatDate(dateFrom, 'dd.MM. HH:mm') : '-'}</TdDate>
      <TdTextRight>
        <Club $alignRight $bold={homeTeam.isWinner}>
          <ClubName>
            {homeTeam.name}
            {homeTeam.resignation && (
              <ResignationLabel>(odstúpené)</ResignationLabel>
            )}
          </ClubName>
          <ClubLogo src={homeTeam.logoSrc} alt={homeTeam.name}></ClubLogo>
        </Club>
      </TdTextRight>
      <TdScore>
        <Score>{homeTeam.score}</Score>
      </TdScore>
      <TdScoreSeparator>
        {scoreNote && <ScoreNote>{scoreNote}</ScoreNote>}
        <ScoreDash>-</ScoreDash>
      </TdScoreSeparator>
      <TdScore>
        <Score>{awayTeam.score}</Score>
      </TdScore>
      <Td>
        <Club $bold={awayTeam.isWinner}>
          <ClubLogo src={awayTeam.logoSrc} alt={awayTeam.name}></ClubLogo>
          <ClubName>
            {awayTeam.name}
            {awayTeam.resignation && (
              <ResignationLabel>(odstúpené)</ResignationLabel>
            )}
          </ClubName>
        </Club>
      </Td>
    </Tr>
  );
};

export default MatchListItem;
