import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';
import MaxWidthBox from '../MaxWidthBox';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(24)};
`;

type IOwnProps = {};

type IProps = IOwnProps;

const TitleBlock: React.FC<IProps> = ({ children }) => {
  return (
    <MaxWidthBox>
      <Wrapper>{children}</Wrapper>
    </MaxWidthBox>
  );
};

export default TitleBlock;
