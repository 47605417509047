import urlConverter from '../urlConverter';

export default ({ zvaz, zapas }: { zvaz: string; zapas: string | number }) => {
  let unionUrl = zvaz;
  const convertedUnionData = urlConverter.find(
    (i) => i._id === zvaz || i.__issfId === Number(zvaz),
  );
  if (convertedUnionData) {
    unionUrl = convertedUnionData.url;
  }
  return `/futbalnet/z/${unionUrl}/zapas/${zapas}/`;
};
