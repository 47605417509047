import { IMatch } from '../../library/Competitions';

const getScoreNote = (
  sportSector: string,
  scoreByPhases: IMatch['scoreByPhases'],
) => {
  if (sportSector === 'futbal') {
    if (scoreByPhases?.length === 4) {
      return 'pp';
    }
  }
  return '';
};

export default getScoreNote;
