import { Location } from 'history';
import { deserializeQuery } from '@sportnet/query-hoc/useQuery';
import cleanupLocationObject from './cleanupLocationObject';
import getCleandupPathFromLocation from './getCleanedPathFromLocation';


const getCleanedPathFromWindowLocation = (location: typeof window.location) => {
    const queryObject = deserializeQuery(location.search);
    const locationObject: Location = {
        pathname: location.pathname,
        search: location.search,
        query: queryObject,
        hash: location.hash,
        state:  undefined,
        action: 'PUSH',
        key: '',
    }
    const cleandUpLocationObject = cleanupLocationObject(locationObject);
    return getCleandupPathFromLocation(cleandUpLocationObject);
}

export default getCleanedPathFromWindowLocation;