import getProp from 'sportnet-utilities/lib/getProp';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { SectionPublicDetail } from '../../api/CmsApi';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import { IPrivateContent, ISection, SectionId } from '../../library/App';
import NotFoundError from '../../NotFoundError';
import RedirectError from '../../RedirectError';
import UnauthorizedError from '../../UnauthorizedError';
import normalizeEntities from '../../utilities/normalizeEntities';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { updateEntities } from '../App/actions';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';

const create = actionCreatorFactory('SECTION');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const loadSection = createAsync<
  { idOrUniqId: string; appSpace?: string },
  ISection
>('LOAD_SECTION', async (parameters, dispatch, getState, { CmsApi }) => {
  const appSpace = parameters.appSpace || CSMAppSpaceSelector(getState());

  let response: SectionPublicDetail;
  let state: IPrivateContent['state'] = 'FULL';

  try {
    response = await CmsApi.getPublicSectionByIdOrUniqId(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      parameters.idOrUniqId,
      {
        expandWidgets: true,
      },
    );
  } catch (e: any) {
    if (e && e.details && e.details.code === 404) {
      throw new NotFoundError(e);
    } else if (e && e.details && e.details.code === 401) {
      const data = getProp(e.details, ['payload', 'data'], null);
      if (data) {
        response = data;
        state = 'UNATHORIZED';
      } else {
        throw new UnauthorizedError(e);
      }
    } else if (e && e.details && e.details.code === 403) {
      const data = getProp(e.details, ['payload', 'data'], null);
      if (data) {
        response = data;
        state = 'FORBIDDEN';
      } else {
        throw new ForbiddenError(e);
      }
    } else {
      throw new InternalServerError(e);
    }
  }

  if (response.redirecturl) {
    throw new RedirectError({
      location: response.redirecturl,
      permanent: true,
    });
  }

  const section = reduceSectionProps({ ...response, state });

  const { entities } = normalizeEntities('sections', [section]);

  dispatch(updateEntities(entities));

  return section;
});

// Set current article id
export const setCurrentSectionId = create<SectionId | null>(
  'SET_CURRENT_SECTION_ID',
);
