import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import styled from '../../../../theme/styled-components';
import Icon from '../../../Icon';
import { ISquadWidgetTeamMember } from './utilities';

const Name = styled.span``;

const ItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${rem(16)} ${rem(24)};
  border: ${rem(1)} solid #ddd;
  border-top: none;
  :first-of-type {
    border-top: ${rem(1)} solid #ddd;
    border-top-left-radius: ${rem(8)};
    border-top-right-radius: ${rem(8)};
  }
  :last-of-type {
    border-bottom-left-radius: ${rem(8)};
    border-bottom-right-radius: ${rem(8)};
  }
  font-size: ${rem(14)};
  line-height: ${rem(14.4)};
  color: inherit;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  :hover,
  :active,
  :focus {
    ${Name} {
      text-decoration: underline;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(16)} ${rem(24)};
  border: ${rem(1)} solid #ddd;
  border-top: none;
  :first-of-type {
    border-top: ${rem(1)} solid #ddd;
    border-top-left-radius: ${rem(8)};
    border-top-right-radius: ${rem(8)};
  }
  :last-of-type {
    border-bottom-left-radius: ${rem(8)};
    border-bottom-right-radius: ${rem(8)};
  }
  font-size: ${rem(14)};
  line-height: ${rem(14.4)};
  color: inherit;
  text-decoration: none;
`;

const ImageWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => rem(size)};
  height: ${({ size }) => rem(size)};
  flex-shrink: 0;
  margin-right: ${rem(8)};
`;

const Image = styled(MediaManagerImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
`;

const Nr = styled.span`
  font-weight: 600;
  margin-left: auto;
  text-decoration: none;
`;

interface IOwnProps {
  member: ISquadWidgetTeamMember;
  url?: string;
}

type IProps = IOwnProps;

const Member: React.FC<IProps> = ({ member, url }) => {
  const size = 24;
  const nr = member.nr || member.nr === 0 ? String(member.nr) : '';
  const renderChilds = React.useMemo(() => {
    return (
      <>
        <ImageWrapper size={size}>
          {member.photo ? (
            <Image
              alt={member.displayName}
              picture={member.photo}
              naturalWidth={size}
              naturalHeight={size}
              script="media"
              loading="lazy"
            />
          ) : member.sportnetUser?.photo?.public_url ? (
            <Image
              alt={member.displayName}
              picture={{ media_url: member.sportnetUser.photo.public_url }}
              naturalWidth={size}
              naturalHeight={size}
              script="media"
              loading="lazy"
            />
          ) : (
            <Icon name="avatar" size={size} />
          )}
        </ImageWrapper>
        <Name>{member.displayName}</Name>
        {nr && <Nr>#{nr}</Nr>}
      </>
    );
  }, [member, nr]);
  if (url) {
    return <ItemLink to={url}>{renderChilds}</ItemLink>;
  }
  return <Item>{renderChilds}</Item>;
};

export default Member;
