import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 12"
    width={size}
    height={size}
  >
    <path fill={color} d="M0,12V0l10,6L0,12z" />
  </svg>
);
