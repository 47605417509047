import { ApiError } from './library/api';
import {
  FetchInfiniteQueryOptions,
  FetchQueryOptions,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryFunctionContext,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

export const QueryNameSpace = {
  user: 'auth:user',
  transfers: 'transfers',
  thirdPartyResults: 'thirdPartyResults',
  thirdPartyResultsWithSportnetIds: 'thirdPartyResultsWithSportnetIds',
  articles: 'articles',
  sectionArticles: 'sectionArticles',
  listingArticles: 'listingArticles',
  allMatches: 'allMatches',
  allCompetitionMatches: 'allCompetitionMatches',
  clubCompetitions: 'clubCompetitions',
  competitionById: 'competitionById',
  competitionPartWithResultsTable: 'competitionPartWithResultsTable',
  matchDetailPrint: 'matchDetailPrint',
  codelistSportSetorCrew: 'codelist:sportSectorCrew',
  codelistSportSetorDelegates: 'codelist:sportSectorDelegates',
  gossips: 'gossips',
  gossipsSection: 'gossips-section',
  gossipsNewCount: 'gossips-new-count',
  gossipDetail: 'gossipDetail',
  publicFeed: 'publicFeed',
  brickSpaBlocks: 'brickSpaBlocks',
} as const;

type QueryNameSpace2 = typeof QueryNameSpace;

export type QueryNameSpaceValue = QueryNameSpace2[keyof QueryNameSpace2];

export const makeClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000, // 5 min,
        cacheTime: 5 * 60 * 1000, // 5 min,
        retry: false,
      },
    },
  });

export function createQueryFn<
  TParams = never,
  TPageParam = any,
  TQueryFnData = unknown,
>(
  key: QueryNameSpaceValue,
  queryFn: (
    context: QueryFunctionContext<[string, TParams], TPageParam>,
  ) => TQueryFnData | Promise<TQueryFnData>,
) {
  queryFn.toString = () => key;
  return queryFn;
}

export function useQ<
  TError = ApiError,
  TParams = never,
  TPageParam = any,
  TQueryFnData = unknown,
>(
  queryFn: (
    context: QueryFunctionContext<[string] | [string, TParams], TPageParam>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  params?: TParams,
  options?: Omit<
    UseQueryOptions<
      TQueryFnData,
      TError,
      TQueryFnData,
      [string] | [string, TParams]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) {
  return useQuery<
    TQueryFnData,
    TError,
    TQueryFnData,
    [string] | [string, TParams]
  >(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function useInfiniteQ<
  TError = ApiError,
  TParams = never,
  TPageParam = any,
  TQueryFnData = unknown,
>(
  queryFn: (
    context: QueryFunctionContext<[string] | [string, TParams], TPageParam>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  params?: TParams,
  options?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TQueryFnData,
      TQueryFnData,
      [string] | [string, TParams]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) {
  return useInfiniteQuery<
    TQueryFnData,
    TError,
    TQueryFnData,
    [string] | [string, TParams]
  >(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function fetchQ<
  TError = ApiError,
  TParams = never,
  TPageParam = any,
  TQueryFnData = unknown,
>(
  queryClient: QueryClient,
  queryFn: (
    context: QueryFunctionContext<[string] | [string, TParams], TPageParam>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  params?: TParams,
  options?: FetchQueryOptions<
    TQueryFnData,
    TError,
    TQueryFnData,
    [string] | [string, TParams]
  >,
) {
  return queryClient.fetchQuery(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function prefetchQ<
  TError = ApiError,
  TParams = never,
  TPageParam = any,
  TQueryFnData = unknown,
>(
  queryClient: QueryClient,
  queryFn: (
    context: QueryFunctionContext<[string] | [string, TParams], TPageParam>,
  ) => TQueryFnData | Promise<TQueryFnData>,
  params?: TParams,
  options?: FetchQueryOptions<
    TQueryFnData,
    TError,
    TQueryFnData,
    [string] | [string, TParams]
  >,
) {
  return queryClient.prefetchQuery(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function fetchInfiniteQ<
  TError = ApiError,
  TParams = never,
  TQueryFnData = unknown,
>(
  queryClient: QueryClient,
  queryFn: QueryFunction<TQueryFnData>,
  params?: TParams,
  options?: FetchInfiniteQueryOptions<TQueryFnData, TError, TQueryFnData>,
) {
  return queryClient.fetchInfiniteQuery(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function prefetchInfiniteQ<
  TError = ApiError,
  TParams = never,
  TQueryFnData = unknown,
>(
  queryClient: QueryClient,
  queryFn: QueryFunction<TQueryFnData>,
  params?: TParams,
  options?: FetchInfiniteQueryOptions<TQueryFnData, TError, TQueryFnData>,
) {
  return queryClient.prefetchInfiniteQuery(
    params ? [queryFn.toString(), params] : [queryFn.toString()],
    queryFn,
    options,
  );
}

export function createMutationFn<TVariables = unknown, TData = unknown>(
  mutationFn: (variables: TVariables) => Promise<TData>,
) {
  return mutationFn;
}

export function useM<
  TVariables = void,
  TData = unknown,
  TError = unknown,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  >,
) {
  return useMutation<TData, TError, TVariables, TContext>(mutationFn, options);
}
