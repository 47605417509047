import { deserializeQuery } from '@sportnet/query-hoc/useQuery';

/**
 * Na vstupe je neparsovany queryString z URL. Funkcia odfiltruje vsetky query parametre,
 * ktore zacinaju retazcom:
 * - `utm`
 * - `rtm`
 * - `ref`
 *
 * POZN.: Zachovavat vsetko okrem vymenovanych je poziadavka M. Cholveu po
 * konzultacii so SEO.
 *
 * Query parametre, ktore pouziva portal vratane futbalnetu (pozor, nemusia byt vsetky,
 * su to len tie ktore som nasiel pri rychlom preklikani)
 * - `page`
 * - `seasonId`
 * - `part`
 * - `tableView`
 * - `sutaz`
 * - `partId`
 * - `year`
 * @returns Canonicka URL pre sekciu
 */

const FORBIDDEN_QUERY_PARAMS = ['utm', 'rtm', 'ref', 'fb'];

const filterQueryParamsForCanonicalUrl = (queryString: string) => {
  const queryObject = queryString ? deserializeQuery(queryString) : null;

  // filter allowed query parameters
  const queryParams = queryObject
    ? Object.entries(queryObject).reduce((acc: string[], [key, val]) => {
        const isForbidden = FORBIDDEN_QUERY_PARAMS.find((param) =>
          key.startsWith(param),
        );
        if (!isForbidden) {
          acc.push(`${key}=${val}`);
        }
        return acc;
      }, [])
    : [];

  return queryParams.join('&');
};

export default filterQueryParamsForCanonicalUrl;
