import { mb } from "@sportnet/ui/lib/Themes/utilities";
import { rem } from "polished";
import styled from "../../theme/styled-components";

const InfiniteArticleListLoader = styled.div`
  min-height: ${rem(300)};
  ${mb('s')} {
    min-height: ${rem(600)};
  }
`;

export default InfiniteArticleListLoader;