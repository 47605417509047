import { mb, mediaSizes } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import { UserPublicProfile } from '../../../../../api/CoreApi';
import SectionsDropDown from '../../../../../components/DropDowns/SectionsDropDown';
import Icon from '../../../../../components/Icon';
import Link from '../../../../../components/Link';
import MaxWidthBox from '../../../../../components/MaxWidthBox';
import config from '../../../../../config';
import LayoutContext from '../../../../../contexts/LayoutContext';
import UrlContext from '../../../../../contexts/UrlContext';
import useIsResponsiveLayout from '../../../../../hooks/useIsResponsiveLayout';
import { SectionNode, SectionTreeType } from '../../../../../library/App';
import CorporateHeader from '../../../../../sportnet.sme.sk-ui/CorporateHeader';
import styled, { css } from '../../../../../theme/styled-components';
import getCallbackPredplatneUrl from '../../../../../utilities/getCallbackPredplatneUrl';
import getColor from '../../../../../utilities/getColor';
import __ from '../../../../../utilities/__';
import {
  getCallbackAuthUrl,
  getCallbackLogoutUrl,
} from '../../../../Authorization';
import Burger from './Burger';
import {
  FIXED_MENU_HEIGHT,
  mainNavigationLinkClass,
  MAIN_MENU_HEIGHT_DESKTOP,
  MAIN_MENU_HEIGHT_TABLET,
  tabletSize,
} from './constants';
import Line from './Line';
import ResponsiveMenu from './ResponsiveMenu';
import Search from './Search';

const StyledCorporateHeader = styled(CorporateHeader)`
  position: relative;
  z-index: 202;
`;

const Wrapper = styled.header<{ responsiveMenuOpened: boolean }>`
  ${({ responsiveMenuOpened }) =>
    responsiveMenuOpened &&
    css`
      > nav {
        max-width: ${rem(280)};
      }
    `};
`;

const MainRowPlaceholder = styled.div<{ shown: boolean }>`
  ${({ shown }) =>
    shown &&
    css`
      min-height: ${rem(104)};
      ${mb(tabletSize)} {
        min-height: ${rem(MAIN_MENU_HEIGHT_DESKTOP + FIXED_MENU_HEIGHT)};
      }
    `}
`;

interface MainRowPositionerProps {
  isFixed: boolean;
  backgroundColor: string;
}

const MainRowPositioner = styled.nav<MainRowPositionerProps>`
  display: flex;
  flex-direction: column;
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  z-index: 101;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      display: none;
    `}
`;

const MainRow = styled(MaxWidthBox)<{ isFixed: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(FIXED_MENU_HEIGHT)};

  ${({ isFixed }) =>
    !isFixed &&
    css`
      min-height: ${rem(MAIN_MENU_HEIGHT_TABLET)};
      ${mb(tabletSize)} {
        min-height: ${rem(MAIN_MENU_HEIGHT_DESKTOP)};
      }
    `}
`;

const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  :active {
    outline: none;
  }
  flex-shrink: 0;
`;

const SearchButton = styled(IconButton)<{ hidden: boolean }>`
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
  ${mb(tabletSize)} {
    margin: 0 0 0 ${rem(8)};
  }
`;

const MainLogoWrapper = styled.a`
  text-decoration: none;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(24)};
  padding: 0;
  ${mb(tabletSize)} {
    margin: 0 ${rem(32)} 0 0;
    height: ${rem(33)};
  }
`;

const SmallMainLogoWrapper = styled(MainLogoWrapper)`
  height: ${rem(24)};
`;

const MainLogo = styled.img`
  height: 100%;
`;

const MoreIcon = styled(Icon)`
  margin: 1px ${rem(8)} 0 0;
`;

const MainNavigationList = styled.ul<{ color: string; activeColor: string }>`
  display: none;
  ${mb(tabletSize)} {
    display: flex;
  }
  flex-wrap: wrap;
  align-items: center;
  flex-shrink: 0;
  margin: ${rem(0)} ${rem(32 - 12)} ${rem(0)} ${rem(-12)};
  padding: 0;
  list-style: none;

  & .${mainNavigationLinkClass} {
    border: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-family: ${({ theme }) => theme.primaryFont.family};
    font-weight: 600;
    text-decoration: none;
    color: inherit;
    outline: none;
    background: none;
    margin: 0;
    padding: ${rem(2)} ${rem(12)};
    font-size: ${rem(14)};
    word-break: break-word;
    color: ${({ color }) => color};
    :hover,
    :active,
    :focus {
      color: ${({ activeColor }) => activeColor};
      ${MoreIcon} svg path {
        fill: ${({ activeColor }) => activeColor};
      }
    }
  }
`;

const MainNavigationListItem = styled.li<{ active: boolean }>`
  display: flex;
  align-items: center;
  list-style-type: none;
  align-self: center;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  text-transform: uppercase;
  :after {
    content: '';
    width: ${rem(1)};
    height: ${rem(16)};
    background: #ddd;
  }
  > a {
    font-weight: bold !important;
    letter-spacing: ${rem(0.28)};
    font-size: ${rem(14)};
    padding: 0 ${rem(16)} !important;
  }
  ${({ active }) =>
    active &&
    css`
      > a {
        color: #cf2528 !important;
      }
    `}
  :last-child {
    :after {
      width: 0;
    }
    button {
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: bold;
    }
  }
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ResponsiveLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mb(tabletSize)} {
    display: none;
  }
`;

const Backdrop = styled.div<{ active: boolean }>`
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.5);

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `};
`;

const ActiveBurgerMenuSection = styled.div`
  padding-left: ${rem(16)};
  font-size: ${rem(12)};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: ${rem(0.96)};
  color: #2f3136;
`;

interface IOwnProps {
  onChangeSearchQuery?: (inputValue: string) => void;
  searchQuery?: string;
  sectionId: number | null;
  location?: string;
  configuration?: {
    textColor?: string;
    logoSrc?: string;
    logoSmallSrc?: string;
    backgroundColor?: string;
    borderColor?: string;
    activeBorderColor?: string;
    logoLinkUrl?: string;
  };
  sections?: SectionTreeType;
  sideMenuItems?: SectionTreeType;
  topBarSections?: SectionTreeType;
  otherSections?: SectionTreeType;
  onSearchSubmit?: () => void;
  onClickLogin?: () => void;
  onClickLogout?: () => void;
  authUser?: UserPublicProfile;
  authUserFetching?: number;
  authUserIsSubscribed?: boolean;
}

type IProps = IOwnProps;

const Header: React.FC<IProps> = (props) => {
  const {
    // authUser,
    onChangeSearchQuery,
    sections = [],
    otherSections = [],
    sideMenuItems = [],
    searchQuery = '',
    onSearchSubmit,
    configuration: { logoSrc = '', logoSmallSrc = '', logoLinkUrl = '/' } = {},
    authUser,
    authUserFetching,
    sectionId,
    location,
    authUserIsSubscribed,
  } = props;
  let {
    configuration: {
      textColor = '',
      backgroundColor = '',
      borderColor = '',
      activeBorderColor = '',
    } = {},
  } = props;

  textColor = getColor(textColor, '#fff');
  backgroundColor = getColor(backgroundColor, '#333');
  borderColor = getColor(borderColor, '#000');
  activeBorderColor = getColor(activeBorderColor, '#3be');

  const [searchActive, setSearchActive] = React.useState(false);
  const [responsiveMenuOpened, setResponsiveMenuOpened] = React.useState(false);
  const [moreSectionsOpened, setMoreSectionsOpened] = React.useState(false);

  const [responsiveMenuActiveSection, setResponsiveMenuActiveSection] =
    React.useState<SectionNode | null>(null);

  const { getSectionUrl } = React.useContext(UrlContext);
  const {
    isMenuFixed,
    setIsMenuFixed,
    isDropdownActive,
    menuPositioner: wrapperElRef,
  } = React.useContext(LayoutContext);

  const findSection = React.useCallback(
    (
      rootSections: SectionTreeType,
      searchSectionId: number
    ): SectionNode | null => {
      const section = rootSections.find((i) => i._id! === searchSectionId);
      if (!section) {
        const rootSubSections = rootSections.reduce((acc, i) => {
          return [...acc, ...(i.sections || [])];
        }, []);
        if (rootSubSections.length) {
          return findSection(rootSubSections, searchSectionId);
        }
        return null;
      }
      return section;
    },
    []
  );

  // const setInitialSectionId = React.useCallback(() => {
  //   if (sectionId && !responsiveMenuActiveSection && responsiveMenuOpened) {
  //     const currentSection = findSection(sections, sectionId);
  //     if (currentSection && (currentSection.sections || []).length) {
  //       setResponsiveMenuActiveSection(currentSection);
  //     } else if (currentSection) {
  //       const currentParentSection = findSection(
  //         sections,
  //         getProp(currentSection, ['parent', 0]),
  //       );
  //       if (currentParentSection) {
  //         setResponsiveMenuActiveSection(currentParentSection);
  //       }
  //     }
  //   }
  // }, [
  //   sectionId,
  //   responsiveMenuOpened,
  //   findSection,
  //   responsiveMenuActiveSection,
  //   sections,
  // ]);

  React.useEffect(() => {
    const setInitialSectionId = () => {
      if (sectionId && !responsiveMenuActiveSection && responsiveMenuOpened) {
        const currentSection = sections.find(
          (section) => section._id === sectionId
        );
        if (currentSection && (currentSection.sections || []).length) {
          setResponsiveMenuActiveSection(currentSection);
        } else if (currentSection) {
          const currentParentSection = findSection(
            sections,
            getProp(currentSection, ['parent', 0])
          );
          if (currentParentSection) {
            setResponsiveMenuActiveSection(currentParentSection);
          }
        }
      }
    };
    setInitialSectionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveMenuOpened]);

  const mobileLayout = useIsResponsiveLayout(992);

  React.useEffect(() => {
    function handleScroll() {
      if (wrapperElRef && wrapperElRef.current) {
        if (isDropdownActive) {
          setIsMenuFixed(false);
        } else {
          const rect = wrapperElRef.current.getBoundingClientRect();
          setIsMenuFixed(mobileLayout ? rect.top - 40 < 0 : rect.top - 56 < 0);
        }
      } else {
        setIsMenuFixed(false);
      }
    }

    handleScroll();
    window.document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [setIsMenuFixed, isDropdownActive, wrapperElRef, mobileLayout, location]);

  const handleCloseMoreSections = React.useCallback(() => {
    setMoreSectionsOpened(false);
  }, []);

  const handleCloseResponsiveMenu = React.useCallback(() => {
    setResponsiveMenuOpened(false);
  }, []);

  const handleCloseSearch = React.useCallback(() => {
    setSearchActive(false);
  }, []);

  const handleCloseAll = React.useCallback(() => {
    setMoreSectionsOpened(false);
    setResponsiveMenuOpened(false);
    setSearchActive(false);
  }, []);

  const handleSearchSubmit = React.useCallback(() => {
    setSearchActive(false);
    setResponsiveMenuOpened(false);
    if (onSearchSubmit) {
      onSearchSubmit();
    }
    if (onChangeSearchQuery) {
      onChangeSearchQuery('');
    }
  }, [onSearchSubmit, onChangeSearchQuery]);

  const handleSearchToggle = () => {
    if (searchActive) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
      // setResponsiveMenuOpened(false);
      setMoreSectionsOpened(false);
    }
  };

  const mainSections = sections.slice(0, config.NUMBER_OF_MAIN_SECTIONS);
  const secondarySections = sections.slice(config.NUMBER_OF_MAIN_SECTIONS);

  const isSectionsChild = (sectionsList: readonly SectionNode[]) => {
    const isFutbalnetChild = location && location.match(/^\/futbalnet/);
    if (
      isFutbalnetChild &&
      getProp(sectionsList, [0, 'uniqid']) === 'Futbalnet'
    ) {
      return true;
    }

    const isSectionChild = sectionsList.find((i) => i._id === sectionId);
    if (!isSectionChild) {
      let isChildOfSectionChild = false;
      sectionsList.some((i) => {
        isChildOfSectionChild = isSectionsChild(i.sections || []);
        return isChildOfSectionChild;
      });
      return isChildOfSectionChild;
    }
    return !!isSectionChild;
  };

  function renderSection(section: SectionNode) {
    return (
      <MainNavigationListItem
        active={isSectionsChild([section])}
        key={section._id}
      >
        <Link
          className={mainNavigationLinkClass}
          to={getSectionUrl({
            ...section,
            _id: section._id!,
            name: section.name!,
          })}
          onClick={handleCloseAll}
        >
          {section.name}
        </Link>
      </MainNavigationListItem>
    );
  }

  const LOGGED_IN_USER_MENU_ITEMS = React.useMemo(
    () => [
      {
        id: 'moj-profil',
        label: 'Môj profil',
        href: 'https://profil.sme.sk/home',
        linkRel: 'nofollow noopener noreferrer',
      },
      {
        id: 'sprava-newslettrov',
        label: 'Správa newslettrov',
        href: 'https://profil.sme.sk/e-maily#sportnet',
        linkRel: 'nofollow noopener noreferrer',
      },
      {
        id: 'online-predplatne',
        label: 'Online predplatné',
        href: 'https://profil.sme.sk/predplatne',
        linkRel: 'nofollow noopener noreferrer',
      },
      {
        id: 'odhlsenie',
        label: 'Odhlásenie',
        href: getCallbackLogoutUrl(),
        linkRel: 'nofollow noopener noreferrer',
      },
    ],
    []
  );

  return (
    <>
      <Wrapper responsiveMenuOpened={responsiveMenuOpened}>
        {/* LOGIN */}
        {!responsiveMenuOpened && (
          <>
            <StyledCorporateHeader
              subscriptionUrl={getCallbackPredplatneUrl({
                ref: 'corp_bar_sportnet',
              })}
              isInitialized={true}
              subscriptionText={config.SUBSCRIPTION_TEXT}
              loginUrl={getCallbackAuthUrl()}
              loginText={config.LOGIN_TEXT}
              isUserLoading={authUserFetching}
              isSubscribed={authUserIsSubscribed}
              username={authUser?.email}
              loggedInMenuItems={LOGGED_IN_USER_MENU_ITEMS}
            />
            <MainRowPlaceholder shown={isMenuFixed} />
          </>
        )}
        <MainRowPositioner
          isFixed={isMenuFixed}
          backgroundColor={backgroundColor}
        >
          <MainRow isFixed={isMenuFixed}>
            <ResponsiveLeftWrapper>
              <Burger
                color={textColor}
                active={responsiveMenuOpened}
                subMenuActive={!!responsiveMenuActiveSection}
                onToggle={() => {
                  if (responsiveMenuOpened && responsiveMenuActiveSection) {
                    const parentId = getProp(
                      responsiveMenuActiveSection,
                      ['parent', 0],
                      null
                    );
                    if (parentId) {
                      const parent = findSection(sections, parentId);
                      setResponsiveMenuActiveSection(parent);
                    }
                  } else if (responsiveMenuOpened) {
                    setResponsiveMenuOpened(false);
                    setIsMenuFixed(false);
                  } else {
                    window.scroll({ top: 0 });
                    setResponsiveMenuOpened(true);
                    setMoreSectionsOpened(false);
                    setSearchActive(false);
                    setIsMenuFixed(false);
                  }
                }}
              />
              {responsiveMenuOpened && (
                <ActiveBurgerMenuSection>
                  {responsiveMenuActiveSection
                    ? responsiveMenuActiveSection.name
                    : __('Menu')}
                </ActiveBurgerMenuSection>
              )}
            </ResponsiveLeftWrapper>
            {logoSrc && !responsiveMenuOpened && !isMenuFixed && (
              <MainLogoWrapper href={logoLinkUrl} onClick={handleCloseAll}>
                <MainLogo alt="logo" src={logoSrc} />
              </MainLogoWrapper>
            )}
            {(logoSmallSrc || logoSrc) &&
              isMenuFixed &&
              !responsiveMenuOpened && (
                <SmallMainLogoWrapper
                  href={logoLinkUrl}
                  onClick={handleCloseAll}
                >
                  <MainLogo alt="logo" src={logoSmallSrc || logoSrc} />
                </SmallMainLogoWrapper>
              )}

            <MainNavigationList
              color={textColor}
              activeColor={activeBorderColor}
            >
              {mainSections.map(renderSection)}
              {secondarySections.length > 0 && (
                <MainNavigationListItem>
                  <SectionsDropDown
                    sections={secondarySections.map((i) => ({
                      ...i,
                      url: getSectionUrl({ ...i, _id: i._id!, name: i.name! }),
                    }))}
                    opened={moreSectionsOpened}
                    onClose={handleCloseMoreSections}
                    color={textColor}
                    activeColor={activeBorderColor}
                  >
                    <button
                      className={mainNavigationLinkClass}
                      onClick={() => {
                        if (moreSectionsOpened) {
                          setMoreSectionsOpened(false);
                        } else {
                          setResponsiveMenuOpened(false);
                          setMoreSectionsOpened(true);
                          setSearchActive(false);
                        }
                      }}
                    >
                      <MoreIcon name="more" size={12} color="#CF2528" />
                      {__('VIAC')}
                    </button>
                  </SectionsDropDown>
                </MainNavigationListItem>
              )}
            </MainNavigationList>
            <RightWrapper>
              {onChangeSearchQuery && (
                <SearchButton
                  onClick={handleSearchToggle}
                  data-testid="CL_Search_Icon_Search"
                  hidden={false}
                >
                  <Icon
                    name={searchActive ? 'close' : 'search'}
                    size={responsiveMenuOpened ? 16 : 24}
                    color={textColor}
                  />
                </SearchButton>
              )}
            </RightWrapper>
          </MainRow>
          {!isMenuFixed && (
            <Line
              {...(!responsiveMenuOpened && { showOnBreakPoint: mediaSizes.l })}
              height={1}
              color={borderColor}
            />
          )}
          {onChangeSearchQuery && (
            <Search
              onSearchSubmit={handleSearchSubmit}
              searchQuery={searchQuery}
              textColor={textColor}
              borderColor={borderColor}
              backgroundColor={backgroundColor}
              onChangeSearchQuery={onChangeSearchQuery}
              searchActive={searchActive}
              onClose={handleCloseSearch}
              isMenuFixed={isMenuFixed}
            />
          )}
          <ResponsiveMenu
            opened={responsiveMenuOpened}
            textColor={textColor}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
            activeBorderColor={activeBorderColor}
            sections={sections}
            otherSections={otherSections}
            sideMenuItems={responsiveMenuActiveSection ? [] : sideMenuItems}
            onClose={handleCloseResponsiveMenu}
            isMenuFixed={isMenuFixed}
            activeSection={responsiveMenuActiveSection}
            setActiveSection={setResponsiveMenuActiveSection}
            searchActive={searchActive}
          />
        </MainRowPositioner>
      </Wrapper>
      <Backdrop
        active={searchActive || responsiveMenuOpened}
        onClick={() => {
          handleCloseAll();
          if (onChangeSearchQuery) {
            onChangeSearchQuery('');
          }
        }}
      />
    </>
  );
};

export default React.memo(Header);
