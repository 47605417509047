import { QueryHocInterface } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { formatUserName } from 'sportnet-utilities';
import AdManager from '../../components/Ads/AdManager';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import RempIntegration from '../../containers/RempIntegration';
import getMemberBaseUrl from '../../utilities/getMemberBaseUrl';
import { fetchCodeList } from '../App/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NotFound from '../NotFound';
import Header from './Header';
import { currentMemberSelector } from './selectors';
import TabPages from './TabPages';

const mapStateToProps = (state: RootState) => ({
  member: currentMemberSelector(state),
  appSettings: appSettingsSelector(state),
});

type Props = {} & {
  dispatch: CustomThunkDispatch;
} & ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ id: string }, {}> &
  QueryHocInterface;

class MemberDetail extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    return Promise.all([
      props.dispatch(fetchCodeList.action({ codeListID: 'country' })),
    ]);
  }

  async componentDidMount() {
    try {
      await MemberDetail.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }
  render() {
    const {
      params: { id },
      member,
      appSettings,
      location: { pathname, hash },
      children,
    } = this.props;

    if (!member) {
      return <NotFound />;
    }

    const title = formatUserName(member);

    const siteId = `${pathname}${hash}`;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta
            property="og:url"
            content={`${appSettings.baseUri}${getMemberBaseUrl({
              clen: id,
            })}`}
          />
          <meta property="og:title" content={title} />
          <meta property="og:type" content="website" />
        </Helmet>
        <RempIntegration destroy />
        <AdManager
          site="futbalnet_clen"
          siteId={siteId}
          origin="futbalnet"
          pagetype="other"
        />
        <Header />
        <TabPages />
        {children}
      </>
    );
  }
}

export default connect(mapStateToProps)(MemberDetail);
