import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { getListResults, isCommiting } from '@sportnet/redux-list/ducks';
import ArticlesListBasic from '../../components/Articles/ArticlesListBasic';
import ArticlesListNumbered from '../../components/Articles/ArticlesListNumbered';
import ArticlesListTimeline from '../../components/Articles/ArticlesListTimeline';
import ButtonGroup from '../../components/ButtonGroup';
import Loading from '../../components/Loading';
import Segment from '../../components/Segment';
import Tabber from '../../components/Tabber';
import config from '../../config';
import { RootState } from '../../configureStore';
import { ITopArticlesSidebarTab } from '../../hooks/useTopArticlesSidebar';
import { IArticle } from '../../library/App';
import AppUrlContextProvider from '../../pages/App/AppUrlContextProvider';
import {
  newestArticlesSelector,
  topArticlesSelector,
} from '../../pages/Article/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const TopArticlesButtonGroup = styled(ButtonGroup)`
  margin: 0 0 ${rem(24)} 0;
  justify-content: center;
`;

const PositionedTabber = styled(Tabber)`
  && {
    margin: ${rem(8)} 0 ${rem(24)} 0;
    ${mb('m')} {
      margin: ${rem(8)} 0 ${rem(24)} 0;
    }
  }
`;

const PaddedSegment = styled(Segment)`
  padding-top: 0;
`;

// Styly pre banner
// const Banner = styled.a`
//   display: block;
//   padding-bottom: ${rem(32)};
//   > img {
//     width: 100%;
//   }
//   ${mb('l')} {
    
//     > img {
//       max-width: ${rem(300)};
//     }
//   }
// `;

type ITabName = 'top' | 'newest' | 'premium' | 'event';

type IOwnProps = {
  className?: string;
  activeTab: ITopArticlesSidebarTab;
  setActiveTab: (tab: ITopArticlesSidebarTab) => void;
  tabs?: Array<{
    label: string | JSX.Element;
    value: ITabName;
  }>;
};

const mapStateToProps = (state: RootState) => ({
  topArticles: topArticlesSelector(state),
  newestArticles: newestArticlesSelector(state),
  premiumArticles: (getListResults(config.LIST_PREMIUM_ARTICLES)(state) ||
    []) as IArticle[],
  eventArticles: (getListResults(config.LIST_EVENT_ARTICLES)(state) ||
    []) as IArticle[],
  isLoadingTopArticles: isCommiting(config.LIST_TOP_ARTICLES)(state) !== false,
  isLoadingNewestArticles:
    isCommiting(config.LIST_NEWEST_ARTICLES)(state) !== false,
  isLoadingPremiumArticles:
    isCommiting(config.LIST_PREMIUM_ARTICLES)(state) !== false,
  isLoadingEventArticles:
    isCommiting(config.LIST_EVENT_ARTICLES)(state) !== false,
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const TopArticlesSidebar: React.FC<IProps> = ({
  topArticles,
  newestArticles,
  premiumArticles,
  eventArticles,
  isLoadingTopArticles,
  isLoadingNewestArticles,
  isLoadingPremiumArticles,
  isLoadingEventArticles,
  className,
  activeTab,
  setActiveTab,
  tabs = [
    { label: __('Najčítanejšie'), value: 'top' },
    { label: __('Najnovšie'), value: 'newest' },
  ],
}) => {
  const [activeTabType, activeTabSubtype] = React.useMemo(() => {
    const invalidActiveTabType = activeTab.split('|')[0];

    const validActiveTab = tabs.find((t) => t.value === invalidActiveTabType)
      ? activeTab
      : 'top|4';

    return validActiveTab.split('|') as [ITabName, string];
  }, [tabs, activeTab]);

  return (
    <AppUrlContextProvider>
      <div className={className}>
        {/* Priestor pre obrázkový banner. 
        <Banner
          href={`${getBaseUrl()}/spravy/hokej-e-kniha-sprievodca-draft-nhl-2022-oh-my-hockey-nemec-mesar-slafkovsky/?utm_source=sportnet-sk&utm_medium=banner&utm_campaign=draft-nhl-2022`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/banner_sprievodca.png" alt="banner sprievodca" />
        </Banner> */}
        <PaddedSegment padding={24}>
          <PositionedTabber
            centered
            onChange={(tab: { value: string }) => {
              if (tab.value === 'top') {
                setActiveTab('top|4');
              } else {
                setActiveTab(tab.value as ITopArticlesSidebarTab);
              }
            }}
            active={activeTabType}
            tabs={tabs}
          />

          {activeTabType === 'event' ? (
            <>
              {isLoadingEventArticles && eventArticles.length === 0 ? (
                <Loading minHeight={rem(290)} />
              ) : (
                <ArticlesListTimeline articles={eventArticles} />
              )}
            </>
          ) : activeTabType === 'premium' ? (
            <>
              {isLoadingPremiumArticles && premiumArticles.length === 0 ? (
                <Loading minHeight={rem(176)} />
              ) : (
                <ArticlesListBasic articles={premiumArticles} />
              )}
            </>
          ) : activeTabType === 'newest' ? (
            <>
              {isLoadingNewestArticles && newestArticles.length === 0 ? (
                <Loading minHeight={rem(290)} />
              ) : (
                <ArticlesListTimeline articles={newestArticles} />
              )}
            </>
          ) : (
            <>
              <TopArticlesButtonGroup
                onChange={({ value }: { value: string }) =>
                  setActiveTab(`top|${value}` as ITopArticlesSidebarTab)
                }
                active={activeTabSubtype}
                buttons={[
                  { label: __('4 hod'), value: '4' },
                  { label: __('24 hod'), value: '24' },
                  { label: __('7 dní'), value: '168' },
                ]}
              />
              {isLoadingTopArticles && topArticles.length === 0 ? (
                <Loading minHeight={rem(256)} />
              ) : (
                <ArticlesListNumbered articles={topArticles} />
              )}
            </>
          )}
        </PaddedSegment>
      </div>
    </AppUrlContextProvider>
  );
};

export default connect(mapStateToProps)(TopArticlesSidebar);
