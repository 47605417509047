import React from 'react';
import Link from '../Link';
import UrlContext from '../../contexts/UrlContext';

export default (link: any) => {
  const { getSectionUrl, getArticleUrl } = React.useContext(UrlContext);

  if (link.type === 'article_detail') {
    return (
      <Link to={getArticleUrl(link.payload)} {...link.linkProps}>
        {link.linkProps.children}
      </Link>
    );
  } else if (link.type === 'article_filter') {
    return <div {...link.linkProps}>{link.linkProps.children}</div>;
  } else if (link.type === 'section_detail') {
    return (
      <Link to={getSectionUrl(link.payload)} {...link.linkProps}>
        {link.linkProps.children}
      </Link>
    );
  }
  return null;
};
