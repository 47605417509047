import urlConverter from '../urlConverter';
import { IResolution } from '../api/FutbalnetApi';

export default ({
  zvaz,
  resolution,
}: {
  zvaz: string | number;
  resolution?: IResolution;
}) => {
  if (!resolution) {
    return null;
  }
  let unionId = zvaz;
  const convertedUnionData = urlConverter.find(
    (i) => i._id === zvaz || i.__issfId === Number(zvaz),
  );
  if (convertedUnionData) {
    unionId = convertedUnionData.url;
  }
  const commiteeId = resolution.commision?.id || ''; // BAD URL
  const seasonId = resolution.season?.txtid || ''; // BAD URL
  const resolutionNumber = resolution.number.replace('/', '|') || '';
  return `/futbalnet/z/${unionId}/komisia/${commiteeId}/rozhodnutie/${seasonId}/${resolutionNumber}/`;
};
