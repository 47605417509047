import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import AdManager from '../../components/Ads/AdManager';
import BigAd from '../../components/Ads/BigAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import TopAd from '../../components/Ads/TopAd';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
  TopAndBottomPaddedSidebarLayout,
} from '../../components/Layout/SidebarLayout';
import Title from '../../components/Title';
import config from '../../config';
import { RootState } from '../../configureStore';
import { ROOT_BREADCRUMB } from '../../containers/SectionsBreadcrumbs';
import InfiniteArticleList from '../../containers/InfiniteArticleList';
import TopArticlesSidebar from '../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../hooks/useTopArticlesSidebar';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { loadArticlesList } from '../Article/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import SideAd from '../../components/Ads/SideAd';
import TopicTags from '../Home/TopicTags';
import NewsFeedArticlesHeader from '../../components/Articles/NewsFeedArticlesHeader';

const NotFoundSubTitle = styled.p`
  font-family: pt-serif-pro, serif;
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 300;
  text-decoration: none;
  word-break: break-word;
  padding: 0;
  margin: 0;
  text-align: center;

  font-size: ${rem(17)};
  line-height: ${rem(25.5)};
  ${mb('s')} {
    font-size: ${rem(19)};
    line-height: ${rem(28.5)};
  }
`;

const NotFoundTitle = styled(Title)`
  margin: 0 0 ${rem(24)} 0;
  ${mb('s')} {
    margin: 0 0 ${rem(32)} 0;
  }
  text-align: center;
`;

const ResponsiveTopArticlesSidebar = styled(TopArticlesSidebar)`
  margin: 0 0 ${rem(48)} 0;
  ${mb('l')} {
    display: none;
  }
`;

const MaxWidthContainer = styled.div`
  max-width: ${rem(680)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${rem(300)};

  margin: 0 auto ${rem(48)} auto;
  ${mb('s')} {
    margin: 0 auto ${rem(56)} auto;
  }
`;

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  initializeOrSetListParams: initializeOrSetListParams.action,
  loadArticlesList,
};
type IMapDispatchToProps = typeof mapDispatchToProps;

type Props = IMapStateToProps &
  IMapDispatchToProps &
  RouteComponentProps<{}, {}>;

const NotFound: React.FC<Props> = ({
  initializeOrSetListParams,
  loadArticlesList,
  appSettings,
  location: { pathname },
}) => {
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_NEWS_FEED_ARTICLES,
      params: {
        offset: 0,
        limit: config.LIST_NEWS_FEED_ARTICLES_LIMIT,
      },
    });
    await loadArticlesList(config.LIST_NEWS_FEED_ARTICLES);
  }, [initializeOrSetListParams, loadArticlesList]);

  const title = __('Ľutujeme, stránka sa nenašla');
  const subtitle = __(
    'Stránka, ktorú hľadáte, nie je k dispozícii, alebo bola presunutá. Skontrolujte prosím zadanú url, alebo použite vyhľadávanie vpravo hore.',
  );

  return (
    <>
      <AdManager site="topic" siteId={'not-found-page'} pagetype="list" />

      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={`${appSettings.baseUri}${pathname}`} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="section" />
        <meta name="description" content={subtitle} />
      </Helmet>

      <TopicTags />

      <BigAd name="big_1" />
      <TopAd />

      <TopAndBottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <MaxWidthContainer>
            <NotFoundTitle>{title}</NotFoundTitle>
            <NotFoundSubTitle>{subtitle}</NotFoundSubTitle>
          </MaxWidthContainer>
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <NewsFeedArticlesHeader />
          <InfiniteArticleList
            listName={config.LIST_NEWS_FEED_ARTICLES}
            widthAd={true}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <TopArticlesSidebar
              activeTab={activeTabTopArticles}
              setActiveTab={setActiveTabTopArticles}
            />
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </TopAndBottomPaddedSidebarLayout>
      <MegaboardAd />
      <Breadcrumbs
        crumbs={[ROOT_BREADCRUMB, { label: 'Stránka sa nenašla' }]}
      />
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NotFound);
