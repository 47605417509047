import { ThirdPartyGrouppedEvents, ThirdPartyResultsOdd, ThirdPartyResultsSegment, ThirdPartyGroupedEventsEvent, ThirdPartyEvent } from '../library/thirdPartyResults';
import { NikeMatchProps, NikeSportnetApiResponse } from '../library/nike';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../query';
import config from '../config';
import formatDate from '../utilities/formatDate';

export const COUNTRY_SLOVAKIA = 4;

type loadNikeEventsParams = {
  date?: Date;
  segment: ThirdPartyResultsSegment;
  sportSlug?: string;
  topCount?: number;
  region?: string;
  oddTypes?: Array<ThirdPartyResultsOdd['type']>;
};

const parseTeams = (
  participants?: NikeMatchProps['participants'],
): [string, string] | undefined => {
  const participantHome =
    Array.isArray(participants) && participants[0] !== undefined
      ? participants[0]
      : undefined;
  const participantAway =
    Array.isArray(participants) && participants[0] !== undefined
      ? participants[1]
      : undefined;

  return [
    participantHome?.participantName ?? '',
    participantAway?.participantName ?? '',
  ];
};

const parseTeamsIds = (
  participants?: NikeMatchProps['participants'],
): [number | undefined, number | undefined] | undefined => {
  const participantHome =
    Array.isArray(participants) && participants[0] !== undefined
      ? participants[0]
      : undefined;
  const participantAway =
    Array.isArray(participants) && participants[0] !== undefined
      ? participants[1]
      : undefined;

  return [
    participantHome?.participantId,
    participantAway?.participantId,
  ];
};

const ALLOWED_ODD_TYPES = ['1', 'X', '2'];

const parseOdds = (nikeMatchSelections: NikeMatchProps['selections']) => {
  return nikeMatchSelections.reduce((acc: ThirdPartyResultsOdd[], nikeMatchSelection) => {
    if (ALLOWED_ODD_TYPES.includes(nikeMatchSelection.name)) {
      acc.push({
        rate: nikeMatchSelection.odds,
        type: nikeMatchSelection.name as ThirdPartyResultsOdd['type'],
        id: nikeMatchSelection.code,
        winning: nikeMatchSelection.winning,
        url: nikeMatchSelection.url,
      });
    }
    return acc;
  }, []);
};

const parseMatchType = (nikeMatchType: NikeMatchProps['type']) => {
  return (
    nikeMatchType === 'Result' ? 'results' : 'offers'
  ) as ThirdPartyGroupedEventsEvent['type'];
};

const parseScore = (
  nikeMatchScore: NikeMatchProps['score'],
): ThirdPartyEvent['results'] => {
  if (nikeMatchScore !== undefined) {
    return {
      restot: [
        {
          type: '1',
          score: nikeMatchScore.home ?? '',
        },
        {
          type: '2',
          score: nikeMatchScore.away ?? '',
        },
      ],
    };
  }
  return undefined;
};

const fetchNikeData = async (url: string) => {
  try {
    const response = await fetch(url);
    const responseBody = (await response.json()) as NikeSportnetApiResponse;
    return responseBody;
  } catch (e) {
    throw e;
  }
};



export const fetchAndParseNikeData = async (
  params: loadNikeEventsParams,
): Promise<ThirdPartyGrouppedEvents[]> => {
  const { segment, date, sportSlug, topCount, region } = params;

  if (!segment) {
    throw new Error('Provide required parameters [loadDoxxBet]!');
  }

  const queryParams = new URLSearchParams();

  if (date) {
    queryParams.set('date', formatDate(date, 'yyyy-MM-dd'));
  }

  if (sportSlug) {
    queryParams.set('sport', sportSlug);
  }

  if (region && !isNaN(Number(region))) {
    queryParams.set('countryId', region);
  }

  if (topCount !== undefined && topCount > 0) {
    queryParams.set('top', String(topCount));
  }

  if (segment === 'offers') {
    queryParams.set('prematch', 'true');
  }

  if (segment === 'results') {
    queryParams.set('results', 'true');
  }

  if (segment === 'live') {
    queryParams.set('live', 'true');
  }

  if (segment === 'all') {
    queryParams.set('prematch', 'true');
    queryParams.set('results', 'true');
    queryParams.set('live', 'true');
  }

  const nikeGrouppedMatches = await fetchNikeData(
    `${config.nike.API_BASE_URL}/sportnet?${queryParams.toString()}`,
  );

  return nikeGrouppedMatches.map((nikeGrouppedMatch) => {
    const group: ThirdPartyGrouppedEvents = {
      sport: nikeGrouppedMatch.sportId,
      sportSlug: nikeGrouppedMatch.sportSlug,
      region: nikeGrouppedMatch.countryId,
      leagueCup: nikeGrouppedMatch.tournamentId,
      path: [
        nikeGrouppedMatch.sportName,
        nikeGrouppedMatch.countryName,
        nikeGrouppedMatch.tournamentName,
      ],
      events: [],
    };

    const groupEvents: ThirdPartyGroupedEventsEvent[] =
      nikeGrouppedMatch.matches.map((match) => {
        return {
          id: match.matchId,
          name: match.name,
          type: parseMatchType(match.type),
          teams: parseTeams(match.participants),
          thirdpartyTeamIds: parseTeamsIds(match.participants),
          date: match.date,
          sport: group.sport,
          sportSlug: group.sportSlug,
          region: group.region,
          leagueCup: group.leagueCup,
          path: group.path,
          odds: parseOdds(match.selections),
          sportName: nikeGrouppedMatch.sportName,
          live: match.type === 'Live',
          gamePart: '',
          livePart: '',
          results: parseScore(match.score),
          statsUrl: match.statsUrl,
          matchUrl: match.matchUrl,
          isNike: true,
          stream: match.stream,
          infocenter: match.infocenter,
          currentPeriod: match.currentPeriod,
          timerMinutes: match.timerMinutes,
        };
      });

    group.events = groupEvents;

    return group;
  });
};

const loadNikeEvents = createQueryFn(
  QueryNameSpace.thirdPartyResults,
  async ({
    queryKey,
  }: QueryFunctionContext<[string, any]>): Promise<ThirdPartyGrouppedEvents[]> => {
    const [, params] = queryKey;
    return fetchAndParseNikeData(params);
  },
);

export default loadNikeEvents;
