import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import React from 'react';
import { ISection } from '../../../library/App';
import styled from 'styled-components';

const Image = styled.img``

type IOwnProps = {
  alt: string;
  icon?: Required<ISection>['icon'];
  size?: 24 | 32;
};

type IProps = IOwnProps;

const SectionBlockIcon: React.FC<IProps> = ({ icon, alt, size = 32 }) => {
  if (!icon) {
    return null;
  }
  return (
    <Image
      src={getMediaManagerUrl(icon, size * 2, size * 2, 'media', false)}
      alt={alt}
      width={size}
      height={size}
      style={{ flexShrink: 0 }}
    />
  );
};

export default SectionBlockIcon;
