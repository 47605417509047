import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../theme/styled-components';

type ISpaceDirection = 'horizontal' | 'vertical';
type ISpaceSize = 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 4 | 7 | 8;

interface OwnProps {
  direction?: ISpaceDirection;
  size?: ISpaceSize;
}

const Wrapper = styled.div<{ $direction: ISpaceDirection; $size: ISpaceSize }>`
  & > * {
    ${({ $direction, $size, theme }) =>
      $direction === 'horizontal'
        ? css`
            margin-right: ${rem((theme.grid.gutterWidth / 4) * $size)};
          `
        : css`
            margin-bottom: ${rem((theme.grid.gutterWidth / 4) * $size)};
          `}
  }
`;

const Spacer: React.FC<OwnProps> = ({
  children,
  direction = 'vertical',
  size = 1,
}) => {
  return (
    <Wrapper $direction={direction} $size={size}>
      {children}
    </Wrapper>
  );
};

export default Spacer;
