import { BlockSpaBricks } from '../../library/Petitpress';
import { QueryNameSpace, createQueryFn } from '../../query';
import getPetitpressEnv from '../../utilities/petitpress/getPetitpressEnv';

export const getBrickSpaBlocks = createQueryFn(
  QueryNameSpace.brickSpaBlocks,
  async () => {
    const endpointUrl = `${
      process.env.REACT_APP_PETITPRESS_API_URL
    }/brick-spa-blocks?env=${getPetitpressEnv()}`;

    const response = await fetch(endpointUrl);

    try {
      return (await response.json()) as BlockSpaBricks;
    } catch (e) {
      console.error(`getBrickSpaBlocks: ${(e as Error).message}`);
      return {
        'sme-header': [],
        'sme-pager': [],
      };
    }
  },
);
