import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { __ } from '@sportnet/content/utilities/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { Link } from 'react-router';
import { IComiteeListItem } from '../../api/FutbalnetApi';
import styled from '../../theme/styled-components';
import ContentNotFound from '../ContentNotFound/withAnimation';

const Wrapper = styled.div`
  padding: ${rem(16)} ${rem(16)} ${rem(16)} ${rem(16)};
  background: #fff;
  box-shadow: 0px ${rem(2)} ${rem(24)} #00000029;
  border-radius: 0px 0px ${rem(4)} ${rem(4)};
  ${mb('m')} {
    max-height: ${rem(600)};
    overflow: auto;
  }
`;

const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  ${mb('xs')} {
    overflow-x: hidden;
  }
  flex-wrap: wrap;
`;

const MenuItem = styled(Link)`
  font-size: ${rem(14)};
  width: 100%;

  ${mb('m')} {
    width: 25%;
  }

  :hover {
    color: ${({ theme }) => theme.color.danger};
    background-color: rgb(207, 37, 40, 0.04);
  }
`;

const MenuItemLabel = styled.div`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(11)};
  padding-left: ${rem(16)};
`;

interface IOwnProps {
  union: string;
  comitees: Array<Readonly<IComiteeListItem>>;
}

type IProps = IOwnProps;

const ComiteeMenu: React.FC<IProps> = ({ union, comitees }) => {
  const renderMenu = () => {
    return (
      <>
        {(comitees || []).map((comitee) => (
          <MenuItem
            key={comitee.id}
            to={`/futbalnet/z/${union}/komisia/${comitee.id}/`}
          >
            <MenuItemLabel>{comitee.name}</MenuItemLabel>
          </MenuItem>
        ))}
      </>
    );
  };

  return (
    <Wrapper>
      <MenuWrapper>
        {comitees ? (
          renderMenu()
        ) : (
          <ContentNotFound title={__('Nenašli sa žiadne komisie')} />
        )}
      </MenuWrapper>
    </Wrapper>
  );
};

export default ComiteeMenu;
