import { rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle } from '../../../library/App';
import styled from '../../../theme/styled-components';
import Link from '../../Link';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li`
  display: flex;
  :not(:last-child) {
    margin-bottom: ${rem(24)};
  }
`;

const ArticleLink = styled(Link)`
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.textColor};
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  cursor: pointer;
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: #1d81c4;
  }
`;

type IOwnProps = {
  articles: IArticle[];
};

type IProps = IOwnProps;

const ArticlesListBasic: React.FC<IProps> = ({ articles }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  function renderArticle(article: IArticle) {
    return (
      <ListItem key={`${article._id}-${article.modified}`}>
        <ArticleLink to={getArticleUrl(article)}>{article.name}</ArticleLink>
      </ListItem>
    );
  }

  return <List>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesListBasic);
