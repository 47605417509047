import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import Loading from '../../components/Loading';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import Redirect from '../Redirect';
import { loadMember, setCurrentMemberId } from './actions';
import FutbalnetApi from './api';
import {
  currentMemberErrorSelector,
  currentMemberIsFetchingSelector,
  currentMemberSelector,
} from './selectors';

const mapStateToProps = (state: RootState) => ({
  isFetching: currentMemberIsFetchingSelector(state) !== false,
  member: currentMemberSelector(state),
  error: currentMemberErrorSelector(state)
});

type Props = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{ id: string }, {}> & {
    dispatch: CustomThunkDispatch;
  };

class Member extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      params: { id },
      dispatch,
    } = props;
    if (process.env.REACT_APP_FUTBALNET_API_BASE_URL) {
      FutbalnetApi.setBaseUrl(process.env.REACT_APP_FUTBALNET_API_BASE_URL);
    }
    await Promise.all([
      dispatch(setCurrentMemberId(id)),
      dispatch(loadMember({ id })),
    ]);
  }

  async componentDidMount() {
    try {
      await Member.getInitialProps(this.props);
    } catch (e: any) {
      //
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.id !== prevProps.params.id) {
      await Member.getInitialProps(this.props);
    }
  }

  render() {
    const { children, isFetching, member, error } = this.props;

    if (isFetching && !member) {
      return <Loading />;
    }

    if (error && error.identifier === 'REDIRECT') {
      return <Redirect to={error.payload.location} />;
    }
    

    return children;
  }
}

export default compose(connect(mapStateToProps))(Member);
