import Checkbox from '@sportnet/ui/lib/Checkbox';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css, withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import Icon from '../Icon';
import { Table, Tbody, Th, Thead, Tr } from '../Table';
import BasicTableRow from './BasicTableRow';

const isBrowser = typeof window !== 'undefined';
const Slip = isBrowser ? require('slipjs') : undefined;

interface Column {
  header: string;
  center?: boolean;
  width?: number;
  sortable?: boolean;
  sort?: 'asc' | 'desc';
  [key: string]: any; // developer is able to add custom properties to header
}

type Row = React.ReactNode;

interface Selected {
  [key: string]: boolean;
}

interface IOwnProps {
  compact?: boolean;
  sticky?: boolean;
  columns: Column[];
  rows: any[];
  onClickRow?: (originalItem: any, rowIdx: number) => any;
  renderRow: (item: any, rowIdx: number) => Row[];
  onReorder?: (rows: any[]) => void;
  rowKey?: string;
  onSort?: (col: Column, newDirection: Column['sort']) => void;
  selected?: Selected;
  onSelect?: (selected: Selected) => void;
}

const StyledTh = styled(Th)<{
  compact?: boolean;
  sticky?: boolean;
  center?: boolean;
  onClick?: (a: any) => void;
  width?: number;
}>`
  ${({ sticky, theme }) =>
    sticky &&
    css`
      position: sticky;
      left: 0;
      background: ${theme.tables.headerBg};
    `}
  ${({ compact }) =>
    !!compact &&
    css`
      padding: ${rem(6)};
    `}
  ${({ center }) =>
    !!center &&
    css`
      > div {
        justify-content: center;
      }
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const ThContent = styled('div')`
  display: flex;
`;

const ThPointer = styled(Th)`
  cursor: pointer;
`;

export const IsolatedCheckBox = styled(Checkbox)`
  margin: 0;
`;

type IProps = IOwnProps & { theme: ITheme };

const BasicTable: React.FC<IProps> = ({
  columns,
  rows,
  onReorder,
  theme,
  onSort,
  renderRow,
  rowKey,
  onClickRow,
  onSelect,
  selected,
  compact,
  sticky,
}) => {
  const uniq = React.useRef(0);
  const slipjsInstance = React.useRef<any>(null);
  const tbodyEl = React.useRef<HTMLTableSectionElement>(null);

  const selectedRef = React.useRef(selected || {});
  selectedRef.current = selected || {};
  const handleSelectRow = React.useCallback(
    (row: any, rowIdx: number) => {
      if (onSelect) {
        const key = rowKey ? row[rowKey] : rowIdx;
        onSelect({
          ...selectedRef.current,
          [key]: !selectedRef.current[key],
        });
      }
    },
    [onSelect, rowKey],
  );
  const anyRowSelected =
    !!selected && Object.keys(selected).some((id) => selected[id]);
  const handleSelectAllRows = React.useCallback(() => {
    if (onSelect) {
      const newSelected = anyRowSelected
        ? {}
        : rows.reduce((acc: Selected, row, rowIdx) => {
            const key = rowKey ? row[rowKey] : rowIdx;
            acc[key] = true;
            return acc;
          }, {});
      onSelect(newSelected);
    }
  }, [anyRowSelected, onSelect, rows, rowKey]);

  React.useEffect(() => {
    if (!rowKey) {
      console.warn(
        'Using BasicTable without defined rowKey. Table operations might not work as intended.',
      );
    }
  }, [rowKey]);

  React.useEffect(() => {
    const _tbodyEl = tbodyEl.current;

    function beforereorder(e: any) {
      if (e.target.closest('td.drag-handle-cell') === null) {
        e.preventDefault();
      }
    }

    function beforewait(e: any) {
      e.preventDefault();
    }

    function beforeswipe(e: any) {
      e.preventDefault();
    }

    function reorder(e: any) {
      if (onReorder) {
        const nextRows = [...rows];
        nextRows.splice(e.detail.originalIndex, 1);
        nextRows.splice(e.detail.spliceIndex, 0, rows[e.detail.originalIndex]);
        e.target.parentNode.insertBefore(e.target, e.detail.insertBefore);
        onReorder(nextRows);
      }
      return false;
    }

    if (_tbodyEl && onReorder) {
      _tbodyEl.addEventListener('slip:beforereorder', beforereorder, false);
      _tbodyEl.addEventListener('slip:beforeswipe', beforeswipe, false);
      _tbodyEl.addEventListener('slip:beforewait', beforewait, false);
      _tbodyEl.addEventListener('slip:reorder', reorder, false);
      slipjsInstance.current = new Slip(_tbodyEl);
    }
    return () => {
      if (_tbodyEl && onReorder) {
        _tbodyEl.removeEventListener('slip:beforereorder', beforereorder);
        _tbodyEl.removeEventListener('slip:beforeswipe', beforeswipe);
        _tbodyEl.removeEventListener('slip:beforewait', beforewait);
        _tbodyEl.removeEventListener('slip:reorder', reorder);
        slipjsInstance.current.detach();
      }
    };
  }, [onReorder, rows]);

  const renderSort = (header: Column) => {
    if (!header.sortable) {
      return null;
    }
    if (!header.sort) {
      return <Icon name="triangle-up-down" color={theme.color.fadedText} />;
    }
    if (header.sort === 'asc') {
      return <Icon name="triangle-up" />;
    }
    if (header.sort === 'desc') {
      return <Icon name="triangle-down" />;
    }
    return null;
  };

  const nextSort = (prevSort?: Column['sort']) => {
    if (!prevSort) {
      return 'asc';
    }
    if (prevSort === 'asc') {
      return 'desc';
    }
    return undefined;
  };

  const renderColumn = (col: Column, index: number) => {
    return (
      <StyledTh
        compact={compact}
        width={col.width}
        center={col.center}
        sticky={sticky && index === 0}
        key={index}
        onClick={
          col.sortable && typeof onSort === 'function'
            ? () => {
                onSort!(col, nextSort(col.sort));
              }
            : undefined
        }
      >
        <ThContent>
          {col.header}
          {renderSort(col)}
        </ThContent>
      </StyledTh>
    );
  };

  const _renderRow = React.useCallback(
    (row: any, rowIdx: number) => {
      const isRowSelected = !!(
        selected && selected[rowKey ? row[rowKey] : rowIdx]
      );

      return (
        <BasicTableRow
          compact={!!compact}
          sticky={!!sticky}
          key={rowKey ? row[rowKey] : ++uniq.current}
          onClickRow={onClickRow}
          renderRow={renderRow}
          isSelectable={!!(onSelect || selected)}
          isSelected={isRowSelected}
          onReorder={onReorder}
          row={row}
          rowIdx={rowIdx}
          onSelectRow={handleSelectRow}
        />
      );
    },
    [
      compact,
      onClickRow,
      onReorder,
      renderRow,
      rowKey,
      selected,
      onSelect,
      handleSelectRow,
      sticky,
    ],
  );

  return (
    <Table>
      <Thead>
        <Tr>
          {onReorder && <Th width={1} />}
          {onSelect && selected ? (
            <ThPointer
              width={1}
              onClick={(e: React.MouseEvent<HTMLTableHeaderCellElement>) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectAllRows();
              }}
            >
              <IsolatedCheckBox
                checked={anyRowSelected}
                onChange={() => {
                  //
                }}
              />
            </ThPointer>
          ) : selected ? (
            <Th width={1} />
          ) : null}
          {columns.map(renderColumn)}
        </Tr>
      </Thead>
      <Tbody ref={tbodyEl}>{rows.map(_renderRow)}</Tbody>
    </Table>
  );
};

export default withTheme(BasicTable);
