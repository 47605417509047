/**
 * Roztriedi autorov clanku na tych, ktori maju `sportnetId` a tych, ktory
 * `sportnetId` nemaju.
 *
 * @param {Article_Author[]} authors Autori clanku
 * @return {object} Objekt s atributom `sportnetAuthors`, kde je zoznam autorov
 *                  so `sportnetId` a `otherAuthors` so zoznamom autorov bez
 *                  `sportnetId`.
 */

import { Article_Author } from '../api/CmsApi';

export default (
  authors: Article_Author[],
): { sportnetAuthors: Article_Author[]; otherAuthors: Article_Author[] } => {
  return authors.reduce(
    (acc, author) => {
      if (author.sportnetId) {
        acc.sportnetAuthors.push(author);
      } else {
        acc.otherAuthors.push(author);
      }
      return acc;
    },
    { sportnetAuthors: [], otherAuthors: [] } as {
      sportnetAuthors: Article_Author[];
      otherAuthors: Article_Author[];
    },
  );
};
