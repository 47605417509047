import { rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { ISection } from '../../../library/App';
import styled, { css, withTheme } from '../../../theme/styled-components';
import { ITheme } from '../../../theme/theme';
import useResponsiveList from '../../../utilities/useResponsiveList';
import __ from '../../../utilities/__';
import Button from '../../Button';
import SectionsDropDown from '../../DropDowns/SectionsDropDown';
import Icon from '../../Icon';
import Link from '../../Link';

const List = styled.ul<{ initializing: boolean }>`
  margin: 0 ${rem(-12)} 0 ${rem(32)};
  list-style: none;
  padding: 0;
  display: flex;
  flex: 1 0 0;
  justify-content: flex-end;
  transition: opacity 250ms linear;
  ${({ initializing }) =>
    initializing &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

const ListItem = styled.li`
  display: flex;
  flex-shrink: 0;
`;

const SectionLink = styled(Link)`
  padding: ${rem(8)} ${rem(12)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.textColor};
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  white-space: nowrap;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  cursor: pointer;
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const StyledSectionsDropDown = styled(SectionsDropDown)`
  & > div {
    top: ${rem(36)};
  }
`;

type IOwnProps = {
  sections: ISection[];
  queryDate?: string;
};

type IProps = IOwnProps & {
  theme: ITheme;
};

const SectionsList: React.FC<IProps> = ({ sections, theme, queryDate }) => {
  const { getSectionUrl } = React.useContext(UrlContext);

  const perserverQueryParams = (url: string) => {
    if (queryDate) {
      return `${url}?date=${queryDate}`;
    }
    return url;
  };

  const [moreSectionsOpened, setMoreSectionsOpened] = React.useState(false);

  const handleCloseMoreSections = React.useCallback(() => {
    setMoreSectionsOpened(false);
  }, []);

  const {
    primaryItems,
    secondaryItems,
    initializing,
    listRef,
  } = useResponsiveList(sections, 32);

  function renderSection(section: ISection) {
    return (
      <ListItem key={section._id}>
        <SectionLink to={perserverQueryParams(getSectionUrl(section))}>
          <ArrowIcon name="arrow" size={12} color={theme.color.primary} />
          {section.name}
        </SectionLink>
      </ListItem>
    );
  }

  return (
    <List ref={listRef} initializing={initializing}>
      {primaryItems.map(renderSection)}
      {secondaryItems.length > 0 && !primaryItems.length ? (
        <ListItem>
          <StyledSectionsDropDown
            sections={sections.map((s) => ({
              ...s,
              url: getSectionUrl(s)
            }))}
            opened={moreSectionsOpened}
            onClose={handleCloseMoreSections}
            position="left"
          >
            <SectionLink onClick={() => setMoreSectionsOpened((prev) => !prev)}>
              <ArrowIcon
                name="arrow-down"
                size={12}
                color={theme.color.primary}
              />
              {__('Rubriky')}
            </SectionLink>
          </StyledSectionsDropDown>
        </ListItem>
      ) : secondaryItems.length > 0 ? (
        <ListItem style={{ padding: `0 ${rem(12)}` }}>
          <StyledSectionsDropDown
            sections={secondaryItems.map((s) => ({
              ...s,
              url: getSectionUrl(s)
            }))}
            opened={moreSectionsOpened}
            onClose={handleCloseMoreSections}
            position="left"
          >
            <Button
              size="s"
              secondary
              onClick={() => setMoreSectionsOpened((prev) => !prev)}
            >
              <Icon name="more-dots" size={10} />
            </Button>
          </StyledSectionsDropDown>
        </ListItem>
      ) : null}
    </List>
  );
};

export default withTheme(SectionsList);
