import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import CompetittionsApi from '../../../../api/CompetitionsApi';
import { IMatch } from '../../../../library/Competitions';
import groupMatchesByRound from '../../../../utilities/print/groupMatchesByRound';

// iterator cez strankovany zoznam eventov
const getPaginatedMatches = async function* makeMatchesIterator(
  playerAppSpace: string,
  competitionId: string,
  competitionPartId: string,
  teamId: string,
  limit: number = 100,
) {
  let offset: number | null = 0;
  let totalCount = 0;
  while (offset === 0 || offset !== null) {
    const { matches, nextOffset } = await CompetittionsApi.getMatches({
      playerAppSpace,
      competitionId,
      partId: competitionPartId,
      teamId,
      limit,
      offset,
    });
    totalCount += matches.length;
    offset = nextOffset || null;
    yield {
      matches,
      totalCount,
    } as { matches: IMatch[]; totalCount: number };
  }
};

interface LoadAllMatchesParams {
  playerAppSpace: string;
  teamId: string;
  competitionId: string;
  competitionPartId: string;
}

const loadAllMatches = createQueryFn(
  QueryNameSpace.allMatches,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<[string] | [string, LoadAllMatchesParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters) {
      throw new Error(
        'Must provide required parameters [playerAppSpace, teamId, competitionId, competitionPartId ]!',
      );
    }

    const { playerAppSpace, teamId, competitionId, competitionPartId } = parameters;

    if (!(playerAppSpace && teamId)) {
      throw new Error(
        'Must provide required parameters [playerAppSpace, teamId]!',
      );
    }

    const matches: IMatch[] = [];
    for await (const page of getPaginatedMatches(
      playerAppSpace,
      competitionId,
      competitionPartId,
      teamId,
    )) {
      matches.push(...page.matches);
    }

    return { matchesByRound: groupMatchesByRound(matches) };
  },
);

export default loadAllMatches;
