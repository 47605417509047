import { addDays, endOfDay, startOfDay } from 'date-fns';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import { RouteProps } from '../../../library/App';
import floorMinutes from '../../../utilities/floorMinutes';
import getAppSpaceFromUrl from '../../../utilities/getAppSpaceFromUrl';
import {
  initializeOrSetListParams,
  loadPagesSettingsSocial,
  loadStaticContent,
} from '../../App/actions';
import { loadArticlesList } from '../../Article/actions';
import { loadClubMatches, loadLatestClubCompetitionPart } from '../actions';
import ClubOverviewDetail from './detail';
import AdManager from '../../../components/Ads/AdManager';
import getCleandedPathFromLocation from '../../../utilities/adverts/getCleanedPathFromLocation';
import { withRouter } from 'react-router';

const mapStateToProps = (state: RootState) => {
  return {
    isFetchingPastPanelMatches: isCommiting(
      config.PANEL_PAST_COMPETITION_MATCHES
    )(state),
    isFetchingFuturePanelMatches: isCommiting(
      config.PANEL_FUTURE_COMPETITION_MATCHES
    )(state),
    isFetchingListPastMatches: isCommiting(
      config.LIST_PAST_COMPETITION_MATCHES
    )(state),
    isFetchingListFutureMatches: isCommiting(
      config.LIST_FUTURE_COMPETITION_MATCHES
    )(state),
    isFetchingListTodayMatches: isCommiting(
      config.LIST_TODAY_COMPETITION_MATCHES
    )(state),
  };
};

type Props = ReturnType<typeof mapStateToProps> & {
  dispatch: CustomThunkDispatch;
} & RouteProps<{ klub: string }>;

class ClubOverview extends React.PureComponent<Props> {
  static async initCMS(props: Props) {
    const {
      dispatch,
      params: { klub },
    } = props;

    const appSpace = getAppSpaceFromUrl('club', klub) || klub;

    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_CLUB_ARTICLES,
        params: {
          limit: config.LIST_CLUB_ARTICLES_LIMIT,
          offset: 0,
          appSpace,
        },
      })
    );

    return Promise.all([
      dispatch(loadArticlesList(config.LIST_CLUB_ARTICLES)),
      dispatch(loadStaticContent.action({ appSpace, cid: 'partneri' })).catch(),
      dispatch(
        loadStaticContent.action({ appSpace, cid: 'partneri-up' })
      ).catch(),
      dispatch(loadPagesSettingsSocial.action({ appSpace })),
    ]);
  }

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return Promise.all([
      ClubOverview.initCMS(props),
      dispatch(
        loadClubMatches({
          listName: config.LIST_FUTURE_COMPETITION_MATCHES,
          dateAfter: startOfDay(addDays(new Date(), 1)),
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        })
      ),
      dispatch(
        loadClubMatches({
          listName: config.LIST_PAST_COMPETITION_MATCHES,
          dateBefore: floorMinutes(new Date()),
          closed: true,
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        })
      ),
      dispatch(
        loadClubMatches({
          listName: config.LIST_TODAY_COMPETITION_MATCHES,
          dateAfter: startOfDay(new Date()),
          dateBefore: endOfDay(new Date()),
          limit: config.LIST_COMPETITION_MATCHES_LIMIT,
          offset: 0,
        })
      ),
      dispatch(loadLatestClubCompetitionPart.action()),
    ]);
  }

  async componentDidMount() {
    try {
      await ClubOverview.getInitialProps(this.props);
    } catch (e: any) {
      //
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.params.klub !== prevProps.params.klub) {
      await ClubOverview.getInitialProps(this.props);
    }
  }

  render() {
    // const {
    //   isFetchingFuturePanelMatches,
    //   isFetchingPastPanelMatches,
    //   isFetchingListFutureMatches,
    //   isFetchingListPastMatches,
    //   isFetchingListTodayMatches,
    // } = this.props;
    // if (
    //   isFetchingFuturePanelMatches ||
    //   isFetchingPastPanelMatches ||
    //   isFetchingListFutureMatches ||
    //   isFetchingListPastMatches ||
    //   isFetchingListTodayMatches
    // ) {
    //   return <Loading />;
    // }

    return (
      <>
        <AdManager
          site="futbalnet_klub"
          siteId={getCleandedPathFromLocation(this.props.location)}
        />
        <ClubOverviewDetail />
      </>
    );
  }
}

export default compose(withRouter, connect(mapStateToProps))(ClubOverview);
