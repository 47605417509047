import { Section } from '../api/CmsApi';
import { IPrivateContent, ISection } from '../library/App';

export default (section: Section & IPrivateContent) => {
  const {
    _id,
    content,
    name,
    picture,
    redirecturl,
    url,
    uniqid,
    icon,
    sectionArticles,
    state,
    parent,
    perex,
    settings,
    meta,
    heading,
  } = section;
  const newSection: ISection = {
    _id: _id!, // @TODO csm/api
    name: name!, // @TODO csm/api
  };
  if (content) {
    newSection.content = content;
  }
  if (picture) {
    newSection.picture = picture;
  }
  if (icon) {
    newSection.icon = icon;
  }
  if (redirecturl) {
    newSection.redirecturl = redirecturl;
  }
  if (url) {
    newSection.url = url;
  }
  if (uniqid) {
    newSection.uniqid = uniqid;
  }
  if (sectionArticles) {
    newSection.sectionArticles = sectionArticles;
  }
  if (state) {
    newSection.state = state;
  }
  if (parent) {
    newSection.parent = parent;
  }
  if (perex) {
    newSection.perex = perex;
  }
  if (settings) {
    newSection.settings = settings;
  }
  if (meta) {
    newSection.meta = meta;
  }
  if (heading) {
    newSection.heading = heading;
  }
  return newSection;
};
