import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../configureStore';
import UrlContext, { IUrlContext } from '../../contexts/UrlContext';
import { RouteProps } from '../../library/App';
import getArticleUrl from '../../utilities/getArticleUrl';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import getClubBaseUrl from '../../utilities/getClubBaseUrl';
import getArticlePagesEditUrl from '../../utilities/getArticlePagesEditUrl';
import { appSettingsSelector } from '../DomainResolver/selectors';
import getSmarttagPageUrl from '../../utilities/smartTags/getSmarttagPageUrl';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ klub: string }>;

const ClubUrlContextProvider: React.FC<IProps> = ({
  appSettings,
  children,
  params: { klub },
}) => {
  const urlContext: IUrlContext = React.useMemo(() => {
    const baseUrl = appSettings.baseUri || '';
    const clubUrl = getClubBaseUrl({
      klub,
    });
    const clubBaseUrl = `${baseUrl}${clubUrl}`;

    return {
      getArticleUrl: (a) => getArticleUrl(`${clubUrl}spravy`, a),
      getSectionUrl: (s) => {
        if (s.redirecturl) {
          return s.redirecturl;
        }
        return `${clubUrl}sekcia/${s._id}/`;
      },
      getFullArticleUrl: (a) => getArticleUrl(`${clubBaseUrl}spravy`, a),
      getFullSectionUrl: (s) => {
        if (s.redirecturl) {
          return s.redirecturl;
        }
        return `${clubBaseUrl}sekcia/${s._id}/`;
      },
      getFullSmarttagPageUrl: (s) => getSmarttagPageUrl('', s),
      getAuthorUrl: (a) => getAuthorUrl(`${clubBaseUrl}redakcia`, a),
      getArticlePagesEditUrl: (s, id) => getArticlePagesEditUrl(s, id),
      getArticleDiscussionUrl: (a) =>
        getArticleUrl(`${clubBaseUrl}diskusia`, a),
    };
  }, [appSettings.baseUri, klub]);

  return (
    <UrlContext.Provider value={urlContext}>{children}</UrlContext.Provider>
  );
};

export default withRouter(
  connect(mapStateToProps)(ClubUrlContextProvider),
) as unknown as React.FC<IOwnProps>;
