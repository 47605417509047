import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M9.9,8.4c-2,0-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6S11.9,8.4,9.9,8.4z M9.9,14.4c-1.3,0-2.4-1.1-2.4-2.4
s1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4S11.2,14.4,9.9,14.4z M21.9,5.4H10.1c-3.1-0.1-5.7,2-6.6,4.8C3.3,10,3.1,9.9,2.8,9.9
C2.2,9.8,1.7,10,1.2,10.3c-0.5,0.3-0.7,0.8-0.8,1.4c-0.1,0.6,0.1,1.1,0.4,1.6c0.4,0.6,1.1,0.8,1.7,0.8c0.4,0,0.7-0.1,1-0.3
c0.3,1,0.8,1.8,1.5,2.6c1.2,1.3,2.8,2.1,4.6,2.2c0.1,0,0.2,0,0.3,0c3.1,0,5.8-2.2,6.5-5.3c0-0.2,0.1-0.4,0.1-0.7c0.1-1.1,1-2,2.1-2
h3.3c0.9,0,1.6-0.7,1.6-1.6V7C23.5,6.1,22.8,5.4,21.9,5.4z M3.3,12.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.3-0.9,0.2-1.2-0.2
c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.2,0.2-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.2,0.6,0.3c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
C3.2,11.9,3.3,12.2,3.3,12.4C3.3,12.4,3.3,12.4,3.3,12.4z M22.3,9c0,0.2-0.2,0.4-0.4,0.4h-3.3c-1.8,0-3.2,1.4-3.3,3.1
c0,0.2,0,0.4-0.1,0.5c-0.5,2.6-2.9,4.4-5.5,4.3c-1.4-0.1-2.8-0.7-3.7-1.8c-1-1.1-1.5-2.4-1.4-3.9C4.7,8.9,7.1,6.6,10,6.6
c0,0,0.1,0,0.1,0h11.8c0.2,0,0.4,0.2,0.4,0.4V9z"
    />
  </svg>
);
