import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M7.9,10.1c1.4,0,2.5-1.1,2.5-2.5c0,0,0,0,0,0l0-5.1C10.4,1.1,9.3,0,7.9,0S5.4,1.1,5.4,2.5l0,0
	v5.1C5.4,9,6.5,10.1,7.9,10.1C7.9,10.1,7.9,10.1,7.9,10.1z M12.4,7.6c0,2.4-2,4.3-4.4,4.3c0,0,0,0,0,0C5.5,11.9,3.5,10,3.4,7.6
	c0,0,0,0,0,0H2c0.1,2.9,2.2,5.3,5.1,5.7V16h1.7v-2.8c2.8-0.4,5-2.8,5.1-5.7L12.4,7.6z"
    />
  </svg>
);
