import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formatUserName } from 'sportnet-utilities';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import TopAd from '../../../components/Ads/TopAd';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
} from '../../../components/Layout/SidebarLayout';
import Spacer from '../../../components/Spacer';
import { RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import { RouteProps } from '../../../library/App';
import getMemberDetailUrl from '../../../utilities/getMemberDetailUrl';
import { currentMemberSelector } from '../selectors';

const mapStateToProps = (state: RootState) => ({
  member: currentMemberSelector(state),
});

type IOwnProps = {};

type Props = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ id: string }>;

const C: React.FC<Props> = ({ member }) => {
  return (
    <>
      <TopAd />
      <BottomPaddedBigAd name="big_1" />
      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <Spacer size={4}>
            <p>
              V prípade, ak si chcete zmeniť viditeľnosť Vášho profilu, prípadne
              Váš profil úplne zneaktívniť, alebo nastaviť Vašu fotografiu ako
              neverejnú, môžete tak urobiť po prihlásení sa do Vášho účtu
              v portáli ISSF tu:{' '}
              <a href="https://issf.futbalsfz.sk/">
                https://issf.futbalsfz.sk/
              </a>{' '}
              Po prihlásení sa do portálu ISSF tieto zmeny môžete vykonať
              v záložke „Môj účet“.
            </p>
            <p>
              Informácie o spracúvaní Vašich osobných údajov nájdete v sekcii
              Ochrana osobných údajov, ale tiež na{' '}
              <a href="https://www.futbalsfz.sk/gdpr">
                https://www.futbalsfz.sk/gdpr
              </a>
            </p>
            <p>
              Pokiaľ chcete vymazať fotografiu, ktorú ste našli cez vyhľadávač
              Google, svoje právo na výmaz si môžete uplatniť cez Google
              formulár tu:{' '}
              <a href="https://www.google.com/webmasters/tools/legal-removal-request?complaint_type=rtbf">
                https://www.google.com/webmasters/tools/legal-removal-request?complaint_type=rtbf
              </a>
            </p>
          </Spacer>
        </SidebarLayoutContent>
        <Sidebar />
      </PaddedSidebarLayout>
      <MegaboardAd />
      {member && (
        <>
          <MegaboardAd />
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: 'Členovia',
                url: '/futbalnet/clenovia/',
              },
              {
                label: formatUserName(member),
                url: getMemberDetailUrl(member.id),
              },
            ]}
          />
        </>
      )}
    </>
  );
};
export default compose(
  withRouter,
  connect(mapStateToProps),
)(C) as unknown as React.FC<IOwnProps>;
