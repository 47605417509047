import Loader from '@sportnet/ui/lib/Loader';
import { darken, rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import __ from '../../../utilities/__';
import Icon from '../../Icon';
import Link from '../../Link';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${rem(48)};
  border-radius: ${rem(4)};
  text-decoration: underline;
  color: ${({ theme }) => theme.app.textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  background: #f1f1f1;
  &:hover {
    background: ${darken(0.05, '#F1F1F1')};
  }
  &:focus {
    background: ${darken(0.1, '#F1F1F1')};
  }
  &:active {
    background: ${darken(0.2, '#F1F1F1')};
  }
`;

const Wrapper = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${rem(48)};
  padding: ${rem(12)};
  cursor: pointer;
  border-radius: ${rem(4)};
  text-decoration: underline;
  color: ${({ theme }) => theme.app.textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  outline: none;
  border: none;
  background: #f1f1f1;
  &:hover {
    background: ${darken(0.05, '#F1F1F1')};
  }
  &:focus {
    background: ${darken(0.1, '#F1F1F1')};
  }
  &:active {
    background: ${darken(0.2, '#F1F1F1')};
  }
`;

const Text = styled.span`
  padding-right: ${rem(8)};
`;

interface IOwnProps {
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  title?: string;
  to?: string;
}

type IProps = IOwnProps;

const LoadMoreButton: React.FC<IProps> = ({
  onClick,
  loading,
  className,
  title,
  to,
}) => {
  return to ? (
    <StyledLink to={to}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text>{title ? title : __('Zobraziť viac')}</Text>
          <Icon name="small-chevron" size={12} />
        </>
      )}
    </StyledLink>
  ) : (
    <Wrapper
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.currentTarget.blur();
        if (onClick) {
          onClick();
        }
      }}
      className={className}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text>{title ? title : __('Zobraziť viac')}</Text>
          <Icon name="small-chevron" size={12} />
        </>
      )}
    </Wrapper>
  );
};

export default LoadMoreButton;
