import { rem } from 'polished';
import React from 'react';
import styled, {
  IThemeProps,
  withTheme,
} from '../../../theme/styled-components';
import __ from '../../../utilities/__';
import Icon from '../../Icon';
import SectionBlockHeaderContainer from '../../Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionName from '../../Sections/SectionBlockHeader/SectionName';

export const StyledIcon = styled(Icon)`
  margin-right: ${rem(12)};
`;

type IOwnProps = {
  className?: string;
};

type IProps = IOwnProps & IThemeProps;

const NewsFeedArticlesHeader: React.FC<IProps> = ({ className, theme }) => {
  return (
    <SectionBlockHeaderContainer
      className={className}
      left={
        <>
          <StyledIcon name="news" size={32} color={theme.color.primary} />
          <SectionName>{__('Najnovšie správy')}</SectionName>
        </>
      }
    />
  );
};

export default withTheme(NewsFeedArticlesHeader);
