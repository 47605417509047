import { rgba } from 'polished';
import * as React from 'react';
import DomainResolverContext from '../../pages/DomainResolver/context';
import styled, { css } from '../../theme/styled-components';

const Wrapper = styled.div`
  ${({ theme }) =>
    css`
      position: relative;
      &:after {
        content: '';
        cursor: pointer;
        position: absolute;
        border: 3px solid transparent;
        transition: opacity 0.2s;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-color: ${theme.color.warning};
        opacity: 0;
        background-color: ${rgba(theme.color.warning, 0.5)};
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
    `}
`;

interface IOwnProps {
  id: string;
}

type IProps = IOwnProps;

const PreviewTargetArea: React.FC<IProps> = ({ children, id }) => {
  const domainResolverContextValue = React.useContext(DomainResolverContext);

  if (domainResolverContextValue.editModeEnabled) {
    return (
      <Wrapper
        onClick={() => {
          domainResolverContextValue.onClickArea(id);
        }}
      >
        {children}
      </Wrapper>
    );
  }

  return <>{children}</>;
};

export default PreviewTargetArea;
