import React from 'react';

export default (size: string, color: string) => {
  const color1 = '#fff';
  const color2 = '#1ab7ea';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 45.808 45.808"
    >
      <path
        d="M45.808 41.227a4.58 4.58 0 0 1-4.581 4.581H4.581A4.58 4.58 0 0 1 0 41.227V4.581A4.58 4.58 0 0 1 4.581 0h36.646a4.58 4.58 0 0 1 4.581 4.581z"
        fill={color2}
      />
      <path
        d="M47.893 28.619q-.218 4.833-6.707 13.235-6.711 8.772-11.357 8.772-2.874 0-4.863-5.344l-2.657-9.8q-1.477-5.342-3.168-5.344a10.048 10.048 0 0 0-2.585 1.562l-1.546-2q2.434-2.153 4.8-4.31 3.252-2.823 4.875-2.974 3.842-.372 4.73 5.283c.641 4.069 1.08 6.6 1.332 7.589q1.105 5.062 2.435 5.058 1.031 0 3.1-3.283a12.962 12.962 0 0 0 2.214-5q.3-2.834-2.214-2.836a6.153 6.153 0 0 0-2.435.542q2.424-7.994 9.266-7.769 5.074.149 4.78 6.619z"
        fill={color1}
        transform="translate(-9.28 -13.598)"
      />
    </svg>
  );
};
