import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.div`
  padding: ${rem(17)} ${rem(16)};
  margin-bottom: ${rem(8)};

  ${mb('m')} {
    padding: ${rem(24)} ${rem(23)};
  }
`;

const Date = styled.div`
  height: ${rem(16.5)};
  width: ${rem(100)};
  margin-bottom: ${rem(10)};

  ${mb('m')} {
    height: ${rem(19)};
  }
`;

const Title = styled.div`
  height: ${rem(24)};
  width: 100%;

  ${mb('m')} {
    height: ${rem(27)};
  }
`;

const Smarttags = styled.div`
  display: flex;
  margin: ${rem(-2)};
  margin-top: ${rem(16)};
`;

const Smarttag = styled.div`
  width: ${rem(80)};
  height: ${rem(25)};
  margin: ${rem(2)};
  border-radius: ${rem(12)};
`;

const Bottom = styled.div`
  display: flex;
  gap: ${rem(10)};
  margin-top: ${rem(25)};
`;

const BottomItem = styled.div`
  height: ${rem(16.5)};
  width: ${rem(32)};
`;

interface OwnProps {
  skeletonClassName?: string;
  className?: string;
}

const GossipLoader: React.FC<OwnProps> = ({ skeletonClassName, className }) => {
  return (
    <Wrapper className={className}>
      <Date className={skeletonClassName} />
      <Title className={skeletonClassName} />
      <Smarttags>
        <Smarttag className={skeletonClassName} />
        <Smarttag className={skeletonClassName} />
        <Smarttag className={skeletonClassName} />
      </Smarttags>
      <Bottom>
        <BottomItem className={skeletonClassName} />
        <BottomItem className={skeletonClassName} />
      </Bottom>
    </Wrapper>
  );
};

export default GossipLoader;
