import Col, { Row } from '@sportnet/ui/lib/Grid';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import AdManager from '../../components/Ads/AdManager';
import BodyAd from '../../components/Ads/BodyAd';
import BottomPaddedBigAd from '../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import SideAd from '../../components/Ads/SideAd';
import TopAd from '../../components/Ads/TopAd';
import ItemsDropDown from '../../components/DropDowns/ItemsDropDown';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../components/Layout/SidebarLayout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/Table';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../containers/RempIntegration';
import getResolutionDetailUrl from '../../utilities/futbalnet/committee/getResolutionDetailUrl';
import getCommiteeBaseUrl from '../../utilities/getCommiteeBaseUrl';
import getMemberDetailUrl from '../../utilities/getMemberDetailUrl';
import getUnionBaseUrl from '../../utilities/getUnionBaseUrl';
import getUnionByUrl from '../../utilities/getUnionByUrl';
import __ from '../../utilities/__';
import { currentOrganizationProfileSelector } from '../Competition/selectors';
import {
  loadCommiteeDetail,
  loadResolutions,
  loadSeasonsList,
} from './actions';
import {
  comiteeDetailSelector,
  latestResolutionsSelector,
  sortedSeasonsSelector,
} from './selectors';
import {
  CustomLink,
  FilterButton,
  FilterWrapper,
  H2,
  MoreIcon,
  ResolutionListTitle,
  ResolutionWrapper,
  RowWrapper,
  TableWrapper,
  TdDecisionItem,
} from './styles';
import { initializeOrSetListParams } from '../App/actions';
import { isCommiting } from '@sportnet/redux-list/ducks';
import getCleandedPathFromLocation from '../../utilities/adverts/getCleanedPathFromLocation';
import Loading from '../../components/Loading';

type RouterProps = RouteComponentProps<
  {
    comiteeId: string;
    zvaz: string;
  },
  void
>;

const mapStateToProps = (
  state: RootState,
  props: RouterProps & ReduxConnectProps,
) => {
  const { comiteeId } = props.params;
  return {
    comiteeDetail: comiteeDetailSelector(comiteeId)(state),
    organizationProfile: currentOrganizationProfileSelector(state),
    seasons: sortedSeasonsSelector(state),
    latestResolutions: latestResolutionsSelector(state),
    loadResolutionIsLoading: isCommiting(config.LIST_RESOLUTIONS)(state),
  };
};

const mapDispatchToProps = {
  loadCommiteeDetail: thunkToAction(loadCommiteeDetail.action),
  loadSeasonsList,
  loadResolutions: thunkToAction(loadResolutions),
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams),
};

type IProps = RouterProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const QUERY_HOC_CONFIG = {
  parameters: {
    seasonId: StringParam(config.ACTIVE_SEASON_ID),
  },
};

const Commitee: React.FC<IProps> = ({
  comiteeDetail,
  latestResolutions,
  loadCommiteeDetail,
  loadResolutions,
  loadSeasonsList,
  location,
  location: { search, pathname },
  params,
  router,
  seasons,
  organizationProfile,
  initializeOrSetListParams,
  loadResolutionIsLoading,
}) => {
  // parameters from URL
  const { zvaz, comiteeId } = params;

  const unionId = React.useMemo(() => {
    // `union` object from `shortnames.json`
    const union = getUnionByUrl(zvaz);
    return union?.__issfId.toString();
  }, [zvaz]);

  // query parameter for selected season
  const { query } = useQuery(
    search,
    (newSearch) =>
      router.push({
        pathname,
        search: newSearch,
      }),
    QUERY_HOC_CONFIG,
  );

  const [isFilterOpened, setIsFilterOpened] = React.useState<boolean>(false);

  useAsyncData(async () => {
    if (unionId) {
      try {
        await Promise.all([
          loadCommiteeDetail({
            unionId,
            comiteeId,
          }),
          loadSeasonsList(),
        ]);
      } catch (e: any) {
        /* console.error(e); */
      }
    }
  }, [unionId, comiteeId, loadSeasonsList]);

  useAsyncData(async () => {
    if (unionId && query.seasonId) {
      initializeOrSetListParams({
        listName: config.LIST_RESOLUTIONS,
        params: {
          unionId,
          comiteeId,
          season: query.seasonId,
        },
      });
      await loadResolutions({
        unionId,
        comiteeId,
        season: query.seasonId,
      });
    }
  }, [unionId, comiteeId, query.seasonId]);

  const getSeasonName = () => {
    const id = query.seasonId || config.ACTIVE_SEASON_ID;
    const season = seasons.find((s) => s.txtid === id);
    return season ? season.name : id;
  };

  const renderMembers = () => {
    let content;
    if (comiteeDetail?.members && comiteeDetail?.members.length) {
      content = comiteeDetail?.members.map((member, idx) => (
        <Tr key={`${member.fullname}-${idx}`}>
          <Td>{member.fullname}</Td>
        </Tr>
      ));
    } else {
      content = __('Komisia nemá žiadnych členov.');
    }
    return content;
  };

  const renderSecretaries = () => {
    let content;
    if (comiteeDetail?.secretary && comiteeDetail.secretary.length) {
      content = comiteeDetail?.secretary.map((secretary) => (
        <Tr key={`${secretary.id}`}>
          <Td>
            <CustomLink to={getMemberDetailUrl(secretary.id)}>
              {secretary.name}&nbsp;{secretary.surname}
            </CustomLink>
          </Td>
        </Tr>
      ));
    } else {
      content = __('Komisia nemá žiadnych tajomníkov.');
    }
    return content;
  };

  const getSeasonOptions = () =>
    seasons.map((i) => ({
      label: i.name,
      url: {
        pathname,
        query: {
          ...query,
          seasonId: i.txtid,
        },
      },
    }));

  const renderDetail = () => {
    return (
      <>
        <H2>{comiteeDetail?.name}</H2>
        <RowWrapper>
          <Row>
            {comiteeDetail?.members && comiteeDetail?.members.length > 0 && (
              <Col m={6}>
                <TableWrapper>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>{__('Členovia')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{renderMembers()}</Tbody>
                  </Table>
                </TableWrapper>
              </Col>
            )}
            {comiteeDetail?.secretary && comiteeDetail.secretary.length > 0 && (
              <Col m={6}>
                <TableWrapper>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>{__('Tajomníci')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{renderSecretaries()}</Tbody>
                  </Table>
                </TableWrapper>
              </Col>
            )}
          </Row>
        </RowWrapper>
        <BodyAd name="body_1" />
        <ResolutionWrapper>
          <ResolutionListTitle>{__('Rozhodnutia')}</ResolutionListTitle>
          <FilterWrapper>
            <ItemsDropDown
              items={getSeasonOptions()}
              opened={isFilterOpened}
              onClose={() => {
                setIsFilterOpened(!isFilterOpened);
              }}
              color="#333"
              activeColor="#CF2528"
              position="left"
            >
              <div>
                <FilterButton
                  onClick={() => {
                    setIsFilterOpened(!isFilterOpened);
                  }}
                >
                  <div>{query.seasonId.replace('-', '/')}</div>
                  <MoreIcon name="arrow-down" size={16} />
                </FilterButton>
              </div>
            </ItemsDropDown>
          </FilterWrapper>
        </ResolutionWrapper>
        <TableWrapper>
          {loadResolutionIsLoading ? (
            <Loading />
          ) : (
            <Table>
              <Tbody>
                {latestResolutions && latestResolutions.length > 0 ? (
                  latestResolutions.map((resolution) => (
                    <Tr key={resolution.number}>
                      <TdDecisionItem>
                        <CustomLink
                          to={getResolutionDetailUrl({
                            seasonId: query.seasonId,
                            resolutionNumber: resolution.number,
                            zvaz,
                            comiteeId,
                          })}
                        >
                          {resolution.number}
                        </CustomLink>
                        <br />
                        <br />
                        {resolution.title}
                      </TdDecisionItem>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>
                      {__('Komisia neprijala v sezóne')}&nbsp;{getSeasonName()}
                      &nbsp;{__('žiadne rozhodnutia.')}
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          )}
        </TableWrapper>
      </>
    );
  };

  const breadcrumbUnionName =
    organizationProfile?.shortName || organizationProfile?.name || '';

  return (
    <>
      <Helmet>
        <title>{`${comiteeDetail?.name} | ${organizationProfile?.title}`}</title>
      </Helmet>
      <RempIntegration destroy />
      <AdManager
        site="futbalnet_zvaz"
        siteId={getCleandedPathFromLocation(location)}
      />
      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>{renderDetail()}</SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      <MegaboardAd />
      {breadcrumbUnionName && comiteeDetail?.name && (
        <FutbalnetUnionBreadcrumbs
          crumbs={[
            {
              label: breadcrumbUnionName,
              url: getUnionBaseUrl({ zvaz }),
            },
            {
              label: comiteeDetail?.name,
              url: getCommiteeBaseUrl({ zvaz, comiteeId }),
            },
          ]}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Commitee);
