import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { commit } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import { IMatch } from '../../library/Competitions';
import normalizeEntities from '../../utilities/normalizeEntities';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import { normalizeCompetitions } from '../Competition/actions';
import {
  currentCompetitionSelector,
  getCurrentCompetitionAppSpaceSelector,
} from '../Competition/selectors';

const create = actionCreatorFactory('UNION');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const normalizeMatches = (entityName: string, data: IMatch[]) => {
  return {
    entities: {
      [entityName]: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: IMatch },
    },
    result: data.map((item) => item._id),
  };
};

export const loadUnionCompetitions = createAsync<
  {
    seasonNames?: string[];
  },
  void
>(
  'LOAD_UNION_COMPETITIONS',
  async ({ seasonNames }, dispatch, getState, { CompetitionsApi }) => {
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
    const competition = currentCompetitionSelector(getState());
    let activeSeasonNames: string[] =
      config.ACTIVE_SEASON_NAMES as unknown as string[];
    if (competition) {
      activeSeasonNames = [competition.season?.name || ''];
    } else if (seasonNames) {
      activeSeasonNames = seasonNames;
    }

    if (appSpace) {
      return dispatch(
        commit.action({
          listName: config.LIST_UNION_COMPETITIONS,
          load: async () => {
            const { competitions } = await CompetitionsApi.getAllCompetitions({
              ownerPpo: config.OWNER_PPOS as any,
              appSpace,
              seasonNames: activeSeasonNames,
            } as any);

            const { result, entities } = normalizeCompetitions(competitions);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: competitions.length,
            };
          },
        }),
      );
    }
  },
);

export const loadUnionMatches = createAsync<
  {
    offset: number;
    limit?: number;
  },
  void
>(
  'LOAD_UNION_MATCHES',
  async ({ offset, limit = 5 }, dispatch, getState, { CompetitionsApi }) => {
    const currentAppSpace = getCurrentCompetitionAppSpaceSelector(getState());
    if (currentAppSpace) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_UNION_MATCHES,
          params: {
            offset,
            appSpace: currentAppSpace,
          },
        }),
      );
      return dispatch(
        commit.action({
          listName: config.LIST_UNION_MATCHES,
          load: async () => {
            const f: {
              dateTo: string;
              dateFrom: string;
              limit: number;
              appSpace: string;
              offset: number;
            } = {
              offset,
              limit,
              dateFrom: startOfDay(new Date()).toJSON(),
              dateTo: endOfDay(new Date()).toJSON(),
              appSpace: currentAppSpace,
            };
            const response = await CompetitionsApi.getMatches(f);

            const { result, entities } = normalizeMatches(
              'matches',
              response.matches,
            );

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: response.total || 0,
              nextOffset: response.nextOffset,
            };
          },
        }),
      );
    }
  },
);

export const loadLastRoundMatches = createAsync<
  {
    competitionId: string;
  },
  void
>(
  'LOAD_LAST_ROUND_MATCHES',
  async ({ competitionId }, dispatch, getState, { CompetitionsApi }) => {
    const response = await CompetitionsApi.getLastRoundMatches(competitionId, {
      offset: 0,
      limit: 16,
    });
    const entities = {
      lastRoundMatches: {
        [competitionId]: { matches: response.matches },
      },
    };
    dispatch(updateEntities(entities));
  },
);

export const loadCommisions = createAsync<{ unionId: string }, void>(
  'LOAD_COMMISIONS',
  async ({ unionId }, dispatch, getState, { FutbalnetApi }) => {
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_COMITEES,
        params: { unionId },
      }),
    );
    return dispatch(
      commit.action({
        listName: config.LIST_COMITEES,
        load: async () => {
          const response = await FutbalnetApi.getComitees(unionId);

          const { entities, results } = normalizeEntities(
            'comitees',
            response,
            (e) => String(e.id),
          );

          // dispatch
          dispatch(updateEntities(entities));

          return {
            results,
            total: response.length,
          };
        },
      }),
    );
  },
);
