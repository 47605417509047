import { AsyncReturnType } from '../library/App';
import { IMemberRefereeProfile } from '../library/Competitions';
import FutbalnetApi from '../pages/Member/api';

/**
 * ...len potrebne fieldy pre detail rozhodcu
 */
const reduceRefereeProfile = (
  trainerProfile: AsyncReturnType<typeof FutbalnetApi['getTrainer']>,
): IMemberRefereeProfile => {
  const reduced: IMemberRefereeProfile = {
    profileStatus: trainerProfile.profileStatus,
    registrationDate: trainerProfile.registration_date,
  };
  return reduced;
};

export default reduceRefereeProfile;
