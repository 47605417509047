import React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M8,0.7C4,0.7,0.7,4,0.7,8c0,4,3.3,7.3,7.3,7.3c1.6,0,3.2-0.5,4.4-1.5c0.3-0.2,0.3-0.6,0.1-0.9c-0.2-0.3-0.6-0.3-0.9-0.1
	C10.6,13.6,9.3,14,8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6c3.3,0,6,2.7,6,6v0.7c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4V5.3
	c0-0.3-0.3-0.6-0.6-0.6S10,5,10,5.3v0.1C9.5,5,8.8,4.7,8,4.7C6.2,4.7,4.7,6.2,4.7,8s1.5,3.3,3.3,3.3c1,0,1.9-0.5,2.5-1.2
	c0.5,0.7,1.3,1.2,2.2,1.2c1.4,0,2.6-1.2,2.6-2.6V8C15.3,4,12,0.7,8,0.7z M8,10c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S9.1,10,8,10z"
    />
  </svg>
);
