import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';

interface IWrapperProps {
  elMaxWidth?: number;
  padding?: number;
}
const Wrapper = styled.div<IWrapperProps>`
  padding: 0 ${({ theme, padding }) => rem(padding ?? theme.app.padding)};
  max-width: ${({ elMaxWidth, theme }) =>
    rem(elMaxWidth ?? theme.app.maxWidth)};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  flex: 1 0 0;
`;

const MaxWidthBox: React.FC<{
  width?: number;
  className?: string;
  padding?: number;
}> = ({ children, width, className, padding }) => {
  return (
    <Wrapper className={className} elMaxWidth={width} padding={padding}>
      {children}
    </Wrapper>
  );
};

export default MaxWidthBox;
