import resultsTableSorters from './resultsTableSorters';

const commonSorters = [
  resultsTableSorters.resignation,
  resultsTableSorters.points,
  resultsTableSorters.commonMatchesPoints,
  resultsTableSorters.commonMatchesGoalDifference,
  resultsTableSorters.goalDifference,
  resultsTableSorters.goalsGiven,
  resultsTableSorters.wins,
  resultsTableSorters.awayWins,
  resultsTableSorters.draws,
  resultsTableSorters.awayDraws,
];

export default commonSorters;
