import { IResultsTableItem } from '../../library/Competitions';
import __ from '../__';

const getCorrections = (resultsTableItems: IResultsTableItem[]) => {
  return resultsTableItems.reduce((acc: string[], i) => {
    if (i.corrections) {
      if (
        i.corrections.points &&
        typeof i.corrections.points.total !== 'undefined' &&
        i.corrections.points.total < 0
      ) {
        acc.push(
          `${__('Družstvu')} ${i.team.name} boli odpočítané body v počte ${
            i.corrections?.points.total * -1
          }. Dôvod korekcie: ${i.corrections.reason}`,
        );
      } else if (
        typeof i.corrections.plusminus !== 'undefined' &&
        i.corrections.plusminus < 0
      ) {
        acc.push(
          `${__('Družstvu')} ${i.team.name} boli odpočítané body v počte ${
            i.corrections?.plusminus * -1
          }. Dôvod korekcie: ${i.corrections.reason}`,
        );
      }
    }
    return acc;
  }, []);
};

export default getCorrections;
