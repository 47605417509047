import { rem } from "polished";
import styled from "styled-components";

const Notification = styled.span`
  width: ${rem(16)};
  height: ${rem(16)};
  background-color: ${({ theme }) => theme.color.sportnetRed};
  outline: 2px solid #ffffff;
  color: white;
  font-size: ${rem(8)};
  font-weight: ${({ theme }) => theme.primaryFont.weight.extraBold};
  border-radius: 50%;
  letter-spacing: 0px;
  line-height: ${rem(15.6)};
`;

export default Notification;