import { Articles_List } from '../../api/CmsApi';

interface ListParams {
  nextOffset?: number | null;
  total?: number;
  limit?: number;
  offset?: number;
}

interface State {
  loading: boolean;
  articles: Articles_List['articles'];
  listParams: ListParams;
  error?: any;
}

interface Action {
  type: string;
  payload?: {
    articles?: Articles_List['articles'];
    error?: any;
    listParams?: ListParams;
    loading?: boolean;
  };
}

export const initialState: State = {
  loading: false,
  listParams: {},
  articles: [],
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'addData': {
      if (action.payload) {
        const { articles, error, listParams } = action.payload;
        const { articles: stateArticles } = state;
        if (error) {
          return {
            ...state,
            error,
          };
        }
        return {
          ...state,
          articles: [...stateArticles, ...(articles || [])],
          error: undefined,
          listParams: listParams ?? {},
        };
      }
      return state;
    }
    case 'setListParams': {
      if (action.payload) {
        const { listParams } = action.payload;
        if (listParams) {
          return {
            ...initialState,
            listParams,
          };
        }
      }
      return {
        ...initialState,
      };
    }
    case 'loading': {
      if (action.payload) {
        return {
          ...state,
          loading: action.payload.loading ?? false,
        };
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
