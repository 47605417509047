import { rem } from 'polished';
import styled from 'styled-components';
import Icon from '../../Icon';
import { withTheme } from '../../../theme/styled-components';
import { ITheme } from '../../../theme/theme';
import React from 'react';

const IconWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${rem(4)};
`;

const Input = styled.input`
  cursor: pointer;
  margin: 0;
  padding: 0;
  opacity: 0;

  & ~ ${IconWrapper} {
    border: ${rem(1)} solid ${({ theme }) => theme.color.sportnetGray};
    box-shadow: inset 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
    transition: background 0.2s, border 0.2s;
    background-color: white;
    svg {
      position: relative;
      top: ${rem(1)};
      transform: scale(0);
    }
  }

  &:checked ~ ${IconWrapper} {
    border: ${rem(1)} solid ${({ theme }) => theme.color.sportnetRed};
    background: ${({ theme }) => theme.color.sportnetRed};
    color: white;
    svg {
      transform: scale(1);
    }
  }
  &:disabled ~ ${IconWrapper} {
    border: 1px solid ${({ theme }) => theme.color.lightBorder};
    background-color: ${({ theme }) => theme.color.lightBackground};
    cursor: ${({ theme }) => theme.color.lightBorder};
  }
`;

const Wrapper = styled.label<{ error?: boolean }>`
  display: inline-block;
  position: relative;
  min-width: ${rem(24)};
  height: ${rem(24)};
  cursor: pointer;
`;

const Label = styled.span<{ disabled?: boolean }>`
  cursor: pointer;
  padding-left: ${rem(12)};
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.disabledLabel : theme.color.primaryFontColor};
`;

interface OwnProps {
  className?: string;
  label?: string;
  name?: string;
  value: boolean;
  onChange?: (name?: string) => void;
  disabled?: boolean;
}

type Props = OwnProps & { theme: ITheme };

const BigCheckbox: React.FC<Props> = ({
  className,
  value = true,
  name,
  onChange,
  label,
  disabled,
}) => {
  return (
    <div>
      <Wrapper className={className}>
        <Input
          id={name}
          checked={value}
          onChange={() => {
            onChange && onChange(name);
          }}
          name={name || ''}
          type="checkbox"
          disabled={disabled}
        />
        <IconWrapper>
          <Icon name="check-big" color={'white'} size={16} />
        </IconWrapper>
      </Wrapper>
      {label && (
        <Label
          disabled={disabled}
          onClick={
            onChange && !disabled
              ? () => {
                  onChange(name);
                }
              : () => {}
          }
        >
          {label}
        </Label>
      )}
    </div>
  );
};

export default withTheme(BigCheckbox);
