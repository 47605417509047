/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Card = {
  cardnr: string;

  type: 'Slovnaft';

  org_regnr: string;

  _id?: string;

  created_date?: string;

  validTo: string;
};
export type Order_Item = {
  quantity?: number;

  item_name?: string;

  paidbycredit?: boolean;

  variant?: {
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  };

  totalprice?: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };
};
export type Order = {
  _id?: number;

  purchase_id?: number;

  invoicenr?: string;

  created_date?: string;

  supplier_id?: string;

  appSpace?: string;

  supplier_name?: string;

  status?: string;

  company?: string;

  business_id?: string;

  person_name?: string;

  person_surname?: string;

  phone?: string;

  email?: string;

  delivery_address?: {
    company?: string;

    person: string;

    street: string;

    number?: string;

    city: string;

    zip: string;

    country: string;
  };

  invoice_address?: {
    company?: string;

    person: string;

    street: string;

    number?: string;

    city: string;

    zip: string;

    country: string;
  };

  supplier?: {};

  payment?: {
    type?: string;

    name?: string;

    description?: string;
  };

  gpwebpay_ordernumber?: Array<{
    nr?: number;

    created_date?: string;
  }>;

  paid_date?: string;

  payment_price?: {};

  paid_payload?: {
    gpwebpay_ordernumber?: number;
  };

  delivery?: {
    type?: string;

    name?: string;

    description?: string;
  };

  totalprice?: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  items?: Array<{
    quantity?: number;

    item_name?: string;

    paidbycredit?: boolean;

    variant?: {
      oldprice?: number;

      code: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };

      orderfragments?: Array<{
        code?: string;

        name?: string;

        unitprice?: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };
      }>;

      vat_rate: number;

      units?: string;

      ean?: string;

      onsale?: {
        status: boolean;

        valid_to?: string;
      };

      availability?: {
        available: boolean;

        onstock?: boolean;

        managed_stock?: boolean;

        quantity?: number;

        max_quantity?: number;

        days?: number;

        at_date?: string;

        label?: string;
      };

      params?: Array<{
        key: string;

        type?: string;

        value?: string;
      }>;

      pricelevels?: Array<{
        pricelevel: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };
      }>;

      picture?: {
        media_url: string;

        public_url: string;

        smarttags?: Array<{
          key: string;

          values: Array<{
            key: string;
          }>;
        }>;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };
    };

    totalprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };
  }>;

  last_update?: string;

  note?: string;

  status_log?: Array<{
    change_date?: string;

    old_status?: string;

    new_status?: string;

    reason?: string;

    admin_user?: {
      display_name?: string;
    };

    auth_user?: {
      display_name?: string;
    };
  }>;

  log?: Array<{
    date?: string;

    message?: string;

    payload?: {};
  }>;

  receipt_id?: number;

  saleschannel?: string;

  shipment_id?: string;

  shipment_link?: string;

  vat_nr?: string;

  voucher?: {
    _id: string;

    payload?: {};
  };

  spsInfo?: {
    refNr?: string;

    shipNr?: string;

    packageNo?: number;

    documentUrl?: string;
  };
};
export type Purchase = {
  _id?: number;

  created_date?: string;

  payment?: {
    type?: string;

    name?: string;

    description?: string;
  };

  credits?: number;

  payment_price?: {
    amount: number;

    currency: string;
  };

  paid_date?: string;

  company?: string;

  business_id?: string;

  person_name?: string;

  person_surname?: string;

  phone?: string;

  email?: string;

  totalprice?: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  note?: string;

  order_ids?: Array<number>;

  orders?: Array<{
    _id?: number;

    purchase_id?: number;

    invoicenr?: string;

    created_date?: string;

    supplier_id?: string;

    appSpace?: string;

    supplier_name?: string;

    status?: string;

    company?: string;

    business_id?: string;

    person_name?: string;

    person_surname?: string;

    phone?: string;

    email?: string;

    delivery_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    invoice_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    supplier?: {};

    payment?: {
      type?: string;

      name?: string;

      description?: string;
    };

    gpwebpay_ordernumber?: Array<{
      nr?: number;

      created_date?: string;
    }>;

    paid_date?: string;

    payment_price?: {};

    paid_payload?: {
      gpwebpay_ordernumber?: number;
    };

    delivery?: {
      type?: string;

      name?: string;

      description?: string;
    };

    totalprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    items?: Array<{
      quantity?: number;

      item_name?: string;

      paidbycredit?: boolean;

      variant?: {
        oldprice?: number;

        code: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };

        orderfragments?: Array<{
          code?: string;

          name?: string;

          unitprice?: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        vat_rate: number;

        units?: string;

        ean?: string;

        onsale?: {
          status: boolean;

          valid_to?: string;
        };

        availability?: {
          available: boolean;

          onstock?: boolean;

          managed_stock?: boolean;

          quantity?: number;

          max_quantity?: number;

          days?: number;

          at_date?: string;

          label?: string;
        };

        params?: Array<{
          key: string;

          type?: string;

          value?: string;
        }>;

        pricelevels?: Array<{
          pricelevel: string;

          unitprice: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        picture?: {
          media_url: string;

          public_url: string;

          smarttags?: Array<{
            key: string;

            values: Array<{
              key: string;
            }>;
          }>;

          dimensions?: {
            X: number;

            Y: number;

            W: number;

            H: number;
          };
        };
      };

      totalprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    last_update?: string;

    note?: string;

    status_log?: Array<{
      change_date?: string;

      old_status?: string;

      new_status?: string;

      reason?: string;

      admin_user?: {
        display_name?: string;
      };

      auth_user?: {
        display_name?: string;
      };
    }>;

    log?: Array<{
      date?: string;

      message?: string;

      payload?: {};
    }>;

    receipt_id?: number;

    saleschannel?: string;

    shipment_id?: string;

    shipment_link?: string;

    vat_nr?: string;

    voucher?: {
      _id: string;

      payload?: {};
    };

    spsInfo?: {
      refNr?: string;

      shipNr?: string;

      packageNo?: number;

      documentUrl?: string;
    };
  }>;
};
export type Address = {
  company?: string;

  person: string;

  street: string;

  number?: string;

  city: string;

  zip: string;

  country: string;
};
export type Order_Create = {
  company?: string;

  business_id?: string;

  person_name: string;

  person_surname: string;

  phone: string;

  email: string;

  invoice_address: {
    company?: string;

    person: string;

    street: string;

    number?: string;

    city: string;

    zip: string;

    country: string;
  };

  delivery_address: {
    company?: string;

    person: string;

    street: string;

    number?: string;

    city: string;

    zip: string;

    country: string;
  };

  delivery_data?: Array<{
    appSpace: string;

    data: {};

    delivery_type: string;
  }>;

  delivery_options: Array<{
    appSpace: string;

    delivery_type: string;

    creditpay?: boolean;

    creditpay_forced?: boolean;
  }>;

  creditpay_item_ids?: Array<string>;

  payment: {
    type: string;
  };

  note?: string;

  saleschannel: string;
};
export type Cart = {
  _id: string;

  created_date?: string;

  expire_at: string;

  items?: Array<{
    quantity: number;

    item_name: string;

    item_picture?: {
      uri: string;

      title?: string;
    };

    creditpay?: boolean;

    creditpay_forced?: boolean;

    product_id: string;

    variant: {
      oldprice?: number;

      code: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };

      orderfragments?: Array<{
        code?: string;

        name?: string;

        unitprice?: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };
      }>;

      vat_rate: number;

      units?: string;

      ean?: string;

      onsale?: {
        status: boolean;

        valid_to?: string;
      };

      availability?: {
        available: boolean;

        onstock?: boolean;

        managed_stock?: boolean;

        quantity?: number;

        max_quantity?: number;

        days?: number;

        at_date?: string;

        label?: string;
      };

      params?: Array<{
        key: string;

        type?: string;

        value?: string;
      }>;

      pricelevels?: Array<{
        pricelevel: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };
      }>;

      picture?: {
        media_url: string;

        public_url: string;

        smarttags?: Array<{
          key: string;

          values: Array<{
            key: string;
          }>;
        }>;

        dimensions?: {
          X: number;

          Y: number;

          W: number;

          H: number;
        };
      };
    };

    item_id?: string;

    totalprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    updated_date?: string;

    payload?: {};

    supplier_id: string;

    supplier_name: string;

    automatic?: boolean;
  }>;

  title?: string;

  totalprice?: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  voucherError?: {
    statusCode: number;

    name: string;

    userinfo?: string;
  };

  voucher?: {
    _id: string;

    appSpace?: string;

    name?: string;

    payload?: {};
  };
};
export type Cart_Item_Create = {
  quantity: number;

  product_id: string;

  url?: string;

  variant_code: string;

  payload?: {};
};
export type Cart_Item = {
  quantity: number;

  item_name: string;

  item_picture?: {
    uri: string;

    title?: string;
  };

  creditpay?: boolean;

  creditpay_forced?: boolean;

  product_id: string;

  variant: {
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  };

  item_id?: string;

  totalprice: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  updated_date?: string;

  payload?: {};

  supplier_id: string;

  supplier_name: string;

  automatic?: boolean;
};
export type Cart_Create = {
  title?: string;

  user_id?: string;

  businessNr?: string;
};
export type Cart_Update = {
  title?: string;

  user_id?: string;

  businessNr?: string;
};
export type Acl = {};
export type CategorySearchParams = Array<{
  label?: string;

  values?: Array<{
    label?: string;
  }>;
}>;
export type Category = {
  name: string;

  hidden?: boolean;

  displayName?: string;

  categories?: Array<{}>;

  created_date?: string;

  _id?: number;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;
};
export type Category_Create = {
  name: string;

  hidden?: boolean;

  displayName?: string;

  description?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;
};
export type Category_Update = {
  name: string;

  hidden?: boolean;

  displayName?: string;

  description?: Array<{}>;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;
};
export type Codelist_Item = {
  label: string;

  default?: boolean;

  payload?: {};
};
export type Error = {
  statusCode: number;

  name: string;

  userinfo?: string;
};
export type PagerList = {
  total?: number;

  limit?: number;

  offset?: number;
};
export type ContentWidget = {
  type: string;
};
export type Price = {
  amount: number;

  amount_vat_excl: number;

  currency: string;

  vat_rate?: number;
};
export type AdminReceipt_Create = {
  order_ids: Array<number>;
};
export type AdminReceipt_Update = {
  status: string;
};
export type ReceiptNew = {
  order_ids: Array<number>;
};
export type ReceiptItem = {
  _id: number;

  created_date: string;

  supplier_id: string;

  supplier_name: string;

  appSpace?: string;

  status: string;

  order_ids: Array<number>;

  orders?: Array<{
    _id?: number;

    purchase_id?: number;

    invoicenr?: string;

    created_date?: string;

    supplier_id?: string;

    appSpace?: string;

    supplier_name?: string;

    status?: string;

    company?: string;

    business_id?: string;

    person_name?: string;

    person_surname?: string;

    phone?: string;

    email?: string;

    delivery_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    invoice_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    supplier?: {};

    payment?: {
      type?: string;

      name?: string;

      description?: string;
    };

    gpwebpay_ordernumber?: Array<{
      nr?: number;

      created_date?: string;
    }>;

    paid_date?: string;

    payment_price?: {};

    paid_payload?: {
      gpwebpay_ordernumber?: number;
    };

    delivery?: {
      type?: string;

      name?: string;

      description?: string;
    };

    totalprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    items?: Array<{
      quantity?: number;

      item_name?: string;

      paidbycredit?: boolean;

      variant?: {
        oldprice?: number;

        code: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };

        orderfragments?: Array<{
          code?: string;

          name?: string;

          unitprice?: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        vat_rate: number;

        units?: string;

        ean?: string;

        onsale?: {
          status: boolean;

          valid_to?: string;
        };

        availability?: {
          available: boolean;

          onstock?: boolean;

          managed_stock?: boolean;

          quantity?: number;

          max_quantity?: number;

          days?: number;

          at_date?: string;

          label?: string;
        };

        params?: Array<{
          key: string;

          type?: string;

          value?: string;
        }>;

        pricelevels?: Array<{
          pricelevel: string;

          unitprice: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        picture?: {
          media_url: string;

          public_url: string;

          smarttags?: Array<{
            key: string;

            values: Array<{
              key: string;
            }>;
          }>;

          dimensions?: {
            X: number;

            Y: number;

            W: number;

            H: number;
          };
        };
      };

      totalprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    last_update?: string;

    note?: string;

    status_log?: Array<{
      change_date?: string;

      old_status?: string;

      new_status?: string;

      reason?: string;

      admin_user?: {
        display_name?: string;
      };

      auth_user?: {
        display_name?: string;
      };
    }>;

    log?: Array<{
      date?: string;

      message?: string;

      payload?: {};
    }>;

    receipt_id?: number;

    saleschannel?: string;

    shipment_id?: string;

    shipment_link?: string;

    vat_nr?: string;

    voucher?: {
      _id: string;

      payload?: {};
    };

    spsInfo?: {
      refNr?: string;

      shipNr?: string;

      packageNo?: number;

      documentUrl?: string;
    };
  }>;

  paid_date?: string;

  totalprice?: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };
};
export type Receipt = {
  _id: number;

  created_date: string;

  status: string;

  supplier_id: string;

  supplier_name: string;

  paid_date?: string;

  order_ids: Array<number>;

  orders: Array<{
    _id?: number;

    purchase_id?: number;

    invoicenr?: string;

    created_date?: string;

    supplier_id?: string;

    appSpace?: string;

    supplier_name?: string;

    status?: string;

    company?: string;

    business_id?: string;

    person_name?: string;

    person_surname?: string;

    phone?: string;

    email?: string;

    delivery_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    invoice_address?: {
      company?: string;

      person: string;

      street: string;

      number?: string;

      city: string;

      zip: string;

      country: string;
    };

    supplier?: {};

    payment?: {
      type?: string;

      name?: string;

      description?: string;
    };

    gpwebpay_ordernumber?: Array<{
      nr?: number;

      created_date?: string;
    }>;

    paid_date?: string;

    payment_price?: {};

    paid_payload?: {
      gpwebpay_ordernumber?: number;
    };

    delivery?: {
      type?: string;

      name?: string;

      description?: string;
    };

    totalprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    items?: Array<{
      quantity?: number;

      item_name?: string;

      paidbycredit?: boolean;

      variant?: {
        oldprice?: number;

        code: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };

        orderfragments?: Array<{
          code?: string;

          name?: string;

          unitprice?: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        vat_rate: number;

        units?: string;

        ean?: string;

        onsale?: {
          status: boolean;

          valid_to?: string;
        };

        availability?: {
          available: boolean;

          onstock?: boolean;

          managed_stock?: boolean;

          quantity?: number;

          max_quantity?: number;

          days?: number;

          at_date?: string;

          label?: string;
        };

        params?: Array<{
          key: string;

          type?: string;

          value?: string;
        }>;

        pricelevels?: Array<{
          pricelevel: string;

          unitprice: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        picture?: {
          media_url: string;

          public_url: string;

          smarttags?: Array<{
            key: string;

            values: Array<{
              key: string;
            }>;
          }>;

          dimensions?: {
            X: number;

            Y: number;

            W: number;

            H: number;
          };
        };
      };

      totalprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    last_update?: string;

    note?: string;

    status_log?: Array<{
      change_date?: string;

      old_status?: string;

      new_status?: string;

      reason?: string;

      admin_user?: {
        display_name?: string;
      };

      auth_user?: {
        display_name?: string;
      };
    }>;

    log?: Array<{
      date?: string;

      message?: string;

      payload?: {};
    }>;

    receipt_id?: number;

    saleschannel?: string;

    shipment_id?: string;

    shipment_link?: string;

    vat_nr?: string;

    voucher?: {
      _id: string;

      payload?: {};
    };

    spsInfo?: {
      refNr?: string;

      shipNr?: string;

      packageNo?: number;

      documentUrl?: string;
    };
  }>;

  last_update?: string;
};
export type EshopRequest_Create = {
  note?: string;

  type: 'question' | 'claim';

  person: {
    name: string;

    surname: string;

    phone?: string;

    email: string;
  };

  products?: Array<{
    item_id: string;

    quantity?: number;
  }>;
};
export type RequestNew = {
  note?: string;

  type: 'question' | 'claim';

  person: {
    name: string;

    surname: string;

    phone?: string;

    email: string;
  };

  products?: Array<{
    item_id: string;

    quantity?: number;
  }>;
};
export type Request = {
  note?: string;

  type: 'question' | 'claim';

  person: {
    name: string;

    surname: string;

    phone?: string;

    email: string;
  };

  products?: Array<{
    item_id: string;

    quantity?: number;
  }>;

  _id: string;

  status: string;

  order_id: number;

  created_date: string;

  status_log?: Array<{
    change_date?: string;

    old_status?: string;

    new_status?: string;

    admin_user?: {
      display_name?: string;
    };
  }>;
};
export type Product_Availability = {
  available: boolean;

  onstock?: boolean;

  managed_stock?: boolean;

  quantity?: number;

  max_quantity?: number;

  days?: number;

  at_date?: string;

  label?: string;
};
export type Product_Param_Value = {
  key: string;
};
export type Product_Param = {
  key: string;

  type?: string;

  values?: Array<{
    key: string;
  }>;
};
export type Product_Variant_Param = {
  key: string;

  type?: string;

  value?: string;
};
export type Product_Tag = {
  tag: string;
};
export type Product_Listing = {
  list_id: string;
};
export type Product_Variant = {
  oldprice?: number;

  code: string;

  unitprice: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  orderfragments?: Array<{
    code?: string;

    name?: string;

    unitprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };
  }>;

  vat_rate: number;

  units?: string;

  ean?: string;

  onsale?: {
    status: boolean;

    valid_to?: string;
  };

  availability?: {
    available: boolean;

    onstock?: boolean;

    managed_stock?: boolean;

    quantity?: number;

    max_quantity?: number;

    days?: number;

    at_date?: string;

    label?: string;
  };

  params?: Array<{
    key: string;

    type?: string;

    value?: string;
  }>;

  pricelevels?: Array<{
    pricelevel: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };
  }>;

  picture?: {
    media_url: string;

    public_url: string;

    smarttags?: Array<{
      key: string;

      values: Array<{
        key: string;
      }>;
    }>;

    dimensions?: {
      X: number;

      Y: number;

      W: number;

      H: number;
    };
  };
};
export type Product_Description = Array<{
  type: string;
}>;
export type Product_Picture = {
  uri: string;

  title?: string;
};
export type Product_Category = number;
export type Product_ListItem_Admin = {
  saleschannels?: Array<string>;

  code?: string;

  brand?: string;

  type?: string;

  name?: string;

  url?: string;

  perex?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active?: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  tags?: Array<{
    tag: string;
  }>;

  created_date?: string;

  last_update?: string;

  _id?: string;

  supplier_id?: string;

  supplier_name?: string;
};
export type Product_ListItem = {
  code?: string;

  type?: string;

  name?: string;

  perex?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active?: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  tags?: Array<{
    tag: string;
  }>;

  variants?: Array<{
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }>;

  created_date?: string;

  last_update?: string;

  _id?: string;

  supplier_id?: string;

  supplier_name?: string;

  appSpace?: string;

  url?: string;

  categoryPriority?: Array<{
    categoryId: number;

    priority: number;
  }>;
};
export type ImportTypes = 'prices' | 'products';
export type Import_Log_Payload = {
  level?: string;

  date?: string;

  message?: string;

  payload?: Array<string>;
};
export type Import_Detail = {
  _id?: string;

  category?: 'prices' | 'products';

  file?: string;

  userFileName?: string;

  created_date?: string;

  status?: string;

  delimiter?: string;

  appSpace?: string;

  log?: Array<{
    level?: string;

    date?: string;

    message?: string;

    payload?: Array<string>;
  }>;
};
export type Import = {
  _id?: string;

  category?: 'prices' | 'products';

  file?: string;

  userFileName?: string;

  created_date?: string;

  status?: string;

  delimiter?: string;

  appSpace?: string;
};
export type URL_Map = {
  urltype?: string;

  appSpace?: string;
};
export type Product = {
  meta?: {
    title?: string;

    description?: string;
  };

  saleschannels?: Array<string>;

  code?: string;

  type?: string;

  name?: string;

  perex?: string;

  url?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  description?: Array<{
    type: string;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active?: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  params?: Array<{
    key: string;

    type?: string;

    values?: Array<{
      key: string;
    }>;
  }>;

  redirect?: {
    _id: string;

    type: string;

    url: string;
  };

  tags?: Array<{
    tag: string;
  }>;

  listings?: Array<{
    list_id: string;
  }>;

  variants?: Array<{
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }>;

  categories?: Array<number>;

  saleschannels_categories?: Array<number>;

  saleschannels_listings?: Array<string>;

  created_date?: string;

  last_update?: string;

  _id?: string;

  supplier_id?: string;

  supplier_name?: string;

  appSpace?: string;

  brand?: string;
};
export type Product_Detail_Admin = {
  meta?: {
    title?: string;

    description?: string;
  };

  saleschannels?: Array<string>;

  code?: string;

  type?: string;

  name?: string;

  perex?: string;

  url?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  description?: Array<{
    type: string;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active?: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  params?: Array<{
    key: string;

    type?: string;

    values?: Array<{
      key: string;
    }>;
  }>;

  redirect?: {
    _id: string;

    type: string;

    url: string;
  };

  tags?: Array<{
    tag: string;
  }>;

  listings?: Array<{
    list_id: string;
  }>;

  variants?: Array<{
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }>;

  categories?: Array<number>;

  saleschannels_categories?: Array<number>;

  saleschannels_listings?: Array<string>;

  created_date?: string;

  last_update?: string;

  _id?: string;

  supplier_id?: string;

  supplier_name?: string;

  appSpace?: string;

  brand?: string;
};
export type Product_Priority_Update = {
  priority: number;

  categoryId: number;
};
export type Product_Create = {
  type: string;

  redirect?: {
    _id: string;

    type: string;

    url: string;
  };

  name: string;

  perex?: string;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  description?: Array<{
    type: string;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  params?: Array<{
    key: string;

    type?: string;

    values?: Array<{
      key: string;
    }>;
  }>;

  tags?: Array<{
    tag: string;
  }>;

  listings?: Array<{
    list_id: string;
  }>;

  variants?: Array<{
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }>;

  categories?: Array<number>;

  saleschannels: Array<string>;

  saleschannels_categories?: Array<number>;

  saleschannels_listings?: Array<string>;
};
export type SupplierDeliveryOption = {
  name: string;

  type: string;

  creditpay: boolean;

  excludeFromFutbalnetShop?: boolean;

  price: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };

  availableCountries?: Array<string>;

  supportedPaymentMethods?: Array<string>;

  volumetric_pricelist?: Array<{
    weight: number;

    volume: number;

    price: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };
  }>;
};
export type Product_Update = {
  type: string;

  redirect?: {
    _id: string;

    type: string;

    url: string;
  };

  name: string;

  perex?: string;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  description?: Array<{
    type: string;
  }>;

  pictures?: Array<{
    uri: string;

    title?: string;
  }>;

  active: boolean;

  sfz_credit?: boolean;

  sfz_credit_only?: boolean;

  params?: Array<{
    key: string;

    type?: string;

    values?: Array<{
      key: string;
    }>;
  }>;

  tags?: Array<{
    tag: string;
  }>;

  listings?: Array<{
    list_id: string;
  }>;

  variants?: Array<{
    oldprice?: number;

    code: string;

    unitprice: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    orderfragments?: Array<{
      code?: string;

      name?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    vat_rate: number;

    units?: string;

    ean?: string;

    onsale?: {
      status: boolean;

      valid_to?: string;
    };

    availability?: {
      available: boolean;

      onstock?: boolean;

      managed_stock?: boolean;

      quantity?: number;

      max_quantity?: number;

      days?: number;

      at_date?: string;

      label?: string;
    };

    params?: Array<{
      key: string;

      type?: string;

      value?: string;
    }>;

    pricelevels?: Array<{
      pricelevel: string;

      unitprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    }>;

    picture?: {
      media_url: string;

      public_url: string;

      smarttags?: Array<{
        key: string;

        values: Array<{
          key: string;
        }>;
      }>;

      dimensions?: {
        X: number;

        Y: number;

        W: number;

        H: number;
      };
    };
  }>;

  categories?: Array<number>;

  saleschannels: Array<string>;

  saleschannels_categories?: Array<number>;

  saleschannels_listings?: Array<string>;
};
export type IssfInvoiceItem = {
  invoicenr: string;

  year: number;

  month: number;

  item_id: string;

  item_name: string;

  price: {
    amount: number;

    amount_vat_excl: number;

    currency: string;

    vat_rate?: number;
  };
};
export type CustomPayments = {
  standard_payment: {
    active: boolean;
  };

  pay_on_delivery: {
    active: boolean;

    name?: string;

    description?: string;

    unitprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };
  };

  pay_at_store: {
    active: boolean;

    name?: string;

    description?: string;
  };

  pay_by_transfer: {
    active: boolean;

    name?: string;

    description?: string;
  };
};
export type Analytics = {
  facebookPixel?: {
    id?: string;
  };

  exponea?: {
    id: string;
  };

  zendesk?: {
    id?: string;
  };

  mailchimp?: {
    scriptUrl?: string;
  };

  ecomail?: {
    appId?: string;
  };

  googleAnalytics?: {
    id?: string;

    enhancedEcommerce?: boolean;
  };

  googleTagManager?: {
    id?: string;
  };

  googleAdWords?: {
    id?: string;

    conversion?: {
      send_to?: string;
    };

    page_view?: {
      send_to?: string;
    };
  };
};
export type PublicSaleschannel = {
  _id: string;

  name: string;
};
export type Saleschannel = {
  _id: string;

  name: string;

  shared?: boolean;

  payments?: {
    standard_payment: {
      active: boolean;
    };

    pay_on_delivery: {
      active: boolean;

      name?: string;

      description?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    };

    pay_at_store: {
      active: boolean;

      name?: string;

      description?: string;
    };

    pay_by_transfer: {
      active: boolean;

      name?: string;

      description?: string;
    };
  };

  analytics?: {
    facebookPixel?: {
      id?: string;
    };

    exponea?: {
      id: string;
    };

    zendesk?: {
      id?: string;
    };

    mailchimp?: {
      scriptUrl?: string;
    };

    ecomail?: {
      appId?: string;
    };

    googleAnalytics?: {
      id?: string;

      enhancedEcommerce?: boolean;
    };

    googleTagManager?: {
      id?: string;
    };

    googleAdWords?: {
      id?: string;

      conversion?: {
        send_to?: string;
      };

      page_view?: {
        send_to?: string;
      };
    };
  };

  deliveryOptionsTypes?: Array<string>;
};
export type SaleschannelCreate = {
  _id: string;

  name: string;

  payments?: {
    standard_payment: {
      active: boolean;
    };

    pay_on_delivery: {
      active: boolean;

      name?: string;

      description?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    };

    pay_at_store: {
      active: boolean;

      name?: string;

      description?: string;
    };

    pay_by_transfer: {
      active: boolean;

      name?: string;

      description?: string;
    };
  };

  analytics?: {
    facebookPixel?: {
      id?: string;
    };

    exponea?: {
      id: string;
    };

    zendesk?: {
      id?: string;
    };

    mailchimp?: {
      scriptUrl?: string;
    };

    ecomail?: {
      appId?: string;
    };

    googleAnalytics?: {
      id?: string;

      enhancedEcommerce?: boolean;
    };

    googleTagManager?: {
      id?: string;
    };

    googleAdWords?: {
      id?: string;

      conversion?: {
        send_to?: string;
      };

      page_view?: {
        send_to?: string;
      };
    };
  };

  deliveryOptionsTypes?: Array<string>;
};
export type SaleschannelUpdate = {
  name?: string;

  catalogs?: Array<number>;

  payments?: {
    standard_payment: {
      active: boolean;
    };

    pay_on_delivery: {
      active: boolean;

      name?: string;

      description?: string;

      unitprice?: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };
    };

    pay_at_store: {
      active: boolean;

      name?: string;

      description?: string;
    };

    pay_by_transfer: {
      active: boolean;

      name?: string;

      description?: string;
    };
  };

  analytics?: {
    facebookPixel?: {
      id?: string;
    };

    exponea?: {
      id: string;
    };

    zendesk?: {
      id?: string;
    };

    mailchimp?: {
      scriptUrl?: string;
    };

    ecomail?: {
      appId?: string;
    };

    googleAnalytics?: {
      id?: string;

      enhancedEcommerce?: boolean;
    };

    googleTagManager?: {
      id?: string;
    };

    googleAdWords?: {
      id?: string;

      conversion?: {
        send_to?: string;
      };

      page_view?: {
        send_to?: string;
      };
    };
  };

  deliveryOptionsTypes?: Array<string>;

  meta?: {
    title?: string;

    description?: string;
  };
};
export type Pricelevel = {
  _id: string;

  name: string;
};
export type B2BPartner = {
  businessId: string;

  groupId?: string;

  pricelevel: string;

  name: string;

  created_date?: string;
};
export type B2BPartner_Create = {
  businessId?: string;

  groupId?: string;

  pricelevel: string;

  name: string;

  type?: 'company' | 'group';
};
export type B2BPartner_Update = {
  name: string;

  pricelevel: string;

  type?: 'company' | 'group';
};
export type Listing = {
  name: string;

  saleschannel: string;

  _id?: string;

  picture?: {
    uri?: string;
  };

  buttonTitle?: string;
};
export type Listing_Create = {
  name: string;

  saleschannel: string;

  buttonTitle: string;

  picture?: {
    uri?: string;
  };
};
export type Listing_Update = {
  name: string;

  saleschannel: string;

  buttonTitle: string;

  picture?: {
    uri?: string;
  };
};
export type PurchaseOptions = {
  delivery_options: Array<{
    supplier_id?: string;

    appSpace?: string;

    supplier_name?: string;

    invoiceProfile?: {};

    contactInfo?: {
      country?: string;

      phone?: string;

      email?: string;

      fax?: string;

      name?: string;

      www?: string;
    };

    options?: Array<{
      type: string;

      name: string;

      creditpay?: boolean;

      creditpay_forced?: boolean;

      availableCountries?: Array<string>;

      supportedPaymentMethods?: Array<string>;

      price: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };

      description?: string;

      icon_url?: string;

      icon_svg?: string;
    }>;
  }>;

  payment_options: Array<{
    type: string;

    name: string;

    description?: string;

    icon_url?: string;

    icon_svg?: string;
  }>;

  cart: {
    _id: string;

    created_date?: string;

    expire_at: string;

    items?: Array<{
      quantity: number;

      item_name: string;

      item_picture?: {
        uri: string;

        title?: string;
      };

      creditpay?: boolean;

      creditpay_forced?: boolean;

      product_id: string;

      variant: {
        oldprice?: number;

        code: string;

        unitprice: {
          amount: number;

          amount_vat_excl: number;

          currency: string;

          vat_rate?: number;
        };

        orderfragments?: Array<{
          code?: string;

          name?: string;

          unitprice?: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        vat_rate: number;

        units?: string;

        ean?: string;

        onsale?: {
          status: boolean;

          valid_to?: string;
        };

        availability?: {
          available: boolean;

          onstock?: boolean;

          managed_stock?: boolean;

          quantity?: number;

          max_quantity?: number;

          days?: number;

          at_date?: string;

          label?: string;
        };

        params?: Array<{
          key: string;

          type?: string;

          value?: string;
        }>;

        pricelevels?: Array<{
          pricelevel: string;

          unitprice: {
            amount: number;

            amount_vat_excl: number;

            currency: string;

            vat_rate?: number;
          };
        }>;

        picture?: {
          media_url: string;

          public_url: string;

          smarttags?: Array<{
            key: string;

            values: Array<{
              key: string;
            }>;
          }>;

          dimensions?: {
            X: number;

            Y: number;

            W: number;

            H: number;
          };
        };
      };

      item_id?: string;

      totalprice: {
        amount: number;

        amount_vat_excl: number;

        currency: string;

        vat_rate?: number;
      };

      updated_date?: string;

      payload?: {};

      supplier_id: string;

      supplier_name: string;

      automatic?: boolean;
    }>;

    title?: string;

    totalprice?: {
      amount: number;

      amount_vat_excl: number;

      currency: string;

      vat_rate?: number;
    };

    voucherError?: {
      statusCode: number;

      name: string;

      userinfo?: string;
    };

    voucher?: {
      _id: string;

      appSpace?: string;

      name?: string;

      payload?: {};
    };
  };

  credits_limit: number;
};
export type Smarttags = Array<{
  key: string;

  values: Array<{
    key: string;
  }>;
}>;
export type Smarttag = {
  key: string;

  values: Array<{
    key: string;
  }>;
};
export type MediaManagerFile = {
  media_url: string;

  public_url: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;
};
export type MediaManagerImage = {
  media_url: string;

  public_url: string;

  smarttags?: Array<{
    key: string;

    values: Array<{
      key: string;
    }>;
  }>;

  dimensions?: {
    X: number;

    Y: number;

    W: number;

    H: number;
  };
};
export type Supplier = {
  _id: string;

  name?: string;

  featured?: boolean;

  featuredOrder?: number;
};
export type Supplier_Update = {
  featured?: boolean;

  featuredOrder?: number;
};
export type Downloader = {
  url: string;

  download_id: string;
};
export type PaymentDetail = {
  _id: number;

  ordernumber: number;

  merordernum: number;

  createdDate: string;

  amount: number;

  currency: string;

  paid_date?: string;

  paymentStatus?: {
    state: number;

    status: string;

    subStatus: string;
  };

  isPaid?: boolean;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * Eshop RESTful API specification.

 * @class EshopApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class EshopApi {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   *
   * @method
   * @name EshopApi#getDownloaderFile
   */
  getDownloaderFile(downloadId: string, parameters: {} = {}): Promise<void> {
    let path = '/downloader/{download_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    path = path.replace('{download_id}', downloadId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name EshopApi#exportCreditItems
   */
  exportCreditItems(
    businessId: string,
    parameters: {
      fromDate?: string;
      toDate?: string;
    } = {},
  ): Promise<{}> {
    let path = '/export/{business_id}/credit-items';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{business_id}', businessId.toString());

    if (typeof parameters['fromDate'] !== 'undefined') {
      queryParameters['from_date'] = parameters['fromDate'];
    }

    queryParameters['from_date'] = this.transformParameter(
      queryParameters['from_date'],
    );

    if (typeof parameters['toDate'] !== 'undefined') {
      queryParameters['to_date'] = parameters['toDate'];
    }

    queryParameters['to_date'] = this.transformParameter(
      queryParameters['to_date'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new EshopApi();
export { EshopApi };
