import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import {
  ContextMarginBottomStyles,
  ContextMarginSmallBottomStyles,
  ContextMarginTopStyles,
} from '.';
import styled, { css } from '../../theme/styled-components';
import MaxWidthBox from '../MaxWidthBox';

const SidebarLayout = styled(MaxWidthBox)`
  display: flex;
`;

export const BottomPaddedSidebarLayout = styled(SidebarLayout)`
  ${ContextMarginBottomStyles}
`;

export const TopPaddedSidebarLayout = styled(SidebarLayout)`
  ${ContextMarginTopStyles}
`;

export const PaddedSidebarLayout = styled(BottomPaddedSidebarLayout)`
  ${ContextMarginBottomStyles}
`;

export const TopicPageLayout = styled(SidebarLayout)`
  padding-top: ${rem(24)};
  ${mb('s')} {
    padding-top: 0;
  }
`;

export const TopAndBottomPaddedSidebarLayout = styled(SidebarLayout)`
  ${ContextMarginBottomStyles}
  ${ContextMarginTopStyles}
`;

export const ContentSidebarLayout = styled.div`
  display: flex;
`;

export const BottomPaddedContentSidebarLayout = styled(ContentSidebarLayout)`
  ${ContextMarginBottomStyles}
`;

export const SidebarLayoutContent = styled.div`
  width: 100%;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const StickySidebarContainer = styled.div`
  position: sticky;
  top: ${rem(89)};
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: ${rem(32)};
  }
`;

interface ISidebarProps {
  width?: number;
  position?: 'right' | 'left';
}

export const SidebarCommonStyles = styled.aside<ISidebarProps>`
  ${({ width = 300 }) => css`
    flex: 0 0 ${rem(width)};
  `}
  ${({ position = 'right' }) =>
    position === 'right'
      ? css`
          margin: 0 0 0 ${rem(32)};
        `
      : css`
          margin: 0 ${rem(32)} 0 0;
        `}
  display: none;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: ${rem(32)};
  }
`;

export const Sidebar = styled(SidebarCommonStyles)`
  ${mb('l')} {
    display: flex;
  }
`;

export const SidebarThreeColRight = styled(SidebarCommonStyles)`
  ${mb('xxl')} {
    display: flex;
  }
`;

export const SidebarDesktop = styled.aside<ISidebarProps>`
  ${({ width = 300 }) => css`
    flex: 0 0 ${rem(width)};
  `}
  ${({ position = 'right' }) =>
    position === 'right'
      ? css`
          margin: 0 0 0 ${rem(32)};
        `
      : css`
          margin: 0 ${rem(32)} 0 0;
        `}

  display: none;
  flex-direction: column;
  ${mb(1200)} {
    display: flex;
  }
  & > *:not(:last-child) {
    margin-bottom: ${rem(32)};
  }
`;

export const ResponsiveSidebar = styled.aside`
  ${ContextMarginSmallBottomStyles}
  ${ContextMarginTopStyles}
  ${mb('l')} {
    display: none;
  }
  & > *:not(:last-child) {
    ${ContextMarginSmallBottomStyles}
  }
`;

export default SidebarLayout;
