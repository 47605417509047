import React from 'react';
import { ShareButtonShape, SocialShareConfig } from '../library';
import FacebookShare from '../Types/FacebookShare';
import TwitterShare from '../Types/TwitterShare';

interface IOwnProps {
  config: SocialShareConfig;
  url: string;
  title?: string;
  shape: ShareButtonShape;
}

type IProps = IOwnProps;

const SocialShare: React.FC<IProps> = ({ config, url, title, shape }) => {
  const result: React.ReactNodeArray = [];

  if (config.facebook) {
    result.push(
      <FacebookShare
        key={`facebook-${url}`}
        shape={shape}
        url={url}
        title={title}
      />,
    );
  }

  if (config.facebook) {
    result.push(
      <TwitterShare
        key={`twitter-${url}`}
        shape={shape}
        url={url}
        title={title}
      />,
    );
  }

  return <>{result}</>;
};

export default SocialShare;
