import React from 'react';
import FutbalnetMenu from '../../components/FutbalnetHeader/Menu';
import { SectionNode } from '../../library/App';
import SectionsList from './SectionsList';

type IOwnProps = {
  xPosition: number;
  sections: SectionNode[];
  active: boolean;
  onClose: () => void;
};

type IProps = IOwnProps;

const CustomSectionsDropDown: React.FC<IProps> = ({
  xPosition,
  sections,
  active,
  onClose,
}) => {
  return (
    <FutbalnetMenu.PositionedDropdown
      xPosition={xPosition}
      active={active}
      onClose={onClose}
    >
      <SectionsList sections={sections} />
    </FutbalnetMenu.PositionedDropdown>
  );
};

export default CustomSectionsDropDown;
