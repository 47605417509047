import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import { EntitiesState } from '../../../pages/App/reducer';
import styled from '../../../theme/styled-components';
import getArticleMainSection from '../../../utilities/getArticleMainSection';
import Article from './Article';

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  max-width: ${rem(500)};
  margin: 0;
  padding: 0;
  > li:not(:last-child) {
    margin-bottom: ${rem(32)};
  }
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById: EntitiesState['sections'];
  className?: string;
};

type IProps = IOwnProps;

const ArticlesListCompact: React.FC<IProps> = ({
  articles,
  sectionsById,
  className,
}) => {
  if (!articles.length) {
    return null;
  }

  function renderArticle(article: IArticle) {
    const section = getArticleMainSection(article, sectionsById);
    return (
      <Article
        key={`${article._id}-${article.modified}`}
        article={article}
        section={section}
      />
    );
  }

  return <List className={className}>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesListCompact);
