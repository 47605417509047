import React from 'react';
import __ from '../../../utilities/__';
import Icon from '../../Icon';
import { CircleShape, RectangleShape, SocialShareName } from '../Common';
import { ShareButtonShape } from '../library';
import { getSharingHref } from '../utils';

interface IOwnProps {
  url: string;
  title?: string;
  shape: ShareButtonShape;
}

type IProps = IOwnProps;

const FacebookShare: React.FC<IProps> = ({ url, title, shape }) => {
  const href = getSharingHref('facebook', url, title);

  if (shape === 'circle') {
    return (
      <CircleShape
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        color="#1877F2"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          window.open(
            href || '',
            '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600',
          );
          return false;
        }}
        title={__('Zdieľať na Facebook')}
      >
        <Icon name="facebook" size={20} color="#fff" />
      </CircleShape>
    );
  }

  return (
    <RectangleShape
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      color="#1877F2"
      onClick={() => {
        window.open(
          href || '',
          '',
          'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=745,width=600',
        );
        return false;
      }}
      title={__('Zdieľať na Facebook')}
    >
      <Icon name="facebook" size={12} color="#fff" />
      <SocialShareName>Share</SocialShareName>
    </RectangleShape>
  );
};

export default FacebookShare;
