import { rem } from 'polished';
import styled from '../../theme/styled-components';

export default styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-height: ${rem(316)};
  overflow-y: auto;
  padding-bottom: ${rem(16)};
`;
