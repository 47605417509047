import {
  commit,
  CommitError,
  commitWithParams,
  getListParameters,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory, { thunkToAction } from 'typescript-fsa-redux-thunk';
import { OrganizationProfileStructureItem } from '../../api/CoreApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities, Pager } from '../../library/App';
import { ICompetition, ISeason } from '../../library/Competitions';
import normalizeEntities from '../../utilities/normalizeEntities';

const create = actionCreatorFactory('FUTBALNET_APP');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const loadUnions = createAsync<void, OrganizationProfileStructureItem[]>(
  'GET_SETTINGS',
  async (parameters, dispatch, getState, { CoreApi }) => {
    try {
      const { items } = await CoreApi.getPPORelationsStructure(
        config.OWNER_PPO,
        { ppoType: 'union' },
      );
      return items;
    } catch (e: any) {
      throw new InternalServerError(e);
    }
  },
);

export const loadUnionSeasons = createAsync<void, ISeason[]>(
  'GET_UNION_SEASONS',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    try {
      const { seasons } = await CompetitionsApi.getUnionSeasons(
        config.OWNER_PPO,
      );
      return seasons;
    } catch (e: any) {
      throw new InternalServerError(e);
    }
  },
);

const reduceMenuCompetitionProps = ({
  season,
  parts,
  ...competition
}: ICompetition): ICompetition => {
  return { ...competition, parts: (parts || []).map(({ teams, ...p }) => p) };
};

export const loadCompetitions = createAsync<
  {
    seasonName?: string;
    appSpace?: string[];
  },
  NormalizedEntities<'competitions'> & Pager
>(
  'LOAD_ALL_COMPETITIONS',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    const { competitions } = await CompetitionsApi.getAllCompetitions({
      ownerPpo: config.OWNER_PPOS as any,
      ...parameters,
    });

    return {
      ...normalizeEntities(
        'competitions',
        competitions.map(reduceMenuCompetitionProps),
      ),
      limit: 0,
      offset: 0,
      total: competitions.length,
      nextOffset: null,
    };
  },
);

export const loadCompetitionsList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(
                thunkToAction(loadCompetitions)(parameters) as any,
              );
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};

export const loadCompetitionsListByParams = (
  listName: string,
  params: {
    seasonName?: string;
    appSpace?: string[];
  },
): CustomThunkAction<Promise<void>> => {
  return (dispatch) => {
    return dispatch(
      commitWithParams.action({
        listName,
        params,
        load: async () => {
          try {
            const { results, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadCompetitions)(params) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
