import DropDown from '../../sportnet.sme.sk-ui/DropDown';
import React from 'react';
import TertiaryButton from '../../sportnet.sme.sk-ui/TertiaryButton';
import { ISection, SectionNode } from '../../library/App';
import getSectionUrl from '../../utilities/getSectionUrl';
import getBaseUrl from '../../utilities/getBaseUrl';

interface OwnProps {
  sections: SectionNode[];
}

const SectionsDropown: React.FC<OwnProps> = ({ sections }) => {
  const [opened, setOpened] = React.useState(false);


  const toggleOpened = () => {
    setOpened((prev) => !prev);
  };

  const handleOnClickOutside = () => {
    setOpened(false);
  };

  const options = sections.map((section) => ({
    id: String(section._id),
    label: section.name!,
    href: getSectionUrl(getBaseUrl(), section as ISection),
  }));

  return (
    <DropDown
      opened={opened}
      openDirection={'right'}
      responsiveOppositeDirection={true}
      items={options}
      controlElement={
        <TertiaryButton
          onClick={toggleOpened}
          size="mobile"
          icon="chevron"
          iconSize="s"
          iconRotation={opened ? 180 : 0}
        />
      }
      onClickOutside={handleOnClickOutside}
    />
  );
};

export default SectionsDropown;
