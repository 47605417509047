import {
  ITeam,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/library/Competitions';
import { __ } from '@sportnet/content/utilities/utilities';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import { emphasizeColor } from '@sportnet/ui/lib/Themes/utilities';
import { format } from 'date-fns';
import { rem } from 'polished';
import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled from 'styled-components';
import getFallbackClubOrUnionLogo from '../../../../utilities/getFallbackClubOrUnionLogo';
import getMatchBaseUrl from '../../../../utilities/getMatchBaseUrl';
import Link from '../../../Link';
import { TeamLogo, TeamLogoWrapper } from '../CompetitionDraw/SegmentMatches';

export const SEGMENT_HEIGHT = 80;

const SegmentMatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .active {
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => emphasizeColor(1, theme.color.primary)};
  }
  > div:first-child {
    margin-bottom: -${rem(2)};
  }
`;

const SegmentRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${rem(11)};
  align-items: center;
  padding: 0 ${rem(8)};
  height: ${rem(SEGMENT_HEIGHT / 2)};
`;

const MatchesResults = styled.div<{ winningSide: boolean }>`
  align-items: center;
  display: flex;
  font-size: ${rem(14)};
  font-weight: ${({ winningSide }) => (winningSide ? '800' : 'normal')};
  & > div {
    padding: ${`0 ${rem(3)}`};
  }
  a {
    color: inherit;
  }
  sup {
    font-size: ${rem(10)};
    font-weight: 700;
  }
`;

const SegmentTeam = styled.div`
  display: flex;
  width: ${rem(170)};
  align-items: center;
`;

const SegmentTeamName = styled.div<{ winningSide: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;
  font-size: ${rem(12)};
  font-weight: ${({ winningSide }) => (winningSide ? '800' : 'normal')};
`;

interface IOwnProps {
  segmentMatches: IWidgetMatchesListMatchItem[];
  homeTeam?: ITeam;
  awayTeam?: ITeam;
}

const SegmentMatches: React.FC<IOwnProps> = ({
  segmentMatches,
  homeTeam,
  awayTeam,
}) => {
  const renderSegmentRow = (
    team?: ITeam,
    opposingTeam?: ITeam,
    isWinningSide?: boolean,
    hasWinningSide?: boolean,
    idx?: number,
    penaltiesWin?: boolean
  ) => {
    return (
      <SegmentRow>
        <SegmentTeam>
          {team && (
            <>
              <TeamLogoWrapper style={{ marginRight: rem(12) }}>
                <TeamLogo
                  fallbackSrc={getFallbackClubOrUnionLogo()}
                  alt={team && team.name}
                  src={
                    !!team
                      ? getTeamLogoSrc(segmentMatches[0].appSpace, team)
                      : ''
                  }
                  loading="lazy"
                />
              </TeamLogoWrapper>
              <SegmentTeamName winningSide={!!isWinningSide}>
                {team && team.name}
              </SegmentTeamName>
            </>
          )}
        </SegmentTeam>
        <MatchesResults winningSide={!!isWinningSide}>
          {segmentMatches.map((m) => {
            const scoreIdx =
              team?.additionalProperties.homeaway === 'home' ? 0 : 1;
            let teamGoals = '';
            if (m.contumationScore && m.contumationScore.length) {
              teamGoals = String(m.contumationScore[scoreIdx]);
            } else if (m.score && m.score.length) {
              if (isWinningSide && penaltiesWin) {
                teamGoals = String(m.score[scoreIdx] + 1);
              } else {
                teamGoals = String(m.score[scoreIdx]);
              }
            }
            const PPWinnerTeamId = null;
            const SNWinnerTeamId = null;
            if (hasWinningSide) {
              return (
                <div key={m._id}>
                  {teamGoals}
                  {PPWinnerTeamId === team?._id && <sup>{__('PP')}</sup>}
                  {SNWinnerTeamId === team?._id && <sup>{__('SN')}</sup>}
                  {(PPWinnerTeamId || SNWinnerTeamId) &&
                    PPWinnerTeamId !== team?._id &&
                    SNWinnerTeamId !== team?._id && <sup />}
                </div>
              );
            } else if (idx === 0) {
              return (
                <div key={m._id} style={{ fontSize: rem(11) }}>
                  {format(new Date(m.startDate), 'd.M.Y HH:mm')}
                </div>
              );
            }
            return <div key={m._id} />;
          })}
        </MatchesResults>
      </SegmentRow>
    );
  };

  let winningSide: any = null;
  let penaltiesWin = false;

  if (
    segmentMatches.length &&
    (((segmentMatches[0].score || []).length &&
      segmentMatches[0].score![0] > segmentMatches[0].score![1]) ||
      ((segmentMatches[0].contumationScore || []).length &&
        segmentMatches[0].contumationScore![0] >
          segmentMatches[0].contumationScore![1]))
  ) {
    const winningTeam = getProp(segmentMatches, [0, 'teams'], []).find(
      (t: ITeam) => getProp(t, ['additionalProperties', 'homeaway']) === 'home'
    );
    if (winningTeam) {
      winningSide = winningTeam?._id;
    }
  } else if (
    segmentMatches.length &&
    (((segmentMatches[0].score || []).length &&
      segmentMatches[0].score![1] > segmentMatches[0].score![0]) ||
      ((segmentMatches[0].contumationScore || []).length &&
        segmentMatches[0].contumationScore![1] >
          segmentMatches[0].contumationScore![0]))
  ) {
    const winningTeam = getProp(segmentMatches, [0, 'teams'], []).find(
      (t: ITeam) => getProp(t, ['additionalProperties', 'homeaway']) === 'away'
    );
    if (winningTeam) {
      winningSide = winningTeam?._id;
    }
  } else if (
    segmentMatches.length &&
    (segmentMatches[0].score || []).length &&
    segmentMatches[0].score![0] === segmentMatches[0].score![1] &&
    (segmentMatches[0].penaltiesScore || []).length &&
    segmentMatches[0].penaltiesScore![0] > segmentMatches[0].penaltiesScore![1]
  ) {
    const winningTeam = getProp(segmentMatches, [0, 'teams'], []).find(
      (t: ITeam) => getProp(t, ['additionalProperties', 'homeaway']) === 'home'
    );
    if (winningTeam) {
      winningSide = winningTeam?._id;
      penaltiesWin = true;
    }
  } else if (
    segmentMatches.length &&
    (segmentMatches[0].score || []).length &&
    segmentMatches[0].score![0] === segmentMatches[0].score![1] &&
    (segmentMatches[0].penaltiesScore || []).length &&
    segmentMatches[0].penaltiesScore![1] > segmentMatches[0].penaltiesScore![0]
  ) {
    const winningTeam = getProp(segmentMatches, [0, 'teams'], []).find(
      (t: ITeam) => getProp(t, ['additionalProperties', 'homeaway']) === 'away'
    );
    if (winningTeam) {
      winningSide = winningTeam?._id;
      penaltiesWin = true;
    }
  }

  const content = (
    <SegmentMatchesWrapper>
      {renderSegmentRow(
        homeTeam,
        awayTeam,
        winningSide === homeTeam?._id,
        !!winningSide,
        0,
        penaltiesWin
      )}
      {renderSegmentRow(
        awayTeam,
        homeTeam,
        winningSide === awayTeam?._id,
        !!winningSide,
        1,
        penaltiesWin
      )}
    </SegmentMatchesWrapper>
  );

  return (
    <Link
      to={getMatchBaseUrl({
        zvaz: segmentMatches[0].appSpace,
        zapas: (segmentMatches[0] as any).__issfId || segmentMatches[0]._id,
      })}
    >
      {content}
    </Link>
  );
};

export default SegmentMatches;
