import Cookies from 'js-cookie';
import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import CmsApi, { SectionPublic, SectionPublicDetail } from '../../api/CmsApi';
import CoreApi, { Codelist, UserPublicProfile } from '../../api/CoreApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import {
  INormalizedSectionNode,
  INormalizedSectionTree,
  IStaticContent,
  NormalizedEntities,
  Pager,
  SectionId,
  SectionNode,
  SectionTreeType,
  Writeable,
} from '../../library/App';
import { IBasicCodelistItem } from '../../library/Competitions';
import { isJwtToken } from '../../utilities/auth/isJwtToken';
import normalizeEntities from '../../utilities/normalizeEntities';
import reducePPOUserProps from '../../utilities/reducePPOUserProps';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import updateEntitiesUtility from '../../utilities/updateEntities';
import { CSMAppSpaceSelector } from '../DomainResolver/selectors';
import { entitiesSelector } from './selectors';
import jwt from 'jsonwebtoken';
import { fromUnixTime, isBefore } from 'date-fns';
import { AuthUser, JwtTokenBody } from '../../library/Auth';
import {
  SPORT_SECTOR_SETTINGS,
  SPORT_SECTOR_PHASES,
  SPORT_SECTOR_EVENTS,
  SPORT_SECTOR_DELEGATES,
  SPORT_SECTOR_CREW,
  SPORT_SECTOR_TELEVISION_BROADCASTERS,
  SPORT_SECTOR_INTERNET_BROADCASTERS,
} from '../../codelists/';
import getItemsBySportSector from '../../utilities/codelists/getItemsBySportSector';
import getBaseUrl from '../../utilities/getBaseUrl';
import PagesApi from '../../api/PagesApi';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const setAuthUser = create<{
  user?: AuthUser | null;
  fetching?: number;
  isEditor?: boolean;
}>('SET_AUTH_USER');

export const loadPath = createAsync<
  {
    id: SectionId;
  },
  { data: { path: string[] | number[] } }
>('LOAD_PATH', async ({ id }, dispatch, getState, { CmsApi }) => {
  const appSpace = CSMAppSpaceSelector(getState());

  const response = await CmsApi.getPathForSectionId(
    config.APP_ID,
    appSpace,
    config.DEFAULT_CONTENT_DIVIDER,
    id
  );
  const { entities, results } = normalizeEntities(
    'sections',
    response.path!.map(reduceSectionProps) // @TODO csm/api path je optional
  );
  dispatch(updateEntities(entities));
  return {
    data: {
      path: results,
    },
  };
});

export const verifyToken = createAsync<{ token: string }, boolean>(
  'VERIFY_TOKEN',
  async ({ token }, dispatch, _getState) => {
    if (isJwtToken(token)) {
      try {
        const body = jwt.decode(token) as JwtTokenBody | null;

        if (body) {
          // overime expiraciu
          const now = new Date();
          if (isBefore(now, fromUnixTime(body.exp))) {
            const { displayName, email, externalProfileId, groups } = body;
            dispatch(
              setAuthUser({
                user: {
                  displayName,
                  email,
                  externalProfileId,
                  groups,
                },
                fetching: 0,
                isEditor: body.pagesRole === 'admin',
              })
            );
            CoreApi.setToken(token);
            CmsApi.setToken(token);
          } else {
            throw new Error('Token expired');
          }
        } else {
          /* napodarilo sa vyparsovat JSON token */
          throw new Error('Malformed JWT');
        }
        return true;
      } catch (e) {
        dispatch(setAuthUser({ fetching: 0 }));
        CoreApi.setToken('');
        CmsApi.setToken('');
        Cookies.remove(config.TOKEN_COOKIE_KEY, {
          path: '/',
          domain: process.env.REACT_APP_BASE_HOSTNAME,
        });
        return false;
      }
    } else {
      try {
        dispatch(setAuthUser({ fetching: 2 }));
        CoreApi.setToken(token);
        CmsApi.setToken(token);

        const [user, PPO] = await Promise.all([
          CoreApi.me({ externalProfile: 'sme', withGroups: true }),
          CoreApi.meAppSpacesForApp('pages'),
        ]);

        const {
          email = '',
          externalProfileId,
          groups,
        } = user as UserPublicProfile & {
          externalProfileId: string;
          groups: string[];
        };
        dispatch(
          setAuthUser({
            user: {
              displayName: '',
              email,
              externalProfileId,
              groups,
            },
            fetching: 0,
            isEditor: PPO.appspaces?.some(
              (ppo) => ppo.app_space === process.env.REACT_APP_APPSPACE
            ),
          })
        );
        return true;
      } catch (e: any) {
        dispatch(setAuthUser({ fetching: 0 }));
        CoreApi.setToken('');
        CmsApi.setToken('');
        Cookies.remove(config.TOKEN_COOKIE_KEY, {
          path: '/',
          domain: process.env.REACT_APP_BASE_HOSTNAME,
        });
        return false;
      }
    }
  }
);

export const performLogin = createAsync<void, undefined>(
  'LOGIN',
  async (_, dispatch, _getState) => {
    let token = '';
    try {
      dispatch(setAuthUser({ fetching: 1 }));
      // toto nastavi cookies
      const response = await fetch(
        process.env.NODE_ENV === 'development'
          ? 'https://beta.sportnet.sme.sk/login/'
          : `${getBaseUrl()}/login/`
      );
      if (response.ok) {
        if (
          response.headers.get('content-type')?.startsWith('application/json')
        ) {
          token = (await response.json())._accessToken;
        }
      }
    } catch (e) {
      console.error('Login error', e);
    }
    if (token) {
      // pocas "develeopment" nemusi byt pustene SSR, ktore nastavuje cookie v produkcii
      if (process.env.NODE_ENV === 'development') {
        Cookies.set(config.TOKEN_COOKIE_KEY, token);
      }
      dispatch(verifyToken({ token }));
    } else {
      dispatch(setAuthUser({ fetching: 0 }));
    }
  }
);

export const loadSectionTree = createAsync<
  {
    sectionIdOrUniqId?: string;
    treelevel?: number;
    appSpace?: string;
  },
  {
    data: {
      tree: SectionTreeType;
    };
    sections: SectionPublic[];
  }
>(
  'LOAD_SECTION_TREE',
  async (
    { sectionIdOrUniqId, treelevel, appSpace: appSpaceOverRide },
    dispatch,
    getState,
    { CmsApi }
  ) => {
    const appSpace = appSpaceOverRide || CSMAppSpaceSelector(getState());

    let response: SectionPublic[];

    if (sectionIdOrUniqId) {
      response = (
        await CmsApi.getPublicSubsectionsByIdOrUniqId(
          config.APP_ID,
          appSpace,
          config.DEFAULT_CONTENT_DIVIDER,
          sectionIdOrUniqId,
          {
            treelevel,
          }
        )
      ).sections!;
    } else {
      response = (
        await CmsApi.getPublicSectionsTree(
          config.APP_ID,
          appSpace,
          config.DEFAULT_CONTENT_DIVIDER
        )
      ).tree!;
    }

    const sectionsEntities: {
      [key: string]: SectionNode;
    } = {};

    const normalizeTree = (
      sections: SectionTreeType
    ): INormalizedSectionTree => {
      return sections.map((section) => {
        sectionsEntities[section._id!] = reduceSectionProps(section);

        const sectionNode: INormalizedSectionNode = {
          _id: section._id!,
        };

        if (section.sections && section.sections.length > 0) {
          const normalizedSections = normalizeTree(section.sections);
          sectionNode.sections = normalizedSections;
        }

        return sectionNode;
      });
    };

    const tree = normalizeTree(response);

    await dispatch(
      updateEntities({
        sections: sectionsEntities,
      })
    );

    return {
      data: {
        tree,
      },
      sections: response,
    };
  }
);

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      })
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    })
  );
});

export const updateEntities = (entitiesToUpdate) => {
  const entities = entitiesToUpdate as Writeable<typeof entitiesToUpdate>;
  return (dispatch, getState) => {
    const oldEntities = entitiesSelector(getState());
    const nextEntities = updateEntitiesUtility(oldEntities, entities);
    return dispatch({
      type: 'UPDATE_ENTITIES',
      payload: {
        result: {
          entities: nextEntities,
        },
      },
    });
  };
};

export const replaceEntities = (entities) => {
  return {
    type: 'REPLACE_ENTITIES',
    payload: {
      result: {
        entities,
      },
    },
  };
};

export const normalizeCompetitionsSettting = (
  key: string,
  sportSector: string,
  data: IBasicCodelistItem[]
) => {
  return {
    entities: {
      [key]: {
        [sportSector]: data.reduce((acc, item) => {
          return { ...acc, [String(item._id)]: item };
        }, {}) as { [key: string]: IBasicCodelistItem[] },
      },
    },
    result: data.map((item) => item._id),
  };
};

export const getSportSectorsTelevisionBroadcasters = createAsync<
  { sportSector: string },
  void
>(
  'GET_SPORT_SECTORS_TELEVISION_BROADCASTERS',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(
      sportSector,
      SPORT_SECTOR_TELEVISION_BROADCASTERS
    );

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_television_broadcasters',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorTelevisionBroadcasters',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsInternetBroadcasters = createAsync<
  { sportSector: string },
  void
>(
  'GET_SPORT_SECTORS_INTERNET_BROADCASTERS',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(
      sportSector,
      SPORT_SECTOR_INTERNET_BROADCASTERS
    );

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_internet_broadcasters',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorInternetBroadcasters',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsPhases = createAsync<{ sportSector: string }, void>(
  'GET_SPORT_SECTORS_PHASES',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(sportSector, SPORT_SECTOR_PHASES);

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_phases',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorPhases',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsEvents = createAsync<{ sportSector: string }, void>(
  'GET_SPORT_SECTORS_EVENTS',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(sportSector, SPORT_SECTOR_EVENTS);

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_events',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorEvents',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsDelegates = createAsync<
  { sportSector: string },
  void
>(
  'GET_SPORT_SECTORS_DELEGATES',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(sportSector, SPORT_SECTOR_DELEGATES);

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_delegates',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorDelegates',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsCrew = createAsync<{ sportSector: string }, void>(
  'GET_SPORT_SECTORS_CREW',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(sportSector, SPORT_SECTOR_CREW);

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_crew',
        sportSector
      ));
    const { entities } = normalizeCompetitionsSettting(
      'sportSectorCrew',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const getSportSectorsSettings = createAsync<
  { sportSector: string },
  void
>(
  'GET_SPORT_SECTORS_SETTINGS',
  async ({ sportSector }, dispatch, getState, { CompetitionsApi }) => {
    const codeList = getItemsBySportSector(sportSector, SPORT_SECTOR_SETTINGS);

    const response =
      codeList ||
      (await CompetitionsApi.getSettingBySportSector(
        'sport_sector_settings',
        sportSector
      ));

    const { entities } = normalizeCompetitionsSettting(
      'sportSectorSettings',
      sportSector,
      response.items
    );
    dispatch(updateEntities(entities));
  }
);

export const loadWholeSectionTree = createAsync<
  void,
  {
    data: {
      tree: INormalizedSectionTree;
    };
  }
>('LOAD_WHOLE_SECTION_TREE', async (params, dispatch, getState, { CmsApi }) => {
  const appSpace = CSMAppSpaceSelector(getState());
  const response = await CmsApi.getPublicSectionsTree(
    config.APP_ID,
    appSpace,
    config.DEFAULT_CONTENT_DIVIDER
  );

  const sectionsEntities: {
    [key: string]: SectionNode;
  } = {};

  const normalizeTree = (sections: SectionTreeType): INormalizedSectionTree => {
    return sections.map((section) => {
      sectionsEntities[section._id!] = reduceSectionProps(section);

      const sectionNode: INormalizedSectionNode = {
        _id: section._id!,
      };

      if (section.sections && section.sections.length > 0) {
        const normalizedSections = normalizeTree(section.sections);
        sectionNode.sections = normalizedSections;
      }

      return sectionNode;
    });
  };

  const tree = normalizeTree(response.tree!); // @TODO csm/api optional type

  await dispatch(
    updateEntities({
      sections: sectionsEntities,
    })
  );

  return {
    data: {
      tree,
    },
  };
});

export const loadPPOUsers = createAsync<
  {
    appSpaceId: string;
    params: {
      limit?: number;
      offset?: number;
      ids?: string[];
      bioActive?: boolean;
    };
  },
  NormalizedEntities<'ppoUsers'> & Pager
>(
  'LOAD_PPO_USERS',
  async ({ appSpaceId, params }, dispatch, getState, { CoreApi }) => {
    const response = await CoreApi.organizationPPOUsers(appSpaceId, params);

    const ppoUsers = (response.users || []).map((user) =>
      reducePPOUserProps(user)
    );

    const normalizedEntities = normalizeEntities(
      'ppoUsers',
      ppoUsers,
      (u) => `${appSpaceId}-${u._id}`
    );

    dispatch(updateEntities(normalizedEntities.entities));

    return {
      ...normalizedEntities,
      total: response.total!,
      limit: response.limit!,
      offset: response.offset!,
      nextOffset: response.next_offset,
    };
  }
);

export const setScrollProgress = create<number | null>('SET_SCROLL_PROGRESS');

export const fetchCodeList = createAsync<
  {
    codeListID: string;
  },
  Codelist['codelist']
>('LOAD_CODE_LIST', async ({ codeListID }, dispatch, getState, { CoreApi }) => {
  const response = await CoreApi.getCodelist(codeListID);

  const entities = {
    codeLists: {
      [codeListID]: { items: response.codelist || [] },
    },
  };

  dispatch(updateEntities(entities));

  return response.codelist;
});

export const loadStaticContent = createAsync<
  { appSpace: string; cid: string },
  IStaticContent | null
>('LOAD_STATIC_TEXT', async (parameters, dispatch, getState, { CmsApi }) => {
  try {
    const response = (await CmsApi.getPublicStaticContentById(
      config.APP_ID,
      parameters.appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      parameters.cid,
      {
        expandWidgets: true,
      }
    )) as IStaticContent;

    const { entities } = normalizeEntities(
      'staticContents',
      [response],
      ({ cid }) => `${parameters.appSpace}:${cid}`
    );

    dispatch(updateEntities(entities));

    return response;
  } catch (e: any) {
    /* console.error(e); */
  }
  return null;
});

export const selectReduxValue = <T>(
  selector: (state) => T
): CustomThunkAction<T> => {
  return (_, getState) => {
    return selector(getState());
  };
};

type ReducedSportnetMenuSection = Pick<
  SectionNode,
  '_id' | 'name' | 'url' | 'redirecturl' | 'icon' | 'sections' | 'uniqid'
> & {
  settings: {
    [key: string]: string;
  };
};

const reduceMenusSectionSettings = (sectionSettings?: {
  [key: string]: string;
}) => {
  if (sectionSettings) {
    return Object.entries(sectionSettings).reduce((acc, [key, val]) => {
      if (key === 'SIDEBAR_STATIC_CONTENT_ID') {
        acc[key] = val;
      }

      return acc;
    }, {});
  }
  return {};
};

const reduceMenusSectionProps = (sections: SectionTreeType) => {
  return sections.map((section) => {
    const reducedSection: ReducedSportnetMenuSection = {
      _id: section._id,
      name: section.name,
      url: section.url,
      redirecturl: section.redirecturl,
      icon: section.icon,
      settings: reduceMenusSectionSettings(section.settings),
      uniqid: section.uniqid,
    };
    if (Array.isArray(section.sections) && section.sections.length > 0) {
      reducedSection.sections = reduceMenusSectionProps(section.sections);
    }
    return reducedSection;
  });
};

export const loadSportnetMenus = createAsync<
  { competitionPanelSectionId: string },
  any
>(
  'LOAD_SPORTNET_MENUS',
  async ({ competitionPanelSectionId }, dispatch, getState, { CmsApi }) => {
    const appSpace = CSMAppSpaceSelector(getState());

    const promises = [
      CmsApi.getPublicSubsectionsByIdOrUniqId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        'sportnet.sme.sk',
        { treelevel: 3 }
      ),
      CmsApi.getPublicSubsectionsByIdOrUniqId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        'sidemenu',
        { treelevel: 0 }
      ),
      CmsApi.getPublicSubsectionsByIdOrUniqId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        'vseobecne-informacie',
        { treelevel: 0 }
      ),
      CmsApi.getPublicSubsectionsByIdOrUniqId(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        'topbarmenu',
        { treelevel: 0 }
      ),
    ];

    if (competitionPanelSectionId) {
      promises.push(
        CmsApi.getPublicSectionByIdOrUniqId(
          config.APP_ID,
          appSpace,
          config.DEFAULT_CONTENT_DIVIDER,
          competitionPanelSectionId
        )
      );
      promises.push(
        CmsApi.getPublicSubsectionsByIdOrUniqId(
          config.APP_ID,
          appSpace,
          config.DEFAULT_CONTENT_DIVIDER,
          competitionPanelSectionId,
          { treelevel: 0 }
        )
      );
    }

    try {
      const [
        mainMenu,
        sideMenu,
        vseobecneInformacieMenus,
        topbarMenus,
        competitionPanelMainSection,
        competitionPanelSections,
      ] = await Promise.all(promises);
      return {
        data: {
          data: {
            'sportnet.sme.sk': reduceMenusSectionProps(
              mainMenu?.sections ?? []
            ),
            sidemenu: reduceMenusSectionProps(sideMenu?.sections ?? []),
            'vseobecne-informacie': reduceMenusSectionProps(
              vseobecneInformacieMenus?.sections ?? []
            ),
            topbarmenu: reduceMenusSectionProps(topbarMenus?.sections ?? []),
            'competition-panel': competitionPanelMainSection
              ? {
                  name:
                    (competitionPanelMainSection as SectionPublicDetail)
                      ?.name ?? '',
                  url:
                    (competitionPanelMainSection as SectionPublicDetail)?.url ??
                    '',
                  picture: (competitionPanelMainSection as SectionPublicDetail)
                    ?.picture,
                  icon: (competitionPanelMainSection as SectionPublicDetail)
                    ?.icon,
                  sections: reduceMenusSectionProps(
                    competitionPanelSections?.sections ?? []
                  ),
                }
              : null,
          },
        },
      };
    } catch (e) {
      console.error(e);
      // zatial skusme pri chybe nevyrenderovat menus
      return {
        data: {
          data: {
            'sportnet.sme.sk': [],
            sidemenu: [],
            'vseobecne-informacie': [],
            topbarmenu: [],
          },
        },
      };
    }
  }
);

export const loadPagesSettingsSocial = createAsync<
  {
    appSpace: string;
  },
  any
>(
  'LOAD_PAGES_SETTINGS_SOCIAL_USERS',
  async ({ appSpace }, dispatch, getState, { CoreApi }) => {
    try {
      const response = await PagesApi.publicGetSettingsSegment(
        appSpace,
        'social'
      );

      dispatch(
        updateEntities({
          pagesSettings: {
            [appSpace]: response,
          },
        })
      );

      return response;
    } catch (e) {
      return {};
    }
  }
);
