import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import CompetitionsApi from '../../../../api/CompetitionsApi';

interface LoadAllCompetitionByIdParams {
  appSpace: string;
  id: string | number;
}
const loadCompetitionById = createQueryFn(
  QueryNameSpace.competitionById,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<
    [string] | [string, LoadAllCompetitionByIdParams]
  >) => {
    const [, parameters] = queryKey;

    if (!parameters || !parameters.id || !parameters.appSpace) {
      throw new Error('Must provide required parameters [appSpace, id]!');
    }

    const result = await CompetitionsApi.getCompetitionById(
      parameters.appSpace,
      String(parameters.id),
    );

    return {
        _id: result._id,
        name: result.name,
        season: result.season,
    };
  },
);

export default loadCompetitionById;