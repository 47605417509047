import React from 'react';
import { rem } from 'polished';
import { TransfersByDate } from '../../library/Transfermarket';
import styled from '../../theme/styled-components';
import getDateString from '../../utilities/transfers/getDateString';
import Icon from '../../components/Icon';
import Transfer from './Transfer';
import { ContentContainer } from '../../components/v2/ContentContainer';
import TransferComponentLoader from './TransferLoader';
import { SKELETON_CLASS_NAME } from '../../components/v2/SkeletonLoader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

export const TransfersListContentContainer = styled(ContentContainer)`
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  }
  margin-bottom: ${rem(32)};
  ${mb('s')} {
    margin-bottom: ${rem(56)};
  }
`;

const DateListItem = styled.div`
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  font-weight: bold;
  padding: ${rem(24)};
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
`;

export const StyledTransfer = styled(Transfer)`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
`;

const LoadMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.primaryFont.family};
  font-size: ${rem(11)};
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: ${rem(0.88)};
  line-height: ${rem(16.8)};
  cursor: pointer;
  color: ${({ theme }) => theme.color.primaryFontColor};
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  padding: ${rem(24)} 0;
  > span {
    margin-left: ${rem(8)};
  }
`;

interface OwnProps {
  tansfersByDay: TransfersByDate;
  isFetchingNextPage?: boolean;
  onLoadMoreClick?: () => void;
}

const TransfersList: React.FC<OwnProps> = ({
  tansfersByDay,
  isFetchingNextPage,
  onLoadMoreClick,
}) => {
  return (
    <TransfersListContentContainer>
      {Object.entries(tansfersByDay).map(([date, transfers]) => (
        <React.Fragment key={date}>
          <DateListItem>{getDateString(new Date(date))}</DateListItem>
          {transfers.map((transfer, idx) => (
            <StyledTransfer
              key={`${transfer._id}-${idx}`}
              transfer={transfer}
            />
          ))}
        </React.Fragment>
      ))}
      {onLoadMoreClick &&
        (isFetchingNextPage ? (
          <>
            <TransferComponentLoader skeletonClassName={SKELETON_CLASS_NAME} />
            <TransferComponentLoader skeletonClassName={SKELETON_CLASS_NAME} />
            <TransferComponentLoader skeletonClassName={SKELETON_CLASS_NAME} />
          </>
        ) : (
          <LoadMoreButton onClick={onLoadMoreClick}>
            Zobraziť viac
            <Icon name="small-chevron" size={12} />
          </LoadMoreButton>
        ))}
    </TransfersListContentContainer>
  );
};

export default React.memo(TransfersList);
