import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../../library/App';
import { EntitiesState } from '../../../../pages/App/reducer';
import styled from '../../../../theme/styled-components';
import getArticleMainSection from '../../../../utilities/getArticleMainSection';
import Article from './Article';

const List = styled.div`
  list-style: none;
  display: grid;
  grid-gap: ${rem(32)};
  gap: ${rem(32)};
  margin: 0;
  padding: 0;
  ${mb('s')} {
    grid-row-gap: ${rem(32)};
    row-gap: ${rem(32)};
    grid-column-gap: ${rem(32)};
    column-gap: ${rem(32)};
    grid-template-columns: 1fr 1fr;
  }
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById?: EntitiesState['sections'];
  className?: string;
};

type IProps = IOwnProps;

const ArticlesGrid: React.FC<IProps> = ({
  articles,
  sectionsById = {},
  className,
}) => {
  function renderArticle(article: IArticle) {
    const section = getArticleMainSection(article, sectionsById);
    return (
      <Article
        key={`${article._id}-${article.modified}`}
        article={article}
        section={section}
      />
    );
  }

  return <List className={className}>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesGrid);
