import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { em, rem } from 'polished';
import React from 'react';
import UrlContext from '../../../contexts/UrlContext';
import { IArticle } from '../../../library/App';
import styled from '../../../theme/styled-components';
import Icon from '../../Icon';
import Link from '../../Link';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  display: grid;
  grid-gap: ${rem(24)};
  gap: ${rem(24)};
  grid-template-columns: 1fr;
  ${mb('s')} {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const ListItem = styled.li`
  display: flex;
  :first-child > a {
    font-weight: 600;
  }
`;

const ArticleName = styled.span``;

const ArticleLink = styled(Link)`
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.textColor};
  cursor: pointer;
  border: none;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  background: none;
  word-break: break-word;
  line-height: ${rem(16.8)};
  :hover,
  :active,
  :focus {
    ${ArticleName} {
      text-decoration: none;
      color: #1d81c4;
    }
  }
  ${mb('l')} {
    display: flex;
    align-items: center;
  }
`;

const SimpleNumber = styled.span`
  font-weight: 700;
  margin-right: ${rem(3)};
  ${mb('l')} {
    display: none;
  }
`;

const CircleNumber = styled.span`
  font-weight: 700;
  display: none;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: ${rem(16)};
  width: ${rem(32)};
  height: ${rem(32)};
  border-radius: 50%;
  font-size: ${rem(11)};
  border: 1px solid ${({ theme }) => theme.color.separator};
  ${mb('l')} {
    display: flex;
  }
`;

const SpacedIcon = styled(Icon)`
  margin-right: ${em(4)};
`;

type IOwnProps = {
  articles: IArticle[];
};

type IProps = IOwnProps;

const ArticlesListNumbered: React.FC<IProps> = ({ articles }) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  function renderArticle(article: IArticle, index: number) {
    return (
      <ListItem key={`${article._id}-${article.modified}`}>
        <ArticleLink to={getArticleUrl(article)}>
          <SimpleNumber>{index + 1}. </SimpleNumber>
          <CircleNumber>{index + 1}</CircleNumber>
          <ArticleName>
            {article.is_private && <SpacedIcon name="premium" size={12} />}
            {article.name}
          </ArticleName>
        </ArticleLink>
      </ListItem>
    );
  }

  return <List>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesListNumbered);
