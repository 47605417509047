import { IBaseObjectName } from '../../library/CompetitionStanding';
import { IResultsTableItem } from '../../library/Competitions';
import commonSorters from './commonSorters';

const sortResults = (
  results: IResultsTableItem[],
  baseObjectName: IBaseObjectName,
  miniTable?: boolean,
) => {
  const [currentSorter, ...sorters] = commonSorters;
  const teamIds = results.map((result) => result.team._id);
  const modifiedResults = results.map((result, idx) => {
    let sortPoints = result[baseObjectName]?.points || 0;
    let sortGoalsGiven = result[baseObjectName]?.goals?.given || 0;
    let sortGoalsReceived = result[baseObjectName]?.goals?.received || 0;

    if (miniTable) {
      sortPoints = 0;
      let matchesStatsBaseObjectName = '';

      if (baseObjectName === 'stats') {
        matchesStatsBaseObjectName = 'matches';
      } else if (baseObjectName === 'homeStats') {
        matchesStatsBaseObjectName = 'awayMatches';
      } else if (baseObjectName === 'awayStats') {
        matchesStatsBaseObjectName = 'homeMatches';
      }

      if (matchesStatsBaseObjectName) {
        result[matchesStatsBaseObjectName]
          .filter((match) => teamIds.includes(match.team._id))
          .forEach((match) => {
            if (match.result === 'W') {
              sortPoints += 3;
            } else if (match.result === 'D') {
              sortPoints += 1;
            } else if (match.result === 'L') {
              // no points awarded for loss
            }
          });
      }
    }

    return {
      ...result,
      [baseObjectName]: {
        ...result[baseObjectName],
        sortPoints,
        sortGoalsGiven,
        sortGoalsReceived,
      },
    };
  });

  return modifiedResults.sort((a, b) =>
    currentSorter(a, b, sorters, baseObjectName),
  );
};

export default sortResults;
