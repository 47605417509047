import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
   <path fill={color} d="M6,12.3c-0.3,0-0.5-0.1-0.7-0.3L2,8.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L6,9.9l6.6-6.6c0.4-0.4,1-0.4,1.4,0
	s0.4,1,0,1.4L6.7,12C6.5,12.2,6.3,12.3,6,12.3z"/>
  </svg>
);