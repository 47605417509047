import React from 'react';
import useAdScriber from '../../../hooks/useAdScriber';

const AdHeader: React.FC<{}> = () => {
  const name = '_header';

  useAdScriber(name, 0);

  return null;
};

export default React.memo(AdHeader);
