import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { SPORT_SECTOR_TELEVISION_BROADCASTERS } from '../../codelists/sport_sector_television_broadcasters';

export const getMatchTelevisionBroadcasters = (
  match?:
    | (IWidgetMatchesListMatchItem &
        {
          television_broadcasters?: {
            _id: string;
            label: string;
            logo_url: string;
          }[];
        })
    | null,
) => {
  if (!match) {
    return [];
  }
  const sportSector = match.rules.sport_sector;
  const sectorBroadcasters = SPORT_SECTOR_TELEVISION_BROADCASTERS.find(
    (item) => item._id === sportSector,
  );
  if (sectorBroadcasters && Array.isArray(match.television_broadcasters)) {
    const ids = match.television_broadcasters.map((tb) => tb._id);
    return sectorBroadcasters.items.filter((tb) => ids.includes(tb._id));
  }
  return [];
};
