import CoreApi from '../api/CoreApi';
import { AsyncReturnType } from '../library/App';

/**
 * Vrati cele meno usra, ako string.
 */
const getPPOUserFullName = (
  user: AsyncReturnType<typeof CoreApi['organizationPPOUser']>,
): string => {
  return `${user.titles?.before || ''} ${user.name} ${user.surname} ${
    user.titles?.after || ''
  }`.trim();
};

export default getPPOUserFullName;
