import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    id="push_pin_24dp"
    data-name="push pin 24dp"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M16,9v-5h2v-2H6v2h2v5c0,1.6568546-1.3431458,3-3,3h0v2h5.9699993v7l1,1,1-1v-7h6.0300007v-2h0c-1.6568546,0-3-1.3431454-3-3Z"
    />
  </svg>
);
