import slugify from 'slugify';
import { ITeam } from '../../library/Competitions';
import { StatsTeam } from '../../library/StatsTable';

const getTeamUrlSlug = (team: ITeam | StatsTeam ) => {
    const slug = slugify(team.displayName || team.name, { lower: true })
    if (slug === 'roc-(rusko)') {
        return 'roc'
    }
    return slug;
};

export default getTeamUrlSlug;
