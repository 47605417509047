import isBefore from 'date-fns/isBefore';
import { IMatch } from '../../library/Competitions';

const isFutureMatch = (match: IMatch) =>
  Boolean(
    !match.closed &&
      (!match.startDate ||
        (match.startDate && isBefore(new Date(), new Date(match.startDate)))) &&
      !match.liveState,
  );

export default isFutureMatch;
