import { mb } from '@sportnet/ui/lib/Themes/utilities';
// import { linearGradient, rem, transparentize } from 'polished';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import Link from '../../components/Link';
import { RootState } from '../../configureStore';
import useClientVariable from '../../hooks/useClientVariable';
import { IArticle } from '../../library/App';
import { appSettingsSelector } from '../../pages/DomainResolver/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { getCallbackPredplatneUrl } from '../Authorization';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: ${rem(48)} auto;
  ${mb('s')} {
    margin: ${rem(56)} auto;
  }

  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};
  word-break: break-word;
`;

// toto sa uz nepouziva, kvoli PetitPress je to zafixovane ako globalny className=paywall-shadow
// const Shadow = styled.div`
//   position: absolute;
//   ${({ theme }) =>
//     linearGradient({
//       colorStops: [
//         `${transparentize(1, theme.app.bgColor)} 0%`,
//         `${theme.app.bgColor} 100%`,
//       ],
//     })}
//   height: ${rem(80)};
//   left: 0;
//   right: 0;
//   bottom: calc(100% + ${rem(48)});
//   ${mb('s')} {
//     bottom: calc(100% + ${rem(56)});
//   }
// `;

const Title = styled.h2`
  font-family: pt-serif-pro, serif;
  font-weight: 600;
  margin: ${rem(12)} 0 0 0;
  padding: 0;
  font-size: ${rem(32)};
  line-height: ${rem(38.4)};
`;

const SubTitle = styled.p`
  margin: 0 0 0 0;
  padding: 0;
`;

const LogInText = styled.span`
  margin: ${rem(24)} 0 0 0;
  font-weight: 600;
`;

const LogInLink = styled(Link)`
  text-decoration: underline;
  color: ${({ theme }) => theme.color.primary};
  font: inherit;
  cursor: pointer;
`;

const BuyPremiumLink = styled(Link)`
  margin: ${rem(32)} 0;
  button {
    background: #37a000;
    color: #fff;
    text-transform: uppercase;
  }
`;

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

interface IOwnProps {
  showShadow?: boolean;
  article?: IArticle;
}

type IProps = IMapStateToProps & IOwnProps;

const PremiumContentMessage: React.FC<IProps> = ({ showShadow, article }) => {
  const authUrl = useClientVariable(getCallbackPredplatneUrl(), '#');

  const smsExternalId = article
    ? article.externalIds?.find(({ service }) => service === 'smsId')
    : null;

  return (
    <Wrapper
      className="paywall"
      {...(smsExternalId && smsExternalId.externalId
        ? { 'data-sms-code': smsExternalId.externalId }
        : {})}
    >
      {showShadow && <div className="paywall-shadow" />}
      <Icon name="premium" size={24} />
      <Title>
        {__('Dočítajte tento článok')}
        <br />
        {__('s predplatným sportnet.sk')}
      </Title>
      <BuyPremiumLink to={authUrl}>
        <Button size="m" success block>
          {__('Kúpiť predplatné')}
        </Button>
      </BuyPremiumLink>
      <SubTitle>{__('Predplatné si môžete kedykoľvek zrušiť.')}</SubTitle>
      <LogInText>
        {__('Máte už konto alebo predplatné?')}
        {` `}
        <LogInLink to={authUrl}>{__('Prihláste sa')}</LogInLink>
      </LogInText>
    </Wrapper>
  );
};

export default connect(mapStateToProps)(PremiumContentMessage);
