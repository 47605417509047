import { InfiniteData } from '@tanstack/react-query';

/**
 * Pouziva sa napriklad pre ziskanie vsetkych clankov zo vsetkych podstranok pri
 * strankovanom nacitavani.
 * 
 * @param data Data, ktore vrati `useInfiniteQuery`
 * @param select Funkcia, ktora zo stranky vyberie field s vysledkami (napr. `page.articles`)
 * @returns pole vsetkych prvkov zo vsetkych nacitanych podstranok
 */
const getAllItemsFromInfiniteQueryPages = <PageType, PageItemType>(
  data: InfiniteData<PageType>,
  select: (page: PageType) => Array<PageItemType>,
) => {
  return (data.pages || []).reduce((acc, page) => {
    acc.push(...select(page));
    return acc;
  }, [] as Array<PageItemType>);
};

export default getAllItemsFromInfiniteQueryPages;
