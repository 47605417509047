import { IWidgetAdvert } from '@sportnet/content/utilities/library';
import React from 'react';
import useIsResponsiveLayout from '../../../../hooks/useIsResponsiveLayout';
import Ad from '../../../Ads/Ad';

type IData = Omit<IWidgetAdvert, 'type'>;

interface IOwnProps {
  data?: IData;
  renderEmpty?: (type: string) => React.ReactNode;
}

type IProps = IOwnProps;

const Advert: React.FC<IProps> = ({ data: { screen, slot } = {} }) => {
  const mobileLayout = useIsResponsiveLayout();

  // ensure component is mounted and layout is resolved before rendering
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  if (!slot) {
    return null;
  }

  if (screen === 'mobile' && !mobileLayout) {
    return null;
  }

  if (screen === 'desktop' && mobileLayout) {
    return null;
  }

  return <Ad name={slot} />;

  // const { layout } = React.useContext(WidgetsContext);

  // if (layout === 'wideform' && slot !== 'article_body_top_position') {
  //   return (
  //       <AbsolutePositioner>
  //         <MaxWidthBox width={1000} padding={0}>
  //           <Ad name={slot} />
  //         </MaxWidthBox>
  //       </AbsolutePositioner>
  //   );
  // }
};

export default React.memo(Advert);
