import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import { ContextMarginTopStyles } from '../../Layout';
import Ad from '../Ad';
import { IAdPosition } from '../AdProvider';

const StyledAd = styled(Ad)`
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  margin: 0 auto;
  width: 100%;
  ${ContextMarginTopStyles}
  display: none;
  ${mb('s')} {
    display: block;
  };
`;

type IOwnProps = {
  name: IAdPosition;
  className?: string;
};

type IProps = IOwnProps;

const BigAd: React.FC<IProps> = ({ name, className }) => {
  return <StyledAd name={name} className={className} />;
};

export default React.memo(BigAd);
