import { isValid } from 'date-fns';
import parse from 'date-fns/parse';
import { createSelector } from 'reselect';
import { HashParams } from '../../library/Transfermarket';
import { deserializeHashParams } from '../../utilities/tvProgram';


export const getHashDefaultsSelector = createSelector(() => ({ ppoDir: 'both', transferType: 'all' }) as HashParams);

export const getDeserializedHashParamsSelector = createSelector(
  (hash: string) => hash,
  getHashDefaultsSelector,
  (hash, defaults) => {
    return deserializeHashParams<HashParams>(hash, defaults);
  },
);

export const getActiveFilterSelector = createSelector(
  (hash: string) => hash,
  getDeserializedHashParamsSelector,
  (_, activeFilter: HashParams) => {
    const { dateFrom: dateFromStr, dateTo: dateToStr } = activeFilter;
    const dateFrom = dateFromStr ? parse(dateFromStr, 'yyyy-MM-dd', new Date()) : undefined;
    const dateTo = dateToStr ? parse(dateToStr, 'yyyy-MM-dd', new Date()) : undefined;
    return {
      ...activeFilter,
      dateFrom: dateFrom && isValid(dateFrom) ? dateFrom : undefined,
      dateTo: dateTo && isValid(dateTo) ? dateTo : undefined,
    }
  },
);
