import { NormalizedEntities } from '../library/App';
import { EntitiesState } from '../pages/App/reducer';

export default function <
  T extends keyof EntitiesState,
  E extends EntitiesState[T][''],
>(
  type: T,
  arrayOfEntities: E[],
  getIdAttribute: (entity: E) => string = (entity: any) => entity._id,
): NormalizedEntities<T> {
  return {
    entities: {
      [type]: arrayOfEntities.reduce((acc, item) => {
        acc[getIdAttribute(item)] = item;
        return acc;
      }, {} as any),
    },
    results: arrayOfEntities.map(getIdAttribute),
    original: arrayOfEntities,
  };
}
