import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import {
  getListResultsByParams,
  isCommittingByParams,
} from '@sportnet/redux-list/ducks';
import AnimatedPanes from '../../components/AnimatedPanes';
import AnimatedPanesWrapper from '../../components/AnimatedPanes/AnimatedPanesWrapper';
import Loading from '../../components/Loading';
import config from '../../config';
import { RootState } from '../../configureStore';
import useDropdownHeight from '../../hooks/useDropdownHeight';
import {
  unionsIsFetchingSelector,
  unionsSelector,
} from '../../pages/FutbalnetApp/selectors';
import { listCompetitionsCategoryPanesByParamsSelector } from './selectors';

type IOwnProps = {
  active: boolean;
  seasonName: string;
  setActiveSeasonName: (seasonName: string) => void;
};

const mapStateToProps = (state: RootState, { seasonName }: IOwnProps) => {
  const listIdentifier = {
    listName: config.LIST_COMPETITIONS,
    parameters: { seasonName },
  };

  const isLoadingCompetitions =
    isCommittingByParams(state, listIdentifier) !== false &&
    (getListResultsByParams(state, listIdentifier) || []).length === 0;

  const isLoadingUnions =
    unionsIsFetchingSelector(state) !== false &&
    unionsSelector(state).length === 0;

  return {
    panes: (listCompetitionsCategoryPanesByParamsSelector as any)(state, listIdentifier),
    isLoading: isLoadingCompetitions || isLoadingUnions,
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const Content: React.FC<IProps> = ({
  active,
  seasonName,
  setActiveSeasonName,
  panes,
  isLoading,
}) => {
  const { height, getHeight } = useDropdownHeight(active);

  const handleBack = React.useCallback(() => {
    setActiveSeasonName('');
  }, [setActiveSeasonName]);

  return (
    <AnimatedPanesWrapper style={{ height }}>
      {isLoading ? (
        <Loading minHeight={rem(212)} />
      ) : (
        <AnimatedPanes
          panes={panes}
          header={seasonName}
          onBack={handleBack}
          getHeight={getHeight}
          active={active}
          unmount
        />
      )}
    </AnimatedPanesWrapper>
  );
};

export default React.memo(connect(mapStateToProps)(Content));
