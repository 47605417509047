import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../configureStore';
import {
  authUserIsSubscribedSelector,
  authUserResolvedSelector,
  authUserSelector,
} from '../../pages/App/selectors';
import isBrowser from '../../utilities/isBrowser';

type IOwnProps = {
  article?: any;
  destroy?: Boolean;
};

const mapStateToProps = (state: RootState) => {
  return {
    authUser: authUserSelector(state),
    authUserResolved: authUserResolvedSelector(state),
    authUserSubscribed: authUserIsSubscribedSelector(state),
  };
};

const RempIntegration: React.FC<
  IOwnProps & ReturnType<typeof mapStateToProps>
> = ({
  article,
  authUser,
  authUserResolved,
  authUserSubscribed,
  destroy = false,
}) => {
  // tieto individualne premenne zamedzia znovu vykonaniu useMemo a useEffect, pretoze
  // sa niektore stranky renderuju kvoli zmene referencie na clanok 2x.
  const isArticle = !!article;

  const artemisId = (article?.externalIds || []).find(
    ({ service }: { service: string }) => service === 'artemisId',
  );

  const externalId = artemisId?.externalId || null;

  const authorsStr = (article?.authors || [])
    .map(({ name }: { name: string }) => name)
    .join(', ');

  const is_private = !!article?.is_private;

  const externalProfileId = (authUser as any)?.externalProfileId || null;

  // data pre inicialuzaciu REMP-u
  const rempData = React.useMemo(() => {
    const user = externalProfileId
      ? {
          userId: externalProfileId,
          userSubscribed: authUserSubscribed,
        }
      : null;
    if (!isArticle) {
      return { article: null, user };
    }
    const article = externalId
      ? {
          id: externalId,
          author_id: authorsStr,
          category: 'SportNet',
          locked: !!is_private,
          tags: [],
        }
      : null;
    return {
      article,
      user,
    };
  }, [
    externalId,
    authorsStr,
    is_private,
    isArticle,
    externalProfileId,
    authUserSubscribed,
  ]);

  // Zaradenie funkcii na vykonanie do REMP workera podla toho ci sa ma
  // vykonat `init` alebo `destroy`
  React.useEffect(() => {
    if (authUserResolved && isBrowser() && (window as any).rempWorker) {
      try {
        if (destroy) {
          (window as any).rempWorker.cmd.push(function () {
            (window as any).rempWorker.destroy();
          });
        } else {
          (window as any).rempWorker.cmd.push(function () {
            (window as any).rempWorker.init(rempData);
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [destroy, rempData, authUserResolved]);

  return null;
};

export default React.memo(connect(mapStateToProps)(RempIntegration));
