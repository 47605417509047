import { OrganizationProfileStructureItem } from '../api/CoreApi';

const traverseTreeBFS = (
  tree: OrganizationProfileStructureItem[],
  callback: (union: OrganizationProfileStructureItem) => boolean,
) => {
  const queue = [...tree];
  let element: OrganizationProfileStructureItem | undefined;
  while (queue.length > 0) {
    element = queue.shift();

    if (!element) {
      continue;
    }

    if (callback(element)) {
      return element;
    }

    (element.subs || []).forEach((s) => {
      queue.push(s);
    });
  }
  return null;
};

export default (unionId: string, tree: OrganizationProfileStructureItem[]) => {
  return traverseTreeBFS(tree, (union) => {
    return union._id === unionId;
  });
};
