import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getFeedItemDetailUrl } from '../../pages/Gossips/utils';
import { FeedItemInCache } from '../../pages/Gossips/detail/features';
import UrlContext from '../../contexts/UrlContext';
import getBaseUrl from '../../utilities/getBaseUrl';

type OwnProps = {
  gossip: FeedItemInCache;
};

type Props = OwnProps;

const SDGossipNewsArticle: React.FC<Props> = ({ gossip }) => {
  const { getAuthorUrl } = React.useContext(UrlContext);
  const author =
    Array.isArray(gossip.authors) && gossip.authors.length > 0
      ? gossip.authors[0]
      : undefined;
  const authorName = author?.displayName ?? '';
  const isAuthorOrganisation = authorName.split(' ').length > 1 ? false : true;
  const authorUrl = author?.sportnetId
    ? `${getBaseUrl()}${getAuthorUrl({
        name: author.displayName,
        sportnetId: author.sportnetId,
      })}`
    : '';
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(
          {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': getFeedItemDetailUrl(gossip._id, gossip.title),
            },
            headline: gossip.title,
            description: gossip.extendedDescriptionExcerpt ?? '',
            ...(gossip.ogImageUrl ? { image: gossip.ogImageUrl } : {}),
            ...(authorName
              ? {
                  author: {
                    '@type': isAuthorOrganisation ? 'Organization' : 'Person',
                    name: authorName,
                    ...(authorUrl ? { url: authorUrl } : {}),
                  },
                }
              : {}),
            publisher: {
              '@type': 'Organization',
              name: 'Sportnet.sk',
              logo: {
                '@type': 'ImageObject',
                url: 'https://sportnet.sme.sk/sportnet-publisher-logo.png',
              },
            },
            datePublished: new Date(gossip.date).toISOString(),
          },
          null,
          2
        )}
      </script>
    </Helmet>
  );
};

export default React.memo(SDGossipNewsArticle);
