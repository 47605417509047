import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import { Article_Author } from '../../../api/CmsApi';
import config from '../../../config';
import UrlContext from '../../../contexts/UrlContext';
import styled from '../../../theme/styled-components';
import formatDate from '../../../utilities/formatDate';
import __ from '../../../utilities/__';
import Icon from '../../Icon';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-8)};
  > * {
    margin: ${rem(8)};
  }
`;

const TextWrapper = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.app.textColor};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  word-break: break-word;
  ${mb('s')} {
    line-height: ${rem(16.8)};
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Author = styled.span`
  font-weight: 700;
`;

const Pipe = styled.span`
  margin: 0 ${rem(8)};
`;

const PremiumTag = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: ${({ theme }) => theme.app.textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
  font-weight: 700;
  font-size: ${rem(13)};
  border-radius: ${rem(4)};
  min-height: ${rem(40)};
  padding: ${rem(10)} ${rem(16)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  box-shadow: 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.12);
`;

interface IOwnProps {
  createAuthorsLink: boolean;
  authors: Article_Author[];
  createdDate: Date | null;
  updatedDate?: Date | null;
  modifiedDate?: Date | null;
  isPrivate: boolean;
}

type IProps = IOwnProps;

const ArticleMetaInformation: React.FC<IProps> = ({
  createAuthorsLink,
  authors,
  createdDate,
  modifiedDate,
  isPrivate,
  updatedDate = null,
}) => {
  // const articleDateString = modifiedDate
  //   ? `${__('Aktualizované')} ${formatDate(
  //       modifiedDate,
  //       config.MODIFIED_ARTICLE_DATE_TIME_FORMAT,
  //     )}`
  //   : `${__('Publikované')} ${formatDate(
  //       createdDate,
  //       config.MODIFIED_ARTICLE_DATE_TIME_FORMAT,
  //     )}`;

  const articleDateString = createdDate
    ? formatDate(createdDate, config.MODIFIED_ARTICLE_DATE_TIME_FORMAT)
    : '';

  const articleUpdateString = updatedDate
    ? `(${__('aktualizované')} ${formatDate(
        updatedDate,
        config.MODIFIED_ARTICLE_DATE_TIME_FORMAT,
      )})`
    : '';

  const { getAuthorUrl } = React.useContext(UrlContext);

  return (
    <Wrapper>
      {isPrivate && (
        <PremiumTag>
          <Icon name="premium" size={12} />
        </PremiumTag>
      )}
      <TextWrapper>
        {authors.length > 0 && (
          <>
            <Author>
              {authors.map((author, i, all) => {
                const components: any[] = [];
                if (author.sportnetId && createAuthorsLink) {
                  components.push(
                    <Link to={getAuthorUrl(author)} key={`author-${i}`}>
                      {author.name}
                    </Link>,
                  );
                } else {
                  components.push(
                    <span key={`author-${i}`}>{author.name}</span>,
                  );
                }
                if (i + 1 < all.length) {
                  components.push(<span key={`ciarka-${i}`}>, </span>);
                }
                return components;
              })}
            </Author>
            <Pipe>|</Pipe>
          </>
        )}
        {`${articleDateString} ${articleUpdateString}`}
      </TextWrapper>
    </Wrapper>
  );
};

export default React.memo(ArticleMetaInformation);
