import React from 'react';

/**
 * Use for variables that differ from those available on server to prevent unexpected behavior due to client/server HTML mismatch
 * @param variable client variable
 * @param defaultValue default variable (will be used on server)
 */
function useClientVariable<V>(variable: V, defaultValue: V) {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? variable : defaultValue;
}

export default useClientVariable;
