/**
 * True/False podla toho, ci zvaz obsahuje muzske sutaze.
 *
 */
import { getProp } from 'sportnet-utilities';
import { UnionItem } from '../library/Union';

const hasMaleCompetitions = (union: UnionItem) =>
  union &&
  (union.competitions || []).find(
    (i) =>
      i.parts &&
      i.parts.length &&
      getProp(i, ['parts', 0, 'rules', 'gender']) === 'M' &&
      getProp(i, ['parts', 0, 'rules', 'category']) === 'ADULTS',
  );

export default hasMaleCompetitions;
