// import { mb } from '@sportnet/ui/lib/Themes/utilities';
// import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { Article_Author } from '../../api/CmsApi';
// import styled from '../../theme/styled-components';
import classifyArticleAuthors from '../../utilities/classifyArticleAuthors';
import Author from '../Author';
// import __ from '../../utilities/__';

// const OtherAuthorsWrapper = styled.div`
//   margin-bottom: ${rem(48)};
//   ${mb('s')} {
//     margin-bottom: ${rem(56)};
//   }
// `;

type IOwnProps = {
  appSpaceId: string;
  authors: Article_Author[];
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

type IProps = IOwnProps & typeof mapDispatchToProps;

const Authors: React.FC<IProps> = ({ appSpaceId, authors }) => {
  const { sportnetAuthors } = classifyArticleAuthors(authors);

  // Zobrazujeme len prave jedneho autora
  // https://gitlab.bart.sk/sportnet/csm/sportnet.sme.sk/-/issues/129#note_175145
  if (authors.length === 1 && sportnetAuthors.length === 1) {
    return (
      <>
        {sportnetAuthors.map((sportnetAuthor) => (
          <Author
            key={`${appSpaceId}-${sportnetAuthor.sportnetId}`}
            appSpaceId={appSpaceId}
            userId={sportnetAuthor.sportnetId!}
            overwrite={{ position: '' }}
          />
        ))}
      </>
    );
  }

  return null;

  // const { otherAuthors } = classifyArticleAuthors(authors);
  // const getLabel = () => {
  //   return sportnetAuthors.length > 0 ? __('Ďalší autori') : __('Autor');
  // };
  // return (
  //   <>
  //     {sportnetAuthors.map((sportnetAuthor) => (
  //       <Author
  //         key={`${appSpaceId}-${sportnetAuthor.sportnetId}`}
  //         appSpaceId={appSpaceId}
  //         userId={sportnetAuthor.sportnetId!}
  //       />
  //     ))}
  //     {otherAuthors.length > 0 && (
  //       <OtherAuthorsWrapper>
  //         {getLabel()}: {otherAuthors.map((author) => author.name).join(', ')}
  //       </OtherAuthorsWrapper>
  //     )}
  //   </>
  // );
};

export default connect(mapStateToProps, mapDispatchToProps)(Authors);
