import React from 'react';
import account from './components/account';
import addCircleOutline from './components/add-circle-outline';
import addEuro from './components/add-euro';
import apps from './components/apps';
import arrowMore from './components/arrowMore';
import arrowWinner from './components/arrowWinner';
import avatar from './components/avatar';
import back from './components/back';
import businessCard from './components/business-card';
import calendarMatch from './components/calendar-match';
import card from './components/card';
import chevron from './components/chevron';
import chevronTable from './components/chevron-table';
import circle from './components/circle';
import contactPerson from './components/contact-person';
import crop from './components/crop';
import doxxbet from './components/doxxbet';
import editDocument from './components/edit-document';
import email from './components/email';
import euro from './components/euro';
import exportIcon from './components/export';
import facebook from './components/facebook';
import facebookFutbalnet from './components/facebook';
import file from './components/file';
import files from './components/files';
import folder from './components/folder';
import futbal from './components/futbal';
import futbalnet from './components/futbalnet';
import futbalnetIcon from './components/futbalnet-icon';
import futbalnetSymbol from './components/futbalnetSymbol';
import gallery from './components/gallery';
import podcast from './components/podcast';
import goal from './components/goal';
import googleAnalytics from './components/google-analytics';
import googleTagManager from './components/google-tag-manager';
import group from './components/group';
import hash from './components/hash';
import horizontalTree from './components/horizontal-tree';
import image from './components/image';
import importIcon from './components/import';
import instagram from './components/instagram';
import link from './components/link';
import linkedin from './components/linkedin';
import livestream from './components/livestream';
import logoPlaceholder from './components/logoPlaceholder';
import mail from './components/mail';
import membership from './components/membership';
import message from './components/message';
import monitor from './components/monitor';
import more from './components/more';
import moreDots from './components/moreDots';
import news from './components/news';
import notification from './components/notification';
import order from './components/order';
import organization from './components/organization';
import phone from './components/phone';
import pinterest from './components/pinterest';
import premium from './components/premium';
import premiumShape from './components/premium-shape';
import print from './components/print';
import product from './components/product';
import question from './components/question';
import redCard from './components/redCard';
import referee from './components/referee';
import search from './components/search';
import settings from './components/settings';
import share from './components/share';
import smallChevron from './components/small-chevron';
import sme from './components/sme';
import sportnetIcon from './components/sportnet-icon';
import stadium from './components/stadium';
import star from './components/star';
import suitcase from './components/suitcase';
import testIcon from './components/test';
import time from './components/time';
import tooltip from './components/tooltip';
import trash from './components/trash';
import tumblr from './components/tumblr';
import twitter from './components/twitter';
import verticalTree from './components/vertical-tree';
import video from './components/video';
import writeIcon from './components/write';
import yellowCard from './components/yellowCard';
import youtube from './components/youtube';
import DoxxbetShort from './components/xx';
import refresh from './components/refresh';
import chevronLeft from './components/chevron-left';
import chevronRight from './components/chevron-right';
import calendarDoxx from './components/calendar';
import stats from './components/stats';
import checkBig from './components/check-big';
import tag from './components/tag';
import filter from './components/filter';
import redChevronDown from './components/red-chevron-down';
import greenChevronUp from './components/green-chevron-up';
import noResults from './components/no-results';
import redArrowLeft from './components/red-arrow-left';
import greenArrowRight from './components/green-arrow-right';
import soccerBall from './components/soccer-ball';
import run from './components/run';
import eventSeat from './components/event-seat';
import groups from './components/groups';
import whistle from './components/whistle';
import stadiumPrint from './components/stadium-print';
import print2 from './components/print2';
import nike from './components/nike';
import lockNike from './components/lock-nike';
import pin2 from './components/pin-2';

export default {
  return: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
      />
    </svg>
  ),
  backspace: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 12.59L17.59 17 14 13.41 10.41 17 9 15.59 12.59 12 9 8.41 10.41 7 14 10.59 17.59 7 19 8.41 15.41 12 19 15.59z"
      />
    </svg>
  ),
  lock: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
      />
    </svg>
  ),
  'lock-open': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"
      />
    </svg>
  ),
  'star-full': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'triangle-up-down': (size: string, color: string) => (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path id="svg_1" d="m5,9.5l4,4l4,-4l-8,0z" fill={color} />
      <path id="svg_2" d="m9,4l-4,4l8,0l-4,-4z" fill={color} />
    </svg>
  ),
  'triangle-down': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
    >
      <path fill={color} d="M5 8l4 4 4-4z" />
    </svg>
  ),
  'triangle-up': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
    >
      <path fill={color} d="M9 6l-4 4h8z" />
    </svg>
  ),
  'arrow-right': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
    >
      <path
        fill={color}
        d="M4.5,9.8C4.3,9.8,4.1,9.7,4,9.5C3.7,9.2,3.7,8.8,4,8.5L6.4,6L4,3.5C3.7,3.2,3.7,2.8,4,2.5s0.8-0.3,1.1,0l3,3
	c0.3,0.3,0.3,0.8,0,1.1l-3,3C4.9,9.7,4.7,9.8,4.5,9.8z"
      />
    </svg>
  ),
  'arrow-left': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
    >
      <path
        fill={color}
        transform="rotate(180, 6, 6)"
        d="M4.5,9.8C4.3,9.8,4.1,9.7,4,9.5C3.7,9.2,3.7,8.8,4,8.5L6.4,6L4,3.5C3.7,3.2,3.7,2.8,4,2.5s0.8-0.3,1.1,0l3,3
	c0.3,0.3,0.3,0.8,0,1.1l-3,3C4.9,9.7,4.7,9.8,4.5,9.8z"
      />
    </svg>
  ),
  'arrow-right-to-end': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
  ),
  'arrow-left-to-end': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"
      />
      <path fill="none" d="M24 24H0V0h24v24z" />
    </svg>
  ),
  error: (size: string, color: string) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="null"
        id="svg_16"
        fill="none"
        d="m-39.370318,-39.370318l478.740686,0l0,478.740686l-478.740686,0l0,-478.740686z"
      />
      <path
        stroke="null"
        id="svg_17"
        fill={color}
        d="m200.000025,0.524739c-110.110358,0 -199.475286,89.364928 -199.475286,199.475286s89.364928,199.475286 199.475286,199.475286s199.475286,-89.364928 199.475286,-199.475286s-89.364928,-199.475286 -199.475286,-199.475286zm19.947529,299.212929l-39.895057,0l0,-39.895057l39.895057,0l0,39.895057zm0,-79.790114l-39.895057,0l0,-119.685172l39.895057,0l0,119.685172z"
      />
    </svg>
  ),
  'check-upload': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M12 24C5.372 24 0 18.627 0 12 0 5.372 5.372 0 12 0c6.627 0 12 5.372 12 12 0 6.627-5.373 12-12 12zm0-22C6.477 2 2 6.477 2 12c0 5.522 4.477 9.999 10 9.999 5.522 0 9.999-4.477 9.999-9.999 0-5.523-4.477-10-9.999-10zm-1.758 14.99L6.01 12.833l1.411-1.385 2.821 2.771 6.347-6.234L18 9.37l-7.758 7.62z"
      />
    </svg>
  ),
  'check-circle': (size: string, color: string) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="null"
        id="svg_4"
        fill="none"
        d="m-39.370326,-39.370336l478.740709,0l0,478.740709l-478.740709,0l0,-478.740709z"
      />
      <path
        stroke="null"
        id="svg_5"
        fill={color}
        d="m200.000029,0.524723c-110.110363,0 -199.475295,89.364932 -199.475295,199.475295s89.364932,199.475295 199.475295,199.475295s199.475295,-89.364932 199.475295,-199.475295s-89.364932,-199.475295 -199.475295,-199.475295zm-39.895059,299.212943l-99.737648,-99.737648l28.126017,-28.126017l71.611631,71.412156l151.401749,-151.401749l28.126017,28.325492l-179.527766,179.527766z"
      />
    </svg>
  ),
  check: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
      />
    </svg>
  ),
  close: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'file-download': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 113 119"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M112.515 83.748a2.995 2.995 0 0 0-2.725-1.757H98.813l.024-28.01a3 3 0 0 0-2.995-3.002H85.835V30.997c0-.021-.006-.04-.006-.06a3.088 3.088 0 0 0-.053-.534c-.005-.023-.015-.045-.02-.069a3.167 3.167 0 0 0-.134-.435 3.212 3.212 0 0 0-.071-.159 2.854 2.854 0 0 0-.176-.326c-.028-.046-.055-.092-.086-.137a2.988 2.988 0 0 0-.323-.392l-.01-.011L57.012.88l-.011-.009a2.948 2.948 0 0 0-.393-.325c-.043-.03-.088-.056-.133-.084a2.93 2.93 0 0 0-.33-.179c-.051-.024-.102-.048-.155-.069a3.04 3.04 0 0 0-.444-.138c-.02-.004-.039-.012-.059-.016a3.031 3.031 0 0 0-.539-.055c-.019 0-.036-.005-.055-.005H2.992A2.996 2.996 0 0 0-.002 2.999v95.99c0 1.657 1.34 3 2.994 3h63.63v-.084l13.96 16.065a2.993 2.993 0 0 0 4.517 0l26.949-31.01a3.002 3.002 0 0 0 .467-3.212zM75.612 27.997H57.887V10.241l17.725 17.756zM5.986 95.99V5.999h45.913v24.998a2.997 2.997 0 0 0 2.994 2.999h24.953v16.983H69.839a3 3 0 0 0-2.995 3.002l.025 28.01H55.892a3.002 3.002 0 0 0-2.259 4.969l7.847 9.03H5.986zm76.855 15.442L62.469 87.991h7.396a3.003 3.003 0 0 0 2.995-3.003l-.025-28.01h20.011l-.024 28.01a2.998 2.998 0 0 0 2.994 3.003h7.396l-20.371 23.441z"
      />
    </svg>
  ),
  pencil: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  plus: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'power-off': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 24"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M11 23.997c-6.065 0-11-4.998-11-11.141C0 8.66 2.297 4.861 5.994 2.942l.92 1.779c-3.03 1.574-4.913 4.69-4.913 8.135 0 5.038 4.037 9.138 8.999 9.138 4.962 0 9-4.1 9-9.138 0-3.445-1.884-6.561-4.915-8.135l.92-1.779C19.703 4.861 22 8.66 22 12.856c0 6.143-4.934 11.141-11 11.141zM10 0h2v12h-2V0z"
      />
    </svg>
  ),
  'remove-circle': (size: string, color: string) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 400 400"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="null"
        id="svg_4"
        fill="none"
        d="m-39.370313,-39.370311l478.740652,0l0,478.740652l-478.740652,0l0,-478.740652z"
      />
      <path
        stroke="null"
        fill={color}
        id="svg_5"
        d="m200.000013,0.524744c-110.11035,0 -199.475272,89.364922 -199.475272,199.475272s89.364922,199.475272 199.475272,199.475272s199.475272,-89.364922 199.475272,-199.475272s-89.364922,-199.475272 -199.475272,-199.475272zm99.737636,219.422799l-199.475272,0l0,-39.895054l199.475272,0l0,39.895054z"
      />
    </svg>
  ),
  'search-document': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111 102"
      width={size}
      height={size}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M110.979 30.941a3.07 3.07 0 0 0-.054-.534c-.005-.024-.014-.046-.02-.07a2.917 2.917 0 0 0-.383-.919c-.029-.047-.056-.093-.087-.137a2.948 2.948 0 0 0-.325-.393l-.01-.011L81.983.881l-.012-.01a3.021 3.021 0 0 0-.394-.325c-.044-.03-.09-.056-.135-.084a3.137 3.137 0 0 0-.332-.18c-.052-.023-.102-.047-.155-.067a2.908 2.908 0 0 0-.454-.141L80.449.06a3.12 3.12 0 0 0-.546-.055c-.018 0-.034-.005-.053-.005H27.627a3.006 3.006 0 0 0-3.013 3v14.766C10.722 19.698-.007 31.599-.007 45.955c0 14.355 10.729 26.256 24.621 28.188V99c0 1.657 1.349 3 3.013 3h80.345a3.007 3.007 0 0 0 3.013-3V31c0-.021-.006-.039-.006-.059zM100.698 28H82.864V10.242L100.698 28zM6.019 45.955c0-12.39 10.124-22.47 22.567-22.47s22.566 10.08 22.566 22.47c0 12.389-10.123 22.468-22.566 22.468S6.019 58.344 6.019 45.955zM104.959 96H30.64V74.342c6-.427 11.495-2.701 15.908-6.259l17.628 17.551c.588.587 1.36.88 2.13.88.771 0 1.543-.293 2.131-.88a2.991 2.991 0 0 0 0-4.242L50.809 63.84c3.979-4.892 6.369-11.115 6.369-17.885 0-15.01-11.729-27.335-26.538-28.388V6h46.197v25c0 1.656 1.35 3 3.013 3h25.109v62z"
      />
    </svg>
  ),
  'arrow-top': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
    >
      <path
        fill={color}
        transform="rotate(270, 6, 6)"
        d="M4.5,9.8C4.3,9.8,4.1,9.7,4,9.5C3.7,9.2,3.7,8.8,4,8.5L6.4,6L4,3.5C3.7,3.2,3.7,2.8,4,2.5s0.8-0.3,1.1,0l3,3
	c0.3,0.3,0.3,0.8,0,1.1l-3,3C4.9,9.7,4.7,9.8,4.5,9.8z"
      />
    </svg>
  ),
  'arrow-down': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
    >
      <path
        fill={color}
        transform="rotate(90, 6, 6)"
        d="M4.5,9.8C4.3,9.8,4.1,9.7,4,9.5C3.7,9.2,3.7,8.8,4,8.5L6.4,6L4,3.5C3.7,3.2,3.7,2.8,4,2.5s0.8-0.3,1.1,0l3,3
	c0.3,0.3,0.3,0.8,0,1.1l-3,3C4.9,9.7,4.7,9.8,4.5,9.8z"
      />
    </svg>
  ),
  home: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M14.883 7.013L7.999 2.407 1.115 7.013.001 5.352 7.999 0l7.999 5.352-1.115 1.661zM3.993 14h8.012V8.001h2.003V16H1.991V8.001h2.002V14z"
      />
    </svg>
  ),
  'expand-down': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 8 4"
    >
      <path fill={color} fillRule="evenodd" d="M8 0L4 4-.001 0H8z" />
    </svg>
  ),
  burger: (size: string, color: string) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M21,13H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,13,21,13z M22,4c0-0.6-0.4-1-1-1H3C2.4,3,2,3.4,2,4s0.4,1,1,1
h18C21.6,5,22,4.6,22,4z M22,20c0-0.6-0.4-1-1-1H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h18C21.6,21,22,20.6,22,20z"
      />
    </svg>
  ),
  pin: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 401.506 401.506"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M397.373,135.487L267.742,5.867c-2.67-2.671-6.22-4.142-9.991-4.142c-3.773,0-7.322,1.471-9.991,4.142l-38.254,38.252
		c-2.67,2.67-4.141,6.219-4.141,9.992c0,3.774,1.471,7.322,4.141,9.992l5.575,5.562l-95.653,95.653L15.314,191.032
		c-4.944,1.221-8.863,5.025-10.227,9.93c-1.367,4.909,0.021,10.187,3.622,13.777l78.173,78.177l-82.741,82.74
		c-2.67,2.67-4.141,6.219-4.141,9.99c0,3.773,1.471,7.322,4.141,9.992c2.671,2.67,6.219,4.143,9.992,4.143s7.321-1.473,9.992-4.143
		l82.74-82.738l78.188,78.18c3.556,3.561,8.759,5,13.796,3.621c4.891-1.361,8.694-5.281,9.915-10.227l25.704-104.104l0.396-0.396
		l95.257-95.257l9.016,9.016c2.632,2.631,6.274,4.141,9.993,4.141c3.773,0,7.322-1.471,9.991-4.141l38.253-38.262
		C402.883,149.962,402.883,140.999,397.373,135.487z M187.332,353.387L46.402,212.465l75.883-18.742l83.781,83.789L187.332,353.387z
		M221.695,253.174l-75.072-75.081l88.44-88.442l75.071,75.08L221.695,253.174z M349.129,163.754L239.484,54.109l18.267-18.266
		l109.646,109.636L349.129,163.754z"
      />
    </svg>
  ),
  info: (size: string, color: string) => (
    <svg
      viewBox="0 0 400 400"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="null"
        id="svg_4"
        fill="none"
        d="m-39.370328,-39.37033l478.740701,0l0,478.740701l-478.740701,0l0,-478.740701z"
      />
      <path
        stroke="null"
        id="svg_5"
        fill={color}
        d="m200.000023,0.524728c-110.110361,0 -199.475292,89.364931 -199.475292,199.475292s89.364931,199.475292 199.475292,199.475292s199.475292,-89.364931 199.475292,-199.475292s-89.364931,-199.475292 -199.475292,-199.475292zm19.947529,299.212938l-39.895058,0l0,-119.685175l39.895058,0l0,119.685175zm0,-159.580234l-39.895058,0l0,-39.895058l39.895058,0l0,39.895058z"
      />
    </svg>
  ),
  'play-circle': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </svg>
  ),
  'pause-circle': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z"
      />
    </svg>
  ),
  tune: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"
      />
    </svg>
  ),
  pallete: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
        fill={color}
      />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  ),
  attach: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"
        fill={color}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  discussion: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
    >
      <path
        d="M10.5,2.5v5c0,0.6-0.4,1-1,1h-6l-2,2v-8c0-0.6,0.4-1,1-1h7C10.1,1.5,10.5,1.9,10.5,2.5z"
        fill={color}
      />
    </svg>
  ),
  discussionOutline: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <path
        d="M2,14.8c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.4-0.5-0.7V3.3c0-1.1,0.9-2.1,2.1-2.1h9.3h0c1.1,0,2.1,0.9,2.1,2.1V10
	c0,1.1-0.9,2.1-2.1,2.1H5l-2.4,2.4C2.4,14.7,2.2,14.8,2,14.8z M3.3,2.8C3,2.8,2.8,3,2.8,3.3v8.9l1.4-1.4c0.1-0.1,0.3-0.2,0.5-0.2h8
	c0.3,0,0.6-0.3,0.6-0.6V3.3c0-0.3-0.3-0.6-0.6-0.6l0,0H3.3z"
        fill={color}
      />
    </svg>
  ),
  calendar: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"
        fill={color}
      />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  ),
  place: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        fill={color}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'shopping-cart': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"
        fill={color}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'shopping-basket': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
        fill={color}
      />
    </svg>
  ),
  person: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  'person-outline': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  ),
  logout: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill={color}
          d="M9,20H5c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h4c0.6,0,1-0.4,1-1S9.6,2,9,2H5C3.3,2,2,3.3,2,5v14
		c0,1.7,1.3,3,3,3h4c0.6,0,1-0.4,1-1S9.6,20,9,20z"
        />
        <path
          fill={color}
          d="M21.9,12.4c0.1-0.2,0.1-0.5,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.3l-5-5c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.3,3.3H9
		c-0.6,0-1,0.4-1,1s0.4,1,1,1h9.6l-3.3,3.3c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5
		C21.8,12.6,21.9,12.5,21.9,12.4z"
        />
      </g>
    </svg>
  ),
  'drag-handle': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <defs>
        <path id="a" d="M0 0h24v24H0V0z" />
      </defs>
      <clipPath id="b">
        <use xlinkHref="#a" overflow="visible" />
      </clipPath>
      <path
        fill={color}
        clipPath="url(#b)"
        d="M20 9H4v2h16V9zM4 15h16v-2H4v2z"
      />
    </svg>
  ),
  visibility: (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
      />
    </svg>
  ),
  'visibility-off': (size: string, color: string) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        d="M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z"
        fill="none"
      />
      <path
        fill={color}
        d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z"
      />
    </svg>
  ),
  account,
  arrowWinner,
  arrowMore,
  print,
  'add-euro': addEuro,
  'add-circle-outline': addCircleOutline,
  apps,
  back,
  'business-card': businessCard,
  'calendar-match': calendarMatch,
  card,
  crop,
  link,
  'edit-document': editDocument,
  email,
  euro,
  export: exportIcon,
  file,
  files,
  folder,
  goal,
  group,
  'horizontal-tree': horizontalTree,
  image,
  import: importIcon,
  membership,
  message,
  monitor,
  notification,
  order,
  product,
  question,
  settings,
  suitcase,
  test: testIcon,
  tooltip,
  trash,
  'vertical-tree': verticalTree,
  write: writeIcon,
  organization,
  /* logo icons */
  'google-analytics': googleAnalytics,
  'google-tag-manager': googleTagManager,
  facebook,
  facebookFutbalnet,
  instagram,
  linkedin,
  livestream,
  twitter,
  youtube,
  time,
  pinterest,
  tumblr,
  mail,
  yellowCard,
  redCard,
  chevron,
  'chevron-table': chevronTable,
  avatar,
  search,
  sme,
  more,
  doxxbet,
  futbalnet,
  'futbalnet-icon': futbalnetIcon,
  futbalnetSymbol,
  premium,
  'premium-shape': premiumShape,
  video,
  gallery,
  podcast,
  stadium,
  referee,
  hash,
  news,
  'more-dots': moreDots,
  phone,
  share,
  'small-chevron': smallChevron,
  futbal,
  'contact-person': contactPerson,
  'logo-placeholder': logoPlaceholder,
  circle,
  star,
  'sportnet-icon': sportnetIcon,
  DoxxbetShort,
  refresh,
  chevronLeft,
  chevronRight,
  calendarDoxx,
  stats,
  'check-big': checkBig,
  tag,
  filter,
  redChevronDown,
  greenChevronUp,
  noResults,
  redArrowLeft,
  greenArrowRight,
  'soccer-ball': soccerBall,
  run,
  'event-seat': eventSeat,
  groups,
  whistle,
  'stadium-print': stadiumPrint,
  print2,
  nike,
  lockNike,
  'pin-2': pin2,
};
