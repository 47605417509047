import { TransferType } from '../../library/Transfermarket';
import __ from '../__';

const getTransferTypeLabel = (transferType: TransferType) => {
  switch (transferType) {
    case 'hosting': {
      return __('HOSŤOVANIE');
    }
    case 'transfer': {
      return __('PRESTUP');
    }
    default: {
      return '';
    }
  }
};

export default getTransferTypeLabel;
