import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled, { css } from '../../theme/styled-components';
import Link from '../Link';

export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
  position: relative;
  font-size: ${rem(14)};
  font-weight: normal;
  line-height: ${rem(14.4)};
`;

export const Thead = styled.div`
  display: table-header-group;
  > * > * {
    background-color: #f1f1f1;
  }
  font-size: ${rem(13)};
  font-weight: 700;
`;

export const Tbody = styled.div`
  display: table-row-group;
  border-top: ${rem(2)} solid #fff;
`;

export const ResponsiveSpacer = styled.div`
  display: table-cell;
  width: 100%;
  ${mb('m')} {
    display: none;
  }
`;

export const Td = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: ${rem(48)};
  padding: ${rem(8)} ${rem(16)};
  :first-child {
    padding-left: ${rem(24)};
  }
  :last-child {
    padding-right: ${rem(24)};
  }
`;

export const StickyTd = styled(Td)`
  position: sticky;
  z-index: 1;
`;

const tr = css`
  display: table-row;
  :nth-child(odd) {
    > * {
      background-color: #f9f9f9;
    }
  }
  :nth-child(even) {
    > * {
      background-color: #fff;
    }
  }
`;

export const TrLink = styled(Link)`
  ${tr}
`;

export const Tr = styled.div`
  ${tr}
`;

export const Th = styled.div`
  display: table-row;
`;

export const StickySeparator = styled.div`
  width: ${rem(8)};
  border-left: ${rem(1)} solid #ddd;
  background: transparent
    linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0) 100%) 0% 0%
    no-repeat padding-box;
  height: 100%;
  ${mb('m')} {
    display: none;
  }
`;

export const Legend = styled.div`
  margin: ${rem(16)} 0 0 0;
  opacity: 0.56;
  font-size: ${rem(13)};
`;
