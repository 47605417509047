import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { RootState } from '../../../../configureStore';
import {
  authUserFetchingSelector,
  authUserIsSubscribedSelector,
  authUserSelector,
  getSectionSubTree,
  sportnetMainMenuSelector,
  sportnetSideMenuSelector,
  sportnetTopbarMenuSelector,
} from '../../../../pages/App/selectors';
import { headerConfigurationSelector } from '../../../../pages/DomainResolver/selectors';
import { currentSectionIdSelector } from '../../../../pages/Section/selectors';
import context from '../../context';
import Header from './Header';

type OwnProps = {};

const mapStateToProps = (state: RootState) => {
  const headerConfiguration = headerConfigurationSelector(state);
  const sectionIdOrUniqId = getProp(
    headerConfiguration,
    ['config', 'sectionIdOrUniqId'],
    '',
  ) as string;
  return {
    headerConfiguration,
    sections: getSectionSubTree(state, sectionIdOrUniqId)?.sections || [],
    sportnetMainMenus: sportnetMainMenuSelector(state),
    otherSections:
      getSectionSubTree(state, 'vseobecne-informacie')?.sections || [],
    topBarSections: sportnetTopbarMenuSelector(state),
    sideMenuItems:sportnetSideMenuSelector(state),
    authUser: authUserSelector(state),
    authUserFetching: authUserFetchingSelector(state),
    sectionId: currentSectionIdSelector(state),
    authUserIsSubscribed: authUserIsSubscribedSelector(state),
  };
};

const mapDispatchToProps = {};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps<{}, {}> &
  typeof mapDispatchToProps;

const HeaderSimple: React.FC<Props> = ({
  headerConfiguration,
  sections,
  otherSections,
  topBarSections,
  sportnetMainMenus,
  sideMenuItems,
  router,
  authUser,
  authUserFetching,
  authUserIsSubscribed,
  sectionId,
  location,
}) => {
  const ctx = React.useContext(context);
  const [searchQuery, setSearchQuery] = React.useState('');

  return (
    <Header
      location={location.pathname}
      configuration={headerConfiguration.config}
      sections={sportnetMainMenus}
      topBarSections={topBarSections}
      otherSections={otherSections}
      sideMenuItems={sideMenuItems}
      onChangeSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      onSearchSubmit={() => {
        window.location.href = `${process.env.REACT_APP_HOST}/search/?q=${searchQuery}`;
      }}
      onClickLogin={ctx.login}
      onClickLogout={ctx.logout}
      authUser={authUser}
      authUserFetching={authUserFetching}
      authUserIsSubscribed={authUserIsSubscribed}
      sectionId={sectionId}
    />
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderSimple);
