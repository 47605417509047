import { rem } from 'polished';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Input from '../Input';
import { DatePickerWrapper } from './DatePickerWrapper';

const StyledInput = styled(Input)`
  padding: 0 ${rem(16)} !important;
`;

export interface OwnProps {
  disabled?: boolean;
  readOnly?: boolean;
  value: Date | null;
  onChange: (value: Date | null) => void;
  showYearDropdown?: boolean;
  timeIntervals?: number;
  error?: boolean;
  label?: string;
  minDate?: Date;
  maxDate?: Date;
  name?: string;
  className?: string;
  placeholder?: string;
}

type Props = OwnProps;

export default class CustomDatePicker extends React.Component<Props> {
  render() {
    const {
      disabled = false,
      readOnly = false,
      showYearDropdown = true,
      value = null,
      onChange,
      timeIntervals = 5,
      error,
      minDate,
      maxDate,
      placeholder,
      ...other
    } = this.props;
    return (
      <DatePickerWrapper>
        <DatePicker
          dateFormat="dd.MM.yyyy"
          timeIntervals={timeIntervals}
          selected={value}
          onChange={(d: Date | null) => {
            onChange(d);
          }}
          disabled={disabled}
          isClearable={!disabled && !readOnly}
          showYearDropdown={showYearDropdown}
          placeholderText={placeholder}
          timeCaption="Čas"
          customInput={
            <StyledInput error={error} {...other} type="text">
              <input />
              {!!value && (
                <Input.Icon
                  className="inputBackspace clickable"
                  name="backspace"
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChange(null);
                  }}
                />
              )}
            </StyledInput>
          }
          scrollableYearDropdown
          dropdownMode="select"
          popperModifiers={{
            preventOverflow: {
              enabled: true, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
          }}
          minDate={minDate}
          maxDate={maxDate}
          {...other}
        />
      </DatePickerWrapper>
    );
  }
}
