import { reducerWithInitialState } from 'typescript-fsa-reducers';
import AppSettings from '../../library/AppSettings';
import * as actions from './actions';
import appSettings from './appSettings';

export interface IAppSettingsState {
  isFetching?: boolean;
  error?: any | null;
  data?: AppSettings;
}

export const appSettingsReducer = reducerWithInitialState<IAppSettingsState>({
  data: appSettings,
  error: null,
  isFetching: false,
}).case(
  actions.setAppSettings,
  (state, payload): IAppSettingsState => ({
    data: payload.settings,
    isFetching: false,
    error: null,
  }),
);
