/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 * RESTful API specification.

 * @class Video Api
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class FutbalnetApi {
  protected baseUrl: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Get Person by ISSF id
   * @method
   * @name FutbalnetApi#getPerson
   */
  getPerson(
    regnr: string | number,
  ): Promise<{
    id: string;
    name: string;
    surname: string;
    regnr: string;
    photo?: {
      description: string;
      url: string;
      urlpattern: string;
    };
    isPublic: boolean;
    title: string;
    sex: string;
    country: string;
    card: {
      number: string;
      validity: string;
      ordered: string;
    };
    profiles: Array<{
      __uri__: string;
      profileId: string;
      type: string;
      person: {
        __uri__: string;
        id: string;
        regnr: string;
      };
    }>;
    birthdate: string;
    datumClenskehoOd: string;
    datumClenskehoDo: string;
  }> {
    let path = '/persons/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Trainer Licenses by ISSF id
   * @method
   * @name FutbalnetApi#getTrainerLicenses
   */
  getTrainerLicenses(
    regnr: string | number,
  ): Promise<
    Array<{
      number: string;
      type: string;
      validity: {
        from: string;
        to: string;
      };
    }>
  > {
    let path = '/trainers/{regnr}/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Referee Licenses by ISSF id
   * @method
   * @name FutbalnetApi#getRefereeLicenses
   */
  getRefereeLicenses(
    regnr: string | number,
  ): Promise<
    Array<{
      number: string;
      type: string;
      validity: {
        from: string;
        to: string;
      };
    }>
  > {
    let path = '/referees/{regnr}/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Delegate M Licenses by ISSF id
   * @method
   * @name FutbalnetApi#getDelegateMLicenses
   */
  getDelegateMLicenses(
    regnr: string | number,
  ): Promise<
    Array<{
      number: string;
      type: string;
      validity: {
        from: string;
        to: string;
      };
    }>
  > {
    let path = '/delegatems/{regnr}/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Delegate O Licenses by ISSF id
   * @method
   * @name FutbalnetApi#getDelegateOLicenses
   */
  getDelegateOLicenses(
    regnr: string | number,
  ): Promise<
    Array<{
      number: string;
      type: string;
      validity: {
        from: string;
        to: string;
      };
    }>
  > {
    let path = '/delegateos/{regnr}/licenses';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Player detail
   * @method
   * @name FutbalnetApi#getPlayer
   */
  getPlayer(
    regnr: string | number,
  ): Promise<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      name: string;
      surname: string;
      regnr: string;
      photo: {
        description: string;
        url: string;
        urlpattern: string;
      };
      isPublic: true;
    };
    profileStatus: {
      status: string;
      regDate: string;
    };
    club_origin: {
      __uri__: string;
      __redis__: string;
      id: number;
      logo: {
        url: string;
        urlpattern: string;
      };
      name: string;
    };
    club_actual: {
      __uri__: string;
      __redis__: string;
      id: number;
      name: string;
    };
    club_hosted?: {
      __uri__: string;
      __redis__: string;
      id: number;
      name: string;
    };
    player_status: string;
  }> {
    let path = '/players/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Trainer detail
   * @method
   * @name FutbalnetApi#getTrainer
   */
  getTrainer(
    regnr: string | number,
  ): Promise<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      name: string;
      surname: string;
      regnr: string;
      photo: {
        description: string;
        url: string;
        urlpattern: string;
      };
      isPublic: true;
    };
    profileStatus: {
      status: string;
      regDate: string;
    };
    union_origin: {
      __uri__: string;
      id: number;
    };
    union_actual: {
      __uri__: string;
      id: number;
    };
    registration_date: string;
  }> {
    let path = '/trainers/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Trainer detail
   * @method
   * @name FutbalnetApi#getTrainer
   */
  getReferee(
    regnr: string | number,
  ): Promise<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      name: string;
      surname: string;
      regnr: string;
      photo: {
        description: string;
        url: string;
        urlpattern: string;
      };
      isPublic: true;
    };
    profileStatus: {
      status: string;
      regDate: string;
    };
    union_origin: {
      __uri__: string;
      id: number;
    };
    union_actual: {
      __uri__: string;
      id: number;
    };
    career_year: number;
    registration_date: string;
  }> {
    let path = '/referees/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Delegate O detail
   * @method
   * @name FutbalnetApi#getDelegateO
   */
  getDelegateO(
    regnr: string | number,
  ): Promise<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      name: string;
      surname: string;
      regnr: string;
      photo: {
        description: string;
        url: string;
        urlpattern: string;
      };
      isPublic: true;
    };
    profileStatus: {
      status: string;
      regDate: string;
    };
    union_origin: {
      __uri__: string;
      id: number;
    };
    union_actual: {
      __uri__: string;
      id: number;
    };
    career_year: number;
    registration_date: string;
  }> {
    let path = '/delegateos/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Delegate M detail
   * @method
   * @name FutbalnetApi#getDelegateM
   */
  getDelegateM(
    regnr: string | number,
  ): Promise<{
    __uri__: string;
    profileId: string;
    type: string;
    person: {
      __uri__: string;
      id: string;
      name: string;
      surname: string;
      regnr: string;
      photo: {
        description: string;
        url: string;
        urlpattern: string;
      };
      isPublic: true;
    };
    profileStatus: {
      status: string;
      regDate: string;
    };
    union_origin: {
      __uri__: string;
      id: number;
    };
    union_actual: {
      __uri__: string;
      id: number;
    };
    career_year: number;
    qualification_year: number;
    registration_date: string;
  }> {
    let path = '/delegatems/{regnr}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Get Player Movements by ISSF id
   * @method
   * @name FutbalnetApi#getPlayerMovements
   */
  getPlayerMovements(
    regnr: string | number,
  ): Promise<
    Array<{
      club_source: {
        id: number;
        logo: {
          url: string;
          urlpattern: string;
        };
        name: string;
      };
      club_destination: {
        id: number;
        logo: {
          url: string;
          urlpattern: string;
        };
        name: string;
      };
      date: {
        from: string;
        to?: string;
      };
      status: string;
      move_type: string;
    }>
  > {
    let path = '/players/{regnr}/movements';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{regnr}', regnr.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new FutbalnetApi();
