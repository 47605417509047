import getProp from 'sportnet-utilities/lib/getProp';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { URL_Map } from '../../api/CmsApi';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import ForbiddenError from '../../ForbiddenError';
import InternalServerError from '../../InternalServerError';
import {
  ArticleId,
  IArticle,
  IPrivateContent,
  ISection,
  SectionId,
} from '../../library/App';
import NotFoundError from '../../NotFoundError';
import RedirectError from '../../RedirectError';
import UnauthorizedError from '../../UnauthorizedError';
import reduceSectionProps from '../../utilities/reduceSectionProps';
import { updateEntities } from '../App/actions';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';

const create = actionCreatorFactory('FUTBALNET_URL_MAP');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export interface IUrlMapResult {
  data: {
    urltype: string;
    object_id: ArticleId | SectionId;
    state: IPrivateContent['state'];
  };
}

export const loadByAppSpaceUrl = createAsync<
  { url: string; appSpace: string },
  IUrlMapResult
>(
  'LOAD_BY_APPSPACE_URL',
  async ({ url, appSpace }, dispatch, getState, { CmsApi }) => {
    let response: URL_Map;
    let state: IPrivateContent['state'] = 'FULL';

    try {
      response = await CmsApi.getContentByUrl(
        config.APP_ID,
        appSpace,
        config.DEFAULT_CONTENT_DIVIDER,
        {
          urlPath: url,
          expandObject: 1,
          expandWidgets: true,
        },
      );
    } catch (e: any) {
      if (e && e.details && e.details.code === 404) {
        throw new NotFoundError(e);
      } else if (e && e.details && e.details.code === 401) {
        const data = getProp(e.details, ['payload', 'data'], null);
        if (data) {
          response = data;
          state = 'UNATHORIZED';
        } else {
          throw new UnauthorizedError(e);
        }
      } else if (e && e.details && e.details.code === 403) {
        const data = getProp(e.details, ['payload', 'data'], null);
        if (data) {
          response = data;
          state = 'FORBIDDEN';
        } else {
          throw new ForbiddenError(e);
        }
      } else {
        throw new InternalServerError(e);
      }
    }

    // NOTE: section urls should not be supported
    if (response.urltype === 'section') {
      throw new NotFoundError({});
    }

    const entities: {
      articles: {
        [key: string]: IArticle;
      };
      sections: {
        [key: string]: ISection;
      };
    } = {
      articles: {},
      sections: {},
    };

    if (response.urltype === 'section') {
      const expandedObject = { ...response.expanded_object, state } as ISection;
      entities.sections[response.object_id!] =
        reduceSectionProps(expandedObject);
    } else if (response.urltype === 'article') {
      const expandedObject = { ...response.expanded_object, state } as IArticle;
      entities.articles[response.object_id!] = expandedObject;
    } else if (response.urltype === 'redirect') {
      throw new RedirectError({
        location: response.location,
        permanent: response.permanent,
      });
    }

    dispatch(updateEntities(entities));

    return {
      data: {
        urltype: response.urltype!,
        object_id: response.object_id!,
        state,
      },
    };
  },
);

export const loadByUrlFutbalnet = (
  url: string,
): CustomThunkAction<Promise<IUrlMapResult>> => {
  return (dispatch, getState) => {
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
    return dispatch(loadByAppSpaceUrl({ appSpace, url }));
  };
};
