

import { rem } from 'polished';
import Icon, {ISize, IIconName as IconName } from '../../components/Icon';
import { LinkProps } from 'react-router';
import Link from '../../components/Link';
import styled, { css, useTheme } from 'styled-components';
import { BaseButton } from '../PrimaryButton/PrimaryButton';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { ITheme } from '../../theme/theme';
import React from 'react';

export const getIconColor = (color: string, theme: ITheme) => {
  switch (color) {
    case 'primary': {
      return theme.color.primaryColor;
    }
    case 'secondaryFontColor': {
      return theme.color.secondaryFontColor;
    }
    default: {
      return color;
    }
  }
};

const Button = styled(BaseButton)<{
  $size: 'desktop' | 'mobile' | 'responsive';
  $hasChildren?: boolean;
  $iconRotation?: number;
}>`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.primaryFont.weight.semiBold};
  line-height: 1.3;
  border-radius: ${({ theme }) => rem(theme.border.borderRadiusSmall)};
  border: ${({ theme }) => theme.border.normalLight};
  color: ${({ theme }) => theme.color.primaryFontColor};
  background-color: ${({ theme }) => theme.color.white};
  justify-content: ${({ $hasChildren = false }) =>
    $hasChildren ? 'flex-start' : 'center'};
  ${({ $hasChildren = false, $size }) =>
    $size === 'desktop'
      ? css`
          font-size: ${rem(12)};
          padding: 0 ${$hasChildren ? rem(16) : 0};
          height: ${rem(32)};
          width: ${$hasChildren ? 'auto' : rem(32)};
        `
      : $size === 'mobile'
      ? css`
          font-size: ${rem(11)};
          padding: 0 ${$hasChildren ? rem(12) : 0};
          height: ${rem(24)};
          width: ${$hasChildren ? 'auto' : rem(24)};
        `
      : css`
          font-size: ${rem(11)};
          padding: 0 ${$hasChildren ? rem(12) : 0};
          height: ${rem(24)};
          width: ${$hasChildren ? 'auto' : rem(24)};
          ${mb('s')} {
            font-size: ${rem(12)};
            padding: 0 ${$hasChildren ? rem(16) : 0};
            height: ${rem(32)};
            width: ${$hasChildren ? 'auto' : rem(32)};
          }
        `};
  :hover:not(:disabled),
  :active,
  :focus {
    background-color: ${({ theme }) => theme.color.white};
    text-decoration: underline;
  }
  .icon {
    margin-right: ${({ $hasChildren = false }) => ($hasChildren ? rem(8) : 0)};
    ${({ $iconRotation }) =>
      typeof $iconRotation !== 'undefined' &&
      css`
        transform: rotate(${$iconRotation}deg);
        transition: transform 0.1s;
      `}
    ${({ $size }) =>
      $size === 'responsive' &&
      css`
        width: ${rem(12)};
        height: ${rem(12)};
        ${mb('s')} {
          width: ${rem(16)};
          height: ${rem(16)};
        }
      `};
  }
`;

interface OwnProps {
  /**
   * Pretaženie styled-components. Štýluje sa priamo <button> element.
   */
  className?: string;
  /**
   * Children komponenty, ktore sa renderuju v ramci `<button></button>`. Ak nemá
   * žiadne children komponenty, renderuje sa ako pravidelný štvorec. Takto sa tlačidlo
   * používa, ak obsahuje iba ikonu.
   */
  children?: React.ReactNode;
  /**
   * Ak je zadany `href` renderuje `<a`>
   */
  href?: LinkProps['to'];
  /**
   * Doplňujúce atribúty pre HTML link.
   */
  linkRel?: string;
  linkTarget?: string;
  /**
   * Ak je zadaný handler pre click, komponent sa renderuje ako `<button>`. Ak je zadaný
   * `href` aj `onClick` súčasne, komponent sa renderuje ako HTML link s `onClick` handlerom.
   * @returns
   */
  onClick?: () => void;
  /**
   * Nastavuje atribut `disabled`.
   */
  disabled?: boolean;
  /**
   * Nastavuje `title` atribut.
   */
  title?: string;
  /**
   * Ikona pred text buttonu. POZOR: Musí mať štvorcový formát!
   */
  icon?: IconName;
  /**
   * Farba ikony. Ak nie je zadaná, použije sa hodnota `currentColor`. Hodnota `primary`
   * nastavuje primárnu farbu témy, hodnota `secondaryFontColor` nastavuje sekundárnu farbu
   * textu (pozri.: `theme.color.secondaryFontColor`). Akákoľvek iná hodnota sa považuje za
   * HTML farbu.
   */
  iconColor?: string;
  /**
   * Nastavuje velkost ikony. Default je `m`, co je 16px.
   */
  iconSize?: ISize;
  /**
   * Rotacia ikony pomocou css `trasnform`. Je spojená s animáciou, ktorá nie je konfigurovateľná.
   */
  iconRotation?: number;
  /**
   * Vypína border (používa sa v reklamnom banneri PARTNERS)
   */

  noBorder?: boolean;
  /**
   * Velkost tlacidla optimalizovana pre "desktop" alebo "mobil".
   */
  size?: 'desktop' | 'mobile' | 'responsive';
}

export const TertiaryButton: React.FC<OwnProps> = ({
  className,
  children,
  href,
  linkRel,
  linkTarget,
  onClick,
  disabled = false,
  title,
  icon,
  iconColor = 'currentColor',
  iconRotation,
  size = 'responsive',
  iconSize = 'm',
}) => {
  const theme = useTheme() as unknown as ITheme;
  return (
    <Button
      onClick={(e: React.MouseEvent) => {
        // stop propagation potrebujeme kvoli dropdown detekcii,
        // ci sme klikli mimo dropdpwn. Bez stop propagation moze byt
        // `event.target` objekte `<span>` ikony
        e.stopPropagation();
        onClick && onClick();
      }}
      className={`${className ?? ''} tertiary-button`}
      disabled={disabled}
      title={title}
      {...(href ? { to: href } : undefined)}
      rel={linkRel}
      target={linkTarget}
      $hasChildren={typeof children !== 'undefined'}
      $size={size}
      $iconRotation={iconRotation}
      as={href ? Link : 'button'}
    >
      {icon && (
        <Icon
          name={icon}
          size={iconSize}
          color={getIconColor(iconColor, theme)}
        />
      )}
      {children}
    </Button>
  );
};
