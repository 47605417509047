/**
 * Komponent renderuje NotFound stranku pre futbalnet v pripade, ze pride request na URL
 * s prefixom `/futbalnet` ktory nepatri medzi platne URLs futbalnetu definovane v routri.
 * Tento fix ma predist tomu aby neplatne futbalnetove URLs koncili v URL mape CMS.API.
 *
 * Kvoli chybe URLs v hlavnom menu, ktorym chybalo lomitko na zaciatku, portal generoval URLs
 * ako `futbalnet/obfz-zvolen/zapas/457770/hokej` ktore koncili v URL mape CMS.API a vytazovali
 * API server.
 */
import React from 'react';
import { useAsyncData } from 'ssr-service';
import NotFoundError from '../../NotFoundError';
import NotFound from '../NotFound';

const FutbalnetNotFound: React.FC = () => {
  useAsyncData(async () => {
    throw new NotFoundError('NOT_FOUND');
  }, []);
  return <NotFound />;;
};

export default FutbalnetNotFound;
