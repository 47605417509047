import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import { SPORT_SECTOR_CREW } from '../../../../codelists/sport_sector_crew';

export interface SportSectorCodelistParams {
  sportSector: string;
}

const getSportSectorCrew = createQueryFn(
  QueryNameSpace.codelistSportSetorCrew,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, SportSectorCodelistParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters || !parameters.sportSector) {
      throw new Error('Must provide required parameters [matsportSectorchId]!');
    }

    const { sportSector } = parameters;

    // sport sector crew
    const sportSectorCrew = SPORT_SECTOR_CREW.find(
      (setting) => setting._id === sportSector,
    );
    if (!sportSectorCrew) {
      throw new Error(
        `Sport sector crew not found by sport sector ${sportSector}`,
      );
    }

    return sportSectorCrew;
  },
);

export default getSportSectorCrew;
