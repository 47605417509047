import { __ } from '@sportnet/content/utilities/utilities';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import { rem } from 'polished';
import useQuery, { QueryHocInterface, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { getProp, removeAccents } from 'sportnet-utilities';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BodyAd from '../../../components/Ads/BodyAd';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import BasicTable from '../../../components/BasicTable';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import { IIconName } from '../../../components/Icon';
import Ladder from '../../../components/Ladder';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import SearchInput from '../../../components/SearchInput';
import Spacer from '../../../components/Spacer';
import Tabber from '../../../components/Tabber';
import UnionCompetitionsWidget from '../../../components/UnionCompetitionsWidget';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { IPlayerStat } from '../../../library/Competitions';
import { UnionItem } from '../../../library/Union';
import styled from '../../../theme/styled-components';
import getCompetitionBreadcrumbLabel from '../../../utilities/futbalnet/breadcrumbs/getCompetitionBreadcrumblabel';
import getCompetitionBaseUrl from '../../../utilities/getCompetitionBaseUrl';
import getMembersNameWithLink from '../../../utilities/getMembersNameWithLink';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import { competitionsSelectorByUnion } from '../../FutbalnetApp/selectors';
import {
  loadCompetitionPartItem,
  loadCompetitionPartPlayers,
  setCurrentCompetitionPartId,
} from '../actions';
import {
  competitionPartsSelector,
  currentCompetitionIdSelector,
  currentCompetitionIsFetchingSelector,
  currentCompetitionPartPlayersIsFetchingSelector,
  currentCompetitionPartResultsTablePlayersSelector,
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../selectors';

const mapStateToProps = (state: RootState) => ({
  currentCompetitionId: currentCompetitionIdSelector(state),
  currentCompetition: currentCompetitionSelector(state),
  currentCompetitionPart: currentCompetitionPartSelector(state),
  isFetchingCompetition: currentCompetitionIsFetchingSelector(state) !== false,
  isFetchingCompetitionPart:
    currentCompetitionPartPlayersIsFetchingSelector(state),
  parts: competitionPartsSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitions: competitionsSelectorByUnion(state),
  resultsTablePlayers: currentCompetitionPartResultsTablePlayersSelector(state),
});

const Ladders = styled.div`
  margin-top: -${rem(16)};
  margin-bottom: ${rem(16)};
`;

const Title = styled.div`
  font-size: ${rem(20)};
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  padding-bottom: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
  margin-bottom: ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
`;

type Props = { dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

const LADDERS = [
  {
    title: __('Najlepší strelci'),
    icon: 'goal',
    statName: 'goals',
  },
  {
    title: __('Odohrané minúty'),
    icon: 'time',
    statName: 'minutes',
  },
  {
    title: __('Žlté karty'),
    icon: 'yellowCard',
    statName: 'yellow_cards',
  },
  {
    title: __('Červené karty'),
    icon: 'redCard',
    statName: 'red_cards',
  },
  // {
  //   title: __('Čisté kontá'),
  //   statName: 'clean_sheets',
  // },
  // {
  //   title: __('Vlastné góly'),
  //   statName: 'own_goals',
  // },
  // {
  //   title: __('Počet štartov v základnej zostave'),
  //   statName: 'match_starts',
  // },
  // {
  //   title: __('Počet vystriedaní'),
  //   statName: 'substitutions',
  // },
];

const DEFAULT_STATE = {
  sortPath: ['name'],
  sortDirection: 'desc',
  inputFocused: false,
};

class CompetitionStatistics extends React.PureComponent<Props> {
  inputRef: React.MutableRefObject<HTMLInputElement | null> = React.createRef();

  setInputFocused = (focusState: boolean) => {
    this.setState({
      inputFocused: focusState,
    });
  };

  inputFocus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
    setTimeout(() => {
      this.setInputFocused(true);
    }, 0);
  };

  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    return dispatch(
      loadCompetitionPartPlayers.action({
        competitionId: props.currentCompetitionId,
        competitionPartId: props.currentCompetitionPart?.__issfId ? String(props.currentCompetitionPart.__issfId) : props.currentCompetitionPart?._id || '',
        summary: props.query && props.query.summary === '1'
      }),
    );
  }

  state = DEFAULT_STATE;

  async componentDidMount() {
    await CompetitionStatistics.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      (this.props.query.part &&
        this.props.query.part !== prevProps.query.part) ||
      this.props.query.summary !== prevProps.query.summary
    ) {
      if (this.props.query.part) {
        this.props.dispatch(
          setCurrentCompetitionPartId(String(this.props.query.part)),
        );
      }
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartItem)({}) as any,
      );
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartPlayers)({
          competitionId: this.props.currentCompetitionId,
          competitionPartId: String(this.props.query.part),
          summary: this.props.query.summary === '1'
        }) as any,
      );
    } else if (
      this.props.query.competitionId &&
      this.props.query.competitionId !== prevProps.query.competitionId
    ) {
      await CompetitionStatistics.getInitialProps(this.props);
    }
  }

  renderLadder = (
    stat: {
      title: string;
      icon: IIconName;
      statName: string;
    },
    idx: number,
  ) => {
    const ladder = [...(this.props.resultsTablePlayers || [])]
      .filter((i) => (i.stats[stat.statName] || 0) > 0)
      .sort((a, b) =>
        (a.stats[stat.statName] || 0) > (b.stats[stat.statName] || 0) ? -1 : 1,
      )
      .slice(0, 5);
    if (ladder.length === 0) {
      return null;
    }
    return (
      <Col xs={12} m={6} key={`Ladder-${idx}`}>
        <Ladder
          initExpanded={idx === 0}
          items={ladder.map((i) => {
            const team = (this.props.currentCompetitionPart?.teams || []).find(
              (t) => t._id === i.teamId,
            );
            return {
              _id: i._id,
              name: i.name,
              __issfId: i.__issfId,
              team: {
                name: team?.organization?.name || '',
              },
              value: i.stats[stat.statName] || 0,
            };
          })}
          title={stat.title}
          icon={stat.icon}
        />
      </Col>
    );
  };

  render() {
    const {
      parts,
      currentCompetitionPart,
      currentCompetition,
      isFetchingCompetition,
      isFetchingCompetitionPart,
      setParameter,
      query: { q, summary },
      params: { sutaz, zvaz },
      location: { search },
      organizationProfile,
      competitions,
      resultsTablePlayers,
    } = this.props;

    const unionItem = { ...organizationProfile, competitions };

    const { sortPath, sortDirection } = this.state;

    if (
      (isFetchingCompetition && !currentCompetition) ||
      isFetchingCompetitionPart ||
      !currentCompetitionPart ||
      !currentCompetitionPart.resultsTable?.players
    ) {
      return <Loading />;
    }

    const ladderItems = LADDERS.map(this.renderLadder).filter((i) => i);

    let sortedStats = [...(resultsTablePlayers || [])];

    if (q) {
      sortedStats = sortedStats.filter(
        (i) =>
          removeAccents(i.name).match(new RegExp(`${String(q)}`, 'i')) ||
          i.name.match(new RegExp(`${String(q)}`, 'i')),
      );
    }

    sortedStats.sort((a, b) => {
      if (getProp<string>(a, sortPath) > getProp<string>(b, sortPath)) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (getProp<string>(a, sortPath) < getProp<string>(b, sortPath)) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });

    const title = `${currentCompetition?.name} (${currentCompetition?.season?.name
      }) - ${__('štatistiky')}`;
    const description = `${currentCompetition?.name}, ${__('štatistiky')}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale.',
    )}`;
    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <RempIntegration destroy />
        <TopAd />
        <BottomPaddedBigAd name="big_1" />
        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <Tabber
              onChange={(tab) => {
                if (tab.value === 'summary') {
                  setParameter({ summary: 1 })
                } else {
                  setParameter({ part: tab.value, summary: 0 })
                }
              }}
              active={
                summary === '1' ? 'summary' : currentCompetitionPart?.__issfId
                  ? String(currentCompetitionPart?.__issfId)
                  : currentCompetitionPart?._id
              }
              tabs={[...!!currentCompetition?.aggregatedPlayerStats ? [{ label: __('Sumárne'), value: 'summary' }] : [], ...parts.map((p: any) => ({
                label: p.name,
                value: p.__issfId ? String(p.__issfId) : p._id,
              }))]}
            />
            <Ladders>
              {ladderItems.length > 0 ? (
                <Row>{ladderItems}</Row>
              ) : (
                <ContentNotFound
                  title={__(
                    'Zatiaľ pre danú časť súťaže neevidujeme žiadne štatistiky.',
                  )}
                />
              )}
            </Ladders>
            <BodyAd name="body_1" />
            <Spacer size={3}>
              <Title>{__('Sumárna štatistika')}</Title>
              <SearchInput
                inputRef={this.inputRef}
                value={String(q)}
                placeholder={__('Zadajte meno hráča...')}
                setValue={(e) => setParameter({ q: e })}
                inputFocused={this.state.inputFocused}
                setInputFocused={this.setInputFocused}
                inputFocus={this.inputFocus}
              />
              <BasicTable
                onSort={(i, dir) => {
                  if (!dir && i._id.join('.') === 'name') {
                    this.setState({ ...DEFAULT_STATE, sortDirection: 'asc' });
                  } else if (!dir) {
                    this.setState(DEFAULT_STATE);
                  } else {
                    this.setState({ sortPath: i._id, sortDirection: dir });
                  }
                }}
                sticky
                columns={[
                  {
                    header: __('Meno hráča'),
                    sortable: true,
                    sort:
                      sortPath.join('.') === 'name'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    _id: ['name'],
                  },
                  {
                    header: __('Strelené góly'),
                    _id: ['stats', 'goals'],
                    sort:
                      sortPath.join('.') === 'stats.goals'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    sortable: true,
                  },
                  ...currentCompetitionPart.rules?.sport_sector === 'beachfutbal' ? [{
                    header: __('Odohrané zápasy'),
                    _id: ['stats', 'match_starts'],
                    sort:
                      sortPath.join('.') === 'stats.match_starts'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    sortable: true,
                  },] : [{
                    header: __('Odohrané minúty'),
                    _id: ['stats', 'minutes'],
                    sort:
                      sortPath.join('.') === 'stats.minutes'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    sortable: true,
                  },],
                  {
                    header: __('Žlté karty'),
                    _id: ['stats', 'yellow_cards'],
                    sort:
                      sortPath.join('.') === 'stats.yellow_cards'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    sortable: true,
                  },
                  {
                    header: __('Červené karty'),
                    _id: ['stats', 'red_cards'],
                    sort:
                      sortPath.join('.') === 'stats.red_cards'
                        ? (sortDirection as 'asc' | 'desc')
                        : undefined,
                    sortable: true,
                  },
                ]}
                rows={sortedStats}
                rowKey="_id"
                renderRow={(i: IPlayerStat) => [
                  getMembersNameWithLink(i.name, i.__issfId || i._id),
                  i.stats.goals || 0,
                  currentCompetitionPart.rules?.sport_sector === 'beachfutbal' ? i.stats.match_starts || 0 : i.stats.minutes || 0,
                  i.stats.yellow_cards || 0,
                  i.stats.red_cards || 0,
                ]}
              />
            </Spacer>
            <UnionCompetitionsWidget union={unionItem as UnionItem} />
          </SidebarLayoutContent>
          <Sidebar>
            <StickySidebarContainer>
              <SideAd name="side_1" />
            </StickySidebarContainer>
          </Sidebar>
        </PaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && currentCompetition && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
              {
                label: getCompetitionBreadcrumbLabel(currentCompetition),
                url: getCompetitionBaseUrl({ zvaz, sutaz }),
              },
              {
                label: 'Štatistiky',
                url: `${getCompetitionBaseUrl({ zvaz, sutaz })}statistiky/${search || ''
                  }`,
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(
  useQuery({
    parameters: {
      q: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      part: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
    },
  }),
  connect(mapStateToProps),
)(CompetitionStatistics);
