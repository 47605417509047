import { rem } from 'polished';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  border: 1px solid ${({ theme }) => theme.color.darkBorder};
  box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
  overflow: hidden;
`;

export default ContentContainer;

