import { rem } from 'polished';
import styled from 'styled-components';
import {
  ContextMarginSmallBottomStyles,
  ContextMarginTopStyles,
} from '../Layout';

export default styled.div`
  background-color: #fff;
  ${ContextMarginSmallBottomStyles}
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  ${ContextMarginTopStyles};
`;
