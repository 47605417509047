import { rem } from 'polished';
import Loading from '../../components/Loading';
import MaxWidthBox from '../../components/MaxWidthBox';
import React from 'react';
import styled from 'styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.lightBackground};
  border-bottom: ${({ theme }) => theme.border.normalLight};
  display: flex;
  ${mb('s')} {
    max-height: ${rem(88)};
    height: ${rem(88)};
  }
`;

const StyledMaxWidthBox = styled(MaxWidthBox)`
  && {
    padding: 0;
    ${mb('s')} {
      padding: 0 ${({ theme }) => rem(theme.app.padding)};
    }
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
`;

type IOwnProps = {
  className?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const MatchesPanelWrapper: React.FC<IProps> = ({
  className,
  isLoading,
  children,
}) => {
  return (
    <Wrapper className={className}>
      <StyledMaxWidthBox>
        <InnerWrapper>
          {isLoading ? <Loading minHeight={rem(82)} /> : children}
        </InnerWrapper>
      </StyledMaxWidthBox>
    </Wrapper>
  );
};
export default MatchesPanelWrapper;
