import { browserHistory } from 'react-router';
import debug from '../../../debug';
import { Location } from 'history';
import getCleanedPathFromWindowLocation from '../../../utilities/adverts/getCleanedPathFromWindowLocation';
import getCleandedPathFromLocation from '../../../utilities/adverts/getCleanedPathFromLocation';

export default (history: typeof browserHistory) => {
  // Resets SmeAdManager state so new ad positions are loaded correctly after performing client side page navigation
  let prevLocation =
    window && window.location
      ? getCleanedPathFromWindowLocation(window.location)
      : '';
  history.listen((location: Location) => {
    const newLocation = getCleandedPathFromLocation(location);

    if (typeof (window as any).pp_gemius_hit === 'function') {
      (window as any).pp_gemius_hit("B81FZusKPPTUbI6._59K45SqXoYusqMVA8koCXDA5QP.27");
    }

    if (prevLocation !== newLocation) {
      const payload = {
        detail: { destinationUrl: window.location.href },
      };

      if (debug.adInit) {
        console.log('Adverts: Dispatching SmeAdManager.state.reload', payload);
      }

      window.dispatchEvent(
        new CustomEvent('SmeAdManager.state.reload', payload),
      );
      prevLocation = newLocation;
    }
  });
};
