import useQuery, { QueryHocInterface, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import Loading from '../../../components/Loading';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import { RouteProps } from '../../../library/App';
import __ from '../../../utilities/__';
import {
  currentCompetitionIdSelector,
  currentOrganizationProfileSelector,
} from '../../Competition/selectors';
import { loadClubCompetitions } from '../actions';
import { currentClubCompetitionsSelector } from '../selectors';
import ClubSquadDetail from './detail';
import AdManager from '../../../components/Ads/AdManager';
import getCleandedPathFromLocation from '../../../utilities/adverts/getCleanedPathFromLocation';

const mapStateToProps = (state: RootState) => ({
  isFetchingCompetitions: isCommiting(config.LIST_CLUB_COMPETITIONS)(state),
  competitions: currentClubCompetitionsSelector(state),
  currentCompetitionId: currentCompetitionIdSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
  dispatch: CustomThunkDispatch;
} & QueryHocInterface &
  RouteProps<{ klub: string; tim?: string; sutaz?: string }>;

class ClubSquad extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const promises: any[] = [];
    if (!props.competitions || props.competitions.length === 0) {
      promises.push(dispatch(loadClubCompetitions.action()));
    }
    return Promise.all(promises);
  }

  async componentDidMount() {
    await ClubSquad.getInitialProps(this.props);
  }

  render() {
    const { isFetchingCompetitions, organizationProfile, location } = this.props;
    if (isFetchingCompetitions) {
      return <Loading />;
    }

    const pageName = __('súpiska');
    const title = `${organizationProfile?.title} - ${pageName}`;
    const description = `${organizationProfile?.name}, ${pageName}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, program zápasov, štatistiky, súpisku.',
    )}`;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <AdManager
          site="futbalnet_klub"
          siteId={getCleandedPathFromLocation(location)}
        />
        <ClubSquadDetail />
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  useQuery({
    parameters: {
      sutaz: { type: QueryHocTypes.String, defaultValue: '' },
      partId: { type: QueryHocTypes.String, defaultValue: '' },
    },
  }),
)(ClubSquad);
