import { rem } from 'polished';
import * as React from 'react';
import { Breadcrumb } from '../../library/App';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { HideScrollBarStyles } from '../Layout/ScrollStyles';
import Link from '../Link';

const Wrapper = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  line-height: ${rem(18.2)};
  margin-bottom: ${rem(24)};
`;

const BreadcrumbsTitle = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.separator}};
  padding: ${rem(24)} 0 ${rem(8)} 0;
  font-size: ${rem(14)};
  letter-spacing: ${rem(0.28)};
  color: ${({ theme }) => theme.app.textColor};
  margin-bottom: ${rem(8)};
`;

const MaxWidthWrapper = styled.div`
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  margin: 0 auto;
  width: 100%;
  flex: 1 0 0;
  ${HideScrollBarStyles}
  padding: 0 ${({ theme }) => rem(theme.app.padding)};
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.color.blue};
  font-size: ${rem(11)};
  letter-spacing: ${rem(0.88)};
`;

const LinkCrumb = styled(Link)`
  flex: 0 1 auto;
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
`;

const Separator = styled('span')`
  margin: 0 ${rem(8)};
`;

const BREADCRUMBS_SEPARATOR_CHARACTER = '»';

interface IOwnProps {
  className?: string;
  crumbs: Breadcrumb[];
}

type Props = IOwnProps;

const Breadcrumbs: React.FC<Props> = ({ crumbs, className }) => (
  <Wrapper className={className}>
    <MaxWidthWrapper className="sptn-main-content">
      <BreadcrumbsTitle>{__('Nachádzate sa tu:')}</BreadcrumbsTitle>
      <BreadcrumbsWrapper>
        {crumbs.map((c, idx) => (
          <React.Fragment key={idx}>
            {c.url ? <LinkCrumb to={c.url}>{c.label}</LinkCrumb> : c.label}
            {idx + 1 < crumbs.length && (
              <Separator>{BREADCRUMBS_SEPARATOR_CHARACTER}</Separator>
            )}
          </React.Fragment>
        ))}
      </BreadcrumbsWrapper>
    </MaxWidthWrapper>
  </Wrapper>
);

export default Breadcrumbs;
