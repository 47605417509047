import { IWidgetPhoto } from '@sportnet/content/utilities/library';
import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import getProp from 'sportnet-utilities/lib/getProp';
import { css } from 'styled-components';
import styled from '../../../../theme/styled-components';
import __ from '../../../../utilities/__';

const Wrapper = styled.div`
  margin: ${rem(48)} 0;
  ${mb('s')} {
    margin: ${rem(56)} 0;
  }
`;

export const ImgWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledMediaManagerImage = styled(MediaManagerImage)`
  &,
  & img {
    border-radius: ${rem(8)};
    vertical-align: bottom; /* fix for bottom gap */
  }
  ${({ fullscreen }) =>
    fullscreen &&
    css`
      width: 100%;
      img {
        width: 100%;
      }
    `}
`;

const Desc = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 400;
  color: ${({ theme }) => theme.color.fadedText};
  font-size: ${rem(12)};
  line-height: ${rem(15.6)};
  margin-top: ${rem(16)};
`;

function formatDescription(name: string, source: string, author: string) {
  const result: string[] = [];

  if (name) {
    result.push(name);
  }

  if (source || author) {
    const result1: string[] = [];
    if (source) {
      result1.push(`${__('Zdroj')}: ${source}`);
    }
    if (author) {
      result1.push(`${__('Autor')}: ${author}`);
    }
    result.push(`(${result1.join(', ')})`);
  }

  return result.join(' ');
}

type IData = Omit<IWidgetPhoto, 'type'>;

interface IOwnProps {
  data?: IData;
  renderEmpty?: (type: string) => React.ReactNode;
  className?: string;
  naturalWidth?: number;
  naturalHeight?: number;
  script?: 'resize' | 'media';
  fullscreen?: boolean;
}

type IProps = IOwnProps;

const WidgetPhoto: React.FC<IProps> = (props) => {
  const {
    renderEmpty,
    className,
    script = 'resize',
    naturalWidth = 992,
    naturalHeight = 0,
    children,
  } = props;

  let fullscreen = false;
  let description = '';
  let author = '';
  let source = '';
  let picture: NonNullable<IData['picture']> & { uri?: string } = {};

  const uri = getProp(props, ['data', 'uri'], '') as string;
  if (uri) {
    // old format for photos
    const data = getProp(props, ['data'], {}) as any;

    description = data.description;
    author = data.author;
    source = data.source;
    picture = data;
    fullscreen = !!data.fullscreen;
  } else {
    const data = getProp(props, ['data', 'picture'], {}) as any;

    description = data.description;
    author = data.author;
    source = data.source;
    picture = data;
    fullscreen = !!getProp(props, ['data', 'fullscreen']);
  }

  const desc = formatDescription(description, source, author);

  if (!picture.media_url && !picture.uri && renderEmpty) {
    return <>{renderEmpty('photo')}</>;
  }

  return (
    <Wrapper className={className}>
      <ImgWrapper>
        <StyledMediaManagerImage
          picture={picture}
          naturalWidth={naturalWidth}
          naturalHeight={naturalHeight}
          script={script}
          webp={true}
          fullscreen={fullscreen}
          loading="lazy"
          alt={description ?? ''}
        />
        {children}
      </ImgWrapper>
      {desc && <Desc>{desc}</Desc>}
    </Wrapper>
  );
};

export default React.memo(WidgetPhoto);
