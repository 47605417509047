import { rem } from 'polished';
import React from 'react';
import TooltipLite from 'react-tooltip-lite';
import { createGlobalStyle } from '../../theme/styled-components';

type OwnProps = {
  content: React.ReactNode;
  isOpen?: boolean;
  direction?: 'up' | 'down' | 'right' | 'left';
};

const Styles = createGlobalStyle`
  .react-tooltip-lite {
    border-radius: ${({ theme }) => rem(theme.tooltip.borderRadius)};
    background: ${({ theme }) => theme.tooltip.backgroundColor};
    color: ${({ theme }) => theme.tooltip.textColor};
    font-size: ${rem(12)};
    * {
      color: ${({ theme }) => theme.tooltip.textColor};
    }
  }

  .react-tooltip-lite-arrow {
    border-color: ${({ theme }) => theme.tooltip.backgroundColor};
  }
`;

const Tooltip: React.FC<OwnProps> = ({
  content,
  children,
  isOpen,
  direction,
}) => {
  return (
    <>
      <Styles />
      <TooltipLite
        hoverDelay={0}
        content={content}
        arrowSize={6}
        distance={8}
        padding={`${rem(4)} ${rem(8)}`}
        styles={{ display: 'inline-flex' }}
        isOpen={isOpen}
        direction={direction}
      >
        {children}
      </TooltipLite>
    </>
  );
};

export default Tooltip;
