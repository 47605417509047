import React from 'react';
import styled from 'styled-components';
import Ad from '../Ad';
import { IAdPosition } from '../AdProvider';

const StyledListBodyAd = styled(Ad)`
  > .artemis-promo-labels {
    margin-top: 0;
    line-height: normal;
  }
`;

type IOwnProps = {
  name: IAdPosition;
  className?: string;
};

type IProps = IOwnProps;

const ListBodyAd: React.FC<IProps> = ({ name, className }) => {
  return <StyledListBodyAd name={name} className={className} />;
};

export default React.memo(ListBodyAd);
