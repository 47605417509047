import { ITheme } from '../../../theme/theme';

export const getBorder = (
  theme: ITheme,
  error?: boolean,
  focused?: boolean,
  readOnly?: boolean,
) => {
  if (error) {
    return `1px solid ${theme.color.darkBorder}`;
  }
  if (focused) {
    return `1px solid ${theme.color.darkBorder}`;
  }
  if (readOnly) {
    return `1px solid ${theme.color.darkBorder}`;
  }
  return `1px solid ${theme.color.darkBorder}`;
};

export const getBorderFromProps = (props: {
  theme: ITheme;
  error?: boolean;
  focused?: boolean;
  readOnly?: boolean;
}) => {
  return getBorder(props.theme, props.error, props.focused, props.readOnly);
};
