import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import ThirdLevelMenu, {
  FullWidthPositionedDropdown,
} from '../../components/ThirdLevelMenu';
import { RootState } from '../../configureStore';
import ClubSearch from '../../containers/ClubSearch';
import CompetitionsArchiveDropdown from '../../containers/CompetitionsArchiveDropdown';
import CompetitionsDropdown from '../../containers/CompetitionsDropdown';
import { CompetitionPanelDefault } from '../../containers/MatchesPanel/CompetitionPanel';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';
import { authUserIsEditorSelector } from '../App/selectors';
import { getGossipsBaseUrl } from '../Gossips/utils';

const PositionedClubSearch = styled(ClubSearch)`
  padding: ${rem(24)} ${rem(16)};
`;

type IOwnProps = {};

const mapStateToProps = (state: RootState) => {
  return {
    isEditor: !!authUserIsEditorSelector(state),
  };
};

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FutbalnetHomeHeader: React.FC<IProps> = ({ isEditor }) => {
  const items = [
    {
      id: 'overview',
      label: __('Prehľad'),
      url: '/futbalnet/',
    },
    {
      id: 'competitions',
      label: __('Súťaže'),
      matchUrl: /futbalnet\/z\/[a-z0-9-]+\/s\/\w+\/$/,
      renderDropdown: (dropdownProps) => {
        return (
          <CompetitionsDropdown
            inputSearchPlaceholder={__('Zadajte názov súťaže...')}
            {...dropdownProps}
          />
        );
      },
    },
    {
      id: 'unions',
      label: __('Zväzy'),
      matchUrl: /futbalnet\/z\/[a-z0-9-]+\/$/,
      renderDropdown: (dropdownProps) => {
        return (
          <CompetitionsDropdown
            {...dropdownProps}
            omitSearch
            omitCompetitions
          />
        );
      },
    },
    {
      id: 'clubs',
      label: __('Kluby'),
      matchUrl: /futbalnet\/k\/[a-z0-9-]+\/$/,
      renderDropdown: (dropdownProps) => (
        <FullWidthPositionedDropdown {...dropdownProps}>
          <PositionedClubSearch />
        </FullWidthPositionedDropdown>
      ),
    },
    {
      id: 'transfers',
      label: __('Prestupy'),
      url: '/futbalnet/prestupy-hostovania/',
    },
    {
      id: 'gossips',
      label: __('Pikošky'),
      url: getGossipsBaseUrl({ trailingSlash: true }),
    },
    {
      id: 'members',
      label: __('Členovia'),
      url: '/futbalnet/clenovia/',
    },

    {
      id: 'archive',
      label: __('Archív'),
      renderDropdown: (dropdownProps) => {
        return <CompetitionsArchiveDropdown {...dropdownProps} />;
      },
    },
  ];

  return (
    <>
      <ThirdLevelMenu menuId="futbalnetHomeHeader" items={items} />
      <CompetitionPanelDefault />
    </>
  );
};

export default React.memo(connect(mapStateToProps)(FutbalnetHomeHeader));
