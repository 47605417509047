import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import Icon from '../../components/Icon';
import LoadMorePanelTable from '../../components/LoadMorePanel/LoadMorePanelTable';
import { RootState } from '../../configureStore';
import { favoriteCompetitionsSelector } from '../../pages/FutbalnetApp/selectors';
import styled, { css } from '../../theme/styled-components';
import __ from '../../utilities/__';
import Competition from './Competition';

const Wrapper = styled.div`
  padding: ${rem(24)};
  background: #fff;
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  border-radius: ${rem(8)};
  color: ${({ theme }) => theme.app.textColor};
`;

const PaddedIcon = styled(Icon)`
  margin-right: ${rem(8)};
`;

const Header = styled.div`
  padding-bottom: ${rem(16)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  margin-bottom: ${rem(24)};

  font-family: ${({ theme }) => theme.primaryFont.family };
  font-weight: 600;
  font-size: ${rem(14)};
  line-height: ${rem(16.8)};

  display: flex;
  align-items: center;
`;

interface LoadMorePanelWrapperProps {
  expanded: boolean;
}

const LoadMorePanelWrapper = styled.div<LoadMorePanelWrapperProps>`
  ${({ expanded }) =>
    expanded &&
    css`
      display: none;
    `}
  ${mb('s')} {
    display: none;
  }
  > * {
    margin-top: ${rem(24)};
  }
`;

const Grid = styled.ul<{ expanded: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-gap: ${rem(24)};
  gap: ${rem(24)};
  grid-template-columns: 1fr;
  ${mb('s')} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ expanded }) =>
    !expanded &&
    css`
      > *:nth-child(n + 5) {
        display: none;
      }
      ${mb('s')} {
        > *:nth-child(n + 5) {
          display: flex;
        }
      }
    `}
`;

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  competitions: favoriteCompetitionsSelector(state),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const FavoriteCompetitionsBlock: React.FC<IProps> = ({ competitions }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Wrapper>
      <Header>
        <PaddedIcon name="star" size={16} />
        {__('Obľúbené súťaže')}
      </Header>
      <Grid expanded={expanded}>
        {competitions.map((competition) => (
          <Competition key={competition._id} competition={competition} />
        ))}
      </Grid>
      <LoadMorePanelWrapper expanded={expanded}>
        <LoadMorePanelTable
          onLoadMore={() => {
            setExpanded(true);
          }}
        />
      </LoadMorePanelWrapper>
    </Wrapper>
  );
};

export default connect(mapStateToProps)(FavoriteCompetitionsBlock);
