import React from 'react';

export default (size: string, color: string) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M3,20c-0.1,0-0.3,0-0.4-0.1c-0.4-0.2-0.7-0.7-0.6-1.1C3.3,12.4,7.2,8.3,13,7.2V4c0-0.4,0.2-0.8,0.6-0.9
C14,2.9,14.4,3,14.7,3.3l7,7c0.4,0.4,0.4,1,0,1.4l-7,7c-0.3,0.3-0.7,0.4-1.1,0.2C13.2,18.8,13,18.4,13,18v-3.1
c-4.2,0.2-7,1.7-9.2,4.7C3.6,19.9,3.3,20,3,20z M14,12.9c0.6,0,1,0.4,1,1v1.7l4.6-4.6L15,6.4V8c0,0.5-0.4,0.9-0.9,1
c-4.8,0.7-7.4,3.4-8.9,6.3C7.6,13.7,10.4,12.9,14,12.9z M14,8L14,8L14,8z"
      />
    </svg>
  );
};
