import { AsyncReturnType } from '../library/App';
import { IMemberDelegateOProfile } from '../library/Competitions';
import FutbalnetApi from '../pages/Member/api';

/**
 * ...len potrebne fieldy pre detail delegata stretnuai
 */
const reduceDelegateMProfile = (
  delegateOProfile: AsyncReturnType<typeof FutbalnetApi['getDelegateO']>,
): IMemberDelegateOProfile => {
  const reduced: IMemberDelegateOProfile = {
    profileStatus: delegateOProfile.profileStatus,
    registrationDate: delegateOProfile.registration_date,
  };
  return reduced;
};

export default reduceDelegateMProfile;
