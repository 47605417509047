import React from 'react';
import { IAdPosition } from '../../components/Ads/AdProvider';
import AdContext from '../../contexts/AdContext';
import scribe from '../../utilities/scribe';

const useAdScriber = (name: IAdPosition, timeout = 0) => {
  const { adverts } = React.useContext(AdContext);

  React.useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      scribe(`.${name}`, adverts[name] || '<!-- EMPTY -->');
    }, timeout);
    return () => {
      window.clearTimeout(timeoutID);
    };
  }, [adverts, name, timeout]);
};

export default useAdScriber;
