import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20,22H4c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h5c0.3,0,0.6,0.2,0.8,0.4L11.5,5H20c1.7,0,3,1.3,3,3v11
    C23,20.7,21.7,22,20,22z M4,4C3.4,4,3,4.4,3,5v14c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1h-9c-0.3,0-0.6-0.2-0.8-0.4
    L8.5,4H4z"
    />
  </svg>
);
