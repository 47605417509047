import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const LinkStyles = css`
  color: ${({ theme }) => theme.color.linkColor};
  text-decoration: none;
  :hover,
  :active,
  :focus {
    color: ${({ theme }) => theme.color.linkColor};
    text-decoration: underline;
  }
`;

export const BoxStyles = css`
  border: ${({ theme }) => theme.border.normalDark};
  box-shadow: ${({ theme }) => theme.shadow.small};
  border-radius: ${({ theme }) => rem(theme.border.borderRadius)};
  background-color: ${({ theme }) => theme.color.white};
`;

/**
 * Styly pre sivy box pre komponenty, ktore "plavaju" nad obsahom. Napr.: otvoreny kalendar, select, ...
 */
export const FloatingBoxStyles = css`
  border: ${({ theme }) => theme.border.normalDark};
  box-shadow: ${({ theme }) => theme.shadow.large};
  border-radius: ${({ theme }) => rem(theme.border.borderRadius)};
  background-color: ${({ theme }) => theme.color.white};
`;

export const HideScrollBarStyles = css`
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox

  ::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const BaseSectionTitleStyles = css`
  border: none;
  outline: none;
  background: none;
  word-break: break-word;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.primaryFont.weight.semiBold};
  color: ${({ theme }) => theme.color.primaryFontColor};
`;

export const SectionH1Styles = css`
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  ${mb('s')} {
    font-size: ${rem(24)};
    line-height: ${rem(28.8)};
  }
`;

export const ContentH2Styles = css`
  font-size: ${rem(20)};
  line-height: ${rem(24)};
  ${mb('s')} {
    font-size: ${rem(24)};
    line-height: ${rem(28.8)};
  }
`;

export const SectionTitleWrapper = styled.div<{
  hasIcon: boolean;
  noBottomBorder: boolean;
}>`
  display: flex;
  margin-bottom: ${rem(24)};
  padding: ${({ hasIcon = false }) => (hasIcon ? `0 0 ${rem(16)} 0` : 0)};
  border-bottom: ${({ theme, noBottomBorder }) =>
    noBottomBorder ? 'none' : theme.border.normalDark};
  align-items: center;
`;

export const ShowMoreLinkStyles = css`
  display: flex;
  font-size: ${rem(11)};
  line-height: ${rem(16.8)};
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  text-transform: uppercase;
  text-decoration: underline;
  letter-spacing: ${rem(0.88)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.primaryFontColor};
  :active,
  :hover,
  :focus,
  :visited,
  :focus-visible,
  :focus-within,
  :target {
    color: ${({ theme }) => theme.color.primaryFontColor};
    border: none;
    outline: none;
  }
  .icon {
    margin-left: ${rem(8)};
  }
`;

export const HiderStyles = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
`;
