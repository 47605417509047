import styled from 'styled-components';
import { FeedItemWithCustomSmarttags } from '../features/getFeedItemsWithBanners';
import { rem } from 'polished';
import { formatFeedItemDate } from '../utils';
import GossipAuthors from './GossipAuthors';
import React from 'react';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const TopBarWrapper = styled.div`
  line-height: 1.3;
  font-size: ${rem(12)};
  ${mb('s')} {
    font-size: ${rem(14)};
  }
`;

const DatePublished = styled.span`
  color: ${({ theme }) => theme.color.primary};
  font-weight: 600;
`;

const Authors = styled.span`
  color: ${({ theme }) => theme.color.secondaryFontColor};
  font-weight: 400;
`;

interface OwnProps {
  className?: string;
  date: FeedItemWithCustomSmarttags['date'];
  authors: Required<FeedItemWithCustomSmarttags>['authors'];
}

const GossipDetailTopBar: React.FC<OwnProps> = ({
  className,
  date,
  authors,
}) => {
  return (
    <TopBarWrapper className={className}>
      <DatePublished>{formatFeedItemDate(new Date(date))}</DatePublished>
      {authors.length > 0 && (
        <Authors>
          {' | '}
          <GossipAuthors authors={authors} />
        </Authors>
      )}
    </TopBarWrapper>
  );
};

export default GossipDetailTopBar;
