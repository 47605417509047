import React from 'react';
import styled from '../../../theme/styled-components';
import Input from '@sportnet/ui/lib/Input';
import Label from '@sportnet/ui/lib/Label/Label';
import FormGroup from '../../../components/FormGroup';
import __ from '../../../utilities/__';
import { rem } from 'polished';

const Wrapper = styled.div`
  margin-top: ${rem(16)};
`;

export const getEmbedUrl = (videoId: string) =>
  `https://video.sportnet.online/embed/${videoId}`;

export const getEmbedCode = (videoId: string) =>
  `<iframe src="${getEmbedUrl(
    videoId,
  )}" allowfullscreen frameborder="0"></frame>`;

export const getVideoUrl = (videoId: string) =>
  `https://video.sportnet.online/mp4/${videoId}`;

const SharePanel = ({ videoId }) => {
  const handleCopyTextToClipboardClick = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      /* */
    }
  };

  return (
    <Wrapper>
      <FormGroup>
        <Label>{__('Download')}</Label>
        <Input value={getVideoUrl(videoId)} disabled>
          <input />
          <Input.Button
            primary
            onClick={() => {
              window.open(getVideoUrl(videoId), '_blank')?.focus();
            }}
            title="Otvoriť v novom okne"
            icon="file-download"
          />
          <Input.Button
            title="Skopírovať do schránky"
            onClick={() => {
              handleCopyTextToClipboardClick(getVideoUrl(videoId));
            }}
            icon={'files'}
            type="button"
          />
        </Input>
      </FormGroup>

      <FormGroup>
        <Label>{__('Embed')}</Label>
        <Input value={getEmbedUrl(videoId)} disabled>
          <input />
          <Input.Button
            title="Skopírovať do schránky"
            onClick={() => {
              handleCopyTextToClipboardClick(getEmbedUrl(videoId));
            }}
            icon={'files'}
          />
        </Input>
      </FormGroup>
    </Wrapper>
  );
};

export default SharePanel;
