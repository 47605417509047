import styled from 'styled-components';
import { rem } from 'polished';
import React from 'react';
import GossipShareDropdown from './GossipShareDropdown';
import GossipShareCopyButton from './GossipShareCopyButton';
import GossipDiscussionCount from './GossipDiscussionCount';

const Bottom = styled.div`
  margin-top: ${rem(24)};
  display: flex;
  align-items: center;
  gap: ${rem(24)};
`;

interface OwnProps {
  detailUrl: string;
  discussionIconUrl?: string;
  discussionPostCount: number;
  isDetail?: boolean;
}

const GossipBottomItems: React.FC<OwnProps> = ({
  detailUrl,
  discussionPostCount,
  discussionIconUrl,
  isDetail,
}) => {
  return (
    <Bottom>
      <GossipDiscussionCount
        discussionPostCount={discussionPostCount}
        discussionIconUrl={discussionIconUrl}
      />
      {isDetail ? (
        <GossipShareDropdown detailUrl={detailUrl} />
      ) : (
        <GossipShareCopyButton detailUrl={detailUrl} />
      )}
    </Bottom>
  );
};

export default GossipBottomItems;
