import MediaManagerImage from '@sportnet/ui/lib/MediaManagerImage';
import PictureFrame from '@sportnet/ui/lib/PictureFrame';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import Icon from '../../../components/Icon';
import { IArticle } from '../../../library/App';
import styled, { css } from '../../../theme/styled-components';
import ArticleIcon from '../../Articles/ArticleIcon';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ArticlePictureFrame = styled(PictureFrame)`
  flex-shrink: 0;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CoverImage = styled(MediaManagerImage)`
  &,
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background: #fff;
    overflow: hidden;
    border-radius: ${rem(8)};
  }
`;

const PremiumBg = styled(Icon)<{ responsive: boolean }>`
  position: absolute;
  left: -0.6px;
  bottom: ${rem(16)};

  ${({ responsive }) =>
    responsive &&
    css`
      ${mb('s')} {
        top: ${rem(32)};
        right: -0.6px;
        left: auto;
        bottom: auto;
        transform: rotate(180deg);
      }
    `}
`;

const PremiumIcon = styled(Icon)<{ responsive: boolean }>`
  position: absolute;
  bottom: ${rem(48)};
  left: ${rem(-9)};

  ${({ responsive }) =>
    responsive &&
    css`
      ${mb('s')} {
        top: ${rem(64)};
        right: ${rem(-9)};
        left: auto;
        bottom: auto;
      }
    `}
`;

type IOwnProps = {
  article: IArticle;
  naturalHeight: number;
  naturalWidth: number;
  responsive?: boolean;
  className?: string;
};

type IProps = IOwnProps;

const ArticleImage: React.FC<IProps> = ({
  article,
  naturalWidth,
  naturalHeight,
  responsive = false,
  className,
}) => {
  return (
    <Wrapper>
      <ArticlePictureFrame
        width={naturalWidth}
        height={naturalHeight}
        className={className}
      >
        <CoverImage
          picture={article.picture || {}}
          script="media"
          naturalWidth={naturalWidth}
          naturalHeight={naturalHeight}
          webp={true}
          loading="lazy"
        />
        {article.is_private && naturalHeight > 200 && (
          <>
            <PremiumBg
              name="premium-shape"
              size={76}
              color="#fff"
              responsive={responsive}
            />
            <PremiumIcon name="premium" size={12} responsive={responsive} />
          </>
        )}
        <ArticleIcon article={article} />
      </ArticlePictureFrame>
    </Wrapper>
  );
};

export default React.memo(ArticleImage);
