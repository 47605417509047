import * as React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { IThemeProps } from '../../../theme/styled-components';
import Icon, { IIconName } from '../../Icon';

interface OwnProps {
  onDispose?: () => void;
  toggleCollapse?: (status: boolean) => void;
  isCollapsed?: boolean;
  collapsible?: boolean;
  withSeparator?: boolean;
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  subtitle?: string | JSX.Element;
  noBottomGutter?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  icon?: IIconName;
}

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div``;

const Text = styled('div')<{ disabled?: boolean }>`
  width: 100%;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.color.fadedText};
    `};
`;

const ClickableText = styled(Text)`
  width: 100%;
  cursor: pointer;
`;

const Actions = styled('div')`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled('button')<{ disabled?: boolean }>`
  /* resets button styles */
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;
  text-align: inherit;

  cursor: pointer;
  transition: 0.2s linear all;

  display: flex;

  align-items: center;
  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.color.fadedText};
    `};
`;

const StyleIcon = styled(Icon)<{ isRotate?: boolean }>`
  width: 100%;
  height: 100%;
  align-items: center;
  ${({ isRotate }) =>
    isRotate &&
    css`
      transform: rotate(180deg);
    `};
`;

const defaultProps = {
  size: 'm',
  noBottomGutter: false,
};

type Props = OwnProps & IThemeProps;

class SegmentHeader extends React.PureComponent<Props> {
  static defaultProps = defaultProps;

  sizeToPx(size: string) {
    const sizeMapping = {
      xs: 16,
      s: 18,
      m: 20,
      l: 22,
      xl: 24,
    };
    return size in sizeMapping
      ? sizeMapping[size as keyof typeof sizeMapping]
      : 16;
  }

  collapse = () => {
    const { toggleCollapse, isCollapsed, disabled } = this.props;
    if (!disabled && toggleCollapse) {
      toggleCollapse(!isCollapsed);
    }
  };

  renderActions = () => {
    const { collapsible, onDispose, size, disabled, isCollapsed } = this.props;
    const actionIconSize =
      typeof size === 'string' ? this.sizeToPx(size!) : size;

    const actions: any[] = [];
    if (collapsible) {
      actions.push(
        <IconWrapper
          key="collapse"
          onClick={this.collapse}
          type="button"
          disabled={disabled}
          data-testid="Segment-IconArrowDown"
        >
          {disabled ? (
            <StyleIcon
              name="chevron-table"
              size={actionIconSize}
              isRotate={isCollapsed}
              color="#e0e0eb"
            />
          ) : (
            <StyleIcon
              name="chevron-table"
              size={actionIconSize}
              isRotate={isCollapsed}
            />
          )}
        </IconWrapper>,
      );
    }
    if (onDispose) {
      actions.push(
        <IconWrapper
          key="close"
          onClick={onDispose}
          disabled={disabled}
          data-testid="Segment-IconClose"
        >
          {disabled ? (
            <Icon name="close" size={actionIconSize} color="#e0e0eb" />
          ) : (
            <Icon name="close" size={actionIconSize} />
          )}
        </IconWrapper>,
      );
    }
    return actions;
  };
  render() {
    const { children, collapsible, disabled } = this.props;
    return (
      <Header>
        <Wrapper>
          {collapsible ? (
            <ClickableText disabled={disabled} onClick={this.collapse}>
              {children}
            </ClickableText>
          ) : (
            <Text disabled={disabled}>{children}</Text>
          )}
          <Actions>{this.renderActions()}</Actions>
        </Wrapper>
      </Header>
    );
  }
}

export default withTheme(SegmentHeader);
