import { isAfter, isSameDay } from 'date-fns';
import { rem } from 'polished';
import React from 'react';
import Icon from '../../../components/Icon';
import Link from '../../../components/Link';
import { IMatch } from '../../../library/Competitions';
import styled from '../../../theme/styled-components';
import formatDate from '../../../utilities/formatDate';
import getMatchUrl from '../../../utilities/match/getMatchUrl';
import __ from '../../../utilities/__';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 600;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
`;

const Wrapper = styled(Link)`
  height: 100%;
  position: relative;
  display: flex;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #fff;
  transition: background-color 250ms ease-in-out;
  &:hover {
    ${Overlay} {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

const PrimaryInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  max-width: 100%;
  align-intems: center;
  justify-content: center;
`;

const EventInfoWrapper = styled.div`
  text-align: center;
`;

const TeamName = styled.span`
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  font-weight: 600;
`;

const LiveIndicator = styled.div`
  margin-left: ${rem(8)};
  color: ${({ theme }) => theme.color.danger};
  :before {
    content: '';
    width: ${rem(4)};
    height: ${rem(4)};
    background: ${({ theme }) => theme.color.danger};
    display: inline-block;
    border-radius: 100%;
    margin-right: ${rem(4)};
    margin-bottom: ${rem(2)};
    margin-top: auto;
  }
`;

type IOwnProps = {
  match: IMatch;
};

type IProps = IOwnProps;

const Match: React.FC<IProps> = ({ match }) => {
  const isLive =
    !match.closed &&
    match.startDate &&
    isSameDay(new Date(), new Date(match.startDate)) &&
    isAfter(new Date(), new Date(match.startDate));

  const roundName = match.round?.name.startsWith('Matchday')
    ? match.competitionPart.name
    : match.round?.name;

  return (
    <Wrapper to={getMatchUrl(match)}>
      <PrimaryInfo>
        <EventInfoWrapper>
          <TeamName>{roundName}</TeamName>&nbsp;-&nbsp;
          {formatDate(new Date(match.startDate), 'dd.MM.yyyy')}
        </EventInfoWrapper>
        {isLive && <LiveIndicator>{__('Live')}</LiveIndicator>}
      </PrimaryInfo>
      <Overlay>
        {isLive ? __('Minúta po minúte') : __('Program a výsledky')}
        <Icon style={{ marginLeft: rem(4) }} name="arrowMore" size={14} />
      </Overlay>
    </Wrapper>
  );
};

export default Match;
