import { SectionId, SectionNode, SectionTreeType } from '../library/App';

const findPath = (
  sections: SectionTreeType,
  callback: (section: SectionNode) => boolean,
  path: SectionNode[] = [],
): SectionNode[] | null => {
  for (const { sections: subSections, ...section } of sections) {
    const newPath = [...path, { ...section, sections: subSections }];
    if (callback({ ...section, sections: subSections })) {
      return newPath;
    }
    if (subSections) {
      const subPath = findPath(subSections, callback, newPath);
      if (subPath) {
        return subPath;
      }
    }
  }

  return null;
};

export default (
  sectionIdOrUniqId: string | SectionId,
  tree: SectionTreeType,
): SectionNode[] | null => {
  const sectionIdOrUniqIdNumber = Number(sectionIdOrUniqId);
  return findPath(tree, (section) => {
    return (
      section._id === sectionIdOrUniqIdNumber ||
      section.uniqid === sectionIdOrUniqId
    );
  });
};
