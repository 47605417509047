import {
  formatParameters,
  getListParameters,
  getListResults,
  listDomain,
} from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { RootState } from '../../configureStore';
import findUnionInTree from '../../utilities/findUnionInTree';
import getCompetitionsCategoryPanes from '../../utilities/getCompetitionsCategoryPanes';
import { entitiesSelector } from '../App/selectors';
import { currentCompetitionAppSpaceSelector } from '../Competition/selectors';
import { competitionsSelectorByUnion, unionsStructureSelector } from '../FutbalnetApp/selectors';
import { ICompetition } from './../../library/Competitions';

export const unionCompetitionsListSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_UNION_COMPETITIONS),
  (entities, ids) => at(entities.competitions, ids || []),
);

const unionMatchesIdsSelector = createSelector(
  listDomain(config.LIST_UNION_MATCHES),
  getListParameters(config.LIST_UNION_MATCHES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_UNION_MATCHES_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const unionMatchesListSelector = createSelector(
  entitiesSelector,
  unionMatchesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

export const lastRoundMatchesSelector = createSelector(
  (_: RootState, competitionId: string) => competitionId,
  entitiesSelector,
  (competitionId, entities) =>
    entities.lastRoundMatches[competitionId]
      ? entities.lastRoundMatches[competitionId].matches
      : [],
);

export const unionCompetitionsPanesSelector = createSelector(
  competitionsSelectorByUnion,
  unionsStructureSelector,
  currentCompetitionAppSpaceSelector,
  (competitionsList, unionsStructure, unionId) => {
    const competitions = competitionsList as ICompetition[];
    const unionNode = findUnionInTree(unionId, unionsStructure);

    const panes = getCompetitionsCategoryPanes(
      competitions,
      unionNode ? [unionNode] : [],
    );

    // expand 1 level
    return panes.map((pane) => ({
      ...pane,
      seasonName: getProp(competitions || [], [0, 'season', 'name']),
      children: pane.children
        ? pane.children.reduce(
            (acc: any[], p) => acc.concat(p.children || []),
            [],
          )
        : void 0,
    }));
  },
);
