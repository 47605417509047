import React from 'react';
import Icon, { IIconName } from '../../../components/Icon';
import styled from 'styled-components';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';

const Td = styled.td`
  padding: 0.5cm 0 0.25cm 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  .icon {
    margin-right: 0.35cm;
  }
`;

interface OwnProps {
  icon: IIconName;
  title: string;
}

const MatchesDetailPrintSectionTitle: React.FC<OwnProps> = ({
  icon,
  title,
}) => {
  return (
    <Tr>
      <Td colSpan={6}>
        <ContentWrapper>
          <Icon name={icon} size={24} />
          <h2>{title}</h2>
        </ContentWrapper>
      </Td>
    </Tr>
  );
};

export default MatchesDetailPrintSectionTitle;
