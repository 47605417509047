import { rem } from 'polished';
import React from 'react';
import styled from '../../../theme/styled-components';
import { ContextMarginBottomStyles } from '../../Layout';
import Ad from '../Ad';

const StyledAd = styled(Ad)`
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  ${ContextMarginBottomStyles}
`;

type IOwnProps = {
  className?: string;
};

type IProps = IOwnProps;

const MegaboardAd: React.FC<IProps> = ({ className }) => {
  return <StyledAd name="megaboard" className={className} />;
};

export default React.memo(MegaboardAd);
