import * as React from 'react';
import DefaultContentNotFound from '../../components/ContentNotFound';
import EntryAnimation from '../../components/EntryAnimation';

type Props = {
  title: string;
  actionLabel?: string;
  onClickAction?: () => void;
};

const ContentNotFound: React.FC<Props> = ({
  title,
  actionLabel,
  onClickAction,
}) => {
  return (
    <EntryAnimation key={'content-not-found'}>
      <DefaultContentNotFound
        title={title}
        actionLabel={actionLabel}
        onClickAction={onClickAction}
      />
    </EntryAnimation>
  );
};

export default ContentNotFound;
