import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import CompetittionsApi from '../../../../api/CompetitionsApi';
import { IMatch } from '../../../../library/Competitions';
import groupMatchesByRound from '../../../../utilities/print/groupMatchesByRound';

// iterator cez strankovany zoznam eventov
const getPaginatedMatches = async function* makeMatchesIterator(
  appSpace: string,
  competitionId: string,
  competitionPartId: string,
  limit: number = 100,
) {
  let offset: number | null = 0;
  let totalCount = 0;
  while (offset === 0 || offset !== null) {
    const { matches, nextOffset } =
      await CompetittionsApi.getCompetitionPartMatches(
        appSpace,
        competitionId,
        competitionPartId,
        { limit, offset },
      );
    totalCount += matches.length;
    offset = nextOffset || null;
    yield {
      matches,
      totalCount,
    } as { matches: IMatch[]; totalCount: number };
  }
};

interface LoadAllCompetitionMatchesParams {
  appSpace: string;
  competitionId: string;
  competitionPartId: string;
}

const loadAllCompetitionMatches = createQueryFn(
  QueryNameSpace.allCompetitionMatches,
  async ({
    queryKey,
  }: QueryFunctionContext<
    [string] | [string, LoadAllCompetitionMatchesParams]
  >) => {
    const [, parameters] = queryKey;

    if (!parameters) {
      throw new Error(
        'Must provide required parameters [appSpace, competitionId, competitionPartId]!',
      );
    }

    const { appSpace, competitionId, competitionPartId } = parameters;

    if (!(appSpace && competitionId && competitionPartId)) {
      throw new Error(
        'Must provide required parameters [appSpace, competitionId, competitionPartId]!',
      );
    }

    const matches: IMatch[] = [];
    for await (const page of getPaginatedMatches(
      appSpace,
      competitionId,
      competitionPartId,
    )) {
      matches.push(...page.matches);
    }

    return { matchesByRound: groupMatchesByRound(matches) };
  },
);

export default loadAllCompetitionMatches;
