import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import registerAdReloader from './components/Ads/AdProvider/registerAdReloader';
import configureStore from './configureStore';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';
import BrickSpaBlocks from './containers/BrickSpaBlocks';

let state = {};
let dehydratedState = {};
if (typeof window !== 'undefined') {
  state = (window as any).__REDUX_STATE__;
  dehydratedState = (window as any).__REACT_QUERY_STATE__;
}
const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://ebd2c82280dc4cad818bf1009698c7f3@sentry.sportnet.online/30',
//   });
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 min,
      cacheTime: 5 * 60 * 1000, // 5 min,
      retry: false,
    },
  },
});

registerAdReloader(browserHistory);

ReactDOM.hydrate(
  <HelmetProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrickSpaBlocks />
        <Hydrate state={dehydratedState}>
          <ThemeProvider theme={theme}>
            <Routes history={browserHistory} />
          </ThemeProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
