import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import { MAX_SIZE } from '@sportnet/ui/lib/MediaManagerPhotoEditor/utils';
import * as React from 'react';
import { IWidgetMatchOverview } from '@sportnet/content/utilities/library';
import { IMatchSettings } from '@sportnet/content/library/Competitions';
import { withTheme } from '@sportnet/content/theme/styled-components';
import { ITheme } from '@sportnet/content/theme/theme';
import Match from './Match';

type IData = Omit<IWidgetMatchOverview, 'type'>;
interface IOwnProps {
  theme: ITheme;
  data: IData;
  renderEmpty?: (type: string) => React.ReactNode;
}

const C: React.FC<IOwnProps> = ({ theme, data, renderEmpty }) => {
  if (!data.matchId && renderEmpty) {
    return <>{renderEmpty('matchOverview')}</>;
  }
  if (data.data) {
    const backgroundImage = data.backgroundImage
      ? getMediaManagerUrl(data.backgroundImage, MAX_SIZE, MAX_SIZE, 'media')
      : undefined;
    return (
      <div className="content-widget-matchOverview">
        <Match
          theme={theme}
          match={data.data.match}
          lastIndex
          sportSectorsPhases={data.data.sportSectorPhases}
          fullWidth
          settings={data.data.match.settings as IMatchSettings}
          verbose={true}
          backgroundImage={backgroundImage}
        />
      </div>
    );
  }
  return null;
};

export default withTheme(C);
