import React from 'react';
import { useAsyncData } from 'ssr-service';
import { prefetchInfiniteQ, useInfiniteQ } from '../../query';
import { useQueryClient } from '@tanstack/react-query';
import isBrowser from '../../utilities/isBrowser';
import ArticleList from '../InfiniteArticleList/v2/ArticleList';
import getAllItemsFromInfiniteQueryPages from '../../utilities/react-query/getAllItemsFromInfiniteQueryPages';
import { Articles_List, Article } from '../../api/CmsApi';
import { getPublicListingArticles } from '../../query-fns/getPublicListingArticles';
import { InfiniteArticleListOwnPropsBase } from '../InfiniteArticleList/v2';

const appSpace = process.env.REACT_APP_APPSPACE ?? '';

interface OwnProps extends InfiniteArticleListOwnPropsBase {
  listingId: string;
}

const InfiniteListingArticleList: React.FC<OwnProps> = ({
  className,
  listingId,
  initialLimit = 10,
  initialOffset,
  secondaryLimit = 20,
  nextOffset,
  querySettings,
  onLoadMore,
}) => {
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
  } = useInfiniteQ(
    getPublicListingArticles,
    { appSpace, limit: initialLimit, listingId, offset: initialOffset },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) =>
        lastPage.nextOffset !== null
          ? {
              nextOffset: lastPage.nextOffset,
              limit: secondaryLimit,
            }
          : undefined,
      enabled: !!appSpace && !!listingId && !!initialLimit && typeof initialOffset !== 'undefined',
      ...querySettings,
    },
  );

  // ssr
  useAsyncData(async () => {
    if (!isBrowser() && typeof initialOffset !== 'undefined') {
      await prefetchInfiniteQ(queryClient, getPublicListingArticles, {
        appSpace,
        limit: initialLimit,
        listingId,
        offset: initialOffset
      });
    }
  }, [appSpace, queryClient, isBrowser, initialLimit, listingId]);

  React.useEffect(() => {
    if (nextOffset && nextOffset > 0 && initialOffset !== nextOffset) {
      fetchNextPage();
    }
  }, [nextOffset, fetchNextPage, initialOffset]);

  // vsetky clanky zo vsetkych podstranok (pages)
  const articles = React.useMemo(() => {
    return data
      ? getAllItemsFromInfiniteQueryPages<Articles_List, Article>(
          data,
          (page) => page.articles,
        )
      : ([] as Articles_List['articles']);
  }, [data]);

  return (
    <ArticleList
      className={className}
      articles={articles}
      isLoading={isLoading}
      isError={isError}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      initialLimit={initialLimit}
      onLoadMore={onLoadMore}
    />
  );
};

export default InfiniteListingArticleList;
