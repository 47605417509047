import React from 'react';
import styled, { IThemeProps, withTheme } from '../../theme/styled-components';
import __ from '../../utilities/__';
import Icon from '../Icon';
import { rem } from 'polished';
import { Link, withRouter } from 'react-router';
import { deserializeQuery, serializeQuery } from '@sportnet/query-hoc/useQuery';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AAsButton = styled(Link)`
  margin: 0;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ disabled, theme }) =>
    disabled ? theme.color.separator : '#fff'};
  display: inline-flex;
  width: auto;
  align-items: center;
  justify-content: center;
  outline: 0;
  color: ${({ theme }) => theme.app.textColor};
  vertical-align: baseline;
  text-transform: none;
  text-shadow: none;
  line-height: 1em;
  font-style: normal;
  text-decoration: none;
  border-radius: ${rem(4)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  box-shadow: 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.12);
  font-size: ${rem(13)};
  font-weight: 400;
  min-height: ${rem(32)};
  padding: ${rem(7)} ${rem(14)};
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const A = withRouter(
  ({ children, page, location: { search, pathname, hash }, ...props }) => {
    const queryString = deserializeQuery(search);
    if (page > 1) {
      queryString.page = page;
    } else {
      delete queryString.page;
    }
    const args = serializeQuery(queryString);
    return (
      <AAsButton to={`${pathname}${args !== '?' ? args : ''}${hash}`} {...props}>
        {children}
      </AAsButton>
    );
  },
) as any;

type IOwnProps = {
  loading: boolean;
  page: number;
  isPageLast: boolean;
  className?: string;
};

type IProps = IOwnProps & IThemeProps;

const NextPrevPaginator: React.FC<IProps> = ({
  loading,
  page,
  isPageLast,
  className,
  theme,
}) => {
  // paginator is not needed (there is only 1 page and it is last page)
  if (page <= 1 && isPageLast) {
    return null;
  }

  return (
    <Wrapper className={className}>
      {page > 1 ? (
        <A page={page - 1} disabled={loading}>
          <Icon name="arrow-left" size={12} color={theme.color.primary} />
          {__('Predchádzajúce')}
        </A>
      ) : (
        <div />
      )}
      {!isPageLast && (
        <A page={page + 1} disabled={loading}>
          {__('Nasledujúce')}
          <Icon name="arrow-right" size={12} color={theme.color.primary} />
        </A>
      )}
    </Wrapper>
  );
};

export default withTheme(NextPrevPaginator);
