import React from 'react';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import styled from 'styled-components';
import { rem } from 'polished';
import __ from '../../../utilities/__';

const Th = styled.th<{ center?: boolean }>`
  padding: ${rem(8)};
  text-align: ${({ center = false}) => center ? 'center' : 'left' };
`;

interface OwnProps {
  sportSector?: string;
  isStatsIssf: boolean;
}

const RenderCompetitionStandingTableHeader: React.FC<OwnProps> = ({
  sportSector,
  isStatsIssf,
}) => {
  return sportSector === 'beachfutbal' ? (
    <Tr>
      <Th>#</Th>
      {/* Logo klubu */}
      <Th></Th>
      <Th>{__('Klub')}</Th>
      {/* Zápasy */}
      <Th>{__('Z')}</Th>
      {/* Výhry */}
      <Th>{__('V')}</Th>
      {/* Výhry po preddĺžení */}
      <Th>{__('Vp')}</Th>
      {/* Výhry po penaltách */}
      <Th>{__('Vpen')}</Th>
      {/* Body */}
      <Th>{__('Skóre')}</Th>
      <Th>{__('B')}</Th>
      {/* Forma */}
      {/* <Th>{__('Forma')}</Th> */}
    </Tr>
  ) : (
    <Tr>
      <Th>#</Th>
      {/* Logo klubu */}
      <Th></Th>
      <Th>{__('Klub')}</Th>
      {/* Zápasy */}
      <Th>{__('Z')}</Th>
      {/* Výhry */}
      <Th>{__('V')}</Th>
      {/* Remízy */}
      <Th>{__('R')}</Th>
      {/* Prehry */}
      <Th>{__('P')}</Th>
      <Th>{__('Skóre')}</Th>
      {/* Body */}
      <Th>{__('B')}</Th>
      {isStatsIssf && (
        <>
          {/* Koeficient pre mládež */}
          <Th>{__('K')}</Th>
          {/* Plus mínus body */}
          <Th>{__('+/-')}</Th>
          {/* Fair Play */}
          <Th>{__('FP')}</Th>
        </>
      )}
      {/*<Th>{__('Forma')}</Th>*/}
    </Tr>
  );
};

export default RenderCompetitionStandingTableHeader;
