import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20,6h-3V5c0-1.7-1.3-3-3-3h-4C8.3,2,7,3.3,7,5v1H4C2.3,6,1,7.3,1,9v10c0,1.7,1.3,3,3,3h16c1.7,0,3-1.3,3-3V9
    C23,7.3,21.7,6,20,6z M9,5c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v1H9V5z M15,8v12H9V8H15z M3,19V9c0-0.6,0.4-1,1-1h3v12H4
    C3.4,20,3,19.6,3,19z M21,19c0,0.6-0.4,1-1,1h-3V8h3c0.6,0,1,0.4,1,1V19z"
    />
  </svg>
);
