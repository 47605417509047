import React from 'react';
import { withRouter } from 'react-router';
import Hider from '../../components/Hider';
import useClientVariable from '../../hooks/useClientVariable';
import { RouteProps } from '../../library/App';
import getMemberBaseUrl from '../../utilities/getMemberBaseUrl';
import GDPR from './GDPR';
import Movements from './Movements';
import Profile from './Profile';
import Stats from './Stats';

type IOwnProps = {};

type IProps = IOwnProps & RouteProps<{ id: string }>;

const TabPages: React.FC<IProps> = ({ location, params: { id } }) => {
  const hash = useClientVariable(location.hash, '');

  const profileTabActive =
    location.pathname === getMemberBaseUrl({ clen: id }) && !hash;
  const statsTabActive = hash === '#statistiky';
  const movementsTabActive = hash === '#prestupy';
  const gdprTabActive = hash === '#gdpr';

  return (
    <>
      <Hider hidden={!profileTabActive}>
        <Profile isActive={profileTabActive} />
      </Hider>
      {statsTabActive && <Stats />}
      {movementsTabActive && <Movements />}
      {gdprTabActive && <GDPR />}
    </>
  );
};

export default withRouter(TabPages) as React.FC<IOwnProps>;
