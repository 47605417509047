import React from 'react';
import { MatchDetailPrint } from '../../../library/Print';
import { Tr } from '../CompetitionMatchesPrint/RenderPrintData';
import styled from 'styled-components';
import { H2 } from '../CompetitionMatchesPrint';
import formatDate from '../../../utilities/formatDate';
import { rem } from 'polished';
import __ from '../../../utilities/__';

const Td = styled.td`
  padding: 1cm;
`;

const Col = styled.span`
  float: left;
  width: 33.333%;
  text-align: center;
`;

const TeamLogo = styled.img`
  width: 3cm;
  height: 3cm;
  margin-bottom: 0.25cm;
`;

const ScoreWrapper = styled.div`
  margin: 0.5cm 0;
`;

const Score = styled.span`
  font-size: ${rem(24)};
`;

interface OwnProps {
  dateFrom: MatchDetailPrint['dateFrom'];
  homeTeam: MatchDetailPrint['homeTeam'];
  awayTeam: MatchDetailPrint['awayTeam'];
  scoreByPhases?: MatchDetailPrint['scoreByPhases'];
  scoreNote: MatchDetailPrint['scoreNote'];
  audience: MatchDetailPrint['audience'];
  sportGround: MatchDetailPrint['sportGround'];
  isFutureMatch: boolean;
  isContumated: MatchDetailPrint['isContumated'];
}

const MatchDetailPrintHeader: React.FC<OwnProps> = ({
  dateFrom,
  homeTeam,
  awayTeam,
  scoreByPhases,
  scoreNote,
  audience,
  sportGround,
  isFutureMatch,
  isContumated,
}) => {
  const renderDate = () =>
    dateFrom ? (
      <>
        {formatDate(dateFrom, 'PPP')},{' '}
        <strong>{formatDate(dateFrom, 'p')}</strong>
      </>
    ) : (
      '-'
    );

  const renderSportGround = () =>
    sportGround && (
      <>
        <strong>{sportGround.name}</strong>
        {sportGround.address && ` (${sportGround.address})`}
      </>
    );

  const scoreByPhasesString =
    Array.isArray(scoreByPhases) && scoreByPhases.length > 0
      ? `[${scoreByPhases.join(', ')}]`
      : '';

  const renderScore = () => {
    if (homeTeam.isWinner) {
      return (
        <Score>
          <strong>{homeTeam.score}</strong>:{awayTeam.score}
        </Score>
      );
    } else if (awayTeam.isWinner) {
      return (
        <Score>
          {homeTeam.score}:<strong>{awayTeam.score}</strong>
        </Score>
      );
    } else {
      return (
        <Score>
          {homeTeam.score}:{awayTeam.score}
        </Score>
      );
    }
  };

  const hasAudienceInfo = typeof audience !== 'undefined';

  const renderAudience = () => {
    return hasAudienceInfo ? (
      <>
        {__('počet divákov: ')}
        <strong>{audience}</strong>
      </>
    ) : null;
  };

  return (
    <Tr>
      <Td colSpan={6}>
        <Col>
          <TeamLogo src={homeTeam.logoSrc} alt={homeTeam.name} />
          <H2>{homeTeam.name}</H2>
          {homeTeam.resignation && <>{__('Odstúpené')}</>}
        </Col>
        <Col>
          <>
            {renderDate()}
            <br />
            {renderSportGround()}
            <br />
            {!isFutureMatch && (
              <ScoreWrapper>
                {renderScore()}
                {scoreByPhasesString ? (
                  <>
                    <br />
                    {scoreByPhasesString}
                  </>
                ) : null}
                {isContumated && <p>{__('KONTUMOVANÉ')}</p>}
              </ScoreWrapper>
            )}
            {renderAudience()}
          </>
        </Col>
        <Col>
          <TeamLogo src={awayTeam.logoSrc} alt={awayTeam.name} />
          <H2>{awayTeam.name}</H2>
          {awayTeam.resignation && <>{__('Odstúpené')}</>}
        </Col>
      </Td>
    </Tr>
  );
};

export default MatchDetailPrintHeader;
