import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

export interface CustomImageProps {
  src: string;
  alt: string;
  sizes?: string[];
  srcSet?: string[];
  onLoadingComplete?: (props: {
    naturalWidth: number;
    naturalHeight: number;
  }) => void;
  lazy?: boolean;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down' | 'initial';
  height?: '100%' | 'auto';
  width?: '100%' | 'auto';
  onClick?: () => void;
}

const Image = styled.img<{
  $objectFit?: CustomImageProps['objectFit'];
  $height?: CustomImageProps['height'];
  $width?: CustomImageProps['width'];
}>`
  object-fit: ${({ $objectFit = 'contan ' }) => $objectFit};
  ${({ $height }) =>
    !!$height &&
    css`
      height: ${$height};
    `}
  ${({ $width }) =>
    !!$width &&
    css`
      width: ${$width};
    `}
  font-size: ${rem(9)};
  cursor: ${({ onClick }) =>
    typeof onClick === 'function' ? 'pointer' : 'inherit'};
`;

interface OwnProps extends CustomImageProps {
  className?: string;
}

export const CustomImage: React.FC<OwnProps> = ({
  className,
  src,
  alt,
  sizes,
  srcSet,
  onLoadingComplete,
  lazy = false,
  objectFit = 'contain',
  height,
  width = '100%',
  onClick,
}) => {
  const handleLoad = (e?: React.SyntheticEvent<HTMLImageElement>) => {
    if (!!e && typeof onLoadingComplete === 'function') {
      const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
      onLoadingComplete({ naturalWidth, naturalHeight });
    }
  };
  return (
    <Image
      className={className}
      src={src}
      alt={alt}
      sizes={sizes?.join(', ')}
      srcSet={srcSet?.join(', ')}
      onLoad={handleLoad}
      loading={lazy ? 'lazy' : undefined}
      $objectFit={objectFit}
      $height={height}
      $width={width}
      onClick={onClick}
    />
  );
};
