import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import { EntitiesState } from '../../../pages/App/reducer';
import styled from '../../../theme/styled-components';
import getArticleMainSection from '../../../utilities/getArticleMainSection';
import ArticlesListCompact from '../ArticlesListCompact';
import ArticlesListHorizontallyScrollable from '../ArticlesListHorizontallyScrollable';
import Article from './Article';

const Wrapper = styled.div`
  ${mb('s')} {
    display: flex;
    & > *:not(:last-child) {
      margin-right: ${rem(32)};
    }
  }
`;

const MainArticleWrapper = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  ${mb('s')} {
    flex-basis: calc(41% - ${rem(16)});
  }
  ${mb('l')} {
    flex-basis: calc(50% - ${rem(16)});
  }
`;

const ResponsiveArticlesListCompact = styled(ArticlesListCompact)`
  display: none;
  ${mb('s')} {
    display: flex;
    flex-direction: column;
  }
`;

const ResponsiveArticlesListHorizontallyScrollable = styled(
  ArticlesListHorizontallyScrollable,
)`
  display: flex;
  flex-direction: row;
  padding-bottom: ${rem(8)};

  ${mb('s')} {
    display: none;
  }
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById: EntitiesState['sections'];
  className?: string;
};

type IProps = IOwnProps;

const TopArticlesGrid: React.FC<IProps> = ({
  articles,
  sectionsById,
  className,
  children,
}) => {
  if (!articles.length) {
    return null;
  }

  const mainArticle = articles[0];
  const secondaryArticles = articles.slice(1, 5);

  const mainArticleSection = getArticleMainSection(mainArticle, sectionsById);

  return (
    <Wrapper className={className}>
      <MainArticleWrapper>
        <Article article={mainArticle} section={mainArticleSection} />
      </MainArticleWrapper>
      {children}
      <ResponsiveArticlesListCompact
        articles={secondaryArticles}
        sectionsById={sectionsById}
      />
      <ResponsiveArticlesListHorizontallyScrollable
        articles={secondaryArticles}
        sectionsById={sectionsById}
      />
    </Wrapper>
  );
};

export default TopArticlesGrid;
