import React, { FunctionComponent } from 'react';
import UrlContext from '../../../contexts/UrlContext';

export default ({
  createAuthorsLink = true,
  authors,
  ContainerWrapper = ({ children }) => <>{children}</>,
  AuthorWrapper = ({ children }) => <>{children}</>,
  after,
}: {
  createAuthorsLink?: boolean;
  authors?: any[];
  ContainerWrapper?: FunctionComponent;
  AuthorWrapper?: FunctionComponent<any>;
  after?: any;
}) => {
  const { getAuthorUrl } = React.useContext(UrlContext);
  // zobrazujeme autorov vtedy ak:
  // 1. prave jeden a ten ma sportnetId
  // 2. prave dvaja a obaja maju sportnetId
  // 3. prave jeden a jeho nazov je Inzercia
  const showAuthors =
    (authors?.length === 1 && authors[0].sportnetId) ||
    (authors?.length === 2 && authors[0].sportnetId && authors[1].sportnetId) ||
    (authors?.length === 1 && authors[0].name === 'Inzercia');
  if (showAuthors) {
    return (
      <ContainerWrapper>
        {authors
          .map((author, i: number) => {
            const link = author.sportnetId && createAuthorsLink ? getAuthorUrl(author) : null;
            return (
              <AuthorWrapper
                to={link}
                as={link ? undefined : 'span'}
                key={`author-${i + 1}`}
              >
                {author.name}
              </AuthorWrapper>
            );
          })
          .map((a, i) => (i > 0 ? [', ', a] : a))}
        {after}
      </ContainerWrapper>
    );
  }
  return null;
};
