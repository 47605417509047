/**
 * U Tour De France sa meni logika, akou sa generuju detail URLs pre dany rocnik.
 * - V Sutaziach v detailUrl staci mat iba slug a netreba celu URL. Ked aj daju celu URL beriem len poslednu cast
 *   a celu URL s rocnikovm vyskladam znovu
 * - Potom po tomto vzore prerobit Hokej, ZOH a Futbal
 */
import { AMAURY_TDF } from '../../config';
import getEventBaseUrl from './getEventBaseUrl';
import { ITeam } from '../../library/Competitions';
import getTeamUrlSlug from './getTeamSlug';

const getTeamUrl = (team: ITeam, competitionId?: string | null) => {
  const baseUrl = competitionId ? getEventBaseUrl(competitionId) : '';
  const { detailUrl = '' } = team;
  if (!baseUrl) {
    return detailUrl || '';
  }
  const amauryTdf = Object.entries(AMAURY_TDF).find(
    ([, val]) => val === competitionId,
  );
  if (amauryTdf) {
    // vezmeme len poslednu cast URL a pripojime k baseUrl
    const slug = detailUrl.split('/').pop();
    return `${baseUrl}/timy/${slug}/`;
  }

  return `${baseUrl}/timy/${getTeamUrlSlug(team as unknown as ITeam)}/`;
};

export default getTeamUrl;
