/**
 * Vrati "human readable" status hraca.
 */
import __ from './__';

const getPlayerStatus = (status: string | undefined) => {
  switch (status) {
    case 'ACTIVE':
      return __('Aktívny');
    case 'INACTIVE':
      return __('Neaktívny');
    case 'ABROAD':
      return __('V zahraničí');
    case 'V_ZAHRANICI':
      return __('V zahraničí');
    case 'NEAKTIVNY':
      return __('Neaktívny');
    default:
      return status;
  }
};

export default getPlayerStatus;
