import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import useQuery, { PageParam } from '@sportnet/query-hoc/useQuery';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import FutbalnetCMSAdManager from '../../../../components/Ads/AdManager/FutbalnetCMSAdManager';
import BodyAd from '../../../../components/Ads/BodyAd';
import BottomPaddedBigAd from '../../../../components/Ads/BottomPaddedBigAd';
import ListBodyRespAd from '../../../../components/Ads/ListBodyRespAd';
import MegaboardAd from '../../../../components/Ads/MegaboardAd';
import SideAd from '../../../../components/Ads/SideAd';
import TopAd from '../../../../components/Ads/TopAd';
import ArticlesList from '../../../../components/Articles/ArticlesList';
import TopArticlesGrid from '../../../../components/Articles/TopArticlesGrid';
import {
  ContextMarginBottomStyles,
  ContextMarginTopStyles,
} from '../../../../components/Layout';
import {
  BottomPaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../../components/Layout/SidebarLayout';
import MaxWidthBox from '../../../../components/MaxWidthBox';
import NextPrevPaginator from '../../../../components/NextPrevPaginator';
import SectionBlockHeaderContainer from '../../../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionBlockIcon from '../../../../components/Sections/SectionBlockHeader/SectionBlockIcon';
import SectionName from '../../../../components/Sections/SectionBlockHeader/SectionName';
import SectionsList from '../../../../components/Sections/SectionBlockHeader/SectionsList';
import Widgets from '../../../../components/Widgets';
import config from '../../../../config';
import { RootState } from '../../../../configureStore';
import FutbalnetSectionsBreadcrumbs from '../../../../containers/FutbalnetBreadcrumbs/FutbalnetSectionsBreadcrumbs';
import PremiumContentMessage from '../../../../containers/PremiumContentMessage';
import useWidgetDoxxbetInjector from '../../../../hooks/useWidgetDoxxbetInjector';
import { ISection, RouteProps } from '../../../../library/App';
import { initializeOrSetListParams } from '../../../../pages/App/actions';
import styled from '../../../../theme/styled-components';
import { getSectionSubTreeByParameters } from '../../../App/selectors';
import {
  loadSectionArticlesList,
  loadSectionArticlesListByParams,
} from '../../../Article/actions';
import {
  listArticlesByParamsSelector,
  sectionArticlesSelector,
} from '../../../Article/selectors';
import { sectionsEntitiesSelector } from '../../../Section/selectors';

const PositionedArticlesList = styled(ArticlesList)`
  margin: 0 auto;
`;

const PositionedTopArticlesGrid = styled(TopArticlesGrid)`
  ${ContextMarginBottomStyles}
`;

const SpacedMaxWidthBox = styled(MaxWidthBox)`
  ${ContextMarginTopStyles}
`;

const PositionedNextPrevPaginator = styled(NextPrevPaginator)`
  max-width: ${rem(680)};
  margin: ${rem(48)} auto 0 auto;
  ${mb('s')} {
    margin: ${rem(56)} auto 0 auto;
  }
`;

type IOwnProps = {
  section: ISection;
  appSpace: string;
};

const mapStateToProps = (
  state: RootState,
  { section, appSpace }: IOwnProps,
) => {
  const sectionId = section._id;
  return {
    mainArticles: listArticlesByParamsSelector(state, {
      listName: config.LIST_SECTION_ARTICLES,
      parameters: { limit: 5, offset: 0, sectionId, appSpace },
    }),
    articles: sectionArticlesSelector(state),
    sectionsById: sectionsEntitiesSelector(state),
    isLoadingSectionArticles:
      isCommiting(config.LIST_SECTION_ARTICLES)(state) || false,
    sectionArticlesNextOffset: getListNextOffset(config.LIST_SECTION_ARTICLES)(
      state,
    ),
    subSections:
      getSectionSubTreeByParameters(state, sectionId, {
        appSpace,
      })?.sections || [],
  };
};

const mapDispatchToProps = {
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadSectionArticlesList,
  loadSectionArticlesListByParams,
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps &
  RouteComponentProps<{ zvaz: string }, {}>;

const QUERY_HOC_CONFIG = {
  parameters: {
    page: PageParam(),
  },
};

const MainSection: React.FC<IProps> = ({
  section,
  location: { search, pathname },
  router,
  initializeOrSetListParams,
  loadSectionArticlesList,
  loadSectionArticlesListByParams,
  sectionsById,
  articles,
  mainArticles,
  isLoadingSectionArticles,
  sectionArticlesNextOffset,
  subSections,
  appSpace,
  params: { zvaz },
}) => {
  const sectionId = section._id;
  const { query } = useQuery(
    search,
    (serializedQuery) => router.push(`${pathname}${serializedQuery}`),
    QUERY_HOC_CONFIG,
  );

  useAsyncData(async () => {
    await initializeOrSetListParams({
      listName: config.LIST_SECTION_ARTICLES,
      params: {
        ...query,
        limit: config.LIST_SECTION_ARTICLES_LIMIT,
        offset: 5,
        sectionId,
        appSpace,
      },
    });
    await loadSectionArticlesList(config.LIST_SECTION_ARTICLES);
  }, [
    initializeOrSetListParams,
    loadSectionArticlesList,
    query,
    sectionId,
    appSpace,
  ]);

  useAsyncData(async () => {
    await loadSectionArticlesListByParams(config.LIST_SECTION_ARTICLES, {
      limit: 5,
      offset: 0,
      sectionId,
      appSpace,
    });
  }, [loadSectionArticlesListByParams, sectionId, appSpace]);

  const content = section.content || [];
  const title = section.heading || section.name;

  useWidgetDoxxbetInjector(content);

  return (
    <>
      <FutbalnetCMSAdManager
        site="rubric"
        siteId={String(sectionId)}
        pagetype="list"
      />

      <TopAd />
      <BottomPaddedBigAd name="big_1" />

      <SpacedMaxWidthBox>
        <SectionBlockHeaderContainer
          left={
            <>
              <SectionBlockIcon section={section as ISection} />
              <SectionName as="h2">{title}</SectionName>
            </>
          }
          right={<SectionsList sections={subSections as ISection[]} />}
        />
      </SpacedMaxWidthBox>

      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <PositionedTopArticlesGrid
            articles={mainArticles}
            sectionsById={sectionsById}
          >
            <ListBodyRespAd name="list_body_1" />
          </PositionedTopArticlesGrid>
          {content.length > 0 && (
            <Widgets
              items={content}
              context={{ currentSectionId: sectionId }}
            />
          )}
          {(section.state === 'UNATHORIZED' ||
            section.state === 'FORBIDDEN') && (
            <PremiumContentMessage showShadow={content.length > 1} />
          )}
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>

      <BodyAd name="body_1" />

      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <PositionedArticlesList
            articles={articles}
            sectionsById={sectionsById}
          />
          <PositionedNextPrevPaginator
            isPageLast={!sectionArticlesNextOffset}
            loading={isLoadingSectionArticles}
            page={query.page}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <StickySidebarContainer>
            <SideAd name="side_2" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>

      <MegaboardAd />

      <FutbalnetSectionsBreadcrumbs section={section} zvaz={zvaz} />
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MainSection) as any as React.FC<IOwnProps>;
