import { commit, CommitError, getListParameters } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory, { thunkToAction } from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { CustomThunkAction, ExtraArgumentType } from '../../configureStore';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities, Pager } from '../../library/App';
import { IMatch } from '../../library/Competitions';
import NotFoundError from '../../NotFoundError';
import isObjectId from '../../utilities/isObjectId';
import normalizeEntities from '../../utilities/normalizeEntities';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import {
  setCurrentCompetitionGroupId,
  setCurrentCompetitionId,
  setCurrentCompetitionPartId,
  setCurrentRoundId,
} from '../Competition/actions';
import { currentCompetitionIdSelector, currentCompetitionPartIdSelector } from '../Competition/selectors';
import { currentMatchSelector, getCurrentMatchIdSelector } from './selectors';
const create = actionCreatorFactory('MATCH');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const normalizeMatches = (
  data: IMatch[],
  idKey: '_id' | '__issfId' = '_id',
) => {
  return {
    entities: {
      matches: data.reduce((acc, item) => {
        return { ...acc, [String(item[idKey])]: item };
      }, {}) as { [key: string]: IMatch },
    },
    result: data.map((item) => item[idKey]),
  };
};

export const setCurrentMatchId = create<string>('SET_CURRENT_MATCH_ID');

export const loadMatchItem = createAsync<void, NormalizedEntities<'matches'>>(
  'LOAD_MATCH_ITEM',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    const currentMatchId = getCurrentMatchIdSelector(getState());
    return dispatch(
      loadMatch.action({
        matchId: currentMatchId,
      }),
    );
  },
);

export const loadMatch = createAsync<
  { matchId: string },
  NormalizedEntities<'matches'>
>(
  'LOAD_MATCH',
  async ({ matchId }, dispatch, getState, { CompetitionsApi }) => {
    try {
      const match = await CompetitionsApi.getMatch(matchId) as IMatch;
      dispatch(setCurrentCompetitionId(match.competition._id));
      dispatch(
        setCurrentCompetitionGroupId(match.competition.competitionGroupId),
      );
      dispatch(setCurrentCompetitionPartId(match.competitionPart._id));
      if (match.round) {
        dispatch(setCurrentRoundId(match.round._id || ''));
      }

      const { entities } = normalizeMatches(
        [match],
        isObjectId(matchId) ? '_id' : '__issfId',
      );

      dispatch(updateEntities(entities));
      return normalizeEntities('matches', [match]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  },
);

export const loadH2hMatches = createAsync<
  {
    limit?: number;
  },
  void
>(
  'LOAD_H2H_MATCHES',
  async ({ limit = 5 }, dispatch, getState, { CompetitionsApi }) => {
    const currentMatch = currentMatchSelector(getState());

    if (currentMatch) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_H2H_MATCHES,
          params: {
            matchId: currentMatch?._id,
          },
        }),
      );
      return dispatch(
        commit.action({
          listName: config.LIST_H2H_MATCHES,
          load: async () => {
            const f: {
              dateTo: string;
              limit: number;
              closed: boolean;
              teamAppSpaces: string[];
              excludeIds: string[];
              competitionGroupId: string;
            } = {
              limit,
              closed: true,
              dateTo: new Date(currentMatch.startDate).toJSON(),
              teamAppSpaces: (currentMatch.teams || []).map((t) => t.appSpace),
              competitionGroupId: currentMatch.competition.competitionGroupId,
              excludeIds: [currentMatch._id],
            };
            const {
              firstTeamMatches = [],
              secondTeamMatches = [],
              commonMatches = [],
            } = await CompetitionsApi.getH2HMatches(f);

            const { result: firstTeamResult, entities: firstTeamEntities } =
              normalizeMatches(
                firstTeamMatches.map((i) => ({ ...i, h2hType: 'firstTeam' })),
              );
            const { result: secondTeamResult, entities: secondTeamEntities } =
              normalizeMatches(
                secondTeamMatches.map((i) => ({ ...i, h2hType: 'secondTeam' })),
              );
            const { result: commonResult, entities: commonEntities } =
              normalizeMatches(
                commonMatches.map((i) => ({ ...i, h2hType: 'common' })),
              );

            dispatch(
              updateEntities({
                matches: {
                  ...commonEntities.matches,
                  ...firstTeamEntities.matches,
                  ...secondTeamEntities.matches,
                },
              }),
            );

            return {
              results: [
                ...firstTeamResult,
                ...secondTeamResult,
                ...commonResult,
              ],
              total: 15,
            };
          },
        }),
      );
    }
  },
);

export const loadMutualMatches = createAsync<
  {
    limit?: number;
  },
  void
>(
  'LOAD_MUTUAL_MATCHES',
  async ({ limit = 5 }, dispatch, getState, { CompetitionsApi }) => {
    const currentMatch = currentMatchSelector(getState());
    if (currentMatch) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_MUTUAL_MATCHES,
          params: {
            matchId: currentMatch?._id,
          },
        }),
      );
      return dispatch(
        commit.action({
          listName: config.LIST_MUTUAL_MATCHES,
          load: async () => {
            const f: {
              dateTo: string;
              limit: number;
              closed: boolean;
              teamAppSpaces: string[];
              excludeIds: string[];
              competitionGroupId: string;
            } = {
              limit,
              closed: true,
              dateTo: new Date(currentMatch.startDate).toJSON(),
              teamAppSpaces: (currentMatch.teams || []).map((t) => t.appSpace),
              competitionGroupId: currentMatch.competition.competitionGroupId,
              excludeIds: [currentMatch._id],
            };
            const response = await CompetitionsApi.getMatches(f);

            const { result, entities } = normalizeMatches(response.matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: response.total || 0,
            };
          },
        }),
      );
    }
  },
);

export const loadLastHomeTeamMatches = createAsync<
  {
    limit?: number;
  },
  void
>(
  'LOAD_LAST_HOMETEAM_MATCHES',
  async ({ limit = 5 }, dispatch, getState, { CompetitionsApi }) => {
    const currentMatch = currentMatchSelector(getState());
    if (currentMatch) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_LAST_HOMETEAM_MATCHES,
          params: {
            matchId: currentMatch?._id,
          },
        }),
      );
      const homeTeam = (currentMatch.teams || []).find(
        (t) =>
          t.additionalProperties && t.additionalProperties.homeaway === 'home',
      );
      return dispatch(
        commit.action({
          listName: config.LIST_LAST_HOMETEAM_MATCHES,
          load: async () => {
            const f: {
              dateTo: string;
              limit: number;
              closed: boolean;
              teamAppSpaces: string[];
              excludeIds: string[];
              competitionGroupId: string;
            } = {
              limit,
              closed: true,
              dateTo: new Date(currentMatch.startDate).toJSON(),
              teamAppSpaces: homeTeam ? [homeTeam.appSpace] : [],
              competitionGroupId: currentMatch.competition.competitionGroupId,
              excludeIds: [currentMatch._id],
            };
            const response = await CompetitionsApi.getMatches(f);

            const { result, entities } = normalizeMatches(response.matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: response.total || 0,
            };
          },
        }),
      );
    }
  },
);

export const loadLastAwayTeamMatches = createAsync<
  {
    limit?: number;
  },
  void
>(
  'LOAD_LAST_AWAYTEAM_MATCHES',
  async ({ limit = 5 }, dispatch, getState, { CompetitionsApi }) => {
    const currentMatch = currentMatchSelector(getState());
    if (currentMatch) {
      await dispatch(
        initializeOrSetListParams({
          listName: config.LIST_LAST_AWAYTEAM_MATCHES,
          params: {
            matchId: currentMatch?._id,
          },
        }),
      );
      const awayTeam = (currentMatch.teams || []).find(
        (t) =>
          t.additionalProperties && t.additionalProperties.homeaway === 'away',
      );
      return dispatch(
        commit.action({
          listName: config.LIST_LAST_AWAYTEAM_MATCHES,
          load: async () => {
            const f: {
              dateTo: string;
              limit: number;
              closed: boolean;
              teamAppSpaces: string[];
              excludeIds: string[];
              competitionGroupId: string;
            } = {
              limit,
              closed: true,
              dateTo: new Date(currentMatch.startDate).toJSON(),
              teamAppSpaces: awayTeam ? [awayTeam.appSpace] : [],
              competitionGroupId: currentMatch.competition.competitionGroupId,
              excludeIds: [currentMatch._id],
            };
            const response = await CompetitionsApi.getMatches(f);

            const { result, entities } = normalizeMatches(response.matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total: response.total || 0,
            };
          },
        }),
      );
    }
  },
);

export const loadMatches = createAsync<
  {
    limit?: number;
    offset?: number;
    dateFrom?: string;
    dateTo?: string;
    playerAppSpace?: string;
    teamId?: string;
    competitionId?: string;
    partId?: string;
  },
  { results: string[] } & Pager
>(
  'LOAD_MATCHES',
  async (
    {
      limit = 20,
      offset = 0,
      dateFrom,
      dateTo,
      playerAppSpace,
      teamId,
      competitionId,
      partId,
    },
    dispatch,
    getState,
    { CompetitionsApi },
  ) => {
    const f: {
      limit: number;
      playerAppSpace?: string;
      teamId?: string;
      offset: number;
      dateFrom?: string;
      dateTo?: string;
      competitionId?: string;
      partId?: string;
    } = {
      offset,
      limit,
    };
    if (dateFrom) {
      f.dateFrom = dateFrom;
    }
    if (dateTo) {
      f.dateTo = dateTo;
    }
    if (playerAppSpace) {
      f.playerAppSpace = playerAppSpace;
    }
    if (teamId) {
      f.teamId = teamId;
    }
    if (competitionId) {
      f.competitionId = competitionId;
    }
    if (partId) {
      f.partId = partId;
    }

    const response = await CompetitionsApi.getMatches(f);

    const { results, entities } = normalizeEntities(
      'matches',
      response.matches,
    );

    dispatch(updateEntities(entities));

    return {
      results: results as string[],
      total: response.total!,
      nextOffset: response.nextOffset,
      offset: f.offset,
      limit: f.limit,
    };
  },
);

export const loadMatchesList = (
  listName: string,
): CustomThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const parameters = getListParameters(listName)(getState());
    return dispatch(
      commit.action({
        listName,
        load: async () => {
          try {
            const competitionId = currentCompetitionIdSelector(getState());
            const partId = currentCompetitionPartIdSelector(getState());
            const { results, limit, nextOffset, offset, total } =
              await dispatch(thunkToAction(loadMatches)({ ...parameters, competitionId: parameters.competitionId || competitionId, partId: parameters.partId || partId }) as any);
            return {
              total,
              limit,
              offset,
              nextOffset,
              results,
            };
          } catch (e: any) {
            throw new CommitError(e);
          }
        },
      }),
    );
  };
};
