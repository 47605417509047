import {  serializeQuery } from '@sportnet/query-hoc/useQuery';


const getTeamPrintUrl = (
  klub: string,
  tim: string,
  competitionId?: string,
  competitionPartId?: string,
) => {
  const query = serializeQuery({ competitionId, competitionPartId});
  return `/futbalnet/k/${klub}/tim/${tim}/print/${query}`;
};

export default getTeamPrintUrl;
