import { __ } from '@sportnet/content/utilities/utilities';
import useQuery, { QueryHocInterface, QueryHocTypes } from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ButtonGroup from '../../../components/ButtonGroup';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Loading from '../../../components/Loading';
import Spacer from '../../../components/Spacer';
import Tabber from '../../../components/Tabber';
import UnionCompetitionsWidget from '../../../components/UnionCompetitionsWidget';
import CompetitionDraw from '../../../components/Widgets/Overrides/FootballCompetitionDraw';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../../containers/RempIntegration';
import { ICompetition } from '../../../library/Competitions';
import { UnionItem } from '../../../library/Union';
import getCompetitionBreadcrumbLabel from '../../../utilities/futbalnet/breadcrumbs/getCompetitionBreadcrumblabel';
import getCompetitionBaseUrl from '../../../utilities/getCompetitionBaseUrl';
import getUnionBaseUrl from '../../../utilities/getUnionBaseUrl';
import { initializeOrSetListParams } from '../../App/actions';
import { competitionsSelectorByUnion } from '../../FutbalnetApp/selectors';
import { loadMatchesList } from '../../Match/actions';
import { currentCompetitionPartMatchesSelector } from '../../Match/selectors';
import {
  loadCompetitionPartItem,
  loadCompetitionPartResults,
  setCurrentCompetitionPartId,
} from '../actions';
import {
  competitionPartsSelector,
  currentCompetitionIdSelector,
  currentCompetitionIsFetchingSelector,
  currentCompetitionPartIsFetchingSelector,
  currentCompetitionPartSelector,
  currentCompetitionSelector,
  currentOrganizationProfileSelector,
} from '../selectors';
import Standings from './standings';
import getCompetitionStandingPrintUrl from '../../../utilities/print/getCompetitionStandingPrintUrl';
import styled from 'styled-components';
import { rem } from 'polished';
import BodyAd from '../../../components/Ads/BodyAd';
import PrintButton from '../../FutbalnetPrint/PrintButton';
import {
  ContextMarginSmallBottomStyles,
  ContextMarginSmallTopStyles,
} from '../../../components/Layout';

export const PrintButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  ${ContextMarginSmallBottomStyles}
  ${ContextMarginSmallTopStyles}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    margin-bottom: 0;
  }
  margin-bottom: ${rem(24)};
`;

const mapStateToProps = (state: RootState) => ({
  currentCompetitionId: currentCompetitionIdSelector(state),
  currentCompetition: currentCompetitionSelector(state),
  currentCompetitionPart: currentCompetitionPartSelector(state),
  isFetchingCompetitionPart: currentCompetitionPartIsFetchingSelector(state),
  isFetchingCompetition: currentCompetitionIsFetchingSelector(state),
  parts: competitionPartsSelector(state),
  organizationProfile: currentOrganizationProfileSelector(state),
  competitions: competitionsSelectorByUnion(state),
  currentCompetitionPartMatches: currentCompetitionPartMatchesSelector(state),
});

type Props = { dispatch: CustomThunkDispatch } & ReturnType<
  typeof mapStateToProps
> &
  RouteComponentProps<{ zvaz: string; sutaz: string }, {}> &
  QueryHocInterface;

class CompetitionTables extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;

    const isCompetitionDraw =
      props.currentCompetition &&
      (config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
        props.currentCompetition._id,
      );

    if (isCompetitionDraw) {
    }

    const promises: Promise<any>[] = [
      dispatch(
        thunkToAction(loadCompetitionPartResults)({
          competitionId: props.currentCompetitionId,
          competitionPartId: props.currentCompetitionPart?.__issfId
            ? String(props.currentCompetitionPart?.__issfId)
            : props.currentCompetitionPart?._id,
        }) as any,
      ),
    ];

    if (isCompetitionDraw) {
      promises.push(
        (async () => {
          await dispatch(
            initializeOrSetListParams({
              listName: config.LIST_PART_MATCHES,
              params: {
                limit: 0,
                offset: 0,
                competitionId: props.currentCompetition?._id,
                partId: props.currentCompetitionPart?._id,
              },
            }),
          );
          return dispatch(loadMatchesList(config.LIST_PART_MATCHES));
        })(),
      );
    }

    return Promise.all(promises);
  }

  async componentDidMount() {
    await CompetitionTables.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.query.part &&
      this.props.query.part !== prevProps.query.part
    ) {
      this.props.dispatch(
        setCurrentCompetitionPartId(String(this.props.query.part)),
      );
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartItem)({}) as any,
      );
      await this.props.dispatch(
        thunkToAction(loadCompetitionPartResults)({
          competitionId: this.props.params.sutaz,
          competitionPartId: String(this.props.query.part),
        }) as any,
      );
    } else if (
      this.props.query.competitionId &&
      this.props.query.competitionId !== prevProps.query.competitionId
    ) {
      await CompetitionTables.getInitialProps(this.props);
    }

    if (
      this.props.currentCompetitionPart &&
      (!prevProps.currentCompetitionPart ||
        this.props.currentCompetitionPart._id !==
          prevProps.currentCompetitionPart._id) &&
      this.props.currentCompetition &&
      (config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
        this.props.currentCompetition._id,
      )
      // this.props.currentCompetitionPart.format === 'draw'
    ) {
      await this.props.dispatch(
        initializeOrSetListParams({
          listName: config.LIST_PART_MATCHES,
          params: {
            limit: 0,
            offset: 0,
            competitionId: this.props.currentCompetition!._id,
            partId: this.props.currentCompetitionPart._id,
          },
        }),
      );
      await this.props.dispatch(loadMatchesList(config.LIST_PART_MATCHES));
    }
  }

  render() {
    const {
      parts,
      currentCompetitionPart,
      currentCompetition,
      isFetchingCompetition,
      query: { tableView },
      setParameter,
      params: { sutaz, zvaz },
      location: { search },
      organizationProfile,
      competitions,
    } = this.props;

    const unionItem = { ...organizationProfile, competitions };

    if (
      (isFetchingCompetition && !currentCompetition) ||
      !currentCompetitionPart ||
      !currentCompetitionPart.resultsTable?.results
    ) {
      return <Loading />;
    }

    let baseObjectName: 'stats' | 'homeStats' | 'awayStats' = 'stats';
    if (tableView === 'home') {
      baseObjectName = 'homeStats';
    } else if (tableView === 'away') {
      baseObjectName = 'awayStats';
    }

    // const isCompetitionDraw = currentCompetitionPart.format === 'draw';
    const isCompetitionDraw =
      currentCompetition &&
      (config.PAVUK_COMPETITION_IDS as unknown as string[]).includes(
        currentCompetition._id,
      );

    const title = `${currentCompetition?.name} (${
      currentCompetition?.season?.name
    }) - ${__('tabuľky')}`;
    const description = `${currentCompetition?.name}, ${__('tabuľky')}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, štatistiky, profily hráčov. Na Sportnet.sk nájdete najlepšie spracované správy o futbale.',
    )}`;
    const breadcrumbUnionName =
      organizationProfile?.shortName || organizationProfile?.name || '';

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <TopAd />
        <BottomPaddedBigAd name="big_1" />
        <RempIntegration destroy />

        <PaddedSidebarLayout className="sptn-main-content">
          <SidebarLayoutContent>
            <div>
              <Tabber
                onChange={(tab) => this.props.setParameter({ part: tab.value })}
                active={
                  currentCompetitionPart?.__issfId
                    ? String(currentCompetitionPart?.__issfId)
                    : currentCompetitionPart?._id
                }
                tabs={parts.map((p: any) => ({
                  label: p.name,
                  value: p.__issfId ? String(p.__issfId) : p._id,
                }))}
              />
              {!isCompetitionDraw && (
                <ButtonsWrapper>
                  <ButtonGroup
                    onChange={(button) =>
                      setParameter({ tableView: button.value })
                    }
                    active={String(tableView)}
                    buttons={[
                      { label: __('Celkom'), value: '' },
                      { label: __('Doma'), value: 'home' },
                      { label: __('Vonku'), value: 'away' },
                    ]}
                  />
                </ButtonsWrapper>
              )}
              {!!currentCompetitionPart && (
                <Spacer size={3}>
                  {currentCompetition && isCompetitionDraw ? (
                    <CompetitionDraw
                      data={{
                        partId: currentCompetitionPart._id,
                        data: {
                          competitionPart: currentCompetitionPart as any,
                          matches:
                            (this.props.currentCompetitionPartMatches as any) ||
                            [],
                        },
                      }}
                    />
                  ) : (
                    <Standings
                      currentCompetitionPart={currentCompetitionPart}
                      currentCompetition={currentCompetition as ICompetition}
                      baseObjectName={baseObjectName}
                      zvaz={this.props.params.zvaz}
                    />
                  )}
                  {!!currentCompetition && !!currentCompetitionPart && (
                    <PrintButtonWrapper>
                      <PrintButton
                        href={getCompetitionStandingPrintUrl(
                          zvaz,
                          currentCompetition.__issfId || currentCompetition._id,
                          currentCompetitionPart._id,
                          baseObjectName,
                        )}
                        gtmEventLabel='tabulka'
                      />
                    </PrintButtonWrapper>
                  )}
                </Spacer>
              )}
            </div>
            <BodyAd className="advertisement-margin-24px" name="body_2" />
            <UnionCompetitionsWidget union={unionItem as UnionItem} />
          </SidebarLayoutContent>
          <Sidebar>
            <StickySidebarContainer>
              <SideAd name="side_1" />
            </StickySidebarContainer>
          </Sidebar>
        </PaddedSidebarLayout>
        <MegaboardAd />
        {breadcrumbUnionName && currentCompetition && (
          <FutbalnetUnionBreadcrumbs
            crumbs={[
              {
                label: breadcrumbUnionName,
                url: getUnionBaseUrl({ zvaz }),
              },
              {
                label: getCompetitionBreadcrumbLabel(currentCompetition),
                url: getCompetitionBaseUrl({ zvaz, sutaz }),
              },
              {
                label: 'Tabuľky',
                url: `${getCompetitionBaseUrl({ zvaz, sutaz })}tabulky/${
                  search || ''
                }`,
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default compose(
  useQuery({
    parameters: {
      part: {
        type: QueryHocTypes.String,
        defaultValue: '',
      },
      tableView: { type: QueryHocTypes.String, defaultValue: '' },
    },
  }),
  connect(mapStateToProps),
)(CompetitionTables);
