import Image from '@sportnet/ui/lib/Image';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from '../../../../theme/styled-components';

export const TeamLogoWrapper = styled.span`
  width: ${rem(24)};
  height: ${rem(24)};
  flex-shrink: 0;
  margin-right: ${rem(8)};
`;

export const TeamLogo = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
`;

export const TeamName = styled.span`
  overflow-wrap: break-word;
  max-width: ${rem(88)};
  text-decoration: none;
  :any-link:hover,
  :any-link:focus,
  :any-link:active {
    text-decoration: underline;
  }
  ${mb('m')} {
    max-width: none;
  }
`;

export const Title = styled.h2`
  font-family: inherit;
  font-weight: 600;
  text-transform: uppercase;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0 0 ${rem(16)} 0;
  padding: 0;
`;
