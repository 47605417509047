export default function (props: {
  id?: string | number;
  params: {
    id?: string;
  };
}) {
  if (!('id' in props)) {
    return Number(props.params.id);
  }
  return Number(props.id);
}
