import { SectionNode } from '../library/App';
import filterQueryParamsForCanonicalUrl from './filterQueryParamsForCanonicalUrl';
import getBaseUrl from './getBaseUrl';

/**
 * Vrati kanonicku URL pre sekciu. Zachovava vsetky query parametre, okrem parametrov,
 * ktore zacinaju retazcom:
 * - `utm`
 * - `rtm`
 * - `ref`
 *
 * POZN.: Zachovavat vsetko okrem vymenovanych je poziadavka M. Cholveu po
 * konzultacii so SEO.
 *
 * Query parametre, ktore pouziva portal vratane futbalnetu (pozor, nemusia byt vsetky,
 * su to len tie ktore som nasiel pri rychlom preklikani)
 * - `page`
 * - `seasonId`
 * - `part`
 * - `tableView`
 * - `sutaz`
 * - `partId`
 * - `year`
 * @param section Objekt sekcie.
 * @param queryString GET parametre z URL.
 * @returns Canonicka URL pre sekciu
 */

export default function (
  section: SectionNode,
  queryString: string,
  overrideUrl?: string,
) {
  const sectionUrl = overrideUrl ?? section.url ?? '';
  const filteredQueyString = filterQueryParamsForCanonicalUrl(queryString);
  return `${getBaseUrl()}/${sectionUrl ? `${sectionUrl}/` : ''}${
    filteredQueyString ? `?${filteredQueyString}` : ''
  }`;
}
