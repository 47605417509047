import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../query';
import FeedsApi from '../../../api/FeedsApi';
import config from '../../../config';

interface PublicFeedParams {
  feedId: string;
}

export const getPublicFeed = createQueryFn(
  QueryNameSpace.publicFeed,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, PublicFeedParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters?.feedId) {
      throw new Error('Missig required parameter [feedId]');
    }

    const { feedId } = parameters;

    const feed = await FeedsApi.getPublicFeed(
      config.FEEDS_GOSSIP_APP_SPACE,
      feedId,
    );

    return feed;
  },
);
