import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill="#333"
      id="icon_16x16"
      d="M11.8,2C6.4,2,2,6.4,2,11.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8S17.1,2,11.8,2L11.8,2z M8.6,4.1
l2.7,2l0.4,0.3l0.5-0.3l2.7-2c1.2,0.5,2.2,1.3,3.1,2.2l-1,3.2l-0.2,0.5l0.4,0.3l2.7,2c-0.1,1.3-0.5,2.5-1.2,3.6h-4l-0.2,0.5
l-1.1,3.3C13,19.9,12.4,20,11.7,20c-0.7,0-1.3-0.1-1.9-0.2l-1-3.2L8.6,16H4.7c-0.7-1.1-1.1-2.4-1.1-3.7l2.7-2l0.4-0.3L6.5,9.6
L5.5,6.4C6.3,5.4,7.4,4.6,8.6,4.1z M11.8,7.3l-0.4,0.3l-3.4,2.5l-0.4,0.3L7.6,11l1.3,4l0.2,0.5h5.3l0.2-0.5l1.3-4l0.2-0.5l-0.4-0.3
l-3.4-2.5L11.8,7.3z"
    />
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="-579.1766"
      y1="343.4583"
      x2="-579.1766"
      y2="344.4583"
      gradientTransform="matrix(7.4178 0 0 -7.0611 4307.9595 2440.1831)"
    >
      <stop offset="0" style={{ stopColor: '#000', stopOpacity: 0 }} />
      <stop offset="1" style={{ stopColor: '#fff', stopOpacity: 0.4 }} />
    </linearGradient>
    <path d="M14,15H9.5l-0.1-0.2l-1.3-4L8,10.6l0.1-0.1l3.4-2.5l0.2-0.1l0.2,0.1l3.4,2.5l0.1,0.1l-0.1,0.2l-1.3,4L14,15z" />
    <path
      fill="#333"
      d="M11.8,8.6l-3.1,2.3l1.2,3.7h3.8l1.2-3.7L11.8,8.6 M11.8,7.3l0.5,0.3l3.4,2.5l0.4,0.3L15.9,11l-1.3,4l-0.2,0.5
H9.1L8.9,15l-1.3-4l-0.2-0.5l0.4-0.3l3.4-2.5L11.8,7.3z"
    />
  </svg>
);
