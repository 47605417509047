import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import Icon from '../../Icon';

export type ISidebarPosition = 'right' | 'left';

export const SIDEBAR_TRANSITION = '0.2s ease-in-out';
export const SIDEBAR_WIDTH = 480;
export const SIDEBAR_ZINDEX = 10000;
export const SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY = 'xl';
export const SIDEBAR_BACKDROP_BACKGROUND = 'rgba(0, 0, 0, 0.25)';

const FixedWrapper = styled.div<{ opened: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: ${SIDEBAR_ZINDEX};
  ${mb('m')} {
    border-left: 1px solid ${({ theme }) => theme.separatorColor};
  }
  ${({ opened }) => {
    if (opened) {
      return css`
        top: 0;
        right: 0;
        bottom: 0;
        left: ${rem(24)};
      `;
    }
    return css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 100%;
    `;
  }}
  background: white;
  transition: all ${SIDEBAR_TRANSITION};
  ${mb('s')} {
    ${({ opened }) =>
      opened
        ? css`
            top: 0;
            left: calc(100% - ${rem(SIDEBAR_WIDTH)});
          `
        : css`
            top: 0;
            left: 100%;
          `}
  }
`;

const ScrollContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 0 auto;
  padding: ${rem(24)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${rem(24)};
  height: ${rem(56)};
  color: ${({ theme }) => theme.color.primaryFontColor};
  font-weight: bold;
  font-size: ${rem(16)};
  border-bottom: 1px solid ${({ theme }) => theme.color.darkBorder};
  ${mb('s')} {
    height: ${rem(72)};
    font-size: ${rem(16)};
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

interface ISidebarBackdrop {
  isOpen: boolean;
  modal: boolean;
}

export const SidebarBackdrop = styled.div<ISidebarBackdrop>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${SIDEBAR_ZINDEX};
  background-color: ${SIDEBAR_BACKDROP_BACKGROUND};
  transition: all ${SIDEBAR_TRANSITION};
  ${({ isOpen, modal }) => {
    if (!modal) {
      if (isOpen) {
        return css`
          opacity: 1;
          ${mb(SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY)} {
            pointer-events: none;
            opacity: 0;
          }
        `;
      } else {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    } else {
      if (isOpen) {
        return css`
          opacity: 1;
        `;
      } else {
        return css`
          pointer-events: none;
          opacity: 0;
        `;
      }
    }
  }}
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.darkBorder};
  padding: ${rem(24)};
`

interface OwnProps {
  className?: string;
  children?: React.ReactNode;
  opened: boolean;
  sidebarPortalRef: React.RefObject<HTMLDivElement>;
  header?: string;
  onClose: () => void;
  footer?: React.ReactNode;
}

const Sidebar: React.FC<OwnProps> = ({
  opened,
  onClose,
  children,
  sidebarPortalRef,
  header = '',
  footer,
}) => {
  const content = (
    <>
      <Header>
        {header}
        <CloseButton onClick={onClose}>
          <Icon name="close" size={24} />
        </CloseButton>
      </Header>
      <ScrollContent>{children}</ScrollContent>
      {footer && <Footer>{footer}</Footer>}
    </>
  );

  return (
    sidebarPortalRef.current &&
    ReactDOM.createPortal(
      <>
        <SidebarBackdrop isOpen={opened} onClick={onClose} modal={true} />
        <FixedWrapper opened={opened}>{content}</FixedWrapper>
      </>,
      sidebarPortalRef.current,
    )
  );
};

export default React.memo(Sidebar);
