import React from 'react';
import useAdScriber from '../../../hooks/useAdScriber';

const AdFooter: React.FC<{}> = () => {
  const name = '_footer';

  useAdScriber(name, 100);

  return <div className={name} />;
};

export default React.memo(AdFooter);
