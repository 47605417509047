import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import useClickOutside from '../../hooks/useClickOutside';
import styled from '../../theme/styled-components';

const PositionWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  z-index: 20000;
  padding-bottom: ${rem(16)};
  overflow: hidden;
  top: ${rem(39)};
  ${mb('s')} {
    top: ${rem(55)};
  }
`;

const Wrapper = styled.div`
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-radius: 0 0 ${rem(4)} ${rem(4)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  flex: 0 0 ${rem(320)};
`;

const Pusher = styled.div`
  flex-shrink: 1;
`;

interface OwnProps {
  xPosition: number;
  onClose: () => void;
  active: boolean;
}

type Props = OwnProps;

const PositionedDropdown: React.FC<Props> = ({
  xPosition,
  children,
  onClose,
  active,
}) => {
  const ref = useClickOutside(onClose, active);

  return (
    <PositionWrapper>
      <Pusher style={{ width: rem(xPosition) }} />
      <Wrapper ref={ref}>{children}</Wrapper>
    </PositionWrapper>
  );
};

export default PositionedDropdown;
