import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import { EntitiesState } from '../../../pages/App/reducer';
import styled from '../../../theme/styled-components';
import getArticleMainSection from '../../../utilities/getArticleMainSection';
import ListBodyAd from '../../Ads/ListBodyAd';
import Article from './Article';

const List = styled.ul`
  list-style: none;
  max-width: ${rem(680)};
  margin: 0;
  padding: 0;
  > li:not(:last-child):not(.article-list-ad){
    margin-bottom: ${rem(32)};
  }
  > li.article-list-ad {
    margin-bottom: ${rem(32)};
  }
  ${mb(750)} {
    > li.article-list-ad {
      margin-bottom: 0;
    }
  }
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById?: EntitiesState['sections'];
  className?: string;
  widthAd?: boolean;
  adAfter?: number;
};

type IProps = IOwnProps;

const ArticlesList: React.FC<IProps> = ({
  articles,
  sectionsById = {},
  className,
  widthAd,
  adAfter = 6,
}) => {
  if (!articles.length) {
    return null;
  }

  function renderArticle(article: IArticle, idx: number) {
    const section = getArticleMainSection(article, sectionsById);
    return (
      <React.Fragment key={`${article._id}-${article.modified}`}>
        <Article article={article} section={section} className={idx === adAfter ? 'before-ad' : ''} />
        {widthAd && idx === adAfter && (
          <li className='article-list-ad'>
            <ListBodyAd name="list_body_2" />
          </li>
        )}
      </React.Fragment>
    );
  }

  return <List className={className}>{articles.map(renderArticle)}</List>;
};

export default React.memo(ArticlesList);
