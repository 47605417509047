import { rem } from 'polished';
import React from 'react';
import styled, { css, withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import FormGroupContext from '../FormGroup/FormGroupContext';
import InfoTooltip from '../InfoTooltip';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LabelWrapper = styled.label<{ required?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.form.labelColor};
  font-weight: bold;
  font-size: ${rem(12)};
  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '*';
        color: ${({ theme }) => theme.color.danger};
        margin-left: ${rem(3)};
      }
    `}
`;

const InfoWrapper = styled.div`
  margin-left: ${rem(6)};
  display: flex;
`;

interface IOwnProps {
  className?: string;
  children: any;
  htmlFor?: string;
  required?: boolean;
  info?: React.ReactNode;
}

type IProps = IOwnProps & { theme: ITheme };

const Label: React.FC<IProps> = ({
  children,
  className,
  htmlFor,
  required,
  info,
  theme,
  ...other
}) => {
  const { id } = React.useContext(FormGroupContext);

  return (
    <Wrapper>
      <LabelWrapper
        required={required}
        htmlFor={htmlFor || id}
        className={className}
        {...other}
      >
        {children}
      </LabelWrapper>
      {info && (
        <InfoWrapper>
          <InfoTooltip content={info} size={14} color={theme.form.labelColor} />
        </InfoWrapper>
      )}
    </Wrapper>
  );
};

export default withTheme(Label);
