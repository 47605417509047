import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M14,3.3h-2.6V2.7c0-1.1-0.9-2.1-2.1-2.1H6.7c-1.1,0-2.1,0.9-2.1,2.1v0.6H2C1.6,3.3,1.3,3.6,1.3,4S1.6,4.8,2,4.8
    h0.6v8.6c0,1.1,0.9,2.1,2.1,2.1h6.7c1.1,0,2.1-0.9,2.1-2.1V4.8H14c0.4,0,0.8-0.3,0.8-0.8S14.4,3.3,14,3.3z M6.1,2.7
    c0-0.3,0.3-0.6,0.6-0.6h2.7c0.3,0,0.6,0.3,0.6,0.6v0.6H6.1V2.7z M11.9,13.3c0,0.3-0.3,0.6-0.6,0.6H4.7c-0.3,0-0.6-0.3-0.6-0.6V4.8
    h7.8V13.3z M7.4,7.3v4c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8v-4c0-0.4,0.3-0.8,0.8-0.8S7.4,6.9,7.4,7.3z M10.1,7.3v4
    c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8v-4c0-0.4,0.3-0.8,0.8-0.8S10.1,6.9,10.1,7.3z"
    />
  </svg>
);
