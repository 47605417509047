import React from 'react';
import { connect } from 'react-redux';
import ThirdLevelMenu from '../../../components/ThirdLevelMenu';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import UrlContext from '../../../contexts/UrlContext';
import { ISection } from '../../../library/App';
import { getSectionSubTree } from '../../App/selectors';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  section: getSectionSubTree(state, config.TOPIC_SECTION_ID),
});

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

const TopicTags: React.FC<IProps> = ({ section }) => {
  const { getSectionUrl } = React.useContext(UrlContext);

  if (!section) {
    return null;
  }

  return (
    <ThirdLevelMenu
      menuId="topicTags"
      items={((section.sections || []) as ISection[]).map((i) => {
        return {
          id: String(i._id),
          label: i.name,
          url: getSectionUrl({ ...i, _id: i._id!, name: i.name! }),
        };
      })}
    />
  );
};

export default connect(mapStateToProps)(TopicTags);
