import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled from '../../../theme/styled-components';

export default styled.h2`
  font-size: ${rem(20)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.textColor};
  border: none;
  font-weight: 600;
  outline: none;
  background: none;
  word-break: break-word;
  line-height: ${rem(24)};
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  ${mb('s')} {
    font-size: ${rem(24)};
    line-height: ${rem(28.8)};
  }
`;
