import React from 'react';
import styled from '../../../theme/styled-components';
import icons from './icons';

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

export type ISocialIconVariant = 'color' | 'dark' | 'light';

export type ISocialIconName = keyof typeof icons;

export interface IOwnProps {
  size?: number;
  variant?: ISocialIconVariant;
  className?: string;
  name: ISocialIconName;
}

type IProps = IOwnProps;

const SocialIcon: React.FC<IProps> = ({
  size = 32,
  variant,
  className,
  name,
  ...restProps
}) => {
  return (
    <Wrapper className={`social-icon ${className}`} {...restProps}>
      {name in icons ? icons[name](size, variant) : null}
    </Wrapper>
  );
};

export default SocialIcon;
export { default as icons } from './icons';
