import {
  IRenderLink,
  IWidgetPicture,
} from '@sportnet/content/utilities/library';
import React from 'react';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';
import __ from '../../../../utilities/__';
import ArticleInternalContentPrelinkToArticle from '../../../../sportnet.sme.sk-ui/ArticleInternalContentPrelinkToArticle';
import UrlContext from '../../../../contexts/UrlContext';

export interface IWidgetRealtedContentArticle {
  _id: number;
  doctype: string;
  name: string;
  valid_from: string | null;
  url: string;
  picture?: IWidgetPicture;
  perex?: string;
}

interface OwnProps {
  articlePreview: IWidgetRealtedContentArticle;
  renderLink?: IRenderLink;
  showTitle: boolean;
  customTitle: string;
  customWidgetTitle: string;
  showWidgetTitle: boolean;
}

const InternalContentArticle: React.FC<OwnProps> = ({
  articlePreview,
  showTitle,
  customTitle,
  customWidgetTitle,
  showWidgetTitle,
}) => {
  const { getArticleUrl } = React.useContext(UrlContext);

  const image = articlePreview?.picture
    ? getMediaManagerUrl(articlePreview?.picture, 640, 360, 'resize', true)
    : undefined;

  const articleImage = image
    ? {
        src: image,
        srcSet: [
          getMediaManagerUrl(articlePreview?.picture, 320, 180, 'resize', true),
          `${image} 2x`,
        ],
        alt: articlePreview.picture?.description ?? articlePreview.name ?? '',
      }
    : undefined;

  return (
    <ArticleInternalContentPrelinkToArticle
      className="content-widget"
      articleTitle={showTitle ? customTitle || articlePreview.name : ''}
      linkProps={{
        href: getArticleUrl(articlePreview as any),
      }}
      articleImage={articleImage}
      widgetTitle={
        showWidgetTitle ? customWidgetTitle || __('Súvisiaci článok') : ''
      }
    />
  );
};

export default InternalContentArticle;
