import isBrowser from './isBrowser';

export default (
  selector: string,
  htmlContent: string,
  replaceOrAppend: 'replace' | 'append' = 'replace',
) => {
  if (!isBrowser()) {
    throw new Error('Scribe can be executed only in browser');
  }
  document.querySelectorAll(selector).forEach((el) => {
    const range = document.createRange();
    range.selectNodeContents(el);
    if (replaceOrAppend === 'replace') {
      range.deleteContents();
    }
    range.selectNodeContents(document.createElement('div')); // fix for safari 10
    el.appendChild(range.createContextualFragment(htmlContent));
  });
};
