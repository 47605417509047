import React from 'react';

export default (size: string, color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 28 81.2">
    <path
      fill={color}
      d="M0,81.2L0,81.2c0.1-6.3,4-11.9,9.8-14.3v-0.1C20.4,62.9,28,52.6,28,40.6c0-12-7.6-22.3-18.2-26.2v-0.1
	C4,11.9,0.1,6.3,0,0h0V81.2z"
    />
  </svg>
);
