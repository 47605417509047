import React from 'react';

export default (size: string = '12') => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height={size}
    viewBox="0 0 182.4 98.6"
  >
    <path
      fill="#f30d31"
      d="M182.4,0H149l-17.2,25.7L124.6,0h-12.8H94.2H78.4L61.2,25.7L54,0H23.5l16.9,46.6L0,98.6h33.3L52.6,71l8.6,27.7
	h9.4h21.2h12.1L123.3,71l8.6,27.7h30.6l-18.2-49.1L182.4,0z M86,73.3l-8.6-23.2l21.3-27.6l8.5,23.5L86,73.3z"
    />
  </svg>
);
