import { ITeam } from '../library/Competitions';

export default (a: ITeam, b: ITeam) => {
  if (a.gender === 'M' && b.gender === 'F') {
    return -1;
  } else if (a.gender === 'F' && b.gender === 'M') {
    return 1;
  } else if (a.category === 'ADULTS' && b.category !== 'ADULTS') {
    return -1;
  } else if (a.category !== 'ADULTS' && b.category === 'ADULTS') {
    return 1;
  } else if (a.category && b.category && a.category > b.category) {
    return -1;
  } else if (a.category && b.category && a.category < b.category) {
    return 1;
  } else if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
  return 0;
};
