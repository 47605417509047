import { CustomImage, CustomImageProps } from '../CustomImage/CustomImage';
import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { AspectRatioWrapper } from '../../components/Widgets/Overrides/MatchesList';
import { LinkProps } from 'react-router/lib/Link';
import Link from '../../components/Link';

const ArticleTitle = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont.family};
  text-decoration: none;
  color: ${({ theme }) => theme.color.linkColor};
  font-size: ${rem(17)};
  line-height: 1.5;
  text-decoration: none;
  ${mb('s')} {
    font-size: ${rem(18)};
    line-height: 1.2;
    color: ${({ theme }) => theme.color.primaryFontColor};
  }
`;

const Wrapper = styled(Link)`
  border-radius: unset;
  overflow: hidden;
  display: flex;
  border: none;
  &:hover {
    ${ArticleTitle} {
      text-decoration: underline;
    }
  }
  ${mb('s')} {
    border-radius: ${({ theme }) => rem(theme.border.borderRadius)};
    border: ${({ theme }) => theme.border.normalDark};
  }
`;

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${mb('s')} {
    padding: ${rem(24)};
  }
`;

const WidgetTitle = styled.div`
  font-size: ${rem(11)};
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  margin-bottom: ${rem(12)};
  font-family: ${({ theme }) => theme.primaryFont.family};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.primaryFontColor};
  ${mb('s')} {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

const PictureWrapper = styled.div`
  max-width: ${rem(160)};
  min-width: ${rem(160)};
  display: none;
  ${mb('s')} {
    display: flex;
  }
`;

const StyledAspectRatioWrapper = styled(AspectRatioWrapper)`
  && {
    border-radius: unset;
    cursor: pointer;
  }
`;

interface OwnProps {
  className?: string;
  articleTitle: string;
  linkProps: {
    href: LinkProps['href'];
    shallow?: boolean;
    scroll?: boolean;
  };
  widgetTitle?: string;
  articleImage?: CustomImageProps;
}

/**
 * BUG: Na mobile nezorbazujeme obrazok, ale kedze sa nachadza v DOM aj tak sa downloadne.
 * @param param0
 * @returns
 */
export const ArticleInternalContentPrelinkToArticle: React.FC<OwnProps> = ({
  className,
  articleImage,
  articleTitle,
  linkProps,
  widgetTitle,
}) => {
  return (
    <Wrapper
      className={className}
      to={linkProps.href}
      shallow={linkProps.shallow}
      scroll={linkProps.scroll}
    >
      {articleImage && (
        <PictureWrapper>
          <StyledAspectRatioWrapper scWidth={4} scHeight={3}>
            <CustomImage
              {...articleImage}
              objectFit="cover"
              height="100%"
              lazy
            />
          </StyledAspectRatioWrapper>
        </PictureWrapper>
      )}

      <TextContentWrapper>
        {widgetTitle && <WidgetTitle>{widgetTitle}</WidgetTitle>}
        {articleTitle && <ArticleTitle>{articleTitle}</ArticleTitle>}
      </TextContentWrapper>
    </Wrapper>
  );
};
