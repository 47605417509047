import React from 'react';
import { rem } from 'polished';
import styled, { withTheme } from 'styled-components';
import { IThemeProps } from '../../../theme/styled-components';
import Button from '../../../sportnet.sme.sk-ui/SecondaryButton';
import Icon from '../../Icon';
import __ from '../../../utilities/__';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
  border: 1px solid ${({ theme }) => theme.color.lightBorder};
  padding: ${rem(48)} ${rem(16)};
  > *:not(:last-child) {
    margin-bottom: ${rem(24)};
  }
`;

const Text = styled.div`
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  font-size: ${rem(18)};
  text-align: center;
  line-height: ${rem(23.4)};
`;

interface OwnProps {
  className?: string;
  text: string;
  onResetFilterClick?: () => void;
  resetText?: string;
}

const NoResults: React.FC<OwnProps & IThemeProps> = ({
  className,
  theme,
  text,
  onResetFilterClick,
  resetText,
}) => {
  return (
    <Wrapper className={className}>
      <Icon name="noResults" size={40} color={theme.color.lightBorder} />
      <Text>{text}</Text>
      {onResetFilterClick && (
        <Button onClick={onResetFilterClick} size="small">
          {resetText ? resetText : __('Resetovať filter')}
        </Button>
      )}
    </Wrapper>
  );
};

export default withTheme(NoResults);
