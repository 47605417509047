import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import styled, { css } from '../../../theme/styled-components';
import ListBodyAd from '../ListBodyAd';

export default styled(ListBodyAd)`
  && {
    ${({ theme }) => css`
      width: calc(100% + ${({ theme }) => rem(theme.app.padding)});
      margin-left: ${rem(-theme.app.padding)};
      margin-right: ${rem(-theme.app.padding)};
      background-color: #f1f1f1;
      margin-top: -1px;
    `};
    ${mb('s')} {
      display: none;
    }
  }
`;
