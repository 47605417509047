import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { rem } from 'polished';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import Loading from '../../components/Loading';
import Segment from '../../components/Segment';
import { IMatch } from '../../library/Competitions';
import { ITheme } from '../../theme/theme';
import isBrowser from '../../utilities/isBrowser';
import MatchPreview from './MatchPreview';

interface OwnProps {
  matches: IMatch[];
  appSpace?: string;
  isFetching: boolean | null;
}

type Props = OwnProps & { theme: ITheme };

const Wrapper = styled.div`
  margin: ${rem(32)} 0;
`;

const Col = styled.div`
  width: 100%;
  padding: 0 ${rem(16)} 0 0;
`;

const MatchesSlider: React.FC<Props> = ({
  appSpace,
  matches,
  isFetching,
  theme,
}) => {
  if (!isBrowser()) {
    return null;
  }

  const renderSliderChildren = () =>
    matches.map((match) => (
      <Col key={match._id}>
        <Segment>
          <MatchPreview theme={theme} match={match} />
        </Segment>
      </Col>
    ));

  return (
    <div>
      {!!isFetching ? (
        <Loading />
      ) : (
        <Wrapper>
          <Carousel
            breakpoints={{
              768: {
                slidesPerPage: 1.1,
                animationSpeed: 500,
              },
            }}
            slidesPerPage={4.1}
            animationSpeed={0}
          >
            {renderSliderChildren()}
          </Carousel>
        </Wrapper>
      )}
    </div>
  );
};

export default withTheme(MatchesSlider);
