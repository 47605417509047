import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import addHours from 'date-fns/addHours';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import slugify from 'slugify';
import { IMatch } from '../../library/Competitions';
import getMatchAwayTeam from '../../utilities/match/getMatchAwayTeam';
import getMatchHomeTeam from '../../utilities/match/getMatchHomeTeam';
import getMatchUrl from '../../utilities/match/getMatchUrl';
import getTeamName from '../../utilities/match/getTeamName';

type OwnProps = {
  matches: IMatch[];
  raceMatches?: IMatch[];
};

type Props = OwnProps;

const SDMatches: React.FC<Props> = ({ matches, raceMatches }) => {
  const matchesData = matches.map((match) => {
    const homeTeam = getMatchHomeTeam(match as unknown as IWidgetMatchesListMatchItem);
    const awayTeam = getMatchAwayTeam(match as unknown as IWidgetMatchesListMatchItem);
    const homeTeamName = getTeamName(match.appSpace, homeTeam);
    const awayTeamName = getTeamName(match.appSpace, awayTeam);
    const imageUrl =
      ['iihf', 'uefa'].includes(match.appSpace) && match.round
        ? `https://mediamanager.ws/images/${match.appSpace}/${
            match.season.name
          }/${slugify(
            match.round.name.startsWith('Matchday')
              ? match.competitionPart.name
              : match.round.name,
            { lower: true },
          )}/${homeTeam?.organization?.country}/${
            awayTeam?.organization?.country
          }.png`
        : undefined;
    const { sportGround } = match;
    return {
      '@context': 'https://schema.org',
      '@type': 'SportsEvent',
      name: `${homeTeamName} vs. ${awayTeamName}`,
      url: getMatchUrl(match) || undefined,
      image: imageUrl,
      startDate: new Date(match.startDate).toISOString(),
      endDate: addHours(new Date(match.startDate), 2).toISOString(),
      homeTeam: {
        '@type': 'SportsTeam',
        url: undefined, // TODO:
        name: homeTeamName,
        image: getTeamLogoSrc(match.appSpace, homeTeam as any),
      },
      awayTeam: {
        '@type': 'SportsTeam',
        url: undefined, // TODO:
        name: awayTeamName,
        image: getTeamLogoSrc(match.appSpace, awayTeam as any),
      },
      location: {
        '@type': 'Place',
        name: sportGround?.sportObjectName || undefined,
        address: {
          '@type': 'PostalAddress',
          name: sportGround?.city || undefined,
        },
      },
    };
  });

  let raceMatchesData = [] as any[];
  if (raceMatches) {
    raceMatchesData = raceMatches.map((match) => {
      const { sportGround } = match;
      return {
        '@context': 'https://schema.org',
        '@type': 'SportsEvent',
        name: match.round?.name,
        endDate: addHours(new Date(match.startDate), 5).toISOString(),
        description:
          sportGround?.name || sportGround?.sportObjectName || undefined,
        startDate: new Date(match.startDate).toISOString(),
        url: getMatchUrl(match) || undefined,
        location: {
          '@type': 'Place',
          name: sportGround?.sportObjectName || undefined,
          address: {
            '@type': 'PostalAddress',
            name: `${sportGround?.city}, ${sportGround?.country}` || undefined,
          },
        },
      };
    });
  }

  const data = [...matchesData, ...raceMatchesData];

  if (!data.length) {
    return null;
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data, null, 2)}
      </script>
    </Helmet>
  );
};

export default React.memo(SDMatches);
