
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import Icon, {IIconName as IconName } from '../../components/Icon';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { LinkProps } from 'react-router';
import Link from '../../components/Link';
import { ButtonSize } from '../types/Button';
import React from 'react';

export const BaseButton = styled.button`
  margin: 0;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: 0;
  vertical-align: baseline;
  text-shadow: none;
  font-style: normal;
  text-decoration: none;
  user-select: none;
  font-family: ${({ theme }) => theme.primaryFont.family};
  :disabled {
    background-color: ${({ theme }) => theme.color.lightBackground};
    color: ${({ theme }) => theme.color.secondaryFontColor};
    :hover,
    :focus,
    :visited {
      background-color: ${({ theme }) => theme.color.lightBackground};
    }
  }
`;

export const StyledButton = styled(BaseButton)<{
  $size?: ButtonSize;
  $hasChildren?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: ${({ $hasChildren = false }) =>
    $hasChildren ? 'flex-start' : 'center'};
  font-weight: ${({ theme }) => theme.primaryFont.weight.bold};
  text-transform: uppercase;
  line-height: 1.3;
  letter-spacing: 0.88px;
  color: #fff;
  background-color: ${({ theme }) => theme.color.primaryColor};
  border: none;
  box-shadow: ${({ theme }) => theme.shadow.small};
  :hover,
  :active,
  :focus {
    background: ${({ theme }) => theme.color.primaryColor};
  }
  font-size: ${rem(10)};

  ${mb('s')} {
    font-size: ${rem(11)};
  }
  ${({ $size = 'large', $hasChildren = false }) =>
    $size === 'small'
      ? css`
          padding: 0 ${$hasChildren ? rem(16) : 0};
          height: ${rem(32)};
          width: ${$hasChildren ? 'auto' : rem(32)};
          border-radius: ${({ theme }) => rem(theme.border.borderRadiusSmall)};
        `
      : css`
          border-radius: ${({ theme }) => rem(theme.border.borderRadius)};
          height: ${rem(40)};
          width: ${$hasChildren ? 'auto' : rem(40)};
          padding: 0 ${$hasChildren ? rem(24) : 0};
          ${mb('s')} {
            height: ${rem(48)};
            width: ${$hasChildren ? 'auto' : rem(48)};
          }
        `}
  .icon {
    margin-right: ${({ $hasChildren = false }) => ($hasChildren ? rem(8) : 0)};
  }
`;

interface OwnProps {
  /**
   * Pretaženie styled-components. Štýluje sa priamo <button> element.
   */
  className?: string;
  /**
   * Children komponenty, ktore sa renderuje v ramci `<button></button>`.
   */
  children?: React.ReactNode;
  /**
   * Ak je zadany `href` renderuje `<a`>. Ma prednost pred `onClick` handlerom.
   * @returns
   */
  href?: LinkProps['to'];
  /**
   * Ak nie je zadany `href` pripaja `onClick` event. Ak je zadany `href`
   * a `onClick` sucasne, `href` ma prednost a renderuje `<a>` tag namiesto `<button>`.
   * @returns
   */
  onClick?: () => void;
  /**
   * Velkost tlacidla.
   */
  size?: ButtonSize;
  /**
   * Nastavuje atribut `disabled`.
   */
  disabled?: boolean;
  /**
   * Nastavuje `title` atribut.
   */
  title?: string;
  /**
   * Ikona pred text buttonu. POZOR: Musí mať štvorcový formát!
   */
  icon?: IconName;
}

export const PrimaryButton: React.FC<OwnProps> = ({
  className,
  children,
  href,
  onClick,
  size = 'large',
  disabled = false,
  title,
  icon,
}) => (
  <StyledButton
    onClick={!href ? onClick : undefined}
    className={className}
    $size={size}
    disabled={disabled}
    $hasChildren={typeof children !== 'undefined'}
    title={title}
    {...(href ? { to: href } : undefined)}
    as={href ? Link : 'button'}
  >
    {icon && <Icon name={icon} size="m" color="currentColor" />}
    {children}
  </StyledButton>
);
