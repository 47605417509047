import React from 'react';
import { withRouter } from 'react-router';
import Hider from '../../components/Hider';
import useClientVariable from '../../hooks/useClientVariable';
import { RouteProps } from '../../library/App';
import getMatchBaseUrl from '../../utilities/getMatchBaseUrl';
import HeadToHead from './HeadToHead';
import Nomination from './Nomination';
import Overview from './Overview';
import Table from './Table';

type IOwnProps = {
  showTabulka: boolean;
};

type IProps = IOwnProps & RouteProps<{ zvaz: string; zapas: string }>;

const TabPages: React.FC<IProps> = ({ showTabulka, location, params: { zvaz, zapas } }) => {
  const hash = useClientVariable(location.hash, '');

  const overviewTabActive =
    location.pathname === getMatchBaseUrl({ zvaz, zapas }) && !hash;

  const nominationTabActive = hash === '#zostavy';
  const tableTabActive = showTabulka && hash === '#tabulka';
  const h2hTabActive = hash === '#h2h';

  return (
    <>
      <Hider hidden={!overviewTabActive}>
        <Overview isActive={overviewTabActive} />
      </Hider>
      <Hider hidden={!nominationTabActive}>
        <Nomination isActive={nominationTabActive} />
      </Hider>
      {tableTabActive && <Table />}
      {h2hTabActive && <HeadToHead />}
    </>
  );
};

export default withRouter(TabPages) as React.FC<IOwnProps>;
