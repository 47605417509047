/**
 * Skrati nazov kola pre mobil
 * @param roundName
 */
const shortenRoundName = (roundName: string) => {
  const lower = roundName.toLocaleLowerCase();
  if (lower.startsWith('skupina')) {
    return roundName.replace('upina', '.');
  } else if (lower.startsWith('semi')) {
    return 'Semi.';
  } else if (lower.startsWith('štvrť')) {
    return 'Štvrť.';
  } else if (lower.startsWith('fin')) {
    return 'Finále';
  } else if (lower.startsWith('o 3.')) {
    return 'o 3.m.';
  }
  return roundName;
};

export default shortenRoundName;
