import React from 'react';
import styled, { css } from '../../theme/styled-components';

export default styled.div<{ hidden: boolean }>`
  ${({ hidden }) =>
    hidden &&
    css`
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
    `}
` as React.FC<{ className?: string; hidden: boolean; [key: string]: any }>;
