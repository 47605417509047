import { mb, mediaSizes } from '@sportnet/ui/lib/Themes/utilities';
import { rem, transparentize } from 'polished';
import React from 'react';
import config from '../../../config';
import { IArticle } from '../../../library/App';
import styled, { css } from '../../../theme/styled-components';
import Icon from '../../Icon';
import ArticleContentTypeIcon from '../ArticleContentTypeIcon';

const DoctypeIconWrapper = styled.span<{
  compactBreakPoint: keyof typeof mediaSizes | number;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => transparentize(0.2, theme.color.primary)};
  width: ${rem(56)};
  height: ${rem(56)};
  margin: auto;
  padding-left: ${rem(4)};
  ${({ compactBreakPoint }) =>
    compactBreakPoint &&
    css`
      width: ${rem(32)};
      height: ${rem(32)};
      padding-left: ${rem(3)};
      svg {
        width: 10px;
        height: 10px;
      }
      ${mb(compactBreakPoint)} {
        width: ${rem(56)};
        height: ${rem(56)};
        padding-left: ${rem(4)};
        svg {
          width: 16px;
          height: 16px;
        }
      }
    `}
`;

type IOwnProps = {
  article: IArticle;
  compactBreakPoint?: keyof typeof mediaSizes | number;
  aspectRatio?: string;
};

type IProps = IOwnProps;

const ArticleIcon: React.FC<IProps> = ({
  article,
  compactBreakPoint = 0,
  aspectRatio = '',
}) => {
  if (article.doctype === config.VIDEO_ARTICLE_DOCTYPE) {
    return (
      <DoctypeIconWrapper compactBreakPoint={compactBreakPoint}>
        <Icon name="video" size={16} color="#fff" />
      </DoctypeIconWrapper>
    );
  }

  return (
    <ArticleContentTypeIcon
      article={article}
      compactBreakPoint={compactBreakPoint}
      aspectRatio={aspectRatio}
    />
  );
};

export default React.memo(ArticleIcon);
