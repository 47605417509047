import { rem } from 'polished';
import React from 'react';
import ArticlesList from '../../components/Articles/ArticlesList';
import LoadMorePanel from '../../components/LoadMorePanel';
import { IArticle } from '../../library/App';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const PositionedLoadMorePanel = styled(LoadMorePanel)`
  max-width: ${rem(680)};
`;

type IOwnProps = {
  articles: IArticle[];
  loading: boolean;
  nextOffset?: number | null;
  onLoadMoreWithOffset: (nextOffset: number) => void;
  widthAd?: boolean;
};

type IProps = IOwnProps;

const NewsFeedArticlesBase: React.FC<IProps> = ({
  articles,
  loading,
  nextOffset,
  onLoadMoreWithOffset,
  widthAd,
}) => {
  return (
    <>
      <ArticlesList articles={articles} widthAd={widthAd} />
      {nextOffset !== null && (
        <PositionedLoadMorePanel
          onLoadMore={() => {
            if (nextOffset !== void 0 && !loading) {
              onLoadMoreWithOffset(nextOffset);
            }
          }}
          loading={loading}
          title={__('Načítať ďalšie články')}
        />
      )}
    </>
  );
};

export default NewsFeedArticlesBase;
