import baseTheme from '@sportnet/ui/lib/Themes/baseTheme';
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';

export const sportnetUiTheme = {
  table: baseTheme.table,
  tooltip: baseTheme.tooltip,
  separatorColor: baseTheme.separatorColor,
  color: {
    primary: baseTheme.color.primary,
    fadedText: baseTheme.color.fadedText,
    primaryBg: '#fff',
  },
  inactiveColor: baseTheme.inactiveColor,
  grid: {
    gutterWidth: baseTheme.grid.gutterWidth,
  },
  baseBorderRadius: baseTheme.baseBorderRadius,
  borderRadius: baseTheme.baseBorderRadius,
  largeBorderRadius: baseTheme.largeBorderRadius,
  responsiveMenuPrimary: baseTheme.responsiveMenuPrimary,
  responsiveMenu: baseTheme.responsiveMenu,
  textColor: baseTheme.textColor,
  fontFamily: "open-sans, sans-serif",
  form: baseTheme.form,
  input: baseTheme.input,
  formGroup: baseTheme.formGroup,
};

export type ISportnetUiTheme = typeof sportnetUiTheme;

const { ThemeProvider } =
  styledComponents as unknown as ThemedStyledComponentsModule<ISportnetUiTheme>;

export { ThemeProvider };
