import Loader from '@sportnet/ui/lib/Loader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { isCommiting } from '@sportnet/redux-list/ducks';
import { ILatestResolutions } from '../../api/FutbalnetApi';
import Link from '../../components/Link';
import ShowMoreButtonTable from '../../components/ShowMoreButton/ShowMoreButtonTable';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '../../components/Table/TableCompact';
import config from '../../config';
import { RootState } from '../../configureStore';
import styled from '../../theme/styled-components';
import getCommiteeBaseUrl from '../../utilities/getCommiteeBaseUrl';
import getCommiteeResolutionBaseUrl from '../../utilities/getCommiteeResolutionBaseUrl';
import __ from '../../utilities/__';

const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.blue};
  text-decoration: underline;
`;

const TableWrapper = styled.div`
  display: none;
  ${mb('s')} {
    display: block;
  }
`;

const MobileTable = styled.div`
  display: block;
  ${mb('s')} {
    display: none;
  }
`;

const MobileTitle = styled.div`
  padding-top: ${rem(4)};
`;

const PositionedTable = styled(Table)`
  margin-bottom: ${rem(16)};
  background-color: red;
`;

const TablesWrapper = styled.div`
  > *:not(:first-child) {
    margin-top: ${rem(24)};
  }
`;

type IOwnProps = {
  zvaz: string | number;
  commitees: ILatestResolutions[];
};

const mapStateToProps = (state: RootState) => {
  return {
    isLoading:
      isCommiting(config.LIST_COMMITEES_WITH_LATEST_RESOLUTIONS)(state) 
  };
};

const mapDispatchToProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const CommiteesWidget: React.FC<IProps> = ({ commitees, zvaz, isLoading }) => {
  const renderCommitee = (commitee: ILatestResolutions) => {
    return (
      <div key={commitee.id}>
        <TableWrapper>
          <PositionedTable>
            <Thead>
              <Tr>
                <Th colSpan="2">{commitee.name}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(commitee.latestresolutions?.resolutions || [])
                .slice(0, 2)
                .map((resolution) => (
                  <Tr key={resolution.number}>
                    <Td>
                      <CustomLink
                        to={getCommiteeResolutionBaseUrl({ zvaz, resolution })}
                      >
                        {resolution.number}
                      </CustomLink>
                    </Td>
                    <Td>{resolution.title}</Td>
                  </Tr>
                ))}
            </Tbody>
          </PositionedTable>
        </TableWrapper>
        <MobileTable>
          <PositionedTable>
            <Thead>
              <Tr>
                <Th>{commitee.name}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(commitee.latestresolutions?.resolutions || [])
                .slice(0, 2)
                .map((resolution) => (
                  <Tr key={resolution.number}>
                    <Td>
                      <CustomLink
                        to={getCommiteeResolutionBaseUrl({ zvaz, resolution })}
                      >
                        {resolution.number}
                      </CustomLink>
                      <MobileTitle>{resolution.title}</MobileTitle>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </PositionedTable>
        </MobileTable>
        <ShowMoreButtonTable
          to={getCommiteeBaseUrl({ zvaz, comiteeId: String(commitee.id) })}
          text={__('Zobraziť viac')}
        />
      </div>
    );
  };

  return commitees && commitees.length > 0 ? (
    <TablesWrapper>
      {commitees.map((commitee) => renderCommitee(commitee))}
    </TablesWrapper>
  ) : isLoading ? (
    <Loader />
  ) : (
    <>{__('Komisie neprijali v tejto sezóne žiadne rozhodnutia.')}</>
  );
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CommiteesWidget),
);
