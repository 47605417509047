import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20.9,8.6c-0.1-0.1-0.1-0.2-0.2-0.3l-7-7c-0.1-0.1-0.2-0.2-0.3-0.2C13.3,1,13.1,1,13,1H4C3.4,1,3,1.4,3,2v20
    c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V9C21,8.9,21,8.7,20.9,8.6z M14,4.4L17.6,8H14V4.4z M5,21V3h7v6c0,0.6,0.4,1,1,1h6v11H5z"
    />
  </svg>
);
