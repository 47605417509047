import { AsyncReturnType } from '../library/App';
import { IMemberDelegateMProfile } from '../library/Competitions';
import FutbalnetApi from '../pages/Member/api';

/**
 * ...len potrebne fieldy pre detail delegata stretnuai
 */
const reduceDelegateMProfile = (
  delegateMProfile: AsyncReturnType<typeof FutbalnetApi['getDelegateM']>,
): IMemberDelegateMProfile => {
  const reduced: IMemberDelegateMProfile = {
    profileStatus: delegateMProfile.profileStatus,
    registrationDate: delegateMProfile.registration_date,
  };
  return reduced;
};

export default reduceDelegateMProfile;
