import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getListResultsByParams,
  isCommittingByParams,
} from '@sportnet/redux-list/ducks';
import styled, { withTheme } from 'styled-components';
import AdManager from '../../components/Ads/AdManager';
import BottomPaddedBigAd from '../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../components/Ads/MegaboardAd';
import TopAd from '../../components/Ads/TopAd';
import FutbalnetHeader from '../../components/FutbalnetHeader';
import FutbalnetTitleBlock from '../../components/FutbalnetHeader/FutbalnetTitleBlock';
import FutbalnetTitle from '../../components/FutbalnetHeader/Title';
import Icon from '../../components/Icon';
import {
  ContextMarginSmallBottomStyles,
} from '../../components/Layout';
import Loading from '../../components/Loading';
import MaxWidthBox from '../../components/MaxWidthBox';
import config from '../../config';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import FutbalnetUnionBreadcrumbs from '../../containers/FutbalnetBreadcrumbs/FutbalnetUnionBreadcrumbs';
import RempIntegration from '../../containers/RempIntegration';
import { ITheme } from '../../theme/theme';
import __ from '../../utilities/__';
import { initializeOrSetListParams } from '../App/actions';
import { getSportnetMainMenuSectionByUiniqId, staticContentSelector } from '../App/selectors';
import { loadListingArticlesList } from '../Article/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';
import {
  seasonCompetitionsListSelector,
  unionSeasonsSelector,
  unionsSelector,
} from '../FutbalnetApp/selectors';
import { setCurrentSectionId } from '../Section/actions';
import StaticInfoSidebar from '../Section/pages/MainSection/StaticInfoSidebar';
import FutbalnetHomeDetail from './detail';
import FutbalnetHomeHeader from './FutbalnetHomeHeader';

const Separator = styled.div`
  margin-top: ${rem(16)};
  ${mb('l')} {
    margin-top: 0;
  }
`;

const PaddedIcon = styled(Icon)`
  margin-right: ${rem(8)};
  ${mb('s')} {
    margin-right: ${rem(12)};
    svg {
      height: ${rem(32)};
      width: ${rem(32)};
    }
  }
`;

export const ResponsiveAdditionalInfoSidebar = styled(StaticInfoSidebar)`
  ${ContextMarginSmallBottomStyles}
  ${mb('l')} {
    display: none;
  }
`;

const mainArticlesDescriptor = {
  listName: config.LIST_FUTBALNET_HOMEPAGE_ARTICLES,
  parameters: {
    limit: 15,
    offset: 0,
    listingId: config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID,
  },
};
const mapStateToProps = (state: RootState) => {
  const section = getSportnetMainMenuSectionByUiniqId(state, { sectionIdOrUniqId: 'Futbalnet' });
  const appSettings = appSettingsSelector(state);
  const appSpace = appSettings.CSMAppSpace || '';
  const { settings = {} } = (section as any) ?? {};
  const { SIDEBAR_STATIC_CONTENT_ID } = settings;
  return {
    section,
    appSpace,
    competitions: seasonCompetitionsListSelector(state),
    isLoading:
      isCommittingByParams(state, mainArticlesDescriptor) !== false &&
      !getListResultsByParams(state, mainArticlesDescriptor),
    unions: unionsSelector(state),
    seasons: unionSeasonsSelector(state),
    SIDEBAR_STATIC_CONTENT_ID,
    staticInfoContent:
      staticContentSelector(state, { appSpace, cid: SIDEBAR_STATIC_CONTENT_ID })
        ?.content || [],
  };
};

type Props = { theme: ITheme } & ReturnType<typeof mapStateToProps> & {
    dispatch: CustomThunkDispatch;
  };

class FutbalnetHome extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch} = props;
    const sectionId = config.FUTBALNET_SECTION_ID;
    dispatch(setCurrentSectionId(sectionId));

    // await dispatch(
    //   initializeOrSetListParams({
    //     listName: config.LIST_FEATURED_VIDEO_ARTICLES,
    //     params: {
    //       offset: 0,
    //       limit: 4,
    //       doctypes: ['video'],
    //     },
    //   }),
    // );

    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_FUTBALNET_HOMEPAGE_ARTICLES,
        params: {
          limit: 15,
          offset: 0,
          listingId: config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID,
        },
      }),
    );

    return Promise.all([
      dispatch(
        loadListingArticlesList(config.LIST_FUTBALNET_HOMEPAGE_ARTICLES),
      ),
    ]);
  }

  async componentDidMount() {
    try {
      await FutbalnetHome.getInitialProps(this.props);
    } catch (e: any) {
      /* */
    }
  }

  handleLoadMoreArticlesWithOffset = async (offset: number) => {
    const { dispatch } = this.props;

    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_FUTBALNET_HOMEPAGE_ARTICLES,
        params: {
          limit: 10,
          offset,
          listingId: config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID,
        },
      }),
    );

    return dispatch(
      loadListingArticlesList(config.LIST_FUTBALNET_HOMEPAGE_ARTICLES),
    );
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setCurrentSectionId(null));
  }

  render() {
    const { isLoading, staticInfoContent } = this.props;
    return (
      <>
        <AdManager
          site="home_page"
          siteId="futbalnet"
          origin="futbalnet"
          pagetype="list"
          rubric="futbalnet"
        />
        <RempIntegration />
        <FutbalnetHomeHeader />
        <FutbalnetHeader>
          <FutbalnetTitleBlock>
            <PaddedIcon name="futbal" color="#CF2528" size={24} />
            <FutbalnetTitle>{__('Futbalnet')}</FutbalnetTitle>
          </FutbalnetTitleBlock>
        </FutbalnetHeader>
        <TopAd />
        <Separator />
        <BottomPaddedBigAd name="big_1" />
        {isLoading ? (
          <Loading />
        ) : (
          <FutbalnetHomeDetail
            onLoadMoreArticlesWithOffset={this.handleLoadMoreArticlesWithOffset}
          />
        )}
        <MaxWidthBox>
        <ResponsiveAdditionalInfoSidebar content={staticInfoContent} />
        </MaxWidthBox>
        <MegaboardAd />
        <FutbalnetUnionBreadcrumbs crumbs={[]} />
      </>
    );
  }
}

export default compose(withTheme, connect(mapStateToProps))(FutbalnetHome);
