import React from 'react';
import useWindowSize from '../../utilities/useWindowSize';

function useIsResponsiveLayout(breakpoint = 768) {
  const resizeHook = useWindowSize();

  const mobileLayout = React.useMemo(() => {
    return resizeHook.outerWidth ? resizeHook.outerWidth <= breakpoint : false;
  }, [resizeHook.outerWidth, breakpoint]);

  return mobileLayout;
}

export default useIsResponsiveLayout;
