import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M22,15v4c0,1.7-1.3,3-3,3H5c-1.7,0-3-1.3-3-3v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4c0,0.6,0.4,1,1,1h14
    c0.6,0,1-0.4,1-1v-4c0-0.6,0.4-1,1-1S22,14.4,22,15z M11.3,15.7c0.1,0.1,0.2,0.2,0.3,0.2C11.7,16,11.9,16,12,16s0.3,0,0.4-0.1
    c0.1-0.1,0.2-0.1,0.3-0.2l5-5c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L13,12.6V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.6L7.7,9.3
    c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L11.3,15.7z"
    />
  </svg>
);
