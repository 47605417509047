import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import React from 'react';
import { withRouter } from 'react-router';
import useClientVariable from '../../../hooks/useClientVariable';
import { RouteProps } from '../../../library/App';
import styled from '../../../theme/styled-components';
import isBrowser from '../../../utilities/isBrowser';
import { HideScrollBarStyles } from '../../Layout/ScrollStyles';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import FullWidthPositionedDropdown from './FullWidthPositionedDropdown';
import MenuItem from './MenuItem';
import PositionedDropdown from './PositionedDropdown';
import { IMenuItem } from './types';

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.primaryFont.family };
  max-width: ${({ theme }) => rem(theme.app.maxWidth)};
  ${mb(1232)} {
    padding: 0 ${({ theme }) => rem(theme.app.padding)};
  }
  margin: 0 auto ${rem(-1)} auto;
  width: 100%;
  flex: 1 0 0;
  position: relative;
  padding-right: ${rem(12)};
`;

const ScrollWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin: 0 0 ${rem(-1)} 0;
  ${HideScrollBarStyles};
  scrollbar-width: none;
`;

const Menu = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  width: max-content;
  padding: 0 ${({ theme }) => rem(theme.app.padding)};
  ${mb(1232)} {
    padding: 0;
  }
`;

const Line = styled.hr`
  margin: 0;
  border: none;
  border-top: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  height: ${rem(1)};
  width: 100%;
  position: relative;
  z-index: 1;
`;

interface OwnProps {
  items: IMenuItem[];
  activeItemId?: string;
}

type Props = OwnProps & RouteProps;
type StaticProps = {
  PositionedDropdown: typeof PositionedDropdown;
  FullWidthPositionedDropdown: typeof FullWidthPositionedDropdown;
  Dropdown: typeof Dropdown;
};

const FutbalnetMenu: React.FC<Props> & StaticProps = ({
  items,
  location,
  activeItemId: defaultActiveItemId = '',
}) => {
  const menuRef = React.useRef<HTMLUListElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const [activeItemId, setActiveItemId] = React.useState('');
  const hash = useClientVariable(location.hash, '');

  const handleResetActiveItem = React.useCallback(() => {
    setActiveItemId('');
  }, []);

  React.useEffect(() => {
    handleResetActiveItem();
  }, [location.pathname, handleResetActiveItem]);

  const handleClickOutside = (e: any) => {
    e.stopPropagation();
    if (!wrapperRef.current?.contains(e.target as Node)) {
      handleResetActiveItem();
    }
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.keyCode === 27) {
      handleResetActiveItem();
    }
  };

  if (isBrowser()) {
    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);
  }

  const handleItemClick = React.useCallback((item: IMenuItem) => {
    if (item.renderDropdown) {
      setActiveItemId((prev) => (prev === item.id ? '' : item.id));
    } else {
      setActiveItemId(item.id);
    }
  }, []);

  const isItemActive = (item: IMenuItem) => {
    return activeItemId
      ? item.id === activeItemId
      : item.hash
      ? hash.startsWith(item.hash)
      : defaultActiveItemId
      ? item.id === defaultActiveItemId
      : false;
  };

  const isAnyItemActive = !!items.find(isItemActive);

  const renderMenuItem = (item: IMenuItem) => {
    return (
      <MenuItem
        key={item.id}
        item={item}
        active={isItemActive(item)}
        onClick={handleItemClick}
        isAnyActive={isAnyItemActive}
      />
    );
  };

  const getDropdownPosition = (idx: number) => {
    const menuEl = menuRef.current;
    const wrapperEl = wrapperRef.current;
    if (menuEl && wrapperEl) {
      const pos =
        (menuEl.children[idx]?.getBoundingClientRect().x || 0) -
        wrapperEl.getBoundingClientRect().x;
      return pos > 0 ? pos : 0;
    }
    return 0;
  };

  const renderDropdowns = (item: IMenuItem, idx: number) => {
    if (item.renderDropdown) {
      return (
        <DropdownItem
          key={item.id}
          item={item}
          xPosition={getDropdownPosition(idx)}
          active={isItemActive(item)}
          onClose={handleResetActiveItem}
        />
      );
    }
    return null;
  };

  return (
    <Wrapper ref={wrapperRef}>
      <ScrollWrapper>
        <Menu ref={menuRef}>{items.map(renderMenuItem)}</Menu>
      </ScrollWrapper>
      <Line />
      {items.map(renderDropdowns)}
    </Wrapper>
  );
};

FutbalnetMenu.PositionedDropdown = PositionedDropdown;
FutbalnetMenu.FullWidthPositionedDropdown = FullWidthPositionedDropdown;
FutbalnetMenu.Dropdown = Dropdown;

export default withRouter(FutbalnetMenu) as React.FC<OwnProps> & StaticProps;
