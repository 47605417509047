import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill="#f9f9f9"
      d="M20,0c11.04569,0,20,8.9543,20,20s-8.95431,20-20,20S0,31.04569,0,20,8.9543,0,20,0Z"
    />
    <path
      fill="#ddd"
      d="M20.00001,39.99996c-6.32086,0-11.94745-2.93988-15.61255-7.51825,.53107-1.08167,2.69891-2.18872,6.36255-3.48175,4.25-1.5,5.25-3,5.25-5.75v-.75c-.5-1-1.5-.75-2-3.25-.25-1.25-1-.25-1.25-3.25,0-1,.75-1.25,.75-1.25-.5-1-.5-2.25-.75-3.25,0-1.5,1.25-5.74994,7.25-5.74994s7.25,4.24994,7.25,5.74994c-.25,1-.5,2.25-.75,3.25,0,0,.75,0,.75,1.25-.25,3-1,2-1.25,3.25-.5,2.5-1.75,2.5-2,3.25v.75c0,2.75,.75,4.25,5.25,5.75,3.66364,1.29303,5.83148,2.40009,6.36255,3.48175-3.6651,4.57837-9.29169,7.51825-15.61255,7.51825Z"
    />
  </svg>
);
