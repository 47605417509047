import React from 'react';
import styled from 'styled-components';
import ConciseWidgets from '../../../../components/Widgets/ConciseWidgets';
import { IWidgets } from '../../../../library/App';
type IOwnProps = {
  content: IWidgets;
  className?: string;
};

const Wrapper = styled.div``;

type IProps = IOwnProps;

const StaticInfoSidebar: React.FC<IProps> = ({ content, className }) => {
  if (!content.length) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <ConciseWidgets items={content} />
    </Wrapper>
  );
};

export default React.memo(StaticInfoSidebar);
