import { rem } from 'polished';
import React from 'react';
import { IArticle } from '../../../library/App';
import { EntitiesState } from '../../../pages/App/reducer';
import styled, { css } from '../../../theme/styled-components';
import getArticleMainSection from '../../../utilities/getArticleMainSection';
import Article from './Article';
import { mb } from '@sportnet/ui/lib/Themes/utilities';

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-shrink: 0;
  padding: 0 ${({ theme }) => rem(theme.app.padding)};

  ${({ theme }) => css`
    width: calc(100% + ${rem(theme.app.padding * 2)});
    margin: ${rem(theme.app.padding)} ${rem(theme.app.padding)}
      ${rem(theme.app.padding)} ${rem(-theme.app.padding)};
  `};
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `};
  overflow-x: auto;
  display: flex;
  ${mb('s')} {
    display: none;
  }
`;

type IOwnProps = {
  articles: IArticle[];
  sectionsById: EntitiesState['sections'];
  className?: string;
};

type IProps = IOwnProps;

const ArticlesListHorizontallyScrollable: React.FC<IProps> = ({
  articles,
  sectionsById,
  className,
}) => {
  if (!articles.length) {
    return null;
  }

  function renderArticle(article: IArticle) {
    const section = getArticleMainSection(article, sectionsById);
    return (
      <Article
        key={`${article._id}-${article.modified}`}
        article={article}
        section={section}
      />
    );
  }

  return (
    <Wrapper>
      <List className={className}>{articles.map(renderArticle)}</List>
    </Wrapper>
  );
};

export default React.memo(ArticlesListHorizontallyScrollable);
