import useQuery, {
  QueryHocInterface,
  QueryHocTypes,
} from '@sportnet/query-hoc';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import Loading from '../../../components/Loading';
import config from '../../../config';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import { RouteProps } from '../../../library/App';
import floorMinutes from '../../../utilities/floorMinutes';
import __ from '../../../utilities/__';
import { currentOrganizationProfileSelector } from '../../Competition/selectors';
import { loadClubCompetitions, loadClubMatches } from '../actions';
import { currentClubCompetitionsSelector } from '../selectors';
import ClubResultsDetail from './detail';
import AdManager from '../../../components/Ads/AdManager';
import getCleandedPathFromLocation from '../../../utilities/adverts/getCleanedPathFromLocation';

const mapStateToProps = (state: RootState) => ({
  isFetchingMatches: isCommiting(config.LIST_CLUB_RESULTS)(state),
  isFetchingCompetitions: isCommiting(config.LIST_CLUB_COMPETITIONS)(state),
  competitions: currentClubCompetitionsSelector(state),
  nextOffset: getListNextOffset(config.LIST_CLUB_RESULTS)(state),
  organizationProfile: currentOrganizationProfileSelector(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
  dispatch: CustomThunkDispatch;
} & QueryHocInterface &
  RouteProps<{ klub: string; tim?: string }>;

class ClubResults extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const promises: any[] = [];
    if (!props.competitions || props.competitions.length === 0) {
      promises.push(dispatch(loadClubCompetitions.action()));
    }
    if (props.params.tim) {
      promises.push(
        dispatch(
          loadClubMatches({
            listName: config.LIST_CLUB_RESULTS,
            dateBefore: floorMinutes(new Date()),
            closed: true,
            limit: config.LIST_CLUB_RESULTS_LIMIT,
            offset: 0,
            competitionId: String(props.query.sutaz),
            partId: String(props.query.partId),
            teamId: props.params.tim,
          }),
        ),
      );
    }
    return Promise.all(promises);
  }

  async componentDidMount() {
    await ClubResults.getInitialProps(this.props);
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      prevProps.query.sutaz !== this.props.query.sutaz ||
      prevProps.query.partId !== this.props.query.partId ||
      prevProps.query.seasonName !== this.props.query.seasonName ||
      prevProps.params.tim !== this.props.params.tim
    ) {
      return this.props.dispatch(
        loadClubMatches({
          listName: config.LIST_CLUB_RESULTS,
          dateBefore: floorMinutes(new Date()),
          closed: true,
          limit: config.LIST_CLUB_RESULTS_LIMIT,
          offset: 0,
          competitionId: String(this.props.query.sutaz),
          partId: String(this.props.query.partId),
          teamId: this.props.params.tim,
        }),
      );
    }
  }

  render() {
    const {
      nextOffset,
      dispatch,
      isFetchingCompetitions,
      params: { tim },
      organizationProfile,
      location,
    } = this.props;
    if (isFetchingCompetitions) {
      return <Loading />;
    }

    const pageName = __('výsledky');
    const title = `${organizationProfile?.title} - ${pageName}`;
    const description = `${organizationProfile?.name}, ${pageName}: ${__(
      'Ako dopadli najnovšie zápasy? Pozrite si live výsledky, program zápasov, štatistiky, súpisku.',
    )}`;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
        </Helmet>
        <AdManager
          site="futbalnet_klub"
          siteId={getCleandedPathFromLocation(location)}
        />
        <ClubResultsDetail
          onLoadMore={() => {
            dispatch(
              loadClubMatches({
                listName: config.LIST_CLUB_RESULTS,
                dateBefore: floorMinutes(new Date()),
                closed: true,
                limit: config.LIST_CLUB_RESULTS_LIMIT,
                offset: nextOffset || 0,
                competitionId: String(this.props.query.sutaz),
                partId: String(this.props.query.partId),
                teamId: tim,
              }),
            );
          }}
        />
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  useQuery({
    parameters: {
      sutaz: { type: QueryHocTypes.String, defaultValue: '' },
      partId: { type: QueryHocTypes.String, defaultValue: '' },
    },
  }),
)(ClubResults);
