import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { format, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import skLocale from 'date-fns/locale/sk';
import moment from 'moment';
import { rem } from 'polished';
import * as React from 'react';
import getProp from 'sportnet-utilities/lib/getProp';
import { css } from 'styled-components';
import CompetitionsApi from '../../../../api/CompetitionsApi';
import { setupCompetitionsApi } from '@sportnet/content/api/setupApis';
import config from '@sportnet/content/utilities/config';
import {
  ILiveState,
  IMatchSettings,
  IProtocol,
  ISportSectorPhase,
  ISportSectorPhasesItem,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/library/Competitions';
import styled from '@sportnet/content/theme/styled-components';
import { ITheme } from '@sportnet/content/theme/theme';
import { __ } from '@sportnet/content/utilities/utilities';
import { Icon } from '@sportnet/content/view/widgets/MatchDetail/TimeLine';
import {
  cleanRoundName,
  getTeam,
  TeamSide,
} from '@sportnet/content/view/widgets/MatchesList/utilities';
import TeamComponent from './Team';

setupCompetitionsApi();

interface IOwnProps {
  match: IWidgetMatchesListMatchItem;
  matchSize?: number;
  lastIndex: boolean;
  theme: ITheme;
  sportSectorsPhases: ISportSectorPhasesItem[];
  fullWidth: boolean;
  compact?: boolean;
  verbose?: boolean;
  settings: IMatchSettings;
  backgroundImage?: string;
  onLiveScoreChange?: ({
    liveState,
    timer,
    protocol,
  }: {
    liveState: ILiveState;
    timer: {
      [key: string]: {
        start: {
          date: string;
          seconds?: number;
        };
        end?: {
          date: string;
          seconds?: number;
        };
      };
    };
    protocol: IProtocol;
  }) => void;
}

interface IMatchWrapperProps {
  lastIndex: boolean;
  matchSize?: number;
  fullWidth: boolean;
  compact: boolean;
  verbose: boolean;
  backgroundImage?: string;
}

const GoalsOverview = styled.div`
  font-size: ${rem(11)};
  width: 100%;
  display: none;
  ${mb('m')} {
    display: flex;
  }
`;

const GoalsOverviewTeamCol = styled.div`
  width: 50%;
  display: flex;
  padding: 0 ${rem(10)};
  flex-direction: column;
`;

const GoalsOverviewPlayer = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(5)} 0px;
`;

const GoalsOverviewPlayerName = styled.div``;

const GoalsOverviewHomeTeamCol = styled(GoalsOverviewTeamCol)`
  align-items: flex-end;
  border-right: ${rem(1)} dotted ${({ theme }) => theme.separatorColor};
  ${GoalsOverviewPlayerName} {
    padding-right: ${rem(10)};
  }
`;

const GoalsOverviewAwayTeamCol = styled(GoalsOverviewTeamCol)`
  align-items: flex-start;
  ${GoalsOverviewPlayer} {
    flex-direction: row-reverse;
  }
  ${GoalsOverviewPlayerName} {
    padding-left: ${rem(10)};
  }
`;

export const MatchWrapper = styled.div<IMatchWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ backgroundImage }) => {
    if (backgroundImage) {
      return css`
        background: url('${backgroundImage}');
        background-size: cover;
        background-position: center;
      `;
    }
    return null;
  }}
  ${({ matchSize }) => {
    if (matchSize) {
      return css`
        width: ${rem(matchSize)};
      `;
    }
    return css`
      width: 100%;
    `;
  }};
  height: 100%;
  ${({ lastIndex, fullWidth, compact, theme }) => {
    if (compact) {
      return css`
        width: 100%;
      `;
    }
    if (!lastIndex && !fullWidth) {
      return css`
        border-right: ${rem(1)} solid ${theme.separatorColor};
      `;
    }
    return css``;
  }}
  ${({ verbose, theme }) => {
    if (verbose) {
      return `${mb('m')} {
        position: relative;
        padding: ${rem(20)};
        margin: auto;
        .content-widget-matchOverview-teamLogo {
          max-height: ${rem(150)};
          max-width: 100%;
        }
        .content-widget-matchOverview-teamName {
          font-size: ${rem(17)};
          padding-top: ${rem(10)};
        }
        ${LiveScore} {
          font-size: ${rem(38)};
        }
        ${MatchStatusIndicator} {
          font-size: ${rem(11)};
        }
        ${GoalsByPhases} {
          font-size: ${rem(12)};
        }
        ${MatchDate} {
          display: block;
        }
        ${MatchTime} {
          display: block;
        }
      }`;
    }
    return css``;
  }}
`;

export const LiveScore = styled.div<{ compact: boolean }>`
  font-size: ${rem(21)};
  padding: ${rem(5)} ${rem(10)};
  width: 40%;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ compact }) => {
    if (compact) {
      return css`
        width: 20%;
      `;
    }
    return css``;
  }}
`;
export const MatchStatusIndicator = styled.div<{ color: string }>`
  font-size: ${rem(9)};
  color: ${({ color }) => color};
  text-transform: uppercase;
  padding-top: ${rem(5)};
`;
export const LiveTime = styled.div`
  font-size: ${rem(11)};
  color: ${({ theme }) => theme.color.danger};
  font-weight: normal;
`;
export const GoalsByPhases = styled.div`
  font-size: ${rem(11)};
  font-weight: normal;
`;
const MatchDate = styled.div`
  font-weight: 300;
  font-size: ${rem(14)};
  display: block;
  ${mb('m')} {
    font-weight: 300;
    font-size: ${rem(17)};
  }
`;
const MatchTime = styled.div`
  font-size: ${rem(12)};
  font-weight: 400;
  display: block;
  ${mb('m')} {
    font-size: ${rem(15)};
    font-weight: 400;
  }
`;
const MatchSportGround = styled.div`
  font-size: ${rem(14)};
  font-weight: 300;
`;

export type Props = IOwnProps;

const MatchWrapperLink = styled.a<{ isLive: boolean }>`
  width: 100%;
  display: flex;
  text-decoration: none;
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : '#333')};
`;

const MatchWrapperDiv = styled.div<{ isLive: boolean }>`
  width: 100%;
  display: flex;
  color: ${({ isLive, theme }) => (isLive ? theme.color.danger : '#333')};
`;

const MatchDetailLink = ({
  href,
  isLive,
  children,
}: {
  href: string;
  isLive?: boolean;
  children: any;
}) => {
  if (href) {
    return (
      <MatchWrapperLink
        href={href}
        isLive={!!isLive}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </MatchWrapperLink>
    );
  }
  return <MatchWrapperDiv isLive={!!isLive}>{children}</MatchWrapperDiv>;
};

class Match<T extends Props> extends React.PureComponent<
  T,
  {
    live: {
      liveState: ILiveState;
      timer: {
        [key: string]: {
          start: {
            date: string;
            seconds?: number;
          };
          end?: {
            date: string;
            seconds?: number;
          };
        };
      };
      protocol: IProtocol;
    };
  }
> {
  state = {
    live: {
      timer: {},
      liveState: {
        ongoing: false,
        seconds: 0,
        phase: '',
        toggleDate: '',
      },
      protocol: {
        events: [] as IProtocol['events'],
      },
    },
  };

  timerEvent: any = null;

  componentDidMount() {
    const { match } = this.props;
    if (
      !match.closed &&
      isEqual(startOfDay(new Date()), startOfDay(new Date(match.startDate)))
    ) {
      // this.registerLiveScore(match._id);
    }
  }

  getTeamsIds = () => {
    const { match } = this.props;
    const homeTeam = getTeam(match, TeamSide.HOME);
    const homeTeamId = homeTeam ? homeTeam._id : '';
    const awayTeam = getTeam(match, TeamSide.AWAY);
    const awayTeamId = awayTeam ? awayTeam._id : '';

    return { homeTeamId, awayTeamId };
  };

  getShootoutGoals = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (getProp(this.state, ['live', 'protocol', 'events'], []).length) {
      homeTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.phase === 'shootout'
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    } else if (match.protocol) {
      homeTeamGoals = (match.protocol.events || []).reduce((acc, e) => {
        if (
          e.team === homeTeamId &&
          e.eventType === 'goal' &&
          e.phase === 'shootout'
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = (match.protocol.events || []).reduce((acc, e) => {
        if (
          e.team === awayTeamId &&
          e.eventType === 'goal' &&
          e.phase === 'shootout'
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByTeams = () => {
    const { match } = this.props;
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    let homeTeamGoals = 0;
    let awayTeamGoals = 0;
    if (match.contumationScore && match.contumationScore.length) {
      homeTeamGoals = match.contumationScore[0];
      awayTeamGoals = match.contumationScore[1];
    } else if (match.score && match.score.length) {
      homeTeamGoals = match.score[0];
      awayTeamGoals = match.score[1];
    } else if (getProp(this.state, ['live', 'protocol', 'events'], []).length) {
      homeTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              e.phase !== 'shootout') ||
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        homeTeamGoals,
      );
      awayTeamGoals = (this.state.live.protocol.events || []).reduce(
        (
          acc,
          e: { team: string; eventType: string; type: string; phase: string },
        ) => {
          if (
            (e.team === awayTeamId &&
              e.eventType === 'goal' &&
              e.type !== 'dropped' &&
              e.phase !== 'shootout') ||
            (e.team === homeTeamId &&
              e.eventType === 'goal' &&
              e.type === 'dropped')
          ) {
            return acc + 1;
          }
          return acc;
        },
        awayTeamGoals,
      );
    } else if (match.protocol) {
      homeTeamGoals = (match.protocol.events || []).reduce((acc, e) => {
        if (
          (e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, homeTeamGoals);
      awayTeamGoals = (match.protocol.events || []).reduce((acc, e) => {
        if (
          (e.team === awayTeamId &&
            e.eventType === 'goal' &&
            e.phase !== 'shootout' &&
            e.type !== 'dropped') ||
          (e.team === homeTeamId &&
            e.eventType === 'goal' &&
            e.type === 'dropped')
        ) {
          return acc + 1;
        }
        return acc;
      }, awayTeamGoals);
    }
    return { homeTeamGoals, awayTeamGoals };
  };

  getGoalsByMatchPart = (events: any[]) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const phases = this.props.sportSectorsPhases.find(
      (i) => i._id === this.props.match.rules.sport_sector,
    );

    return events.reduce(
      (
        acc: any,
        event: {
          eventType: string;
          type: string;
          team: string;
          phase: string;
        },
      ) => {
        if (event.eventType === 'goal') {
          const otherTeamId =
            event.team === homeTeamId ? awayTeamId : homeTeamId;
          if (event.type === 'dropped') {
            return {
              ...acc,
              [event.phase]: {
                ...acc[event.phase],
                [otherTeamId]:
                  (acc[event.phase] ? acc[event.phase][otherTeamId] || 0 : 0) +
                  1,
              },
            };
          }
          return {
            ...acc,
            [event.phase]: {
              ...acc[event.phase],
              [event.team]:
                (acc[event.phase] ? acc[event.phase][event.team] || 0 : 0) + 1,
            },
          };
        }
        return acc;
      },
      phases
        ? phases.items.reduce((acc, phase) => {
            if (getProp(this.props, ['match', 'timer', phase._id])) {
              return {
                ...acc,
                [phase._id]: {
                  [homeTeamId]: 0,
                  [awayTeamId]: 0,
                },
              };
            }
            return acc;
          }, {})
        : {},
    );
  };

  getCurrentMatchStatus = async (matchId: string) => {
    const currentState = await CompetitionsApi.getMatch(matchId);
    if (currentState.liveState) {
      this.setState({
        live: {
          liveState: currentState.liveState as any, // TODO: fix types
          timer: currentState.timer || {},
          protocol: {
            ...(currentState.protocol || {}),
            events: currentState.protocol
              ? currentState.protocol.events! || []
              : [],
          },
        },
      });
    }
  };

  registerLiveScore = async (matchId: string) => {
    this.setState(
      {
        live: {
          liveState: this.props.match.liveState || this.state.live.liveState,
          timer: this.props.match.timer || this.state.live.timer,
          protocol: {
            ...(this.props.match.protocol || {}),
            events: this.props.match.protocol
              ? this.props.match.protocol.events
              : [],
          },
        },
      },
      () => {
        this.registerTimer();
      },
    );
    this.getCurrentMatchStatus(matchId);
  };

  registerTimer = () => {
    if (this.timerEvent) {
      clearInterval(this.timerEvent);
      this.timerEvent = 1;
    }
    this.forceUpdate();
    if (this.state.live.liveState.ongoing) {
      this.timerEvent = setInterval(() => {
        this.forceUpdate();
      }, 1000);
    }
  };

  renderAdditionalScoreInfo = () => {
    const { match } = this.props;
    if (!match.scoreByPhases) {
      return null;
    }

    // ak sa zapas v riadnom hracom case skoncil 0:0, nezobrazujeme polcasovy vysledok
    const HTsGoalsCount =
      getProp(match.scoreByPhases, [0, 0], 0) +
      getProp(match.scoreByPhases, [0, 1], 0) +
      getProp(match.scoreByPhases, [1, 0], 0) +
      getProp(match.scoreByPhases, [1, 1], 0);

    const ETsGoalsCount =
      getProp(match.scoreByPhases, [2, 0], 0) +
      getProp(match.scoreByPhases, [2, 1], 0) +
      getProp(match.scoreByPhases, [3, 0], 0) +
      getProp(match.scoreByPhases, [3, 1], 0);

    // ak sa zapas skoncil v riadnom hracom case a zapas skoncil 0:0, nezobrazujeme polcasovy vysledok
    if (!HTsGoalsCount && match.scoreByPhases.length === 2) {
      return null;
    }

    // ak sa zapas skoncil v riadnom hracom case a zapas neskoncil 0:0, zobrazime vysledok po prvom polcase
    if (HTsGoalsCount && match.scoreByPhases.length === 2) {
      return (
        <>
          <GoalsByPhases>({match.scoreByPhases[0].join(':')})</GoalsByPhases>
        </>
      );
    }

    // ak sa zapas skoncil po predlzeni alebo penaltach, zobrazime vysledok po fulltime a po predlzeni
    if (HTsGoalsCount + ETsGoalsCount > 0 && match.scoreByPhases.length === 4) {
      const firstTeamHTsScore =
        match.scoreByPhases[0][0] + match.scoreByPhases[1][0];
      const secondTeamHTsScore =
        match.scoreByPhases[0][1] + match.scoreByPhases[1][1];

      const firstTeamETsScore =
        match.scoreByPhases[2][0] + match.scoreByPhases[3][0];
      const secondTeamETsScore =
        match.scoreByPhases[2][1] + match.scoreByPhases[3][1];

      return (
        <>
          <GoalsByPhases>
            ({firstTeamHTsScore}:{secondTeamHTsScore}, {firstTeamETsScore}:
            {secondTeamETsScore})
          </GoalsByPhases>
        </>
      );
    }

    // ak sa zapas skoncil penaltami a 1HT, 2ET aj ET bez golov, nezobrazime nic
    return null;
  };

  renderContumatedMatchScore = (match: IWidgetMatchesListMatchItem) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    if (match.contumation && match.contumation.advantage) {
      return (
        <div>
          {match.contumation.advantage === homeTeamId
            ? match.settings!.contumation.score
            : 0}{' '}
          -{' '}
          {match.contumation.advantage === awayTeamId
            ? match.settings!.contumation.score
            : 0}
        </div>
      );
    }
    return null;
  };

  renderContumatedMatch = (match: IWidgetMatchesListMatchItem) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();

    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );
    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const phases = this.props.sportSectorsPhases.find(
      (i) => i._id === this.props.match.rules.sport_sector,
    );
    const penaltyPhase = (phases ? phases.items : []).find(
      (i) => i._id === 'shootout',
    );

    return (
      <>
        {match.contumation && match.contumation.keepScore ? (
          <>
            <div>{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
            <GoalsByPhases>
              (
              {Object.keys(goalsByMatchPart)
                .map((phase) => {
                  return `${goalsByMatchPart[phase][homeTeamId] || 0}:${
                    goalsByMatchPart[phase][awayTeamId] || 0
                  }`;
                })
                .join(', ')}
              )
            </GoalsByPhases>
            {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
              <GoalsByPhases>
                {!!penaltyPhase && penaltyPhase.label}
                <br />
                {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
              </GoalsByPhases>
            )}
          </>
        ) : (
          this.renderContumatedMatchScore(match)
        )}
        <MatchStatusIndicator color={this.props.theme.color.warning}>
          Kontumované
        </MatchStatusIndicator>
      </>
    );
  };

  renderClosedMatch = (match: IWidgetMatchesListMatchItem) => {
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();
    const goalsByMatchPart = this.getGoalsByMatchPart(
      (match.protocol || { events: [] }).events,
    );

    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const phases = this.props.sportSectorsPhases.find(
      (i) => i._id === this.props.match.rules.sport_sector,
    );
    const penaltyPhase = (phases ? phases.items : []).find(
      (i) => i._id === 'shootout',
    );

    return (
      <>
        <div>{`${homeTeamGoals} - ${awayTeamGoals}${
          match.rules.sport_sector === 'futbal' &&
          getProp(match, ['scoreByPhases'], []).length === 4
            ? ' pp'
            : ''
        }`}</div>
        {match.rules.sport_sector === 'futbal' ? (
          this.renderAdditionalScoreInfo()
        ) : (
          <>
            <GoalsByPhases>
              (
              {Object.keys(goalsByMatchPart)
                .map((phase) => {
                  return `${goalsByMatchPart[phase][homeTeamId] || 0}:${
                    goalsByMatchPart[phase][awayTeamId] || 0
                  }`;
                })
                .join(', ')}
              )
            </GoalsByPhases>
            {match.timer &&
              (match.timer.OT || (match.timer['1ET'] && match.timer['2ET'])) &&
              !match.timer.shootout && (
                <GoalsByPhases>{__('Po predĺžení')}</GoalsByPhases>
              )}
          </>
        )}
        {(!!homeTeamPenaltyGoals || !!awayTeamPenaltyGoals) && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        <MatchStatusIndicator color={this.props.theme.color.success}>
          Ukončené
        </MatchStatusIndicator>
        {match.protocol && match.protocol.audience && (
          <GoalsByPhases>{`${match.protocol.audience} ${__(
            'divákov',
          )}`}</GoalsByPhases>
        )}
      </>
    );
  };

  renderFutureMatch = (match: IWidgetMatchesListMatchItem) => {
    return (
      <>
        <div>
          {format(new Date(match.startDate), config.DEFAULT_TIME_FORMAT)}
        </div>
        {!this.props.verbose && (
          <MatchStatusIndicator color={this.props.theme.inactiveColor}>
            {format(new Date(match.startDate), config.DEFAULT_DATE_FORMAT)}
          </MatchStatusIndicator>
        )}
      </>
    );
  };

  renderTime = (activePhase?: ISportSectorPhase | null) => {
    const nowDiff = moment().diff(
      this.state.live.liveState.toggleDate,
      'seconds',
    );
    let currentTime = moment(this.state.live.liveState.toggleDate).add(
      this.state.live.liveState.seconds,
      'seconds',
    );
    if (this.state.live.liveState.ongoing) {
      if (this.props.settings.reverseTime) {
        currentTime = moment(currentTime).subtract(nowDiff, 'seconds');
      } else {
        currentTime = moment(currentTime).add(nowDiff, 'seconds');
      }
    }
    const diff = currentTime.diff(
      moment(this.state.live.liveState.toggleDate),
      'seconds',
    );
    const minutes = String(Math.ceil(diff / 60)).padStart(2, '0');
    const seconds = String(diff % 60).padStart(2, '0');

    if (!minutes || minutes === 'NaN') {
      return null;
    }
    if (this.props.settings.displaySeconds) {
      return (
        <LiveTime>
          {minutes}:{seconds} ({!!activePhase && activePhase.label})
        </LiveTime>
      );
    }
    return (
      <LiveTime>
        {minutes}' ({!!activePhase && activePhase.label})
      </LiveTime>
    );
  };

  renderLiveMatch = (match: IWidgetMatchesListMatchItem) => {
    const phases = this.props.sportSectorsPhases.find(
      (i) => i._id === this.props.match.rules.sport_sector,
    );
    const { homeTeamId, awayTeamId } = this.getTeamsIds();
    const { homeTeamGoals, awayTeamGoals } = this.getGoalsByTeams();

    let activePhaseIndex = 0;
    let activePhase: ISportSectorPhase | null | undefined = null;
    if (getProp(this.state, ['live', 'liveState', 'seconds'], 0) && phases) {
      activePhaseIndex = phases.items.findIndex(
        (phase) => phase._id === this.state.live.liveState.phase,
      );
      activePhase = phases.items.find(
        (phase) => phase._id === this.state.live.liveState.phase,
      );
    } else if (phases) {
      Object.keys(match.timer || {}).forEach((phaseKey) => {
        if (
          match.timer[phaseKey].start &&
          match.timer[phaseKey].start.date &&
          isAfter(new Date(), new Date(match.timer[phaseKey].start.date)) &&
          (!match.timer[phaseKey].end ||
            !match.timer[phaseKey].end.date ||
            isBefore(new Date(), new Date(match.timer[phaseKey].end.date)))
        ) {
          activePhaseIndex = phases.items.findIndex(
            (phase) => phase._id === phaseKey,
          );
          activePhase = phases.items.find((phase) => phase._id === phaseKey);
        }
      });
    }

    if (!activePhase && phases) {
      Object.keys(match.timer || {}).forEach((phaseKey) => {
        if (
          match.timer[phaseKey].start &&
          match.timer[phaseKey].start.date &&
          isAfter(new Date(), new Date(match.timer[phaseKey].start.date))
        ) {
          activePhaseIndex = phases.items.findIndex(
            (phase) => phase._id === phaseKey,
          );
          activePhase = phases.items.find((phase) => phase._id === phaseKey);
        }
      });
    }

    const goalsByMatchPart = this.getGoalsByMatchPart(
      // TODO: ked raz pojdeme live: this.state.live.protocol ? this.state.live.protocol.events || [] : [],
      getProp(match, ['protocol', 'events'], []),
    );

    const {
      homeTeamGoals: homeTeamPenaltyGoals,
      awayTeamGoals: awayTeamPenaltyGoals,
    } = this.getShootoutGoals();
    const penaltyPhase = (phases ? phases.items : []).find(
      (i) => i._id === 'shootout',
    );

    return (
      <>
        <div
          style={{ color: this.props.theme.color.danger }}
        >{`${homeTeamGoals} - ${awayTeamGoals}`}</div>
        {Object.keys(goalsByMatchPart).length > 0 && (
          <GoalsByPhases>
            (
            {Object.keys(goalsByMatchPart)
              .map((phase, idx) => {
                if (idx <= activePhaseIndex) {
                  return `${goalsByMatchPart[phase][homeTeamId] || 0}:${
                    goalsByMatchPart[phase][awayTeamId] || 0
                  }`;
                }
                return '-:-';
              })
              .join(', ')}
            )
          </GoalsByPhases>
        )}
        {this.state.live.liveState.phase === 'shootout' && (
          <GoalsByPhases>
            {!!penaltyPhase && penaltyPhase.label}
            <br />
            {`${homeTeamPenaltyGoals}:${awayTeamPenaltyGoals}`}
          </GoalsByPhases>
        )}
        <MatchStatusIndicator color={this.props.theme.color.danger}>
          LIVE
        </MatchStatusIndicator>{' '}
        {this.renderTime(activePhase)}
      </>
    );
  };

  getMatchState = (match: IWidgetMatchesListMatchItem) => {
    let content: React.ReactNode = null;
    let isFutureMatch = false;
    let isLiveMatch = false;
    if (match.contumation && match.contumation.isContumated) {
      content = this.renderContumatedMatch(match);
    } else if (match.closed || this.state.live.liveState.phase === 'end') {
      content = this.renderClosedMatch(match);
    } else if (
      !match.closed &&
      ((match.startDate && isAfter(new Date(), new Date(match.startDate))) ||
        (match.liveState && this.state.live.liveState.phase))
    ) {
      content = this.renderLiveMatch(match);
      isLiveMatch = true;
    } else if (!match.closed) {
      content = this.renderFutureMatch(match);
      isFutureMatch = true;
    } else {
      content = this.renderLiveMatch(match);
      isLiveMatch = true;
    }
    if (this.props.verbose) {
      return (
        <LiveScore compact={!!this.props.compact}>
          {!isLiveMatch && (
            <MatchDate>
              {format(new Date(match.startDate), config.DEFAULT_DATE_FORMAT, {
                locale: skLocale,
              })}
            </MatchDate>
          )}
          {!isFutureMatch && !isLiveMatch && (
            <MatchTime>
              {format(new Date(match.startDate), config.DEFAULT_TIME_FORMAT)}
            </MatchTime>
          )}
          {isLiveMatch && match.round && match.round.name && (
            <MatchTime>{cleanRoundName(match.round.name)}</MatchTime>
          )}
          {!!match.sportGround && (
            <MatchSportGround>
              {match.sportGround.sportObjectName}
            </MatchSportGround>
          )}
          {content}
        </LiveScore>
      );
    }
    return <LiveScore compact={!!this.props.compact}>{content}</LiveScore>;
  };

  renderShootersOverview = () => {
    const goalEvents = (
      this.state.live.protocol
        ? this.state.live.protocol.events || []
        : getProp(this.props.match, ['protocol', 'events'], [])
    ).filter((g) => {
      if (g.eventType === 'goal' && g.phase !== 'shootout') {
        return g;
      }
      return false;
    });
    const goals = goalEvents.reduce((acc, i) => {
      return { ...acc, [i.team]: [...(acc[i.team] || []), i] };
    }, {});

    const { match } = this.props;

    const homeTeam = getTeam(match, TeamSide.HOME);
    const awayTeam = getTeam(match, TeamSide.AWAY);

    const homeGoals = goals[homeTeam ? homeTeam._id : ''];
    const awayGoals = goals[awayTeam ? awayTeam._id : ''];

    return (
      <GoalsOverview>
        <GoalsOverviewHomeTeamCol>
          {(homeGoals || []).map((g: any) => {
            const time = g.eventTime.split(':');
            const seconds = Number(time[1]);
            return (
              <GoalsOverviewPlayer key={g.eventTime}>
                <GoalsOverviewPlayerName>
                  {g.player.name} {Number(time[0]) + (seconds > 0 ? 1 : 0)}'
                </GoalsOverviewPlayerName>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    title=""
                    eventType="goal"
                    subType={g.type}
                    theme={this.props.theme}
                    size={15}
                    sport={match.rules.sport_sector || ''}
                  />
                </div>
              </GoalsOverviewPlayer>
            );
          })}
        </GoalsOverviewHomeTeamCol>
        <GoalsOverviewAwayTeamCol>
          {(awayGoals || []).map((g: any) => {
            const time = g.eventTime.split(':');
            const seconds = Number(time[1]);
            return (
              <GoalsOverviewPlayer key={g.eventTime}>
                <GoalsOverviewPlayerName>
                  {g.player.name} {Number(time[0]) + (seconds > 0 ? 1 : 0)}'
                </GoalsOverviewPlayerName>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon
                    title=""
                    subType={g.type}
                    eventType="goal"
                    theme={this.props.theme}
                    size={15}
                    sport={match.rules.sport_sector || ''}
                  />
                </div>
              </GoalsOverviewPlayer>
            );
          })}
        </GoalsOverviewAwayTeamCol>
      </GoalsOverview>
    );
  };

  render() {
    const { match, lastIndex, fullWidth, compact, verbose, backgroundImage } =
      this.props;

    const homeTeam = getTeam(match, TeamSide.HOME);
    const awayTeam = getTeam(match, TeamSide.AWAY);

    const content = (
      <MatchWrapper
        fullWidth={fullWidth}
        lastIndex={lastIndex}
        matchSize={this.props.matchSize}
        compact={!!compact}
        verbose={!!verbose}
        backgroundImage={backgroundImage}
      >
        <MatchDetailLink href={''}>
          <TeamComponent
            matchAppSpace={match.appSpace}
            competitionId={match.competition._id}
            align="left"
            team={homeTeam}
            compact={!!compact}
          />
          {this.getMatchState(match)}
          <TeamComponent
            matchAppSpace={match.appSpace}
            competitionId={match.competition._id}
            align="right"
            team={awayTeam}
            compact={!!compact}
          />
        </MatchDetailLink>
        {verbose && this.renderShootersOverview()}
      </MatchWrapper>
    );
    return content;
  }
}

export default Match;
