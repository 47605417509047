import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootState } from '../../configureStore';
import UrlContext, { IUrlContext } from '../../contexts/UrlContext';
import { RouteProps } from '../../library/App';
import getArticleUrl from '../../utilities/getArticleUrl';
import getAuthorUrl from '../../utilities/getAuthorUrl';
import getUnionBaseUrlByShortName from '../../utilities/getUnionBaseUrlByShortName';
import getArticlePagesEditUrl from '../../utilities/getArticlePagesEditUrl';
import { appSettingsSelector } from '../DomainResolver/selectors';
import getSmarttagPageUrl from '../../utilities/smartTags/getSmarttagPageUrl';

type IOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string }>;

const UnionUrlContextProvider: React.FC<IProps> = ({
  appSettings,
  children,
  params: { zvaz },
}) => {
  const urlContext: IUrlContext = React.useMemo(() => {
    const baseUrl = appSettings.baseUri || '';
    const unionUrl = getUnionBaseUrlByShortName(zvaz);
    const unionBaseUrl = `${baseUrl}${unionUrl}`;

    return {
      getArticleUrl: (a) => getArticleUrl(`${unionUrl}spravy`, a),
      getSectionUrl: (s) => {
        if (s.redirecturl) {
          return s.redirecturl;
        }
        return `${unionUrl}sekcia/${s._id}/`;
      },
      getFullArticleUrl: (a) => getArticleUrl(`${unionBaseUrl}spravy`, a),
      getFullSectionUrl: (s) => {
        if (s.redirecturl) {
          return s.redirecturl;
        }
        return `${unionBaseUrl}sekcia/${s._id}/`;
      },
      getFullSmarttagPageUrl: (s) => getSmarttagPageUrl('', s),
      getAuthorUrl: (a) => getAuthorUrl(`${unionBaseUrl}redakcia`, a),
      getArticlePagesEditUrl: (s, id) => getArticlePagesEditUrl(s, id),
      getArticleDiscussionUrl: (a) =>
        getArticleUrl(`${unionBaseUrl}diskusia`, a),
    };
  }, [appSettings.baseUri, zvaz]);

  return (
    <UrlContext.Provider value={urlContext}>{children}</UrlContext.Provider>
  );
};

export default withRouter(
  connect(mapStateToProps)(UnionUrlContextProvider),
) as unknown as React.FC<IOwnProps>;
