import Button from '@sportnet/ui/lib/Button';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { IWidgetRaceMatchesList } from '@sportnet/content/utilities/library';
import { IMatchesList } from '@sportnet/content/library/Competitions';
import { __ } from '@sportnet/content/utilities/utilities';
import WidgetHeader from '@sportnet/content/view/components/Header';
import {
  getMatches,
  ILoadMatchesParams,
} from '@sportnet/content/view/widgets/RaceMatchesList/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import MatchesList from './MatchesList';

const AspectRatioWrapper = styled('div')<{ scWidth: number; scHeight: number }>`
  position: relative;
  width: 100%;
  padding-top: ${({ scWidth, scHeight }) => 100 / (scWidth / scHeight)}%;
`;

const AspectRatioContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  ${({ theme }) => css`
    margin: 0 -${rem(theme.grid.gutterWidth / 2)};
    width: calc(100% + ${rem(theme.grid.gutterWidth)});
  `}
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled(WidgetHeader)`
  && {
    > h2 {
      font-size: ${rem(18)};
      font-weight: bold;
    }
  }
` as any;

const NotFoundWrapper = styled.div`
  text-align: center;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  color: red;
`;

const LoadMoreWrapper = styled.div`
  margin-top: ${rem(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoadMoreButton = styled(Button)`
  && {
    width: max-content;
    margin-top: ${rem(16)};
  }
`;

type IData = Omit<IWidgetRaceMatchesList, 'type'>;
interface IOwnProps {
  data: IData;
  renderEmpty?: (type: string) => React.ReactNode;
}

type Props = IOwnProps;

const RaceMatchesListWidget: React.FC<Props> = ({
  data: widgetData,
  renderEmpty,
}) => {
  const [matchesList, setMatchesList] = React.useState<IMatchesList>({
    matches: widgetData?.data?.matches || [],
    total: widgetData?.data?.total ?? 0,
    nextOffset: widgetData?.data?.nextOffset ?? null,
  });
  const [error, setError] = React.useState('');
  const [isLoadMoreLoading, setIsLoadMoreLoading] = React.useState(false);

  if (!widgetData.competitionId && !widgetData.teamId && renderEmpty) {
    return <>{renderEmpty('raceMatchesList')}</>;
  }

  if (!widgetData.data) {
    return null;
  }

  const handleLoadMoreClick = async () => {
    const params: ILoadMatchesParams = {};
    if (matchesList.nextOffset) {
      params.offset = matchesList.nextOffset;
    }

    try {
      setIsLoadMoreLoading(true);
      const resp = await getMatches(widgetData, params);
      setMatchesList({
        ...resp,
        matches: [...matchesList.matches, ...resp.matches],
      });
    } catch (err: any) {
      setError(err.message || err);
    } finally {
      setIsLoadMoreLoading(false);
    }
  };

  const renderWidgetContent = () => (
    <Wrapper>
      {widgetData.title && (
        <TitleWrapper>
          <Title>{widgetData.title}</Title>
        </TitleWrapper>
      )}
      <>
        {error ? (
          <ErrorWrapper>{error}</ErrorWrapper>
        ) : (
          <>
            {matchesList && matchesList.matches.length > 0 ? (
              <>
                <MatchesList matchesList={matchesList} />
                <LoadMoreWrapper>
                  {matchesList.nextOffset && (
                    <LoadMoreButton
                      primary
                      loading={isLoadMoreLoading}
                      onClick={handleLoadMoreClick}
                    >
                      {__('Zobraziť viac')}
                    </LoadMoreButton>
                  )}
                </LoadMoreWrapper>
              </>
            ) : (
              <NotFoundWrapper>
                {__(
                  'Pre zvolené kritériá, alebo časové obdobie, neboli nájdené žiadne zápasy.',
                )}
              </NotFoundWrapper>
            )}
          </>
        )}
      </>
    </Wrapper>
  );

  const ratio = (widgetData.ratio ? widgetData.ratio.split(':') : []).map(
    Number,
  );

  return (
    <div className="content-widget-matchesList">
      {ratio && ratio.length === 2 ? (
        <AspectRatioWrapper scWidth={ratio[0]} scHeight={ratio[1]}>
          <AspectRatioContentWrapper>
            {renderWidgetContent()}
          </AspectRatioContentWrapper>
        </AspectRatioWrapper>
      ) : (
        renderWidgetContent()
      )}
    </div>
  );
};

export default RaceMatchesListWidget;
