/**
 * Vrati Link s menom hraca, alebo len meno, ak nie je zadane issfId
 */
import * as React from 'react';
import Link from '../components/Link';
import styled from '../theme/styled-components';
import getMemberBaseUrl from './getMemberBaseUrl';

const CustomLink = styled(Link)`
  :hover,
  :active,
  :focus {
    text-decoration: underline;
  }
`;

const getMembersNameWithLink = (
  name: string,
  issfId?: string | number | null,
) => {
  return issfId ? (
    <CustomLink to={getMemberBaseUrl({ clen: issfId })}>{name}</CustomLink>
  ) : (
    name
  );
};

export default getMembersNameWithLink;
