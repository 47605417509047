import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryNameSpace, createQueryFn } from '../../../../query';
import { IMatch } from '../../../../library/Competitions';
import CompetitionsApi from '../../../../api/CompetitionsApi';
import getMatchDetailDataForPrint from '../../../../utilities/print/getMatchDetailDataForPrint';
import { SPORT_SECTOR_PHASES } from '../../../../codelists/sport_sector_phases';
import { SPORT_SECTOR_EVENTS } from '../../../../codelists/sport_sector_events';
import { SPORT_SECTOR_SETTINGS } from '../../../../codelists/sport_sector_settings';
import { MatchDetailPrint } from '../../../../library/Print';
import { SPORT_SECTOR_CREW } from '../../../../codelists';

interface GetMatchDetailParams {
  matchId: string;
}

const getMatchDetail = createQueryFn(
  QueryNameSpace.matchDetailPrint,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, GetMatchDetailParams]>) => {
    const [, parameters] = queryKey;

    if (!parameters || !parameters.matchId) {
      throw new Error('Must provide required parameters [matchId]!');
    }

    const match = (await CompetitionsApi.getMatch(
      parameters.matchId,
    )) as IMatch;

    const sportSector = match.rules
      ?.sport_sector as MatchDetailPrint['sportSector'];

    if (!sportSector) {
      throw new Error('SPORT_SECTOR_IS_NULL');
    }

    // ciselniky
    const sportSectorPhases = SPORT_SECTOR_PHASES.find(
      (phase) => phase._id === sportSector,
    );

    if (!sportSectorPhases) {
      throw new Error(
        `Sport sector phases not found by sport sector ${sportSector}`,
      );
    }

    // event
    const sportSectorEvent = SPORT_SECTOR_EVENTS.find(
      (event) => event._id === sportSector,
    );
    if (!sportSectorEvent) {
      throw new Error(
        `Sport sector events not found by sport sector ${sportSector}`,
      );
    }

    // sport settings
    const sportSectorSetings = SPORT_SECTOR_SETTINGS.find(
      (setting) => setting._id === sportSector,
    );
    if (!sportSectorSetings) {
      throw new Error(
        `Sport sector settings not found by sport sector ${sportSector}`,
      );
    }

    // sport sector crew
    const sportSectorCrew = SPORT_SECTOR_CREW.find(
      (setting) => setting._id === sportSector,
    );
    if (!sportSectorCrew) {
      throw new Error(
        `Sport sector crew not found by sport sector ${sportSector}`,
      );
    }

    const matchDetail = getMatchDetailDataForPrint(match, {
      sportSectorPhases,
      sportSectorEvent,
      sportSectorSetings,
    });

    return matchDetail;
  },
);

export default getMatchDetail;
