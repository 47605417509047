import { QueryFunctionContext } from '@tanstack/react-query';
import CmsApi from '../api/CmsApi';
import { createQueryFn, QueryNameSpace } from '../query';
import config from '../config';

interface GetPublicListingArticlesParams {
  appSpace: string;
  listingId: string;
  limit?: number;
  offset?: number;
}

// Type pre parametre vo volani API funkcie `getPublicArticlesBySectionId` s tym, ze `limit` a `offset`
// budu povinne.
type GetPublicListingArticlesApiCallParams = Omit<
  Required<Parameters<typeof CmsApi['getPublicListingArticles']>>[4],
  'offset' | 'limit'
> & {
  offset: number;
  limit: number;
};

export const getPublicListingArticles = createQueryFn(
  QueryNameSpace.listingArticles,
  async ({
    queryKey,
    pageParam,
  }: QueryFunctionContext<
    [string] | [string, GetPublicListingArticlesParams]
  >) => {
    const [, parameters] = queryKey;

    if (!parameters) {
      throw new Error(`Provide "parameters"!`);
    }

    if (!parameters?.appSpace) {
      throw new Error(`Provide required parameter "appSpace"`);
    }

    if (!parameters?.listingId) {
      throw new Error(`Provide required parameter "listingId"`);
    }

    const { appSpace, listingId } = parameters;

    const apiCallParams: GetPublicListingArticlesApiCallParams = {
      offset: pageParam?.nextOffset ?? 0,
      limit: pageParam?.limit || 10,
    };

    // strankovanie ma prednost
    if (pageParam?.limit > 0) {
      apiCallParams.limit = pageParam.limit;
    } else {
      if (parameters.limit || parameters.limit === 0) {
        apiCallParams.limit = parameters.limit;
      }
    }

    if (pageParam?.nextOffset) {
      apiCallParams.offset = pageParam.nextOffset;
    } else {
      if (parameters.offset || parameters.offset === 0) {
        apiCallParams.offset = parameters.offset;
      }
    }

    const {
      articles,
      limit,
      offset,
      total,
      nextOffset = null,
    } = await CmsApi.getPublicListingArticles(
      config.APP_ID,
      appSpace,
      config.DEFAULT_CONTENT_DIVIDER,
      listingId,
      apiCallParams,
    );

    return {
      articles,
      limit,
      offset,
      nextOffset,
      total,
    };
  },
);
