import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import Loading from '../../../components/Loading';
import { CustomThunkDispatch, RootState } from '../../../configureStore';
import { loadMemberMovements, setCurrentMemberType } from '../actions';
import {
  currentMemberMovementsIsFetchingSelector,
  currentMemberSelector,
} from '../selectors';
import MovementsDetail from './detail';

const mapStateToProps = (state: RootState) => ({
  isFetching: currentMemberMovementsIsFetchingSelector(state) !== false,
  member: currentMemberSelector(state),
});

type IOwnProps = {};

type Props = IOwnProps & { dispatch: CustomThunkDispatch } & ReturnType<
    typeof mapStateToProps
  > &
  RouteComponentProps<{ id: string }, {}>;

class PlayerMovements extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const {
      params: { id },
      dispatch,
    } = props;
    await Promise.all([
      dispatch(setCurrentMemberType('PLAYER')),
      dispatch(loadMemberMovements.action({ id })),
    ]);
  }

  async componentDidMount() {
    try {
      await PlayerMovements.getInitialProps(this.props);
    } catch (e: any) {
      //
    }
  }

  render() {
    const { isFetching, member } = this.props;
    if (isFetching && !member) {
      return <Loading />;
    }
    return <MovementsDetail />;
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PlayerMovements) as unknown as React.FC<IOwnProps>;
