import React from 'react';

export default (size: string, color: string) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 378.5 72.6">
    <path
      fill="#f30d31"
      d="m 364.4,6.7 h 5.7 v 1.7 h -1.9 v 5.3 h -1.8 V 8.4 h -1.9 V 6.7 Z m 12.3,0 -1.9,2.9 -1.9,-2.9 h -2.1 v 7 h 1.8 V 9.5 l 2,3 2.1,-3 v 4.2 h 1.8 v -7 z m -45.5,33.6 c 0,4.2 -1.1,9 -1.7,11.2 h -30.4 v 0.8 c 0,1.4 2.2,6.3 9.5,6.3 3.6,0 7.3,-1.2 9.7,-2.8 l 5.5,11.2 c -4.7,3.6 -11.6,5.5 -17.5,5.5 -14.6,0 -23.9,-8.1 -23.9,-21.9 0,-15.4 10.9,-31.3 27.9,-31.3 12.8,0 20.9,8 20.9,21 z m -15.8,-1.8 c 0,-2.4 -2,-5.5 -6.3,-5.5 -4.7,0 -7.4,4.1 -7.9,6.8 h 14.1 c 0.1,-0.3 0.1,-0.9 0.1,-1.3 z M 361.9,6.7 h -17.7 l -3,13.9 h -6.4 l -3.5,15.3 h 6.4 l -4.5,20.3 c -0.4,1.9 -0.5,3.4 -0.5,4.6 0,8 6.5,11.8 16.8,11.8 3.5,0 6.2,-0.6 7.9,-1.7 l 0.2,-14.6 c -0.3,0.3 -1.5,0.6 -2.2,0.6 -2.5,0 -3.6,-1.4 -3.6,-3.4 0,-0.5 0.1,-1.4 0.2,-2.2 l 3.5,-15.4 h 8.1 l 3.5,-15.3 H 359 Z M 282,38.5 c 0,14 -7.7,34 -23.8,34 -5.7,0 -10.4,-2.1 -12.8,-6.8 l -1.2,5.6 h -17.8 v 0 h -16.1 l -6.1,-19.6 -13.8,19.6 h -9 -14.6 -7.1 l -6.1,-19.6 -13.8,19.6 h -23.6 l 28.7,-37 -12,-33.1 h 21.7 l 5.1,18.3 12.2,-18.3 h 11.7 12.1 9.5 l 5.1,18.3 12.3,-18.3 h 23.7 l -27.1,35.2 9.4,25.4 10,-45.2 11.8,-15.3 h 9.3 l -5.6,24.8 c 4,-4.9 7.9,-6.7 12.4,-6.7 9.7,-0.1 15.5,6.4 15.5,19.1 z m -86.4,-4.2 -8.1,-22.4 -18.9,24.5 8.2,22.1 z m 68.2,7.5 c 0,-4.2 -2.6,-6.8 -6.3,-6.8 -2.3,0 -4.4,1.4 -6,3.5 l -3.4,14.9 c 1.3,2.3 3.6,3.6 6.2,3.6 6,-0.1 9.5,-9.3 9.5,-15.2 z M 67.7,28.6 C 67.7,45.8 58,71.2 26.5,71.2 H 0 L 15.5,1.1 h 25.9 c 13.2,0.1 26.3,10.3 26.3,27.5 z m -20.3,1 c 0,-6.8 -4,-11 -9.6,-11 H 31.6 L 23.8,54 h 6.3 C 41.6,54 47.4,39 47.4,29.6 Z m 49.5,42.9 c -18,0 -29.3,-12 -29.3,-28.5 0,-19.7 13.3,-44 37.3,-44 17.8,0 29.2,12 29.2,28.4 0,19.9 -13.4,44.1 -37.2,44.1 z m 16.8,-43.4 c 0,-6.6 -3.8,-11.5 -10,-11.5 -9.5,0 -15.8,16.2 -15.8,25.9 0,6.6 3.7,11.4 9.9,11.4 9.5,0.1 15.9,-16.1 15.9,-25.8 z"
      id="path4"
    />
  </svg>
);
