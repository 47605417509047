import React from 'react';
import Checkbox from '../../components/v2/Checkbox';

export interface TiedCheckbox {
  label?: string;
  name: string;
  value: boolean;
  disabled?: boolean;
}

export const getTiedCheckboxValuesMap = (checkboxes: TiedCheckbox[]) => {
  return checkboxes.reduce((acc, checkbox) => {
    acc[checkbox.name] = checkbox.value;
    return acc;
  }, {} as { [name: string]: boolean });
};

interface OwnProps {
  groupId: string;
  checkboxes: TiedCheckbox[];
  onChange: (groupId: string, changedCheckboxes: TiedCheckbox[]) => void;
}

const TiedCheckboxGroup: React.FC<OwnProps> = ({
  groupId,
  checkboxes,
  onChange,
}) => {
  // upravime state v poli checkboxov a ak su vsetky odznacene oznacime nasledujuci checkbox
  // hend vedla toho, ktory menime, alebo prvý ak meníme posledný
  const handleChangeCheckbox = (name?: string) => {
    const changedState = checkboxes.reduce(
      (acc, checkbox, index) => {
        const newCheckbox = { ...checkbox };
        if (newCheckbox.name === name) {
          newCheckbox.value = !newCheckbox.value;
          acc.checkedIndex = index;
        }
        acc.changedCheckboxes.push(newCheckbox);
        if (newCheckbox.value) {
          acc.anyChecked ||= true;
        }
        return acc;
      },
      { changedCheckboxes: [], anyChecked: false, checkedIndex: 0 } as {
        changedCheckboxes: TiedCheckbox[];
        anyChecked: boolean;
        checkedIndex: number;
      },
    );
    // ak su vsetky odznacene, oznacim nasledujuci alebo prvy
    if (!changedState.anyChecked) {
      const length = changedState.changedCheckboxes.length;
      const computedIndex = (changedState.checkedIndex % length) + 1;
      const checkedIndex = computedIndex >= length ? 0 : computedIndex;
      changedState.changedCheckboxes[checkedIndex].value = true;
    }
    onChange(groupId, changedState.changedCheckboxes);
  };

  return (
    <>
      {checkboxes.map((checkbox) => (
        <Checkbox
          key={`${groupId}:${checkbox.name}`}
          label={checkbox.label}
          name={checkbox.name}
          value={checkbox.value}
          onChange={handleChangeCheckbox}
          disabled={checkbox.disabled}
        />
      ))}
    </>
  );
};

export default React.memo(TiedCheckboxGroup);
