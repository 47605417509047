import React from 'react';
import { connect } from 'react-redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import ArticleSectionHeader from '../../components/Articles/ArticleSectionHeader';
import ArticlesList from '../../components/Articles/ArticlesList';
import { ContextMarginBigTopStyles } from '../../components/Layout';
import NextPrevPaginator from '../../components/NextPrevPaginator';
import config from '../../config';
import { RootState } from '../../configureStore';
import { similarArticlesSelector } from '../../pages/Article/selectors';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const PositionedNextPrevPaginator = styled(NextPrevPaginator)`
  ${ContextMarginBigTopStyles}
`;

type IOwnProps = {
  page: number;
};

const mapStateToProps = (state: RootState) => {
  return {
    similarArticles: similarArticlesSelector(state),
    isLoadingSimilarArticles:
      isCommiting(config.LIST_SIMILAR_ARTICLES)(state) || false,
    similarArticlesNextOffset: getListNextOffset(config.LIST_SIMILAR_ARTICLES)(
      state,
    ),
  };
};

const mapDispatchToProps = {};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const SimilarArticles: React.FC<IProps> = ({
  similarArticles,
  isLoadingSimilarArticles,
  similarArticlesNextOffset,
  page,
}) => {
  if (similarArticles.length <= 0) {
    return null;
  }

  return (
    <>
      <ArticleSectionHeader>{__('Súvisiaci obsah')}</ArticleSectionHeader>
      {similarArticles.length > 0 && (
        <>
          <ArticlesList articles={similarArticles} />
          <PositionedNextPrevPaginator
            isPageLast={!similarArticlesNextOffset}
            loading={isLoadingSimilarArticles}
            page={page}
          />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SimilarArticles);
