export function getSharingHref(name: string, url: string, title?: string) {
  switch (name) {
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    case 'twitter':
      return `https://twitter.com/intent/tweet?url=${url}`;
    case 'tumblr':
      return `http://tumblr.com/widgets/share/tool?canonicalUrl=${url}`;
    case 'pinterest':
      return `http://pinterest.com/pin/create/button/?url=${url}`;
    case 'mail':
      return `mailto:?subject=${title}&body=${url}`;
    default:
      return null;
  }
}
