import React from 'react';

export default (size: string, color: string = '#333') => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24.295 12">
    <g transform="translate(-142 -271)">
      <path
        d="M142.392,283v-2.182h10.67c.784,0,1.091-.136,1.091-.716v-1.722c0-.545-.323-.733-1.091-.733h-7.176c-2.812,0-3.886-1.176-3.886-2.915v-.818c0-1.738,1.074-2.915,3.886-2.915h10.381v2.165H145.886c-.733,0-1.108.187-1.108.733v.853c0,.512.325.733,1.108.733h7.176c2.812,0,3.887,1.177,3.887,2.915v1.671c0,1.739-1.075,2.932-3.887,2.932Z"
        transform="translate(0)"
        fill={color}
      />
      <path
        d="M180.177,277.727H177.45V275h-2.182v2.727h-2.727v2.182h2.727v2.727h2.182v-2.727h2.727Z"
        transform="translate(-13.882 -1.818)"
        fill="#cf2528"
      />
    </g>
  </svg>
);
