import React from 'react';
import { connect } from 'react-redux';
import { getListNextOffset, isCommiting } from '@sportnet/redux-list/ducks';
import { RootState } from '../../configureStore';
import { cumulatedListArticlesSelector } from '../../pages/Article/selectors';
import ArticlesList from '../../components/Articles/ArticlesList';
import { getListParameters } from '@sportnet/redux-list';
import styled from '../../theme/styled-components';
import LoadMorePanel from '../../components/LoadMorePanel';
import { rem } from 'polished';
import __ from '../../utilities/__';
import { loadArticles } from '../../pages/Article/actions';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { initializeOrSetListParams } from '../../pages/App/actions';
import { loadSmartTagByIdent } from '../SmartTags/actions';
import infiniteArticlesListReducer, { initialState } from './reducer';
import { NormalizedEntities, Pager } from '../../library/App';
import InfiniteArticleListLoader from './InfiniteArticleListLoader';

const PositionedLoadMorePanel = styled(LoadMorePanel)`
  max-width: ${rem(680)};
`;

type IOwnProps = {
  listName: string;
  widthAd?: boolean;
  className?: string;
};

const mapStateToProps = (state: RootState, { listName }: IOwnProps) => ({
  initialArticles: cumulatedListArticlesSelector(state, listName),
  initialLoading: isCommiting(listName)(state) || false,
  initialNextOffset: getListNextOffset(listName)(state),
  listParams: getListParameters(listName)(state),
});

const mapDispatchToProps = {
  initializeOrSetListParams: thunkToAction(initializeOrSetListParams.action),
  loadSmartTagByIdent: thunkToAction(loadSmartTagByIdent.action),
  loadArticles: thunkToAction(loadArticles.action),
};

type IProps = IOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

/**
 * Zoznam clankov s infinite nacitavanim. Pomocou redux-list nacita prvych `n` clankov
 * (default je limit 10). Kedze je prvych `n` clankov v Redux, budu vyrenderovane aj v SSR.
 * Nacitavanie dalsich clankov v liste uz prebieha len cez lokalny state. Pri dalsom nacitani
 * clankov sa limit zvysi jednorazovo o dvojnasobok.
 *
 * Idealne a ocakavane spravanie je teda:
 *   - nacitanie prvych 10 clankov cez SSR
 *   - nasledne nacitanie dalsich clankov, ale uz po 20 kusov
 *   - v zozname clankov s zorbazi reklama po 3. clanku a nasledne kazdych +10 clankov,
 *     teda 13, 23, 33, 43, ...
 *
 * Vsetky parametre filtra pre docitavanie clankov v infinity docitavani sa vezmu z redux-list.
 *
 */
const InfiniteArticleList: React.FC<IProps> = ({
  initialArticles,
  initialLoading,
  initialNextOffset,
  widthAd,
  listParams,
  loadArticles,
  className,
}) => {
  const [state, dispatch] = React.useReducer(infiniteArticlesListReducer, {
    ...initialState,
  });

  const dispatchLoading = (loading: boolean) => {
    dispatch({
      type: 'loading',
      payload: {
        loading,
      },
    });
  };

  const dispatchAddData = (
    response: NormalizedEntities<'articles'> & Pager,
  ) => {
    dispatch({
      type: 'addData',
      payload: {
        articles: response.original,
        listParams: {
          limit: response.limit,
          offset: response.offset,
          total: response.total,
          nextOffset: response.nextOffset || null,
        },
      },
    });
  };

  const dispatchSetListParams = (nextOffset: number | null) => {
    dispatch({
      type: 'setListParams',
      payload: {
        listParams: {
          nextOffset,
        },
      },
    });
  };

  React.useEffect(() => {
    if (!initialNextOffset) {
      return;
    }
    dispatchSetListParams(initialNextOffset);
  }, [initialNextOffset]);

  const handleLoadMoreArticles = async (offset: number) => {
    const { limit = 10, ...restParams } = listParams;
    try {
      dispatchLoading(true);
      const response = await loadArticles({
        ...restParams,
        page: undefined,
        limit: limit * 2, // navysime default limit o dvojnasobok
        offset,
      });
      dispatchAddData(response);
    } catch (e) {
      console.error(e);
    } finally {
      dispatchLoading(false);
    }
  };

  const {
    listParams: { nextOffset },
    articles,
    loading: stateLoading,
  } = state;

  const loading = initialLoading || stateLoading;

  if (initialArticles.length === 0 && initialLoading) {
    return <InfiniteArticleListLoader />;
  }

  return (
    <div className={className}>
      <ArticlesList articles={initialArticles} widthAd={widthAd} adAfter={2} />
      <ArticlesList articles={articles} widthAd={false} /> 
      {nextOffset && (
        <PositionedLoadMorePanel
          onLoadMore={() => {
            if (nextOffset !== void 0 && !loading) {
              handleLoadMoreArticles(nextOffset);
            }
          }}
          loading={loading}
          title={__('Načítať ďalšie články')}
        />
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfiniteArticleList);
