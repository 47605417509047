import React from 'react';
import AdContext from '../../../contexts/AdContext';
import { formatAdParams, IAdParams } from '../AdProvider';
import AdManagerContext from './AdManagerContext';

type IProps = IAdParams;

const AdManager: React.FC<IProps> = ({ children, ...currentParams }) => {
  const contextParams = React.useContext(AdManagerContext);
  const newParams = { ...contextParams, ...currentParams };

  const { params, setParams, resetAdverts } = React.useContext(AdContext);

  const oldKey = formatAdParams(params);
  const newKey = formatAdParams(newParams);

  React.useEffect(() => {
    if (oldKey !== newKey) {
      setParams(newParams);
    }
    return () => {
      resetAdverts();
    };
  }, [setParams, newKey, oldKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default React.memo(AdManager);
