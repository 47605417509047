import { DropDownItemProps } from '../../types/DropDown';
import { Login } from '../Login/Login';
import LoggedInUserDropdownBody from '../LoggedInUserDropdownBody';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ active: boolean }>`
  display: inline-flex;
  position: relative;
  height: 100%;
  ${({ active }) =>
    !!active &&
    css`
      :after {
        content: '';
        position: absolute;
        bottom: -11px;
        left: 50%;
        margin-left: -8px;
        width: 10px;
        height: 10px;
        margin-bottom: -1px;
        background: white;
        transform: rotate(45deg);
        border-top: 1px solid ${({ theme }) => theme.color.darkBorder};
        border-left: 1px solid ${({ theme }) => theme.color.darkBorder};
        z-index: 20001;
      }
    `}
`;

type OwnProps =  {
  className?: string;
  username: string;
  items: DropDownItemProps[];
};

export const LoggedInUserDropdown: React.FC<OwnProps> = ({
  username,
  items,
}) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <Wrapper
      active={visible}
      onMouseOut={() => {
        setVisible(false);
      }}
      onMouseOver={() => {
        setVisible(true);
      }}
    >
      <Login text={username} />
      <LoggedInUserDropdownBody visible={visible} items={items} />
    </Wrapper>
  );
};
