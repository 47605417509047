import React from 'react';
import { rem } from 'polished';
import styled from '../../theme/styled-components';
import TransferLoader from './TransferLoader';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { TransfersListContentContainer } from './TransfersList';

const DateListItem = styled.div`
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  font-weight: bold;
  padding: ${rem(24)};
`;

const Date = styled.div`
  width: 100%;
  height: ${rem(18)};
  background-color: ${({ theme }) => theme.color.lightBorder};
  ${mb('s')} {
    width: 33%;
  }
`;

interface OwnProps {
  loadMore?: boolean;
}

const TransfersListLoader: React.FC<OwnProps> = ({ loadMore = false }) => {
  return (
    <TransfersListContentContainer>
      {!loadMore && (
        <DateListItem>
          <Date className="skeleton" />
        </DateListItem>
      )}
      <TransferLoader skeletonClassName="skeleton" />
      <TransferLoader skeletonClassName="skeleton" />
      <TransferLoader skeletonClassName="skeleton" />
    </TransfersListContentContainer>
  );
};

export default React.memo(TransfersListLoader);
