import { commit } from '@sportnet/redux-list/ducks';
import actionCreatorFactory from 'typescript-fsa';
import asyncFactory from 'typescript-fsa-redux-thunk';
import config from '../../config';
import { ExtraArgumentType } from '../../configureStore';
import InternalServerError from '../../InternalServerError';
import { NormalizedEntities } from '../../library/App';
import { ICompetition, IMatch } from '../../library/Competitions';
import NotFoundError from '../../NotFoundError';
import normalizeEntities from '../../utilities/normalizeEntities';
import { initializeOrSetListParams, updateEntities } from '../App/actions';
import {
  normalizeCompetitionParts,
  setCurrentCompetitionPartId,
} from '../Competition/actions';
import { getCurrentCompetitionAppSpaceSelector } from '../Competition/selectors';

const create = actionCreatorFactory('CLUB');
const createAsync = asyncFactory<any, ExtraArgumentType>(create);

export const normalizeMatches = (data: IMatch[]) => {
  return {
    entities: {
      matches: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: IMatch },
    },
    result: data.map((item) => item._id),
  };
};

export const loadClubMatches = createAsync<
  {
    listName: string;
    offset: number;
    limit?: number;
    dateBefore?: Date;
    dateAfter?: Date;
    appSpace?: string;
    closed?: boolean;
    competitionId?: string;
    partId?: string;
    seasonName?: string;
    teamId?: string;
  },
  void
>(
  'LOAD_CLUB_MATCHES',
  async (
    {
      listName,
      appSpace,
      dateBefore,
      dateAfter,
      closed,
      competitionId,
      partId,
      offset,
      teamId,
      seasonName,
      limit = 20,
    },
    dispatch,
    getState,
    { CompetitionsApi },
  ) => {
    const currentAppSpace =
      appSpace || getCurrentCompetitionAppSpaceSelector(getState());

    if (currentAppSpace) {
      const params: { [key: string]: any } = {
        appSpace: currentAppSpace,
        offset,
      };
      if (competitionId) {
        params.competitionId = competitionId;
      }
      if (partId) {
        params.partId = partId;
      }
      if (seasonName) {
        params.seasonName = seasonName;
      }
      if (teamId) {
        params.teamId = teamId;
      }
      await dispatch(
        initializeOrSetListParams({
          listName,
          params,
        }),
      );
      return dispatch(
        commit.action({
          listName,
          load: async () => {
            const f: {
              dateFrom?: string;
              dateTo?: string;
              limit: number;
              offset: number;
              closed?: boolean;
              playerAppSpace: string;
              competitionId?: string;
              partId?: string;
              seasonName?: string;
              teamId?: string;
              withDate?: boolean;
            } = {
              offset,
              playerAppSpace: currentAppSpace,
              limit,
            };
            if (typeof closed !== 'undefined') {
              f.closed = closed;
            }
            if (dateBefore) {
              f.dateTo = dateBefore.toJSON();
            }
            if (dateAfter) {
              f.dateFrom = dateAfter.toJSON();
            }
            if (competitionId) {
              f.competitionId = competitionId;
            }
            if (partId) {
              f.partId = partId;
            }
            if (seasonName) {
              f.seasonName = seasonName;
            }
            if (teamId) {
              f.teamId = teamId;
            }

            let matches: any = [];
            let total = 0;
            let nextOffset: null | number = null;

            const response = await CompetitionsApi.getMatches({
              ...f,
              withDate: true,
            });

            matches = response.matches;
            total = response.total || 0;
            nextOffset = response.nextOffset || null;

            // robi sarapatu v dnesnych zapasoch, takto tam da hocijake bez datumu
            // if (!nextOffset) {
            //   const matchesWithoutDateResponse =
            //     await CompetitionsApi.getMatches({
            //       ...f,
            //       withDate: false,
            //     });
            //   matches = [...matches, ...matchesWithoutDateResponse.matches];
            //   nextOffset = matchesWithoutDateResponse.nextOffset || null;
            // }

            const { result, entities } = normalizeMatches(matches);

            dispatch(updateEntities(entities));

            return {
              results: result,
              total,
              nextOffset,
              offset,
            };
          },
        }),
      );
    }
  },
);

export const loadLatestClubCompetitionPart = createAsync<
  void,
  NormalizedEntities<'competitionParts'>
>(
  'LOAD_LATEST_CLUB_COMPETITION_PART',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    try {
      const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
      const part = await CompetitionsApi.getLatestTeamAppSpaceResultsTable(
        appSpace,
      );
      dispatch(setCurrentCompetitionPartId(part._id));

      const { entities } = normalizeCompetitionParts([part]);

      dispatch(updateEntities(entities));
      return normalizeEntities('competitionParts', [part]);
    } catch (e: any) {
      if (e && e.details && e.details.status === 404) {
        throw new NotFoundError(e);
      }
      throw new InternalServerError(e);
    }
  },
);

export const normalizeCompetitions = (data: ICompetition[]) => {
  return {
    entities: {
      competitions: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: ICompetition },
    },
    result: data.map((item) => item._id),
  };
};

export const normalizeClubCompetitions = (data: ICompetition[]) => {
  return {
    entities: {
      clubCompetitions: data.reduce((acc, item) => {
        return { ...acc, [String(item._id)]: item };
      }, {}) as { [key: string]: ICompetition },
    },
    result: data.map((item) => item._id),
  };
};

export const loadClubCompetitions = createAsync<void, void>(
  'LOAD_CLUB_COMPETITIONS',
  async (parameters, dispatch, getState, { CompetitionsApi }) => {
    const appSpace = getCurrentCompetitionAppSpaceSelector(getState());
    await dispatch(
      initializeOrSetListParams({
        listName: config.LIST_CLUB_COMPETITIONS,
        params: { appSpace },
      }),
    );
    return dispatch(
      commit.action({
        listName: config.LIST_CLUB_COMPETITIONS,
        load: async () => {
          const { competitions } = await CompetitionsApi.getClubCompetitions(
            appSpace,
            {
              ownerPpo: config.OWNER_PPOS as any,
            },
          );
          const { result, entities } = normalizeClubCompetitions(competitions);

          await dispatch(updateEntities(entities));

          return {
            results: result,
            total: competitions.length,
          };
        },
      }),
    );
  },
);
