import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from '../../theme/styled-components';

const RelativeWrapper = styled.div<{
  withBorder?: boolean;
  inWidget?: boolean;
}>`
  width: auto;
  flex-shrink: 0;
  overflow-x: auto;
  ${mb('m')} {
    width: 100%;
  }
  ${({ withBorder, inWidget }) =>
    withBorder &&
    css`
      margin: ${inWidget ? rem(0) : rem(8)} ${rem(-16)}
        ${inWidget ? rem(16) : rem(32)} ${rem(-16)};
      ${mb('m')} {
        margin: ${inWidget ? rem(0) : rem(8)} 0 ${inWidget ? rem(16) : rem(32)}
          0;
      }
    `}
`;

const Wrapper = styled.div<{ withBorder?: boolean; centered?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 100%;
  ${({ centered }) =>
    !centered &&
    css`
      width: max-content;
    `}
  ${({ withBorder }) =>
    withBorder &&
    css`
      :after {
        content: '';
        height: 1px;
        width: 100%;
        background: ${({ theme }) => theme.color.separator};
        position: absolute;
        z-index: 0;
        bottom: 0;
      }
    `}
`;

export const Tab = styled.div<{
  active: boolean;
  centered?: boolean;
  width?: number;
}>`
  cursor: pointer;
  white-space: nowrap;
  min-width: auto;
  padding: ${rem(16)};
  line-height: ${rem(19)};
  font-size: ${rem(14)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  ${({ centered, width }) =>
    !!centered &&
    !!width &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: ${width}%;
    `}
  ${({ active, theme }) =>
    !!active &&
    css`
      font-weight: 700;
      z-index: 1;
      border-bottom: ${rem(1)} solid ${theme.color.primary};
    `}
`;

interface ITab {
  label: JSX.Element | string;
  value: string;
}

interface OwnProps {
  tabs: ITab[];
  onChange: (tab: ITab) => void;
  centered?: boolean;
  active?: string;
  withBorder?: boolean;
  className?: string;
  inWidget?: boolean;
}

const Tabber: React.FC<OwnProps> = ({
  tabs,
  withBorder = true,
  inWidget = false,
  active,
  centered,
  onChange,
  className,
}) => {
  const renderTab = (tab: ITab, idx: number) => (
    <Tab
      key={tab.value}
      onClick={() => onChange(tab)}
      active={active && active === tab.value}
      {...(!!centered && {
        centered: true,
        width: 100 / tabs.length,
      })}
      className="tabber-tab"
    >
      {tab.label}
    </Tab>
  );
  return (
    <RelativeWrapper
      withBorder={withBorder}
      inWidget={inWidget}
      className={className}
    >
      <Wrapper withBorder={withBorder} centered={centered}>
        {tabs.map(renderTab)}
      </Wrapper>
    </RelativeWrapper>
  );
};

export default Tabber;
