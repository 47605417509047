import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5
    C21,3.9,20.1,3,19,3z M14.1,11.9l-3,3.9L9,13.1L6,17h12C18,17,14.1,11.9,14.1,11.9z"
    />
  </svg>
);
