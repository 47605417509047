import { rem } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useAsyncData } from 'ssr-service';
import BottomPaddedBigAd from '../../../components/Ads/BottomPaddedBigAd';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import SideAd from '../../../components/Ads/SideAd';
import TopAd from '../../../components/Ads/TopAd';
import ContentNotFound from '../../../components/ContentNotFound/withAnimation';
import {
  PaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import Tabber from '../../../components/Tabber';
import VideoJS from '../../../components/VideoJS';
import config from '../../../config';
import { RootState } from '../../../configureStore';
import RempIntegration from '../../../containers/RempIntegration';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import { RouteProps } from '../../../library/App';
import __ from '../../../utilities/__';
import { authUserIsEditorSelector } from '../../App/selectors';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import { currentMatchSelector } from '../selectors';
import SharePanel, { getEmbedCode, getVideoUrl } from './SharePanel';
import FutbalnetMatchBreadcrumbs from '../../../containers/FutbalnetBreadcrumbs/FutbalnetMatchBreadcrumbs';
import VideoApi from './api';
import Loading from '../../../components/Loading';
import styled from '../../../theme/styled-components';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { getRandomString } from '@sportnet/content/utilities/utilities';

const VideoContentWrapper = styled.div`
  margin-bottom: ${rem(24)};
  ${mb('s')} {
    margin-bottom: ${rem(32)} 0;
  }
`;

const mapStateToProps = (state: RootState) => ({
  match: currentMatchSelector(state),
  isEditor: !!authUserIsEditorSelector(state),
});

interface IOwnProps {
  isActive?: boolean;
}

type Props = ReturnType<typeof mapStateToProps> &
  RouteProps<{ zvaz: string; sutaz: string; zapas: string }> &
  IOwnProps;

const CATEGORIES: { [key: string]: string } = {
  live: __('Live'),
  '1.polcas': __('1. polčas'),
  '2.polcas': __('2. polčas'),
  highlighty: __('Highlighty'),
};

const MatchVideo: React.FC<Props> = ({ match, isActive = true, isEditor }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [videos, setVideos] = React.useState<Array<{
    _id?: string;
    category: string;
    src?: string;
    thumbnail?: string;
    type?: string;
  }> | null>(null);
  const [activeTab, setActiveTab] = React.useState('');
  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  useAsyncData(async () => {
    if (match) {
      try {
        setIsLoading(true);
        setError(false);
        const { videos: items } = await VideoApi.publicGetVideos(
          config.OWNER_PPO,
          {
            matchId: match.__issfId,
            competitionMatchId: match._id,
            expand_match: 1,
          },
        );
        setVideos(items);
        if (items.length) {
          setActiveTab(items[0].category);
        }
      } catch (e) {
        console.error(e);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [match]);

  const activeVideo =
    !!activeTab && (videos || []).find((v) => v.category === activeTab);

  if (!videos) {
    return <Loading />;
  }

  if (!match) {
    return null;
  }

  return (
    <>
      {isActive && <TopAd />}
      {isActive && <BottomPaddedBigAd name="big_1" />}

      <RempIntegration destroy />

      <PaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <VideoContentWrapper>
            {isLoading ? (
              <Loading />
            ) : videos.length > 0 ? (
              <>
                <Tabber
                  onChange={(i) => setActiveTab(i.value)}
                  active={activeTab}
                  tabs={videos.map((v) => {
                    const c = CATEGORIES[v.category];
                    return {
                      label: c || v.category,
                      value: v.category,
                    };
                  })}
                />
                {!!activeVideo && activeVideo._id && (
                  <>
                    <VideoJS
                      key={`match-${match._id}-video-${
                        activeVideo._id
                      }-${getRandomString(5)}`}
                      options={{
                        autoplay: false,
                        controls: true,
                        fluid: true,
                        aspectRatio: '16:9',
                        poster: activeVideo.thumbnail,
                        sources: [
                          {
                            src: `${process.env.REACT_APP_VIDEO_API_BASE_URL}/mp4/${activeVideo._id}`,
                            type: activeVideo.type,
                          },
                        ],
                      }}
                      onPlay={() => {
                        if (window && (window as any).videoplayerAnalytics) {
                          ((window || {}) as any).videoplayerAnalytics({
                            data: { videoEvent: { video: activeVideo } },
                          });
                        }
                      }}
                      nuevoOptions={
                        isEditor
                          ? {
                              embed: getEmbedCode(activeVideo._id),
                              url: getVideoUrl(activeVideo._id),
                            }
                          : undefined
                      }
                    />
                    {isEditor && <SharePanel videoId={activeVideo._id} />}
                  </>
                )}
                {!!activeVideo && activeVideo.src && (
                  <iframe
                    title={activeTab}
                    style={{ width: '100%', minHeight: rem(400), border: 0 }}
                    src={activeVideo.src}
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                )}
              </>
            ) : error ? (
              <ContentNotFound
                title={__('Pri získavaní videozáznamov sa vyskytla chyba!')}
              />
            ) : (
              <ContentNotFound
                title={__('Žiaden videozáznam zatiaľ nebol pridaný.')}
              />
            )}
          </VideoContentWrapper>
          <ResponsiveTopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </PaddedSidebarLayout>
      {isActive && (
        <>
          <MegaboardAd />
          <FutbalnetMatchBreadcrumbs
            subpage={{ label: 'Videozáznam', subpageUrl: 'videozaznam' }}
          />
        </>
      )}
    </>
  );
};

export default compose(connect(mapStateToProps))(
  MatchVideo,
) as unknown as React.FC<IOwnProps>;
