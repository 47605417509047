import { IComiteeDetail } from '../api/FutbalnetApi';

export default (comitee: IComiteeDetail) => {
  return {
    id: comitee.id,
    name: comitee.name,
    secretary: (comitee.secretary || []).map((secretary) => ({
      id: secretary.id,
      name: secretary.name,
      surname: secretary.surname,
    })),
    members: comitee.members || [],
  };
};
