// pri prvom nacitni stranky sa nacita 10 clankov (ak nie je v URL zadany parameter `page` alebo je rovny `1`)
const ARTICLES_LIMIT_FIRST_LOAD = 10;
// pri kazdom dalsom docitavani clankov sa na cita po 20 kusov
const ARTICLES_LIMIT_FURTHER_LOADS = 20;

/**
 * kolko clnakov sa nacita pri prvom loade stranky (v URL moze byt zadany parameter `page` vacsi ako 1,
 * ktory saprepocita na `offset`)
 * @param offset
 */
export const getInitialLimitByOffset = (
  offset: number,
  firstLoadLimit: number = ARTICLES_LIMIT_FIRST_LOAD,
  furtherLoadLimit: number = ARTICLES_LIMIT_FURTHER_LOADS,
) => {
  return offset > 0 ? furtherLoadLimit : firstLoadLimit;
};

export const getPageNumberByOffset = (
  offset: number,
  furtherLoadLimit: number = ARTICLES_LIMIT_FURTHER_LOADS,
) => {
  return Math.ceil(offset / furtherLoadLimit) + 1;
};

export const getOffsetByPageNumber = (
  page: number,
  firstLoadLimit: number = ARTICLES_LIMIT_FIRST_LOAD,
  furtherLoadLimit: number = ARTICLES_LIMIT_FURTHER_LOADS,
) => {
  if (page <= 1) {
    return 0;
  } else if (page === 2) {
    return firstLoadLimit;
  } else {
    return firstLoadLimit + (page - 2) * furtherLoadLimit;
  }
};
