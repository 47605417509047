import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="M12,10.8c-0.2,0-0.4-0.1-0.5-0.2L8,7.1l-3.5,3.5c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l4-4
      c0.3-0.3,0.8-0.3,1.1,0l4,4c0.3,0.3,0.3,0.8,0,1.1C12.4,10.7,12.2,10.8,12,10.8z"
    />
  </svg>
);
