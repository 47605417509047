import { rem } from 'polished';
import React from 'react';
import styled, { css } from '../../../../../theme/styled-components';

const Wrapper = styled.button<{ active: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  :active {
    outline: none;
  }
  flex-shrink: 0;
  width: ${rem(24)};
  height: ${rem(24)};
  ${({ active }) =>
    active &&
    css`
      width: ${rem(16)};
      height: ${rem(16)};
    `}
  padding: ${rem(2)};
  position: relative;
`;

const Line = styled.span<{ active: boolean; color: string }>`
  display: block;
  position: absolute;
  left: ${rem(2)};

  height: ${rem(2)};
  border-radius: ${rem(8)};
  background-color: ${({ color }) => color};

  transform-origin: left center;
  transition: all 200ms ease-in-out;
`;

const LineTop = styled(Line)<{ active: boolean }>`
  top: ${rem(4)};
  width: ${rem(20)};
  ${({ subMenuActive }) =>
    subMenuActive &&
    css`
      width: ${rem(7)};
      transform: rotate(-45deg);
      top: ${rem(8)};
    `}
  ${({ active, subMenuActive }) =>
    active &&
    !subMenuActive &&
    css`
      transform: rotate(45deg);
      top: ${rem(3)};
      left: ${rem(4)};
      width: ${rem(12.5)};
    `}
`;
const LineMiddle = styled(Line)<{ active: boolean }>`
  top: ${rem(11)};
  width: ${rem(16)};
  ${({ active }) =>
    active &&
    css`
      opacity: 0;
    `}
`;

const LineBottom = styled(Line)<{ active: boolean }>`
  top: ${rem(18)};
  width: ${rem(20)};
  ${({ subMenuActive }) =>
    subMenuActive &&
    css`
      width: ${rem(7)};
      transform: rotate(45deg);
      top: ${rem(7)};
    `}
  ${({ active, subMenuActive }) =>
    active &&
    !subMenuActive &&
    css`
      transform: rotate(-45deg);
      top: ${rem(11.5)};
      left: ${rem(4)};
      width: ${rem(12.5)};
    `}
`;

interface OwnProps {
  color: string;
  active: boolean;
  onToggle: () => void;
  className?: string;
  subMenuActive: boolean;
}

const Burger: React.FC<OwnProps> = ({
  className,
  active,
  subMenuActive,
  color,
  onToggle,
}) => {
  return (
    <Wrapper active={active} className={className} onClick={onToggle}>
      <LineTop subMenuActive={subMenuActive} active={active} color={color} />
      <LineMiddle subMenuActive={subMenuActive} active={active} color={color} />
      <LineBottom subMenuActive={subMenuActive} active={active} color={color} />
    </Wrapper>
  );
};

export default Burger;
