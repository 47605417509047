import {
  IRenderLink,
  IWidgetPhotoGallery,
  IWidgetPicture,
} from '@sportnet/content/utilities/library';

export interface IWidgetInternalContentPhotogallery {
  _id: number;
  doctype: string;
  name: string;
  valid_from: string | null;
  url: string;
  picture?: IWidgetPicture;
  widgets: IWidgetPhotoGallery[];
  perex?: string;
}

interface OwnProps {
  articlePreview: IWidgetInternalContentPhotogallery;
  renderLink: IRenderLink;
  showTitle: boolean;
  customTitle: string;
  customWidgetTitle: string;
  showWidgetTitle: boolean;
}

// eslint-disable-next-line no-empty-pattern
const InternalContentPhotogallery: React.FC<OwnProps> = ({}) => {
  return null;
};

export default InternalContentPhotogallery;
