export const SPORT_SECTOR_PHASES = [
  {
    _id: "futbal",
    items: [
      {
        _id: "1HT",
        label: "1. polčas",
        playPhase: true,
        basePhase: true,
        playTimeOrder: 1,
        startTime: 0,
        endTime: 2700,
      },
      {
        _id: "2HT",
        label: "2. polčas",
        playPhase: true,
        basePhase: true,
        playTimeOrder: 2,
        startTime: 2700,
        endTime: 5400,
      },
      {
        _id: "1ET",
        label: "1. predĺženie",
        playPhase: true,
        playTimeOrder: 3,
        startTime: 5400,
        endTime: 6300,
      },
      {
        _id: "2ET",
        label: "2. predĺženie",
        playPhase: true,
        playTimeOrder: 4,
        startTime: 6300,
        endTime: 7200,
      },
      { _id: "shootout", label: "Pokutové kopy", playPhase: true },
      { _id: "break", label: "Prestávka", playPhase: false },
      { _id: "end", label: "Koniec stretnutia", playPhase: false },
    ],
  },
  {
    _id: "beachfutbal",
    items: [
      {
        _id: "1Q",
        label: "1. tretina",
        playPhase: true,
        basePhase: true,
        playTimeOrder: 1,
        startTime: 0,
        endTime: 720,
      },
      {
        _id: "2Q",
        label: "2. tretina",
        playPhase: true,
        basePhase: true,
        playTimeOrder: 2,
        startTime: 720,
        endTime: 1440,
      },
      {
        _id: "3Q",
        label: "3. tretina",
        playPhase: true,
        basePhase: true,
        playTimeOrder: 3,
        startTime: 1440,
        endTime: 2160,
      },
      {
        _id: "1ET",
        label: "Predĺženie",
        playPhase: true,
        playTimeOrder: 4,
        startTime: 2160,
        endTime: 2340,
      },
      { _id: "shootout", label: "Pokutové kopy", playPhase: true },
      { _id: "break", label: "Prestávka", playPhase: false },
      { _id: "end", label: "Koniec stretnutia", playPhase: false },
    ],
  },
];
