import Input from '@sportnet/ui/lib/Input';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';

const InputWrapper = styled.div<{
  hasSearchQuery: boolean;
  inputFocused: boolean;
}>`
  > div {
    box-shadow: 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.12);
  }
  input {
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: ${rem(1)} solid rgba(221, 221, 221, 1) !important;
    border-left-width: 0 !important;
    ${({ hasSearchQuery, inputFocused }) => {
      if (inputFocused && !hasSearchQuery) {
        return css`
          // border-right-width: 0 !important;
          border-radius: 0;
          ${mb('m')} {
            border-right-width: ${rem(1)} !important;
            border-radius: 0 ${rem(4)} ${rem(4)} 0;
          }
        `;
      } else if (hasSearchQuery) {
        return css`
          border-right-width: 0 !important;
          border-radius: 0;
        `;
      }
      return css`
        border-radius: 0 ${rem(4)} ${rem(4)} 0;
      `;
    }};
  }
  > div > .inputSearchIcon {
    border-width: ${rem(1)};
    padding-left: ${rem(16)};
    border-color: rgba(221, 221, 221, 1) !important;
    border-top: ${rem(1)} solid rgba(221, 221, 221, 1) !important;
    border-left: ${rem(1)} solid rgba(221, 221, 221, 1) !important;
    border-bottom: ${rem(1)} solid rgba(221, 221, 221, 1) !important;
  }
  > div > .inputBackspace {
    ${({ hasSearchQuery }) =>
      hasSearchQuery
        ? css``
        : css`
            display: none;
          `};
    cursor: pointer;
    border-left: 0 !important;
    border-top: 1px solid rgba(221, 221, 221, 1) !important;
    border-bottom: 1px solid rgba(221, 221, 221, 1) !important;
    border-right: 1px solid rgba(221, 221, 221, 1) !important;
    padding: 0 ${rem(16)};
    ${({ inputFocused }) =>
      inputFocused
        ? css`
            ${mb('m')} {
              border-right: 1px solid rgba(221, 221, 221, 1) !important;
              border-radius: 0 ${rem(4)} ${rem(4)} 0;
            }
          `
        : css`
            padding-right: ${rem(16)};
            border-right: 1px solid rgba(221, 221, 221, 1) !important;
            border-radius: 0 ${rem(4)} ${rem(4)} 0;
          `};
  }
  > div > .inputCancel {
    ${({ inputFocused }) =>
      inputFocused
        ? css``
        : css`
            display: none;
          `}
    border-left: 0 !important;
    border-top: 1px solid rgba(221, 221, 221, 1) !important;
    border-bottom: 1px solid rgba(221, 221, 221, 1) !important;
    border-right: 1px solid rgba(221, 221, 221, 1) !important;
    font-size: ${rem(13)};
    padding: 0 ${rem(16)};
    ${mb('m')} {
      display: none;
    }
  }
  > div > .inputCancel:hover {
    background: transparent;
  }
  > div > .inputLoading {
    border-width: ${rem(1)};
    padding-left: ${rem(16)};
    border-color: rgba(221, 221, 221, 1) !important;
  }
`;

interface IOwnProps {
  value: string;
  setValue: (value: string) => void;
  inputFocused: boolean;
  setInputFocused: (state: boolean) => void;
  inputFocus: () => void;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  placeholder?: string;
  isFetching?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSearchClear?: () => void;
}

type Props = IOwnProps;

const SearchInput: React.FC<Props> = ({
  inputRef,
  placeholder,
  value,
  setValue,
  inputFocused,
  setInputFocused,
  inputFocus,
  isFetching,
  onKeyDown,
  onSearchClear,
}) => {
  return (
    <InputWrapper hasSearchQuery={!!value} inputFocused={inputFocused}>
      <Input
        value={value}
        inputRef={inputRef}
        onFocus={() => {
          setInputFocused(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setInputFocused(false);
          }, 0);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
        }}
        placeholder={placeholder || ''}
        onKeyDown={onKeyDown}
        enterKeyHint="search"
      >
        {isFetching ? (
          <Input.Loading className="inputLoading" />
        ) : (
          <Input.Icon
            className="inputSearchIcon"
            onClick={inputFocus}
            color="#aaa"
            size={16}
            name="search"
          />
        )}
        <input />
        <Input.Icon
          className="inputBackspace"
          onClick={(e) => {
            setValue('');
            if (onSearchClear) {
              onSearchClear();
            }
            inputFocus();
          }}
          name="backspace"
        />
        {/* <Input.Button
          className="inputCancel"
          onClick={() => {
            setInputFocused(false);
          }}
        >
          {__('Zrušiť')}
        </Input.Button> */}
      </Input>
    </InputWrapper>
  );
};

export default SearchInput;
