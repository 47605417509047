import { SectionId, SectionNode, SectionTreeType } from '../library/App';

const traverseTreeBFS = (
  tree: SectionTreeType,
  callback: (section: SectionNode) => boolean,
) => {
  const queue = [...tree];
  let element: SectionNode | undefined;
  while (queue.length > 0) {
    element = queue.shift();

    if (!element) {
      continue;
    }

    if (callback(element)) {
      return element;
    }

    (element.sections || []).forEach((s) => {
      queue.push(s);
    });
  }
  return null;
};

export default (
  sectionIdOrUniqId: string | SectionId | null,
  tree: SectionTreeType,
) => {
  const sectionIdOrUniqIdNumber = Number(sectionIdOrUniqId);
  return traverseTreeBFS(tree, (section) => {
    return (
      section._id === sectionIdOrUniqIdNumber ||
      section.uniqid === sectionIdOrUniqId
    );
  });
};

export const findSectionInTreeByUrl = (
  sectionUrl: string,
  tree: SectionTreeType,
) => {
  return traverseTreeBFS(tree, (section) => {
    return section.url === sectionUrl;
  });
};
