import Loader from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import styled, { withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';

const Wrapper = styled('div')`
  display: flex;
  min-height: 50vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Loading: React.FC<{
  theme: ITheme;
  size?: string;
  minHeight?: string;
}> = ({ theme, size = 'xl', minHeight = '50vh' }) => {
  return (
    <Wrapper style={{ minHeight }}>
      <Loader size={size} color={theme.loader.color} />
    </Wrapper>
  );
};

export default withTheme(Loading);
