import {
  formatParameters,
  getListParameters,
  getListResults,
  listDomain,
} from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { entitiesSelector } from '../App/selectors';
import { ICompetition } from '../../library/Competitions';

const currentClubResultsIdsSelector = createSelector(
  listDomain(config.LIST_CLUB_RESULTS),
  getListParameters(config.LIST_CLUB_RESULTS),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_CLUB_RESULTS_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentClubResultsSelector = createSelector(
  entitiesSelector,
  currentClubResultsIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

const currentClubFixturesIdsSelector = createSelector(
  listDomain(config.LIST_CLUB_FIXTURES),
  getListParameters(config.LIST_CLUB_FIXTURES),
  (domain, parameters) => {
    let offset = parameters.offset || 0;
    const limit = parameters.limit || config.LIST_CLUB_FIXTURES_LIMIT;
    const keys: string[] = [];
    while (offset >= 0) {
      keys.unshift(formatParameters({ ...parameters, offset }));
      offset -= limit;
    }
    const byParameters = domain.byParameters || {};
    return keys.reduce((acc: any[], key) => {
      return acc.concat(byParameters[key]?.results || []);
    }, []);
  },
);

export const currentClubFixturesSelector = createSelector(
  entitiesSelector,
  currentClubFixturesIdsSelector,
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentClubCompetitionsSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_CLUB_COMPETITIONS),
  (entities, ids) => at(entities.clubCompetitions, ids || []) as ICompetition[],
);
