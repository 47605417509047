import React from 'react';

/**
 * don't forget to memoize callback supplied to the hook
 */
function useClickOutside(callback: () => void, active = true) {
  const ref = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (!active) {
      return;
    }
    const handleClick = (e: MouseEvent) => {
      const el = ref.current;
      if (el && !el.contains(e.target as Node)) {
        callback();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [callback, active]);

  return ref;
}

export default useClickOutside;
