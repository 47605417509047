import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 46 46.038"
  >
    <g>
      <circle cx="23" cy="23" r="23" fill="#fff" />
      <g>
        <path
          fill="#e60019"
          d="M23 0a23.007 23.007 0 0 0-8.386 44.429 22.143 22.143 0 0 1 .076-6.607c.416-1.8 2.688-11.434 2.688-11.434a8.378 8.378 0 0 1-.681-3.407c0-3.2 1.855-5.584 4.165-5.584 1.969 0 2.915 1.477 2.915 3.237 0 1.969-1.249 4.922-1.912 7.667a3.346 3.346 0 0 0 3.407 4.165c4.089 0 7.231-4.316 7.231-10.525 0-5.509-3.956-9.351-9.616-9.351a9.954 9.954 0 0 0-10.393 9.976 8.978 8.978 0 0 0 1.7 5.244.685.685 0 0 1 .151.663c-.17.719-.568 2.291-.644 2.612-.095.416-.341.511-.776.3-2.877-1.344-4.676-5.528-4.676-8.916 0-7.25 5.263-13.914 15.2-13.914 7.97 0 14.179 5.679 14.179 13.289 0 7.932-5 14.311-11.926 14.311-2.328 0-4.524-1.212-5.263-2.65L19 38.976a25.058 25.058 0 0 1-2.858 6.039A23.021 23.021 0 1 0 23 0z"
        />
      </g>
    </g>
  </svg>
);
