import { IMatch } from '../../library/Competitions';
import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';

import { isAfter, isSameDay } from 'date-fns';
import { rem } from 'polished';
import Icon from '../../components/Icon';
import Link from '../../components/Link';
import MatchesPanelMatch from '../../sportnet.sme.sk-ui/MatchesPanel/Match';
import React from 'react';
import __ from '../../utilities/__';
import formatDate from '../../utilities/formatDate';
import getFootballEndResultType from '../../utilities/getFootballEndResultType';
import getIceHockeyEndResultType from '../../utilities/getIceHockeyEndResultType';
import getMatchAwayTeam from '../../utilities/match/getMatchAwayTeam';
import getMatchHomeTeam from '../../utilities/match/getMatchHomeTeam';
import getMatchUrl from '../../utilities/match/getMatchUrl';
import getPhaseFromTimer from '../../utilities/match/getPhaseFromTimer';
import getPhaseLabel from '../../utilities/match/getPhaseLabel';
import getPublicCountryCode from '../../utilities/match/getPublicCountryCode';

import shortenRoundName from '../../utilities/match/shortenRoundName';
import styled from 'styled-components';
import { getCompetitionName } from '../../utilities/match/getCompetitionName';
import { getTeamLogoAlt } from '../../utilities/match/getTeamLogoAlt';
import { getMatchTelevisionBroadcasters } from '../../utilities/match/getMatchTelevisionBroadcasters';
import {
  getTeamLogoSrc,
  getTeamName,
} from '../../components/Widgets/Overrides/MatchesList/utilities';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 600;
  font-size: ${rem(13)};
  line-height: normal;
  .icon {
    margin-left: ${rem(4)};
  }
`;

const Wrapper = styled(Link)`
  position: relative;
  height: 100%;
  display: flex;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  line-height: normal;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 250ms ease-in-out;
  align-items: center;
  &:hover {
    ${Overlay} {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

const WrapperDiv = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  line-height: normal;
  cursor: initial;
  outline: none;
  border: none;
  align-items: center;
`;

type IOwnProps = {
  match: IMatch;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const Match: React.FC<IProps> = ({ match }) => {
  const isCompetitionInterstate = match.competition.interstate ?? false;

  const homeTeam = getMatchHomeTeam(
    match as unknown as IWidgetMatchesListMatchItem
  );
  const awayTeam = getMatchAwayTeam(
    match as unknown as IWidgetMatchesListMatchItem
  );
  const [homeTeamGoals, awayTeamGoals] = match.score || [undefined, undefined];
  const [homeTeamPenaltyGoals, awayTeamPenaltyGoals] = match.penaltiesScore || [
    undefined,
    undefined,
  ];

  const competitionName = getCompetitionName(match as any);

  const sport = match.rules?.sport_sector ?? '';

  const isClosed = !!match.closed && !!match.score && !!match.score.length;
  const isLive =
    !match.closed &&
    !!match.startDate &&
    isSameDay(new Date(), new Date(match.startDate)) &&
    isAfter(new Date(), new Date(match.startDate));
  const homeTeamName =
    homeTeam?.shortName ||
    getPublicCountryCode(homeTeam?.organization?.country) ||
    getTeamName(match.appSpace, isCompetitionInterstate, homeTeam);

  const awayTeamName =
    awayTeam?.shortName ||
    getPublicCountryCode(awayTeam?.organization?.country) ||
    getTeamName(match.appSpace, isCompetitionInterstate, awayTeam);

  const homeTeamNameAlt = getTeamName(
    match.appSpace,
    isCompetitionInterstate,
    homeTeam
  );
  const awayTeamNameAlt = getTeamName(
    match.appSpace,
    isCompetitionInterstate,
    awayTeam
  );

  const roundName = match.round?.name.startsWith('Matchday')
    ? match.competitionPart.name
    : match.round?.name;

  let PPWinnerTeamId = null;
  let SNWinnerTeamId = null;
  let PENWinnerTeamId = null;

  if (match.timer && match.timer.OT) {
    const result = getIceHockeyEndResultType(match, {
      homeTeamId: homeTeam?._id || '',
      awayTeamId: awayTeam?._id || '',
    });
    PPWinnerTeamId = result.PPWinnerTeamId;
    SNWinnerTeamId = result.SNWinnerTeamId;
  } else if (match.timer && match.timer['1ET']) {
    const result = getFootballEndResultType(match, {
      homeTeamId: homeTeam?._id || '',
      awayTeamId: awayTeam?._id || '',
    });
    PPWinnerTeamId = result.PPWinnerTeamId;
    PENWinnerTeamId = result.SNWinnerTeamId;
  }

  const homeTeamScore =
    homeTeamGoals !== undefined
      ? homeTeamGoals +
        (homeTeamPenaltyGoals !== undefined &&
        awayTeamPenaltyGoals !== undefined &&
        homeTeamPenaltyGoals > awayTeamPenaltyGoals
          ? 1
          : 0)
      : undefined;

  const awayTeamScore =
    awayTeamGoals !== undefined
      ? awayTeamGoals +
        (awayTeamPenaltyGoals !== undefined &&
        homeTeamPenaltyGoals !== undefined &&
        awayTeamPenaltyGoals > homeTeamPenaltyGoals
          ? 1
          : 0)
      : undefined;

  const homeTeamScoreNote =
    homeTeam?._id === PPWinnerTeamId
      ? 'PP'
      : homeTeam?._id === SNWinnerTeamId
      ? 'SN'
      : homeTeam?._id === PENWinnerTeamId
      ? 'p.k.'
      : undefined;
  const awayTeamScoreNote =
    awayTeam?._id === PPWinnerTeamId
      ? 'PP'
      : awayTeam?._id === SNWinnerTeamId
      ? 'SN'
      : awayTeam?._id === PENWinnerTeamId
      ? 'p.k.'
      : undefined;

  const homeTeamIsWinning =
    homeTeamGoals !== undefined && awayTeamGoals !== undefined
      ? homeTeamGoals > awayTeamGoals ||
        (homeTeamGoals === awayTeamGoals &&
          homeTeamPenaltyGoals !== undefined &&
          awayTeamPenaltyGoals !== undefined &&
          homeTeamPenaltyGoals > awayTeamPenaltyGoals)
        ? true
        : false
      : false;

  const awayTeamIsWinning =
    homeTeamGoals !== undefined && awayTeamGoals !== undefined
      ? homeTeamGoals < awayTeamGoals ||
        (homeTeamGoals === awayTeamGoals &&
          homeTeamPenaltyGoals !== undefined &&
          awayTeamPenaltyGoals !== undefined &&
          awayTeamPenaltyGoals > homeTeamPenaltyGoals)
        ? true
        : false
      : false;

  const liveMatchPhase = isLive ? getPhaseFromTimer(match.timer) : '';

  const dateOrMatchPart = isClosed
    ? formatDate(new Date(match.startDate), 'dd.MM.')
    : isLive
    ? liveMatchPhase
      ? getPhaseLabel(liveMatchPhase, sport)
      : formatDate(new Date(match.startDate), 'dd.MM.')
    : formatDate(new Date(match.startDate), 'dd.MM. HH:mm');

  // pri odohranom zapase defualtne skracujeme nazov kola/skupiny
  // ale na mobile ide v plnej verzii
  const roundNameByMatchState = isClosed
    ? shortenRoundName(roundName ?? '')
    : roundName;

  const televisionBroadcasters = getMatchTelevisionBroadcasters(match as any);

  const matchUrl = getMatchUrl(match);

  const renderMatch = () => (
    <MatchesPanelMatch
      homeTeam={{
        teamName: homeTeamName,
        teamLogoSrc: getTeamLogoSrc(
          match.appSpace,
          isCompetitionInterstate,
          homeTeam as any
        ),
        score:
          homeTeamScore !== undefined
            ? String(homeTeamScore)
            : isLive
            ? '0'
            : '', // pre live zapas moze prist `score` ako `null` ale dohoda je zorbazit 0:0 v takom pripade
        scoreNote: homeTeamScoreNote,
        teamLogoAlt: getTeamLogoAlt(
          match.appSpace,
          homeTeamNameAlt,
          competitionName
        ),
      }}
      homeTeamIsWinning={homeTeamIsWinning}
      awayTeam={{
        teamName: awayTeamName,
        teamLogoSrc: getTeamLogoSrc(
          match.appSpace,
          isCompetitionInterstate,
          awayTeam as any
        ),
        score:
          awayTeamScore !== undefined
            ? String(awayTeamScore)
            : isLive
            ? '0'
            : '', // pre live zapas moze prist `score` ako `null` ale dohoda je zorbazit 0:0 v takom pripade
        scoreNote: awayTeamScoreNote,
        teamLogoAlt: getTeamLogoAlt(
          match.appSpace,
          awayTeamNameAlt,
          competitionName
        ),
      }}
      awayTeamIsWinning={awayTeamIsWinning}
      isLive={isLive}
      isClosed={isClosed}
      isFuture={!isLive && !isClosed}
      dateOrMatchPhase={dateOrMatchPart}
      dateOrMatchPhaseMobile={dateOrMatchPart}
      roundName={roundNameByMatchState ?? ''}
      roundNameMobile={roundName ?? ''}
      roundNameShort={
        !isLive && !isClosed
          ? shortenRoundName(roundName ?? '')
          : roundName ?? ''
      }
      televisionBroadcasters={televisionBroadcasters}
    />
  );

  return matchUrl ? (
    <Wrapper to={getMatchUrl(match)}>
      {renderMatch()}
      <Overlay>
        {__('Detail zápasu')}
        <Icon name="arrowMore" size={14} />
      </Overlay>
    </Wrapper>
  ) : (
    <WrapperDiv> {renderMatch()}</WrapperDiv>
  );
};

export default Match;
