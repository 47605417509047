import React from 'react';
import FutbalnetLogo from './FutbalnetLogo';
import TitleBlock from './TitleBlock';

type IOwnProps = {};

type IProps = IOwnProps;

const FutbalnetTitleBlock: React.FC<IProps> = ({ children }) => {
  return (
    <TitleBlock>
      {children}
      <FutbalnetLogo />
    </TitleBlock>
  );
};

export default FutbalnetTitleBlock;
