import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { entitiesSelector } from '../App/selectors';

export const comiteeDetailSelector = (comiteeId: string) =>
  createSelector(entitiesSelector, (entities) =>
    comiteeId in entities.comitees ? entities.comitees[comiteeId] : null,
  );

export const resolutionDetailSelector = (resolutionNumber: string) =>
  createSelector(entitiesSelector, (entities) =>
    resolutionNumber in entities.latestResolutions
      ? entities.latestResolutions[resolutionNumber]
      : null,
  );

export const seasonsSelector = createSelector(
  entitiesSelector,
  (entities) => Object.values(entities.seasons || []).map((v) => v),
);

export const sortedSeasonsSelector = createSelector(
  seasonsSelector,
  (seasons) => seasons.sort((a, b) => (a.txtid < b.txtid ? 1 : -1))
);

export const latestResolutionsSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_RESOLUTIONS),
  (entities, ids) => {
    return at(entities.latestResolutions, ids || []);
  },
);

// vsetky komisie ktore prijali aspon jedno rozhodnutie
export const commiteesWithLatestResolutionsSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_COMMITEES_WITH_LATEST_RESOLUTIONS),
  (entities, ids) => {
    const items = at(entities.commiteesWithLatestResolutions, ids || []);
    return items.filter((i) =>
      i?.latestresolutions?.resolutions
        ? i?.latestresolutions?.resolutions.length > 0
        : false,
    );
  },
);
