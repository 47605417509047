import * as React from 'react';

export default (size: string, color: string) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 12 12"
    xmlSpace="preserve"
    width={size}
    height={size}
  >
    <rect
      id="Rectangle_8655"
      data-name="Rectangle 8655"
      width={size}
      height={size}
      fill="none"
    />
    <path
      id="Path_1831"
      data-name="Path 1831"
      d="M5.5,5l.7.7L3.915,8H12V9H3.915l2.3,2.3L5.5,12,2,8.5Z"
      transform="translate(-1 -2.5)"
      fill="#ff0005"
    />
  </svg>
);
