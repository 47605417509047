import { rem } from 'polished';
import * as React from 'react';
import { Collapse } from 'react-collapse';
import styled, { css } from '../../theme/styled-components';

export const SegmentWrapper = styled.div<{
  padding?: number;
  noGutter?: boolean;
}>`
  .ReactCollapse--collapse {
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .ReactCollapse--content {
    display: flex;
    flex-direction: column;
  }
  background: #ffffff;
  border: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  border-radius: ${rem(8)};
  opacity: 1;
  padding: ${({ theme, padding }) =>
    rem(padding ? padding : theme.grid.gutterWidth / 2)};
  ${({ noGutter }) =>
    !!noGutter &&
    css`
      padding-top: 0;
      padding-bottom: 0;
    `}
`;

const Title = styled.div`
  font-size: ${rem(18)};
  line-height: ${rem(21.6)};
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.separator};
  padding-bottom: ${rem(24)};
  margin-bottom: ${rem(24)};
  font-family: ${({ theme }) => theme.primaryFont.family };
  color: ${({ theme }) => theme.textColor};
`;

type OwnProps = {
  title?: JSX.Element | string;
  padding?: number;
  noGutter?: boolean;
  className?: string;
  header?: any;
};

const Segment: React.FC<OwnProps> = ({
  padding,
  noGutter,
  title,
  header,
  className,
  children,
}) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(
    !!header && !header.props.toggleCollapse && !!header.props.isCollapsed,
  );
  const internalCollapsed =
    header && !!header.props.toggleCollapse
      ? !!header.props.isCollapsed
      : collapsed;
  return (
    <SegmentWrapper padding={padding} noGutter={noGutter} className={className}>
      {!!header && !!header.props.collapsible && (
        <React.Fragment>
          {React.cloneElement(header, {
            isCollapsed: internalCollapsed,
            toggleCollapse: (state: boolean) => {
              if (header.props.toggleCollapse) {
                header.props.toggleCollapse(state);
              } else {
                setCollapsed(state);
              }
            },
          })}
          <Collapse isOpened={!internalCollapsed}>
            <>{children}</>
          </Collapse>
        </React.Fragment>
      )}
      {!!header && !header.props.collapsible && (
        <React.Fragment>
          {React.cloneElement(header)}
          {children}
        </React.Fragment>
      )}
      {title && <Title>{title}</Title>}
      {!header && children}
    </SegmentWrapper>
  );
};

export default Segment;
