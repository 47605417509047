import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={size}
    height={size}
  >
    <path
      fill={color}
      transform="translate(-1 -2)"
      d="M9,7.3c1.4,0,2.5,1.1,2.5,2.5s-1.1,2.5-2.5,2.5s-2.5-1.1-2.5-2.5
S7.6,7.3,9,7.3z M6.7,2L5.3,3.6H2.8c-0.9,0-1.5,0.7-1.6,1.6v9.3c0,0.9,0.7,1.6,1.6,1.6h12.4c0.9,0,1.5-0.7,1.6-1.6V5.1
c0-0.9-0.7-1.6-1.6-1.6h-2.5L11.3,2H6.7z M9,13.7c-2.1,0-3.9-1.7-3.9-3.9S6.9,5.9,9,5.9s3.9,1.7,3.9,3.9l0,0
C12.9,11.9,11.1,13.7,9,13.7z"
    />
  </svg>
);
