import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M15.4,6.9c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1
      c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0h-4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2.1l-1.7-1.6c-0.6-0.6-1.2-1-2-1.3
      c-1.3-0.5-2.7-0.4-4,0.2c-1.3,0.6-2.2,1.7-2.7,3C1.4,9,2.9,12,5.6,12.9c2.7,1,5.7-0.5,6.7-3.2c0.1-0.4,0.6-0.6,1-0.5
      c0.4,0.1,0.6,0.6,0.5,1c-1,2.8-3.6,4.5-6.4,4.5c-0.7,0-1.5-0.1-2.2-0.4C1.6,13.1-0.3,9.3,1,5.8c0.6-1.7,1.8-3.1,3.5-3.8
      C6,1.1,7.9,1,9.6,1.6c0.9,0.3,1.8,0.9,2.5,1.6l1.8,1.7V2.7c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4c0,0,0,0.1,0,0.1
      C15.4,6.8,15.4,6.9,15.4,6.9z"
    />
  </svg>
);
