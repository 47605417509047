import { mb } from '@sportnet/ui/lib/Themes/utilities';
import isPast from 'date-fns/isPast';
import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';

const WrapperLink = styled.a`
  display: block;
  img {
    vertical-align: bottom; /* fix for bottom gap */
    max-width: 100%;
    width: 100%;
  }
  margin: 0 ${rem(-16)} ${rem(32)} ${rem(-16)};
  ${mb('m')} {
    margin: 0 0 ${rem(32)} 0;
  }
`;

const CustomBanner: React.FC<{
  validFrom: Date;
  validTo: Date;
  linkTo: string;
  src: string;
  alt: string;
  srcset?: string;
  sizes?: string;
}> = ({ validFrom, validTo, linkTo, src, alt, srcset, sizes }) => {
  if (isPast(validFrom) && !isPast(validTo)) {
    return (
      <WrapperLink href={linkTo} target="_blank">
        <img {...{ src, alt, srcset, sizes }} />
      </WrapperLink>
    );
  }

  return null;
};

export default React.memo(CustomBanner);
