import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { SKELETON_CLASS_NAME } from '../../../components/v2/SkeletonLoader';
import MatchListItemLoader from './MatchListItemLoader';
import { EmptyFooter, EmptyHeader, RoundName, Table } from './RenderPrintData';

const RoundNameLoader = styled(RoundName)`
  width: ${rem(150)};
  height: ${rem(32)};
  margin: 0.5cm 0;
`;

const Tr = styled.tr`
  font-family: ${({ theme }) => theme.primaryFont.family};
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

const RenderPrintDataLoader: React.FC = () => {
  return (
    <Table>
      <EmptyHeader>
        <tr>
          <td colSpan={6}>&nbsp;</td>
        </tr>
      </EmptyHeader>
      <tbody>
        <Tr>
          <td colSpan={6}>
            <RoundNameLoader className={SKELETON_CLASS_NAME} />
          </td>
        </Tr>
        <MatchListItemLoader />
        <MatchListItemLoader />
        <MatchListItemLoader />
        <MatchListItemLoader />
        <MatchListItemLoader />
      </tbody>
      <EmptyFooter>
        <tr>
          <td colSpan={6}>&nbsp;</td>
        </tr>
      </EmptyFooter>
    </Table>
  );
};

export default RenderPrintDataLoader;
