import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import getDay from 'date-fns/getDay';
import isToday from 'date-fns/isToday';
import formatDate from './formatDate';

const days = ['ne', 'po', 'ut', 'st', 'št', 'pi', 'so'];

export default (date: number | Date) => {
  const difInMinutes = differenceInMinutes(new Date(), date);
  const difInDays = differenceInDays(new Date(), date);

  if (difInMinutes <= 5) {
    return 'teraz';
  }

  if (isToday(date)) {
    return formatDate(date, `'dnes' HH:mm`);
  }

  if (difInDays <= 7) {
    const day = days[getDay(date)];
    return formatDate(date, `'${day}' HH:mm`);
  }

  return formatDate(date, `dd.MM.yyyy`);
};
