import React from 'react';

export default (size: string, color: string) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M11,15c-0.3,0-0.5-0.1-0.7-0.3l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.3,2.3l9.3-9.3c0.4-0.4,1-0.4,1.4,0
    s0.4,1,0,1.4l-10,10C11.5,14.9,11.3,15,11,15z M21,19v-7c0-0.6-0.4-1-1-1s-1,0.4-1,1v7c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V5
    c0-0.6,0.4-1,1-1h11c0.6,0,1-0.4,1-1s-0.4-1-1-1H4C2.3,2,1,3.3,1,5v14c0,1.7,1.3,3,3,3h14C19.7,22,21,20.7,21,19z"
    />
  </svg>
);
